import React, { useEffect, useState } from "react";
import Select from "../../../react-ui/Select";

function ProfilSelection({
  options,
  optionId,
  updateItem = null,
  itemId,
  keyName,
  optionKeyName = "title",
  confirmSelectFunction = null,
  disabled,
  ...selectProps
}) {
  const [selected, setSelected] = useState(optionId);

  useEffect(() => {
    setSelected(optionId);
  }, [optionId]);

  const selectItem = (e) => {
    setSelected(e);

    if (updateItem)
      updateItem({
        id: itemId,
        [keyName]: e,
      });
  };

  const handleOnSelect = (e) => {
    if (confirmSelectFunction) confirmSelectFunction(e, selectItem);
    else selectItem(e);
  };

  return (
    <Select
      {...selectProps}
      size="small"
      keyName={optionKeyName}
      options={options}
      value={selected}
      fontSize="10px"
      onSelect={handleOnSelect}
      disabled={disabled}
    />
  );
}

export default ProfilSelection;
