import React, { useMemo } from "react";
import styled from "styled-components";
import { formatNumberString } from "../../../utils/formatNumberString";
import FormattedInput from "../../react-ui/FormattedInput";
import { centsToEuros } from "../../../utils/currencyConverter";
import EntryDate from "./EntryDate";
import FormattedSelect from "./FormattedSelect";
import Label from "../../Form/Label";

function AgentInformations({
  coefficients,
  qualifications,
  contractTypes,
  updateAgent,
  contract_type,
  qualification,
  coefficient,
  collective_agreement_hourly_rate,
  hourly_price,
  vacation_time,
  health_mutual_cents,
  entry_date,
  calculated_seniority,
  forced_seniority,
  id,
  hasDecreased,
  configApplication,
  periodicity,
  coeffToMonthly,
  isLockedByUser,
  hasForcedSeniority,
}) {
  const hourlyPriceValue = useMemo(() => {
    if (hourly_price === null || hourly_price === undefined) return undefined;
    if (periodicity === "hourly") return hourly_price;
    return hourly_price * coeffToMonthly;
  }, [coeffToMonthly, hourly_price, periodicity]);

  const updateHourlyPriceValue = (values) => {
    if (periodicity === "hourly") {
      updateAgent(values);
      return;
    }
    updateAgent({
      ...values,
      hourly_price:
        typeof values.hourly_price !== "number"
          ? null
          : values.hourly_price / coeffToMonthly,
    });
  };

  return (
    <Container>
      <Wrapper>
        <ItemContainer>
          <StyledLabel label="Type de contrat" />
          <FormattedSelect
            value={contract_type}
            options={contractTypes.map((contract) => ({
              id: contract[1],
              name: contract[0],
            }))}
            updateItem={updateAgent}
            itemId={id}
            keyName="contract_type"
            disabled={
              hasDecreased ||
              configApplication.guarding.agents.disable_contrat ||
              !isLockedByUser
            }
          />
        </ItemContainer>
        <ItemContainer>
          <StyledLabel label="Qualification" />
          <FormattedSelect
            value={qualification}
            options={qualifications.map((qual) => ({
              id: qual,
              name: qual,
            }))}
            updateItem={updateAgent}
            itemId={id}
            keyName="qualification"
            disabled={
              hasDecreased ||
              configApplication.guarding.agents.disable_qualification ||
              !isLockedByUser
            }
          />
        </ItemContainer>
        <ItemContainer>
          <StyledLabel label="Coefficient" />
          <FormattedSelect
            value={coefficient}
            options={coefficients.map((coeff) => ({
              id: coeff,
              name: coeff,
            }))}
            updateItem={updateAgent}
            itemId={id}
            keyName="coefficient"
            disabled={
              hasDecreased ||
              configApplication.guarding.agents.disable_coefficient ||
              !isLockedByUser
            }
          />
        </ItemContainer>
        <ItemContainer>
          <PeriodicitySelect
            showSearch={false}
            bordered={false}
            value={periodicity}
            className="periodicity-select"
            options={[
              {
                id: "hourly",
                name: <PeriodicityLabel label="Tx. horaire" />,
              },
              {
                id: "monthly",
                name: <PeriodicityLabel label="Salaire brut mensuel" />,
              },
            ]}
            updateItem={updateAgent}
            itemId={id}
            keyName="periodicity"
            disabled={!isLockedByUser}
          />
        </ItemContainer>
        <ItemContainer>
          <StyledLabel label="CCN" />
          <CellRight>
            {formatNumberString({
              str:
                periodicity === "hourly"
                  ? collective_agreement_hourly_rate
                  : collective_agreement_hourly_rate * coeffToMonthly,
              nbDecimal: periodicity === "hourly" ? 4 : 2,
              space: true,
            })}{" "}
            €
          </CellRight>
        </ItemContainer>
        <ItemContainer>
          <StyledLabel label="Forcé" />
          <StyledInput
            value={hourlyPriceValue}
            keyName="hourly_price"
            updateItem={updateHourlyPriceValue}
            itemId={id}
            isNumber
            suffix="€"
            textAlign="right"
            disabled={
              hasDecreased ||
              configApplication.guarding.agents.disable_forced_hourly_price ||
              !isLockedByUser
            }
          />
        </ItemContainer>
      </Wrapper>
      <Wrapper>
        <ItemContainer>
          <StyledLabel label="Vacation" />
          <StyledInput
            value={vacation_time}
            keyName="vacation_time"
            updateItem={updateAgent}
            itemId={id}
            isNumber
            suffix="h"
            textAlign="right"
            disabled={
              hasDecreased ||
              configApplication.guarding.agents.disable_vacation_duration ||
              !isLockedByUser
            }
          />
        </ItemContainer>
        <ItemContainer>
          <StyledLabel label="Mutuelle" />
          <StyledInput
            value={centsToEuros(health_mutual_cents)}
            keyName="health_mutual_cents"
            updateItem={updateAgent}
            itemId={id}
            isNumber
            toCents
            suffix="€/mois"
            textAlign="right"
            disabled={
              hasDecreased ||
              configApplication.guarding.agents.disable_mutual_price ||
              !isLockedByUser
            }
          />
        </ItemContainer>
        <ItemContainer>
          <StyledLabel label="Ancienneté métier" />
          <EntryDate
            value={hasForcedSeniority ? undefined : entry_date}
            keyName="entry_date"
            updateItem={updateAgent}
            itemId={id}
            disabled={
              hasForcedSeniority ||
              hasDecreased ||
              configApplication.guarding.agents.disable_date_start_working ||
              !isLockedByUser
            }
          />
        </ItemContainer>
        <ItemContainer>
          <StyledLabel label="Tx. ancienneté" />
        </ItemContainer>
        <ItemContainer>
          <StyledLabel label="CCN" />
          <CellRight>
            {formatNumberString({
              str: calculated_seniority,
              nbDecimal: Infinity,
              space: true,
            }) || 0}{" "}
            %
          </CellRight>
        </ItemContainer>
        <ItemContainer>
          <StyledLabel label="Forcé" />
          <StyledInput
            value={forced_seniority}
            keyName="forced_seniority"
            updateItem={updateAgent}
            itemId={id}
            isNumber
            suffix="%"
            textAlign="right"
            disabled={
              hasDecreased ||
              configApplication.guarding.agents
                .disable_forced_percentage_seniority ||
              !isLockedByUser
            }
          />
        </ItemContainer>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  gap: 15px;
`;

const StyledLabel = styled(Label)`
  font-size: 12px;
`;

const Wrapper = styled.div`
  display: grid;
  column-gap: 5px;
  grid-template-columns:
    1fr minmax(100px, 1.5fr) minmax(100px, 1fr) minmax(80px, 1fr)
    1fr minmax(80px, 1fr);
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 10px !important;
  justify-content: space-between;
`;

const StyledInput = styled(FormattedInput)`
  height: 24px;
`;

const CellRight = styled.span`
  text-align: right;
  font-size: 10px;
`;

const PeriodicitySelect = styled(FormattedSelect)`
  .ant-select-selector {
    padding: 0 7px 0 0 !important;
    border: none !important;
    height: auto !important;
  }
  .ant-select-selection-item {
    line-height: 0 !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.blue07};
  }
`;

const PeriodicityLabel = styled(StyledLabel)`
  cursor: pointer;
  white-space: normal !important;
`;

export default AgentInformations;
