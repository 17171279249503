import React from "react";
import { array, bool, func, shape, string } from "prop-types";
import { DatePicker as AntdDatePicker, Form } from "antd";
import styled from "styled-components";
import Label from "./Label";
import ReactDatePicker from "./ReactDatePicker";

const { RangePicker } = AntdDatePicker;

function DatePicker({
  datePicker,
  handleUpdateOnChange,
  isShowing,
  ...formItemProps
}) {
  const { isRangePicker, ...datePickerProps } = datePicker;
  const handleOnBlur = (e) => {
    if (datePicker.onChange) datePicker.onChange(e);
    handleUpdateOnChange();
  };

  const disabled = datePicker.disabled || isShowing;

  return (
    <Form.Item
      {...formItemProps}
      label={<Label label={formItemProps.label} rules={formItemProps.rules} />}
    >
      {/* isRangePicker is deprecated */}
      {isRangePicker ? (
        <StyledRangePicker
          {...datePickerProps}
          onChange={handleOnBlur}
          disabled={disabled}
          placeholder={isShowing ? "" : datePicker.placeholder}
          format="DD/MM/YYYY"
        />
      ) : (
        <StyledDatePicker
          {...datePickerProps}
          onBlur={handleOnBlur}
          onSelect={handleOnBlur}
          disabled={disabled}
          placeholderText={isShowing ? "" : datePicker.placeholder}
        />
      )}
    </Form.Item>
  );
}

const StyledDatePicker = styled(ReactDatePicker)`
  width: 100%;
`;

const StyledRangePicker = styled(RangePicker)`
  width: 100%;
`;
DatePicker.propTypes = {
  datePicker: shape({
    placeholder: string,
    format: string,
    isRangePicker: bool,
  }).isRequired,
  name: string.isRequired,
  label: string,
  rules: array,
  handleUpdateOnChange: func.isRequired,
  isShowing: bool,
};

DatePicker.defaultProps = {
  label: "",
  rules: [],
  isShowing: false,
};

export default DatePicker;
