import React from "react";
import styled from "styled-components";
import { opportunityField } from "../../../constant";
import FormCol from "../../../Form/FormCol";
import Select from "../../../react-ui/Select";
import Label from "../../../Form/Label";

function OpportunityResponseType({
  opportunity,
  isGuarding,
  responseTypeOptions,
  responseTypeId,
  opportunityType,
  hasRenting,
  responseType,
  required,
  loadConfiguration,
}) {
  return (
    <>
      {!opportunity && !isGuarding() && (
        <>
          <FormCol
            labelOnTop
            label="Type de réponse"
            rules={[{ required }]}
            name={opportunityField.responseType}
          >
            <StyledSelect
              options={responseTypeOptions}
              placeholder="Sélectionnez un type"
            />
          </FormCol>
          {loadConfiguration()?.selectedConfiguration.configuration?.general
            ?.maintenance && (
            <FormCol
              labelOnTop
              label="Type d'opportunité"
              rules={[
                {
                  required:
                    responseTypeId !== "referencing" &&
                    responseTypeId !== "renting",
                },
              ]}
              name={opportunityField.opportunityType}
            >
              <StyledSelect
                options={opportunityType}
                placeholder="Sélectionnez un type"
                disabled={
                  responseTypeId === "referencing" ||
                  responseTypeId === "renting"
                }
              />
            </FormCol>
          )}
        </>
      )}
      {opportunity &&
        !isGuarding() &&
        (opportunity[opportunityField.opportunityType] ||
          opportunity[opportunityField.responseType]) && (
          <OppTypeContainer>
            <Label label="Type d'opportunité : " />
            {opportunity[opportunityField.opportunityType] &&
              opportunityType.find(
                (el) => el.id === opportunity[opportunityField.opportunityType]
              ).name}
            {opportunity[opportunityField.opportunityType] &&
              opportunity[opportunityField.responseType] &&
              " - "}
            {opportunity[opportunityField.responseType] &&
              responseType(
                hasRenting,
                loadConfiguration()?.configuration.general.framework_agreement
              ).find(
                (el) => el.id === opportunity[opportunityField.responseType]
              ).name}
            {opportunity[opportunityField.responseType] &&
              opportunity[opportunityField.responseType] === "quotation" &&
              ` - ${opportunity.framework_agreement.name}`}
          </OppTypeContainer>
        )}
    </>
  );
}

const StyledSelect = styled(Select)`
  width: 100%;
`;

const OppTypeContainer = styled.div`
  display: flex;
  width: 100%;
  padding-left: 8px;
`;
export default OpportunityResponseType;
