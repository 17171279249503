const excelArray = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const wordArray = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

const pdfArray = ["application/pdf"];

const zipArray = ["application/zip"];

const csvArray = ["text/csv"];

export const FILE_TYPE = {
  UNKNOWN: 0,
  IMAGE: 1,
  VIDEO: 2,
  AUDIO: 3,
  EXCEL: 4,
  WORD: 5,
  PDF: 6,
  ZIP: 7,
  CSV: 8,
};

export const getFileType = (contentType) => {
  if (!contentType) return FILE_TYPE.UNKNOWN;
  if (contentType.startsWith("image")) return FILE_TYPE.IMAGE;
  if (contentType.startsWith("video")) return FILE_TYPE.VIDEO;
  if (contentType.startsWith("audio")) return FILE_TYPE.AUDIO;
  if (excelArray.includes(contentType)) return FILE_TYPE.EXCEL;
  if (wordArray.includes(contentType)) return FILE_TYPE.WORD;
  if (pdfArray.includes(contentType)) return FILE_TYPE.PDF;
  if (zipArray.includes(contentType)) return FILE_TYPE.ZIP;
  if (csvArray.includes(contentType)) return FILE_TYPE.CSV;
  return FILE_TYPE.UNKNOWN;
};
