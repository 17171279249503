import { Form, TreeSelect } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { validateMessages } from "../../../constant";
import FormRow from "../../../Form/FormRow";
import Separator from "../../../react-ui/Separator";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import FormCol from "../../../Form/FormCol";
import Select from "../../../react-ui/Select";
import Input from "../../../react-ui/Input";
import { filterInTree } from "../../../../utils/search";
import TitleContainer from "../../../react-ui/TitleContainer";
import AddButton from "../../../react-ui/AddButton";
import Button from "../../../react-ui/Button";
import ReactDatePicker from "../../../Form/ReactDatePicker";
import FormattedInput from "../../../react-ui/FormattedInput";
import {
  centsToEuros,
  eurosToCents,
} from "../../../../utils/currencyConverter";
import {} from "../../../../utils/createFormData";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { generateFormData } from "../../../../utils/generateFormData";
import { stringToFloat } from "../../../../utils/stringToFloat";
import {
  coeffToMargin,
  marginToCoeff,
} from "../../../../utils/marginConverter";

const selector = (state) => ({
  formToken: state.formToken,
});

function ExternalOrderForm({
  status,
  selectedExternalOrder,
  clients,
  closeModal,
  entities,
  works,
  users,
  selectedClientId,
}) {
  const { formToken } = useStore(selector);
  const [useMargin, setUseMargin] = useState(true);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const selectedEntityId = Form.useWatch("entity_id", form);

  const { mutate: createExternalOrder, isLoading } = useMutation(
    (todo) => postData(formToken, `/external_order/create`, todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("ExternalOrders");
      },
    }
  );

  const { mutate: updateExternalOrder, isLoading: updateIsLoading } =
    useMutation((todo) => postData(formToken, `/external_order/update`, todo), {
      onSettled: () => {
        queryClient.invalidateQueries("ExternalOrders");
      },
    });

  useEffect(() => {
    form.resetFields();
    if (status === "update" && selectedExternalOrder) {
      const work = works.find((w) => w.id === selectedExternalOrder.work_id);
      const initialValues = {
        ...selectedExternalOrder,
        signature_date: new Date(selectedExternalOrder.signature_date),
        margin: marginToCoeff({
          marginRate: selectedExternalOrder.margin,
          isUsingMargin: work.use_margin,
          calculMethod: work.calcul_method,
        }),
        turnover: formatNumberString({
          str: centsToEuros(selectedExternalOrder.turnover),
        }),
      };
      setUseMargin(work.use_margin);
      form.setFieldsValue(initialValues);
    } else {
      const initialValues = {
        client_id: selectedClientId,
      };
      form.setFieldsValue(initialValues);
    }
  }, [form, selectedExternalOrder, status, selectedClientId, works]);

  const handleSubmit = (values) => {
    const formData = generateFormData({
      e: values,
      formName: "external_order",
      excludedKeys: ["signature_date", "turnover", "margin"],
    });
    formData.append(
      `external_order[signature_date]`,
      values.signature_date.toLocaleDateString("fr-FR")
    );
    formData.append(`external_order[turnover]`, eurosToCents(values.turnover));
    const work = works.find((w) => w.id === form.getFieldValue("work_id"));
    formData.append(
      `external_order[margin]`,
      coeffToMargin({
        isUsingMargin: work.use_margin,
        calculMethod: work.calcul_method,
        coeff: stringToFloat(values.margin),
      })
    );
    if (status === "update" && selectedExternalOrder) {
      formData.append(`id`, selectedExternalOrder.id);
      updateExternalOrder(formData);
    } else {
      createExternalOrder(formData);
    }
    closeModal();
  };

  const formattedClients = useMemo(() => {
    if (!clients) return [];
    const clientIsClientChild = (client) => {
      if (
        client.id === selectedClientId ||
        client.parent_id === selectedClientId
      )
        return true;
      const parent = clients.find((c) => c.id === client.parent_id);
      if (!parent) return false;
      return clientIsClientChild(parent);
    };
    return clients.map((el) => ({
      id: el.id,
      pId: el.parent_id,
      title: el.name,
      value: el.id,
      disabled: !clientIsClientChild(el),
    }));
  }, [clients, selectedClientId]);

  const formattedEntities = useMemo(() => {
    if (!entities) return [];
    return entities.map((el) => ({
      id: el.id,
      pId: el.parent_id,
      title: el.name,
      value: el.id,
    }));
  }, [entities]);

  const selectedEntityWorks = useMemo(() => {
    if (!selectedEntityId) {
      return [];
    }
    const selectedEntity = entities.find((el) => el.id === selectedEntityId);
    return works.filter((el) =>
      selectedEntity.works.map((w) => w.id).includes(el.id)
    );
  }, [entities, selectedEntityId, works]);

  const resetWorks = (id) => {
    const selectedEntity = entities.find((el) => el.id === id);
    if (
      !selectedEntity.works
        .map((w) => w.id)
        .includes(form.getFieldValue("work_id"))
    ) {
      form.setFieldValue("work_id", undefined);
    }
  };

  const formattedUsers = useMemo(() => {
    return users.map((user) => ({
      ...user,
      name: `${user.first_name} ${user.last_name}`,
    }));
  }, [users]);

  const onWorkChange = (work_id) => {
    const work = works.find((w) => w.id === work_id);
    const margin = marginToCoeff({
      marginRate: work.default_margin,
      isUsingMargin: work.use_margin,
      calculMethod: work.calcul_method,
    });
    setUseMargin(work.use_margin);
    form.setFieldValue("margin", margin);
  };

  return (
    <Form
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
    >
      <TitleContainer label="Créer une commande externe">
        <StyledButton
          label="Annuler"
          onClick={closeModal}
          fontSize="14px"
          btnType="cancel"
          type="button"
        />
        <AddButton
          label={status === "create" ? "Créer" : "Modifier"}
          type="submit"
          value="submit"
          fontSize="14px"
          loading={isLoading || updateIsLoading}
        />
      </TitleContainer>
      <Container>
        <StyledFormRow>
          <FormCol
            width="50%"
            label="Intitulé"
            name="name"
            labelOnTop
            rules={[{ required: true }]}
          >
            <Input />
          </FormCol>
          <FormCol width="50%" label="Référence" name="reference" labelOnTop>
            <Input />
          </FormCol>
        </StyledFormRow>
        <StyledFormRow>
          <FormCol
            labelOnTop
            label="Agence"
            rules={[{ required: true }]}
            name="entity_id"
            width="50%"
          >
            <TreeSelect
              treeDefaultExpandAll
              treeData={formattedEntities}
              treeDataSimpleMode
              filterTreeNode={filterInTree}
              showSearch
              onChange={resetWorks}
            />
          </FormCol>
          <FormCol
            labelOnTop
            label="Activité"
            rules={[{ required: true }]}
            name="work_id"
            width="50%"
          >
            <Select
              keyName="fullname"
              options={selectedEntityWorks}
              showSearch={false}
              onChange={onWorkChange}
            />
          </FormCol>
        </StyledFormRow>

        <Separator isHorizontal size={1} />

        <Row>
          <FormCol
            labelOnTop
            width="30%"
            label="Date de signature"
            name="signature_date"
            rules={[{ required: true }]}
          >
            <ReactDatePicker />
          </FormCol>
          <FormCol
            labelOnTop
            width="40%"
            label="Entreprise"
            name="client_id"
            rules={[{ required: true }]}
          >
            <TreeSelect
              treeDefaultExpandAll
              treeData={formattedClients}
              treeDataSimpleMode
              filterTreeNode={filterInTree}
              showSearch
            />
          </FormCol>
          <FormCol labelOnTop label="Utilisateur" name="user_id" width="30%">
            <Select options={formattedUsers} showSearch={false} allowClear />
          </FormCol>
        </Row>

        <StyledFormRow>
          <FormCol
            rules={[{ required: true }]}
            labelOnTop
            label="Prix de vente (HT)"
            name="turnover"
            width="50%"
          >
            <FormattedInput size="middle" isNumber suffix="€" />
          </FormCol>
          <FormCol
            rules={[{ required: true }]}
            labelOnTop
            label={useMargin ? "Marge" : "Coefficient"}
            name="margin"
            width="50%"
          >
            <FormattedInput
              size="middle"
              isNumber
              suffix={useMargin ? "%" : undefined}
            />
          </FormCol>
        </StyledFormRow>

        <Separator isHorizontal size={1} />

        <Row>
          <FormCol labelOnTop label="Description" name="description">
            <Input textArea />
          </FormCol>
        </Row>
      </Container>
    </Form>
  );
}

const Container = styled.div`
  padding: 0 15px 15px 15px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const Row = styled.div`
  display: flex;
`;

const StyledFormRow = styled(FormRow)`
  padding-top: 10px !important;
`;

export default ExternalOrderForm;
