import { Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStore } from "../../../store";
import SwitchComponent from "../SwitchComponent";
import { GridContainer, StyledCard } from "../styledComponent";
import { deepEqual } from "../FilesImport";

export default function Filters({ props, configuration, setConfiguration }) {
  const selector = (state) => ({
    setApiProvider: state.setApiProvider,
    apiProvider: state.apiProvider,
  });
  const { apiProvider } = useStore(selector);

  const [defaultApiProvider, setDefaultApiProvider] = useState({
    name: configuration?.ouvrage_option?.ouvrage_default
      ? "batiprix"
      : "smart4",
  });
  const [filterCompatibily, setFilterCompatibily] = useState({
    show: configuration?.general?.compatibility,
  });
  const [filterByDefault, setFilterByDefault] = useState({
    materials:
      configuration?.costing?.page_1?.filter_default_values?.includes(
        "materials"
      ),
    prestations:
      configuration?.costing?.page_1?.filter_default_values?.includes(
        "prestations"
      ),
    subcontractings:
      configuration?.costing?.page_1?.filter_default_values?.includes(
        "subcontractings"
      ),
    ouvrages:
      configuration?.costing?.page_1?.filter_default_values?.includes(
        "ouvrages"
      ),
    groups:
      configuration?.costing?.page_1?.filter_default_values?.includes("groups"),
  });

  const itemFilterDefault = [
    {
      initialValue: filterByDefault.materials,
      labelText: "Produits",
      name: "materials",
    },
    {
      initialValue: filterByDefault.prestations,
      name: "prestations",
      labelText: "Prestations",
    },
    {
      initialValue: filterByDefault.subcontractings,
      name: "subcontractings",
      labelText: "Sous-traitance",
    },
    {
      initialValue: configuration?.ouvrage && filterByDefault.ouvrages,
      isDisabled: configuration?.ouvrage === undefined,
      name: "ouvrages",
      labelText: "Ouvrages",
    },
    {
      initialValue: configuration?.group && filterByDefault.groups,
      name: "groups",
      isDisabled: configuration?.group === undefined,
      labelText: "Groupements",
    },
  ];

  const [showColumn, setShowColumn] = useState({
    reference: configuration?.costing.page_1?.table_col_reference_code,
    cost: configuration?.costing.page_1.table_col_price,
    subContracting: configuration?.costing.page_1.table_left_col_subcontracting,
  });

  const itemShowColumn = [
    {
      initialValue: showColumn.reference,
      labelText: "Référence",
      name: "reference",
    },
    {
      initialValue: showColumn.cost,
      name: "cost",
      labelText: "Coût",
    },
    {
      initialValue: showColumn.subContracting,
      name: "subContracting",
      labelText: "Sous-traitance",
    },
  ];

  useEffect(() => {
    const filter_default_values = Object.keys(filterByDefault).filter(
      (key) => filterByDefault[key]
    );

    const data_values = {
      ...configuration,
      costing: {
        ...configuration.costing,
        page_1: {
          ...configuration.costing.page_1,
          filter_default_values,
        },
      },
    };

    if (!deepEqual(data_values, configuration)) {
      setConfiguration(data_values);
    }
  }, [filterByDefault]);

  useEffect(() => {
    const show_column = Object.keys(showColumn).filter(
      (key) => showColumn[key]
    );

    const data_values = {
      ...configuration,
      costing: {
        ...configuration.costing,
        page_1: {
          ...configuration.costing.page_1,
          table_col_reference_code: show_column.includes("reference"),
          table_col_price: show_column.includes("cost"),
          table_left_col_subcontracting: show_column.includes("subContracting"),
        },
      },
    };

    if (!deepEqual(data_values, configuration)) {
      setConfiguration(data_values);
    }
  }, [showColumn]);

  useEffect(() => {
    const data_values = {
      ...configuration,
      general: {
        ...configuration.general,
        compatibility: filterCompatibily.show,
      },
    };

    if (!deepEqual(data_values, configuration)) {
      setConfiguration(data_values);
    }
  }, [filterCompatibily]);

  useEffect(() => {
    const data_values = {
      ...configuration,
      ouvrage_option: {
        ...configuration.ouvrage_option,
        ouvrage_default: defaultApiProvider.name === "batiprix",
      },
    };
    if (!deepEqual(data_values, configuration)) {
      setConfiguration(data_values);
    }
  }, [defaultApiProvider]);

  return (
    <Container
      size={14}
      direction="vertical"
      style={{
        width: "100%",
      }}
    >
      {props?.list_api_providers?.length > 1 ? (
        <Space
          size={14}
          direction="horizontal"
          style={{
            width: "100%",
          }}
        >
          {/* CARD SELECT API PROVIDER */}
          <StyledCard
            headStyle={{ backgroundColor: "#0B2239", color: "white" }}
            bodyStyle={{ backgroundColor: "#F5F6F8", height: "52px" }}
            size="small"
            title="Fournisseur API par défaut"
            style={{
              width: "100%",
            }}
          >
            <GridContainer direction="vertical" size={14}>
              <Select
                size="small"
                filled="true"
                style={{
                  width: "140px",
                  padding: "0 8px 0 8px",
                  radius: "2px",
                }}
                defaultValue={defaultApiProvider.name}
                placeholder="Select a model"
                onChange={(value) => {
                  setDefaultApiProvider({
                    name: props?.list_api_providers?.find(
                      (provider) => provider.id === value
                    )?.name,
                  });
                }}
              >
                {props?.list_api_providers
                  ?.filter(({ name }) => name !== "docusign")
                  .map(({ name, id }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
              </Select>
            </GridContainer>
          </StyledCard>

          {/* CARD FILTER COMPATIBILITY */}
          <FilterCompatibilyComponent
            filterCompatibily={filterCompatibily}
            setFilterCompatibily={setFilterCompatibily}
          />
        </Space>
      ) : (
        <FilterCompatibilyComponent
          filterCompatibily={filterCompatibily}
          setFilterCompatibily={setFilterCompatibily}
        />
      )}
      {/* CARD DEFAULT FILTERS */}
      <FilterDefaultComponent
        itemFilterDefault={itemFilterDefault}
        setFilterByDefault={setFilterByDefault}
      />
      {/* CARD SHOW COLUMN */}
      <ShowColumnComponent
        itemShowColumn={itemShowColumn}
        setShowColumn={setShowColumn}
      />
    </Container>
  );
}

function FilterCompatibilyComponent({
  filterCompatibily,
  setFilterCompatibily,
}) {
  const items = [
    {
      initialValue: filterCompatibily.show,
      labelText: "Afficher",
      name: "show",
    },
  ];

  const handleSaveNewValue = (value, name) => {
    setFilterCompatibily({ ...filterCompatibily, [name]: value });
  };
  return (
    <StyledCard
      headStyle={{ backgroundColor: "#0B2239", color: "white" }}
      bodyStyle={{ backgroundColor: "#F5F6F8" }}
      size="small"
      title="Filtres de compatibilité"
      style={{
        width: "100%",
      }}
    >
      <GridContainer
        direction="vertical"
        size={14}
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
        }}
      >
        {items.map((item) => (
          <div className="switch" key={item.name}>
            <SwitchComponent
              initialValue={item.initialValue}
              labelText={item.labelText}
              isDisabled={item.isDisabled}
              handleChange={handleSaveNewValue}
              name={item.name}
            />
          </div>
        ))}
      </GridContainer>
    </StyledCard>
  );
}

function FilterDefaultComponent({ itemFilterDefault, setFilterByDefault }) {
  return (
    <StyledCard
      headStyle={{ backgroundColor: "#0B2239", color: "white" }}
      bodyStyle={{ backgroundColor: "#F5F6F8" }}
      size="small"
      title="Filtres par défaut"
      style={{
        width: "100% !important",
      }}
    >
      <GridContainer
        direction="vertical"
        className="grid-container"
        size={14}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, auto)",
          gridAutoFlow: "row dense",
          // gridAutoFlow: "row dense",
        }}
      >
        {itemFilterDefault.map((item) => (
          <div className="switch" key={item.name}>
            <SwitchComponent
              initialValue={item.initialValue}
              labelText={item.labelText}
              isDisabled={item.isDisabled}
              handleChange={(value, name) => {
                setFilterByDefault((prev) => ({ ...prev, [name]: value }));
              }}
              name={item.name}
            />
          </div>
        ))}
      </GridContainer>
    </StyledCard>
  );
}

function ShowColumnComponent({ itemShowColumn, setShowColumn }) {
  return (
    <StyledCard
      headStyle={{ backgroundColor: "#0B2239", color: "white" }}
      bodyStyle={{ backgroundColor: "#F5F6F8" }}
      size="small"
      title="Afficher colonne"
      style={{
        width: "100%",
      }}
    >
      <GridContainer
        direction="vertical"
        className="grid-container"
        size={14}
        style={{
          display: "grid",
          gridTemplateColumns: "auto auto auto",
        }}
      >
        {itemShowColumn.map((item) => (
          <SwitchComponent
            key={item.name}
            initialValue={item.initialValue}
            labelText={item.labelText}
            isDisabled={item.isDisabled}
            handleChange={(value, name) => {
              setShowColumn((prev) => ({ ...prev, [name]: value }));
            }}
            name={item.name}
          />
        ))}
      </GridContainer>
    </StyledCard>
  );
}

const Container = styled(Space)`
  .ant-space-item {
    width: 100%;
  }
  .ant-form-item-label label {
    font-size: 12px;
  }
  @media (max-width: 1380px) {
    .grid-container {
      grid-template-columns: auto auto !important;
    }
  }

  @media (min-width: 1381px) {
    .ant-form-item-row {
      display: flex !important;
    }
  }
`;
