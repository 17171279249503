import React, { useState } from "react";
import Input from "../../../../../../react-ui/Input";
import { formatNumberString } from "../../../../../../../utils/formatNumberString";
import {
  coeffToMargin,
  marginToCoeff,
} from "../../../../../../../utils/marginConverter";
import { stringToFloat } from "../../../../../../utils";

function MarginRate({
  record,
  value,
  id_opportunity,
  isUsingMargin,
  calculMethod,
  update_function,
}) {
  const [margin, setMargin] = useState([]);
  return (
    <Input
      fontSize="10px"
      textAlign="right"
      size="small"
      isMarginRate
      id={record.id}
      value={formatNumberString({
        str:
          margin?.[record.id] != null
            ? margin?.[record.id]
            : marginToCoeff({
                marginRate: record.margin_rate,
                isUsingMargin,
                calculMethod,
              }),

        isCoeff: !isUsingMargin,
      })}
      onBlur={async (e) => {
        if (margin?.[record.id]) {
          await update_function[record.flag]({
            opportunity_id: id_opportunity,
            ids: record.id,
            id: record.id,
            ouvrage_parent_id: record.ouvrage_parent_id,
            margin_rate: coeffToMargin({
              calculMethod,
              isUsingMargin,
              coeff: stringToFloat(e.target.value),
            }),
          });
          setMargin(null);
        }
      }}
      onChange={(e) =>
        setMargin((v) => ({
          ...v,
          [e.target.id]: e.target.value,
        }))
      }
    />
  );
}

export default MarginRate;
