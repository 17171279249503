import React from "react";
import styled from "styled-components";

function NumberCell({ children }) {
  return <Container>{children}</Container>;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export default NumberCell;
