import React from "react";
import {instance, postData, getData} from "./instance";
import { useStore } from "../store";
import { useMutation, useQuery } from "react-query";
const selector = (state) => ({
    formToken: state.formToken
})



export const fecthCategoriesData = (formToken) => {
    const { data, isSuccess, refetch} = useQuery('getCategories', () => getData(formToken, "/category_profession"))
    return data
}

export const updateCategories = (formToken) => useMutation(updateTodo => postData(formToken, "/category_profession/update", updateTodo))

export const createCategories = (formToken) => useMutation(createTodo => postData(formToken, "/category_profession/create", createTodo))