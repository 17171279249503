import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import BaseIcon from "../../react-ui/Icons/BaseIcon";
import { useStore } from "../../store";
import { useConfigurationWork } from "../../../hooks/useConfigurationWork";

const antIcon = (isHeader) => <SpinIcon spin $isheader={isHeader} />;

const selector = (state) => ({
  configurations: state.configurations,
  vocabulary: state.vocabulary,
});

function AddFolderFile({
  isHeader,
  addFolder,
  openFileBrowser,
  isLoading,
  withFolder = true,
  withFile = true,
  withSearch = false,
  withAnnex = false,
  withOpportunityFolders = false,
  onClickSearchIcon,
  onAnnexAdd,
  onFolderOpportunityAdd,
  workName = null,
  fromSA,
}) {
  const { vocabulary } = useStore(selector);
  const { configuration } = useConfigurationWork(workName);

  return (
    <AddContainer $isheader={isHeader}>
      {withFolder && (
        <AddIcon
          className="fa-duotone fa-folder-plus"
          onClick={addFolder}
          title="Ajouter un nouveau dossier"
        />
      )}
      {withFile &&
        (isLoading ? (
          <Spin indicator={antIcon(isHeader)} />
        ) : (
          <AddIcon
            className="fa-duotone fa-file-plus"
            onClick={openFileBrowser}
          />
        ))}
      {withOpportunityFolders && (
        <AddIcon
          className="fa-duotone fa-folder-tree"
          onClick={onFolderOpportunityAdd}
          title="Ajouter les dossiers d'opportunité"
        />
      )}
      {withSearch && (
        <>
          <AddIcon
            className="fa-duotone fa-folder-magnifying-glass"
            onClick={(event) =>
              onClickSearchIcon({ event, from: "doc-folders" })
            }
            title="Rechercher et ajouter des dossiers admins/documents globaux"
          />
          <AddIcon
            className="fa-duotone fa-file-circle-exclamation"
            onClick={(event) =>
              onClickSearchIcon({ event, from: "file-rules" })
            }
            title="Rechercher et ajouter des fichiers règles"
          />
        </>
      )}
      {withAnnex && configuration && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {workName === "guarding" || (fromSA && !isHeader && !workName) ? (
            <>
              <AddIcon />
              <AddIcon />
            </>
          ) : (
            <>
              <AddIcon
                className={configuration?.general.product_icon}
                onClick={(e) => onAnnexAdd(e, "product_files")}
                title={`Ajouter les fiches ${
                  vocabulary.products ? ` des ${vocabulary.products}` : ""
                }`}
              />
              <AddIcon
                className={configuration?.general.prestation_icon}
                onClick={(e) => onAnnexAdd(e, "prestation_files")}
                title={`Ajouter les fiches ${
                  vocabulary.prestations ? ` des ${vocabulary.prestations}` : ""
                }`}
              />
            </>
          )}
        </>
      )}
    </AddContainer>
  );
}

const AddContainer = styled.div`
  display: flex;
  padding: 0 5px;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${({ theme, $isheader }) =>
    $isheader ? "white" : theme.colors.blue11};
`;

const AddIcon = styled(BaseIcon)`
  margin-left: auto;
  margin-right: auto;
  width: 14px;
  &:last-of-type {
    margin-right: unset;
  }
  &:first-of-type {
    margin-left: unset;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.blue05};
  }
`;

const SpinIcon = styled(LoadingOutlined)`
  font-size: 12px !important;
  color: ${({ theme, $isheader }) =>
    $isheader ? "white" : theme.colors.blue11};
`;

export default AddFolderFile;
