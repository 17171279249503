import { select2 } from "select2";

const select = () => {
  $(document).ready(function() {
    $('.select2').select2();
  })
}

export { select };

//TABULATOR
import Tabulator from 'tabulator-tables';
$(document).ready(() => {
const tableContractors = document.getElementById('contractors');
if(!tableContractors){
  return
}
if (tableContractors) {
  const contractorsJson = tableContractors.getAttribute('data-info');
  const table = new Tabulator("#contractors", {
    selectable: 1,
    resizableColumns:true,
    layout : "fitColumns",
    rowClick:function(e, row){
      // use the url generate by the route contractors#infos
      fetch("/companies/1/fetch_contractor_infos/"+row._row.data.id, {
          headers: {
            "Accept": "text/javascript",
            "X-Requested-With": "XMLHttpRequest"
          }
        }).then(function(response) { return response.text() }).then(function(body) {
          eval(body)
      }.bind(this));
    },
    
    columns:[
      {title:"Nom commercial ", field:"trade_name", sorter:"string"},
      {title:"Dénomination sociale", field:"corporate_name", sorter:"string"},
    ],
  });


  if (table) {
    table.setData(contractorsJson);
    window.addEventListener('resize', function(){
      //trigger full rerender including all data and rows
      table.redraw(true);
    });
  }
}


//the first row is select when we open the page
const contractorsTableBody = document.querySelector('#contractors .tabulator-table')

if (contractorsTableBody) {
  if (contractorsTableBody.firstChild !== null) {
    const addSelected = () => {
      contractorsTableBody.firstChild.classList.add("selected-row");
    }
    const removeSelected = () => {
      contractorsTableBody.firstChild.classList.remove("selected-row");
    }
    addSelected()
    contractorsTableBody.addEventListener("click", removeSelected)
  }
}

$(document).ready(function() {
  const changeTextMaxSelectForContractor = (e) => {
    const text = "Supprimez l'entreprise avant de le modifier";
      return text;
  }

  // allow the search box in the new/edit contractor activity field
  const clientContractor = $(".contractor_client_id");
  if (clientContractor) {
    $(".dropdown-client-id").change().select2({
        disableSearch: false,
        maximumSelectionLength: 1,
        language: {maximumSelected: changeTextMaxSelectForContractor}
    })
  }
});

$(document).on("click", ".clickable-row", function() {
  var link  = $(this).data("link")
  window.location = link
});
})
