import React from "react";
import styled from "styled-components";
import { opportunityField } from "../../../constant";
import FormCol from "../../../Form/FormCol";
import CreateLink from "../../../react-ui/CreateLink";
import ClientsSelectWrapper from "../ClientsSelectWrapper";

function CompanyDecisionMaking({
  setClientModalOpenFrom,
  disabled,
  show_link,
  clientModalOpenFrom,
  companyId,
  clientActivities,
  form,
  configApplication,
  works,
  formatClients,
  setClientsInMemory,
  clientsInMemory,
  vocabulary,
  configuration,
}) {
  return (
    <FormCol
      labelOnTop
      label={vocabulary.opportunity.decision_customer}
      customContent={
        <ClientCustomContent>
          {show_link && (
            <StyledCreateLink
              label={`Créer ${vocabulary.clients.a_client}`}
              onClick={() =>
                setClientModalOpenFrom(opportunityField.decisionMakerCustomer)
              }
            />
          )}
        </ClientCustomContent>
      }
      name={opportunityField.decisionMakerCustomer}
    >
      <ClientsSelectWrapper
        setClientsInMemory={setClientsInMemory}
        clientsInMemory={clientsInMemory}
        clientModalOpenFrom={clientModalOpenFrom}
        setClientModalOpenFrom={setClientModalOpenFrom}
        companyId={companyId}
        clientActivities={clientActivities}
        form={form}
        configApplication={configApplication}
        works={works}
        formatClients={formatClients}
        isShowing={disabled}
        selectorId={opportunityField.decisionMakerCustomer}
        vocabulary={vocabulary}
        configuration={configuration}
      />
    </FormCol>
  );
}

const StyledCreateLink = styled(CreateLink)`
  margin-left: auto;
  margin-top: 10px;
`;

const ClientCustomContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default CompanyDecisionMaking;
