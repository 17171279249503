import { Upload } from "antd";
import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import FormCol from "../../../Form/FormCol";

const uploadButton = (
  <div>
    <PlusOutlined />
    <div
      style={{
        marginTop: 8,
      }}
    >
      Télécharger image
    </div>
  </div>
);

function Avatar({ isShowing }) {
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const beforeUpload = () => {
    return false;
  };

  return (
    <CenteredCol
      label=""
      name="avatar"
      valuePropName="fileList"
      getValueFromEvent={normFile}
    >
      <Upload
        beforeUpload={beforeUpload}
        disabled={isShowing}
        listType="picture-card"
        maxCount={1}
        accept="image/*"
      >
        {uploadButton}
      </Upload>
    </CenteredCol>
  );
}

const CenteredCol = styled(FormCol)`
  display: flex;
  align-items: center;
  .ant-upload-list-picture-card {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-upload-list-item-container {
    background-color: #f0f1f3;
    border-radius: 8px;
    width: 90px !important;
    height: 90px !important;
  }
  .ant-upload-select {
    width: 90px !important;
    height: 90px !important;
  }

  .ant-upload-list-item {
    padding: 0 !important;
  }
  .ant-upload-list-item-image {
    border-radius: 8px;
  }
  .ant-upload-list-item::before {
    height: 100% !important;
    width: 100% !important;
    border-radius: 8px;
  }
`;

export default Avatar;
