import axios from "axios";
import { api } from "../react-navigation/api";

export const getData = async (formToken, path, options = {}) => {
  const { data } = await instance(formToken).get(path, options);
  return data;
};

export const postData = async (formToken, path, postData, options = {}) => {
  const { data } = await instance(formToken).post(path, postData, options);
  return data;
};

export const putData = async (formToken, path, postData) => {
  const { data } = await instance(formToken).put(path, postData);
  return data;
};

export const patchData = async (formToken, path, postData) => {
  const { data } = await instance(formToken).patch(path, postData);
  return data;
};

export const deleteData = async (formToken, path, deleteData) => {
  const { data } = await instance(formToken).delete(path, deleteData);
  return data;
};

export const instance = (formToken) => {
  api.defaults.headers.common["X-CSRF-Token"] = formToken;
  return api;
};

// instance.interceptors.request.use(
//     (config) => {
//         authToken
//         // debugger
//         // Do something before request is sent
//         config.headers = `'Content-Type': 'application/json', "X-CSRF-Token":${formToken}`;
//         return config;
//     },
//     function (error) {
//         // Do something with request error
//         return Promise.reject(error);
//     }
// );
