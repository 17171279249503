import React, { useEffect, useMemo, useState } from "react";
import { Checkbox } from "antd";
import { useStore } from "../../store";
import useDeleteTodo from "../../hookQuery/useDeleteTodo";
import { ShowDeleteConfirm } from "../confirmModal";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import ActionsContainer from "../../react-ui/ActionsContainer";
import Delete from "../../react-ui/Icons/Delete";
import { stringSorter } from "../../../utils/stringSorter";
import { numberSorter } from "../../../utils/numberSorter";

const selector = (state) => ({
  selectedDeplacement: state.selectedDeplacement,
  editDeplacement: state.editDeplacement,
  formToken: state.formToken,
});

function Actions({ value, handleDeleteMovement, isDeletable }) {
  return isDeletable ? (
    <ActionsContainer>
      <Delete
        onClick={() =>
          ShowDeleteConfirm(value.name, value.id, handleDeleteMovement)
        }
      />
    </ActionsContainer>
  ) : null;
}

function MovementArray({
  setMovementDetailsStatus,
  movements,
  entityWorkId,
  from,
  handleFAAndOpp,
  faIsValidate,
  movementDetailsStatus,
}) {
  const { editDeplacement, formToken, selectedDeplacement } =
    useStore(selector);
  const { mutate: removeMovements } = useDeleteTodo(
    formToken,
    "/movement_profession/delete",
    "Movements"
  );

  const pageEntityWork = parseInt(entityWorkId, 10);

  const [tableRowsData, setTableRowData] = useState([]);

  const isDeletable = (id) => from === "admin" && id === pageEntityWork;

  useEffect(() => {
    const dataResult = [];
    let child;

    movements?.forEach((value) => {
      child = {
        ...value,
        key: value.id,
        id: value.id,
        name: value.movement_included
          ? value.movement_included.name
          : value.name,
        is_in_framework_agreement:
          value.is_in_framework_agreement || value.movement_included,
        type: value.type_transport,
        actions: (
          <Actions
            handleDeleteMovement={handleDeleteMovement}
            value={value}
            isDeletable={isDeletable(value.entity_work_id)}
          />
        ),
      };
      dataResult.push(child);
    });
    setTableRowData(dataResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [movements]);

  const openUpdateMovementProfession = (value) => {
    editDeplacement(value);
    setMovementDetailsStatus("update");
  };

  const handleDeleteMovement = (idMovement) => {
    setMovementDetailsStatus("empty");
    editDeplacement();
    removeMovements(
      JSON.stringify({ id: idMovement, entity_work_id: pageEntityWork })
    );
  };

  const getColumnsMovements = useMemo(() => {
    const nameCol = {
      title: "Intitulé",
      dataIndex: "name",
      sorter: (a, b) => stringSorter(a.name, b.name),
    };

    const typeCol = {
      title: "Type",
      dataIndex: "type",
      sorter: (a, b) => stringSorter(a.type, b.type),
      width: "100px",
    };

    const frameworkAgreementCol = {
      title: "Accord cadre",
      dataIndex: "is_in_framework_agreement",
      width: "60px",
      align: "center",
      sorter: (a, b) =>
        numberSorter(a.is_in_framework_agreement, b.is_in_framework_agreement),
      render: (value, record) => (
        <Checkbox
          checked={value}
          onClick={() => handleFAAndOpp(value, record)}
          disabled={from === "frameworkAgreement" && !faIsValidate}
        />
      ),
    };

    const actionsCol = {
      title: "",
      dataIndex: "actions",
      width: "20px",
    };

    if (from !== "admin") return [nameCol, typeCol, frameworkAgreementCol];
    return [nameCol, typeCol, actionsCol];
  }, [from, handleFAAndOpp, faIsValidate]);

  return (
    <TableContainer
      bordered={false}
      scrollY
      columns={getColumnsMovements}
      dataSource={tableRowsData}
      pagination={false}
      isRowSelectable
      rowSelectableId={
        movementDetailsStatus === "update" ? selectedDeplacement?.id : undefined
      }
      onRow={(record) => {
        return {
          onClick: () => {
            openUpdateMovementProfession(
              movements.find((el) => el.id === record.id)
            );
          },
        };
      }}
    />
  );
}

export default MovementArray;
