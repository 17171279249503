import React from "react";
import i18next from "i18next";
import { useConfigurationWork } from "../../hooks/useConfigurationWork";

function WorkIcon({ workName }) {
  const { configuration, work } = useConfigurationWork(workName);
  if (!configuration) return null;

  return (
    <i
      className={`${configuration.general.work_icon} mr-1 icon-search`}
      title={i18next.t(`work.${work}`)}
    />
  );
}

export default WorkIcon;
