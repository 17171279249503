import { Form } from "antd";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { validateMessages } from "../../../constant";
import {
  costPriceRule,
  estimateQuantityRule,
  marginRule,
} from "../../../constants/rules";
import FormCol from "../../../Form/FormCol";
import FormRow from "../../../Form/FormRow";
import Label from "../../../Form/Label";
import FormattedInput from "../../../react-ui/FormattedInput";
import Select from "../../../react-ui/Select";

const getRulesOnArray = (works) => {
  const arr = [];

  if (works.length > 1 || !works.find((w) => w.name === "guarding"))
    arr.push(estimateQuantityRule);
  return arr.concat([costPriceRule, marginRule]);
};

const opportunityType = [
  { name: "Travaux neufs", id: "new_work" },
  { name: "Maintenance", id: "maintenance" },
];

const responseType = [
  { name: "Consultation", id: "consultation" },
  { name: "Devis accord-cadre", id: "quotation" },
];

function RuleForm({
  works,
  handleSubmit,
  initialValues,
  ruleStatus,
  form,
  siteTypologies,
  entities,
}) {
  const ruleOn = Form.useWatch("rule_on", form);
  const workId = Form.useWatch("work_id", form);
  const rulesOnArray = getRulesOnArray(works);
  const showStaticInformation = ruleStatus !== "create" && !!initialValues;

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    if (ruleOn === "estimate_quantity") {
      const curWorkId = form.getFieldValue("work_id");
      if (
        !curWorkId ||
        works.find((w) => curWorkId === w.id)?.name === "guarding"
      )
        form.setFieldsValue({
          work_id: undefined,
        });
    }
  }, [ruleOn, form, works]);

  const updateOnChange = () => {
    if (ruleStatus === "update") {
      form.submit();
    }
  };

  const relevantWorks = useMemo(() => {
    if (ruleOn === "estimate_quantity")
      return works.filter((w) => w.name !== "guarding");
    return works;
  }, [works, ruleOn]);

  const getValueSuffix = (rOn) => rulesOnArray.find((r) => r.id === rOn).suffix;

  const getRuleOnName = (rOn) =>
    rulesOnArray.find((roa) => roa.id === rOn).name;

  const showTypology = () => {
    const ron = initialValues?.rule_on || ruleOn;
    return ron === "final_price" || ron === "margin";
  };

  const showTypes = () => {
    const curWorkId = initialValues?.work_id || workId;
    if (!curWorkId) return false;
    const work = works.find((w) => w.id === curWorkId);
    return work.name !== "guarding";
  };

  return (
    <Form
      id="rule-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
      <FormRow>
        {ruleStatus === "create" && (
          <>
            <FormCol
              label="Règle sur"
              width="50%"
              name="rule_on"
              rules={[{ required: true }]}
            >
              <Select
                showSearch={false}
                options={rulesOnArray}
                keyName="name"
                onChange={updateOnChange}
              />
            </FormCol>
            <FormCol
              label="Métier"
              name="work_id"
              width="50%"
              rules={[{ required: true }]}
            >
              <Select
                showSearch={false}
                options={relevantWorks}
                keyName="fullname"
                onChange={updateOnChange}
              />
            </FormCol>
          </>
        )}
        {showStaticInformation && (
          <StaticTextContainer>
            <StaticTextWrapper>
              <StaticTextLabel label="Règle sur : " />
              {getRuleOnName(initialValues.rule_on)}
            </StaticTextWrapper>
            <StaticTextWrapper $padLeft>
              <StaticTextLabel label="Métier : " />
              {
                relevantWorks?.find((w) => initialValues.work_id === w.id)
                  ?.fullname
              }
            </StaticTextWrapper>
          </StaticTextContainer>
        )}
      </FormRow>
      {showTypes() && (
        <FormRow>
          <FormCol label="Type de réponse" name="response_type" width="50%">
            <Select
              showSearch={false}
              options={responseType}
              onChange={updateOnChange}
              allowClear
            />
          </FormCol>
          <FormCol
            label="Type d'opportunité"
            name="opportunity_type"
            width="50%"
          >
            <Select
              showSearch={false}
              options={opportunityType}
              onChange={updateOnChange}
              allowClear
            />
          </FormCol>
        </FormRow>
      )}
      {((ruleOn && ruleOn !== "estimate_quantity") ||
        (showStaticInformation &&
          initialValues.rule_on &&
          initialValues.rule_on !== "estimate_quantity")) && (
        <FormRow>
          <FormCol
            label={getRuleOnName(
              showStaticInformation ? initialValues.rule_on : ruleOn
            )}
            name="value_to_compare"
            width="50%"
            rules={[{ required: true }]}
          >
            <FormattedInput
              onBlur={updateOnChange}
              size="middle"
              isNumber
              suffix={getValueSuffix(
                showStaticInformation ? initialValues.rule_on : ruleOn
              )}
            />
          </FormCol>
        </FormRow>
      )}
      {showTypology() && (
        <>
          <FormRow>
            <FormCol label="Typologies" name="site_typology_ids">
              <Select
                options={siteTypologies}
                keyName="typology"
                onChange={updateOnChange}
                mode="multiple"
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol label="Agences concernées" name="entity_ids">
              <Select
                options={entities}
                onChange={updateOnChange}
                mode="multiple"
              />
            </FormCol>
          </FormRow>
        </>
      )}
    </Form>
  );
}

const StaticTextContainer = styled.div`
  align-self: center;
  display: flex;
  width: 100%;
`;

const StaticTextWrapper = styled.div`
  width: 50%;
  padding-left: ${({ $padLeft }) => ($padLeft ? "8px" : "")};
`;

const StaticTextLabel = styled(Label)`
  margin-right: 8px;
`;

export default RuleForm;
