import { Tag as AntdTag } from "antd";
import { getLuminance } from "polished";
import React from "react";

function Tag({ color, label, className, bordered = true, ...tagProps }) {
  const luminance = getLuminance(color);
  const textColor = luminance > 0.4 ? "#000" : "#fff";
  return (
    <AntdTag
      color={color}
      style={{
        color: textColor,
      }}
      bordered={bordered}
      className={className}
      {...tagProps}
    >
      {label}
    </AntdTag>
  );
}

export default Tag;
