/* eslint-disable no-param-reassign */
import { getData } from "../../../../../../request/instance";
import { fetchMaterialsUrl } from "../../../../../../../utils/fetchMaterialsUrl";

export const MaterialFetching = ({
  formToken,
  showFaItems,
  faId,
  id,
  materialsNextPage,
  wordEntered,
  categoryFiltered,
  compatibleMaterials,
  sortBy,
  from,
}) => {
  return async () => {
    let come_from = "";
    let id_from = "";
    if (from === "admin") {
      come_from = "admin";
      id_from = id;
    } else {
      come_from = showFaItems ? "quotation" : "consultation";
      id_from = showFaItems ? faId : id;
    }

    const data = await getData(
      formToken,
      fetchMaterialsUrl({
        from: come_from,
        id: id_from,
        nextPage: materialsNextPage.current,
        search: wordEntered,
        categoryFiltered,
        compatibleMaterials,
        ...(from !== "admin" && { oppId: id }),
        sortBy,
      })
    );
    return data;
  };
};

export const MaterialFetchingSucess = ({
  didFirstInfiniteQuery,
  setMaterials,
  showFaItems,
  materials,
  materialsNextPage,
  ouvrageSmart4NextPage,
  from,
}) => {
  return {
    refetchOnWindowFocus: false, // Disable refetch on window focus to avoid unwanted refetches
    refetchInterval: false, // Disable refetch interval to avoid unwanted refetches
    refetchIntervalInBackground: false, // Disable refetch interval in background to avoid unwanted refetches
    retry: false, // Disable retry on error to avoid unwanted refetches
    enabled: ouvrageSmart4NextPage.current === null && from !== "configuration",
    onSuccess: (payload) => {
      if (!didFirstInfiniteQuery.current) didFirstInfiniteQuery.current = true;
      const lastPage = payload.pages[payload.pages.length - 1];

      // Reset materials when back to first page of materials
      if (lastPage?.current_page === 1) {
        setMaterials(
          showFaItems ? lastPage.material_fas : lastPage.material_professions
        );
      } else {
        setMaterials([
          ...materials,
          ...(showFaItems
            ? lastPage.material_fas
            : lastPage.material_professions),
        ]);
      }
      materialsNextPage.current = lastPage.next_page;
    },
  };
};
