import { currency_euros_to_cents } from "../components/utils";
import { eurosToCents } from "./currencyConverter";
import { coeffToMargin } from "./marginConverter";
import { quantityTimeConverter } from "./quantityTimeConverter";

export const handlePictureKey = ({
  formData,
  picture,
  keyName,
  pictureName = "picture",
}) => {
  if (picture.length < 1) formData.append(`destroy_${pictureName}`, true);
  else if (picture[0]?.originFileObj)
    formData.append(`${keyName}[${pictureName}]`, picture[0].originFileObj);
};

export const handleDriveLinkKey = ({ formData, drive_link, keyName }) => {
  if (drive_link.length < 1) formData.append("destroy_drive_link", true);
  else if (drive_link[0]?.originFileObj)
    formData.append(`${keyName}[drive_link]`, drive_link[0].originFileObj);
};

export const handleDateKey = ({ formData, date_validity, keyName }) => {
  if (!date_validity) formData.append("destroy_date_validity", true);
  else formData.append(`${keyName}[date_validity]`, date_validity);
};

export const handleMarginKey = ({
  formData,
  margin_rate,
  keyName,
  isUsingMargin,
  calculMethod,
}) => {
  if (!margin_rate) formData.append(`${keyName}[margin_rate]`, "");
  else
    formData.append(
      `${keyName}[margin_rate]`,
      coeffToMargin({
        isUsingMargin,
        calculMethod,
        coeff: parseFloat(margin_rate.replace(/,/g, ".")),
      })
    );
};

export const createMaterialProfessionForm = (
  e,
  isUsingMargin,
  calculMethod
) => {
  const formatableKeys = [
    "picture",
    "cost_price_cents",
    "margin_rate",
    "category_profession_id",
    "distributor_id",
    "drive_link",
  ];
  const formData = new FormData();
  Object.keys(e).forEach(
    (key) =>
      !formatableKeys.includes(key) &&
      formData.append(
        `material_profession[${key}]`,
        e[key] !== undefined && e[key] !== null ? e[key] : ""
      )
  );
  // Last element of array correspond to selected category + check if not "import csv" category with type number check
  if (
    e.category_profession_id &&
    e.category_profession_id.length > 0 &&
    typeof e.category_profession_id[e.category_profession_id.length - 1] ==
      "number"
  )
    formData.append(
      "material_profession[category_profession_id]",
      e.category_profession_id[e.category_profession_id.length - 1]
    );
  if (e.cost_price_cents)
    formData.append(
      "material_profession[cost_price_cents]",
      currency_euros_to_cents(e.cost_price_cents)
    );
  handleMarginKey({
    formData,
    margin_rate: e.margin_rate,
    keyName: "material_profession",
    isUsingMargin,
    calculMethod,
  });
  if (e.picture) {
    handlePictureKey({
      formData,
      picture: e.picture,
      keyName: "material_profession",
    });
  }
  if (e.drive_link) {
    handleDriveLinkKey({
      formData,
      drive_link: e.drive_link,
      keyName: "material_profession",
    });
  }
  handleDateKey({
    formData,
    date_validity: e.date_validity,
    keyName: "material_profession",
  });
  formData.append(
    "distributor_id",
    e.distributor_id !== undefined && e.distributor_id !== null
      ? e.distributor_id
      : ""
  );
  return formData;
};

export const createOuvrageProfessionForm = (e, isUsingMargin, calculMethod) => {
  const formatableKeys = [
    "picture",
    "cost_price_cents",
    "margin_rate",
    "category_profession_id",
  ];
  const formData = new FormData();
  Object.keys(e).forEach(
    (key) =>
      !formatableKeys.includes(key) &&
      formData.append(
        `ouvrage_profession[${key}]`,
        e[key] !== undefined && e[key] !== null ? e[key] : ""
      )
  );
  // Last element of array correspond to selected category + check if not "import csv" category with type number check
  if (
    e.category_profession_id &&
    e.category_profession_id.length > 0 &&
    typeof e.category_profession_id[e.category_profession_id.length - 1] ==
      "number"
  )
    formData.append(
      "ouvrage_profession[category_profession_id]",
      e.category_profession_id[e.category_profession_id.length - 1]
    );
  if (e.cost_price_cents)
    formData.append(
      "ouvrage_profession[cost_price_cents]",
      currency_euros_to_cents(e.cost_price_cents)
    );
  handleMarginKey({
    formData,
    margin_rate: e.margin_rate,
    keyName: "ouvrage_profession",
    isUsingMargin,
    calculMethod,
  });
  if (e.picture) {
    handlePictureKey({
      formData,
      picture: e.picture,
      keyName: "ouvrage_profession",
    });
  }
  handleDateKey({
    formData,
    date_validity: e.date_validity,
    keyName: "ouvrage_profession",
  });
  formData.append(
    "distributor_id",
    e.distributor_id !== undefined && e.distributor_id !== null
      ? e.distributor_id
      : ""
  );
  return formData;
};

export const createPrestationProfessionForm = (
  e,
  profil,
  isUsingMargin,
  calculMethod,
  isUsingMinutes,
  currentPrestation
) => {
  const formatableKeys = [
    "picture",
    "margin_rate",
    "hourly_rate_profil",
    "cost_price_cents",
    "drive_link",
  ];
  const formData = new FormData();
  if (profil) {
    formatableKeys.push("subcontracting");
    formData.append(
      "prestation_profession[subcontracting]",
      profil.subcontracting ? profil.subcontracting : false
    );
  }
  Object.keys(e).forEach(
    (key) =>
      !formatableKeys.includes(key) &&
      formData.append(
        `prestation_profession[${key}]`,
        e[key] !== undefined && e[key] !== null ? e[key] : ""
      )
  );
  if (e.minutes) {
    formData.append("prestation_profession[minutes]", e.minutes);
  }
  if (e.cost_price_cents)
    formData.append(
      "prestation_profession[cost_price_cents]",
      eurosToCents(e.cost_price_cents)
    );
  // Last element of array correspond to selected category + check if not "import csv" category with type number check
  if (
    e.category_profession_id &&
    e.category_profession_id.length > 0 &&
    typeof e.category_profession_id[e.category_profession_id.length - 1] ==
      "number"
  )
    formData.append(
      "prestation_profession[category_profession_id]",
      e.category_profession_id[e.category_profession_id.length - 1]
    );
  handleMarginKey({
    formData,
    margin_rate: e.margin_rate,
    keyName: "prestation_profession",
    isUsingMargin,
    calculMethod,
  });
  if (e.quantity) {
    if (
      currentPrestation !== null &&
      e.minutes !== currentPrestation?.minutes
    ) {
      formData.delete("prestation_profession[quantity]");
      formData.append(
        "prestation_profession[quantity]",
        currentPrestation?.quantity
      );
    } else {
      formData.delete("prestation_profession[quantity]");
      formData.append(
        "prestation_profession[quantity]",
        quantityTimeConverter({
          quantity: parseFloat(e.quantity.replace(/,/g, ".")),
          isUsingMinutes: e.minutes,
          toMinutes: !e.minutes,
        })
      );
    }
  }
  if (e.speed_quantity) {
    if (
      currentPrestation !== null &&
      e.minutes !== currentPrestation?.minutes
    ) {
      formData.delete("prestation_profession[speed_quantity]");
      formData.append(
        "prestation_profession[speed_quantity]",
        currentPrestation?.speed_quantity
      );
    } else {
      formData.delete("prestation_profession[speed_quantity]");
      formData.append(
        "prestation_profession[speed_quantity]",
        quantityTimeConverter({
          quantity: parseFloat(e.speed_quantity.replace(/,/g, ".")),
          isUsingMinutes: e.minutes,
          toMinutes: e.minutes,
        })
      );
    }
  }
  if (e.picture)
    handlePictureKey({
      formData,
      picture: e.picture,
      keyName: "prestation_profession",
    });
  if (e.drive_link) {
    handleDriveLinkKey({
      formData,
      drive_link: e.drive_link,
      keyName: "prestation_profession",
    });
  }
  return formData;
};

// See https://stackoverflow.com/questions/41431322/how-to-convert-formdata-html5-object-to-json to improve this function
export const formDataToJson = (formData, keyName, excludedKeys = []) => {
  const object = {};
  formData.forEach((value, key) => {
    if (excludedKeys.includes(key)) return;
    let newKey = key;
    if (keyName) newKey = key.replace(`${keyName}[`, "").replace(`]`, "");
    object[newKey] = value;
  });
  return object;
};

export const createAssociations = ({
  modelName,
  formData,
  prestationsAssociatesLocal = [],
  materialsAssociatesLocal = [],
  familiesAssociatesLocal = [],
}) => {
  prestationsAssociatesLocal
    .map((el) => ({
      prestation_parent_id: el.prestation_parent_id,
      speed: el.speed,
      speed_quantity: el.speed_quantity,
      quantity: el.quantity,
    }))
    .forEach((prestationAssociate, idx) =>
      Object.keys(prestationAssociate).forEach((key) =>
        formData.append(
          `${modelName}[prestations_associates][${idx}][${key}]`,
          prestationAssociate[key] !== undefined &&
            prestationAssociate[key] !== null
            ? prestationAssociate[key]
            : ""
        )
      )
    );
  familiesAssociatesLocal
    .map((el) => ({
      family_profession_id: el.family_profession_id,
    }))
    .forEach((familyAssociate, idx) =>
      Object.keys(familyAssociate).forEach((key) =>
        formData.append(
          `${modelName}[families_associates][${idx}][${key}]`,
          familyAssociate[key] !== undefined && familyAssociate[key] !== null
            ? familyAssociate[key]
            : ""
        )
      )
    );
  materialsAssociatesLocal
    .map((el) => ({
      material_parent_id: el.material_parent_id,
      linked: el.linked,
      quantity: el.quantity,
    }))
    .forEach((materialAssociate, idx) =>
      Object.keys(materialAssociate).forEach((key) =>
        formData.append(
          `${modelName}[materials_associates][${idx}][${key}]`,
          materialAssociate[key] !== undefined &&
            materialAssociate[key] !== null
            ? materialAssociate[key]
            : ""
        )
      )
    );
};

export const createZoneTemplateForm = ({ values }) => {
  const formatableKeys = [
    "entitled",
    "position",
    "option",
    "difficulty_profession_id",
    "majoration_profession_id",
  ];
  const formData = new FormData();
  Object.keys(values).forEach(
    (key) =>
      formatableKeys.includes(key) &&
      formData.append(
        `zone[${key}]`,
        values[key] !== undefined && values[key] !== null ? values[key] : ""
      )
  );

  if (values?.carrycot_profession_id !== undefined) {
    formData.append(
      "zone[carrycot_profession_id]",
      values.carrycot_profession_id
    );
  }

  return formData;
};
