import { Checkbox, Form } from "antd";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import FormRow from "../../Form/FormRow";
import FormCol from "../../Form/FormCol";
import Label from "../../Form/Label";
import TitleContainer from "../../react-ui/TitleContainer";
import Button from "../../react-ui/Button";
import FormattedInput from "../../react-ui/FormattedInput";
import AssociationsTable from "./AssociationsTable";
import { useStore } from "../../store";
import { postData } from "../../request/instance";
import { eurosToCents } from "../../../utils/currencyConverter";
import SaveButton from "../../react-ui/SaveButton";
import Select from "../../react-ui/Select";
import { stringToFloat } from "../../../utils/stringToFloat";

const selector = (state) => ({
  formToken: state.formToken,
});

function ApplyValueForm({
  closeModal,
  frequencies,
  workPositions,
  disitinctWorkBonuses,
  disitinctIndemnities,
  opportunityId,
  configApplication,
  coeffToMonthly,
  isLockedByUser,
}) {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);
  const [form] = Form.useForm();
  const [periodicity, setPeriodicity] = useState("hourly");
  const deleteForcedSeniorityForm = Form.useWatch(
    "delete_forced_seniority",
    form
  );
  const deleteHourlyPriceForm = Form.useWatch("delete_hourly_price", form);
  const indemnities = useRef([]);
  const workBonuses = useRef([]);

  const { mutate: applyValuesMutation, isLoading } = useMutation(
    (todo) => postData(formToken, `/agent/apply_values`, todo),
    {
      onSettled: () => closeModal(),
    }
  );

  const handleSubmit = ({
    delete_forced_seniority,
    delete_hourly_price,
    forced_seniority,
    vacation_time,
    health_mutual_cents,
    hourly_price,
    ...workPositionsChecked
  }) => {
    const ids = Object.keys(
      Object.fromEntries(
        Object.entries(workPositionsChecked).filter(([, value]) => !!value)
      )
    );
    const send = {
      opportunity_id: opportunityId,
      ids,
      indemnity_attributes: indemnities.current,
      work_bonus_attributes: workBonuses.current,
      agent: {
        periodicity,
        ...(forced_seniority && { forced_seniority }),
        ...(vacation_time && { vacation_time }),
        ...(health_mutual_cents && {
          health_mutual_cents: eurosToCents(health_mutual_cents),
        }),
        ...(hourly_price && {
          hourly_price:
            periodicity === "hourly"
              ? stringToFloat(hourly_price)
              : stringToFloat(hourly_price) / coeffToMonthly,
        }),
        ...(delete_hourly_price && { hourly_price: "" }),
        ...(delete_forced_seniority && { forced_seniority: "" }),
      },
    };
    applyValuesMutation(send, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            (query.queryKey[0] === "WorkBonuses" ||
              query.queryKey[0] === "Indemnities" ||
              query.queryKey[0] === "Agents") &&
            (query.queryKey[1]?.distinct ||
              (query.queryKey[1]?.wpId &&
                ids.includes(query.queryKey[1].wpId.toString()))),
        });
      },
    });
  };

  const updateIdemnities = ({ id, ...fields }) => {
    indemnities.current = updateAssociation(indemnities.current, {
      id,
      ...fields,
    });
  };

  const updateWorkBonuses = ({ id, ...fields }) => {
    workBonuses.current = updateAssociation(workBonuses.current, {
      id,
      ...fields,
    });
  };

  const updateAssociation = (associations, { id, ...fields }) => {
    const newAssociations = [...associations];
    const assoIdx = newAssociations.findIndex((el) => el.name === id);
    if (assoIdx < 0) {
      newAssociations.push({ name: id, ...fields });
      return newAssociations;
    }

    const newAsso = { ...newAssociations[assoIdx], ...fields };
    const amountIsNotDefined =
      newAsso.amount_cents === undefined ||
      newAsso.amount_cents === null ||
      newAsso.amount_cents === "";
    const frequencyIsNotDefined =
      newAsso.frequency === undefined ||
      newAsso.frequency === null ||
      newAsso.frequency === "";
    if (amountIsNotDefined) delete newAsso.amount_cents;
    if (frequencyIsNotDefined) delete newAsso.frequency;
    if (amountIsNotDefined && frequencyIsNotDefined)
      newAssociations.splice(assoIdx, 1);
    else newAssociations[assoIdx] = newAsso;
    return newAssociations;
  };

  const checkAllWps = () => {
    const wps = {};
    workPositions.forEach((wp) => {
      wps[wp.id] = true;
    });
    form.setFieldsValue(wps);
  };

  const uncheckAllWps = () => {
    const wps = {};
    workPositions.forEach((wp) => {
      wps[wp.id] = false;
    });
    form.setFieldsValue(wps);
  };

  const deleteForcedSeniority = () => {
    form.setFieldValue("forced_seniority", undefined);
  };

  const deleteHourlyPrice = () => {
    form.setFieldValue("hourly_price", undefined);
  };

  return (
    <Form
      id="reference-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
    >
      <TitleContainer label="Appliquer des valeurs">
        <StyledButton
          label="Annuler"
          onClick={closeModal}
          fontSize="14px"
          btnType="cancel"
          type="button"
        />
        <StyledSaveButton
          label="Appliquer"
          type="submit"
          value="submit"
          fontSize="14px"
          loading={isLoading}
        />
      </TitleContainer>

      <FormContainer>
        <DeleteFormRow>
          <DeleteLabel label="Supprimer les valeurs :" />
          <FormCol
            name="delete_forced_seniority"
            width="25%"
            valuePropName="checked"
          >
            <Checkbox onChange={deleteForcedSeniority}>
              Taux d'ancienneté forcé
            </Checkbox>
          </FormCol>
          <FormCol
            name="delete_hourly_price"
            width="40%"
            valuePropName="checked"
          >
            <Checkbox onChange={deleteHourlyPrice}>
              Taux horaire/Salaire mensuel brut forcé
            </Checkbox>
          </FormCol>
        </DeleteFormRow>
        <StyledFormRow>
          <FormCol label="Vacation" name="vacation_time" labelOnTop>
            <StyledInput isNumber suffix="h" textAlign="right" />
          </FormCol>
          <FormCol label="Mutuelle" name="health_mutual_cents" labelOnTop>
            <StyledInput isNumber toCents suffix="€/mois" textAlign="right" />
          </FormCol>
          <FormCol
            label="Taux d'ancienneté forcé"
            name="forced_seniority"
            labelOnTop
          >
            <StyledInput
              isNumber
              suffix="%"
              disabled={deleteForcedSeniorityForm}
              textAlign="right"
            />
          </FormCol>
          <FormCol
            label={
              <PeriodicitySelect
                showSearch={false}
                bordered={false}
                value={periodicity}
                onChange={(val) => setPeriodicity(val)}
                options={[
                  {
                    id: "hourly",
                    name: <PeriodicityLabel label="Tx. horaire forcé" />,
                  },
                  {
                    id: "monthly",
                    name: (
                      <PeriodicityLabel label="Salaire brut mensuel forcé" />
                    ),
                  },
                ]}
              />
            }
            name="hourly_price"
            labelOnTop
          >
            <StyledInput
              isNumber
              suffix="€"
              disabled={deleteHourlyPriceForm}
              textAlign="right"
            />
          </FormCol>
        </StyledFormRow>

        <AssociationsContainer>
          <AssociationWrapper>
            <AssociationTitle label="Primes" />
            <AssociationsTable
              isFromApplyValues
              isWorkBonus
              associations={disitinctWorkBonuses?.map((el) => ({
                name: el,
                id: el,
              }))}
              updateMutation={updateWorkBonuses}
              frequencies={frequencies}
              odd={false}
              configApplication={configApplication}
              isLockedByUser={isLockedByUser}
            />
          </AssociationWrapper>
          <AssociationWrapper>
            <AssociationTitle label="Indemnités" />
            <AssociationsTable
              isFromApplyValues
              associations={disitinctIndemnities?.map((el) => ({
                name: el,
                id: el,
              }))}
              updateMutation={updateIdemnities}
              frequencies={frequencies}
              odd={false}
              configApplication={configApplication}
              isLockedByUser={isLockedByUser}
            />
          </AssociationWrapper>
        </AssociationsContainer>

        <WpContainer>
          <WpTitleWrapper>
            <WpTitle label="Sélectionnez les postes à mettre à jour" />
            <ButtonWrapper>
              <WpButton
                label={
                  <>
                    <i className="fa-duotone fa-calendar-times mr-1" />
                    Décocher tous les postes
                  </>
                }
                onClick={uncheckAllWps}
                fontSize="14px"
                type="button"
              />
              <WpButton
                label={
                  <>
                    <i className="fa-duotone fa-calendar-check mr-1" />
                    Cocher tous les postes
                  </>
                }
                onClick={checkAllWps}
                fontSize="14px"
                type="button"
              />
            </ButtonWrapper>
          </WpTitleWrapper>
          <WpWrapper>
            {workPositions?.map((wp) => (
              <WpFormRow key={wp.id}>
                <FormCol
                  name={`${wp.id}`}
                  valuePropName="checked"
                  labelAlign="right"
                >
                  <Checkbox>{wp.title}</Checkbox>
                </FormCol>
              </WpFormRow>
            ))}
          </WpWrapper>
        </WpContainer>
      </FormContainer>
    </Form>
  );
}

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const WpButton = styled(Button)`
  font-size: 11px !important;
`;

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

const StyledInput = styled(FormattedInput)`
  height: 24px;
`;

const DeleteLabel = styled(Label)`
  font-size: 18px !important;
`;

const DeleteFormRow = styled(FormRow)`
  padding-bottom: 16px;
`;

const StyledFormRow = styled(FormRow)`
  .ant-form-item-label {
    padding: 0 !important;
  }
`;

const AssociationsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const AssociationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 50%;
`;

const AssociationTitle = styled(Label)`
  text-align: center;
  margin: 20px 0 0;
  font-size: 18px !important;
`;

const WpContainer = styled.div`
  display: flex;
  margin: 20px 0 10px;
  flex-direction: column;
  gap: 10px;
`;

const WpTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 20px;
  gap: 10px;
`;

const WpWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

const WpTitle = styled(Label)`
  font-size: 18px !important;
`;

const WpFormRow = styled(FormRow)`
  padding: 0 !important;
  width: 20%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

const StyledSaveButton = styled(SaveButton)``;

const PeriodicitySelect = styled(Select)`
  .ant-select-selector {
    padding: 0 7px 0 0 !important;
    border: none !important;
    height: 21px !important;
  }
  .ant-select-selection-item {
    line-height: 0 !important;
    padding-inline-end: 24px !important;
  }
  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.blue07};
    font-size: 14px !important;
  }
`;

const PeriodicityLabel = styled(Label)`
  cursor: pointer;
  white-space: normal !important;
  font-size: 14px !important;
`;

export default ApplyValueForm;
