import { Divider, Select } from "antd";
import React, { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { postData } from "../../../request/instance";
import { filesNames, icons } from "../FilesImport";
import GeneralComponent from "./GeneralComponent";
import Vocabulaire from "./Vocabulaire";

function GeneralTab({
  props,
  configuration,
  setConfiguration,
  setVocabulary,
  vocabulary,
  formToken,
}) {
  const [chosenIcon, setChosenIcon] = useState(configuration.general.work_icon);
  const [chosenFile, setChosenFile] = useState(props.entity_work_name);

  const { mutate: mergeConfiguration } = useMutation(
    (todoData) =>
      postData(formToken, `/company_work_configuration/merge`, todoData),
    {
      onSuccess: (data) => {
        const newConfigTemplate = {
          ...data.merged_configuration,
          general: {
            ...data.merged_configuration.general,
            work_icon: chosenIcon,
          },
          template_name: data.company_work_name,
        };
        setConfiguration(newConfigTemplate);
      },
      onError: (error) => {
        console.log("ERROR ====>", error);
      },
    }
  );

  return (
    <Container>
      <LeftChild>
        <SelectContainer>
          <WrapperColLeft>
            {props.role === "superadmin" && <p>Modèle utilisé: </p>}
            <p>Icône métier: </p>
          </WrapperColLeft>
          <WrapperColRight>
            {props.role === "superadmin" && (
              <Select
                size="small"
                filled="true"
                style={{
                  width: "210px",
                  padding: "0 8px 0 8px",
                  radius: "2px",
                }}
                defaultValue={configuration.template_name ?? chosenFile}
                placeholder="Select a model"
                onChange={(value) => {
                  setChosenFile(value);
                  mergeConfiguration({
                    company_work_name: value,
                  });
                }}
              >
                {filesNames.map((file) => (
                  <Select.Option key={file} value={file}>
                    {file}
                  </Select.Option>
                ))}
              </Select>
            )}
            <Select
              size="small"
              filled="true"
              style={{ padding: "0 8px 0 8px", radius: "2px" }}
              defaultValue={icons.find((icon) => icon === chosenIcon)}
              placeholder="Select an icon"
              onChange={(value) => {
                setChosenIcon(value);
                setConfiguration({
                  ...configuration,
                  general: { ...configuration.general, work_icon: value },
                });
              }}
            >
              {icons.map((icon) => (
                <Select.Option key={icon} value={icon}>
                  <i className={icon} />
                </Select.Option>
              ))}
            </Select>
          </WrapperColRight>
        </SelectContainer>

        <GeneralComponent
          props={props}
          configuration={configuration}
          setConfiguration={setConfiguration}
        />
      </LeftChild>
      <Divider
        type="vertical"
        style={{
          height: "auto",
          borderColor: "rgba(11, 34, 57, 1)",
          margin: "10px 0",
        }}
      />
      <RightChild>
        <Vocabulaire
          props={props}
          vocabulary={vocabulary}
          setVocabulary={setVocabulary}
          configuration={configuration}
        />
      </RightChild>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  /* gap: 2%; */
  background: rgba(255, 255, 255, 1);
`;

const LeftChild = styled.div`
  flex-basis: 50%;
  padding: 10px;
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;

const WrapperColLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 10px;

  p {
    margin: 0;
  }
`;

const WrapperColRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 10px;

  p {
    margin: 0;
  }
`;

const RightChild = styled.div`
  flex-basis: 50%;
  padding: 10px;
`;

export default GeneralTab;
