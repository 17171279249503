import React, { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { Loading } from "../../../loading";
import AddButton from "../../../react-ui/AddButton";
import Button from "../../../react-ui/Button";
import TitleContainer from "../../../react-ui/TitleContainer";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import TemplateForm from "./TemplateForm";
import Loader from "../../../react-ui/Loader";

const selector = (state) => ({
  formToken: state.formToken,
});

function TemplateDetails({
  status,
  works,
  companyWorks,
  selectedTemplate,
  setSelectedTemplate,
  templates,
  closeDetails,
  settableWorks,
  canSetGlobalWorks,
  setStatus,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();

  const { mutate: createTemplate, isLoading: createLoading } = useMutation(
    (todo) => postData(formToken, "/sa_template/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Templates");
      },
      onSuccess: (payload) => {
        setStatus("update");
        setSelectedTemplate(payload);
      },
      onError: () => {
        setStatus("empty");
      },
    }
  );

  const { mutate: updateTemplate, status: templateStatus } = useMutation(
    (todo) => postData(formToken, "/sa_template/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Templates");
      },
      onError: () => {
        setSelectedTemplate({
          ...templates.find((el) => el.id === selectedTemplate.id),
        });
      },
    }
  );
  const appendAssociationForCreation = ({
    assosToCreate,
    formData,
    associationName,
    associationAttributeName,
    defaultId,
  }) => {
    let assoIdx = 0;
    if (!assosToCreate && status === "create") {
      formData.append(
        `sa_template[${associationAttributeName}_attributes][][${associationName}_id]`,
        defaultId
      );
    } else if (!assosToCreate?.some((el) => el === "global")) {
      assosToCreate?.forEach((assoId) => {
        formData.append(
          `sa_template[${associationAttributeName}_attributes][${assoIdx}][${associationName}_id]`,
          assoId
        );
        assoIdx += 1;
      });
    }
    return assoIdx;
  };

  const appendAssociationForDestroy = ({
    assosToDestroy,
    formData,
    assoIdx,
    associationAttributeName,
  }) => {
    let newAssoIdx = assoIdx;
    assosToDestroy?.forEach((asso) => {
      formData.append(
        `sa_template[${associationAttributeName}_attributes][${newAssoIdx}][id]`,
        asso.id
      );
      formData.append(
        `sa_template[${associationAttributeName}_attributes][${newAssoIdx}][_destroy]`,
        true
      );
      newAssoIdx += 1;
    });
  };

  const getAssosToDestroy = ({
    associationName,
    assosFromForm,
    actualTemplate,
  }) => {
    if (!assosFromForm) return undefined;
    if (associationName === "company_work") {
      const selectedCwIds = actualTemplate.sa_template_company_works.map(
        (el) => el.company_work_id
      );
      const selectedCompanyWorks = companyWorks.filter((el) =>
        selectedCwIds.includes(el.id)
      );
      const cwIdsToDestroy = selectedCompanyWorks
        .filter((cw) => !assosFromForm.includes(cw.work_id))
        .map((el) => el.id);

      return actualTemplate.sa_template_company_works.filter((cwd) =>
        cwIdsToDestroy.includes(cwd.company_work_id)
      );
    }
    return [];
  };

  const getAssosToCreate = ({
    associationName,
    assosFromForm,
    actualTemplate,
  }) => {
    if (!assosFromForm) return undefined;
    if (assosFromForm.includes("global")) return assosFromForm;
    if (associationName === "company_work") {
      const cwToCreate = companyWorks
        .filter((el) => assosFromForm.includes(el.work_id))
        .map((el) => el.id);
      if (status === "create") return cwToCreate;

      const selectedCwIds = actualTemplate.sa_template_company_works.map(
        (el) => el.company_work_id
      );
      const selectedCompanyWorksIds = companyWorks
        .filter((el) => selectedCwIds.includes(el.id))
        .map((el) => el.id);

      return cwToCreate.filter((el) => !selectedCompanyWorksIds.includes(el));
    }
    return [];
  };

  const appendAssociationsToForm = ({
    assosFromForm,
    formData,
    associationName,
    defaultId,
    actualTemplate,
  }) => {
    const associationAttributeName = `sa_template_${associationName}s`;
    if (status === "create") {
      const assosToCreate = getAssosToCreate({
        associationName,
        assosFromForm,
      });
      appendAssociationForCreation({
        assosToCreate,
        formData,
        associationName,
        defaultId,
        associationAttributeName,
      });
    }
    if (status === "update") {
      const assosToCreate = getAssosToCreate({
        associationName,
        assosFromForm,
        actualTemplate,
      });
      const assosToDestroy = getAssosToDestroy({
        associationName,
        assosFromForm,
        actualTemplate,
      });

      let assoIdx = 0;
      assoIdx = appendAssociationForCreation({
        assosToCreate,
        formData,
        associationName,
        defaultId,
        associationAttributeName,
      });
      appendAssociationForDestroy({
        assosToDestroy,
        formData,
        assoIdx,
        associationAttributeName,
      });
    }
  };

  const appendTemplateToForm = ({ formData, values, actualTemplate }) => {
    formData.append(`sa_template[name]`, values.name);
    appendAssociationsToForm({
      actualTemplate,
      assosFromForm: values.works,
      formData,
      associationName: "company_work",
      defaultId: companyWorks.find((el) => el.work_id === settableWorks[0].id)
        .id,
    });
  };

  const handleSubmit = (values) => {
    const formData = new FormData();

    if (status === "create") {
      appendTemplateToForm({ values, formData });
      createTemplate(formData);
    }
    if (status === "update") {
      const actualTemplate = templates.find(
        (el) => el.id === selectedTemplate.id
      );
      appendTemplateToForm({ values, formData, actualTemplate });
      formData.append("id", selectedTemplate.id);
      updateTemplate(formData);
    }
  };

  const initialValues = useMemo(() => {
    if (status !== "update" || !selectedTemplate) return undefined;
    const templCwIds = selectedTemplate.sa_template_company_works.map(
      (el) => el.company_work_id
    );

    const templCompanyWorks = companyWorks.filter((el) =>
      templCwIds.includes(el.id)
    );
    return {
      ...selectedTemplate,
      works:
        templCwIds.length > 0
          ? works
              .filter((w) =>
                templCompanyWorks.some((cw) => cw.work_id === w.id)
              )
              .map((w) => w.id)
          : ["global"],
    };
  }, [companyWorks, selectedTemplate, status, works]);

  const getTemplateName = () => {
    if (status !== "update") return "";
    const actualTemplate = templates.find(
      (el) => el.id === selectedTemplate?.id
    );
    if (!actualTemplate?.name) return "";
    return ` : ${actualTemplate.name}`;
  };

  return (
    <Container>
      <TitleContainer label={`Détails du modèle${getTemplateName()}`}>
        {status === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={closeDetails}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="template-form"
              loading={createLoading}
            />
          </>
        )}
        <Loading status={templateStatus} />
      </TitleContainer>
      {status === "empty" && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez un template pour afficher les détails
          </p>
        </div>
      )}
      {status !== "empty" && (
        <Wrapper>
          {status === "reload" && <Loader />}
          {status !== "reload" && (
            <TemplateForm
              works={works}
              handleSubmit={handleSubmit}
              initialValues={initialValues}
              status={status}
              settableWorks={settableWorks}
              canSetGlobalWorks={canSetGlobalWorks}
              updateOnChange={status === "update"}
              companyWorks={companyWorks}
              setStatus={setStatus}
            />
          )}
        </Wrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

export default TemplateDetails;
