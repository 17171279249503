import React, { useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import { useQueryClient } from "react-query";
import ContactForm from "../Form/ContactForm";

function ContactModal({
  contactModalOpenFrom,
  setContactModalOpenFrom,
  companyId,
  updateOnChange,
  formOpp,
}) {
  const [clientsInMemory, setClientsInMemory] = useState([]);

  const queryClient = useQueryClient();

  const onSuccess = (contact) => {
    queryClient.invalidateQueries("Contacts");
    const oldContacts = formOpp.getFieldValue(contactModalOpenFrom) || [];
    formOpp.setFieldsValue({
      [contactModalOpenFrom]: [...oldContacts, contact.id],
    });
    updateOnChange();
  };

  return (
    <StyledModal
      destroyOnClose
      open={contactModalOpenFrom !== ""}
      footer={null}
      closable={false}
      onCancel={() => setContactModalOpenFrom("")}
      bodyStyle={{ padding: 0 }}
      width={900}
      maskClosable={false}
    >
      <ContactForm
        companyId={companyId}
        onSuccess={onSuccess}
        onClose={() => setContactModalOpenFrom("")}
        setClientsInMemory={setClientsInMemory}
        clientsInMemory={clientsInMemory}
      />
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

export default ContactModal;
