$(document).ready(() => {

const outfitAdmin = document.getElementById("outfits-tables")
const outfitCosting = document.getElementById("outfits-tables-costing")

if(!outfitAdmin && !outfitCosting){
  return
}

  // row clickable to display the show
  $(document).on("click", "#outfits-tables .row-outfit", function() {
    const link = $(this).data("details")
    $.ajax({
      url: link,
      type: "GET"
    });
  });

  // row clickable to display the show
  $(document).on("click", "#outfits-tables-costing .row-outfit", function() {
    const link = $(this).data("details")
    $.ajax({
      url: link,
      type: "GET"
    });
  });

  // hovering the div row when hover
  const rowsTable = document.querySelectorAll("#outfits-tables .row-outfit");
  if (rowsTable) {
    const addHovering = () => {
      event.currentTarget.classList.add("hovering")
    }

    const removeHovering = () => {
      event.currentTarget.classList.remove("hovering")
    }
    rowsTable.forEach(row => row.addEventListener("mouseover", addHovering))
    rowsTable.forEach(row => row.addEventListener("mouseout", removeHovering))
  }

  // hovering the div row when hover
  const rowsTableCosting = document.querySelectorAll("#outfits-tables-costing .row-outfit");
  if (rowsTableCosting) {
    const addHovering = () => {
      event.currentTarget.classList.add("hovering")
    }

    const removeHovering = () => {
      event.currentTarget.classList.remove("hovering")
    }
    rowsTableCosting.forEach(row => row.addEventListener("mouseover", addHovering))
    rowsTableCosting.forEach(row => row.addEventListener("mouseout", removeHovering))
  }

  // add a grey background to the odd div left part
  const addBackGroundToDivRows = () => {
    const cardBoxs = $("#outfits-tables .row-outfit:even");
    if (cardBoxs) {
      $.each(cardBoxs, function (index, value) {
        $(this).addClass("greylight-background-odd");
      });
    }
  }
  addBackGroundToDivRows()

  // add a grey background to the odd div left part
  const addBackGroundToDivRowsCosting = () => {
    const cardBoxs = $("#outfits-tables-costing .row-outfit:even");
    if (cardBoxs) {
      $.each(cardBoxs, function (index, value) {
        $(this).addClass("greylight-background-odd");
      });
    }
  }
  addBackGroundToDivRowsCosting()

  // add a grey background to the odd tr right part
  const addBackGroundToRows = () => {
    const trBoxs = $(".details-clothings tr");
    if (trBoxs) {
      $.each(trBoxs, function (index, value) {
        $(this).addClass("greylight-background-odd");
      });
    }
  }

  //add background when the item is selected
  const addBackGroundWhenSelected = () => {
    rowsTable.forEach(row => row.classList.remove("grey-background"))
    event.currentTarget.classList.add("grey-background")
  };

  rowsTable.forEach(row => row.addEventListener("click", addBackGroundWhenSelected))
})
