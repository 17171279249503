import React, { useMemo } from "react";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { stringToFloat } from "../../../utils";
import { formulaMarginRate } from "../../../../utils/formulaMarginRate";

export const FormFactory = ({
  configuration,
  cost,
  updateOnChange,
  from,
  faIsValidate,
  unities,
  vocabulary,
  showFAFields,
  isUsingMargin,
  calculMethod,
  categories,
  itemsToCascader,
  group,
}) => {
  return useMemo(() => {
    const formatedUnities = unities?.map((unity) => {
      const formatedUnity = {
        title: unity.name,
        ...unity,
      };
      return formatedUnity;
    });

    const uploadPicture = [
      {
        type: "upload",
        item: {
          upload: {
            listType: "picture-card",
            maxCount: 1,
            accept: "image/*",
          },
          name: "picture",
        },
        style: { display: "flex", justifyContent: "flex-start" },
      },
    ];
    const firstRow = [
      {
        type: "multiple",
        items: [
          {
            type: "input",
            item: {
              input: {
                placeholder: `Nom du ${vocabulary.product_lowercase}`,
                disabled: showFAFields,
              },
              name: "name",
              label: "Intitulé",
              rules: [
                {
                  required: true,
                  message: "Champ obligatoire.",
                },
              ],
            },
          },
          // {
          //   type: "input",
          //   item: {
          //     input: {
          //       placeholder: `Référence ${vocabulary.product_lowercase}`,
          //       disabled: showFAFields,
          //     },
          //     name: "reference_code",
          //     label: "Code article",
          //   },
          // },
        ],
      },
    ];

    const matOppName = [
      {
        type: "input",
        item: {
          input: {
            placeholder: `Nom du ${vocabulary.product_lowercase}`,
          },
          name: "material_included_name",
          label: "Intitulé accordcadre",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const matOppRefCode = [
      {
        type: "input",
        item: {
          input: {
            placeholder: `Référence ${vocabulary.product_lowercase}`,
          },
          name: "material_included_reference_code",
          label: "Code article accordcadre",
        },
      },
    ];

    const secondRow = [
      {
        type: "cascader",
        item: {
          cascader: {
            placeholder: "Choisissez une catégorie",
            changeOnSelect: true,
            expandTrigger: "hover",
            allowClear: false,
            disabled: showFAFields,
          },
          options: itemsToCascader({
            items: categories,
            keyParent: "parent_category_id",
            keyName: "title",
          }),
          name: "category_profession_id",
          label: "Catégorie",
          rules: [
            {
              required: false,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const descriptionRemarkRow = [
      {
        type: "textarea",
        item: {
          textarea: {
            placeholder: "Ajouter une description...",
            disabled: showFAFields,
          },
          name: "description",
          label: "Description",
        },
      },
    ];

    const fourthRow = [
      !group &&
        configuration.admin.product_tab.form.input_margin_rate && {
          type: "input",
          item: {
            input: {
              disabled: showFAFields,
              onInput: (e) => {
                e.currentTarget.value = formatNumberString({
                  str: e.currentTarget.value,
                  isCoeff: !isUsingMargin,
                });
              },
            },
            isMarginRate: true,
            name: "margin_rate",
          },
        },
    ];

    const sellingPriceAndFAMarginRate = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            suffix: "€",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 2,
              });
              cost.current?.setFieldsValue({
                margin_rate_computed: formatNumberString({
                  str: formulaMarginRate({
                    costPrice: stringToFloat(
                      cost.current?.form.getFieldValue("cost_price_cents")
                    ),
                    calculMethod,
                    sellingPrice: stringToFloat(e.currentTarget.value),
                    isUsingMargin,
                  }),
                  isCoeff: !isUsingMargin,
                  space: true,
                }),
              });
            },
            onFocus: (e) => {
              cost.current?.setFieldsValue({
                selling_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              });
            },
            onBlur: (e) => {
              cost.current?.setFieldsValue({
                selling_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
          },
          name: "selling_price_cents",
          label: "Prix de vente",
          rules: [
            {
              required: false,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            disabled: true,
          },
          name: "margin_rate_computed",
          isComputed: true,
          isMarginRate: true,
        },
      },
    ];

    const sixthRow = [
      {
        type: "select",
        item: {
          select: {
            // eslint-disable-next-line prettier/prettier
            // placeholder: vocabulary.unity_profession,
            disabled: showFAFields,
            allowClear: true,
            defaultValue: "unité",
          },
          options: [...formatedUnities, { title: "unité" }],
          name: "unity_profession_id",
          label: "Unité de quantité",
        },
      },
      configuration.general.maintenance && {
        type: "checkbox",
        item: {
          checkbox: {
            disabled:
              from === "opportunity" ||
              from === "referencing" ||
              updateOnChange,
          },
          name: "maintenance",
          label: "Maintenance",
        },
      },
    ];

    const seventhRow = [
      configuration.admin.product_tab.form.input_date_validity && {
        type: "datePicker",
        item: {
          datePicker: {
            placeholder: "Choisir une date",
            disabled: showFAFields,
          },
          name: "date_validity",
          label: "Date de validité",
        },
      },
    ];

    const referenceConstructorRow = [
      configuration.admin.product_tab.form.input_reference_constructor && {
        type: "input",
        item: {
          input: {
            placeholder: "Référence constructeur",
            disabled: showFAFields,
          },
          name: "reference_constructor",
          label: "Référence constructeur",
        },
        span: 12,
      },
    ];

    return [
      { columns: uploadPicture, key: "picture" },
      { columns: firstRow, key: "name+picture" },
      showFAFields && {
        columns: matOppName,
        key: "matOppName",
      },
      // showFAFields && {
      //   columns: matOppRefCode,
      //   key: "matOppRefCode",
      // },
      { columns: secondRow, key: "category_profession_id" },
      { columns: descriptionRemarkRow, key: "descriptionRemarkRow" },
      showFAFields &&
        from !== "referencing" && {
          columns: sellingPriceAndFAMarginRate,
          key: "sellingPriceAndFAMarginRate",
        },
      { columns: fourthRow, key: "margin_rate" },
      { columns: sixthRow, key: "unity+maintenance" },
      { columns: seventhRow, key: "date" },
    ];
  }, [
    unities,
    updateOnChange,
    from,
    faIsValidate,
    vocabulary.product_lowercase,
    vocabulary.unity_profession,
    showFAFields,
    configuration.admin.product_tab.form.input_margin_rate,
    configuration.admin.product_tab.form.input_date_validity,
    configuration.admin.product_tab.form.input_reference_constructor,
    isUsingMargin,
    cost,
    calculMethod,
  ]);
};
