export const vehiculeMovement = [
    {
        "id": 0,
        "entity_work_id": 0,
        "opportunity_id": 0,
        "movement_profession_id": 0,
        "name": "vehicule",
        "description": "",
        "type_transport": "Vehicule",
        "TVA_fuel": 20,
        "consomation": 7,
        "TVA_recuperation": 100,
        "price_fuel_cents_cents": 100,
        "cost_annual_vehicle_cents": 1000000,
        "km_annual": 20000,
        "km_cost": 100,
        "other_cost_cents": null,
        "created_at": "2024-05-23T08:25:05.147Z",
        "updated_at": "2024-05-23T08:25:05.147Z",
        "margin_rate": null,
        "reference_code": null,
        "is_from_fa": false
    }
]

export const vehiculeMovementOpportunity = [
    {
        "id": 0,
        "zone_id": 0,
        "roundtrip_distance_km": 100,
        "roundtrip_duration_hour": 100,
        "quantity": 1,
        "total_distance_km": null,
        "total_duration_hour": null,
        "occupants_number": 1,
        "subcontracting": false,
        "created_at": "2024-05-29T08:11:22.003Z",
        "updated_at": "2024-05-29T08:11:33.458Z",
        "opportunity_id": 0,
        "other_cost_cents": null,
        "total_cost_cents": null,
        "name": "vehicule",
        "description": "",
        "type_transport": "Vehicule",
        "TVA_fuel": 20,
        "consomation": 7,
        "TVA_recuperation": 100,
        "price_fuel_cents_cents": 200,
        "cost_annual_vehicle_cents": 1000000,
        "km_annual": 20000,
        "km_cost": 100,
        "movement_tpl_opportunity_id": 0,
        "margin_rate": 50,
        "selling_price_cents": 0,
        "total_selling_price_cents": 0,
        "cost_price_cents": 0,
        "reference_code": null,
        "cost_changed": false,
        "movement_profession_id": 0,
        "is_from_fa": false,
        "old_cost_cents": null
    }
]

export const otherMovement = [
    {
        "id": 0,
        "entity_work_id": 0,
        "opportunity_id": 0,
        "movement_profession_id": 0,
        "name": "train",
        "description": "",
        "type_transport": "Train",
        "TVA_fuel": null,
        "consomation": null,
        "TVA_recuperation": null,
        "price_fuel_cents_cents": null,
        "cost_annual_vehicle_cents": null,
        "km_annual": null,
        "km_cost": null,
        "other_cost_cents": 10000,
        "created_at": "2024-05-23T08:25:05.139Z",
        "updated_at": "2024-05-23T08:25:05.139Z",
        "margin_rate": null,
        "reference_code": null,
        "is_from_fa": false
    }
]

export const otherMovementOpportunity = [
    {
        "id": 0,
        "zone_id": 0,
        "roundtrip_distance_km": null,
        "roundtrip_duration_hour": null,
        "quantity": 1,
        "total_distance_km": null,
        "total_duration_hour": null,
        "occupants_number": null,
        "subcontracting": false,
        "created_at": "2024-05-29T08:17:49.713Z",
        "updated_at": "2024-05-29T08:17:49.713Z",
        "opportunity_id": 0,
        "other_cost_cents": "100",
        "total_cost_cents": "100",
        "name": "train",
        "description": "",
        "type_transport": "Train",
        "TVA_fuel": null,
        "consomation": null,
        "TVA_recuperation": null,
        "price_fuel_cents_cents": null,
        "cost_annual_vehicle_cents": null,
        "km_annual": null,
        "km_cost": null,
        "movement_tpl_opportunity_id": 0,
        "margin_rate": 50,
        "selling_price_cents": 18450,
        "total_selling_price_cents": 18450,
        "cost_price_cents": 10000,
        "reference_code": null,
        "cost_changed": false,
        "movement_profession_id": 0,
        "is_from_fa": false,
        "old_cost_cents": null
    }
]

export const otherCost = [
    {
        "id": 0,
        "zone_id": 0,
        "roundtrip_distance_km": null,
        "roundtrip_duration_hour": null,
        "quantity": 1,
        "total_distance_km": null,
        "total_duration_hour": null,
        "occupants_number": null,
        "subcontracting": false,
        "created_at": "2024-05-29T08:16:14.575Z",
        "updated_at": "2024-05-29T08:16:14.575Z",
        "opportunity_id": 0,
        "other_cost_cents": "1",
        "total_cost_cents": "1",
        "name": "Lorem",
        "description": "fr",
        "type_transport": "Hotel",
        "TVA_fuel": null,
        "consomation": null,
        "TVA_recuperation": null,
        "price_fuel_cents_cents": null,
        "cost_annual_vehicle_cents": null,
        "km_annual": null,
        "km_cost": null,
        "movement_tpl_opportunity_id": 0,
        "margin_rate": 50,
        "selling_price_cents": 150,
        "total_selling_price_cents": 150,
        "cost_price_cents": 100,
        "reference_code": null,
        "cost_changed": false,
        "movement_profession_id": null,
        "is_from_fa": false,
        "old_cost_cents": null
    }
]

export const otherCostMovementOpportunity = [
    {
        "id": 0,
        "zone_id": 0,
        "roundtrip_distance_km": null,
        "roundtrip_duration_hour": null,
        "quantity": 1,
        "total_distance_km": null,
        "total_duration_hour": null,
        "occupants_number": null,
        "subcontracting": false,
        "created_at": "2024-05-29T08:16:14.575Z",
        "updated_at": "2024-05-29T08:16:14.575Z",
        "opportunity_id": 0,
        "other_cost_cents": "1",
        "total_cost_cents": "1",
        "name": "frf",
        "description": "fr",
        "type_transport": "Hotel",
        "TVA_fuel": null,
        "consomation": null,
        "TVA_recuperation": null,
        "price_fuel_cents_cents": null,
        "cost_annual_vehicle_cents": null,
        "km_annual": null,
        "km_cost": null,
        "movement_tpl_opportunity_id": 0,
        "margin_rate": 50,
        "selling_price_cents": 150,
        "total_selling_price_cents": 150,
        "cost_price_cents": 100,
        "reference_code": null,
        "cost_changed": false,
        "movement_profession_id": null,
        "is_from_fa": false,
        "old_cost_cents": null
    }
]


export const carrycotData = [
    {
        "id": 0,
        "entity_work_id": 0,
        "name": "Lorem",
        "description": "deded",
        "daily_cost_cents": "2 000",
        "movement_cost_cents": "11",
        "duration": null,
        "day_quantity": null,
        "roundtrip_quantity": null,
        "zone_id": 0,
        "subcontracting": false,
        "created_at": "2024-05-29T09:05:07.355Z",
        "updated_at": "2024-05-29T09:05:07.355Z",
        "opportunity_id": 0,
        "carrycot_tpl_opportunity_id": 0,
        "margin_rate": 50,
        "profil_opportunity_id": 0,
        "selling_price_cents": 0,
        "cost_price_cents": null,
        "reference_code": null,
        "carrycot_profession_id": 0,
        "abbreviation": null,
        "quantity": null,
        "total_selling_price_cents": null,
        "total_cost_price_cents": null,
        "is_from_fa": false,
        "old_cost_cents": null
    }
]

export const carrycotFakeDataOpportunity = [
    {
        "id": 0,
        "carrycot_profession_id": 0,
        "entity_work_id": 0,
        "opportunity_id": 0,
        "name": "Lorem",
        "description": "deded",
        "daily_cost_cents": 200000,
        "movement_cost_cents": 1100,
        "created_at": "2024-05-23T08:25:05.100Z",
        "updated_at": "2024-05-23T08:25:05.100Z",
        "margin_rate": 50,
        "reference_code": null,
        "profil_opportunity_id": 0,
        "cost_price_cents": null,
        "abbreviation": null,
        "is_from_fa": false,
        "carrycot_profession": {
            "profil_profession_id": 0
        }
    }
]

export const carrycotDataProfil = [
    {
        "id": 0,
        "entity_work_id": 0,
        "name": "testingProfil",
        "description": "",
        "subcontracting": false,
        "hourly_rate_cents": 1200,
        "profil_profession_id": 0,
        "created_at": "2024-05-23T08:25:04.461Z",
        "updated_at": "2024-05-23T08:25:04.461Z",
        "opportunity_id": 0,
        "margin_rate": null
    }
]

export const fakeZoneData = [
    {
        "id": 0,
        "opportunity_id": null,
        "entitled": "Zone par défaut",
        "created_at": "2024-05-24T16:47:45.525Z",
        "updated_at": "2024-05-29T14:20:31.490Z",
        "total_cost_price_cents": 2200,
        "total_margin_rate": null,
        "total_selling_price_cents": 3300,
        "material_margin_rate": 50,
        "material_cost_price_cents": 1100,
        "material_selling_price_cents": 1650,
        "internal_margin_rate": null,
        "internal_cost_price_cents": 0,
        "internal_selling_price_cents": 0,
        "prestation_internal_margin_rate": 0,
        "prestation_internal_cost_price_cents": 0,
        "prestation_internal_selling_price_cents": 0,
        "movement_internal_margin_rate": 0,
        "movement_internal_cost_price_cents": 0,
        "movement_internal_selling_price_cents": 0,
        "carrycot_internal_margin_rate": 0,
        "carrycot_internal_cost_price_cents": 0,
        "carrycot_internal_selling_price_cents": 0,
        "subcontracting_margin_rate": null,
        "subcontracting_cost_price_cents": 0,
        "subcontracting_selling_price_cents": 0,
        "prestation_subcontracting_margin_rate": 0,
        "prestation_subcontracting_cost_price_cents": 0,
        "prestation_subcontracting_selling_price_cents": 0,
        "movement_subcontracting_margin_rate": 0,
        "movement_subcontracting_cost_price_cents": 0,
        "movement_subcontracting_selling_price_cents": 0,
        "carrycot_subcontracting_margin_rate": 0,
        "carrycot_subcontracting_cost_price_cents": 0,
        "carrycot_subcontracting_selling_price_cents": 0,
        "difficulty_profession_id": null,
        "majoration_profession_id": null,
        "carrycot_tpl_opportunity_id": null,
        "presta_pack_int_margin_rate": 0,
        "presta_pack_int_cost_price_cents": 0,
        "presta_pack_int_selling_price_cents": 0,
        "presta_pack_sub_margin_rate": 0,
        "presta_pack_sub_cost_price_cents": 0,
        "presta_pack_sub_selling_price_cents": 0,
        "difficulty_opportunity_id": 801,
        "majoration_opportunity_id": 881,
        "ouvrage_selling_price_cents": 1650,
        "ouvrage_cost_price_cents": 1100,
        "ouvrage_margin_rate": 50,
        "position": 100,
        "zonable_type": "Opportunity",
        "zonable_id": 0,
        "carrycot_opportunity_id": null,
        "carrycot_profession_id": null,
        "is_default": false,
        "maintenance": null,
        "difficulty_opportunity": {
          "name": "first"
        },
        "majoration_opportunity": {
          "name": "first"
        },
        "material_opportunities": [
          {
            "name": "M11"
          },
          {
            "name": "M10"
          },
          {
            "name": "M10"
          }
        ],
        "ouvrage_opportunities": [
          {
            "name": "Ouvrage 1"
          },
          {
            "name": "knot 1"
          }
        ],
        "prestation_opportunities": []
      }
]