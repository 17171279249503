import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { postData } from "../request/instance";

export default function useDeleteTodo(formToken, path, name) {
    const queryClient = useQueryClient()
    return useMutation(todoData => postData(formToken, path, todoData), {
        onSuccess: async data => {
            queryClient.invalidateQueries([name, data.id])

        },
        
        // onError: (arr, _, context) => {
        //     queryClient.setQueryData(name, context.prev) //rollback the cache to the previous state
        // },
        onSettled: todo => {
            queryClient.invalidateQueries(name) //refetch th collection on the background
        }
    })
}