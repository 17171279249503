import React from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Material from "../../Admin-cf/Materials/index";
import Prestation from "../../Admin-cf/Prestations";
import Carrycots from "../../Admin-cf/Carrycots";
import Movements from "../../Admin-cf/Movements";

function CostingReferencing({
  formToken,
  entityWorkId,
  configuration,
  vocabulary,
}) {
  const { id_opportunity } = useParams();

  const items = [
    {
      label: "Matériels",
      key: "item-1",
      children: (
        <Material
          token={formToken}
          entityWorkId={entityWorkId}
          opportunityId={id_opportunity}
          from="referencing"
          configuration={configuration}
          vocabulary={vocabulary}
        />
      ),
    },
    {
      label: "Prestations",
      key: "item-2",
      children: (
        <Prestation
          entityWorkId={entityWorkId}
          opportunityId={id_opportunity}
          from="referencing"
          configuration={configuration}
          vocabulary={vocabulary}
        />
      ),
    },
    {
      label: "Nacelles",
      key: "item-3",
      children: (
        <Carrycots
          entityWorkId={entityWorkId}
          opportunityId={id_opportunity}
          from="referencing"
          configuration={configuration}
          vocabulary={vocabulary}
        />
      ),
    },
    {
      label: "Déplacements",
      key: "item-4",
      children: (
        <Movements
          entityWorkId={entityWorkId}
          opportunityId={id_opportunity}
          from="referencing"
          configuration={configuration}
          vocabulary={vocabulary}
        />
      ),
    },
  ];

  return (
    <StyledTabs
      defaultActiveKey="1"
      type="card"
      style={{ height: "calc(100% - 190px)", overflow: "auto" }}
      destroyInactiveTabPane
      items={items}
    />
  );
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-content {
    height: 100%;
  }
`;

export default CostingReferencing;
