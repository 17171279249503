import React from "react";
import { string } from "prop-types";
import styled from "styled-components";

function Edit({ className, ...iconProps }) {
  return (
    <StyledIcon className={`fa-duotone fa-edit ${className}`} {...iconProps} />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: 12px;
`;

Edit.propTypes = {
  className: string,
};

Edit.defaultProps = {
  className: "",
};

export default Edit;
