import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { opportunityField } from "../../constant";
import ContactsSelect from "./ContactsSelect";

const labelDict = {
  [opportunityField.clientContacts]: "Contact(s) de l'entreprise facturée",
  [opportunityField.providedCustomerContacts]:
    "Contact(s) de l'entreprise prestée",
  [opportunityField.decisionMakerCustomerContacts]:
    "Contact(s) de l'entreprise décisionnaire",
};

const clientKeyDict = {
  [opportunityField.clientContacts]: opportunityField.client,
  [opportunityField.providedCustomerContacts]:
    opportunityField.providedCustomer,
  [opportunityField.decisionMakerCustomerContacts]:
    opportunityField.decisionMakerCustomer,
};

function ContactsSelectWrapper({
  setContactModalOpenFrom,
  contactsInMemory,
  disabled,
  show_link,
  companyId,
  setContactsInMemory,
  name,
  form,
  required,
}) {
  const client =
    Form.useWatch(clientKeyDict[name], form) ??
    form.getFieldValue(clientKeyDict[name]);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const contactsInForm = form.getFieldValue(name);
    if (!client || !contactsInForm) form.setFieldValue(name, []);
    else {
      form.setFieldValue(
        name,
        contactsInForm.filter(
          (c) =>
            contactsInMemory.find((cIM) => cIM.id === c.value).client_id ===
            client.value
        )
      );
    }
  }, [client, contactsInMemory, form, name]);

  return (
    <div style={{ width: "100%", alignSelf: "end" }}>
      <ContactsSelect
        companyId={companyId}
        setContactModalOpenFrom={setContactModalOpenFrom}
        contacts={contacts}
        setContacts={setContacts}
        disabled={disabled || !client?.value}
        show_link={show_link}
        name={name}
        label={labelDict[name]}
        setContactsInMemory={setContactsInMemory}
        clientId={client?.value}
        required={required}
      />
    </div>
  );
}

export default ContactsSelectWrapper;
