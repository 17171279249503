import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useIsMutating, useQuery, useQueryClient } from "react-query";
import useUpdateTodo from "../../../hookQuery/useUpdateTodo";
import { useStore } from "../../../store";
import { currency_cents_to_euros } from "../../../utils";
import TableContainer from "../../CostingMovementsCarrycots/TableContainer";
import Input from "../../../react-ui/Input";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { stringToFloat } from "../../../../utils/stringToFloat";
import { eurosToCents } from "../../../../utils/currencyConverter";
import { getMarginCol } from "../../../../constants/marginCol";
import { coeffToMargin } from "../../../../utils/marginConverter";
import { simple_default_configuration } from "../../../../simple_default";
import { getData } from "../../../request/instance";
import WarningLabel from "../../../react-ui/WarningLabel";
import { fakeGlobalData } from "../../../data-utils/page3-data";

const selector = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
function GlobalTable({
  responseType,
  opportunity_work,
  configuration,
  vocabulary,
  from,
  hasOption,
}) {
  const isMutating = useIsMutating();
  const queryClient = useQueryClient();
  const { id_opportunity } = useParams();
  const { formToken, isUsingMargin, calculMethod } = useStore(selector);
  const { mutate: carrycotUpdate } = useUpdateTodo(
    formToken,
    `/carrycot_opportunity/update_all_costing`,
    "CarrycotOpportunities",
    [
      "OpportunityCotationLc",
      "Zones",
      "ProfilsCarrycotOpportunity",
      "CarrycotCosting",
    ]
  );

  const { mutate: movementUpdate } = useUpdateTodo(
    formToken,
    `/movement_opportunity/update_all_costing`,
    "MovementsOpportunity",
    [
      "MovementProfilsOpportunity",
      "VehicleMovementOprt",
      "OpportunityCotationLc",
      "Zones",
    ]
  );

  const { mutate: updateMaterialOpportunity } = useUpdateTodo(
    formToken,
    "/material_opportunity/update_all_costing",
    "MaterialsOpportunity",
    ["Zones", "OpportunityCotationLc"]
  );

  const { mutate: updateOuvrageOpportunity } = useUpdateTodo(
    formToken,
    "/ouvrage_opportunity/update_all_costing",
    "MaterialsOpportunity",
    ["OpportunityCotationLc", "Zones", "OuvragesOpportunity"]
  );
  const { data: opportunity_quotation_lc, isLoading } = useQuery(
    "OpportunityCotationLc",
    () => getData(formToken, `/opportunity_quotation_lc/${id_opportunity}`),
    {
      enabled: from !== "configuration",
      onSuccess: () => queryClient.invalidateQueries("Warnings"),
    }
  );
  const { mutate: updatePrestationOpportunity } = useUpdateTodo(
    formToken,
    "/prestation_opportunity/update_all_costing",
    "PrestationsOpportunity",
    ["Zones", "OpportunityCotationLc"]
  );

  const [margin_rate, setMargin_rate] = useState(null);
  const [selling_price, setSelling_price] = useState(null);
  const data = [
    {
      desable: true,
      key: "1",
      blank: "Total",
      hide: false,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.total_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: opportunity_quotation_lc?.total_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.total_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "2",
      blank: vocabulary.products,
      hide: opportunity_quotation_lc?.material_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.material_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: opportunity_quotation_lc?.material_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.material_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: updateMaterialOpportunity,
      selling_price_method: updateMaterialOpportunity,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "36",
      blank: "Ouvrages",
      hide: opportunity_quotation_lc?.ouvrage_cost_price_cents === 0,
      margin: opportunity_quotation_lc?.ouvrage_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.ouvrage_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.ouvrage_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin_method: updateOuvrageOpportunity,
      selling_price_method: updateOuvrageOpportunity,
    },
    {
      desable: true,
      key: "3",
      blank: "Coûts internes",
      hide: opportunity_quotation_lc?.internal_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.internal_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: opportunity_quotation_lc?.internal_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.internal_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "4",
      blank: "----- Prestations",
      className: "row_children_colored",
      hide:
        opportunity_quotation_lc?.prestation_internal_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.prestation_internal_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      subcontracting: false,
      is_package: false,
      margin: opportunity_quotation_lc?.prestation_internal_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.prestation_internal_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: updatePrestationOpportunity,
      selling_price_method: updatePrestationOpportunity,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "Prestations forfaits int",
      blank: "----- Prestations forfaits",
      className: "row_children_colored",
      hide: opportunity_quotation_lc?.presta_pack_int_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.presta_pack_int_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      subcontracting: false,
      is_package: true,
      margin: opportunity_quotation_lc?.presta_pack_int_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.presta_pack_int_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: updatePrestationOpportunity,
      selling_price_method: updatePrestationOpportunity,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "5",
      blank: "----- Déplacements",
      className: "row_children_colored",
      hide: opportunity_quotation_lc?.movement_internal_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.movement_internal_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: opportunity_quotation_lc?.movement_internal_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.movement_internal_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      subcontracting: false,
      margin_method: movementUpdate,
      selling_price_method: movementUpdate,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "6",
      blank: "----- Nacelles",
      className: "row_children_colored",
      hide: opportunity_quotation_lc?.carrycot_internal_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.carrycot_internal_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: opportunity_quotation_lc?.carrycot_internal_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.carrycot_internal_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      subcontracting: false,

      margin_method: carrycotUpdate,
      selling_price_method: carrycotUpdate,
    },
    {
      desable: true,
      key: "7",
      blank: "Coûts sous-traitance",
      hide:
        !configuration.general.subcontracting ||
        opportunity_quotation_lc?.subcontracting_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.subcontracting_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: opportunity_quotation_lc?.subcontracting_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.subcontracting_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "8",
      blank: " ----- Prestations",
      className: "row_children_colored",
      hide:
        opportunity_quotation_lc?.prestation_subcontracting_cost_price_cents ===
        0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.prestation_subcontracting_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      subcontracting: true,
      is_package: false,
      margin: opportunity_quotation_lc?.prestation_subcontracting_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.prestation_subcontracting_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: updatePrestationOpportunity,
      selling_price_method: updatePrestationOpportunity,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "Prestations forfaits sub",
      blank: " ----- Prestations forfaits",
      className: "row_children_colored",
      hide: opportunity_quotation_lc?.presta_pack_sub_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.presta_pack_sub_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      subcontracting: true,
      is_package: true,
      margin: opportunity_quotation_lc?.presta_pack_sub_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.presta_pack_sub_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: updatePrestationOpportunity,
      selling_price_method: updatePrestationOpportunity,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "9",
      blank: "----- Déplacements",
      className: "row_children_colored",
      hide:
        opportunity_quotation_lc?.movement_subcontracting_cost_price_cents ===
        0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.movement_subcontracting_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: opportunity_quotation_lc?.movement_subcontracting_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.movement_subcontracting_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      subcontracting: true,
      margin_method: movementUpdate,
      selling_price_method: movementUpdate,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "10",
      blank: "----- Nacelles",
      className: "row_children_colored",
      hide:
        opportunity_quotation_lc?.carrycot_subcontracting_cost_price_cents ===
        0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.carrycot_subcontracting_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: opportunity_quotation_lc?.carrycot_subcontracting_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.carrycot_subcontracting_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: carrycotUpdate,
      selling_price_method: carrycotUpdate,
      subcontracting: true,
    },
  ];
  const globalArray = (warnings) => [
    {
      title: "",
      dataIndex: "blank",
    },
    {
      title: "Coût de revient",
      dataIndex: "cost_price",
      hidden:
        responseType === "quotation" ||
        !configuration.costing.page_3.show_cost_price,
      width: "100px",
      align: "right",
    },
    getMarginCol({
      configuration,
      isUsingMargin,
      calculMethod,
      width: "80px",
      hidden: responseType === "quotation",
      hasSorter: false,
      render: (value, globalValue) => (
        <Input
          fontSize="10px"
          textAlign="right"
          disabled={globalValue.desable}
          size="small"
          isMarginRate
          id={globalValue.key}
          value={formatNumberString({
            str:
              margin_rate?.[globalValue.key] != null
                ? margin_rate?.[globalValue.key]
                : value,
            isCoeff: !isUsingMargin,
          })}
          onBlur={(e) => {
            if (margin_rate) {
              globalValue.margin_method(
                JSON.stringify({
                  opportunity_id: id_opportunity,
                  margin_rate: coeffToMargin({
                    calculMethod,
                    isUsingMargin,
                    coeff: stringToFloat(e.target.value),
                  }),
                  subcontracting: globalValue.subcontracting,
                  ...(typeof globalValue.is_package !== "undefined" && {
                    is_package: globalValue.is_package,
                  }),
                })
              );
            }
            setMargin_rate(null);
          }}
          onChange={(e) =>
            setMargin_rate((v) => ({
              ...v,
              [e.target.id]: e.target.value,
            }))
          }
        />
      ),
    }),

    {
      title: "Prix de vente HT",
      dataIndex: "selling_price",
      width: "100px",
      align: "right",
      render: (value, globalValue) =>
        // eslint-disable-next-line no-nested-ternary
        responseType === "quotation" ? (
          globalValue.key === "1" ? (
            <WarningLabel
              hasWarning={
                warnings &&
                warnings.some(({ rule }) => {
                  return rule.rule_on === "final_price";
                })
              }
            >
              {value} €
            </WarningLabel>
          ) : (
            <div>{value} €</div>
          )
        ) : (
          <Input
            fontSize="10px"
            textAlign="right"
            disabled={globalValue.desable}
            size="small"
            suffix="€"
            id={globalValue.key}
            value={
              selling_price?.[globalValue.key] != null
                ? selling_price?.[globalValue.key]
                : value
            }
            onBlur={(e) => {
              if (selling_price) {
                globalValue.selling_price_method(
                  JSON.stringify({
                    opportunity_id: id_opportunity,
                    selling_price_cents: eurosToCents(e.target.value),
                    subcontracting: globalValue.subcontracting,
                    ...(typeof globalValue.is_package !== "undefined" && {
                      is_package: globalValue.is_package,
                    }),
                  })
                );
              }
              setSelling_price(null);
            }}
            onChange={(e) =>
              setSelling_price((v) => ({
                ...v,
                [e.target.id]: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              }))
            }
            onFocus={(e) =>
              setSelling_price((v) => ({
                ...v,
                [e.target.id]: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              }))
            }
          />
        ),
    },
  ];
  return (
    <Container>
      <h4>{hasOption ? "GLOBALE (hors option)" : "GLOBALE"}</h4>
      <TableContainer
        columns={globalArray(
          queryClient.getQueryData([
            "Warnings",
            { opportunityId: parseInt(id_opportunity, 10) },
          ])
        )}
        data={from === "configuration" ? fakeGlobalData : data}
        pagination={false}
        coloredRowOfferPage={false}
        loading={!!isMutating || isLoading}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
`;

export default GlobalTable;
