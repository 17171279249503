import React from "react";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { Select } from "antd";
import { documentsDataIndex } from "../../Admin-cf/DocumentsFolders/Documents/DocumentsArray";
import FormCol from "../../Form/FormCol";
import Input from "../../react-ui/Input";
import CustomSelect from "../../react-ui/Select";

function FilterHeader({
  columnsToShow,
  setColumnsToShow,
  wordEntered,
  setWordEntered,
  isSearch,
  templatesOptions,
  onTemplateSelect,
  templateId,
  templateIsLoading,
  isTemplateConstructing,
}) {
  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  return (
    <Container>
      <Wrapper>
        {isSearch ? (
          <Search
            allowClear
            placeholder="Nom de fichier"
            value={wordEntered}
            onChange={handleFilter}
          />
        ) : (
          <FormCol label="Nom" name="name" rules={[{ required: true }]}>
            <Input />
          </FormCol>
        )}
      </Wrapper>
      <Wrapper>
        {isSearch ? (
          <StyledSelect
            mode="multiple"
            placeholder="Colonnes complémentaires"
            options={[
              { label: "Créé le", value: documentsDataIndex.created_at },
              {
                label: "Typologies",
                value: documentsDataIndex.site_typologies,
              },
              { label: "Dossiers", value: documentsDataIndex.folders },
            ]}
            value={columnsToShow}
            maxTagCount="responsive"
            allowClear
            onChange={(value) => {
              setColumnsToShow(value);
            }}
          />
        ) : (
          <StyledCustomSelect
            placeholder="Ajouter un modèle"
            options={templatesOptions}
            value={templateId}
            disabled={templateIsLoading || isTemplateConstructing}
            loading={templateIsLoading || isTemplateConstructing}
            onSelect={onTemplateSelect}
          />
        )}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin-bottom: 5px;
  gap: 5px;
`;

const Wrapper = styled.div`
  width: 50%;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;
`;

export default FilterHeader;
