import React from "react";
import { formatNumberString } from "../../../../../utils/formatNumberString";
import Input from "../../../../react-ui/Input";
import { useStore } from "../../../../store";

const selector = (state) => ({
  zoneOpenData: state.zoneOpenData,
  setZoneOpen: state.setZoneOpen,
  formToken: state.formToken,
  isUsingMinutes: state.isUsingMinutes,
});

const FinalQuantity = React.memo(({ value }) => {
  const { isUsingMinutes } = useStore(selector);

  const getAbbreviation = () => {
    if (value.minutes !== null) {
      return value.minutes ? "min" : "h";
    }
    return isUsingMinutes ? "min" : "h";
  };

  return "prestation_profession_id" in value && !value.is_package ? (
    <Input
      fontSize="10px"
      textAlign="right"
      value={formatNumberString({
        str: value.hour_quantity,
      })}
      suffix={getAbbreviation()}
      disabled
    />
  ) : null;
});

export default FinalQuantity;
