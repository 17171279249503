import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["status", "results_icon"]

  updateStatus() {
    let offerId = this.statusTarget.dataset.offerId;
      fetch(`/offers/${offerId}`, {
        method: "PATCH",
        headers: { "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
        },
        body: JSON.stringify({
          status: this.statusTarget.value
        })
      }).then(function(response) { return response.text() }).then(function(body) {
        eval(body)
        let result_status_change = this.results_iconTarget
        if(body === 'true') {
          result_status_change.innerHTML = "<i class='fas fa-check'></i>"
          setTimeout( () => { result_status_change.innerHTML = "" }, 2000)
          fetch('/offers', {
            method: "GET",
            headers: { "Content-Type": "application/javascript",
                       "Accept": "application/javascript",
                       "X-Requested-With": "XMLHttpRequest",
                     }
          }).then(function(response) { return response.text() }).then(function(body) {
            eval(body)
          });
          window.location.reload()
        } else {
          result_status_change.innerHTML = "<i class='fas fa-times'></i>"
        }
      
    }.bind(this));
  }
}