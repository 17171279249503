import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["previous"]

  allSelected() {
    let checkMonday = document.getElementById('plannings_work_period_day_type_monday')
    let checkTuesday = document.getElementById('plannings_work_period_day_type_tuesday')
    let checkWednesday = document.getElementById('plannings_work_period_day_type_wednesday')
    let checkThursday = document.getElementById('plannings_work_period_day_type_thursday')
    let checkFriday = document.getElementById('plannings_work_period_day_type_friday')
    let checkSaturday = document.getElementById('plannings_work_period_day_type_saturday')
    let checkSunday = document.getElementById('plannings_work_period_day_type_sunday')
    let checkWorkFreeDay = document.getElementById('plannings_work_period_day_type_work_free_day')
    checkMonday.checked =true
    checkTuesday.checked =true
    checkWednesday.checked =true
    checkThursday.checked =true
    checkFriday.checked =true
    checkSaturday.checked =true
    checkSunday.checked =true
    checkWorkFreeDay.checked =true
  }

  allUnselected() {
    let checkMonday = document.getElementById('plannings_work_period_day_type_monday')
    let checkTuesday = document.getElementById('plannings_work_period_day_type_tuesday')
    let checkWednesday = document.getElementById('plannings_work_period_day_type_wednesday')
    let checkThursday = document.getElementById('plannings_work_period_day_type_thursday')
    let checkFriday = document.getElementById('plannings_work_period_day_type_friday')
    let checkSaturday = document.getElementById('plannings_work_period_day_type_saturday')
    let checkSunday = document.getElementById('plannings_work_period_day_type_sunday')
    let checkWorkFreeDay = document.getElementById('plannings_work_period_day_type_work_free_day')
    checkMonday.checked =false
    checkTuesday.checked =false
    checkWednesday.checked =false
    checkThursday.checked =false
    checkFriday.checked =false
    checkSaturday.checked =false
    checkSunday.checked =false
    checkWorkFreeDay.checked =false
  }
}
