import React, { useEffect, useState } from "react";
import { Space } from "antd";
import SwitchComponent from "../SwitchComponent";
import { StyledCard, StyledSpace } from "../styledComponent";

export default function AdministrationSection({
  general,
  products,
  prestations,
  profils,
  categories,
  clients,
  setProducts,
  setPrestations,
  setProfils,
  setCategories,
  setClients,
  setConfiguration,
  configuration,
}) {
  const handleSaveNewValue = (value, name, type) => {
    switch (type) {
      case "products":
        setProducts({ ...products, [name]: value });
        break;
      case "prestations":
        setPrestations({ ...prestations, [name]: value });
        break;
      case "profils":
        setProfils({ ...profils, [name]: value });
        break;
      case "categories":
        setCategories({ ...categories, [name]: value });
        break;
      case "clients":
        setClients({ ...clients, [name]: value });
        break;
      default:
        break;
    }
  };

  const [productsItems, setProductsItems] = useState([
    {
      initialValue:
        general.pageSelectionItems || general.prixVente
          ? products.marge
          : false,
      isDisabled: !general.pageSelectionItems && !general.prixVente,
      labelText: "Gérer la marge",
      name: "marge",
    },
    {
      initialValue:
        general.pageSelectionItems || general.prixVente
          ? products.colonneMarge
          : false,
      isDisabled: !general.pageSelectionItems || !general.prixVente,
      labelText: "Colonne marge",
      name: "colonneMarge",
    },
    {
      initialValue: general.pageSelectionItems && products.dateValidite,
      isDisabled: !general.pageSelectionItems,
      labelText: "Date de validité",
      name: "dateValidite",
    },
    {
      initialValue: general.pageSelectionItems && products.associerProduits,
      isDisabled: !general.pageSelectionItems,
      labelText: "Associer des produits",
      name: "associerProduits",
    },
  ]);

  const [prestationsItems, setPrestationsItems] = useState([
    {
      initialValue:
        general.pageSelectionItems || general.prixVente
          ? prestations.margePrestation
          : false,
      isDisabled: !general.pageSelectionItems || !general.prixVente,
      labelText: "Gérer la marge",
      name: "margePrestation",
    },
    {
      initialValue: general.pageSelectionItems && prestations.description,
      isDisabled: !general.pageSelectionItems,
      labelText: "Description",
      name: "description",
    },
    {
      initialValue: general.pageSelectionItems && prestations.speed,
      isDisabled: !general.pageSelectionItems,
      labelText: "Gérer la vitesse",
      name: "speed",
    },
    {
      initialValue:
        general.pageSelectionItems || general.accordCadre
          ? prestations.majoration
          : false,
      isDisabled: !general.pageSelectionItems || !general.accordCadre,
      labelText: "Majoration",
      name: "majoration",
      info: "Majoration pour les accords cadre. Cela ne concerne pas la majoration pour les chiffrages de consultation",
    },
  ]);

  const [profilsItems, setProfilsItems] = useState([
    {
      initialValue:
        general.profils && general.prixVente ? profils.margeProfil : false,
      isDisabled: !general.profils || !general.prixVente,
      labelText: "Gérer la marge",
      name: "margeProfil",
    },
  ]);

  const [categoriesItems, setCategoriesItems] = useState([
    {
      initialValue: general.prixVente ? categories.margeCategorie : false,
      isDisabled: !general.prixVente,
      labelText: "Gérer la marge",
      name: "margeCategorie",
    },
  ]);

  const [clientsItems, setClientsItems] = useState([
    {
      initialValue: clients.activiteRequise,
      labelText: "Activité requise",
      name: "activite_requise",
    },
  ]);

  useEffect(() => {
    setConfiguration({
      ...configuration,
      admin: {
        ...configuration.admin,
        product_tab: {
          ...configuration.admin.product_tab,
          form: {
            ...configuration.admin.product_tab.form,
            input_margin_rate: products.marge,
            input_date_validity: products.dateValidite,
            product_associate: {
              ...configuration.admin.product_tab.product_associate,
              input_linked: products.associerProduits,
            },
          },
          table_marge_coeff: products.colonneMarge,
          prestation_associate_table: {
            ...configuration.admin.product_tab.prestation_associate_table,
            speed_quantity: prestations.speed,
          },
        },
        prestation_tab: {
          ...configuration.admin.prestation_tab,
          form: {
            ...configuration.admin.prestation_tab.form,
            input_margin_rate: prestations.margePrestation,
            input_description: prestations.description,
            input_speed: prestations.speed,
            input_majoration: prestations.majoration,
          },
        },
        profil_tab: {
          ...configuration.admin.profil_tab,
          form: {
            ...configuration.admin.profil_tab.form,
            input_margin_rate: profils.margeProfil,
          },
        },
        categories_tab: {
          ...configuration.admin.categories_tab,
          form: {
            ...configuration.admin.categories_tab.form,
            input_margin_rate: categories.margeCategorie,
          },
        },
      },
      clients: {
        ...configuration.clients,
        required_activity: clients.activiteRequise,
      },
    });
  }, [products, prestations, profils, categories, clients]);

  useEffect(() => {
    setProductsItems([
      {
        initialValue:
          general.pageSelectionItems || general.prixVente
            ? products.marge
            : false,
        isDisabled: !general.pageSelectionItems || !general.prixVente,
        labelText: "Gérer la marge",
        name: "marge",
      },
      {
        initialValue:
          general.pageSelectionItems || general.prixVente
            ? products.colonneMarge
            : false,
        isDisabled: !general.pageSelectionItems || !general.prixVente,
        labelText: "Colonne marge",
        name: "colonneMarge",
      },
      {
        initialValue: general.pageSelectionItems && products.dateValidite,
        isDisabled: !general.pageSelectionItems,
        labelText: "Date de validité",
        name: "dateValidite",
      },
      {
        initialValue: general.pageSelectionItems && products.associerProduits,
        isDisabled: !general.pageSelectionItems,
        labelText: "Associer des produits",
        name: "associerProduits",
      },
    ]);

    setPrestationsItems([
      {
        initialValue:
          general.pageSelectionItems && general.prixVente
            ? prestations.margePrestation
            : false,
        isDisabled: !general.pageSelectionItems || !general.prixVente,
        labelText: "Gérer la marge",
        name: "margePrestation",
      },
      {
        initialValue: general.pageSelectionItems && prestations.description,
        isDisabled: !general.pageSelectionItems,
        labelText: "Description",
        name: "description",
      },
      {
        initialValue: general.pageSelectionItems && prestations.speed,
        isDisabled: !general.pageSelectionItems,
        labelText: "Gérer la vitesse",
        name: "speed",
      },
      {
        initialValue:
          general.pageSelectionItems || general.accordCadre
            ? prestations.majoration
            : false,
        isDisabled: !general.pageSelectionItems || !general.accordCadre,
        labelText: "Majoration",
        name: "majoration",
        info: "Majoration pour les accords cadre. Cela ne concerne pas la majoration pour les chiffrages de consultation",
      },
    ]);

    setProfilsItems([
      {
        initialValue:
          general.profils && general.prixVente ? profils.margeProfil : false,
        isDisabled: !general.profils || !general.prixVente,
        labelText: "Gérer la marge",
        name: "margeProfil",
      },
    ]);

    setCategoriesItems([
      {
        initialValue: general.prixVente ? categories.margeCategorie : false,
        isDisabled: !general.prixVente,
        labelText: "Gérer la marge",
        name: "margeCategorie",
      },
    ]);

    setClientsItems([
      {
        initialValue: clients.activiteRequise,
        labelText: "Activité requise",
        name: "activiteRequise",
      },
    ]);
  }, [general]);

  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      {/* Products Section */}
      <Space
        direction="vertical"
        size={16}
        style={{
          width: "100%",
        }}
      >
        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Produits"
          style={{
            width: "100%",
          }}
        >
          <Space
            direction="horizontal"
            size={16}
            style={{
              width: "100%",
            }}
          >
            {productsItems.map((item) => (
              <SwitchComponent
                key={item.name}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  handleSaveNewValue(value, name, "products");
                }}
                name={item.name}
                isDisabled={item?.isDisabled}
              />
            ))}
          </Space>
        </StyledCard>
      </Space>

      {/* Prestation Section */}
      <Space
        direction="vertical"
        size={16}
        style={{
          width: "100%",
        }}
      >
        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Prestations"
          style={{
            width: "100%",
          }}
        >
          <Space
            direction="horizontal"
            size={16}
            style={{
              width: "100%",
            }}
          >
            {prestationsItems.map((item) => (
              <SwitchComponent
                key={item.name}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  handleSaveNewValue(value, name, "prestations");
                }}
                name={item.name}
                info={item.info}
                isDisabled={item?.isDisabled}
              />
            ))}
          </Space>
        </StyledCard>
      </Space>

      {/* Profils X Categories Section */}
      <StyledSpace
        direction="horizontal"
        size="large"
        style={{
          width: "100%",
          display: "flex",
          flexBasis: "50%",
        }}
      >
        {/* Profils Section */}
        <Space
          direction="vertical"
          size={16}
          style={{
            width: "100%",
          }}
        >
          <StyledCard
            headStyle={{ backgroundColor: "#0B2239", color: "white" }}
            bodyStyle={{ backgroundColor: "#F5F6F8" }}
            size="small"
            title="Profils"
            style={{
              width: "100%",
            }}
          >
            <Space
              direction="horizontal"
              size={16}
              style={{
                width: "100%",
              }}
            >
              {profilsItems.map((item) => (
                <SwitchComponent
                  key={item.name}
                  initialValue={item.initialValue}
                  labelText={item.labelText}
                  handleChange={(value, name) => {
                    handleSaveNewValue(value, name, "profils");
                  }}
                  name={item.name}
                  isDisabled={item?.isDisabled}
                  info={item?.info}
                />
              ))}
            </Space>
          </StyledCard>
        </Space>

        {/* Categories Section */}
        <Space
          direction="vertical"
          size={16}
          style={{
            width: "100%",
          }}
        >
          <StyledCard
            headStyle={{ backgroundColor: "#0B2239", color: "white" }}
            bodyStyle={{ backgroundColor: "#F5F6F8" }}
            size="small"
            title="Catégories"
            style={{
              width: "100%",
            }}
          >
            <Space
              direction="horizontal"
              size={16}
              style={{
                width: "100%",
              }}
            >
              {categoriesItems.map((item) => (
                <SwitchComponent
                  key={item.name}
                  isDisabled={item.isDisabled}
                  initialValue={item.initialValue}
                  labelText={item.labelText}
                  handleChange={(value, name) => {
                    handleSaveNewValue(value, name, "categories");
                  }}
                  name={item.name}
                />
              ))}
            </Space>
          </StyledCard>
        </Space>
      </StyledSpace>

      {/* Clients Section */}
      <Space
        direction="vertical"
        size={16}
        style={{
          width: "100%",
        }}
      >
        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Clients"
          style={{
            width: "100%",
          }}
        >
          <Space
            direction="horizontal"
            size={16}
            style={{
              width: "100%",
            }}
          >
            {clientsItems.map((item) => (
              <SwitchComponent
                key={item.name}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  handleSaveNewValue(value, name, "clients");
                }}
                name={item.name}
              />
            ))}
          </Space>
        </StyledCard>
      </Space>
    </Space>
  );
}
