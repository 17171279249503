import React, { useEffect, useState } from "react";
import { Alert, Segmented } from "antd";
import styled from "styled-components";
import SegmentLabel from "../../react-ui/SegmentLabel";
import ChildOuvrageArray from "./DetailsTabs/ChildOuvrageArray";
import ChildMaterialArray from "./DetailsTabs/ChildMaterialArray";
import ChildPrestationArray from "./DetailsTabs/ChildPrestationArray";
import OuvrageForm from "./OuvrageForm";

const options = (configuration, selectedSegment, tabs) =>
  [
    {
      value: "material_associates",
      label: (
        <SegmentLabel isSelected={selectedSegment === "material_associates"}>
          <i className={configuration.general.product_icon} /> associés
        </SegmentLabel>
      ),
    },
    {
      value: "prestation_associates",
      label: (
        <SegmentLabel isSelected={selectedSegment === "prestation_associates"}>
          <i className={configuration.general.prestation_icon} /> associées
        </SegmentLabel>
      ),
    },
    {
      value: "child_ouvrage",
      label: (
        <SegmentLabel isSelected={selectedSegment === "child_ouvrage"}>
          <i className={configuration.general.ouvrage_icon} /> enfant
        </SegmentLabel>
      ),
    },
    {
      value: "edit_ouvrage",
      label: (
        <SegmentLabel isSelected={selectedSegment === "edit_ouvrage"}>
          <i className={configuration.general.ouvrage_icon} /> edit
        </SegmentLabel>
      ),
    },
  ].filter((tab) => tabs.includes(tab.value));

function OuvrageModify({
  configuration,
  token,
  ouvrageSelected,
  ouvrageChildId,
  vocabulary,
  entityWorkId,
  faId,
  faIsvalidate,
  unities,
  opportunityId = null,
  from,
  ouvrageDetailsStatus,
  setOuvrageDetailsStatus,
  isUsingMargin,
  categories,
  calculMethod,
  faIsValidate,
  initialOuvrage,
  onSubmit,
  group,
  setOuvrageChildId,
  setOuvrageDynamicLabel,
  round_type_list,
}) {
  const [selectedSegment, setSelectedSegment] = useState("edit_ouvrage");
  const [wordEntered, setWordEntered] = useState("");
  const [impactedModelZone, setImpactedModelZone] = useState([]);

  useEffect(() => {
    setImpactedModelZone([]);
  }, [ouvrageSelected]);
  return (
    <Container>
      {impactedModelZone?.length > 0 && (
        <AlertContainer>
          <Alert
            showIcon
            message={
              <>
                Les modèles de zones suivants ne prennent pas en compte cette
                modification.
                <br />
                Vous devez modifier les associations directement depuis ces
                modèles.
                <br />
                <ul style={{ marginBottom: "0px" }}>
                  {impactedModelZone.map((zone) => (
                    <li key={zone.id}>{zone}</li>
                  ))}
                </ul>
              </>
            }
            type="warning"
            closable
          />
        </AlertContainer>
      )}
      <Segmented
        options={options(configuration, selectedSegment, [
          "child_ouvrage",
          "material_associates",
          "prestation_associates",
          "edit_ouvrage",
        ])}
        onChange={(e) => {
          //   if (setItem) setItem();
          setSelectedSegment(e);
        }}
        value={selectedSegment}
      />
      {selectedSegment === "child_ouvrage" && (
        <ChildOuvrageArray
          token={token}
          ouvrageParentId={ouvrageChildId}
          ouvrageSelected={ouvrageSelected}
          configuration={configuration}
          vocabulary={vocabulary}
          setWordEntered={setWordEntered}
          wordEntered={wordEntered}
          unities={unities}
          entityWorkId={entityWorkId}
          opportunityId={opportunityId}
          group={group}
          setImpactedModelZone={setImpactedModelZone}
        />
      )}
      {selectedSegment === "material_associates" && (
        <ChildMaterialArray
          vocabulary={vocabulary}
          configuration={configuration}
          entityWorkId={entityWorkId}
          formToken={token}
          ouvrageSelected={ouvrageSelected}
          ouvrageChildId={ouvrageChildId}
          opportunityId={opportunityId}
          from={from}
          setImpactedModelZone={setImpactedModelZone}
          round_type_list={round_type_list}
        />
      )}
      {selectedSegment === "prestation_associates" && (
        <ChildPrestationArray
          entityWorkId={entityWorkId}
          vocabulary={vocabulary}
          configuration={configuration}
          formToken={token}
          faId={faId}
          faIsValidate={faIsvalidate}
          ouvrageChildId={ouvrageChildId}
          ouvrageSelected={ouvrageSelected}
          unities={unities}
          opportunityId={opportunityId}
          from={from}
          setImpactedModelZone={setImpactedModelZone}
        />
      )}
      {selectedSegment === "edit_ouvrage" && (
        <OuvrageForm
          ouvrageDetailsStatus={ouvrageDetailsStatus}
          setOuvrageDetailsStatus={setOuvrageDetailsStatus}
          isUsingMargin={isUsingMargin}
          onSubmit={onSubmit}
          unities={unities}
          initialOuvrage={initialOuvrage()}
          updateOnChange
          configuration={configuration}
          vocabulary={vocabulary}
          calculMethod={calculMethod}
          faIsValidate={faIsValidate}
          categories={categories}
          id="ouvrage_knot_form"
          group={group}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const AlertContainer = styled.div`
  margin: 10px;
`;

export default OuvrageModify;
