import React from "react";
import styled from "styled-components";

function DuplicateDisable({
  className = "",
  $fontSize = "12px",
  ...iconProps
}) {
  return (
    <StyledIcon
      className={`fa-duotone fa-copy fa-dark-grey fa-1xhalf ${className}`}
      $fontSize={$fontSize}
      {...iconProps}
    />
  );
}

const StyledIcon = styled.i`
  font-size: ${({ $fontSize }) => $fontSize};
  :hover {
    transform: initial !important;
    cursor: not-allowed;
  }
`;

export default DuplicateDisable;
