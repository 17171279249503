import React, { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { useStore } from "../../store";
import { Loading } from "../../loading";

import { postData } from "../../request/instance";
import CategoryForm from "./CategoryForm";
import { getUrlParams } from "../../utils";
import TitleContainer from "../../react-ui/TitleContainer";
import Button from "../../react-ui/Button";
import AddButton from "../../react-ui/AddButton";
import { handleMarginKey } from "../../../utils/createFormData";
import DetailTabs from "../DetailTabs";

const selector = (state) => ({
  selectedCategory: state.selectedCategory,
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
  editCategory: state.editCategory,
});
function CategoryDetails({
  categories,
  categoryDetailsStatus,
  setCategoryDetailsStatus,
  entity_work_name,
  configuration,
  tva_professions,
  use_special_tva,
}) {
  const queryClient = useQueryClient();
  const {
    selectedCategory,
    formToken,
    isUsingMargin,
    calculMethod,
    editCategory,
  } = useStore(selector);

  const entityWorkId = getUrlParams();

  const { mutate: updateCategory, status } = useMutation(
    (todo) => postData(formToken, "/category_profession/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Categories");
        queryClient.invalidateQueries("TvaSelector");
      },
    }
  );

  const { mutate: createcategory, isLoading } = useMutation(
    (todo) => postData(formToken, "/category_profession/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Categories");
      },
      onSuccess: (payload) => {
        editCategory(payload);
        setCategoryDetailsStatus("update");
      },
      onError: () => setCategoryDetailsStatus("empty"),
    }
  );

  const handleRegisterCategoryProfession = (e) => {
    const formatableKeys = ["margin_rate", "parent_category_id"];
    const formData = new FormData();
    Object.keys(e).forEach(
      (key) =>
        !formatableKeys.includes(key) &&
        formData.append(
          `category_profession[${key}]`,
          e[key] !== undefined && e[key] !== null ? e[key] : ""
        )
    );
    if (use_special_tva) {
      if (e?.tva_profession_id) {
        formData.append("tva_profession_id", e.tva_profession_id);
      }
      if (e?.tva_profession_id === undefined) {
        formData.append("tva_profession_id", "none");
      }
    }

    handleMarginKey({
      formData,
      margin_rate: e.margin_rate,
      keyName: "category_profession",
      isUsingMargin,
      calculMethod,
    });
    if (
      e.parent_category_id &&
      e.parent_category_id[e.parent_category_id.length - 1] &&
      e.parent_category_id[e.parent_category_id.length - 1] !== -1
    )
      formData.append(
        "category_profession[parent_category_id]",
        e.parent_category_id[e.parent_category_id.length - 1]
      );

    if (
      e.parent_category_id &&
      e.parent_category_id[e.parent_category_id.length - 1] === -1
    )
      formData.append("delete_parent_category", true);
    formData.append("entity_work_id", getUrlParams());
    if (categoryDetailsStatus === "update") {
      formData.append("id", selectedCategory.id);
      updateCategory(formData);
    }
    if (categoryDetailsStatus === "create") {
      createcategory(formData);
    }
  };

  const onSubmit = (e) => {
    handleRegisterCategoryProfession(e);
  };

  const getName = () => {
    if (categoryDetailsStatus !== "update") return "";
    const curItem = categories.find((el) => el.id === selectedCategory.id);
    if (!curItem?.title) return "";
    return ` : ${curItem.title}`;
  };

  const setItem = useCallback(() => {
    editCategory(categories?.find((el) => el.id === selectedCategory?.id));
  }, [categories, selectedCategory?.id, editCategory]);

  return (
    <Container>
      <TitleContainer label={`Détails de la catégorie${getName()}`}>
        {categoryDetailsStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={() => setCategoryDetailsStatus("empty")}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="category-form"
              loading={isLoading}
            />
          </>
        )}
        <Loading status={status} />
      </TitleContainer>
      {categoryDetailsStatus === "empty" && (
        <div className="d-flex justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez une catégorie pour afficher les détails
          </p>
        </div>
      )}
      {categoryDetailsStatus !== "empty" && (
        <DetailTabs
          configuration={configuration}
          detailsStatus={categoryDetailsStatus}
          tabs={["family_associates"]}
          categories={categories}
          entityWorkId={entityWorkId}
          parent="category"
          setItem={setItem}
        >
          <CategoryForm
            use_special_tva={use_special_tva}
            tva_professions={tva_professions}
            updateOnChange={categoryDetailsStatus === "update"}
            initialCategory={
              categoryDetailsStatus === "update" ? selectedCategory : undefined
            }
            categories={categories}
            onSubmit={onSubmit}
            entity_work_name={entity_work_name}
            configuration={configuration}
          />
        </DetailTabs>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default CategoryDetails;
