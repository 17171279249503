import React, { useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import GroupingsListIndex from "./GroupingsList/GroupingsListIndex";
import Separator from "../../react-ui/Separator";
import GroupingDetailsIndex from "./GroupingDetails/GroupingDetailsIndex";
import { getData, postData } from "../../request/instance";
import TitleContainer from "../../react-ui/TitleContainer";
import AddButton from "../../react-ui/AddButton";

function Groupings({ token, company_work_id, configuration, vocabulary }) {
  const [selectedGrouping, setSelectedGrouping] = useState(null);
  const [detailsStatus, setDetailsStatus] = useState("empty");
  const queryClient = useQueryClient();
  const { data: groupings } = useQuery(
    "Groupings",
    () => getData(token, `/groupings/${company_work_id}`),
    { refetchOnWindowFocus: false }
  );

  const { mutate: deleteGrouping } = useMutation(
    (todo) => postData(token, "/grouping/delete", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Groupings");
        queryClient.invalidateQueries("Prestations");
        queryClient.invalidateQueries("Materials");
      },
    }
  );

  const handleDeleteGrouping = (id) => {
    deleteGrouping({ id });
    setDetailsStatus("empty");
  };
  const handleSelectGrouping = (grouping) => {
    setSelectedGrouping(grouping);
    setDetailsStatus("update");
  };

  const openCreation = () => {
    setDetailsStatus("create");
  };

  return (
    <Container>
      <div style={{ width: "49%" }}>
        <TitleContainer label={`${vocabulary.groupings} disponibles`}>
          <AddButton
            onClick={openCreation}
            // disabled={detailsStatus === "create"}
            label={`${vocabulary.groupings}`}
          />
        </TitleContainer>
        <GroupingsListIndex
          handleDeleteGrouping={handleDeleteGrouping}
          groupings={groupings}
          handleSelectGrouping={handleSelectGrouping}
          configuration={configuration}
          selectedGrouping={selectedGrouping}
        />
      </div>

      <Separator />
      <GroupingDetailsIndex
        setSelectedGrouping={setSelectedGrouping}
        configuration={configuration}
        detailsStatus={detailsStatus}
        selectedGrouping={selectedGrouping}
        setDetailsStatus={setDetailsStatus}
        token={token}
        company_work_id={company_work_id}
        vocabulary={vocabulary}
      />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
  width: 100%;
`;

export default Groupings;
