import { Modal } from "antd";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { centsToEuros } from "../../../../utils/currencyConverter";
import { formatNumberString } from "../../../../utils/formatNumberString";
import CarrycotForm from "../../../Admin-cf/Carrycots/CarrycotForm";
import { getData, postData } from "../../../request/instance";
import { currency_euros_to_cents } from "../../../utils";
import CarrycotTable from "./CarrycotTable";
import { handleMarginKey } from "../../../../utils/createFormData";
import { useStore } from "../../../store";
import {
  carrycotData,
  carrycotDataProfil,
  carrycotFakeDataOpportunity,
} from "../../../data-utils/page2-data";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

function CarrycotSection({
  formToken,
  id_opportunity,
  zones,
  entity_work_id,
  faId,
  from,
}) {
  const { isUsingMargin, calculMethod } = useStore(selector);
  const queryClient = useQueryClient();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data: carrycots } = useQuery(
    "Carrycots",
    () => getData(formToken, `/carrycot_tpl_opportunity/${id_opportunity}`),
    {
      enabled: from !== "configuration",
    }
  );

  const { data: profils } = useQuery(
    "Profils",
    () => getData(formToken, `/profil_opportunity/${id_opportunity}`),
    {
      enabled: from !== "configuration",
    }
  );

  const { data: opportunities } = useQuery(
    "CarrycotOpportunities",
    async () => {
      const data = await getData(
        formToken,
        `/carrycot_opportunity/${id_opportunity}`
      );
      return data.map((el) => {
        return {
          ...el,
          duration: el.duration
            ? formatNumberString({
                str: el.duration,
                nbDecimal: 2,
                space: true,
              })
            : null,
          daily_cost_cents: el.daily_cost_cents
            ? formatNumberString({
                str: centsToEuros(el.daily_cost_cents),
                nbDecimal: 2,
                space: true,
              })
            : null,
          movement_cost_cents: el.movement_cost_cents
            ? formatNumberString({
                str: centsToEuros(el.movement_cost_cents),
                nbDecimal: 2,
                space: true,
              })
            : null,
          day_quantity: el.day_quantity
            ? formatNumberString({
                str: el.day_quantity,
                nbDecimal: 2,
                space: true,
              })
            : null,
          roundtrip_quantity: el.roundtrip_quantity
            ? formatNumberString({
                str: el.roundtrip_quantity,
                nbDecimal: 2,
                space: true,
              })
            : null,
        };
      });
    },
    {
      enabled: from !== "configuration",
    }
  );

  const { mutate: createCarrycot } = useMutation(
    (todo) => postData(formToken, "/carrycot_tpl_opportunity/create", todo),
    {
      enabled: from !== "configuration",
      onSettled: () => {
        queryClient.invalidateQueries("Carrycots");
      },
    }
  );

  const { mutate: createOpportunityMutation } = useMutation(
    (todo) => postData(formToken, "/carrycot_opportunity/create", todo),
    {
      enabled: from !== "configuration",
      onSuccess: () => {
        queryClient.invalidateQueries("CarrycotOpportunities");
      },
      onError: () => queryClient.resetQueries("CarrycotOpportunities"),
    }
  );

  const { mutate: deleteOpportunityMutation } = useMutation(
    (todo) => postData(formToken, "/carrycot_opportunity/delete", todo),
    {
      enabled: from !== "configuration",
      onSuccess: () => {
        queryClient.invalidateQueries("CarrycotOpportunities");
      },
      onError: () => queryClient.resetQueries("CarrycotOpportunities"),
    }
  );

  const { mutate: updateOpportunityMutation } = useMutation(
    (todo) => postData(formToken, "/carrycot_opportunity/update", todo),
    {
      enabled: from !== "configuration",
      onSuccess: () => {
        queryClient.invalidateQueries("CarrycotOpportunities");
      },
      onError: () => queryClient.resetQueries("CarrycotOpportunities"),
    }
  );

  const createOpportunity = (carrycotProperties) => {
    createOpportunityMutation(
      JSON.stringify({
        opportunity_id: id_opportunity,
        ...carrycotProperties,
      })
    );
  };

  const deleteOpportunity = (id) =>
    deleteOpportunityMutation(
      JSON.stringify({
        id,
        opportunity_id: id_opportunity,
      })
    );

  const updateOpportunity = (newOpportunity) => {
    updateOpportunityMutation(
      JSON.stringify({
        ...newOpportunity,
        not_from_costing: true,
      })
    );
  };

  const onSubmit = (e) => {
    const formatableKeys = [
      "daily_cost_cents",
      "movement_cost_cents",
      "cost_price_cents",
      "margin_rate",
    ];
    const formData = new FormData();
    Object.keys(e).forEach(
      (key) =>
        !formatableKeys.includes(key) &&
        formData.append(
          `carrycot_tpl_opportunity[${key}]`,
          e[key] !== undefined && e[key] !== null ? e[key] : ""
        )
    );
    if (e.daily_cost_cents) {
      formData.append(
        "carrycot_tpl_opportunity[daily_cost_cents]",
        currency_euros_to_cents(e.daily_cost_cents)
      );
    }
    if (e.movement_cost_cents) {
      formData.append(
        "carrycot_tpl_opportunity[movement_cost_cents]",
        currency_euros_to_cents(e.movement_cost_cents)
      );
    }
    if (e.cost_price_cents) {
      formData.append(
        "carrycot_tpl_opportunity[cost_price_cents]",
        currency_euros_to_cents(e.cost_price_cents)
      );
    }
    handleMarginKey({
      formData,
      margin_rate: e.margin_rate,
      keyName: "carrycot_tpl_opportunity",
      isUsingMargin,
      calculMethod,
    });
    formData.append("carrycot_tpl_opportunity[entity_work_id]", entity_work_id);
    formData.append("carrycot_tpl_opportunity[opportunity_id]", id_opportunity);
    createCarrycot(formData);
    setIsModalVisible(false);
  };

  return (
    <>
      <Modal
        width={840}
        open={isModalVisible}
        maskClosable={false}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
      >
        <CarrycotForm
          submit={{ onCancel: () => setIsModalVisible(false) }}
          onSubmit={onSubmit}
          profils={profils}
          profilKey="profil_opportunity_id"
          isPackage={!!faId}
          opportunityId={id_opportunity}
          entityWorkId={entity_work_id}
        />
      </Modal>
      <CarrycotTable
        opportunities={from === "configuration" ? carrycotData : opportunities}
        carrycots={
          from === "configuration" ? carrycotFakeDataOpportunity : carrycots
        }
        zones={zones}
        profils={from === "configuration" ? carrycotDataProfil : profils}
        createOpportunity={createOpportunity}
        deleteOpportunity={deleteOpportunity}
        updateOpportunity={updateOpportunity}
        setIsModalVisible={setIsModalVisible}
        faId={faId}
        from={from}
      />
    </>
  );
}

export default CarrycotSection;
