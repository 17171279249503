import React, { useEffect, useState } from "react";
import Search from "antd/lib/input/Search";
import styled from "styled-components";
import { Checkbox } from "antd";
import { useMutation } from "react-query";
import { useStore } from "../../store";
import LazyOuvrageArray from "../Ouvrages/OuvrageStructure/LazyOuvrageArray";
import { translate_filter } from "../Categories/translate_data";
import { postData } from "../../request/instance";
import Input from "../../react-ui/Input";
import { formatNumberString } from "../../../utils/formatNumberString";
import { stringToFloat } from "../../utils";
import { numberSorter } from "../../../utils/numberSorter";
import {
  if_record_exist,
  add_record,
  add_data_in_record,
} from "../Ouvrages/OuvrageUtils/CheckedLogic";
import FormattedInput from "../../react-ui/FormattedInput";

const selector = (state) => ({
  selectedMateriel: state.selectedMateriel,
  selectedFamily: state.selectedFamily,
});

function OuvrageAssociate({
  entityWorkId,
  categories,
  fromToken,
  parent,
  setImpactedModelZone,
}) {
  const { selectedMateriel, selectedFamily } = useStore(selector);
  const [ouvrages, setOuvrages] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [categoryFiltered, setCategoryFiltered] = useState();
  const [ouvrageChecked, setOuvrageChecked] = useState([]);
  const [ouvrageAssociateData, setOuvrageAssociateData] = useState([]);

  useEffect(() => {
    setOuvrageAssociateData([]);
    setOuvrageChecked([]);
  }, [selectedFamily, selectedMateriel]);
  const { mutate: delete_ouvrage_associated } = useMutation(
    (todo) => postData(fromToken, "/ouvrage_material_associate/delete", todo),
    {
      onSuccess: (data) => {
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  const { mutate: delete_ouvrage_associated_family } = useMutation(
    (todo) =>
      postData(fromToken, "/parent_child_ouvrage_professions/delete", todo),
    {
      onSuccess: (payload) => {
        console.log(payload);
      },
    }
  );

  const { mutate: add_ouvrage_associated } = useMutation(
    (todo) => postData(fromToken, "/ouvrage_material_associate/create", todo),
    {
      onSuccess: (data) => {
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );
  const { mutate: add_ouvrage_in_family } = useMutation(
    (todo) => postData(fromToken, "/parent_child_ouvrage_professions", todo),
    {
      onSuccess: (payload) => {
        console.log(payload);
      },
    }
  );

  const { mutate: update_ouvrage_associated_family } = useMutation(
    (todo) =>
      postData(fromToken, "/parent_child_ouvrage_professions/update", todo),
    {
      onSuccess: (data) => {
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  const { mutate: update_ouvrage_associated } = useMutation(
    (todo) => postData(fromToken, "/ouvrage_material_associate/update", todo),
    {
      onSuccess: (data) => {
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const handleAddAssociateOuvrage = (checked, record) => {
    if (checked) {
      if (parent === "family") {
        add_ouvrage_in_family({
          quantity: 1,
          ouvrage_profession_id: record.id,
          family_profession_id: selectedFamily?.id,
        });
      } else
        add_ouvrage_associated({
          quantity: 1,
          ...{
            ouvrage_profession_id: record.id,
            material_profession_id: selectedMateriel.id,
          },
        });
    } else if (parent === "family")
      delete_ouvrage_associated_family({
        ouvrage_profession_id: record.id,
        family_profession_id: selectedFamily.id,
      });
    else
      delete_ouvrage_associated({
        ouvrage_profession_id: record.id,
        material_profession_id: selectedMateriel.id,
      });
  };

  const displayInputQuantity = (record) => {
    return ouvrageChecked.some((item) => item.id === record.id)
      ? ouvrageChecked.find((item) => item.id === record.id).check
      : record.associated;
  };

  const OuvragProfessionsColumns = [
    {
      title: "Intitulé",
      dataIndex: "name",
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      filters: translate_filter(categories),
      filterMode: "tree",
      filterSearch: true,
      sorter: true,
      defaultFilteredValue: categoryFiltered || [],
      onFilter: () => {
        return true;
      },
      filterMultiple: true,
      width: "20%",
      render: (_, record) => <div>{record.category_profession.title}</div>,
    },
    {
      title: "Associer",
      dataIndex: "associated",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        numberSorter(a.associated, b.associated) || numberSorter(a.id, b.id),
      width: "65px",
      render: (_, record) => (
        <CenteredContainer>
          <Checkbox
            onChange={({ target: { checked } }) => {
              handleAddAssociateOuvrage(checked, record);
              if (!ouvrageChecked.some((item) => item.id === record.id)) {
                // Add the record to materialChecked if it doesn't exist
                add_record({
                  setter: setOuvrageChecked,
                  valueChecked: ouvrageChecked,
                  record,
                  checked,
                });
              } else {
                // Update the check value if the record exists
                if_record_exist({
                  setter: setOuvrageChecked,
                  valueChecked: ouvrageChecked,
                  record,
                  checked,
                });
              }
              setOuvrageAssociateData([
                ...ouvrageAssociateData,
                {
                  id: record.id,
                  quantity_associated: 1,
                },
              ]);
            }}
            checked={
              ouvrageChecked.some((item) => item.id === record.id)
                ? ouvrageChecked.find((item) => item.id === record.id).check
                : record.associated
            }
          />
        </CenteredContainer>
      ),
    },
    {
      title: "Quantité",
      dataIndex: "quantity",
      key: "quantity",
      width: "70px",
      render: (val, record) =>
        displayInputQuantity(record) && (
          <FormattedInput
            isNumber
            fontSize="10px"
            value={
              ouvrageAssociateData.some((item) => item.id === record.id)
                ? parseFloat(
                    ouvrageAssociateData.find((item) => item.id === record.id)
                      .quantity_associated
                  )
                : formatNumberString({ str: record.quantity_associated })
            }
            size="small"
            textAlign="right"
            onChange={(e) => {
              if (!ouvrageAssociateData.some((item) => item.id === record.id)) {
                // Add the record to materialAssociateData if it doesn't exist
                add_data_in_record({
                  setter: setOuvrageAssociateData,
                  valueData: ouvrageAssociateData,
                  record,
                  e,
                });
              } else {
                // Update the quantity_associated value if the record exists
                setOuvrageAssociateData(
                  ouvrageAssociateData.map((item) => {
                    if (item.id === record.id) {
                      return {
                        ...item,
                        quantity_associated: formatNumberString({
                          str: e.target.value,
                        }),
                      };
                    }
                    return item;
                  })
                );
              }
            }}
            onBlur={(e) => {
              if (parent === "family")
                update_ouvrage_associated_family({
                  family_profession_id: selectedFamily.id,
                  ouvrage_profession_id: record.id,
                  quantity: stringToFloat(e.target.value),
                });
              else
                update_ouvrage_associated({
                  material_profession_id: selectedMateriel.id,
                  ouvrage_profession_id: record.id,
                  quantity: stringToFloat(e.target.value),
                });
            }}
          />
        ),
    },
    {
      title: "",
      dataIndex: "action",
      width: "20px",
    },
  ];

  return (
    <>
      <StyledSearch
        allowClear
        placeholder="Rechercher par nom de groupement"
        value={wordEntered}
        onChange={handleFilter}
      />
      <LazyOuvrageArray
        columns={OuvragProfessionsColumns}
        from="admin"
        entityWorkId={entityWorkId}
        ouvrages={ouvrages}
        setOuvrages={setOuvrages}
        wordEntered={wordEntered}
        categoryFiltered={categoryFiltered}
        setCategoryFiltered={setCategoryFiltered}
        material_parent_id={parent === "material" && selectedMateriel?.id}
        group
        selectedFamilyId={parent === "family" && selectedFamily.id}
      />
    </>
  );
}

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSearch = styled(Search)`
  margin: 10px 0;
`;
export default OuvrageAssociate;
