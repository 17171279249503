import React, { useEffect, useLayoutEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import styled from "styled-components";
import fileDownload from "js-file-download";
import ResumeTable from "./ResumeAndMargin/ResumeTable";
import GlobalTable from "./ResumeAndMargin/GlobalTable";
import Details from "./Details/Details";
import ZoneResume from "./ResumeAndMargin/ZoneResume";
import TitleContainer from "../../react-ui/TitleContainer";
import Separator from "../../react-ui/Separator";
import ContentZone from "./Details/ContentZone";
import AddButton from "../../react-ui/AddButton";
import { getData, postData } from "../../request/instance";
import { useStore } from "../../store";
import { useLazyQuery } from "../../../hooks/useLazyQuery";
import Button from "../../react-ui/Button";
import Excel from "../../react-ui/Icons/Excel";
import Baskets from "./Baskets";
import Loader from "../../react-ui/Loader";

const selector = (state) => ({
  formToken: state.formToken,
  itemsAreUpdating: state.itemsAreUpdating,
});

function Costing3({
  responseType,
  opportunityType,
  opportunityId,
  chrono,
  hasSummaryExcel,
  hasRenting,
  opportunity_work,
  configuration,
  vocabulary,
  from = "",
}) {
  const { formToken, itemsAreUpdating } = useStore(selector);

  const { data: hasOption } = useQuery(
    "HasOption",
    () => getData(formToken, `/zone/${opportunityId}/has_option`),
    {
      enabled: from !== "configuration",
    }
  );
  const { mutate: createMaintenanceOpp, isLoading } = useMutation(
    (todoData) =>
      postData(formToken, "/opportunity/create_maintenance", todoData),
    {
      enabled: from !== "configuration",
      onSuccess: ({ next_page }) => {
        window.location.href = next_page;
      },
    }
  );

  const { mutate: createLocaOpp, isLoading: locaIsLoading } = useMutation(
    (todoData) => postData(formToken, "/opportunity/create_renting", todoData),
    {
      enabled: from !== "configuration",
      onSuccess: ({ next_page }) => {
        window.location.href = next_page;
      },
    }
  );

  const [getXls] = useLazyQuery(
    ["getXls"],
    () =>
      getData(formToken, `/opportunity/generate_low_current/${opportunityId}`, {
        responseType: "blob",
      }),
    {
      enabled: from !== "configuration",
      onSuccess: (res) => {
        const date = new Date();
        fileDownload(
          res,
          `Détails_Coûts_${chrono}_${date
            .toLocaleDateString("fr-FR")
            .replace(/\//g, "-")}.xlsx`
        );
      },
    }
  );

  useEffect(() => {
    let timeout;

    if (itemsAreUpdating) {
      timeout = setTimeout(() => {
        window.location.reload();
      }, 10000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [itemsAreUpdating]);

  if (itemsAreUpdating)
    return (
      <Container>
        <Loader width={80} height={80} />
      </Container>
    );

  return (
    <Container>
      <ListContainer>
        {responseType !== "referencing" && (
          <>
            <TitleContainer label="Marges et prix de vente" />
            <div className="content-array">
              {responseType !== "quotation" && (
                <ResumeTable
                  configuration={configuration}
                  from={from}
                  hasOption={hasOption}
                />
              )}
              <GlobalTable
                responseType={responseType}
                opportunity_work={opportunity_work}
                configuration={configuration}
                vocabulary={vocabulary}
                from={from}
                hasOption={hasOption}
              />
              <ZoneResume
                responseType={responseType}
                opportunity_work={opportunity_work}
                configuration={configuration}
                vocabulary={vocabulary}
                from={from}
              />
            </div>
          </>
        )}
        {responseType === "referencing" && (
          <Baskets
            opportunityId={opportunityId}
            vocabulary={vocabulary}
            configuration={configuration}
          />
        )}
      </ListContainer>

      <Separator />
      <div className="main_costing-array">
        <TitleContainer label="Détails">
          {hasSummaryExcel && (
            <Button
              type="button"
              onClick={() => getXls()}
              disabled={from === "configuration"}
            >
              <StyledIcon />
              Détails des coûts
            </Button>
          )}
          {hasRenting &&
            (responseType === "quotation" || responseType === "consultation") &&
            configuration.general.renting && (
              <StyledButton
                label="Offre locative"
                type="button"
                loading={locaIsLoading}
                disabled={from === "configuration"}
                onClick={() => createLocaOpp({ opportunity_id: opportunityId })}
              />
            )}
          {opportunityType === "new_work" &&
            configuration.general.maintenance && (
              <StyledButton
                label="Maintenance"
                disabled={from === "configuration"}
                type="button"
                loading={isLoading}
                onClick={() =>
                  createMaintenanceOpp({ opportunity_id: opportunityId })
                }
              />
            )}
        </TitleContainer>
        <div className="content-details">
          {responseType !== "referencing" && (
            <Details
              responseType={responseType}
              opportunity_work={opportunity_work}
              configuration={configuration}
              vocabulary={vocabulary}
              from={from}
            />
          )}
          {responseType === "referencing" && (
            <ContentZone
              responseType={responseType}
              opportunity_work={opportunity_work}
              configuration={configuration}
              vocabulary={vocabulary}
            />
          )}
        </div>
      </div>
    </Container>
  );
}

const StyledIcon = styled(Excel)`
  margin-right: 0.313rem;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  height: 76vh;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  height: 100%;
`;

const StyledButton = styled(AddButton)`
  margin-left: 4px;
`;

export default Costing3;
