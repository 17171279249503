$(document).ready(() => {

const wizard = document.getElementById("company_wizard_cost_price_ajustment_method")

if (!wizard) {
  return
}
const disable = (inputs) => {
  for(const input of inputs){
    input.setAttribute("disabled", "");
    input.classList.add("disable-button");
    input.previousSibling.classList.add('disable-button');
    input.parentElement.classList.add('disable-button');
  }
}

const enable = (inputs) => {
  for(const input of inputs){
    input.removeAttribute("disabled", "");
    input.classList.remove('disable-button');
    input.previousSibling.classList.remove('disable-button');
    input.parentElement.classList.remove('disable-button');
  }
}

const disableSingle = (input) => {
  input.setAttribute("disabled", "");
  input.classList.add("disable-button");
  input.previousSibling.classList.add('disable-button');
}

const enableSingle = (input) => {
  input.removeAttribute("disabled", "");
  input.classList.remove('disable-button');
  input.previousSibling.classList.remove('disable-button');
}

if(document.querySelector('.step-2')){
  const coefficientRevenueInputs = document.querySelectorAll('.cost_price')
  const salesMarginInputs = document.querySelectorAll('.selling_price')
  const calculMethodInput = document.querySelectorAll('.form-check-input') 
  const costPriceAdjustementMethodInput = document.getElementById('company_wizard_cost_price_ajustment_method');
  const costPriceAdjustementValueInput = document.getElementById('company_wizard_cost_price_ajustment_value');
  const percentP = document.querySelector('.percent')

  if(calculMethodInput[0].checked){
    enable(salesMarginInputs)
    disable(coefficientRevenueInputs)
  }
  else{
    disable(salesMarginInputs)
    enable(coefficientRevenueInputs)
  }

  for(const input of calculMethodInput){
    input.addEventListener("change", () => {
      switch(input.value){
        case "cost_price" :
          disable(salesMarginInputs)
          enable(coefficientRevenueInputs)
          break;
        case "selling_price" :
          enable(salesMarginInputs)
          disable(coefficientRevenueInputs)
          break;
        case "Oui" :
          enableSingle(costPriceAdjustementMethodInput)
          enableSingle(costPriceAdjustementValueInput)
          percentP.classList.remove('disable-button');
          break;
        case "Non" :
          disableSingle(costPriceAdjustementMethodInput)
          disableSingle(costPriceAdjustementValueInput)
          percentP.classList.add('disable-button');
          break;
      }
    })
  }
}
})