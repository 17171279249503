import React from "react";
import styled from "styled-components";

function ArrowCross({ className = "", ...iconProps }) {
  return (
    <StyledIcon
      className={`fa-duotone fa-arrows-up-down-left-right ${className}`}
      {...iconProps}
    />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: 12px;
`;

export default ArrowCross;
