import React from "react";
import Tooltip from "antd/lib/tooltip";
import { formatNumberString } from "../../../../../../../utils/formatNumberString";
import { currency_cents_to_euros } from "../../../../../../utils";
import ActionsContainer from "../../../../../../react-ui/ActionsContainer";
import Info from "../../../../../../react-ui/Icons/Info";
import FileDocument from "../../../../../../react-ui/Icons/FileDocument";
import Eye from "../../../../../../react-ui/Icons/Eye";
import Filter from "../../../../../../react-ui/Icons/Filter";

export const MaterialFormat = ({
  setMaterialData,
  materialsToHandle,
  faId,
  colGap,
  setModalType,
  configuration,
  handleCompatibleClick,
  setItemToShowId,
}) => {
  setMaterialData(
    materialsToHandle?.map((value) => {
      return {
        ...value,
        key: `material-${value.id}-${value.is_from_fa || ""}`,
        id: value.id,
        name: value.name,
        reference_code: value.reference_code,
        searchName: value.name,
        category: value.category_profession.import_csv
          ? "Import CSV"
          : value.category_profession.title,
        price: formatNumberString({
          str: currency_cents_to_euros(
            faId && value.is_from_fa
              ? value.selling_price_cents
              : value.cost_price_cents
          ),
          nbDecimal: 2,
          space: true,
        }),
        flag: "materials",
        enabled: false,
        action: (
          <ActionsContainer $colGap={colGap}>
            {value.remark && (
              <Tooltip placement="bottom" title={value.remark}>
                <Info $fontSize="11px" />
              </Tooltip>
            )}
            {configuration.general.compatibility && (
              <Filter
                onClick={() => handleCompatibleClick(value)}
                $fontSize="11px"
              />
            )}
            <FileDocument
              onClick={() =>
                value.drive_link
                  ? window.open(value.drive_link, "_blank")
                  : undefined
              }
              disabled={!value.drive_link}
              $fontSize="11px"
            />
            <Eye
              onClick={() => {
                setModalType("material");
                setItemToShowId(value.id);
              }}
              $fontSize="11px"
            />
          </ActionsContainer>
        ),
      };
    })
  );
};
