import { Controller } from "stimulus"
export default class extends Controller {
  static targets = ["coefficient" ,"qualification","entry_date" ,"calculated_seniority" ,"ccn_hourly_rate",
  "salary_grids", "opportunityid", "agentid"]
    connect() {
      this.calculate_seniority()
      this.change_coefficient_options(false)
    }

    changeDotToCommas = (event) => {
      event.currentTarget.value= event.currentTarget.value.replace(/\./g, ',')
      let nbCommas = (event.currentTarget.value.match(/,/g) || []).length
      if (nbCommas > 1){
        let commaPosition = event.currentTarget.value.indexOf(",")
        let beforeComma = event.currentTarget.value.substring(0,commaPosition+1)
        let afterComma = event.currentTarget.value.substring(commaPosition +1, event.currentTarget.value.length)
        afterComma = afterComma.replace(/\,/g, '')
        let newString=beforeComma + afterComma
        event.currentTarget.value=newString
      }
    event.currentTarget.value= event.currentTarget.value.replace(/[^0-9 ,]/g, '')
    }

    calculate_seniority() {
      if(this.entry_dateTarget.value === ""){
        this.calculated_seniorityTarget.value = "0.0"
      }
      fetch(`/opportunities/${this.opportunityidTarget.value}/agents/${this.agentidTarget.value}/calculate_seniority_ccn`, {
          method: "POST",
          headers: { "Content-Type": "application/json",
                     "Accept": "application/json",
                     "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
                   },
          body: JSON.stringify({ "entry_date": this.entry_dateTarget.value })
        }).then(response => response.json())
          .then((data) => {
            if(data){
              this.calculated_seniorityTarget.value = data.seniority_rate;
            }
          })
    }

    find_ccn_hourly_rate() {
      let qualification = this.qualificationTarget.value
      let coefficient = this.coefficientTarget.value
      let jsonSalaryGrid = JSON.parse(this.salary_gridsTarget.value);
        jsonSalaryGrid.forEach((salaryItem) => {
            if(salaryItem.qualification === qualification && salaryItem.coefficient === parseInt(coefficient)) {
              if(this.ccn_hourly_rateTarget){
              this.ccn_hourly_rateTarget.value =  parseFloat(salaryItem.collective_agreement_hourly_rate).toFixed(4)
            }
          }
        })
    }

    change_coefficient_options = (changeCoefficient = true) => {
        let jsonSalaryGrid = JSON.parse(this.salary_gridsTarget.value);
        const correspondingOptions = jsonSalaryGrid.filter(salary => salary.qualification === this.qualificationTarget.value)
        const correspondingValues = correspondingOptions.map(option => option.coefficient)
        let optionDescending = [...this.coefficientTarget.options].reverse();
        optionDescending.forEach((option)=> {
          if (correspondingValues.includes(parseInt(option.value))){
            if(changeCoefficient) { option.selected = true }
            option.style.display = "block";
          } else {
            option.style.display = "none";
          }
        })
        this.find_ccn_hourly_rate();
      }
}