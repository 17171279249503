import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "react-query";
import { getData } from "../../request/instance";
import CostingSection from "./CostingSection";
import ListSection from "./ListSection";
import Separator from "../../react-ui/Separator";
import { fakeZoneData } from "../../data-utils/page2-data";

function CostingMovementsCarrycots({
  formToken,
  entity_work_id,
  faId,
  configuration,
  vocabulary,
  from = "",
}) {
  const { id_opportunity } = useParams();

  const { data: zones } = useQuery(
    "Zones",
    () => getData(formToken, `/zone/${id_opportunity}`),
    {
      enabled: from !== "configuration",
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Container>
      <ListSection
        formToken={formToken}
        zones={from === "configuration" ? fakeZoneData : zones}
        id_opportunity={id_opportunity}
        faId={faId}
        from={from}
      />
      <Separator />
      <CostingSection
        formToken={formToken}
        zones={from === "configuration" ? fakeZoneData : zones}
        entity_work_id={entity_work_id}
        faId={faId}
        configuration={configuration}
        vocabulary={vocabulary}
        from={from}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  height: 76vh;
`;
export default CostingMovementsCarrycots;
