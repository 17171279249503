import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { useStore } from "../../store";
import MovementDetails from "./MovementDetails";
import MovementArray from "./MovementArray";
import { getUrlParams } from "../../utils";
import useTodo from "../../hookQuery/useTodo";
import TitleContainer from "../../react-ui/TitleContainer";
import Separator from "../../react-ui/Separator";
import AddButton from "../../react-ui/AddButton";
import { postData } from "../../request/instance";
import { fetchMovementsUrl } from "../../../utils/fetchMovementsUrl";
import { getFetchUrlIdAndFrom } from "../../../utils/getFetchUrlIdAndFrom";

const selector = (state) => ({
  formToken: state.formToken,
  editDeplacement: state.editDeplacement,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function Movements({
  entityWorkId = getUrlParams(),
  from = "admin",
  opportunityId = null,
  faIsValidate = false,
  faId = null,
}) {
  const queryClient = useQueryClient();
  const { formToken, editDeplacement } = useStore(selector);

  const [wordEntered, setWordEntered] = useState("");
  const [movementDetailsStatus, setMovementDetailsStatus] = useState("empty");

  const { data: movements, refetch } = useTodo(
    formToken,
    fetchMovementsUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
      search: wordEntered,
    }),
    ["Movements", { from }]
  );

  const { mutate: createMovOpportunity, isLoading: isCreaMovOppLoading } =
    useMutation(
      (todoData) =>
        postData(
          formToken,
          "/movement_opportunity/create_for_referencing",
          todoData
        ),
      {
        onSuccess: (payload) => {
          queryClient.invalidateQueries("Movements");
          editDeplacement({
            ...movements.find((el) => el.id === payload.movement_profession_id),
            movement_included: payload,
            is_in_framework_agreement: true,
          });
        },
      }
    );

  const { mutate: deleteMovOpportunity, isLoading: isDelMovOppLoading } =
    useMutation(
      (todoData) =>
        postData(formToken, "/movement_opportunity/delete", todoData),
      {
        onSuccess: (payload) => {
          queryClient.invalidateQueries("Movements");
          editDeplacement({
            ...movements.find((el) => el.id === payload.movement_profession_id),
            movement_included: null,
            is_in_framework_agreement: false,
          });
        },
      }
    );

  const { mutate: createMovFA, isLoading: isCreaMovFALoading } = useMutation(
    (todoData) =>
      postData(formToken, "/movement_framework_agreement/create", todoData),
    {
      onSuccess: (payload) => {
        queryClient.invalidateQueries("Movements");
        editDeplacement({
          ...movements.find((el) => el.id === payload.movement_profession_id),
          movement_included: payload,
          is_in_framework_agreement: true,
        });
      },
    }
  );

  const { mutate: deleteMovFA, isLoading: isDelMovFALoading } = useMutation(
    (todoData) =>
      postData(formToken, "/movement_framework_agreement/delete", todoData),
    {
      onSuccess: (payload) => {
        queryClient.invalidateQueries("Movements");
        editDeplacement({
          ...movements.find((el) => el.id === payload.movement_profession_id),
          movement_included: null,
          is_in_framework_agreement: false,
        });
      },
    }
  );

  useEffect(() => {
    editDeplacement(null);
    setMovementDetailsStatus("empty");
    debouncedSearch({ refetch });
  }, [wordEntered, refetch, editDeplacement]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const openMovementCreation = () => {
    editDeplacement(null);
    setMovementDetailsStatus("create");
  };

  const handleMovementFA = useCallback(
    (checked, movementProfession) => {
      if (isCreaMovFALoading || isDelMovFALoading) return;
      if (!checked) {
        createMovFA({
          movement_profession_id: movementProfession.id,
          framework_agreement_id: faId,
        });
      } else {
        deleteMovFA({
          movement_profession_id: movementProfession.id,
          id: movementProfession.movement_included.id,
        });
      }
    },
    [isCreaMovFALoading, faId, isDelMovFALoading, createMovFA, deleteMovFA]
  );

  const handleMovementOpportunity = useCallback(
    (checked, id) => {
      if (isCreaMovOppLoading || isDelMovOppLoading) return;
      if (!checked) {
        createMovOpportunity({
          movement_profession_id: id,
          opportunity_id: opportunityId,
        });
      } else {
        deleteMovOpportunity({
          movement_profession_id: id,
          opportunity_id: opportunityId,
        });
      }
    },
    [
      isCreaMovOppLoading,
      opportunityId,
      isDelMovOppLoading,
      createMovOpportunity,
      deleteMovOpportunity,
    ]
  );

  const handleFAAndOpp = useCallback(
    (checked, movementProfession) => {
      if (from === "referencing")
        handleMovementOpportunity(checked, movementProfession.id);
      else handleMovementFA(checked, movementProfession);
    },
    [handleMovementOpportunity, handleMovementFA, from]
  );

  return (
    <Container>
      <div className="main_admin-materiels">
        <TitleContainer label="Déplacements disponibles">
          {(from !== "frameworkAgreement" ||
            (from === "frameworkAgreement" && faIsValidate)) && (
            <AddButton
              onClick={openMovementCreation}
              disabled={movementDetailsStatus === "create"}
              label="Déplacement"
            />
          )}
        </TitleContainer>

        <StyledSearch
          allowClear
          placeholder="Rechercher par nom de déplacement"
          value={wordEntered}
          onChange={handleFilter}
        />
        <Separator isHorizontal size={3} />
        <div>
          <MovementArray
            setMovementDetailsStatus={setMovementDetailsStatus}
            movements={movements}
            entityWorkId={entityWorkId}
            from={from}
            handleFAAndOpp={handleFAAndOpp}
            faIsValidate={faIsValidate}
            movementDetailsStatus={movementDetailsStatus}
          />
        </div>
      </div>
      <Separator />
      <MovementDetails
        setMovementDetailsStatus={setMovementDetailsStatus}
        movementDetailsStatus={movementDetailsStatus}
        entityWorkId={entityWorkId}
        from={from}
        opportunityId={opportunityId}
        handleFAAndOpp={handleFAAndOpp}
        faIsValidate={faIsValidate}
        faId={faId}
        movements={movements}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
  width: 100%;
`;

const StyledSearch = styled(Search)``;

export default Movements;
