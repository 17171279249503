import React, { useCallback, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ArrowRightOutlined } from "@ant-design/icons";
import i18next from "i18next";
import { stringSorter } from "../../../utils/stringSorter";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { getData, postData } from "../../request/instance";
import { getUrlParams } from "../../utils";
import Plus from "../../react-ui/Icons/Plus";
import Input from "../../react-ui/Input";
import { formatNumberString } from "../../../utils/formatNumberString";
import Delete from "../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../confirmModal";

function TvaArray({ token, company_work_id }) {
  const queryClient = useQueryClient();
  const inputValueRef = useRef(null);
  const inputNameRef = useRef(null);
  const [nameTva, setNameTva] = useState("");
  const [tvaValue, setTvaValue] = useState([]);
  const [tvaValueEntity, setTvaValueEntity] = useState([]);
  const [tvaProfessionName, setTvaProfessionName] = useState([]);

  const { data: data_tva } = useQuery("TvaProfessions", () =>
    getData(token, `/tva_professions/${getUrlParams()}`)
  );

  const { mutate: createTvaProfession } = useMutation(
    (payload) => postData(token, "/tva_profession/create", payload),
    {
      onSuccess: () => {
        setNameTva("");
        queryClient.invalidateQueries("TvaProfessions");
      },
    }
  );

  const { mutate: deleteTvaProfession } = useMutation(
    (payload) => postData(token, "/tva_profession/delete", payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("TvaProfessions");
      },
    }
  );

  const { mutate: updateAllValuesTvaProfession } = useMutation(
    (payload) => postData(token, "/tva_profession_update_all_values", payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("TvaProfessions");
      },
    }
  );

  const { mutate: updateValueTvaProfession } = useMutation(
    (payload) => postData(token, "/tva_profession_update_value", payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("TvaProfessions");
      },
    }
  );

  const { mutate: updateNameTvaProfession } = useMutation(
    (payload) => postData(token, "/tva_profession_update_name", payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("TvaProfessions");
      },
    }
  );

  const keyPressNameHandler = (e) => {
    if (e.key === "Enter") {
      inputNameRef.current.blur();
    }
  };
  const keyPressValueHandler = (e) => {
    if (e.key === "Enter") {
      inputValueRef.current.blur();
    }
  };

  const determineValueInInputPercentage = (record, entity, value) => {
    if (tvaValueEntity?.[`tva_value-${record.id}-${entity.id}`] === undefined) {
      return value;
    }
    return tvaValueEntity?.[`tva_value-${record.id}-${entity.id}`];
  };

  const dynamicColumns = useCallback(() => {
    if (data_tva?.agencies?.length > 0) {
      const columns = data_tva?.agencies.toReversed().map((entity) => {
        return {
          title: entity.name,
          dataIndex: entity.id,
          width: 100,
          sorter: (a, b) => stringSorter(a.name, b.name),
          render: (value, record) =>
            record?.key !== "creative-row" && (
              <Input
                ref={inputValueRef}
                onKeyPress={keyPressValueHandler}
                style={{ width: 90 }}
                suffix="%"
                disabled={
                  !data_tva?.entity_is_master && record?.tva_type === "general"
                }
                value={formatNumberString({
                  str: determineValueInInputPercentage(record, entity, value),
                  nbDecimal: 2,
                })}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setTvaValueEntity((v) => ({
                    ...v,
                    [`tva_value-${record.id}-${entity.id}`]: newValue,
                  }));
                }}
                onBlur={() => {
                  updateValueTvaProfession({
                    id: record?.[`tva_entity_work_id-${entity.id}`],
                    entity_work_id: entity.entity_work_id,
                    tva_profession_id: record.id,
                    tva_value:
                      tvaValueEntity?.[`tva_value-${record.id}-${entity.id}`],
                  });
                  setTvaValueEntity(null);
                }}
              />
            ),
        };
      });
      return columns;
    }
    return [];
  }, [data_tva?.agencies, tvaValueEntity, updateValueTvaProfession]);

  const columns = useMemo(() => {
    const handleDeleteTvaProfession = (idTvaProfession) => {
      deleteTvaProfession(JSON.stringify({ id: idTvaProfession }));
    };
    return [
      {
        title: "Nom de la TVA",
        dataIndex: "name",
        sorter: (a, b) => stringSorter(a.name, b.name),
        // width: 200,
        fixed: "left",
      },
      {
        title: "Type de TVA",
        dataIndex: "tva_type",
        sorter: (a, b) => stringSorter(a.name, b.name),
        width: 100,
        fixed: "left",
        render: (value) => (value ? i18next.t(`tva.${value}`) : ""),
      },
      {
        title: "Appliquer à toutes les agences",
        dataIndex: "apply_to_all_agencies",
        sorter: (a, b) => stringSorter(a.name, b.name),
        width: 120,
        fixed: "left",
      },
      ...dynamicColumns(),
      {
        title: "",
        dataIndex: "action",
        width: 20,
        fixed: "right",
        render: (value, record) =>
          record.tva_type === "special" ? (
            <Delete
              onClick={() =>
                ShowDeleteConfirm(
                  record.name.props.value,
                  record.id,
                  handleDeleteTvaProfession
                )
              }
            />
          ) : (
            <div />
          ),
      },
    ];
  }, [dynamicColumns]);

  const determineValueInInputName = (value) => {
    if (tvaProfessionName?.[value.id] === undefined) {
      return value.name;
    }
    return tvaProfessionName?.[value.id];
  };

  const dataFormated = useCallback(() => {
    const data = [];
    let child;
    data_tva?.tva_professions?.forEach((value) => {
      child = {
        key: value.id,
        id: value.id,
        name: (
          <Input
            ref={inputNameRef}
            onKeyPress={keyPressNameHandler}
            value={determineValueInInputName(value)}
            onChange={(e) =>
              setTvaProfessionName((v) => ({
                ...v,
                [value.id]: e.target.value,
              }))
            }
            onBlur={() =>
              updateNameTvaProfession({
                id: value.id,
                name: tvaProfessionName?.[value.id] || value.name,
              })
            }
          />
        ),
        tva_type: value.tva_type,
        apply_to_all_agencies: (
          <StyledcellRow>
            <Input
              isMarginRate
              value={formatNumberString({
                str: tvaValue?.[`all-${value.id}`],
                nbDecimal: 2,
              })}
              suffix="%"
              onChange={(e) =>
                setTvaValue((v) => ({
                  ...v,
                  [`all-${value.id}`]: e.target.value,
                }))
              }
            />
            <Button
              icon={<i className="fa-light fa-arrow-right" />}
              onClick={() =>
                updateAllValuesTvaProfession({
                  tva_value: tvaValue[`all-${value.id}`],
                  tva_profession_id: value.id,
                  entity_work_id: getUrlParams(),
                })
              }
            />
          </StyledcellRow>
        ),
      };

      value.tva_entity_works?.forEach((tvaEntity) => {
        const entity_id = tvaEntity.entity_work_id;
        child[entity_id] = tvaEntity.tva_value;
        child[`tva_entity_work_id-${entity_id}`] = tvaEntity.id;
      });

      data.push(child);
    });
    if (data_tva?.can_create_special_tva && data_tva?.entity_is_master) {
      data.push({
        key: "creative-row",
        name: (
          <StyledcellRow>
            <Input
              placeholder="Non de la nouvelle TVA"
              onChange={(e) => setNameTva(e.target.value)}
              value={nameTva}
            />{" "}
            <Plus
              onClick={() =>
                createTvaProfession({
                  name: nameTva,
                  tva_type: 1,
                  entity_work_id: getUrlParams(),
                })
              }
            />
          </StyledcellRow>
        ),
      });
    }
    return data;
  }, [
    data_tva?.tva_professions,
    nameTva,
    tvaValue,
    tvaProfessionName,
    getUrlParams,
  ]);

  return (
    <StyledTable
      columns={columns}
      dataSource={dataFormated()}
      scroll={{
        x: 1500,
        y: 900,
      }}
      pagination={false}
      rowClassName={["cursor-pointer"]}
      //   onRow={(record) => {
      //     return {
      //       onClick: () => {
      //         openUpdate(difficulties.find((el) => el.id === record.id));
      //         topRef.current?.scrollIntoView({ behavior: "smooth" });
      //       },
      //     };
      //   }}
    />
  );
}

const StyledTable = styled(TableContainer)`
  margin: 10px 0;
`;

const StyledcellRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export default TvaArray;
