import React from "react";
import styled, { css } from "styled-components";

function WarningLabel({ hasWarning = false, children }) {
  return <Container $hasWarning={hasWarning}>{children}</Container>;
}

const Container = styled.div`
  transition: all 0.3s ease-in;
  ${({ $hasWarning }) =>
    $hasWarning &&
    css`
      font-size: 12px !important;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.yellow06};
    `}
`;

export default WarningLabel;
