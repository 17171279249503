import { coeffToMargin, marginToCoeff } from "./marginConverter";

export const formulaSellingPrice = ({
  costPrice,
  marginRate,
  defaultMarginRate,
  calculMethod,
  isCoeff,
}) => {
  let res;
  let margin;
  let isUsingMargin = !isCoeff;
  if (isCoeff === true && marginRate != "undefined") {
    let coeff = marginRate;
    margin = coeffToMargin({ coeff, isUsingMargin, calculMethod });
  } else {
    if (marginRate === "undefined") {
      margin = defaultMarginRate;
    } else {
      margin = marginRate;
    }
  }
  if (calculMethod === "cost_price") {
    res = costPrice * (margin / 100 + 1);
  } else {
    res = costPrice / (1 - margin / 100);
  }
  return res / 100;
};
