import React, { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled, { css } from "styled-components";
import { Checkbox } from "antd";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import { stringSorter } from "../../../../utils/stringSorter";
import NumberCell from "../../../react-ui/NumberCell";
import Delete from "../../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../../confirmModal";
import { translate_filter } from "../../Categories/translate_data";
import { numberSorter } from "../../../../utils/numberSorter";
import { securityFields } from "./SecurityCol";

const selector = (state) => ({
  formToken: state.formToken,
});

const columns = ({
  handleDeleteFolder,
  documents,
  isFromDocumentForm,
  disabledLinked,
  handleLinkedChange,
}) => [
  {
    title: "Nom du dossier",
    dataIndex: "name",
    fixed: "left",
    width: "190px",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Créé le",
    dataIndex: "created_at",
    width: "80px",
    sorter: (a, b) => stringSorter(a.created_at, b.created_at),
    render: (created_at) => (
      <NumberCell>
        {new Date(created_at).toLocaleDateString("fr-FR")}
      </NumberCell>
    ),
  },
  {
    title: "Documents",
    dataIndex: "documents",
    width: "300px",
    hidden: isFromDocumentForm,
    filterMode: "tree",
    filters: translate_filter(documents, null, null, "name"),
    filterSearch: true,
    onFilter: (value, record) => {
      const docIds = record.document_folders.map((el) => el.document_id);
      return docIds && docIds.includes(parseInt(value, 10));
    },
    render: (docsStr) => docsStr || "",
  },
  {
    title: "Sécurité",
    dataIndex: "security",
    width: "60px",
    sorter: (a, b) => stringSorter(a.security, b.security),
    render: (security) => securityFields.find((el) => el.id === security)?.name,
  },
  {
    title: "Couleur",
    dataIndex: "color",
    width: "40px",
    render: (color) => <ColorCell color={color} />,
  },
  {
    title: "",
    dataIndex: "",
    fixed: "right",
    hidden: isFromDocumentForm,
    align: "center",
    width: "20px",
    render: (record) =>
      record.is_editable && (
        <Delete
          onClick={(e) => {
            e.stopPropagation();
            ShowDeleteConfirm(record.name, record.id, handleDeleteFolder);
          }}
        />
      ),
  },
  {
    title: "Lié",
    dataIndex: "linked",
    fixed: "right",
    align: "center",
    width: "40px",
    sorter: (a, b) => numberSorter(a.linked, b.linked),
    hidden: !isFromDocumentForm,
    render: (linked, record) => (
      <Checkbox
        checked={linked}
        disabled={disabledLinked || record.disabled}
        onChange={(e) => handleLinkedChange(record.id, e)}
      />
    ),
  },
];

function FoldersArray({
  folders,
  setStatus,
  setSelectedFolder,
  foldersAreLoading,
  documents,
  documentsAreLoading,
  isFromDocumentForm,
  disabledLinked,
  setDocumentFolders,
  documentFolders,
}) {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);

  const { mutate: deleteFolder } = useMutation(
    (todo) => postData(formToken, "/folder/delete", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Folders");
      },
    }
  );

  const handleDeleteFolder = (id) => deleteFolder({ id });

  const dataSource = useMemo(() => {
    if (!folders) return [];
    return folders.map((folder) => {
      const docIds = folder.document_folders.map((el) => el.document_id);
      const docs = documents
        ?.filter((doc) => docIds?.includes(doc.id))
        .map((doc) => doc.name)
        .join(", ");

      const commonInformations = {
        ...folder,
        documents: docs,
      };
      if (!isFromDocumentForm) return commonInformations;

      const linked = documentFolders.some((fd) => fd.id === folder.id);

      return {
        ...commonInformations,
        linked,
      };
    });
  }, [documents, folders, isFromDocumentForm, documentFolders]);

  const handleLinkedChange = useCallback(
    (id, e) => {
      const folder = folders.find((el) => el.id === id);
      if (e.target.checked) {
        setDocumentFolders((dfs) => [...dfs, folder]);
      } else {
        setDocumentFolders((dfs) => {
          const newDfs = [...dfs];
          const idxToDel = dfs.findIndex((el) => el.id === folder.id);
          newDfs.splice(idxToDel, 1);
          return newDfs;
        });
      }
    },
    [folders, setDocumentFolders]
  );

  return (
    <TableContainer
      bordered={false}
      columns={columns({
        handleDeleteFolder,
        documents,
        isFromDocumentForm,
        disabledLinked,
        handleLinkedChange,
      })}
      rowKey="id"
      dataSource={dataSource}
      pagination={false}
      isRowSelectable
      onRow={(record) => {
        return {
          onClick: () => {
            if (isFromDocumentForm) return;
            setSelectedFolder(folders.find((el) => el.id === record.id));
            setStatus("update");
          },
        };
      }}
      scrollY={!isFromDocumentForm}
      scroll={isFromDocumentForm ? { y: 300 } : undefined}
      loading={foldersAreLoading || documentsAreLoading}
    />
  );
}

const ColorCell = styled.div`
  height: 24px;
  width: 24px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: inset 0 0 1px 0 rgba(0, 0, 0, 0.25);
  ${({ color }) =>
    color
      ? css`
          background-color: ${color};
        `
      : css`
          background-size: 50% 50%;
          background-image: conic-gradient(
            rgba(0, 0, 0, 0.06) 0 25%,
            transparent 0 50%,
            rgba(0, 0, 0, 0.06) 0 75%,
            transparent 0
          );
        `}
`;

export default FoldersArray;
