export const groupArrayByKey = ({ arr = [], keyId = "id" }) => {
  const groupedById = arr?.reduce((acc, curr) => {
    const key = curr[keyId] !== null ? curr[keyId] : curr["name"];
    (acc[key] = acc[key] || []).push(curr);
    return acc;
  }, {});

  return groupedById;
};

export const groupByMultipleKeys = (array, keys) => {
  var groups = {};
  array.forEach((obj) => {
    var group = JSON.stringify(keys?.map((el) => obj[el]));
    groups[group] = groups[group] || [];
    groups[group].push(obj);
  });
  return Object.keys(groups).map(function (group) {
    return groups[group];
  });
};
