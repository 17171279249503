import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { centsToEuros } from "../../../../../utils/currencyConverter";
import { formatNumberString } from "../../../../../utils/formatNumberString";
import { ShowDeleteConfirm } from "../../../../Admin-cf/confirmModal";
import Delete from "../../../../react-ui/Icons/Delete";
import NumberCell from "../../../../react-ui/NumberCell";
import { getData, postData } from "../../../../request/instance";
import { useStore } from "../../../../store";
import TableContainer from "../../../CostingMovementsCarrycots/TableContainer";
import LinkedOpportunity from "./LinkedOpportunity";
import FormattedInput from "../../../../react-ui/FormattedInput";

const rentingColumns = (opportunityType, periodType) => [
  {
    title: "Lié à une opportunité",
    dataIndex: "opportunity_id",
    width: "28%",
    render: (opportunity_id, record) => (
      <LinkedOpportunity
        opportunityId={opportunity_id}
        partialOpps={record.partialOpps}
        updateItem={record.updateService}
        itemId={record.id}
        itemType={opportunityType}
      />
    ),
  },
  {
    title: "Intitulé",
    dataIndex: "name",
    width: "18%",
    render: (name, record) => (
      <FormattedInput
        value={name}
        keyName="name"
        updateItem={record.updateService}
        itemId={record.id}
      />
    ),
  },
  {
    title: "Coût de revient",
    dataIndex: "total_cost_price_cents",
    width: "13%",
    render: (value, record) => (
      <FormattedInput
        value={centsToEuros(value)}
        keyName="total_cost_price_cents"
        updateItem={record.updateService}
        itemId={record.id}
        isNumber
        toCents
        suffix="€"
        disabled={!!record.opportunity_id}
        textAlign="right"
      />
    ),
  },
  {
    title: "Prix de vente HT total après remise",
    children: [
      {
        title: "Depuis l'opportunité",
        dataIndex: "total_selling_price_cents",
        width: "13%",
        render: (value) => (
          <NumberCell>
            {value !== null &&
              `${formatNumberString({
                str: centsToEuros(value),
                space: true,
              })} €`}
          </NumberCell>
        ),
      },
      {
        title: "Forcé",
        dataIndex: "total_selling_price_cents_forced",
        width: "13%",
        render: (value, record) => (
          <FormattedInput
            value={centsToEuros(value)}
            keyName="total_selling_price_cents_forced"
            updateItem={record.updateService}
            itemId={record.id}
            isNumber
            toCents
            suffix="€"
            textAlign="right"
          />
        ),
      },
    ],
  },
  {
    title: periodType === "Mensuel" ? "Loyer mensuel" : "Loyer trimestriel",
    dataIndex: "selling_price_cents",
    width: "13%",
    render: (value) => (
      <NumberCell>
        {value !== null &&
          `${formatNumberString({ str: centsToEuros(value), space: true })} €`}
      </NumberCell>
    ),
  },
  {
    title: "",
    align: "center",
    dataIndex: "deleteService",
    width: "2%",
    render: (deleteService, record) => (
      <Delete
        onClick={() =>
          ShowDeleteConfirm(record.name, record.id, deleteService, record)
        }
      />
    ),
  },
];

const selector = (state) => ({
  formToken: state.formToken,
});

function OppCosting({
  rentingOpp,
  partialOpps,
  opportunityType,
  periodType,
  configuration,
  vocabulary,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const [data, setData] = useState([]);
  const queryKey = ["Services", { type: opportunityType }];

  const { data: services } = useQuery(queryKey, () =>
    getData(
      formToken,
      `/service_rentings?renting_opportunity_id=${rentingOpp.id}&opportunity_type=${opportunityType}`
    )
  );

  const { mutate: createServiceMutation } = useMutation(
    (todo) => postData(formToken, "/service_renting/create", todo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries("RentingOpportunity");
        queryClient.invalidateQueries("OpportunityCotationLc");
      },
    }
  );

  const { mutate: updateServiceMutation } = useMutation(
    (todo) => postData(formToken, "/service_renting/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries("RentingOpportunity");
        queryClient.invalidateQueries("OpportunityCotationLc");
      },
    }
  );

  const { mutate: deleteServiceMutation } = useMutation(
    (todo) => postData(formToken, "/service_renting/delete", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries(queryKey);
        queryClient.invalidateQueries("RentingOpportunity");
        queryClient.invalidateQueries("OpportunityCotationLc");
      },
    }
  );

  const updateService = useCallback(
    (fields, id) => {
      updateServiceMutation({
        id,

        ...fields,
      });
    },
    [updateServiceMutation]
  );

  const deleteService = useCallback(
    (id) => {
      deleteServiceMutation({
        id,
      });
    },
    [deleteServiceMutation]
  );

  useEffect(() => {
    if (services) {
      setData(
        services.map((el) => {
          return {
            ...el,
            key: el.id,
            updateService: (fields) => updateService(fields, el.id),
            deleteService,
            partialOpps,
          };
        })
      );
    }
  }, [services, updateService, partialOpps, rentingOpp, deleteService]);

  const createService = () => {
    createServiceMutation({
      renting_opportunity_id: rentingOpp.id,
      opportunity_type: opportunityType,
    });
  };

  return (
    <Container>
      <TableContainer
        tr={2}
        header={{
          title: (
            <h4>
              {opportunityType === "new_work"
                ? `${vocabulary.product_lowercase.toUpperCase()} ET POSE`
                : "SERVICES COMPLEMENTAIRES"}
            </h4>
          ),
          buttons: [
            {
              buttonLabel: "Ajouter",
              onClick: createService,
            },
          ],
        }}
        columns={rentingColumns(opportunityType, periodType)}
        data={data}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export default OppCosting;
