import { encodeUrlParams } from "./encodeUrlParams";

export const fetchDifficultiesUrl = ({ from, id, search }) => {
  let url =
    from === "admin" || from === "frameworkAgreement"
      ? "/difficulty_profession?"
      : `/difficulty_opportunities/${id}?`;
  const params = {
    from,
    ...(search && { search }),
    ...(from === "admin" && { entity_work_id: id }),
    ...(from === "frameworkAgreement" && {
      framework_agreement_id: id,
      include_entities_child: true,
    }),
  };

  url += encodeUrlParams(params);

  return url;
};
