import React from "react";
import styled from "styled-components";

function DocusignIcon({ className = "", $fontSize = "12px", ...iconProps }) {
  return (
    <StyledIcon className="Docusign-icon" $fontSize={$fontSize} {...iconProps}>
      <span className="docusign-icon">
        <svg
          width="20"
          height="20"
          viewBox="0 0 403 403"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M402.261 0.568848H0.260742V402.569H402.261V0.568848Z"
            fill="#D5FF00"
          />
          <path
            d="M346.391 307.689H56.0669V346.757H346.391V307.689Z"
            fill="black"
          />
          <path
            d="M201.282 285.311L228.925 257.722L312.958 173.636L284.999 145.73L220.816 209.966V50.7982H181.696L181.748 209.966L117.513 145.73L89.6069 173.636L173.64 257.722L201.282 285.311Z"
            fill="black"
          />
        </svg>
      </span>
    </StyledIcon>
  );
}

const StyledIcon = styled.span`
  font-size: ${({ $fontSize }) => $fontSize};
`;

export default DocusignIcon;
