import React, { useEffect, useState } from "react";
import { useStore } from "../../store";
import { getUrlParams } from "../../utils";
import useDeleteTodo from "../../hookQuery/useDeleteTodo";
import { ShowDeleteConfirm } from "../confirmModal";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import ActionsContainer from "../../react-ui/ActionsContainer";
import Delete from "../../react-ui/Icons/Delete";
import { stringSorter } from "../../../utils/stringSorter";

const selector = (state) => ({
  selectedDistributor: state.selectedDistributor,
  editDistributor: state.editDistributor,
  formToken: state.formToken,
});

function Actions({ value, handleDeleteDistributor, isDeletable }) {
  return isDeletable ? (
    <ActionsContainer>
      <Delete
        onClick={() =>
          ShowDeleteConfirm(value.name, value.id, handleDeleteDistributor)
        }
      />
    </ActionsContainer>
  ) : null;
}

function DistributorArray({
  setDistributorDetailsStatus,
  distributors,
  distributorDetailsStatus,
}) {
  const { editDistributor, formToken, selectedDistributor } =
    useStore(selector);
  const pageEntityWork = parseInt(getUrlParams(), 10);

  const { mutate: removeDistributors } = useDeleteTodo(
    formToken,
    "/distributor/delete",
    "Distributors"
  );

  const [tableRowsData, setTableRowData] = useState([]);

  useEffect(() => {
    const isDeletable = (entityWorkId) => entityWorkId === pageEntityWork;

    const handleDeleteDistributor = (idDistributor) => {
      setDistributorDetailsStatus("empty");
      editDistributor();
      removeDistributors(
        JSON.stringify({
          id: idDistributor,
          entity_work_id: pageEntityWork,
        })
      );
    };
    const dataResult = [];
    let child;

    distributors?.forEach((value) => {
      child = {
        key: value.id,
        id: value.id,
        name: value.name,

        actions: (
          <Actions
            handleDeleteDistributor={handleDeleteDistributor}
            value={value}
            isDeletable={isDeletable(value.entity_work_id)}
          />
        ),
      };
      dataResult.push(child);
    });

    setTableRowData(dataResult);
  }, [
    distributors,
    pageEntityWork,
    removeDistributors,
    editDistributor,
    setDistributorDetailsStatus,
  ]);

  useEffect(() => {}, [tableRowsData]);

  const openUpdateDistributorProfession = (value) => {
    editDistributor(value);
    setDistributorDetailsStatus("update");
  };

  const DistributorColumns = [
    {
      title: "Intitulé",
      dataIndex: "name",
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: "",
      dataIndex: "actions",
      width: "20px",
    },
  ];

  return (
    <TableContainer
      bordered={false}
      scrollY
      columns={DistributorColumns}
      dataSource={tableRowsData}
      pagination={false}
      isRowSelectable
      rowSelectableId={
        distributorDetailsStatus === "update"
          ? selectedDistributor?.id
          : undefined
      }
      onRow={(record) => {
        return {
          onClick: () => {
            openUpdateDistributorProfession(
              distributors.find((el) => el.id === record.id)
            );
          },
        };
      }}
    />
  );
}

export default DistributorArray;
