import React from "react";
import styled from "styled-components";

function Swap({ className = "", ...iconProps }) {
  return (
    <StyledIcon className={`fa-duotone fa-swap ${className}`} {...iconProps} />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: ${({ $fontSize }) => $fontSize};
`;
export default Swap;
