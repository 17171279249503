import React from "react";
import ActionsContainer from "../../../../../../react-ui/ActionsContainer";
import Eye from "../../../../../../react-ui/Icons/Eye";
import Edit from "../../../../../../react-ui/Icons/Edit";

export const OuvrageSmart4Format = ({
  setOuvrageSmart4Data,
  ouvrageSmart4,
  setModalType,
  colGap,
  setItemToShowId,
  handleOpenFormModal,
}) => {
  setOuvrageSmart4Data(
    ouvrageSmart4?.map((ouvrage) => {
      return {
        ...ouvrage,
        key: ouvrage.id,
        id: ouvrage.id,
        flag: "ouvrages",
        ouvrage_profession_id: ouvrage.id,
        name: ouvrage.name,
        category_profession_id: ouvrage.category_profession_id,
        category: ouvrage.category_profession.title,
        action: (
          <ActionsContainer $colGap={colGap}>
            {ouvrage?.opportunity_id && (
              <Edit
                $fontSize="11px"
                onClick={() => handleOpenFormModal("ouvrages", ouvrage.id)}
              />
            )}
            <Eye
              $fontSize="11px"
              onClick={() => {
                setModalType("ouvrage_smart4");
                setItemToShowId(ouvrage.id);
              }}
            />
          </ActionsContainer>
        ),
      };
    })
  );
};
