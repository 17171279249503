import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useQueryClient } from "react-query";
import { useLazyQuery } from "../../hooks/useLazyQuery";
import { formatNumberString } from "../../utils/formatNumberString";
import { numberSorter } from "../../utils/numberSorter";
import { stringSorter } from "../../utils/stringSorter";
import { dateSorter } from "../../utils/dateSorter";
import TableContainer from "../Chiffrage/CostingMovementsCarrycots/TableContainer";
import NumberCell from "../react-ui/NumberCell";
import ActionsContainer from "../react-ui/ActionsContainer";
import Eye from "../react-ui/Icons/Eye";
import { getData } from "../request/instance";
import { useStore } from "../store";

const selector = (state) => ({
  formToken: state.formToken,
});

function OffersHidden({ offers_hidden }) {
  const [data, setData] = useState([]);
  const { formToken } = useStore(selector);
  const [offerId, setOfferId] = useState();
  const queryClient = useQueryClient();

  const [showOffer] = useLazyQuery(
    ["showOfferId"],
    () => getData(formToken, `/admin/hiddens/offer/${offerId}`),
    {
      onSuccess: () => queryClient.invalidateQueries("HiddenOffers"),
    }
  );
  useEffect(() => {
    if (offerId) {
      showOffer();
    }
  }, [offerId, showOffer]);
  useEffect(() => {
    if (offers_hidden?.length > 0) {
      setData([
        ...offers_hidden.map((offer) => ({
          ...offer,
          show: (
            <ActionsContainer center="center">
              <Eye onClick={() => setOfferId(offer.key)} />
            </ActionsContainer>
          ),
        })),
      ]);
    }
  }, [offers_hidden]);

  const globalArray = [
    {
      title: "Intitulé",
      dataIndex: "name",
      key: "name",
      width: 200,
      fixed: "left",
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: "Nombre d'opportunités",
      dataIndex: "opportunities_count",
      key: "opportunities_count",
      width: 100,
      align: "right",
      sorter: (a, b) => numberSorter(a.opportunities_won, b.opportunities_won),
      render: (value) =>
        value ? (
          <NumberCell>
            {formatNumberString({
              str: value,
              nbDecimal: 2,
              space: true,
            })}
          </NumberCell>
        ) : (
          0
        ),
    },
    {
      title: "Entreprises",
      dataIndex: "clients",
      key: "clients",
      width: 200,
      align: "left",
    },
    {
      title: "Date limite de réponse",
      dataIndex: "answer_date",
      key: "answer_date",
      width: 100,
      align: "left",
      sorter: (a, b) => dateSorter(a.answer_date, b.answer_date),
    },
    {
      title: "CA",
      dataIndex: "turnover",
      key: "turnover",
      width: 100,
      align: "right",
      sorter: (a, b) => numberSorter(a.turnover, b.turnover),
      render: (value) =>
        value ? (
          <NumberCell>
            {formatNumberString({
              str: value,
              nbDecimal: 2,
              space: true,
            })}
            €
          </NumberCell>
        ) : (
          "0€"
        ),
    },
    {
      title: "Agence(s)",
      dataIndex: "entities",
      key: "entities",
      width: 100,
      align: "left",
    },
    {
      title: "Propriétaire",
      dataIndex: "offer_user",
      key: "offer_user",
      width: 100,
      align: "left",
      sorter: (a, b) => stringSorter(a.offer_user, b.offer_user),
    },
    {
      title: "",
      dataIndex: "show",
      key: "show",
      align: "center",
      fixed: "right",
      width: 20,
    },
  ];

  return (
    <Container>
      <WrapperFullTable>
        <Title>Offres</Title>
        <WrapperTable>
          <TableContainer
            columns={globalArray}
            data={data}
            pagination={false}
            scroll={{ x: 1300, y: "69vh" }}
          />
        </WrapperTable>
      </WrapperFullTable>
    </Container>
  );
}

const Container = styled.div`
  justify-content: space-between;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  height: fit-content;
  width: 100%;
`;
const WrapperTable = styled.div`
  display: inline-block;
  width: 100%;
  height: 72vh;
`;
const WrapperFullTable = styled.div`
  background-color: white;
  border-radius: 8px;
  flex: 1 1 300px;
  display: inline-block;
  width: 100%;
  height: auto;
  margin-bottom: 0.7rem;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;
const Title = styled.h4`
  display: flex;
  font-weight: 500;
  font-size: 18px;
  font-family: "Cerebri Sans,sans-serif";
  color: #343a40;
  justify-content: center;
  margin: 0;
  margin-top: 0.375rem;
`;

export default OffersHidden;
