import { select2 } from "select2";
const select = () => {
  $(document).ready(function() {
    $('.select2').select2();
  })
}

export { select };
$(document).ready(() => {
//add class active to the card open(displaying)
const siteMap = document.getElementById("sitemap-clients");

  if(!siteMap){
    return
  }

if (siteMap) {
  const cardBoxs = siteMap.querySelectorAll(".client");
  const addActive = () => {
    // event.currentTarget.classList.add("selected")
    // event.currentTarget.nextElementSibling.firstElementChild.classList.add("selected")
    cardBoxs.forEach(child => {
      if (event.currentTarget !== child) {
        child.classList.remove('selected');
        child.parentElement.nextElementSibling.classList.remove('show');
        child.parentElement.nextElementSibling.lastElementChild.classList.remove("selected");
      } else {
        event.currentTarget.classList.add('selected');
        event.currentTarget.parentElement.parentElement.classList.add('main-box-shadow');
        event.currentTarget.parentElement.nextElementSibling.lastElementChild.classList.add("selected");
      }
    })
  }
  cardBoxs.forEach(card => card.addEventListener("click", addActive))


  // FILL DIRECTLY THE CASE "Cette entreprise dépend d'un groupe ?" in the modal new_client by the left row client
  const changeParent = (parentId) => {
      $(".new-client-modal-parent").val(parentId).change()

        // execute code only when the modal new_client is open
  };

  // HEAD_OFFICE_CLIENT
  // get click on first add-client
  const btnAddClients = document.querySelectorAll(".modal-new-client-toggle");
  if (btnAddClients) {
    const fillClient = () => {
      changeParent(event.currentTarget.dataset.parent)
    };
    btnAddClients.forEach((client) => {
      client.addEventListener("click", fillClient);
    })
  }
}


$(document).ready(function() {
  const changeTextMaxSelect = (e) => {
    const text = "Supprimez l'activité avant de la modifier";
      return text;
  }
  // edit client
  const allChilds = $(".all-childs");
  if (allChilds) {
    const idClient = allChilds.data("child");
    $(".dropdown-child").val(idClient).change().select2({
        placeholder: "Sélectionnez l'entreprise",
        disableSearch: false
    })
  }

  // allow the search box in the new client activity field
  const activityClient = $(".client_clients_activity_id");
  if (activityClient) {
    $(".dropdown-activity").change().select2({
        disableSearch: false,
        maximumSelectionLength: 1,
        language: {maximumSelected: changeTextMaxSelect}
    })
  }
});

$(document).on("click", ".clickable-row", function() {
  var link  = $(this).data("link")
  window.location = link
});
})