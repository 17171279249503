import {
  Form,
  TreeSelect,
  Upload,
  Button as AntdButton,
  notification,
} from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { UploadOutlined } from "@ant-design/icons";
import { validateMessages } from "../../../constant";
import FormRow from "../../../Form/FormRow";
import FormCol from "../../../Form/FormCol";
import Select from "../../../react-ui/Select";
import { filterInTree } from "../../../../utils/search";
import TitleContainer from "../../../react-ui/TitleContainer";
import AddButton from "../../../react-ui/AddButton";
import Input from "../../../react-ui/Input";
import { getEntitiesByWork } from "../../../../utils/getEntitiesByWork";
import ReactDatePicker from "../../../Form/ReactDatePicker";
import Button from "../../../react-ui/Button";
import FileUploaded from "./FileUploaded";
import FoldersArray from "../Folders/FoldersArray";
import { Loading } from "../../../loading";

const itemRender = ({
  file,
  actions,
  form,
  disabled,
  fileKeyName,
  onChange,
}) => (
  <FileUploaded
    actions={actions}
    file={file}
    form={form}
    disabled={disabled}
    keyName={fileKeyName}
    onChange={onChange}
  />
);

function DocumentForm({
  works = [],
  handleSubmit,
  initialValues,
  status,
  entities = [],
  settableEntities = [],
  settableWorks = [],
  onCancel,
  siteTypologies = [],
  parentEntity,
  canSetGlobalEntities,
  canSetGlobalWorks,
  formIsLoading,
  folders,
  foldersAreLoading,
  documents,
  documentsAreLoading,
  setDocumentFolders,
  documentFolders,
  fromCosting = false,
  fromOpportunity = false,
  updateOnChange = false,
  updateStatus,
}) {
  const [form] = Form.useForm();
  const canUpdate = useMemo(() => {
    if (status === "create") return true;
    if (!initialValues) return false;
    return initialValues.is_editable;
  }, [initialValues, status]);
  const showAllEntities = useMemo(
    () =>
      status === "update" &&
      !!initialValues &&
      initialValues.entities.some(
        (ent) =>
          ent === "global" || !settableEntities.map((el) => el.id).includes(ent)
      ),
    [status, initialValues, settableEntities]
  );

  const selectedWorks = Form.useWatch("works", form);

  useEffect(() => {
    if (status === "empty") form.resetFields();
  }, [status, form]);

  const getSelectedWorkIds = useCallback(
    () =>
      !selectedWorks ||
      selectedWorks.length < 1 ||
      (selectedWorks.length === 1 && selectedWorks[0] === "global")
        ? works.map((el) => el.id)
        : selectedWorks,
    [selectedWorks, works]
  );

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    const workIds = getSelectedWorkIds();
    const selectedEntities = form.getFieldValue("entities")?.filter((id) => {
      if (id === "global") return true;
      const entity = entities.find((el) => el.id === id);
      const entityWorkIds = entity.works.map((w) => w.id);
      return entityWorkIds.some((wId) => workIds.includes(wId));
    });
    form.setFieldValue("entities", selectedEntities);
  }, [getSelectedWorkIds, form, entities]);

  const siteTypologiesWithGlobal = useMemo(() => {
    const global = { id: "global", typology: "Toutes les typos" };
    return [global, ...siteTypologies];
  }, [siteTypologies]);

  const worksWithGlobal = useMemo(() => {
    const worksToUse =
      status === "update" && !!initialValues
        ? works
            .filter(
              (el) =>
                settableWorks.some((w) => el.id === w.id) ||
                initialValues.works.includes(el.id)
            )
            .map((w) => {
              const disabled = !settableWorks.map((el) => el.id).includes(w.id);

              return {
                ...w,
                disabled,
              };
            })
        : settableWorks;
    if (!canSetGlobalWorks && !initialValues?.works.includes("global"))
      return [...worksToUse];
    const global = {
      id: "global",
      fullname: "Tous les métiers",
      disabled: !canSetGlobalWorks,
    };
    return [global, ...worksToUse];
  }, [status, initialValues, works, settableWorks, canSetGlobalWorks]);

  const relevantEntitiesWithGlobal = useMemo(() => {
    const entitiesToUse = showAllEntities ? entities : settableEntities;
    const parentEntityToUse = showAllEntities
      ? entities.find((entity) => entity.head_office_id === null)
      : parentEntity;
    const workIds = getSelectedWorkIds();
    const entitiesByWork = getEntitiesByWork({
      entities: entitiesToUse,
      parentEntity: parentEntityToUse,
      workIds,
    })?.map((entity) => {
      const disabled =
        !settableEntities.map((el) => el.id).includes(entity.id) ||
        !entity.works.some((el) => workIds.includes(el.id));

      return {
        id: entity.id,
        pId: entity.parent_id,
        title: entity.name,
        value: entity.id,
        disabled,
      };
    });
    if (canSetGlobalEntities || showAllEntities)
      return [
        {
          id: "global",
          title: "Toutes les agences",
          value: "global",
          disabled: !canSetGlobalEntities,
        },
      ].concat(entitiesByWork);
    return entitiesByWork;
  }, [
    settableEntities,
    showAllEntities,
    entities,
    parentEntity,
    getSelectedWorkIds,
    canSetGlobalEntities,
  ]);

  const handleGlobalSelection = ({ arr, key }) => {
    const hasGlobal = arr.some((el) => el === "global");
    const globalHasJustBeenSelected =
      hasGlobal && arr[arr.length - 1] === "global";
    const globalIsAlreadySelected = hasGlobal && !globalHasJustBeenSelected;

    if (globalHasJustBeenSelected) {
      form.setFieldValue(key, ["global"]);
      return;
    }
    if (globalIsAlreadySelected) {
      const newArr = [...arr];
      const globalIdx = arr.findIndex((el) => el === "global");
      newArr.splice(globalIdx, 1);
      form.setFieldValue(key, newArr);
      return;
    }
    form.setFieldValue(key, arr);
  };

  const onChangeWorks = (arr) => {
    handleGlobalSelection({ arr, key: "works" });
  };

  const onChangeEntities = (arrEntities) => {
    const arr = arrEntities.map((el) => el.value);
    handleGlobalSelection({ arr, key: "entities" });
  };

  const onChangeTypologies = (arr) => {
    handleGlobalSelection({ arr, key: "typologies" });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const getTitle = () => {
    if (status === "empty") return "Importer des documents";
    if (!canUpdate) return "Visualiser un document";
    return status === "create"
      ? "Importer des documents"
      : "Modifier un document";
  };

  const fileKeyName = status === "create" ? "files" : "file";

  const onChange = () => {
    if (updateOnChange) form.submit();
  };

  const beforeUpload = (file) => {
    // Check the file size
    const isLt75M = file.size / 1024 / 1024 < 75;
    if (!isLt75M) {
      notification.error({
        description: "Un fichier ne peut pas dépasser 75Mo",
        placement: "bottom",
        duration: 5,
      });
      return false;
    }
    return false;
  };

  return (
    <Form
      id="document-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      initialValues={initialValues}
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <TitleContainer label={getTitle()}>
        {!updateOnChange && status !== "empty" && (
          <StyledButton
            label={canUpdate ? "Annuler" : "Fermer"}
            onClick={onCancel}
            fontSize="14px"
            btnType="cancel"
            type="button"
          />
        )}
        {!updateOnChange && canUpdate && (
          <AddButton
            label={status === "create" ? "Créer" : "Modifier"}
            type="submit"
            value="submit"
            fontSize="14px"
            loading={formIsLoading}
          />
        )}
        {updateOnChange && <Loading status={updateStatus} />}
      </TitleContainer>

      {status === "empty" && (
        <Wrapper>
          Sélectionnez ou créez un document pour afficher les détails
        </Wrapper>
      )}

      {status !== "empty" && (
        <FormContainer $fromOpportunity={fromOpportunity}>
          <FormRow>
            <FormCol
              label={status === "create" ? "Fichiers (max. 20)" : "Fichier"}
              name={fileKeyName}
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[{ required: true }]}
            >
              <Upload
                beforeUpload={beforeUpload}
                multiple={status === "create"}
                onChange={onChange}
                itemRender={(_, file, __, actions) =>
                  itemRender({
                    file,
                    actions,
                    form,
                    disabled: !canUpdate,
                    fileKeyName,
                    onChange,
                  })
                }
                maxCount={status === "create" ? 20 : 1}
                disabled={!canUpdate}
              >
                <UploadBtnContainer>
                  <AntdButton disabled={!canUpdate} icon={<UploadOutlined />}>
                    Cliquez pour télécharger
                  </AntdButton>
                </UploadBtnContainer>
              </Upload>
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol label="Description" name="description">
              <Input textArea disabled={!canUpdate} onBlur={onChange} />
            </FormCol>
            {!fromCosting && (
              <FormCol label="Date de validité" name="date_validity">
                <ReactDatePicker disabled={!canUpdate} />
              </FormCol>
            )}
          </FormRow>

          {!fromCosting &&
            (canSetGlobalWorks ||
              settableWorks.length > 1 ||
              initialValues?.works.includes("global") ||
              initialValues?.works.length > 1) && (
              <FormRow>
                <FormCol
                  label="Métiers"
                  name="works"
                  rules={[{ required: true }]}
                >
                  <Select
                    mode="multiple"
                    showSearch={false}
                    options={worksWithGlobal}
                    keyName="fullname"
                    onChange={onChangeWorks}
                    disabled={!canUpdate}
                  />
                </FormCol>
              </FormRow>
            )}

          {!fromCosting &&
            (canSetGlobalEntities ||
              showAllEntities ||
              settableEntities.length > 1) && (
              <FormRow>
                <FormCol
                  label="Agences concernées"
                  name="entities"
                  rules={[{ required: true }]}
                >
                  <StyledTreeSelect
                    treeDefaultExpandAll
                    treeCheckable
                    treeData={relevantEntitiesWithGlobal}
                    treeDataSimpleMode
                    showCheckedStrategy="SHOW_ALL"
                    filterTreeNode={filterInTree}
                    treeCheckStrictly
                    showSearch
                    onChange={onChangeEntities}
                    disabled={!canUpdate}
                  />
                </FormCol>
              </FormRow>
            )}

          {!fromCosting && (
            <FormRow>
              <FormCol
                label="Typologies"
                name="typologies"
                rules={[{ required: true }]}
              >
                <Select
                  options={siteTypologiesWithGlobal}
                  keyName="typology"
                  mode="multiple"
                  onChange={onChangeTypologies}
                  disabled={!canUpdate}
                />
              </FormCol>
            </FormRow>
          )}

          {!fromCosting && (
            <ArrayWrapper>
              <FoldersArray
                folders={folders}
                foldersAreLoading={foldersAreLoading}
                documents={documents}
                documentsAreLoading={documentsAreLoading}
                isFromDocumentForm
                disabledLinked={!canUpdate}
                setDocumentFolders={setDocumentFolders}
                documentFolders={documentFolders}
              />
            </ArrayWrapper>
          )}
        </FormContainer>
      )}
    </Form>
  );
}

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const FormContainer = styled.div`
  ${({ $fromOpportunity }) =>
    !$fromOpportunity &&
    css`
      padding: 0 30px 16px 30px;
    `};
  overflow-y: scroll;
`;

const UploadBtnContainer = styled.div`
  .ant-btn > span {
    display: inline-flex !important;
  }
`;

const StyledTreeSelect = styled(TreeSelect)`
  ${({ disabled }) =>
    disabled &&
    css`
      .ant-select-selection-item {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ArrayWrapper = styled.div`
  width: 940px;
  padding-top: 16px;
`;

export default DocumentForm;
