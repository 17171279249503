import i18next from "i18next";
import { Controller } from "stimulus";

const instantiateElement = ({
  className,
  tag,
  label,
  labelClass,
  forText,
  required = true,
}) => {
  let element = $(className);
  const toRemove = required ? "optional" : "required";
  const toAdd = required ? "required" : "optional";

  element[0].classList.remove(toRemove);
  element[0].classList.add(toAdd);
  element[0].getElementsByTagName(tag)[0].classList.remove(toRemove);
  element[0].getElementsByTagName(tag)[0].classList.add(toAdd);
  element[0].getElementsByTagName(
    "label"
  )[0].innerHTML = `<label class="${labelClass} ${toAdd}" for=${forText}>${label} ${
    toAdd === "required" ? '<abbr title="required">*</abbr>' : ""
  }</label>`;
};

const emptyCombo = (selectEl, length = 0) => {
  selectEl.options.length = length;
  return selectEl;
};

const populateCombo = (selectEl, items) => {
  return appendChildren(
    selectEl,
    items.map((item) => new Option(item.name, item.id))
  );
};

const appendChildren = (el, children) => {
  children.forEach((child) => el.appendChild(child));
  return el;
};

export default class extends Controller {
  static targets = [
    "workid",
    "entities",
    "entityid",
    "worklabel",
    "startdate",
    "enddate",
    "opptype",
    "restype",
    "clientid",
  ];

  initialize() {
    const formEditOpportunity = document.querySelector(
      ".edit_opportunity_page"
    );
    let dynamicWorks = $(".dynamic-work");
    for (var i = 0; i < dynamicWorks.length; i++) {
      dynamicWorks[i].style.display = "flex";
      dynamicWorks[i].style.flexDirection = "column";
    }
    if (!formEditOpportunity) {
      this.change_entities_list();
      this.change_framework_agreements_list();
    } else {
      this.display_block();
      this.display_on_low_current_change();
    }
  }

  change_entities_list() {
    fetch(
      `/entities/${this.entityidTarget.value}/works/${this.workidTarget.value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let selectOption = $(".dropdown-entities");

        populateCombo(emptyCombo(selectOption[0]), data);
      });
    this.display_block();
    this.display_on_low_current_change();
  }

  change_framework_agreements_list() {
    const workLabel = this.worklabelTarget;
    if (workLabel.value === "guarding" || !this.clientidTarget.value) return;

    fetch(
      `/framework_agreements?` +
        new URLSearchParams({
          client_id: this.clientidTarget.value,
          work_id: this.workidTarget.value,
          start_date: this.startdateTarget.value,
          end_date: this.enddateTarget.value,
        }),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let selectOption = $(".dropdown-framework-agreement");
        // Always keep first option because it's placeholder
        populateCombo(emptyCombo(selectOption[0], 1), data);
      });
  }

  display_on_low_current_change() {
    const formEditOpportunity = document.querySelector(
      ".edit_opportunity_page"
    );
    const workLabel = this.worklabelTarget;
    if (workLabel.value === "guarding") return;
    const oppTypeText = formEditOpportunity
      ? i18next.t(`opportunity_type.${this.opptypeTarget.value}`)
      : this.opptypeTarget.options[this.opptypeTarget.selectedIndex].text;
    const resTypeText = formEditOpportunity
      ? i18next.t(`response_type.${this.restypeTarget.value}`)
      : this.restypeTarget.options[this.restypeTarget.selectedIndex].text;
    const isContract =
      oppTypeText === "Maintenance" ||
      resTypeText === "Référencement accord-cadre";
    const showFrameworkAgreement = resTypeText === "Devis accord-cadre";
    const disableOppType = resTypeText === "Référencement accord-cadre";
    const startDateLabel = isContract
      ? "Début du contrat"
      : "Début du chantier";
    let frameworkAgreementBlock = document.getElementById("frameworkagreement");
    let quotationEndDateBlock = document.getElementById("quotationenddate");

    instantiateElement({
      className: ".opportunity_quotation_start_date",
      tag: "input",
      forText: "quotation_start_date",
      label: startDateLabel,
      labelClass: "date",
      required: isContract,
    });
    if (isContract) {
      quotationEndDateBlock.style.display = "block";
      instantiateElement({
        className: ".opportunity_quotation_end_date",
        tag: "input",
        forText: "quotation_end_date",
        label: "Fin du contrat",
        labelClass: "date",
      });
    } else {
      quotationEndDateBlock.style.display = "none";
      const contractEndDate = document.getElementById(
        "opportunity_quotation_end_date"
      );
      contractEndDate.value = "";
    }
    if (!formEditOpportunity) {
      if (showFrameworkAgreement) {
        frameworkAgreementBlock.style.display = "block";
        instantiateElement({
          className: ".opportunity_framework_agreement_id",
          tag: "select",
          forText: "framework_agreement_id",
          label: "accord-cadre",
          labelClass: "select",
        });
      } else {
        frameworkAgreementBlock.style.display = "none";
        let selectOption = $(".dropdown-framework-agreement");
        // Always keep first option because it's placeholder
        selectOption[0].options.selectedIndex = 0;
      }
      if (disableOppType) {
        instantiateElement({
          className: ".opportunity_opportunity_type",
          tag: "select",
          forText: "opportunity_type",
          label: "Type d'opportunité",
          labelClass: "select",
          required: false,
        });

        let selectOptionOppType = $(".dropdown-opportunity-type");
        selectOptionOppType[0].options.selectedIndex = 0;
        selectOptionOppType[0].classList.add("disabled");
        selectOptionOppType[0].setAttribute("disabled", "disabled");
      } else {
        let selectOptionOppType = $(".dropdown-opportunity-type");
        selectOptionOppType[0].classList.remove("disabled");
        selectOptionOppType[0].removeAttribute("disabled");
        instantiateElement({
          className: ".opportunity_opportunity_type",
          tag: "select",
          forText: "opportunity_type",
          label: "Type d'opportunité",
          labelClass: "select",
        });
      }
    }
  }

  display_block() {
    const formEditOpportunity = document.querySelector(
      ".edit_opportunity_page"
    );
    let guardingEls = $(".guarding");
    let lowcurrentEls = $(".lowcurrent");
    let itemSelected = this.workidTarget.options
      ? this.workidTarget.options[this.workidTarget.selectedIndex].text
      : null;
    let workLabel = this.worklabelTarget;
    let quotationEndDateBlock = document.getElementById("quotationenddate");

    //la traduction utilisée par i18next est dans le fichier /frontend/packs/en.js
    if (
      (itemSelected &&
        parseInt(itemSelected.localeCompare(i18next.t(`work.guarding`))) ===
          parseInt(0)) ||
      (!itemSelected && workLabel.value === "guarding")
    ) {
      if (!formEditOpportunity) {
        let selectOption = $(".dropdown-framework-agreement");
        let selectOptionResponseType = $(".dropdown-response-type");
        let selectOptionOppType = $(".dropdown-opportunity-type");
        selectOption[0].options.selectedIndex = 0;
        selectOptionResponseType[0].options.selectedIndex = 0;
        selectOptionOppType[0].options.selectedIndex = 0;
      }
      for (var i = 0; i < guardingEls.length; i++) {
        guardingEls[i].style.display = "flex";
        guardingEls[i].style.flexDirection = "column";
      }
      for (var i = 0; i < lowcurrentEls.length; i++) {
        lowcurrentEls[i].style.display = "none";
      }
      quotationEndDateBlock.style.display = "block";
      instantiateElement({
        className: ".opportunity_quotation_start_date",
        tag: "input",
        forText: "quotation_start_date",
        label: "Début du contrat",
        labelClass: "date",
      });
      instantiateElement({
        className: ".opportunity_quotation_end_date",
        tag: "input",
        forText: "quotation_end_date",
        label: "Fin du contrat",
        labelClass: "date",
      });
      workLabel.value = "guarding";
    } else {
      for (var i = 0; i < guardingEls.length; i++) {
        guardingEls[i].style.display = "none";
      }
      for (var i = 0; i < lowcurrentEls.length; i++) {
        lowcurrentEls[i].style.display = "flex";
        lowcurrentEls[i].style.flexDirection = "column";
      }
      if (!formEditOpportunity) {
        instantiateElement({
          className: ".opportunity_opportunity_type",
          tag: "select",
          forText: "opportunity_type",
          label: "Type d'opportunité",
          labelClass: "select",
        });
        instantiateElement({
          className: ".opportunity_response_type",
          tag: "select",
          forText: "response_type",
          label: "Type de réponse",
          labelClass: "select",
        });
      }
      if (
        itemSelected &&
        parseInt(
          itemSelected.localeCompare(i18next.t(`work.electronic security`))
        ) === parseInt(0)
      ) {
        workLabel.value = "electronic security";
      }
      if (
        itemSelected &&
        parseInt(itemSelected.localeCompare(i18next.t(`work.fire safety`))) ===
          parseInt(0)
      ) {
        workLabel.value = "fire safety";
      }
    }
  }
}
