import React from "react";
import styled from "styled-components";
import { ShowDeleteConfirm } from "../confirmModal";
import { useStore } from "../../store";
import useTodo from "../../hookQuery/useTodo";
import useDeleteTodo from "../../hookQuery/useDeleteTodo";
import { columnsUnity } from "../../constant";
import { getUrlParams } from "../../utils";
import Delete from "../../react-ui/Icons/Delete";
import ActionsContainer from "../../react-ui/ActionsContainer";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";

const selector = (state) => ({
  formToken: state.formToken,
  setSelectedParameter: state.setSelectedParameter,
});

export function UnityArray({ setParameterDetailsStatus, topRef }) {
  const { formToken, setSelectedParameter } = useStore(selector);

  const pageEntityWork = parseInt(getUrlParams(), 10);

  const { data: unities } = useTodo(
    formToken,
    `/unity_profession?entity_work_id=${getUrlParams()}&from=admin`,
    "Unities"
  );
  const { mutate: deleteUnity } = useDeleteTodo(
    formToken,
    `/unity_profession/delete`,
    "Unities"
  );

  const isDeletable = (entityWorkId) => entityWorkId === pageEntityWork;

  const handleDeleteUnity = (idUnity) => {
    deleteUnity(JSON.stringify({ id: idUnity }));
  };

  const data = [];
  let child;
  unities?.forEach((value) => {
    child = {
      id: value.id,
      name: value.name,
      abbreviation: value.abbreviation,
      delete: isDeletable(value.entity_work_id) ? (
        <ActionsContainer>
          <Delete
            onClick={() =>
              ShowDeleteConfirm(value.name, value.id, handleDeleteUnity)
            }
          />
        </ActionsContainer>
      ) : null,
    };
    data.push(child);
  });

  const openUpdate = (value) => {
    setSelectedParameter(value);
    setParameterDetailsStatus({ status: "update", type: "unity" });
  };

  return (
    <StyledTable
      columns={columnsUnity}
      dataSource={data}
      scroll={{ y: 400 }}
      pagination={false}
      rowClassName={["cursor-pointer"]}
      onRow={(record) => {
        return {
          onClick: () => {
            openUpdate(unities.find((el) => el.id === record.id));
            topRef.current?.scrollIntoView({ behavior: "smooth" });
          },
        };
      }}
    />
  );
}

const StyledTable = styled(TableContainer)`
  margin: 10px 0;
`;
