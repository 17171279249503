import { Tabs } from "antd";
import React from "react";
import styled from "styled-components";
import Documents from "./Documents";
import Folders from "./Folders";
import ProfilManagements from "./ProfilManagements";
import Templates from "./Templates";
import FileRules from "./FileRules";

function DocumentsFolder({
  works,
  companyWorks,
  entities,
  settableEntities,
  settableWorks,
  siteTypologies,
  parentEntity,
  canSetGlobalEntities,
  canSetGlobalWorks,
  users,
}) {
  const items = [
    {
      label: <TabHeader>Documents</TabHeader>,
      key: "1",
      children: (
        <Documents
          works={works}
          settableWorks={settableWorks}
          companyWorks={companyWorks}
          siteTypologies={siteTypologies}
          settableEntities={settableEntities}
          entities={entities}
          parentEntity={parentEntity}
          canSetGlobalEntities={canSetGlobalEntities}
          canSetGlobalWorks={canSetGlobalWorks}
        />
      ),
    },
    {
      label: <TabHeader>Dossiers</TabHeader>,
      key: "2",
      children: (
        <Folders
          works={works}
          companyWorks={companyWorks}
          entities={entities}
          siteTypologies={siteTypologies}
        />
      ),
    },
    {
      label: "Fichiers règles",
      key: "fileRules",
      children: <FileRules />,
    },
    // {
    //   label: "Profils",
    //   key: "3",
    //   children: <ProfilManagements users={users} />,
    // },
    {
      label: "Modèles",
      key: "templates",
      children: (
        <Templates
          works={works}
          companyWorks={companyWorks}
          settableWorks={settableWorks}
          canSetGlobalWorks={canSetGlobalWorks}
        />
      ),
    },
  ];

  return (
    <StyledTabs
      defaultActiveKey="1"
      type="card"
      style={{
        height: "calc(100% - 70px - 65.56px)",
        overflow: "auto",
      }}
      destroyInactiveTabPane
      items={items}
    />
  );
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }
`;

const TabHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export default DocumentsFolder;
