import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React from "react";
import styled from "styled-components";

function TemplateConfirmModal({
  addTemplateToTree,
  selectedTemplate,
  setSelectedTemplate,
  showTree,
  offer_id,
  opportunity_ids,
  getDoublesFileRules,
  setDoubleModal,
}) {
  const onCancel = () => setSelectedTemplate();
  return (
    <StyledModal
      open={!!selectedTemplate}
      closeIcon={false}
      maskClosable={false}
      onCancel={onCancel}
      title={
        <FooterContainer>
          <StyledExclamationCircleOutlined />
          La construction de dossiers n'est pas vide. Que souhaitez-vous faire ?
        </FooterContainer>
      }
      footer={
        <ButtonContainer>
          <Button key="1" onClick={onCancel}>
            Annuler
          </Button>
          <Button
            key="2"
            ghost
            type="primary"
            onClick={() => {
              showTree(
                {
                  id: selectedTemplate.id,
                  offer_id,
                  opportunity_ids,
                },
                {
                  onSuccess: (payload) => {
                    const doubles = getDoublesFileRules(payload.tree);
                    if (doubles.length < 1)
                      addTemplateToTree({
                        selectedTemplate,
                        treeData: payload.tree,
                        isReinstantiate: false,
                      });
                    else
                      setDoubleModal({
                        doubles,
                        selectedTemplate,
                        treeData: payload.tree,
                        isReinstantiate: false,
                      });
                  },
                }
              );
              onCancel();
            }}
          >
            Ajouter le modèle à la construction actuelle
          </Button>
          <Button
            key="3"
            danger
            ghost
            onClick={() => {
              showTree(
                {
                  id: selectedTemplate.id,
                  offer_id,
                  opportunity_ids,
                },
                {
                  onSuccess: (payload) => {
                    const doubles = getDoublesFileRules(payload.tree);
                    if (doubles.length < 1)
                      addTemplateToTree({
                        selectedTemplate,
                        treeData: payload.tree,
                        isReinstantiate: true,
                      });
                    else
                      setDoubleModal({
                        doubles,
                        selectedTemplate,
                        treeData: payload.tree,
                        isReinstantiate: true,
                      });
                  },
                }
              );
              onCancel();
            }}
          >
            Tout remplacer par le modèle
          </Button>
        </ButtonContainer>
      }
    />
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 800px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
`;

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  font-size: 22px;
  color: #faad14 !important;
`;

export default TemplateConfirmModal;
