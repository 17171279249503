import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Checkbox, Modal } from "antd";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useStore } from "../../store";
import { getData, postData } from "../../request/instance";
import TitleContainer from "../../react-ui/TitleContainer";
import AddButton from "../../react-ui/AddButton";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { stringSorter } from "../../../utils/stringSorter";
import { ShowDeleteConfirm } from "../confirmModal";
import Delete from "../../react-ui/Icons/Delete";
import ReferenceForm from "./ReferenceForm";
import { handlePictureKey } from "../../../utils/createFormData";
import { generateFormData } from "../../../utils/generateFormData";
import { stringToFloat } from "../../../utils/stringToFloat";
import { numberSorter } from "../../../utils/numberSorter";
import NumberCell from "../../react-ui/NumberCell";
import { formatNumberString } from "../../../utils/formatNumberString";
import { translate_filter } from "../Categories/translate_data";
import { fetchReferencesUrl } from "../../../utils/fetchReferencesUrl";

const selector = (state) => ({
  formToken: state.formToken,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

const columns = (handleDeleteReference, entities, siteTypologies) => [
  {
    title: "",
    dataIndex: "picture",
    fixed: "left",
    width: "50px",
    render: (picture) =>
      picture && (
        <img className="img-fluid" src={picture} alt="reference-pic" />
      ),
  },
  {
    title: "Nom du client",
    dataIndex: "name",
    fixed: "left",
    width: "200px",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Créée le",
    dataIndex: "created_at",
    width: "100px",
    sorter: (a, b) => stringSorter(a.created_at, b.created_at),
    render: (created_at) => (
      <NumberCell>
        {new Date(created_at).toLocaleDateString("fr-FR")}
      </NumberCell>
    ),
  },
  {
    title: "Global",
    dataIndex: "global",
    width: "70px",
    sorter: (a, b) => numberSorter(a.global, b.global),
    render: (global) => (
      <GlobalContainer>
        <Checkbox checked={global} disabled />
      </GlobalContainer>
    ),
  },
  {
    title: "Métier",
    dataIndex: "work",
    width: "170px",
    sorter: (a, b) => stringSorter(a.work, b.work),
  },
  {
    title: "Période",
    dataIndex: "period",
    width: "170px",
    sorter: (a, b) => stringSorter(a.period, b.period),
  },
  {
    title: "Montant",
    dataIndex: "total",
    width: "100px",
    sorter: (a, b) => numberSorter(a.total, b.total),
    render: (value) =>
      value ? (
        <NumberCell>
          {formatNumberString({ str: value, space: true })} €
        </NumberCell>
      ) : null,
  },
  {
    title: "Agences",
    dataIndex: "entities",
    width: "350px",
    filterMode: "tree",
    filters: translate_filter(entities, null, "parent_id", "name"),
    filterSearch: true,
    onFilter: (value, record) =>
      record.entity_ids && record.entity_ids.includes(parseInt(value, 10)),
    render: (entitiesStr) => entitiesStr || "",
  },
  {
    title: "Typologies",
    dataIndex: "site_typologies",
    filterMode: "tree",
    filters: translate_filter(siteTypologies, null, null, "typology"),
    filterSearch: true,
    onFilter: (value, record) =>
      record.site_typology_ids &&
      record.site_typology_ids.includes(parseInt(value, 10)),
    width: "100px",
    render: (site_typologies) => site_typologies || "",
  },
  {
    title: "",
    dataIndex: "",
    fixed: "right",
    align: "center",
    width: "20px",
    render: (record) => (
      <Delete
        onClick={(e) => {
          e.stopPropagation();
          ShowDeleteConfirm(record.name, record.id, handleDeleteReference);
        }}
      />
    ),
  },
];

function References({ works, companyWorks, siteTypologies, entities }) {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);
  const [status, setStatus] = useState("empty");
  const [selectedReference, setSelectedReference] = useState();
  const [wordEntered, setWordEntered] = useState("");

  const { data: references, refetch } = useQuery("References", () =>
    getData(formToken, fetchReferencesUrl({ search: wordEntered }))
  );
  const { mutate: createReference } = useMutation(
    (todo) => postData(formToken, "/reference/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("References");
      },
    }
  );

  const { mutate: updateReference } = useMutation(
    (todo) => postData(formToken, "/reference/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("References");
      },
    }
  );

  const { mutate: deleteReference } = useMutation(
    (todo) => postData(formToken, "/reference/delete", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("References");
      },
    }
  );

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  const dataSource = useMemo(() => {
    if (!references) return [];
    return references.map((reference) => {
      const workId = companyWorks.find(
        (cw) => reference.company_work_id === cw.id
      ).work_id;
      return {
        ...reference,
        work: works.find((w) => w.id === workId).fullname,
        entities: entities
          .filter((st) => reference.entity_ids?.includes(st.id))
          .map((st) => st.name)
          .join(", "),
        site_typologies: siteTypologies
          .filter((st) => reference.site_typology_ids?.includes(st.id))
          .map((st) => st.typology)
          .join(", "),
      };
    });
  }, [references, companyWorks, works, siteTypologies, entities]);

  const openModal = () => {
    setStatus("create");
  };

  const initialValues = useMemo(() => {
    if (status !== "update" || !selectedReference) return undefined;

    const workId = companyWorks.find(
      (cw) => selectedReference.company_work_id === cw.id
    ).work_id;
    const picture = selectedReference.picture
      ? [
          {
            uid: "1",
            name: "image",
            status: "done",
            url: selectedReference.picture,
          },
        ]
      : [];

    return {
      ...selectedReference,
      picture,
      entities: entities
        .filter((entity) => selectedReference.entity_ids.includes(entity.id))
        ?.map((el) => ({
          id: el.id,
          pId: el.parent_id,
          title: el.name,
          value: el.id,
        })),
      work_id: workId,
    };
  }, [status, companyWorks, selectedReference, entities]);

  const closeModal = () => {
    setStatus("empty");
    setSelectedReference();
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const handleSubmit = (values) => {
    const formData = generateFormData({
      e: values,
      formName: "reference_admin",
      excludedKeys: [
        "picture",
        "work_id",
        "site_typology_ids",
        "entities",
        "total",
      ],
    });
    const entity_ids = values.entities?.map((el) => el.value);
    if (values.picture) {
      handlePictureKey({
        formData,
        picture: values.picture,
        keyName: "reference_admin",
      });
    }
    values.site_typology_ids?.forEach((el) =>
      formData.append("site_typology_ids[]", el)
    );
    entity_ids?.forEach((el) => formData.append("entity_ids[]", el));
    if (values.total)
      formData.append("reference_admin[total]", stringToFloat(values.total));
    if (status === "create") {
      const workId = works.length > 1 ? values.work_id : works[0].id;
      const companyWorkId = companyWorks.find((cw) => workId === cw.work_id).id;
      formData.append("reference_admin[company_work_id]", companyWorkId);
      createReference(formData);
    } else if (status === "update") {
      formData.append("id", selectedReference.id);
      updateReference(formData);
    }
    closeModal();
  };

  const handleDeleteReference = (id) => deleteReference({ id });

  return (
    <>
      <ReferenceModal
        open={status !== "empty"}
        maskClosable={false}
        footer={null}
        width={1000}
        destroyOnClose
        onCancel={closeModal}
        closable={false}
      >
        <ReferenceForm
          works={works}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          referenceStatus={status}
          siteTypologies={siteTypologies}
          entities={entities}
          closeModal={closeModal}
        />
      </ReferenceModal>
      <TitleContainer label="Références disponibles">
        <AddButton label="Référence" onClick={openModal} />
      </TitleContainer>
      <StyledSearch
        allowClear
        placeholder="Rechercher par nom de référence"
        value={wordEntered}
        onChange={handleFilter}
      />
      <TableContainer
        bordered={false}
        columns={columns(handleDeleteReference, entities, siteTypologies)}
        rowKey="id"
        dataSource={dataSource}
        pagination={false}
        isRowSelectable
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedReference(
                references.find((el) => el.id === record.id)
              );
              setStatus("update");
            },
          };
        }}
        scrollY
      />
    </>
  );
}

const ReferenceModal = styled(Modal)`
  .ant-modal-content {
    min-height: 300px;
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

const StyledSearch = styled(Search)`
  margin-bottom: 12px;
`;

const GlobalContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default References;
