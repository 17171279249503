import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cost_coefficient_revenue"]

  disabled_buttons_next() {
    let breadcrumbs = document.getElementsByClassName('breadcrumb')
    let breadcrumbs_separators = document.getElementsByClassName('breadcrumb-separator')
    let breadcrumbs_selected = document.getElementsByClassName('breadcrumb-selected')
    let breadcrumbs_clicked = document.getElementsByClassName('breadcrumb-clicked')
    let breadcrumbs_separators_clicked = document.getElementsByClassName('breadcrumb-separator-clicked')
    let breadcrumbs_selected_clicked = document.getElementsByClassName('breadcrumb-selected-clicked')
    breadcrumbs.forEach (breadcrumb => breadcrumb.hidden=true)
    breadcrumbs_separators.forEach (breadcrumb_separator => breadcrumb_separator.hidden=true)
    breadcrumbs_selected.forEach (breadcrumb_selected => breadcrumb_selected.hidden=true)
    breadcrumbs_clicked.forEach (breadcrumb_clicked => breadcrumb_clicked.hidden=false)
    breadcrumbs_separators_clicked.forEach (breadcrumb_separator_clicked => breadcrumb_separator_clicked.hidden=false)
    breadcrumbs_selected_clicked.forEach (breadcrumb_selected_clicked => breadcrumb_selected_clicked.hidden=false)
  }

  disableButton(){
    const submitButton = document.querySelector(".submit-btn")
    submitButton.disabled = true
  }

  enableButton(){
    const submitButton = document.querySelector(".submit-btn")
    submitButton.disabled = false
  }

  change_cost_coefficient_revenue() {
    const errorLabel = document.querySelector(".error-label")
    const submitButton = document.querySelector(".submit-btn")
    if(!this.checkField()){
      errorLabel.textContent = 'Il est impossible d\'avoir un taux de marge de plus de 100%'
    }
    else if(!this.checkFieldNull()){
      errorLabel.textContent = 'Merci d\'entrer un taux de marge'
    }
    else{
      submitButton.disabled = true
      errorLabel.textContent = "Mise à jour en cours ..."
      let opportunityId = this.cost_coefficient_revenueTarget.dataset["opportunityId"]
          fetch(`/opportunities/${opportunityId}/update`, {
              method: "PUT",
              headers: {  "Content-Type": "application/json",
              "Accept": "text/javascript",
                "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
                      },
              body: JSON.stringify({
                cost_coefficient_revenue: this.cost_coefficient_revenueTarget.value
              })
            }).then(function(response) { return response.text() }).then(function(body) {
              eval(body)
              window.location.reload()
              submitButton.disabled = true
            }.bind(this));
          }
  }
  checkField(){
    const input = this.cost_coefficient_revenueTarget
    const calculMethod = document.querySelector(".calcul-method").value
    const submitButton = document.querySelector(".submit-btn")
    if(calculMethod === "selling_price"){
      const value = Number.parseFloat(input.value.replace(" ", "").replace(",", ".")).toFixed(2)
      if(value >= 100){
        submitButton.disabled = true
        input.value = 0
        return false
      }
      submitButton.disabled = false
      return true
    } else {
      submitButton.disabled = false
      return true
    }
  }
  checkFieldNull(){
    const input = this.cost_coefficient_revenueTarget
    const submitButton = document.querySelector(".submit-btn")
    const value = Number.parseFloat(input.value.replace(" ", "").replace(",", ".")).toFixed(2)
      if(value == 'NaN'){
        submitButton.disabled = true
        input.value = 0
        return false
      }
      submitButton.disabled = false
      return true
  }
}
