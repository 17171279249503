import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['previous', 'next', 'save', 'new', 'edit']
  
  disabled_breadcrumb(){
    let breadcrumbs = document.getElementsByClassName('breadcrumb')
    let breadcrumbs_separators = document.getElementsByClassName('breadcrumb-separator')
    let breadcrumbs_selected = document.getElementsByClassName('breadcrumb-selected')
    let breadcrumbs_clicked = document.getElementsByClassName('breadcrumb-clicked')
    let breadcrumbs_separators_clicked = document.getElementsByClassName('breadcrumb-separator-clicked')
    let breadcrumbs_selected_clicked = document.getElementsByClassName('breadcrumb-selected-clicked')
    breadcrumbs.forEach (breadcrumb => breadcrumb.hidden=true)
    breadcrumbs_separators.forEach (breadcrumb_separator => breadcrumb_separator.hidden=true)
    breadcrumbs_selected.forEach (breadcrumb_selected => breadcrumb_selected.hidden=true)
    breadcrumbs_clicked.forEach (breadcrumb_clicked => breadcrumb_clicked.hidden=false)
    breadcrumbs_separators_clicked.forEach (breadcrumb_separator_clicked => breadcrumb_separator_clicked.hidden=false)
    breadcrumbs_selected_clicked.forEach (breadcrumb_selected_clicked => breadcrumb_selected_clicked.hidden=false)
  }

  disabled_buttons_next() {
    let button_next = this.nextTarget;
    let button_save = document.getElementById('update_all_outfits_btn')
    let button_new = document.getElementById('button_new_outfit')
    // this.disabled_breadcrumb()
    button_next.disabled = true
    button_save.hidden = true
    button_new.hidden = true
     if (this.hasEditTarget){
       let button_edit = this.editTarget;
       button_edit.hidden = true
     }
  }

  disabled_buttons_previous() {
    let button_previous = this.previousTarget;
    let button_save = document.getElementById('update_all_outfits_btn')
    let button_new = document.getElementById('button_new_outfit')
    // this.disabled_breadcrumb()
    button_previous.disabled = true
    button_save.hidden = true
    button_new.hidden = true
    if (this.hasEditTarget){
      let button_edit = this.editTarget;
      button_edit.hidden = true
    }
  }

  disabled_buttons_save() {
    let button_previous = this.previousTarget;
    let button_next = this.nextTarget;
    let button_new = document.getElementById('button_new_outfit')
    let button_save = document.getElementById('update_all_outfits_btn')
    // this.disabled_breadcrumb()
    button_previous.disabled = true
    button_next.disabled = true
    button_new.hidden = true
    button_save.innerHTML = "<div class='d-flex align-items-center'><i class='fas fa-spin fa-spinner text-success mr-1'></i> <p class='text-success mb-0'>Enregistrement</p></div>";
    if (this.hasEditTarget){
      let button_edit = this.editTarget;
      button_edit.hidden = true
    }
  }

  disabled_buttons(){
    let button_previous = this.previousTarget;
    let button_next = this.nextTarget;
    let button_new = document.getElementById('button_new_outfit')
    let button_save = document.getElementById('update_all_outfits_btn')
    // this.disabled_breadcrumb()
    button_save.hidden = true
    button_previous.disabled = true
    button_next.disabled = true
    button_new.hidden = true
    if (this.hasEditTarget){
      let button_edit = this.editTarget;
      button_edit.hidden = true
    }
  }
}