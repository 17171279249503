import React, { useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import Separator from "../../../react-ui/Separator";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import ProfilArray from "./ProfilArray";
import ProfilDetails from "./ProfilDetails";

const selector = (state) => ({
  formToken: state.formToken,
});

function ProfilRules({ works, companyWorks }) {
  const { formToken } = useStore(selector);
  const [profilStatus, setProfilStatus] = useState();
  const [selectedProfilRule, setSelectedProfilRule] = useState();

  const { data: profilRules } = useQuery(
    "ProfilRules",
    () => getData(formToken, "/profil_rules"),
    { refetchOnWindowFocus: false }
  );
  const { data: rules } = useQuery(
    "Rules",
    () => getData(formToken, "/rules"),
    { refetchOnWindowFocus: false }
  );

  const { data: ruleProfilRules } = useQuery(
    ["RuleProfilRules", { ruleProfilRuleId: selectedProfilRule?.id }],
    () =>
      getData(
        formToken,
        `/rule_profil_rules?profil_rule_id=${selectedProfilRule?.id}`
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedProfilRule,
    }
  );

  const { data: users } = useQuery(
    "Users",
    async () => {
      const data = await getData(
        formToken,
        `/users_by_work?work_ids=${works.map((el) => el.id)}`
      );
      return data.map((el) => ({
        ...el,
        fullname: `${el.first_name} ${el.last_name}`,
      }));
    },
    { refetchOnWindowFocus: false }
  );

  return (
    <Container>
      <ProfilArray
        profilStatus={profilStatus}
        setProfilStatus={setProfilStatus}
        profilRules={profilRules}
        works={works}
        companyWorks={companyWorks}
        selectedProfilRule={selectedProfilRule}
        setSelectedProfilRule={setSelectedProfilRule}
        users={users}
      />
      <Separator />
      <ProfilDetails
        profilStatus={profilStatus}
        setProfilStatus={setProfilStatus}
        companyWorks={companyWorks}
        works={works}
        selectedProfilRule={selectedProfilRule}
        setSelectedProfilRule={setSelectedProfilRule}
        rules={rules?.filter(
          (r) =>
            selectedProfilRule &&
            r.company_work_id === selectedProfilRule.company_work_id
        )}
        users={users}
        ruleProfilRules={ruleProfilRules?.filter(
          (rpr) => rpr.profil_rule_id === selectedProfilRule?.id
        )}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 10px;
`;

export default ProfilRules;
