import React, { useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { Loading } from "../../loading";
import Separator from "../../react-ui/Separator";
import TitleContainer from "../../react-ui/TitleContainer";
import { postData } from "../../request/instance";
import { useStore } from "../../store";
import { formatNumberString } from "../../../utils/formatNumberString";
import { numberSorter } from "../../../utils/numberSorter";
import NumberCell from "../../react-ui/NumberCell";
import { stringSorter } from "../../../utils/stringSorter";
import Button from "../../react-ui/Button";
import AddButton from "../../react-ui/AddButton";
import DifficultyForm from "../GeneralParameters/DifficultyForm";

const selector = (state) => ({
  formToken: state.formToken,
});

export const columnsDiff = [
  {
    title: "Intitulé",
    dataIndex: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
    width: "50%",
  },
  {
    title: "Coefficient",
    dataIndex: "rate",
    sorter: (a, b) => numberSorter(a.rate, b.rate),
    width: "30%",
    render: (value) => (value ? <NumberCell>{value}</NumberCell> : null),
  },
];

function FADifficulties({ selectedFA, difficulties }) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const [selectedDiff, setSelectedDiff] = useState();
  const [diffFormStatus, setDiffFormStatus] = useState("empty");

  const { mutate: updateDifficulty, status } = useMutation(
    (todo) => postData(formToken, `/difficulty_profession/update`, todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Difficulties");
      },
    }
  );

  const { mutate: createDifficulty } = useMutation(
    (todo) => postData(formToken, `/difficulty_profession/create`, todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Difficulties");
      },
    }
  );

  const handleRegisterParameterProfession = (e) => {
    const formatableKeys = ["rate"];
    const formData = new FormData();
    Object.keys(e).forEach(
      (key) =>
        !formatableKeys.includes(key) &&
        formData.append(
          `difficulty_profession[${key}]`,
          e[key] !== undefined && e[key] !== null ? e[key] : ""
        )
    );
    formData.append(
      `difficulty_profession[rate]`,
      e.rate ? parseFloat(e.rate.replace(/,/g, ".")) : ""
    );
    formData.append(
      `difficulty_profession[framework_agreement_id]`,
      selectedFA.id
    );
    if (diffFormStatus === "update") {
      formData.append("id", selectedDiff.id);
      updateDifficulty(formData);
    }
    if (diffFormStatus === "create") {
      formData.append("framework_agreement_id", selectedFA.id);
      createDifficulty(formData);
      setDiffFormStatus("empty");
    }
  };

  const getStatus = () => {
    if (status === "loading") return "loading";
    if (status === "success") return "success";
    return "idle";
  };

  const data = useMemo(
    () =>
      difficulties?.map((value) => ({
        ...value,
        key: value.id,
        rate: formatNumberString({ str: value.rate }),
      })),
    [difficulties]
  );

  return (
    <Container>
      <Wrapper>
        <TitleContainer label="Difficultés de l'accord-cadre">
          <AddButton
            onClick={() => setDiffFormStatus("create")}
            disabled={diffFormStatus === "create"}
            label="Difficulté"
          />
        </TitleContainer>
        <TableContainer
          columns={columnsDiff}
          dataSource={data}
          scroll={{ y: 400 }}
          pagination={false}
          rowClassName={["cursor-pointer"]}
          onRow={(record) => {
            return {
              onClick: () => {
                setSelectedDiff(difficulties.find((el) => el.id === record.id));
                setDiffFormStatus("update");
              },
            };
          }}
        />
      </Wrapper>
      <Separator />
      <Wrapper>
        <TitleContainer label="Détails de la difficulté">
          {diffFormStatus === "create" && (
            <>
              <StyledButton
                label="Annuler"
                onClick={() => setDiffFormStatus("empty")}
                fontSize="14px"
                btnType="cancel"
              />
              <AddButton
                label="Créer"
                type="submit"
                value="submit"
                fontSize="14px"
                form="parameter-form"
              />
            </>
          )}
          <Loading status={getStatus()} />
        </TitleContainer>
        {diffFormStatus === "empty" && (
          <div className="d-flex justify-content-center align-items-center">
            <p className="my-0">
              Sélectionnez ou créez un paramètre pour afficher les détails
            </p>
          </div>
        )}
        {diffFormStatus !== "empty" && (
          <DifficultyForm
            updateOnChange={diffFormStatus === "update"}
            initialDifficulty={
              diffFormStatus === "update" ? selectedDiff : undefined
            }
            onSubmit={handleRegisterParameterProfession}
          />
        )}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  width: 50%;
  height: 100%;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default FADifficulties;
