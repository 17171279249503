import { Form } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { validateMessages } from "../../../constant";
import FormRow from "../../../Form/FormRow";
import FormCol from "../../../Form/FormCol";
import TitleContainer from "../../../react-ui/TitleContainer";
import AddButton from "../../../react-ui/AddButton";
import Input from "../../../react-ui/Input";
import Button from "../../../react-ui/Button";
import SecurityCol, { securityFields } from "../Folders/SecurityCol";
import Select from "../../../react-ui/Select";
import Tag from "../../../react-ui/Tag";

function FileRuleForm({
  handleSubmit,
  initialValues,
  status,
  closeModal,
  formIsLoading,
  folders = [],
}) {
  const [form] = Form.useForm();
  const location = Form.useWatch("location", form);
  const folderId = Form.useWatch("folder_id", form);
  const security = Form.useWatch("security", form);
  const [hiddenSecurityFields, setHiddenSecurityFields] = useState([]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    if (location === "offer_opportunity") {
      form.setFieldsValue({
        folder_id: undefined,
        security: undefined,
      });
      setHiddenSecurityFields([]);
    }
  }, [form, location]);

  useEffect(() => {
    if (folderId) {
      const selectedFolder = folders.find((f) => f.id === folderId);
      const folderSecurity = selectedFolder.security;
      const secu = form.getFieldValue("security");
      let newHidden = [];
      if (folderSecurity === "entity") {
        newHidden = securityFields
          .filter((sf) => sf.id !== folderSecurity)
          .map((sf) => sf.id);
      } else if (folderSecurity === "entity_global") {
        newHidden = securityFields
          .filter((sf) => sf.id === "entity_related_global")
          .map((sf) => sf.id);
      }
      setHiddenSecurityFields(newHidden);
      if (newHidden.some((id) => secu === id))
        form.setFieldValue("security", undefined);
    }
  }, [form, folderId, folders]);

  const getTitle = () => {
    return status === "create"
      ? "Créer un fichier règle"
      : "Modifier un fichier règle";
  };

  const getLocationCustomContent = useMemo(() => {
    if (!location) return undefined;
    const customContent = "Recherche dans les dossiers créés ";
    if (location === "offer_opportunity") {
      return `${customContent}dans l'offre et ses opportunités`;
    }
    return `${customContent}en admin`;
  }, [location]);

  return (
    <Form
      id="file-rule-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
      <TitleContainer label={getTitle()}>
        <StyledButton
          label="Annuler"
          onClick={closeModal}
          fontSize="14px"
          btnType="cancel"
          type="button"
        />

        <AddButton
          label={status === "create" ? "Créer" : "Modifier"}
          type="submit"
          value="submit"
          fontSize="14px"
          loading={formIsLoading}
        />
      </TitleContainer>

      <FormContainer>
        <FormRow>
          <FormCol
            width="50%"
            label="Localisation"
            name="location"
            rules={[{ required: true }]}
          >
            <Select
              showSearch={false}
              customContent={getLocationCustomContent}
              options={[
                {
                  name: "Admin",
                  id: "admin",
                },
                {
                  name: "Offres et opportunités",
                  id: "offer_opportunity",
                },
              ]}
            />
          </FormCol>
          <FormCol
            width="50%"
            label="Nom"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </FormCol>
        </FormRow>

        {location === "admin" && (
          <FormRow>
            <FormCol
              width="50%"
              label="Dossier lié"
              name="folder_id"
              rules={[{ required: true }]}
            >
              <Select
                keysToSearch={["title"]}
                options={folders.map((folder) => ({
                  title: folder.name,
                  name: (
                    <Tag
                      color={folder.color || "#f0f0f0"}
                      label={folder.name}
                    />
                  ),
                  id: folder.id,
                }))}
              />
            </FormCol>
            <SecurityCol
              hiddenSecurityFields={hiddenSecurityFields}
              security={security}
            />
          </FormRow>
        )}
      </FormContainer>
    </Form>
  );
}

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

export default FileRuleForm;
