import { Segmented, Alert, Space, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import SegmentLabel from "../../react-ui/SegmentLabel";
import { postData } from "../../request/instance";
import { useStore } from "../../store";
import FamilyAssociates from "./FamilyAssociates";
import PrestationAssociates from "./PrestationAssociates";
import MaterialAssociates from "./MaterialAssociates";
import MaterialLinked from "./MaterialLinked";
import CategoryLinked from "./CategoryLinked";
import OuvrageAssociate from "./OuvrageAssociate";

const options = (configuration, selectedSegment, tabs) =>
  [
    {
      value: "details",
      label: (
        <SegmentLabel isSelected={selectedSegment === "details"}>
          Détails
        </SegmentLabel>
      ),
    },
    {
      value: "material_associates",
      label: (
        <SegmentLabel isSelected={selectedSegment === "material_associates"}>
          <i className={configuration.general.product_icon} /> compatibles
        </SegmentLabel>
      ),
    },
    {
      value: "prestation_associates",
      label: (
        <SegmentLabel isSelected={selectedSegment === "prestation_associates"}>
          <i className={configuration.general.prestation_icon} /> associées
        </SegmentLabel>
      ),
    },
    {
      value: "family_associates",
      label: (
        <SegmentLabel isSelected={selectedSegment === "family_associates"}>
          <i className={configuration.general.family_icon} /> associées
        </SegmentLabel>
      ),
    },
    configuration.group === true
      ? {
          value: "ouvrage_associates",
          label: (
            <SegmentLabel isSelected={selectedSegment === "ouvrage_associates"}>
              <i className={configuration.general.group_icon} /> associés
            </SegmentLabel>
          ),
        }
      : {},
    {
      value: "material_linked",
      label: (
        <SegmentLabel isSelected={selectedSegment === "material_linked"}>
          <i className={configuration.general.product_icon} /> liés
        </SegmentLabel>
      ),
    },
    {
      value: "category_linked",
      label: (
        <SegmentLabel isSelected={selectedSegment === "category_linked"}>
          <i className={configuration.general.category_icon} /> liées
        </SegmentLabel>
      ),
    },
  ].filter((tab) => tab.value === "details" || tabs.includes(tab.value));

const selector = (state) => ({
  selectedMateriel: state.selectedMateriel,
  formToken: state.formToken,
  selectedFamily: state.selectedFamily,
});

function DetailTabs({
  children,
  configuration,
  detailsStatus,
  manufacturers,
  distributors,
  from,
  categories,
  unities,
  opportunityId,
  entityWorkId,
  faId,
  faIsValidate,
  parent,
  vocabulary,
  setItem,
  categoriesFilter,
  entity_work_name,
  handleFamilyChecked,
  familiesAssociates,
  setMaterialsParent,
  materialsParent,
  defaultMajoration,
  round_type_list,
  tabs = [],
}) {
  const queryClient = useQueryClient();
  const { formToken, selectedFamily, selectedMateriel } = useStore(selector);
  const [selectedSegment, setSelectedSegment] = useState("details");
  const [impactedModelZone, setImpactedModelZone] = useState([]);
  const getUpdateAssoUrl = () => {
    if (from === "referencing") return "associate_opportunity";
    if (from === "frameworkAgreement") return "asso_framework_agreement";
    return "associate";
  };

  useEffect(() => {
    setImpactedModelZone([]);
  }, [selectedFamily, selectedMateriel]);

  const { mutate: updateMaterialAssociate } = useMutation(
    (todo) =>
      postData(formToken, `/material_${getUpdateAssoUrl()}/update`, todo),
    {
      onSettled: (data) => {
        queryClient.invalidateQueries("MaterialsAssociates");
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  const { mutate: updatePrestationAssociate } = useMutation(
    (todo) =>
      postData(formToken, `/prestation_${getUpdateAssoUrl()}/update`, todo),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("PrestationsAssociates");
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  useEffect(() => {
    if (detailsStatus === "create") {
      setSelectedSegment("details");
    }
  }, [detailsStatus]);

  return (
    <Container>
      {impactedModelZone?.length > 0 && (
        <AlertContainer>
          <Alert
            showIcon
            message={
              <>
                Les modèles de zones suivants ne prennent pas en compte cette
                modification.
                <br />
                Vous devez modifier les associations directement depuis ces
                modèles.
                <br />
                <ul style={{ marginBottom: "0px" }}>
                  {impactedModelZone.map((zone) => (
                    <li key={zone.id}>{zone}</li>
                  ))}
                </ul>
              </>
            }
            type="warning"
            closable
          />
        </AlertContainer>
      )}
      {detailsStatus === "update" && (
        <>
          <Segmented
            style={{
              position: "sticky",
              top: 0,
              zIndex: 999,
              width: "100%",
              padding: 0,
              borderRadius: 0,
            }}
            options={options(configuration, selectedSegment, tabs)}
            onChange={(e) => {
              if (setItem) setItem();
              setSelectedSegment(e);
            }}
            value={selectedSegment}
          />
          {selectedSegment === "details" && children}
          {selectedSegment === "prestation_associates" && (
            <PrestationAssociates
              categories={categories}
              unities={unities}
              updatePrestationAssociate={updatePrestationAssociate}
              from={from}
              opportunityId={opportunityId}
              entityWorkId={entityWorkId}
              faId={faId}
              faIsValidate={faIsValidate}
              parent={parent}
              configuration={configuration}
              vocabulary={vocabulary}
              defaultMajoration={defaultMajoration}
              setImpactedModelZone={setImpactedModelZone}
            />
          )}
          {selectedSegment === "material_associates" && (
            <MaterialAssociates
              categories={categories}
              unities={unities}
              manufacturers={manufacturers}
              distributors={distributors}
              updateMaterialAssociate={updateMaterialAssociate}
              from={from}
              opportunityId={opportunityId}
              entityWorkId={entityWorkId}
              faId={faId}
              faIsValidate={faIsValidate}
              parent={parent}
              configuration={configuration}
              vocabulary={vocabulary}
              setMaterialsParent={setMaterialsParent}
              materialsParent={materialsParent}
              round_type_list={round_type_list}
              setImpactedModelZone={setImpactedModelZone}
            />
          )}
          {selectedSegment === "family_associates" && (
            <FamilyAssociates entityWorkId={entityWorkId} parent={parent} />
          )}
          {selectedSegment === "material_linked" && (
            <MaterialLinked
              categoriesFilter={categoriesFilter}
              entityWorkId={entityWorkId}
              from={from}
              opportunityId={opportunityId}
              faIsValidate={faIsValidate}
              faId={faId}
              entity_work_name={entity_work_name}
              handleFamilyChecked={handleFamilyChecked}
              familiesAssociates={familiesAssociates}
              configuration={configuration}
              vocabulary={vocabulary}
              parent={parent}
            />
          )}
          {selectedSegment === "category_linked" && (
            <CategoryLinked
              categories={categories}
              familiesAssociates={familiesAssociates}
              handleFamilyChecked={handleFamilyChecked}
            />
          )}
          {selectedSegment === "ouvrage_associates" && (
            <OuvrageAssociate
              entityWorkId={entityWorkId}
              categories={categories}
              fromToken={formToken}
              parent={parent}
              setImpactedModelZone={setImpactedModelZone}
            />
          )}
        </>
      )}
      {detailsStatus === "create" && children}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const AlertContainer = styled.div`
  margin: 10px;
`;

export default DetailTabs;
