export const findItemByKey = ({ oldData, key, parentKeys }) => {
  const foundItem = oldData.find((el) => el.key === key);
  if (foundItem) return { ...foundItem };

  return findItemByKey({
    oldData: oldData.find((el) => parentKeys?.includes(el.key)).children,
    key,
    parentKeys,
  });
};

export const findItemByIdType = ({ data, id, type }) => {
  const item = data.find((el) => el.id === id && el.type === type);
  if (item) return item;

  for (let i = 0; i < data.length; i += 1) {
    if (data[i].children) {
      const potentialItem = findItemByIdType({
        data: data[i].children,
        id,
        type,
      });
      if (potentialItem) return potentialItem;
    }
  }
  return undefined;
};

export const findItemByKeyOnly = ({ data, key }) => {
  const item = data.find((el) => el.key === key);
  if (item) return item;

  for (let i = 0; i < data.length; i += 1) {
    if (data[i].children) {
      const potentialItem = findItemByKeyOnly({
        data: data[i].children,
        key,
      });
      if (potentialItem) return potentialItem;
    }
  }
  return undefined;
};

export const findIsRootItem = (record) => {
  return !record.parentKeys || record.parentKeys.length === 0;
};

export const findRootItemIdx = (oldData, record) => {
  return oldData.findIndex((item) => {
    return (
      item.key === record.key ||
      (record.parentKeys?.includes(item.key) &&
        findRootItemIdx(item.children, record) !== -1)
    );
  });
};

export const findItemPositionById = ({ data, id, excludedNodes = [] }) => {
  const position = data.findIndex((el) => el.id === id);
  if (position !== -1) return position;

  for (let i = 0; i < data.length; i += 1) {
    if (data[i].children) {
      const potentialPosition = findItemPositionById({
        data: data[i].children.filter(
          (child) =>
            !excludedNodes.some((excludedKey) => excludedKey === child.type)
        ),
        id,
        excludedNodes,
      });
      if (potentialPosition !== -1 && potentialPosition !== null)
        return potentialPosition;
    }
  }
  return null;
};

export const findItemParentIdById = ({ data, id }) => {
  const position = data.findIndex((el) => el.id === id);
  if (position !== -1) return null;

  for (let i = 0; i < data.length; i += 1) {
    if (data[i].children) {
      const potentialParent = findItemParentIdById({
        data: data[i].children,
        id,
      });
      if (potentialParent === null) return data[i].id;
      if (potentialParent !== null && potentialParent !== undefined)
        return potentialParent;
    }
  }
  return undefined;
};

export const getNewRootItem = ({
  item,
  record,
  deleteItem = true,
  direction,
  recordToAdd,
  recordToReplace,
  recordsToReplace,
}) => {
  if (!item.children || item.children.length === 0) {
    return { ...item };
  }
  const recordIdx = item.children.findIndex(
    (child) => child.key === record.key
  );
  if (recordIdx !== -1) {
    const newChildren = [...item.children];
    if (deleteItem) {
      newChildren.splice(recordIdx, 1);
    } else if (recordsToReplace) {
      newChildren[recordIdx].children = recordsToReplace;
    } else if (recordToReplace) {
      newChildren[recordIdx] = recordToReplace;
    } else {
      newChildren.splice(
        direction === "downward" ? recordIdx + 1 : recordIdx,
        0,
        recordToAdd
      );
    }
    return { ...item, children: newChildren };
  }
  const newChildren = [];
  for (let i = 0; i < item.children.length; i += 1) {
    newChildren.push(
      getNewRootItem({
        item: item.children[i],
        record,
        deleteItem,
        direction,
        recordToAdd,
        recordToReplace,
        recordsToReplace,
      })
    );
  }
  return { ...item, children: newChildren };
};

export const reconstructRecord = ({
  oldRecord,
  grandParentKeys = [],
  recordId,
}) => {
  const newKey = `${recordId !== undefined ? `${recordId}-` : ""}${
    oldRecord.key
  }`;
  const parentKeys = [...grandParentKeys, newKey];

  return {
    ...oldRecord,
    key: newKey,
    parentKeys: [],
    children: oldRecord.children
      ? oldRecord.children.map((child) => ({
          ...reconstructRecord({
            oldRecord: child,
            grandParentKeys: parentKeys,
            recordId,
          }),
          parentKeys,
        }))
      : undefined,
  };
};
