import React from "react";
import { Modal } from "antd";

import styled from "styled-components";
import TitleContainer from "../../../react-ui/TitleContainer";
import GuardingInfos from "./GuardingInfos";

function ConfirmModal({
  isModalOpen,
  handleCancel,
  handleOk,
  message,
  modalAction,
  record,
  entities,
  userEntityId,
  token,
  refetchOffer,
}) {
  return (
    <div>
      <StyledModal
        open={isModalOpen}
        maskClosable={false}
        destroyOnClose
        onCancel={handleCancel}
        onOk={handleOk}
        okButtonProps={{
          form: "duplicate-opportunity-form",
          key: "submit",
          htmlType: "submit",
        }}
      >
        <TitleContainer label={message.title} />
        <Container>
          <p>{message.content}</p>
          {modalAction === "duplicate" && record && (
            <GuardingInfos
              record={record}
              entities={entities}
              userEntityId={userEntityId}
              token={token}
              refetchOffer={refetchOffer}
            />
          )}
        </Container>
      </StyledModal>
    </div>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
  .ant-modal-footer {
    padding: 0 24px 24px !important;
  }
`;

const Container = styled.div`
  padding: 0 24px;
`;

export default ConfirmModal;
