import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Checkbox, Form, Row } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import i18next from "i18next";
import TitleContainer from "../react-ui/TitleContainer";
import FormRow from "../Form/FormRow";
import FormCol from "../Form/FormCol";
import Input from "../react-ui/Input";
import Select from "../react-ui/Select";
import ButtonCol from "../Admin-cf/Users/Form/ButtonCol";
import Separator from "../react-ui/Separator";
import UserRights from "../Admin-cf/Users/Form/UserRights";
import Avatar from "../Admin-cf/Users/Form/Avatar";
import { useStore } from "../store";
import { generateFormData } from "../../utils/generateFormData";
import { getData, patchData, postData } from "../request/instance";
import { useLazyQuery } from "../../hooks/useLazyQuery";
import { handlePictureKey } from "../../utils/createFormData";
import { typesArray } from "../../constants/typesAndRoles";
import CopySecret from "../react-ui/CopySecret";
import Loader from "../react-ui/Loader";

const genderArray = [
  { name: "M.", id: "M." },
  { name: "Mme.", id: "Mme." },
];

function ProfileForm({
  formToken,
  angencie_name,
  work_names,
  procurement,
  sites,
  current_user,
  option_documents,
}) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [status, setStatus] = useState("update");
  const isShowing = status === "show";
  const isUpdating = status === "update";

  const {
    data: user,
    refetch,
    isLoading: usersAreLoading,
  } = useQuery("User", () => getData(formToken, `/users/${current_user.id}`), {
    refetchOnWindowFocus: false,
  });

  const [loadAvatar] = useLazyQuery(
    ["loadAvatar"],
    () => getData(formToken, `/user/load_avatar?id=${current_user.id}`),
    {
      onSuccess: (avatar) => {
        if (avatar)
          form.setFieldValue("avatar", [
            {
              uid: "1",
              name: "image",
              status: "done",
              url: avatar,
            },
          ]);
      },
    }
  );

  const { mutate: updateUser, isLoading } = useMutation(
    (todo) => patchData(formToken, `/users/${current_user.id}`, todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Users");
      },

      onSuccess: (payload) => {
        setStatus("show");
      },
    }
  );

  const generateUserFormData = useCallback(
    (values) => {
      const pictureName = "avatar";
      const formName = "user";
      const formData = generateFormData({
        e: values,
        formName,
        excludedKeys: [
          pictureName,
          "active",
          "user_entity_work_ids",
          "client_ids",
          "api_token",
        ],
      });

      //   formData.append(`${formName}[entity_id]`, values.entity_id);
      if (values[pictureName]) {
        handlePictureKey({
          formData,
          picture: values[pictureName],
          keyName: formName,
          pictureName,
        });
      }
      values.user_entity_work_ids?.forEach((workId) =>
        formData.append(`${formName}[user_entity_work_ids][]`, workId)
      );
      values.client_ids?.forEach((clientId) =>
        formData.append(`${formName}[client_ids][]`, clientId)
      );
      formData.append("id", user.id);
      return formData;
    },
    [user]
  );

  useEffect(() => {
    loadAvatar();

    const initialValues = {
      ...user,
      role: i18next.t(`roles.${user?.role}`),
    };
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, loadAvatar, user]);

  const handleResetPassword = () => {
    window.location = "/users/edit";
  };

  const handleSubmit = (values) => {
    const formData = generateUserFormData(values);
    updateUser(formData);
  };
  return (
    <StyledContainer>
      <div>
        <TitleContainer label="Gestion du profil" />
        {usersAreLoading ? (
          <Loader />
        ) : (
          <Form form={form} onFinish={handleSubmit} colon={false}>
            <StyledFormRow>
              <Avatar isShowing={false} />
              <FormCol>
                <ButtonCol
                  isUpdating
                  setStatus={setStatus}
                  isLoading={isLoading}
                />
                <StyledButtonContainer>
                  <div
                    onClick={() => handleResetPassword()}
                    className="btn btn-danger rounded-lg"
                    style={{
                      padding: "0.3rem",
                      height: 33,
                      fontSize: 14,
                      marginTop: 10,
                    }}
                  >
                    Réinitialiser le mot de passe
                  </div>
                </StyledButtonContainer>
              </FormCol>
            </StyledFormRow>

            <Separator isHorizontal size={1} />
            <Row>
              <FormCol
                label=""
                labelOnTop
                name="gender"
                rules={[{ required: true }]}
                width="20%"
              >
                <Select
                  options={genderArray}
                  // disabled={isShowing}
                  showSearch={false}
                />
              </FormCol>
              <FormCol
                label="Prénom"
                name="first_name"
                labelOnTop
                rules={[{ required: true }]}
                width="40%"
              >
                <Input />
              </FormCol>
              <FormCol
                label="Nom"
                name="last_name"
                labelOnTop
                rules={[{ required: true }]}
                width="40%"
              >
                <Input />
              </FormCol>
            </Row>

            <StyledFormRow>
              <FormCol
                label="Adresse email / Identifiant de connexion"
                name="email"
                labelOnTop
                rules={[
                  { required: true },
                  {
                    type: "email",
                    message: "Email invalide.",
                  },
                ]}
                width="60%"
              >
                <Input />
              </FormCol>
              <FormCol
                label="Numéro de téléphone"
                name="phone"
                labelOnTop
                rules={[
                  { required: true },
                  {
                    // eslint-disable-next-line prefer-regex-literals
                    pattern: new RegExp(
                      "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$"
                    ),
                    message: "Numéro invalide.",
                  },
                ]}
                width="40%"
              >
                <Input />
              </FormCol>
            </StyledFormRow>

            <Separator isHorizontal size={1} />
            <Row>
              {procurement && (
                <FormCol
                  label="Type"
                  name="type"
                  rules={[{ required: true }]}
                  width="30%"
                  labelOnTop
                >
                  <Select options={typesArray} showSearch={false} disabled />
                </FormCol>
              )}
              <FormCol
                label="Rôle"
                name="role"
                width={procurement ? "30%" : "50%"}
                labelOnTop
              >
                <Input defaultValue={user.role} disabled />
              </FormCol>
              {(!procurement || user?.type === "supplier") && (
                <FormCol
                  label="Agence"
                  name="entity"
                  width={procurement ? "40%" : "50%"}
                  labelOnTop
                >
                  <Input disabled defaultValue={angencie_name} />
                </FormCol>
              )}
              {procurement && user?.type === "buyer" && (
                <FormCol
                  label="Site(s)"
                  name="client_ids"
                  width="40%"
                  labelOnTop
                >
                  <Select
                    options={sites}
                    disabled
                    showSearch={false}
                    mode="multiple"
                  />
                </FormCol>
              )}
            </Row>
            <Row>
              <FormCol label="Activité(s)" name="work" labelOnTop>
                <Input disabled defaultValue={work_names} />
              </FormCol>
            </Row>
            <Separator isHorizontal size={1} />
            <Row>
              <FormCol label="Fonction" name="function" labelOnTop>
                <Input />
              </FormCol>
            </Row>
            <StyledFormRow>
              <FormCol label="Présentation" name="description" labelOnTop>
                <Input textArea />
              </FormCol>
            </StyledFormRow>
            <Separator isHorizontal size={1} />
            <FormCol
              label="Jeton API"
              name="api_token"
              labelOnTop
              valuePropName="secret"
              width="50%"
            >
              <CopySecret copyText="Copier votre jeton API" defaultIsHidden />
            </FormCol>
            <Separator isHorizontal size={1} />
            {(user.role === "admin" || user.role === "setup") &&
              option_documents && (
                <FormCol
                  label="Notification d'obsolescence des documents"
                  name="notification_deprecated_documents"
                  valuePropName="checked"
                  withoutPaddingRight
                  rules={[{ required: true }]}
                >
                  <Checkbox />
                </FormCol>
              )}
          </Form>
        )}
      </div>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
`;

const StyledFormRow = styled(FormRow)`
  padding-top: 10px !important;
`;

const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: baseline;
`;

const MaxLicenses = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default ProfileForm;
