import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminCf from "../Admin-cf/AdminCf";
import RulesHandler from "../Admin-cf/RulesHandler";
import References from "../Admin-cf/References";
import DocumentsFolders from "../Admin-cf/DocumentsFolders";
import Users from "../Admin-cf/Users";
import Applications from "../Admin-cf/Applications";

function AdminRoutes(props) {
  return (
    <Routes>
      <Route path="low_current/:entity_work" element={<AdminCf {...props} />} />
      <Route
        path="rules"
        element={
          <RulesHandler
            works={props.works}
            companyWorks={props.company_works}
            siteTypologies={props.site_typologies}
            entities={props.entities}
          />
        }
      />
      <Route
        path="references"
        element={
          <References
            works={props.works}
            companyWorks={props.company_works}
            siteTypologies={props.site_typologies}
            entities={props.entities}
          />
        }
      />
      <Route
        path="manage_documents"
        element={
          <DocumentsFolders
            works={props.works}
            settableWorks={props.settable_works}
            companyWorks={props.company_works}
            siteTypologies={props.site_typologies}
            settableEntities={props.settable_entities}
            entities={props.entities}
            parentEntity={props.parent_entity}
            canSetGlobalEntities={props.can_set_global_entities}
            canSetGlobalWorks={props.can_set_global_works}
            users={props.users}
          />
        }
      />
      <Route
        path="users"
        element={
          <Users
            works={props.works}
            entities={props.entities}
            roles={props.roles}
            currentUserId={props.current_user_id}
            sites={props.sites}
            option_documents={props.option_documents}
          />
        }
      />
      <Route path="oauth/applications" element={<Applications />} />
    </Routes>
  );
}

export default AdminRoutes;
