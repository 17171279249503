import React, { useMemo } from "react";
import { Form } from "antd";
import { array, bool, func, shape, string } from "prop-types";
import StyledInput from "../react-ui/Input";
import Label from "./Label";
import { useStore } from "../store";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
});
function Input({
  input,
  handleUpdateOnChange,
  isShowing,
  isMarginRate,
  isComputed,
  label,
  ...formItemProps
}) {
  const { isUsingMargin } = useStore(selector);

  const handleOnBlur = (e) => {
    if (input.onBlur) input.onBlur(e);
    handleUpdateOnChange();
  };

  const disabled = input.disabled || isShowing;

  const constructedLabel = useMemo(() => {
    if (label) return label;
    if (isMarginRate) {
      const computedStr = isComputed ? " calculé" : "";
      return isUsingMargin
        ? `Taux de marge${computedStr}`
        : `Coefficient${computedStr}`;
    }
    return undefined;
  }, [isMarginRate, label, isUsingMargin, isComputed]);

  return (
    <Form.Item
      {...formItemProps}
      label={<Label label={constructedLabel} rules={formItemProps.rules} />}
    >
      <StyledInput
        {...input}
        onBlur={handleOnBlur}
        disabled={disabled}
        placeholder={isShowing ? "" : input.placeholder}
        isMarginRate={isMarginRate}
      />
    </Form.Item>
  );
}

Input.propTypes = {
  input: shape({
    placeholder: string,
    onBlur: func,
    disabled: bool,
    onInput: func,
    onFocus: func,
    suffix: string,
  }).isRequired,
  name: string.isRequired,
  label: string,
  rules: array,
  handleUpdateOnChange: func.isRequired,
  isShowing: bool,
  isMarginRate: bool,
  isComputed: bool,
};

Input.defaultProps = {
  label: "",
  rules: [],
  isShowing: false,
  isMarginRate: false,
  isComputed: false,
};

export default Input;
