import React from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import Delete from "../../../react-ui/Icons/Delete";
import { useStore } from "../../../store";
import { postData } from "../../../request/instance";
import { ShowDeleteConfirm } from "../../../Admin-cf/confirmModal";
import Edit from "../../../react-ui/Icons/Edit";

const selector = (state) => ({
  formToken: state.formToken,
});

function BasketTitle({ id, name, setBasketModalOpen, setSelectedBasket }) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const { mutate: deleteBasketMutation } = useMutation(
    (todo) => postData(formToken, "/referencing_basket/delete", todo),
    {
      onSuccess: () => queryClient.invalidateQueries("Baskets"),
    }
  );

  const deleteBasket = () => {
    ShowDeleteConfirm(name, id, () => deleteBasketMutation({ id }));
  };

  const openBasket = () => {
    setBasketModalOpen("update");
    setSelectedBasket(id);
  };

  return (
    <Container>
      <LeftWrapper>{name}</LeftWrapper>
      <RightWrapper>
        <EditIcon onClick={openBasket} />
        <DeleteIcon onClick={deleteBasket} />
      </RightWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 80%;
`;

const RightWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  width: 20%;
`;

const DeleteIcon = styled(Delete)`
  font-size: 14px;
`;

const EditIcon = styled(Edit)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blue06};
`;

export default BasketTitle;
