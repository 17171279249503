import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import TableContainer from "../../../CostingMovementsCarrycots/TableContainer";
import Input from "../../../../react-ui/Input";
import { formatNumberString } from "../../../../../utils/formatNumberString";
import { stringToFloat } from "../../../../../utils/stringToFloat";
import { eurosToCents } from "../../../../../utils/currencyConverter";

import { currency_cents_to_euros } from "../../../../utils";
import { getMarginCol } from "../../../../../constants/marginCol";
import { useStore } from "../../../../store";
import { coeffToMargin } from "../../../../../utils/marginConverter";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

const TableMaterialAndPackage = React.memo(
  ({
    zone_id,
    opportunities,
    calculOpportunity,
    isLoading,
    type,
    responseType,
    secondColumnType,
    quantityKey = "quantity",
    configuration,
  }) => {
    const { id_opportunity } = useParams();
    const { calculMethod, isUsingMargin } = useStore(selector);

    const [margin, setMargin] = useState(null);
    const [unitary_selling_HT, setUnitary_selling_HT] = useState(null);
    const [total_selling_HT, setTotal_selling_HT] = useState(null);
    const pushDataInTable = useMemo(() => {
      const dataSource = [];
      opportunities
        ?.filter((el) => el[quantityKey] > 0)
        ?.forEach((opp) => {
          const total_cost_price =
            opp.total_cost_price_cents || opp.total_cost_cents;
          if (opp.zone_id === zone_id || responseType === "referencing") {
            dataSource.push({
              ...opp,
              key: opp.id,
              name: opp.name,
              id: opp.id,
              categories: opp.category_profession?.title,
              type_transport: opp.type_transport,
              quantity: formatNumberString({
                str: opp[quantityKey],
                nbDecimal: 2,
                space: true,
              }),
              unitary_cost_price: formatNumberString({
                str: currency_cents_to_euros(opp.cost_price_cents),
                nbDecimal: 2,
                space: true,
              }),
              total_cost_price: formatNumberString({
                str: currency_cents_to_euros(total_cost_price),
                nbDecimal: 2,
                space: true,
              }),
              margin: opp.margin_rate,
              unitary_selling_HT: formatNumberString({
                str: currency_cents_to_euros(opp.selling_price_cents),
                nbDecimal: 2,
                space: true,
              }),
              total_selling_HT: formatNumberString({
                // Handle special case for carrycot opp which hasn't total_selling_price_cents logic
                str: currency_cents_to_euros(opp.total_selling_price_cents),
                nbDecimal: 2,
                space: true,
              }),
            });
          }
        });
      return dataSource;
    }, [opportunities, responseType, secondColumnType, quantityKey]);

    const getTableColumns = useMemo(() => {
      const nameCol = {
        title: "Intitulé",
        dataIndex: "name",
      };

      const categoriesCol = {
        title: "Categories",
        dataIndex: "categories",
        width: "14%",
      };
      const typeTransportCol = {
        title: "Type de déplacement",
        dataIndex: "type_transport",
        width: "14%",
      };
      const quantityCol = {
        title: "Qt",
        dataIndex: "quantity",
        width: "4%",
      };
      const costCol =
        responseType === "referencing"
          ? {
              title: "Coût de revient",
              hidden: !configuration.costing.page_3.show_cost_price,
              dataIndex: "unitary_cost_price",
              width: "10%",
              align: "right",
              render: (value) => <div>{value} €</div>,
            }
          : {
              title: "Coût de revient",
              hidden: !configuration.costing.page_3.show_cost_price,
              children: [
                {
                  title: "Unitaire",
                  dataIndex: "unitary_cost_price",
                  width: "10%",
                  align: "right",
                  render: (value) => <div>{value} €</div>,
                },
                {
                  title: "Total",
                  dataIndex: "total_cost_price",
                  width: "10%",
                  align: "right",
                  render: (value) => <div>{value} €</div>,
                },
              ],
            };

      const marginCol = getMarginCol({
        configuration,
        isUsingMargin,
        calculMethod,
        hasSorter: false,
        render: (value, opp) => (
          <Input
            fontSize="10px"
            textAlign="right"
            size="small"
            isMarginRate
            id={opp.id}
            value={formatNumberString({
              str: margin?.[opp.id] != null ? margin?.[opp.id] : value,
              isCoeff: !isUsingMargin,
            })}
            onBlur={(e) => {
              if (margin?.[opp.id]) {
                calculOpportunity({
                  opportunity_id: id_opportunity,
                  ...(opp.ids ? { ids: opp.ids } : { id: opp.id }),
                  margin_rate: coeffToMargin({
                    calculMethod,
                    isUsingMargin,
                    coeff: stringToFloat(e.target.value),
                  }),
                });
              }
            }}
            onChange={(e) =>
              setMargin((v) => ({
                ...v,
                [e.target.id]: e.target.value,
              }))
            }
          />
        ),
      });

      const sellingCol =
        responseType === "referencing"
          ? {
              title: "Prix de vente HT",
              dataIndex: "unitary_selling_HT",
              width: "10%",
              render: (value, opp) => (
                <Input
                  fontSize="10px"
                  textAlign="right"
                  size="small"
                  suffix="€"
                  disabled={configuration.costing.page_3.disable_selling_price}
                  id={opp.id}
                  value={unitary_selling_HT?.[opp.id] || value}
                  onBlur={(e) => {
                    if (unitary_selling_HT?.[opp.id]) {
                      calculOpportunity({
                        opportunity_id: id_opportunity,
                        ...(opp.ids ? { ids: opp.ids } : { id: opp.id }),
                        selling_price_cents: eurosToCents(e.target.value),
                      });
                    }
                    setUnitary_selling_HT(null);
                  }}
                  onChange={(e) => {
                    setUnitary_selling_HT((v) => ({
                      ...v,
                      [e.target.id]: formatNumberString({
                        str: e.target.value,
                        nbDecimal: 2,
                      }),
                    }));
                  }}
                  onFocus={(e) => {
                    setUnitary_selling_HT((v) => ({
                      ...v,
                      [e.target.id]: formatNumberString({
                        str: e.target.value,
                        nbDecimal: 2,
                      }),
                    }));
                  }}
                />
              ),
            }
          : {
              title: "Prix de vente HT",
              children: [
                {
                  title: "Unitaire",
                  dataIndex: "unitary_selling_HT",
                  width: "10%",
                  align: "right",

                  render: (value, opp) => {
                    return responseType === "consultation" ? (
                      <Input
                        fontSize="10px"
                        textAlign="right"
                        size="small"
                        suffix="€"
                        disabled={
                          configuration.costing.page_3.disable_selling_price
                        }
                        id={opp.id}
                        value={unitary_selling_HT?.[opp.id] || value}
                        onBlur={(e) => {
                          if (unitary_selling_HT?.[opp.id]) {
                            calculOpportunity({
                              opportunity_id: id_opportunity,
                              ...(opp.ids ? { ids: opp.ids } : { id: opp.id }),
                              selling_price_cents: eurosToCents(e.target.value),
                            });
                          }
                          setUnitary_selling_HT(null);
                        }}
                        onChange={(e) => {
                          setUnitary_selling_HT((v) => ({
                            ...v,
                            [e.target.id]: formatNumberString({
                              str: e.target.value,
                              nbDecimal: 2,
                            }),
                          }));
                        }}
                        onFocus={(e) => {
                          setUnitary_selling_HT((v) => ({
                            ...v,
                            [e.target.id]: formatNumberString({
                              str: e.target.value,
                              nbDecimal: 2,
                            }),
                          }));
                        }}
                      />
                    ) : (
                      <div>{value} €</div>
                    );
                  },
                },
                {
                  title: "Total",
                  dataIndex: "total_selling_HT",
                  width: "10%",
                  align: "right",
                  render: (value, opp) =>
                    responseType === "consultation" ? (
                      <Input
                        fontSize="10px"
                        textAlign="right"
                        size="small"
                        suffix="€"
                        disabled={
                          configuration.costing.page_3.disable_selling_price
                        }
                        id={opp.id}
                        value={total_selling_HT?.[opp.id] || value}
                        onBlur={(e) => {
                          if (total_selling_HT?.[opp.id]) {
                            calculOpportunity({
                              ...(opp.ids ? { ids: opp.ids } : { id: opp.id }),
                              opportunity_id: id_opportunity,
                              total_selling_price_cents: eurosToCents(
                                e.target.value
                              ),
                            });
                          }
                          setTotal_selling_HT(null);
                        }}
                        onChange={(e) => {
                          setTotal_selling_HT((v) => ({
                            ...v,
                            [e.target.id]: formatNumberString({
                              str: e.target.value,
                              nbDecimal: 2,
                            }),
                          }));
                        }}
                        onFocus={(e) => {
                          setTotal_selling_HT((v) => ({
                            ...v,
                            [e.target.id]: formatNumberString({
                              str: e.target.value,
                              nbDecimal: 2,
                            }),
                          }));
                        }}
                      />
                    ) : (
                      <div>{value} €</div>
                    ),
                },
              ],
            };
      // Material and presta
      if (secondColumnType === "categories") {
        if (responseType === "quotation")
          return [nameCol, categoriesCol, quantityCol, sellingCol];
        return [
          nameCol,
          categoriesCol,
          quantityCol,
          costCol,
          marginCol,
          sellingCol,
        ];
      }
      // Movement
      if (secondColumnType === "type_transport") {
        if (responseType === "quotation")
          return [nameCol, typeTransportCol, quantityCol, sellingCol];
        return [
          nameCol,
          typeTransportCol,
          quantityCol,
          costCol,
          marginCol,
          sellingCol,
        ];
      }
      // Carrycot
      if (responseType === "quotation")
        return [nameCol, quantityCol, sellingCol];
      return [nameCol, quantityCol, costCol, marginCol, sellingCol];
    }, [
      responseType,
      configuration,
      isUsingMargin,
      calculMethod,
      secondColumnType,
      margin,
      calculOpportunity,
      id_opportunity,
      unitary_selling_HT,
      total_selling_HT,
    ]);
    return pushDataInTable.length ? (
      <Container>
        <div>
          <h4 id={type} className="pl-2">
            {type}
          </h4>
          <TableContainer columns={getTableColumns} data={pushDataInTable} />
        </div>
      </Container>
    ) : null;
  }
);

const Container = styled.div`
  margin-top: 20px;
`;

export default TableMaterialAndPackage;
