import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AddButton from "../../../react-ui/AddButton";
import TitleContainer from "../../../react-ui/TitleContainer";
import { getData, postData } from "../../../request/instance";
import { useStore } from "../../../store";
import { fetchFoldersUrl } from "../../../../utils/fetchFoldersUrl";
import { fetchFileRulesUrl } from "../../../../utils/fetchFileRulesUrl";
import FileRuleForm from "./FileRuleForm";
import FileRulesArray from "./FileRulesArray";

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

const selector = (state) => ({
  formToken: state.formToken,
});

function FileRules() {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);
  const [status, setStatus] = useState("empty");
  const [selectedFileRule, setSelectedFileRule] = useState();
  const [wordEntered, setWordEntered] = useState("");

  const { data: folders, isLoading: foldersAreLoading } = useQuery(
    "Folders",
    () => getData(formToken, fetchFoldersUrl({ search: undefined })),
    { refetchOnWindowFocus: false }
  );

  const {
    data: fileRules,
    refetch,
    isLoading: fileRulesAreLoading,
  } = useQuery(
    "FileRules",
    () => getData(formToken, fetchFileRulesUrl({ search: wordEntered })),
    { refetchOnWindowFocus: false }
  );

  const { mutate: createFileRule, isLoading: createIsLoading } = useMutation(
    (todo) => postData(formToken, "/file_rule/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("FileRules");
        closeModal();
      },
    }
  );

  const { mutate: updateFileRule, isLoading: updateIsLoading } = useMutation(
    (todo) => postData(formToken, "/file_rule/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("FileRules");
        closeModal();
      },
    }
  );

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  const openModal = () => {
    setStatus("create");
  };

  const initialValues = useMemo(() => {
    if (status !== "update" || !selectedFileRule) return {};

    return {
      ...selectedFileRule,
    };
  }, [selectedFileRule, status]);

  const closeModal = () => {
    setStatus("empty");
    setSelectedFileRule();
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const appendFileRuleToForm = ({ formData, values }) => {
    formData.append("file_rule[name]", values.name);
    formData.append("file_rule[security]", values.security || "");
    formData.append("file_rule[folder_id]", values.folder_id || "");
    if (values.location)
      formData.append("file_rule[location]", values.location);
  };

  const handleSubmit = (values) => {
    const formData = new FormData();

    if (status === "create") {
      appendFileRuleToForm({ formData, values });
      createFileRule(formData);
    }
    if (status === "update") {
      appendFileRuleToForm({ formData, values });
      formData.append("id", selectedFileRule.id);
      updateFileRule(formData);
    }
  };

  return (
    <>
      <StyledModal
        open={status !== "empty"}
        footer={null}
        width={1000}
        maskClosable={false}
        destroyOnClose
        onCancel={closeModal}
        closable={false}
      >
        <FileRuleForm
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          status={status}
          closeModal={closeModal}
          formIsLoading={createIsLoading || updateIsLoading}
          folders={folders}
        />
      </StyledModal>
      <TitleContainer label="Fichiers règles disponibles">
        <AddButton label="Créer un fichier règle" onClick={openModal} />
      </TitleContainer>
      <StyledSearch
        allowClear
        placeholder="Rechercher par nom de fichier règle"
        value={wordEntered}
        onChange={handleFilter}
      />
      <FileRulesArray
        folders={folders}
        setStatus={setStatus}
        setSelectedFileRule={setSelectedFileRule}
        foldersAreLoading={foldersAreLoading}
        fileRules={fileRules}
        fileRulesAreLoading={fileRulesAreLoading}
      />
    </>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 300px;
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

const StyledSearch = styled(Search)`
  margin-bottom: 12px;
`;

export default FileRules;
