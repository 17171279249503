import React, { useMemo, useRef } from "react";
import { bool, func, number, object, shape, string } from "prop-types";
import { currency_cents_to_euros } from "../../utils";
import Form from "../../Form";
import { formatNumberString } from "../../../utils/formatNumberString";
import { marginToCoeff } from "../../../utils/marginConverter";
import { useStore } from "../../store";
import { useMarginItem } from "../../hooks/useMarginItem";
import MarginItem from "../../react-ui/MarginItem";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

function ProfilForm({
  isShowing,
  onSubmit,
  updateOnChange,
  submit,
  initialProfil,
  entity_work_name,
  configuration,
  entityWorkId,
  opportunityId,
}) {
  const { isUsingMargin, calculMethod } = useStore(selector);
  const { hasMargin, setHasMargin } = useMarginItem({
    initial: initialProfil,
  });

  const costProfilRef = useRef();
  const formFactory = useMemo(() => {
    const firstRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Nom du profil",
          },
          name: "name",
          label: "Intitulé",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },

      {
        type: "upload",
        item: {
          upload: {
            listType: "picture-card",
            maxCount: 1,
            accept: "image/*",
          },
          name: "picture",
        },
        style: { display: "flex", justifyContent: "flex-end" },
      },
    ];

    const secondRow = [
      {
        type: "textarea",
        item: {
          textarea: {
            placeholder: "Ajoutez une description",
          },
          name: "description",
          label: "Description",
        },
      },
      configuration.admin.profil_tab.form.input_margin_rate && {
        type: "input",
        item: {
          input: {
            customContent: (
              <MarginItem
                hasMargin={hasMargin}
                entityWorkId={entityWorkId}
                opportunityId={opportunityId}
                itemType="profil_profession"
              />
            ),
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                isCoeff: !isUsingMargin,
              });
              setHasMargin(e.currentTarget.value !== "");
            },
          },
          isMarginRate: true,
          name: "margin_rate",
        },
      },
    ];

    const thirdRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 2,
              });
            },
            onFocus: (e) => {
              costProfilRef.current?.setFieldsValue({
                hourly_rate_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              });
            },
            onBlur: (e) => {
              costProfilRef.current?.setFieldsValue({
                hourly_rate_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
            suffix: "€",
          },
          name: "hourly_rate_cents",
          label: "Coût horaire",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      configuration.general.subcontracting && {
        type: "checkbox",
        item: {
          checkbox: {},
          name: "subcontracting",
          label: "Sous-traitance",
        },
      },
    ];

    return [
      { columns: firstRow, key: "name+picture" },
      { columns: secondRow, key: "description" },
      { columns: thirdRow, key: "hourly_rate_cents+subcontracting" },
    ];
  }, [
    configuration.admin.prestation_tab.form.input_margin_rate,
    configuration.general.subcontracting,
    entityWorkId,
    hasMargin,
    isUsingMargin,
    opportunityId,
    setHasMargin,
  ]);

  const initialValues = useMemo(() => {
    if (!initialProfil) return {};
    const margin_rate = initialProfil.margin_rate
      ? formatNumberString({
          str: marginToCoeff({
            marginRate: initialProfil.margin_rate,
            isUsingMargin,
            calculMethod,
          }),
          isCoeff: !isUsingMargin,
        })
      : undefined;
    const picture = initialProfil.picture
      ? [
          {
            uid: "1",
            name: "image",
            status: "done",
            url: initialProfil.picture,
          },
        ]
      : [];
    return {
      name: initialProfil.name,
      description: initialProfil.description,
      subcontracting: initialProfil.subcontracting,
      hourly_rate_cents: formatNumberString({
        str: currency_cents_to_euros(initialProfil.hourly_rate_cents),
        nbDecimal: 2,
        space: true,
      }),
      margin_rate,
      picture,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialProfil?.id]);

  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={formFactory}
      isShowing={isShowing}
      id="profil-form"
      ref={costProfilRef}
    />
  );
}

ProfilForm.propTypes = {
  isShowing: bool,
  onSubmit: func,
  updateOnChange: bool,
  submit: shape({
    onCancel: func,
  }),
  initialProfil: object,
  entity_work_name: string,
  configuration: object,
  opportunityId: number,
  entityWorkId: string,
};

ProfilForm.defaultProps = {
  isShowing: false,
  onSubmit: undefined,
  updateOnChange: false,
  submit: undefined,
  initialProfil: undefined,
  entity_work_name: "",
  configuration: {},
  opportunityId: undefined,
  entityWorkId: undefined,
};

export default ProfilForm;
