// Theme for js files (use it with styled component in react files)

export const theme = {
  colors: {
    blue01: "#EEF5FC",
    blue02: "#BAD6F2",
    blue03: "#87B8E8",
    blue04: "#5499DE",
    blue05: "#277ACE",
    blue06: "#2166AB",
    blue07: "#1D5C9A",
    blue08: "#1a5289",
    blue09: "#174778",
    blue10: "#143D67",
    blue11: "#103356",
    basicblue: "#0b2239",
    blue13: "#071422",
    blue14: "#030a11",

    orange01: "#FEEEEB",
    orange02: "#FDCBC4",
    orange03: "#FBA89D",
    orange04: "#F98576",
    orange05: "#F8624F",
    orange06: "#F7513B",
    basicorange: "#f63c25",
    orange08: "#EB240A",
    orange09: "#C41E08",
    orange10: "#9D1807",
    orange11: "#761205",

    yellow01: "#FFF4D7",
    yellow02: "#FEE39A",
    yellow03: "#FED872",
    yellow04: "#FDCD49",
    basicyellow: "#FDC323",
    yellow06: "#F2B202",
    yellow07: "#CA9502",
    yellow08: "#A27702",

    grey01: "#F5F5F5",
    grey02: "#D6D6D6",
    grey03: "#B8B8B8",
    grey04: "#999999",
    grey05: "#858585",
    grey06: "#707070",
    grey07: "#666666",
    basicgrey: "#585858",
    grey09: "#474747",
    grey10: "#333333",
    grey11: "#1F1F1F",

    basicgreen: "#1abc9c",
  },
};
