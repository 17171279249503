import { Form } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { validateMessages } from "../../../constant";
import FormRow from "../../../Form/FormRow";
import ButtonCol from "./ButtonCol";
import Logo from "./Logo";
import Separator from "../../../react-ui/Separator";
import { getData, postData } from "../../../request/instance";
import { useStore } from "../../../store";
import FormCol from "../../../Form/FormCol";
import Select from "../../../react-ui/Select";
import Input from "../../../react-ui/Input";
import WorksSelect from "./WorksSelect";
import MaxSitesInput from "./MaxSitesInput";
import { useLazyQuery } from "../../../../hooks/useLazyQuery";
import ClientsSelect from "./ClientsSelect";

const selector = (state) => ({
  formToken: state.formToken,
  vocabularyApplication: state.vocabularyApplication,
  configurationApplication: state.configurationApplication,
});

function ClientForm({
  isUpdating,
  setStatus,
  selectedClientId,
  isShowing,
  setSelectedClient,
  selectedClient,
  status,
  arrayClients,
  generateClientFormData,
  statuses,
  activities,
  works,
  setArrayClients,
  clientsInMemory,
  setClientsInMemory,
  vocabulary,
  configuration,
}) {
  const [clients, setClients] = useState([]);
  const { formToken, vocabularyApplication, configurationApplication } =
    useStore(selector);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    if (!selectedClientId) selectedClient();
    else
      setSelectedClient({
        ...arrayClients.find((client) => client.id === selectedClientId),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId]);

  const { mutate: updateClient, isLoading } = useMutation(
    (todo) => postData(formToken, `/client/update`, todo),
    {
      onError: () => {
        queryClient.invalidateQueries("Clients");
        setSelectedClient({
          ...arrayClients.find((client) => client.id === selectedClientId),
        });
      },
      onSuccess: (payload) => {
        setSelectedClient((selected) =>
          payload.clients.find((c) => c.id === selected?.id)
        );
        setStatus("show");
        setArrayClients((olds) => {
          const news = [...olds];
          payload.clients.forEach((client) => {
            const updatedIdx = olds.findIndex((c) => c.id === client.id);
            if (updatedIdx !== -1) news[updatedIdx] = client;
          });
          return news;
        });
      },
    }
  );

  useEffect(() => {
    if (status === "empty") form.resetFields();
  }, [status, form]);

  const [loadLogo] = useLazyQuery(
    ["loadLogo"],
    () => getData(formToken, `/client/load_logo?id=${selectedClientId}`),
    {
      onSuccess: (logo) => {
        if (logo)
          form.setFieldValue("logo", [
            {
              uid: "1",
              name: "image",
              status: "done",
              url: logo,
            },
          ]);
      },
    }
  );

  useEffect(() => {
    if (selectedClient) {
      loadLogo();
      const initialValues = {
        ...selectedClient,
        parent: {
          label: selectedClient.parent?.name,
          value: selectedClient.parent?.id,
        },
      };
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [form, selectedClient, loadLogo]);

  const handleSubmit = (values) => {
    const formData = generateClientFormData(values);
    updateClient(formData);
  };

  return (
    <Container>
      <Form
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
      >
        <Row>
          <div style={{ width: "100%" }} />
          <Logo isShowing={isShowing} />
          <ButtonCol
            isUpdating={isUpdating}
            isShowing={isShowing}
            setStatus={setStatus}
            isLoading={isLoading}
          />
        </Row>

        <Separator isHorizontal size={1} />

        <Row>
          <FormCol
            label={
              vocabularyApplication.application_name === "procurement"
                ? vocabularyApplication.clients.client_name
                : vocabulary.clients.name
            }
            labelOnTop
            name="name"
            width="70%"
            rules={[{ required: true }]}
          >
            <Input disabled={isShowing} />
          </FormCol>
          {configurationApplication?.common.clients.show_status && (
            <FormCol label="Statut" labelOnTop name="status" width="30%">
              <Select
                options={statuses}
                disabled={isShowing}
                showSearch={false}
              />
            </FormCol>
          )}
          <MaxSitesInput
            isShowing={isShowing}
            clients={clientsInMemory}
            selectedClientPosition={selectedClient?.position}
          />
        </Row>

        <StyledFormRow>
          {configurationApplication?.common.clients.show_activity && (
            <FormCol
              label="Activité"
              labelOnTop
              name="clients_activity_id"
              width="70%"
              rules={[{ required: configuration.clients.required_activity }]}
            >
              <Select
                options={activities}
                disabled={isShowing}
                showSearch={false}
              />
            </FormCol>
          )}
          {configurationApplication?.common.clients.show_works && (
            <WorksSelect
              isShowing={isShowing}
              isUpdating={isUpdating}
              works={works}
              form={form}
              clients={clientsInMemory}
              selectedClientId={selectedClientId}
              selectedClientPosition={selectedClient?.position}
              selectedParentId={selectedClient?.parent_id}
            />
          )}
          <FormCol label="SIRET" labelOnTop name="siret" width="30%">
            <Input disabled={isShowing} />
          </FormCol>
        </StyledFormRow>
        <Separator isHorizontal size={1} />

        <Row>
          <FormCol label="Adresse" labelOnTop name="address1" width="70%">
            <Input disabled={isShowing} />
          </FormCol>
          <FormCol
            label="Ville"
            labelOnTop
            name="city"
            width="30%"
            rules={[{ required: true }]}
          >
            <Input disabled={isShowing} />
          </FormCol>
        </Row>

        <StyledFormRow>
          <FormCol
            label="Complément d'adresse"
            labelOnTop
            name="address2"
            width="70%"
          >
            <Input disabled={isShowing} />
          </FormCol>
          <FormCol
            label="Code postal"
            labelOnTop
            name="zipcode"
            width="30%"
            rules={[{ required: true }]}
          >
            <Input disabled={isShowing} />
          </FormCol>
        </StyledFormRow>

        <StyledFormRow>
          <FormCol
            width="50%"
            labelOnTop
            label={vocabularyApplication?.clients.client_phone}
            name="phone"
          >
            <Input disabled={isShowing} />
          </FormCol>
          <FormCol
            width="50%"
            labelOnTop
            label={vocabularyApplication?.clients.client_email}
            name="email"
          >
            <Input disabled={isShowing} />
          </FormCol>
        </StyledFormRow>
        <Separator isHorizontal size={1} />
        <StyledFormRow>
          {configurationApplication?.common.clients.show_parent_in_update && (
            <FormCol
              labelOnTop
              width="40%"
              label={
                vocabularyApplication.application_name === "procurement"
                  ? vocabularyApplication.clients.parent_client
                  : vocabulary.clients.parent_client
              }
              name="parent"
            >
              <ClientsSelect
                setClientsInMemory={setClientsInMemory}
                clientsInMemory={clientsInMemory}
                clients={clients}
                setClients={setClients}
                selectedClientId={selectedClientId}
                isShowing={isShowing}
              />
            </FormCol>
          )}
          <FormCol
            labelOnTop
            width={
              configurationApplication?.common.clients.show_parent_in_update
                ? "60%"
                : "100%"
            }
            label="Commentaires"
            name="comment"
          >
            <Input disabled={isShowing} textArea />
          </FormCol>
        </StyledFormRow>
      </Form>
    </Container>
  );
}

const Container = styled.div`
  .ant-form-item-explain-error {
    white-space: nowrap;
  }
`;

const Row = styled.div`
  display: flex;
`;

const StyledFormRow = styled(FormRow)`
  padding-top: 10px !important;
`;

export default ClientForm;
