export const formatNumberString = ({
  str,
  nbDecimal = 2,
  space = false,
  isCoeff = false,
}) => {
  if (typeof str === "number") {
    str =
      nbDecimal === Infinity
        ? str.toString()
        : parseFloat(str.toFixed(nbDecimal), 10).toString();
  }
  if (typeof str !== "string") return "";
  if (str === "Infinity" || str === "-Infinity") return "∞";

  str = str
    .replace(/\./g, ",")
    .replace(/[^0-9,-]/g, "")
    .replace(/\s+/g, "");
  let nbCommas = (str.match(/,/g) || []).length;
  let commaPosition = str.indexOf(",");
  let beforeComma = str.substring(0, commaPosition + 1);
  let afterComma = str.substring(commaPosition + 1, str.length);

  if (nbCommas === 1 && afterComma.length > nbDecimal) {
    afterComma = afterComma.substring(0, nbDecimal);
  }

  if (nbCommas > 1) {
    afterComma = afterComma.replace(/\,/g, "");
  }

  beforeComma = beforeComma.replace(/[^0-9,-]/g, "");
  afterComma = afterComma.replace(/[^0-9,-]/g, "");

  if (space === true) {
    if (nbCommas >= 1) {
      beforeComma = beforeComma.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else {
      afterComma = afterComma.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  }
  str = beforeComma + afterComma;

  if (nbDecimal < 1) str = str.replace(/\,/g, "");
  return str;
};
