import React, { useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import styled from "styled-components";
import { debounce } from "lodash";
import FormCol from "../../Form/FormCol";
import CreateLink from "../../react-ui/CreateLink";
import { getData } from "../../request/instance";
import { useStore } from "../../store";
import SelectContacts from "../../react-ui/SelectContacts";

const debouncedSearch = debounce(({ fetchNextPage, nextPage }) => {
  // eslint-disable-next-line no-param-reassign
  nextPage.current = 1;
  fetchNextPage({ pageParam: nextPage.current });
}, 300);

function ContactsSelect({
  setContactModalOpenFrom,
  contacts,
  disabled,
  show_link,
  companyId,
  setContacts,
  name,
  label,
  setContactsInMemory,
  clientId,
  required,
}) {
  // eslint-disable-next-line no-shadow
  const formToken = useStore(({ formToken }) => formToken);
  const nextPage = useRef(1);
  const [wordEntered, setWordEntered] = useState("");
  const { fetchNextPage, isFetching } = useInfiniteQuery(
    ["Contact", { clientId }],
    () =>
      getData(
        formToken,
        `/companies/${companyId}/contacts/dropdown?next_page=${
          nextPage.current
        }${wordEntered ? `&search=${wordEntered}` : ""}${
          clientId ? `&client_id=${clientId}` : ""
        }`
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (payload) => {
        const lastPage = payload.pages[payload.pages.length - 1];
        const payloadContacts = lastPage.contacts?.map((el) => ({
          ...el,
          name: `${el.last_name} ${el.first_name}`,
        }));

        if (lastPage.current_page === 1) {
          setContacts(payloadContacts);
        } else
          setContacts((old) =>
            [...old, ...payloadContacts].filter(
              (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
            )
          );
        setContactsInMemory((old) =>
          [...old, ...payloadContacts].filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          )
        );
        nextPage.current = lastPage.next_page;
      },
    }
  );

  const onScrollCapture = (event) => {
    const perc =
      (event.target.scrollTop /
        (event.target.scrollHeight - event.target.clientHeight)) *
      100;
    if (perc >= 95 && !isFetching && nextPage.current !== null) {
      fetchNextPage({ pageParam: nextPage.current });
    }
  };

  const renderDropdown = (menu) => {
    return <div onScrollCapture={onScrollCapture}>{menu}</div>;
  };

  useEffect(() => {
    debouncedSearch({ fetchNextPage, nextPage });
  }, [wordEntered, fetchNextPage, clientId]);

  return (
    <FormCol
      labelOnTop
      label={label}
      name={name}
      rules={[{ required }]}
      customContent={
        <ContactCustomContent>
          {!show_link ? null : (
            <StyledCreateLink
              label="Créer un contact"
              disabled={disabled}
              onClick={() => (disabled ? null : setContactModalOpenFrom(name))}
            />
          )}
        </ContactCustomContent>
      }
    >
      <StyledSelect
        disabled={disabled}
        options={contacts}
        placeholder="Sélectionnez un ou plusieurs contacts"
        mode="multiple"
        dropdownRender={renderDropdown}
        onSearch={(value) => setWordEntered(value ?? "")}
        onBlur={() => setWordEntered("")}
        labelInValue
        maxTagCount="responsive"
      />
    </FormCol>
  );
}

const StyledCreateLink = styled(CreateLink)`
  margin-left: auto;
  margin-top: 10px;
`;

const StyledSelect = styled(SelectContacts)`
  width: 100%;
`;

const ContactCustomContent = styled.div`
  display: flex;
  justify-content: space-between;
`;
export default ContactsSelect;
