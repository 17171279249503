import React from "react";
import styled from "styled-components";
import { EyeOutlined } from "@ant-design/icons";
import { Popover } from "antd";

function ItemPopoverContent({ client }) {
  return (
    <div>
      <p>
        <span style={{ fontSize: "14px" }}>
          {client.address1 && (
            <>
              {client.address1}
              <br />
            </>
          )}
          {client.address2 && (
            <>
              {client.address2}
              <br />
            </>
          )}
          {client.zipcode} {client.city}
        </span>
      </p>
      <p>
        {client.siret && (
          <>
            SIRET: {client.siret}
            <br />
          </>
        )}
        Activité: {client.activity}
        {client.comment && (
          <>
            <br />
            Commentaires:
            <br />
            <CommentText>{client.comment}</CommentText>
          </>
        )}
      </p>
    </div>
  );
}

function ItemPopover({ item }) {
  return (
    <Popover
      overlayStyle={{ zIndex: 9999 }}
      content={<ItemPopoverContent client={item} />}
      title={item.name}
      trigger="hover"
      mouseEnterDelay={0.5}
      mouseLeaveDelay={0}
    >
      <span>
        {item.name} <EyeOutlined />
      </span>
    </Popover>
  );
}

const CommentText = styled.span`
  font-size: 12px;
  white-space: pre-line; // Pour conserver les sauts de ligne dans le texte
`;

export default ItemPopover;
