import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { array, bool, func, number, object, shape, string } from "prop-types";
import { currency_cents_to_euros, getUrlParams } from "../../utils";
import Form from "../../Form";
import { formatNumberString } from "../../../utils/formatNumberString";
import {
  itemsToCascader,
  singleItemToCascader,
} from "../../../utils/itemsForCascader";
import { centsToEuros } from "../../../utils/currencyConverter";
import { formulaMarginRate } from "../../../utils/formulaMarginRate";
import { stringToFloat } from "../../../utils/stringToFloat";
import { marginToCoeff } from "../../../utils/marginConverter";
import { useStore } from "../../store";
import { quantityTimeConverter } from "../../../utils/quantityTimeConverter";
import { useMarginItem } from "../../hooks/useMarginItem";
import MarginItem from "../../react-ui/MarginItem";
import { formulaSellingPrice } from "../../../utils/formulaSellingPrice";
import { getData } from "../../request/instance";
import { fetchDefaultMarginrate } from "../../../utils/fetchDefaultMarginrate";
import TvaSelector from "../../react-ui/TvaSelector";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
  isUsingMinutes: state.isUsingMinutes,
  formToken: state.formToken,
});
function PrestationForm({
  isShowing,
  categories,
  unities,
  profils,
  onSubmit,
  updateOnChange,
  submit,
  initialPrestation,
  from,
  hasPrestationIncluded,
  isCreating,
  faIsValidate,
  majorations,
  defaultMajoration,
  opportunityId,
  entity_work_name,
  configuration,
  vocabulary,
  entityWorkId,
  currentPrestation,
  defaultMinutes,
  tva_professions,
  use_special_tva,
}) {
  const [disabledSpeedField, setDisabledSpeedField] = useState(true);
  const [isPackage, setIsPackage] = useState(false);

  const {
    hasMargin,
    setHasMargin,
    categoryId,
    setCategoryId,
    profilId,
    setProfilId,
    hasTVA,
    setHasTVA,
  } = useMarginItem({
    initial: initialPrestation,
    categories,
    profils,
  });

  const formRef = useRef();
  const { isUsingMargin, calculMethod, isUsingMinutes, formToken } =
    useStore(selector);
  const [minutes, setMinutes] = useState(
    initialPrestation?.minutes !== null
      ? initialPrestation?.minutes
      : isUsingMinutes
  );

  useEffect(() => {
    setMinutes(
      initialPrestation?.minutes !== null
        ? initialPrestation?.minutes
        : isUsingMinutes
    );
  }, [initialPrestation, isUsingMinutes]);

  const getTimeUnity = useCallback(() => {
    if (minutes !== null) {
      return minutes;
    }
    return isUsingMinutes;
  }, [minutes, isUsingMinutes]);

  const showFAFields = useMemo(
    () =>
      (from === "referencing" || from === "frameworkAgreement") &&
      (updateOnChange || isShowing),
    [from, updateOnChange, isShowing]
  );

  const getMajorationKey = useCallback(
    () =>
      from === "referencing" ||
      (from === "frameworkAgreement" && opportunityId !== null && !faIsValidate)
        ? "majoration_opportunity_id"
        : "majoration_profession_id",
    [from, faIsValidate, opportunityId]
  );

  const computeHourlyRateCents = useCallback(
    (majoId = undefined) => {
      if (!initialPrestation || !initialPrestation.profil_profession_id)
        return undefined;
      let majoIdToCompute;
      if (majoId) majoIdToCompute = majoId;
      else if (hasPrestationIncluded)
        majoIdToCompute =
          initialPrestation.prestation_included[getMajorationKey()];
      else majoIdToCompute = defaultMajoration;
      const rate =
        (from === "referencing" || from === "frameworkAgreement") &&
        majoIdToCompute
          ? majorations.find(
              (el) => el.id === majoIdToCompute
              // (initialPrestation.prestation_included.majoration_profession_id)
            )?.rate
          : 1;
      const profil = profils.find(
        (el) => el.id === initialPrestation.profil_profession_id
      );
      return (profil?.hourly_rate_cents || 0) * rate;
    },
    [
      profils,
      initialPrestation,
      majorations,
      from,
      defaultMajoration,
      getMajorationKey,
      hasPrestationIncluded,
    ]
  );

  useEffect(() => {
    if (initialPrestation) {
      if (isCreating) {
        setMinutes(isUsingMinutes);
        setDisabledSpeedField(true);
        if (configuration.admin.profil_tab.visible) {
          setIsPackage(false);
        } else {
          setIsPackage(true);
        }
      } else {
        setDisabledSpeedField(!initialPrestation.speed);
        setIsPackage(initialPrestation.is_package);
      }
    } else {
      setDisabledSpeedField(true);
      setMinutes(isUsingMinutes);
      if (configuration.admin.profil_tab.visible) {
        setIsPackage(false);
      } else {
        setIsPackage(true);
      }
    }
  }, [initialPrestation, configuration.admin.profil_tab.visible]);
  const formFactory = useMemo(() => {
    const formatedProfils = profils.map((el) => {
      return {
        title: el.name,
        ...el,
      };
    });
    const formatedUnities = unities.map((unity) => {
      return {
        title: unity.name,
        ...unity,
      };
    });

    const presOppName = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Nom de la prestation",
            disabled: !hasPrestationIncluded,
          },
          name: "prestation_included_name",
          label: "Intitulé accord-cadre",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const presOppRefCode = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Référence prestation",
            disabled: !hasPrestationIncluded,
          },
          name: "prestation_included_reference_code",
          label: "Code référence accord-cadre",
        },
      },
    ];

    const firstRow = [
      {
        type: "multiple",
        items: [
          showFAFields &&
            updateOnChange && {
              type: "checkbox",
              item: {
                checkbox: {},
                name: "is_in_framework_agreement",
                label: "Intégrer à l'accord-cadre",
                disabled: from === "frameworkAgreement" && !faIsValidate,
              },
            },
          {
            type: "input",
            item: {
              input: {
                placeholder: "Nom de la prestation",
                disabled: showFAFields,
              },
              name: "name",
              label: "Intitulé",
              rules: [
                {
                  required: true,
                  message: "Champ obligatoire.",
                },
              ],
            },
          },
          {
            type: "input",
            item: {
              input: {
                disabled: showFAFields,
              },
              name: "quotation_position",
              label: "Positionnement devis",
            },
          },
          {
            type: "upload",
            item: {
              upload: {
                maxCount: 1,
                disabled: showFAFields && !hasPrestationIncluded,
              },
              singleLine: true,
              text: "",
              name: "drive_link",
              label: "Fiche produit",
            },
          },
        ],
      },

      {
        type: "upload",
        item: {
          upload: {
            listType: "picture-card",
            maxCount: 1,
            accept: "image/*",
          },
          name: "picture",
        },
        style: { display: "flex", justifyContent: "flex-end" },
      },
    ];

    const secondRow = [
      {
        type: "cascader",
        item: {
          cascader: {
            placeholder: "Choisissez une catégorie",
            changeOnSelect: true,
            expandTrigger: "hover",
            allowClear: false,
            disabled: showFAFields,
            onChange: (value) => {
              if (value && value[0])
                setCategoryId(categories?.find((el) => el.id === value[0])?.id);
            },
          },
          options: itemsToCascader({
            items: categories,
            keyParent: "parent_category_id",
            keyName: "title",
          }),
          name: "category_profession_id",
          label: "Catégorie",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            placeholder: "Code article",
            disabled: showFAFields,
          },
          name: "reference_code",
          label: "Code article",
        },
      },
    ];

    const descriptionRemarkRow = [
      configuration.admin.prestation_tab.form.input_description && {
        type: "textarea",
        item: {
          textarea: {
            placeholder: "Ajoutez une description",
            disabled: showFAFields && !hasPrestationIncluded,
          },
          name: "description",
          label: "Description",
        },
      },
      {
        type: "textarea",
        item: {
          textarea: {
            placeholder: "Ajouter une remarque...",
            disabled: showFAFields,
          },
          name: "remark",
          label: "Information internes",
        },
      },
    ];

    const majorationRow = [
      configuration.admin.prestation_tab.form.input_majoration && {
        type: "select",
        item: {
          select: {
            disabled: !hasPrestationIncluded,
            placeholder: "Choisissez une majoration",
            onSelect: (majoId) => {
              formRef.current?.setFieldsValue({
                hourly_rate_profil: formatNumberString({
                  str: currency_cents_to_euros(computeHourlyRateCents(majoId)),
                  nbDecimal: 2,
                }),
                margin_rate_computed: formatNumberString({
                  str: formulaMarginRate({
                    costPrice: currency_cents_to_euros(
                      computeHourlyRateCents(majoId)
                    ),
                    calculMethod,
                    sellingPrice: stringToFloat(
                      formRef.current?.form.getFieldValue("selling_price_cents")
                    ),
                    isUsingMargin,
                  }),
                  isCoeff: !isUsingMargin,
                  space: true,
                }),
              });
            },
          },
          options: majorations,
          keyName: "name",
          name: getMajorationKey(),
          label: "Majoration",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const sellingPriceAndFAMarginRate = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            suffix: `${isPackage ? "€" : "€/h"}`,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 2,
              });
              formRef.current?.setFieldsValue({
                margin_rate_computed: formatNumberString({
                  str: formulaMarginRate({
                    costPrice: stringToFloat(
                      isPackage
                        ? formRef.current?.form.getFieldValue(
                            "cost_price_cents"
                          )
                        : formRef.current?.form.getFieldValue(
                            "hourly_rate_profil"
                          )
                    ),
                    calculMethod,
                    sellingPrice: stringToFloat(e.currentTarget.value),
                    isUsingMargin,
                  }),
                  isCoeff: !isUsingMargin,
                  space: true,
                }),
              });
            },
            onFocus: (e) => {
              formRef.current?.setFieldsValue({
                selling_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              });
            },
            onBlur: (e) => {
              formRef.current?.setFieldsValue({
                selling_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
            disabled: !hasPrestationIncluded,
          },
          name: "selling_price_cents",
          label: "Prix de vente HT",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            disabled: true,
          },
          name: "margin_rate_computed",
          isComputed: true,
          isMarginRate: true,
        },
      },
    ];
    const marginRatePackageRow = [
      {
        type: "checkbox",
        span: use_special_tva ? 4 : 12,
        item: {
          checkbox: {
            disabled: !isCreating || !configuration.admin.profil_tab.visible,
            onChange: ({ target: { checked } }) => setIsPackage(checked),
          },
          name: "is_package",
          label: "Forfait",
        },
      },
      {
        type: "input",
        span: use_special_tva ? 8 : 12,
        item: {
          input: {
            customContent: (
              <MarginItem
                hasMargin={hasMargin}
                profilId={profilId}
                categoryId={categoryId}
                entityWorkId={entityWorkId}
                opportunityId={opportunityId}
                itemType="prestation_profession"
              />
            ),
            disabled: showFAFields,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                isCoeff: !isUsingMargin,
              });
              setHasMargin(e.currentTarget.value !== "");
            },
          },
          isMarginRate: true,
          name: "margin_rate",
        },
      },
      use_special_tva && {
        type: "select",
        span: 12,
        item: {
          select: {
            onChange: (e) => {
              setHasTVA(e !== "none" && e !== undefined);
            },
            allowClear: true,
            customContent: (
              <TvaSelector
                hasTVA={hasTVA}
                itemType="prestation_profession"
                entityWorkId={getUrlParams()}
                categoryId={
                  categoryId || initialPrestation?.category_profession_id
                }
                tvaProfessionId={initialPrestation?.tva_profession_id}
              />
            ),
          },
          options: tva_professions,
          keyName: "name",
          name: "tva_profession_id",
          label: "TVA spéciale",
        },
      },
    ];
    const packageRow = [
      {
        type: "checkbox",
        item: {
          checkbox: {
            disabled: !isCreating || !configuration.admin.profil_tab.visible,
            onChange: ({ target: { checked } }) => setIsPackage(checked),
          },
          name: "is_package",
          label: "Forfait",
        },
      },
    ];
    const hourlyRateCentsRow = [
      {
        type: "input",
        item: {
          input: {
            suffix: "€",
            disabled: showFAFields,
            placeholder: "0,00",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
              });
            },
          },
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
          name: "cost_price_cents",
          label: "Coût de revient",
        },
        span: 12,
      },
      !showFAFields
        ? {
            type: "input",
            item: {
              input: {
                disabled: true,
                suffix: isPackage ? "€" : "€/h",
              },
              label: "Prix de vente HT calculé",
              name: "selling_price_computed",
              isComputed: true,
              isMarginRate: true,
            },
          }
        : {},
    ];
    const packageCalculatedSellingPriceRow = [
      !showFAFields
        ? {
            type: "input",
            item: {
              input: {
                disabled: true,
                suffix: isPackage ? "€" : "€/h",
              },
              label: "Prix de vente HT calculé",
              name: "selling_price_computed",
              isComputed: true,
              isMarginRate: true,
            },
          }
        : {},
    ];

    const timeRow = [
      {
        type: "select",
        item: {
          select: {
            disabled: showFAFields,
            placeholder: "Choisissez une unité de temps",
            onChange: (value) => {
              setMinutes(value);
            },
          },
          options: [
            { title: "Heure", id: false },
            { title: "Minute", id: true },
          ],
          name: "minutes",
          keyName: "title",
          label: "Temps",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      !showFAFields
        ? {
            type: "input",
            item: {
              input: {
                disabled: true,
                suffix: isPackage ? "€" : "€/h",
              },
              label: "Prix de vente HT calculé",
              name: "selling_price_computed",
              isComputed: true,
              isMarginRate: true,
            },
          }
        : {},
    ];
    const fifthRow = [
      {
        type: "select",
        item: {
          select: {
            disabled: showFAFields,
            placeholder: "Choisissez un profil",
            onChange: (value) => {
              setProfilId(profils?.find((el) => el.id === value)?.id);
            },
          },
          options: formatedProfils,
          name: "profil_profession_id",
          label: "Profil",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            suffix: "€/h",
            placeholder: "0,00",
            disabled: true,
          },
          name: "hourly_rate_profil",
          label: "Coût de revient horaire",
        },
      },
    ];
    const profilRow = [
      {
        type: "select",
        item: {
          select: {
            disabled: showFAFields,
            placeholder: "Choisissez un profil",
            onChange: (value) => {
              setProfilId(profils?.find((el) => el.id === value)?.id);
            },
          },
          options: formatedProfils,
          name: "profil_profession_id",
          label: "Profil",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const speedRow = [
      configuration.admin.prestation_tab.form.input_speed && {
        type: "checkbox",
        item: {
          checkbox: {
            disabled: showFAFields,
            onChange: ({ target: { checked } }) =>
              setDisabledSpeedField(!checked),
          },
          name: "speed",
          label: "Vitesse",
        },
        span: 3,
      },
      disabledSpeedField && {
        type: "input",
        item: {
          input: {
            placeholder: "0",
            disabled: showFAFields,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 2,
              });
            },
          },
          name: "quantity",
          label: `Durée (${getTimeUnity() ? "min" : "h"}) par défaut`,
        },
      },
      !disabledSpeedField && {
        type: "select",
        item: {
          select: {
            placeholder: "Choisissez une unité",
            disabled: disabledSpeedField || showFAFields,
          },
          options: formatedUnities,
          name: "unity_profession_id",
          label: "Unité de quantité",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
        span: 14,
      },
      !disabledSpeedField && {
        type: "input",
        item: {
          input: {
            placeholder: "0",
            disabled: disabledSpeedField || showFAFields,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
              });
            },
          },
          name: "speed_quantity",
          label: `Unité/${getTimeUnity() ? "min" : "h"}`,
        },
        span: 7,
        style: disabledSpeedField ? { display: "none" } : {},
      },
    ];

    const seventhRow = [
      configuration.general.subcontracting && {
        type: "checkbox",
        item: {
          checkbox: {
            disabled: !isPackage || showFAFields,
          },
          name: "subcontracting",
          label: "Sous-traitance",
        },
      },
      // {
      //   type: "checkbox",
      //   item: {
      //     checkbox: {
      //       disabled: showFAFields,
      //     },
      //     name: "carrycot",
      //     label: "Eligible nacelle",
      //   },
      // },
      configuration.general.maintenance && {
        type: "checkbox",
        item: {
          checkbox: {
            disabled: from === "opportunity" || showFAFields,
          },
          name: "maintenance",
          label: "Maintenance",
        },
      },
    ];

    return [
      { columns: firstRow, key: "name+picture" },
      showFAFields && {
        columns: presOppName,
        key: "matOppName",
      },
      showFAFields && {
        columns: presOppRefCode,
        key: "matOppRefCode",
      },
      { columns: secondRow, key: "category_profession_id" },
      showFAFields &&
        !isPackage && {
          columns: majorationRow,
          key: "majorationRow",
        },
      { columns: descriptionRemarkRow, key: "descriptionRemarkRow" },
      showFAFields &&
        from !== "referencing" && {
          columns: sellingPriceAndFAMarginRate,
          key: "sellingPriceAndFAMarginRate",
        },
      !isShowing &&
        configuration.admin.prestation_tab.form.input_margin_rate && {
          columns: marginRatePackageRow,
          key: "margin_rate+package+tva_profession_id",
        },
      isShowing &&
        configuration.costing.page_3.show_margin && {
          columns: marginRatePackageRow,
          key: "margin_rate+package+tva_profession_id",
        },
      isShowing &&
        !configuration.costing.page_3.show_margin && {
          columns: packageRow,
          key: "package",
        },
      isPackage &&
        isShowing &&
        configuration.costing.page_1.table_col_price && {
          columns: hourlyRateCentsRow,
          key: "hourlyRateCentsCol",
        },
      isPackage &&
        !isShowing && {
          columns: hourlyRateCentsRow,
          key: "hourlyRateCentsCol",
        },
      isPackage &&
        isShowing &&
        !configuration.costing.page_1.table_col_price && {
          columns: packageCalculatedSellingPriceRow,
          key: "packageCalculatedSellingPriceRow",
        },

      !isPackage &&
        !isShowing && { columns: fifthRow, key: "profil_profession_id" },
      !isPackage &&
        isShowing &&
        !configuration.costing.page_1.table_col_price && {
          columns: profilRow,
          key: "profil_profession_id",
        },
      !isPackage &&
        isShowing &&
        configuration.costing.page_1.table_col_price && {
          columns: fifthRow,
          key: "profil_profession_id",
        },
      !isPackage && { columns: timeRow, key: "minutes" },
      !isPackage && {
        columns: speedRow,
        key: "unity_profession_id+speed_quantity",
      },
      {
        columns: seventhRow,
        key: "quantity+subcontracting+carrycot+maintenance",
      },
    ];
  }, [
    categories,
    profils,
    unities,
    disabledSpeedField,
    from,
    showFAFields,
    hasPrestationIncluded,
    isCreating,
    isPackage,
    updateOnChange,
    faIsValidate,
    calculMethod,
    majorations,
    getMajorationKey,
    computeHourlyRateCents,
    isUsingMargin,
    isUsingMinutes,
    hasMargin,
    profilId,
    categoryId,
    setHasTVA,
    hasTVA,
    entityWorkId,
    opportunityId,
    minutes,
  ]);
  const initialValues = useMemo(() => {
    const defaultUnity = profils.length > 0 ? unities[0]?.id : undefined;
    if (!initialPrestation || isCreating)
      return {
        unity_profession_id: defaultUnity,
        is_package: !isCreating || !configuration.admin.profil_tab.visible,
        minutes: defaultMinutes,
      };
    const margin_rate = initialPrestation.margin_rate
      ? formatNumberString({
          str: marginToCoeff({
            marginRate: initialPrestation.margin_rate,
            isUsingMargin,
            calculMethod,
          }),
          isCoeff: !isUsingMargin,
        })
      : undefined;
    const unity_profession_id =
      initialPrestation.unity_profession_id || defaultUnity;
    const picture = initialPrestation.picture
      ? [
          {
            uid: "1",
            name: "image",
            status: "done",
            url: initialPrestation.picture,
          },
        ]
      : [];
    const originalDriveLink = !hasPrestationIncluded
      ? initialPrestation.drive_link
      : initialPrestation.prestation_included?.drive_link;
    const drive_link = originalDriveLink
      ? [
          {
            uid: "1",
            name: !hasPrestationIncluded
              ? initialPrestation.drive_link_name
              : initialPrestation.prestation_included?.drive_link_name,
            status: "done",
            url: originalDriveLink,
          },
        ]
      : [];

    const speed_quantity = initialPrestation.minutes
      ? parseFloat(initialPrestation.speed_quantity) / 60
      : initialPrestation.speed_quantity;

    return {
      ...initialPrestation,
      name: initialPrestation.name,
      profil_profession_id: initialPrestation.profil_profession_id,
      description: !hasPrestationIncluded
        ? initialPrestation.description
        : initialPrestation.prestation_included?.description,
      drive_link,
      category_profession_id: singleItemToCascader({
        childItem: categories?.find(
          (el) => el.id === initialPrestation.category_profession_id
        ),
        items: categories,
        keyParent: "parent_category_id",
      }),
      margin_rate,
      subcontracting: initialPrestation?.subcontracting,
      speed: initialPrestation.speed,
      speed_quantity: formatNumberString({
        str: speed_quantity,
      }),
      quantity: formatNumberString({
        str: quantityTimeConverter({
          quantity: initialPrestation.quantity,
          isUsingMinutes:
            initialPrestation.minutes !== null
              ? initialPrestation.minutes
              : isUsingMinutes,
        }),
      }),
      carrycot: initialPrestation.carrycot,
      maintenance: initialPrestation.maintenance,
      unity_profession_id,
      picture,
      reference_code: initialPrestation.reference_code,
      hourly_rate_profil: formatNumberString({
        str: currency_cents_to_euros(computeHourlyRateCents()),
        nbDecimal: 2,
      }),
      cost_price_cents: formatNumberString({
        str: centsToEuros(initialPrestation.cost_price_cents),
      }),
      prestation_included_name: hasPrestationIncluded
        ? initialPrestation.prestation_included?.name
        : initialPrestation.name,
      prestation_included_reference_code: hasPrestationIncluded
        ? initialPrestation.prestation_included?.reference_code
        : initialPrestation.reference_code,
      is_in_framework_agreement: initialPrestation.is_in_framework_agreement,
      selling_price_cents: !hasPrestationIncluded
        ? formatNumberString({
            str: currency_cents_to_euros(
              initialPrestation.is_package
                ? initialPrestation.cost_price_cents
                : computeHourlyRateCents()
            ),
            nbDecimal: 2,
            space: true,
          })
        : formatNumberString({
            str: currency_cents_to_euros(
              initialPrestation.prestation_included?.selling_price_cents
            ),
            nbDecimal: 2,
            space: true,
          }),
      margin_rate_computed:
        !hasPrestationIncluded || from !== "frameworkAgreement"
          ? 0
          : formatNumberString({
              str: formulaMarginRate({
                costPrice: initialPrestation.is_package
                  ? initialPrestation.cost_price_cents
                  : computeHourlyRateCents(),
                calculMethod,
                sellingPrice:
                  initialPrestation.prestation_included?.selling_price_cents,
                isUsingMargin,
              }),
              isCoeff: !isUsingMargin,
              space: true,
            }),
      minutes:
        initialPrestation.minutes !== null
          ? initialPrestation.minutes
          : isUsingMinutes,
      tva_profession_id: initialPrestation?.tva_profession_id,
      [getMajorationKey()]:
        hasPrestationIncluded && !!initialPrestation.prestation_included
          ? initialPrestation.prestation_included[getMajorationKey()]
          : defaultMajoration,
      selling_price_computed: formatNumberString({
        str: formulaSellingPrice({
          costPrice: initialPrestation.is_package
            ? initialPrestation.cost_price_cents
            : computeHourlyRateCents(),
          marginRate:
            initialPrestation.margin_rate == null ||
            typeof initialPrestation.margin_rate === "undefined"
              ? initialPrestation.default_margin_rate
              : initialPrestation.margin_rate,
          calculMethod,
          isCoeff: false,
        }),
        isCoeff: !isUsingMargin,
        space: true,
      }),
    };
  }, [
    initialPrestation,
    unities,
    categories,
    profils,
    hasPrestationIncluded,
    calculMethod,
    from,
    defaultMajoration,
    getMajorationKey,
    isUsingMargin,
    isUsingMinutes,
  ]);
  const onValuesChange = async (changedValues) => {
    if (changedValues.profil_profession_id && formRef.current) {
      const profil = profils.find(
        (el) => el.id === changedValues.profil_profession_id
      );
      formRef.current.setFieldsValue({
        hourly_rate_profil: formatNumberString({
          str: currency_cents_to_euros(profil.hourly_rate_cents),
          nbDecimal: 2,
        }),
        subcontracting: profil.subcontracting,
      });

      const data = await getData(
        formToken,
        fetchDefaultMarginrate({
          itemType: "prestation_profession",
          opportunityId,
          entityWorkId,
          categoryId,
          profilId: changedValues.profil_profession_id,
        })
      );
      formRef.current?.setFieldsValue({
        selling_price_computed: formatNumberString({
          str: formulaSellingPrice({
            costPrice:
              formRef.current?.form.getFieldValue("hourly_rate_profil")
                .length === 0
                ? 0
                : stringToFloat(
                    formRef.current?.form.getFieldValue("hourly_rate_profil")
                  ) * 100,
            marginRate:
              typeof formRef.current?.form.getFieldValue("margin_rate") ===
              "undefined"
                ? "undefined"
                : formRef.current?.form.getFieldValue("margin_rate"),
            defaultMarginRate: data.margin_rate,
            calculMethod,
            isCoeff: !isUsingMargin,
          }),
          isCoeff: !isUsingMargin,
          space: true,
        }),
      });
    }

    if (changedValues.category_profession_id && formRef.current) {
      const data = await getData(
        formToken,
        fetchDefaultMarginrate({
          itemType: "prestation_profession",
          opportunityId,
          entityWorkId,
          categoryId: changedValues.category_profession_id,
          profilId,
        })
      );
      formRef.current?.setFieldsValue({
        selling_price_computed: formatNumberString({
          str: formulaSellingPrice({
            costPrice: formRef.current?.form.getFieldValue("is_package")
              ? formRef.current?.form.getFieldValue("cost_price_cents") ===
                  undefined ||
                formRef.current?.form.getFieldValue("cost_price_cents")
                  .length === 0
                ? 0
                : stringToFloat(
                    formRef.current?.form.getFieldValue("cost_price_cents")
                  ) * 100
              : formRef.current?.form.getFieldValue("hourly_rate_profil")
                  .length === 0
              ? 0
              : stringToFloat(
                  formRef.current?.form.getFieldValue("hourly_rate_profil")
                ) * 100,
            marginRate:
              typeof formRef.current?.form.getFieldValue("margin_rate") ===
                "undefined" ||
              formRef.current?.form.getFieldValue("margin_rate").length === 0
                ? "undefined"
                : formRef.current?.form.getFieldValue("margin_rate"),
            defaultMarginRate: data.margin_rate,
            calculMethod,
            isCoeff: !isUsingMargin,
          }),
          isCoeff: !isUsingMargin,
          space: true,
        }),
      });
    }
    if ("margin_rate" in changedValues && formRef.current) {
      const data = await getData(
        formToken,
        fetchDefaultMarginrate({
          itemType: "prestation_profession",
          opportunityId,
          entityWorkId,
          categoryId,
        })
      );
      formRef.current?.setFieldsValue({
        selling_price_computed: formatNumberString({
          str: formulaSellingPrice({
            costPrice: initialPrestation.is_package
              ? formRef.current?.form.getFieldValue("cost_price_cents")
                  .length === 0
                ? 0
                : stringToFloat(
                    formRef.current?.form.getFieldValue("cost_price_cents")
                  ) * 100
              : formRef.current?.form.getFieldValue("hourly_rate_profil")
                  .length === 0
              ? 0
              : stringToFloat(
                  formRef.current?.form.getFieldValue("hourly_rate_profil")
                ) * 100,
            marginRate:
              changedValues.margin_rate.length === 0
                ? "undefined"
                : stringToFloat(
                    formRef.current?.form.getFieldValue("margin_rate")
                  ),
            defaultMarginRate: data.margin_rate,
            calculMethod,
            isCoeff: !isUsingMargin,
          }),
          isCoeff: !isUsingMargin,
          space: true,
        }),
      });
    }
    if ("cost_price_cents" in changedValues && formRef.current) {
      const data = await getData(
        formToken,
        fetchDefaultMarginrate({
          itemType: "prestation_profession",
          opportunityId,
          entityWorkId,
          categoryId,
        })
      );
      formRef.current?.setFieldsValue({
        selling_price_computed: formatNumberString({
          str: formulaSellingPrice({
            costPrice: stringToFloat(changedValues.cost_price_cents) * 100,
            marginRate:
              typeof formRef.current?.form.getFieldValue("margin_rate") ===
              "undefined"
                ? "undefined"
                : stringToFloat(
                    formRef.current?.form.getFieldValue("margin_rate")
                  ),
            defaultMarginRate: data.margin_rate,
            calculMethod,
            isCoeff: !isUsingMargin,
          }),
          isCoeff: !isUsingMargin,
          space: true,
        }),
      });
    }

    if ("minutes" in changedValues && formRef.current) {
      let actualSpeedQuantity;
      let actualQuantity;
      if (changedValues.minutes && currentPrestation !== null) {
        actualQuantity = currentPrestation.quantity;
        actualSpeedQuantity = currentPrestation.speed_quantity;
      } else {
        actualQuantity = formRef.current?.form.getFieldValue("quantity");
        actualSpeedQuantity =
          formRef.current?.form.getFieldValue("speed_quantity");
      }
      const quantityConvert = formatNumberString({
        str: quantityTimeConverter({
          quantity: stringToFloat(actualQuantity),
          isUsingMinutes: true,
          toMinutes: changedValues.minutes,
        }),
      });
      let speedQuantityConvert;
      if (changedValues.minutes && currentPrestation !== null) {
        speedQuantityConvert = formatNumberString({
          str: parseFloat(actualSpeedQuantity) / 60,
        });
      } else {
        speedQuantityConvert = formatNumberString({
          str: quantityTimeConverter({
            quantity: stringToFloat(actualSpeedQuantity),
            isUsingMinutes: !changedValues.minutes,
            toMinutes: !changedValues.minutes,
          }),
        });
      }
      formRef.current.setFieldsValue({
        quantity: quantityConvert,
        speed_quantity: speedQuantityConvert,
      });
    }
  };

  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={formFactory}
      isShowing={isShowing}
      onValuesChange={onValuesChange}
      ref={formRef}
      id="prestation-form"
    />
  );
}

PrestationForm.propTypes = {
  categories: array,
  unities: array,
  profils: array,
  isShowing: bool,
  onSubmit: func,
  updateOnChange: bool,
  submit: shape({
    onCancel: func,
  }),
  initialPrestation: object,
  from: string,
  hasPrestationIncluded: bool,
  isCreating: bool,
  faIsValidate: bool,
  majorations: array,
  defaultMajoration: number,
  opportunityId: number,
  entity_work_name: string,
  configuration: object,
  vocabulary: object,
  entityWorkId: string,
  currentPrestation: object,
  defaultMinutes: bool,
  use_special_tva: bool,
  tva_professions: array,
};

PrestationForm.defaultProps = {
  categories: [],
  unities: [],
  profils: [],
  isShowing: false,
  onSubmit: undefined,
  updateOnChange: false,
  submit: undefined,
  initialPrestation: undefined,
  hasPrestationIncluded: false,
  from: "admin",
  isCreating: false,
  faIsValidate: false,
  majorations: [],
  defaultMajoration: undefined,
  opportunityId: null,
  entity_work_name: "",
  configuration: {},
  vocabulary: {},
  entityWorkId: undefined,
  currentPrestation: {},
  defaultMinutes: null,
  use_special_tva: false,
  tva_professions: [],
};

export default PrestationForm;
