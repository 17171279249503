import React, { useEffect, useMemo, useState } from "react";
import { Checkbox } from "antd";
import { useStore } from "../../store";
import useDeleteTodo from "../../hookQuery/useDeleteTodo";
import { ShowDeleteConfirm } from "../confirmModal";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import ActionsContainer from "../../react-ui/ActionsContainer";
import Delete from "../../react-ui/Icons/Delete";
import { stringSorter } from "../../../utils/stringSorter";
import { numberSorter } from "../../../utils/numberSorter";
import { centsToEuros } from "../../../utils/currencyConverter";
import NumberCell from "../../react-ui/NumberCell";
import { formatNumberString } from "../../../utils/formatNumberString";

const selector = (state) => ({
  selectedCarrycot: state.selectedCarrycot,
  editCarrycot: state.editCarrycot,
  formToken: state.formToken,
});

function Actions({ value, handleDeleteCarrycot, isDeletable }) {
  return isDeletable ? (
    <ActionsContainer>
      <Delete
        onClick={() =>
          ShowDeleteConfirm(value.name, value.id, handleDeleteCarrycot)
        }
      />
    </ActionsContainer>
  ) : null;
}

function CarrycotArray({
  carrycotDetailsStatus,
  setCarrycotDetailsStatus,
  carrycots,
  profils,
  entityWorkId,
  from,
  handleFAAndOpp,
  faIsValidate,
}) {
  const { editCarrycot, formToken, selectedCarrycot } = useStore(selector);
  const { mutate: removeCarrycots } = useDeleteTodo(
    formToken,
    "/carrycot_profession/delete",
    "Carrycots"
  );
  const pageEntityWork = parseInt(entityWorkId, 10);

  const [tableRowsData, setTableRowData] = useState([]);

  const isDeletable = (id) => from === "admin" && id === pageEntityWork;

  useEffect(() => {
    const dataResult = [];
    let child;

    carrycots?.forEach((value) => {
      child = {
        ...value,
        key: value.id,
        id: value.id,
        name: value.carrycot_included
          ? value.carrycot_included.name
          : value.name,
        cost_price_cents: formatNumberString({
          str: centsToEuros(value.cost_price_cents),
        }),
        is_in_framework_agreement:
          value.is_in_framework_agreement || value.carrycot_included,
        profil: profils?.find(
          (profil) => profil.id === value.profil_profession_id
        )?.name,
        actions: (
          <Actions
            handleDeleteCarrycot={handleDeleteCarrycot}
            value={value}
            isDeletable={isDeletable(value.entity_work_id)}
          />
        ),
      };
      dataResult.push(child);
    });

    setTableRowData(dataResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrycots, profils]);

  const openUpdateCarrycotProfession = (value) => {
    editCarrycot(value);
    setCarrycotDetailsStatus("update");
  };

  const handleDeleteCarrycot = (idCarrycot) => {
    setCarrycotDetailsStatus("empty");
    editCarrycot();
    removeCarrycots(
      JSON.stringify({ id: idCarrycot, entity_work_id: pageEntityWork })
    );
  };

  const getColumnsCarrycots = useMemo(() => {
    const nameCol = {
      title: "Intitulé",
      dataIndex: "name",
      sorter: (a, b) => stringSorter(a.name, b.name),
    };

    const profilCol = {
      title: "Profil personnel",
      dataIndex: "profil",
      sorter: (a, b) => stringSorter(a.profil, b.profil),
      width: "42.5%",
    };

    const costPriceCol = {
      title: "Coût de revient",
      dataIndex: "cost_price_cents",
      sorter: (a, b) => numberSorter(a.cost_price_cents, b.cost_price_cents),
      width: "100px",
      render: (value) => (value ? <NumberCell>{value} €</NumberCell> : null),
    };

    const frameworkAgreementCol = {
      title: "Accord cadre",
      dataIndex: "is_in_framework_agreement",
      width: "60px",
      align: "center",
      sorter: (a, b) =>
        numberSorter(a.is_in_framework_agreement, b.is_in_framework_agreement),
      render: (value, record) => (
        <Checkbox
          checked={value}
          onClick={() => handleFAAndOpp(value, record)}
          disabled={from === "frameworkAgreement" && !faIsValidate}
        />
      ),
    };

    const actionsCol = {
      title: "",
      dataIndex: "actions",
      width: "20px",
    };

    if (from !== "admin") return [nameCol, costPriceCol, frameworkAgreementCol];
    return [nameCol, profilCol, actionsCol];
  }, [from, handleFAAndOpp, faIsValidate]);

  return (
    <TableContainer
      bordered={false}
      scrollY
      columns={getColumnsCarrycots}
      dataSource={tableRowsData}
      pagination={false}
      isRowSelectable
      rowSelectableId={
        carrycotDetailsStatus === "update" ? selectedCarrycot?.id : undefined
      }
      onRow={(record) => {
        return {
          onClick: () => {
            openUpdateCarrycotProfession(
              carrycots.find((el) => el.id === record.id)
            );
          },
        };
      }}
    />
  );
}

export default CarrycotArray;
