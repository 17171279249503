import React from "react";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { stringSorter } from "../../../../utils/stringSorter";
import { numberSorter } from "../../../../utils/numberSorter";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { centsToEuros } from "../../../../utils/currencyConverter";
import { translate_filter } from "../../Categories/translate_data";
import NumberCell from "../../../react-ui/NumberCell";
import AddButton from "../../../react-ui/AddButton";

const columns = () => [
  {
    title: "Nom",
    dataIndex: "last_name",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Prénom",
    dataIndex: "first_name",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Fonction",
    dataIndex: "fonction",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Téléphone",
    dataIndex: "phone",
    filterMode: "tree",
  },
];

function ContactsArray({
  contacts = [],
  contactsAreLoading,
  wordEntered,
  setWordEntered,
  selectedExternalOrder,
  setSelectedExternalOrder,
  setStatus,
  clients,
  entities,
  works,
}) {
  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  return (
    <>
      <ButtonContainer>
        <StyledSearch
          allowClear
          placeholder="Rechercher un contact..."
          value={wordEntered}
          onChange={handleFilter}
        />
      </ButtonContainer>
      <Container>
        <TableContainer
          bordered={false}
          columns={columns()}
          rowKey="id"
          dataSource={contacts}
          pagination={false}
          scrollY
          loading={contactsAreLoading}
        />
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const StyledSearch = styled(Search)`
  margin-bottom: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

export default ContactsArray;
