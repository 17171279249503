import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Modal } from "antd";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Search from "antd/lib/input/Search";
import { useStore } from "../../store";
import Input from "../../react-ui/Input";
import { formatNumberString } from "../../../utils/formatNumberString";
import { stringToFloat } from "../../../utils/stringToFloat";
import Eye from "../../react-ui/Icons/Eye";
import { getData, postData } from "../../request/instance";
import {
  getFetchUrlIdAndFrom,
  getFrom,
} from "../../../utils/getFetchUrlIdAndFrom";
import { generateFormData } from "../../../utils/generateFormData";
import LazyMaterialArray from "../Materials/LazyMaterialArray";
import { fetchMaterialAssociatesUrl } from "../../../utils/fetchMaterialAssociatesUrl";
import MaterialsForm from "../Materials/MaterialsForm";
import Select from "../../react-ui/Select";

const selector = (state) => ({
  selectedMateriel: state.selectedMateriel,
  formToken: state.formToken,
  selectedFamily: state.selectedFamily,
});

function MaterialAssociates({
  categories,
  unities,
  manufacturers,
  distributors,
  updateMaterialAssociate,
  from,
  opportunityId,
  entityWorkId,
  faId,
  faIsValidate,
  parent,
  configuration,
  vocabulary,
  setMaterialsParent,
  materialsParent,
  round_type_list,
  setImpactedModelZone,
}) {
  const queryClient = useQueryClient();
  const { selectedMateriel, formToken, selectedFamily } = useStore(selector);
  const [materialAssociateData, setMaterialAssociateData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showMaterial, setShowMaterial] = useState();
  const [wordEntered, setWordEntered] = useState("");
  const [materials, setMaterials] = useState([]);

  const getParentInformations = useCallback(() => {
    let keyId;
    let keyName;
    let id;
    const specialFrom = getFrom({ from, faIsValidate, opportunityId });
    if (specialFrom === "referencing") {
      keyId = "material_opportunity_parent_id";
      id = selectedMateriel?.material_included?.id;
      keyName = "material_opportunity";
    } else if (specialFrom === "frameworkAgreement") {
      keyId = "material_fa_parent_id";
      id = selectedMateriel?.material_included?.id;
      keyName = "material_fa_child";
    } else {
      keyId =
        parent === "material" ? "material_child_id" : "family_profession_id";
      keyName = "material_parent";
      id = parent === "material" ? selectedMateriel?.id : selectedFamily?.id;
    }
    return { keyId, keyName, id };
  }, [
    faIsValidate,
    from,
    opportunityId,
    parent,
    selectedFamily?.id,
    selectedMateriel?.id,
    selectedMateriel?.material_included?.id,
  ]);

  const { data: materialsAssociateApi } = useQuery(
    [
      "MaterialsAssociates",
      {
        from,
        parent,
        faId,
        keyId: getParentInformations().keyId,
        parentId: getParentInformations().id,
      },
    ],
    () =>
      getData(
        formToken,
        fetchMaterialAssociatesUrl({
          ...getFetchUrlIdAndFrom({
            from,
            opportunityId,
            faIsValidate,
            faId,
            entityWorkId,
          }),
          keyId: getParentInformations().keyId,
          parentId: getParentInformations().id,
        })
      )
  );

  const { mutate: createAssociate } = useMutation(
    (todo) => postData(formToken, "/material_associate/create", todo),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("MaterialsAssociates");
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  const onOppAndFAAssoCreation = {
    // Optimistic update of material_professions
    onMutate: async ({ material_profession_id }) => {
      const previousMaterials = materialsParent;
      const idxToUpdate = materialsParent.findIndex(
        (el) => el.id === material_profession_id
      );
      const materialsUpdated = materialsParent;
      if (idxToUpdate === -1)
        return {
          previousMaterials,
          material_profession_id,
          materialsUpdated,
        };
      materialsUpdated[idxToUpdate] = {
        ...materialsUpdated[idxToUpdate],
        is_in_framework_agreement: true,
        material_included: null,
      };
      setMaterialsParent([...materialsUpdated]);
      return {
        previousMaterials,
        material_profession_id,
        materialsUpdated,
      };
    },
    onSuccess: (payload, _, context) => {
      const idxToUpdate = context.materialsUpdated.findIndex(
        (el) => el.id === context.material_profession_id
      );
      queryClient.invalidateQueries("MaterialsAssociates");
      if (idxToUpdate === -1) return;
      const { materialsUpdated } = context;
      materialsUpdated[idxToUpdate] = {
        ...materialsUpdated[idxToUpdate],
        material_included: materialsUpdated[idxToUpdate]
          .is_in_framework_agreement
          ? payload
          : null,
      };
      setMaterialsParent([...materialsUpdated]);
    },
    onError: (_, __, context) => {
      setMaterialsParent(context.previousMaterials);
    },
  };

  const { mutate: createAssoOpp } = useMutation(
    (todo) =>
      postData(
        formToken,
        "/material_associate_opportunity/create_for_referencing",
        todo
      ),
    onOppAndFAAssoCreation
  );

  const getAssociateKey = () => {
    if (from === "referencing") return "material_associate_opportunity";
    if (from === "frameworkAgreement")
      return "material_asso_framework_agreement";
    return "material_associate";
  };

  const { mutate: createAssoFA } = useMutation(
    (todo) =>
      postData(formToken, "/material_asso_framework_agreement/create", todo),
    onOppAndFAAssoCreation
  );

  const { mutate: removeAssociate } = useMutation(
    (todo) => postData(formToken, `/${getAssociateKey()}/delete`, todo),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("MaterialsAssociates");
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  useEffect(() => {
    const { keyId, keyName, id } = getParentInformations();

    const materialsAsso =
      materialsAssociateApi?.filter((value) => value[keyId] === id) || [];

    setMaterialAssociateData(
      materials?.map((mat) => {
        const matAsso = materialsAsso?.find((asso) =>
          from === "admin"
            ? asso.material_parent_id === mat.id
            : asso[`${keyName}`]?.material_profession_id === mat.id
        );

        const matAssoFields = matAsso
          ? {
              associated: true,
              asso_id: matAsso.id,
              ...matAsso,
              name: from !== "admin" ? matAsso[keyName]?.name : mat.name,
            }
          : {};

        return {
          ...mat,
          ...matAssoFields,
          id: mat.id,
          key: mat.id,
        };
      })
    );
  }, [from, getParentInformations, materials, materialsAssociateApi]);

  const handleUpdate = async (id, newProperties) => {
    updateMaterialAssociate(JSON.stringify({ id, ...newProperties }));
  };

  const handleDelete = async (id) => {
    removeAssociate(
      JSON.stringify(
        from === "referencing" ? { id, referencing: true } : { id }
      )
    );
  };

  const showModal = (mat) => {
    setIsModalVisible(true);
    setShowMaterial(materials.find((item) => item.id === mat.id));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const createForFAAndOpp = (e, linked) => {
    if (from === "referencing")
      createAssoOpp({
        parent_id: selectedMateriel.material_included.id,
        opportunity_id: opportunityId,
        material_profession_id: e.id,
        linked,
        quantity: e.quantity,
      });
    else
      createAssoFA({
        material_fa_parent_id: selectedMateriel.material_included.id,
        framework_agreement_id: faId,
        material_profession_id: e.id,
        linked,
        quantity: e.quantity,
      });
  };

  const handleChecked = ({ id, checked, linked }) => {
    if (checked) {
      const mat = materials.find((el) => el.id === id);
      if (from !== "admin") {
        createForFAAndOpp(mat, linked);
        return;
      }
      const formData = generateFormData({
        e: mat,
        formName: "material_associate",
        excludedKeys: ["quantity", "id"],
      });
      formData.append("material_associate[material_parent_id]", mat.id);
      if (mat.quantity)
        formData.append(
          "material_associate[quantity]",
          stringToFloat(mat.quantity)
        );
      if (linked) formData.append("material_associate[linked]", true);
      if (parent === "material")
        formData.append(
          "material_associate[material_child_id]",
          selectedMateriel?.id
        );
      else
        formData.append(
          "material_associate[family_profession_id]",
          selectedFamily?.id
        );
      createAssociate(formData);
    } else {
      const { keyName, id: parentId, keyId } = getParentInformations();
      handleDelete(
        materialsAssociateApi.find(
          (el) =>
            el[keyId] === parentId &&
            (from === "admin"
              ? el.material_parent_id === id
              : el[`${keyName}`]?.material_profession_id === id)
        ).id
      );
    }
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const matCols = [
    {
      title: "Intitulé",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Lié",
      dataIndex: "linked",
      key: "linked",
      width: "60px",
      hidden:
        !configuration.admin.product_tab.form.product_associate.input_linked,
      render: (val, material) =>
        (from !== "admin" && !selectedMateriel.is_in_framework_agreement) ||
        (from === "frameworkAgreement" &&
          opportunityId &&
          !faIsValidate) ? null : (
          <CenteredContainer>
            <Checkbox
              disabled={
                from === "frameworkAgreement" && opportunityId && !faIsValidate
              }
              checked={val}
              onChange={({ target: { checked } }) => {
                if (material.associated)
                  handleUpdate(material.asso_id, { linked: checked });
                else {
                  handleChecked({
                    id: material.id,
                    checked: true,
                    linked: true,
                  });
                }
              }}
            />
          </CenteredContainer>
        ),
    },
    {
      title: "Quantité",
      dataIndex: "quantity",
      key: "quantity",
      width: "70px",
      hidden:
        !configuration.admin.product_tab.form.product_associate.input_linked,
      render: (val, material) =>
        material.associated && (
          <Input
            fontSize="10px"
            value={material.linked ? formatNumberString({ str: val }) : ""}
            size="small"
            textAlign="right"
            suffix={material.abbreviation}
            onChange={(e) => {
              const materialUpdated = materialAssociateData;
              const idxToUpdate = materialUpdated.findIndex(
                (el) => el.id === material.id
              );
              materialUpdated[idxToUpdate].quantity = formatNumberString({
                str: e.target.value,
              });
              setMaterialAssociateData([...materialUpdated]);
            }}
            onBlur={(e) =>
              handleUpdate(material.asso_id, {
                quantity: e.target.value ? stringToFloat(e.target.value) : null,
              })
            }
            disabled={
              !material.linked ||
              (from === "frameworkAgreement" && opportunityId && !faIsValidate)
            }
          />
        ),
    },
    {
      title: "Arrondi",
      dataIndex: "round_type",
      key: "round_type",
      width: "94px",
      hidden:
        !configuration.admin.product_tab.form.product_associate.input_linked,
      render: (val, material) =>
        material.associated && (
          <>
            <StyledSelect
              $faId={faId}
              allowClear
              bordered={false}
              fontSize="10px"
              placeholder="Aucun"
              value={material.linked ? val : ""}
              onChange={(e) => {
                if (!e)
                  handleUpdate(material.asso_id, {
                    round_type: null,
                    round_number: null,
                  });
                else
                  handleUpdate(material.asso_id, {
                    round_type: e,
                  });
              }}
              options={round_type_list}
              disabled={
                !material.linked ||
                (from === "frameworkAgreement" &&
                  opportunityId &&
                  !faIsValidate)
              }
            />
            {val === "decimal" && (
              <Input
                fontSize="10px"
                value={formatNumberString({ str: material.round_number })}
                size="small"
                textAlign="right"
                onChange={(e) => {
                  const materialUpdated = materialAssociateData;
                  const idxToUpdate = materialUpdated.findIndex(
                    (el) => el.id === material.id
                  );
                  materialUpdated[idxToUpdate].round_number =
                    formatNumberString({
                      str: e.target.value,
                    });
                  setMaterialAssociateData([...materialUpdated]);
                }}
                onBlur={(e) => {
                  handleUpdate(material.asso_id, {
                    round_number: e.target.value
                      ? stringToFloat(e.target.value)
                      : 0,
                  });
                }}
                disabled={
                  !material.linked ||
                  (from === "frameworkAgreement" &&
                    opportunityId &&
                    !faIsValidate)
                }
              />
            )}
          </>
        ),
    },
    {
      title: "Comp.",
      dataIndex: "associated",
      width: "60px",
      hidden: !configuration.general.compatibility,
      align: "center",
      defaultSortOrder: "descend",
      sorter: true,
      render: (associated, record) =>
        (from !== "admin" && !selectedMateriel.is_in_framework_agreement) ||
        (from === "frameworkAgreement" &&
          opportunityId &&
          !faIsValidate) ? null : (
          <Checkbox
            checked={associated}
            onChange={({ target: { checked } }) =>
              handleChecked({ id: record.id, checked })
            }
          />
        ),
    },
    {
      title: "",
      dataIndex: "show",
      key: "show",
      width: "20px",
      render: (_, material) => (
        <CenteredContainer>
          <Eye
            onClick={() => {
              showModal(material);
            }}
          />
        </CenteredContainer>
      ),
    },
  ].filter((col) => !col.hidden);

  return (
    <>
      <Modal
        width={840}
        open={isModalVisible}
        maskClosable={false}
        footer={null}
        onCancel={handleCancel}
      >
        <h3>Information du {vocabulary?.product_lowercase}</h3>
        <MaterialsForm
          initialMaterial={showMaterial}
          categories={categories}
          unities={unities}
          manufacturers={manufacturers}
          from={from}
          hasMaterialIncluded={
            showMaterial?.material_included !== null &&
            showMaterial?.material_included !== undefined
          }
          isShowing
          faIsValidate={faIsValidate}
          distributors={distributors}
          configuration={configuration}
          vocabulary={vocabulary}
          entityWorkId={entityWorkId}
          opportunityId={opportunityId}
        />
      </Modal>
      <StyledSearch
        allowClear
        placeholder={`Rechercher par ${vocabulary?.product_lowercase}, code article, etc...`}
        value={wordEntered}
        onChange={handleFilter}
      />
      <LazyMaterialArray
        key={parent === "material" ? selectedMateriel?.id : selectedFamily?.id}
        columns={matCols}
        dataSource={materialAssociateData}
        from={from}
        opportunityId={opportunityId}
        faIsValidate={faIsValidate}
        faId={faId}
        entityWorkId={entityWorkId}
        materials={materials}
        setMaterials={setMaterials}
        wordEntered={wordEntered}
        parent={parent}
        isForAssociates
        adminDetails
      />
    </>
  );
}

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSearch = styled(Search)`
  margin: 10px 0;
`;

const StyledSelect = styled(Select)`
  width: 94px;
`;

export default MaterialAssociates;
