import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Modal } from "antd";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useStore } from "../../../store";
import { getData, postData } from "../../../request/instance";
import TitleContainer from "../../../react-ui/TitleContainer";
import { fetchDocumentsUrl } from "../../../../utils/fetchDocumentsUrl";
import { fetchFoldersUrl } from "../../../../utils/fetchFoldersUrl";
import DocumentForm from "./DocumentForm";
import { createFile } from "../../../../utils/createFile";
import DocumentsArray from "./DocumentsArray";
import ImportButton from "../../../react-ui/ImportButton";

const selector = (state) => ({
  formToken: state.formToken,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function Documents({
  works,
  companyWorks,
  entities,
  settableEntities,
  settableWorks,
  siteTypologies,
  parentEntity,
  canSetGlobalEntities,
  canSetGlobalWorks,
}) {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);
  const [status, setStatus] = useState("empty");
  const [selectedDocument, setSelectedDocument] = useState();
  const [wordEntered, setWordEntered] = useState("");
  const [documentFolders, setDocumentFolders] = useState([]);
  const [mutationIsLoading, setMutationIsLoading] = useState(false);

  const {
    data: documents,
    refetch,
    isLoading: documentsAreLoading,
  } = useQuery(
    "Documents",
    () => getData(formToken, fetchDocumentsUrl({ search: wordEntered })),
    { refetchOnWindowFocus: false }
  );

  const { data: folders, isLoading: foldersAreLoading } = useQuery(
    "Folders",
    () => getData(formToken, fetchFoldersUrl({ search: undefined })),
    { refetchOnWindowFocus: false }
  );

  const { mutate: createDocument } = useMutation((todo) =>
    postData(formToken, "/document/create", todo)
  );

  const { mutate: updateDocument, isLoading: updateIsLoading } = useMutation(
    (todo) => postData(formToken, "/document/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Documents");
        closeModal();
      },
    }
  );

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  const openModal = () => {
    setStatus("create");
  };

  const initialValues = useMemo(() => {
    if (status !== "update" || !selectedDocument) return undefined;
    const docCwIds = selectedDocument.company_work_documents.map(
      (el) => el.company_work_id
    );
    const docEntIds = selectedDocument.entity_documents.map(
      (el) => el.entity_id
    );
    const docTypoIds = selectedDocument.site_typology_documents.map(
      (el) => el.site_typology_id
    );
    const docFolderIds = selectedDocument.document_folders.map(
      (el) => el.folder_id
    );
    const docCompanyWorks = companyWorks.filter((el) =>
      docCwIds.includes(el.id)
    );
    setDocumentFolders(folders.filter((el) => docFolderIds.includes(el.id)));

    return {
      ...selectedDocument,
      works:
        docCwIds.length > 0
          ? works
              .filter((w) => docCompanyWorks.some((cw) => cw.work_id === w.id))
              .map((w) => w.id)
          : ["global"],
      entities:
        docEntIds.length > 0
          ? entities
              .filter((ent) => docEntIds.includes(ent.id))
              .map((ent) => ent.id)
          : ["global"],
      folders: docFolderIds,
      typologies: docTypoIds.length > 0 ? docTypoIds : ["global"],
      file: [
        {
          uid: "1",
          name: selectedDocument.name,
          status: "done",
          url: selectedDocument.file,
        },
      ],
    };
  }, [companyWorks, entities, folders, selectedDocument, status, works]);

  const closeModal = () => {
    setStatus("empty");
    setSelectedDocument();
    setDocumentFolders([]);
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const appendAssociationForCreation = ({
    assosToCreate,
    formData,
    keyName,
    associationName,
    associationAttributeName,
    defaultId,
  }) => {
    let assoIdx = 0;
    if (!assosToCreate && status === "create") {
      formData.append(
        `${keyName}[${associationAttributeName}_attributes][][${associationName}_id]`,
        defaultId
      );
    } else if (!assosToCreate.some((el) => el === "global")) {
      assosToCreate?.forEach((assoId) => {
        formData.append(
          `${keyName}[${associationAttributeName}_attributes][${assoIdx}][${associationName}_id]`,
          assoId
        );
        assoIdx += 1;
      });
    }
    return assoIdx;
  };

  const appendAssociationForDestroy = ({
    assosToDestroy,
    formData,
    keyName,
    assoIdx,
    associationAttributeName,
  }) => {
    let newAssoIdx = assoIdx;
    assosToDestroy?.forEach((asso) => {
      formData.append(
        `${keyName}[${associationAttributeName}_attributes][${newAssoIdx}][id]`,
        asso.id
      );
      formData.append(
        `${keyName}[${associationAttributeName}_attributes][${newAssoIdx}][_destroy]`,
        true
      );
      newAssoIdx += 1;
    });
  };

  const getAssosToDestroy = ({ associationName, assosFromForm }) => {
    if (!assosFromForm) return undefined;
    if (associationName === "company_work") {
      const selectedCwIds = selectedDocument.company_work_documents.map(
        (el) => el.company_work_id
      );
      const selectedCompanyWorks = companyWorks.filter((el) =>
        selectedCwIds.includes(el.id)
      );
      const cwIdsToDestroy = selectedCompanyWorks
        .filter((cw) => !assosFromForm.includes(cw.work_id))
        .map((el) => el.id);

      return selectedDocument.company_work_documents.filter((cwd) =>
        cwIdsToDestroy.includes(cwd.company_work_id)
      );
    }

    if (associationName === "entity") {
      return selectedDocument.entity_documents.filter(
        ({ entity_id }) => !assosFromForm.includes(entity_id)
      );
    }

    if (associationName === "site_typology") {
      return selectedDocument.site_typology_documents.filter(
        ({ site_typology_id }) => !assosFromForm.includes(site_typology_id)
      );
    }

    return selectedDocument.document_folders.filter(
      ({ folder_id }) => !assosFromForm.includes(folder_id)
    );
  };

  const getAssosToCreate = ({ associationName, assosFromForm }) => {
    if (!assosFromForm) return undefined;
    if (assosFromForm.includes("global")) return assosFromForm;
    if (associationName === "company_work") {
      const cwToCreate = companyWorks
        .filter((el) => assosFromForm.includes(el.work_id))
        .map((el) => el.id);
      if (status === "create") return cwToCreate;

      const selectedCwIds = selectedDocument.company_work_documents.map(
        (el) => el.company_work_id
      );
      const selectedCompanyWorksIds = companyWorks
        .filter((el) => selectedCwIds.includes(el.id))
        .map((el) => el.id);

      return cwToCreate.filter((el) => !selectedCompanyWorksIds.includes(el));
    }

    if (associationName === "entity") {
      if (status === "create") return assosFromForm;
      const selectedEntIds = selectedDocument.entity_documents.map(
        (el) => el.entity_id
      );

      return assosFromForm.filter((el) => !selectedEntIds.includes(el));
    }

    if (associationName === "site_typology") {
      if (status === "create") return assosFromForm;

      const selectedTypoIds = selectedDocument.site_typology_documents.map(
        (el) => el.site_typology_id
      );

      return assosFromForm.filter((el) => !selectedTypoIds.includes(el));
    }

    if (status === "create") return assosFromForm;

    const selectedFolderIds = selectedDocument.document_folders.map(
      (el) => el.folder_id
    );

    return assosFromForm.filter((el) => !selectedFolderIds.includes(el));
  };

  const appendAssociationsToForm = ({
    assosFromForm,
    formData,
    keyName,
    associationName,
    defaultId,
  }) => {
    const associationAttributeName =
      associationName === "folder"
        ? "document_folders"
        : `${associationName}_documents`;
    if (status === "create") {
      const assosToCreate = getAssosToCreate({
        associationName,
        assosFromForm,
      });
      appendAssociationForCreation({
        assosToCreate,
        formData,
        keyName,
        associationName,
        defaultId,
        associationAttributeName,
      });
    }
    if (status === "update") {
      const assosToCreate = getAssosToCreate({
        associationName,
        assosFromForm,
      });
      const assosToDestroy = getAssosToDestroy({
        associationName,
        assosFromForm,
      });

      let assoIdx = 0;
      assoIdx = appendAssociationForCreation({
        assosToCreate,
        formData,
        keyName,
        associationName,
        defaultId,
        associationAttributeName,
      });
      appendAssociationForDestroy({
        assosToDestroy,
        formData,
        keyName,
        assoIdx,
        associationAttributeName,
      });
    }
  };

  const appendDocumentToForm = ({ formData, values, file }) => {
    const keyName = status === "create" ? "documents[]" : "document";
    formData.append(`${keyName}[name]`, file.name);
    if (file.originFileObj) {
      formData.append(
        `${keyName}[file]`,
        createFile([file.originFileObj], file.name, {
          type: file.type,
        })
      );
    }
    formData.append(`${keyName}[description]`, values.description || "");
    formData.append(`${keyName}[date_validity]`, values.date_validity || "");
    appendAssociationsToForm({
      assosFromForm: values.works,
      formData,
      keyName,
      associationName: "company_work",
      defaultId: companyWorks.find((el) => el.work_id === settableWorks[0].id)
        .id,
    });
    appendAssociationsToForm({
      assosFromForm: values.entities,
      formData,
      keyName,
      associationName: "entity",
      defaultId: settableEntities[0].id,
    });
    appendAssociationsToForm({
      assosFromForm: values.typologies,
      formData,
      keyName,
      associationName: "site_typology",
    });
    appendAssociationsToForm({
      assosFromForm: documentFolders.map((el) => el.id),
      formData,
      keyName,
      associationName: "folder",
    });
  };

  const handleSubmit = (values) => {
    let formData = new FormData();

    if (status === "create") {
      setMutationIsLoading(true);
      let nbMB = 0;
      values.files.forEach((file) => {
        const fileSize = Math.ceil(file.size / 1024 / 1024);
        if (fileSize >= 75) return;
        if (nbMB + fileSize >= 100) {
          nbMB = 0;
          createDocument(formData);
          formData = new FormData();
        }
        nbMB += fileSize;
        appendDocumentToForm({ formData, values, file });
      });
      createDocument(formData, {
        onSettled: () => {
          setMutationIsLoading(false);
          queryClient.invalidateQueries("Documents");
          closeModal();
        },
      });
    }
    if (status === "update") {
      const fileSize = Math.ceil(values.file[0].size / 1024 / 1024);
      if (fileSize >= 100) return;
      appendDocumentToForm({ formData, values, file: values.file[0] });
      formData.append("id", selectedDocument.id);
      updateDocument(formData);
    }
  };

  return (
    <>
      <StyledModal
        open={status !== "empty"}
        maskClosable={false}
        footer={null}
        width={1000}
        destroyOnClose
        onCancel={closeModal}
        closable={false}
      >
        <DocumentForm
          works={works}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          status={status}
          entities={entities}
          settableWorks={settableWorks}
          settableEntities={settableEntities}
          closeModal={closeModal}
          siteTypologies={siteTypologies}
          parentEntity={parentEntity}
          canSetGlobalEntities={canSetGlobalEntities}
          canSetGlobalWorks={canSetGlobalWorks}
          formIsLoading={mutationIsLoading || updateIsLoading}
          folders={folders}
          foldersAreLoading={foldersAreLoading}
          documents={documents}
          documentsAreLoading={documentsAreLoading}
          setDocumentFolders={setDocumentFolders}
          documentFolders={documentFolders}
          onCancel={closeModal}
        />
      </StyledModal>
      <TitleContainer label="Documents disponibles">
        <ImportButton label="Importer des documents" onClick={openModal} />
      </TitleContainer>
      <StyledSearch
        allowClear
        placeholder="Rechercher par nom de document"
        value={wordEntered}
        onChange={handleFilter}
      />
      <DocumentsArray
        entities={entities}
        works={works}
        companyWorks={companyWorks}
        siteTypologies={siteTypologies}
        documents={documents}
        canSetGlobalEntities={canSetGlobalEntities}
        settableEntities={settableEntities}
        setSelectedDocument={setSelectedDocument}
        setStatus={setStatus}
        documentsAreLoading={documentsAreLoading}
        folders={folders}
        foldersAreLoading={foldersAreLoading}
      />
    </>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 300px;
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

const StyledSearch = styled(Search)`
  margin-bottom: 12px;
`;

export default Documents;
