import React, { useCallback, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { Modal } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TitleContainer from "../../../react-ui/TitleContainer";
import AddButton from "../../../react-ui/AddButton";
import BasketModalForm from "./BasketModalForm";
import { useStore } from "../../../store";
import { getData } from "../../../request/instance";
import BasketCard from "./BasketCard";

const selector = (state) => ({
  formToken: state.formToken,
});

function Baskets({ opportunityId, vocabulary, configuration }) {
  const { formToken } = useStore(selector);
  const [basketModalOpen, setBasketModalOpen] = useState();
  const [selectedBasket, setSelectedBasket] = useState();
  const { data: baskets } = useQuery(
    "Baskets",
    () =>
      getData(
        formToken,
        `/referencing_baskets?id_opportunity=${opportunityId}`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: basketItems } = useQuery(
    "BasketItems",
    () =>
      getData(
        formToken,
        `/referencing_basket_items?id_opportunity=${opportunityId}`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const openCreateBasketModal = () => {
    setBasketModalOpen("create");
  };

  const findAndSelectBasket = useCallback(
    (id) => {
      setSelectedBasket(baskets.find((basket) => basket.id === id));
    },
    [baskets]
  );

  const closeModal = useCallback(() => {
    setBasketModalOpen();
    setSelectedBasket();
  }, []);

  return (
    <>
      <TitleContainer label="Simulateur">
        <AddButton label="Créer un panier" onClick={openCreateBasketModal} />
      </TitleContainer>
      <StyledModal
        open={
          basketModalOpen === "create" ||
          (basketModalOpen === "update" && !!selectedBasket)
        }
        maskClosable={false}
        footer={null}
        width={1100}
        destroyOnClose
        onCancel={closeModal}
      >
        <DndProvider backend={HTML5Backend}>
          <BasketModalForm
            isOpen={basketModalOpen}
            setSelectedBasket={setSelectedBasket}
            opportunityId={opportunityId}
            selectedBasket={selectedBasket}
            vocabulary={vocabulary}
            configuration={configuration}
            basketItems={basketItems}
            setBasketModalOpen={setBasketModalOpen}
          />
        </DndProvider>
      </StyledModal>
      <CardContainer>
        {baskets?.map((basket, idx) => (
          <BasketCard
            id={basket.id}
            name={basket.name}
            odd={idx % 2}
            setBasketModalOpen={setBasketModalOpen}
            setSelectedBasket={findAndSelectBasket}
            configuration={configuration}
            basketItems={basketItems}
          />
        ))}
      </CardContainer>
    </>
  );
}

const CardContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow: scroll;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 500px;
    padding: 0 !important;
    padding-top: 50px !important;
    border-radius: 2.5px !important;
  }
`;

export default Baskets;
