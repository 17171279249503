import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { Checkbox, notification } from "antd";
import { ShowDeleteConfirm } from "../Admin-cf/confirmModal";
import ActionsContainer from "../react-ui/ActionsContainer";
import TableContainer from "../Chiffrage/CostingMovementsCarrycots/TableContainer";
import Delete from "../react-ui/Icons/Delete";
import { getData, postData } from "../request/instance";
import { useStore } from "../store";
import Label from "../Form/Label";
import AddLineButton from "../react-ui/AddLineButton";
import AddButton from "../react-ui/AddButton";
import Input from "../react-ui/Input";
import Select from "../react-ui/Select";

const columnsPartner = (
  subcontracting,
  handleDelete,
  handleUpdate,
  contractors
) => {
  const cols = [
    {
      title: "N° de lot",
      dataIndex: "batch_number",
      width: "10%",
      render: (batch_number, record) => (
        <Input
          defaultValue={batch_number}
          onBlur={(e) =>
            handleUpdate({ id: record.id, batch_number: e.currentTarget.value })
          }
        />
      ),
    },
    {
      title: subcontracting ? "Sous-traitant" : "Cotraitant",
      dataIndex: "contractor_id",
      width: "33%",
      render: (contractor_id, record) => (
        <StyledSelect
          defaultValue={contractor_id}
          options={contractors}
          keyName="trade_name"
          onChange={(e) => handleUpdate({ id: record.id, contractor_id: e })}
        />
      ),
    },
    {
      title: "Prestations éxécutées",
      dataIndex: "prestations",
      width: subcontracting ? "57%" : "50%",
      render: (prestations, record) => (
        <StyledTextArea
          defaultValue={prestations}
          textArea
          onBlur={(e) =>
            handleUpdate({ id: record.id, prestations: e.currentTarget.value })
          }
        />
      ),
    },
  ];

  if (!subcontracting)
    cols.push({
      title: "Mandataire",
      dataIndex: "mandatary",
      width: "7%",
      render: (mandatary, record) => (
        <Center>
          <Checkbox
            defaultChecked={mandatary}
            textArea
            onChange={(e) =>
              handleUpdate({ id: record.id, mandatary: e.target.checked })
            }
          />
        </Center>
      ),
    });

  cols.push({
    dataIndex: "delete",
    align: "center",
    width: "5%",
    render: (_, record) => (
      <ActionsContainer>
        <Delete
          onClick={(e) => {
            ShowDeleteConfirm(record.name, record.id, handleDelete);
            e.stopPropagation();
          }}
        />
      </ActionsContainer>
    ),
  });
  return cols;
};

const selector = (state) => ({
  formToken: state.formToken,
});

function PartnerArray({ subcontracting, offerReviewId, setIsOpenFrom }) {
  const selectedContractorsUrl = subcontracting
    ? "offer_review_subcontractor"
    : "offer_review_group_member";
  const selectedContractorsKey = subcontracting
    ? "OfferReviewSubcontractors"
    : "OfferReviewGroupMembers";
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const { data: contractors } = useQuery(
    "Contractors",
    () => getData(formToken, "/contractors"),
    {
      placeholderData: [],
    }
  );

  const { data: selectedContractors, isLoading } = useQuery(
    selectedContractorsKey,
    () =>
      getData(
        formToken,
        `/${selectedContractorsUrl}s?offer_review_id=${offerReviewId}`
      ),
    {
      placeholderData: [],
    }
  );

  const { mutate: createSelectedContractor } = useMutation(
    (todo) => postData(formToken, `/${selectedContractorsUrl}/create`, todo),
    {
      onSuccess: () => queryClient.invalidateQueries(selectedContractorsKey),
    }
  );

  const { mutate: updateSelectedContractor } = useMutation(
    (todo) => postData(formToken, `/${selectedContractorsUrl}/update`, todo),
    {
      onError: () => queryClient.resetQueries(selectedContractorsKey),
    }
  );

  const { mutate: deleteSelectedContractor } = useMutation(
    (todo) => postData(formToken, `/${selectedContractorsUrl}/delete`, todo),
    {
      onSuccess: () => queryClient.invalidateQueries(selectedContractorsKey),
    }
  );

  const handleUpdate = (values) => updateSelectedContractor(values);

  const handleDelete = (id) => deleteSelectedContractor({ id });

  const handleOnAdd = () => {
    if (!contractors.length)
      return notification.error({
        description: "Vous n'avez aucun partenaire",
        placement: "bottom",
        duration: 5,
      });
    return createSelectedContractor({
      offer_review_id: offerReviewId,
      contractor_id: contractors[0].id,
    });
  };

  const handleOnCreate = () => {
    setIsOpenFrom(subcontracting ? "subcontracting" : "cotraiting");
  };

  return (
    <>
      <Header>
        <StyledLabel
          label={
            subcontracting
              ? "Gestion des sous-traitants"
              : "Gestion des cotraitants"
          }
        />
        <AddLineButton label="Ajouter" onClick={handleOnAdd} type="button" />
        <AddButton
          label="Créer un partenaire"
          onClick={handleOnCreate}
          type="button"
        />
      </Header>
      <TableContainer
        columns={columnsPartner(
          subcontracting,
          handleDelete,
          handleUpdate,
          contractors
        )}
        dataSource={selectedContractors.map((el) => ({ ...el, key: el.id }))}
        pagination={false}
        loading={isLoading}
      />
    </>
  );
}

const StyledLabel = styled(Label)``;

const Header = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding-top: 16px;
  padding-bottom: 8px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledTextArea = styled(Input)`
  resize: none;
  height: 80px !important;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export default PartnerArray;
