import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import Separator from "../../../react-ui/Separator";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import TemplateDetails from "./TemplateDetails";
import TemplateArray from "./TemplateArray";

const selector = (state) => ({
  formToken: state.formToken,
});

function Templates({ works, companyWorks, settableWorks, canSetGlobalWorks }) {
  const { formToken } = useStore(selector);
  const [status, setStatus] = useState("empty");
  const [selectedTemplate, setSelectedTemplate] = useState();

  const { data: templates, refetch } = useQuery(
    "Templates",
    () => getData(formToken, "/sa_templates"),
    {
      onSuccess: (payload) => {
        if (status === "reload") {
          setSelectedTemplate(
            payload.find((el) => el.id === selectedTemplate.id)
          );
          setStatus("update");
        }
      },
      onError: () => {
        if (status === "reload") setStatus("empty");
      },
    }
  );

  useEffect(() => {
    if (status === "reload") refetch();
  }, [status, refetch]);

  const closeDetails = useCallback(() => {
    setStatus("empty");
    setSelectedTemplate();
  }, [setSelectedTemplate, setStatus]);

  return (
    <Container>
      <TemplateArray
        status={status}
        setStatus={setStatus}
        templates={templates}
        works={works}
        companyWorks={companyWorks}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        closeDetails={closeDetails}
      />
      <Separator />
      <TemplateDetails
        status={status}
        works={works}
        companyWorks={companyWorks}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        templates={templates}
        closeDetails={closeDetails}
        settableWorks={settableWorks}
        canSetGlobalWorks={canSetGlobalWorks}
        setStatus={setStatus}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 10px;
  height: 100%;
`;

export default Templates;
