import React, { useState } from "react";
import styled from "styled-components";
import { opportunityField } from "../../constant";
import FormCol from "../../Form/FormCol";
import CreateLink from "../../react-ui/CreateLink";
import Select from "../../react-ui/Select";
import TitleContainer from "../../react-ui/TitleContainer";
import FormRow from "../../Form/FormRow";
import CompanyProvided from "./ComplementForm/CompanyProvided";
import CompanyDecisionMaking from "./ComplementForm/CompanyDecisionMaking";
import EstimatePrice from "./ComplementForm/EstimatePrice";
import DecisionDate from "./ComplementForm/DecisionDate";
import TypeOfSite from "./ComplementForm/TypeOfSite";
import SuccessProbability from "./ComplementForm/SuccessProbability";
import ProbabilityCancelation from "./ComplementForm/ProbabilityCancelation";
import OpportunityDescription from "./ComplementForm/OpportunityDescription";
import ContactsSelect from "./ContactsSelect";
import ContactsSelectWrapper from "./ContactsSelectWrapper";

function ComplementPart({
  opportunity,
  successProbability,
  cancellationProbability,
  typologies,
  providers,
  contactsInMemory,
  setContactsInMemory,
  setClientModalOpenFrom,
  setProviderModalType,
  setTypologyModalType,
  form,
  setContactModalOpenFrom,
  isSimple,
  loadConfiguration,
  configApplication,
  isLockedByUser,
  clientsInMemory,
  setClientsInMemory,
  formatClients,
  clientModalOpenFrom,
  companyId,
  clientActivities,
  works,
  vocabulary,
  configuration,
}) {
  const [contacts, setContacts] = useState([]);
  const component = {
    company_provided: (
      <CompanyProvided
        setClientModalOpenFrom={setClientModalOpenFrom}
        disabled={
          configApplication.common.opportunity.disable_provided_customer ||
          !isLockedByUser
        }
        show_link={
          configApplication.common.opportunity.show_create_client &&
          isLockedByUser
        }
        formatClients={formatClients}
        clientsInMemory={clientsInMemory}
        setClientsInMemory={setClientsInMemory}
        companyId={companyId}
        clientActivities={clientActivities}
        form={form}
        configApplication={configApplication}
        works={works}
        clientModalOpenFrom={clientModalOpenFrom}
        vocabulary={vocabulary}
        configuration={configuration}
      />
    ),
    provided_customer_contacts: (
      <ContactsSelectWrapper
        companyId={companyId}
        setContactModalOpenFrom={setContactModalOpenFrom}
        contactsInMemory={contactsInMemory}
        setContactsInMemory={setContactsInMemory}
        disabled={
          configApplication.common.opportunity.disable_contacts ||
          !isLockedByUser
        }
        show_link={
          configApplication.common.opportunity.show_create_contact &&
          isLockedByUser
        }
        name={opportunityField.providedCustomerContacts}
        form={form}
        required={false}
      />
    ),
    decision_maker_customer_contacts: (
      <ContactsSelectWrapper
        companyId={companyId}
        setContactModalOpenFrom={setContactModalOpenFrom}
        contactsInMemory={contactsInMemory}
        setContactsInMemory={setContactsInMemory}
        disabled={
          configApplication.common.opportunity.disable_contacts ||
          !isLockedByUser
        }
        show_link={
          configApplication.common.opportunity.show_create_contact &&
          isLockedByUser
        }
        name={opportunityField.decisionMakerCustomerContacts}
        form={form}
        required={false}
      />
    ),
    company_decision_making: (
      <CompanyDecisionMaking
        setClientModalOpenFrom={setClientModalOpenFrom}
        disabled={
          configApplication.common.opportunity
            .disable_decision_maker_customer || !isLockedByUser
        }
        show_link={
          configApplication.common.opportunity.show_create_client &&
          isLockedByUser
        }
        formatClients={formatClients}
        clientsInMemory={clientsInMemory}
        setClientsInMemory={setClientsInMemory}
        companyId={companyId}
        clientActivities={clientActivities}
        form={form}
        configApplication={configApplication}
        works={works}
        clientModalOpenFrom={clientModalOpenFrom}
        vocabulary={vocabulary}
        configuration={configuration}
      />
    ),
    provider_in_place: configApplication.common.opportunity.show_prestataire ? (
      <FormCol
        labelOnTop
        width="100%"
        label={isSimple() ? "Concurent(s)" : "Prestataire(s) en place"}
        name={opportunityField.providedOpportunityCustomer}
        customContent={
          <ProviderCustomContent>
            {configApplication.common.opportunity.show_create_prestataire &&
              isLockedByUser && (
                <StyledCreateLink
                  label={
                    isSimple() ? "Créer un concurent" : "Créer un prestataire"
                  }
                  onClick={() => setProviderModalType("provider")}
                />
              )}
          </ProviderCustomContent>
        }
      >
        <StyledSelect
          disabled={
            configApplication.common.opportunity.disable_prestataire ||
            !isLockedByUser
          }
          options={providers}
          placeholder={`Sélectionnez un ou plusieurs ${
            isSimple() ? "concurents" : "prestataires"
          }`}
          mode="multiple"
        />
      </FormCol>
    ) : (
      <FormCol width="100%">
        <div className="test" style={{ height: 0, width: "100%" }} />
      </FormCol>
    ),
    company_contact: (
      <ContactsSelect
        companyId={companyId}
        setContactModalOpenFrom={setContactModalOpenFrom}
        contacts={contacts}
        setContacts={setContacts}
        setContactsInMemory={setContactsInMemory}
        disabled={
          configApplication.common.opportunity.disable_contacts ||
          !isLockedByUser
        }
        show_link={
          configApplication.common.opportunity.show_create_contact &&
          isLockedByUser
        }
        name={opportunityField.contacts}
        label={`Contact(s) ${vocabulary.opportunity.inside_client}`}
        vocabulary={vocabulary}
      />
    ),
    estimate_price: (
      <EstimatePrice
        form={form}
        disabled={
          configApplication.common.opportunity.disable_estimated_amount ||
          !isLockedByUser
        }
      />
    ),
    decision_date: (
      <DecisionDate
        disabled={
          configApplication.common.opportunity.disable_response_date ||
          !isLockedByUser
        }
        vocabulary={vocabulary}
      />
    ),
    type_of_site: (
      <TypeOfSite
        typologies={typologies}
        setTypologyModalType={setTypologyModalType}
        disabled={
          configApplication.common.opportunity.disable_site_typology ||
          !isLockedByUser
        }
        show_link={
          configApplication.common.opportunity.show_create_typology &&
          isLockedByUser
        }
      />
    ),
    probability_success: configApplication.common.opportunity
      .show_success_probability ? (
      <SuccessProbability
        successProbability={successProbability}
        disabled={!isLockedByUser}
      />
    ) : (
      <FormCol width="100%">
        <div className="test" style={{ height: 0, width: "100%" }} />
      </FormCol>
    ),
    probability_cancelation: configApplication.common.opportunity
      .show_cancellation_probability ? (
      <ProbabilityCancelation
        cancellationProbability={cancellationProbability}
        disabled={!isLockedByUser}
      />
    ) : (
      <FormCol width="100%">
        <div className="test" style={{ height: 0, width: "100%" }} />
      </FormCol>
    ),
    opportunity_description: (
      <OpportunityDescription disabled={!isLockedByUser} />
    ),
    blank: (
      <FormCol width="100%">
        <div className="test" style={{ height: 93, width: "100%" }} />
      </FormCol>
    ),
  };

  const filterLine = (col, line) => {
    const configuration_line = opportunity
      ? loadConfiguration()?.configuration.opportunity.complement[col][line]
          .name
      : loadConfiguration()?.selectedConfiguration.configuration.opportunity
          .complement[col][line].name;
    // const configuration_line =
    //   loadConfiguration()?.configuration.opportunity.complement[col][line].name;

    if (configuration_line?.every((c) => c === "blank")) {
      return null;
    }
    return (
      <FormRow minHeight="109px">
        {configuration_line?.map((c) => component[c])}
      </FormRow>
    );
  };

  return (
    <>
      <StyledTitleContainer label="Informations complémentaires" />

      <Container>
        <FormCol width="50%">
          {filterLine("col_1", "line_1")}
          {filterLine("col_1", "line_2")}
          {filterLine("col_1", "line_3")}
          {filterLine("col_1", "line_4")}
        </FormCol>
        <FormCol width="50%">
          {filterLine("col_2", "line_1")}
          {filterLine("col_2", "line_2")}
          {filterLine("col_2", "line_3")}
          {filterLine("col_2", "line_4")}
        </FormCol>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 16px 0;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
const StyledTitleContainer = styled(TitleContainer)`
  margin-top: 20px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
`;

const StyledCreateLink = styled(CreateLink)`
  margin-left: auto;
  margin-top: 10px;
`;

const ProviderCustomContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default ComplementPart;
