import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useTodo from "../../../../../hookQuery/useTodo";
import { useStore } from "../../../../../store";
import TableContainer from "../../../../CostingMovementsCarrycots/TableContainer";
import TableCarrycotChild from "./TableCarrycotChild";
import useUpdateTodo from "../../../../../hookQuery/useUpdateTodo";
import { currency_cents_to_euros } from "../../../../../utils";
import Input from "../../../../../react-ui/Input";
import { formatNumberString } from "../../../../../../utils/formatNumberString";
import { stringToFloat } from "../../../../../../utils/stringToFloat";
import { getMarginCol } from "../../../../../../constants/marginCol";
import { coeffToMargin } from "../../../../../../utils/marginConverter";

const selector = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
const TableCarrycot = React.memo(
  ({ zone_id, subcontracting, carrycotsOpp, configuration }) => {
    const { id_opportunity } = useParams();
    const [margin, setMargin] = useState(null);

    const { formToken, calculMethod, isUsingMargin } = useStore(selector);

    const { mutate: carrycot_update, isLoading: carrycotLoading } =
      useUpdateTodo(
        formToken,
        `/carrycot_opportunity/update`,
        "CarrycotOpportunities",
        [
          "OpportunityCotationLc",
          "Zones",
          "ProfilsCarrycotOpportunity",
          "CarrycotCosting",
        ],
        true
      );

    const { data: profils_opportunity } = useTodo(
      formToken,
      `/profil_opportunity/${id_opportunity}`,
      "ProfilsOpportunity"
    );

    const pushDataInTable = useMemo(() => {
      const carrycotDataSource = [];
      carrycotsOpp
        ?.filter((el) => currency_cents_to_euros(el.selling_price_cents) > 0)
        ?.forEach((carrycot) => {
          if (
            carrycot.zone_id === zone_id &&
            carrycot.subcontracting === subcontracting
          ) {
            carrycotDataSource.push({
              flag: "carrycot",
              key: carrycot.id,
              name: carrycot.name,
              id: carrycot.id,
              ground_staff: profils_opportunity?.find(
                (profil) => profil.id === carrycot.profil_opportunity_id
              ).name,
              total_intervention_duration: formatNumberString({
                str: carrycot.duration,
                nbDecimal: 2,
                space: true,
              }),
              total_intervention_day: formatNumberString({
                str: carrycot.day_quantity,
                nbDecimal: 2,
                space: true,
              }),
              total_intervention_round_trip: formatNumberString({
                str: carrycot.roundtrip_quantity,
                nbDecimal: 2,
                space: true,
              }),
              cost_price: formatNumberString({
                str: currency_cents_to_euros(carrycot.cost_price_cents),
                nbDecimal: 2,
                space: true,
              }),
              categories: carrycot.category_profession_id,
              margin: carrycot.margin_rate,
              selling_price: formatNumberString({
                str: currency_cents_to_euros(carrycot.selling_price_cents),
                nbDecimal: 2,
                space: true,
              }),
            });
          }
        });
      if (!carrycotLoading) setMargin(null);
      return carrycotDataSource;
    }, [carrycotsOpp, profils_opportunity]);

    const CarrycotColummns = [
      {
        title: "Intitulé",
        dataIndex: "name",
      },
      {
        title: "Personnel au sol",
        dataIndex: "ground_staff",
        width: "19%",
      },
      {
        title: "Total intervention",
        children: [
          {
            title: "Durée",
            dataIndex: "total_intervention_duration",
            width: "10%",
            render: (value) => <div>{value} h</div>,
          },
          {
            title: "Jours",
            dataIndex: "total_intervention_day",
            width: "10%",
          },
          {
            title: "Aller/retour",
            dataIndex: "total_intervention_round_trip",
            width: "10%",
          },
        ],
      },
      {
        title: "Coût de revient",
        dataIndex: "cost_price",
        width: "10%",
        align: "right",
        render: (value) => <div>{value} €</div>,
      },
      getMarginCol({
        configuration,
        isUsingMargin,
        calculMethod,
        hasSorter: false,
        render: (value, globalValue) => (
          <Input
            fontSize="10px"
            textAlign="right"
            size="small"
            id={globalValue.id}
            isMarginRate
            value={formatNumberString({
              str:
                margin?.[globalValue.id] != null
                  ? margin?.[globalValue.id]
                  : value,
              isCoeff: !isUsingMargin,
            })}
            onBlur={(e) => {
              if (margin?.[globalValue.id]) {
                carrycot_update({
                  opportunity_id: id_opportunity,
                  id: globalValue.id,
                  margin_rate: coeffToMargin({
                    calculMethod,
                    isUsingMargin,
                    coeff: stringToFloat(e.target.value),
                  }),
                });
              }
            }}
            onChange={(e) =>
              setMargin((v) => ({
                ...v,
                [e.target.id]: e.target.value,
              }))
            }
          />
        ),
      }),
      {
        title: "Prix de vente HT",
        dataIndex: "selling_price",
        width: "10%",
        align: "right",
        render: (value) => <div>{value} €</div>,
      },
    ];

    const renderChildCarrycot = (record) => {
      return (
        <TableCarrycotChild
          parentId={record.id}
          carrycotsOpp={carrycotsOpp}
          configuration={configuration}
        />
      );
    };
    return pushDataInTable.length ? (
      <Container>
        <div>
          {subcontracting ? (
            <h4 id="carrycot-subcontracting" className="pl-2">
              Coûts sous-traitance - Nacelles
            </h4>
          ) : (
            <h4 id="carrycot" className="pl-2">
              Coûts internes - Nacelles
            </h4>
          )}
        </div>

        <TableContainer
          columns={CarrycotColummns}
          data={pushDataInTable}
          expandedRowRender={renderChildCarrycot}
          tr={2}
        />
      </Container>
    ) : null;
  }
);

const Container = styled.div`
  margin-top: 20px;
`;

export default TableCarrycot;
