import React from "react";
import styled from "styled-components";
import FormCol from "../../../Form/FormCol";
import { opportunityField } from "../../../constant";
import CreateLink from "../../../react-ui/CreateLink";
import ClientsSelectWrapper from "../ClientsSelectWrapper";

function OpportunityClient({
  clientHasFA,
  opportunity,
  setClientModalOpenFrom,
  formatClients,
  clientsInMemory,
  setClientsInMemory,
  required,
  disabled,
  show_link,
  clientModalOpenFrom,
  companyId,
  clientActivities,
  form,
  configApplication,
  works,
  vocabulary,
  configuration,
}) {
  return (
    <FormCol
      labelOnTop
      label={vocabulary.opportunity.billed_customer}
      rules={[{ required }]}
      customContent={
        <ClientCustomContent>
          {clientHasFA() && !opportunity && (
            <StyledText>Possède un ou plusieurs accords cadres</StyledText>
          )}
          {show_link && (
            <StyledCreateLink
              label={`Créer ${vocabulary.clients.a_client}`}
              onClick={() => setClientModalOpenFrom(opportunityField.client)}
            />
          )}
        </ClientCustomContent>
      }
      name={opportunityField.client}
    >
      <ClientsSelectWrapper
        setClientsInMemory={setClientsInMemory}
        clientsInMemory={clientsInMemory}
        clientModalOpenFrom={clientModalOpenFrom}
        setClientModalOpenFrom={setClientModalOpenFrom}
        companyId={companyId}
        clientActivities={clientActivities}
        form={form}
        configApplication={configApplication}
        works={works}
        formatClients={formatClients}
        isShowing={disabled}
        selectorId={opportunityField.client}
        vocabulary={vocabulary}
        configuration={configuration}
      />
    </FormCol>
  );
}
const StyledCreateLink = styled(CreateLink)`
  margin-left: auto;
  margin-top: 10px;
`;

const ClientCustomContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledText = styled.p`
  margin: 0;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #5499de;
`;

export default OpportunityClient;
