import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AddButton from "../../../react-ui/AddButton";
import TitleContainer from "../../../react-ui/TitleContainer";
import FoldersArray from "./FoldersArray";
import { getData, postData } from "../../../request/instance";
import { useStore } from "../../../store";
import { fetchFoldersUrl } from "../../../../utils/fetchFoldersUrl";
import FolderForm from "./FolderForm";
import { fetchDocumentsUrl } from "../../../../utils/fetchDocumentsUrl";

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

const selector = (state) => ({
  formToken: state.formToken,
});

function Folders({ works, companyWorks, entities, siteTypologies }) {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);
  const [status, setStatus] = useState("empty");
  const [selectedFolder, setSelectedFolder] = useState();
  const [wordEntered, setWordEntered] = useState("");
  const [folderDocuments, setFolderDocuments] = useState([]);

  const { data: documents, isLoading: documentsAreLoading } = useQuery(
    "Documents",
    () => getData(formToken, fetchDocumentsUrl({ search: undefined })),
    { refetchOnWindowFocus: false }
  );

  const {
    data: folders,
    refetch,
    isLoading: foldersAreLoading,
  } = useQuery(
    "Folders",
    () => getData(formToken, fetchFoldersUrl({ search: wordEntered })),
    { refetchOnWindowFocus: false }
  );

  const { mutate: createFolder, isLoading: createIsLoading } = useMutation(
    (todo) => postData(formToken, "/folder/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Folders");
        closeModal();
      },
    }
  );

  const { mutate: updateFolder, isLoading: updateIsLoading } = useMutation(
    (todo) => postData(formToken, "/folder/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Folders");
        closeModal();
      },
    }
  );

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  const openModal = () => {
    setStatus("create");
  };

  const initialValues = useMemo(() => {
    if (status !== "update" || !selectedFolder)
      return { color: "#82424200", security: "entity" };

    const docIds = selectedFolder.document_folders.map((el) => el.document_id);
    const color = selectedFolder.color || "#82424200";
    setFolderDocuments(documents.filter((el) => docIds.includes(el.id)));
    return {
      ...selectedFolder,
      documents: docIds,
      color,
    };
  }, [documents, selectedFolder, status]);

  const closeModal = () => {
    setStatus("empty");
    setSelectedFolder();
    setFolderDocuments([]);
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const appendDocumentForCreation = ({ docsToCreate, formData }) => {
    let docsIdx = 0;
    docsToCreate?.forEach((docId) => {
      formData.append(
        `folder[document_folders_attributes][${docsIdx}][document_id]`,
        docId
      );
      docsIdx += 1;
    });
    return docsIdx;
  };

  const appendDocumentForDestroy = ({ docsToDestroy, formData, docIdx }) => {
    let newDocIdx = docIdx;
    docsToDestroy?.forEach((document) => {
      formData.append(
        `folder[document_folders_attributes][${newDocIdx}][id]`,
        document.id
      );
      formData.append(
        `folder[document_folders_attributes][${newDocIdx}][_destroy]`,
        true
      );
      newDocIdx += 1;
    });
  };

  const getDocsToDestroy = ({ docsFromForm }) => {
    return selectedFolder.document_folders.filter(
      ({ document_id }) => !docsFromForm.includes(document_id)
    );
  };

  const getDocsToCreate = ({ docsFromForm }) => {
    if (status === "create") return docsFromForm;

    const selectedDocsIds = selectedFolder.document_folders.map(
      (el) => el.document_id
    );

    return docsFromForm.filter((el) => !selectedDocsIds.includes(el));
  };

  const appendDocumentsToForm = ({ docsFromForm, formData }) => {
    if (status === "create") {
      const docsToCreate = getDocsToCreate({
        docsFromForm,
      });
      appendDocumentForCreation({
        docsToCreate,
        formData,
      });
    }
    if (status === "update") {
      const docsToCreate = getDocsToCreate({
        docsFromForm,
      });
      const docsToDestroy = getDocsToDestroy({
        docsFromForm,
      });

      let docIdx = 0;
      docIdx = appendDocumentForCreation({
        docsToCreate,
        formData,
      });
      appendDocumentForDestroy({
        docsToDestroy,
        formData,
        docIdx,
      });
    }
  };

  const getColor = (color) => {
    if (color?.length > 7) return undefined;
    return color;
  };

  const appendFolderToForm = ({ formData, values }) => {
    formData.append("folder[name]", values.name);
    formData.append("folder[security]", values.security);
    formData.append("folder[color]", getColor(values.color) || "");

    appendDocumentsToForm({
      docsFromForm: folderDocuments.map((el) => el.id),
      formData,
    });
  };

  const handleSubmit = (values) => {
    const formData = new FormData();

    if (status === "create") {
      appendFolderToForm({ formData, values });
      createFolder(formData);
    }
    if (status === "update") {
      appendFolderToForm({ formData, values });
      formData.append("id", selectedFolder.id);
      updateFolder(formData);
    }
  };

  return (
    <>
      <StyledModal
        open={status !== "empty"}
        footer={null}
        width={1000}
        maskClosable={false}
        destroyOnClose
        onCancel={closeModal}
        closable={false}
      >
        <FolderForm
          documents={documents}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          status={status}
          closeModal={closeModal}
          formIsLoading={createIsLoading || updateIsLoading}
          folderAreLoading={foldersAreLoading}
          documentsAreLoading={documentsAreLoading}
          works={works}
          companyWorks={companyWorks}
          entities={entities}
          siteTypologies={siteTypologies}
          folders={folders}
          folderDocuments={folderDocuments}
          setFolderDocuments={setFolderDocuments}
        />
      </StyledModal>
      <TitleContainer label="Dossiers disponibles">
        <AddButton label="Créer un dossier" onClick={openModal} />
      </TitleContainer>
      <StyledSearch
        allowClear
        placeholder="Rechercher par nom de dossier"
        value={wordEntered}
        onChange={handleFilter}
      />
      <FoldersArray
        folders={folders}
        setStatus={setStatus}
        setSelectedFolder={setSelectedFolder}
        folderAreLoading={foldersAreLoading}
        documents={documents}
        documentsAreLoading={documentsAreLoading}
      />
    </>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 300px;
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

const StyledSearch = styled(Search)`
  margin-bottom: 12px;
`;

export default Folders;
