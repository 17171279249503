import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["status", "results_icon", "pipe"]
  connect() {
    this.add_edit_link()
  }
  edit_redirection() {
    window.location.href = `/opportunities/${event.currentTarget.dataset.id}/edit`;
  }
  add_edit_link() {
    const editRowBtn = document.querySelectorAll(".edit")
    if (editRowBtn) {
    editRowBtn.forEach(row => row.addEventListener('click', this.edit_redirection))
    }
  }

  update_status() {
    const opportunityId = this.statusTarget.dataset.opportunityId;
      fetch(`/opportunities/${opportunityId}/status_change`, {
        method: "PUT",
        headers: { "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
        },
        body: JSON.stringify({
          status: this.statusTarget.value
        })
      }).then(function(response) { return response.text() }).then(function(body) {
        eval(body)
        let result_status_change = this.results_iconTarget
        if(body === 'true') {
          result_status_change.innerHTML = "<i class='fas fa-check'></i>"
          setTimeout( () => { result_status_change.innerHTML = "" }, 2000)
          fetch('/opportunities', {
            method: "GET",
            headers: { "Content-Type": "application/javascript",
                       "Accept": "application/javascript",
                       "X-Requested-With": "XMLHttpRequest",
                     }
          }).then(function(response) { return response.text() });
        } else {
          result_status_change.innerHTML = "<i class='fas fa-times'></i>"
        }
    }.bind(this));

  }

  update_pipe(){
    const opportunityId = this.pipeTarget.dataset.opportunityId;
    let pipeValue = true
    if(this.pipeTarget.checked){
      pipeValue = true
    }
    else{
      pipeValue = false
    }
    fetch(`/opportunities/${opportunityId}/pipe_change`, {
      method: "PUT",
      headers: { "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({
        pipe: pipeValue
      })
    }).then(function(response) { return response.text() }).then(function(body) {
      eval(body)
      if(body === 'true') {
        fetch('/opportunities', {
          method: "GET",
          headers: { "Content-Type": "application/javascript",
                     "Accept": "application/javascript",
                     "X-Requested-With": "XMLHttpRequest",
                   }
        }).then(function(response) { return response.text() }).then(function(body) {
          eval(body)
        });
        window.location.reload()
      } else {
      }
    }.bind(this));
  }
}