import React, { useCallback, useEffect, useState } from "react";
import { Tree } from "antd";
import { DownOutlined } from "@ant-design/icons";

import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useStore } from "../../store";

import { translate_data } from "./translate_data";
import CategoryDetails from "./CategoryDetails";
import { getUrlParams } from "../../utils";
import TitleContainer from "../../react-ui/TitleContainer";
import Separator from "../../react-ui/Separator";
import AddButton from "../../react-ui/AddButton";
import { getData, postData } from "../../request/instance";

const selector = (state) => ({
  formToken: state.formToken,
  editCategory: state.editCategory,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

function Categories({
  entity_work_name,
  configuration,
  vocabulary,
  use_special_tva,
}) {
  const { editCategory, formToken, isUsingMargin, calculMethod } =
    useStore(selector);
  const queryClient = useQueryClient();

  const { data: categories } = useQuery("Categories", () =>
    getData(formToken, `/category_profession?entity_work_id=${getUrlParams()}`)
  );

  const { data: tva_professions } = useQuery("TvaProfessionsList", () =>
    getData(formToken, `/tva_professions_list/${getUrlParams()}`)
  );

  const { mutate: removeCategories } = useMutation(
    (todoData) => postData(formToken, "/category_profession/delete", todoData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Categories");
      },
    }
  );

  const [antdArray, setAntdArray] = useState([]);
  const [categoryDetailsStatus, setCategoryDetailsStatus] = useState("empty");

  const handleDelete = useCallback(
    (id) => {
      setCategoryDetailsStatus("empty");
      editCategory();
      removeCategories(JSON.stringify({ id }));
    },
    [removeCategories, editCategory]
  );

  useEffect(() => {
    setAntdArray(
      translate_data({
        items: categories,
        handleDelete,
        isUsingMargin,
        calculMethod,
      })
    );
  }, [categories, handleDelete, isUsingMargin, calculMethod]);

  const handleSelectCategory = (selectedKeys, info) => {
    setCategoryDetailsStatus("update");
    editCategory(categories.find((el) => el.id === info.id));
  };

  return (
    <Container>
      <div className="main_admin-materiels">
        <TitleContainer label="Catégories disponibles">
          <AddButton
            onClick={() => setCategoryDetailsStatus("create")}
            disabled={categoryDetailsStatus === "create"}
            label="Catégorie"
          />
        </TitleContainer>
        <StyledTree
          showLine={{ showLeafIcon: false }}
          showIcon
          switcherIcon={<DownOutlined />}
          defaultExpandAll={false}
          onClick={handleSelectCategory}
          treeData={antdArray}
        />
      </div>
      <Separator />
      <CategoryDetails
        use_special_tva={use_special_tva}
        tva_professions={tva_professions}
        categories={categories}
        categoryDetailsStatus={categoryDetailsStatus}
        setCategoryDetailsStatus={setCategoryDetailsStatus}
        entity_work_name={entity_work_name}
        configuration={configuration}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
`;

const StyledTree = styled(Tree)`
  background-color: transparent;
  .ant-tree-switcher {
    background: transparent;
  }

  .ant-tree-node-content-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-tree-iconEle,
  .ant-tree-icon__customize {
    margin-left: 10px;
  }

  .ant-tree-list-holder-inner {
    height: 75vh;
    overflow-y: scroll;
  }
`;

export default Categories;
