import React, { useMemo, useState } from "react";
import { Modal, Spin, Switch, Form, Button } from "antd";
import styled from "styled-components";
import { useMutation } from "react-query";
import Select from "../../../react-ui/Select";
import Delete from "../../../react-ui/Icons/Delete";
import TitleContainer from "../../../react-ui/TitleContainer";
import Input from "../../../react-ui/Input";
import { postData, deleteData } from "../../../request/instance";
import UpdateDuplicateButton from "../../../react-ui/UpdateDuplicateButton";
import DuplicateButton from "../../../react-ui/DuplicateButton";
import ConcernedAgency from "../../../Chiffrage/OpportunityDetails/OpportunityForm/ConcernedAgency";
import FormRow from "../../../Form/FormRow";
import { opportunityField } from "../../../constant";
import { getEntitiesByWork } from "../../../../utils/getEntitiesByWork";

function DuplicateModal({
  isModalDuplicateOpen,
  setIsModalDuplicateOpen,
  record,
  token,
  setRecord,
  opportunities,
  refetchOffer,
  isUsingPrices,
  recordVocabulary,
  entities,
  userEntityId,
}) {
  const [form] = Form.useForm();
  const [updatePriceChecked, setUpdatePriceChecked] = useState(false);
  const [changeAgencyChecked, setChangeAgencyChecked] = useState(false);
  const { mutate: update_prices, isLoading: update_prices_loading } =
    useMutation(
      (data) =>
        postData(
          token,
          `/opportunities/${data.opportunity_id}/duplicate_update_prices`,
          data.to_send
        ),
      {
        onSuccess: () => {
          setIsModalDuplicateOpen(false);
          refetchOffer();
        },
      }
    );

  const { mutate: duplicate_opportunity, isLoading: duplicate_is_loading } =
    useMutation(
      (data) =>
        postData(
          token,
          `/opportunities/${data.opportunity_id}/duplicate`,
          data.to_send
        ),
      {
        onSuccess: () => {
          setIsModalDuplicateOpen(false);
          refetchOffer();
        },
      }
    );

  const handleDuplicate = (value) => {
    duplicate_opportunity({ opportunity_id: value });
    setChangeAgencyChecked(false);
    setUpdatePriceChecked(false);
  };

  const handleCancel = () => {
    setIsModalDuplicateOpen(false);
    setRecord({});
  };

  const findOpportunity = () => {
    return opportunities.find((opportunity) => opportunity.id === record?.id);
  };

  const entitiesInWork = useMemo(() => {
    const id = record[opportunityField.workId];
    const parentEntity = entities.find((entity) => entity.id === userEntityId);
    const entitiesByWork = getEntitiesByWork({
      entities,
      parentEntity,
      workId: id,
    });
    return entitiesByWork?.map((el) => {
      const disabled = !el.works.some((w) => w.id === id);
      return {
        id: el.id,
        pId: el.parent_id,
        title: el.name,
        value: el.id,
        disabled,
      };
    });
  }, [entities, userEntityId, record]);

  const handleSubmit = (values) => {
    if (updatePriceChecked) {
      update_prices({
        opportunity_id: record.id,
        to_send: {
          entity_id: values[opportunityField.entityId],
          check_important_field_changement: true,
        },
      });
    } else {
      duplicate_opportunity({
        opportunity_id: record.id,
        to_send: {
          entity_id: values[opportunityField.entityId],
          check_important_field_changement: true,
        },
      });
    }
    setChangeAgencyChecked(false);
    setUpdatePriceChecked(false);
  };

  return (
    <StyledModal
      open={isModalDuplicateOpen}
      onCancel={handleCancel}
      maskClosable={false}
      okButtonProps={{ hidden: isUsingPrices }}
      footer={[
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            gap: 10,
          }}
        >
          <StyledDuplicateButton
            htmlType="submit"
            label="Dupliquer l'opportunité"
            loading={duplicate_is_loading || update_prices_loading}
            onClick={(e) => {
              e.stopPropagation();
              handleSubmit(form.getFieldsValue("duplicate-opportunity-form"));
            }}
          />
          <Button key="cancel" onClick={handleCancel}>
            Annuler
          </Button>
        </div>,
      ]}
    >
      <StyledTitleContainer label="Dupliquer l'opportunité" />
      <Container>
        <p>
          Souhaitez vous dupliquer l'opportunité {record.opportunity_name} ?
        </p>
        {isUsingPrices && (
          <>
            <Wrapper>
              <StyledSpan>Dupliquer et mettre à jour les prix ?</StyledSpan>
              <Switch
                checkedChildren="Oui"
                unCheckedChildren="Non"
                checked={updatePriceChecked}
                onChange={() => setUpdatePriceChecked(!updatePriceChecked)}
              />
            </Wrapper>
            <Wrapper>
              <StyledSpan>Changer d'agence ?</StyledSpan>
              <Switch
                checkedChildren="Oui"
                unCheckedChildren="Non"
                checked={changeAgencyChecked}
                onChange={() => setChangeAgencyChecked(!changeAgencyChecked)}
              />
            </Wrapper>
            <Form
              id="duplicate-opportunity-form"
              form={form}
              onFinish={handleSubmit}
              // initialValues={initialValues}
              colon={false}
              requiredMark={false}
              // validateMessages={validateMessages}
            >
              {changeAgencyChecked && (
                <FormRow>
                  <ConcernedAgency entitiesInWork={entitiesInWork} required />
                </FormRow>
              )}
            </Form>
          </>
        )}
      </Container>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
  .ant-modal-footer {
    padding: 0 24px 24px !important;
  }
`;

const StyledSpan = styled.span`
  width: 250px;
`;

const Container = styled.div`
  padding: 0 24px;
`;

const StyledTitleContainer = styled(TitleContainer)``;

const StyledSpin = styled(Spin)`
  color: red;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  padding-bottom: 10px;
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDuplicateButton = styled(DuplicateButton)`
  margin: 0px 0;
`;
const StyledUpdateDuplicateButton = styled(UpdateDuplicateButton)``;

export default DuplicateModal;
