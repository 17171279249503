import React, { useEffect, useLayoutEffect } from "react";
import ActionCable from "actioncable";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ConfigProvider, notification } from "antd";
import frFr from "antd/lib/locale/fr_FR";
import moment from "moment";
import { ThemeProvider } from "styled-components";
import { useStore } from "../store";
import { theme } from "../../theme/theme";
import OpportunitiesRoutes from "./OpportunitiesRoutes";
import AdminRoutes from "./AdminRoutes";
import DashboardRoutes from "./DashboardRoutes";
import HiddenRoutes from "./HiddenRoutes";
import OffersRoutes from "./OffersRoutes";
import { api } from "./api";
import Clients from "../Admin-cf/Clients";

moment.locale("fr");
const queryClient = new QueryClient();
const selector = (state) => ({
  setFormToken: state.setFormToken,
  setConfigurations: state.setConfigurations,
  setIsUsingMargin: state.setIsUsingMargin,
  setIsUsingMinutes: state.setIsUsingMinutes,
  setManagementSpecialTVA: state.setManagementSpecialTVA,
  setCalculMethod: state.setCalculMethod,
  setStep: state.setStep,
  setRole: state.setRole,
  setVocabulary: state.setVocabulary,
  setProcurement: state.setProcurement,
  setConfigurationApplication: state.setConfigurationApplication,
  setVocabularyApplication: state.setVocabularyApplication,
  setItemsAreUpdating: state.setItemsAreUpdating,
});

const getActionCableUrl = () => {
  const websocketStart = !process.env.APP_DOMAIN ? "ws" : "wss";
  return `${websocketStart}://${
    process.env.APP_DOMAIN ?? "localhost:3000"
  }/cable`;
};

function Providers({ token }) {
  const setItemsAreUpdating = useStore(
    // eslint-disable-next-line no-shadow
    ({ setItemsAreUpdating }) => setItemsAreUpdating
  );
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    let cable;
    if (token) {
      ActionCable.INTERNAL.protocols.push(token);
      cable = ActionCable.createConsumer(getActionCableUrl());
    }
    if (cable) {
      const channel = cable.subscriptions.create(
        {
          channel: "Smart4Channel",
        },
        {
          received: (data) => {
            if (data.items_are_updating === false) {
              setItemsAreUpdating(data.items_are_updating);
            }
          },
        }
      );

      return () => {
        if (channel) {
          cable.subscriptions.remove({
            channel: "Smart4Channel",
          });
        }
        cable.disconnect();
      };
    }
  }, [token]);

  return null;
}

const IndexLowCurrent = React.memo((props) => {
  const {
    setFormToken,
    setIsUsingMargin,
    setCalculMethod,
    setIsUsingMinutes,
    setStep,
    setManagementSpecialTVA,
    setRole,
    setConfigurations,
    setVocabulary,
    setProcurement,
    setConfigurationApplication,
    setVocabularyApplication,
    setItemsAreUpdating,
  } = useStore(selector);

  // Initialize axios interceptors
  useEffect(() => {
    const resInterceptor = (response) => {
      if (response?.data?.message) {
        notification.info({
          description: response?.data?.message,
          placement: "bottom",
          duration: 5,
        });
      }
      return response;
    };
    const errInterceptor = (error) => {
      /* Disconnect the user if unauthorized or unauthenticated.
       We check if the error message is the same as the unauthenticated or timeout one
       stored in config/locales/devise.en.yml */
      if (
        error.response?.status === 401 &&
        (error.response?.data?.error ===
          "Votre session a expiré. Merci de vous authentifier de nouveau." ||
          error.response?.data?.error ===
            "Vous devez vous authentifier afin de bénéficier de Smart4.")
      ) {
        window.location.reload();
      } else if (error.response?.data?.message) {
        notification.error({
          description: error.response?.data?.message,
          placement: "bottom",
          duration: 5,
        });
      }

      throw error;
    };
    const interceptor = api.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );
    return () => api.interceptors.response.eject(interceptor);
  }, []);

  useLayoutEffect(() => {
    setFormToken(props.token);
    if (props.use_margin) setIsUsingMargin(props.use_margin);
    if (props.minutes) setIsUsingMinutes(props.minutes);
    if (props.management_special_TVA)
      setManagementSpecialTVA(props.management_special_TVA);
    if (props.calcul_method) setCalculMethod(props.calcul_method);
    if (props.role) setRole(props.role);
    if (props.configurations) setConfigurations(props.configurations);
    else if (props.configuration) {
      if (props.configuration.length >= 0)
        setConfigurations(props.configuration);
      else setConfigurations([props.configuration]);
    }
    if (props.vocabulary) setVocabulary(props.vocabulary);
    if (props.procurement) setProcurement(props.procurement);
    if (props.vocabulary_application)
      setVocabularyApplication(props.vocabulary_application);
    if (props.items_are_updating !== undefined)
      setItemsAreUpdating(props.items_are_updating);
    if (props.configuration_application)
      setConfigurationApplication(props.configuration_application);
  }, [
    setFormToken,
    setIsUsingMargin,
    props,
    setCalculMethod,
    setIsUsingMinutes,
    setStep,
    setRole,
    setConfigurations,
    setVocabulary,
    setProcurement,
    setConfigurationApplication,
    setVocabularyApplication,
    setItemsAreUpdating,
  ]);

  return (
    <ConfigProvider
      locale={frFr}
      theme={{
        token: {
          fontFamily:
            "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Nunito', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Providers token={props.token} />
          <Router>
            <Routes>
              <Route
                path="/opportunities/*"
                element={<OpportunitiesRoutes {...props} />}
              />
              <Route
                path="/admin/hiddens"
                element={<HiddenRoutes {...props} />}
              />
              <Route path="/admin/*" element={<AdminRoutes {...props} />} />
              <Route
                path="/dashboard/*"
                element={<DashboardRoutes {...props} />}
              />
              <Route path="/offers/*" element={<OffersRoutes {...props} />} />
              <Route
                path="companies/:companyId/clients"
                element={
                  <Clients
                    companyId={props.company_id}
                    statuses={props.statuses}
                    activities={props.activities}
                    works={props.works}
                    vocabulary_application={props.vocabulary_application}
                    role_user={props.role_user}
                    entities={props.entities}
                    users={props.users}
                    vocabulary={props.vocabulary}
                    configuration={props.default_configuration}
                  />
                }
              />
            </Routes>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
});

export default IndexLowCurrent;
