export const marginToCoeff = ({ marginRate, isUsingMargin, calculMethod }) => {
  if (marginRate === undefined || marginRate === null || marginRate === "")
    return undefined;
  if (!isUsingMargin) {
    return calculMethod === "cost_price"
      ? 1 + marginRate / 100
      : 1 / (1 - marginRate / 100);
  }
  return marginRate;
};

export const coeffToMargin = ({ coeff, isUsingMargin, calculMethod }) => {
  if (coeff === undefined || coeff === null || coeff === "") return undefined;

  let marginRate = coeff;
  if (!isUsingMargin) {
    if (calculMethod === "cost_price") marginRate = (coeff - 1) * 100;
    else marginRate = -(1 / coeff - 1) * 100;
  }
  return marginRate;
};
