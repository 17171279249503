import { Checkbox, Form } from "antd";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import FormRow from "../../Form/FormRow";
import FormCol from "../../Form/FormCol";
import Label from "../../Form/Label";
import TitleContainer from "../../react-ui/TitleContainer";
import Button from "../../react-ui/Button";
import AddButton from "../../react-ui/AddButton";
import AssociationsTable from "./AssociationsTable";
import { useStore } from "../../store";
import { postData } from "../../request/instance";
import AddLineButton from "../../react-ui/AddLineButton";

const selector = (state) => ({
  formToken: state.formToken,
});

function CreateAssoForm({
  closeModal,
  frequencies,
  workPositions,
  opportunityId,
  configApplication,
  isLockedByUser,
}) {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);
  const [form] = Form.useForm();
  const [indemnities, setIndemnities] = useState([]);
  const [workBonuses, setWorkBonuses] = useState([]);
  const indemnityId = useRef(1);
  const wbId = useRef(1);

  const { mutate: createAssociationsMutation, isLoading } = useMutation(
    (todo) => postData(formToken, `/agent/create_associations`, todo),
    {
      onSettled: () => closeModal(),
    }
  );

  const handleSubmit = (workPositionsChecked) => {
    const ids = Object.keys(
      Object.fromEntries(
        Object.entries(workPositionsChecked).filter(([, value]) => !!value)
      )
    );
    const send = {
      opportunity_id: opportunityId,
      ids,
      indemnity_attributes: indemnities.map((el) => ({
        ...el,
        amount_cents: el.amount_cents || 0,
      })),
      work_bonus_attributes: workBonuses.map((el) => ({
        ...el,
        amount_cents: el.amount_cents || 0,
      })),
    };
    createAssociationsMutation(send, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) =>
            (query.queryKey[0] === "WorkBonuses" ||
              query.queryKey[0] === "Indemnities" ||
              query.queryKey[0] === "Agents") &&
            (query.queryKey[1]?.distinct ||
              (query.queryKey[1]?.wpId &&
                ids.includes(query.queryKey[1].wpId.toString()))),
        });
      },
    });
  };

  const createWb = () => {
    setWorkBonuses((oldWorkBonuses) => {
      const newWorkBonuses = [...oldWorkBonuses];
      newWorkBonuses.push({
        id: wbId.current,
        name: "Nouvelle prime",
        amount_cents: 0,
        frequency: "hourly",
      });
      return newWorkBonuses;
    });
    wbId.current += 1;
  };

  const createIndemnity = () => {
    setIndemnities((oldIndemnities) => {
      const newIndemnities = [...oldIndemnities];
      newIndemnities.push({
        id: indemnityId.current,
        name: "Nouvelle indemnité",
        amount_cents: 0,
        frequency: "hourly",
      });
      return newIndemnities;
    });
    indemnityId.current += 1;
  };

  const updateIdemnities = ({ id, ...fields }) => {
    setIndemnities((oldIndemnities) => {
      const newIndemnities = [...oldIndemnities];
      const newIndemnityIdx = newIndemnities.findIndex((el) => el.id === id);
      newIndemnities[newIndemnityIdx] = {
        ...newIndemnities[newIndemnityIdx],
        ...fields,
      };
      return newIndemnities;
    });
  };

  const updateWorkBonuses = ({ id, ...fields }) => {
    setWorkBonuses((oldWorkBonuses) => {
      const newWorkBonuses = [...oldWorkBonuses];
      const newWorkBonusIdx = newWorkBonuses.findIndex((el) => el.id === id);
      newWorkBonuses[newWorkBonusIdx] = {
        ...newWorkBonuses[newWorkBonusIdx],
        ...fields,
      };
      return newWorkBonuses;
    });
  };

  const deleteIdemnities = (id) => {
    setIndemnities((oldIndemnities) => {
      const newIndemnities = [...oldIndemnities];
      const newIndemnityIdx = newIndemnities.findIndex((el) => el.id === id);
      newIndemnities.splice(newIndemnityIdx, 1);
      return newIndemnities;
    });
  };

  const deleteWorkBonuses = (id) => {
    setWorkBonuses((oldWorkBonuses) => {
      const newWorkBonuses = [...oldWorkBonuses];
      const newWorkBonusIdx = newWorkBonuses.findIndex((el) => el.id === id);
      newWorkBonuses.splice(newWorkBonusIdx, 1);
      return newWorkBonuses;
    });
  };

  const checkAllWps = () => {
    const wps = {};
    workPositions.forEach((wp) => {
      wps[wp.id] = true;
    });
    form.setFieldsValue(wps);
  };

  const uncheckAllWps = () => {
    const wps = {};
    workPositions.forEach((wp) => {
      wps[wp.id] = false;
    });
    form.setFieldsValue(wps);
  };

  return (
    <Form
      id="create-asso-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
    >
      <TitleContainer label="Ajouter des primes et des indemnités">
        <StyledButton
          label="Annuler"
          onClick={closeModal}
          fontSize="14px"
          btnType="cancel"
          type="button"
        />
        <AddButton
          label="Ajouter"
          type="submit"
          value="submit"
          fontSize="14px"
          loading={isLoading}
        />
      </TitleContainer>

      <FormContainer>
        <AssociationsContainer>
          <AssociationWrapper>
            <AssociationTitleWrapper>
              <AssociationTitle label="Primes" />
              <AddLineButton label="Ajouter" onClick={createWb} type="button" />
            </AssociationTitleWrapper>
            <AssociationsTable
              isWorkBonus
              associations={workBonuses}
              updateMutation={updateWorkBonuses}
              deleteMutation={deleteWorkBonuses}
              frequencies={frequencies}
              odd={false}
              isFromCreateAsso
              configApplication={configApplication}
              isLockedByUser={isLockedByUser}
            />
          </AssociationWrapper>
          <AssociationWrapper>
            <AssociationTitleWrapper>
              <AssociationTitle label="Indemnités" />
              <AddLineButton
                label="Ajouter"
                onClick={createIndemnity}
                type="button"
              />
            </AssociationTitleWrapper>
            <AssociationsTable
              associations={indemnities}
              updateMutation={updateIdemnities}
              deleteMutation={deleteIdemnities}
              frequencies={frequencies}
              odd={false}
              isFromCreateAsso
              configApplication={configApplication}
              isLockedByUser={isLockedByUser}
            />
          </AssociationWrapper>
        </AssociationsContainer>

        <WpContainer>
          <WpTitleWrapper>
            <WpTitle label="Sélectionnez les postes à mettre à jour" />
            <ButtonWrapper>
              <WpButton
                label={
                  <>
                    <i className="fa-duotone fa-calendar-times mr-1" />
                    Décocher tous les postes
                  </>
                }
                onClick={uncheckAllWps}
                fontSize="14px"
                type="button"
              />
              <WpButton
                label={
                  <>
                    <i className="fa-duotone fa-calendar-check mr-1" />
                    Cocher tous les postes
                  </>
                }
                onClick={checkAllWps}
                fontSize="14px"
                type="button"
              />
            </ButtonWrapper>
          </WpTitleWrapper>
          <WpWrapper>
            {workPositions?.map((wp) => (
              <WpFormRow key={wp.id}>
                <FormCol
                  name={`${wp.id}`}
                  valuePropName="checked"
                  labelAlign="right"
                >
                  <Checkbox>{wp.title}</Checkbox>
                </FormCol>
              </WpFormRow>
            ))}
          </WpWrapper>
        </WpContainer>
      </FormContainer>
    </Form>
  );
}

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const WpButton = styled(Button)`
  font-size: 11px !important;
`;

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

const AssociationsContainer = styled.div`
  display: flex;
  gap: 15px;
`;

const AssociationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 50%;
`;

const AssociationTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  gap: 15px;
`;

const AssociationTitle = styled(Label)`
  text-align: center;
  font-size: 18px !important;
`;

const WpContainer = styled.div`
  display: flex;
  margin: 20px 0 10px;
  flex-direction: column;
  gap: 10px;
`;

const WpTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 20px;
  gap: 10px;
`;

const WpWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;

const WpTitle = styled(Label)`
  font-size: 18px !important;
`;

const WpFormRow = styled(FormRow)`
  padding: 0 !important;
  width: 20%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
`;

export default CreateAssoForm;
