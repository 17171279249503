import create from "zustand";
import { devtools } from "zustand/middleware";
import produce from "immer";
import { fecthMaterialsData } from "./request/material.request";
import { fecthCategoriesData } from "./request/category.request";
import { currency_cents_to_euros } from "./utils";

// const getMaterials = fecthMaterialsData(null)
// const getCategories = fecthCategoriesData(null)

const store = (set, get) => ({
  categories: null,
  difficulties: null,
  majorations: null,
  unities: null,
  configurations: null,
  configurationApplication: null,
  vocabularyApplication: null,
  vocabulary: null,
  selectedMateriel: null,
  formToken: "",
  isUsingMargin: false,
  isUsingMinutes: false,
  calculMethod: "",
  step: null,
  zoneData: [],
  zoneDataChild: [],
  zoneOpenData: [],
  selectedPrestation: null,
  selectedCategory: null,
  selectedCarrycot: null,
  selectedProfil: null,
  selectedDeplacement: null,
  selectedGenralParameter: [],
  selectedDifficulty: null,
  selectedMajoration: null,
  selectedCarrycot: null,
  selectedManufacturer: null,
  arrowEstimateToCipherValue: null,
  activeCalculateMargin: null,
  selectedDistributor: null,
  selectedFamily: null,
  loadingStatus: null,
  role: null,
  warnings: null,
  desableLoadingOuvrageTree: false,
  newFile: null,
  selectedWorkCompany: null,
  apiProvider: null,
  procurement: null,
  management_special_TVA: false,
  itemsAreUpdating: false,

  //action
  //ZONE--------------------------
  // setZoneData: (zones, defaultMaterialsData) => set(produce((state)=> {state.zoneData = Object.fromEntries(zones.map((zone) => [
  //     zone.id,
  //     defaultMaterialsData.filter((material) => material.zoneId === zone.id)
  // ]))})),
  createDataZone: (value, zoneId, flag) =>
    set(
      produce((state) => {
        state.zoneData = [
          {
            key: value.id,
            zoneId: zoneId,
            entitled: value.name,
            costprice: value.cost_price_cents,
            marginrate: value.margin_rate,
            sellprice: currency_cents_to_euros(
              (parseFloat(value.margin_rate) / 100 + 1) * value.cost_price_cents
            ),
            estimate: 9,
            cipher: 9,
            difficulty: 0,
            finalquantity: 9,
            nacelle: 9,
            increase: 9,
            flag: flag,
          },
          ...state.zoneData,
        ];
      }),
      false,
      "addDataZone"
    ),

  createDataZoneChild: (
    value,
    zoneId,
    flag,
    materialsAssociates,
    prestationsAssociates,
    materials,
    prestations
  ) =>
    set(
      produce((state) => {
        const data = [];
        materialsAssociates
          .filter((materialAsso) => materialAsso.material_child_id === value.id)
          .map((item) => {
            const materialValue = materials.find(
              (material) => material.id === item.material_parent_id
            );
            data.push(materialValue);
          });
        prestationsAssociates
          .filter(
            (prestationAsso) =>
              prestationAsso.material_profession_id === value.id
          )
          .map((item) => {
            const prestationValue = prestations.find(
              (prestation) => prestation.id === item.prestation_parent_id
            );
            data.push(prestationValue);
          });

        data.map(
          (item) =>
            (state.zoneDataChild = [
              {
                zoneId: zoneId,
                key: item.id,
                parent_key: value.id,
                entitled: item.name,
                costprice: item.cost_price_cents,
                marginrate: item.margin_rate,
                sellprice: 77,
                estimate: 2,
                cipher: 10,
                difficulty: 3,
                finalquantity: 9,
                nacelle: 9,
                increase: 9,
                flag: item.unity_profession_id ? "prestations" : "materials",
              },
              ...state.zoneDataChild,
            ])
        );
      }),
      false,
      "addDataZoneChild"
    ),

  updatedTodosZone: (value, e) =>
    set(
      produce((state) => {
        const index = state.zoneData?.findIndex(
          (todo) => todo.key === value.key
        );
        if (index > -1) {
          state.zoneData[index] = {
            key: value.key,
            zoneId: value.zoneId,
            entitled: value.entitled,
            costprice: value.costprice,
            marginrate: e,
            sellprice: currency_cents_to_euros(
              (parseFloat(e) / 100 + 1) * value.costprice
            ),
            estimate: 9,
            cipher: 10,
            difficulty: 2,
            finalquantity: 9,
            nacelle: 9,
            increase: 9,
            flag: state.zoneData[index].flag,
          };
        }
      }),
      false,
      "updateZone"
    ),

  updatedTodosZoneChild: (value, e) =>
    set(
      produce((state) => {
        const index = get(state).zoneDataChild?.findIndex(
          (todo) => todo.key === value.key
        );
        //copy object value for modify
        debugger;
        const arrayAssign = { ...value };
        if (index > -1) {
          debugger;
          if (e.target.id === "difficulty") {
            arrayAssign.difficulty = e.target.value;
          }
          if (e.target.id === "cipher") {
            arrayAssign.cipher = e.target.value;
          }
          if (e?.currentTarget.id === "estimate") {
            arrayAssign.cipher = arrayAssign.estimate;
          }
          state.zoneDataChild[index] = {
            key: arrayAssign.key,
            parent_key: arrayAssign.parent_key,
            zoneId: arrayAssign.zoneId,
            entitled: arrayAssign.entitled,
            costprice: arrayAssign.costprice,
            marginrate: arrayAssign.marginrate,
            sellprice: arrayAssign.sellprice,
            estimate: 2,
            cipher: arrayAssign.cipher,
            difficulty: arrayAssign.difficulty,
            finalquantity:
              arrayAssign.cipher *
              arrayAssign.difficulty *
              get(state).zoneData.find(
                (item) => item.key === arrayAssign.parent_key
              ).cipher,
            nacelle: 9,
            increase: 9,
            flag: state.zoneDataChild[index].flag,
          };
        }
      }),
      false,
      "updateZoneChild"
    ),

  //MATERIALS---------------------
  setMaterials: (value) =>
    set(
      produce((state) => {
        state.materials = value;
      }),
      false,
      "setMaterials"
    ),

  setMaterialsAssociate: (value) =>
    set(
      produce((state) => {
        state.materialsAssociate = value;
      }),
      false,
      "setMaterialsAssociate"
    ),

  updatedTodosMaterials: (value) =>
    set(
      produce((state) => {
        const index = state.materials?.findIndex(
          (todo) => todo.id === value.id
        );
        if (index > -1) {
          state.materials[index] = value;
        }
      }),
      false,
      "updateMaterials"
    ),

  editMateriel: (value) =>
    set(
      produce((state) => {
        state.selectedMateriel = { ...state.selectedMateriel, ...value };
      }),
      false,
      "setEditMateriel"
    ),

  //CATEGORY--------------------------------
  editCategories: (value) =>
    set(
      produce((state) => {
        (state.categories = { ...state.categories, ...value }),
          false,
          "editCategories";
      })
    ),

  //PRESTATION-------------------------------

  editPrestation: (value) =>
    set(
      produce((state) => {
        state.selectedPrestation = { ...state.selectedPrestation, ...value };
      }),
      false,
      "setEditPrestation"
    ),
  arrowEstimateToCipher: (value) =>
    set(
      produce((state) => {
        state.arrowEstimateToCipherValue = value;
      }),
      false,
      "setArrowEstimateToCipher"
    ),

  // CATEGORY -------------------------------

  editCategory: (value) =>
    set(
      produce((state) => {
        state.selectedCategory = { ...state.selectedCategory, ...value };
      }),
      false,
      "setEditCategory"
    ),

  // CARRYCOT---------------------------------------

  editCarrycot: (value) =>
    set(
      produce((state) => {
        state.selectedCarrycot = { ...state.selectedCarrycot, ...value };
      }),
      false,
      "setEditCarrycot"
    ),

  //PROFIL-----------------------------------------------

  editProfil: (value) =>
    set(
      produce((state) => {
        state.selectedProfil = { ...state.selectedProfil, ...value };
      }),
      false,
      "setEditProfil"
    ),

  //Fabricant/Distributeur-----------------------------------------------

  editManufacturer: (value) =>
    set(
      produce((state) => {
        state.selectedManufacturer = {
          ...state.selectedManufacturer,
          ...value,
        };
      }),
      false,
      "setEditManufacturer"
    ),

  //DISTRIBUTOR-------------------------------------------------
  editDistributor: (value) =>
    set(
      produce((state) => {
        state.selectedDistributor = {
          ...state.selectedDistributor,
          ...value,
        };
      }),
      false,
      "setEditDistributor"
    ),

  ///MOVEMENT----------------------------------------------

  editDeplacement: (value) =>
    set(
      produce((state) => {
        state.selectedDeplacement = { ...state.selectedDeplacement, ...value };
      }),
      false,
      "setEditDeplacement"
    ),

  editFamily: (value) =>
    set(
      produce((state) => {
        state.selectedFamily = { ...state.selectedFamily, ...value };
      }),
      false,
      "setEditFamily"
    ),

  //DIFFICULTY-----------------------------

  setDifficulty: (value) =>
    set(
      produce((state) => {
        state.difficulties = value;
      }),
      false,
      "setDifficulty"
    ),

  //Work Company-----------------------------
  setWorkCompany: (value) =>
    set(
      produce((state) => {
        state.selectedWorkCompany = value;
      }),
      false,
      "setWorkCompany"
    ),
  //MAJORATION-------------------------------

  setMajoration: (value) =>
    set(
      produce((state) => {
        state.majorations = value;
      }),
      false,
      "setMajoration"
    ),

  //UNITY-----------------------------------

  setUnity: (value) =>
    set(
      produce((state) => {
        state.unities = value;
      }),
      false,
      "setUnity"
    ),

  //DESABLE LOADING FOR TREE

  setDesableLoadingOuvrageTree: (value) =>
    set(
      produce((state) => {
        state.desableLoadingOuvrageTree = value;
      }),
      false,
      "setDesableLoadingOuvrageTree"
    ),

  //GENERAL PARAMETER---------------------------------
  setSelectedParameter: (value) =>
    set(
      produce((state) => {
        state.selectedGenralParameter = {
          ...state.selectedGenralParameter,
          ...value,
        };
      }),
      false,
      "setSelectedParameter"
    ),

  // Company Work -----------------------------------
  setApiProvider: (value) =>
    set(
      produce((state) => {
        state.apiProvider = value;
      }),
      false,
      "setApiProvider"
    ),

  setFormToken: (value) =>
    set(
      produce((state) => {
        state.formToken = value;
      }),
      false,
      "setFormToken"
    ),

  setIsUsingMargin: (value) =>
    set(
      produce((state) => {
        state.isUsingMargin = value;
      }),
      false,
      "setIsUsingMargin"
    ),

  setIsUsingMinutes: (value) =>
    set(
      produce((state) => {
        state.isUsingMinutes = value;
      }),
      false,
      "setIsUsingMinutes"
    ),

  setConfigurations: (value) =>
    set(
      produce((state) => {
        state.configurations = value;
      }),
      false,
      "setConfigurations"
    ),

  setConfigurationApplication: (value) =>
    set(
      produce((state) => {
        state.configurationApplication = value;
      }),
      false,
      "setConfigurationApplication"
    ),

  setVocabularyApplication: (value) =>
    set(
      produce((state) => {
        state.vocabularyApplication = value;
      }),
      false,
      "setVocabularyApplication"
    ),

  setVocabulary: (value) =>
    set(
      produce((state) => {
        state.vocabulary = value;
      }),
      false,
      "setVocabulary"
    ),

  setCalculMethod: (value) =>
    set(
      produce((state) => {
        state.calculMethod = value;
      }),
      false,
      "setCalculMethod"
    ),

  setStep: (value) =>
    set(
      produce((state) => {
        state.step = value;
      }),
      false,
      "setStep"
    ),

  setZoneData: (value) =>
    set(
      produce((state) => {
        state.zoneData = [...state.zoneData, ...value];
      }),
      false,
      "setZoneData"
    ),

  setItemsAreUpdating: (value) =>
    set(
      produce((state) => {
        state.itemsAreUpdating = value;
      }),
      false,
      "setItemsAreUpdating"
    ),

  setZoneOpen: (value) =>
    set(
      produce((state) => {
        state.zoneOpenData = value;
      }),
      false,
      "setZoneOpen"
    ),

  setSelectedDifficulty: (value) =>
    set(
      produce((state) => {
        state.selectedDifficulty = value;
      }),
      false,
      "setSelectedDifficulty"
    ),

  setSelectedMajoration: (value) =>
    set(
      produce((state) => {
        state.selectedMajoration = value;
      }),
      false,
      "setSelectedMajoration"
    ),

  setSelectedCarrycot: (value) =>
    set(
      produce((state) => {
        state.selectedCarrycot = value;
      }),
      false,
      "setSelectedCarrycot"
    ),
  setActiveCalculateMargin: (value) =>
    set(
      produce((state) => {
        state.activeCalculateMargin = value;
      }),
      false,
      "setActiveCalculateMargin"
    ),

  setLoadingStatus: (value) =>
    set(
      produce((state) => {
        state.loadingStatus = value;
      }),
      false,
      "setLoadingStatus"
    ),

  setProcurement: (value) =>
    set(
      produce((state) => {
        state.procurement = value;
      }),
      false,
      "setProcurement"
    ),

  setRole: (value) =>
    set(
      produce((state) => {
        state.role = value;
      }),
      false,
      "setRole"
    ),

  setWarnings: (value) =>
    set(
      produce((state) => {
        state.warnings = value;
      }),
      false,
      "setWarnings"
    ),

  setNewFile: (value) =>
    set(
      produce((state) => {
        state.newFile = value;
      }),
      false,
      "setNewFile"
    ),

  setManagementSpecialTVA: (value) =>
    set(
      produce((state) => {
        state.management_special_TVA = value;
      }),
      false,
      "setManagementSpecialTVA"
    ),

  //zone store part
  // updatedTodosZone: (value) => set(produce((state) => {}), false, "updateZone"),
});

export const useStore = create(devtools(store));
