import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd"; // Importer Tooltip d'Ant Design

function Option({ className = "", ...iconProps }) {
  return (
    <Tooltip
      title="Option"
      mouseLeaveDelay={0}
      placement="top"
      autoAdjustOverflow={{ adjustX: 1, adjustY: 1 }}
    >
      {" "}
      <StyledIcon
        className={`fa-duotone fa-option ${className}`}
        {...iconProps}
      />
    </Tooltip>
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: 12px;
  &:before {
    color: inherit;
  }
`;

export default Option;
