import React from "react";
import { Form, InputNumber as AntdInputNumber } from "antd";
import { array, bool, func, shape, string } from "prop-types";
import styled from "styled-components";
import Label from "./Label";

function InputNumber({
  inputNumber,
  handleUpdateOnChange,
  isShowing,
  ...formItemProps
}) {
  const handleOnBlur = (e) => {
    if (inputNumber.onBlur) inputNumber.onBlur(e);
    handleUpdateOnChange();
  };

  const disabled = inputNumber.disabled || isShowing;

  return (
    <Form.Item
      {...formItemProps}
      label={<Label label={formItemProps.label} rules={formItemProps.rules} />}
    >
      <StyledInputNumber
        {...inputNumber}
        onBlur={handleOnBlur}
        disabled={disabled}
        placeholder={isShowing ? "" : inputNumber.placeholder}
      />
    </Form.Item>
  );
}

const StyledInputNumber = styled(AntdInputNumber)`
  width: 100%;
`;

InputNumber.propTypes = {
  inputNumber: shape({
    placeholder: string,
    onBlur: func,
    disabled: bool,
    onInput: func,
    onFocus: func,
    suffix: string,
  }).isRequired,
  name: string.isRequired,
  label: string,
  rules: array,
  handleUpdateOnChange: func.isRequired,
  isShowing: bool,
};

InputNumber.defaultProps = {
  label: "",
  rules: [],
  isShowing: false,
};

export default InputNumber;
