import React from "react";
import { instance, postData, getData, deleteData } from "./instance";
import { useStore } from "../store";
import { useMutation, useQuery } from "react-query";
import { getUrlParams } from "../utils";




export const fecthMaterialsData = (formToken) => {
    const { data, isSuccess, refetch} = useQuery('getMaterials', () => getData(formToken, `/material_profession/${getUrlParams()}`))
    return data
}

//a factoriser

export const fecthMaterialsDataOpportunity = (formToken, id_opportunity) => {
    const { data, isSuccess, refetch} = useQuery('getMaterials', () => getData(formToken, `/material_by_opportunity/${id_opportunity}`))
    return data
}

export const updateMaterials = (formToken) => useMutation(updateTodo => postData(formToken, "/material_profession/update", updateTodo))

export const createMaterials = (formToken) => useMutation(createTodo => postData(formToken, "/material_profession/create", createTodo))

export const deleteMaterial = (formToken) => useMutation(deleTodo => deleteData(formToken, "/material_profession/delete", deleTodo))

export const fecthMaterialsAssociateData = (formToken) => {
    const {data, isSuccess, refetch} = useQuery('getMaterialAssociate', ()=> getData(formToken, `/material_associates`))
    return data
}

export const createMaterialAssociate = (formToken) => useMutation(createTodo => postData(formToken, "/material_associate/create", createTodo))

export const updateMaterialAssociate = (formToken) => useMutation(updateTodo => postData(formToken, "material_associate/update", updateTodo))

export const deleteMaterialAssociate = (formToken) => useMutation(deleteTodo => postData(formToken, "/material_associate/delete", deleteTodo))