import React from "react";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { stringSorter } from "../../../../utils/stringSorter";
import { numberSorter } from "../../../../utils/numberSorter";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { centsToEuros } from "../../../../utils/currencyConverter";
import { translate_filter } from "../../Categories/translate_data";
import NumberCell from "../../../react-ui/NumberCell";
import AddButton from "../../../react-ui/AddButton";

const columns = ({ clients, entities, works }) => [
  {
    title: "Intitulé",
    dataIndex: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Prix de vente (HT)",
    dataIndex: "turnover",
    sorter: (a, b) => numberSorter(a.turnover, b.turnover),
    render: (turnover) => (
      <NumberCell>
        {formatNumberString({ str: centsToEuros(turnover), space: true })} €
      </NumberCell>
    ),
  },
  {
    title: "Date de signature",
    dataIndex: "signature_date",
    sorter: (a, b) => stringSorter(a.signature_date, b.signature_date),
    render: (signatureDate) =>
      new Date(signatureDate).toLocaleDateString("fr-FR"),
  },
  {
    title: "Agence",
    dataIndex: "entity_id",
    filterMode: "tree",
    filters: translate_filter(entities, null, null, "name"),
    filterSearch: true,
    onFilter: (value, record) => {
      return record.entity_id === parseInt(value, 10);
    },
    sorter: (a, b) => numberSorter(a.entity_id, b.entity_id),
    render: (entity_id) => entities.find(({ id }) => id === entity_id).name,
  },
  {
    title: "Entreprise",
    dataIndex: "client_id",
    filterMode: "tree",
    filters: translate_filter(clients, null, null, "name"),
    filterSearch: true,
    onFilter: (value, record) => {
      return record.client_id === parseInt(value, 10);
    },
    sorter: (a, b) => numberSorter(a.client_id, b.client_id),
    render: (client_id) => clients.find(({ id }) => id === client_id).name,
  },
  {
    title: "Activité",
    dataIndex: "work_id",
    filterMode: "tree",
    filters: translate_filter(works, null, null, "fullname"),
    filterSearch: true,
    onFilter: (value, record) => {
      return record.work_id === parseInt(value, 10);
    },
    sorter: (a, b) => numberSorter(a.work_id, b.work_id),
    render: (work_id) => works.find(({ id }) => id === work_id).fullname,
  },
];

function ExternalOrdersArray({
  externalOrders = [],
  clientsAreLoading,
  wordEntered,
  setWordEntered,
  selectedExternalOrder,
  setSelectedExternalOrder,
  setStatus,
  clients,
  entities,
  works,
}) {
  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  return (
    <>
      <ButtonContainer>
        <StyledSearch
          allowClear
          placeholder="Rechercher une commande externe..."
          value={wordEntered}
          onChange={handleFilter}
        />
        <AddButton
          label="Ajouter une commande"
          onClick={() => setStatus("create")}
        />
      </ButtonContainer>
      <Container>
        <TableContainer
          bordered={false}
          columns={columns({ clients, entities, works })}
          rowKey="id"
          dataSource={externalOrders}
          pagination={false}
          isRowSelectable
          scrollY
          loading={clientsAreLoading}
          rowSelectableId={selectedExternalOrder?.id}
          onRow={(record) => {
            return {
              onClick: () => {
                setSelectedExternalOrder(record);
                setStatus("update");
              },
            };
          }}
        />
      </Container>
    </>
  );
}

const Container = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const StyledSearch = styled(Search)`
  margin-bottom: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 4px;
`;

export default ExternalOrdersArray;
