import { marginToCoeff } from "./marginConverter";

export const formulaMarginRate = ({
  sellingPrice,
  costPrice,
  calculMethod,
  isUsingMargin,
}) => {
  let res;
  if (calculMethod === "cost_price")
    res = ((sellingPrice || 0) / (costPrice || 1) - 1) * 100;
  else res = (1 - (costPrice || 0) / (sellingPrice || 1)) * 100;
  return marginToCoeff({ marginRate: res, isUsingMargin, calculMethod });
};
