import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { debounce } from "lodash";
import styled, { css } from "styled-components";
import { Tree } from "antd";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import DraggableTreeRow from "./DraggableTreeRow";
import BaseIcon from "../Icons/BaseIcon";

const { DirectoryTree, TreeNode } = Tree;

function DropContainer({ drop, children, backgroundColor }) {
  return (
    <DropWrapper $backgroundColor={backgroundColor} ref={drop}>
      {children}
    </DropWrapper>
  );
}

const TableTree = React.memo(
  ({
    // columns needs a dataIndex and a className in order to have te width resize logic
    columns = [],
    loading,
    data = [],
    tableProps = {},
    treeProps = {},
    draggableProps = {},
    drop,
    backgroundColor,
    nodesToHide = null,
  }) => {
    const [colsWidth, setColsWidth] = useState([]);
    const ref = useRef();
    const index = useRef(0);
    const delayedDragEnterLogic = useRef({});

    useEffect(() => {
      const handleResize = () => {
        if (ref && ref.current) {
          setColsWidth(
            columns
              .filter((column) => column.dataIndex && column.className)
              .map((column) => ({
                dataIndex: column.dataIndex,
                width: ref.current.getElementsByClassName(column.className)[0]
                  ?.clientWidth,
              }))
          );
        }
      };
      handleResize();
      const debouncedHandleResize = debounce(handleResize, 1000);
      window.addEventListener("resize", debouncedHandleResize);
      return () => {
        window.removeEventListener("resize", debouncedHandleResize);
      };
    }, [columns]);

    const openOnLongHover = ({ draggedRecord, hoverRecord, droppable }) => {
      Object.keys(delayedDragEnterLogic.current).forEach((key) => {
        clearTimeout(delayedDragEnterLogic.current[key]);
      });

      if (draggedRecord.key !== hoverRecord.key && droppable) {
        delayedDragEnterLogic.current[hoverRecord.key] = window.setTimeout(
          () => {
            const nodeArr = document.getElementsByClassName(hoverRecord.key);
            if (nodeArr.length > 0 && nodeArr[0]) {
              const antTreeSwitchOpen = nodeArr[0].getElementsByClassName(
                "ant-tree-switcher_close"
              );
              if (antTreeSwitchOpen.length > 0) {
                antTreeSwitchOpen[0].click();
              }
            }
          },
          800
        );
      }
    };

    const scrollTo = useCallback((target) => {
      const { bottom: currentBottom, top: currentTop } =
        target.current.getBoundingClientRect();
      const treeRef = ref.current.getElementsByClassName(
        "ant-tree-list-holder"
      )[0];
      const { bottom: boxBottom, top: boxTop } =
        treeRef.getBoundingClientRect();

      if (currentTop > boxBottom - 48) {
        treeRef.scrollTop += 8;
      }

      if (boxTop + 48 > currentBottom) {
        treeRef.scrollTop -= 8;
      }
    }, []);

    const onDrop = useCallback(() => {
      Object.keys(delayedDragEnterLogic.current).forEach((key) => {
        clearTimeout(delayedDragEnterLogic.current[key]);
      });
    }, []);

    const folderShrinkOnDrag = ({ record }) => {
      const nodeArr = document.getElementsByClassName(record.key);
      if (nodeArr.length > 0 && nodeArr[0]) {
        const antTreeSwitchOpen = nodeArr[0].getElementsByClassName(
          "ant-tree-switcher_open"
        );
        if (antTreeSwitchOpen.length > 0) {
          antTreeSwitchOpen[0].click();
        }
      }
    };

    const constructTreeColumns = useCallback(
      (element) => {
        const renderColumn = (column) => {
          if (!column.render && !column.dataIndex) return null;
          if (!column.render) return element[column.dataIndex];
          if (!column.dataIndex) return column.render({ ...element });
          return column?.render(element[column.dataIndex], { ...element });
        };

        return columns.map((column) => {
          const colWidth = colsWidth.find(
            (el) => el.dataIndex === column.dataIndex
          );
          if (!colWidth)
            return (
              <React.Fragment
                key={`${column.dataIndex}-${column.title}-${element.key}`}
              >
                {renderColumn(column)}
              </React.Fragment>
            );
          return (
            <Column
              width={colWidth.width}
              key={`${column.dataIndex}-${column.title}-${element.key}`}
            >
              {renderColumn(column)}
            </Column>
          );
        });
      },
      [colsWidth, columns]
    );

    const constructRowTitle = useCallback(
      ({ element, elementNode, elementDraggableProps }) => {
        index.current += 1;
        return Object.keys(draggableProps)?.length > 0 ? (
          <DraggableTreeRow
            record={element}
            itemId={element.key}
            index={index.current}
            onHover={scrollTo}
            onStart={folderShrinkOnDrag}
            onDrop={onDrop}
            onDragEnter={openOnLongHover}
            {...elementDraggableProps}
          >
            <Row>
              {elementDraggableProps.draggable !== false && (
                <DragContainer>
                  <DragIcon className="fa-sharp fa-duotone fa-grip-dots-vertical" />
                </DragContainer>
              )}
              {elementNode}
            </Row>
          </DraggableTreeRow>
        ) : (
          <Row>{elementNode}</Row>
        );
      },
      [draggableProps, onDrop, scrollTo]
    );

    const constructTreeNodes = useCallback(
      ({ nodes, parent }) => {
        const nodesToShow = nodes.filter((item) => {
          if (nodesToHide === null) return true;
          return !nodesToHide.includes(item.key);
        });
        if (!!parent && (!nodesToShow || nodesToShow.length < 1)) {
          const element = {
            type: "no-data",
            key: `${parent.key}-no-data`,
            parent,
            parentKeys: parent.parentKeys
              ? [...parent.parentKeys, parent.key]
              : [parent.key],
            isLeaf: true,
          };
          return [
            {
              ...element,
              icon: <EmptyIcon className="fa-duotone fa-empty-set" />,
              checkable: false,
              title: constructRowTitle({
                element,
                elementNode: <NoData>Aucune donnée</NoData>,
                elementDraggableProps: {
                  ...draggableProps,
                  draggable: false,
                  type: "onlyDropItem",
                },
              }),
            },
          ];
        }

        return nodesToShow.map((element) => {
          return {
            ...element,
            title: constructRowTitle({
              element,
              elementNode: constructTreeColumns(element),
              elementDraggableProps: element.elementDraggableProps
                ? element.elementDraggableProps
                : draggableProps,
            }),
            children: element.children
              ? constructTreeNodes({ nodes: element.children, parent: element })
              : undefined,
          };
        });
      },
      [constructRowTitle, constructTreeColumns, draggableProps, nodesToHide]
    );

    const treeData = useMemo(() => {
      const oldData = [...data];
      index.current = 0;
      return constructTreeNodes({ nodes: oldData });
    }, [constructTreeNodes, data]);

    const renderTreeNodes = useCallback(
      (trData) =>
        trData.map((item) => {
          const itemProps = { ...item };
          delete itemProps.children;
          if (item.children) {
            return (
              <TreeNode className={itemProps.key} {...itemProps}>
                {renderTreeNodes(item.children)}
              </TreeNode>
            );
          }
          return <TreeNode className={itemProps.key} {...itemProps} />;
        }),
      []
    );

    const content = (
      <TableRef ref={ref}>
        <TableContainer columns={columns} loading={loading} {...tableProps} />
        <TreeContainer $height={treeProps.height}>
          <DirectoryTree {...treeProps}>
            {!loading && renderTreeNodes(treeData)}
          </DirectoryTree>
          <div />
        </TreeContainer>
      </TableRef>
    );

    return drop ? (
      <DropContainer drop={drop} backgroundColor={backgroundColor}>
        {content}
      </DropContainer>
    ) : (
      content
    );
  }
);

const TableRef = styled.div`
  width: 100%;
  tbody {
    display: none;
  }
  div:first-child {
    margin-bottom: 0 !important;
  }
`;

const TreeContainer = styled.div`
  width: 100%;
  .ant-tree-switcher {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-tree {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    ${({ $height }) =>
      $height &&
      css`
        min-height: ${$height}px;
        .ant-tree-list-holder-inner {
          min-height: ${$height}px;
        }
      `}
  }
  .ant-tree-node-content-wrapper-normal,
  .ant-tree-node-content-wrapper-close,
  .ant-tree-node-content-wrapper-open {
    display: flex;
    padding: 0 !important;
  }
  .ant-tree-title {
    width: 100%;
    overflow: hidden;
  }
  .ant-tree-iconEle {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .ant-tree .ant-tree-treenode {
    padding: 0 !important;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode {
    min-height: 28px;
  }
  .ant-tree.ant-tree-directory .ant-tree-treenode::before {
    height: 100%;
  }
  .ant-tree .ant-tree-checkbox {
    margin-inline-end: 0px !important;
    margin-bottom: 3px !important;
  }
`;

const Row = styled.div`
  display: flex;
  font-size: 10px;
  justify-content: end;
  min-height: 28px;
`;

const Column = styled.div`
  flex-basis: ${({ width }) => `${width}px`};
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 5px !important;
`;

const DragContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
`;

const DragIcon = styled(BaseIcon)`
  color: rgba(0, 0, 0, 0.25);
`;

const DropWrapper = styled.div`
  width: 100%;
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      .ant-table-thead > tr > th,
      .ant-table-thead > tr > td {
        transition: none !important;
        background-color: ${$backgroundColor} !important;
      }
    `}
`;

const NoData = styled.div`
  margin-right: auto;
`;

const EmptyIcon = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.blue11};
`;

export default TableTree;
