import React from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { useStore } from "../../store";
import { Loading } from "../../loading";
import { getUrlParams } from "../../utils";
import { postData } from "../../request/instance";
import ManufacturerForm from "./ManufacturerForm";
import TitleContainer from "../../react-ui/TitleContainer";
import { handlePictureKey } from "../../../utils/createFormData";
import Button from "../../react-ui/Button";
import AddButton from "../../react-ui/AddButton";

const selector = (state) => ({
  selectedManufacturer: state.selectedManufacturer,
  formToken: state.formToken,
  editManufacturer: state.editManufacturer,
});
function ManufacturerDetails({
  manufacturerDetailsStatus,
  setManufacturerDetailsStatus,
  manufacturers,
}) {
  const { selectedManufacturer, formToken, editManufacturer } =
    useStore(selector);
  const queryClient = useQueryClient();
  const { mutate: updateManufacturer, status } = useMutation(
    (todo) => postData(formToken, "/manufacturer/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Manufacturers");
      },
    }
  );

  const { mutate: createManufacturer, isLoading } = useMutation(
    (todo) => postData(formToken, "/manufacturer/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Manufacturers");
      },
      onSuccess: (payload) => {
        editManufacturer(payload);
        setManufacturerDetailsStatus("update");
      },
      onError: () => setManufacturerDetailsStatus("empty"),
    }
  );

  const handleRegisterManufacturerProfession = (e) => {
    const formatableKeys = ["picture"];
    const formData = new FormData();
    Object.keys(e).forEach(
      (key) =>
        e[key] !== undefined &&
        e[key] !== null &&
        !formatableKeys.includes(key) &&
        formData.append(`manufacturer[${key}]`, e[key])
    );
    if (e.picture) {
      handlePictureKey({
        formData,
        picture: e.picture,
        keyName: "manufacturer",
      });
    }
    formData.append("manufacturer[entity_work_id]", getUrlParams());
    if (manufacturerDetailsStatus === "update") {
      formData.append("id", selectedManufacturer.id);
      updateManufacturer(formData);
    }
    if (manufacturerDetailsStatus === "create") {
      createManufacturer(formData);
    }
  };

  const onSubmit = (e) => {
    handleRegisterManufacturerProfession(e);
  };

  const isShowing =
    manufacturerDetailsStatus === "update" &&
    selectedManufacturer.entity_work_id !== parseInt(getUrlParams(), 10);

  const isUpdating = manufacturerDetailsStatus === "update" && !isShowing;

  const getName = () => {
    if (manufacturerDetailsStatus !== "update") return "";
    const curItem = manufacturers.find(
      (el) => el.id === selectedManufacturer.id
    );
    if (!curItem?.name) return "";
    return ` : ${curItem.name}`;
  };

  return (
    <Container>
      <TitleContainer label={`Détails du fabricant${getName()}`}>
        {manufacturerDetailsStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={() => setManufacturerDetailsStatus("empty")}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="manu-distrib-form"
              loading={isLoading}
            />
          </>
        )}
        <Loading status={status} />
      </TitleContainer>
      {manufacturerDetailsStatus === "empty" && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez un fabricant pour afficher les détails
          </p>
        </div>
      )}
      {manufacturerDetailsStatus !== "empty" && (
        <Wrapper>
          <ManufacturerForm
            updateOnChange={isUpdating}
            initialManufacturer={
              manufacturerDetailsStatus === "update"
                ? selectedManufacturer
                : undefined
            }
            onSubmit={onSubmit}
            isShowing={isShowing}
          />
        </Wrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default ManufacturerDetails;
