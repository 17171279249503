import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Checkbox } from "antd";
import { postData } from "../../request/instance";
import Delete from "../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../confirmModal";
import LazyOuvrageArray from "./OuvrageStructure/LazyOuvrageArray";
import { translate_filter } from "../Categories/translate_data";
import { numberSorter } from "../../../utils/numberSorter";
import { stringSorter } from "../../../utils/stringSorter";

function OuvrageArray({
  token,
  entityWorkId,
  setOuvrageSelected,
  ouvrageSelected,
  ouvrages,
  setOuvrageDetailsStatus,
  ouvrageDetailsStatus,
  setOuvrageDetails,
  setOuvrageDynamicLabel,
  setOuvrages,
  wordEntered,
  categories,
  setOuvrageChildId,
  ouvrageProfessionsQueryKey,
  group,
  configuration,
}) {
  const queryClient = useQueryClient();

  const ouvrageProfessionQueryKey = [
    "OuvrageProfession",
    ouvrageSelected[0]?.id,
  ];

  const [categoryFiltered, setCategoryFiltered] = useState();

  const handleSelectOuvrage = (record) => {
    setOuvrageSelected([record]);
    setOuvrageDetailsStatus("modify");
    setOuvrageDynamicLabel(`Modifier ${record.name}`);
    setOuvrageChildId(record.id);
  };

  const { mutate: deleteOuvrageMaster, status } = useMutation(
    (todo) => postData(token, "/ouvrage_profession/delete", todo),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries(ouvrageProfessionQueryKey);
        setOuvrages((oldOuvrages) => {
          const newOuvrages = [...oldOuvrages];
          return [
            ...newOuvrages.filter(
              (ouvrage) => ouvrage.id !== Number(JSON.parse(payload).id)
            ),
          ];
        });
      },
    }
  );

  const handleDeleteOuvrageMaster = (idOuvrage) => {
    deleteOuvrageMaster(JSON.stringify({ id: idOuvrage }));
    setOuvrageSelected([]);
    setOuvrageDetails([]);
  };
  const onRow = (record) => {
    handleSelectOuvrage(record);
  };

  const OuvrageMaintenanceColumn = {
    title: "Maint.",
    dataIndex: "maintenance",
    hidden: !configuration.general.maintenance,
    sorter: (a, b) => numberSorter(a.maintenance, b.maintenance),
    width: "60px",
    render: (_, record) => (
      <Checkbox defaultChecked={record.maintenance} disabled />
    ),
  };
  const OuvragProfessionsColumns = [
    {
      title: "Intitulé",
      dataIndex: "name",
      sorter: (a, b) => stringSorter(a.name, b.name),
      render: (_, record) => <div>{record.name}</div>,
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      filters: translate_filter(categories),
      filterMode: "tree",
      filterSearch: true,
      sorter: true,
      defaultFilteredValue: categoryFiltered || [],
      onFilter: () => {
        return true;
      },
      filterMultiple: true,
      width: "25%",
      render: (_, record) => <div>{record.category_profession.title}</div>,
    },
    OuvrageMaintenanceColumn,
    {
      title: "",
      dataIndex: "action",
      width: "20px",
      render: (_, record) => (
        <Delete
          onClick={() =>
            ShowDeleteConfirm(record.name, record.id, handleDeleteOuvrageMaster)
          }
        />
      ),
    },
  ];

  return (
    <LazyOuvrageArray
      columns={OuvragProfessionsColumns}
      from="admin"
      entityWorkId={entityWorkId}
      ouvrages={ouvrages}
      setOuvrages={setOuvrages}
      wordEntered={wordEntered}
      categoryFiltered={categoryFiltered}
      setCategoryFiltered={setCategoryFiltered}
      ouvrageProfessionsQueryKey={ouvrageProfessionsQueryKey}
      group={group}
      onRow={onRow}
    />
  );
}

export default OuvrageArray;
