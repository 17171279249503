import { Divider } from "antd";
import { notification } from "antd";

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DropElement from "./DropElement";

function DropZoneComponent({
  label,
  elementsList,
  setElementsList,
  data,
  dataJson,
  setDataJson,
  defaultTVA,
  handleDragIn,
  col,
  line,
  type,
  removeElement,
}) {
  const [isMandatoryRequired, setIsMandatoryRequired] = useState(
    label[data?.name[1]] === "Intitulé" ||
      label[data?.name[1]] === "Client facturé" ||
      label[data?.name[1]] === "Agence concernée"
  );
  const [isSplit, setIsSplit] = useState(data?.name[1] !== undefined);
  const place = `${col}-${line}`;
  const source = {
    place,
    col: 1,
  };

  const split = () => {
    const block = line === "line_4" ? "blank" : "empty";

    if (type === "mandatory") {
      setDataJson((prevState) => ({
        ...prevState,
        mandatory: {
          ...prevState.mandatory,
          [col]: {
            ...prevState.mandatory[col],
            [line]: {
              ...prevState.mandatory[col][line],
              name: [data?.name[0], block],
              required: [data?.required[0], null],
            },
          },
        },
      }));
    } else {
      setDataJson((prevState) => ({
        ...prevState,
        complement: {
          ...prevState.complement,
          [col]: {
            ...prevState.complement[col],
            [line]: {
              ...prevState.complement[col][line],
              name: [data?.name[0], block],
            },
          },
        },
      }));
    }

    setIsSplit(true);
  };

  const unSplit = () => {
    if (isMandatoryRequired) {
      notification.error({
        message: "Action interdite",
        description:
          "Vous ne pouvez pas supprimer la partie de droite, un élément obligatoire y est présent.",
        duration: 5,
        placement: "bottom",
      });
    } else {
      if (data?.name[1] !== "blank" && data?.name[1] !== "empty") {
        setElementsList((prev) => [...prev, data?.name[1]]);

        removeElement(source, type);
      } else if (type === "mandatory") {
        setDataJson((prevState) => ({
          ...prevState,
          mandatory: {
            ...prevState.mandatory,
            [col]: {
              ...prevState.mandatory[col],
              [line]: {
                ...prevState.mandatory[col][line],
                name: [data?.name[0]],
                required: [data?.required[0]],
              },
            },
          },
        }));
      } else {
        setDataJson((prevState) => ({
          ...prevState,
          complement: {
            ...prevState.complement,
            [col]: {
              ...prevState.complement[col],
              [line]: {
                ...prevState.complement[col][line],
                name: [data?.name[0]],
              },
            },
          },
        }));
      }
      setIsSplit(false);
    }
  };

  useEffect(() => {
    setIsMandatoryRequired(
      label[data?.name[1]] === "Intitulé" ||
        label[data?.name[1]] === "Client facturé" ||
        label[data?.name[1]] === "Agence concernée"
    );
  }, [data, label]);

  return (
    <WrapperDropZone>
      <div className="container-dropZone">
        <div className="content-dropZone">
          <DropElement
            label={label}
            elementsList={elementsList}
            setElementsList={setElementsList}
            place={place}
            col={0}
            isSplit={isSplit}
            element={data?.name[0]}
            elementRequired={type === "mandatory" ? data?.required[0] : null}
            dataJson={dataJson}
            setDataJson={setDataJson}
            defaultTVA={defaultTVA}
            handleDragIn={handleDragIn}
            type={type}
            removeElement={removeElement}
          />
        </div>
        {isSplit && (
          <>
            <Divider
              dashed
              type="vertical"
              style={{
                height: "auto",
                borderColor: "rgba(181, 181, 181, 1)",
                margin: "0 4px",
              }}
            />
            <div className="content-dropZone">
              <DropElement
                label={label}
                setElementsList={setElementsList}
                place={place}
                col={1}
                isSplit={isSplit}
                element={data?.name[1]}
                elementRequired={
                  type === "mandatory" ? data?.required[1] : null
                }
                dataJson={dataJson}
                setDataJson={setDataJson}
                defaultTVA={defaultTVA}
                handleDragIn={handleDragIn}
                type={type}
                removeElement={removeElement}
              />
            </div>
          </>
        )}
      </div>
      <div className="split-control">
        <button type="button" onClick={() => unSplit()} disabled={!isSplit}>
          <span>1</span>
        </button>
        <button type="button" onClick={() => split()} disabled={isSplit}>
          <span>2</span>
        </button>
      </div>
    </WrapperDropZone>
  );
}

const WrapperDropZone = styled.div`
  display: flex;
  height: 100%;

  .container-dropZone {
    display: flex;
    width: 100%;
    height: 100%;
    border: 2px dotted rgba(181, 181, 181, 1);
    padding: 4px;
    position: relative;
    height: 100%;
  }

  .content-dropZone {
    flex: 1;
    height: auto;
  }

  .split-control button {
    font-weight: 700;
    font-size: 10px !important;
    text-align: center;
    background-color: rgba(217, 217, 217, 1);
    color: rgba(134, 128, 128, 1);
    border: none;
    width: 11px;
    height: 12px;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .split-control button:disabled {
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 1);
    color: rgba(0, 0, 0, 1);
  }
`;

export default DropZoneComponent;
