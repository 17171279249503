import React from "react";
import styled from "styled-components";
import FormCol from "../../../Form/FormCol";
import Select from "../../../react-ui/Select";
import { opportunityField } from "../../../constant";
import { useStore } from "../../../store";

function OpportunityTva({ opportunity, loadConfiguration }) {
  return (
    <FormCol
      labelOnTop
      label={
        opportunity
          ? loadConfiguration().vocabulary.tva_management
          : loadConfiguration().selectedVocabulary.vocabulary.tva_management
      }
      name={opportunityField.managementTVA}
    >
      <StyledSelectTva
        options={[
          { id: true, title: "Oui" },
          { id: false, title: "Non" },
        ]}
        keyName="title"
      />
    </FormCol>
  );
}

const StyledSelectTva = styled(Select)`
  width: 100%;
`;

export default OpportunityTva;
