import React, { useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Label from "../../../../Form/Label";
import Input from "../../../../react-ui/Input";
import { formatNumberString } from "../../../../../utils/formatNumberString";
import { postData } from "../../../../request/instance";
import { useStore } from "../../../../store";
import { stringToFloat } from "../../../../utils";

const selector = (state) => ({
  formToken: state.formToken,
});

function CoefficientLeaser({ coeff, rentingOppId, useMargin }) {
  const nbDecimal = useMemo(() => (useMargin ? 2 : 4), [useMargin]);
  const suffix = useMargin ? "%" : <span />;
  const { formToken } = useStore(selector);
  const [value, setValue] = useState(
    formatNumberString({ str: coeff, nbDecimal })
  );
  const queryClient = useQueryClient();

  const { mutate: updateCoefficient } = useMutation(
    (todo) => postData(formToken, "/renting_opportunity/update", todo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Services");
        queryClient.invalidateQueries("RentingOpportunity");
        queryClient.invalidateQueries("OpportunityCotationLc");
      },
      onError: (err) =>
        setValue(
          formatNumberString({
            str:
              err.response?.data?.coefficient || useMargin
                ? coeff * 100
                : coeff,
            nbDecimal,
          })
        ),
    }
  );

  const handleOnBlur = (e) => {
    const newValue = formatNumberString({
      str: e.target.value,
      nbDecimal,
    });
    updateCoefficient({
      id: rentingOppId,
      renting_opportunity: {
        coefficient: useMargin
          ? stringToFloat(newValue) / 100
          : stringToFloat(newValue),
      },
    });
  };

  const handleOnChange = (e) => {
    const newValue = formatNumberString({
      str: e.target.value,
      nbDecimal,
    });

    setValue(newValue);
  };

  useEffect(() => {
    setValue(formatNumberString({ str: coeff, nbDecimal }));
  }, [coeff, nbDecimal]);

  return (
    <>
      <Label label={useMargin ? "Taux" : "Coefficient"} />
      <Input
        value={value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        suffix={suffix}
        textAlign="right"
      />
    </>
  );
}

export default CoefficientLeaser;
