import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["quantity", "opportunityid", "materialid"]
    update_material() {
      let quantityValue = this.quantityTarget.value
      if (!quantityValue){
        quantityValue=0
      }
        fetch(`/opportunities/${this.opportunityidTarget.value}/materials/${this.materialidTarget.value}/update_materials_fields`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json",
              "Accept": "application/javascript",
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
            },
            body: JSON.stringify({
              quantity: quantityValue
            })
          }).then(function(response) { return response.text() }).then(function(body) {
            eval(body)
          }.bind(this));
    }
}
