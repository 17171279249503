const salaryGrid = document.querySelector('.salary-grid-list')

const selectQualification = document.querySelector('.salary_grid_qualification');
const selectCoefficient = document.querySelector('.salary_grid_coefficient');
const selectCollectiveAgreementHourly_rate = document.querySelector('.salary_grid_collective_agreement_hourly_rate');
const selectHourlyRate = document.querySelector('.salary_grid_hourly_rate');


$(document).ready(() => {

  if(salaryGrid) {
    const jsonSalaryGrid = JSON.parse(salaryGrid.value);
    const findCcnHourlyRate = (qualification, coefficient) => {
      jsonSalaryGrid.forEach((salaryItem) => {
        if(salaryItem.qualification === qualification && salaryItem.coefficient === parseInt(coefficient)) {
          if(selectCollectiveAgreementHourly_rate){
            selectCollectiveAgreementHourly_rate.value =  salaryItem.collective_agreement_hourly_rate
          }
          if(selectHourlyRate){
            selectHourlyRate.value = salaryItem.collective_agreement_hourly_rate
          }
        }
      })
    }
    
    const setCcnHourlyRate = (event) => {
      const qualif = selectQualification.value;
      const coef = selectCoefficient.value;
      findCcnHourlyRate(qualif, coef);
    }
    
    selectQualification.addEventListener('change', setCcnHourlyRate)
    selectCoefficient.addEventListener('change', setCcnHourlyRate)
    const changeValue = () => {
    const correspondingOptions = jsonSalaryGrid.filter(salary => salary.qualification === selectQualification.value)
    const correspondingValues = correspondingOptions.map(option => option.coefficient)
    optionDescending = [...selectCoefficient.options].reverse();
    optionDescending.forEach((option)=> {
      if (correspondingValues.includes(parseInt(option.value))){
        option.selected = true;
        option.style.display = "block";
      } else {
        option.style.display = "none";
      }
    })
    setCcnHourlyRate();
  }

  if(selectCoefficient.querySelector('option[selected="selected"]')){
    selectCoefficient.value = selectCoefficient.querySelector('option[selected="selected"]').value
  }
  // setCcnHourlyRate();
  selectQualification.addEventListener('change', changeValue);
  }
})


