import React, { useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { Loading } from "../../loading";
import Separator from "../../react-ui/Separator";
import TitleContainer from "../../react-ui/TitleContainer";
import { postData } from "../../request/instance";
import { useStore } from "../../store";
import MajorationForm from "../GeneralParameters/MajorationForm";
import { formatNumberString } from "../../../utils/formatNumberString";
import { numberSorter } from "../../../utils/numberSorter";
import NumberCell from "../../react-ui/NumberCell";
import { stringSorter } from "../../../utils/stringSorter";
import Button from "../../react-ui/Button";
import AddButton from "../../react-ui/AddButton";

const selector = (state) => ({
  formToken: state.formToken,
});

export const columnsMajoration = [
  {
    title: "Intitulé",
    dataIndex: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
    width: "50%",
  },
  {
    title: "Coefficient",
    dataIndex: "rate",
    sorter: (a, b) => numberSorter(a.rate, b.rate),
    width: "30%",
    render: (value) => (value ? <NumberCell>{value}</NumberCell> : null),
  },
];

function FAMajorations({ selectedFA, majorations }) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const [selectedMajo, setSelectedMajo] = useState();
  const [majoFormStatus, setMajoFormStatus] = useState("empty");

  const { mutate: updateMajoration, status } = useMutation(
    (todo) => postData(formToken, `/majoration_profession/update`, todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Majorations");
      },
    }
  );

  const { mutate: createMajoration } = useMutation(
    (todo) => postData(formToken, `/majoration_profession/create`, todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Majorations");
      },
    }
  );

  const handleRegisterParameterProfession = (e) => {
    const formatableKeys = ["rate"];
    const formData = new FormData();
    Object.keys(e).forEach(
      (key) =>
        !formatableKeys.includes(key) &&
        formData.append(
          `majoration_profession[${key}]`,
          e[key] !== undefined && e[key] !== null ? e[key] : ""
        )
    );
    formData.append(
      `majoration_profession[rate]`,
      e.rate ? parseFloat(e.rate.replace(/,/g, ".")) : ""
    );
    formData.append(
      `majoration_profession[framework_agreement_id]`,
      selectedFA.id
    );
    if (majoFormStatus === "update") {
      formData.append("id", selectedMajo.id);
      updateMajoration(formData);
    }
    if (majoFormStatus === "create") {
      formData.append("framework_agreement_id", selectedFA.id);
      createMajoration(formData);
      setMajoFormStatus("empty");
    }
  };

  const getStatus = () => {
    if (status === "loading") return "loading";
    if (status === "success") return "success";
    return "idle";
  };

  const data = useMemo(
    () =>
      majorations?.map((value) => ({
        ...value,
        key: value.id,
        rate: formatNumberString({ str: value.rate }),
      })),
    [majorations]
  );

  return (
    <Container>
      <Wrapper>
        <TitleContainer label="Majorations de l'accord-cadre">
          <AddButton
            onClick={() => setMajoFormStatus("create")}
            disabled={majoFormStatus === "create"}
            label="Majoration"
          />
        </TitleContainer>
        <TableContainer
          columns={columnsMajoration}
          dataSource={data}
          scroll={{ y: 400 }}
          pagination={false}
          rowClassName={["cursor-pointer"]}
          onRow={(record) => {
            return {
              onClick: () => {
                setSelectedMajo(majorations.find((el) => el.id === record.id));
                setMajoFormStatus("update");
              },
            };
          }}
        />
      </Wrapper>
      <Separator />
      <Wrapper>
        <TitleContainer label="Détails de la majoration">
          {majoFormStatus === "create" && (
            <>
              <StyledButton
                label="Annuler"
                onClick={() => setMajoFormStatus("empty")}
                fontSize="14px"
                btnType="cancel"
              />
              <AddButton
                label="Créer"
                type="submit"
                value="submit"
                fontSize="14px"
                form="parameter-form"
              />
            </>
          )}
          <Loading status={getStatus()} />
        </TitleContainer>
        {majoFormStatus === "empty" && (
          <div className="d-flex justify-content-center align-items-center">
            <p className="my-0">
              Sélectionnez ou créez un paramètre pour afficher les détails
            </p>
          </div>
        )}
        {majoFormStatus !== "empty" && (
          <MajorationForm
            updateOnChange={majoFormStatus === "update"}
            initialMajoration={
              majoFormStatus === "update" ? selectedMajo : undefined
            }
            onSubmit={handleRegisterParameterProfession}
          />
        )}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  width: 50%;
  height: 100%;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default FAMajorations;
