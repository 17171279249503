import React from "react";
import { Upload as AntdUpload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import styled from "styled-components";

function Upload({
  listType = "picture-card",
  maxCount = 1,
  accept = "image/*",
  ...uploadProps
}) {
  const handleOnChange = (e) => {
    if (uploadProps.onChange) uploadProps.onChange(e);
  };

  return (
    <StyledUpload
      {...uploadProps}
      onChange={handleOnChange}
      listType={listType}
      maxCount={maxCount}
      accept={accept}
      beforeUpload={() => false} // Prevent <AntdUpload /> trigerring post default request after choosing img
    >
      <div>
        <UploadOutlined />
        <UploadText>Télécharger image</UploadText>
      </div>
    </StyledUpload>
  );
}

const StyledUpload = styled(AntdUpload)`
  .ant-upload-list-picture-card-container {
    margin: 0 10px 0 0;
  }
  .ant-upload-select-picture-card {
    margin: 0;
  }
`;

const UploadText = styled.p`
  margin-top: 8px;
`;

export default Upload;
