import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { formatNumberString } from "../../../../../utils/formatNumberString";
import { quantityTimeConverter } from "../../../../../utils/quantityTimeConverter";
import { useStore } from "../../../../store";
import { postData } from "../../../../request/instance";
import ArrowRight from "../../../../react-ui/Icons/ArrowRight";
import { formatRoundNumber } from "../../../../../utils/formatRoundNumber";

const selector = (state) => ({
  formToken: state.formToken,
  isUsingMinutes: state.isUsingMinutes,
  setDesableLoadingOuvrageTree: state.setDesableLoadingOuvrageTree,
});

function Estimate({
  parentQuantity,
  value,
  updatePrestationOpportunity,
  updateMaterialOpportunity,
  updateOuvrageOpportunity,
  id_opportunity,
  zone_id,
  isUpdatingMaterialQuantity = false,
}) {
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const queryClient = useQueryClient();
  const { isUsingMinutes, formToken, setDesableLoadingOuvrageTree } =
    useStore(selector);
  let quantity = null;
  if (value.recordType === "material") {
    if (value.estimate_quantity && value.estimate_quantity > 0) {
      if (
        value &&
        (value.round_type === null || typeof value.round_type === "undefined")
      ) {
        quantity = value.estimate_quantity * parentQuantity;
      } else if (value) {
        quantity = formatRoundNumber({
          number: value.estimate_quantity * parentQuantity,
          round_type: value.round_type,
          round_number: value.round_number,
        });
      }
    }
  } else {
    quantity = value.estimate_quantity * parentQuantity;
  }

  const { mutate: fillAssoItems } = useMutation(
    (todo) =>
      postData(formToken, `/material_opportunity/fill_associated_items`, todo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["AssociatedItems", value.id]);
        queryClient.invalidateQueries("Warnings");
      },
    }
  );

  const { mutate: ouvrageFillItems } = useMutation(
    (todo) => postData(formToken, `/ouvrage_opportunity/fill_items`, todo),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["AssociatedItems", value.id]);
        queryClient.refetchQueries([
          "Ouvrage",
          {
            id: value.id,
          },
        ]);
        queryClient.invalidateQueries("Warnings");
      },
    }
  );

  useEffect(() => {
    if (!isUpdatingMaterialQuantity && shouldUpdate) {
      fillAssoItems({ id: value.id });
      setShouldUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fillAssoItems, isUpdatingMaterialQuantity, shouldUpdate]);

  if (
    value.is_parent &&
    value.recordType === "material" &&
    (value.material_parent_asso_oprts?.length > 0 ||
      value.prestation_asso_oprts?.length > 0)
  ) {
    return (
      <ParentContainer
        onClick={() => {
          setShouldUpdate(true);
        }}
      >
        <i className="fa-duotone fa-arrow-turn-down-right" />
      </ParentContainer>
    );
  }
  // desabled arrow for ouvrage
  // console.log(value);
  // if (value.group && "parent_ouvrage_id" in value) {
  //   return (
  //     <ParentContainer
  //       onClick={() => {
  //         ouvrageFillItems({ id: value.id, quantity: value.quantity });
  //       }}
  //     >
  //       <i className="fa-duotone fa-arrow-turn-down-right" />
  //     </ParentContainer>
  //   );
  // }
  if (!quantity) return null;

  const refetchOuvrage = async () => {
    const data_from_record = { ...value };
    const ouvrage_master_id =
      data_from_record.ouvrage_parent_id || data_from_record.id;
    await setDesableLoadingOuvrageTree(true);
    await queryClient.refetchQueries([
      "Ouvrage",
      {
        id: ouvrage_master_id,
      },
    ]);
    await setDesableLoadingOuvrageTree(false);
  };

  const setEstimateInCipher = (recordType) => {
    if (recordType === "prestation") {
      updatePrestationOpportunity(
        {
          opportunity_id: id_opportunity,
          id: value.id,
          unity_quantity:
            value.is_package || value.speed
              ? quantity
              : quantityTimeConverter({
                  quantity,
                  isUsingMinutes:
                    value.minutes !== null ? value.minutes : isUsingMinutes,
                  toMinutes: false,
                }),
        },
        {
          onSuccess: async () => {
            if (value.ouvrage_parent_id) {
              await refetchOuvrage();
            }
            queryClient.invalidateQueries("Warnings");
          },
        }
      );
      return;
    }
    if (recordType === "material") {
      updateMaterialOpportunity(
        {
          opportunity_id: id_opportunity,
          id: value.id,
          quantity,
        },
        {
          onSuccess: async () => {
            if (value.ouvrage_parent_id) {
              await refetchOuvrage();
            }
            queryClient.invalidateQueries("Warnings");
          },
        }
      );
      return;
    }
    if (recordType === "ouvrage") {
      updateOuvrageOpportunity(
        {
          opportunity_id: id_opportunity,
          id: value.id,
          quantity,
        },
        {
          onSuccess: async () => {
            if (value.ouvrage_parent_id) {
              await refetchOuvrage();
            }
            queryClient.invalidateQueries("Warnings");
          },
        }
      );
      return;
    }
    if (recordType === "" && value.group) {
      updateOuvrageOpportunity(
        {
          opportunity_id: id_opportunity,
          id: value.id,
          quantity,
        },
        {
          onSuccess: async () => {
            await refetchOuvrage();
            queryClient.invalidateQueries("Warnings");
          },
        }
      );
    }
  };

  return (
    <Container onClick={() => setEstimateInCipher(value.recordType)}>
      {formatNumberString({ str: quantity })}
      <StyledArrow id="estimate" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 15px;
  position: relative;
  cursor: pointer;
`;

const StyledArrow = styled(ArrowRight)`
  position: absolute;
  right: 0;
`;

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  i {
    font-size: 12px;
  }
`;

export default Estimate;
