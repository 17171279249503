import React from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

export const ShowDeleteConfirm = (name, id, deleteAction, value = null) => {
  const title = name
    ? `Voulez vous vraiment supprimer l'élément suivant : "${name}" ?`
    : "Voulez vous supprimer cet élément ?";
  confirm({
    title,
    icon: <ExclamationCircleOutlined />,
    okText: "Oui",
    okType: "danger",
    cancelText: "Non",
    maskClosable: false,
    onOk() {
      if (value) deleteAction(JSON.stringify(id), value);
      if (!value) deleteAction(JSON.stringify(id));
      // deleteAction(JSON.stringify(id));
      return false;
    },
    onCancel() {
      return false;
    },
  });
};
