import { Form, notification } from "antd";
import i18next from "i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { load } from "dotenv";
import { centsToEuros } from "../../../utils/currencyConverter";
import { formatDates } from "../../../utils/formatDates";
import { formatNumberString } from "../../../utils/formatNumberString";
import { opportunityField, validateMessages } from "../../constant";
import AddButton from "../../react-ui/AddButton";
import { getData, postData } from "../../request/instance";
import { useStore } from "../../store";
import ComplementPart from "./ComplementPart";
import ContactModal from "./ContactModal";
import MandatoryPart from "./MandatoryPart";
import ProviderModal from "./ProviderModal";
import TypologyModal from "./TypologyModal";
import { getEntitiesByWork } from "../../../utils/getEntitiesByWork";
import { genNode } from "./ClientsSelectWrapper";
import { ContactOption } from "../../react-ui/SelectContacts";

const selector = (state) => ({
  formToken: state.formToken,
});

const dateFields = [
  opportunityField.startDate,
  opportunityField.endDate,
  opportunityField.submissionDate,
  opportunityField.customerDecisionDate,
];

function Opportunity({
  works,
  entities,
  userEntityId,
  userId,
  offerId,
  companyId,
  clientActivities,
  opportunity,
  offerDeliveryDate,
  companyWorks,
  rentalOffers,
  configuration,
  vocabulary,
  configApplication,
  vocabularyApplication,
  userClientIds,
  procurement,
  isLockedByUser,
}) {
  const fieldDisabled =
    !isLockedByUser || configApplication.common.opportunity.disable_save_button;
  const [form] = Form.useForm();
  const { formToken } = useStore(selector);
  const [clientModalOpenFrom, setClientModalOpenFrom] = useState("");
  const [providerModalType, setProviderModalType] = useState("");
  const [typologyModalType, setTypologyModalType] = useState("");
  const [contactModalOpenFrom, setContactModalOpenFrom] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [clientsInMemory, setClientsInMemory] = useState([]);
  const [contactsInMemory, setContactsInMemory] = useState(
    opportunity
      ? opportunity.contacts
          .concat(opportunity.client_contacts)
          .concat(opportunity.provided_customer_contacts)
          .concat(opportunity.decision_maker_customer_contacts)
      : []
  );

  const { data: typologies } = useQuery("Typologies", async () => {
    const data = await getData(formToken, `/site_typologies`);
    return data.map((typo) => ({ ...typo, name: typo.typology }));
  });

  const workId = Form.useWatch(opportunityField.workId, form);
  const { data: providers, refetch } = useQuery(
    "Providers",
    () =>
      getData(
        formToken,
        `/current_providers?&work_id=${
          opportunity ? opportunity[opportunityField.workId] : workId
        }`
      ),
    {
      enabled: opportunity ? !!opportunity[opportunityField.workId] : !!workId,
    }
  );

  const formatClients = useCallback(
    (clients) => {
      if (!clients) return [];
      if (!procurement) return clients;
      const workIdToUse = opportunity
        ? opportunity[opportunityField.workId]
        : workId;
      const filterClients = (cl) => {
        if (
          (opportunity && cl.id === opportunity.client_id) ||
          (workIdToUse &&
            userClientIds.includes(cl.id) &&
            cl.work_ids.includes(workIdToUse))
        )
          return true;
        const children = clients.filter((c) => c.parent_id === cl.id);
        if (children) return children.some((child) => filterClients(child));
        return false;
      };
      const filtered = clients?.filter(filterClients).map((el) => ({
        ...el,
        disabled: el.position !== 2,
      }));
      const clientsUsed = [
        opportunityField.client,
        opportunityField.providedCustomer,
        opportunityField.decisionMakerCustomer,
      ];
      clientsUsed.forEach((c) => {
        if (
          !filtered.some(
            (f) => !f.disabled && f.id === form.getFieldValue(c)?.value
          )
        ) {
          form.setFieldValue(c, { label: undefined, value: undefined });
        }
      });
      return filtered;
    },
    [form, procurement, userClientIds, workId, opportunity]
  );

  useEffect(() => {
    if (workId) {
      refetch();
      form.setFieldValue([opportunityField.providedOpportunityCustomer], []);
    }
  }, [workId, refetch, form]);

  const { data: frameworkAgreements } = useQuery(
    "FrameworkAgreements",
    () =>
      getData(
        formToken,
        `/framework_agreements?entities_ids=${encodeURIComponent(
          JSON.stringify(entities.map((el) => el.id))
        )}&work_ids=${encodeURIComponent(
          JSON.stringify(works.map((el) => el.id))
        )}`
      ),
    { placeholderData: [] }
  );

  const { mutate: createOpp } = useMutation(
    (todoData) => postData(formToken, "/opportunity/create", todoData),
    {
      onError: () => {
        setIsLoading(false);
      },
      onSuccess: ({ next_page }) => {
        window.location.href = next_page;
      },
    }
  );

  const { mutate: updateOpp } = useMutation(
    (todoData) => postData(formToken, "/opportunity/update", todoData),
    {
      onError: () => {
        setIsLoading(false);
      },
      onSuccess: ({ next_page }) => {
        if (next_page) window.location.href = next_page;
      },
    }
  );

  const loadConfiguration = useCallback(() => {
    let id;
    if (opportunity) {
      id = opportunity[opportunityField.workId];
    } else id = workId || works[0].id;
    if (opportunity) {
      return { configuration, vocabulary };
    }
    const selectedWork = works?.find((el) => el.id === id);
    const selectedConfiguration = configuration?.find(
      (c) => c.work === i18next.t(`work_fr_to_en.${selectedWork.name}`)
    );
    const selectedVocabulary = vocabulary?.find(
      (c) => c.work === i18next.t(`work_fr_to_en.${selectedWork.name}`)
    );
    return { selectedConfiguration, selectedVocabulary };
  }, [workId, works, opportunity]);

  const initialValues = useMemo(
    () =>
      opportunity
        ? {
            ...opportunity,
            [opportunityField.estimatedAmount]: formatNumberString({
              str: centsToEuros(opportunity.estimated_amount_cents),
              space: true,
            }),
            [opportunityField.typologiesOpportunityIds]:
              opportunity.site_typologies.map((el) => el.id),
            [opportunityField.providedOpportunityCustomer]:
              opportunity.current_providers.map((el) => el.id),
            [opportunityField.managementTVA]: opportunity.management_TVA,
            [opportunityField.contactIds]: opportunity.contacts.map(
              (el) => el.id
            ),
            [opportunityField.contacts]: opportunity.contacts.map((el) => ({
              value: el.id,
              key: el.id,
              label: <ContactOption value={el} withLabel />,
            })),
            [opportunityField.clientContacts]: opportunity.client_contacts.map(
              (el) => ({
                value: el.id,
                key: el.id,
                label: <ContactOption value={el} withLabel />,
              })
            ),
            [opportunityField.providedCustomerContacts]:
              opportunity.provided_customer_contacts.map((el) => ({
                value: el.id,
                key: el.id,
                label: <ContactOption value={el} withLabel />,
              })),
            [opportunityField.decisionMakerCustomerContacts]:
              opportunity.decision_maker_customer_contacts.map((el) => ({
                value: el.id,
                key: el.id,
                label: <ContactOption value={el} withLabel />,
              })),
            [opportunityField.client]: {
              ...opportunity[opportunityField.client],
              label: opportunity[opportunityField.client]?.name,
              title: genNode(opportunity[opportunityField.client])?.title,
              value: opportunity[opportunityField.client]?.id,
            },
            [opportunityField.providedCustomer]: {
              ...opportunity[opportunityField.providedCustomer],
              label: opportunity[opportunityField.providedCustomer]?.name,
              title: genNode(opportunity[opportunityField.providedCustomer])
                ?.title,
              value: opportunity[opportunityField.providedCustomer]?.id,
            },
            [opportunityField.decisionMakerCustomer]: {
              ...opportunity[opportunityField.decisionMakerCustomer],
              label: opportunity[opportunityField.decisionMakerCustomer]?.name,
              title: genNode(
                opportunity[opportunityField.decisionMakerCustomer]
              )?.title,
              value: opportunity[opportunityField.decisionMakerCustomer]?.id,
            },
            ...formatDates({ values: opportunity, dateFields }),
          }
        : {
            [opportunityField.workId]: works[0].id,
            [opportunityField.entityId]: userEntityId,
            [opportunityField.successProbability]: 50,
            [opportunityField.cancellationProbability]: 0,
            [opportunityField.managementTVA]: true,
            [opportunityField.submissionDate]: new Date(offerDeliveryDate),
            [opportunityField.managementTVA]:
              loadConfiguration().selectedConfiguration.configuration
                .opportunity.default_values.special_tva,
          },
    [works, userEntityId, opportunity, offerDeliveryDate]
  );

  const entityId = Form.useWatch(opportunityField.entityId, form);
  const startDate = Form.useWatch(opportunityField.startDate, form);
  const endDate = Form.useWatch(opportunityField.endDate, form);
  const opportunityTypeId = Form.useWatch(
    opportunityField.opportunityType,
    form
  );
  const responseTypeId = Form.useWatch(opportunityField.responseType, form);
  const successProbability = Form.useWatch(
    opportunityField.successProbability,
    form
  );
  const cancellationProbability = Form.useWatch(
    opportunityField.cancellationProbability,
    form
  );

  const response_opportunty = () => {
    if (
      !loadConfiguration()?.configuration?.general?.maintenance ||
      !loadConfiguration()?.configuration?.general?.framework_agreement ||
      !loadConfiguration()?.configuration?.general?.renting
    ) {
      return true;
    }
    return false;
  };

  function findValueInObject(obj, target) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        if (key === "name" && obj[key].includes(target)) {
          return true;
        }
        const result = findValueInObject(obj[key], target);
        if (result) return true;
      }
    }
    return false;
  }

  const isGuarding = useCallback(() => {
    let id;
    if (opportunity) {
      id = opportunity[opportunityField.workId];
    } else id = workId || works[0].id;
    const selectedWork = works.find((el) => el.id === id);
    return (
      selectedWork.name === i18next.t("work.guarding") ||
      selectedWork.name === "guarding"
    );
  }, [workId, works, opportunity]);

  const isSimple = useCallback(() => {
    let id;
    if (opportunity) {
      id = opportunity[opportunityField.workId];
    } else id = workId || works[0].id;
    const selectedWork = works.find((el) => el.id === id);
    return selectedWork.name === i18next.t("work.simple");
  }, [workId, works, opportunity]);

  const hasRenting = useCallback(() => {
    if (isGuarding()) return false;
    let id;
    if (opportunity) {
      if (opportunity[opportunityField.responseType] === "renting") return true;
      id = opportunity[opportunityField.workId];
    } else id = workId || works[0].id;
    const selectedCompanyWork = companyWorks.find((el) => el.work_id === id);
    return (
      selectedCompanyWork.renting &&
      rentalOffers.filter((el) => el.company_work_id === selectedCompanyWork.id)
        .length > 0
    );
  }, [workId, companyWorks, opportunity, works, isGuarding, rentalOffers]);

  const hasTva = useCallback(() => {
    if (isGuarding()) return false;
    let id;
    if (opportunity) {
      if (
        companyWorks.find(
          (c) => c.work_id === opportunity?.[opportunityField.workId]
        )?.management_special_TVA
      )
        return true;
      id = opportunity[opportunityField.workId];
    }
    id = workId || works[0].id;
    const selectedCompanyWork = companyWorks.find((el) => el.work_id === id);
    return selectedCompanyWork.management_special_TVA;
  }, [workId, companyWorks, opportunity, works, isGuarding]);

  const isOnlyStartDate = useCallback(() => {
    if (opportunity)
      return (
        !isGuarding() &&
        opportunity[opportunityField.opportunityType] !== "maintenance" &&
        opportunity[opportunityField.responseType] !== "referencing"
      );

    return (
      !isGuarding() &&
      opportunityTypeId !== "maintenance" &&
      responseTypeId !== "referencing"
    );
  }, [isGuarding, opportunityTypeId, responseTypeId, opportunity]);

  const entitiesInWork = useMemo(() => {
    let id;
    if (opportunity) {
      id = opportunity[opportunityField.workId];
    } else id = workId || works[0].id;
    const parentEntity = entities.find((entity) => entity.id === userEntityId);
    const entitiesByWork = getEntitiesByWork({
      entities,
      parentEntity,
      workId: id,
    });
    return entitiesByWork?.map((el) => {
      const disabled = !el.works.some((w) => w.id === id);
      return {
        id: el.id,
        pId: el.parent_id,
        title: el.name,
        value: el.id,
        disabled,
      };
    });
  }, [entities, workId, opportunity, works, userEntityId]);

  useEffect(() => {
    if (responseTypeId === "referencing" || responseTypeId === "renting")
      form.setFieldValue([opportunityField.opportunityType], undefined);
  }, [responseTypeId, form]);

  useEffect(() => {
    if (entityId && !entitiesInWork.some((el) => el.id === entityId)) {
      form.setFieldValue([opportunityField.entityId], undefined);
    }
  }, [entitiesInWork, entityId, form]);

  const handleSubmit = (v) => {
    // eslint-disable-next-line no-shadow
    const {
      client,
      contacts,
      client_contacts,
      provided_customer_contacts,
      decision_maker_customer_contacts,
      ...values
    } = v;
    setIsLoading(true);
    if (opportunity) {
      const toUpdate = {
        id: opportunity.id,
        opportunity: {
          ...values,
          work: opportunity[opportunityField.workId],
          entity: values[opportunityField.entityId],
          client_id: client.value,
          provided_customer: values[opportunityField.providedCustomer]?.value,
          decision_maker_customer:
            values[opportunityField.decisionMakerCustomer]?.value,
          user: userId,
          offer: offerId,
          ...(contacts?.length > 0 && {
            [opportunityField.contactIds]: contacts?.map(({ value }) => value),
          }),
          ...(client_contacts?.length > 0 && {
            [opportunityField.clientContactIds]: client_contacts?.map(
              ({ value }) => value
            ),
          }),
          ...(provided_customer_contacts?.length > 0 && {
            [opportunityField.providedCustomerContactIds]:
              provided_customer_contacts?.map(({ value }) => value),
          }),
          ...(decision_maker_customer_contacts?.length > 0 && {
            [opportunityField.decisionMakerCustomerContactIds]:
              decision_maker_customer_contacts?.map(({ value }) => value),
          }),
          ...formatDates({ values, convertToString: true, dateFields }),
          ...(!findValueInObject(
            loadConfiguration()?.configuration.opportunity.mandatory,
            "opportunity_response_type"
          ) && {
            opportunity_type: "new_work",
            response_type: "consultation",
          }),
          ...(!findValueInObject(
            loadConfiguration()?.configuration.opportunity.mandatory,
            "opportunity_agency_concerned"
          ) && {
            entity: form.getFieldValue(opportunityField.entityId),
          }),
        },
      };
      updateOpp({
        ...toUpdate,
      });
    } else {
      const selectedCompanyWork = companyWorks.find(
        (el) => el.work_id === values[opportunityField.workId]
      );
      createOpp({
        rental_offer_ids: rentalOffers
          .filter((el) => el.company_work_id === selectedCompanyWork.id)
          .map((el) => el.id),
        opportunity: {
          ...values,
          work: values[opportunityField.workId],
          entity: values[opportunityField.entityId],
          management_TVA: values[opportunityField.managementTVA],
          client_id: client.value,
          provided_customer: values[opportunityField.providedCustomer]?.value,
          decision_maker_customer:
            values[opportunityField.decisionMakerCustomer]?.value,
          user: userId,
          offer: offerId,
          ...(contacts?.length > 0 && {
            [opportunityField.contactIds]: contacts?.map(({ value }) => value),
          }),
          ...(client_contacts?.length > 0 && {
            [opportunityField.clientContactIds]: client_contacts?.map(
              ({ value }) => value
            ),
          }),
          ...(provided_customer_contacts?.length > 0 && {
            [opportunityField.providedCustomerContactIds]:
              provided_customer_contacts?.map(({ value }) => value),
          }),
          ...(decision_maker_customer_contacts?.length > 0 && {
            [opportunityField.decisionMakerCustomerContactIds]:
              decision_maker_customer_contacts?.map(({ value }) => value),
          }),
          ...formatDates({ values, convertToString: true, dateFields }),
          ...(!findValueInObject(
            opportunity
              ? loadConfiguration()?.configuration.opportunity.mandatory
              : loadConfiguration()?.selectedConfiguration.configuration
                  .opportunity.mandatory,
            "opportunity_response_type"
          ) && {
            opportunity_type: "new_work",
            response_type: "consultation",
          }),
          ...(!findValueInObject(
            opportunity
              ? loadConfiguration()?.configuration.opportunity.mandatory
              : loadConfiguration()?.selectedConfiguration.configuration
                  .opportunity.mandatory,
            "opportunity_agency_concerned"
          ) && {
            entity: form.getFieldValue(opportunityField.entityId),
          }),
        },
      });
    }
  };

  return (
    <>
      <ProviderModal
        opportunity={opportunity}
        works={works}
        workId={workId}
        providerModalType={providerModalType}
        setProviderModalType={setProviderModalType}
        companyId={companyId}
        formOpp={form}
        providers={providers}
      />
      <TypologyModal
        workId={workId}
        typologyModalType={typologyModalType}
        setTypologyModalType={setTypologyModalType}
        companyId={companyId}
        formOpp={form}
        typologies={typologies}
      />
      <ContactModal
        contactModalOpenFrom={contactModalOpenFrom}
        setContactModalOpenFrom={setContactModalOpenFrom}
        companyId={companyId}
        setContactsInMemory={setContactsInMemory}
        formOpp={form}
        setClientsInMemory={setClientsInMemory}
        clientsInMemory={clientsInMemory}
        vocabulary={loadConfiguration().vocabulary}
      />
      <Form
        id="opportunity-form"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
        colon={false}
        requiredMark={false}
        validateMessages={validateMessages}
        onFinishFailed={() =>
          notification.error({
            description: "Des champs obligatoires n'ont pas été remplis.",
            placement: "bottom",
            duration: 5,
          })
        }
      >
        <ButtonContainer>
          <AddButton
            label={
              opportunity
                ? "Enregistrer"
                : vocabularyApplication.opportunity.create_opportunity_button
            }
            type="submit"
            value="submit"
            fontSize="14px"
            loading={loading}
            disabled={fieldDisabled}
          />
        </ButtonContainer>
        <Wrapper $edit={!!opportunity}>
          <MandatoryPart
            works={works}
            workId={workId}
            formatClients={formatClients}
            isGuarding={isGuarding}
            isSimple={isSimple}
            responseTypeId={responseTypeId}
            isOnlyStartDate={isOnlyStartDate}
            startDate={startDate}
            endDate={endDate}
            entitiesInWork={entitiesInWork}
            setClientModalOpenFrom={setClientModalOpenFrom}
            form={form}
            opportunity={opportunity}
            companyWorks={companyWorks}
            hasRenting={hasRenting}
            hasTva={hasTva}
            loadConfiguration={loadConfiguration}
            configApplication={configApplication}
            isLockedByUser={isLockedByUser}
            clientsInMemory={clientsInMemory}
            setClientsInMemory={setClientsInMemory}
            clientModalOpenFrom={clientModalOpenFrom}
            companyId={companyId}
            clientActivities={clientActivities}
            frameworkAgreements={frameworkAgreements}
            entityId={entityId}
            setContactModalOpenFrom={setContactModalOpenFrom}
            contactsInMemory={contactsInMemory}
            setContactsInMemory={setContactsInMemory}
            vocabulary={
              opportunity
                ? loadConfiguration().vocabulary
                : loadConfiguration().selectedVocabulary.vocabulary
            }
            configuration={
              opportunity
                ? loadConfiguration().configuration
                : loadConfiguration().selectedConfiguration.configuration
            }
          />
          <ComplementPart
            opportunity={opportunity}
            successProbability={successProbability}
            cancellationProbability={cancellationProbability}
            typologies={typologies}
            providers={providers}
            contactsInMemory={contactsInMemory}
            setContactsInMemory={setContactsInMemory}
            setClientModalOpenFrom={setClientModalOpenFrom}
            setProviderModalType={setProviderModalType}
            setTypologyModalType={setTypologyModalType}
            form={form}
            setContactModalOpenFrom={setContactModalOpenFrom}
            isSimple={isSimple}
            loadConfiguration={loadConfiguration}
            isGuarding={isGuarding}
            configApplication={configApplication}
            isLockedByUser={isLockedByUser}
            clientsInMemory={clientsInMemory}
            setClientsInMemory={setClientsInMemory}
            clientModalOpenFrom={clientModalOpenFrom}
            companyId={companyId}
            clientActivities={clientActivities}
            formatClients={formatClients}
            works={works}
            vocabulary={
              opportunity
                ? loadConfiguration().vocabulary
                : loadConfiguration().selectedVocabulary.vocabulary
            }
            configuration={
              opportunity
                ? loadConfiguration().configuration
                : loadConfiguration().selectedConfiguration.configuration
            }
          />
        </Wrapper>
      </Form>
    </>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: ${({ $edit }) =>
    `calc(100vh - 70px - 65.56px - 24.16px - 46px - 0.75rem - 10px${
      $edit ? " - 31px" : ""
    })`};
`;

export default Opportunity;
