import React, { useState } from "react";
import { Select as AntdSelect, Tooltip, Popover } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import styled, { css } from "styled-components";
import { array, bool, node, oneOfType, string } from "prop-types";
import { searchCaseDiatricsInsensitive } from "../../utils/search";

const filterOption = (search, option, keysToSearch) =>
  keysToSearch.some((keyName) =>
    searchCaseDiatricsInsensitive(search, option[keyName])
  );

// Définissez le composant ContactInfo en dehors de SelectContacts
function ContactInfo({ contact }) {
  // Logic to display contact information
  return (
    <div>
      {contact && (
        <>
          <p>
            <span style={{ fontSize: "14px" }}>
              Email : {contact.email}
              <br />
              Téléphone : {contact.phone}
              <br />
              Fonction : {contact.fonction}
            </span>
          </p>
          <p>
            <span style={{ fontSize: "14px" }}>
              Entreprise :<br />
            </span>
            <span style={{ fontSize: "13px" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;{contact.client.name}
              <br />
            </span>
            <span style={{ fontSize: "12px" }}>
              {contact.client.address1 && (
                <>
                  &nbsp;&nbsp;&nbsp;&nbsp;{contact.client.address1}
                  <br />
                </>
              )}
              {contact.client.address2 && (
                <>
                  &nbsp;&nbsp;&nbsp;&nbsp;{contact.client.address2}
                  <br />
                </>
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;{contact.client.zipcode}{" "}
              {contact.client.city}
            </span>
          </p>
        </>
      )}
    </div>
  );
}

export function ContactOption({
  value,
  customTooltip,
  keyName = "name",
  fontSize = "14px",
  withLabel = false,
}) {
  const [hoveredContact, setHoveredContact] = useState(null);
  const element = (v) => (
    <StyledSpan fontSize={fontSize}>{v[keyName]}</StyledSpan>
  );

  const handleMouseEnter = () => {
    setHoveredContact(value);
  };

  const handleMouseLeave = () => {
    setHoveredContact(null);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {customTooltip ? (
        <Tooltip title={value[keyName]} mouseLeaveDelay={0}>
          {element(value)}
        </Tooltip>
      ) : (
        element(value)
      )}
      {withLabel && (
        <span>
          {value.last_name} {value.first_name}
        </span>
      )}
      <Popover
        content={<ContactInfo contact={hoveredContact} />}
        title={value.name}
        trigger="hover"
        overlayStyle={{ zIndex: 9999 }}
        mouseEnterDelay={0}
        mouseLeaveDelay={0}
      >
        <EyeOutlined style={{ marginLeft: 5, zIndex: 9999 }} />
      </Popover>
    </div>
  );
}

function SelectContacts({
  options,
  keyName,
  className,
  fontSize,
  mode,
  keysToSearch,
  customTooltip,
  disabled,
  customContent = null,
  ...selectProps
}) {
  return (
    <>
      <StyledSelect
        fontSize={fontSize}
        className={className}
        showSearch
        disabled={disabled}
        defaultActiveFirstOption={false}
        filterOption={(search, option) =>
          filterOption(
            search,
            option,
            keysToSearch.length > 0 ? keysToSearch : [keyName]
          )
        }
        mode={mode}
        dropdownStyle={{
          fontSize,
        }}
        {...selectProps}
      >
        {options?.map((value) =>
          value.options ? (
            <AntdSelect.OptGroup key={value[keyName]}>
              {value.options?.map((el) => {
                return (
                  <AntdSelect.Option {...value} key={value.id} value={value.id}>
                    <ContactOption
                      value={el}
                      customTooltip={customTooltip}
                      keyName={keyName}
                      fontSize={fontSize}
                    />
                  </AntdSelect.Option>
                );
              })}
            </AntdSelect.OptGroup>
          ) : (
            <AntdSelect.Option {...value} key={value.id} value={value.id}>
              <ContactOption
                value={value}
                customTooltip={customTooltip}
                keyName={keyName}
                fontSize={fontSize}
              />
            </AntdSelect.Option>
          )
        )}
      </StyledSelect>
      {customContent && <StyledText>{customContent}</StyledText>}
    </>
  );
}

const StyledSelect = styled(AntdSelect)`
  width: 100%;
  .ant-select-arrow-loading {
    color: #5499de !important;
    font-size: 18px;
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder,
  .ant-select-item-option-content {
    font-size: ${({ fontSize }) => `${fontSize} !important`};
    color: rgba(0, 0, 0, 0.88) !important;
  }
  ${({ allowClear }) =>
    allowClear &&
    css`
      &:hover {
        .ant-select-arrow {
          opacity: 0;
        }
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      .ant-select-selection-item {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}

  .ant-select-clear {
    background-color: inherit;
    color: #f1556c;
  }
  .ant-select-dropdown .ant-select-item {
    font-size: ${({ fontSize }) => `${fontSize} !important`};
  }
  input {
    font-size: ${({ fontSize }) => `${fontSize} !important`};
  }
`;

const StyledSpan = styled.span`
  font-size: ${({ fontSize }) => `${fontSize} !important`};
  width: 100%;
`;

const StyledText = styled.p`
  margin: 0;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #5499de;
`;

SelectContacts.propTypes = {
  options: array,
  keyName: string,
  className: string,
  fontSize: string,
  mode: string,
  keysToSearch: array,
  disabled: bool,
  customTooltip: bool,
  customContent: oneOfType([string, node]),
};

SelectContacts.defaultProps = {
  options: [],
  keyName: "name",
  className: "",
  fontSize: "14px",
  mode: "",
  keysToSearch: [],
  disabled: false,
  customTooltip: false,
  customContent: null,
};

export default SelectContacts;
