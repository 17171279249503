import React, { useMemo } from "react";
import Form from "../../../Form";

function GroupingForm({
  isShowing,
  onSubmit,
  updateOnChange,
  submit,
  initialGrouping,
  vocabulary,
}) {
  const formFactory = useMemo(() => {
    const firstRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: `Nom du ${vocabulary.grouping_lowercase}`,
          },
          name: "name",
          label: "Intitulé",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const secondRow = [
      {
        type: "textarea",
        item: {
          textarea: {
            placeholder: `Description du ${vocabulary.grouping_lowercase}`,
          },
          name: "description",
          label: "Description",
        },
      },
    ];

    const thirdRow = [
      {
        type: "checkbox",
        item: {
          checkbox: {},
          name: "zone_link",
          label: "Lier à une zone",
        },
      },
      {
        type: "input",
        item: {
          input: {
            placeholder: ``,
          },
          name: "position",
          label: "Position",
        },
      },
    ];

    return [
      { columns: firstRow, key: "name" },
      { columns: secondRow, key: "description" },
      { columns: thirdRow, key: "zone_link" },
    ];
  }, []);

  const initialValues = useMemo(() => {
    if (!initialGrouping) return {};

    return {
      name: initialGrouping.name,
      description: initialGrouping.description,
      zone_link: initialGrouping.zone_link,
      position: initialGrouping.position,
    };
  }, [initialGrouping]);
  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={formFactory}
      isShowing={isShowing}
      id="grouping-form"
    />
  );
}

export default GroupingForm;
