/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { debounce } from "lodash";
import Search from "antd/lib/input/Search";
import OfferForm from "./offerForm";
import TitleContainer from "../../react-ui/TitleContainer";
import ImportButton from "../../react-ui/ImportButton";
import DocumentForm from "../../Admin-cf/DocumentsFolders/Documents/DocumentForm";
import { getData, postData } from "../../request/instance";
import { fetchDocumentsUrl } from "../../../utils/fetchDocumentsUrl";
import { useStore } from "../../store";
import { createFile } from "../../../utils/createFile";
import DocumentsArray from "../../Admin-cf/DocumentsFolders/Documents/DocumentsArray";
import DocSaModal from "../../documents-and-simplified/DocSaModal";
import AddButton from "../../react-ui/AddButton";

const selector = (state) => ({
  formToken: state.formToken,
});
const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function OfferInformations({
  offer,
  token,
  offer_id,
  configApplication,
  supervisor,
  siteTypologies,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const [status, setStatus] = useState("empty");
  const [wordEntered, setWordEntered] = useState("");
  const [selectedDocument, setSelectedDocument] = useState();
  const [mutationIsLoading, setMutationIsLoading] = useState(false);

  const {
    data: documents,
    refetch,
    isLoading: documentsAreLoading,
  } = useQuery(
    ["Documents", { offer_id }],
    () =>
      getData(formToken, fetchDocumentsUrl({ search: wordEntered, offer_id })),
    { refetchOnWindowFocus: false }
  );

  const { mutate: createDocument } = useMutation((todo) =>
    postData(formToken, "/document/create", todo)
  );

  const { mutate: updateDocument, isLoading: updateIsLoading } = useMutation(
    (todo) => postData(formToken, "/document/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries(["Documents", { offer_id }]);
        closeModal();
      },
    }
  );

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  const openModal = () => {
    setStatus("create");
  };
  const closeModal = () => {
    setStatus("empty");
    setSelectedDocument();
  };

  const appendDocumentToForm = ({ formData, values, file }) => {
    const keyName = status === "create" ? "documents[]" : "document";
    formData.append(`${keyName}[name]`, file.name);
    if (file.originFileObj) {
      formData.append(
        `${keyName}[file]`,
        createFile([file.originFileObj], file.name, {
          type: file.type,
        })
      );
    }
    formData.append(`${keyName}[description]`, values.description || "");
    formData.append(`${keyName}[offer_id]`, offer_id);
  };

  const handleSubmit = (values) => {
    let formData = new FormData();

    if (status === "create") {
      setMutationIsLoading(true);
      let nbMB = 0;
      values.files.forEach((file) => {
        const fileSize = Math.ceil(file.size / 1024 / 1024);
        if (fileSize >= 100) return;
        if (nbMB + fileSize >= 100) {
          nbMB = 0;
          createDocument(formData);
          formData = new FormData();
        }
        nbMB += fileSize;
        appendDocumentToForm({ formData, values, file });
      });
      createDocument(formData, {
        onSettled: () => {
          setMutationIsLoading(false);
          queryClient.invalidateQueries(["Documents", { offer_id }]);
          closeModal();
        },
      });
    }
    if (status === "update") {
      const fileSize = Math.ceil(values.file[0].size / 1024 / 1024);
      if (fileSize >= 100) return;
      appendDocumentToForm({ formData, values, file: values.file[0] });
      formData.append("id", selectedDocument.id);
      updateDocument(formData);
    }
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const initialValues = useMemo(() => {
    if (status !== "update" || !selectedDocument) return undefined;

    return {
      ...selectedDocument,
      works: [],
      entities: [],
      folders: [],
      typologies: [],
      file: [
        {
          uid: "1",
          name: selectedDocument.name,
          status: "done",
          url: selectedDocument.file,
        },
      ],
    };
  }, [selectedDocument, status]);

  const opportunity_ids = useMemo(() => {
    return (
      offer?.opportunities?.filter((el) => el.pipe).map((opp) => opp.id) || []
    );
  }, [offer]);

  const opportunity_names = useMemo(() => {
    return (
      offer?.opportunities?.filter((el) => el.pipe).map((opp) => opp.chrono) ||
      []
    );
  }, [offer]);

  return (
    <div>
      {offer.documents && (
        <DocSaModal
          opportunity_ids={opportunity_ids}
          formToken={formToken}
          closeModal={closeModal}
          isOpen={status !== "empty"}
          siteTypologies={siteTypologies}
          opportunity_names={opportunity_names}
          offer_name={offer.offer?.name}
          offer_id={offer_id}
          supervisor={supervisor}
          fromOffer
        />
      )}
      <StyledTitleContainer label="Informations" />

      <Container>
        <OfferForm
          offer={offer}
          token={token}
          offer_id={offer_id}
          configApplication={configApplication}
          openModal={openModal}
          supervisor={supervisor}
        />
      </Container>
    </div>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const StyledTitleContainer = styled(TitleContainer)`
  margin: 0;
  display: flex;
  justify-content: center;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 300px;
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

const StyledSearch = styled(Search)`
  margin: 5px 0;
`;

export default OfferInformations;
