import { Button, Form, Popover } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";
import FormCol from "../../Form/FormCol";
import FormRow from "../../Form/FormRow";
import Input from "../../react-ui/Input";
import { validateMessages } from "../../constant";
import { stringToFloat } from "../../../utils/stringToFloat";
import { formatNumberString } from "../../../utils/formatNumberString";

function PeriodForm({
  period,
  prevPeriod,
  nextPeriod,
  idx,
  handlePeriodChange,
  setSelectedPeriod,
}) {
  const [form] = Form.useForm();
  const actualPeriod = Form.useWatch("period", form);
  const handleSubmit = (e) => {
    setSelectedPeriod(undefined);
    handlePeriodChange(stringToFloat(e.period), idx);
  };

  const initialValues = useMemo(
    () => ({
      period,
    }),
    [period]
  );
  return (
    <Form
      id={`period-form-${idx}`}
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
      colon={false}
      requiredMark={false}
      validateMessages={validateMessages}
    >
      <FormWrapper onMouseLeave={() => setSelectedPeriod(undefined)}>
        <FormRow>
          <FormCol
            label="Valeur de fin"
            rules={[
              { required: true },
              {
                validator: () => {
                  const actualPeriodFloat = stringToFloat(actualPeriod);
                  if (actualPeriodFloat <= 0) return Promise.resolve();
                  if (prevPeriod && actualPeriodFloat <= prevPeriod + 1)
                    return Promise.reject(
                      new Error(
                        `La valeur ne peut pas être inférieure ou égale à ${
                          prevPeriod + 1
                        }`
                      )
                    );
                  if (nextPeriod && actualPeriodFloat >= nextPeriod)
                    return Promise.reject(
                      new Error(
                        `La valeur ne peut pas être supérieure ou égale à ${nextPeriod}`
                      )
                    );
                  return Promise.resolve();
                },
              },
            ]}
            name="period"
          >
            <Input
              placeholder="Valeur de fin"
              autocomplete="off"
              onInput={(e) => {
                e.currentTarget.value = formatNumberString({
                  str: e.currentTarget.value,
                  nbDecimal: 0,
                });
              }}
            />
          </FormCol>
        </FormRow>
        <FormRow marginTop="auto" justify="flex-end">
          <Button type="default" onClick={() => setSelectedPeriod(undefined)}>
            Annuler
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: "5px" }}
            // loading={loading}
          >
            Enregistrer
          </Button>
        </FormRow>
      </FormWrapper>
    </Form>
  );
}

function PeriodHeader({
  title,
  period,
  prevPeriod,
  nextPeriod,
  idx,
  handlePeriodChange,
  handlePeriodDelete,
  isDeletable,
  selectedPeriod,
  setSelectedPeriod,
}) {
  const handleClickChange = (open) => {
    setSelectedPeriod(open ? idx : undefined);
  };

  const handleDelete = () => {
    setSelectedPeriod(undefined);
    handlePeriodDelete(idx);
  };

  return (
    <Popover
      destroyTooltipOnHide
      trigger="click"
      open={selectedPeriod === idx}
      onOpenChange={handleClickChange}
      title={
        <TitleWrapper>
          Modifier la période
          {isDeletable && (
            <DeleteText onClick={handleDelete}>Supprimer la période</DeleteText>
          )}
        </TitleWrapper>
      }
      content={
        <PeriodForm
          period={period}
          prevPeriod={prevPeriod}
          nextPeriod={nextPeriod}
          idx={idx}
          handlePeriodChange={handlePeriodChange}
          setSelectedPeriod={setSelectedPeriod}
        />
      }
    >
      <Header>
        {title}
        <Icon className="fa-duotone fa-pencil-square-o" />
      </Header>
    </Popover>
  );
}

const Header = styled.div`
  width: 100%;
  min-height: 1.6em;
  cursor: pointer;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DeleteText = styled.span`
  font-size: 12px;
  color: red;
  cursor: pointer;
  font-weight: normal;
  text-decoration: underline;
`;

const Icon = styled.i`
  margin-left: 5px;
`;

const FormWrapper = styled.div`
  width: 300px;
  height: 140px;
  display: flex;
  flex-direction: column;
`;

export default PeriodHeader;
