import { encodeUrlParams } from "./encodeUrlParams";

export const fetchFoldersUrl = ({ search }) => {
  let url = "/folders?";
  const params = {
    ...(search && { search }),
  };

  url += encodeUrlParams(params);

  return url;
};
