export const otherCostArray = [
  {
    title: "Hôtel",
    id: "Hotel",
  },

  {
    title: "Restaurant",
    id: "Restaurant",
  },

  {
    title: "Forfait",
    id: "Forfait",
  },
];
