import React, { useRef, useMemo } from "react";
import Form from "../../Form";
import { FormFactory } from "./OuvrageStructure/OuvrageFormFactory";
import { InitialValues } from "./OuvrageStructure/OuvrageInitialValues";
import {
  itemsToCascader,
  singleItemToCascader,
} from "../../../utils/itemsForCascader";

function OuvrageForm({
  ouvrageDetailsStatus,
  isUsingMargin,
  onSubmit,
  updateOnChange,
  isShowing,
  faIsValidate,
  configuration,
  initialOuvrage,
  from,
  submit,
  vocabulary,
  calculMethod,
  unities,
  categories,
  id,
  opportunityType = null,
  group,
}) {
  const cost = useRef();
  const showFAFields = useMemo(
    () =>
      (from === "referencing" || from === "frameworkAgreement") &&
      (updateOnChange || isShowing),
    [from, updateOnChange, isShowing]
  );
  return (
    <div>
      <Form
        submit={submit}
        updateOnChange={updateOnChange}
        initialValues={InitialValues({
          isUsingMargin,
          initialOuvrage,
          from,
          calculMethod,
          categories,
          singleItemToCascader,
          opportunityType,
        })}
        onSubmit={onSubmit}
        rows={FormFactory({
          configuration,
          cost,
          updateOnChange,
          vocabulary,
          isUsingMargin,
          faIsValidate,
          unities,
          showFAFields,
          calculMethod,
          from,
          ouvrageDetailsStatus,
          categories,
          itemsToCascader,
          group,
        })}
        isShowing={isShowing}
        id="ouvrage-form"
        ref={cost}
      />
    </div>
  );
}

export default OuvrageForm;
