// add a grey background to the odd div in the each
$(document).ready(() => {
  const indexFormationAdmin = document.getElementById("formations")
  if(!indexFormationAdmin){
    return
  }
  const addBackGround = () => {
    const formationsCardBoxs = $("#formations div.formation:even");
    if (formationsCardBoxs) {
      $.each(formationsCardBoxs, function (index, value) {
        $(this).addClass("greylight-background-odd");
      });
    }
  }
  addBackGround()



// add background to the row when we click on one formation

const formationsAdmin =  document.querySelectorAll(".formation");
if (formationsAdmin) {
  const removeBackground = () => {
    formationsAdmin.forEach(formation => {
      formation.classList.remove("grey-background");
    })
  }

  const addBackground = (event) => {
    removeBackground()
    event.currentTarget.classList.add("grey-background");
  }

  formationsAdmin.forEach(formation => {
    formation.addEventListener("click", addBackground)
  })
}
});
