import { TreeSelect } from "antd";
import { debounce } from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import { fetchClientsUrl } from "../../../../utils/fetchClientsUrl";

const debouncedSearch = debounce(({ fetchNextPage, nextPage }) => {
  // eslint-disable-next-line no-param-reassign
  nextPage.current = 1;
  fetchNextPage({ pageParam: nextPage.current });
}, 300);

function ClientsSelect({
  selectedClientId,
  isShowing,
  clients,
  setClients,
  clientsInMemory,
  setClientsInMemory,
  value,
  formatClients,
  selectorId = ["Clients", { dropdown: true }],
  onSelect,
  onBlur,
  fromOpportunity,
  vocabulary,
  ...props
}) {
  // eslint-disable-next-line no-shadow
  const formToken = useStore(({ formToken }) => formToken);
  const [wordEntered, setWordEntered] = useState("");
  const nextPage = useRef(1);

  useEffect(() => {
    setClientsInMemory((olds) =>
      [...clients, ...olds].filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      )
    );
  }, [clients, setClientsInMemory]);

  const { fetchNextPage, isFetching } = useInfiniteQuery(
    selectorId,
    () =>
      getData(
        formToken,
        fetchClientsUrl({
          search: wordEntered,
          sortBy: { field: "name", order: "ASC" },
          nextPage: nextPage.current,
          fromOpportunity,
        })
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (payload) => {
        const lastPage = payload.pages[payload.pages.length - 1];

        if (lastPage.current_page === 1) {
          setClients(lastPage.clients);
        } else
          setClients((old) =>
            [...old, ...lastPage.clients].filter(
              (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
            )
          );
        nextPage.current = lastPage.next_page;
      },
    }
  );

  useEffect(() => {
    debouncedSearch({ fetchNextPage, nextPage });
  }, [wordEntered, fetchNextPage]);

  const treeData = useMemo(() => {
    if (!clients) return [];
    if (formatClients) return formatClients(clients);
    const clientIsClientChild = (client) => {
      if (!selectedClientId) return false;
      if (
        client.id === selectedClientId ||
        client.parent_id === selectedClientId
      )
        return true;
      const parent = clients.find((c) => c.id === client.parent_id);
      if (!parent) return false;
      return clientIsClientChild(parent);
    };
    return clients.map((el) => ({
      id: el.id,
      pId: el.parent_id,
      title: el.name,
      value: el.id,
      disabled: clientIsClientChild(el),
    }));
  }, [clients, selectedClientId, formatClients]);

  const onScrollCapture = (event) => {
    const perc =
      (event.target.scrollTop /
        (event.target.scrollHeight - event.target.clientHeight)) *
      100;
    if (perc >= 95 && !isFetching && nextPage.current !== null) {
      fetchNextPage({ pageParam: nextPage.current });
    }
  };

  const renderDropDown = (menu) => {
    return <div onScrollCapture={onScrollCapture}>{menu}</div>;
  };

  const valueWithLabel = useMemo(() => {
    if (!value) return value;
    if (typeof value === "object") {
      if (value.title) {
        return { value: value.value, label: value.title };
      }
      return value;
    }
    const cl = clientsInMemory.find((c) => c.id === value);
    if (!cl) return undefined;
    return { label: cl.name, value: cl.id };
  }, [clientsInMemory, value]);

  const onSelectHandler = (v) => {
    if (onSelect) onSelect(v, clientsInMemory);
  };

  const onBlurHandler = (v) => {
    setWordEntered("");
    if (onBlur) onBlur(v, clientsInMemory);
  };

  return (
    <TreeSelect
      treeDefaultExpandAll
      treeData={treeData}
      treeDataSimpleMode
      placeholder="Sélectionnez"
      filterTreeNode={() => true}
      showSearch
      allowClear
      disabled={isShowing}
      listHeight={300}
      loading={isFetching}
      onSearch={(v) => setWordEntered(v)}
      dropdownRender={renderDropDown}
      labelInValue
      value={valueWithLabel}
      onSelect={onSelectHandler}
      onBlur={onBlurHandler}
      {...props}
    />
  );
}

export default ClientsSelect;
