import React, { useMemo, useRef } from "react";
import { array, bool, func, number, object, shape, string } from "prop-types";
import moment from "moment";
import { currency_cents_to_euros, getUrlParams } from "../../utils";
import Form from "../../Form";
import {
  itemsToCascader,
  singleItemToCascader,
} from "../../../utils/itemsForCascader";
import { formatNumberString } from "../../../utils/formatNumberString";
import { formulaMarginRate } from "../../../utils/formulaMarginRate";
import { stringToFloat } from "../../../utils/stringToFloat";
import { marginToCoeff } from "../../../utils/marginConverter";
import { useStore } from "../../store";
import { simple_default_configuration } from "../../../simple_default";
import MarginItem from "../../react-ui/MarginItem";
import { useMarginItem } from "../../hooks/useMarginItem";
import { formulaSellingPrice } from "../../../utils/formulaSellingPrice";
import { fetchDefaultMarginrate } from "../../../utils/fetchDefaultMarginrate";
import { getData } from "../../request/instance";
import TvaSelector from "../../react-ui/TvaSelector";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
  formToken: state.formToken,
});
function MaterialsForm({
  isShowing,
  categories,
  unities,
  onSubmit,
  updateOnChange,
  submit,
  initialMaterial,
  manufacturers,
  distributors,
  from,
  faIsValidate,
  hasMaterialIncluded,
  entity_work_name,
  configuration,
  vocabulary,
  opportunityId,
  entityWorkId,
  tva_professions,
  use_special_tva,
}) {
  const cost = useRef();
  const showFAFields = useMemo(
    () =>
      (from === "referencing" || from === "frameworkAgreement") &&
      (updateOnChange || isShowing),
    [from, updateOnChange, isShowing]
  );
  const { isUsingMargin, calculMethod, formToken } = useStore(selector);
  const {
    hasMargin,
    hasTVA,
    setHasTVA,
    setHasMargin,
    categoryId,
    setCategoryId,
  } = useMarginItem({
    initial: initialMaterial,
    categories,
  });

  const formFactory = useMemo(() => {
    const formatedUnities = unities.map((unity) => {
      const formatedUnity = {
        title: unity.name,
        ...unity,
      };
      return formatedUnity;
    });
    const firstRow = [
      {
        type: "multiple",
        items: [
          showFAFields &&
            updateOnChange && {
              type: "checkbox",
              item: {
                checkbox: {},
                name: "is_in_framework_agreement",
                label: "Intégrer à l'accord-cadre",
                disabled: from === "frameworkAgreement" && !faIsValidate,
              },
            },
          {
            type: "input",
            item: {
              input: {
                placeholder: `Nom du ${vocabulary.product_lowercase}`,
                disabled: showFAFields,
              },
              name: "name",
              label: "Intitulé",
              rules: [
                {
                  required: true,
                  message: "Champ obligatoire.",
                },
              ],
            },
          },
          {
            type: "input",
            item: {
              input: {
                placeholder: `Référence ${vocabulary.product_lowercase}`,
                disabled: showFAFields,
              },
              name: "reference_code",
              label: "Code article",
            },
          },
          {
            type: "input",
            item: {
              input: {
                placeholder: `100`,
                disabled: showFAFields,
              },
              name: "quotation_position",
              label: "Positionnement devis",
            },
          },
        ],
      },

      {
        type: "upload",
        item: {
          upload: {
            listType: "picture-card",
            maxCount: 1,
            accept: "image/*",
          },
          name: "picture",
        },
        style: { display: "flex", justifyContent: "flex-end" },
      },
    ];

    const matOppName = [
      {
        type: "input",
        item: {
          input: {
            placeholder: `Nom du ${vocabulary.product_lowercase}`,
            disabled: !hasMaterialIncluded,
          },
          name: "material_included_name",
          label: "Intitulé accord-cadre",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const matOppRefCode = [
      {
        type: "input",
        item: {
          input: {
            placeholder: `Référence ${vocabulary.product_lowercase}`,
            disabled: !hasMaterialIncluded,
          },
          name: "material_included_reference_code",
          label: "Code article accord-cadre",
        },
      },
    ];

    const secondRow = [
      {
        type: "cascader",
        item: {
          cascader: {
            placeholder: "Choisissez une catégorie",
            changeOnSelect: true,
            expandTrigger: "hover",
            allowClear: false,
            disabled: showFAFields,
            onChange: (value) => {
              if (value && value[0])
                setCategoryId(categories?.find((el) => el.id === value[0])?.id);
            },
          },
          options: itemsToCascader({
            items: categories,
            keyParent: "parent_category_id",
            keyName: "title",
          }),
          name: "category_profession_id",
          label: "Catégorie",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const descriptionRemarkRow = [
      {
        type: "textarea",
        item: {
          textarea: {
            placeholder: "Ajouter une description...",
            disabled: showFAFields && !hasMaterialIncluded,
          },
          name: "description",
          label: "Description",
        },
      },
      {
        type: "textarea",
        item: {
          textarea: {
            placeholder: "Ajouter une remarque...",
            disabled: showFAFields,
          },
          name: "remark",
          label: "Remarque",
        },
      },
    ];

    const fourthRow = [
      {
        type: "upload",
        item: {
          upload: {
            maxCount: 1,
            disabled: showFAFields && !hasMaterialIncluded,
          },
          singleLine: true,
          text: "",
          name: "drive_link",
          label: "Fiche produit",
        },
      },
    ];

    const fifthRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            suffix: "€",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 2,
              });
            },
            onFocus: (e) => {
              cost.current?.setFieldsValue({
                cost_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              });
            },
            onBlur: (e) => {
              cost.current?.setFieldsValue({
                cost_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
            disabled: showFAFields,
          },
          name: "cost_price_cents",
          label: "Prix d'achat / Coût de revient",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      configuration.admin.product_tab.form.input_margin_rate && {
        type: "input",
        item: {
          input: {
            disabled: showFAFields,
            customContent: (
              <MarginItem
                hasMargin={hasMargin}
                categoryId={categoryId}
                entityWorkId={entityWorkId}
                opportunityId={opportunityId}
                itemType="material_profession"
              />
            ),
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                isCoeff: !isUsingMargin,
              });
              setHasMargin(e.currentTarget.value !== "");
            },
          },
          isMarginRate: true,
          name: "margin_rate",
        },
      },
      // {
      //   type: "inputNumber",
      //   item: {
      //     inputNumber: {
      //       placeholder: "1",
      //       disabled: showFAFields && !hasMaterialIncluded,
      //     },
      //     name: "material_position",
      //     label: "Position du matériel",
      //   },
      // },
    ];

    const costPriceRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            suffix: "€",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 2,
              });
            },
            onFocus: (e) => {
              cost.current?.setFieldsValue({
                cost_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              });
            },
            onBlur: (e) => {
              cost.current?.setFieldsValue({
                cost_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
            disabled: showFAFields,
          },
          name: "cost_price_cents",
          label: "Prix d'achat / Coût de revient",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const marginRow = [
      configuration.admin.product_tab.form.input_margin_rate && {
        type: "input",
        item: {
          input: {
            disabled: showFAFields,
            customContent: (
              <MarginItem
                hasMargin={hasMargin}
                categoryId={categoryId}
                entityWorkId={entityWorkId}
                opportunityId={opportunityId}
                itemType="material_profession"
              />
            ),
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                isCoeff: !isUsingMargin,
              });
              setHasMargin(e.currentTarget.value !== "");
            },
          },
          isMarginRate: true,
          name: "margin_rate",
        },
      },
    ];

    const sellingPriceAndFAMarginRate = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            suffix: "€",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 2,
              });
              cost.current?.setFieldsValue({
                margin_rate_computed: formatNumberString({
                  str: formulaMarginRate({
                    costPrice: stringToFloat(
                      cost.current?.form.getFieldValue("cost_price_cents")
                    ),
                    calculMethod,
                    sellingPrice: stringToFloat(e.currentTarget.value),
                    isUsingMargin,
                  }),
                  isCoeff: !isUsingMargin,
                  space: true,
                }),
              });
            },
            onFocus: (e) => {
              cost.current?.setFieldsValue({
                selling_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              });
            },
            onBlur: (e) => {
              cost.current?.setFieldsValue({
                selling_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
            disabled: !hasMaterialIncluded,
          },
          name: "selling_price_cents",
          label: "Prix de vente HT",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            disabled: true,
          },
          name: "margin_rate_computed",
          isComputed: true,
          isMarginRate: true,
        },
      },
    ];

    const sixthRow = [
      {
        type: "input",
        item: {
          input: {
            disabled: true,
            suffix: "€",
          },
          label: "Prix de vente HT calculé",
          name: "selling_price_computed",
          isComputed: true,
          isMarginRate: true,
        },
      },
      use_special_tva && {
        type: "select",
        span: 12,
        item: {
          select: {
            onChange: (e) => {
              setHasTVA(e !== "none" && e !== undefined);
            },
            allowClear: true,
            customContent: (
              <TvaSelector
                hasTVA={hasTVA}
                itemType="material_profession"
                entityWorkId={getUrlParams()}
                categoryId={
                  categoryId || initialMaterial?.category_profession_id
                }
                tvaProfessionId={initialMaterial?.tva_profession_id}
              />
            ),
          },
          options: tva_professions,
          keyName: "name",
          name: "tva_profession_id",
          label: "TVA spéciale",
        },
      },
    ];

    const seventhRow = [
      {
        type: "select",
        item: {
          select: {
            // eslint-disable-next-line prettier/prettier
            placeholder: "unité",
            disabled: showFAFields,
            allowClear: true,
          },
          options: [...formatedUnities, { title: "unité" }],
          name: "unity_profession_id",
          label: "Unité de quantité",
        },
      },
      configuration.admin.product_tab.form.input_date_validity && {
        type: "datePicker",
        item: {
          datePicker: {
            placeholder: "Choisir une date",
            disabled: showFAFields,
          },
          name: "date_validity",
          label: "Date de validité",
        },
      },
    ];

    const eigthRow = [
      configuration.admin.product_tab.form.input_manufacturer_id && {
        type: "select",
        item: {
          select: {
            placeholder: "Choisissez un fabricant",
            allowClear: true,
            disabled: showFAFields,
          },
          options: manufacturers,
          keyName: "name",
          name: "manufacturer_id",
          label: "Fabricant",
        },
      },
      configuration.admin.product_tab.form.input_reference_constructor && {
        type: "input",
        item: {
          input: {
            placeholder: "Référence constructeur",
            disabled: showFAFields,
          },
          name: "reference_constructor",
          label: "Référence constructeur",
        },
      },
    ];

    const distributorRow = [
      configuration.admin.product_tab.form.input_distributor_id && {
        type: "select",
        item: {
          select: {
            placeholder: "Choisissez un distributeur",
            disabled: showFAFields,
            mode: "multiple",
          },
          options: distributors,
          keyName: "name",
          name: "distributor_id",
          label: "Distributeurs",
        },
      },
    ];

    return [
      { columns: firstRow, key: "name+picture" },
      showFAFields && {
        columns: matOppName,
        key: "matOppName",
      },
      showFAFields && {
        columns: matOppRefCode,
        key: "matOppRefCode",
      },
      { columns: secondRow, key: "category_profession_id" },
      { columns: descriptionRemarkRow, key: "descriptionRemarkRow" },
      showFAFields &&
        from !== "referencing" && {
          columns: sellingPriceAndFAMarginRate,
          key: "sellingPriceAndFAMarginRate",
        },
      { columns: fourthRow, key: "drive_link+margin_rate" },
      !isShowing && {
        columns: fifthRow,
        key: "cost_price_cents+material_position",
      },
      isShowing &&
        configuration.costing.page_1.table_col_price &&
        configuration.costing.page_3.show_margin && {
          columns: fifthRow,
          key: "cost_price_cents+material_position",
        },
      isShowing &&
        configuration.costing.page_1.table_col_price &&
        !configuration.costing.page_3.show_margin && {
          columns: costPriceRow,
          key: "cost_price_cents+material_position",
        },
      isShowing &&
        !configuration.costing.page_1.table_col_price &&
        configuration.costing.page_3.show_margin && {
          columns: marginRow,
          key: "cost_price_cents+material_position",
        },

      !showFAFields && {
        columns: sixthRow,
        key: "selling_price_computed+tva_profession_id",
      },
      { columns: seventhRow, key: "unity+date" },
      { columns: eigthRow, key: "manufacturer_id+distributor_id" },
      { columns: distributorRow, key: "distributorRow" },
    ];
  }, [
    categories,
    unities,
    manufacturers,
    showFAFields,
    hasMaterialIncluded,
    distributors,
    faIsValidate,
    from,
    updateOnChange,
    calculMethod,
    isUsingMargin,
    hasMargin,
    hasTVA,
    setHasTVA,
    categoryId,
    entityWorkId,
    opportunityId,
    setHasMargin,
    setCategoryId,
  ]);

  const onValuesChange = async (changedValues) => {
    if (changedValues.category_profession_id && cost.current) {
      const data = await getData(
        formToken,
        fetchDefaultMarginrate({
          itemType: "material_profession",
          opportunityId,
          entityWorkId,
          categoryId: changedValues.category_profession_id,
        })
      );
      cost.current?.setFieldsValue({
        selling_price_computed: formatNumberString({
          str: formulaSellingPrice({
            costPrice:
              cost.current?.form.getFieldValue("cost_price_cents") ===
                undefined ||
              cost.current?.form.getFieldValue("cost_price_cents").length === 0
                ? 0
                : stringToFloat(
                    cost.current?.form.getFieldValue("cost_price_cents")
                  ) * 100,
            marginRate:
              cost.current?.form.getFieldValue("margin_rate") === undefined ||
              cost.current?.form.getFieldValue("margin_rate").length === 0
                ? "undefined"
                : cost.current?.form.getFieldValue("margin_rate"),
            defaultMarginRate: data.margin_rate,
            calculMethod,
            isCoeff: !isUsingMargin,
          }),
          isCoeff: !isUsingMargin,
          space: true,
        }),
      });
    }
    if ("margin_rate" in changedValues && cost.current) {
      const data = await getData(
        formToken,
        fetchDefaultMarginrate({
          itemType: "material_profession",
          opportunityId,
          entityWorkId,
          categoryId,
        })
      );
      cost.current?.setFieldsValue({
        selling_price_computed: formatNumberString({
          str: formulaSellingPrice({
            costPrice:
              stringToFloat(
                cost.current?.form.getFieldValue("cost_price_cents")
              ) * 100,
            marginRate:
              changedValues.margin_rate.length === 0
                ? "undefined"
                : stringToFloat(
                    cost.current?.form.getFieldValue("margin_rate")
                  ),
            defaultMarginRate: data.margin_rate,
            calculMethod,
            isCoeff: !isUsingMargin,
          }),
          isCoeff: !isUsingMargin,
          space: true,
        }),
      });
    }
    if ("cost_price_cents" in changedValues && cost.current) {
      const data = await getData(
        formToken,
        fetchDefaultMarginrate({
          itemType: "material_profession",
          opportunityId,
          entityWorkId,
          categoryId,
        })
      );
      cost.current?.setFieldsValue({
        selling_price_computed: formatNumberString({
          str: formulaSellingPrice({
            costPrice: stringToFloat(changedValues.cost_price_cents) * 100,
            marginRate:
              typeof cost.current?.form.getFieldValue("margin_rate") ===
                "undefined" ||
              cost.current?.form.getFieldValue("margin_rate").length === 0
                ? "undefined"
                : stringToFloat(
                    cost.current?.form.getFieldValue("margin_rate")
                  ),
            defaultMarginRate: data.margin_rate,
            calculMethod,
            isCoeff: !isUsingMargin,
          }),
          isCoeff: !isUsingMargin,
          space: true,
        }),
      });
    }
  };

  const initialValues = useMemo(() => {
    if (!initialMaterial) return {};
    const margin_rate = formatNumberString({
      str: marginToCoeff({
        marginRate: initialMaterial.margin_rate,
        isUsingMargin,
        calculMethod,
      }),
      isCoeff: !isUsingMargin,
    });
    const date_validity = initialMaterial.date_validity
      ? moment(initialMaterial.date_validity)
      : undefined;
    const picture = initialMaterial.picture
      ? [
          {
            uid: "1",
            name: "image",
            status: "done",
            url: initialMaterial.picture,
          },
        ]
      : [];
    const originalDriveLink = !hasMaterialIncluded
      ? initialMaterial.drive_link
      : initialMaterial.material_included?.drive_link;
    const drive_link = originalDriveLink
      ? [
          {
            uid: "1",
            name: !hasMaterialIncluded
              ? initialMaterial.drive_link_name
              : initialMaterial.material_included?.drive_link_name,
            status: "done",
            url: originalDriveLink,
          },
        ]
      : [];
    return {
      ...initialMaterial,
      name: initialMaterial.name,
      tva_profession_id: initialMaterial.tva_profession_id,
      description: !hasMaterialIncluded
        ? initialMaterial.description
        : initialMaterial.material_included?.description,
      margin_rate,
      cost_price_cents: formatNumberString({
        str: currency_cents_to_euros(initialMaterial.cost_price_cents),
        nbDecimal: 2,
        space: true,
      }),
      category_profession_id: !initialMaterial.category_profession.import_csv
        ? singleItemToCascader({
            childItem: categories?.find(
              (el) => el.id === initialMaterial.category_profession_id
            ),
            items: categories,
            keyParent: "parent_category_id",
          })
        : "Import CSV",
      reference_code: initialMaterial.reference_code,
      unity_profession_id: initialMaterial.unity_profession_id,
      date_validity,
      drive_link,
      material_position: !hasMaterialIncluded
        ? initialMaterial.material_position
        : initialMaterial.material_included?.material_position,
      manufacturer_id: initialMaterial.manufacturer_id,
      distributor_id: initialMaterial.distributors.map((distributor) => {
        return distributor;
      }),
      picture,
      material_included_name:
        initialMaterial.material_included?.name || initialMaterial.name,
      material_included_reference_code:
        initialMaterial.material_included?.reference_code ||
        initialMaterial.reference_code,
      is_in_framework_agreement: initialMaterial.is_in_framework_agreement,
      selling_price_cents: !hasMaterialIncluded
        ? formatNumberString({
            str: currency_cents_to_euros(initialMaterial.cost_price_cents),
            nbDecimal: 2,
            space: true,
          })
        : formatNumberString({
            str: currency_cents_to_euros(
              initialMaterial.material_included?.selling_price_cents
            ),
            nbDecimal: 2,
            space: true,
          }),
      margin_rate_computed:
        !hasMaterialIncluded || from !== "frameworkAgreement"
          ? 0
          : formatNumberString({
              str: formulaMarginRate({
                costPrice: initialMaterial.cost_price_cents,
                calculMethod,
                sellingPrice:
                  initialMaterial.material_included?.selling_price_cents,
                isUsingMargin,
              }),
              isCoeff: !isUsingMargin,
              space: true,
            }),
      selling_price_computed: formatNumberString({
        str: formulaSellingPrice({
          costPrice: initialMaterial.cost_price_cents,
          marginRate:
            initialMaterial.margin_rate == null
              ? initialMaterial.default_margin_rate
              : initialMaterial.margin_rate,
          calculMethod,
          isCoeff: false,
        }),
        isCoeff: !isUsingMargin,
        space: true,
      }),
    };
  }, [
    initialMaterial,
    categories,
    hasMaterialIncluded,
    calculMethod,
    from,
    isUsingMargin,
  ]);
  return (
    <Form
      submit={submit}
      onValuesChange={onValuesChange}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={formFactory}
      isShowing={isShowing}
      id="material-form"
      ref={cost}
    />
  );
}

MaterialsForm.propTypes = {
  categories: array,
  unities: array,
  isShowing: bool,
  onSubmit: func,
  updateOnChange: bool,
  submit: shape({
    onCancel: func,
  }),
  initialMaterial: object,
  manufacturers: array,
  from: string,
  faIsValidate: bool,
  hasMaterialIncluded: bool,
  distributors: array,
  entity_work_name: string,
  configuration: object,
  vocabulary: object,
  opportunityId: number,
  entityWorkId: string,
  tva_professions: array,
};

MaterialsForm.defaultProps = {
  categories: [],
  unities: [],
  isShowing: false,
  onSubmit: undefined,
  updateOnChange: false,
  submit: undefined,
  initialMaterial: undefined,
  manufacturers: [],
  from: "admin",
  faIsValidate: false,
  hasMaterialIncluded: false,
  distributors: [],
  entity_work_name: "",
  vocabulary: {},
  configuration: {},
  opportunityId: undefined,
  entityWorkId: undefined,
  tva_professions: [],
};

export default MaterialsForm;
