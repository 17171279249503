import { Form, TreeSelect } from "antd";
import React, { useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import FormCol from "../../../Form/FormCol";
import Select from "../../../react-ui/Select";
import { filterInTree } from "../../../../utils/search";
import WorksSelect from "./WorksSelect";
import { useStore } from "../../../store";
import { typesArray } from "../../../../constants/typesAndRoles";

const selector = (state) => ({
  procurement: state.procurement,
});

function UserRights({
  isUpdating,
  isShowing,
  selectedUser,
  roles,
  entities,
  works,
  allUsers,
  form,
  sites,
}) {
  const { procurement } = useStore(selector);
  const type = Form.useWatch("type");

  useEffect(() => {
    if (type === "supplier") {
      const role = form.getFieldValue("role");
      const entityId = form.getFieldValue("entity_id");
      if (role !== "manager" && role !== "salesman") {
        form.setFieldValue("role", undefined);
      }
      if (
        entityId &&
        entities.find((entity) => entity.id === entityId).parent_id === null
      ) {
        form.setFieldValue("entity_id", undefined);
      }
      form.setFieldValue("client_ids", undefined);
    }
    if (type === "buyer") {
      form.setFieldValue("entity_id", undefined);
    }
  }, [type, form, entities]);

  const formattedEntities = useMemo(() => {
    return entities?.map((el) => {
      return {
        id: el.id,
        pId: el.parent_id,
        title: el.name,
        value: el.id,
        disabled: procurement && type === "supplier" && el.parent_id === null,
      };
    });
  }, [entities, procurement, type]);

  const formattedRoles = useMemo(() => {
    if (!procurement || type !== "supplier") return [...roles];
    return roles.filter(
      (role) => role.id === "manager" || role.id === "salesman"
    );
  }, [roles, procurement, type]);

  return (
    <>
      <Row>
        {procurement && (
          <FormCol
            label="Type"
            name="type"
            rules={[{ required: true }]}
            width="30%"
            labelOnTop
          >
            <Select
              options={typesArray}
              disabled={isShowing}
              showSearch={false}
            />
          </FormCol>
        )}
        <FormCol
          label="Rôle"
          name="role"
          rules={[{ required: true }]}
          width={procurement ? "30%" : "50%"}
          labelOnTop
        >
          <Select
            options={formattedRoles}
            disabled={isShowing}
            showSearch={false}
          />
        </FormCol>
        {(!procurement || type === "supplier") && (
          <FormCol
            label="Agence"
            name="entity_id"
            rules={[{ required: true }]}
            width={procurement ? "40%" : "50%"}
            labelOnTop
          >
            <StyledTreeSelect
              treeDefaultExpandAll
              treeData={formattedEntities}
              treeDataSimpleMode
              placeholder="Sélectionnez une agence"
              filterTreeNode={filterInTree}
              showSearch
              disabled={isShowing}
            />
          </FormCol>
        )}
        {procurement && type === "buyer" && (
          <FormCol label="Site(s)" name="client_ids" width="40%" labelOnTop>
            <Select
              options={sites}
              disabled={isShowing}
              showSearch={false}
              mode="multiple"
            />
          </FormCol>
        )}
      </Row>
      <WorksSelect
        isShowing={isShowing}
        entities={entities}
        works={works}
        isUpdating={isUpdating}
        form={form}
        allUsers={allUsers}
        selectedUserId={selectedUser?.id}
        type={type}
      />
    </>
  );
}

const Row = styled.div`
  display: flex;
`;

const StyledTreeSelect = styled(TreeSelect)`
  ${({ disabled }) =>
    disabled &&
    css`
      .ant-select-selection-item {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
`;

export default UserRights;
