import React, { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import { stringSorter } from "../../../../utils/stringSorter";
import NumberCell from "../../../react-ui/NumberCell";
import Delete from "../../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../../confirmModal";
import { translate_filter } from "../../Categories/translate_data";
import { securityFields } from "../Folders/SecurityCol";
import Tag from "../../../react-ui/Tag";

const selector = (state) => ({
  formToken: state.formToken,
});

const columns = ({ handleDelete, folders }) => [
  {
    title: "Nom du fichier",
    dataIndex: "name",
    fixed: "left",
    width: "190px",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Créé le",
    dataIndex: "created_at",
    width: "80px",
    sorter: (a, b) => stringSorter(a.created_at, b.created_at),
    render: (created_at) => (
      <NumberCell>
        {new Date(created_at).toLocaleDateString("fr-FR")}
      </NumberCell>
    ),
  },
  {
    title: "Localisation",
    dataIndex: "location",
    width: "90px",
    sorter: (a, b) => stringSorter(a.location, b.location),
    render: (location) =>
      location === "admin" ? "Admin" : "Offres et opportunités",
  },
  {
    title: "Dossier",
    dataIndex: "folder_id",
    width: "300px",
    filterMode: "tree",
    filters: translate_filter(folders, null, null, "name"),
    filterSearch: true,
    onFilter: (value, record) => {
      return record.folder_id === parseInt(value, 10);
    },
    render: (folder_id) => {
      if (!folder_id || folders.length === 0) return "";
      const folder = folders.find((el) => el.id === folder_id);
      return <Tag color={folder.color || "#f0f0f0"} label={folder.name} />;
    },
  },
  {
    title: "Sécurité",
    dataIndex: "security",
    width: "120px",
    sorter: (a, b) => stringSorter(a.security, b.security),
    render: (security) => securityFields.find((el) => el.id === security)?.name,
  },
  {
    title: "",
    dataIndex: "",
    fixed: "right",
    align: "center",
    width: "20px",
    render: (record) => (
      <Delete
        onClick={(e) => {
          e.stopPropagation();
          ShowDeleteConfirm(record.name, record.id, handleDelete);
        }}
      />
    ),
  },
];

function FileRulesArray({
  folders = [],
  setStatus,
  setSelectedFileRule,
  foldersAreLoading,
  fileRules = [],
  fileRulesAreLoading,
}) {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);

  const { mutate: deleteFileRule } = useMutation(
    (todo) => postData(formToken, "/file_rule/delete", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("FileRules");
      },
    }
  );

  const handleDelete = (id) => deleteFileRule({ id });

  const dataSource = useMemo(() => {
    if (!fileRules) return [];
    return fileRules.map((fileRule) => {
      return {
        ...fileRule,
      };
    });
  }, [fileRules]);

  return (
    <TableContainer
      bordered={false}
      columns={columns({
        folders,
        handleDelete,
      })}
      rowKey="id"
      dataSource={dataSource}
      pagination={false}
      isRowSelectable
      onRow={(record) => {
        return {
          onClick: () => {
            setSelectedFileRule(fileRules.find((el) => el.id === record.id));
            setStatus("update");
          },
        };
      }}
      scrollY
      loading={foldersAreLoading || fileRulesAreLoading}
    />
  );
}

export default FileRulesArray;
