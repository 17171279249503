const fetchPartial = (url, callback ='') => {
    fetch(url)
    .then(function(response) { return response.text() }).then(function(body) {
      eval(body)
      eval(callback);
    }.bind(this));
}

export { fetchPartial } 

