
const checkFormCompletion = (event) => {
    let form = event.currentTarget ? event.currentTarget.closest('form') : event
    const validationButton = form.querySelector('[type="submit"]')
    let formComplete = true;
    form.querySelectorAll('.form-control.required').forEach((requiredInput) => {
      if(requiredInput.value === '' && requiredInput.type !== "radio") { formComplete = false}
    })
    if (validationButton) {
      if(formComplete === true) {
        validationButton.disabled = false;
        validationButton.classList.remove('btn-disabled')
      } else {
        validationButton.disabled = true;
        validationButton.classList.add('btn-disabled')
      }
    }

  }
  if(document.querySelectorAll('.simple_form')) {
    document.querySelectorAll('.simple_form').forEach((form) => {
      if(form.querySelector('.required:not(.radio_buttons)')){
        const validationButton = form.querySelector('input[type="submit"]');
        if (validationButton) {
          validationButton.disabled = true;
          validationButton.classList.add('btn-disabled')
        }
        form.querySelectorAll('.form-control.required').forEach((requiredInput) => {
          requiredInput.addEventListener('mouseleave', checkFormCompletion)
          requiredInput.addEventListener('change', checkFormCompletion)
          checkFormCompletion(form)
        })
      }
    })
  }
