import React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import TitleContainer from "../../../react-ui/TitleContainer";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import Details from "./Details";
import Total from "./Total";
import Loader from "../../../react-ui/Loader";

const selector = (state) => ({
  formToken: state.formToken,
});

function ListSection({
  opportunityId,
  rentingOpp,
  isLoading,
  selectedLeaser,
  configuration,
  vocabulary,
}) {
  const { formToken } = useStore(selector);
  const { data: oppQuoLc } = useQuery("OpportunityCotationLc", () =>
    getData(formToken, `/opportunity_quotation_lc/${opportunityId}`)
  );

  return (
    <Container>
      {isLoading && <Loader />}
      {!!rentingOpp && !!selectedLeaser && (
        <>
          <TitleContainer label="Récapitulatif" />
          <Total
            oppQuoLc={oppQuoLc && oppQuoLc ? oppQuoLc : undefined}
            period={rentingOpp.period}
            periodType={selectedLeaser.period_type}
          />
          <Details
            opportunityType="new_work"
            rentingOppId={rentingOpp.id}
            periodType={selectedLeaser.period_type}
            configuration={configuration}
            vocabulary={vocabulary}
          />
          <Details
            opportunityType="maintenance"
            rentingOppId={rentingOpp.id}
            periodType={selectedLeaser.period_type}
            configuration={configuration}
            vocabulary={vocabulary}
          />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
`;

export default ListSection;
