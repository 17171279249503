import React, { useState, useMemo } from "react";
import styled, { ThemeProvider } from "styled-components";
import { ConfigProvider, Modal } from "antd";
import frFr from "antd/lib/locale/fr_FR";
import { QueryClientProvider, QueryClient, useMutation } from "react-query";
import Button from "../react-ui/Button";
import Form from "../Form";
import { theme } from "../../theme/theme";
import AddButton from "../react-ui/AddButton";
import { postData } from "../request/instance";
import ProfileForm from "./ProfileForm";
import ApiManagement from "./ApiManagement";
import Separator from "../react-ui/Separator";

const queryClient = new QueryClient();

function Index({
  formToken,
  angencie_name,
  work_names,
  current_user,
  procurement,
  sites,
  option_documents,
}) {
  return (
    <ConfigProvider locale={frFr}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <StyledContainer>
            <ProfileForm
              formToken={formToken}
              angencie_name={angencie_name}
              work_names={work_names}
              procurement={procurement}
              sites={sites}
              current_user={current_user}
              option_documents={option_documents}
            />
            <Separator />
            <ApiManagement formToken={formToken} current_user={current_user} />
          </StyledContainer>
          <div style={{ height: 300 }} />
        </QueryClientProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;s
`;
export default Index;
