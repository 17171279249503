import { encodeUrlParams } from "./encodeUrlParams";

export const fetchContactsUrl = ({ search, clientId }) => {
  let url = `/fetch_contacts_client?`;
  const params = {
    client_id: clientId,
    ...(search && { search }),
  };

  url += encodeUrlParams(params);

  return url;
};
