import { Modal } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useQuery } from "react-query";
import useTodo from "../../../hookQuery/useTodo";
import Button from "../../../react-ui/Button";
import TitleContainer from "../../../react-ui/TitleContainer";
import { useStore } from "../../../store";
import ZonesArray from "./ZonesArray";
import Configurator from "./Configurator";
import { getData } from "../../../request/instance";
import { simple_default_configuration } from "../../../../simple_default";
import ZoneConfigButton from "../../../react-ui/ZoneConfigButton";
import TvaButton from "../../../react-ui/TvaButton";
import { getUrlParams } from "../../../utils";
import { fetchMajorationsUrl } from "../../../../utils/fetchMajorationsUrl";
import { fetchDifficultiesUrl } from "../../../../utils/fetchDifficultiesUrl";

const selector = (state) => ({
  formToken: state.formToken,
});

const fake_zone = [{ id: 0, entitled: "Zone", type: "zone" }];

function CostingSection({
  opportunityType,
  faId,
  opportunity_work,
  configuration,
  vocabulary,
  company_work,
  from,
  selectedZoneTemplate,
}) {
  const { id_opportunity } = useParams();
  const { formToken } = useStore(selector);
  const [showColTVA, setShowColTVA] = useState(false);

  const [isModalConfigurationVisible, setIsModalConfigurationVisible] =
    useState(false);

  const { data: defaultValues } = useTodo(
    formToken,
    `/default_value_opportunities/${id_opportunity}`,
    {
      enabled: from !== "configuration",
    },
    "DefaultValues"
  );

  const { data: majorations } = useTodo(
    formToken,
    fetchMajorationsUrl({
      from,
      id: from === "admin" ? selectedZoneTemplate.zonable_id : id_opportunity,
    }),
    "Majorations"
  );

  const { data: difficulties } = useTodo(
    formToken,
    fetchDifficultiesUrl({
      from,
      id: from === "admin" ? selectedZoneTemplate.zonable_id : id_opportunity,
    }),
    "Difficulties"
  );

  const { data: tva_professions } = useQuery(
    "TvaProfessionsList",
    () =>
      getData(
        formToken,
        `/list_tva_opportunity?opportunity_id=${id_opportunity}`
      ),
    {
      enabled: from !== "admin" && from !== "configuration",
    }
  );

  const { data: carrycots } = useQuery("CarrycotTPL", () => {
    if (from === "admin") {
      return getData(
        formToken,
        `/carrycot_profession?id=${selectedZoneTemplate.zonable_id}&from=${from}`
      );
    }
    return getData(formToken, `/carrycot_tpl_opportunity/${id_opportunity}`);
  });

  const urlConstructor = useCallback(() => {
    if (from === "admin")
      return `/zone/show_by_id/${selectedZoneTemplate.id}?from=${from}`;
    return `/zone/${id_opportunity}&from=${from}`;
  }, [from, selectedZoneTemplate, id_opportunity]);

  const { data: zones, isLoading: zoneLoading } = useQuery(
    "Zones",
    () => getData(formToken, urlConstructor()),
    {
      enabled: from !== "configuration",
    }
  );

  const handleOpenConfiguration = () => {
    setIsModalConfigurationVisible(true);
  };

  const handleCancelConfiguration = () => {
    setIsModalConfigurationVisible(false);
  };

  const zones_data = useMemo(() => {
    if (from === "configuration") return fake_zone;
    if (Array.isArray(zones)) return zones;
    return [zones];
  }, [zones, from]);

  return (
    <Container from={from}>
      <Modal
        width={1100}
        maskClosable={false}
        open={isModalConfigurationVisible}
        footer={null}
        onCancel={handleCancelConfiguration}
        destroyOnClose
      >
        <Configurator
          defaultValues={defaultValues}
          difficulties={difficulties}
          majorations={majorations}
          carrycots={carrycots}
          id_opportunity={id_opportunity}
          zones={zones}
          opportunity_work={opportunity_work}
          configuration={configuration}
          vocabulary={vocabulary}
        />
      </Modal>
      <TitleContainer label="Chiffrage">
        {company_work.management_special_TVA && (
          <TvaButton
            label={vocabulary.TVA}
            showColTVA={showColTVA}
            setShowColTVA={setShowColTVA}
          />
        )}
        {from !== "admin" && (
          <ZoneConfigButton
            label="Configurateur"
            onClick={handleOpenConfiguration}
            disabled={from === "configuration"}
          />
        )}
      </TitleContainer>
      <Wrapper>
        <ZonesArray
          showColTVA={showColTVA}
          difficulties={difficulties}
          tva_professions={tva_professions}
          id_opportunity={id_opportunity}
          zones={zones_data}
          zoneLoading={zoneLoading}
          opportunityType={opportunityType}
          faId={faId}
          majorations={majorations}
          carrycots={carrycots}
          opportunity_work={opportunity_work}
          configuration={configuration}
          vocabulary={vocabulary}
          from={from}
        />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 64%;
  height: ${({ from }) => (from === "configuration" ? "auto" : "80vh")};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
`;

const SyledButton = styled(Button)`
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
`;

export default CostingSection;
