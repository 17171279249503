import React from "react";
import { string } from "prop-types";
import styled from "styled-components";

function AddLine({ className, ...iconProps }) {
  return (
    <StyledIcon
      className={`fa-duotone fa-level-up-alt ${className}`}
      {...iconProps}
    />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: 12px;
  rotate: 90deg;
`;

AddLine.propTypes = {
  className: string,
};

AddLine.defaultProps = {
  className: "",
};

export default AddLine;
