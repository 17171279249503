import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useQuery } from "react-query";
import { useStore } from "../../store";
import FamiliesArray from "./FamiliesArray";
import FamilyDetails from "./FamilyDetails";
import { getUrlParams } from "../../utils";
import useTodo from "../../hookQuery/useTodo";
import TitleContainer from "../../react-ui/TitleContainer";
import Separator from "../../react-ui/Separator";
import AddButton from "../../react-ui/AddButton";

import { fetchDistributorsUrl } from "../../../utils/fetchDistributorsUrl";
import { fetchManufacturersUrl } from "../../../utils/fetchManufacturersUrl";
import { fetchUnitiesUrl } from "../../../utils/fetchUnitiesUrl";
import { getFetchUrlIdAndFrom } from "../../../utils/getFetchUrlIdAndFrom";
import { getData } from "../../request/instance";

const selector = (state) => ({
  formToken: state.formToken,
  editFamily: state.editFamily,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function Families({
  entityWorkId = getUrlParams(),
  from = "admin",
  opportunityId = null,
  faIsValidate = false,
  faId = null,
  entity_work_name,
  configuration,
  vocabulary,
}) {
  const { formToken, editFamily } = useStore(selector);

  const [wordEntered, setWordEntered] = useState("");
  const [detailsStatus, setDetailsStatus] = useState("empty");
  const [materials, setMaterials] = useState([]);

  const { data: categories } = useTodo(
    formToken,
    `/category_profession?entity_work_id=${entityWorkId}`,
    "Categories"
  );

  const { data: categoriesFilter } = useTodo(
    formToken,
    `/category_profession?entity_work_id=${entityWorkId}&import_csv=true`,
    "CategoriesFilter"
  );

  const { data: unities } = useTodo(
    formToken,
    fetchUnitiesUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
    }),
    ["Unities", { from }]
  );

  const { data: manufacturers } = useTodo(
    formToken,
    fetchManufacturersUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
    }),
    ["Manufacturers", { from }]
  );

  const { data: distributors } = useTodo(
    formToken,
    fetchDistributorsUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
    }),
    ["Distributors", { from }]
  );

  const sendSearch = () => (wordEntered ? `&search=${wordEntered}` : "");

  const { data: families, refetch } = useTodo(
    formToken,
    `/family_professions?entity_work_id=${getUrlParams()}${sendSearch()}&from=admin`,
    "Families"
  );

  const { data: familiesAssociates } = useQuery(["FamiliesAssociates"], () =>
    getData(formToken, `/family_associates?entity_work_id=${entityWorkId}`)
  );

  useEffect(() => {
    editFamily(null);
    setDetailsStatus("empty");
    debouncedSearch({ refetch });
  }, [wordEntered, refetch, editFamily]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const openCreation = () => {
    editFamily(null);
    setDetailsStatus("create");
  };

  return (
    <Container>
      <div className="main_admin-materiels">
        <TitleContainer label="Familles disponibles">
          <AddButton
            onClick={openCreation}
            disabled={detailsStatus === "create"}
            label="Famille"
          />
        </TitleContainer>
        <StyledSearch
          allowClear
          placeholder="Rechercher par nom de famille"
          value={wordEntered}
          onChange={handleFilter}
        />
        <Separator isHorizontal size={3} />
        <div>
          <FamiliesArray
            setDetailsStatus={setDetailsStatus}
            families={families}
            detailsStatus={detailsStatus}
          />
        </div>
      </div>
      <Separator />
      <FamilyDetails
        detailsStatus={detailsStatus}
        setDetailsStatus={setDetailsStatus}
        families={families}
        entityWorkId={entityWorkId}
        from={from}
        opportunityId={opportunityId}
        faIsValidate={faIsValidate}
        faId={faId}
        entity_work_name={entity_work_name}
        materials={materials}
        setMaterials={setMaterials}
        categories={categories}
        unities={unities}
        manufacturers={manufacturers}
        distributors={distributors}
        categoriesFilter={categoriesFilter}
        familiesAssociates={familiesAssociates}
        configuration={configuration}
        vocabulary={vocabulary}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
`;

const StyledSearch = styled(Search)``;

export default Families;
