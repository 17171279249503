import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Tag from "../../react-ui/Tag";
import Input from "../../react-ui/Input";
import {
  findIsRootItem,
  findItemByKey,
  findRootItemIdx,
  getNewRootItem,
} from "../../react-ui/TableTree/treeutils";

const regExtension = /(?:\.([^.]+))?$/;

function ItemTitle({
  id,
  recordKey,
  name,
  isLeaf,
  type,
  color,
  disabled,
  setData,
  parentKeys,
  onBlur,
  isDraggable,
  additionalName,
}) {
  const [showInput, setShowInput] = useState(false);
  const [extension, setExtension] = useState("");
  const inputRef = useRef(null);

  const toggleEdit = (e) => {
    e.stopPropagation();
    if (disabled) return;
    const ext = regExtension.exec(name)[1];
    setExtension(ext ? `.${ext}` : "");
    setShowInput(true);
  };

  const hideEdit = (e) => {
    setExtension("");
    setShowInput(false);
    handleEdit(e);
    if (onBlur) onBlur({ id, name: e.target.value, extension });
  };

  const handleEdit = (event) => {
    if (event.target.value === "") return;
    setData((oldData) => {
      const newData = [...oldData];
      const record = findItemByKey({ oldData, key: recordKey, parentKeys });
      record.name = event.target.value + extension;
      const isRootItem = findIsRootItem(record);
      const rootItemIdx = findRootItemIdx(oldData, record);
      if (isRootItem) {
        newData[rootItemIdx] = record;
      } else {
        newData[rootItemIdx] = getNewRootItem({
          item: newData[rootItemIdx],
          record,
          deleteItem: false,
          recordToReplace: record,
        });
      }
      return newData;
    });
  };

  const keypressHandler = (event) => {
    if (event.key === "Enter") {
      handleEdit(event);
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    if (isDraggable) {
      document
        .getElementById(`drag-row-${recordKey}`)
        .setAttribute("draggable", !showInput);
    }
    if (showInput)
      inputRef.current.focus({
        cursor: "start",
      });
  }, [showInput, recordKey, isDraggable]);

  const getName = () => {
    if (type === "folder")
      return <Tag color={color || "#f0f0f0"} label={name} />;
    return (
      <NameContainer title={name}>
        <NameWrapper>{name}</NameWrapper>
        {additionalName && additionalName}
      </NameContainer>
    );
  };

  const getFilenameBase = () => {
    let lastDot = name.lastIndexOf(".");
    lastDot = lastDot > 0 ? lastDot : Infinity;
    const filenameBase = name.substr(0, lastDot);
    return filenameBase;
  };

  return (
    <Name
      onClick={toggleEdit}
      style={{ fontSize: isLeaf ? "inherit" : "12px" }}
    >
      {showInput ? (
        <StyledInput
          ref={inputRef}
          defaultValue={getFilenameBase()}
          onBlur={hideEdit}
          onKeyPress={keypressHandler}
          addonAfter={extension}
        />
      ) : (
        getName()
      )}
    </Name>
  );
}

const Name = styled.div`
  padding: 0 5px !important;
  margin-right: auto;
  cursor: pointer;
  width: 100%;
  position: relative;
`;

const StyledInput = styled(Input)`
  height: 22px;
  .ant-input {
    height: 22px !important;
  }
`;

const NameContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: baseline;
  position: absolute;
  width: calc(100% - 10px);
`;

const NameWrapper = styled.div`
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default ItemTitle;
