import React from "react";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { stringSorter } from "../../../utils/stringSorter";
import { translate_filter } from "../Categories/translate_data";
import { numberSorter } from "../../../utils/numberSorter";
import TitleContainer from "../../react-ui/TitleContainer";
import WorkIcon from "../../react-ui/WorkIcon";
import { useStore } from "../../store";
import { typesArray } from "../../../constants/typesAndRoles";

const usersColumns = ({ works, entities, roles, sites, procurement }) => [
  {
    title: "Nom",
    dataIndex: "last_name",
    width: "90px",
    fixed: "left",
    sorter: (a, b) => stringSorter(a.last_name, b.last_name),
    render: (last_name) => <NameCell>{last_name?.toUpperCase()}</NameCell>,
  },
  {
    title: "Prénom",
    dataIndex: "first_name",
    width: "90px",
    fixed: "left",
    sorter: (a, b) => stringSorter(a.first_name, b.first_name),
    render: (first_name) => <NameCell>{first_name}</NameCell>,
  },
  {
    title: "Agence",
    dataIndex: "entity_id",
    width: procurement ? "90px" : "130px",
    filterMode: "tree",
    filters: translate_filter(entities, null, "parent_id", "name"),
    sorter: (a, b) =>
      stringSorter(
        entities.find((entity) => entity.id === a.entity_id).name,
        entities.find((entity) => entity.id === b.entity_id).name
      ),
    filterSearch: true,
    onFilter: (value, record) => {
      return record.entity_id === parseInt(value, 10);
    },
    render: (entity_id) =>
      entities.find((entity) => entity.id === entity_id).name,
  },
  {
    title: "Sites",
    dataIndex: "client_ids",
    width: "90px",
    filterMode: "tree",
    filters: translate_filter(sites, null, null, "name"),
    filterSearch: true,
    onFilter: (value, record) => {
      return record.client_ids.includes(parseInt(value, 10));
    },
    render: (client_ids) =>
      sites
        .filter((site) => client_ids.includes(parseInt(site.id, 10)))
        .map((site) => site.name)
        .join(", "),
    hidden: !procurement,
  },
  {
    title: "Type",
    dataIndex: "type",
    width: "80px",
    sorter: (a, b) => stringSorter(a.type, b.type),
    render: (recType) => typesArray.find((type) => type.id === recType).name,
    hidden: !procurement,
  },
  {
    title: "Role",
    dataIndex: "role",
    width: "80px",
    filterMode: "tree",
    filters: translate_filter(roles, null, null, "name"),
    filterSearch: true,
    sorter: (a, b) => stringSorter(a.role, b.role),
    onFilter: (value, record) => {
      return record.role === value;
    },
    render: (recRole) => roles.find((role) => role.id === recRole).name,
  },
  {
    title: "Activité(s)",
    dataIndex: "work_ids",
    width: procurement ? "80px" : "100px",
    filterMode: "tree",
    filters: translate_filter(
      works.map((work) => ({
        ...work,
        name: (
          <React.Fragment key={work.id}>
            <WorkIcon workName={work.name} /> {work.fullname}
          </React.Fragment>
        ),
      })),
      null,
      null,
      "name"
    ),
    filterSearch: true,
    onFilter: (value, record) => {
      return record.work_ids.includes(parseInt(value, 10));
    },
    render: (work_ids) =>
      works
        .filter((work) => work_ids.includes(parseInt(work.id, 10)))
        .map((work) => (
          <React.Fragment key={work.id}>
            <WorkIcon workName={work.name} />{" "}
          </React.Fragment>
        )),
  },
  {
    title: "Actif",
    dataIndex: "active",
    width: "55px",
    fixed: "right",
    filters: [{ text: "Afficher les utilisateurs inactifs", value: false }],
    defaultFilteredValue: [true],
    onFilter: (value, record) => record.active === value,
    defaultSortOrder: "descend",
    sorter: (a, b) => numberSorter(a.active, b.active),
    render: (active) => (
      <CenteredCell>
        {active ? (
          <OkIcon className="fa-duotone fa-check text-success" />
        ) : (
          <RedIcon className="fa-duotone fa-times" />
        )}
      </CenteredCell>
    ),
  },
];

const selector = (state) => ({
  procurement: state.procurement,
});

function UsersArray({
  users,
  usersAreLoading,
  works,
  entities,
  roles,
  wordEntered,
  setWordEntered,
  setStatus,
  setSelectedUserId,
  selectedUserId,
  sites,
}) {
  const { procurement } = useStore(selector);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  return (
    <Container>
      <TitleContainer label="Liste des utilisateurs" />
      <StyledSearch
        allowClear
        placeholder="Rechercher un utilisateur..."
        value={wordEntered}
        onChange={handleFilter}
      />
      <TableContainer
        bordered={false}
        columns={usersColumns({ works, entities, roles, procurement, sites })}
        rowKey="id"
        dataSource={users}
        rowSelectableId={selectedUserId}
        pagination={false}
        isRowSelectable
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedUserId(record.id);
              setStatus("show");
            },
          };
        }}
        scrollY
        scroll={{ x: "max-content" }}
        loading={usersAreLoading}
      />
    </Container>
  );
}

const Container = styled.div`
  width: ${({ $procurement }) => ($procurement ? "60%" : "50%")};
`;

const NameCell = styled.div`
  word-break: break-word;
`;

const CenteredCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSearch = styled(Search)`
  margin-bottom: 12px;
`;

const RedIcon = styled.i`
  color: ${({ theme }) => theme.colors.basicorange};
  font-size: 14px;
`;

const OkIcon = styled.i`
  font-size: 14px;
`;

export default UsersArray;
