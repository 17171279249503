import React, { useEffect, useMemo, useState } from "react";
import { Form, Modal, Upload } from "antd";
import styled from "styled-components";
import { UploadOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import FormCol from "../../Form/FormCol";
import FormRow from "../../Form/FormRow";
import Input from "../../react-ui/Input";
import Select from "../../react-ui/Select";
import TitleContainer from "../../react-ui/TitleContainer";
import { useStore } from "../../store";
import AddButton from "../../react-ui/AddButton";
import Button from "../../react-ui/Button";
import { postData } from "../../request/instance";
import { validateMessages } from "../../constant";
import WorksSelect from "../../Admin-cf/Clients/Form/WorksSelect";
import MaxSitesInput from "../../Admin-cf/Clients/Form/MaxSitesInput";
import ClientsSelect from "../../Admin-cf/Clients/Form/ClientsSelect";

const selector = (state) => ({
  formToken: state.formToken,
  vocabularyApplication: state.vocabularyApplication,
  procurement: state.procurement,
});

const clientField = {
  name: "name",
  address1: "address1",
  address2: "address2",
  status: "status",
  parent: "parent",
  siret: "siret",
  city: "city",
  zipCode: "zipcode",
  clientsActivityId: "clients_activity_id",
  comment: "comment",
  logo: "logo",
  email: "email",
  phone: "phone",
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }

  return e && e.fileList;
};

function ClientModal({
  clientModalOpenFrom,
  setClientModalOpenFrom,
  companyId,
  clientActivities,
  formOpp,
  configApplication,
  works,
  setOtherClients,
  clientsInMemory,
  setClientsInMemory,
  formatClients,
  vocabulary,
  configuration,
}) {
  const [clients, setClients] = useState([]);
  const [form] = Form.useForm();
  const { formToken, vocabularyApplication, procurement } = useStore(selector);

  const { mutate: createClient, isLoading } = useMutation(
    (todoData) =>
      postData(formToken, `/companies/${companyId}/clients`, todoData),
    {
      onSettled: () => closeModal(),
      onSuccess: (client) => {
        setOtherClients((olds) => [client, ...olds]);
        if (formOpp) {
          formOpp.setFieldValue([clientModalOpenFrom], {
            label: client.name,
            value: client.id,
          });
        }
      },
    }
  );

  const selectableClients = useMemo(() => {
    return procurement
      ? clients.filter(
          (client) =>
            client.position === 0 ||
            (client.position === 1 &&
              clients.filter((c) => c.parent_id === client.id).length <
                client.max_juridic_entities)
        )
      : clients;
  }, [procurement, clients]);

  const initialValues = useMemo(
    () => ({
      [clientField.status]: "prospect",
    }),
    []
  );

  useEffect(() => {
    if (
      !formOpp &&
      clientModalOpenFrom !== "" &&
      clientModalOpenFrom !== "new"
    ) {
      const c = clientsInMemory.find((cl) => cl.id === clientModalOpenFrom);
      form.setFieldValue(clientField.parent, { label: c.name, value: c.id });
    }
  }, [clientModalOpenFrom, form, formOpp, clientsInMemory]);

  const closeModal = () => {
    setClientModalOpenFrom("");
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const { logo, work_ids, parent, ...valuesToSend } = values;
    const formData = new FormData();

    if (logo && logo[0]) {
      formData.append("client[logo]", logo[0].originFileObj);
    }
    if (work_ids) {
      work_ids.forEach((workId) => formData.append(`work_ids[]`, workId));
    }
    Object.keys(valuesToSend).forEach((key) =>
      formData.append(
        `client[${key}]`,
        valuesToSend[key] !== undefined && valuesToSend[key] !== null
          ? valuesToSend[key]
          : ""
      )
    );
    if (parent?.value) {
      formData.append("client[parent_id]", parent?.value);
    }
    createClient(formData);
  };

  return (
    <StyledModal
      destroyOnClose
      open={clientModalOpenFrom !== ""}
      footer={null}
      closable={false}
      onCancel={closeModal}
      bodyStyle={{ padding: 0 }}
      width={900}
      maskClosable={false}
    >
      <Form
        id="new-client-form"
        form={form}
        colon={false}
        requiredMark={false}
        initialValues={initialValues}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
      >
        <TitleContainer label={`Créer ${vocabulary.clients.a_client}`}>
          <StyledButton
            label="Annuler"
            onClick={closeModal}
            fontSize="14px"
            btnType="cancel"
            type="button"
          />
          <AddButton
            label={`Créer ${vocabulary.clients.the_client}`}
            type="submit"
            value="submit"
            fontSize="14px"
            loading={isLoading}
          />
        </TitleContainer>
        <FormContainer>
          <FormRow>
            <FormCol
              width="50%"
              label={
                vocabularyApplication?.application_name === "procurement"
                  ? vocabularyApplication?.clients.client_name
                  : vocabulary.clients.name
              }
              rules={[{ required: true }]}
              name={clientField.name}
            >
              <Input placeholder={vocabularyApplication?.clients.client_name} />
            </FormCol>
            <FormCol width="50%" label="SIRET" name={clientField.siret}>
              <Input placeholder="SIRET" />
            </FormCol>
          </FormRow>

          <FormRow>
            <FormCol width="50%" label="Adresse" name={clientField.address1}>
              <Input placeholder="Adresse" />
            </FormCol>
            <FormCol
              width="50%"
              label="Ville"
              rules={[{ required: true }]}
              name={clientField.city}
            >
              <Input placeholder="Ville" />
            </FormCol>
          </FormRow>

          <FormRow>
            <FormCol
              width="50%"
              label="Complément d'adresse"
              name={clientField.address2}
            >
              <Input placeholder="Complément d'adresse" />
            </FormCol>
            <FormCol
              width="50%"
              label="Code postal"
              rules={[{ required: true }]}
              name={clientField.zipCode}
            >
              <Input placeholder="Code postal" />
            </FormCol>
          </FormRow>

          <FormRow>
            <FormCol
              width="50%"
              label={vocabularyApplication?.clients.client_phone}
              name={clientField.phone}
            >
              <Input
                placeholder={vocabularyApplication?.clients.client_phone}
              />
            </FormCol>
            <FormCol
              width="50%"
              label={vocabularyApplication?.clients.client_email}
              name={clientField.email}
            >
              <Input
                placeholder={vocabularyApplication?.clients.client_email}
              />
            </FormCol>
          </FormRow>

          <FormRow>
            {configApplication.common.clients.show_status && (
              <FormCol width="50%" label="Statut" name={clientField.status}>
                <StyledSelect
                  options={[
                    { name: "Prospect", id: "prospect" },
                    { name: "Client", id: "client" },
                  ]}
                  placeholder="Choisissez un statut"
                  allowClear
                />
              </FormCol>
            )}
            {configApplication.common.clients.show_activity && (
              <FormCol
                width="50%"
                label="Activité"
                rules={[{ required: configuration.clients.required_activity }]}
                name={clientField.clientsActivityId}
              >
                <StyledSelect
                  options={clientActivities}
                  placeholder="Sélectionnez une activité"
                />
              </FormCol>
            )}
            {configApplication?.common.clients.show_works && (
              <WorksSelect
                isUpdating
                works={works}
                form={form}
                clients={clientsInMemory}
                fromModal
              />
            )}
            <MaxSitesInput clients={clientsInMemory} fromModal />
          </FormRow>

          <FormRow>
            <FormCol
              labelOnTop
              width="50%"
              label={
                vocabularyApplication?.application_name === "procurement"
                  ? `${vocabularyApplication?.clients.this_client} dépend d'un groupe ?`
                  : `${vocabulary.clients.this_client} dépend d'un groupe ?`
              }
              name={clientField.parent}
            >
              <ClientsSelect
                setClientsInMemory={setClientsInMemory}
                clientsInMemory={clientsInMemory}
                clients={selectableClients}
                setClients={setClients}
                formatClients={formatClients}
              />
            </FormCol>
            <FormCol
              labelOnTop
              width="50%"
              label="Commentaires"
              name={clientField.comment}
            >
              <Input textArea placeholder="Commentaires" />
            </FormCol>
          </FormRow>

          <FormRow>
            <Form.Item
              noStyle
              name={clientField.logo}
              valuePropName="fileList"
              getValueFromEvent={normFile}
              multiple="false"
            >
              <Upload
                maxCount={1}
                beforeUpload={() => false} // Prevent <AntdUpload /> trigerring post default request after choosing img
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <UploadOutlined />
                  <UploadText>Télécharger un logo...</UploadText>
                </div>
              </Upload>
            </Form.Item>
          </FormRow>
        </FormContainer>
      </Form>
    </StyledModal>
  );
}

const StyledSelect = styled(Select)`
  width: 100%;
`;

const UploadText = styled.p`
  margin-top: 8px;
  padding-left: 5px;
  font-size: 14px !important;
`;

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

export default ClientModal;
