import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { Checkbox, Modal, Tabs } from "antd";
import TitleContainer from "../react-ui/TitleContainer";
import TableContainer from "../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { getData, postData } from "../request/instance";
import { stringSorter } from "../../utils/stringSorter";
import AddButton from "../react-ui/AddButton";
import Input from "../react-ui/Input";
import { fetchAuthorizedApplicationsUrl } from "../../utils/fetchApplicationsUrl";
import { useStore } from "../store";
import Delete from "../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../Admin-cf/confirmModal";
import NumberCell from "../react-ui/NumberCell";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

function FormBatiprix({ selectedAPI, formToken, currentUserId }) {
  const queryClient = useQueryClient();
  const [batiprixToken, setBatiprixToken] = useState(null);

  const { data: api, refetch: refetchBatiprixApi } = useQuery(
    "BatiprixApi",
    () => getData(formToken, `/batiprix_api/show/${currentUserId}`),
    {
      enabled: !!selectedAPI,
      refetchOnWindowFocus: false,
      onSuccess: (payload) => {
        setBatiprixToken(payload.batiprix_token);
      },
    }
  );

  useEffect(() => {
    refetchBatiprixApi();
  }, [currentUserId]);

  const { mutate: updateBatiprix, isLoading: updateBatiprixIsLoading } =
    useMutation(
      (todoData) =>
        postData(formToken, `/batiprix_api/update/${currentUserId}`, todoData),
      {
        onSuccess: (payload) => {
          // setBatiprixToken(null);
          queryClient.invalidateQueries("ListApis");
        },
      }
    );

  return (
    <div style={{ margin: 20 }}>
      <div
        style={{
          marginTop: 10,
          display: "flex",
          justifyContent: "right",
          width: "100%",
        }}
      >
        <AddButton
          label="Enregistrer"
          fontSize="14px"
          loading={updateBatiprixIsLoading}
          onClick={() =>
            updateBatiprix({
              batiprix_token: batiprixToken,
            })
          }
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label htmlFor="batiprixtoken">Batiprix Token:</label>
        <Input
          id="batiprixtoken"
          value={batiprixToken}
          disabled={false}
          onChange={(e) => setBatiprixToken(e.target.value)}
        />
      </div>
    </div>
  );
}

function FormDocusign({ selectedAPI, formToken, currentUserId }) {
  const [docusignAccountID, setDocusignAccountID] = useState(null);
  const [docusignAccountApiId, setDocusignAccountApiId] = useState(null);
  const queryClient = useQueryClient();
  const { data: api, refetch: refetchDocusignApi } = useQuery(
    "DocusignApi",
    () => getData(formToken, `/docusign_api/show/${currentUserId}}`),
    {
      enabled: !!selectedAPI,
      refetchOnWindowFocus: false,
      onSuccess: (payload) => {
        setDocusignAccountID(payload.docusign_user_id);
        setDocusignAccountApiId(payload.docusign_account_api_id);
      },
    }
  );

  useEffect(() => {
    refetchDocusignApi();
  }, [currentUserId]);

  const { mutate: updateDocusign, isLoading: loadingUpdateDocusign } =
    useMutation(
      (todoData) =>
        postData(formToken, `/docusign_api/update/${currentUserId}`, todoData),
      {
        onSuccess: (payload) => {
          queryClient.invalidateQueries("ListApis");
        },
      }
    );

  return (
    <div style={{ margin: 20 }}>
      <div
        style={{
          marginTop: 10,
          display: "flex",
          justifyContent: "right",
          width: "100%",
        }}
      >
        <AddButton
          label="Enregistrer"
          fontSize="14px"
          loading={loadingUpdateDocusign}
          onClick={() =>
            updateDocusign({
              docusign_user_id: docusignAccountID,
              docusign_account_api_id: docusignAccountApiId,
            })
          }
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label htmlFor="docusignAccountId">Identifiant utilisateur:</label>
        <Input
          id="docusignAccountId"
          value={docusignAccountID}
          disabled={false}
          onChange={(e) => setDocusignAccountID(e.target.value)}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label htmlFor="docusignAccountApiId">Identifiant de compte API:</label>
        <Input
          id="docusignAccountApiId"
          value={docusignAccountApiId}
          disabled={false}
          onChange={(e) => setDocusignAccountApiId(e.target.value)}
        />
      </div>
    </div>
  );
}

const connectedColumns = (handleDelete) => [
  {
    title: "Nom",
    dataIndex: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Créée le",
    dataIndex: "created_at",
    sorter: (a, b) => stringSorter(a.created_at, b.created_at),
    render: (created_at) => (
      <NumberCell>
        {new Date(created_at).toLocaleDateString("fr-FR")}
      </NumberCell>
    ),
    width: "30%",
  },
  {
    title: "",
    dataIndex: "delete",
    render: (_, record) => (
      <div
        role="button"
        onKeyDown={() => null}
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
          confirm({
            title: `Révoquer l'autorisation de l'application ${record.name} ?`,
            icon: <ExclamationCircleOutlined />,
            okText: "Oui",
            okType: "danger",
            cancelText: "Non",
            maskClosable: false,
            onOk() {
              handleDelete(record.id);
              return false;
            },
            onCancel() {
              return false;
            },
          });
        }}
        className="btn btn-danger rounded-lg"
        style={{
          padding: 4,
          height: "auto",
          fontSize: 11,
        }}
      >
        Révoquer
      </div>
    ),
    align: "center",
    width: "10%",
  },
];

function ApiManager({ formToken, current_user }) {
  const [listApiState, setListApiState] = useState([]);
  const queryClient = useQueryClient();
  const { data: list_apis, refetch } = useQuery(
    "ListApis",
    () => getData(formToken, `/api_provider/${current_user.id}`),
    {
      refetchOnWindowFocus: false,
      onSuccess: (payload) => {
        setListApiState(payload);
      },
    }
  );
  useEffect(() => {
    refetch();
  }, [current_user, queryClient]);
  const [selectedAPI, setSelectedAPI] = useState(null);
  const handleSelectAPI = (api_name) => {
    setSelectedAPI(api_name);
  };

  const ApiColumns = useCallback(
    () => [
      {
        title: "Intitulé",
        dataIndex: "name",
        sorter: (a, b) => stringSorter(a.name, b.name),
        render: (name) => (
          <div onClick={() => handleSelectAPI(name)}>{name}</div>
        ),
        width: "85%",
      },
      {
        title: "Configuré",
        dataIndex: "configured",
        sorter: (a, b) => stringSorter(a.configured, b.configured),
        render: (configured) => {
          if (configured) {
            return <OkIcon className="fa-duotone fa-check text-success" />;
          }
          return <RedIcon className="fa-duotone fa-times" />;
        },
        align: "right",
        width: "15%",
      },
    ],
    []
  );

  const formatDataListApi = useCallback(() => {
    return listApiState?.map((api) => {
      return {
        ...api,
        key: api.id,
      };
    });
  }, [listApiState]);

  return (
    <StyledContainer>
      <TableContainer columns={ApiColumns()} data={formatDataListApi()} />

      <StyledBox>
        {selectedAPI === "docusign" && (
          <FormDocusign
            selectedAPI={selectedAPI}
            formToken={formToken}
            currentUserId={current_user.id}
          />
        )}
        {selectedAPI === "batiprix" && (
          <FormBatiprix
            selectedAPI={selectedAPI}
            formToken={formToken}
            currentUserId={current_user.id}
          />
        )}
      </StyledBox>
    </StyledContainer>
  );
}

function AuthorizedApplicationManager({ formToken }) {
  const queryClient = useQueryClient();

  const { data: authorizedApplications } = useQuery(
    "AuthorizedApplications",
    () => getData(formToken, fetchAuthorizedApplicationsUrl({})),
    { refetchOnWindowFocus: false }
  );

  const { mutate: revokeApplication } = useMutation(
    (todo) => postData(formToken, `/authorized_applications/revoke`, todo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("AuthorizedApplications");
      },
    }
  );

  const handleDelete = (id) => {
    revokeApplication({ id });
  };
  return (
    <StyledContainer>
      <TableContainer
        columns={connectedColumns(handleDelete)}
        data={authorizedApplications ?? []}
      />
    </StyledContainer>
  );
}

function ApiManagement({ formToken, current_user, fromAdmin }) {
  const tabsItems = [
    {
      label: "API",
      key: "api",
      children: (
        <ApiManager formToken={formToken} current_user={current_user} />
      ),
    },
    {
      label: "Connectées",
      key: "connected",
      children: <AuthorizedApplicationManager formToken={formToken} />,
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      {!fromAdmin && (
        <TitleContainer label="Gestion des applications externes" />
      )}
      {fromAdmin ? (
        <ApiManager formToken={formToken} current_user={current_user} />
      ) : (
        <StyledTabs destroyInactiveTabPane items={tabsItems} />
      )}
    </div>
  );
}

const StyledTabs = styled(Tabs)`
  width: 100%;
  position: relative;
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
    width: 100%;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const RedIcon = styled.i`
  color: ${({ theme }) => theme.colors.basicorange};
  font-size: 14px;
`;

const OkIcon = styled.i`
  font-size: 14px;
`;

export default ApiManagement;
