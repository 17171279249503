import React from "react";
import { bool, string } from "prop-types";
import styled from "styled-components";
import Button from "./Button";
import Delete from "./Icons/Delete";

function DeleteButton({ label, className, loading, ...buttonProps }) {
  return (
    <StyledButton className={className} loading={loading} {...buttonProps}>
      {!loading && <StyledIcon />}
      {label}
    </StyledButton>
  );
}

const StyledIcon = styled(Delete)`
  margin-right: 0.313rem;
  color: ${({ theme }) => theme.colors.blue06} !important;
`;

const StyledButton = styled(Button)`
  padding: 0.3rem 0.5rem;
`;

DeleteButton.propTypes = {
  label: string,
  className: string,
  loading: bool,
};

DeleteButton.defaultProps = {
  label: "",
  className: "",
  loading: false,
};

export default DeleteButton;
