import React from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { useStore } from "../../store";
import { Loading } from "../../loading";
import { currency_euros_to_cents } from "../../utils";

import { postData } from "../../request/instance";
import CarrycotForm from "./CarrycotForm";
import TitleContainer from "../../react-ui/TitleContainer";
import {
  handleMarginKey,
  handlePictureKey,
} from "../../../utils/createFormData";
import AddButton from "../../react-ui/AddButton";
import Button from "../../react-ui/Button";
import { eurosToCents } from "../../../utils/currencyConverter";

const selector = (state) => ({
  selectedCarrycot: state.selectedCarrycot,
  editCarrycot: state.editCarrycot,
  formToken: state.formToken,
  updatedTodosCarrycot: state.updatedTodosCarrycot,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
function CarrycotDetails({
  carrycots,
  carrycotDetailsStatus,
  setCarrycotDetailsStatus,
  profils,
  entityWorkId,
  opportunityId,
  from,
  handleFAAndOpp,
  faIsValidate,
  faId,
}) {
  const {
    selectedCarrycot,
    formToken,
    editCarrycot,
    isUsingMargin,
    calculMethod,
  } = useStore(selector);
  const queryClient = useQueryClient();
  const { mutate: updateCarrycot, status } = useMutation(
    (todo) => postData(formToken, "/carrycot_profession/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Carrycots");
      },
      onError: () => {
        editCarrycot(selectedCarrycot);
      },
    }
  );

  const { mutate: createCarrycot, isLoading } = useMutation(
    (todo) => postData(formToken, "/carrycot_profession/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Carrycots");
      },
      onSuccess: (payload) => {
        editCarrycot(payload);
        setCarrycotDetailsStatus("update");
      },
      onError: () => setCarrycotDetailsStatus("empty"),
    }
  );

  const { mutate: updateCarrycotOpportunity, status: carrycotOppStatus } =
    useMutation(
      (todo) => postData(formToken, "/carrycot_opportunity/update", todo),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("Carrycots");
        },
        onError: () => {
          editCarrycot(selectedCarrycot);
        },
      }
    );

  const { mutate: updateCarrycotFA, status: carrycotFAStatus } = useMutation(
    (todo) => postData(formToken, "/carrycot_framework_agreement/update", todo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Carrycots");
      },
      onError: () => {
        editCarrycot(selectedCarrycot);
      },
    }
  );

  const handleRegisterCarrycotProfession = (e) => {
    const formatableKeys = [
      "picture",
      "daily_cost_cents",
      "movement_cost_cents",
      "cost_price_cents",
      "margin_rate",
    ];
    const formData = new FormData();
    if (from === "referencing")
      formData.append("carrycot_profession[opportunity_id]", opportunityId);
    else if (from === "frameworkAgreement")
      formData.append("carrycot_profession[framework_agreement_id]", faId);
    else formData.append("carrycot_profession[entity_work_id]", entityWorkId);
    Object.keys(e).forEach(
      (key) =>
        !formatableKeys.includes(key) &&
        formData.append(
          `carrycot_profession[${key}]`,
          e[key] !== undefined && e[key] !== null ? e[key] : ""
        )
    );
    if (e.daily_cost_cents) {
      formData.append(
        "carrycot_profession[daily_cost_cents]",
        currency_euros_to_cents(e.daily_cost_cents)
      );
    }
    if (e.movement_cost_cents) {
      formData.append(
        "carrycot_profession[movement_cost_cents]",
        currency_euros_to_cents(e.movement_cost_cents)
      );
    }
    if (e.cost_price_cents) {
      formData.append(
        "carrycot_profession[cost_price_cents]",
        currency_euros_to_cents(e.cost_price_cents)
      );
    }
    if (e.picture) {
      handlePictureKey({
        formData,
        picture: e.picture,
        keyName: "carrycot_profession",
      });
    }
    handleMarginKey({
      formData,
      margin_rate: e.margin_rate,
      keyName: "carrycot_profession",
      isUsingMargin,
      calculMethod,
    });
    if (carrycotDetailsStatus === "update") {
      formData.append("id", selectedCarrycot.id);
      updateCarrycot(formData);
    }
    if (carrycotDetailsStatus === "create") {
      if (from === "referencing")
        formData.append("create_for_referencing", true);
      createCarrycot(formData);
    }
  };

  const handleRegsiterCarrycotFAAndOpp = (e) => {
    if (!selectedCarrycot.carrycot_included && e.is_in_framework_agreement) {
      handleFAAndOpp(false, selectedCarrycot);
      return;
    }
    if (selectedCarrycot.carrycot_included && !e.is_in_framework_agreement) {
      handleFAAndOpp(true, selectedCarrycot);
      return;
    }
    const formData = new FormData();
    const modelName =
      from === "referencing"
        ? "carrycot_opportunity"
        : "carrycot_framework_agreement";

    if (
      e.carrycot_included_name !== null &&
      e.carrycot_included_name !== undefined
    )
      formData.append(`${modelName}[name]`, e.carrycot_included_name);
    if (e.abbreviation !== null && e.abbreviation !== undefined)
      formData.append(`${modelName}[abbreviation]`, e.abbreviation);
    if (
      e.carrycot_included_reference_code !== null &&
      e.carrycot_included_reference_code !== undefined
    )
      formData.append(
        `${modelName}[reference_code]`,
        e.carrycot_included_reference_code
      );
    if (e.description !== null && e.description !== undefined)
      formData.append(`${modelName}[description]`, e.description);
    formData.append("id", selectedCarrycot.carrycot_included.id);
    if (from === "referencing") {
      formData.append(`${modelName}[opportunity_id]`, opportunityId);
      formData.append("not_from_costing", true);
      updateCarrycotOpportunity(formData);
    } else {
      if (e.selling_price_cents !== null && e.selling_price_cents !== undefined)
        formData.append(
          `${modelName}[selling_price_cents]`,
          eurosToCents(e.selling_price_cents)
        );
      updateCarrycotFA(formData);
    }
  };

  const onSubmit = (e) => {
    if (from !== "admin" && carrycotDetailsStatus === "update") {
      handleRegsiterCarrycotFAAndOpp(e);
    } else {
      handleRegisterCarrycotProfession(e);
    }
  };

  const isShowing =
    (carrycotDetailsStatus === "update" &&
      from === "admin" &&
      selectedCarrycot.entity_work_id !== parseInt(entityWorkId, 10)) ||
    (from === "frameworkAgreement" &&
      !faIsValidate &&
      carrycotDetailsStatus === "update");

  const isUpdating = carrycotDetailsStatus === "update" && !isShowing;

  const getStatus = () => {
    if (
      carrycotOppStatus === "loading" ||
      carrycotFAStatus === "loading" ||
      status === "loading"
    )
      return "loading";
    if (
      carrycotOppStatus === "success" ||
      carrycotFAStatus === "success" ||
      status === "success"
    )
      return "success";
    return "idle";
  };

  const getName = () => {
    if (carrycotDetailsStatus !== "update") return "";
    const curItem = carrycots.find((el) => el.id === selectedCarrycot.id);
    if (!curItem?.carrycot_included?.name && !curItem?.name) return "";
    return ` : ${curItem.carrycot_included?.name || curItem.name}`;
  };

  return (
    <Container>
      <TitleContainer label={`Détails de la nacelle${getName()}`}>
        {carrycotDetailsStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={() => setCarrycotDetailsStatus("empty")}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="carrycot-form"
              loading={isLoading}
            />
          </>
        )}
        <Loading status={getStatus()} />
      </TitleContainer>
      {carrycotDetailsStatus === "empty" && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez une nacelle pour afficher les détails
          </p>
        </div>
      )}
      {carrycotDetailsStatus !== "empty" && (
        <Wrapper>
          <CarrycotForm
            updateOnChange={isUpdating}
            initialCarrycot={
              carrycotDetailsStatus === "update" ? selectedCarrycot : undefined
            }
            onSubmit={onSubmit}
            profils={profils}
            isShowing={isShowing}
            from={from}
            faIsValidate={faIsValidate}
            entityWorkId={entityWorkId}
            opportunityId={opportunityId}
            hasCarrycotIncluded={
              selectedCarrycot.carrycot_included !== null &&
              selectedCarrycot.carrycot_included !== undefined
            }
          />
        </Wrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default CarrycotDetails;
