import { Space } from "antd";
import React, { useEffect, useState } from "react";
import {
  checkIsItemInOpportunityVariable,
  updateArrayBlockStates,
} from "../../General/helper-general";
import {
  ContainerOutCard,
  GridContainer,
  StyledCard,
  StyledDivider,
} from "../../styledComponent";
import SwitchComponent from "../../SwitchComponent";

export default function ArrayBlockVariable({
  handleSaveNewValue,
  configuration,
  general,
  apiOpportunityLabels,
}) {
  const [arrayInApiOpportunity, setArrayInApiOpportunity] = useState(
    configuration?.api?.opportunity?.length > 0
      ? Object.values(configuration?.api?.opportunity)
      : []
  );

  function checkIsItemInApiOpportunity(item) {
    if (arrayInApiOpportunity?.includes(apiOpportunityLabels[item])) {
      return true;
    }
    return false;
  }

  const [devisItems, setDevisItems] = useState([
    {
      initialValue: general.prixVente
        ? checkIsItemInApiOpportunity("pricesWork")
        : false,
      isDisabled: !general.prixVente,
      labelText: "Devis détaillé sans sous-totaux",
      name: "pricesWork",
    },
    {
      initialValue: general.prixVente
        ? checkIsItemInApiOpportunity("detailedPricesWork")
        : false,
      isDisabled: !general.prixVente,
      labelText: "Devis détaillé avec sous-totaux",
      name: "detailedPricesWork",
    },
    {
      initialValue: general.prixVente
        ? checkIsItemInApiOpportunity("detailedPricesSubtotalPricesOnlyWork")
        : false,
      isDisabled: !general.prixVente,
      labelText: "Devis non détaillé avec sous-totaux",
      name: "detailedPricesSubtotalPricesOnlyWork",
    },
    {
      initialValue: general.prixVente
        ? checkIsItemInApiOpportunity("detailedPricesOnlyTotalPriceWork")
        : false,
      isDisabled: !general.prixVente,
      labelText: "Devis non détaillé",
      name: "detailedPricesOnlyTotalPriceWork",
    },
  ]);

  const [optionItems, setOptionItems] = useState([
    {
      initialValue:
        general.prixVente && general.option
          ? checkIsItemInApiOpportunity("pricesOptionWork")
          : false,
      isDisabled: !general.prixVente || !general.option,
      labelText: "Option - Devis détaillé sans sous-totaux",
      name: "pricesOptionWork",
    },
    {
      initialValue:
        general.prixVente && general.option
          ? checkIsItemInApiOpportunity("pricesOptionVatWork")
          : false,
      isDisabled: !general.prixVente || !general.option,
      labelText: "Option - Devis détaillé avec TVA",
      name: "pricesOptionVatWork",
    },
    {
      initialValue:
        general.prixVente && general.option
          ? checkIsItemInApiOpportunity("detailedPricesOptionWork")
          : false,
      isDisabled: !general.prixVente || !general.option,
      labelText: "Option - Devis détaillé avec sous-totaux",
      name: "detailedPricesOptionWork",
    },
    {
      initialValue:
        general.prixVente && general.option
          ? checkIsItemInApiOpportunity(
              "detailedPricesOptionSubtotalPricesOnlyWork"
            )
          : false,
      isDisabled: !general.prixVente || !general.option,
      labelText: "Option - Devis non détaillé avec sous-totaux",
      name: "detailedPricesOptionSubtotalPricesOnlyWork",
    },
    {
      initialValue:
        general.prixVente && general.option
          ? checkIsItemInApiOpportunity(
              "detailedPricesOptionOnlyTotalPriceWork"
            )
          : false,
      isDisabled: !general.prixVente || !general.option,
      labelText: "Option - Devis non détaillé",
      name: "detailedPricesOptionOnlyTotalPriceWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("materialsOptionWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Produits liste",
      name: "materialsOptionWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("materialsOptionZonesWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Produits liste par zone",
      name: "materialsOptionZonesWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("fonctionnalitiesOptionWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Produits fonctionnalités",
      name: "fonctionnalitiesOptionWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("fonctionnalitiesOptionZonesWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Produits fonctionnalités par zone",
      name: "fonctionnalitiesOptionZonesWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("prestationsOptionWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Prestations Liste",
      name: "prestationsOptionWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("prestationsOptionZonesWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Prestations Liste par zone",
      name: "prestationsOptionZonesWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("prestationOptionDescriptionWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Prestations (Intitulé | Description)",
      name: "prestationOptionDescriptionWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("prestationOptionPictureWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Prestations (Photo | Intitulé | Description)",
      name: "prestationOptionPictureWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("ouvragesOptionWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Ouvrages",
      name: "ouvragesOptionWork",
    },
    {
      initialValue:
        general.pageSelectionItems && general.option
          ? checkIsItemInApiOpportunity("ouvragesOptionDetailsWork")
          : false,
      isDisabled: !general.pageSelectionItems || !general.option,
      labelText: "Option - Ouvrages Détails",
      name: "ouvragesOptionDetailsWork",
    },
    {
      initialValue:
        checkIsItemInApiOpportunity("allArticlesOptionWithoutPricesWork") &&
        general.option,
      isDisabled: !general.option,
      labelText: "Option - Tous les articles",
      name: "allArticlesOptionWithoutPricesWork",
    },
    {
      initialValue:
        checkIsItemInApiOpportunity(
          "allArticlesOptionWithoutPricesZonesWork"
        ) && general.option,
      isDisabled: !general.option,
      labelText: "Option - Tous les articles par zone",
      name: "allArticlesOptionWithoutPricesZonesWork",
    },
  ]);

  const [productItems, setProductItems] = useState([
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("materialsWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Produits liste",
      name: "materialsWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("materialsZonesWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Produits liste par zone",
      name: "materialsZonesWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("fonctionnalitiesWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Produits fonctionnalités",
      name: "fonctionnalitiesWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("fonctionnalitiesZonesWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Produits fonctionnalités par zone",
      name: "fonctionnalitiesZonesWork",
    },
  ]);

  const [prestationItems, setPrestationItems] = useState([
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("prestationsWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Liste",
      name: "prestationsWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("prestationsZonesWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Liste par zone",
      name: "prestationsZonesWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("prestationDescriptionWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Intitulé | Description",
      name: "prestationDescriptionWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("prestationPictureWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Photo | Intitulé | Description",
      name: "prestationPictureWork",
    },
  ]);

  const [ouvrageArticlesItems, setOuvrageArticlesItems] = useState([
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("ouvragesWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Ouvrages",
      name: "ouvragesWork",
    },
    {
      initialValue: general.pageSelectionItems
        ? checkIsItemInApiOpportunity("ouvragesDetailsWork")
        : false,
      isDisabled: !general.pageSelectionItems,
      labelText: "Ouvrages Détails",
      name: "ouvragesDetailsWork",
    },
    {
      initialValue: checkIsItemInApiOpportunity("allArticlesWithoutPricesWork"),
      labelText: "Tous les articles",
      name: "allArticlesWithoutPricesWork",
    },
    {
      initialValue: checkIsItemInApiOpportunity(
        "allArticlesWithoutPricesZonesWork"
      ),
      labelText: "Tous les articles par zone",
      name: "allArticlesWithoutPricesZonesWork",
    },
  ]);

  const [contactItems, setContactItems] = useState([
    {
      initialValue: checkIsItemInOpportunityVariable(
        "client_contacts",
        "mandatory",
        configuration
      )
        ? checkIsItemInApiOpportunity("clientContactsWork")
        : false,
      isDisabled: !checkIsItemInOpportunityVariable(
        "client_contacts",
        "mandatory",
        configuration
      ),
      labelText: "Client facturé",
      name: "clientContactsWork",
    },
    {
      initialValue: checkIsItemInOpportunityVariable(
        "provided_customer_contacts",
        "complement",
        configuration
      )
        ? checkIsItemInApiOpportunity("providedCustomerContactsWork")
        : false,
      isDisabled: !checkIsItemInOpportunityVariable(
        "provided_customer_contacts",
        "complement",
        configuration
      ),
      labelText: "Client presté",
      name: "providedCustomerContactsWork",
    },
    {
      initialValue: checkIsItemInOpportunityVariable(
        "decision_maker_customer_contacts",
        "complement",
        configuration
      )
        ? checkIsItemInApiOpportunity("decisionMakerCustomerContactsWork")
        : false,
      isDisabled: !checkIsItemInOpportunityVariable(
        "decision_maker_customer_contacts",
        "complement",
        configuration
      ),
      labelText: "Client décisionnaire",
      name: "decisionMakerCustomerContactsWork",
    },
    {
      initialValue: checkIsItemInOpportunityVariable(
        "contacts_work",
        "complement",
        configuration
      )
        ? checkIsItemInApiOpportunity("contactsWork")
        : false,
      isDisabled: !checkIsItemInOpportunityVariable(
        "contacts_work",
        "complement",
        configuration
      ),
      labelText: "Général",
      name: "contactsWork",
    },
  ]);

  const [referenceItems, setReferenceItems] = useState([
    {
      initialValue: checkIsItemInApiOpportunity("referencesGlobal"),
      labelText: "Réferences générales",
      name: "referencesGlobal",
    },
    {
      initialValue: checkIsItemInApiOpportunity("referencesGlobalTypology"),
      labelText: "Références générales liées à la typologie",
      name: "referencesGlobalTypology",
    },
  ]);

  const [headOfficeItems, setHeadOfficeItems] = useState([
    {
      initialValue: checkIsItemInApiOpportunity("headOfficeTurnoverWork"),
      labelText: "Les 3 derniers CA",
      name: "headOfficeTurnoverWork",
    },
    {
      initialValue: checkIsItemInApiOpportunity(
        "headOfficeTurnoverRepartitionWork"
      ),
      labelText: "Les 3 derniers CA + répartition",
      name: "headOfficeTurnoverRepartitionWork",
    },
    {
      initialValue: checkIsItemInApiOpportunity("referencesGlobalHeadOffice"),
      labelText: "Références générales",
      name: "referencesGlobalHeadOffice",
    },
    {
      initialValue: checkIsItemInApiOpportunity("referencesTypologyHeadOffice"),
      labelText: "Références générales liées à la typologie",
      name: "referencesTypologyHeadOffice",
    },
  ]);

  const [entityItems, setEntityItems] = useState([
    {
      initialValue: checkIsItemInApiOpportunity("entityTurnoverWork"),
      labelText: "Les 3 derniers CA",
      name: "entityTurnoverWork",
    },
    {
      initialValue: checkIsItemInApiOpportunity(
        "entityTurnoverRepartitionWork"
      ),
      labelText: "Les 3 derniers CA + répartition",
      name: "entityTurnoverRepartitionWork",
    },
    {
      initialValue: checkIsItemInApiOpportunity("referencesGlobalEntity"),
      labelText: "Références générales",
      name: "referencesGlobalEntity",
    },
    {
      initialValue: checkIsItemInApiOpportunity("referencesTypologyEntity"),
      labelText: "Références générales liées à la typologie",
      name: "referencesTypologyEntity",
    },
  ]);

  useEffect(() => {
    setArrayInApiOpportunity(Object.values(configuration.api.opportunity));

    updateArrayBlockStates(
      configuration,
      setDevisItems,
      setOptionItems,
      setProductItems,
      setPrestationItems,
      setOuvrageArticlesItems,
      setContactItems,
      setReferenceItems,
      setHeadOfficeItems,
      setEntityItems,
      general,
      checkIsItemInApiOpportunity,
      checkIsItemInOpportunityVariable
    );
  }, [configuration]);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <StyledDivider
        orientation="left"
        style={{
          height: "24px",
          width: "100%",
          borderColor: "rgba(11, 34, 57, 1)",
          margin: "10px 0",
        }}
      >
        Tableaux
      </StyledDivider>
      <Space size="small" direction="vertical">
        {/* DEVIS */}
        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Devis"
          style={{
            width: "100%",
          }}
        >
          <GridContainer direction="vertical" size={16}>
            {devisItems.map((item) => (
              <SwitchComponent
                key={item.name}
                isDisabled={item.isDisabled}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  handleSaveNewValue(value, name, "arrayBlock");
                }}
                name={item.name}
              />
            ))}
          </GridContainer>
        </StyledCard>
        {/* PRODUCT */}
        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Produits"
          style={{
            width: "100%",
          }}
        >
          <GridContainer direction="vertical" size={16}>
            {productItems.map((item) => (
              <SwitchComponent
                key={item.name}
                isDisabled={item.isDisabled}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  handleSaveNewValue(value, name, "arrayBlock");
                }}
                name={item.name}
              />
            ))}
          </GridContainer>
        </StyledCard>
        {/* PRESTATION */}
        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Prestations"
          style={{
            width: "100%",
          }}
        >
          <GridContainer direction="vertical" size={16}>
            {prestationItems.map((item) => (
              <SwitchComponent
                key={item.name}
                isDisabled={item.isDisabled}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  handleSaveNewValue(value, name, "arrayBlock");
                }}
                name={item.name}
              />
            ))}
          </GridContainer>
        </StyledCard>
        {/* Ouvrage */}
        <ContainerOutCard
          style={{
            backgroundColor: "#F5F6F8",
            borderRadius: 10,
            width: "100%",
          }}
        >
          <GridContainer>
            {ouvrageArticlesItems.map((item) => (
              <SwitchComponent
                key={item.name}
                isDisabled={item.isDisabled}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  handleSaveNewValue(value, name, "arrayBlock");
                }}
                name={item.name}
              />
            ))}
          </GridContainer>
        </ContainerOutCard>
        {/* OPTION */}
        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Option"
          style={{
            width: "100%",
          }}
        >
          <GridContainer direction="vertical" size={16}>
            {optionItems.map((item) => (
              <SwitchComponent
                key={item.name}
                isDisabled={item.isDisabled}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  handleSaveNewValue(value, name, "arrayBlock");
                }}
                name={item.name}
              />
            ))}
          </GridContainer>
        </StyledCard>
        {/* Contact */}
        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Contacts"
          style={{
            width: "100%",
          }}
        >
          <GridContainer direction="vertical" size={16}>
            {contactItems.map((item) => (
              <SwitchComponent
                key={item.name}
                isDisabled={item.isDisabled}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  handleSaveNewValue(value, name, "arrayBlock");
                }}
                name={item.name}
              />
            ))}
          </GridContainer>
        </StyledCard>
        {/* Reference */}
        <ContainerOutCard
          style={{
            backgroundColor: "#F5F6F8",
            borderRadius: 10,
            width: "100%",
          }}
        >
          <GridContainer direction="vertical" size={16}>
            {referenceItems.map((item) => (
              <SwitchComponent
                key={item.name}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  handleSaveNewValue(value, name, "arrayBlock");
                }}
                name={item.name}
              />
            ))}
          </GridContainer>
        </ContainerOutCard>

        {/* Head Office */}
        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Siège social"
          style={{
            width: "100%",
          }}
        >
          <GridContainer direction="vertical" size={16}>
            {headOfficeItems.map((item) => (
              <SwitchComponent
                key={item.name}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  handleSaveNewValue(value, name, "arrayBlock");
                }}
                name={item.name}
              />
            ))}
          </GridContainer>
        </StyledCard>

        {/* Entity */}
        <StyledCard
          headStyle={{ backgroundColor: "#0B2239", color: "white" }}
          bodyStyle={{ backgroundColor: "#F5F6F8" }}
          size="small"
          title="Agence concernée"
          style={{
            width: "100%",
          }}
        >
          <GridContainer direction="vertical" size={16}>
            {entityItems.map((item) => (
              <SwitchComponent
                key={item.name}
                initialValue={item.initialValue}
                labelText={item.labelText}
                handleChange={(value, name) => {
                  handleSaveNewValue(value, name, "arrayBlock");
                }}
                name={item.name}
              />
            ))}
          </GridContainer>
        </StyledCard>
      </Space>
    </div>
  );
}
