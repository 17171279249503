import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import ExternalOrderForm from "./ExternalOrderForm";
import ExternalOrdersArray from "./ExternalOrdersArray";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import { fetchExternalOrdersUrl } from "../../../../utils/fetchExternalOrdersUrl";

const selector = (state) => ({
  formToken: state.formToken,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function ExternalOrders({ clients, entities, works, users, selectedClientId }) {
  const [status, setStatus] = useState();
  const [wordEntered, setWordEntered] = useState();
  const [selectedExternalOrder, setSelectedExternalOrder] = useState();
  const { formToken } = useStore(selector);

  const {
    data: externalOrders,
    refetch,
    isLoading: externalOrdersAreLoading,
  } = useQuery(
    "ExternalOrders",
    () =>
      getData(
        formToken,
        fetchExternalOrdersUrl({
          search: wordEntered,
          clientId: selectedClientId,
        })
      ),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  useEffect(() => {
    refetch();
  }, [refetch, selectedClientId]);

  const closeModal = useCallback(() => {
    setStatus();
    setSelectedExternalOrder();
  }, []);
  return (
    <Container>
      <StyledModal
        open={status !== undefined}
        maskClosable={false}
        footer={null}
        width={900}
        destroyOnClose
        onCancel={closeModal}
        closable={false}
      >
        <ExternalOrderForm
          status={status}
          clients={clients}
          closeModal={closeModal}
          entities={entities}
          works={works}
          users={users}
          selectedClientId={selectedClientId}
          selectedExternalOrder={selectedExternalOrder}
        />
      </StyledModal>
      <ExternalOrdersArray
        externalOrders={externalOrders}
        externalOrdersAreLoading={externalOrdersAreLoading}
        selectedExternalOrder={selectedExternalOrder}
        setSelectedExternalOrder={setSelectedExternalOrder}
        setStatus={setStatus}
        entities={entities}
        clients={clients}
        works={works}
        wordEntered={wordEntered}
        setWordEntered={setWordEntered}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 300px;
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

export default ExternalOrders;
