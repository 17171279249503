import { encodeUrlParams } from "./encodeUrlParams";
import { sendCompatibleMaterials } from "./sendCompatibleMaterials";

const getUrl = (from) => {
  switch (from) {
    case "admin":
      return "/prestation_profession?";
    case "quotation":
      return "/prestation_framework_agreements?";
    default:
      return "/prestation_by_opportunity?";
  }
};

export const fetchPrestationsUrl = ({
  from,
  id,
  search,
  opportunityType,
  compatibleMaterials = [],
  oppId,
  sortBy,
  parent,
  parentId,
  from_zone_template = false,
  selectedZoneTemplate
}) => {
  let url = getUrl(from);
  const params = {
    from,
    ...(sortBy && { sort_by: sortBy.field, order: sortBy.order }),
    ...(search && { search }),
    ...(parent && { parent }),
    ...(parentId && { parent_id: parentId }),
    ...(from === "admin" && { id }),
    ...(from === "consultation" && { id }),
    ...(from === "quotation" && {
      framework_agreement_id: id,
      type: opportunityType,
      id_opportunity: oppId,
    }),
    ...(from === "referencing" && {
      id_opportunity: id,
      prestation_opportunities: true,
      include_entities_child: true,
    }),
    ...(from_zone_template && { from_zone_template }),
    ...(selectedZoneTemplate && { selected_zone_template_id: selectedZoneTemplate.id }),
    ...(from === "frameworkAgreement" && {
      prestation_framework_agreements: true,
      framework_agreement_id: id,
      include_entities_child: true,
    }),
  };

  url += encodeUrlParams(params);
  url += sendCompatibleMaterials(compatibleMaterials);

  return url;
};

export const fetchPrestationByIdUrl = ({ from, id }) => {
  let url = "/prestation_by_id?";
  const params = {
    id,
    ...(from === "referencing" && { referencing: true }),
    ...(from === "frameworkAgreement" && { framework_agreement: true }),
  };

  url += encodeUrlParams(params);

  return url;
};
