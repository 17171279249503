import { Input, notification } from "antd";
import React from "react";
import {
  QueryClientProvider,
  QueryClient,
  MutationCache,
  QueryCache,
  useMutation,
} from "react-query";
import SearchBox from "./SearchBox";
import { postData } from "../request/instance";

const queryClient = new QueryClient();

function SearchBoxWrapper({ token, vocabulary_application }) {
  return (
    <QueryClientProvider client={queryClient}>
      <SearchBox
        token={token}
        vocabulary_application={vocabulary_application}
      />
    </QueryClientProvider>
  );
}

export default SearchBoxWrapper;
