import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["type", "price", "monthly_price", "amortisation"]
  connect() {
      this.updateDisplay()
  }
  updateDisplay() {
    if(this.typeTarget.value === "buy") {
        this.priceTarget.classList.remove("material-disable-input")
        this.amortisationTarget.classList.remove("material-disable-input")
        this.monthly_priceTarget.classList.add("material-disable-input")
    } else if(this.typeTarget.value === "rent") {
        this.priceTarget.classList.add("material-disable-input")
        this.amortisationTarget.classList.add("material-disable-input")
        this.monthly_priceTarget.classList.remove("material-disable-input")
    } else {
        this.priceTarget.classList.add("material-disable-input")
        this.amortisationTarget.classList.add("material-disable-input")
        this.monthly_priceTarget.classList.add("material-disable-input")
    }
  }

  validateNumbersOnly = (event) => {
    event.currentTarget.value= event.currentTarget.value.replace(/\./g, ',')
    let nbCommas = (event.currentTarget.value.match(/,/g) || []).length
    if (nbCommas > 1){
      let commaPosition = event.currentTarget.value.indexOf(",")
      let beforeComma = event.currentTarget.value.substring(0,commaPosition+1)
      let afterComma = event.currentTarget.value.substring(commaPosition +1, event.currentTarget.value.length)
      afterComma = afterComma.replace(/\,/g, '')
      let newString=beforeComma + afterComma
      event.currentTarget.value=newString
    }
  event.currentTarget.value= event.currentTarget.value.replace(/[^0-9 ,]/g, '')
  }
}