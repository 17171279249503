/* eslint-disable consistent-return */
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Tree } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import TableContainer from "../../../../CostingMovementsCarrycots/TableContainer";
import { getData } from "../../../../../request/instance";
import { useLazyQuery } from "../../../../../../hooks/useLazyQuery";
import { useStore } from "../../../../../store";
import { quantityTimeConverter } from "../../../../../../utils/quantityTimeConverter";

const selector = (state) => ({
  isUsingMinutes: state.isUsingMinutes,
});
function OuvrageContent({
  OuvrageColumns,
  parentOuvrage,
  formToken,
  configuration,
}) {
  const [treeData, setTreeData] = useState([]);
  const { isUsingMinutes } = useStore(selector);
  const [fetchOuvrage] = useLazyQuery(
    ["Ouvrage", { id: parentOuvrage.id }],
    // hardcode id ouvrage 7937
    () => getData(formToken, `/ouvrage_opportunity/${parentOuvrage.id}`),
    {
      onSuccess: (payload) => {
        setTreeData(childrenPopulate(payload.ouvrage.Elements));
      },
    }
  );

  const itemSelector = (ElementType) => {
    if (ElementType === "O") {
      return <i className={configuration.general.ouvrage_icon} />;
    }
    if (ElementType === "M") {
      return <i className={configuration.general.product_icon} />;
    }
    if (ElementType === "P") {
      return <i className={configuration.general.prestation_icon} />;
    }
  };

  const columnsConfiguration = useCallback(
    (index) => {
      if (index === "cost_unitary" || index === "cost_total") {
        return configuration.costing.page_3.show_cost_price ? "flex" : "none";
      }
      if (index === "margin_rate") {
        return configuration.costing.page_3.show_margin ? "flex" : "none";
      }
    },
    [
      configuration.costing.page_3.show_cost_price,
      configuration.costing.page_3.show_margin,
    ]
  );

  const populataColumnsTree = (element) => {
    let type_element = "";
    if (element.ElementType === "P") {
      type_element = "prestation";
      element.hour_quantity = quantityTimeConverter({
        quantity: element.hour_quantity,
        isUsingMinutes,
      });
      element.unity_quantity = quantityTimeConverter({
        quantity: element.unity_quantity,
        isUsingMinutes,
      });
    }
    if (element.ElementType === "M") {
      type_element = "material";
    }
    if (element.ElementType === "O") {
      type_element = "ouvrage";
    }

    return OuvrageColumns.slice(1).map((v) =>
      v.children?.length > 0 ? (
        v.children.map((e) => (
          <div
            style={{
              width: `${
                OuvrageColumns[OuvrageColumns.length - 1].title === e.title
                  ? e.width - 4
                  : e.width + 1
              }px`,
              border: "1px #e0e7ed solid",
              padding: 5,
              marginLeft: -1,
              marginBottom: -1,
              display: columnsConfiguration(e.dataIndex),
              justifyContent: "right",
            }}
          >
            {e.render(element.Label, {
              flag: type_element,
              ouvrage_parent_id: parentOuvrage.id,
              ...element,
            })}
          </div>
        ))
      ) : (
        <div
          style={{
            width: `${
              OuvrageColumns[OuvrageColumns.length - 1].title === v.title
                ? v.width - 4
                : v.width + 1
            }px`,
            border: "1px #e0e7ed solid",
            padding: 5,
            marginLeft: -1,
            marginBottom: -1,
            display: columnsConfiguration(v.dataIndex),
          }}
        >
          {v?.render(element.Label, {
            flag: type_element,
            ouvrage_parent_id: parentOuvrage.id,
            ...element,
          })}
        </div>
      )
    );
  };

  const childrenPopulate = (elements) => {
    if (elements?.length === 0) return;
    return elements?.map((v) => ({
      title: (
        <div style={{ fontSize: 10, backgroundColor: "#eef5fc" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <div>
              {" "}
              {itemSelector(v.ElementType)} {v.name}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {populataColumnsTree(v)}
            </div>
          </div>
        </div>
      ),
      key: v.id,
      children: childrenPopulate(v.Elements),
    }));
  };

  useEffect(() => {
    fetchOuvrage();
  }, [parentOuvrage]);

  return (
    <div className="page3 tree_costing-table">
      <Tree
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
        showLine
        switcherIcon={<PlusOutlined />}
        treeData={treeData}
      />
    </div>
  );
}

export default OuvrageContent;
