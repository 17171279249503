/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment/moment";
import { Form, Badge } from "antd";
import { useMutation, useQuery } from "react-query";
import FormCol from "../../Form/FormCol";
import FormRow from "../../Form/FormRow";
import ReactDatePicker from "../../Form/ReactDatePicker";
import Input from "../../react-ui/Input";
import Label from "../../Form/Label";
import { deleteData, putData, getData } from "../../request/instance";
import { currency_cents_to_euros, getUrlParams } from "../../utils";
import { formatNumberString } from "../../../utils/formatNumberString";
import EyeButton from "../../react-ui/EyeButton";
import DeleteButton from "../../react-ui/DeleteButton";
import DocumentsButton from "../../react-ui/DocumentsButton";
import SaveButton from "../../react-ui/SaveButton";

function OfferForm({
  offer,
  token,
  offer_id,
  configApplication,
  openModal,
  supervisor,
}) {
  const { data: numDocuments } = useQuery(["numDocuments", offer_id], () =>
    getData(token, `/offers/${offer_id}/documents_size`)
  );

  const [startDate, setStartDate] = useState(
    new Date(moment(offer.offer.delivery_date).format("YYYY/MM/DD"))
  );
  const [titleValueForm, setTitleValueForm] = useState("default");

  const dateToSave = (date) => {
    if (typeof date.delivery_date === "undefined") {
      return moment(startDate).format("YYYY-MM-DD");
    }
    return moment(date.delivery_date).format("YYYY-MM-DD");
  };

  const { mutate: update_offer, isLoading: loadingUpdateOffer } = useMutation(
    (data) =>
      putData(token, `/offers/${offer_id}`, {
        name: data.name,
        delivery_date: dateToSave(data),
      })
  );

  const { mutate: delete_offer } = useMutation(
    () => deleteData(token, `/offers/${offer_id}`),
    { onSuccess: () => (window.location = "/offers") }
  );

  const handleDeleteOffer = () => {
    delete_offer();
  };

  const handleSubmit = (value) => {
    update_offer(value);
  };
  return (
    <Form
      onFinish={handleSubmit}
      className="form__offer"
      style={{ width: "100%" }}
    >
      <Container>
        <div style={{ width: "50%" }}>
          <FormRow marginTop="0px">
            <FormCol labelOnTop width="100%" label="Intitulé" name="name">
              <Input
                size="small"
                defaultValue={offer?.offer.name}
                style={{ height: 29 }}
                onChange={(e) => setTitleValueForm(e.target.value)}
                disabled={configApplication.common.offer.disable_name}
              />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol
              labelOnTop
              width="100%"
              label="Date limite de réponse"
              name="delivery_date"
            >
              <StyledDatePicker
                placeholderText="Sélectionnez une date"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                disabled={configApplication.common.offer.disable_limit_date}
              />
            </FormCol>
          </FormRow>
        </div>
        <StyledInformationOffer>
          <StyledLabelValue>
            <Label label="Entreprise(s) facturée(s) :" />
            <div>{offer.client_list}</div>
          </StyledLabelValue>
          <StyledLabelValue>
            <Label label="Chiffre d'affaires estimé :" />
            <div>
              {formatNumberString({
                str: currency_cents_to_euros(offer.calculate_previsional * 100),
                nbDecimal: 2,
                space: true,
              })}{" "}
              €
            </div>
          </StyledLabelValue>
          <StyledLabelValue>
            <Label label="Chiffre d'affaires calculé :" />
            <div>
              {formatNumberString({
                str: currency_cents_to_euros(offer.revenues),
                nbDecimal: 2,
                space: true,
              })}{" "}
              €
            </div>
          </StyledLabelValue>
        </StyledInformationOffer>
      </Container>
      <StyledActionForm>
        {offer.offer_review && (
          <EyeButton
            onClick={() => {
              window.location = `/offers/${offer.offer.id}/offer_review`;
            }}
            label="Revue d'offre"
            fontSize="14px"
          />
        )}
        <ContainerDocuments>
          <StyledBadge count={numDocuments} overflowCount={999} />
          <DocumentsButton
            label={`Documents ${
              supervisor ? "de l'offre" : "et construction de dossiers"
            }`}
            fontSize="14px"
            onClick={openModal}
            disabled={!offer.documents}
          />
        </ContainerDocuments>
        {offer.editor && (
          <div
            className="d-flex btn btn-xl btn-editor button__editor"
            onClick={() =>
              window.open(
                `/offers/${offer.offer.id}/proposal_editor_redirection?redirect_url=${process.env.EDITOR_URL}`,
                "_blank"
              )
            }
            style={{ width: 130, gridColumn: 4 }}
          >
            <i className=" fa-duotone fa-file-alt mr-2" />
            <div className="d-flex align-items-center ">
              <p className="mb-0">Editor</p>
            </div>
          </div>
        )}
        {!offer.editor && (
          <div
            className="d-flex btn btn-xl btn-editor-disabled button__editor"
            style={{ width: 130, gridColumn: 4 }}
          >
            <i className=" fa-duotone fa-file-alt mr-2" />
            <div className="d-flex align-items-center ">
              <p className="mb-0">Editor</p>
            </div>
          </div>
        )}

        {offer.opportunities.length === 0 &&
          !configApplication.common.offer.disable_delete_button && (
            <StyledDeleteButton
              label="Supprimer"
              fontSize="14px"
              onClick={() => handleDeleteOffer()}
            />
          )}
        {offer.opportunities.length === 0 &&
          configApplication.common.offer.disable_delete_button && (
            <StyledDeleteButton label="Supprimer" fontSize="14px" disabled />
          )}
        <SaveButton
          label="Enregistrer"
          className="i"
          type="submit"
          value="submit"
          fontSize="14px"
          style={{ marginLeft: "auto" }}
          loading={loadingUpdateOffer}
          disabled={
            !moment(startDate).isValid() ||
            titleValueForm === "" ||
            configApplication.common.offer.disable_save_button
          }
        />
      </StyledActionForm>
    </Form>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  width: 100%;
  align-items: center;
`;

const StyledInformationOffer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2em;
  gap: 2em;
`;

const StyledDatePicker = styled(ReactDatePicker)`
  width: 100%;
`;

const StyledLabelValue = styled.div`
  display: flex;
  justify-content: no-space-between;
  gap: 4px;
`;

const StyledActionForm = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin: 16px 0;
  flex-wrap: wrap;
`;

const StyledDeleteButton = styled(DeleteButton)`
  margin-left: auto;
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -40%);
`;

const ContainerDocuments = styled.div`
  position: relative;
  display: inline-block;
`;

export default OfferForm;
