import { Form } from "antd";
import React from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { validateMessages } from "../../constant";
import FormCol from "../../Form/FormCol";
import Input from "../../react-ui/Input";
import UserRights from "./Form/UserRights";
import Separator from "../../react-ui/Separator";
import FormRow from "../../Form/FormRow";
import TitleContainer from "../../react-ui/TitleContainer";
import Button from "../../react-ui/Button";
import AddButton from "../../react-ui/AddButton";
import { postData } from "../../request/instance";
import { useStore } from "../../store";

const selector = (state) => ({
  formToken: state.formToken,
});

function InviteForm({
  roles,
  entities,
  works,
  allUsers,
  closeModal,
  generateUserFormData,
  sites,
}) {
  const { formToken } = useStore(selector);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { mutate: createUser, isLoading } = useMutation(
    (todo) => postData(formToken, `/user_invitations`, todo),
    {
      onSettled: () => {
        closeModal();
        queryClient.invalidateQueries("Users");
      },
    }
  );

  const handleSubmit = (values) => {
    const formData = generateUserFormData(values);
    createUser(formData);
  };
  return (
    <Form
      form={form}
      colon={false}
      requiredMark={false}
      initialValues={{
        user_entity_work_ids: [],
      }}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
    >
      <TitleContainer label="Inviter un utilisateur">
        <StyledButton
          label="Annuler"
          onClick={closeModal}
          fontSize="14px"
          btnType="cancel"
          type="button"
        />
        <AddButton
          label="Inviter"
          type="submit"
          value="submit"
          fontSize="14px"
          loading={isLoading}
        />
      </TitleContainer>
      <FormContainer>
        <FormRow>
          <FormCol
            withoutPaddingRight
            label="Adresse email / Identifiant de connexion"
            name="email"
            labelOnTop
            rules={[
              { required: true },
              {
                type: "email",
                message: "Email invalide.",
              },
            ]}
          >
            <Input />
          </FormCol>
        </FormRow>
        <Separator isHorizontal size={1} />
        <UserRights
          isUpdating
          isShowing={false}
          roles={roles}
          entities={entities}
          works={works}
          allUsers={allUsers}
          form={form}
          sites={sites}
        />
      </FormContainer>
    </Form>
  );
}

const FormContainer = styled.div`
  padding: 0 15px 10px;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default InviteForm;
