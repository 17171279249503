import React from "react";

export const agent_tour = [
  {
    title: <h2>Découvrez la nouvelle page Personnel</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Nouveautés majeures : <br />
        <ul>
          <li>
            Possibilité de garder les informations de certains agents lorsqu'un
            poste perd des ETP *.
          </li>
          <li>
            Division d'un agent temps plein en plusieurs agents temps partiels.
          </li>
          <li>Conversion du taux horaire en salaire brut mensuel.</li>
          <li>
            Création de primes et d'indemnités pour tous les agents en une seule
            action.
          </li>
        </ul>
        <p>
          * Exemple: Lorsque je modifie un poste H24 ayant 5,45 ETP à un poste
          7h-19h du lundi au vendredi ayant 1,89 ETP, je dois sélectionner les 4
          agents que je ne veux plus prendre en compte pour ce poste.
        </p>
      </div>
    ),
    placement: "center",
    target: "body",
  },
  {
    title: <h2>Division d'agent</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce bouton vous permet de diviser un agent temps plein en plusieurs
        agents temps partiels.
        <br />
        Vous pouvez choisir le nombre d'agents voulu ainsi que le pourcentage
        pour chacun d'entre eux.
        <br />
        <br />
        Les agents temps partiels créés à partir de cette division peuvent être
        supprimés - sauf un seul agent considéré comme le "parent".
      </div>
    ),
    spotlightPadding: 5,
    target: ".split-agent-btn",
  },
  {
    title: <h2>Conversion du taux horaire en salaire brut mensuel</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        En cliquant sur ce sélecteur vous pouvez choisir de convertir le taux
        horaire en salaire brut mensuel et inversément pour un agent.
      </div>
    ),
    spotlightPadding: 5,
    target: ".periodicity-select",
  },
  {
    title: <h2>Conversion du taux horaire en salaire brut mensuel</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        La conversion peut également être faite de manière simultanée sur
        plusieurs postes grace au bouton "Appliquer les valeurs".
      </div>
    ),
    spotlightPadding: 5,
    target: ".periodicity-select-modal",
  },
  {
    title: <h2>Création de primes et d'indemnités pour tous les agents</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Vous pouvez désormais créer des primes et indemnités communes à
        plusieurs postes en une seule action.
      </div>
    ),
    spotlightPadding: 5,
    target: ".indemnity-work-bonuses-modal",
  },
];
