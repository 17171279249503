import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { notification } from "antd";
import { useStore } from "../../store";
import { Loading } from "../../loading";

import { getData, postData } from "../../request/instance";
import PrestationForm from "./PrestationForm";
import TitleContainer from "../../react-ui/TitleContainer";
import {
  createPrestationProfessionForm,
  handleDriveLinkKey,
} from "../../../utils/createFormData";
import AddButton from "../../react-ui/AddButton";
import Button from "../../react-ui/Button";
import { eurosToCents } from "../../../utils/currencyConverter";
import { quantityTimeConverter } from "../../../utils/quantityTimeConverter";
import { stringToFloat } from "../../../utils/stringToFloat";
import { formatNumberString } from "../../../utils/formatNumberString";
import { getUrlParams } from "../../utils";

const selector = (state) => ({
  selectedPrestation: state.selectedPrestation,
  editPrestation: state.editPrestation,
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
  isUsingMinutes: state.isUsingMinutes,
});
function PrestationDetails({
  prestations,
  categories,
  unities,
  profils,
  prestationDetailsStatus,
  setPrestationDetailsStatus,
  entityWorkId,
  opportunityId,
  from,
  handleFAAndOpp,
  faIsValidate,
  faId,
  majorations,
  defaultMajoration,
  entity_work_name,
  configuration,
  vocabulary,
  defaultMinutes,
  use_special_tva,
}) {
  const queryClient = useQueryClient();
  const {
    selectedPrestation,
    formToken,
    editPrestation,
    isUsingMargin,
    calculMethod,
    isUsingMinutes,
  } = useStore(selector);
  const [currentPrestation, setCurrentPrestation] = useState(null);

  const { data: tva_professions } = useQuery("TvaProfessionsList", () =>
    getData(formToken, `/tva_professions_list/${getUrlParams()}`)
  );

  const { mutate: updatePrestation, status } = useMutation(
    (todo) => postData(formToken, "/prestation_profession/update", todo),
    {
      onSettled: (payload) => {
        setCurrentPrestation(payload);
        queryClient.invalidateQueries("Prestations");
        queryClient.invalidateQueries("TvaSelector");
      },
      onError: () => {
        editPrestation(selectedPrestation);
      },
    }
  );

  useEffect(() => {
    setCurrentPrestation(selectedPrestation);
  }, [selectedPrestation]);

  const { mutate: createPrestation, isLoading } = useMutation(
    (todo) => postData(formToken, "/prestation_profession/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Prestations");
      },
      onSuccess: (payload) => {
        editPrestation(payload);
        setPrestationDetailsStatus("update");
      },
      onError: () => setPrestationDetailsStatus("empty"),
    }
  );

  const { mutate: updatePrestationOpportunity, status: prestationOppStatus } =
    useMutation(
      (todo) => postData(formToken, "/prestation_opportunity/update", todo),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("Prestations");
        },
        onError: () => {
          editPrestation(selectedPrestation);
        },
      }
    );

  const { mutate: updatePrestationFA, status: prestationFAStatus } =
    useMutation(
      (todo) =>
        postData(formToken, "/prestation_framework_agreement/update", todo),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("Prestations");
        },
        onError: () => {
          editPrestation(selectedPrestation);
        },
      }
    );

  const { mutate: duplicatePrestation, isLoading: duplicateLoading } =
    useMutation(
      (todo) =>
        postData(formToken, "/prestation_profession/duplicate_for_fa", todo),
      {
        onSettled: () => {
          queryClient.invalidateQueries("Prestations");
        },
        onSuccess: (payload) => {
          editPrestation(payload);
          notification.info({
            placement: "bottom",
            description: "Prestation dupliquée",
          });
        },
      }
    );

  const handleRegisterPrestationProfession = (e) => {
    const profil = e.is_package
      ? null
      : profils.find((el) => el.id === e.profil_profession_id);
    const formData = createPrestationProfessionForm(
      e,
      profil,
      isUsingMargin,
      calculMethod,
      isUsingMinutes,
      currentPrestation
    );
    if (use_special_tva) {
      if (e?.tva_profession_id) {
        formData.append("tva_profession_id", e.tva_profession_id);
      }
      if (e?.tva_profession_id === undefined) {
        formData.append("tva_profession_id", "none");
      }
    }
    if (formData.get("minutes") === true)
      formData.append(
        "minutes",
        formatNumberString({
          str: quantityTimeConverter({
            quantity: stringToFloat(formData.get("hour_quantity")),
            isUsingMinutes: true,
            toMinutes: true,
          }),
        })
      );
    if (from === "referencing")
      formData.append("prestation_profession[opportunity_id]", opportunityId);
    else formData.append("prestation_profession[entity_work_id]", entityWorkId);
    if (prestationDetailsStatus === "update") {
      formData.append("id", selectedPrestation.id);
      updatePrestation(formData);
    }
    if (prestationDetailsStatus === "create") {
      if (from === "referencing")
        formData.append("create_for_referencing", true);
      if (from === "frameworkAgreement")
        formData.append("framework_agreement_id", faId);
      createPrestation(formData);
    }
  };

  const handleRegsiterPrestationFAAndOpp = (e) => {
    if (
      !selectedPrestation.prestation_included &&
      e.is_in_framework_agreement
    ) {
      handleFAAndOpp(false, selectedPrestation);
      return;
    }
    if (
      selectedPrestation.prestation_included &&
      !e.is_in_framework_agreement
    ) {
      handleFAAndOpp(true, selectedPrestation);
      return;
    }
    const formData = new FormData();
    const modelName =
      from === "referencing"
        ? "prestation_opportunity"
        : "prestation_framework_agreement";

    if (
      e.prestation_included_name !== null &&
      e.prestation_included_name !== undefined
    )
      formData.append(`${modelName}[name]`, e.prestation_included_name);
    if (
      e.prestation_included_reference_code !== null &&
      e.prestation_included_reference_code !== undefined
    )
      formData.append(
        `${modelName}[reference_code]`,
        e.prestation_included_reference_code
      );
    if (e.description !== null && e.description !== undefined)
      formData.append(`${modelName}[description]`, e.description);
    if (
      e.majoration_opportunity_id !== null &&
      e.majoration_opportunity_id !== undefined
    )
      formData.append(
        `${modelName}[majoration_opportunity_id]`,
        e.majoration_opportunity_id
      );
    if (
      e.majoration_profession_id !== null &&
      e.majoration_profession_id !== undefined
    )
      formData.append(
        `${modelName}[majoration_profession_id]`,
        e.majoration_profession_id
      );
    if (e.drive_link) {
      handleDriveLinkKey({
        formData,
        drive_link: e.drive_link,
        keyName: modelName,
      });
    }
    formData.append("id", selectedPrestation.prestation_included.id);
    if (from === "referencing") {
      formData.append(`${modelName}[opportunity_id]`, opportunityId);
      updatePrestationOpportunity(formData);
    } else {
      if (e.selling_price_cents !== null && e.selling_price_cents !== undefined)
        formData.append(
          `${modelName}[selling_price_cents]`,
          eurosToCents(e.selling_price_cents)
        );
      updatePrestationFA(formData);
    }
  };

  const onSubmit = (e) => {
    if (from !== "admin" && prestationDetailsStatus === "update") {
      handleRegsiterPrestationFAAndOpp(e);
    } else {
      handleRegisterPrestationProfession(e);
    }
  };

  const getStatus = () => {
    if (
      prestationOppStatus === "loading" ||
      prestationFAStatus === "loading" ||
      status === "loading"
    )
      return "loading";
    if (
      prestationOppStatus === "success" ||
      prestationFAStatus === "success" ||
      status === "success"
    )
      return "success";
    return "idle";
  };

  const getName = () => {
    if (prestationDetailsStatus !== "update") return "";
    const curItem = prestations.find((el) => el.id === selectedPrestation.id);
    if (!curItem?.prestation_included?.name && !curItem?.name) return "";
    return ` : ${curItem.prestation_included?.name || curItem.name}`;
  };

  return (
    <Container>
      <TitleContainer label={`Détails de la prestation${getName()}`}>
        {prestationDetailsStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={() => setPrestationDetailsStatus("empty")}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="prestation-form"
              loading={isLoading}
            />
          </>
        )}
        {prestationDetailsStatus === "update" &&
          !!selectedPrestation.prestation_included &&
          (from === "referencing" || faIsValidate) && (
            <AddButton
              loading={duplicateLoading}
              label="Dupliquer"
              type="button"
              value="button"
              fontSize="14px"
              onClick={() => {
                if (from === "referencing")
                  duplicatePrestation({
                    id: selectedPrestation.id,
                    opportunity_id: opportunityId,
                  });
                else
                  duplicatePrestation({
                    id: selectedPrestation.id,
                    framework_agreement_id: faId,
                  });
              }}
            />
          )}
        <Loading status={getStatus()} />
      </TitleContainer>
      {prestationDetailsStatus === "empty" && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez une prestation pour afficher les détails
          </p>
        </div>
      )}
      {prestationDetailsStatus !== "empty" && (
        <Wrapper>
          <PrestationForm
            use_special_tva={use_special_tva}
            tva_professions={tva_professions}
            updateOnChange={prestationDetailsStatus === "update"}
            majorations={majorations}
            initialPrestation={
              prestationDetailsStatus === "update"
                ? selectedPrestation
                : undefined
            }
            defaultMajoration={defaultMajoration}
            profils={profils}
            categories={categories}
            unities={unities}
            onSubmit={onSubmit}
            from={from}
            currentPrestation={currentPrestation}
            faIsValidate={faIsValidate}
            hasPrestationIncluded={
              selectedPrestation.prestation_included !== null &&
              selectedPrestation.prestation_included !== undefined
            }
            isShowing={
              from === "frameworkAgreement" &&
              !faIsValidate &&
              prestationDetailsStatus === "update"
            }
            isCreating={prestationDetailsStatus === "create"}
            opportunityId={opportunityId}
            entity_work_name={entity_work_name}
            configuration={configuration}
            vocabulary={vocabulary}
            entityWorkId={entityWorkId}
            defaultMinutes={defaultMinutes}
          />
        </Wrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default PrestationDetails;
