export const generateFormData = ({ e, formName, excludedKeys = [] }) => {
  const formData = new FormData();
  Object.keys(e).forEach(
    (key) =>
      !excludedKeys.includes(key) && key !== undefined && key !== null && key !== "" &&
      formData.append(
        `${formName}[${key}]`,
        e[key] !== undefined && e[key] !== null ? e[key] : ""
      )
  );
  return formData;
};
