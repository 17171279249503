import React from "react";
import { instance, postData, getData } from "./instance";
import { useStore } from "../store";
import { useMutation, useQuery } from "react-query";

const getUrlParams = () => {
    const lastSegment = window.location.pathname.split("/").pop();
    if (!lastSegment) return "error"
    return lastSegment
};



export const fetchPrestationProfession = (formToken) => {
    const { data, isSuccess, refetch} = useQuery('getPrestationProfessions', () => getData(formToken, `/prestation_profession/${getUrlParams()}`))
    return data
}

//a facto

export const fetchPrestationProfessionOpportunity = (formToken, id_opportunity) => {
    const { data, isSuccess, refetch} = useQuery('getPrestationProfessions', () => getData(formToken, `/prestation_by_opportunity/${id_opportunity}`))
    return data
}

export const updatePrestations = (formToken) => useMutation(updateTodo => postData(formToken, "/prestation_profession/update", updateTodo))

export const createPrestations = (formToken) => useMutation(createTodo => postData(formToken, "/prestation_profession/create", createTodo))

export const deletePrestations = (formToken) => useMutation(deleteTodo => postData(formToken, "/prestation_profession/delete", deleteTodo))
export const fetchPrestationAssociate = (formToken) => {
    const {data} = useQuery('getPrestationAssociate', () => getData(formToken, `/prestation_associate`))
    return data
}

export const deletePrestationAssociate = (formToken) => useMutation(deleteTodo => postData(formToken, "/prestation_associate/delete", deleteTodo))

export const createPrestationAssociate = (formToken) => useMutation(createTodo => postData(formToken, "/prestation_associate/create", createTodo))

export const updatePrestationAssociate = (formToken) => useMutation(updateTodo => postData(formToken, "/prestation_associate/update", updateTodo))
