import { Checkbox, Form, TreeSelect } from "antd";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { validateMessages } from "../../constant";
import FormRow from "../../Form/FormRow";
import FormCol from "../../Form/FormCol";
import Select from "../../react-ui/Select";
import Label from "../../Form/Label";
import { filterInTree } from "../../../utils/search";
import TitleContainer from "../../react-ui/TitleContainer";
import Button from "../../react-ui/Button";
import AddButton from "../../react-ui/AddButton";
import Input from "../../react-ui/Input";
import Upload from "../../react-ui/Upload";
import FormattedInput from "../../react-ui/FormattedInput";
import { getEntitiesByWork } from "../../../utils/getEntitiesByWork";

function ReferenceForm({
  works,
  handleSubmit,
  initialValues,
  referenceStatus,
  siteTypologies,
  entities,
  closeModal,
}) {
  const [form] = Form.useForm();
  const workIdForm = Form.useWatch("work_id", form);
  const workId = useMemo(() => {
    if (referenceStatus === "create") {
      if (works.length > 1) return workIdForm;
      return works[0].id;
    }
    return initialValues?.work_id;
  }, [workIdForm, initialValues, referenceStatus, works]);
  const showStaticInformation = referenceStatus !== "create" && !!initialValues;

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    if (referenceStatus === "create") {
      const entitiesForm = form
        .getFieldValue("entities")
        ?.filter(({ value: id }) => {
          const entity = entities.find((el) => el.id === id);
          return entity.works.map((w) => w.id).includes(workId);
        });
      form.setFieldsValue({ entities: entitiesForm });
    }
  }, [workId, form, entities, referenceStatus]);

  const relevantEntities = useMemo(() => {
    if (entities?.length < 1) return [];
    const entitiesByWork = getEntitiesByWork({
      entities,
      parentEntity: entities.find((entity) => entity.head_office_id === null),
      workId,
    });
    return entitiesByWork?.map((entity) => {
      const disabled = !entity.works.some((el) => el.id === workId);
      return {
        id: entity.id,
        pId: entity.parent_id,
        title: entity.name,
        value: entity.id,
        disabled,
      };
    });
  }, [workId, entities]);

  const findChilds = (triggerValue, entity_ids) => {
    const childs =
      relevantEntities.filter(
        (el) => !entity_ids.includes(el.value) && el.pId === triggerValue
      ) || [];
    let allChilds = [...childs];
    childs.forEach(({ value }) => {
      allChilds = allChilds.concat(findChilds(value, entity_ids));
    });
    return allChilds;
  };

  const checkChilds = (_, __, { triggerValue, checked }) => {
    if (!checked) return;
    const entitiesForm = form.getFieldValue("entities");
    // const childs = findChilds(
    //   triggerValue,
    //   entitiesForm.map((el) => el.value)
    // );
    // form.setFieldsValue({ entities: [...entitiesForm, ...childs] });
    form.setFieldsValue({ entities: [...entitiesForm] });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  return (
    <Form
      id="reference-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
      <TitleContainer
        label={`${
          referenceStatus === "create" ? "Créer" : "Modifier"
        } une référence`}
      >
        <StyledButton
          label="Annuler"
          onClick={closeModal}
          fontSize="14px"
          btnType="cancel"
          type="button"
        />
        <AddButton
          label={`${
            referenceStatus === "create" ? "Créer" : "Modifier"
          } la référence`}
          type="submit"
          value="submit"
          fontSize="14px"
        />
      </TitleContainer>

      <FormContainer>
        <FormRow>
          <LeftUploadCol>
            <LeftFormCol label="Global" name="global" valuePropName="checked">
              <Checkbox />
            </LeftFormCol>
            {works.length > 1 && (
              <>
                {referenceStatus === "create" && (
                  <LeftFormCol
                    label="Métier"
                    name="work_id"
                    withoutPaddingRight
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch={false}
                      options={works}
                      keyName="fullname"
                    />
                  </LeftFormCol>
                )}
                {showStaticInformation && (
                  <StaticTextContainer>
                    <StaticTextWrapper>
                      <StaticTextLabel label="Métier : " />
                      {
                        works?.find((w) => initialValues.work_id === w.id)
                          ?.fullname
                      }
                    </StaticTextWrapper>
                  </StaticTextContainer>
                )}
              </>
            )}
            <LeftFormCol
              label="Nom du client"
              name="name"
              rules={[{ required: true }]}
            >
              <Input />
            </LeftFormCol>
          </LeftUploadCol>
          <UploadFormCol
            valuePropName="fileList"
            getValueFromEvent={normFile}
            name="picture"
            width="auto"
          >
            <Upload />
          </UploadFormCol>
        </FormRow>

        <FormRow>
          <FormCol label="Période réalisée" name="period">
            <Input />
          </FormCol>
          <FormCol label="Montant" name="total">
            <FormattedInput size="middle" isNumber suffix="€" />
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol label="Description de la mission" name="description">
            <Input textArea />
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol label="Typologies" name="site_typology_ids">
            <Select
              options={siteTypologies}
              keyName="typology"
              mode="multiple"
            />
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol label="Agences concernées" name="entities">
            <TreeSelect
              treeDefaultExpandAll
              treeCheckable
              treeData={relevantEntities}
              treeDataSimpleMode
              showCheckedStrategy="SHOW_ALL"
              filterTreeNode={filterInTree}
              treeCheckStrictly
              onChange={checkChilds}
              showSearch
            />
          </FormCol>
        </FormRow>
      </FormContainer>
    </Form>
  );
}

const StaticTextContainer = styled.div`
  align-self: center;
  display: flex;
  width: 100%;
`;

const StaticTextWrapper = styled.div`
  width: 50%;
  padding-left: ${({ $padLeft }) => ($padLeft ? "8px" : "")};
`;

const StaticTextLabel = styled(Label)`
  margin-right: 8px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

const UploadFormCol = styled(FormCol)`
  margin-left: auto;
`;

const LeftUploadCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  gap: 16px;
`;

const LeftFormCol = styled(FormCol)`
  padding: 0 8px 0 0 !important;
`;

export default ReferenceForm;
