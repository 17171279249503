import React, { useMemo } from "react";
import styled from "styled-components";
import AssociationsTable from "./AssociationsTable";

const AssociationsInformations = React.memo(
  ({
    agentId,
    wpId,
    opportunityId,
    workBonuses,
    frequencies,
    updateWbMutation,
    deleteWbMutation,
    updateIndMutation,
    deleteIndMutation,
    indemnities,
    odd,
    hasDecreased,
    configApplication,
    isLockedByUser,
  }) => {
    const wbs = useMemo(() => {
      return workBonuses?.filter(
        ({ workbonusable_id }) => workbonusable_id === agentId
      );
    }, [workBonuses, agentId]);

    const indmnts = useMemo(() => {
      return indemnities?.filter(
        ({ indemnityable_id }) => indemnityable_id === agentId
      );
    }, [indemnities, agentId]);
    return (
      <NestedContainer>
        <NestedWrapper>
          <AssociationsTable
            isWorkBonus
            frequencies={frequencies}
            associations={wbs}
            updateMutation={updateWbMutation}
            deleteMutation={deleteWbMutation}
            opportunityId={opportunityId}
            agentId={agentId}
            wpId={wpId}
            odd={odd}
            hasDecreased={hasDecreased}
            configApplication={configApplication}
            isLockedByUser={isLockedByUser}
          />
        </NestedWrapper>
        <NestedWrapper>
          <AssociationsTable
            frequencies={frequencies}
            associations={indmnts}
            updateMutation={updateIndMutation}
            deleteMutation={deleteIndMutation}
            opportunityId={opportunityId}
            agentId={agentId}
            wpId={wpId}
            odd={odd}
            hasDecreased={hasDecreased}
            configApplication={configApplication}
            isLockedByUser={isLockedByUser}
          />
        </NestedWrapper>
      </NestedContainer>
    );
  }
);

const NestedContainer = styled.div`
  width: 50%;
  display: flex;
  gap: 3px;
`;
const NestedWrapper = styled.div`
  width: 50%;
`;

export default AssociationsInformations;
