import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import {
  constructMinsOrH,
  getApplicatedDays,
} from "../../../../../utils/planning";
import { stringSorter } from "../../../../../utils/stringSorter";
import { ShowDeleteConfirm } from "../../../../Admin-cf/confirmModal";
import TableContainer from "../../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import ActionsContainer from "../../../../react-ui/ActionsContainer";
import AddButton from "../../../../react-ui/AddButton";
import Delete from "../../../../react-ui/Icons/Delete";
import Duplicate from "../../../../react-ui/Icons/Duplicate";
import TitleContainer from "../../../../react-ui/TitleContainer";
import { getData, postData } from "../../../../request/instance";
import { useStore } from "../../../../store";
import DuplicateDisable from "../../../../react-ui/Icons/DuplicateDisable";
import DeleteDisable from "../../../../react-ui/Icons/DeleteDisable";

const { confirm } = Modal;

const SPColumns = (
  onActionClick,
  configApplication,
  isLockedByUser,
  areOtherSps = false
) => [
  {
    title: "Intitulé",
    dataIndex: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
    width: "25%",
  },
  {
    title: "Heure de début",
    dataIndex: "start_datetime",
    sorter: (a, b) => stringSorter(a.start_datetime, b.start_datetime),
    render: (start_datetime) => {
      const start = new Date(start_datetime);
      const date = new Date(
        `2020-06-01T${constructMinsOrH(start.getUTCHours())}:${constructMinsOrH(
          start.getMinutes()
        )}:00`
      );
      return `${constructMinsOrH(date.getHours())}:${constructMinsOrH(
        date.getMinutes()
      )}`;
    },
    width: "20%",
  },
  {
    title: "Heure de fin",
    dataIndex: "end_datetime",
    sorter: (a, b) => stringSorter(a.end_datetime, b.end_datetime),
    render: (end_datetime) => {
      const end = new Date(end_datetime);
      const date = new Date(
        `2020-06-01T${constructMinsOrH(end.getUTCHours())}:${constructMinsOrH(
          end.getMinutes()
        )}:00`
      );
      return `${constructMinsOrH(date.getHours())}:${constructMinsOrH(
        date.getMinutes()
      )}`;
    },
    width: "20%",
  },
  {
    title: "Jours appliqués",
    dataIndex: "dates",
    render: (dates) => <DayAppliqued>{getApplicatedDays(dates)}</DayAppliqued>,
    width: "30%",
  },
  {
    title: "",
    dataIndex: "",
    render: (record) => (
      <ActionsContainer center>
        {areOtherSps &&
        configApplication.guarding.workpositions.edit_special_planning_modal &&
        isLockedByUser ? (
          <Duplicate
            onClick={() => {
              onActionClick(record);
            }}
          />
        ) : (
          areOtherSps && <DuplicateDisable />
        )}
        {!areOtherSps &&
        configApplication.guarding.workpositions.edit_special_planning_modal &&
        isLockedByUser ? (
          <Delete
            onClick={() => {
              onActionClick(record);
            }}
          />
        ) : (
          !areOtherSps && <DeleteDisable />
        )}
      </ActionsContainer>
    ),
  },
];

const selector = (state) => ({
  formToken: state.formToken,
});

function SPArray({
  setSpStatus,
  spStatus,
  selectedSp,
  setSelectedSp,
  workPositionId,
  opportunityId,
  specialPlannings = [],
  setWorkPositionsLocal,
  configApplication,
  isLockedByUser,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const openForCreation = () => {
    setSpStatus("create");
    setSelectedSp();
  };

  const { data: otherSps } = useQuery(
    ["OtherSpecialPlannings", { workPositionId }],
    () =>
      getData(
        formToken,
        `/special_plannings?work_position_id=${workPositionId}&other=true`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: removeSpecialPlanningMutation } = useMutation(
    (data) => postData(formToken, "/special_planning/delete", data),
    {
      onSettled: () =>
        queryClient.invalidateQueries(["SpecialPlannings", { workPositionId }]),
      onSuccess: ({ has_calendar_changed }) => {
        queryClient.invalidateQueries(["Planning", { workPositionId }]);
        queryClient.invalidateQueries(["MonthlyCalendar", { workPositionId }]);
        queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
        setWorkPositionsLocal((state) => {
          const newWp = [...state];
          const index = state.findIndex((el) => el.id === workPositionId);

          newWp[index] = {
            ...newWp[index],
            has_calendar_changed,
          };
          return newWp;
        });
      },
    }
  );

  const { mutate: dupSpecialPlanningMutation } = useMutation(
    (data) => postData(formToken, "/special_planning/duplicate_sp", data),
    {
      onSettled: () =>
        queryClient.invalidateQueries(["SpecialPlannings", { workPositionId }]),
      onSuccess: ({ has_calendar_changed }) => {
        queryClient.invalidateQueries(["Planning", { workPositionId }]);
        queryClient.invalidateQueries(["MonthlyCalendar", { workPositionId }]);
        queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
        setWorkPositionsLocal((state) => {
          const newWp = [...state];
          const index = state.findIndex((el) => el.id === workPositionId);

          newWp[index] = {
            ...newWp[index],
            has_calendar_changed,
          };
          return newWp;
        });
      },
    }
  );

  const removeSpecialPlanning = (record) => {
    ShowDeleteConfirm(record.name, record.id, () => {
      removeSpecialPlanningMutation({ id: record.id });
      setSpStatus();
      setSelectedSp();
    });
  };

  const dupSpecialPlanning = (record) => {
    confirm({
      title: `Voulez-vous dupliquer le planning spécial ${record.name} pour ce poste ?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Oui",
      okType: "danger",
      maskClosable: false,
      cancelText: "Non",
      onOk() {
        dupSpecialPlanningMutation({
          id: record.id,
          work_position_id: workPositionId,
        });
        setSpStatus();
        setSelectedSp();
        return false;
      },
      onCancel() {
        return false;
      },
    });
  };
  return (
    <Container>
      <Wrapper>
        <TitleContainer label="Plannings spéciaux du poste">
          <AddButton
            onClick={openForCreation}
            disabled={
              spStatus === "create" ||
              !configApplication.guarding.workpositions
                .create_special_planning_modal ||
              !isLockedByUser
            }
            label="Planning spécial"
          />
        </TitleContainer>
        <TableContainer
          bordered={false}
          columns={SPColumns(
            removeSpecialPlanning,
            configApplication,
            isLockedByUser
          )}
          dataSource={specialPlannings}
          pagination={false}
          isRowSelectable
          rowSelectableId={
            spStatus === "update" && selectedSp ? selectedSp.id : undefined
          }
          rowKey="id"
          scroll={{ y: 150 }}
          onRow={(record) => {
            return {
              onClick: () => {
                setSpStatus("update");
                setSelectedSp(record);
              },
            };
          }}
        />
      </Wrapper>
      <Wrapper>
        <TitleContainer label="Plannings spéciaux d'autres postes duplicables" />
        <TableContainer
          bordered={false}
          columns={SPColumns(
            dupSpecialPlanning,
            configApplication,
            isLockedByUser,
            true
          )}
          dataSource={otherSps}
          pagination={false}
          rowKey="id"
          scroll={{ y: 150 }}
        />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 49%;
`;

const DayAppliqued = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default SPArray;
