import { currency_euros_to_cents } from "../components/utils";

export const centsToEuros = (price) => {
  if (typeof price !== "string" && typeof price !== "number") return;
  if (typeof price !== "string") price = price.toString();
  return (price / 100).toFixed(2).replace(/\.00$/, "");
};

export const eurosToCents = (price) => {
  if (typeof price !== "string" && typeof price !== "number") return;
  if (typeof price !== "string") price = price.toString();
  return parseFloat(
    (
      parseFloat(
        price.replace(/[€]/g, "").replace(/[,]/g, ".").replace(/\s+/g, "")
      ) * 100
    ).toFixed(2)
  );
};
