import React, { useMemo } from "react";
import styled from "styled-components";
import Select from "../../../../react-ui/Select";

function TvaSelect({
  value,
  tva_options,
  updatePrestationOpportunity,
  updateMaterialOpportunity,
}) {
  const tva_options_list = useMemo(() => {
    return tva_options;
  }, [tva_options]);

  const handleUpdateTva = (e) => {
    if ("material_profession_id" in value) {
      updateMaterialOpportunity({
        id: value.id,
        tva_opportunity_id: e,
      });
    } else {
      updatePrestationOpportunity({
        id: value.id,
        tva_opportunity_id: e,
      });
    }
  };
  if (value.recordType === "ouvrage" || "Elements" in value) {
    return null;
  }
  return (
    <StyledSelect
      options={tva_options_list}
      defaultValue={
        value.tva_opportunity_id ||
        tva_options.find((tva) => tva.tva_type === "general").id
      }
      bordered={false}
      placeholder="TVA"
      onChange={(e) => handleUpdateTva(e)}
      fontSize="10px"
    />
  );
}
const StyledSelect = styled(Select)`
  width: 105px;
`;
export default TvaSelect;
