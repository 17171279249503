import React from "react";
import styled from "styled-components";

function FormRow({
  className = "",
  children,
  marginTop,
  justify = "flex-start",
  visibility = "visible",
  height = "auto",
  minHeight,
}) {
  return (
    <Container
      className={className}
      marginTop={marginTop}
      justify={justify}
      visibility={visibility}
      height={height}
      minHeight={minHeight}
    >
      {children}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-top: ${({ marginTop }) => marginTop || "16px"};
  justify-content: ${({ justify }) => justify};
  visibility: ${({ visibility }) => visibility};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
`;

export default FormRow;
