import React from "react";
import styled from "styled-components";
import FormCol from "../../../Form/FormCol";
import { opportunityField } from "../../../constant";
import CreateLink from "../../../react-ui/CreateLink";
import Select from "../../../react-ui/Select";

function TypeOfSite({ setTypologyModalType, typologies, disabled, show_link }) {
  return (
    <FormCol
      labelOnTop
      label="Typologie(s) du site"
      customContent={
        <TypologyCustomContent>
          {show_link && (
            <StyledCreateLink
              label="Créer une typologie"
              onClick={() => setTypologyModalType("typology")}
            />
          )}
        </TypologyCustomContent>
      }
      name={opportunityField.typologiesOpportunityIds}
    >
      <StyledSelect
        disabled={disabled}
        options={typologies}
        placeholder="Sélectionnez une ou des typologies"
        mode="multiple"
      />
    </FormCol>
  );
}

const StyledSelect = styled(Select)`
  width: 100%;
`;
const StyledCreateLink = styled(CreateLink)`
  margin-left: auto;
  margin-top: 10px;
`;

const TypologyCustomContent = styled.div`
  display: flex;
  justify-content: space-between;
`;
export default TypeOfSite;
