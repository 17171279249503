import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { Form } from "antd";
import TitleContainer from "../../react-ui/TitleContainer";
import UserForm from "./Form";
import { postData } from "../../request/instance";
import { useStore } from "../../store";

const selector = (state) => ({
  formToken: state.formToken,
});

function UserInformations({
  status,
  users,
  selectedUserId,
  setStatus,
  currentUserId,
  roles,
  entities,
  works,
  allUsers,
  generateUserFormData,
  sites,
  option_documents,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const [selectedUser, setSelectedUser] = useState();
  const isShowing = status === "show";
  const isUpdating = status === "update";
  const showForm = (isUpdating || isShowing) && selectedUserId;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    if (!selectedUserId) setSelectedUser();
    else
      setSelectedUser({ ...users.find((user) => user.id === selectedUserId) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserId]);

  const { mutate: updateUser, isLoading } = useMutation(
    (todo) => postData(formToken, `/admin/user/update`, todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Users");
      },
      onError: () => {
        setSelectedUser({
          ...users.find((user) => user.id === selectedUserId),
        });
      },
      onSuccess: (payload) => {
        setSelectedUser(payload.user);
        setStatus("show");
      },
    }
  );

  const getTitle = () => {
    let title = "Informations";
    if (
      showForm &&
      selectedUser &&
      selectedUser.first_name &&
      selectedUser.last_name
    ) {
      title += `: ${selectedUser.first_name} ${selectedUser.last_name}`;
    }
    return title;
  };

  const handleSubmit = (values) => {
    const formData = generateUserFormData(values);
    updateUser(formData);
  };

  return (
    <Container>
      <TitleContainer label={getTitle()} />
      {!showForm && (
        <Wrapper>
          Sélectionnez un utilisateur pour afficher ses informations
        </Wrapper>
      )}
      {showForm && (
        <UserForm
          isUpdating={isUpdating}
          isShowing={isShowing}
          selectedUser={selectedUser}
          handleSubmit={handleSubmit}
          setStatus={setStatus}
          status={status}
          currentUserId={currentUserId}
          isLoading={isLoading}
          roles={roles}
          entities={entities}
          works={works}
          allUsers={allUsers}
          form={form}
          sites={sites}
          option_documents={option_documents}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 50%;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default UserInformations;
