import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useQueryClient } from "react-query";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import FormattedSelect from "./FormattedSelect";
import FormattedInput from "../../react-ui/FormattedInput";
import { centsToEuros } from "../../../utils/currencyConverter";
import Delete from "../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../../Admin-cf/confirmModal";
import DeleteDisable from "../../react-ui/Icons/DeleteDisable";

const associationsCols = ({
  isWorkBonus,
  frequencies,
  deleteAsso,
  updateAsso,
  isFromApplyValues,
  isFromCreateAsso,
  hasDecreased,
  configApplication,
  isLockedByUser,
}) => [
  {
    title: "Nom",
    dataIndex: "name",
    width: "36%",
    render: (value, record) =>
      isFromApplyValues ? (
        value
      ) : (
        <FormattedInput
          value={value}
          keyName="name"
          itemId={record.id}
          updateItem={updateAsso}
          disabled={
            hasDecreased ||
            (isWorkBonus &&
              configApplication.guarding.agents.disable_workbonus_name) ||
            (!isWorkBonus &&
              configApplication.guarding.agents.disable_indemnity_name) ||
            !isLockedByUser
          }
        />
      ),
  },
  {
    title: "Montant",
    dataIndex: "amount_cents",
    width: "25%",
    render: (value, record) => (
      <FormattedInput
        value={centsToEuros(value)}
        keyName="amount_cents"
        itemId={record.id}
        isNumber
        toCents
        suffix="€"
        textAlign="right"
        updateItem={updateAsso}
        updateOnChange={isFromApplyValues}
        disabled={
          hasDecreased ||
          (isWorkBonus &&
            configApplication.guarding.agents.disable_workbonus_price) ||
          (!isWorkBonus &&
            configApplication.guarding.agents.disable_indemnity_price) ||
          !isLockedByUser
        }
      />
    ),
  },
  {
    title: "Fréquence",
    dataIndex: "frequency",
    render: (value, record) => (
      <FormattedSelect
        keyName="frequency"
        options={Object.keys(frequencies).map((key) => ({
          name: key,
          id: frequencies[key],
        }))}
        value={value}
        itemId={record.id}
        updateItem={updateAsso}
        allowClear={isFromApplyValues}
        disabled={
          hasDecreased ||
          (isWorkBonus &&
            configApplication.guarding.agents.disable_workbonus_frequency) ||
          (!isWorkBonus &&
            configApplication.guarding.agents.disable_indemnity_frequency) ||
          !isLockedByUser
        }
      />
    ),
  },
  {
    title: "",
    align: "center",
    hidden: isFromApplyValues || hasDecreased,
    dataIndex: "delete",
    width: "5%",
    render: (_, record) =>
      (isWorkBonus &&
        !configApplication.guarding.agents.disable_workbonus_frequency &&
        isLockedByUser) ||
      (!isWorkBonus &&
        !configApplication.guarding.agents.disable_indemnity_frequency &&
        isLockedByUser) ? (
        <DeleteIcon
          onClick={() =>
            isFromCreateAsso
              ? deleteAsso(record.id)
              : ShowDeleteConfirm(record.name, record.id, deleteAsso)
          }
        />
      ) : (isWorkBonus &&
          configApplication.guarding.agents.disable_workbonus_frequency &&
          !isLockedByUser) ||
        (!isWorkBonus &&
          configApplication.guarding.agents.disable_indemnity_frequency &&
          !isLockedByUser) ? (
        <DeleteIconDisable
          onClick={() =>
            isFromCreateAsso
              ? deleteAsso(record.id)
              : ShowDeleteConfirm(record.name, record.id, deleteAsso)
          }
        />
      ) : (
        <DeleteIconDisable
          onClick={() =>
            isFromCreateAsso
              ? deleteAsso(record.id)
              : ShowDeleteConfirm(record.name, record.id, deleteAsso)
          }
        />
      ),
  },
];

function AssociationsTable({
  isWorkBonus,
  associations,
  frequencies,
  updateMutation,
  deleteMutation,
  opportunityId,
  agentId,
  wpId,
  odd,
  isFromApplyValues = false,
  isFromCreateAsso = false,
  hasDecreased,
  configApplication,
  isLockedByUser,
}) {
  const queryClient = useQueryClient();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (associations?.length > 0) {
      setData([...associations]);
    } else setData([]);
  }, [associations]);

  const deleteAsso = useCallback(
    (id) => {
      if (isFromApplyValues) return;
      if (isFromCreateAsso) {
        deleteMutation(id);
        return;
      }
      deleteMutation(
        {
          id,
          opportunity_id: opportunityId,
          agent_id: agentId,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              isWorkBonus ? "WorkBonuses" : "Indemnities",
              { wpId },
            ]);
            queryClient.invalidateQueries([
              isWorkBonus ? "WorkBonuses" : "Indemnities",
              { distinct: true },
            ]);
          },
        }
      );
    },
    [
      deleteMutation,
      opportunityId,
      agentId,
      queryClient,
      isWorkBonus,
      wpId,
      isFromApplyValues,
      isFromCreateAsso,
    ]
  );

  const updateAsso = useCallback(
    ({ id, ...fields }) => {
      if (hasDecreased) return;
      if (isFromApplyValues || isFromCreateAsso) {
        updateMutation({ id, ...fields });
        return;
      }
      const assoParams = isWorkBonus
        ? {
            work_bonus: {
              ...fields,
            },
          }
        : {
            indemnity: {
              ...fields,
            },
          };
      updateMutation(
        {
          id,
          opportunity_id: opportunityId,
          agent_id: agentId,
          ...assoParams,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([
              isWorkBonus ? "WorkBonuses" : "Indemnities",
              { wpId },
            ]);
            queryClient.invalidateQueries([
              isWorkBonus ? "WorkBonuses" : "Indemnities",
              { distinct: true },
            ]);
          },
        }
      );
    },
    [
      updateMutation,
      opportunityId,
      agentId,
      isWorkBonus,
      queryClient,
      wpId,
      isFromApplyValues,
      isFromCreateAsso,
      hasDecreased,
    ]
  );

  return (
    <StyledTableContainer
      columns={associationsCols({
        isWorkBonus,
        frequencies,
        deleteAsso,
        updateAsso,
        isFromApplyValues,
        isFromCreateAsso,
        hasDecreased,
        configApplication,
        isLockedByUser,
      })}
      coloredRowOfferPage={!odd}
      data={data}
      rowKey={isFromApplyValues ? "name" : "id"}
    />
  );
}

const StyledTableContainer = styled(TableContainer)`
  .ant-table {
    background: transparent;
  }
  .ant-table-title {
    padding: 4px 0;
  }
  .ant-table-container {
    background: white;
  }
`;

const DeleteIcon = styled(Delete)`
  font-size: 10px;
`;

const DeleteIconDisable = styled(DeleteDisable)`
  font-size: 10px;
`;

export default AssociationsTable;
