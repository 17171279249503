import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import ContactsArray from "./ContactsArray";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import { fetchContactsUrl } from "../../../../utils/fetchContactsUrl";

const selector = (state) => ({
  formToken: state.formToken,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function Contacts({ clients, entities, works, users, selectedClientId }) {
  const [status, setStatus] = useState();
  const [wordEntered, setWordEntered] = useState();
  const [selectedContact, setSelectedContact] = useState();
  const { formToken } = useStore(selector);

  const {
    data: contacts,
    refetch,
    isLoading: contactsAreLoading,
  } = useQuery(
    "Contacts",
    () =>
      getData(
        formToken,
        fetchContactsUrl({
          search: wordEntered,
          clientId: selectedClientId,
        })
      ),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  useEffect(() => {
    refetch();
  }, [refetch, selectedClientId]);

  const closeModal = useCallback(() => {
    setStatus();
    setSelectedContact();
  }, []);
  return (
    <Container>
      <ContactsArray
        contacts={contacts}
        contactsAreLoading={contactsAreLoading}
        selectedContact={selectedContact}
        setSelectedContact={setSelectedContact}
        setStatus={setStatus}
        entities={entities}
        clients={clients}
        works={works}
        wordEntered={wordEntered}
        setWordEntered={setWordEntered}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Contacts;
