import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { isInteger, isString } from "lodash";
import useTodo from "../../../hookQuery/useTodo";
import { useStore } from "../../../store";
import TableMaterialAndPackage from "./Table/TableMaterialAndPackage";
import TablePrestation from "./Table/TablePrestation";
import TableMovement from "./Table/TablesMovement/TableMovement";
import TableCarrycot from "./Table/tablesCarrycots/TableCarrycot";
import useUpdateTodo from "../../../hookQuery/useUpdateTodo";
import { groupByMultipleKeys } from "../../../../utils/groupArrayByKey";
import { simple_default_configuration } from "../../../../simple_default";
import TablesOuvrage from "./Table/TablesOuvrage/TablesOuvrage";
import { postData } from "../../../request/instance";

const selector = (state) => ({
  formToken: state.formToken,
});
const ContentZone = React.memo(
  ({ zone_id, responseType, opportunity_work, configuration, vocabulary }) => {
    const { id_opportunity } = useParams();
    const queryClient = useQueryClient();
    const { formToken } = useStore(selector);
    const { data: method_calcul } = useTodo(
      formToken,
      `/opportunities/${id_opportunity}/calculate_margin_method`,
      "MethodCalcul"
    );

    const { data: materials_opportunity } = useTodo(
      formToken,
      `/material_opportunity?opportunity_id=${id_opportunity}`,
      "MaterialsOpportunity"
    );

    const { mutate: calculMaterialOpportunity, isLoading: materialLoading } =
      useMutation(
        (todo) =>
          postData(formToken, "/material_opportunity/update_costing", todo),
        {
          onSuccess: (_, variables) => {
            if (isInteger(variables.ouvrage_parent_id)) {
              queryClient.refetchQueries("Ouvrage", {
                id: variables.ouvrage_parent_id,
              });
              queryClient.invalidateQueries("OuvragesOpportunity", {
                id: variables.ouvrage_parent_id,
              });
            }
            queryClient.invalidateQueries("MaterialsOpportunity", {
              id: variables.id,
            });
            queryClient.invalidateQueries("OpportunityCotationLc");
            queryClient.invalidateQueries("Zones");
            queryClient.invalidateQueries("BasketItems");
          },
        }
      );

    const { mutate: calculOuvrageOpportunity, isLoading: ouvrageLoading } =
      useMutation(
        (todo) =>
          postData(formToken, "/ouvrage_opportunity/update_costing", todo),
        {
          onSuccess: (data, variables) => {
            queryClient.invalidateQueries("OuvragesOpportunity", {
              id: data.id,
            });

            if (isInteger(variables.ouvrage_parent_id)) {
              queryClient.refetchQueries("Ouvrage", {
                id: variables.ouvrage_parent_id,
              });
            }
            queryClient.invalidateQueries("OpportunityCotationLc");
            queryClient.invalidateQueries("Zones");
          },
        }
      );

    const { data: prestationsOpp } = useTodo(
      formToken,
      `/prestation_opportunity/${id_opportunity}`,
      "PrestationsOpportunity"
    );

    const { mutate: calculPrestaOpportunity, isLoading: prestationLoading } =
      useMutation(
        (todo) =>
          postData(formToken, "/prestation_opportunity/update_costing", todo),
        {
          onSuccess: (_, variables) => {
            if (isInteger(variables.ouvrage_parent_id)) {
              queryClient.refetchQueries("Ouvrage", {
                id: variables.ouvrage_parent_id,
              });
              queryClient.invalidateQueries("OuvragesOpportunity", {
                id: variables.ouvrage_parent_id,
              });
            }
            queryClient.invalidateQueries("PrestationsOpportunity", {
              id: variables.id,
            });
            queryClient.invalidateQueries("OpportunityCotationLc");
            queryClient.invalidateQueries("Zones");
            queryClient.invalidateQueries("BasketItems");
          },
        }
      );

    const { data: movements_opportunity } = useTodo(
      formToken,
      `/movement_opportunity/${id_opportunity}`,
      "MovementsOpportunity"
    );

    const { data: ouvrages_opportunity } = useTodo(
      formToken,
      `/ouvrage_opportunity?opportunity_id=${id_opportunity}`,
      "OuvragesOpportunity"
    );

    const { mutate: movementUpdate, isLoading: movementLoading } =
      useUpdateTodo(
        formToken,
        `/movement_opportunity/update`,
        "MovementsOpportunity",
        [
          "MovementProfilsOpportunity",
          "VehicleMovementOprt",
          "BasketItems",
          "OpportunityCotationLc",
          "Zones",
        ]
      );

    const { data: carrycots_opportunities } = useTodo(
      formToken,
      `/carrycot_opportunity/${id_opportunity}`,
      "CarrycotOpportunities"
    );

    const { mutate: carrycot_update, isLoading: carrycotLoading } =
      useUpdateTodo(
        formToken,
        `/carrycot_opportunity/update`,
        "CarrycotOpportunities",
        [
          "OpportunityCotationLc",
          "Zones",
          "ProfilsCarrycotOpportunity",
          "BasketItems",
          "CarrycotCosting",
        ]
      );

    const prestaPackageOpp = useMemo(
      () => prestationsOpp?.filter((el) => el.is_package && !el.subcontracting),
      [prestationsOpp]
    );

    const prestaPackageSubOpp = useMemo(
      () => prestationsOpp?.filter((el) => el.is_package && el.subcontracting),
      [prestationsOpp]
    );

    const movementsOpp = useMemo(
      () => movements_opportunity?.filter((el) => !el.subcontracting),
      [movements_opportunity]
    );

    const movementsSubOpp = useMemo(
      () => movements_opportunity?.filter((el) => el.subcontracting),
      [movements_opportunity]
    );

    const carrycotsOpp = useMemo(
      () => carrycots_opportunities?.filter((el) => !el.subcontracting),
      [carrycots_opportunities]
    );

    const carrycotsSubOpp = useMemo(
      () => carrycots_opportunities?.filter((el) => el.subcontracting),
      [carrycots_opportunities]
    );

    const filterOuvrageGroup = useCallback(() => {
      const ouvrage_opportunities_data = ouvrages_opportunity;
      return ouvrage_opportunities_data?.filter(
        (ouvrage) => ouvrage?.group !== true
      );
    }, [ouvrages_opportunity]);

    const groupArray = useCallback(
      ({
        arr = [],
        keyIds = ["id"],
        quantityKey = "quantity",
        totalCostKey = "total_cost_price_cents",
      }) => {
        // Group same opportunities by id
        const groupedById = groupByMultipleKeys(
          arr?.filter((el) => el.zone_id === zone_id),
          keyIds
        );

        // Addition quantity of same opportunities
        const newArr = groupedById
          ? Object.keys(groupedById).map((idx) => {
              return {
                ...groupedById[idx][0],
                ids: groupedById[idx].map((el) => el.id),
                [quantityKey]: groupedById[idx].reduce(
                  (acc, curr) => acc + curr[quantityKey],
                  0
                ),
                [totalCostKey]: groupedById[idx].reduce(
                  (acc, curr) => acc + curr[totalCostKey],
                  0
                ),
                total_selling_price_cents: groupedById[idx].reduce(
                  (acc, curr) => acc + curr.total_selling_price_cents,
                  0
                ),
              };
            })
          : [];
        return newArr;
      },
      [zone_id]
    );

    const getItems = useCallback(
      ({
        arr = [],
        keyIds = ["id"],
        quantityKey = "quantity",
        totalCostKey = "total_cost_price_cents",
      }) =>
        responseType === "referencing"
          ? arr?.map((el) => ({ ...el, ids: [el.id] }))
          : groupArray({ arr, keyIds, quantityKey, totalCostKey }),
      [responseType, groupArray]
    );

    const getAppropriateItems = useCallback(
      (items) =>
        responseType === "quotation" ? getFromFa(items) : getNotFromFa(items),
      [responseType]
    );

    const getNotFromFa = (items) => items?.filter((el) => !el.is_from_fa);

    const getFromFa = (items) => items?.filter((el) => !!el.is_from_fa);

    return (
      <div>
        <TableMaterialAndPackage
          zone_id={zone_id}
          responseType={responseType}
          configuration={configuration}
          opportunities={getItems({
            arr: getAppropriateItems(materials_opportunity),
            keyIds: [
              "material_profession_id",
              "name",
              "cost_price_cents",
              "margin_rate",
              "tva_profession_id",
            ],
          })}
          calculOpportunity={calculMaterialOpportunity}
          isLoading={materialLoading}
          type={`${vocabulary.products}`}
          secondColumnType="categories"
        />
        {responseType === "quotation" && (
          <TableMaterialAndPackage
            zone_id={zone_id}
            responseType="consultation"
            configuration={configuration}
            opportunities={getItems({
              arr: getNotFromFa(materials_opportunity),
              keyIds: [
                "material_profession_id",
                "name",
                "cost_price_cents",
                "margin_rate",
                "tva_profession_id",
              ],
            })}
            calculOpportunity={calculMaterialOpportunity}
            isLoading={materialLoading}
            type="Matériels (Hors accord-cadre)"
            secondColumnType="categories"
          />
        )}
        <TablePrestation
          zone_id={zone_id}
          configuration={configuration}
          subcontracting={false}
          responseType={responseType}
          getItems={getItems}
          prestationsOpp={getAppropriateItems(prestationsOpp)}
          type="Coûts internes -  Prestations"
        />
        {responseType === "quotation" && (
          <TablePrestation
            zone_id={zone_id}
            configuration={configuration}
            subcontracting={false}
            method_calcul={method_calcul}
            responseType="consultation"
            getItems={getItems}
            prestationsOpp={getNotFromFa(prestationsOpp)}
            type="Coûts internes - Prestations (Hors accord-cadre)"
          />
        )}
        {responseType !== "consultation" && (
          <>
            <TableMaterialAndPackage
              zone_id={zone_id}
              responseType={responseType}
              configuration={configuration}
              opportunities={getAppropriateItems(movementsOpp)}
              calculOpportunity={movementUpdate}
              isLoading={movementLoading}
              secondColumnType="type_transport"
              type="Coûts internes - Déplacements"
            />
            <TableMaterialAndPackage
              zone_id={zone_id}
              configuration={configuration}
              responseType={responseType}
              opportunities={getAppropriateItems(carrycotsOpp)}
              calculOpportunity={carrycot_update}
              isLoading={carrycotLoading}
              type="Coûts internes - Nacelles"
              quantityKey={
                responseType === "quotation" ? "quantity" : "day_quantity"
              }
            />
          </>
        )}
        {responseType === "quotation" && (
          <>
            <TableMaterialAndPackage
              zone_id={zone_id}
              configuration={configuration}
              responseType="consultation"
              opportunities={getNotFromFa(movementsOpp)}
              calculOpportunity={movementUpdate}
              isLoading={movementLoading}
              secondColumnType="type_transport"
              type="Coûts internes - Déplacements (Hors accord-cadre)"
            />
            <TableMaterialAndPackage
              zone_id={zone_id}
              configuration={configuration}
              responseType="consultation"
              opportunities={getNotFromFa(carrycotsOpp)}
              calculOpportunity={carrycot_update}
              isLoading={carrycotLoading}
              type="Coûts internes - Nacelles (Hors accord-cadre)"
              quantityKey={
                responseType === "quotation" ? "quantity" : "day_quantity"
              }
              isCarrycot
            />
          </>
        )}
        {responseType === "consultation" && (
          <>
            <TableMovement
              zone_id={zone_id}
              subcontracting={false}
              movementsOpp={getNotFromFa(movementsOpp)}
              configuration={configuration}
            />
            <TableCarrycot
              zone_id={zone_id}
              configuration={configuration}
              subcontracting={false}
              carrycotsOpp={getNotFromFa(carrycotsOpp)}
            />
          </>
        )}
        <TableMaterialAndPackage
          zone_id={zone_id}
          configuration={configuration}
          responseType={responseType}
          opportunities={getItems({
            arr: getAppropriateItems(prestaPackageOpp),
            keyIds: [
              "prestation_profession_id",
              "name",
              "cost_price_cents",
              "margin_rate",
              "tva_profession_id",
            ],
            quantityKey: "unity_quantity",
            totalCostKey: "total_cost_cents",
          })}
          calculOpportunity={calculPrestaOpportunity}
          isLoading={prestationLoading}
          type="Coûts internes - Prestations forfaits"
          secondColumnType="categories"
          quantityKey="unity_quantity"
        />
        {responseType === "quotation" && (
          <TableMaterialAndPackage
            zone_id={zone_id}
            configuration={configuration}
            responseType="consultation"
            opportunities={getItems({
              arr: getNotFromFa(prestaPackageOpp),
              keyIds: [
                "prestation_profession_id",
                "name",
                "cost_price_cents",
                "margin_rate",
                "tva_profession_id",
              ],
              quantityKey: "unity_quantity",
              totalCostKey: "total_cost_cents",
            })}
            calculOpportunity={calculPrestaOpportunity}
            isLoading={prestationLoading}
            type="Coûts internes - Prestations forfaits (Hors accord-cadre)"
            secondColumnType="categories"
            quantityKey="unity_quantity"
          />
        )}
        <TablePrestation
          zone_id={zone_id}
          configuration={configuration}
          subcontracting
          method_calcul={method_calcul}
          responseType={responseType}
          getItems={getItems}
          prestationsOpp={getAppropriateItems(prestationsOpp)}
          type="Coût sous-traitance - Prestations"
        />
        {responseType === "quotation" && (
          <TablePrestation
            zone_id={zone_id}
            configuration={configuration}
            subcontracting
            method_calcul={method_calcul}
            responseType="consultation"
            getItems={getItems}
            prestationsOpp={getNotFromFa(prestationsOpp)}
            type="Coût sous-traitance - Prestations (Hors accord-cadre)"
          />
        )}
        {responseType !== "consultation" && (
          <>
            <TableMaterialAndPackage
              zone_id={zone_id}
              configuration={configuration}
              responseType={responseType}
              opportunities={getAppropriateItems(movementsSubOpp)}
              calculOpportunity={movementUpdate}
              isLoading={movementLoading}
              secondColumnType="type_transport"
              type="Coûts sous-traitance - Déplacements"
            />
            <TableMaterialAndPackage
              zone_id={zone_id}
              configuration={configuration}
              responseType={responseType}
              opportunities={getAppropriateItems(carrycotsSubOpp)}
              calculOpportunity={carrycot_update}
              isLoading={carrycotLoading}
              type="Coûts sous-traitance - Nacelles"
              quantityKey={
                responseType === "quotation" ? "quantity" : "day_quantity"
              }
              isCarrycot
            />
          </>
        )}
        {responseType === "quotation" && (
          <>
            <TableMaterialAndPackage
              zone_id={zone_id}
              configuration={configuration}
              responseType="consultation"
              opportunities={getNotFromFa(movementsSubOpp)}
              calculOpportunity={movementUpdate}
              isLoading={movementLoading}
              secondColumnType="type_transport"
              type="Coûts sous-traitance - Déplacements (Hors accord-cadre)"
            />
            <TableMaterialAndPackage
              zone_id={zone_id}
              configuration={configuration}
              responseType="consultation"
              opportunities={getNotFromFa(carrycotsSubOpp)}
              calculOpportunity={carrycot_update}
              isLoading={carrycotLoading}
              type="Coûts sous-traitance - Nacelles (Hors accord-cadre)"
              quantityKey={
                responseType === "quotation" ? "quantity" : "day_quantity"
              }
              isCarrycot
            />
          </>
        )}
        {responseType === "consultation" && (
          <>
            <TableMovement
              zone_id={zone_id}
              subcontracting
              movementsOpp={getNotFromFa(movementsSubOpp)}
              configuration={configuration}
            />
            <TableCarrycot
              zone_id={zone_id}
              configuration={configuration}
              subcontracting
              carrycotsOpp={getNotFromFa(carrycotsSubOpp)}
            />
          </>
        )}
        <TableMaterialAndPackage
          zone_id={zone_id}
          configuration={configuration}
          responseType={responseType}
          opportunities={getItems({
            arr: getAppropriateItems(prestaPackageSubOpp),
            keyIds: [
              "prestation_profession_id",
              "name",
              "cost_price_cents",
              "margin_rate",
              "tva_profession_id",
            ],
            quantityKey: "unity_quantity",
            totalCostKey: "total_cost_cents",
          })}
          calculOpportunity={calculPrestaOpportunity}
          isLoading={prestationLoading}
          type="Coûts sous-traitance - Prestations forfaits"
          secondColumnType="categories"
          quantityKey="unity_quantity"
        />
        {responseType === "quotation" && (
          <TableMaterialAndPackage
            zone_id={zone_id}
            configuration={configuration}
            responseType="consultation"
            opportunities={getItems({
              arr: getNotFromFa(prestaPackageSubOpp),
              keyIds: [
                "prestation_profession_id",
                "name",
                "cost_price_cents",
                "margin_rate",
                "tva_profession_id",
              ],
              quantityKey: "unity_quantity",
              totalCostKey: "total_cost_cents",
            })}
            calculOpportunity={calculPrestaOpportunity}
            isLoading={prestationLoading}
            type="Coûts sous-traitance - Prestations forfaits (Hors accord-cadre)"
            secondColumnType="categories"
            quantityKey="unity_quantity"
          />
        )}
        <TablesOuvrage
          type="Ouvrage"
          ouvrages_opportunity={filterOuvrageGroup()}
          zone_id={zone_id}
          formToken={formToken}
          configuration={configuration}
          calculOuvrageOpportunity={calculOuvrageOpportunity}
          calculMaterialOpportunity={calculMaterialOpportunity}
          calculPrestaOpportunity={calculPrestaOpportunity}
        />
      </div>
    );
  }
);

export default ContentZone;
