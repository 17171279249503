import React, { useMemo } from "react";
import styled from "styled-components";
import mime from "mime";
import { FILE_TYPE, getFileType } from "../../../../utils/getFileType";
import BaseIcon from "../../../react-ui/Icons/BaseIcon";

const $fontSize = "14px";

function FileColumn({
  file,
  name,
  contentType = mime.getType(name),
  className = "",
}) {
  const fileType = useMemo(() => getFileType(contentType), [contentType]);
  const isUnknown = fileType === FILE_TYPE.UNKNOWN;
  const isExcel = fileType === FILE_TYPE.EXCEL;
  const isWord = fileType === FILE_TYPE.WORD;
  const isPdf = fileType === FILE_TYPE.PDF;
  const isZip = fileType === FILE_TYPE.ZIP;
  const isCsv = fileType === FILE_TYPE.CSV;
  const isAudio = fileType === FILE_TYPE.AUDIO;
  const isVideo = fileType === FILE_TYPE.VIDEO;
  const isImage = fileType === FILE_TYPE.IMAGE;

  return (
    <FileLink
      className={className}
      isImage={isImage}
      href={file}
      target="_blank"
      onClick={(event) => event.stopPropagation()}
    >
      {isUnknown && (
        <BaseIcon className="fa-duotone fa-file" $fontSize={$fontSize} />
      )}
      {isExcel && (
        <BaseIcon className="fa-duotone fa-file-excel" $fontSize={$fontSize} />
      )}
      {isWord && (
        <BaseIcon className="fa-duotone fa-file-word" $fontSize={$fontSize} />
      )}
      {isPdf && (
        <BaseIcon className="fa-duotone fa-file-pdf" $fontSize={$fontSize} />
      )}
      {isZip && (
        <BaseIcon className="fa-duotone fa-file-zip" $fontSize={$fontSize} />
      )}
      {isCsv && (
        <BaseIcon className="fa-duotone fa-file-csv" $fontSize={$fontSize} />
      )}
      {isAudio && (
        <BaseIcon className="fa-duotone fa-file-audio" $fontSize={$fontSize} />
      )}
      {isVideo && (
        <BaseIcon className="fa-duotone fa-file-video" $fontSize={$fontSize} />
      )}
      {isImage && (
        <BaseIcon className="fa-duotone fa-file-image" $fontSize={$fontSize} />
      )}
    </FileLink>
  );
}

const FileLink = styled.a`
  color: ${({ theme }) => theme.colors.blue11};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.colors.blue05};
  }
  height: 22px;
`;

export default FileColumn;
