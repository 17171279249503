// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/funnel
import React, { useMemo, useState } from "react";
import { ResponsiveFunnel } from "@nivo/funnel";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import styled from "styled-components";
import { Card, Col, Modal, Row } from "antd";
import { FallOutlined, RiseOutlined, LineOutlined } from "@ant-design/icons";
import { schemePaired } from "d3-scale-chromatic";
import { useQuery } from "react-query";
import { formatNumberString } from "../../utils/formatNumberString";
import TableContainer from "../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { numberSorter } from "../../utils/numberSorter";
import NumberCell from "../react-ui/NumberCell";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const fetchOpportunitiesWonDetails = async ({
  user,
  entitiesToFilter,
  startDate,
  endDate,
  externalOrderAnonymous,
}) => {
  const response = await fetch(
    `/dashboard/filter_detailled_user_opportunities_won?entities=${entitiesToFilter}&users=${user}&start_date=${startDate}&end_date=${endDate}&external_order_anonymous=${externalOrderAnonymous}`
  );
  const data = await response.json();
  return data;
};

const fetchOpportunitiesWonEntitiesDetails = async ({
  usersToFilter,
  entity,
  startDate,
  endDate,
  externalOrderAnonymous,
}) => {
  const response = await fetch(
    `/dashboard/filter_detailled_user_opportunities_won?entities=${entity}&users=${usersToFilter}&start_date=${startDate}&end_date=${endDate}&external_order_anonymous=${externalOrderAnonymous}`
  );
  const data = await response.json();
  return data;
};

const fetchOpportunitiesDetails = async ({
  user,
  entitiesToFilter,
  startDate,
  endDate,
  externalOrderAnonymous,
}) => {
  const response = await fetch(
    `/dashboard/filter_detailled_user_opportunities?entities=${entitiesToFilter}&users=${user}&start_date=${startDate}&end_date=${endDate}&external_order_anonymous=${externalOrderAnonymous}`
  );
  const dataOpportunitiesDetails = await response.json();
  return dataOpportunitiesDetails;
};

const fetchOpportunitiesEntitiesDetails = async ({
  usersToFilter,
  entity,
  startDate,
  endDate,
  externalOrderAnonymous,
}) => {
  const response = await fetch(
    `/dashboard/filter_detailled_user_opportunities?entities=${entity}&users=${usersToFilter}&start_date=${startDate}&end_date=${endDate}&external_order_anonymous=${externalOrderAnonymous}`
  );
  const data = await response.json();
  return data;
};

function Charts({
  opportunities_submission,
  opportunities_status,
  opportunities_turnover,
  cumulated_opportunities_turnover,
  detailled_users,
  detailled_entities,
  average_sales_cycle_duration,
  externalOrderAnonymous,
  entitiesToFilter,
  usersToFilter,
  startDate,
  endDate,
}) {
  const won = opportunities_status.find((opportunity) => {
    return opportunity.status_name === "won";
  });
  const prospects = opportunities_status.find((opportunity) => {
    return opportunity.status_name === "prospect";
  });
  const working = opportunities_status.find((opportunity) => {
    return opportunity.status_name === "pending";
  });
  const waiting = opportunities_status.find((opportunity) => {
    return opportunity.status_name === "given";
  });
  const lost = opportunities_status.find((opportunity) => {
    return opportunity.status_name === "lost";
  });
  const abandoned = opportunities_status.find((opportunity) => {
    return opportunity.status_name === "abandoned";
  });
  const prospectStatusCount = prospects?.status_count || 0;
  const workingStatusCount = working?.status_count || 0;
  const waitingStatusCount = waiting?.status_count || 0;
  const wonStatusCount = won?.status_count || 0;
  const lostStatusCount = lost?.status_count || 0;
  const abandonnedStatusCount = abandoned?.status_count || 0;
  const totalStatus = wonStatusCount + lostStatusCount + abandonnedStatusCount;
  const wonStatusTurnover = won?.status_turnover || 0;
  const wonStatusTurnoverGrowth =
    Math.round(won?.status_turnover_growth) || null;
  const wonStatusTurnoverAverage = won?.status_turnover_average || 0;
  const wonStatusTurnoverAverageGrowth =
    Math.round(won?.status_turnover_average_growth) || null;
  const wonStatusMargin = won?.status_margin || 0;
  const wonStatusMarginGrowth = Math.round(won?.status_margin_growth) || null;
  const wonStatusMarginAverage = won?.status_margin_average || 0;
  const opportunitiesSubmittedResume = opportunities_submission[0];
  const submittedCount = opportunitiesSubmittedResume?.count || 0;
  const submittedTurnover = opportunitiesSubmittedResume?.turnover || 0;
  const submittedTurnoverGrowth =
    Math.round(opportunitiesSubmittedResume?.turnover_growth) || null;
  const submittedMargin = opportunitiesSubmittedResume?.margin || 0;
  const submittedMarginGrowth =
    Math.round(opportunitiesSubmittedResume?.margin_growth) || null;
  const submittedTurnoverAverage =
    opportunitiesSubmittedResume?.turnover_average || 0;
  const submittedTurnoverAverageGrowth =
    Math.round(opportunitiesSubmittedResume?.turnover_average_growth) || null;
  const submittedMarginAverage =
    opportunitiesSubmittedResume?.margin_average || 0;

  const formatDataFunnel = () => {
    return [
      {
        id: "step_prospect",
        value: prospectStatusCount,
        label: "Prospect",
      },
      {
        id: "step_working",
        value: workingStatusCount,
        label: "En cours",
      },
      {
        id: "step_waiting",
        value: waitingStatusCount,
        label: "Remis",
      },
      {
        id: "step_won",
        value: wonStatusCount,
        label: "Gagné",
      },
    ];
  };

  const formatDataOpportunitiesStatus = () => {
    return [
      {
        id: "Gagnées",
        value: wonStatusCount / totalStatus,
        label: "Gagnées",
        color: "#008000",
      },
      {
        id: "Perdues",
        value: lostStatusCount / totalStatus,
        label: "Perdues",
        color: "#FF0000",
      },
      {
        id: "Abandonnées",
        value: abandonnedStatusCount / totalStatus,
        label: "Abandonnées",
        color: "#FFA500",
      },
    ];
  };

  const formatedIcon = (percentage) => {
    let icon;
    if (percentage > 0) {
      icon = <RiseOutlined />;
    } else if (percentage < 0) {
      icon = <FallOutlined />;
    } else if (percentage === 0) {
      icon = <LineOutlined />;
    }
    return icon;
  };

  const opportunitiesWinMoney = () => {
    return wonStatusTurnover / 100;
  };

  const opportunitiesWinMoneyFormated = () => {
    let final_price = opportunitiesWinMoney();
    final_price = formatNumberString({
      str: final_price,
      nbDecimal: 2,
      space: true,
    });
    return final_price;
  };

  const opportunitiesWinGrowthPercent = () => {
    return wonStatusTurnoverGrowth * 100;
  };

  const opportunitiesWinGrowthPercentFormated = () => {
    if (wonStatusTurnoverGrowth !== null) {
      let percent = Math.abs(opportunitiesWinGrowthPercent());
      percent = formatNumberString({
        str: percent,
        nbDecimal: 0,
        space: true,
      });
      return `${percent}%`;
    }
    return "Non applicable";
  };

  const opportunitiesMoney = () => {
    return submittedTurnover / 100;
  };

  const opportunitiesMoneyFormated = () => {
    let final_price = opportunitiesMoney();
    final_price = formatNumberString({
      str: final_price,
      nbDecimal: 2,
      space: true,
    });
    return final_price;
  };

  const opportunitiesGrowthPercent = () => {
    return submittedTurnoverGrowth * 100;
  };

  const opportunitiesGrowthPercentFormated = () => {
    if (submittedTurnoverGrowth !== null) {
      let percent = Math.abs(opportunitiesGrowthPercent());
      percent = formatNumberString({
        str: percent,
        nbDecimal: 0,
        space: true,
      });
      return `${percent}%`;
    }
    return "Non applicable";
  };

  const opportunitiesWinMargin = () => {
    return wonStatusMargin / 100;
  };

  const opportunitiesWinMarginFormated = () => {
    let final_price = opportunitiesWinMargin();
    final_price = formatNumberString({
      str: final_price,
      nbDecimal: 2,
      space: true,
    });
    return final_price;
  };

  const opportunitiesWinMarginGrowthPercent = () => {
    return wonStatusMarginGrowth / 100;
  };

  const opportunitiesWinMarginGrowthPercentFormated = () => {
    if (wonStatusMarginGrowth !== null) {
      let percent = Math.abs(opportunitiesWinMarginGrowthPercent());
      percent = formatNumberString({
        str: percent,
        nbDecimal: 0,
        space: true,
      });
      return `${percent}%`;
    }
    return "Non applicable";
  };

  const opportunitiesMargin = () => {
    return submittedMargin / 100;
  };

  const opportunitiesMarginFormated = () => {
    let final_price = opportunitiesMargin();
    final_price = formatNumberString({
      str: final_price,
      nbDecimal: 2,
      space: true,
    });
    return final_price;
  };

  const opportunitiesMarginGrowthPercent = () => {
    return submittedMarginGrowth * 100;
  };

  const opportunitiesMarginGrowthPercentFormated = () => {
    if (submittedMarginGrowth !== null) {
      let percent = Math.abs(opportunitiesMarginGrowthPercent());
      percent = formatNumberString({
        str: percent,
        nbDecimal: 0,
        space: true,
      });
      return `${percent}%`;
    }
    return "Non applicable";
  };

  const opportunitiesWinAverage = () => {
    let average = wonStatusTurnoverAverage / 100;
    average = formatNumberString({
      str: average,
      nbDecimal: 2,
      space: true,
    });
    return average;
  };

  const opportunitiesWinTurnoverAverageGrowthPercent = () => {
    return wonStatusTurnoverAverageGrowth * 100;
  };

  const opportunitiesWinTurnoverAverageGrowthPercentFormated = () => {
    if (wonStatusTurnoverAverageGrowth !== null) {
      let percent = Math.abs(opportunitiesWinTurnoverAverageGrowthPercent());
      percent = formatNumberString({
        str: percent,
        nbDecimal: 0,
        space: true,
      });
      return `${percent}%`;
    }
    return "Non applicable";
  };

  const opportunitiesAverage = () => {
    let average = submittedTurnoverAverage / 100;
    average = formatNumberString({
      str: average,
      nbDecimal: 2,
      space: true,
    });
    return average;
  };

  const opportunitiesTurnoverAverageGrowthPercent = () => {
    return submittedTurnoverAverageGrowth * 100;
  };

  const opportunitiesTurnoverAverageGrowthPercentFormated = () => {
    if (submittedTurnoverAverageGrowth !== null) {
      let percent = Math.abs(opportunitiesTurnoverAverageGrowthPercent());
      percent = formatNumberString({
        str: percent,
        nbDecimal: 0,
        space: true,
      });
      return `${percent}%`;
    }
    return "Non applicable";
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [isModalOpenEntity, setIsModalOpenEntity] = useState(false);
  const [modalDataEntity, setModalDataEntity] = useState([]);
  const [isModalOpenUserAll, setIsModalOpenUserAll] = useState(false);
  const [modalDataUserAll, setModalDataUserAll] = useState([]);
  const [isModalOpenEntityAll, setIsModalOpenEntityAll] = useState(false);
  const [modalDataEntityAll, setModalDataEntityAll] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedEntityName, setSelectedEntityName] = useState("");

  // Fonction pour ouvrir le modal et récupérer les données
  const showModal = async (user, name) => {
    setSelectedUser(user);
    setSelectedUserName(name);
    setIsModalOpen(true);

    const data = await fetchOpportunitiesWonDetails({
      user,
      entitiesToFilter,
      startDate,
      endDate,
      externalOrderAnonymous,
    });
    setModalData(data);
  };

  // Fonction pour fermer le modal
  const handleCancel = () => {
    setIsModalOpen(false);
    setModalData([]);
    setSelectedUser(null);
  };

  const showModalEntity = async (entity, name) => {
    setSelectedEntity(entity);
    setSelectedEntityName(name);
    setIsModalOpenEntity(true);

    const dataEntitiesWon = await fetchOpportunitiesWonEntitiesDetails({
      usersToFilter,
      entity,
      startDate,
      endDate,
      externalOrderAnonymous,
    });
    setModalDataEntity(dataEntitiesWon);
  };

  // Fonction pour fermer le modal
  const handleCancelEntity = () => {
    setIsModalOpenEntity(false);
    setModalDataEntity([]);
    setSelectedEntity(null);
  };

  const showModalUserAll = async (user, name) => {
    setSelectedUser(user);
    setSelectedUserName(name);
    setIsModalOpenUserAll(true);

    const dataOpportunitiesDetails = await fetchOpportunitiesDetails({
      user,
      entitiesToFilter,
      startDate,
      endDate,
      externalOrderAnonymous,
    });
    setModalDataUserAll(dataOpportunitiesDetails);
  };

  const handleCancelUserAll = () => {
    setIsModalOpenUserAll(false);
    setModalDataUserAll([]);
    setSelectedUser(null);
  };

  const showModalEntityAll = async (entity, name) => {
    setSelectedEntity(entity);
    setSelectedEntityName(name);
    setIsModalOpenEntityAll(true);

    const dataEntitiesAll = await fetchOpportunitiesEntitiesDetails({
      usersToFilter,
      entity,
      startDate,
      endDate,
      externalOrderAnonymous,
    });
    setModalDataEntityAll(dataEntitiesAll);
  };

  // Fonction pour fermer le modal
  const handleCancelEntityAll = () => {
    setIsModalOpenEntityAll(false);
    setModalDataEntityAll([]);
    setSelectedEntity(null);
  };

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  }

  const columnsModal = [
    {
      title: "Nom de l'opportunité",
      dataIndex: "opportunity_name",
      key: "name",
      width: "30%",
    },
    {
      title: "Client facturé",
      dataIndex: ["client", "name"],
      key: "client_name",
      width: "15%",
      render: (value) => value || "",
    },
    {
      title: "Chiffre d'affaires",
      dataIndex: "total_price",
      key: "revenue",
      align: "right",
      render: (value) =>
        value
          ? `${formatNumberString({ str: value, nbDecimal: 2, space: true })} €`
          : "0 €",
      width: "20%",
    },
    {
      title: "Marge",
      dataIndex: "dashboard_margin",
      key: "margin",
      align: "right",
      render: (value) =>
        value
          ? `${formatNumberString({ str: value, nbDecimal: 2, space: true })} €`
          : "0 €",
      width: "15%",
    },
    {
      title: "Date de début",
      dataIndex: "quotation_start_date",
      key: "start_date",
      render: (date) => (date ? formatDate(date) : ""),
      width: "10%",
    },
    {
      title: "Date de fin",
      dataIndex: "quotation_end_date",
      key: "start_date",
      render: (date) => (date ? formatDate(date) : ""),
      width: "10%",
    },
  ];

  const globalArray = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      width: 200,
      fixed: "left",
    },
    {
      title: "Opportunités gagnées",
      children: [
        {
          title: "Nombre",
          dataIndex: "opportunities_won",
          key: "opportunities_won",
          width: 75,
          align: "right",
          sorter: (a, b) =>
            numberSorter(a.opportunities_won, b.opportunities_won),
          render: (value, record) =>
            value ? (
              <StyledLink onClick={() => showModal(record.key, record.name)}>
                <NumberCell>
                  {formatNumberString({
                    str: value,
                    nbDecimal: 2,
                    space: true,
                  })}
                </NumberCell>
              </StyledLink>
            ) : (
              0
            ),
        },
        {
          title: "Valeur HT",
          dataIndex: "opportunities_won_money",
          key: "opportunities_won_money",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(a.opportunities_won_money, b.opportunities_won_money),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },
    {
      title: "Marge gagnée",
      children: [
        {
          title: "Taux",
          dataIndex: "margin_rate_won",
          key: "margin_rate_won",
          width: 60,
          align: "right",
          sorter: (a, b) => numberSorter(a.margin_rate_won, b.margin_rate_won),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                %
              </NumberCell>
            ) : (
              "0%"
            ),
        },
        {
          title: "Valeur HT",
          dataIndex: "margin_money_won",
          key: "margin_money_won",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(a.margin_money_won, b.margin_money_won),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },
    {
      title: "Opportunités chiffrées",
      children: [
        {
          title: "Nombre",
          dataIndex: "opportunities_calculated",
          key: "opportunities_calculated",
          width: 75,
          align: "right",
          sorter: (a, b) =>
            numberSorter(
              a.opportunities_calculated,
              b.opportunities_calculated
            ),
          render: (value, record) =>
            value ? (
              <StyledLink
                onClick={() => showModalUserAll(record.key, record.name)}
              >
                <NumberCell>
                  {formatNumberString({
                    str: value,
                    nbDecimal: 2,
                    space: true,
                  })}
                </NumberCell>
              </StyledLink>
            ) : (
              0
            ),
        },
        {
          title: "Valeur HT",
          dataIndex: "opportunities_calculated_money",
          key: "opportunities_calculated_money",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(
              a.opportunities_calculated_money,
              b.opportunities_calculated_money
            ),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },
    {
      title: "Marge chiffrée",
      children: [
        {
          title: "Taux",
          dataIndex: "margin_rate_calculated",
          key: "margin_rate_calculated",
          width: 60,
          align: "right",
          sorter: (a, b) =>
            numberSorter(a.margin_rate_calculated, b.margin_rate_calculated),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                %
              </NumberCell>
            ) : (
              "0%"
            ),
        },
        {
          title: "Valeur HT",
          dataIndex: "margin_money_calculated",
          key: "margin_money_calculated",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(a.margin_money_calculated, b.margin_money_calculated),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },
    {
      title: "Taux transformation",
      dataIndex: "transformation_rate",
      key: "transformation_rate",
      width: 110,
      align: "right",
      sorter: (a, b) =>
        numberSorter(a.transformation_rate, b.transformation_rate),
      render: (value) =>
        value ? (
          <NumberCell>
            {formatNumberString({
              str: value,
              nbDecimal: 2,
              space: true,
            })}
            %
          </NumberCell>
        ) : (
          "0%"
        ),
    },
    {
      title: "Durée moyenne du cyle de vente",
      dataIndex: "sales_cycle_duration_average",
      key: "sales_cycle_duration_average",
      width: 100,
      align: "right",
      sorter: (a, b) =>
        numberSorter(
          a.sales_cycle_duration_average,
          b.sales_cycle_duration_average
        ),
      render: (value) =>
        value ? (
          <NumberCell>
            {formatNumberString({
              str: value,
              nbDecimal: 2,
              space: true,
            })}
            j
          </NumberCell>
        ) : (
          "0"
        ),
    },
    {
      title: "Valeur moyenne  HT opportunité",
      children: [
        {
          title: "Gagnée",
          dataIndex: "opportunity_money_average_won",
          key: "opportunity_money_average_won",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(
              a.opportunity_money_average_won,
              b.opportunity_money_average_won
            ),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
        {
          title: "Chiffrée",
          dataIndex: "opportunity_money_average_calculated",
          key: "opportunity_money_average_calculated",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(
              a.opportunity_money_average_calculated,
              b.opportunity_money_average_calculated
            ),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },

    {
      title: "Commandes externes",
      children: [
        {
          title: "Nombre",
          dataIndex: "external_order",
          key: "external_order",
          width: 75,
          align: "right",
          sorter: (a, b) => numberSorter(a.external_order, b.external_order),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
              </NumberCell>
            ) : (
              0
            ),
        },
        {
          title: "Valeur HT",
          dataIndex: "external_order_money",
          key: "external_order_money",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(a.external_order_money, b.external_order_money),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },

    {
      title: "Marge commande externe",
      children: [
        {
          title: "Taux",
          dataIndex: "margin_rate_external_order",
          key: "margin_rate_external_order",
          width: 60,
          align: "right",
          sorter: (a, b) =>
            numberSorter(
              a.margin_rate_external_order,
              b.margin_rate_external_order
            ),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                %
              </NumberCell>
            ) : (
              "0%"
            ),
        },
        {
          title: "Valeur HT",
          dataIndex: "margin_money_external_order",
          key: "margin_money_external_order",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(
              a.margin_money_external_order,
              b.margin_money_external_order
            ),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },
  ];

  const globalArrayEntities = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      width: 200,
      fixed: "left",
    },
    {
      title: "Opportunités gagnées",
      children: [
        {
          title: "Nombre",
          dataIndex: "opportunities_won",
          key: "opportunities_won",
          width: 75,
          align: "right",
          sorter: (a, b) =>
            numberSorter(a.opportunities_won, b.opportunities_won),
          render: (value, record) =>
            value ? (
              <StyledLink
                onClick={() => showModalEntity(record.key, record.name)}
              >
                <NumberCell>
                  {formatNumberString({
                    str: value,
                    nbDecimal: 2,
                    space: true,
                  })}
                </NumberCell>
              </StyledLink>
            ) : (
              0
            ),
        },
        {
          title: "Valeur HT",
          dataIndex: "opportunities_won_money",
          key: "opportunities_won_money",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(a.opportunities_won_money, b.opportunities_won_money),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },
    {
      title: "Marge gagnée",
      children: [
        {
          title: "Taux",
          dataIndex: "margin_rate_won",
          key: "margin_rate_won",
          width: 60,
          align: "right",
          sorter: (a, b) => numberSorter(a.margin_rate_won, b.margin_rate_won),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                %
              </NumberCell>
            ) : (
              "0%"
            ),
        },
        {
          title: "Valeur HT",
          dataIndex: "margin_money_won",
          key: "margin_money_won",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(a.margin_money_won, b.margin_money_won),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },
    {
      title: "Opportunités chiffrées",
      children: [
        {
          title: "Nombre",
          dataIndex: "opportunities_calculated",
          key: "opportunities_calculated",
          width: 75,
          align: "right",
          sorter: (a, b) =>
            numberSorter(
              a.opportunities_calculated,
              b.opportunities_calculated
            ),
          render: (value, record) =>
            value ? (
              <StyledLink
                onClick={() => showModalEntityAll(record.key, record.name)}
              >
                <NumberCell>
                  {formatNumberString({
                    str: value,
                    nbDecimal: 2,
                    space: true,
                  })}
                </NumberCell>
              </StyledLink>
            ) : (
              0
            ),
        },
        {
          title: "Valeur HT",
          dataIndex: "opportunities_calculated_money",
          key: "opportunities_calculated_money",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(
              a.opportunities_calculated_money,
              b.opportunities_calculated_money
            ),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },
    {
      title: "Marge chiffrée",
      children: [
        {
          title: "Taux",
          dataIndex: "margin_rate_calculated",
          key: "margin_rate_calculated",
          width: 60,
          align: "right",
          sorter: (a, b) =>
            numberSorter(a.margin_rate_calculated, b.margin_rate_calculated),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                %
              </NumberCell>
            ) : (
              "0%"
            ),
        },
        {
          title: "Valeur HT",
          dataIndex: "margin_money_calculated",
          key: "margin_money_calculated",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(a.margin_money_calculated, b.margin_money_calculated),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },
    {
      title: "Taux transformation",
      dataIndex: "transformation_rate",
      key: "transformation_rate",
      width: 110,
      align: "right",
      sorter: (a, b) =>
        numberSorter(a.transformation_rate, b.transformation_rate),
      render: (value) =>
        value ? (
          <NumberCell>
            {formatNumberString({
              str: value,
              nbDecimal: 2,
              space: true,
            })}
            %
          </NumberCell>
        ) : (
          "0%"
        ),
    },
    {
      title: "Durée moyenne du cyle de vente",
      dataIndex: "sales_cycle_duration_average",
      key: "sales_cycle_duration_average",
      width: 100,
      align: "right",
      sorter: (a, b) =>
        numberSorter(
          a.sales_cycle_duration_average,
          b.sales_cycle_duration_average
        ),
      render: (value) =>
        value ? (
          <NumberCell>
            {formatNumberString({
              str: value,
              nbDecimal: 2,
              space: true,
            })}
            j
          </NumberCell>
        ) : (
          "0"
        ),
    },
    {
      title: "Valeur moyenne  HT opportunité",
      children: [
        {
          title: "Gagnée",
          dataIndex: "opportunity_money_average_won",
          key: "opportunity_money_average_won",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(
              a.opportunity_money_average_won,
              b.opportunity_money_average_won
            ),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
        {
          title: "Chiffrée",
          dataIndex: "opportunity_money_average_calculated",
          key: "opportunity_money_average_calculated",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(
              a.opportunity_money_average_calculated,
              b.opportunity_money_average_calculated
            ),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },

    {
      title: "Commandes externes",
      children: [
        {
          title: "Nombre",
          dataIndex: "external_order",
          key: "external_order",
          width: 75,
          align: "right",
          sorter: (a, b) => numberSorter(a.external_order, b.external_order),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
              </NumberCell>
            ) : (
              0
            ),
        },
        {
          title: "Valeur HT",
          dataIndex: "external_order_money",
          key: "external_order_money",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(a.external_order_money, b.external_order_money),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },

    {
      title: "Marge commande externe",
      children: [
        {
          title: "Taux",
          dataIndex: "margin_rate_external_order",
          key: "margin_rate_external_order",
          width: 60,
          align: "right",
          sorter: (a, b) =>
            numberSorter(
              a.margin_rate_external_order,
              b.margin_rate_external_order
            ),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                %
              </NumberCell>
            ) : (
              "0%"
            ),
        },
        {
          title: "Valeur HT",
          dataIndex: "margin_money_external_order",
          key: "margin_money_external_order",
          width: 100,
          align: "right",
          sorter: (a, b) =>
            numberSorter(
              a.margin_money_external_order,
              b.margin_money_external_order
            ),
          render: (value) =>
            value ? (
              <NumberCell>
                {formatNumberString({
                  str: value,
                  nbDecimal: 2,
                  space: true,
                })}
                €
              </NumberCell>
            ) : (
              "0€"
            ),
        },
      ],
    },
  ];

  const keys = useMemo(() => {
    const desassembledKeys = [
      ["CA HT estimé", " (opportunité)", "", ""],
      ["CA HT signé", " (opportunité)", "", ""],
      ["CA HT signé", " (commande", " externe", " utilisateur)"],
    ];
    if (externalOrderAnonymous) {
      desassembledKeys.push([
        "CA HT signé",
        " (commande",
        " externe",
        " anonyme)",
      ]);
    }
    const assembledKeys = desassembledKeys.map((key) => key.join(""));
    return { assembledKeys, desassembledKeys };
  }, [externalOrderAnonymous]);

  const legends = useMemo(() => {
    const commonProps = {
      anchor: "bottom-right",
      direction: "column",
      justify: false,
      translateX: 120,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 70,
      itemDirection: "left-to-right",
      itemOpacity: 0.85,
      symbolSize: 10,
      effects: [
        {
          on: "hover",
          style: {
            itemOpacity: 1,
          },
        },
      ],
    };
    return [
      {
        ...commonProps,
        translateY: 0,
        dataFrom: "keys",
        data: keys.desassembledKeys.map((item, idx) => {
          return {
            id: item[0],
            label: item[0],
            color: schemePaired[idx],
          };
        }),
      },
      {
        ...commonProps,
        translateY: 15,
        dataFrom: "keys",
        data: keys.desassembledKeys.map((item) => ({
          id: item[1],
          label: item[1],
          color: "transparent",
        })),
      },
      {
        ...commonProps,
        translateY: 30,
        dataFrom: "keys",
        data: keys.desassembledKeys.map((item) => ({
          id: item[2],
          label: item[2],
          color: "transparent",
        })),
      },
      {
        ...commonProps,
        translateY: 45,
        dataFrom: "keys",
        data: keys.desassembledKeys.map((item) => ({
          id: item[3],
          label: item[3],
          color: "transparent",
        })),
      },
    ];
  }, [keys]);

  return (
    <ContainerCharts>
      <Container>
        <WrapperLeftHalf>
          <Title>Performance commerciale</Title>
          <Wrapper>
            <ResponsivePie
              data={formatDataOpportunitiesStatus()}
              margin={{ top: 5, right: 10, bottom: 80, left: 10 }}
              valueFormat=" >-.0~%"
              sortByValue
              innerRadius={0.5}
              padAngle={0.7}
              cornerRadius={3}
              activeOuterRadiusOffset={8}
              colors={{ datum: "data.color" }}
              borderWidth={1}
              borderColor={{
                from: "color",
                modifiers: [["darker", 0.2]],
              }}
              enableArcLinkLabels={false}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextColor="#343a40"
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: "color" }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor="#343a40"
              legends={[
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: 56,
                  itemsSpacing: 0,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]}
            />
          </Wrapper>
        </WrapperLeftHalf>
        <WrapperRightHalf>
          <Title>Pipe commercial</Title>
          <Wrapper>
            <ResponsiveFunnel
              data={formatDataFunnel()}
              margin={{ top: 5, right: 10, bottom: 20, left: 10 }}
              valueFormat=">-.0f"
              colors={{ scheme: "blues" }}
              borderWidth={20}
              labelColor={{
                from: "color",
                modifiers: [["darker", 3]],
              }}
              beforeSeparatorLength={30}
              beforeSeparatorOffset={20}
              afterSeparatorLength={30}
              afterSeparatorOffset={20}
              currentPartSizeExtension={10}
              currentBorderWidth={40}
              motionConfig="wobbly"
            />
          </Wrapper>
        </WrapperRightHalf>
      </Container>
      <Container>
        <WrapperCardLeft>
          <Row gutter={16} align="middle" justify="space-between">
            <Col style={{ width: "33%" }}>
              <Card title="Opportunités gagnées HT">
                <Paragraph>
                  {opportunitiesWinMoneyFormated()}
                  {"€ "}
                  <CardValue percentage={wonStatusTurnoverGrowth}>
                    {"   "}
                    {formatedIcon(wonStatusTurnoverGrowth)}
                    {opportunitiesWinGrowthPercentFormated()}
                  </CardValue>
                </Paragraph>
              </Card>
            </Col>
            <Col style={{ width: "33%" }}>
              <Card title="Marge gagnée">
                <Paragraph>
                  {opportunitiesWinMarginFormated()}
                  {"€ "}
                  <CardValue percentage={wonStatusMarginGrowth}>
                    {"   "}
                    {formatedIcon(wonStatusMarginGrowth)}
                    {opportunitiesWinMarginGrowthPercentFormated()}
                  </CardValue>
                </Paragraph>
              </Card>
            </Col>
            <Col style={{ width: "33%" }}>
              <Card title="Opportunité moyenne gagnée HT">
                <Paragraph>
                  {opportunitiesWinAverage()}
                  {"€ "}
                  <CardValue percentage={wonStatusTurnoverAverageGrowth}>
                    {"   "}
                    {formatedIcon(wonStatusTurnoverAverageGrowth)}
                    {opportunitiesWinTurnoverAverageGrowthPercentFormated()}
                  </CardValue>
                </Paragraph>
              </Card>
            </Col>
            <Col style={{ width: "33%" }}>
              <Card title="Opportunités chiffrées HT">
                <Paragraph>
                  {opportunitiesMoneyFormated()}
                  {"€ "}
                  <CardValue percentage={submittedTurnoverGrowth}>
                    {"   "}
                    {formatedIcon(submittedTurnoverGrowth)}
                    {opportunitiesGrowthPercentFormated()}
                  </CardValue>
                </Paragraph>
              </Card>
            </Col>
            <Col style={{ width: "33%" }}>
              <Card title="Marge chiffrée">
                <Paragraph>
                  {opportunitiesMarginFormated()}
                  {"€ "}
                  <CardValue percentage={submittedMarginGrowth}>
                    {"   "}
                    {formatedIcon(submittedMarginGrowth)}
                    {opportunitiesMarginGrowthPercentFormated()}
                  </CardValue>
                </Paragraph>
              </Card>
            </Col>
            <Col style={{ width: "33%" }}>
              <Card title="Opportunité moyenne chiffrée HT">
                <Paragraph>
                  {opportunitiesAverage()}
                  {"€ "}
                  <CardValue percentage={submittedTurnoverAverageGrowth}>
                    {"   "}
                    {formatedIcon(submittedTurnoverAverageGrowth)}
                    {opportunitiesTurnoverAverageGrowthPercentFormated()}
                  </CardValue>
                </Paragraph>
              </Card>
            </Col>
          </Row>
        </WrapperCardLeft>
        <WrapperCardRight>
          <Row gutter={16} align="middle" justify="space-between">
            <Col style={{ width: "100%" }}>
              <Card title="Durée moyenne du cycle de vente">
                <Paragraph>{average_sales_cycle_duration}</Paragraph>
              </Card>
            </Col>
          </Row>
        </WrapperCardRight>
      </Container>
      <Container>
        <WrapperLeftHalf>
          <Title>CA HT cumulé</Title>
          <WrapperLine>
            <ResponsiveLine
              data={cumulated_opportunities_turnover}
              margin={{ top: 50, right: 107, bottom: 50, left: 65 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              curve="monotoneX"
              yFormat={(value) =>
                `${Number(value).toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                })} €`
              }
              axisTop={null}
              axisRight={null}
              axisBottom={{
                orient: "bottom",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
                legendOffset: 36,
                legendPosition: "middle",
              }}
              axisLeft={{
                orient: "left",
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
                legendOffset: -40,
                legendPosition: "middle",
                format: (value) =>
                  `${Number(value / 1000).toLocaleString("fr-FR", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })} k€`,
              }}
              colors={{ scheme: "paired" }}
              lineWidth={4}
              pointSize={10}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              enableSlices="x"
              useMesh
              legends={[
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]}
            />
          </WrapperLine>
        </WrapperLeftHalf>
        <WrapperRightHalf>
          <Title>CA HT mensuel</Title>
          <WrapperLine>
            <ResponsiveBar
              data={opportunities_turnover}
              keys={keys.assembledKeys}
              indexBy="month"
              margin={{ top: 50, right: 105, bottom: 50, left: 65 }}
              padding={0.15}
              innerPadding={2}
              groupMode="grouped"
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              valueFormat={(value) =>
                `${Number(value).toLocaleString("fr-FR", {
                  minimumFractionDigits: 2,
                })} €`
              }
              colors={{ scheme: "paired" }}
              borderColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
                legendPosition: "middle",
                legendOffset: 32,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "",
                legendPosition: "middle",
                legendOffset: -40,
                format: (value) =>
                  `${Number(value / 1000).toLocaleString("fr-FR", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })} k€`,
              }}
              enableLabel={false}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
              legends={legends}
            />
          </WrapperLine>
        </WrapperRightHalf>
      </Container>
      <Container>
        <WrapperFullTable>
          <Title>Détails Personnes</Title>
          <WrapperTable>
            <TableContainer
              columns={globalArray}
              data={detailled_users}
              pagination={false}
              scroll={{ x: 1300 }}
              fontSize="12"
              rowKey="name"
            />
            <Modal
              title={`Détails des opportunités gagnées par ${selectedUserName}`}
              open={isModalOpen}
              onCancel={handleCancel}
              footer={null}
              width="50%"
              centered
            >
              <TableContainer
                columns={columnsModal}
                dataSource={modalData}
                pagination={false}
                rowKey="name"
              />
            </Modal>
            <Modal
              title={`Détails des opportunités chiffrées par ${selectedUserName}`}
              open={isModalOpenUserAll}
              onCancel={handleCancelUserAll}
              footer={null}
              width="50%"
              centered
            >
              <TableContainer
                columns={columnsModal}
                dataSource={modalDataUserAll}
                pagination={false}
                rowKey="name"
              />
            </Modal>
          </WrapperTable>
        </WrapperFullTable>
      </Container>
      <Container>
        <WrapperFullTable>
          <Title>Détails Agences</Title>
          <WrapperTable>
            <TableContainer
              columns={globalArrayEntities}
              data={detailled_entities}
              pagination={false}
              scroll={{ x: 1300 }}
              fontSize="12"
            />
            <Modal
              title={`Détails des opportunités gagnées pour ${selectedEntityName}`}
              open={isModalOpenEntity}
              onCancel={handleCancelEntity}
              footer={null}
              width="50%"
              centered
            >
              <TableContainer
                columns={columnsModal}
                dataSource={modalDataEntity}
                pagination={false}
                rowKey="name"
              />
            </Modal>
            <Modal
              title={`Détails des opportunités chiffrées pour ${selectedEntityName}`}
              open={isModalOpenEntityAll}
              onCancel={handleCancelEntityAll}
              footer={null}
              width="50%"
              centered
            >
              <TableContainer
                columns={columnsModal}
                dataSource={modalDataEntityAll}
                pagination={false}
                rowKey="name"
              />
            </Modal>
          </WrapperTable>
        </WrapperFullTable>
      </Container>
    </ContainerCharts>
  );
}

const Title = styled.h4`
  display: flex;
  font-weight: 500;
  font-size: 18px;
  font-family: "Cerebri Sans,sans-serif";
  color: #343a40;
  justify-content: center;
  margin: 0;
  margin-top: 0.375rem;
`;

const ContainerCharts = styled.div`
  justify-content: space-between;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  height: 78vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`;

const Container = styled.div`
  justify-content: space-between;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  height: fit-content;
  width: 100%;
`;

const Wrapper = styled.div`
  display: inline-block;
  width: 100%;
  height: 400px;
`;

const WrapperTable = styled.div`
  display: inline-block;
  width: 100%;
  height: fit-content;
`;

const WrapperLine = styled.div`
  display: inline-block;
  width: 100%;
  height: 400px;
`;

const WrapperRight = styled.div`
  background-color: white;
  border-radius: 8px;
  flex: 1 1 300px;
  display: inline-block;
  width: 33%;
  height: 330px;
  margin-bottom: 0.7rem;
  margin-left: 0.7rem;
`;

const WrapperLeft = styled.div`
  background-color: white;
  border-radius: 8px;
  flex: 1 1 300px;
  display: inline-block;
  width: 33%;
  height: auto;
  margin-bottom: 0.7rem;
  margin-right: 0.7rem;
`;

const WrapperFullTable = styled.div`
  background-color: white;
  border-radius: 8px;
  flex: 1 1 300px;
  display: inline-block;
  width: 100%;
  height: auto;
  margin-bottom: 0.7rem;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

const WrapperRightHalf = styled.div`
  background-color: white;
  border-radius: 8px;
  flex: 1 1 652px;
  display: inline-block;
  width: 50%;
  height: 430px;
  margin-bottom: 0.7rem;
  margin-left: 0.7rem;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

const WrapperLeftHalf = styled.div`
  background-color: white;
  border-radius: 8px;
  flex: 1 1 652px;
  display: inline-block;
  width: 50%;
  height: 430px;
  margin-bottom: 0.7rem;
  margin-right: 0.7rem;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

const WrapperCardLeft = styled.div`
  flex: 2 1 860px;
  display: inline-grid;
  width: 66%;
  height: 330px;
  margin-bottom: 0.7rem;
  margin-right: 0.7rem;
`;

const WrapperCardRight = styled.div`
  flex: 1 1 300px;
  display: inline-grid;
  width: 33%;
  height: 330px;
  margin-bottom: 0.7rem;
  margin-left: 0.7rem;
`;

const Paragraph = styled.p`
  font-size: 24px !important;
  margin-bottom: 0 !important;
`;

const CardValue = styled.span`
  font-size: 16px;
  color: ${({ percentage }) => {
    if (percentage === null) {
      return "grey";
    }
    if (percentage >= 0) {
      return "green";
    }
    return "red";
  }};
`;

const gridStyleProgress = {
  width: "50%",
  textAlign: "center",
};

const gridStyle = {
  width: "50%",
  textAlign: "center",
};

const StyledLink = styled.a`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

// const Card = styled.div`
//   flex: 1 1 300px;
//   display: inline-block;
//   width: 33%;
//   height: 100%;
//   margin-bottom: 0.7rem;
// `;
export default Charts;
