import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Form } from "antd";
import moment from "moment";
import { opportunityField } from "../../constant";
import FormCol from "../../Form/FormCol";
import TitleContainer from "../../react-ui/TitleContainer";
import DurationCol from "./DurationCol";
import FormRow from "../../Form/FormRow";
import ConcernedAgency from "./OpportunityForm/ConcernedAgency";
import OpportunityClient from "./OpportunityForm/OpportunityClient";
import OpportunityName from "./OpportunityForm/OpportunityName";
import OpportunityResponseType from "./OpportunityForm/OpportunityResponseType";
import OpportunityDateLimit from "./OpportunityForm/OpportunityDateLimit";
import OpportunityTypeCosting from "./OpportunityForm/OpportunityTypeCosting";
import OpportunityPeriodAlert from "./OpportunityForm/OpportunityPeriodAlert";
import Select from "../../react-ui/Select";
import ContactsSelectWrapper from "./ContactsSelectWrapper";
import OpportunityTva from "./OpportunityForm/OpportunityTva";

const basicResponsesType = (hasRenting, hasFA) => {
  const arr = [
    { name: "Consultation", id: "consultation" },
    // { name: "Référencement accord-cadre", id: "referencing" },
  ];
  if (hasFA)
    arr.push({ name: "Référencement accord-cadre", id: "referencing" });
  if (hasRenting) arr.push({ name: "Offre locative", id: "renting" });

  return arr;
};

const responseType = (hasRenting, hasFA) => {
  const arr = [...basicResponsesType(hasRenting, hasFA)];
  if (hasFA) arr.push({ name: "Devis accord-cadre", id: "quotation" });
  return arr;
};

const opportunityType = [
  { name: "Travaux neufs", id: "new_work" },
  { name: "Maintenance", id: "maintenance" },
];

function MandatoryPart({
  works,
  isGuarding,
  isSimple,
  responseTypeId,
  isOnlyStartDate,
  startDate,
  endDate,
  entitiesInWork,
  setClientModalOpenFrom,
  form,
  opportunity,
  hasRenting,
  hasTva,
  loadConfiguration,
  configApplication,
  isLockedByUser,
  companyWorks,
  workId,
  clientsInMemory,
  setClientsInMemory,
  formatClients,
  clientModalOpenFrom,
  companyId,
  clientActivities,
  frameworkAgreements,
  entityId,
  setContactModalOpenFrom,
  contactsInMemory,
  setContactsInMemory,
  vocabulary,
  configuration,
}) {
  const [responseTypeOptions, setResponseTypeOptions] = useState(
    basicResponsesType(
      hasRenting(),
      opportunity
        ? loadConfiguration()?.configuration.general.framework_agreement
        : loadConfiguration()?.selectedConfiguration.configuration.general
            .framework_agreement
    )
  );
  const client = Form.useWatch(opportunityField.client, form);
  const clientId = client?.value;
  const frameworkAgreementsToShow = useMemo(
    () =>
      frameworkAgreements.filter(
        (fa) =>
          fa.work_id === workId &&
          fa.entities.some((el) => el.id === entityId) &&
          fa.clients.some((el) => el.id === clientId)
      ),
    [frameworkAgreements, entityId, clientId, workId]
  );

  useEffect(() => {
    form.setFieldValue([opportunityField.frameworkAgreementId], undefined);
    if (frameworkAgreementsToShow.length > 0)
      setResponseTypeOptions([
        ...responseType(
          hasRenting(),
          opportunity
            ? loadConfiguration()?.configuration.general.framework_agreement
            : loadConfiguration()?.selectedConfiguration.configuration.general
                .framework_agreement
        ),
      ]);
    else {
      setResponseTypeOptions([
        ...basicResponsesType(
          hasRenting(),
          opportunity
            ? loadConfiguration()?.configuration.general.framework_agreement
            : loadConfiguration()?.selectedConfiguration.configuration.general
                .framework_agreement
        ),
      ]);
      if (form.getFieldValue(opportunityField.responseType) === "quotation")
        form.setFieldValue([opportunityField.responseType], undefined);
    }
  }, [
    frameworkAgreementsToShow,
    form,
    hasRenting,
    isSimple,
    loadConfiguration,
  ]);

  useEffect(() => {
    form.setFieldValue([opportunityField.frameworkAgreementId], undefined);
  }, [form, responseTypeId]);

  const clientHasFA = useCallback(() => {
    return (
      clientId &&
      frameworkAgreementsToShow.some((fa) =>
        fa.clients.some((el) => el.id === clientId)
      )
    );
  }, [clientId, frameworkAgreementsToShow]);

  const determinePeriod = () => {
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);
    const year = momentEndDate.diff(moment(momentStartDate), "years", true);
    return year;
  };

  const multipleUserWorks = () => {
    if (works.length > 1) {
      return "visible";
    }
    return "collapse";
  };

  const isCollapsed = () => {
    if (works.length > 1) {
      return "auto";
    }
    return "0px";
  };
  const component = ({ required, disabled }) => {
    return {
      opportunity_agency_concerned: (
        <ConcernedAgency
          isGuarding={isGuarding}
          loadConfiguration={loadConfiguration}
          entitiesInWork={entitiesInWork}
          required={required}
          disabled={disabled || !isLockedByUser || opportunity}
        />
      ),
      opportunity_response_type: (
        <OpportunityResponseType
          opportunity={opportunity}
          opportunityType={opportunityType}
          hasRenting={hasRenting}
          isGuarding={isGuarding}
          responseType={responseType}
          responseTypeId={responseTypeId}
          responseTypeOptions={responseTypeOptions}
          required={required}
          loadConfiguration={loadConfiguration}
        />
      ),
      opportunity_client: (
        <OpportunityClient
          formatClients={formatClients}
          clientsInMemory={clientsInMemory}
          setClientsInMemory={setClientsInMemory}
          clientHasFA={clientHasFA}
          opportunity={opportunity}
          setClientModalOpenFrom={setClientModalOpenFrom}
          required={required}
          disabled={
            configApplication.common.opportunity.disable_billed_customer ||
            !isLockedByUser
          }
          show_link={
            configApplication.common.opportunity.show_create_client &&
            isLockedByUser
          }
          companyId={companyId}
          clientActivities={clientActivities}
          form={form}
          configApplication={configApplication}
          works={works}
          clientModalOpenFrom={clientModalOpenFrom}
          loadConfiguration={loadConfiguration}
          vocabulary={vocabulary}
          configuration={configuration}
        />
      ),
      client_contacts: (
        <ContactsSelectWrapper
          companyId={companyId}
          setContactModalOpenFrom={setContactModalOpenFrom}
          contactsInMemory={contactsInMemory}
          setContactsInMemory={setContactsInMemory}
          disabled={
            configApplication.common.opportunity.disable_contacts ||
            !isLockedByUser
          }
          show_link={
            configApplication.common.opportunity.show_create_contact &&
            isLockedByUser
          }
          name={opportunityField.clientContacts}
          form={form}
          required={required}
        />
      ),
      opportunity_name: (
        <OpportunityName
          isCollapsed={isCollapsed}
          works={works}
          multipleUserWorks={multipleUserWorks}
          required={required}
          disabled={
            configApplication.common.opportunity.disable_name || !isLockedByUser
          }
        />
      ),
      opportunity_date: (
        <DurationCol
          opportunity={opportunity}
          isGuarding={isGuarding}
          hasRenting={hasRenting}
          onlyStartDate={isOnlyStartDate()}
          startDate={startDate}
          endDate={endDate}
          responseTypeId={responseTypeId}
          works={works}
          work_id={opportunity?.[opportunityField.workId]}
          isSimple={isSimple}
          required={required}
          determinePeriod={determinePeriod}
          disabled={disabled || !isLockedByUser}
        />
      ),
      opportunity_limit_date: (
        <OpportunityDateLimit
          isGuarding={isGuarding}
          isOnlyStartDate={isOnlyStartDate}
          determinePeriod={determinePeriod}
          startDate={startDate}
          required={required}
          disabled={
            configApplication.common.opportunity.disable_limit_date ||
            !isLockedByUser
          }
        />
      ),
      opportunity_period_alert: (
        <OpportunityPeriodAlert
          determinePeriod={determinePeriod}
          isGuarding={isGuarding}
          startDate={startDate}
        />
      ),
      opportunity_tva: hasTva() && (
        <OpportunityTva
          opportunity={opportunity}
          loadConfiguration={loadConfiguration}
          companyWork={companyWorks.find(
            (c) => c.work_id === opportunity?.[opportunityField.workId]
          )}
        />
      ),
      blank: (
        <FormCol width="100%">
          <div style={{ height: 100, width: "100%" }} />
        </FormCol>
      ),
    };
  };

  const filterLine = (col, line) => {
    const configurationLine = opportunity
      ? loadConfiguration()?.configuration.opportunity.mandatory[col][line].name
      : loadConfiguration()?.selectedConfiguration.configuration.opportunity
          .mandatory[col][line].name;

    const required = opportunity
      ? loadConfiguration()?.configuration.opportunity.mandatory[col][line]
          .required
      : loadConfiguration()?.selectedConfiguration.configuration.opportunity
          .mandatory[col][line].required;
    const disabled = opportunity
      ? loadConfiguration()?.configuration.opportunity.mandatory[col][line]
          .disabled === "edit" && !!opportunity
      : loadConfiguration()?.selectedConfiguration.configuration.opportunity
          .mandatory[col][line].disabled === "edit" && !!opportunity;

    const configuration_line = opportunity
      ? loadConfiguration()?.configuration.opportunity.mandatory[col][line].name
      : loadConfiguration()?.selectedConfiguration.configuration.opportunity
          .mandatory[col][line].name;

    if (configuration_line?.every((c) => c === "blank")) {
      return null;
    }
    return (
      <FormRow minHeight="100px">
        {configurationLine?.map((name, index) => {
          const isRequired = required ? required[index] : false;
          return component({ required: isRequired, disabled })[name];
        })}
      </FormRow>
    );
  };

  const define_height_of_blank = () => {
    if (opportunity) {
      if (works.length > 1) {
        return 50;
      }
      return 0;
    }
    if (!opportunity) {
      if (works.length > 1) {
        return 100;
      }
      return 0;
    }
  };

  return (
    <>
      <StyledTitleContainer label="Informations essentielles" />
      <Container>
        <FormCol width="50%">
          <OpportunityTypeCosting
            works={works}
            isCollapsed={isCollapsed}
            multipleUserWorks={multipleUserWorks}
            opportunity={opportunity}
            required
            visibility={multipleUserWorks}
          />
          {filterLine("col_1", "line_2")}
          {filterLine("col_1", "line_3")}
          {filterLine("col_1", "line_4")}
        </FormCol>
        <FormCol width="50%">
          <FormRow
            marginTop={multipleUserWorks() === "collapse" ? "0px" : "0px"}
          >
            <div style={{ height: define_height_of_blank() }} />
          </FormRow>
          {filterLine("col_2", "line_2")}
          {filterLine("col_2", "line_3")}
          {filterLine("col_2", "line_4")}
          {responseTypeId === "quotation" && (
            <FormCol
              labelOnTop
              label="Accord-cadre"
              rules={[{ required: true }]}
              name={opportunityField.frameworkAgreementId}
            >
              <StyledSelect
                options={frameworkAgreementsToShow}
                disabled={!isLockedByUser}
                placeholder="Sélectionnez un accord-cadre"
              />
            </FormCol>
          )}
        </FormCol>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const StyledTitleContainer = styled(TitleContainer)`
  margin: 0;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default MandatoryPart;
