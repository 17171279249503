import React, { useEffect, useMemo, useRef, useState } from "react";
import { array, bool, func, number, object, shape, string } from "prop-types";
import { currency_cents_to_euros } from "../../utils";
import { formatNumberString } from "../../../utils/formatNumberString";
import Form from "../../Form";
import { formulaMarginRate } from "../../../utils/formulaMarginRate";
import { stringToFloat } from "../../../utils/stringToFloat";
import { useStore } from "../../store";
import { marginToCoeff } from "../../../utils/marginConverter";
import MarginItem from "../../react-ui/MarginItem";
import { useMarginItem } from "../../hooks/useMarginItem";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
function CarrycotForm({
  isShowing,
  profils,
  onSubmit,
  updateOnChange,
  submit,
  initialCarrycot,
  profilKey,
  from,
  faIsValidate,
  hasCarrycotIncluded,
  isPackage,
  entityWorkId,
  opportunityId,
}) {
  const formRef = useRef();
  const { isUsingMargin, calculMethod } = useStore(selector);
  const { hasMargin, setHasMargin } = useMarginItem({
    initial: initialCarrycot,
  });

  const showFAFields = useMemo(
    () =>
      (from === "referencing" || from === "frameworkAgreement") &&
      (updateOnChange || isShowing),
    [from, updateOnChange, isShowing]
  );

  const formFactory = useMemo(() => {
    const formatedProfils = profils.map((profil) => {
      return {
        title: profil.name,
        ...profil,
      };
    });

    const oppNameRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Nom de la nacelle",
            disabled: !hasCarrycotIncluded,
          },
          name: "carrycot_included_name",
          label: "Intitulé accord-cadre",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const oppReferenceCodeRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Référence nacelle",
            disabled: !hasCarrycotIncluded,
          },
          name: "carrycot_included_reference_code",
          label: "Code référence accord-cadre",
        },
      },
    ];

    const firstRow = [
      {
        type: "multiple",
        items: [
          showFAFields &&
            updateOnChange && {
              type: "checkbox",
              item: {
                checkbox: {},
                name: "is_in_framework_agreement",
                label: "Intégrer à l'accord-cadre",
                disabled: from === "frameworkAgreement" && !faIsValidate,
              },
            },
          {
            type: "input",
            item: {
              input: {
                placeholder: "Nom de la nacelle",
                disabled: showFAFields,
              },
              name: "name",
              label: "Intitulé",
              rules: [
                {
                  required: true,
                  message: "Champ obligatoire.",
                },
              ],
            },
          },
          {
            type: "input",
            item: {
              input: {
                placeholder: "Code de référence",
                disabled: showFAFields,
              },
              name: "reference_code",
              label: "Code de référence",
            },
          },
        ],
      },

      profilKey === "profil_profession_id" && {
        type: "upload",
        item: {
          upload: {
            listType: "picture-card",
            maxCount: 1,
            accept: "image/*",
          },
          name: "picture",
        },
        style: { display: "flex", justifyContent: "flex-end" },
      },
    ];

    const thirdRow = [
      {
        type: "textarea",
        item: {
          textarea: {
            placeholder: "Ajoutez une description",
            disabled: showFAFields && !hasCarrycotIncluded,
          },
          name: "description",
          label: "Description",
        },
      },
    ];

    const fourthRow = [
      {
        type: "input",
        span: 11,
        item: {
          input: {
            placeholder: "0,00",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 2,
              });
            },
            onFocus: (e) => {
              formRef.current?.setFieldsValue({
                daily_cost_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              });
            },
            onBlur: (e) => {
              formRef.current?.setFieldsValue({
                daily_cost_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
            suffix: "€",
          },
          name: "daily_cost_cents",
          label: "Coût de revient journalier",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        span: 13,
        item: {
          input: {
            placeholder: "0,00",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 2,
              });
            },
            onFocus: (e) => {
              formRef.current?.setFieldsValue({
                movement_cost_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              });
            },
            onBlur: (e) => {
              formRef.current?.setFieldsValue({
                movement_cost_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
            suffix: "€",
          },
          name: "movement_cost_cents",
          label: "Coût déplacement Aller/Retour",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const fifthRow = [
      {
        type: "select",
        span: 11,
        item: {
          select: {
            placeholder: "Choisissez une profil",
          },
          options: formatedProfils,
          name: profilKey,
          label: "Personnel au sol",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        span: 6,
        item: {
          input: {
            disabled: showFAFields,
            customContent: (
              <MarginItem
                itemType="carrycot_profession"
                hasMargin={hasMargin}
                entityWorkId={entityWorkId}
                opportunityId={opportunityId}
              />
            ),
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                isCoeff: !isUsingMargin,
              });
              setHasMargin(e.currentTarget.value !== "");
            },
          },
          isMarginRate: true,
          name: "margin_rate",
        },
      },
      {
        type: "input",
        span: 7,
        item: {
          input: {
            placeholder: "100",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 0,
              });
            },
            onFocus: (e) => {
              formRef.current?.setFieldsValue({
                profil_duration_rate: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 0,
                }),
              });
            },
            onBlur: (e) => {
              formRef.current?.setFieldsValue({
                profil_duration_rate: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 0,
                }),
              });
            },
            suffix: "%",
          },
          name: "profil_duration_rate",
          label: "% de présence",
        },
      },
    ];

    const costPriceAndAbbreviationRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            suffix: "€",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
              });
            },
            disabled: showFAFields,
          },
          name: "cost_price_cents",
          label: "Coût de revient",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
        span: 12,
      },
      showFAFields &&
        !isPackage && {
          type: "input",
          item: {
            input: {
              placeholder: "Ajoutez une abbréviation",
              disabled: !hasCarrycotIncluded,
            },
            name: "abbreviation",
            label: "Abbréviation",
          },
          span: 12,
        },
    ];

    const sellingPriceAndFAMarginRate = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            suffix: "€",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 2,
              });
              formRef.current?.setFieldsValue({
                margin_rate_computed: formatNumberString({
                  str: formulaMarginRate({
                    costPrice: stringToFloat(
                      formRef.current?.form.getFieldValue("cost_price_cents")
                    ),
                    calculMethod,
                    sellingPrice: stringToFloat(e.currentTarget.value),
                    isUsingMargin,
                  }),
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
            onFocus: (e) => {
              formRef.current?.setFieldsValue({
                selling_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              });
            },
            onBlur: (e) => {
              formRef.current?.setFieldsValue({
                selling_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
            disabled: !hasCarrycotIncluded,
          },
          name: "selling_price_cents",
          label: "Prix de vente",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            disabled: true,
          },
          name: "margin_rate_computed",
          isComputed: true,
          isMarginRate: true,
        },
      },
    ];

    return [
      { columns: firstRow, key: "name+picture" },
      showFAFields && {
        columns: oppNameRow,
        key: "oppNameRow",
      },
      showFAFields && {
        columns: oppReferenceCodeRow,
        key: "oppReferenceCodeRow",
      },
      { columns: thirdRow, key: "daily_cost_cents+movement_cost_cents" },
      (from !== "admin" || isPackage) && {
        columns: costPriceAndAbbreviationRow,
        key: "costPriceRow",
      },
      showFAFields &&
        from !== "referencing" && {
          columns: sellingPriceAndFAMarginRate,
          key: "sellingPriceAndFAMarginRate",
        },
      from === "admin" &&
        !isPackage && {
          columns: fourthRow,
          key: "profil_profession_id+maintenance",
        },
      from === "admin" && !isPackage && { columns: fifthRow, key: "profil" },
    ];
  }, [
    profils,
    hasCarrycotIncluded,
    showFAFields,
    updateOnChange,
    from,
    faIsValidate,
    profilKey,
    hasMargin,
    isPackage,
    isUsingMargin,
    calculMethod,
    entityWorkId,
    opportunityId,
  ]);

  const initialValues = useMemo(() => {
    if (!initialCarrycot) return {};
    const picture = initialCarrycot.picture
      ? [
          {
            uid: "1",
            name: "image",
            status: "done",
            url: initialCarrycot.picture,
          },
        ]
      : [];

    const margin_rate = initialCarrycot.margin_rate
      ? formatNumberString({
          str: marginToCoeff({
            marginRate: initialCarrycot.margin_rate,
            isUsingMargin,
            calculMethod,
          }),
          isCoeff: !isUsingMargin,
        })
      : undefined;
    return {
      name: initialCarrycot.name,
      margin_rate,
      description: hasCarrycotIncluded
        ? initialCarrycot.carrycot_included?.description
        : initialCarrycot.description,
      profil_profession_id: initialCarrycot.profil_profession_id,
      daily_cost_cents: formatNumberString({
        str: currency_cents_to_euros(initialCarrycot.daily_cost_cents),
        nbDecimal: 2,
        space: true,
      }),
      movement_cost_cents: formatNumberString({
        str: currency_cents_to_euros(initialCarrycot.movement_cost_cents),
        nbDecimal: 2,
        space: true,
      }),
      cost_price_cents: formatNumberString({
        str: currency_cents_to_euros(initialCarrycot.cost_price_cents),
      }),
      profil_duration_rate: formatNumberString({
        str: initialCarrycot.profil_duration_rate,
      }),
      reference_code: initialCarrycot.reference_code,
      maintenance: initialCarrycot.maintenance,
      picture,
      carrycot_included_name: hasCarrycotIncluded
        ? initialCarrycot.carrycot_included?.name
        : initialCarrycot.name,
      carrycot_included_reference_code: hasCarrycotIncluded
        ? initialCarrycot.carrycot_included?.reference_code
        : initialCarrycot.reference_code,
      is_in_framework_agreement: initialCarrycot.is_in_framework_agreement,
      abbreviation: hasCarrycotIncluded
        ? initialCarrycot.carrycot_included?.abbreviation
        : "",
      selling_price_cents: !hasCarrycotIncluded
        ? formatNumberString({
            str: currency_cents_to_euros(initialCarrycot.cost_price_cents),
            nbDecimal: 2,
            space: true,
          })
        : formatNumberString({
            str: currency_cents_to_euros(
              initialCarrycot.carrycot_included?.selling_price_cents
            ),
            nbDecimal: 2,
            space: true,
          }),
      margin_rate_computed:
        !hasCarrycotIncluded || from !== "frameworkAgreement"
          ? 0
          : formatNumberString({
              str: formulaMarginRate({
                costPrice: initialCarrycot.cost_price_cents,
                calculMethod,
                sellingPrice:
                  initialCarrycot.carrycot_included?.selling_price_cents,
                isUsingMargin,
              }),
              nbDecimal: 2,
              space: true,
            }),
    };
  }, [initialCarrycot, hasCarrycotIncluded, calculMethod, from, isUsingMargin]);

  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={formFactory}
      isShowing={isShowing}
      id="carrycot-form"
      ref={formRef}
    />
  );
}

CarrycotForm.propTypes = {
  isShowing: bool,
  onSubmit: func,
  updateOnChange: bool,
  submit: shape({
    onCancel: func,
  }),
  profils: array,
  initialCarrycot: object,
  profilKey: string,
  from: string,
  faIsValidate: bool,
  hasCarrycotIncluded: bool,
  isPackage: bool,
  entityWorkId: string,
  opportunityId: number,
};

CarrycotForm.defaultProps = {
  isShowing: false,
  onSubmit: undefined,
  updateOnChange: false,
  submit: undefined,
  profils: [],
  initialCarrycot: undefined,
  profilKey: "profil_profession_id",
  from: "admin",
  faIsValidate: false,
  hasCarrycotIncluded: false,
  isPackage: false,
  entityWorkId: undefined,
  opportunityId: undefined,
};

export default CarrycotForm;
