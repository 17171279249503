import React, { useEffect, useState, useCallback } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Tree } from "antd";
import styled from "styled-components";
import { getData, postData } from "../../request/instance";
import OuvrageForm from "./OuvrageForm";
import { useStore } from "../../store";
import OuvrageModify from "./OuvrageModify";
import AddButton from "../../react-ui/AddButton";
import EditButton from "../../react-ui/EditButton";
import Delete from "../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../confirmModal";
import Loader from "../../react-ui/Loader";

function RenderTree({
  ouvrageDetailsStatus,
  treeData,
  vocabulary,
  defaultText,
}) {
  if (ouvrageDetailsStatus !== "create") {
    if (treeData?.length === 0)
      return <StylePlaceHolderDetails>{defaultText}</StylePlaceHolderDetails>;
    return (
      <Tree
        style={{ width: "100%", overflow: "scroll", height: "75vh" }}
        treeData={treeData}
        showLine
        defaultExpandAll
      />
    );
  }
  return null;
}

function OuvrageDetails({
  setOuvrageDetailsStatus,
  ouvrageDetailsStatus,
  configuration,
  setOuvrageChildId,
  ouvrageDetails,
  setOuvrageDynamicLabel,
  loading,
  vocabulary,
  defaultText,
  token,
  ouvrageSelected,
}) {
  const [treeData, setTreeData] = useState([]);

  const queryClient = useQueryClient();
  const ouvrageProfessionQueryKey = [
    "OuvrageProfession",
    ouvrageSelected[0]?.id,
  ];

  const handleOuvrageModify = useCallback(
    (id, name, ouvrage_master = null) => {
      setOuvrageDetailsStatus(ouvrage_master ? "modify" : "knot_modify");
      setOuvrageDynamicLabel(`Modifier ${name}`);
      setOuvrageChildId(id);
    },
    [setOuvrageChildId, setOuvrageDetailsStatus, setOuvrageDynamicLabel]
  );

  const handleNewKnot = useCallback(
    (id) => {
      setOuvrageDetailsStatus("knot");
      setOuvrageDynamicLabel("Créer un nouveau noeud");
      setOuvrageChildId(id);
    },
    [setOuvrageChildId, setOuvrageDetailsStatus, setOuvrageDynamicLabel]
  );

  const iconSelector = useCallback(
    (v) => {
      if (v.is_ouvrage) {
        return <i className={configuration.general.ouvrage_icon} />;
      }
      if ("profil_profession_id" in v || "prestation_parent" in v) {
        return <i className={configuration.general.prestation_icon} />;
      }
      return <i className={configuration.general.product_icon} />;
    },
    [
      configuration.general.ouvrage_icon,
      configuration.general.prestation_icon,
      configuration.general.product_icon,
    ]
  );

  const displayName = (v) => {
    if (v?.name) {
      return v.name;
    }
    if (v.material_parent?.name) {
      return v.material_parent.name;
    }
    if (v.prestation_parent?.name) {
      return v.prestation_parent.name;
    }
  };

  const displayQuantity = (v) => {
    let unity = "";
    let quantity = "";
    if (v?.is_ouvrage && v?.quantity === null) {
      return ``;
    }
    if (v?.is_ouvrage) {
      if (v?.unity_profession === null) {
        unity = "u";
      } else {
        unity = v?.unity_profession;
      }
      return `(${v.quantity}${unity})`;
    }
    if (v?.name) {
      if (v?.unity_profession_id === null) {
        if (v?.profil_profession_id != null) {
          unity = v?.default_unity_abreviation;
        } else {
          unity = "u";
        }
      } else {
        unity = v?.unity_profession_abbreviation;
      }
      return `(${v.quantity}${unity})`;
    }
    if (v.material_parent?.name) {
      if (v.material_parent?.unity_profession_id === null) {
        unity = "u";
      } else {
        unity = v.material_parent?.unity_profession.abbreviation;
      }
      return `(${v.quantity}${unity})`;
    }
    if (v.prestation_parent?.name) {
      if (v.prestation_parent?.unity_profession_id === null) {
        if (v?.prestation_parent?.profil_profession_id != null) {
          if (
            v?.prestation_parent?.entity_work?.company_work?.minutes === true
          ) {
            quantity = v.quantity * 60;
            unity = "min";
          } else {
            quantity = v.quantity;
            unity = "h";
          }
        } else {
          quantity = v.quantity;
          unity = "u";
        }
      } else {
        quantity = v.quantity;
        unity = v.prestation_parent?.unity_profession.abbreviation;
      }
      return `(${quantity}${unity})`;
    }
  };

  const { mutate: deleteOuvrageMaster, status } = useMutation(
    (todo) => postData(token, "/ouvrage_profession/delete", todo),
    {
      onSuccess: (_, payload) => {
        queryClient.invalidateQueries(ouvrageProfessionQueryKey);
        setOuvrageChildId(ouvrageSelected[0]?.id);
        setOuvrageDynamicLabel(`Modifier ${ouvrageSelected[0]?.name}`);
      },
    }
  );

  const handleDeleteOuvrageMaster = (idOuvrage) => {
    deleteOuvrageMaster(JSON.stringify({ id: idOuvrage }));
  };

  const childrenPopulate = useCallback(
    (elements = []) => {
      // debugger;
      // eslint-disable-next-line consistent-return
      return elements.map((v) => ({
        title: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="mr-2">
              {iconSelector(v)} {displayName(v)} {displayQuantity(v)}
            </div>
            {v.is_ouvrage && (
              <StyledButtonContainer>
                <EditButton
                  style={{ margin: 2, height: 30 }}
                  onClick={() =>
                    handleOuvrageModify(v.id, v.name, v.ouvrage_master)
                  }
                  label="Modifier"
                />
                <AddButton
                  style={{ margin: 2, height: 30 }}
                  onClick={() => handleNewKnot(v.id)}
                  label="Nouveau noeud"
                />
                {!v.ouvrage_master && (
                  <StyledDelete
                    onClick={() =>
                      ShowDeleteConfirm(
                        v?.name,
                        v?.id,
                        handleDeleteOuvrageMaster
                      )
                    }
                  />
                )}
              </StyledButtonContainer>
            )}
          </div>
        ),
        key: `${v.id}-${displayName(v)}-${Math.random()
          .toString(36)
          .substring(2)}`,
        children:
          (v?.child?.length > 0 ||
            v?.material_associates?.length > 0 ||
            v?.prestation_associates?.length > 0) &&
          childrenPopulate([
            ...v.material_associates,
            ...v.prestation_associates,
            ...v.child,
          ]),
      }));
    },
    [handleNewKnot, handleOuvrageModify, iconSelector]
  );

  useEffect(() => {
    if (ouvrageDetails?.length > 0) {
      setTreeData(childrenPopulate(ouvrageDetails));
    } else {
      setTreeData([]);
    }
  }, [childrenPopulate, ouvrageDetails]);

  return (
    <>
      {" "}
      {loading ? (
        <Loader />
      ) : (
        <RenderTree
          ouvrageDetailsStatus={ouvrageDetailsStatus}
          treeData={treeData}
          vocabulary={vocabulary}
          defaultText={defaultText}
        />
      )}
    </>
  );
}

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StylePlaceHolderDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledDelete = styled(Delete)`
  margin: 7px;
  padding-top: 3px;
  font-size: 14px;
`;

export default OuvrageDetails;
