import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import AddButton from "../../../react-ui/AddButton";
import TitleContainer from "../../../react-ui/TitleContainer";
import { getData, postData } from "../../../request/instance";
import { useStore } from "../../../store";
import ProfilManagementForm from "./ProfilManagementForm";
import { fetchProfilManagementsUrl } from "../../../../utils/fetchProfilManagementsUrl";
import ProfilManagementsArray from "./ProfilManagementsArray";

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

const selector = (state) => ({
  formToken: state.formToken,
});

function ProfilManagements({ users }) {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);
  const [status, setStatus] = useState("empty");
  const [selectedProfil, setSelectedProfil] = useState();
  const [wordEntered, setWordEntered] = useState("");

  const {
    data: profilManagements,
    isLoading: profilManagementsAreLoading,
    refetch,
  } = useQuery(
    "ProfilManagements",
    () =>
      getData(formToken, fetchProfilManagementsUrl({ search: wordEntered })),
    { refetchOnWindowFocus: false }
  );

  const { mutate: createProfilManagement, isLoading: createIsLoading } =
    useMutation(
      (todo) => postData(formToken, "/profil_management/create", todo),
      {
        onSettled: () => {
          queryClient.invalidateQueries("ProfilManagements");
          closeModal();
        },
      }
    );

  const { mutate: updateProfilManagement, isLoading: updateIsLoading } =
    useMutation(
      (todo) => postData(formToken, "/profil_management/update", todo),
      {
        onSettled: () => {
          queryClient.invalidateQueries("ProfilManagements");
          closeModal();
        },
      }
    );

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  const openModal = () => {
    setStatus("create");
  };

  const initialValues = useMemo(() => {
    if (status !== "update" || !selectedProfil) return undefined;

    const userIds = selectedProfil.profil_management_users.map(
      (el) => el.user_id
    );
    return {
      ...selectedProfil,
      users: userIds,
    };
  }, [selectedProfil, status]);

  const closeModal = () => {
    setStatus("empty");
    setSelectedProfil();
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const appendUserForCreation = ({ usersToCreate, formData }) => {
    let usersIdx = 0;
    usersToCreate?.forEach((userId) => {
      formData.append(
        `profil_management[profil_management_users_attributes][${usersIdx}][user_id]`,
        userId
      );
      usersIdx += 1;
    });
    return usersIdx;
  };

  const appendUserForDestroy = ({ usersToDestroy, formData, userIdx }) => {
    let newUserIdx = userIdx;
    usersToDestroy?.forEach((user) => {
      formData.append(
        `profil_management[profil_management_users_attributes][${newUserIdx}][id]`,
        user.id
      );
      formData.append(
        `profil_management[profil_management_users_attributes][${newUserIdx}][_destroy]`,
        true
      );
      newUserIdx += 1;
    });
  };

  const getUsersToDestroy = ({ usersFromForm }) => {
    return selectedProfil.profil_management_users.filter(
      ({ user_id }) => !usersFromForm.includes(user_id)
    );
  };

  const getUsersToCreate = ({ usersFromForm }) => {
    if (status === "create") return usersFromForm;

    const selectedUserIds = selectedProfil.profil_management_users.map(
      (el) => el.user_id
    );

    return usersFromForm.filter((el) => !selectedUserIds.includes(el));
  };

  const appendUsersToForm = ({ usersFromForm, formData }) => {
    if (status === "create") {
      const usersToCreate = getUsersToCreate({
        usersFromForm,
      });
      appendUserForCreation({
        usersToCreate,
        formData,
      });
    }
    if (status === "update") {
      const usersToCreate = getUsersToCreate({
        usersFromForm,
      });
      const usersToDestroy = getUsersToDestroy({
        usersFromForm,
      });

      let userIdx = 0;
      userIdx = appendUserForCreation({
        usersToCreate,
        formData,
      });
      appendUserForDestroy({
        usersToDestroy,
        formData,
        userIdx,
      });
    }
  };

  const appendProfilManagementToForm = ({ formData, values }) => {
    formData.append("profil_management[name]", values.name);

    appendUsersToForm({
      usersFromForm: values.users,
      formData,
    });
  };

  const handleSubmit = (values) => {
    const formData = new FormData();

    if (status === "create") {
      appendProfilManagementToForm({ formData, values });
      createProfilManagement(formData);
    }
    if (status === "update") {
      appendProfilManagementToForm({ formData, values });
      formData.append("id", selectedProfil.id);
      updateProfilManagement(formData);
    }
  };

  return (
    <>
      <StyledModal
        open={status !== "empty"}
        footer={null}
        width={1000}
        maskClosable={false}
        destroyOnClose
        onCancel={closeModal}
        closable={false}
      >
        <ProfilManagementForm
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          status={status}
          closeModal={closeModal}
          formIsLoading={createIsLoading || updateIsLoading}
          users={users}
        />
      </StyledModal>
      <TitleContainer label="Profils disponibles">
        <AddButton label="Créer un profil" onClick={openModal} />
      </TitleContainer>
      <StyledSearch
        allowClear
        placeholder="Rechercher par nom de profil"
        value={wordEntered}
        onChange={handleFilter}
      />
      <ProfilManagementsArray
        profilManagements={profilManagements}
        setStatus={setStatus}
        setSelectedProfil={setSelectedProfil}
        profilManagementsAreLoading={profilManagementsAreLoading}
      />
    </>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 300px;
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

const StyledSearch = styled(Search)`
  margin-bottom: 12px;
`;

export default ProfilManagements;
