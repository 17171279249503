// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/funnel
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getData } from "../request/instance";
import Charts from "./Charts";
import FilterHeader from "./FilterHeader";
import Loader from "../react-ui/Loader";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

function Dashboard({ token, users, entities, role }) {
  const [params, setParams] = useState({
    entitiesToFilter: [entities.map((entity) => entity.id)],
    usersToFilter: [users.map((user) => user.id)],
    start_date: new Date(new Date().getFullYear(), 0, 1).toDateString(),
    end_date: new Date(new Date().getFullYear(), 11, 31).toDateString(),
    user_role: role,
    external_order_anonymous: false,
  });

  const {
    data: opportunities_submission,
    refetch,
    isLoading: isLoadingOpportunitiesSubmitted,
  } = useQuery("OpportunitiesSubmission", () =>
    getData(
      token,
      `/dashboard/filter_opportunities_submission?entities=${params.entitiesToFilter}&users=${params.usersToFilter}&start_date=${params.start_date}&end_date=${params.end_date}`
    )
  );

  const {
    data: opportunities_status,
    refetch: refetchOpportunityStatus,
    isLoading: isLoadingOpportunitiesStatus,
  } = useQuery("OpportunitiesStatus", () =>
    getData(
      token,
      `/dashboard/filter_opportunities_status?entities=${params.entitiesToFilter}&users=${params.usersToFilter}&start_date=${params.start_date}&end_date=${params.end_date}`
    )
  );

  const { data: opportunities_turnover, refetch: refetchOpportunityTurnover } =
    useQuery(
      "OpportunitiesTurnover",
      () =>
        getData(
          token,
          `/dashboard/filter_opportunities_turnover?entities=${params.entitiesToFilter}&users=${params.usersToFilter}&start_date=${params.start_date}&end_date=${params.end_date}&external_order_anonymous=${params.external_order_anonymous}`
        ),
      { placeholderData: [] }
    );
  const {
    data: cumulated_opportunities_turnover,
    refetch: refetchOpportunityCumulatedTurnover,
  } = useQuery(
    "OpportunitiesCumulatedTurnover",
    () =>
      getData(
        token,
        `/dashboard/filter_opportunities_cumulated_turnover?entities=${params.entitiesToFilter}&users=${params.usersToFilter}&start_date=${params.start_date}&end_date=${params.end_date}&external_order_anonymous=${params.external_order_anonymous}`
      ),
    { placeholderData: [] }
  );

  const { data: detailled_users, refetch: refetchDetailledUsers } = useQuery(
    "DetailledUsers",
    () =>
      getData(
        token,
        `/dashboard/filter_detailled_users?entities=${params.entitiesToFilter}&users=${params.usersToFilter}&start_date=${params.start_date}&end_date=${params.end_date}&external_order_anonymous=${params.external_order_anonymous}`
      ),
    { placeholderData: [] }
  );

  const { data: detailled_entities, refetch: refetchDetailledEntities } =
    useQuery(
      "DetailledEntities",
      () =>
        getData(
          token,
          `/dashboard/filter_detailled_entities?entities=${params.entitiesToFilter}&users=${params.usersToFilter}&start_date=${params.start_date}&end_date=${params.end_date}&external_order_anonymous=${params.external_order_anonymous}`
        ),
      { placeholderData: [] }
    );

  const {
    data: average_sales_cycle_duration,
    refetch: refetchAverageSalesCycleDuration,
  } = useQuery(
    "AverageSalesCycleDuration",
    () =>
      getData(
        token,
        `/dashboard/sales_cycle_duration_total?entities=${params.entitiesToFilter}&users=${params.usersToFilter}&start_date=${params.start_date}&end_date=${params.end_date}`
      ),
    { placeholderData: [] }
  );

  useEffect(() => {
    refetch();
  }, [refetch, params]); // permet de relancer une fonction lorsque le state est changé

  useEffect(() => {
    refetchOpportunityStatus();
  }, [refetchOpportunityStatus, params]);

  useEffect(() => {
    refetchOpportunityTurnover();
  }, [refetchOpportunityTurnover, params]);

  useEffect(() => {
    refetchOpportunityCumulatedTurnover();
  }, [refetchOpportunityCumulatedTurnover, params]);

  useEffect(() => {
    refetchDetailledUsers();
  }, [refetchDetailledUsers, params]);

  useEffect(() => {
    refetchDetailledEntities();
  }, [refetchDetailledEntities, params]);

  useEffect(() => {
    refetchAverageSalesCycleDuration();
  }, [refetchAverageSalesCycleDuration, params]);

  return (
    <div>
      <FilterHeader
        params={params}
        setParams={setParams}
        entities={entities}
        users={users}
        role={role}
      />
      {!!opportunities_status &&
        !!opportunities_submission &&
        !isLoadingOpportunitiesStatus &&
        !isLoadingOpportunitiesSubmitted && (
          <Charts
            opportunities_submission={opportunities_submission}
            opportunities_status={opportunities_status}
            opportunities_turnover={opportunities_turnover}
            cumulated_opportunities_turnover={cumulated_opportunities_turnover}
            detailled_users={detailled_users}
            detailled_entities={detailled_entities}
            average_sales_cycle_duration={average_sales_cycle_duration}
            externalOrderAnonymous={params.external_order_anonymous}
            entitiesToFilter={params.entitiesToFilter}
            usersToFilter={params.usersToFilter}
            startDate={params.start_date}
            endDate={params.end_date}
          />
        )}
      {isLoadingOpportunitiesStatus && isLoadingOpportunitiesSubmitted && (
        <Loader />
      )}
    </div>
  );
}
export default Dashboard;
