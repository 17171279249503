import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["annual_revaluation"]

  change_annual_revaluation() {
    let opportunityId = this.annual_revaluationTarget.dataset["opportunityId"]
    let opportunityPeriodId = this.annual_revaluationTarget.dataset["opportunityPeriodId"]
        fetch(`/opportunities/${opportunityId}/opportunities_periods/${opportunityPeriodId}`, {
            method: "PUT",
            headers: {  "Content-Type": "application/json",
            "Accept": "text/javascript",
              "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
                     },
            body: JSON.stringify({annual_revaluation: this.annual_revaluationTarget.value })
          }).then(function(response) { return response.text() }).then(function(body) {
            eval(body)
          }.bind(this));
  }
}