import React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { offerReviewFields } from "../constant";
import FormCol from "../Form/FormCol";
import FormRow from "../Form/FormRow";
import Label from "../Form/Label";
import CreateLink from "../react-ui/CreateLink";
import Select from "../react-ui/Select";
import { getData } from "../request/instance";
import { useStore } from "../store";

const selector = (state) => ({
  formToken: state.formToken,
});

function ClientContact({
  companyId,
  updateOnChange,
  clientsList,
  decisionClientsList,
  providedClientsList,
  setContactModalOpenFrom,
}) {
  const { formToken } = useStore(selector);

  const { data: contacts } = useQuery("Contacts", async () => {
    const data = await getData(formToken, `/companies/${companyId}/contacts`);
    return data?.map((el) => ({
      ...el,
      name: `${el.last_name} ${el.first_name}`,
    }));
  });

  return (
    <>
      <FormRow marginTop="0px" justify="space-between">
        <Row>
          <StyledLabel label="Client(s) Facturé(s) : " />
          <Text>{clientsList || "Aucun client facturé"}</Text>
        </Row>
        <Row>
          <StyledLabel label="Client(s) Décisionnaire(s) : " />
          <Text>{decisionClientsList || "Aucun client décisionnaire"}</Text>
        </Row>
        <Row>
          <StyledLabel label="Client(s) Presté(s) : " />
          <Text>{providedClientsList || "Aucun client presté"}</Text>
        </Row>
      </FormRow>

      <FormRow marginTop="32px">
        <FormCol
          labelOnTop
          width="25%"
          label="Contact(s) Direction"
          name={offerReviewFields.directionContacts}
          customContent={
            <StyledCreateLink
              label="Créer un contact"
              onClick={() =>
                setContactModalOpenFrom(offerReviewFields.directionContacts)
              }
            />
          }
        >
          <StyledSelect
            options={contacts}
            placeholder="Sélectionnez un ou plusieurs contacts"
            mode="multiple"
            onChange={updateOnChange}
            maxTagCount="responsive"
          />
        </FormCol>
        <FormCol
          labelOnTop
          width="25%"
          label="Contact(s) Achat"
          name={offerReviewFields.buyerContacts}
          customContent={
            <StyledCreateLink
              label="Créer un contact"
              onClick={() =>
                setContactModalOpenFrom(offerReviewFields.buyerContacts)
              }
            />
          }
        >
          <StyledSelect
            options={contacts}
            placeholder="Sélectionnez un ou plusieurs contacts"
            mode="multiple"
            onChange={updateOnChange}
            maxTagCount="responsive"
          />
        </FormCol>
        <FormCol
          labelOnTop
          width="25%"
          label="Contact(s) Technique"
          name={offerReviewFields.technicContacts}
          customContent={
            <StyledCreateLink
              label="Créer un contact"
              onClick={() =>
                setContactModalOpenFrom(offerReviewFields.technicContacts)
              }
            />
          }
        >
          <StyledSelect
            options={contacts}
            placeholder="Sélectionnez un ou plusieurs contacts"
            mode="multiple"
            onChange={updateOnChange}
            maxTagCount="responsive"
          />
        </FormCol>
        <FormCol
          labelOnTop
          width="25%"
          label="Contact(s) Autre"
          name={offerReviewFields.otherContacts}
          customContent={
            <StyledCreateLink
              label="Créer un contact"
              onClick={() =>
                setContactModalOpenFrom(offerReviewFields.otherContacts)
              }
            />
          }
        >
          <StyledSelect
            options={contacts}
            placeholder="Sélectionnez un ou plusieurs contacts"
            mode="multiple"
            onChange={updateOnChange}
            maxTagCount="responsive"
          />
        </FormCol>
      </FormRow>
    </>
  );
}

const StyledSelect = styled(Select)`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
`;

const Text = styled.span``;

const StyledLabel = styled(Label)`
  white-space: nowrap;
`;

const StyledCreateLink = styled(CreateLink)`
  margin-top: 10px;
`;

export default ClientContact;
