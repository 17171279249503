import React, { useCallback, useEffect, useState } from "react";
import ClientsSelect from "../../Admin-cf/Clients/Form/ClientsSelect";
import ClientModal from "./ClientModal";
import ItemPopover from "./ItemPopover";

export const genNode = (item) =>
  item
    ? {
        ...item,
        title: <ItemPopover item={item} />,
        id: item.id,
        pId: item.parent_id,
        value: item.id,
        label: item.name,
      }
    : { label: undefined, value: undefined };

const generateTreeNodes = (data) => data.map(genNode);

function ClientsSelectWrapper({
  setClientsInMemory,
  clientsInMemory,
  clientModalOpenFrom,
  setClientModalOpenFrom,
  companyId,
  clientActivities,
  form,
  configApplication,
  works,
  formatClients,
  selectorId,
  vocabulary,
  configuration,
  ...props
}) {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    setClientsInMemory((olds) =>
      [...clients, ...olds].filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      )
    );
  }, [clients, setClientsInMemory]);

  const formatClientsWithNodes = useCallback(
    (cls) => {
      return generateTreeNodes(formatClients(cls));
    },
    [formatClients]
  );

  return (
    <>
      <ClientModal
        clientModalOpenFrom={clientModalOpenFrom}
        setClientModalOpenFrom={setClientModalOpenFrom}
        companyId={companyId}
        clientActivities={clientActivities}
        formOpp={form}
        configApplication={configApplication}
        works={works}
        setOtherClients={setClients}
        setClientsInMemory={setClientsInMemory}
        clientsInMemory={clientsInMemory}
        formatClients={formatClientsWithNodes}
        vocabulary={vocabulary}
        configuration={configuration}
      />
      <ClientsSelect
        setClientsInMemory={setClientsInMemory}
        clientsInMemory={clientsInMemory}
        clients={clients}
        formatClients={formatClientsWithNodes}
        setClients={setClients}
        selectorId={selectorId}
        fromOpportunity
        {...props}
      />
    </>
  );
}

export default ClientsSelectWrapper;
