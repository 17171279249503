import React, { useMemo } from "react";
import styled from "styled-components";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { stringSorter } from "../../../../utils/stringSorter";
import { ShowDeleteConfirm } from "../../confirmModal";

function GroupingsListIndex({
  groupings,
  handleSelectGrouping,
  handleDeleteGrouping,
  selectedGrouping,
}) {
  const columnsGroupings = useMemo(
    () => [
      {
        title: "Nom",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => stringSorter(a.name, b.name),
        width: "100%",
        ellipsis: true,
      },

      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "60px",
        align: "right",
        render: (_, value) => (
          <Icon
            className="fa-duotone fa-trash-xmark text-danger"
            onClick={() =>
              ShowDeleteConfirm(value.name, value.id, handleDeleteGrouping)
            }
          />
        ),
      },
    ],
    [groupings]
  );
  return (
    <div>
      <TableContainer
        bordered={false}
        scrollY
        columns={columnsGroupings}
        dataSource={groupings}
        pagination={false}
        isRowSelectable
        onRow={(record) => {
          return {
            onClick: () => {
              handleSelectGrouping(groupings.find((el) => el.id === record.id));
            },
          };
        }}
      />
    </div>
  );
}
const Icon = styled.i`
  font-size: 11px;
`;
export default GroupingsListIndex;
