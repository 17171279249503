import React, { useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Checkbox } from "antd";
import styled from "styled-components";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { getData, postData } from "../../request/instance";
import Edit from "../../react-ui/Icons/Edit";
import { getUrlParams } from "../../utils";
import Delete from "../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../confirmModal";
import Input from "../../react-ui/Input";
import Select from "../../react-ui/Select";
import InputNumber from "../../react-ui/InputNumber";

function ZoneTemplateArray({
  token,
  entityId,
  setSelectedZoneTemplate,
  setZoneEdit,
  updateZoneTemplate,
  difficulties,
  majorations,
  carrycot_professions,
}) {
  const [zoneTemplateData, setZoneTemplateData] = useState([]);
  const [arrayState, setArrayState] = useState({});
  const { data: zone_templates, refetch: zone_temmplate_refetch } = useQuery(
    "ZoneTemplates",
    () => getData(token, `/zone/${getUrlParams()}?from=admin`),
    {
      onSuccess: (payload) => {
        setZoneTemplateData(payload);
      },
    }
  );

  const { mutate: deleteOuvrageMaster } = useMutation(
    (todo) => postData(token, `/zone/delete?from=admin`, todo),
    {
      onSuccess: () => {
        zone_temmplate_refetch();
      },
    }
  );

  const handleDeleteZoneTemplate = (idZoneTemplate, event) => {
    event.stopPropagation();
    deleteOuvrageMaster(JSON.stringify({ id: idZoneTemplate }));
    setSelectedZoneTemplate(null);
  };

  const handleUpdateZone = (data) => {
    updateZoneTemplate({
      from: "admin",
      ...data,
    });
  };

  const handleChange = (e, value, key) => {
    const newValue = e;
    setArrayState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[value?.id],
        [value?.id]: newValue,
      },
    }));
  };

  const zone_template_columns = useMemo(
    () => [
      {
        title: "Intitulé",
        dataIndex: "entitled",
        key: "entitled",
        width: "30%",
        render: (entitled, value) => (
          <Input
            value={arrayState?.entitled?.[value?.id] || entitled}
            onChange={(e) => handleChange(e.target.value, value, "entitled")}
            onBlur={(e) =>
              handleUpdateZone({
                id: value.id,
                entitled: e.target.value,
              })
            }
          />
        ),
      },
      {
        title: "Difficulté",
        dataIndex: "difficulty_profession",
        key: "difficulty_profession",
        width: "10%",
        render: (difficulty_profession, value) => (
          <Select
            options={difficulties}
            value={
              arrayState?.difficulty?.[value?.id] ||
              value.difficulty_profession_id
            }
            onSelect={(e) => {
              handleChange(e, value, "difficulty");
              handleUpdateZone({
                id: value.id,
                difficulty_profession_id: e,
              });
            }}
          />
        ),
      },
      {
        title: "Majoration",
        dataIndex: "majoration_profession",
        key: "majoration_profession",
        width: "10%",
        render: (majoration_profession, value) => (
          <Select
            options={majorations}
            value={
              arrayState?.majoration?.[value?.id] ||
              value?.majoration_profession_id
            }
            onSelect={(e) => {
              handleChange(e, value, "majoration");
              handleUpdateZone({
                id: value.id,
                majoration_profession_id: e,
              });
            }}
          />
        ),
      },
      {
        title: "Nacelle",
        dataIndex: "carrycot_profession",
        key: "carrycot_profession",
        width: "10%",
        render: (carrycot_profession, value) => (
          <Select
            options={carrycot_professions}
            value={
              arrayState?.carrycot?.[value?.id] || value?.carrycot_profession_id
            }
            onSelect={(e) => {
              handleChange(e, value, "carrycot");
              handleUpdateZone({
                id: value.id,
                carrycot_profession_id: e,
              });
            }}
          />
        ),
      },
      {
        title: "Maintenance",
        dataIndex: "maintenance",
        key: "maintenance",
        align: "center",
        width: "3%",
        render: (maintenance) => (
          <div>
            <Checkbox defaultChecked={maintenance} disabled />
          </div>
        ),
      },
      {
        title: "Option",
        dataIndex: "option",
        key: "option",
        align: "center",
        width: "3%",
        render: (option, value) => (
          <div>
            <Checkbox
              checked={arrayState?.option?.[value?.id] || option}
              onChange={(e) => {
                handleChange(e.target.checked, value, "option");
                handleUpdateZone({
                  id: value.id,
                  option: e.target.checked,
                });
              }}
            />
          </div>
        ),
      },
      {
        title: "Défaut",
        dataIndex: "is_default",
        key: "is_default",
        align: "center",
        width: "3%",
        render: (is_default, value) => (
          <div>
            <Checkbox
              checked={arrayState?.is_default?.[value?.id] || is_default}
              onChange={(e) => {
                handleChange(e.target.checked, value, "is_default");
                handleUpdateZone({
                  id: value.id,
                  is_default: e.target.checked,
                });
              }}
            />
          </div>
        ),
      },
      {
        title: "Position",
        dataIndex: "position",
        key: "position",
        width: "3%",
        render: (position, value) => (
          <InputNumber
            value={arrayState?.position?.[value?.id] || position}
            onChange={(e) => handleChange(e.target.value, value, "position")}
            onBlur={(e) =>
              handleUpdateZone({
                id: value.id,
                position: e.target.value,
              })
            }
          />
        ),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "3%",
        render: (value, record) => (
          <ContainerActionButton>
            <Edit
              onClick={() => {
                setZoneEdit(true);
                setSelectedZoneTemplate(record);
              }}
            />
            <Delete
              onClick={(event) => {
                event.stopPropagation();
                ShowDeleteConfirm(record.name, record.id, (id) =>
                  handleDeleteZoneTemplate(id, event)
                );
              }}
            />
          </ContainerActionButton>
        ),
      },
    ],
    [
      carrycot_professions,
      difficulties,
      handleDeleteZoneTemplate,
      handleUpdateZone,
      majorations,
      setSelectedZoneTemplate,
      setZoneEdit,
      zoneTemplateData,
    ]
  );
  return (
    <div>
      <TableContainer
        columns={zone_template_columns}
        data={zoneTemplateData}
        isRowSelectable
      />
    </div>
  );
}

const ContainerActionButton = styled.div`
  display: flex;
  gap: 10px;
`;

export default ZoneTemplateArray;
