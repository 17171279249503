import React from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { useStore } from "../../store";
import { Loading } from "../../loading";
import { currency_euros_to_cents, getUrlParams } from "../../utils";
import { postData } from "../../request/instance";
import DistributorsForm from "./DistributorsForm";
import TitleContainer from "../../react-ui/TitleContainer";
import { handlePictureKey } from "../../../utils/createFormData";
import Button from "../../react-ui/Button";
import AddButton from "../../react-ui/AddButton";

const selector = (state) => ({
  selectedDistributor: state.selectedDistributor,
  formToken: state.formToken,
  editDistributor: state.editDistributor,
});
function DistributorDetails({
  distributorDetailsStatus,
  setDistributorDetailsStatus,
  distributors,
}) {
  const { selectedDistributor, formToken, editDistributor } =
    useStore(selector);
  const queryClient = useQueryClient();
  const { mutate: updateDistributor, status } = useMutation(
    (todo) => postData(formToken, "/distributor/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Distributors");
      },
    }
  );

  const { mutate: createDistributor, isLoading } = useMutation(
    (todo) => postData(formToken, "/distributor/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Distributors");
      },
      onSuccess: (payload) => {
        editDistributor(payload);
        setDistributorDetailsStatus("update");
      },
      onError: () => setDistributorDetailsStatus("empty"),
    }
  );

  const handleRegisterDistributorProfession = (e) => {
    const formatableKeys = ["picture", "hourly_rate_cents"];
    const formData = new FormData();
    Object.keys(e).forEach(
      (key) =>
        e[key] !== undefined &&
        e[key] !== null &&
        !formatableKeys.includes(key) &&
        formData.append(`distributor[${key}]`, e[key])
    );
    if (e.hourly_rate_cents) {
      formData.append(
        "distributor[hourly_rate_cents]",
        currency_euros_to_cents(e.hourly_rate_cents)
      );
    }
    if (e.picture) {
      handlePictureKey({
        formData,
        picture: e.picture,
        keyName: "distributor",
      });
    }
    formData.append("distributor[entity_work_id]", getUrlParams());
    if (distributorDetailsStatus === "update") {
      formData.append("id", selectedDistributor.id);
      updateDistributor(formData);
    }
    if (distributorDetailsStatus === "create") {
      createDistributor(formData);
    }
  };

  const onSubmit = (e) => {
    handleRegisterDistributorProfession(e);
  };

  const isShowing =
    distributorDetailsStatus === "update" &&
    selectedDistributor.entity_work_id !== parseInt(getUrlParams(), 10);

  const isUpdating = distributorDetailsStatus === "update" && !isShowing;

  const getName = () => {
    if (distributorDetailsStatus !== "update") return "";
    const curItem = distributors.find((el) => el.id === selectedDistributor.id);
    if (!curItem?.name) return "";
    return ` : ${curItem.name}`;
  };

  return (
    <Container>
      <TitleContainer label={`Détails du distributeur${getName()}`}>
        {distributorDetailsStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={() => setDistributorDetailsStatus("empty")}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="manu-distrib-form"
              loading={isLoading}
            />
          </>
        )}
        <Loading status={status} />
      </TitleContainer>
      {distributorDetailsStatus === "empty" && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez un distributeur pour afficher les détails
          </p>
        </div>
      )}
      {distributorDetailsStatus !== "empty" && (
        <Wrapper>
          <DistributorsForm
            updateOnChange={isUpdating}
            initialDistributor={
              distributorDetailsStatus === "update"
                ? selectedDistributor
                : undefined
            }
            onSubmit={onSubmit}
            isShowing={isShowing}
          />
        </Wrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default DistributorDetails;
