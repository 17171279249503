import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useIsMutating } from "react-query";
import useUpdateTodo from "../../../hookQuery/useUpdateTodo";
import { useStore } from "../../../store";
import useTodo from "../../../hookQuery/useTodo";
import { currency_cents_to_euros } from "../../../utils";
import TableContainer from "../../CostingMovementsCarrycots/TableContainer";
import Input from "../../../react-ui/Input";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { stringToFloat } from "../../../../utils/stringToFloat";
import { eurosToCents } from "../../../../utils/currencyConverter";
import { getMarginCol } from "../../../../constants/marginCol";
import { coeffToMargin } from "../../../../utils/marginConverter";
import { simple_default_configuration } from "../../../../simple_default";

const selector = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

function ZoneTable({
  zone_id,
  responseType,
  opportunity_work,
  configuration,
  vocabulary,
}) {
  const { id_opportunity } = useParams();
  const isMutating = useIsMutating();

  const { formToken, isUsingMargin, calculMethod } = useStore(selector);

  const { mutate: updateMaterialOpportunity } = useUpdateTodo(
    formToken,
    "/material_opportunity/update_all_costing",
    "MaterialsOpportunity",
    ["OpportunityCotationLc", "Zones"]
  );

  const { mutate: updateOuvrageOpportunity } = useUpdateTodo(
    formToken,
    "/ouvrage_opportunity/update_all_costing",
    "MaterialsOpportunity",
    ["OpportunityCotationLc", "Zones", "OuvragesOpportunity"]
  );

  const { mutate: updatePrestationOpportunity } = useUpdateTodo(
    formToken,
    "/prestation_opportunity/update_all_costing",
    "PrestationsOpportunity",
    ["OpportunityCotationLc", "Zones"]
  );

  const { mutate: movementUpdate } = useUpdateTodo(
    formToken,
    `/movement_opportunity/update_all_costing`,
    "MovementsOpportunity",
    [
      "MovementProfilsOpportunity",
      "VehicleMovementOprt",
      "OpportunityCotationLc",
      "Zones",
    ]
  );

  const { mutate: carrycotUpdate } = useUpdateTodo(
    formToken,
    `/carrycot_opportunity/update_all_costing`,
    "CarrycotOpportunities",
    ["OpportunityCotationLc", "Zones"]
  );

  const { data: zones, isLoading } = useTodo(
    "formToken",
    `/zone/${id_opportunity}`,
    "Zones"
  );

  const [margin_rate, setMargin_rate] = useState(null);
  const [selling_price, setSelling_price] = useState(null);

  const zoneData = zones.find((zone) => zone.id === zone_id);
  const data = [
    {
      desable: true,
      key: "1",
      zone_id,
      blank: "Total",
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(zoneData?.total_cost_price_cents),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: zoneData?.total_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(zoneData?.total_selling_price_cents),
        nbDecimal: 2,
        space: true,
      }),
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "2",
      blank: vocabulary.products,
      zone_id,
      hide: zoneData?.material_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(zoneData?.material_cost_price_cents),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: zoneData?.material_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(zoneData?.material_selling_price_cents),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: updateMaterialOpportunity,
      selling_price_method: updateMaterialOpportunity,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "36",
      blank: "Ouvrages",
      hide: zoneData?.ouvrage_cost_price_cents === 0,
      zone_id,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(zoneData?.ouvrage_cost_price_cents),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: zoneData?.ouvrage_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(zoneData?.ouvrage_selling_price_cents),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: updateOuvrageOpportunity,
      selling_price_method: updateOuvrageOpportunity,
    },
    {
      desable: true,
      key: "3",
      blank: "Coûts internes",
      hide: zoneData?.internal_cost_price_cents === 0,
      zone_id,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(zoneData?.internal_cost_price_cents),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: zoneData?.internal_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(zoneData?.internal_selling_price_cents),
        nbDecimal: 2,
        space: true,
      }),
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "4",
      blank: "----- Prestations",
      className: "row_children_colored",
      zone_id,
      hide: zoneData?.prestation_internal_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.prestation_internal_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      subcontracting: false,
      is_package: false,
      margin: zoneData?.prestation_internal_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.prestation_internal_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: updatePrestationOpportunity,
      selling_price_method: updatePrestationOpportunity,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "Prestations forfaits int",
      blank: "----- Prestations forfaits",
      className: "row_children_colored",
      zone_id,
      hide: zoneData?.presta_pack_int_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.presta_pack_int_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      subcontracting: false,
      is_package: true,
      margin: zoneData?.presta_pack_int_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.presta_pack_int_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: updatePrestationOpportunity,
      selling_price_method: updatePrestationOpportunity,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "5",
      blank: "----- Déplacements",
      className: "row_children_colored",
      zone_id,
      subcontracting: false,
      hide: zoneData?.movement_internal_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.movement_internal_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: zoneData?.movement_internal_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.movement_internal_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: movementUpdate,
      selling_price_method: movementUpdate,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "6",
      blank: "----- Nacelles",
      className: "row_children_colored",
      zone_id,
      subcontracting: false,
      hide: zoneData?.carrycot_internal_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.carrycot_internal_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: zoneData?.carrycot_internal_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.carrycot_internal_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: carrycotUpdate,
      selling_price_method: carrycotUpdate,
    },
    {
      desable: true,
      key: "7",
      blank: "Coûts sous-traitance",
      hide:
        !configuration.general.subcontracting ||
        zoneData?.subcontracting_cost_price_cents === 0,
      zone_id,

      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(zoneData?.subcontracting_cost_price_cents),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: zoneData?.subcontracting_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.subcontracting_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "8",
      blank: " ----- Prestations",
      className: "row_children_colored",
      zone_id,
      hide: zoneData?.prestation_subcontracting_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.prestation_subcontracting_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      subcontracting: true,
      is_package: false,
      margin: zoneData?.prestation_subcontracting_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.prestation_subcontracting_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: updatePrestationOpportunity,
      selling_price_method: updatePrestationOpportunity,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "Prestations forfaits sub",
      blank: " ----- Prestations forfaits",
      className: "row_children_colored",
      zone_id,
      hide: zoneData?.presta_pack_sub_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.presta_pack_sub_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      subcontracting: true,
      is_package: true,
      margin: zoneData?.presta_pack_sub_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.presta_pack_sub_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: updatePrestationOpportunity,
      selling_price_method: updatePrestationOpportunity,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "9",
      blank: "----- Déplacements",
      className: "row_children_colored",
      zone_id,
      subcontracting: true,
      hide: zoneData?.movement_subcontracting_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.movement_subcontracting_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: zoneData?.movement_subcontracting_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.movement_subcontracting_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: movementUpdate,
      selling_price_method: movementUpdate,
    },
    {
      desable: configuration.costing.page_3.disable_selling_price,
      key: "10",
      blank: "----- Nacelles",
      className: "row_children_colored",
      zone_id,
      subcontracting: true,
      hide: zoneData?.carrycot_subcontracting_cost_price_cents === 0,
      cost_price: `${formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.carrycot_subcontracting_cost_price_cents
        ),
        nbDecimal: 2,
        space: true,
      })} €`,
      margin: zoneData?.carrycot_subcontracting_margin_rate,
      selling_price: formatNumberString({
        str: currency_cents_to_euros(
          zoneData?.carrycot_subcontracting_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_method: carrycotUpdate,
      selling_price_method: carrycotUpdate,
    },
  ];
  const zoneArrays = [
    {
      title: "",
      dataIndex: "blank",
    },
    {
      title: "Coût de revient",
      hidden:
        responseType === "quotation" ||
        !configuration.costing.page_3.show_cost_price,
      dataIndex: "cost_price",
      width: "100px",
      align: "right",
    },
    getMarginCol({
      configuration,
      isUsingMargin,
      calculMethod,
      width: "80px",
      hidden: responseType === "quotation",
      hasSorter: false,
      render: (value, globalValue) => (
        <Input
          fontSize="10px"
          textAlign="right"
          disabled={globalValue.desable}
          size="small"
          isMarginRate
          id={`${globalValue.key}${globalValue.zone_id}`}
          value={formatNumberString({
            str:
              margin_rate?.[`${globalValue.key}${globalValue.zone_id}`] != null
                ? margin_rate?.[`${globalValue.key}${globalValue.zone_id}`]
                : value,
            isCoeff: !isUsingMargin,
          })}
          onBlur={(e) => {
            if (margin_rate) {
              globalValue.margin_method(
                JSON.stringify({
                  opportunity_id: id_opportunity,
                  margin_rate: coeffToMargin({
                    calculMethod,
                    isUsingMargin,
                    coeff: stringToFloat(e.target.value),
                  }),
                  zone_id: globalValue.zone_id,
                  subcontracting: globalValue.subcontracting,
                  ...(typeof globalValue.is_package !== "undefined" && {
                    is_package: globalValue.is_package,
                  }),
                })
              );
            }
            setMargin_rate(null);
          }}
          onChange={(e) =>
            setMargin_rate((v) => ({
              ...v,
              [e.target.id]: e.target.value,
            }))
          }
        />
      ),
    }),
    {
      title: "Prix de vente HT",
      dataIndex: "selling_price",
      width: "100px",
      align: "right",
      render: (value, globalValue) =>
        responseType === "quotation" ? (
          <div>{value} €</div>
        ) : (
          <Input
            fontSize="10px"
            textAlign="right"
            disabled={globalValue.desable}
            size="small"
            suffix="€"
            id={`${globalValue.key}${globalValue.zone_id}`}
            value={
              selling_price?.[`${globalValue.key}${globalValue.zone_id}`] !=
              null
                ? selling_price?.[`${globalValue.key}${globalValue.zone_id}`]
                : value
            }
            onBlur={(e) => {
              if (selling_price) {
                globalValue.selling_price_method(
                  JSON.stringify({
                    opportunity_id: id_opportunity,
                    selling_price_cents: eurosToCents(e.target.value),
                    zone_id: globalValue.zone_id,
                    subcontracting: globalValue.subcontracting,
                    ...(typeof globalValue.is_package !== "undefined" && {
                      is_package: globalValue.is_package,
                    }),
                  })
                );
              }
              setSelling_price(null);
            }}
            onChange={(e) =>
              setSelling_price((v) => ({
                ...v,
                [e.target.id]: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              }))
            }
            onFocus={(e) =>
              setSelling_price((v) => ({
                ...v,
                [e.target.id]: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              }))
            }
          />
        ),
    },
  ];
  return (
    <TableContainer
      columns={zoneArrays}
      data={data}
      pagination={false}
      loading={!!isMutating || isLoading}
    />
  );
}

export default ZoneTable;
