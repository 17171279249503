import { encodeUrlParams } from "./encodeUrlParams";

export const fetchTemplatesUrl = ({ offer_id, opportunity_ids }) => {
  let url = "/sa_templates?";
  const params = {
    ...(offer_id && { offer_id }),
  };

  url += encodeUrlParams(params);
  if (opportunity_ids?.length > 0) {
    let query = "";
    opportunity_ids?.forEach((el) => {
      query += `&opportunity_ids[]=${el}`;
    });
    url += query;
  }
  return url;
};
