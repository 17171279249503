import React from "react";
import { Switch, Input } from "antd";
import styled from "styled-components";
import { bool, func } from "prop-types";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const { Search } = Input;

function ListHeader({
  showAll,
  setShowAll,
  showMaterial,
  setShowMaterial,
  setSearchedWord,
  hasOption,
}) {
  return (
    <Container>
      <StyledSearch
        allowClear
        placeholder="Rechercher par matériel, prestation, profil ou nacelle..."
        onChange={(e) => setSearchedWord(e.target.value.toLowerCase())}
      />
      <Wrapper>
        <SwitchContainer>
          <SwitchText>Par zone</SwitchText>
          <StyledSwitch checked={showAll} onChange={(e) => setShowAll(e)} />
          <SwitchText>{hasOption ? "Total (hors option)" : "Total"}</SwitchText>
        </SwitchContainer>
        <SwitchContainer>
          <SwitchText>Afficher matériels :</SwitchText>
          <Switch
            checked={showMaterial}
            onChange={(e) => setShowMaterial(e)}
            unCheckedChildren={<EyeInvisibleOutlined />}
            checkedChildren={<EyeOutlined />}
          />
        </SwitchContainer>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: spa;
  align-items: flex-start;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledSearch = styled(Search)`
  margin-bottom: 20px;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-right: 10px;
`;

const SwitchText = styled.p`
  margin: 0 10px 0 0;
  font-size: 14px !important;
`;

const StyledSwitch = styled(Switch)`
  margin-right: 10px;
`;

ListHeader.propTypes = {
  showAll: bool.isRequired,
  setShowAll: func.isRequired,
  showMaterial: bool.isRequired,
  setShowMaterial: func.isRequired,
  setSearchedWord: func.isRequired,
  hasOption: bool.isRequired,
};

export default ListHeader;
