import React from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import GroupingForm from "./GroupingForm";
import TitleContainer from "../../../react-ui/TitleContainer";
import Button from "../../../react-ui/Button";
import AddButton from "../../../react-ui/AddButton";
import { Loading } from "../../../loading";
import DetailTabs from "../../DetailTabs";
import { postData } from "../../../request/instance";
import GroupingTabs from "./GroupingTabs";

function GroupingDetailsIndex({
  detailsStatus,
  selectedGrouping,
  setDetailsStatus,
  token,
  configuration,
  company_work_id,
  vocabulary,
  setSelectedGrouping,
}) {
  const queryClient = useQueryClient();

  const { mutate: updateGrouping, status } = useMutation(
    (todo) => postData(token, "/grouping/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Groupings");
      },
    }
  );

  const { mutate: createGrouping, isLoading } = useMutation(
    (obj) => postData(token, "/grouping/create", obj),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Groupings");
      },
      onSuccess: (payload) => {
        // editFamily(payload);
        setSelectedGrouping(payload);
        setDetailsStatus("update");
      },
      onError: () => setDetailsStatus("empty"),
    }
  );

  const isUpdating = detailsStatus === "update";

  const handleRegisterGrouping = (e) => {
    const formatableKeys = ["picture"];
    const formData = new FormData();
    Object.keys(e).forEach(
      (key) =>
        e[key] !== undefined &&
        e[key] !== null &&
        !formatableKeys.includes(key) &&
        formData.append(`grouping[${key}]`, e[key])
    );
    formData.append("grouping[company_work_id]", company_work_id);
    if (detailsStatus === "update") {
      formData.append("id", selectedGrouping.id);
      updateGrouping(formData);
    }
    if (detailsStatus === "create") {
      createGrouping(formData);
    }
  };

  const onSubmit = (e) => {
    handleRegisterGrouping(e);
  };

  const getName = () => {
    if (detailsStatus !== "update") return "";
    const curItem = selectedGrouping;
    if (!curItem?.name) return "";
    return ` : ${curItem.name}`;
  };
  return (
    <Container>
      <TitleContainer
        label={`Détails du ${vocabulary.grouping_lowercase} ${getName()}`}
      >
        {detailsStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={() => setDetailsStatus("empty")}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="grouping-form"
              loading={isLoading}
            />
          </>
        )}
        <Loading status={status} />
      </TitleContainer>
      {detailsStatus === "empty" && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez un {vocabulary.grouping_lowercase} pour
            afficher les détails
          </p>
        </div>
      )}
      {detailsStatus !== "empty" && (
        <GroupingTabs
          detailsStatus={detailsStatus}
          configuration={configuration}
          vocabulary={vocabulary}
          initialGrouping={
            detailsStatus === "update" ? selectedGrouping : undefined
          }
          onSubmit={onSubmit}
          updateOnChange={isUpdating}
          selectedGrouping={selectedGrouping}
          updateGrouping={updateGrouping}
        >
          <GroupingForm
            updateOnChange={isUpdating}
            initialGrouping={
              detailsStatus === "update" ? selectedGrouping : undefined
            }
            onSubmit={onSubmit}
            vocabulary={vocabulary}
          />
        </GroupingTabs>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default GroupingDetailsIndex;
