import React, { useMemo } from "react";
import styled from "styled-components";
import { formatNumberString } from "../../../../utils/formatNumberString";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";

const dayTypes = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
  "work_free_day",
];

const WHCols = [
  {
    title: "Lun",
    children: [
      {
        title: () => <i className="fa-duotone fa-sun" />,
        dataIndex: "mondayDay",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
      {
        title: () => <i className="fa-duotone fa-moon" />,
        dataIndex: "mondayNight",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
    ],
  },
  {
    title: "Mar",
    children: [
      {
        title: () => <i className="fa-duotone fa-sun" />,
        dataIndex: "tuesdayDay",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
      {
        title: () => <i className="fa-duotone fa-moon" />,
        dataIndex: "tuesdayNight",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
    ],
  },
  {
    title: "Mer",
    children: [
      {
        title: () => <i className="fa-duotone fa-sun" />,
        dataIndex: "wednesdayDay",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
      {
        title: () => <i className="fa-duotone fa-moon" />,
        dataIndex: "wednesdayNight",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
    ],
  },
  {
    title: "Jeu",
    children: [
      {
        title: () => <i className="fa-duotone fa-sun" />,
        dataIndex: "thursdayDay",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
      {
        title: () => <i className="fa-duotone fa-moon" />,
        dataIndex: "thursdayNight",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
    ],
  },
  {
    title: "Ven",
    children: [
      {
        title: () => <i className="fa-duotone fa-sun" />,
        dataIndex: "fridayDay",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
      {
        title: () => <i className="fa-duotone fa-moon" />,
        dataIndex: "fridayNight",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
    ],
  },
  {
    title: "Sam",
    children: [
      {
        title: () => <i className="fa-duotone fa-sun" />,
        dataIndex: "saturdayDay",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
      {
        title: () => <i className="fa-duotone fa-moon" />,
        dataIndex: "saturdayNight",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
    ],
  },
  {
    title: "Dim",
    children: [
      {
        title: () => <i className="fa-duotone fa-sun" />,
        dataIndex: "sundayDay",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
      {
        title: () => <i className="fa-duotone fa-moon" />,
        dataIndex: "sundayNight",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
    ],
  },
  {
    title: "J.F",
    children: [
      {
        title: () => <i className="fa-duotone fa-sun" />,
        dataIndex: "work_free_dayDay",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
      {
        title: () => <i className="fa-duotone fa-moon" />,
        dataIndex: "work_free_dayNight",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
      },
    ],
  },
];

function WeeklyHours({ planning }) {
  const dataSource = useMemo(() => {
    const newDataSource = {
      key: "1",
    };
    dayTypes.forEach((el) => {
      newDataSource[`${el}Day`] = planning?.nb_hours_per_day[el].day;
      newDataSource[`${el}Night`] = planning?.nb_hours_per_day[el].night;
    });
    return [newDataSource];
  }, [planning]);

  return (
    <StyledTable
      bordered
      columns={WHCols}
      dataSource={dataSource}
      pagination={false}
    />
  );
}

const StyledTable = styled(TableContainer)`
  margin: 10px 0 !important;
  .fa-sun {
    color: orange;
    font-size: 12px;
  }

  .fa-moon {
    color: lightsteelblue;
    font-size: 12px;
  }
`;

export default WeeklyHours;
