import React, { useEffect, useRef, useState } from "react";
import { Modal, Row, Form, Checkbox } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import AddButton from "../../react-ui/AddButton";
import Button from "../../react-ui/Button";

import Input from "../../react-ui/Input";
import FormCol from "../../Form/FormCol";
import Select from "../../react-ui/Select";
import Separator from "../../react-ui/Separator";
import { getData, postData, putData } from "../../request/instance";
import ButtonCol from "../Users/Form/ButtonCol";
import { getUrlParams } from "../../utils";
import useTodo from "../../hookQuery/useTodo";
import { fetchDifficultiesUrl } from "../../../utils/fetchDifficultiesUrl";
import { fetchMajorationsUrl } from "../../../utils/fetchMajorationsUrl";
import { createZoneTemplateForm } from "../../../utils/createFormData";
import { fetchCarrycotsUrl } from "../../../utils/fetchCarrycotsUrl";

function ZoneTemplateModal({
  isModalOpen,
  handleCancel,
  token,
  selectedZoneTemplate,
  statusForm,
  setStatusForm,
  difficulties,
  carrycot_professions,
  majorations,
}) {
  const entityWorkId = getUrlParams();
  const [form] = Form.useForm();
  const [isDefault, setIsDefault] = useState(true);
  const [maintenance, setMaintenance] = useState(false);
  const [option, setOption] = useState(false);
  const queryClient = useQueryClient();
  const formRef = useRef(null);

  const { data: default_value, refetch: default_value_refetch } = useQuery(
    "DefaultValue",
    () => getData(token, `/default_value/${entityWorkId}`)
  );

  const { mutate: createZoneTemplate } = useMutation(
    (todo) => postData(token, "/zone/create", todo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("ZoneTemplates");
        handleCancel();
        form.resetFields();
      },
    }
  );

  const { mutate: updateZoneTemplate } = useMutation(
    (todo) => postData(token, "/zone/update", todo),
    {
      onSuccess: () => {
        queryClient.refetchQueries("ZoneTemplates");
        handleCancel();
      },
    }
  );

  useEffect(() => {
    const initialValues = {
      ...selectedZoneTemplate,
    };
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, selectedZoneTemplate]);

  const handleSubmit = (values) => {
    const formData = createZoneTemplateForm({ values });
    formData.append("zone[zonable_id]", entityWorkId);
    formData.append("zone[is_default]", isDefault);
    formData.append("zone[maintenance]", maintenance);
    formData.append("zone[option]", option);
    formData.append("from", "admin");
    // formData.append
    if (statusForm === "create") {
      createZoneTemplate(formData);
    } else {
      formData.append("id", selectedZoneTemplate.id);
      updateZoneTemplate(formData);
    }
  };
  return (
    <Modal
      title={statusForm === "create" ? "Créer une zone" : "Modifier une zone"}
      open={isModalOpen}
      onCancel={handleCancel}
      width={800}
      footer={[
        <ContainerButton>
          <AddButton
            label={statusForm === "create" ? "Créer" : "Modifier"}
            onClick={() => formRef.current.submit()}
          />
          <Button label="Annuler" onClick={handleCancel} />
        </ContainerButton>,
      ]}
    >
      <Form ref={formRef} form={form} colon={false} onFinish={handleSubmit}>
        <Row>
          <FormCol
            label="Intitulé"
            name="entitled"
            labelOnTop
            rules={[{ required: true }]}
            width="38%"
          >
            <Input />
          </FormCol>
          <FormCol label="Maintenance" name="maintenance" width="17%">
            <Checkbox
              defaultChecked={selectedZoneTemplate?.maintenance || maintenance}
              onChange={() => setMaintenance(!maintenance)}
              disabled={statusForm !== "create"}
            />
          </FormCol>
          <FormCol label="Option" name="option" width="13%">
            <Checkbox
              defaultChecked={selectedZoneTemplate?.option || option}
              onChange={() => setOption(!option)}
              disabled={statusForm !== "create"}
            />
          </FormCol>
          <FormCol label="Défaut" width="12%" name="is_default">
            <Checkbox
              defaultChecked={selectedZoneTemplate?.is_default || isDefault}
              onChange={() => setIsDefault(!isDefault)}
            />
          </FormCol>
          {isDefault && (
            <FormCol
              label="Position"
              name="position"
              labelOnTop
              rules={[{ required: true }]}
              width="20%"
            >
              <Input />
            </FormCol>
          )}
        </Row>
        <Separator isHorizontal size={1} />
        <Row>
          <FormCol
            label="Difficulté"
            name="difficulty_profession_id"
            labelOnTop
            rules={[{ required: true }]}
            width="33%"
          >
            <Select options={difficulties} />
          </FormCol>
          <FormCol
            label="Majoration"
            rules={[{ required: true }]}
            labelOnTop
            width="34%"
            name="majoration_profession_id"
          >
            <Select options={majorations} />
          </FormCol>
          <FormCol
            label="Nacelle"
            name="carrycot_profession_id"
            labelOnTop
            width="33%"
          >
            <Select options={carrycot_professions} />
          </FormCol>
        </Row>
      </Form>
    </Modal>
  );
}

const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

export default ZoneTemplateModal;
