import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import OuvrageUpdate from "../../../../Admin-cf/Ouvrages/OuvrageUpdate";
import Separator from "../../../../react-ui/Separator";
import TitleContainer from "../../../../react-ui/TitleContainer";
import OuvrageDetails from "../../../../Admin-cf/Ouvrages/OuvrageDetails";
import Button from "../../../../react-ui/Button";
import AddButton from "../../../../react-ui/AddButton";
import { Loading } from "../../../../loading";
import { getData } from "../../../../request/instance";

function ModalOuvrage({
  categories,
  formToken,
  configuration,
  vocabulary,
  unities,
  opportunity_id,
  ouvrageId = null,
  opportunityType,
}) {
  const [ouvrageDetailsStatus, setOuvrageDetailsStatus] = useState("create");
  const [statusOuvrage, setStatusOuvrage] = useState("");
  const [ouvrageSelected, setOuvrageSelected] = useState([]);
  const [ouvrageChildId, setOuvrageChildId] = useState(null);
  const [ouvrageDetails, setOuvrageDetails] = useState([]);
  const [ouvrageDynamicLabel, setOuvrageDynamicLabel] =
    useState("Créer l'ouvrage");

  const [currentOuvrageId, setCurrentOuvrageId] = useState(null);

  useEffect(() => {
    setCurrentOuvrageId(ouvrageId || ouvrageSelected[0]?.id);
    if (ouvrageId) setOuvrageDetailsStatus("modify");
  }, [ouvrageId, ouvrageSelected]);

  const ouvrageProfessionQueryKey = useMemo(
    () => ["OuvrageProfession", currentOuvrageId],
    [currentOuvrageId]
  );

  const { data: ouvrage_profession, refetch: ouvrage_refetch } = useQuery(
    ouvrageProfessionQueryKey,
    () => getData(formToken, `/ouvrage_profession/${currentOuvrageId}`),
    {
      onSuccess: (payload) => {
        setOuvrageDetails([payload]);
      },
      enabled: !!currentOuvrageId,
    }
  );

  useEffect(() => {
    if (ouvrageId === null) {
      setCurrentOuvrageId(null);
      setOuvrageDetails(null);
    }
  }, [ouvrageId]);

  useEffect(() => {
    if (currentOuvrageId) {
      ouvrage_refetch();
    }
  }, [ouvrage_refetch, currentOuvrageId]);

  return (
    <Container>
      <div style={{ width: "50%" }}>
        <TitleContainer label="Détails d'ouvrage" />
        <OuvrageDetails
          ouvrageSelected={[{id: currentOuvrageId}]}
          ouvrageDetails={ouvrageDetails}
          setOuvrageDetails={setOuvrageDetails}
          setOuvrageSelected={setOuvrageSelected}
          token={formToken}
          loading={false}
          ouvrageDetailsStatus={ouvrageDetailsStatus}
          setOuvrageDetailsStatus={setOuvrageDetailsStatus}
          configuration={configuration}
          ouvrageChildId={ouvrageChildId}
          setOuvrageChildId={setOuvrageChildId}
          setOuvrageDynamicLabel={setOuvrageDynamicLabel}
        />
      </div>
      <Separator />
      <div style={{ width: "50%" }}>
        <TitleContainer label={ouvrageDynamicLabel}>
          {(ouvrageDetailsStatus === "create" ||
            ouvrageDetailsStatus === "knot") && (
            <>
              <StyledButton
                label="Annuler"
                onClick={() => setOuvrageDetailsStatus("empty")}
                fontSize="14px"
                btnType="cancel"
              />
              <AddButton
                label="Créer"
                type="submit"
                value="submit"
                fontSize="14px"
                form="ouvrage-form"
              />
            </>
          )}
          <Loading status={statusOuvrage} />
        </TitleContainer>
        <OuvrageUpdate
          ouvrageSelected={[{ id: currentOuvrageId }]}
          token={formToken}
          ouvrageDetailsStatus={ouvrageDetailsStatus}
          setOuvrageDetailsStatus={setOuvrageDetailsStatus}
          configuration={configuration}
          ouvrageChildId={ouvrageChildId || currentOuvrageId}
          setOuvrageChildId={setOuvrageChildId}
          vocabulary={vocabulary}
          unities={unities}
          faIsValidate={false}
          from="referencing"
          setOuvrageSelected={setOuvrageSelected}
          faId={null}
          ouvrageDetails={ouvrageDetails}
          setStatusOuvrage={setStatusOuvrage}
          categories={categories}
          opportunityId={opportunity_id}
          setOuvrageDetails={setOuvrageDetails}
          opportunityType={opportunityType}
        />
      </div>
      <div style={{ width: 25 }} />
    </Container>
  );
}

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
  width: 100%;
`;

export default ModalOuvrage;
