import { Checkbox, Form } from "antd";
import React, { useEffect, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import {
  constructMinsOrH,
  getApplicatedDays,
} from "../../../../../utils/planning";
import { validateMessages } from "../../../../constant";
import FormCol from "../../../../Form/FormCol";
import FormRow from "../../../../Form/FormRow";
import ReactDatePicker from "../../../../Form/ReactDatePicker";
import Input from "../../../../react-ui/Input";
import { postData } from "../../../../request/instance";
import { useStore } from "../../../../store";

const selector = (state) => ({
  formToken: state.formToken,
});

function SPForm({
  quotationStartDate,
  quotationEndDate,
  monthlyCalendar,
  workPositionId,
  spStatus,
  setSpStatus,
  setSelectedSp,
  selectedSp,
  updateSp,
  specialPlannings,
  opportunityId,
  setWorkPositionsLocal,
  configApplication,
  isLockedByUser,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { mutate: createSp } = useMutation(
    (todo) => postData(formToken, "/special_planning/create", todo),
    {
      onSettled: () =>
        queryClient.invalidateQueries(["SpecialPlannings", { workPositionId }]),
      onSuccess: ({ has_calendar_changed }) => {
        queryClient.invalidateQueries(["Planning", { workPositionId }]);
        queryClient.invalidateQueries(["MonthlyCalendar", { workPositionId }]);
        queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
        setWorkPositionsLocal((state) => {
          const newWp = [...state];
          const index = state.findIndex((el) => el.id === workPositionId);

          newWp[index] = {
            ...newWp[index],
            has_calendar_changed,
          };
          return newWp;
        });
      },
    }
  );

  const dates = Form.useWatch("dates", form);
  const startTime = Form.useWatch("startTime", form);
  const endTime = Form.useWatch("endTime", form);
  const endAtMidnight = Form.useWatch("endAtMidnight", form);

  const initialValues = useMemo(() => {
    if (spStatus === "update" && selectedSp) {
      const start = new Date(selectedSp.start_datetime);
      const end = new Date(selectedSp.end_datetime);
      return {
        ...selectedSp,
        startTime: new Date(
          `2020-06-01T${constructMinsOrH(
            start.getUTCHours()
          )}:${constructMinsOrH(start.getMinutes())}:00`
        ),
        endTime: new Date(
          `2020-06-0${
            selectedSp.end_at_midnight ? "2" : "1"
          }T${constructMinsOrH(end.getUTCHours())}:${constructMinsOrH(
            end.getMinutes()
          )}:00`
        ),
        endAtMidnight: selectedSp.end_at_midnight,
        dates: [
          {
            "react-datepicker__day--highlighted-custom-1": selectedSp.dates.map(
              (el) => new Date(el)
            ),
          },
          {
            "react-datepicker__day--highlighted-custom-2":
              monthlyCalendar.work_free_days_list.map(
                (el) => new Date(el.day_date)
              ),
          },
        ],
      };
    }
    return {
      dates: [
        {
          "react-datepicker__day--highlighted-custom-1": [],
        },
        {
          "react-datepicker__day--highlighted-custom-2":
            monthlyCalendar.work_free_days_list.map(
              (el) => new Date(el.day_date)
            ),
        },
      ],
    };
  }, [selectedSp, spStatus, monthlyCalendar]);

  useEffect(() => {
    if (endAtMidnight) {
      const newEndDate = new Date();
      newEndDate.setHours(0, 0, 0, 0);

      form.setFieldValue("endTime", newEndDate);
    }
  }, [endAtMidnight, form]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const validatorValidPeriod = () => {
    if (!startTime || !endTime) return Promise.resolve();

    const startDate = new Date("2020-06-01");
    startDate.setHours(startTime.getHours());
    startDate.setMinutes(startTime.getMinutes());

    const endDate = new Date(endAtMidnight ? "2020-06-02" : "2020-06-01");
    endDate.setHours(endTime.getHours());
    endDate.setMinutes(endTime.getMinutes());

    if (startDate.getTime() >= endDate.getTime())
      return Promise.reject(new Error(`Durée invalide.`));
    return Promise.resolve();
  };

  const addNewDate = (date) => {
    date.setHours(0, 0, 0, 0);
    const selectedDates =
      dates[0]["react-datepicker__day--highlighted-custom-1"];
    const workFreeDaysDates = dates[1];
    if (
      !configApplication.guarding.workpositions.edit_special_planning_modal ||
      !isLockedByUser
    ) {
      form.setFieldValue("dates", [
        { "react-datepicker__day--highlighted-custom-1": selectedDates },
        workFreeDaysDates,
      ]);
      return;
    }
    if (selectedDates?.length > 0) {
      const newDates = [...selectedDates];
      const idx = selectedDates.findIndex((el) => {
        el.setHours(0, 0, 0, 0);
        return el.getTime() === date.getTime();
      });
      if (idx !== -1) {
        newDates.splice(idx, 1);
      } else newDates.push(date);

      form.setFieldValue("dates", [
        { "react-datepicker__day--highlighted-custom-1": newDates },
        workFreeDaysDates,
      ]);
      return;
    }

    form.setFieldValue("dates", [
      { "react-datepicker__day--highlighted-custom-1": [date] },
      workFreeDaysDates,
    ]);
  };

  const handleSubmit = (values) => {
    const toSend = {
      special_planning: {
        name: values.name,
        start_datetime: `2020-06-01T${constructMinsOrH(
          values.startTime.getHours()
        )}:${constructMinsOrH(values.startTime.getMinutes())}:00`,
        end_datetime: `2020-06-01T${constructMinsOrH(
          values.endTime.getHours()
        )}:${constructMinsOrH(values.endTime.getMinutes())}:00`,
        end_at_midnight: values.endAtMidnight,
        dates: values.dates[0][
          "react-datepicker__day--highlighted-custom-1"
        ].map((el) => el.toString()),
        work_position_id: workPositionId,
      },
    };
    if (spStatus === "create") {
      createSp(toSend);
      setSpStatus();
      setSelectedSp();
    } else {
      updateSp({ ...toSend, id: selectedSp.id });
    }
  };

  const updateOnChange = () => {
    if (
      !configApplication.guarding.workpositions.edit_special_planning_modal ||
      !isLockedByUser
    )
      return;
    if (spStatus === "update") form.submit();
  };

  const validatorDatesOverlap = () => {
    if (!startTime || !endTime) return Promise.resolve();
    const spsToCheck = specialPlannings.filter(
      (el) => el.id !== selectedSp?.id
    );

    if (
      spsToCheck.some((sp) => {
        const start = new Date(sp.start_datetime);
        const end = new Date(sp.end_datetime);
        const spStartTime = new Date(
          `2020-06-01T${constructMinsOrH(
            start.getUTCHours()
          )}:${constructMinsOrH(start.getMinutes())}:00`
        );
        const spEndTime = new Date(
          `2020-06-01T${constructMinsOrH(end.getUTCHours())}:${constructMinsOrH(
            end.getMinutes()
          )}:00`
        );

        return sp.dates.some((date) => {
          const startDate = new Date(date);
          startDate.setHours(
            spStartTime.getHours(),
            spStartTime.getMinutes(),
            0,
            0
          );

          const endDate = new Date(date);
          endDate.setHours(spEndTime.getHours(), spEndTime.getMinutes(), 0, 0);
          if (sp.end_at_midnight) endDate.setDate(endDate.getDate() + 1);
          const startDateToCheck = dates[0][
            "react-datepicker__day--highlighted-custom-1"
          ].find(
            (selectedDate) =>
              selectedDate.getFullYear() === startDate.getFullYear() &&
              selectedDate.getMonth() === startDate.getMonth() &&
              selectedDate.getDate() === startDate.getDate()
          );

          if (startDateToCheck) {
            startDateToCheck.setHours(
              startTime.getHours(),
              startTime.getMinutes(),
              0,
              0
            );
            const endDateToCheck = new Date(startDateToCheck);
            endDateToCheck.setHours(
              endTime.getHours(),
              endTime.getMinutes(),
              0,
              0
            );
            if (endAtMidnight)
              endDateToCheck.setDate(endDateToCheck.getDate() + 1);

            return (
              startDateToCheck.getTime() < endDate.getTime() &&
              endDateToCheck.getTime() > startDate.getTime()
            );
          }
          return false;
        });
      })
    )
      return Promise.reject(
        new Error(`Des plannings spéciaux se chevauchent.`)
      );
    return Promise.resolve();
  };

  return (
    <Form
      id="sp-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
      <FormRow>
        <LeftWrapper>
          <LeftCol
            label="Intitulé"
            name="name"
            rules={[{ required: true }]}
            withoutPaddingRight
          >
            <Input
              onBlur={updateOnChange}
              disabled={
                !configApplication.guarding.workpositions
                  .edit_special_planning_modal || !isLockedByUser
              }
            />
          </LeftCol>
          <LeftCol
            label="Heure de début"
            name="startTime"
            dependencies={["endTime", "endAtMidnight"]}
            rules={[
              { required: true },
              {
                validator: validatorValidPeriod,
              },
            ]}
            withoutPaddingRight
          >
            <ReactDatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Heure"
              dateFormat="HH:mm"
              allowClear={false}
              onBlur={updateOnChange}
              onChange={updateOnChange}
              disabled={
                !configApplication.guarding.workpositions
                  .edit_special_planning_modal || !isLockedByUser
              }
            />
          </LeftCol>
          <LeftCol
            label="Heure de fin"
            name="endTime"
            rules={[{ required: true }]}
            withoutPaddingRight
          >
            <ReactDatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Heure"
              dateFormat="HH:mm"
              allowClear={false}
              disabled={
                endAtMidnight ||
                !configApplication.guarding.workpositions
                  .edit_special_planning_modal ||
                !isLockedByUser
              }
              onBlur={updateOnChange}
              onChange={updateOnChange}
            />
          </LeftCol>
          <LeftCol
            label="Fini à minuit"
            name="endAtMidnight"
            valuePropName="checked"
            withoutPaddingRight
          >
            <Checkbox
              onChange={updateOnChange}
              disabled={
                !configApplication.guarding.workpositions
                  .edit_special_planning_modal || !isLockedByUser
              }
            />
          </LeftCol>
          <AppliedDates>
            {dates &&
              dates[0]["react-datepicker__day--highlighted-custom-1"].length >
                0 &&
              getApplicatedDays(
                dates[0]["react-datepicker__day--highlighted-custom-1"]
              )}
          </AppliedDates>
        </LeftWrapper>
        <DatesWrapper>
          <FormCol
            name="dates"
            valuePropName="highlightDates"
            label="Jours appliqués"
            labelOnTop
            dependencies={["startTime", "endTime", "endAtMidnight"]}
            rules={[
              {
                validator: validatorDatesOverlap,
              },
            ]}
          >
            <StyledReactDatePicker
              onChange={addNewDate}
              inline
              disabledKeyboardNavigation
              minDate={new Date(quotationStartDate)}
              maxDate={new Date(quotationEndDate)}
              onBlur={updateOnChange}
              onSelect={updateOnChange}
            />
          </FormCol>
        </DatesWrapper>
      </FormRow>
    </Form>
  );
}

const StyledReactDatePicker = styled(ReactDatePicker)`
  .react-datepicker__day--highlighted-custom-1 {
    background-color: #1d5d90 !important;
    color: white !important;
    border-radius: 0.3rem;
  }
  .react-datepicker__day--highlighted-custom-2 {
    border: solid 3px yellow;
    box-sizing: content-box;
    color: black;
    border-radius: 0.3rem;
  }
`;

const DatesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  padding-left: 8px;
`;

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
`;

const LeftCol = styled(FormCol)`
  padding-left: 0 !important;
`;

const AppliedDates = styled.span`
  font-size: 12px;
`;

export default SPForm;
