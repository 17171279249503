import React from "react";
import styled from "styled-components";
import FormCol from "../../../Form/FormCol";
import { opportunityField } from "../../../constant";
import ReactDatePicker from "../../../Form/ReactDatePicker";

function OpportunityDateLimit({ required, disabled }) {
  return (
    <FormCol
      labelOnTop
      label="Date limite de réponse"
      name={opportunityField.submissionDate}
      rules={[{ required }]}
    >
      <StyledDatePicker
        placeholderText="Sélectionnez une date"
        disabled={disabled}
      />
    </FormCol>
  );
}
const StyledDatePicker = styled(ReactDatePicker)`
  width: 100%;
`;
export default OpportunityDateLimit;
