import React, { useState, useMemo } from "react";
import styled, { ThemeProvider } from "styled-components";
import { ConfigProvider, Modal } from "antd";
import frFr from "antd/lib/locale/fr_FR";
import { QueryClientProvider, QueryClient, useMutation } from "react-query";
import JobsAvailable from "./works_available";
import Button from "../react-ui/Button";
import WorkEdit from "./work_edit";
import Form from "../Form";
import { theme } from "../../theme/theme";
import AddButton from "../react-ui/AddButton";
import { postData } from "../request/instance";
import WorkAndSub from "./WorkAndSub";

const queryClient = new QueryClient();

function Index({
  works,
  user_works,
  token,
  list_api_providers,
  available_works,
  company,
  user_roles,
  procurement,
  configuration,
  all_works_configuration,
}) {
  return (
    <ConfigProvider locale={frFr}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <WorkAndSub
            company={company}
            available_works={available_works}
            list_api_providers={list_api_providers}
            token={token}
            user_works={user_works}
            works={works}
            user_roles={user_roles}
            procurement={procurement}
            configuration={configuration}
            all_works_configuration={all_works_configuration}
          />
        </QueryClientProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
}

export default Index;
