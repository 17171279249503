import React, { useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { Form, Tabs } from "antd";
import TitleContainer from "../../react-ui/TitleContainer";
import ClientForm from "./Form";
import Offers from "../../offers";
import { useStore } from "../../store";
import ExternalOrders from "./ExternalOrders";
import Contacts from "./Contacts";

const selector = (state) => ({
  formToken: state.formToken,
  configurationApplication: state.configurationApplication,
  vocabularyApplication: state.vocabularyApplication,
  configurations: state.configurations,
  procurement: state.procurement,
});

function ClientInformations({
  status,
  clients,
  selectedClientId,
  setStatus,
  generateClientFormData,
  companyId,
  statuses,
  activities,
  works,
  role_user,
  entities,
  users,
  setClients,
  clientsInMemory,
  setClientsInMemory,
  vocabulary,
  configuration,
}) {
  const {
    formToken,
    configurationApplication,
    configurations,
    vocabularyApplication,
    procurement,
  } = useStore(selector);
  const [selectedClient, setSelectedClient] = useState();
  const isShowing = status === "show";
  const isUpdating = status === "update";
  const showInformations = (isUpdating || isShowing) && selectedClientId;
  const [form] = Form.useForm();

  useLayoutEffect(() => {
    form.resetFields();
    if (!selectedClientId) setSelectedClient();
    else
      setSelectedClient({
        ...clients.find((client) => client.id === selectedClientId),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId]);

  const getTitle = () => {
    let title = "Informations";
    if (showInformations && selectedClient && selectedClient.name) {
      title += `: ${selectedClient.name}`;
    }
    return title;
  };

  const items = [
    {
      label: "Détails",
      key: "details",
      children: (
        <ClientForm
          isUpdating={isUpdating}
          setStatus={setStatus}
          selectedClientId={selectedClientId}
          isShowing={isShowing}
          setSelectedClient={setSelectedClient}
          selectedClient={selectedClient}
          status={status}
          arrayClients={clients}
          generateClientFormData={generateClientFormData}
          companyId={companyId}
          statuses={statuses}
          activities={activities}
          works={works}
          setArrayClients={setClients}
          clientsInMemory={clientsInMemory}
          setClientsInMemory={setClientsInMemory}
          vocabulary={vocabulary}
          configuration={configuration}
        />
      ),
    },
    {
      label:
        vocabularyApplication?.clients.offers_opportunities_accordion_title,
      key: "offers-opportunities",
      children: (
        <Offers
          token={formToken}
          role_user={role_user}
          configuration={configurations}
          vocabularyApplication={vocabularyApplication}
          configApplication={configurationApplication}
          works={works}
          selectedClientId={selectedClientId}
        />
      ),
    },
    {
      label: "Contacts",
      key: "contacts",
      children: (
        <Contacts
          clients={clients}
          entities={entities}
          works={works}
          users={users}
          selectedClientId={selectedClientId}
        />
      ),
    },
    {
      label: "Commandes externes",
      key: "external-order",
      children: (
        <ExternalOrders
          clients={clients}
          entities={entities}
          works={works}
          users={users}
          selectedClientId={selectedClientId}
        />
      ),
      hidden: procurement,
    },
  ].filter((el) => !el.hidden);

  return (
    <Container>
      <TitleContainer label={getTitle()} />
      {!showInformations && (
        <Wrapper>Sélectionnez un client pour afficher ses informations</Wrapper>
      )}
      {showInformations && (
        <StyledTabs
          defaultActiveKey="details"
          type="card"
          destroyInactiveTabPane
          items={items}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 69%;
  .ant-tabs,
  .ant-tabs-top,
  .ant-tabs-card {
    position: sticky;
    width: 100%;
    height: calc(100% - 58px);
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const StyledTabs = styled(Tabs)``;

export default ClientInformations;
