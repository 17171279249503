import React from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import TitleContainer from "../../../react-ui/TitleContainer";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import OppCosting from "./OppCosting";
import Leaser from "./Leaser";
import Loader from "../../../react-ui/Loader";

const selector = (state) => ({
  formToken: state.formToken,
});

function CostingSection({
  offerId,
  rentingOpp,
  isLoading,
  selectedLeaser,
  setSelectedLeaser,
  configuration,
  vocabulary,
}) {
  const { formToken } = useStore(selector);

  const { data: partialOpps } = useQuery(
    ["PartialOpportunities", { offerId }],
    () => getData(formToken, `/opportunities_partial?offer_id=${offerId}`),
    { placeholderData: [] }
  );

  return (
    <Container>
      <TitleContainer label="Coûts locatifs" />
      {isLoading && <Loader />}
      {!!rentingOpp && (
        <>
          <Leaser
            rentingOpp={rentingOpp}
            selectedLeaser={selectedLeaser}
            setSelectedLeaser={setSelectedLeaser}
          />
          <OppCosting
            rentingOpp={rentingOpp}
            periodType={selectedLeaser?.period_type}
            opportunityType="new_work"
            partialOpps={partialOpps.filter(
              (el) => el.opportunity_type === "new_work"
            )}
            configuration={configuration}
            vocabulary={vocabulary}
          />
          <OppCosting
            rentingOpp={rentingOpp}
            periodType={selectedLeaser?.period_type}
            opportunityType="maintenance"
            partialOpps={partialOpps.filter(
              (el) => el.opportunity_type === "maintenance"
            )}
            configuration={configuration}
            vocabulary={vocabulary}
          />
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 64%;
`;

export default CostingSection;
