import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useStore } from "../../store";
import ManufacturerDetails from "./ManufacturerDetails";
import ManufacturerArray from "./ManufacturerArray";
import { getUrlParams } from "../../utils";
import useTodo from "../../hookQuery/useTodo";
import TitleContainer from "../../react-ui/TitleContainer";
import Separator from "../../react-ui/Separator";
import AddButton from "../../react-ui/AddButton";

const selector = (state) => ({
  formToken: state.formToken,
  editManufacturer: state.editManufacturer,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function Manufacturer() {
  const { formToken, editManufacturer } = useStore(selector);

  const [wordEntered, setWordEntered] = useState("");
  const [manufacturerDetailsStatus, setManufacturerDetailsStatus] =
    useState("empty");

  const sendSearch = () => (wordEntered ? `&search=${wordEntered}` : "");

  const { data: manufacturers, refetch } = useTodo(
    formToken,
    `/manufacturers?entity_work_id=${getUrlParams()}${sendSearch()}&from=admin`,
    "Manufacturers"
  );

  useEffect(() => {
    editManufacturer(null);
    setManufacturerDetailsStatus("empty");
    debouncedSearch({ refetch });
  }, [wordEntered, refetch, editManufacturer]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const openManufacturerCreation = () => {
    editManufacturer(null);
    setManufacturerDetailsStatus("create");
  };

  return (
    <Container>
      <div className="main_admin-materiels">
        <TitleContainer label="Fabricants disponibles">
          <AddButton
            onClick={openManufacturerCreation}
            disabled={manufacturerDetailsStatus === "create"}
            label="Fabricant"
          />
        </TitleContainer>
        <StyledSearch
          allowClear
          placeholder="Rechercher par nom de fabricant"
          value={wordEntered}
          onChange={handleFilter}
        />
        <Separator isHorizontal size={3} />
        <div>
          <ManufacturerArray
            manufacturerDetailsStatus={manufacturerDetailsStatus}
            setManufacturerDetailsStatus={setManufacturerDetailsStatus}
            manufacturers={manufacturers}
          />
        </div>
      </div>
      <Separator />
      <ManufacturerDetails
        manufacturerDetailsStatus={manufacturerDetailsStatus}
        setManufacturerDetailsStatus={setManufacturerDetailsStatus}
        manufacturers={manufacturers}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
`;

const StyledSearch = styled(Search)``;

export default Manufacturer;
