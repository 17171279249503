import React from "react";
import styled from "styled-components";

function ZoneConfig({ className = "", ...iconProps }) {
  return (
    <StyledIcon
      className={`fa-kit fa-solid-bars-progress-gear ${className}`}
      {...iconProps}
    />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: ${({ $fontSize }) => $fontSize};
`;
export default ZoneConfig;
