import React, { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import { stringSorter } from "../../../../utils/stringSorter";
import NumberCell from "../../../react-ui/NumberCell";
import Delete from "../../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../../confirmModal";

const selector = (state) => ({
  formToken: state.formToken,
});

const columns = ({ handleDelete }) => [
  {
    title: "Nom du profil",
    dataIndex: "name",
    fixed: "left",
    width: "190px",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Créé le",
    dataIndex: "created_at",
    width: "80px",
    sorter: (a, b) => stringSorter(a.created_at, b.created_at),
    render: (created_at) => (
      <NumberCell>
        {new Date(created_at).toLocaleDateString("fr-FR")}
      </NumberCell>
    ),
  },
  {
    title: "",
    dataIndex: "",
    fixed: "right",
    align: "center",
    width: "20px",
    render: (record) => (
      <Delete
        onClick={(e) => {
          e.stopPropagation();
          ShowDeleteConfirm(record.name, record.id, handleDelete);
        }}
      />
    ),
  },
];

function ProfilManagementsArray({
  profilManagements,
  setStatus,
  setSelectedProfil,
  profilManagementsAreLoading,
}) {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);

  const { mutate: deleteProfilManagement } = useMutation(
    (todo) => postData(formToken, "/profil_management/delete", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("ProfilManagements");
      },
    }
  );

  const handleDelete = (id) => deleteProfilManagement({ id });

  const dataSource = useMemo(() => {
    if (!profilManagements) return [];
    return profilManagements;
  }, [profilManagements]);

  return (
    <TableContainer
      bordered={false}
      columns={columns({
        handleDelete,
      })}
      rowKey="id"
      dataSource={dataSource}
      pagination={false}
      isRowSelectable
      onRow={(record) => {
        return {
          onClick: () => {
            setSelectedProfil(
              profilManagements.find((el) => el.id === record.id)
            );
            setStatus("update");
          },
        };
      }}
      scrollY
      loading={profilManagementsAreLoading}
    />
  );
}

export default ProfilManagementsArray;
