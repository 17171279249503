import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { debounce } from "lodash";
import { getData } from "../../request/instance";
import { fetchDocumentsUrl } from "../../../utils/fetchDocumentsUrl";
import SATableTree, { constructDocumentRow } from "./SATableTree";
import BaseIcon from "../../react-ui/Icons/BaseIcon";
import { useStore } from "../../store";

const folderIcon = ({ expanded }) =>
  expanded ? (
    <FolderIcon className="fa-duotone fa-folder-open" />
  ) : (
    <FolderIcon className="fa-duotone fa-folder" />
  );

const draggableProps = {
  droppable: false,
  type: "resourceItem",
};

const selector = (state) => ({
  newFile: state.newFile,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 600);

const SAResourceTable = React.memo(
  ({
    folders,
    opportunity_ids,
    formToken,
    siteTypologies,
    columnsToShow,
    foldersAreLoading,
    opportunity_names,
    offer_name,
    offer_id,
    wordEntered,
    fromOffer,
  }) => {
    const { newFile } = useStore(selector);
    const [, setFilters] = useState({});
    const [data, setData] = useState([]);
    const [showAll, setShowAll] = useState(true);

    const { data: documentsWithoutFolders, isLoading: documentsAreLoading } =
      useQuery(
        ["Documents", { opportunity_ids, simplified_application: true }],
        () =>
          getData(
            formToken,
            fetchDocumentsUrl({
              search: undefined,
              opportunity_ids,
              simplified_application: true,
            })
          ),
        { refetchOnWindowFocus: false }
      );

    const { data: documentsFromSearch, refetch } = useQuery(
      [
        "DocumentsFromSearch",
        {
          offer_id,
          opportunity_ids,
          simplified_application: true,
          search_from_sa: true,
        },
      ],
      () =>
        getData(
          formToken,
          fetchDocumentsUrl({
            search: wordEntered,
            opportunity_ids,
            offer_id,
            simplified_application: true,
            search_from_sa: true,
          })
        ),
      {
        refetchOnWindowFocus: false,
        onSettled: () => {
          setShowAll(wordEntered === "");
        },
      }
    );

    useEffect(() => {
      debouncedSearch({
        refetch,
      });
    }, [wordEntered, refetch]);

    useEffect(() => {
      if (documentsWithoutFolders) {
        setData((oldData) => {
          const newData = oldData.filter(
            (element) => element.type !== "document"
          );
          documentsWithoutFolders.forEach((document) => {
            newData.push(
              constructDocumentRow({
                document,
                folders,
                siteTypologies,
              })
            );
          });
          return newData;
        });
      }
    }, [
      documentsWithoutFolders,
      folders,
      siteTypologies,
      documentsFromSearch,
      wordEntered,
    ]);

    useEffect(() => {
      if (folders) {
        setData((oldData) => {
          const newData = [...oldData].filter(
            (element) => !element.type.includes("folder")
          );
          folders.forEach((folder) => {
            newData.unshift({
              ...folder,
              name: folder.name,
              key: `folder-${folder.id}`,
              icon: folderIcon,
              type: "folder",
              isLeaf: false,
            });
          });
          opportunity_ids.forEach((opportunity_id, idx) =>
            newData.unshift({
              id: opportunity_id,
              name: opportunity_names[idx],
              key: `opportunity-${opportunity_id}`,
              icon: folderIcon,
              type: "folder-opportunity",
              isLeaf: false,
            })
          );
          newData.unshift({
            id: offer_id,
            name: offer_name,
            key: `offer-${offer_id}`,
            icon: folderIcon,
            type: "folder-offer",
            isLeaf: false,
          });
          return newData;
        });
      }
    }, [folders, offer_id, offer_name, opportunity_ids, opportunity_names]);

    useEffect(() => {
      if (newFile) {
        setData((oldData) => {
          const idx = fromOffer ? 0 : 1;
          const newData = [...oldData];
          if (newData[idx].children) {
            newData[idx].children = [newFile, ...newData[idx].children];
          }
          return newData;
        });
      }
    }, [newFile, fromOffer]);

    const documentsToHide = useMemo(() => {
      if (!documentsFromSearch || showAll) return null;
      const toHide = [];
      data.forEach((el) => {
        if (
          el.type === "document" &&
          !documentsFromSearch.some((docFrom) => docFrom.id === el.id)
        ) {
          toHide.push(el.key);
        } else if (el.children?.length > 0) {
          el.children.forEach((doc) => {
            if (
              doc.type === "document" &&
              !documentsFromSearch.some((docFrom) => docFrom.id === doc.id)
            ) {
              toHide.push(doc.key);
            }
          });
        }
      });
      return toHide;
    }, [data, documentsFromSearch, showAll]);

    const foldersToOpen = useMemo(() => {
      if (!documentsFromSearch || showAll) return null;
      const toOpen = [];
      data.forEach((el) => {
        if (
          el.type === "folder" &&
          documentsFromSearch.some((docFrom) =>
            docFrom.document_folders.map((df) => df.folder_id).includes(el.id)
          )
        ) {
          toOpen.push(el);
        } else if (
          el.type === "folder-opportunity" &&
          documentsFromSearch.some(
            (docFrom) => docFrom.opportunity_id === el.id
          )
        ) {
          toOpen.push(el);
        } else if (
          el.type === "folder-offer" &&
          documentsFromSearch.some((docFrom) => docFrom.offer_id === el.id)
        ) {
          toOpen.push(el);
        }
      });
      return toOpen;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentsFromSearch, showAll]);

    return (
      <SATableTree
        data={data}
        loading={documentsAreLoading || foldersAreLoading}
        setFilters={setFilters}
        folders={folders}
        siteTypologies={siteTypologies}
        columnsToShow={columnsToShow}
        opportunity_ids={opportunity_ids}
        setData={setData}
        formToken={formToken}
        draggableProps={draggableProps}
        documentsToHide={documentsToHide}
        foldersToOpen={foldersToOpen}
        tableHeight={500}
        fromSA
      />
    );
  }
);

const FolderIcon = styled(BaseIcon)`
  color: ${({ theme }) => theme.colors.blue11};
`;

export default SAResourceTable;
