import React from "react";
import { Form } from "antd";
import {
  array,
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from "prop-types";
import Label from "./Label";
import StyledSelect from "../react-ui/Select";

function Select({
  select,
  options,
  keyName,
  handleUpdateOnChange,
  isShowing,
  mode,
  noSort,
  ...formItemProps
}) {
  const handleOnChange = (e) => {
    if (select.onChange) select.onChange(e);
    handleUpdateOnChange();
  };

  const disabled = select.disabled || isShowing;

  return (
    <Form.Item
      {...formItemProps}
      label={<Label label={formItemProps.label} rules={formItemProps.rules} />}
    >
      <StyledSelect
        {...select}
        onChange={handleOnChange}
        disabled={disabled}
        placeholder={isShowing ? "" : select.placeholder}
        options={
          noSort
            ? options
            : options.sort((a, b) => a[keyName]?.localeCompare(b[keyName]))
        }
        keyName={keyName}
        mode={mode}
      />
    </Form.Item>
  );
}

Select.propTypes = {
  select: shape({
    placeholder: string,
    onBlur: func,
    disabled: bool,
  }).isRequired,
  options: arrayOf(
    shape({
      id: oneOfType([string, bool, number]).isRequired,
    })
  ),
  name: string.isRequired,
  keyName: string,
  label: string,
  rules: array,
  handleUpdateOnChange: func.isRequired,
  isShowing: bool,
  noSort: bool,
  mode: string,
};

Select.defaultProps = {
  options: [],
  keyName: "title",
  label: "",
  rules: [],
  isShowing: false,
  noSort: false,
  mode: "",
};

export default Select;
