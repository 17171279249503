import React from "react";
import SwitchComponent from "../SwitchComponent";
import { GridContainer } from "../styledComponent";

function Filter({ filters, setFilters }) {
  const handleChangeValue = (value, name) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  return (
    <GridContainer
      direction="vertical"
      size={16}
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto auto",
        width: "70%",
      }}
    >
      <SwitchComponent
        initialValue={filters.show_cost_price}
        labelText="Visualiser le coût de revient"
        handleChange={handleChangeValue}
        name="show_cost_price"
      />
      <SwitchComponent
        initialValue={filters.show_margin}
        labelText="Visualiser la marge"
        handleChange={handleChangeValue}
        name="show_margin"
      />
      <SwitchComponent
        initialValue={filters.disable_selling_price}
        labelText="Désactiver l'édition du prix de vente"
        handleChange={handleChangeValue}
        name="disable_selling_price"
      />
    </GridContainer>
  );
}

export default Filter;
