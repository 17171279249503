import React, { useState, useEffect } from "react";
import { Modal, Checkbox } from "antd";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { useQueryClient, useMutation } from "react-query";
import MaterialsForm from "../../Materials/MaterialsForm";
import LazyMaterialArray from "../../Materials/LazyMaterialArray";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { stringToFloat, currency_cents_to_euros } from "../../../utils";
import Eye from "../../../react-ui/Icons/Eye";
import { postData } from "../../../request/instance";
import FormattedInput from "../../../react-ui/FormattedInput";
import { numberSorter } from "../../../../utils/numberSorter";
import Select from "../../../react-ui/Select";
import Input from "../../../react-ui/Input";

const selector = (state) => ({
  formToken: state.formToken,
});

function ChildMaterialArray({
  categories,
  unities,
  manufacturers,
  distributors,
  updateMaterialAssociate,
  from,
  opportunityId,
  entityWorkId,
  faId,
  faIsValidate,
  parent,
  configuration,
  vocabulary,
  setMaterialsParent,
  materialsParent,
  ouvrageSelected,
  formToken,
  ouvrageChildId,
  setImpactedModelZone,
  round_type_list,
}) {
  const queryClient = useQueryClient();
  const [materialAssociateData, setMaterialAssociateData] = useState([]);
  const [materialChecked, setMaterialChecked] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showMaterial, setShowMaterial] = useState();
  const [wordEntered, setWordEntered] = useState("");
  const [materials, setMaterials] = useState([]);
  const [tableRowsData, setTableRowData] = useState([]);

  const ouvrageProfessionQueryKey = [
    "OuvrageProfession",
    ouvrageSelected[0]?.id,
  ];

  const { mutate: createAssociate } = useMutation(
    (todo) => postData(formToken, "/material_associate/create", todo),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(ouvrageProfessionQueryKey);
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  const { mutate: deleteAssociate } = useMutation(
    (todo) => postData(formToken, `/material_associate/delete`, todo),
    {
      onSettled: (data) => {
        queryClient.invalidateQueries(ouvrageProfessionQueryKey);
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  const { mutate: updateAssociate } = useMutation(
    (todo) => postData(formToken, `/material_associate/update`, todo),
    {
      onSettled: (data) => {
        queryClient.invalidateQueries("Materials");
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );
  const showModal = (mat) => {
    setIsModalVisible(true);
    setShowMaterial(materials.find((item) => item.id === mat.id));
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const handleCheckMaterial = (checked, record) => {
    if (checked) {
      createAssociate({
        material_parent_id: record.id,
        ouvrage_profession_id: ouvrageChildId,
        quantity: 1,
        linked: true,
      });
    } else {
      deleteAssociate({
        material_parent_id: record.id,
        ouvrage_profession_id: ouvrageChildId,
      });
    }
  };

  useEffect(() => {
    setMaterialChecked([]);
  }, [ouvrageSelected[0]?.id]);

  useEffect(() => {
    const dataResult = [];
    let child;
    materials?.forEach((value) => {
      child = {
        ...value,
        key: value.id,
        id: value.id,
        name: value.material_included
          ? value.material_included.name
          : value.name,
        price: formatNumberString({
          str: currency_cents_to_euros(value.cost_price_cents),
          nbDecimal: 2,
          space: true,
        }),
        is_in_framework_agreement:
          value.is_in_framework_agreement || value.material_included,
        margin: value.margin_rate,
        actions: undefined,
        category: value.category_profession?.title,
      };
      dataResult.push(child);
    });
    setTableRowData(dataResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materials]);

  const displayInputQuantity = (record) => {
    return materialChecked.some((item) => item.id === record.id)
      ? materialChecked.find((item) => item.id === record.id).check
      : record.associated;
  };
  const matCols = [
    {
      title: "Intitulé",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Associer",
      dataIndex: "associated",
      width: "65px",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        numberSorter(a.associated, b.associated) || numberSorter(a.id, b.id),
      render: (associated, record) => (
        <Checkbox
          onChange={({ target: { checked } }) => {
            handleCheckMaterial(checked, record);
            if (!materialChecked.some((item) => item.id === record.id)) {
              // Add the record to materialChecked if it doesn't exist
              setMaterialChecked([
                ...materialChecked,
                {
                  id: record.id,
                  check: checked,
                },
              ]);
            } else {
              // Update the check value if the record exists
              setMaterialChecked(
                materialChecked.map((item) => {
                  if (item.id === record.id) {
                    return {
                      ...item,
                      check: checked,
                    };
                  }
                  return item;
                })
              );
            }
            setMaterialAssociateData([
              ...materialAssociateData,
              {
                id: record.id,
                quantity_associated: 1,
              },
            ]);
          }}
          checked={
            materialChecked.some((item) => item.id === record.id)
              ? materialChecked.find((item) => item.id === record.id).check
              : record.associated
          }
        />
      ),
    },
    {
      title: "Quantité",
      dataIndex: "quantity",
      key: "quantity",
      width: "75px",
      render: (val, record) =>
        displayInputQuantity(record) && (
          <FormattedInput
            isNumber
            fontSize="10px"
            value={
              materialAssociateData.some((item) => item.id === record.id)
                ? parseFloat(
                    materialAssociateData.find((item) => item.id === record.id)
                      .quantity_associated
                  )
                : formatNumberString({ str: record.quantity_associated })
            }
            size="small"
            textAlign="right"
            suffix={record.abbreviation}
            onChange={(e) => {
              if (
                !materialAssociateData.some((item) => item.id === record.id)
              ) {
                // Add the record to materialAssociateData if it doesn't exist
                setMaterialAssociateData([
                  ...materialAssociateData,
                  {
                    id: record.id,
                    quantity_associated: formatNumberString({
                      str: e.target.value,
                    }),
                  },
                ]);
              } else {
                // Update the quantity_associated value if the record exists
                setMaterialAssociateData(
                  materialAssociateData.map((item) => {
                    if (item.id === record.id) {
                      return {
                        ...item,
                        quantity_associated: formatNumberString({
                          str: e.target.value,
                        }),
                      };
                    }
                    return item;
                  })
                );
              }
            }}
            onBlur={(e) =>
              updateAssociate({
                material_parent_id: record.id,
                ouvrage_profession_id: ouvrageChildId,
                quantity: stringToFloat(e.target.value),
              })
            }
          />
        ),
    },
    {
      title: "Arrondi",
      dataIndex: "round_type",
      key: "round_type",
      width: "94px",
      hidden:
        !configuration.admin.product_tab.form.product_associate.input_linked,
      render: (val, material) => {
        return (
          displayInputQuantity(material) && (
            <>
              <StyledSelect
                $faId={faId}
                allowClear
                bordered={false}
                fontSize="10px"
                placeholder="Aucun"
                value={
                  materialAssociateData.some((item) => item.id === material.id)
                    ? materialAssociateData.find(
                        (item) => item.id === material.id
                      ).round_type
                    : material.round_type
                }
                onChange={(e) => {
                  let roundType = null;
                  let roundNumber = material.round_number;
                  if (!e) {
                    roundNumber = null;
                    updateAssociate({
                      material_parent_id: material.id,
                      ouvrage_profession_id: ouvrageChildId,
                      round_type: null,
                      round_number: null,
                    });
                  } else {
                    roundType = e;
                    updateAssociate({
                      material_parent_id: material.id,
                      ouvrage_profession_id: ouvrageChildId,
                      round_type: e,
                    });
                  }

                  if (
                    !materialAssociateData.some(
                      (item) => item.id === material.id
                    )
                  ) {
                    // Add the record to materialAssociateData if it doesn't exist
                    setMaterialAssociateData([
                      ...materialAssociateData,
                      {
                        id: material.id,
                        round_type: roundType,
                        round_number: roundNumber,
                      },
                    ]);
                  } else {
                    // Update the quantity_associated value if the record exists
                    setMaterialAssociateData(
                      materialAssociateData.map((item) => {
                        if (item.id === material.id) {
                          return {
                            ...item,
                            round_type: roundType,
                            round_number: roundNumber,
                          };
                        }
                        return item;
                      })
                    );
                  }
                }}
                options={round_type_list}
                disabled={
                  (materialChecked.some((item) => item.id === material.id)
                    ? !materialChecked.find((item) => item.id === material.id)
                        .check
                    : !material.associated) ||
                  (from === "frameworkAgreement" &&
                    opportunityId &&
                    !faIsValidate)
                }
              />
              {(materialAssociateData.some((item) => item.id === material.id)
                ? materialAssociateData.find((item) => item.id === material.id)
                    .round_type
                : material.round_type) === "decimal" && (
                <Input
                  fontSize="10px"
                  value={formatNumberString({
                    str: materialAssociateData.some(
                      (item) => item.id === material.id
                    )
                      ? materialAssociateData.find(
                          (item) => item.id === material.id
                        ).round_number
                      : material.round_number,
                  })}
                  size="small"
                  textAlign="right"
                  onChange={(e) => {
                    const materialUpdated = material;
                    materialUpdated.round_number = formatNumberString({
                      str: e.target.value,
                    });
                    if (
                      !materialAssociateData.some(
                        (item) => item.id === material.id
                      )
                    ) {
                      // Add the record to materialAssociateData if it doesn't exist
                      setMaterialAssociateData([
                        ...materialAssociateData,
                        {
                          id: material.id,
                          round_number: formatNumberString({
                            str: e.target.value,
                          }),
                        },
                      ]);
                    } else
                      setMaterialAssociateData(
                        materialAssociateData.map((item) => {
                          if (item.id === material.id) {
                            return {
                              ...item,
                              round_number: formatNumberString({
                                str: e.target.value,
                              }),
                            };
                          }
                          return item;
                        })
                      );
                  }}
                  onBlur={(e) => {
                    updateAssociate({
                      material_parent_id: material.id,
                      ouvrage_profession_id: ouvrageChildId,
                      round_number: e.target.value
                        ? stringToFloat(e.target.value)
                        : 0,
                    });
                  }}
                  disabled={
                    (materialChecked.some((item) => item.id === material.id)
                      ? !materialChecked.find((item) => item.id === material.id)
                          .check
                      : !material.associated) ||
                    (from === "frameworkAgreement" &&
                      opportunityId &&
                      !faIsValidate)
                  }
                />
              )}
            </>
          )
        );
      },
    },
    {
      title: "",
      dataIndex: "show",
      key: "show",
      width: "20px",
      render: (_, material) => (
        <CenteredContainer>
          <Eye
            onClick={() => {
              showModal(material);
            }}
          />
        </CenteredContainer>
      ),
    },
  ].filter((col) => !col.hidden);

  return (
    <>
      <Modal
        width={840}
        maskClosable={false}
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <h3>Information du {vocabulary?.product_lowercase}</h3>
        <MaterialsForm
          initialMaterial={showMaterial}
          categories={categories}
          unities={unities}
          manufacturers={manufacturers}
          from={from}
          hasMaterialIncluded={
            showMaterial?.material_included !== null &&
            showMaterial?.material_included !== undefined
          }
          isShowing
          faIsValidate={faIsValidate}
          distributors={distributors}
          configuration={configuration}
          vocabulary={vocabulary}
        />
      </Modal>
      <StyledSearch
        allowClear
        placeholder={`Rechercher par ${vocabulary?.product_lowercase}, code article, etc...`}
        value={wordEntered}
        onChange={handleFilter}
      />
      <LazyMaterialArray
        ouvrageId={ouvrageChildId}
        columns={matCols}
        dataSource={tableRowsData}
        from={from}
        opportunityId={opportunityId}
        faIsValidate={faIsValidate}
        faId={faId}
        entityWorkId={entityWorkId}
        materials={materials}
        setMaterials={setMaterials}
        wordEntered={wordEntered}
        parent="ouvrage"
        isForAssociates
        adminDetails
      />
    </>
  );
}

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledSearch = styled(Search)`
  margin: 10px 0;
`;

const StyledSelect = styled(Select)`
  width: 94px;
`;

export default ChildMaterialArray;
