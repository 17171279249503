import React, { useState, useEffect } from "react";
import { Spin, Tag } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

export const Loading = ({ status }) => {
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (status === "success") {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 1500);
    }
  }, [status]);

  return (
    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
      {status === "loading" && (
        <Spin style={{ display: "flex", justifyContent: "center" }} />
      )}
      {status === "success" && showSuccess && (
        <Tag
          icon={<CheckCircleOutlined />}
          color="success"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Enregistré !
        </Tag>
      )}
    </div>
  );
};
