import { PaperClipOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Delete from "../../../react-ui/Icons/Delete";
import Edit from "../../../react-ui/Icons/Edit";
import Input from "../../../react-ui/Input";

const regExtension = /(?:\.([^.]+))?$/;

function FileUploaded({
  form,
  file,
  disabled,
  actions: { remove },
  keyName,
  onChange,
}) {
  const inputRef = useRef(null);
  const uploadName = useRef(file.name);
  const [showInput, setShowInput] = useState(false);
  const [extension, setExtension] = useState("");
  const handleDelete = (e) => {
    e.stopPropagation();
    remove();
  };

  useEffect(() => {
    if (file.size) {
      const isLt75M = file.size / 1024 / 1024 < 75;
      if (!isLt75M) {
        remove();
      }
    }
  }, [file, remove]);

  const handleEdit = (event) => {
    const currentFiles = [...form.getFieldValue(keyName)];
    const selectedFileIdx = currentFiles.findIndex((el) => el.uid === file.uid);
    if (event.target.value) {
      currentFiles[selectedFileIdx].name = event.target.value + extension;
    } else {
      currentFiles[selectedFileIdx].name = uploadName.current;
    }
    form.setFieldValue(keyName, currentFiles);
  };

  const toggleEdit = (e) => {
    if (disabled) return;
    e.stopPropagation();
    const ext = regExtension.exec(file.name)[1];
    setExtension(ext ? `.${ext}` : "");
    setShowInput(true);
  };

  const hideEdit = () => {
    setExtension("");
    setShowInput(false);
    const currentFiles = [...form.getFieldValue(keyName)];
    const selectedFileIdx = currentFiles.findIndex((el) => el.uid === file.uid);
    uploadName.current = currentFiles[selectedFileIdx].name;
    onChange();
  };

  useEffect(() => {
    if (showInput)
      inputRef.current.focus({
        cursor: "start",
      });
  }, [showInput]);

  const getFilenameBase = () => {
    let lastDot = file.name.lastIndexOf(".");
    lastDot = lastDot > 0 ? lastDot : Infinity;
    const filenameBase = file.name.substr(0, lastDot);
    return filenameBase;
  };

  return (
    <Container onClick={toggleEdit}>
      {!disabled && (
        <>
          <StyledDelete>
            <Delete onClick={handleDelete} />
          </StyledDelete>
          <StyledEdit>
            <Edit onClick={toggleEdit} />
          </StyledEdit>
        </>
      )}
      {showInput ? (
        <StyledInput
          ref={inputRef}
          defaultValue={getFilenameBase()}
          onChange={handleEdit}
          onBlur={hideEdit}
          addonAfter={extension}
        />
      ) : (
        <FileName>{file.name}</FileName>
      )}
      <StyledPaperClip />
    </Container>
  );
}

const StyledInput = styled(Input)`
  height: 22px;
  .ant-input {
    height: 22px !important;
  }
`;

const StyledEdit = styled.div`
  i {
    transition: color 0.3s;
    color: rgba(0, 0, 0, 0.45);
  }
  margin-left: auto;
  padding: 0 4px;
`;

const StyledDelete = styled.div`
  i {
    transition: color 0.3s;
  }
  &:not(:hover) {
    i {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  }
  padding: 0 4px;
`;

const Container = styled.div`
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: stretch;
  margin-top: 6px;
  padding: 1px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    ${StyledDelete}:not(:hover) +
      ${StyledEdit} {
      i {
        color: ${({ theme }) => theme.colors.blue04};
      }
    }
  }
`;

const StyledPaperClip = styled(PaperClipOutlined)`
  color: rgba(0, 0, 0, 0.45);
`;

const FileName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 8px;
`;

export default FileUploaded;
