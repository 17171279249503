import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["previous"]

  disabled_buttons_next() {
    let button_previous=this.previousTarget
    button_previous.disabled = true
    // this.disabled_breadcrumb()
  }

  disabled_breadcrumb(){
    // let breadcrumbs = document.getElementsByClassName('breadcrumb')
    // let breadcrumbs_separators = document.getElementsByClassName('breadcrumb-separator')
    // let breadcrumbs_selected = document.getElementsByClassName('breadcrumb-selected')
    // let breadcrumbs_clicked = document.getElementsByClassName('breadcrumb-clicked')
    // let breadcrumbs_separators_clicked = document.getElementsByClassName('breadcrumb-separator-clicked')
    // let breadcrumbs_selected_clicked = document.getElementsByClassName('breadcrumb-selected-clicked')
    // breadcrumbs.forEach (breadcrumb => breadcrumb.hidden=true)
    // breadcrumbs_separators.forEach (breadcrumb_separator => breadcrumb_separator.hidden=true)
    // breadcrumbs_selected.forEach (breadcrumb_selected => breadcrumb_selected.hidden=true)
    // breadcrumbs_clicked.forEach (breadcrumb_clicked => breadcrumb_clicked.hidden=false)
    // breadcrumbs_separators_clicked.forEach (breadcrumb_separator_clicked => breadcrumb_separator_clicked.hidden=false)
    // breadcrumbs_selected_clicked.forEach (breadcrumb_selected_clicked => breadcrumb_selected_clicked.hidden=false)
  }
}
