export const multiplyFloat = ({ floatArray, transformer = 1000 }) => {
  if (!floatArray || floatArray.length < 1) return 0;

  const untransformedResult = floatArray.reduce(
    (prev, curr) => prev * transformer * curr,
    1
  );

  return untransformedResult / Math.pow(transformer, floatArray.length);
};
