import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { createFile } from "../../../utils/createFile";
import { postData } from "../../request/instance";
import DocumentForm from "../../Admin-cf/DocumentsFolders/Documents/DocumentForm";

function ImportDocumentDetails({
  opportunity_id,
  formToken,
  status,
  selectedDocument,
  setStatus,
  offer_id,
}) {
  const queryClient = useQueryClient();
  const [mutationIsLoading, setMutationIsLoading] = useState(false);

  const { mutate: createDocument } = useMutation((todo) =>
    postData(formToken, "/document/create", todo)
  );

  const {
    mutate: updateDocument,
    isLoading: updateIsLoading,
    status: updateStatus,
  } = useMutation((todo) => postData(formToken, "/document/update", todo), {
    onSettled: () => {
      queryClient.invalidateQueries([
        "Documents",
        offer_id ? { offer_id } : { opportunity_id },
      ]);
      queryClient.invalidateQueries("numDocuments");
    },
  });

  const appendDocumentToForm = ({ formData, values, file }) => {
    const keyName = status === "create" ? "documents[]" : "document";
    formData.append(`${keyName}[name]`, file.name);
    if (file.originFileObj) {
      formData.append(
        `${keyName}[file]`,
        createFile([file.originFileObj], file.name, {
          type: file.type,
        })
      );
    }
    formData.append(`${keyName}[description]`, values.description || "");
    if (opportunity_id)
      formData.append(`${keyName}[opportunity_id]`, opportunity_id);
    if (offer_id) formData.append(`${keyName}[offer_id]`, offer_id);
  };

  const handleSubmit = (values) => {
    let formData = new FormData();

    if (status === "create") {
      setMutationIsLoading(true);
      let nbMB = 0;
      values.files.forEach((file) => {
        const fileSize = Math.ceil(file.size / 1024 / 1024);
        if (fileSize >= 100) return;
        if (nbMB + fileSize >= 100) {
          nbMB = 0;
          createDocument(formData);
          formData = new FormData();
        }
        nbMB += fileSize;
        appendDocumentToForm({ formData, values, file });
      });
      createDocument(formData, {
        onSettled: () => {
          setMutationIsLoading(false);
          queryClient.invalidateQueries([
            "Documents",
            offer_id ? { offer_id } : { opportunity_id },
          ]);
          queryClient.invalidateQueries("numDocuments");
          setStatus("empty");
        },
      });
    }
    if (status === "update") {
      const fileSize = Math.ceil(values.file[0].size / 1024 / 1024);
      if (fileSize >= 100) return;
      appendDocumentToForm({ formData, values, file: values.file[0] });
      formData.append("id", selectedDocument.id);
      updateDocument(formData);
    }
  };

  const initialValues = useMemo(() => {
    if (status !== "update" || !selectedDocument) return undefined;

    return {
      ...selectedDocument,
      works: [],
      entities: [],
      folders: [],
      typologies: [],
      file: [
        {
          uid: "1",
          name: selectedDocument.name,
          status: "done",
          url: selectedDocument.file,
        },
      ],
    };
  }, [selectedDocument, status]);

  return (
    <Container>
      <DocumentForm
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        status={status}
        formIsLoading={mutationIsLoading || updateIsLoading}
        fromCosting
        fromOpportunity
        onCancel={() => setStatus("empty")}
        updateOnChange={status === "update" && !!initialValues}
        updateStatus={updateStatus}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  overflow: hidden;
`;

export default ImportDocumentDetails;
