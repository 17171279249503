import { formatNumberString } from "../../utils/formatNumberString";

const estimateQuantityRule = {
  id: "estimate_quantity",
  name: "Quantité estimée",
  description: "TODO: Quantité estimée description",
  suffix: "",
};

const costPriceRule = {
  id: "final_price",
  name: "Prix final HT",
  description: "TODO: Coût de revient description",
  suffix: "€",
};

const marginRule = {
  id: "margin",
  name: "Marge",
  description: "TODO: Marge description",
  suffix: "%",
};

const rules = [estimateQuantityRule, costPriceRule, marginRule];

const findRule = (ruleOn) => rules.find((r) => r.id === ruleOn);

const getOperatorStr = ({ rule_on, operator }) => {
  let operatorStr = operator === "lower" ? "inférieur" : "supérieur";

  if (rule_on !== costPriceRule.id) {
    operatorStr += "e";
  }

  return `${operatorStr} à`;
};

const getRuleName = ({
  rule_on,
  operator,
  value_to_compare,
  typology = "",
}) => {
  const localRule = findRule(rule_on);
  const operatorStr = getOperatorStr({ rule_on, operator });
  const valueCompareStr = value_to_compare
    ? `${formatNumberString({ str: value_to_compare, space: true })}`
    : "la normale";
  const typoStr = typology ? ` (${typology})` : "";

  return `${localRule.name} ${operatorStr} ${valueCompareStr}${localRule.suffix}${typoStr}`;
};

export {
  estimateQuantityRule,
  costPriceRule,
  marginRule,
  findRule,
  getRuleName,
};
