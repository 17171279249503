import React, { useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { offerReviewFields } from "../constant";
import FormCol from "../Form/FormCol";
import FormRow from "../Form/FormRow";
import CreateLink from "../react-ui/CreateLink";
import Input from "../react-ui/Input";
import Select from "../react-ui/Select";
import { getData } from "../request/instance";
import { useStore } from "../store";
import RiskModal from "./RiskModal";

const selector = (state) => ({
  formToken: state.formToken,
});

function RiskManagement({ companyId, updateOnChange, form }) {
  const { formToken } = useStore(selector);
  const [riskModalType, setRiskModalType] = useState("");

  const { data: risksContracts } = useQuery("RisksContracts", () =>
    getData(formToken, `/risk_management_contracts/${companyId}`)
  );

  const { data: risksSites } = useQuery("RisksSites", () =>
    getData(formToken, `/risk_management_sites/${companyId}`)
  );

  const { data: servicesOffers } = useQuery("ServicesOffers", () =>
    getData(formToken, `/service_monitoring_obligations/${companyId}`)
  );

  const getRisks = () => {
    if (riskModalType === "") return [];
    if (riskModalType === "site") return risksSites;
    if (riskModalType === "contract") return risksContracts;
    return servicesOffers;
  };

  return (
    <>
      <RiskModal
        riskModalType={riskModalType}
        setRiskModalType={setRiskModalType}
        formOfferReview={form}
        risks={getRisks()}
        companyId={companyId}
        updateOnChange={updateOnChange}
      />
      <FormRow marginTop="0">
        <FormCol
          labelOnTop
          label="Risques liés au site"
          name={offerReviewFields.riskSiteOfferReviews}
          customContent={
            <StyledCreateLink
              label="Créer un risque site"
              onClick={() => setRiskModalType("site")}
            />
          }
        >
          <StyledSelect
            options={risksSites}
            mode="multiple"
            onChange={updateOnChange}
            maxTagCount="responsive"
          />
        </FormCol>
      </FormRow>

      <FormRow marginTop="0">
        <FormCol
          labelOnTop
          label="Risques liés au contrat"
          name={offerReviewFields.riskContractReviews}
          customContent={
            <StyledCreateLink
              label="Créer un risque contrat"
              onClick={() => setRiskModalType("contract")}
            />
          }
        >
          <StyledSelect
            options={risksContracts}
            mode="multiple"
            onChange={updateOnChange}
            maxTagCount="responsive"
          />
        </FormCol>
      </FormRow>

      <FormRow marginTop="0">
        <FormCol
          labelOnTop
          label="Obligations en terme de suivi de la prestation"
          name={offerReviewFields.serviceOfferReviews}
          customContent={
            <StyledCreateLink
              label="Créer une obligation de suivi"
              onClick={() => setRiskModalType("service")}
            />
          }
        >
          <StyledSelect
            options={servicesOffers}
            mode="multiple"
            onChange={updateOnChange}
            maxTagCount="responsive"
          />
        </FormCol>
      </FormRow>

      <FormRow>
        <FormCol
          label="Commentaires"
          name={offerReviewFields.commentsRiskManagement}
        >
          <StyledTextArea onBlur={updateOnChange} textArea />
        </FormCol>
      </FormRow>
    </>
  );
}

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledCreateLink = styled(CreateLink)`
  margin-top: 10px;
`;

const StyledTextArea = styled(Input)`
  resize: none;
  height: 105px !important;
`;

export default RiskManagement;
