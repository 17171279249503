import React from "react";
import styled, { css } from "styled-components";

function TitleContainer({
  className,
  children,
  label,
  noBorderBottom = false,
  onClick = undefined,
  onRight = true,
}) {
  return (
    <Container
      className={className}
      onClick={onClick}
      $noBorderBottom={noBorderBottom}
    >
      {label && <Title>{label}</Title>}
      <AddContainer $onRight={onRight}>{children}</AddContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue14};
  padding: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  border-radius: 2.5px;
  ${({ $noBorderBottom }) =>
    $noBorderBottom &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const AddContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ $onRight }) =>
    $onRight
      ? css`
          justify-content: flex-end;
          position: absolute;
          right: 5px;
        `
      : css`
          flex: 1;
        `}
`;

const Title = styled.h4`
  line-height: 22px;
  font-size: 18px;
  color: white;
  margin: 0;
  font-weight: normal;
  white-space: nowrap;
`;

export default TitleContainer;
