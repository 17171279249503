import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { notification } from "antd";
import { useStore } from "../../store";
import { currency_cents_to_euros, getUrlParams } from "../../utils";
import { ShowDeleteConfirm } from "../confirmModal";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import ActionsContainer from "../../react-ui/ActionsContainer";
import Delete from "../../react-ui/Icons/Delete";
import NumberCell from "../../react-ui/NumberCell";
import { numberSorter } from "../../../utils/numberSorter";
import { stringSorter } from "../../../utils/stringSorter";
import { postData } from "../../request/instance";
import { formatNumberString } from "../../../utils/formatNumberString";
import { getMarginCol } from "../../../constants/marginCol";

const selector = (state) => ({
  selectedProfil: state.selectedProfil,
  editProfil: state.editProfil,
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

function Actions({ value, handleDeleteProfil, isDeletable }) {
  return isDeletable ? (
    <ActionsContainer>
      <Delete
        onClick={() =>
          ShowDeleteConfirm(value.name, value.id, handleDeleteProfil)
        }
      />
    </ActionsContainer>
  ) : null;
}

function ProfilArray({
  setProfilDetailsStatus,
  profils,
  profilDetailsStatus,
  entity_work_name,
  configuration,
}) {
  const { editProfil, formToken, selectedProfil, isUsingMargin, calculMethod } =
    useStore(selector);
  const queryClient = useQueryClient();
  const pageEntityWork = parseInt(getUrlParams(), 10);

  const openNotification = (description) => {
    notification.error({
      description,
      placement: "bottom",
      duration: 10,
    });
  };

  const { mutate: removeProfils } = useMutation(
    (todo) => postData(formToken, "/profil_profession/delete", todo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Profils");
      },
      onError: (err) => {
        if (err.response?.data?.errors)
          openNotification(err.response.data.errors);
      },
    }
  );

  const [tableRowsData, setTableRowData] = useState([]);

  const isDeletable = (entityWorkId) => entityWorkId === pageEntityWork;

  useEffect(() => {
    const dataResult = [];
    let child;

    profils?.forEach((value) => {
      child = {
        key: value.id,
        id: value.id,
        name: value.name,
        hourly_rate: formatNumberString({
          str: currency_cents_to_euros(value.hourly_rate_cents),
          nbDecimal: 2,
          space: true,
        }),
        margin: value.margin_rate,
        subcontracting: value.subcontracting ? "Oui" : "Non",
        actions: (
          <Actions
            handleDeleteProfil={handleDeleteProfil}
            value={value}
            isDeletable={isDeletable(value.entity_work_id)}
          />
        ),
      };
      dataResult.push(child);
    });

    setTableRowData(dataResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profils]);

  useEffect(() => {}, [tableRowsData]);

  const openUpdateProfilProfession = (value) => {
    editProfil(value);
    setProfilDetailsStatus("update");
  };

  const handleDeleteProfil = (idProfil) => {
    setProfilDetailsStatus("empty");
    editProfil();
    removeProfils(
      JSON.stringify({ id: idProfil, entity_work_id: pageEntityWork })
    );
  };

  const profilColumns = [
    {
      title: "Intitulé",
      dataIndex: "name",
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: "Coût horaire",
      dataIndex: "hourly_rate",
      width: "90px",
      sorter: (a, b) => numberSorter(a.hourly_rate, b.hourly_rate),
      render: (value) => (value ? <NumberCell>{value} €</NumberCell> : null),
    },
    {
      title: "Sous-traitant",
      dataIndex: "subcontracting",
      width: "95px",
      hidden: !configuration.general.subcontracting,
      sorter: (a, b) => stringSorter(a.subcontracting, b.subcontracting),
    },
    getMarginCol({
      isUsingMargin,
      calculMethod,
      entity_work_name,
      configuration,
    }),
    {
      title: "",
      dataIndex: "actions",
      width: "20px",
    },
  ].filter((col) => !col.hidden);

  return (
    <TableContainer
      bordered={false}
      scrollY
      columns={profilColumns}
      dataSource={tableRowsData}
      pagination={false}
      isRowSelectable
      rowSelectableId={
        profilDetailsStatus === "update" ? selectedProfil?.id : undefined
      }
      onRow={(record) => {
        return {
          onClick: () => {
            openUpdateProfilProfession(
              profils.find((el) => el.id === record.id)
            );
          },
        };
      }}
    />
  );
}

export default ProfilArray;
