import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useStore } from "../../../../../store";
import useTodo from "../../../../../hookQuery/useTodo";
import TableContainer from "../../../../CostingMovementsCarrycots/TableContainer";
import useUpdateTodo from "../../../../../hookQuery/useUpdateTodo";
import { currency_cents_to_euros } from "../../../../../utils";
import Input from "../../../../../react-ui/Input";
import { formatNumberString } from "../../../../../../utils/formatNumberString";
import { stringToFloat } from "../../../../../../utils/stringToFloat";
import { eurosToCents } from "../../../../../../utils/currencyConverter";
import { getMarginCol } from "../../../../../../constants/marginCol";
import { coeffToMargin } from "../../../../../../utils/marginConverter";

const selector = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
function TableMovementChild({ parentId, movementsOpp, configuration }) {
  const { id_opportunity } = useParams();

  const { formToken, isUsingMargin, calculMethod } = useStore(selector);

  const [margin, setMargin] = useState(null);
  const [unitary_selling_HT, setUnitary_selling_HT] = useState(null);
  const [total_selling_HT, setTotal_selling_HT] = useState(null);

  const { data: vehicle_movement_opportunity } = useTodo(
    formToken,
    `/vehicle_movement_oprt/${id_opportunity}`,
    "VehicleMovementOprt"
  );

  const { data: profils_opportunity } = useTodo(
    formToken,
    `/profil_opportunity/${id_opportunity}`,
    "ProfilsOpportunity"
  );

  const { data: movement_profils_opportunity } = useTodo(
    formToken,
    `/profils_movement_opportunity/${id_opportunity}`,
    "MovementProfilsOpportunity"
  );

  const { mutate: calculateProfilMovement, isLoading: profilMovementLoading } =
    useUpdateTodo(
      formToken,
      `/profils_movement_opportunity/update_costing`,
      "MovementProfilsOpportunity",
      ["MovementsOpportunity", "OpportunityCotationLc", "Zones"],
      true
    );

  const {
    mutate: calculateVehicleMovement,
    isLoading: vehicleMovementLoading,
  } = useUpdateTodo(
    formToken,
    `/vehicle_movement_oprt/update_costing`,
    "VehicleMovementOprt",
    ["MovementsOpportunity", "OpportunityCotationLc", "Zones"],
    true
  );

  const pushDataInTable = useMemo(() => {
    const movementDataSource = [];
    vehicle_movement_opportunity
      ?.filter((vehicle) => vehicle.movement_opportunity_id === parentId)
      .forEach((item) => {
        movementDataSource.push({
          updateCalculate: calculateVehicleMovement,
          flag: "vehicle",
          id: item.id,
          name: item.name,
          quantity: formatNumberString({
            str: item.quantity,
            nbDecimal: 2,
            space: true,
          }),
          duration: formatNumberString({
            str: item.total_duration_hour,
            nbDecimal: 2,
            space: true,
          }),
          distance: formatNumberString({
            str: movementsOpp?.find(
              (movement) => movement.id === item.movement_opportunity_id
            )?.roundtrip_distance_km,
            nbDecimal: 2,
            space: true,
          }),
          cost_price_unitary: formatNumberString({
            str: currency_cents_to_euros(item.cost_price_cents),
            nbDecimal: 2,
            space: true,
          }),
          cost_price_total: formatNumberString({
            str: currency_cents_to_euros(item.total_cost_price_cents),
            nbDecimal: 2,
            space: true,
          }),
          margin: item.margin_rate,
          selling_price_total: formatNumberString({
            str: currency_cents_to_euros(item.total_selling_price_cents),
            nbDecimal: 2,
            space: true,
          }),
          selling_price_unitary: formatNumberString({
            str: currency_cents_to_euros(item.selling_price_cents),
            nbDecimal: 2,
            space: true,
          }),
        });
      });
    movement_profils_opportunity
      ?.filter(
        (movement_profil) =>
          movement_profil.movement_opportunity_id === parentId
      )
      .forEach((movement_child) => {
        movementDataSource.push({
          updateCalculate: calculateProfilMovement,
          flag: "profil_movement",
          id: movement_child.id,
          name: profils_opportunity?.find(
            (profil) => profil.id === movement_child.profil_opportunity_id
          )?.name,
          quantity: formatNumberString({
            str: movement_child.quantity,
            nbDecimal: 2,
            space: true,
          }),
          distance: formatNumberString({
            str: movementsOpp?.find(
              (movement) =>
                movement.id === movement_child.movement_opportunity_id
            )?.roundtrip_distance_km,
            nbDecimal: 2,
            space: true,
          }), // total distance du movement oprt parent
          duration: formatNumberString({
            str: movement_child.total_duration_hour / movement_child.quantity,
            nbDecimal: 2,
            space: true,
          }), // total duration du parent
          cost_price_total: formatNumberString({
            str: currency_cents_to_euros(movement_child.total_cost_price_cents),
            nbDecimal: 2,
            space: true,
          }),
          cost_price_unitary: formatNumberString({
            str: currency_cents_to_euros(movement_child.cost_price_cents),
            nbDecimal: 2,
            space: true,
          }), // hourly rate cents du profil references
          margin: movement_child.margin_rate,
          selling_price_unitary: formatNumberString({
            str: currency_cents_to_euros(movement_child.selling_price_cents),
            nbDecimal: 2,
            space: true,
          }),
          selling_price_total: formatNumberString({
            str: currency_cents_to_euros(
              movement_child.total_selling_price_cents
            ),
            nbDecimal: 2,
            space: true,
          }),
        });
      });
    return movementDataSource;
  }, [
    movement_profils_opportunity,
    profils_opportunity,
    movementsOpp,
    vehicle_movement_opportunity,
  ]);

  const MovementChildrenColumns = [
    {
      title: "Intitulé",
      dataIndex: "name",
    },
    {
      title: "Aller/Retour",
      children: [
        {
          title: "Distance",
          dataIndex: "distance",
          width: "11%",
          render: (value) => <div>{value} km</div>,
        },
        {
          title: "Durée",
          dataIndex: "duration",
          width: "11%",
          render: (value) => <div>{value} h</div>,
        },
      ],
    },
    {
      title: "Qt",
      dataIndex: "quantity",
      width: "5%",
    },
    {
      title: "Coût de revient",
      children: [
        {
          title: "Unitaire",
          dataIndex: "cost_price_unitary",
          width: "11%",
          align: "right",
          render: (value, globalValue) => (
            <div>
              {value} {globalValue.flag === "vehicle" ? "€" : "€/h"}
            </div>
          ),
        },
        {
          title: "Total",
          dataIndex: "cost_price_total",
          width: "9%",
          align: "right",
          render: (value) => <div>{value} €</div>,
        },
      ],
    },
    getMarginCol({
      configuration,
      isUsingMargin,
      calculMethod,
      hasSorter: false,
      render: (value, global) => (
        <Input
          fontSize="10px"
          textAlign="right"
          size="small"
          id={global.id}
          isMarginRate
          value={formatNumberString({
            str:
              margin?.[`${global.name}#${global.id}`] != null
                ? margin?.[`${global.name}#${global.id}`]
                : value,
            isCoeff: !isUsingMargin,
          })}
          onBlur={(e) => {
            if (margin?.[`${global.name}#${e.target.id}`]) {
              global.updateCalculate({
                opportunity_id: id_opportunity,
                id: global.id,
                margin_rate: coeffToMargin({
                  calculMethod,
                  isUsingMargin,
                  coeff: stringToFloat(e.target.value),
                }),
                has_change: true,
              });
            }
            // setMargin(null);
          }}
          onChange={(e) =>
            setMargin((v) => ({
              ...v,
              [`${global.name}#${e.target.id}`]: e.target.value,
            }))
          }
        />
      ),
    }),
    {
      title: "Prix de vente HT",
      children: [
        {
          title: "Unitaire",
          dataIndex: "selling_price_unitary",
          width: "11%",
          align: "right",
          render: (value, global) => (
            <Input
              fontSize="10px"
              textAlign="right"
              size="small"
              suffix={global.flag === "vehicle" ? "€" : "€/h"}
              id={global.id}
              value={
                unitary_selling_HT?.[`${global.name}#${global.id}`] || value
              }
              onBlur={(e) => {
                if (unitary_selling_HT?.[`${global.name}#${e.target.id}`]) {
                  global.updateCalculate({
                    opportunity_id: id_opportunity,
                    id: global.id,
                    selling_price_cents: eurosToCents(e.target.value),
                    has_change: true,
                  });
                }
                setUnitary_selling_HT(null);
              }}
              onChange={(e) =>
                setUnitary_selling_HT((v) => ({
                  ...v,
                  [`${global.name}#${e.target.id}`]: formatNumberString({
                    str: e.target.value,
                    nbDecimal: 2,
                  }),
                }))
              }
              onFocus={(e) =>
                setUnitary_selling_HT((v) => ({
                  ...v,
                  [`${global.name}#${e.target.id}`]: formatNumberString({
                    str: e.target.value,
                    nbDecimal: 2,
                  }),
                }))
              }
            />
          ),
        },
        {
          title: "Total",
          dataIndex: "selling_price_total",
          width: "11%",
          align: "right",
          render: (value, global) => (
            <Input
              fontSize="10px"
              textAlign="right"
              size="small"
              suffix="€"
              id={global.id}
              value={total_selling_HT?.[`${global.name}#${global.id}`] || value}
              onBlur={(e) => {
                if (total_selling_HT?.[`${global.name}#${e.target.id}`]) {
                  global.updateCalculate({
                    opportunity_id: id_opportunity,
                    id: global.id,
                    has_change: true,
                    total_selling_price_cents: eurosToCents(e.target.value),
                  });
                }
                setTotal_selling_HT(null);
              }}
              onChange={(e) =>
                setTotal_selling_HT((v) => ({
                  ...v,
                  [`${global.name}#${e.target.id}`]: formatNumberString({
                    str: e.target.value,
                    nbDecimal: 2,
                  }),
                }))
              }
              onFocus={(e) =>
                setTotal_selling_HT((v) => ({
                  ...v,
                  [`${global.name}#${e.target.id}`]: formatNumberString({
                    str: e.target.value,
                    nbDecimal: 2,
                  }),
                }))
              }
            />
          ),
        },
      ],
    },
  ];
  return (
    <TableContainer columns={MovementChildrenColumns} data={pushDataInTable} />
  );
}

export default TableMovementChild;
