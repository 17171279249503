import React from "react";
import { useParams } from "react-router-dom";
import { array, number, string } from "prop-types";
import styled from "styled-components";
import TitleContainer from "../../../react-ui/TitleContainer";
import MovementSection from "./MovementSection";
import Collapse from "../../../react-ui/Collapse";
import Panel from "../../../react-ui/Panel";
import CarrycotSection from "./CarrycotSection";

function CostingSection({
  formToken,
  zones,
  entity_work_id,
  faId,
  configuration,
  vocabulary,
  from,
}) {
  const { id_opportunity } = useParams();
  return (
    <Container>
      <TitleContainer label="Chiffrage des déplacements et nacelles" />
      <Wrapper>
        {configuration.costing.page_2.show_movements && (
          <Collapse defaultActiveKey={["movements"]}>
            <Panel header="Déplacements" key="movements" id="movements">
              <MovementSection
                zones={zones}
                formToken={formToken}
                id_opportunity={id_opportunity}
                entity_work_id={entity_work_id}
                faId={faId}
                from={from}
              />
            </Panel>
          </Collapse>
        )}
        {configuration.costing.page_2.show_carrycots && (
          <Collapse defaultActiveKey={["carrycots"]}>
            <Panel header="Nacelles" key="carrycots" id="carrycots">
              <CarrycotSection
                zones={zones}
                formToken={formToken}
                id_opportunity={id_opportunity}
                entity_work_id={entity_work_id}
                faId={faId}
                from={from}
              />
            </Panel>
          </Collapse>
        )}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 64%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

CostingSection.propTypes = {
  formToken: string.isRequired,
  entity_work_id: number.isRequired,
  zones: array,
  faId: number,
  configuration: array,
  vocabulary: array,
  from: string,
};

CostingSection.defaultProps = {
  zones: [],
  faId: undefined,
  configuration: [],
  vocabulary: [],
  from: "",
};

export default CostingSection;
