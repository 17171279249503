import { removeEmptyFields } from "./removeEmptyFields";

export const encodeUrlParams = (params) => {
  removeEmptyFields(params);
  if (
    params &&
    Object.getPrototypeOf(params) === Object.prototype &&
    Object.keys(params).length !== 0
  )
    return Object.entries(params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
  return "";
};
