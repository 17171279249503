import { Controller } from "stimulus"
const currentDataWorks = $(".dataWorksEntity")

$(document).ready(function () {
    if (currentDataWorks) {
        const idDataWorksUser = currentDataWorks.data("works");
        $(".dropdown-company-works").val(idDataWorksUser).change().select2({
            placeholder: "Sélectionnez un ou plusieurs métiers",
        })
    }
})