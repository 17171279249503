import { Tabs } from "antd";
import React from "react";
import styled from "styled-components";
import ProfilRules from "./ProfilRules";
import Rules from "./Rules";

function RulesHandler({ works, companyWorks, siteTypologies, entities }) {
  const items = [
    {
      label: "Règles",
      key: "1",
      children: (
        <Rules
          works={works}
          companyWorks={companyWorks}
          siteTypologies={siteTypologies}
          entities={entities}
        />
      ),
    },
    {
      label: "Profils",
      key: "2",
      children: <ProfilRules works={works} companyWorks={companyWorks} />,
    },
  ];

  return (
    <StyledTabs
      defaultActiveKey="1"
      type="card"
      style={{
        height: "calc(100% - 70px - 65.56px)",
        overflow: "auto",
      }}
      destroyInactiveTabPane
      items={items}
    />
  );
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
    width: 100%;
    display: flex;
  }
`;

export default RulesHandler;
