import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { getData } from "../request/instance";

export default function useTodo(formToken, path, name) {
  return useQuery(name, () => getData(formToken, path), {
    staleTime: 5 * 60 * 1000,
  });
}
