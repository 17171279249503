/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { deepEqual } from "../FilesImport";
import OpportunityLayoutDraggable from "./OpportunityLayoutDraggable";

function OpportunityTab({
  configuration,
  setConfiguration,
  management_special_TVA,
  HTML5Backend,
}) {
  const { mandatory, complement, default_values } = configuration.opportunity;
  const { opportunity_variable, opportunity } = configuration.api;
  const [isUpdated, setIsUpdated] = useState(false);

  const label = {
    mandatory: {
      opportunity_name: "Intitulé",
      opportunity_client: "Client facturé",
      opportunity_agency_concerned: "Agence concernée",
      client_contacts: "Contacts facturés",
      opportunity_response_type: "Type de réponse",
      opportunity_date: "Dates de débuts et de fin",
      opportunity_tva: "Gestion de la TVA",
      opportunity_limit_date: "Date limite de réponse",
    },
    complement: {
      company_provided: "Client presté",
      provided_customer_contacts: "Contacts prestés",
      company_decision_making: "Client décisionnaire",
      decision_maker_customer_contacts: "Contacts décisionnaires",
      provider_in_place: "Prestataire en place",
      company_contact: "Contacts",
      decision_date: "Date de décision du client",
      probability_success: "Probabilité de succès",
      probability_cancelation: "Probabilité d'abandon",
      type_of_site: "Typologies de site",
      opportunity_description: "Description",
      estimate_price: "Montant estimé",
    },
  };

  const variablesMandatoryText = {
    RESPONSE_TYPE: "opportunity_response_type",
    QUOTATION_END_DATE: "opportunity_date",
    QUOTATION_START_DATE: "opportunity_date",
    SUBMISSION_DATE: "opportunity_limit_date",
  };

  const variablesComplementText = {
    PROVIDERS: "provider_in_place",
    CUSTOMER_DECISION_DATE: "decision_date",
    SUCCESS_PROBABILITY: "probability_success",
    CANCELLATION_PROBABILITY: "probability_cancelation",
    SITE_TYPOLOGIES: "type_of_site",
    DESCRIPTION: "opportunity_description",
    ESTIMATED_AMOUNT: "estimate_price",
  };

  const variablesMandatoryTableau = {
    CLIENT_CONTACTS_WORK: "client_contacts",
  };

  const variablesComplementTableau = {
    PROVIDED_CUSTOMER_CONTACTS_WORK: "provided_customer_contacts",
    DECISION_MAKER_CUSTOMER_CONTACTS_WORK: "decision_maker_customer_contacts",
  };

  const [elementsList, setElementsList] = useState([]);

  const [elementsList2, setElementsList2] = useState([]);

  const [dataJson, setDataJson] = useState({
    mandatory,
    complement,
    default_values,
  });

  const [opportunity_variables, setOpportunity_variables] = useState({
    ...opportunity_variable,
  });

  const [opportunityTableau, setOpportunityTableau] = useState(opportunity);

  const mandatoryBlocs = [
    "opportunity_name",
    "opportunity_client",
    "opportunity_agency_concerned",
    "client_contacts",
    "opportunity_response_type",
    "opportunity_date",
    "opportunity_tva",
    "opportunity_limit_date",
  ];
  const complementBlocs = [
    "company_provided",
    "provided_customer_contacts",
    "company_decision_making",
    "decision_maker_customer_contacts",
    "provider_in_place",
    "company_contact",
    "decision_date",
    "probability_success",
    "probability_cancelation",
    "type_of_site",
    "opportunity_description",
    "estimate_price",
  ];

  const [forbidKeys, setForbidKeys] = useState({
    opportunity_tva: !management_special_TVA,
    provided_customer_contacts:
      elementsList2.includes("company_provided") ?? false,
    decision_maker_customer_contacts:
      elementsList2.includes("company_decision_making") ?? false,
  });

  const removeElement = useCallback(
    (source, type) => {
      if (source === undefined) return;
      const [col, line] = source.place.split("-");
      const block = line === "line_4" ? "blank" : "empty";
      if (type === "mandatory" || type === "mandatoryRequired") {
        if (source.col === 1) {
          setDataJson((prevState) => ({
            ...prevState,
            mandatory: {
              ...prevState.mandatory,
              [col]: {
                ...prevState.mandatory[col],
                [line]: {
                  ...prevState.mandatory[col][line],
                  name: [prevState.mandatory[col][line].name[0], block],
                  required: [prevState.mandatory[col][line].required[0], null],
                },
              },
            },
          }));
        } else if (source.col === 0 && source.isSplit) {
          setDataJson((prevState) => ({
            ...prevState,
            mandatory: {
              ...prevState.mandatory,
              [col]: {
                ...prevState.mandatory[col],
                [line]: {
                  ...prevState.mandatory[col][line],
                  name: [block, prevState.mandatory[col][line].name[1]],
                  required: [null, prevState.mandatory[col][line].required[1]],
                },
              },
            },
          }));
        } else {
          setDataJson((prevState) => ({
            ...prevState,
            mandatory: {
              ...prevState.mandatory,
              [col]: {
                ...prevState.mandatory[col],
                [line]: {
                  ...prevState.mandatory[col][line],
                  name: [block],
                  required: [],
                },
              },
            },
          }));
        }
      } else if (type === "complement") {
        if (source.col === 1) {
          setDataJson((prevState) => ({
            ...prevState,
            complement: {
              ...prevState.complement,
              [col]: {
                ...prevState.complement[col],
                [line]: {
                  ...prevState.complement[col][line],
                  name: [prevState.complement[col][line].name[0], block],
                },
              },
            },
          }));
        } else if (source.col === 0 && source.isSplit) {
          setDataJson((prevState) => ({
            ...prevState,
            complement: {
              ...prevState.complement,
              [col]: {
                ...prevState.complement[col],
                [line]: {
                  ...prevState.complement[col][line],
                  name: [block, prevState.complement[col][line].name[1]],
                },
              },
            },
          }));
        } else {
          setDataJson((prevState) => ({
            ...prevState,
            complement: {
              ...prevState.complement,
              [col]: {
                ...prevState.complement[col],
                [line]: {
                  ...prevState.complement[col][line],
                  name: [block],
                },
              },
            },
          }));
        }
      }

      setIsUpdated(false);
    },
    [dataJson, elementsList, elementsList2]
  );

  const getLists = useCallback(() => {
    const mandatoryList = [];
    const complementList = [];

    Object.keys(dataJson.mandatory).map((key) => {
      Object.keys(dataJson.mandatory[key]).map((key2) => {
        if (
          dataJson.mandatory[key][key2].name[0] !== "blank" &&
          dataJson.mandatory[key][key2].name[0] !== "empty"
        ) {
          mandatoryList.push(dataJson.mandatory[key][key2].name[0]);
        }
        if (
          dataJson.mandatory[key][key2].name[1] !== "blank" &&
          dataJson.mandatory[key][key2].name[1] !== "empty" &&
          dataJson.mandatory[key][key2].name[1] !== undefined
        ) {
          mandatoryList.push(dataJson.mandatory[key][key2].name[1]);
        }

        return null;
      });
      return mandatoryList;
    });

    Object.keys(dataJson.complement).map((key) => {
      Object.keys(dataJson.complement[key]).map((key2) => {
        if (
          dataJson.complement[key][key2].name[0] !== "blank" &&
          dataJson.complement[key][key2].name[0] !== "empty"
        ) {
          complementList.push(dataJson.complement[key][key2].name[0]);
        }
        if (
          dataJson.complement[key][key2].name[1] !== "blank" &&
          dataJson.complement[key][key2].name[1] !== "empty" &&
          dataJson.complement[key][key2].name[1] !== undefined
        ) {
          complementList.push(dataJson.complement[key][key2].name[1]);
        }
        return null;
      });
      return complementList;
    });

    setElementsList([
      ...mandatoryBlocs.filter((element) => !mandatoryList.includes(element)),
    ]);
    setElementsList2([
      ...complementBlocs.filter((element) => !complementList.includes(element)),
    ]);
  }, [
    complementBlocs,
    dataJson.complement,
    dataJson.mandatory,
    mandatoryBlocs,
  ]);

  const removeDuplicates = useCallback(() => {
    const mandatoryList = [];
    const complementList = [];

    let source = {};

    Object.keys(dataJson.mandatory).map((key) => {
      Object.keys(dataJson.mandatory[key]).map((key2) => {
        if (
          dataJson.mandatory[key][key2].name[0] !== "blank" &&
          dataJson.mandatory[key][key2].name[0] !== "empty"
        ) {
          if (!mandatoryList.includes(dataJson.mandatory[key][key2].name[0])) {
            mandatoryList.push(dataJson.mandatory[key][key2].name[0]);
          } else {
            source = {
              place: `${key}-${key2}`,
              col: 0,
              isSplit: dataJson.mandatory[key][key2].name.length > 1,
            };

            removeElement(source, "mandatory");
          }
        }
        if (
          dataJson.mandatory[key][key2].name[1] !== "blank" &&
          dataJson.mandatory[key][key2].name[1] !== "empty" &&
          dataJson.mandatory[key][key2].name[1] !== undefined
        ) {
          if (!mandatoryList.includes(dataJson.mandatory[key][key2].name[1])) {
            mandatoryList.push(dataJson.mandatory[key][key2].name[1]);
          } else {
            source = {
              place: `${key}-${key2}`,
              col: 1,
              isSplit: true,
            };

            removeElement(source, "mandatory");
          }
        }

        return null;
      });
      return mandatoryList;
    });

    Object.keys(dataJson.complement).map((key) => {
      Object.keys(dataJson.complement[key]).map((key2) => {
        if (
          dataJson.complement[key][key2].name[0] !== "blank" &&
          dataJson.complement[key][key2].name[0] !== "empty"
        ) {
          if (
            !complementList.includes(dataJson.complement[key][key2].name[0])
          ) {
            complementList.push(dataJson.complement[key][key2].name[0]);
          } else {
            source = {
              place: `${key}-${key2}`,
              col: 0,
              isSplit: dataJson.complement[key][key2].name.length > 1,
            };

            removeElement(source, "complement");
          }
        }
        if (
          dataJson.complement[key][key2].name[1] !== "blank" &&
          dataJson.complement[key][key2].name[1] !== "empty" &&
          dataJson.complement[key][key2].name[1] !== undefined
        ) {
          if (
            !complementList.includes(dataJson.complement[key][key2].name[1])
          ) {
            complementList.push(dataJson.complement[key][key2].name[1]);
          } else {
            source = {
              place: `${key}-${key2}`,
              col: 1,
              isSplit: true,
            };

            removeElement(source, "complement");
          }
        }
        return null;
      });
      return complementList;
    });

    const uniqueMandatory = Array.from(new Set(elementsList));
    const uniqueComplement = Array.from(new Set(elementsList2));

    setElementsList(uniqueMandatory);
    setElementsList2(uniqueComplement);

    setElementsList([
      ...mandatoryBlocs.filter((element) => !mandatoryList.includes(element)),
    ]);
    setElementsList2([
      ...complementBlocs.filter((element) => !complementList.includes(element)),
    ]);
  }, [
    complementBlocs,
    dataJson.complement,
    dataJson.mandatory,
    elementsList,
    elementsList2,
    mandatoryBlocs,
    removeElement,
  ]);

  const conditionTVA = useCallback(() => {
    if (!management_special_TVA) {
      let source = {};

      for (const key in dataJson.mandatory) {
        for (const key2 in dataJson.mandatory[key]) {
          if (dataJson.mandatory[key][key2].name[0] === "opportunity_tva") {
            source = {
              place: `${key}-${key2}`,
              col: 0,
              isSplit: dataJson.mandatory[key][key2].name.length > 1,
            };
            break;
          } else if (
            dataJson.mandatory[key][key2].name[1] === "opportunity_tva"
          ) {
            source = {
              place: `${key}-${key2}`,
              col: 1,
              isSplit: true,
            };
            break;
          }
        }
      }

      if (source.place !== undefined) {
        removeElement(source, "mandatory");
        setElementsList((prevState) => [...prevState, "opportunity_tva"]);
      }
    }
  }, [dataJson.mandatory, management_special_TVA, removeElement]);

  const removeContact = useCallback(
    (target) => {
      let source = {};

      for (const key in dataJson.complement) {
        for (const key2 in dataJson.complement[key]) {
          if (dataJson.complement[key][key2].name[0] === target) {
            source = {
              place: `${key}-${key2}`,
              col: 0,
              isSplit: dataJson.complement[key][key2].name.length > 1,
            };

            break;
          } else if (dataJson.complement[key][key2].name[1] === target) {
            source = {
              place: `${key}-${key2}`,
              col: 1,
              isSplit: true,
            };

            break;
          }
        }
      }

      if (source.place !== undefined) {
        removeElement(source, "complement");
        setElementsList2((prevState) => [...prevState, target]);
      }
    },
    [dataJson.complement, removeElement]
  );

  const checkClient = useCallback(() => {
    const elements = ["company_provided", "company_decision_making"];
    const targets = {
      provided_customer_contacts: false,
      decision_maker_customer_contacts: false,
    };
    let target = "";

    elements.forEach((element) => {
      if (element === "company_provided") {
        target = "provided_customer_contacts";
      } else {
        target = "decision_maker_customer_contacts";
      }
      if (elementsList2.includes(element)) {
        targets[target] = true;

        removeContact(target);
      } else {
        targets[target] = false;
      }
    });

    setForbidKeys((prevState) => ({
      ...prevState,
      provided_customer_contacts: targets.provided_customer_contacts,
      decision_maker_customer_contacts:
        targets.decision_maker_customer_contacts,
    }));
  }, [elementsList2, removeContact]);

  const conditionTextEditor = useCallback(() => {
    const mandatoryList = [];
    const complementList = [];

    Object.keys(variablesMandatoryText).forEach((key) => {
      if (elementsList.includes(variablesMandatoryText[key])) {
        mandatoryList.push(key);
      }
    });

    Object.keys(variablesComplementText).forEach((key) => {
      if (elementsList2.includes(variablesComplementText[key])) {
        complementList.push(key);
      }
    });

    let data = {};

    Object.keys(variablesMandatoryText).forEach((key) => {
      data = {
        ...data,
        [key]: !mandatoryList.includes(key),
      };
    });

    Object.keys(variablesComplementText).forEach((key) => {
      data = {
        ...data,
        [key]: !complementList.includes(key),
      };
    });

    setOpportunity_variables((prevState) => ({
      ...prevState,
      ...data,
    }));
  }, [elementsList, elementsList2]);

  const conditionTableauEditor = useCallback(() => {
    const list = [];

    Object.keys(variablesMandatoryTableau).forEach((key) => {
      if (elementsList.includes(variablesMandatoryTableau[key])) {
        list.push(key);
      }
    });

    Object.keys(variablesComplementTableau).forEach((key) => {
      if (elementsList2.includes(variablesComplementTableau[key])) {
        list.push(key);
      }
    });
    const data =
      list.length > 0
        ? Object.values(opportunityTableau)?.filter(
            (element) => !list?.includes(element)
          )
        : opportunityTableau;
    if (
      !deepEqual(Object.fromEntries(Object.entries(data)), opportunityTableau)
    ) {
      setOpportunityTableau(Object.fromEntries(Object.entries(data)));
    }
  }, [elementsList, elementsList2, configuration]);

  const newElement = useCallback(
    (col, old, item, type, isSplit) => {
      let newItem;
      const isMandatory = type === "mandatory";
      if (col === 1) {
        if (type === "mandatory") {
          newItem = {
            name: [old.name[0], item.content],
            required: [old.required[0], item.required ?? false],
          };
        } else {
          newItem = {
            name: [old.name[0], item.content],
          };
        }
      } else if (col === 0 && isSplit) {
        if (type === "mandatory") {
          newItem = {
            name: [item.content, old.name[1]],
            required: [item.required ?? false, old.required[1]],
          };
        } else {
          newItem = {
            name: [item.content, old.name[1]],
          };
        }
      } else {
        newItem = {
          name: [item.content],
          ...(isMandatory && { required: [item.required ?? false] }),
        };
      }
      if (
        old.name[col] !== undefined &&
        old.name[col] !== "blank" &&
        old.name[col] !== "empty"
      ) {
        if (type === "mandatory") {
          setElementsList((prevState) => [...prevState, old.name[col]]);
        } else {
          setElementsList2((prevState) => [...prevState, old.name[col]]);
        }
      }
      return newItem;
    },
    [elementsList, elementsList2]
  );

  const handleDragIn = useCallback(
    (place, col, isSplit, item, targetType) => {
      const { length } = Object.keys(opportunityTableau);
      const arrayInApiOpportunity = Object.values(opportunityTableau);
      let objectTmp = { ...opportunityTableau };
      if (item.type === "mandatory") {
        Object.keys(variablesMandatoryTableau).forEach((key) => {
          if (arrayInApiOpportunity.includes(key)) {
            return;
          }
          if (variablesMandatoryTableau[key] === item.content) {
            objectTmp = {
              ...objectTmp,
              [length]: key,
            };
          }
        });
      } else {
        Object.keys(variablesComplementTableau).forEach((key) => {
          if (arrayInApiOpportunity.includes(key)) {
            return;
          }
          if (variablesComplementTableau[key] === item.content) {
            objectTmp = {
              ...objectTmp,
              [length]: key,
            };
          }
        });
      }
      setOpportunityTableau(objectTmp);

      const [place_col, line] = place.split("-");

      if (targetType === "mandatoryRequired") {
        notification.error({
          message: "Impossible de déplacer cet élément",
          description: "Un élément obligatoire est présent dans la zone cible",
          duration: 5,
          placement: "bottom",
        });
        return;
      }

      const old = dataJson[targetType][place_col][line];
      const newItem = newElement(col, old, item, targetType, isSplit);

      if (targetType === "mandatory") {
        setDataJson((prevState) => ({
          ...prevState,
          mandatory: {
            ...prevState.mandatory,
            [place_col]: {
              ...prevState.mandatory[place_col],
              [line]: newItem,
            },
          },
        }));

        setElementsList((prevState) => {
          return prevState.filter((element) => element !== item?.content);
        });
      } else {
        setDataJson((prevState) => ({
          ...prevState,
          complement: {
            ...prevState.complement,
            [place_col]: {
              ...prevState.complement[place_col],
              [line]: newItem,
            },
          },
        }));

        setElementsList2((prevState) => {
          return prevState.filter((element) => element !== item?.content);
        });
      }

      setIsUpdated(true);
      removeElement(item.source, item.type);
    },
    [dataJson, elementsList, elementsList2]
  );

  const handleDragOut = useCallback(
    (item, type) => {
      if (type === "mandatoryRequired") {
        notification.error({
          message: "Action interdite",
          description:
            "Vous ne pouvez pas supprimer cet élément, il est obligatoire.",
          duration: 5,
          placement: "bottom",
        });
        return;
      }
      const objectOpportunity = { ...opportunityTableau };
      const arrayInApiOpportunity = Object.values(opportunityTableau);

      if (type === "mandatory") {
        // REMOVE ELEMENT FROM OPPORTUNITY TABLEAU MANDATORY
        const keyInArray = Object.keys(variablesMandatoryTableau).find(
          (key) => variablesMandatoryTableau[key] === item.content
        );
        if (arrayInApiOpportunity.includes(keyInArray)) {
          const index = Object.keys(objectOpportunity).find(
            (key) => objectOpportunity[key] === keyInArray
          );
          const newObjectOpportunity = Object.fromEntries(
            Object.entries(objectOpportunity)
              .filter((key) => key[0] !== index)
              .map(([key, value], i) => [i, value])
          );
          setOpportunityTableau(newObjectOpportunity);
        }
        removeElement(item.source, type);

        setElementsList((prevState) => {
          return prevState.filter((element) => element !== item?.content);
        });
        setElementsList((prevState) => [...prevState, item?.content]);
      } else {
        // REMOVE ELEMENT FROM OPPORTUNITY TABLEAU COMPLEMENT
        const keyInArray = Object.keys(variablesComplementTableau).find(
          (key) => variablesComplementTableau[key] === item.content
        );
        if (arrayInApiOpportunity.includes(keyInArray)) {
          const index = Object.keys(objectOpportunity).find(
            (key) => objectOpportunity[key] === keyInArray
          );
          const newObjectOpportunity = Object.fromEntries(
            Object.entries(objectOpportunity)
              .filter((key) => key[0] !== index)
              .map(([key, value], i) => [i, value])
          );
          setOpportunityTableau(newObjectOpportunity);
        }

        setTimeout(() => {
          removeElement(item.source, item.type);
        }, 5000);

        setElementsList2((prevState) => {
          return prevState.filter((element) => element !== item?.content);
        });

        setElementsList2((prevState) => [...prevState, item?.content]);
      }
    },
    [dataJson, elementsList, elementsList2]
  );

  useEffect(() => {
    getLists();
    removeDuplicates();
  }, []);

  useEffect(() => {
    conditionTVA();
  }, [management_special_TVA]);

  useEffect(() => {
    if (!deepEqual(configuration.opportunity, dataJson)) {
      setTimeout(
        () => {
          setConfiguration({
            ...configuration,
            opportunity: dataJson,
          });
        },
        isUpdated ? 300 : 0
      );
    }
  }, [dataJson]);

  useEffect(() => {
    if (
      !deepEqual(configuration.api.opportunity_variable, opportunity_variables)
    ) {
      setConfiguration({
        ...configuration,
        api: {
          ...configuration.api,
          opportunity_variable: opportunity_variables,
        },
      });
    }
  }, [opportunity_variables]);

  useEffect(() => {
    if (!deepEqual(configuration.api.opportunity, opportunityTableau)) {
      setConfiguration({
        ...configuration,
        api: {
          ...configuration.api,
          opportunity: opportunityTableau,
        },
      });
    }
  }, [opportunityTableau]);

  useEffect(() => {
    conditionTextEditor();
    conditionTableauEditor();
  }, [elementsList, elementsList2, configuration]);

  useEffect(() => {
    checkClient();
  }, [elementsList2]);

  return (
    <DndProvider backend={HTML5Backend} key={1}>
      <div>
        <OpportunityLayoutDraggable
          label={label.mandatory}
          dataJson={dataJson.mandatory}
          setDataJson={setDataJson}
          defaultTVA={dataJson.default_values}
          type="mandatory"
          elementsList={elementsList}
          setElementsList={setElementsList}
          headerName="Informations essentielles"
          handleDragIn={(place, col, isSplit, item, targetType) => {
            handleDragIn(place, col, isSplit, item, targetType);
          }}
          handleDragOut={(item, type) => {
            handleDragOut(item, type);
          }}
          removeElement={(source, type) => {
            removeElement(source, type);
          }}
          forbidKeys={forbidKeys}
        />
        <OpportunityLayoutDraggable
          label={label.complement}
          dataJson={dataJson.complement}
          setDataJson={setDataJson}
          type="complement"
          elementsList={elementsList2}
          setElementsList={setElementsList2}
          headerName="Informations complémentaires"
          handleDragIn={(place, col, isSplit, item, targetType) => {
            handleDragIn(place, col, isSplit, item, targetType);
          }}
          handleDragOut={(item, type) => {
            handleDragOut(item, type);
          }}
          removeElement={(source, type) => {
            removeElement(source, type);
          }}
          forbidKeys={forbidKeys}
        />
      </div>
    </DndProvider>
  );
}

export default OpportunityTab;
