import React from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { useStore } from "../../store";
import { Loading } from "../../loading";
import { currency_euros_to_cents, getUrlParams } from "../../utils";
import { postData } from "../../request/instance";
import ProfilForm from "./ProfilForm";
import TitleContainer from "../../react-ui/TitleContainer";
import {
  handleMarginKey,
  handlePictureKey,
} from "../../../utils/createFormData";
import AddButton from "../../react-ui/AddButton";
import Button from "../../react-ui/Button";

const selector = (state) => ({
  selectedProfil: state.selectedProfil,
  formToken: state.formToken,
  editProfil: state.editProfil,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
function ProfilDetails({
  profilDetailsStatus,
  setProfilDetailsStatus,
  profils,
  entity_work_name,
  configuration,
}) {
  const { selectedProfil, formToken, editProfil, isUsingMargin, calculMethod } =
    useStore(selector);
  const queryClient = useQueryClient();
  const { mutate: updateProfil, status } = useMutation(
    (todo) => postData(formToken, "/profil_profession/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Profils");
      },
    }
  );

  const { mutate: createProfil, isLoading } = useMutation(
    (todo) => postData(formToken, "/profil_profession/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Profils");
      },
      onSuccess: (payload) => {
        editProfil(payload);
        setProfilDetailsStatus("update");
      },
      onError: () => setProfilDetailsStatus("empty"),
    }
  );

  const handleRegisterProfilProfession = (e) => {
    const formatableKeys = [
      "picture",
      "hourly_rate_cents",
      "subcontracting",
      "margin_rate",
    ];
    const formData = new FormData();
    Object.keys(e).forEach(
      (key) =>
        !formatableKeys.includes(key) &&
        formData.append(
          `profil_profession[${key}]`,
          e[key] !== undefined && e[key] !== null ? e[key] : ""
        )
    );
    if (e.hourly_rate_cents) {
      formData.append(
        "profil_profession[hourly_rate_cents]",
        currency_euros_to_cents(e.hourly_rate_cents)
      );
    }
    formData.append(
      "profil_profession[subcontracting]",
      e.subcontracting ? e.subcontracting : false
    );
    if (e.picture) {
      handlePictureKey({
        formData,
        picture: e.picture,
        keyName: "profil_profession",
      });
    }
    handleMarginKey({
      formData,
      margin_rate: e.margin_rate,
      keyName: "profil_profession",
      isUsingMargin,
      calculMethod,
    });
    formData.append("profil_profession[entity_work_id]", getUrlParams());
    if (profilDetailsStatus === "update") {
      formData.append("id", selectedProfil.id);
      updateProfil(formData);
    }
    if (profilDetailsStatus === "create") {
      createProfil(formData);
    }
  };

  const onSubmit = (e) => {
    handleRegisterProfilProfession(e);
  };

  const isShowing =
    profilDetailsStatus === "update" &&
    selectedProfil.entity_work_id !== parseInt(getUrlParams(), 10);

  const isUpdating = profilDetailsStatus === "update" && !isShowing;

  const getName = () => {
    if (profilDetailsStatus !== "update") return "";
    const curItem = profils.find((el) => el.id === selectedProfil.id);
    if (!curItem?.name) return "";
    return ` : ${curItem.name}`;
  };

  return (
    <Container>
      <TitleContainer label={`Détails du profil${getName()}`}>
        {profilDetailsStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={() => setProfilDetailsStatus("empty")}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="profil-form"
              loading={isLoading}
            />
          </>
        )}
        <Loading status={status} />
      </TitleContainer>
      {profilDetailsStatus === "empty" && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez un profil pour afficher les détails
          </p>
        </div>
      )}
      {profilDetailsStatus !== "empty" && (
        <Wrapper>
          <ProfilForm
            updateOnChange={isUpdating}
            initialProfil={
              profilDetailsStatus === "update" ? selectedProfil : undefined
            }
            onSubmit={onSubmit}
            isShowing={isShowing}
            entity_work_name={entity_work_name}
            configuration={configuration}
            entityWorkId={getUrlParams()}
          />
        </Wrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default ProfilDetails;
