import React from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import Label from "../../../../Form/Label";
import Select from "../../../../react-ui/Select";
import { postData } from "../../../../request/instance";
import { useStore } from "../../../../store";

const selector = (state) => ({
  formToken: state.formToken,
});

function LeaserSelect({
  selectedLeaserId,
  rentalOffers,
  setSelectedLeaser,
  rentingOpp,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();

  const { mutate: updateRentalOfferOpp } = useMutation(
    (todo) => postData(formToken, "/rental_offer_opportunity/update", todo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Services");
        queryClient.invalidateQueries("RentingOpportunity");
        queryClient.invalidateQueries("OpportunityCotationLc");
      },
    }
  );

  const handleLeaserChange = (e) => {
    if (e) {
      setSelectedLeaser(rentalOffers.find((el) => el.id === e));
      updateRentalOfferOpp(
        {
          id: rentingOpp.rental_offer_opportunity.id,
          rental_offer_opportunity: {
            rental_offer_id: e,
          },
        },
        {
          onError: () => {
            setSelectedLeaser(
              rentalOffers.find(
                (el) =>
                  el.id === rentingOpp.rental_offer_opportunity.rental_offer_id
              )
            );
          },
        }
      );
    }
  };

  return (
    <>
      <Label label="Leaser" />
      <StyledSelect
        value={selectedLeaserId}
        options={rentalOffers}
        onChange={handleLeaserChange}
      />
    </>
  );
}

const StyledSelect = styled(Select)`
  width: 100%;
`;

export default LeaserSelect;
