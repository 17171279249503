import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { notification } from "antd";
import { documentsColumns } from "../../Admin-cf/DocumentsFolders/Documents/DocumentsArray";
import TableTree from "../../react-ui/TableTree";
import { getData, postData } from "../../request/instance";
import { fetchDocumentsUrl } from "../../../utils/fetchDocumentsUrl";
import FileColumn from "../../Admin-cf/DocumentsFolders/Documents/FileColumn";
import {
  findIsRootItem,
  findRootItemIdx,
  getNewRootItem,
  reconstructRecord,
} from "../../react-ui/TableTree/treeutils";
import ItemTitle from "./ItemTitle";
import Delete from "../../react-ui/Icons/Delete";
import BaseIcon from "../../react-ui/Icons/BaseIcon";
import AddFolderFile from "./AddFolderFile";
import { createFile } from "../../../utils/createFile";
import { useStore } from "../../store";
import Warning from "../../react-ui/Icons/Warning";
import Info from "../../react-ui/Icons/Info";

const folderIcon = ({ expanded }) =>
  expanded ? (
    <FolderIcon className="fa-duotone fa-folder-open" />
  ) : (
    <FolderIcon className="fa-duotone fa-folder" />
  );

export const constructDocumentRow = ({
  document,
  folderKey,
  folders,
  siteTypologies,
}) => {
  const docFolderIds =
    document.document_folders?.map((el) => el.folder_id) || [];
  const docFolders = folders?.filter((fold) => docFolderIds?.includes(fold.id));
  const docTypoIds =
    document.site_typology_documents?.map((el) => el.site_typology_id) || [];
  const docTypos =
    docTypoIds.length > 0
      ? siteTypologies
          .filter((st) => docTypoIds?.includes(st.id))
          .map((st) => st.typology)
          .join(", ")
      : "Toutes les typologies";

  let annexName;

  if (document.material_opportunities?.length > 0)
    annexName = document.material_opportunities
      ?.map((matOpp) => matOpp.name)
      ?.join(", ");

  if (document.prestation_opportunities?.length > 0)
    annexName = document.prestation_opportunities
      ?.map((pres) => pres.name)
      ?.join(", ");

  return {
    ...document,
    folders: docFolders,
    site_typologies: docTypos,
    type: "document",
    additionalName:
      annexName || document.is_valid === false ? (
        <AdditionalContainer $warning={document.is_valid === false}>
          {document.is_valid === false && (
            <StyledWarning title="La date de validité sera expirée pour la date limite de réponse" />
          )}
          {annexName && <StyledInfo title={annexName} />}
        </AdditionalContainer>
      ) : undefined,
    key: `${folderKey ? `${folderKey}-` : ""}document-${document.id}`,
    icon: (
      <FileColumn
        file={document.file || document.file_url}
        name={document.name}
        contentType={document.content_type}
      />
    ),
    isLeaf: true,
  };
};

const selector = (state) => ({
  setNewFile: state.setNewFile,
});

function SATableTree({
  data,
  loading,
  setFilters,
  folders,
  siteTypologies,
  columnsToShow,
  opportunity_ids,
  setData,
  formToken,
  drop,
  backgroundColor,
  draggableProps = {},
  documentsToHide,
  foldersToOpen,
  companyWorkIds,
  createNewFolder,
  createNewFile,
  tableHeight = 410,
  editable = false,
  withFile = true,
  withSearch = false,
  withAnnex = false,
  onAnnexAdd,
  sa_template_id,
  onClickSearchIcon,
  constructNodeRow,
  onBlur,
  onDelete,
  checkProps = {},
  selectUnselectChildren,
  fromSA = false,
  offer_id,
  fromOffer = false,
  withOpportunityFolders = false,
  onFolderOpportunityAdd,
  workName,
}) {
  const { setNewFile } = useStore(selector);
  const queryClient = useQueryClient();
  const { mutate: createDocument, isLoading } = useMutation((todo) =>
    postData(formToken, "/document/create", todo)
  );
  const [expandedKeys, setExpandedKeys] = useState([]);

  const newFolderId = useRef(0);
  const inputFile = useRef();
  const newFileProps = useRef();
  const handleFilters = useCallback(
    (_, tableFilters) => {
      setFilters(tableFilters);
    },
    [setFilters]
  );

  const tableProps = useMemo(
    () => ({
      bordered: false,
      onChange: handleFilters,
      dataSource: [],
      pagination: false,
    }),
    [handleFilters]
  );

  const appendChildren = useCallback(
    ({ record, children }) => {
      setData((oldData) => {
        const newData = [...oldData];
        const isRootItem = findIsRootItem(record);
        const folderIdx = findRootItemIdx(oldData, record);
        if (isRootItem) {
          newData[folderIdx] = reconstructRecord({
            oldRecord: { ...newData[folderIdx], children },
          });
        } else {
          newData[folderIdx] = reconstructRecord({
            oldRecord: getNewRootItem({
              item: newData[folderIdx],
              record,
              deleteItem: false,
              recordsToReplace: children,
            }),
          });
        }
        return newData;
      });
    },
    [setData]
  );

  const loadChildrenFromSA = useCallback(
    async (record) => {
      const params = {
        search: undefined,
        simplified_application: true,
      };
      if (record.type === "folder") {
        params.folder_id = record.fromTemplate ? record.folder_id : record.id;
        params.opportunity_ids = opportunity_ids;
      }
      if (record.type === "folder-offer") {
        params.offer_id = record.id;
        params.opportunity_ids = opportunity_ids[0] ? [opportunity_ids[0]] : [];
      }
      if (record.type === "folder-opportunity") {
        params.only_opportunity = true;
        params.opportunity_ids = [record.id];
      }
      const documents = await getData(formToken, fetchDocumentsUrl(params));
      return documents.map((document) =>
        constructDocumentRow({
          document,
          folderKey: record.key,
          folders,
          siteTypologies,
        })
      );
    },
    [folders, formToken, opportunity_ids, siteTypologies]
  );

  const loadChildrenFromSearch = useCallback(
    async (record) => {
      const params = {
        search: undefined,
        folder_id: record.id,
        sa_template_id,
      };
      const documents = await getData(formToken, fetchDocumentsUrl(params));
      return documents.map((document) =>
        constructDocumentRow({
          document,
          folderKey: record.key,
          folders,
          siteTypologies,
        })
      );
    },
    [folders, formToken, siteTypologies, sa_template_id]
  );

  const loadChildrenFromTemplate = useCallback(
    async (record) => {
      const actualNode = await getData(
        formToken,
        `/sa_template_node/show?id=${record.id}${
          fromSA && opportunity_ids
            ? opportunity_ids
                .map((opportunity_id) => `&opportunity_ids[]=${opportunity_id}`)
                .join()
            : ""
        }${fromSA && offer_id ? `&offer_id=${offer_id}` : ""}`
      );
      const children = [];
      actualNode.children.forEach((node) => {
        const formattedNode = constructNodeRow(node);
        if (formattedNode) children.push(formattedNode);
      });
      if (
        record.type === "folder-new" ||
        record.type === "opportunity_folders" ||
        fromSA
      )
        return children;
      return [
        {
          id: `all-folder-files-${record.id}`,
          name: "Tous les documents du dossier",
          key: `all-files-${record.id}`,
          type: "all-files",
          isLeaf: true,
          notEdittable: true,
          notDelettable: true,
          icon: <BaseIcon className="fa-duotone fa-files" />,
          elementDraggableProps: {
            ...draggableProps,
            draggable: false,
          },
        },
        ...children,
      ];
    },
    [
      formToken,
      constructNodeRow,
      draggableProps,
      fromSA,
      opportunity_ids,
      offer_id,
    ]
  );

  const onLoadData = useCallback(
    async (record, childrenToAppend = []) => {
      if (record.children) return Promise.resolve();
      let children = [];
      if (fromSA && record.type !== "folder-new") {
        children = children.concat(await loadChildrenFromSA(record));
      }
      if (constructNodeRow && record.fromTemplate) {
        children = children.concat(await loadChildrenFromTemplate(record));
      }
      if (sa_template_id) {
        children = children.concat(await loadChildrenFromSearch(record));
      }
      appendChildren({
        record,
        children: childrenToAppend.concat(children),
      });
      if (selectUnselectChildren) {
        selectUnselectChildren({ node: record, fromOldData: true });
      }
      return Promise.resolve();
    },
    [
      appendChildren,
      constructNodeRow,
      loadChildrenFromSA,
      loadChildrenFromTemplate,
      sa_template_id,
      loadChildrenFromSearch,
      selectUnselectChildren,
      fromSA,
    ]
  );

  const openAndLoadFolder = useCallback((key) => {
    const nodeArr = document.getElementsByClassName(key);
    if (nodeArr.length > 0 && nodeArr[0]) {
      const antTreeSwitchOpen = nodeArr[0].getElementsByClassName(
        "ant-tree-switcher_close"
      );
      if (antTreeSwitchOpen.length > 0) {
        antTreeSwitchOpen[0].click();
      }
    }
  }, []);

  useEffect(() => {
    if (foldersToOpen) {
      setExpandedKeys(foldersToOpen.map((el) => el.key));
    }
  }, [foldersToOpen]);

  const setFolderInTree = useCallback(
    async ({ fromHeader, newFolder, record }) => {
      if (fromHeader) {
        setData((oldData) => {
          const newData = [...oldData];
          newData.unshift(newFolder);
          return newData;
        });
        return;
      }
      if (!record.children) {
        await onLoadData(record, createNewFolder ? [] : [newFolder]);
      } else {
        appendChildren({
          record,
          children: [newFolder, ...record.children],
        });
      }
      openAndLoadFolder(record.key);
    },
    [appendChildren, onLoadData, openAndLoadFolder, setData, createNewFolder]
  );

  const addFolder = useCallback(
    ({ event, fromHeader = false, record }) => {
      event.stopPropagation();
      const newFolder = {
        name: "Nouveau dossier",
        icon: folderIcon,
        isLeaf: false,
        children: [],
      };
      if (createNewFolder) {
        createNewFolder({ newFolder, setFolderInTree, fromHeader, record });
      } else {
        newFolder.type = "folder-new";
        newFolder.key = `folder-new-${newFolderId.current}`;
        newFolderId.current += 1;
        setFolderInTree({ fromHeader, newFolder, record });
      }
    },
    [createNewFolder, setFolderInTree]
  );

  const setFileInTree = useCallback(
    async (document, templateNode = null) => {
      const newFile = constructDocumentRow({
        document,
        folderKey: newFileProps.current?.record?.key,
        folders,
        siteTypologies,
      });
      if (templateNode) {
        newFile.id = templateNode.id;
      }
      setNewFile(newFile);
      if (newFileProps.current.fromHeader) {
        setData((oldData) => {
          const newData = [...oldData];
          newData.unshift(newFile);
          return newData;
        });
        return;
      }
      if (newFileProps.current?.record.type === "empty-file") {
        setData((oldData) => {
          const newData = [...oldData];
          const isRootItem = findIsRootItem(newFileProps.current?.record);
          const rootItemIdx = findRootItemIdx(
            oldData,
            newFileProps.current?.record
          );
          if (isRootItem) {
            newData.splice(rootItemIdx, 1, newFile);
          } else {
            newData[rootItemIdx] = getNewRootItem({
              item: newData[rootItemIdx],
              deleteItem: false,
              record: newFileProps.current?.record,
              recordToReplace: newFile,
            });
          }
          return newData;
        });
        return;
      }
      if (!newFileProps.current.record.children) {
        await onLoadData(
          newFileProps.current.record,
          (!fromOffer &&
            newFileProps.current.record.type === "folder-opportunity") ||
            (fromOffer &&
              newFileProps.current.record.type === "folder-offer") ||
            createNewFile
            ? []
            : [newFile]
        );
      } else {
        appendChildren({
          record: newFileProps.current.record,
          children: [newFile, ...newFileProps.current.record.children],
        });
      }
      openAndLoadFolder(newFileProps.current.record.key);
    },
    [
      appendChildren,
      folders,
      onLoadData,
      openAndLoadFolder,
      setData,
      setNewFile,
      siteTypologies,
      createNewFile,
      fromOffer,
    ]
  );

  const addFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    const formData = new FormData();

    const fileSize = Math.ceil(file.size / 1024 / 1024);
    if (fileSize >= 75) {
      notification.error({
        description: "Un fichier ne peut pas dépasser 75Mo",
        placement: "bottom",
        duration: 5,
      });
      return;
    }
    formData.append(`documents[][name]`, file.name);
    formData.append(
      `documents[][file]`,
      createFile([file], file.name, {
        type: file.type,
      })
    );
    if (!fromOffer && opportunity_ids)
      formData.append(`documents[][opportunity_id]`, opportunity_ids[0]);
    if (fromOffer) formData.append(`documents[][offer_id]`, offer_id);
    if (!!companyWorkIds && companyWorkIds.length > 0) {
      companyWorkIds.forEach((compWId) =>
        formData.append(
          `documents[][company_work_documents_attributes][][company_work_id]`,
          compWId
        )
      );
    }
    createDocument(formData, {
      onSettled: () => {
        queryClient.invalidateQueries(
          // opportunity_id ? ["Documents", { opportunity_id }] : "Documents"
          "Documents"
        );
      },
      onSuccess: async (payload) => {
        if (createNewFile) {
          createNewFile({
            document: payload[0],
            record: newFileProps.current?.record,
            setFileInTree,
          });
        } else {
          setFileInTree(payload[0]);
        }
      },
    });
  };

  const openFileBrowser = useCallback(({ e, record, fromHeader = false }) => {
    e.stopPropagation();
    newFileProps.current = { record, fromHeader };
    inputFile.current.click();
  }, []);

  const setFoldersAndFilesInTree = useCallback(
    async ({ fromHeader, newFoldersFiles = [], record }) => {
      if (fromHeader) {
        setData((oldData) => {
          const newData = [...oldData];
          newFoldersFiles.forEach((node) => {
            newData.unshift(node);
          });
          return newData;
        });
        return;
      }
      if (!record.children) {
        await onLoadData(record, createNewFolder ? [] : newFoldersFiles);
      } else {
        appendChildren({
          record,
          children: newFoldersFiles.concat([...record.children]),
        });
      }
      openAndLoadFolder(record.key);
    },
    [appendChildren, onLoadData, openAndLoadFolder, setData, createNewFolder]
  );

  const columns = useMemo(() => {
    let colsToRet = [
      {
        title: "Nom de fichier/dossier",
        dataIndex: "name",
        render: (name, record) => (
          <ItemTitle
            id={record.id}
            name={name}
            isLeaf={record.isLeaf}
            type={record.type}
            color={record.color}
            setData={setData}
            recordKey={record.key}
            parentKeys={record.parentKeys}
            disabled={!editable || record.notEdittable}
            onBlur={onBlur}
            additionalName={record.additionalName}
            isDraggable={Object.keys(draggableProps)?.length > 0}
          />
        ),
      },
    ].concat(
      documentsColumns({
        folders,
        siteTypologies,
        columnsToShow,
      })
    );
    if (editable) {
      let addFolderFileWidth = 30;
      if (withFile) addFolderFileWidth += 30;
      if (withSearch) addFolderFileWidth += 60;
      if (withAnnex && workName !== "guarding") addFolderFileWidth += 60;
      if (withOpportunityFolders) addFolderFileWidth += 30;
      colsToRet = colsToRet.concat([
        {
          title: (
            <AddFolderFile
              workName={workName}
              isHeader
              addFolder={(event) => addFolder({ event, fromHeader: true })}
              openFileBrowser={(e) => openFileBrowser({ e, fromHeader: true })}
              isLoading={isLoading}
              withFile={withFile}
              withSearch={withSearch}
              withAnnex={withAnnex}
              withOpportunityFolders={withOpportunityFolders}
              fromSA={fromSA}
              onFolderOpportunityAdd={(event) => {
                if (onFolderOpportunityAdd)
                  onFolderOpportunityAdd({
                    event,
                    fromHeader: true,
                    setFoldersAndFilesInTree,
                  });
              }}
              onAnnexAdd={(event, type) => {
                if (onAnnexAdd)
                  onAnnexAdd({
                    event,
                    appendChildren,
                    fromHeader: true,
                    setFoldersAndFilesInTree,
                    type,
                  });
              }}
              onClickSearchIcon={({ event, from }) => {
                if (onClickSearchIcon)
                  onClickSearchIcon({
                    event,
                    from,
                    fromHeader: true,
                    setFoldersAndFilesInTree,
                  });
              }}
            />
          ),
          dataIndex: "addFolderFile",
          className: "addFolderFile",
          width: `${addFolderFileWidth}px`,
          render: (_, record) =>
            record.type.includes("folder") || record.type === "empty-file" ? (
              <AddFolderFile
                workName={record.workName}
                withFolder={record.type !== "empty-file"}
                addFolder={(event) => addFolder({ event, record })}
                openFileBrowser={(e) => openFileBrowser({ e, record })}
                isLoading={isLoading}
                withFile={withFile}
                withSearch={withSearch}
                withAnnex={withAnnex}
                fromSA={fromSA}
                withOpportunityFolders={withOpportunityFolders}
                onFolderOpportunityAdd={(event) => {
                  if (onFolderOpportunityAdd)
                    onFolderOpportunityAdd({
                      event,
                      record,
                      setFoldersAndFilesInTree,
                    });
                }}
                onAnnexAdd={(event, type) => {
                  if (onAnnexAdd)
                    onAnnexAdd({
                      event,
                      record,
                      appendChildren,
                      setFoldersAndFilesInTree,
                      type,
                    });
                }}
                onClickSearchIcon={({ event, from }) => {
                  if (onClickSearchIcon)
                    onClickSearchIcon({
                      event,
                      from,
                      record,
                      setFoldersAndFilesInTree,
                    });
                }}
              />
            ) : null,
        },
        {
          title: "",
          dataIndex: "delete",
          className: "delete",
          width: "30px",
          render: (_, record) =>
            !record.notDelettable && (
              <DeleteContainer>
                <Delete
                  onClick={(e) => {
                    e.stopPropagation();
                    setData((oldData) => {
                      const newData = [...oldData];
                      const isRootItem = findIsRootItem(record);
                      const rootItemIdx = findRootItemIdx(oldData, record);
                      if (isRootItem) {
                        newData.splice(rootItemIdx, 1);
                      } else {
                        newData[rootItemIdx] = getNewRootItem({
                          item: newData[rootItemIdx],
                          record,
                        });
                      }
                      return newData;
                    });
                    if (onDelete) onDelete({ id: record.id });
                  }}
                />
              </DeleteContainer>
            ),
        },
      ]);
    }
    return colsToRet;
  }, [
    workName,
    folders,
    siteTypologies,
    columnsToShow,
    editable,
    setData,
    addFolder,
    openFileBrowser,
    isLoading,
    withFile,
    onBlur,
    onDelete,
    withSearch,
    onClickSearchIcon,
    draggableProps,
    setFoldersAndFilesInTree,
    withAnnex,
    onAnnexAdd,
    withOpportunityFolders,
    onFolderOpportunityAdd,
    appendChildren,
    fromSA,
  ]);

  const treeProps = useMemo(
    () => ({
      multiple: true,
      height: tableHeight,
      showLine: true,
      loadData: onLoadData,
      expandedKeys,
      onExpand: (newExpandedKeys, { expanded, node }) =>
        setExpandedKeys(
          expanded
            ? [...newExpandedKeys, node.key]
            : newExpandedKeys.filter((key) => key !== node.key)
        ),
      ...checkProps,
    }),
    [onLoadData, expandedKeys, tableHeight, checkProps]
  );

  return (
    <>
      <input
        type="file"
        id="file"
        ref={inputFile}
        onClick={(event) => {
          // eslint-disable-next-line no-param-reassign
          event.target.value = null;
        }}
        onChange={addFile}
        style={{ display: "none" }}
      />
      <TableTree
        columns={columns}
        data={data}
        tableProps={tableProps}
        treeProps={treeProps}
        loading={loading}
        drop={drop}
        backgroundColor={backgroundColor}
        draggableProps={draggableProps}
        nodesToHide={documentsToHide}
      />
    </>
  );
}

const DeleteContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const FolderIcon = styled(BaseIcon)`
  color: ${({ theme }) => theme.colors.blue11};
`;

const AdditionalContainer = styled.div`
  ${({ $warning }) =>
    !$warning &&
    css`
      margin-left: auto;
    `}
  display: flex;
  gap: 2px;
  align-items: baseline;
`;

const StyledWarning = styled(Warning)`
  font-size: 12px;
`;

const StyledInfo = styled(Info)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.blue05};
`;

export default SATableTree;
