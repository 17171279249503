
import { formatNumberString } from "../../../../utils/formatNumberString";

export const if_record_exist = ({setter, valueChecked, record, checked}) => {
    setter(
        valueChecked.map((item) => {
          if (item.id === record.id) {
            return {
              ...item,
              check: checked,
            };
          }
          return item;
        })
      );
}

export const add_record = ({setter, valueChecked, record, checked}) => {
    setter([
        ...valueChecked,
        {
          id: record.id,
          check: checked,
        },
      ]);
}

export const add_data_in_record = ({setter, valueData, record, e}) => {
    setter([
        ...valueData,
        {
          id: record.id,
          quantity_associated: formatNumberString({
            str: e.target.value,
          }),
        },
      ]);
}
