import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["previous"]

  allSelected() {
    let checkWorkPositions = document.getElementsByClassName('work_position_checked')
    let i=0
    while (i<checkWorkPositions.length){
      checkWorkPositions[i].checked=true
      i++;
    }
  }

  allUnselected() {
    let checkWorkPositions = document.getElementsByClassName('work_position_checked')
    let i=0
    while (i<checkWorkPositions.length){
      checkWorkPositions[i].checked=false
      i++;
    }
  }
}
