/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState, useRef } from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { getData, postData } from "../request/instance";
import OfferResult from "./preview-result/OfferResult";
import OpportunityResult from "./preview-result/OpportunityResult";
import Loader from "../react-ui/Loader";

function Preview({ search, loadingSearchQuery, vocabulary_application }) {
  return (
    <>
      {loadingSearchQuery ? (
        <StyledPreview>
          <Loader />
        </StyledPreview>
      ) : (
        <StyledPreview>
          <OfferResult
            offers_result={search?.offers_result}
            configuration={search?.configuration}
            vocabulary_application={vocabulary_application}
          />
          <OpportunityResult
            opportunities_result={search?.opportunities_result}
            configuration={search?.configuration}
            vocabulary_application={vocabulary_application}
          />
        </StyledPreview>
      )}
    </>
  );
}

function SearchBox({ token, vocabulary_application }) {
  const [searchQuery, setSearchQuery] = useState("");
  const {
    data: search,
    refetch: refetchSearch,
    isLoading: loadingSearchQuery,
  } = useQuery(
    "Search",
    () => getData(token, `/search?search_query=${searchQuery}`),
    {
      enabled: searchQuery.length >= 3, // Enable query when searchQuery length is at least 3
      refetchOnWindowFocus: false, // Disable refetch on window focus to avoid unwanted refetches
      refetchInterval: false, // Disable refetch interval to avoid unwanted refetches
      refetchIntervalInBackground: false, // Disable refetch interval in background to avoid unwanted refetches
      retry: false, // Disable retry on error to avoid unwanted refetches
    }
  );

  useEffect(() => {
    let timeoutId;
    if (searchQuery.length >= 3) {
      timeoutId = setTimeout(() => {
        refetchSearch();
      }, 300); // Delay query by 500ms to avoid too many requests
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [refetchSearch, searchQuery]);

  const searchInputRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (event) => {
      // if (event.key === "/") {
      //   event.preventDefault();
      //   searchInputRef.current.focus();
      // }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const handleSearch = (inputValue) => {
    setSearchQuery(inputValue.target.value);
  };
  return (
    <StyledSearchBox>
      <Search
        className="search__box"
        allowClear
        ref={searchInputRef}
        onChange={(e) => handleSearch(e)}
        placeholder={vocabulary_application.search_bar.placeholder}
      />
      {searchQuery.length >= 3 && (
        <Preview
          search={search}
          loadingSearchQuery={loadingSearchQuery}
          vocabulary_application={vocabulary_application}
        />
      )}
    </StyledSearchBox>
  );
}

const StyledSearchBox = styled.div`
  width: 700px;
  margin-left: 2rem;
  margin-right: 3rem;
  margin-top: 1.4em;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1342px) and (max-width: 1542px) {
    width: 550px;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  @media screen and (min-width: 1131px) and (max-width: 1341px) {
    width: 400px;
    margin-left: 2rem;
    margin-right: 1rem;
  }
  @media screen and (min-width: 1100px) and (max-width: 1130px) {
    width: 350px;
    margin-left: 2rem;
    margin-right: 1rem;
  }
  @media screen and (max-width: 1099px) {
    width: 300px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

const StyledPreview = styled.div`
  max-height: 50vh;
  overflow-y: scroll;
  background-color: white;
  border-radius: 0.5em;
  box-shadow: 0 0 0.5em 0.08em #999999;
`;

export default SearchBox;
