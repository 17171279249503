import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Select from "../../../../react-ui/Select";

function Increase({
  value,
  zone,
  majorations,
  updatePrestationOpportunity,
  from,
}) {
  const { id_opportunity } = useParams();
  const majoration_key =
    from === "admin" ? "majoration_profession_id" : "majoration_opportunity_id";

  return "prestation_profession_id" in value && !value.is_package ? (
    <StyledSelect
      bordered={false}
      placeholder="Majoration"
      fontSize="10px"
      value={
        value?.[majoration_key]
          ? value?.[majoration_key]
          : zone?.[majoration_key]
      }
      onChange={(e) =>
        updatePrestationOpportunity({
          opportunity_id: id_opportunity,
          id: value.id,
          [majoration_key]: e,
          ...(value?.ouvrage_parent_id && {
            ouvrage_parent_id: value.ouvrage_parent_id,
          }),
        })
      }
      options={majorations}
    />
  ) : (
    []
  );
}

const StyledSelect = styled(Select)`
  width: 105px;
`;

export default Increase;
