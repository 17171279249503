import { Form } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { offerReviewFields, validateMessages } from "../constant";
import CollapsibleTitle from "../react-ui/CollapsibleTitle";
import { useStore } from "../store";
import { getData, postData } from "../request/instance";
import ClientContact from "./ClientContact";
import TenderProcedure from "./TenderProcedure";
import { formatNumberString } from "../../utils/formatNumberString";
import { stringToFloat } from "../../utils/stringToFloat";
import Market from "./Market";
import { formatDates } from "../../utils/formatDates";
import { eurosToCents, centsToEuros } from "../../utils/currencyConverter";
import RiskManagement from "./RiskManagement";
import InternalInformations from "./InternalInformations";
import PartnerModal from "./PartnerModal";
import ContactModal from "./ContactModal";
import Loader from "../react-ui/Loader";

const selector = (state) => ({
  formToken: state.formToken,
});

const numberFieldsConstant = [
  { key: offerReviewFields.decisionCriteriaPrice },
  { key: offerReviewFields.decisionCriteriaTechnic },
  { key: offerReviewFields.decisionCriteriaOther },
  { key: offerReviewFields.minimumPriceMarket, cents: true },
  { key: offerReviewFields.maximumPriceMarket, cents: true },
];

const cleareableSelectFieldsConstant = [
  offerReviewFields.typeAo,
  offerReviewFields.variantes,
  offerReviewFields.visiteSite,
  offerReviewFields.reprisePersonnel,
  offerReviewFields.kindMarket,
  offerReviewFields.subcontracting,
  offerReviewFields.priceRevisionModality,
  offerReviewFields.entityInChargeId,
  offerReviewFields.technicalsConform,
  offerReviewFields.contractsConform,
  offerReviewFields.groupType,
  offerReviewFields.mandataryType,
];

const dateFieldsConstant = [offerReviewFields.startDateMarket];

function OfferReview({
  offerReviewId,
  companyId,
  clientsList,
  decisionClientsList,
  providedClientsList,
  typeAosList,
  variantesList,
  visiteSitesList,
  reprisePersonnelsList,
  kindMarketsList,
  subcontractingsList,
  priceRevisionModalitiesList,
  userName,
  collaboratorsList,
  entities,
  technicalsConformList,
  contractsConformList,
  groupTypesList,
  mandataryTypesList,
}) {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);
  const [isOpenFrom, setIsOpenFrom] = useState("");
  const [contactModalOpenFrom, setContactModalOpenFrom] = useState("");
  const [form] = Form.useForm();

  const { data: offerReview, isLoading } = useQuery("OfferReview", () =>
    getData(formToken, `/offer_review/${offerReviewId}`)
  );

  const { mutate: updateOfferReview } = useMutation(
    (todoData) => postData(formToken, "/offer_review/update", todoData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("OfferReview");
      },
      onError: () => {
        form.setFieldsValue({ ...offerReview });
      },
    }
  );

  const formatInitForm = ({ values, numberFields = [], dateFields = [] }) => {
    const formInit = { ...values, ...formatDates({ values, dateFields }) };

    numberFields.forEach(({ key, cents }) => {
      formInit[key] = formatNumberString({
        str: cents ? centsToEuros(values[key]) : values[key],
        nbDecimal: 2,
        space: true,
      });
    });

    return formInit;
  };

  const initialValues = useMemo(
    () =>
      offerReview
        ? formatInitForm({
            values: offerReview,
            numberFields: numberFieldsConstant,
            dateFields: dateFieldsConstant,
          })
        : {},
    [offerReview]
  );

  const formatSubmitForm = ({
    values,
    cleareableSelectFields = [],
    numberFields = [],
    dateFields = [],
  }) => {
    const formSubmit = {
      ...values,
      ...formatDates({ values, convertToString: true, dateFields }),
    };

    cleareableSelectFields.forEach((key) => {
      formSubmit[key] = values[key] || "";
    });

    numberFields.forEach(({ key, cents }) => {
      if (values[key] === "") formSubmit[key] = "";
      else
        formSubmit[key] = cents
          ? eurosToCents(values[key])
          : stringToFloat(values[key]);
    });

    if (values[offerReviewFields.groupType] !== "joint") {
      formSubmit[offerReviewFields.mandataryType] = "";
      form.setFieldsValue({ [offerReviewFields.mandataryType]: "" });
    }

    return formSubmit;
  };

  const handleSubmit = (values) => {
    updateOfferReview({
      id: offerReviewId,
      offer_review: formatSubmitForm({
        values,
        numberFields: numberFieldsConstant,
        cleareableSelectFields: cleareableSelectFieldsConstant,
        dateFields: dateFieldsConstant,
      }),
    });
  };

  const updateOnChange = useCallback(() => {
    form.submit();
  }, [form]);

  return (
    <Container>
      <ContactModal
        contactModalOpenFrom={contactModalOpenFrom}
        setContactModalOpenFrom={setContactModalOpenFrom}
        companyId={companyId}
        formOpp={form}
        updateOnChange={updateOnChange}
      />
      <PartnerModal
        isOpenFrom={isOpenFrom}
        setIsOpenFrom={setIsOpenFrom}
        companyId={companyId}
        offerReviewId={offerReviewId}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Form
          id="offer-review-form"
          form={form}
          onFinish={handleSubmit}
          initialValues={initialValues}
          colon={false}
          requiredMark={false}
          validateMessages={validateMessages}
        >
          <CollapsibleTitle label="Clients et Contacts">
            <ClientContact
              companyId={companyId}
              clientsList={clientsList}
              decisionClientsList={decisionClientsList}
              providedClientsList={providedClientsList}
              updateOnChange={updateOnChange}
              setContactModalOpenFrom={setContactModalOpenFrom}
            />
          </CollapsibleTitle>

          <CollapsibleTitle label="Procédure d'appel d'offres">
            <TenderProcedure
              typeAosList={typeAosList}
              variantesList={variantesList}
              visiteSitesList={visiteSitesList}
              reprisePersonnelsList={reprisePersonnelsList}
              updateOnChange={updateOnChange}
              form={form}
            />
          </CollapsibleTitle>
          <CollapsibleTitle label="Informations relatives au marché">
            <Market
              kindMarketsList={kindMarketsList}
              subcontractingsList={subcontractingsList}
              priceRevisionModalitiesList={priceRevisionModalitiesList}
              updateOnChange={updateOnChange}
              offerReview={offerReview}
              form={form}
            />
          </CollapsibleTitle>
          <CollapsibleTitle label="Risques de l’offre">
            <RiskManagement
              companyId={companyId}
              updateOnChange={updateOnChange}
              form={form}
            />
          </CollapsibleTitle>
          <CollapsibleTitle label="Informations internes">
            <InternalInformations
              userName={userName}
              collaboratorsList={collaboratorsList}
              entities={entities}
              technicalsConformList={technicalsConformList}
              contractsConformList={contractsConformList}
              groupTypesList={groupTypesList}
              mandataryTypesList={mandataryTypesList}
              offerReviewId={offerReviewId}
              setIsOpenFrom={setIsOpenFrom}
              updateOnChange={updateOnChange}
              form={form}
            />
          </CollapsibleTitle>
        </Form>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: scroll;
  height: calc(100vh - 70px - 81px - 10px);
`;

export default OfferReview;
