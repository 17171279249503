import React, { useMemo } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { numberSorter } from "../../../utils/numberSorter";
import { stringSorter } from "../../../utils/stringSorter";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import CenteredCell from "../../react-ui/CenteredCell";
import NumberCell from "../../react-ui/NumberCell";
import { getData } from "../../request/instance";
import { useStore } from "../../store";

const selector = (state) => ({
  formToken: state.formToken,
});

function FAArray({ entityWorkId, setSelectedFA, setInitialFA }) {
  const { formToken } = useStore(selector);
  const { data: frameworkAgreements } = useQuery("FrameworkAgreements", () =>
    getData(formToken, `/framework_agreements?entity_work_id=${entityWorkId}`)
  );

  const columnsFA = [
    {
      title: "Intitulé",
      dataIndex: "name",
      sorter: (a, b) => stringSorter(a.name, b.name),
      width: "20%",
    },
    {
      title: "Entreprises facturées",
      dataIndex: "clients",
      render: (clients) => clients?.map((client) => client.name)?.join(", "),
      // sorter: (a, b) => stringSorter(a.name, b.name),
      width: "26%",
    },
    {
      title: "Agences concernées",
      dataIndex: "entities",
      render: (entities) => entities?.map((entity) => entity.name)?.join(", "),
      // sorter: (a, b) => stringSorter(a.name, b.name),
      width: "26%",
    },
    {
      title: "Date de début",
      dataIndex: "startDate",
      render: (startDate) =>
        startDate && (
          <NumberCell>
            {new Date(startDate).toLocaleDateString("fr-FR")}
          </NumberCell>
        ),
      sorter: (a, b) => stringSorter(a.startDate, b.startDate),
      width: "11%",
    },
    {
      title: "Date de fin",
      dataIndex: "endDate",
      render: (endDate) =>
        endDate && (
          <NumberCell>
            {new Date(endDate).toLocaleDateString("fr-FR")}
          </NumberCell>
        ),
      sorter: (a, b) => stringSorter(a.endDate, b.endDate),
      width: "11%",
    },
    {
      title: "Statut",
      dataIndex: "status",
      render: (status) => (
        <CenteredCell>
          {status ? (
            <OkIcon className="fa-duotone fa-check text-success" />
          ) : (
            <RedIcon className="fa-duotone fa-times" />
          )}
        </CenteredCell>
      ),
      sorter: (a, b) => numberSorter(a.status, b.status),
      width: "8%",
    },
  ];

  const dataSource = useMemo(
    () =>
      frameworkAgreements?.map((el) => ({
        ...el,
        id: el.id,
        key: el.id,
        name: el.name,
        status: el.status,
        endDate: el.end_date,
        startDate: el.start_date,
        clients: el.clients,
        entities: el.entities,
      })),
    [frameworkAgreements]
  );

  return (
    <TableContainer
      bordered={false}
      scrollY
      columns={columnsFA}
      dataSource={dataSource}
      pagination={false}
      rowClassName={["cursor-pointer"]}
      onRow={(record) => {
        return {
          onClick: () => {
            setSelectedFA(record);
            setInitialFA(record);
          },
        };
      }}
    />
  );
}

const RedIcon = styled.i`
  color: ${({ theme }) => theme.colors.basicorange};
  font-size: 14px;
`;

const OkIcon = styled.i`
  font-size: 14px;
`;

export default FAArray;
