import React, { useEffect, useMemo, useRef, useState } from "react";
import { array, bool, func, number, object, shape, string } from "prop-types";
import {
  computeKmCost,
  currency_cents_to_euros,
  stringToFloat,
} from "../../utils";
import { formatNumberString } from "../../../utils/formatNumberString";
import Form from "../../Form";
import { otherCostArray } from "../../../constants/typeTransports";
import { formulaMarginRate } from "../../../utils/formulaMarginRate";
import { marginToCoeff } from "../../../utils/marginConverter";
import { useStore } from "../../store";
import { useMarginItem } from "../../hooks/useMarginItem";
import MarginItem from "../../react-ui/MarginItem";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
function MovementForm({
  isShowing,
  onSubmit,
  updateOnChange,
  submit,
  initialMovement,
  hasPicture,
  formatedTransports,
  from,
  hasMovementIncluded,
  faIsValidate,
  entityWorkId,
  opportunityId,
}) {
  const [movementType, setMovementType] = useState();
  const { hasMargin, setHasMargin } = useMarginItem({
    initial: initialMovement,
  });
  const formRef = useRef();
  const showFAFields = useMemo(
    () =>
      (from === "referencing" || from === "frameworkAgreement") &&
      (updateOnChange || isShowing),
    [from, updateOnChange, isShowing]
  );
  const { isUsingMargin, calculMethod } = useStore(selector);

  useEffect(() => {
    if (initialMovement) setMovementType(initialMovement.type_transport);
    else if (formatedTransports.length === 1)
      setMovementType(formatedTransports[0].id);
    else setMovementType(undefined);
  }, [initialMovement, formatedTransports]);

  const formFactory = useMemo(() => {
    const disabledTypeTransport = !!initialMovement;

    const oppNameRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Nom du déplacement",
            disabled: !hasMovementIncluded,
          },
          name: "movement_included_name",
          label: "Intitulé accord-cadre",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const oppReferenceCodeRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Référence déplacement",
            disabled: !hasMovementIncluded,
          },
          name: "movement_included_reference_code",
          label: "Code référence accord-cadre",
        },
      },
    ];

    const firstRow = [
      {
        type: "multiple",
        items: [
          showFAFields &&
            updateOnChange && {
              type: "checkbox",
              item: {
                checkbox: {},
                name: "is_in_framework_agreement",
                label: "Intégrer à l'accord-cadre",
                disabled: from === "frameworkAgreement" && !faIsValidate,
              },
            },
          {
            type: "input",
            item: {
              input: {
                placeholder: "Nom du déplacement",
                disabled: showFAFields,
              },
              name: "name",
              label: "Intitulé",
              rules: [
                {
                  required: true,
                  message: "Champ obligatoire.",
                },
              ],
            },
          },
          {
            type: "select",
            item: {
              select: {
                onChange: (value) => setMovementType(value),
                disabled: disabledTypeTransport || showFAFields,
                placeholder: "Choisissez un type",
              },
              options: formatedTransports,
              name: "type_transport",
              label: "Type de déplacement",
              rules: [
                {
                  required: true,
                  message: "Champ obligatoire.",
                },
              ],
            },
          },
        ],
      },

      hasPicture && {
        type: "upload",
        item: {
          upload: {
            listType: "picture-card",
            maxCount: 1,
            accept: "image/*",
          },
          name: "picture",
        },
        style: { display: "flex", justifyContent: "flex-end" },
      },
    ];

    const secondRow = [
      {
        type: "textarea",
        item: {
          textarea: {
            placeholder: "Ajoutez une description",
            disabled: showFAFields && !hasMovementIncluded,
          },
          name: "description",
          label: "Description",
        },
      },
      {
        type: "input",
        item: {
          input: {
            placeholder: "Code de référence",
            disabled: showFAFields,
          },
          name: "reference_code",
          label: "Code de référence",
        },
      },
    ];

    const fourthRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            disabled: showFAFields,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
              });
            },
            suffix: "%",
          },
          name: "TVA_fuel",
          label: "TVA Carburant",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            disabled: showFAFields,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
              });
            },
            suffix: "%",
          },
          name: "TVA_recuperation",
          label: "Récupération TVA",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const fourthRowBis = [
      {
        type: "input",
        span: 12,
        item: {
          input: {
            placeholder: "0,00",
            disabled: showFAFields,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
              });
            },
            suffix: "€",
          },
          name: "other_cost_cents",
          label: "Coût de revient",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const fifthRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            disabled: showFAFields,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
              });
            },
            onFocus: (e) => {
              formRef.current?.setFieldsValue({
                price_fuel_cents_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              });
            },
            onBlur: (e) => {
              formRef.current?.setFieldsValue({
                price_fuel_cents_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
            suffix: "€",
          },
          name: "price_fuel_cents_cents",
          label: "Prix carburant par litre (TTC)",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            disabled: showFAFields,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
              });
            },
            suffix: "L/100km",
          },
          name: "consomation",
          label: "Consommation",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const sixthRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            disabled: showFAFields,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
              });
            },
            onFocus: (e) => {
              formRef.current?.setFieldsValue({
                cost_annual_vehicle_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              });
            },
            onBlur: (e) => {
              formRef.current?.setFieldsValue({
                cost_annual_vehicle_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
            suffix: "€",
          },
          name: "cost_annual_vehicle_cents",
          label: "Coût annuel véhicule (HT)",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            disabled: showFAFields,
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
              });
            },
            suffix: "km",
          },
          name: "km_annual",
          label: "Km annuel",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const seventhRow = [
      movementType === "Vehicule" && {
        type: "input",
        span: 12,
        item: {
          input: {
            disabled: true,
            suffix: "€",
          },
          name: "km_cost",
          label: "Coût au km (HT)",
        },
      },
      {
        type: "input",
        span: 12,
        item: {
          input: {
            disabled: showFAFields,
            customContent: (
              <MarginItem
                hasMargin={hasMargin}
                entityWorkId={entityWorkId}
                opportunityId={opportunityId}
                itemType="movement_profession"
              />
            ),
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                isCoeff: !isUsingMargin,
              });
              setHasMargin(e.currentTarget.value !== "");
            },
          },
          isMarginRate: true,
          name: "margin_rate",
        },
      },
    ];

    const sellingPriceAndFAMarginRate = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            suffix: "€",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 2,
              });
              formRef.current?.setFieldsValue({
                margin_rate_computed: formatNumberString({
                  str: formulaMarginRate({
                    costPrice: stringToFloat(
                      formRef.current?.form.getFieldValue("other_cost_cents")
                    ),
                    calculMethod,
                    sellingPrice: stringToFloat(e.currentTarget.value),
                    isUsingMargin,
                  }),
                  isCoeff: !isUsingMargin,
                  space: true,
                }),
              });
            },
            onFocus: (e) => {
              formRef.current?.setFieldsValue({
                selling_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              });
            },
            onBlur: (e) => {
              formRef.current?.setFieldsValue({
                selling_price_cents: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                  space: true,
                }),
              });
            },
            disabled: !hasMovementIncluded,
          },
          name: "selling_price_cents",
          label: "Prix de vente",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            disabled: true,
          },
          name: "margin_rate_computed",
          isComputed: true,
          isMarginRate: true,
        },
      },
    ];

    return [
      { columns: firstRow, key: "name+picture+type_transport" },
      showFAFields && {
        columns: oppNameRow,
        key: "oppNameRow",
      },
      showFAFields && {
        columns: oppReferenceCodeRow,
        key: "oppReferenceCodeRow",
      },
      { columns: secondRow, key: "description" },
      movementType === "Vehicule" && {
        columns: fourthRow,
        key: "TVA_fuel+price_fuel_cents_cents",
      },
      movementType !== undefined &&
        movementType !== "Vehicule" && {
          columns: fourthRowBis,
          key: "other_cost_cents",
        },
      showFAFields &&
        from !== "referencing" && {
          columns: sellingPriceAndFAMarginRate,
          key: "sellingPriceAndFAMarginRate",
        },
      movementType === "Vehicule" && {
        columns: fifthRow,
        key: "consomation+TVA_recuperation",
      },
      movementType === "Vehicule" && {
        columns: sixthRow,
        key: "km_annual+cost_annual_vehicle_cents",
      },
      { columns: seventhRow, key: "km_cost+margin_rate" },
    ];
  }, [
    initialMovement,
    hasMovementIncluded,
    showFAFields,
    updateOnChange,
    from,
    faIsValidate,
    formatedTransports,
    hasPicture,
    movementType,
    hasMargin,
    entityWorkId,
    opportunityId,
    isUsingMargin,
    setHasMargin,
    calculMethod,
  ]);

  const initialValues = useMemo(() => {
    if (!initialMovement) {
      if (formatedTransports.length === 1)
        return {
          type_transport: formatedTransports[0].id,
        };
      return {};
    }
    const TVA_fuel = formatNumberString({ str: initialMovement.TVA_fuel });
    const TVA_recuperation = formatNumberString({
      str: initialMovement.TVA_recuperation,
    });
    const consomation = formatNumberString({
      str: initialMovement.consomation,
    });
    const km_annual = formatNumberString({ str: initialMovement.km_annual });
    const km_cost = formatNumberString({
      str: initialMovement.km_cost,
      nbDecimal: 4,
    });
    const margin_rate = initialMovement.margin_rate
      ? formatNumberString({
          str: marginToCoeff({
            marginRate: initialMovement.margin_rate,
            isUsingMargin,
            calculMethod,
          }),
          isCoeff: !isUsingMargin,
        })
      : undefined;
    const picture = initialMovement.picture
      ? [
          {
            uid: "1",
            name: "image",
            status: "done",
            url: initialMovement.picture,
          },
        ]
      : [];
    return {
      name: initialMovement.name,
      description: hasMovementIncluded
        ? initialMovement.movement_included?.description
        : initialMovement.description,
      type_transport: initialMovement.type_transport,
      TVA_fuel,
      TVA_recuperation,
      price_fuel_cents_cents: formatNumberString({
        str: currency_cents_to_euros(initialMovement.price_fuel_cents_cents),
        space: true,
      }),
      cost_annual_vehicle_cents: formatNumberString({
        str: currency_cents_to_euros(initialMovement.cost_annual_vehicle_cents),
        space: true,
      }),
      km_cost,
      margin_rate,
      consomation,
      km_annual,
      reference_code: initialMovement.reference_code,
      other_cost_cents: formatNumberString({
        str: currency_cents_to_euros(initialMovement.other_cost_cents),
        space: true,
      }),
      maintenance: initialMovement.maintenance,
      picture,
      movement_included_name: hasMovementIncluded
        ? initialMovement.movement_included?.name
        : initialMovement.name,
      movement_included_reference_code: hasMovementIncluded
        ? initialMovement.movement_included?.reference_code
        : initialMovement.reference_code,
      is_in_framework_agreement: initialMovement.is_in_framework_agreement,
      selling_price_cents: !hasMovementIncluded
        ? formatNumberString({
            str: currency_cents_to_euros(initialMovement.other_cost_cents),
            nbDecimal: 2,
            space: true,
          })
        : formatNumberString({
            str: currency_cents_to_euros(
              initialMovement.movement_included?.selling_price_cents
            ),
            nbDecimal: 2,
            space: true,
          }),
      margin_rate_computed:
        !hasMovementIncluded || from !== "frameworkAgreement"
          ? 0
          : formatNumberString({
              str: formulaMarginRate({
                costPrice: initialMovement.other_cost_cents,
                calculMethod,
                sellingPrice:
                  initialMovement.movement_included?.selling_price_cents,
                isUsingMargin,
              }),
              isCoeff: !isUsingMargin,
              space: true,
            }),
    };
  }, [
    initialMovement,
    hasMovementIncluded,
    formatedTransports,
    calculMethod,
    from,
    isUsingMargin,
  ]);

  const onValuesChange = (_, e) => {
    if (e.type_transport !== "Vehicule" || !formRef.current) return;
    const kmAnnual = stringToFloat(e.km_annual) || 1;
    const kmCost = computeKmCost({
      costAnnualVehicle: stringToFloat(e.cost_annual_vehicle_cents),
      kmAnnual,
      priceFuel: stringToFloat(e.price_fuel_cents_cents),
      TvaRecuperation: stringToFloat(e.TVA_recuperation) / 100,
      TvaFuel: stringToFloat(e.TVA_fuel) / 100,
      consomation: stringToFloat(e.consomation),
    });
    formRef.current.setFieldsValue({
      km_cost: formatNumberString({ str: kmCost, nbDecimal: 4 }),
    });
  };

  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={formFactory}
      isShowing={isShowing}
      onValuesChange={onValuesChange}
      ref={formRef}
      id="movement-form"
    />
  );
}

MovementForm.propTypes = {
  isShowing: bool,
  onSubmit: func,
  updateOnChange: bool,
  submit: shape({
    onCancel: func,
  }),
  initialMovement: object,
  hasPicture: bool,
  formatedTransports: array,
  from: string,
  hasMovementIncluded: bool,
  faIsValidate: bool,
  opportunityId: number,
  entityWorkId: string,
};

MovementForm.defaultProps = {
  isShowing: false,
  onSubmit: undefined,
  updateOnChange: false,
  submit: undefined,
  initialMovement: undefined,
  hasPicture: true,
  formatedTransports: [
    {
      title: "Véhicule",
      id: "Vehicule",
    },
    {
      title: "Train",
      id: "Train",
    },
    {
      title: "Taxi",
      id: "Taxi",
    },
    {
      title: "Avion",
      id: "Avion",
    },
    ...otherCostArray,
  ],
  from: "admin",
  faIsValidate: false,
  hasMovementIncluded: false,
  opportunityId: undefined,
  entityWorkId: undefined,
};

export default MovementForm;
