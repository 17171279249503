import React from "react";
import { InputNumber as AntdInputNumber } from "antd";
import { bool, string } from "prop-types";
import styled from "styled-components";

function InputNumber({ controls, className, ...inputNumberProps }) {
  return (
    <StyledInputNumber
      controls={controls}
      className={className}
      {...inputNumberProps}
    />
  );
}

const StyledInputNumber = styled(AntdInputNumber)`
  input {
    font-size: ${({ fontSize }) => fontSize} !important;
    padding: 2px 4px !important;
    text-align: ${({ textAlign }) => textAlign} !important;
  }
`;

InputNumber.propTypes = {
  controls: bool,
  className: string,
  fontSize: string,
  textAlign: string,
};

InputNumber.defaultProps = {
  controls: false,
  className: "",
  fontSize: "14px",
  textAlign: "right",
};

export default InputNumber;
