import React, { useMemo } from "react";
import styled from "styled-components";
import i18next from "i18next";

function OfferResult({
  opportunities_result,
  configuration,
  vocabulary_application,
}) {
  const defineOpportunityIcon = (opportunity) => {
    if (opportunity?.work_name === "guarding") {
      return (
        <i
          className="fa-duotone fa-user-shield mr-1 icon-search"
          title={i18next.t("work.guarding")}
        />
      );
    }
    return (
      <i
        className={`${
          configuration.find(
            (config) => config.work.name === opportunity?.work_name
          )?.configuration.general.work_icon
        } mr-1 icon-search`}
        title={i18next.t(
          `work.${
            configuration.find(
              (config) => config.work.name === opportunity?.work_name
            )?.work
          }`
        )}
      />
    );
  };

  const formattedOfferResult = useMemo(() => {
    if (opportunities_result?.length === 0)
      return (
        <StyledResultDiv>
          {vocabulary_application.search_bar.preview_no_opportunity_result}
        </StyledResultDiv>
      );
    return opportunities_result?.map((opportunity) => (
      <StyledResultDiv
        key={opportunity?.id}
        onClick={() =>
          (window.location = `/opportunities/${opportunity?.id}/last_page`)
        }
      >
        <StyledIcon>{defineOpportunityIcon(opportunity)}</StyledIcon>
        {opportunity?.opportunity_name}
      </StyledResultDiv>
    ));
  }, [
    opportunities_result,
    vocabulary_application.search_bar.preview_no_opportunity_result,
  ]);

  return (
    <StyledContainerOffer>
      <StyledTitle>
        {vocabulary_application.search_bar.preview_opportunities}
      </StyledTitle>
      {formattedOfferResult}
    </StyledContainerOffer>
  );
}

const StyledContainerOffer = styled.div`
  background-color: white;
  margin-bottom: 0.5em;
`;

const StyledResultDiv = styled.div`
  line-height: 1.5;
  font-size: 12px;
  color: #333333;
  display: flex;
  align-items: center;
  padding-left: 2em;
  cursor: pointer;
  text-align: left;
  &:hover {
    background-color: #9abcea;
  }
`;

const StyledTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  display: flex;
  align-items: center;
  height: 30px;
  padding-left: 1em;
`;
const StyledIcon = styled.div`
  display: flex;
  justify-content: left;
  width: 41px;
`;
export default OfferResult;
