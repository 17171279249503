import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import styled from "styled-components";
import { getData } from "../../../request/instance";
import { fetchFileRulesUrl } from "../../../../utils/fetchFileRulesUrl";
import SATableTree from "../../../documents-and-simplified/simplified-application/SATableTree";
import BaseIcon from "../../../react-ui/Icons/BaseIcon";

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 600);

function SearchFileRules({
  data,
  setData,
  formToken,
  checkProps,
  selectUnselectChildren,
  wordEntered,
}) {
  const [showAll, setShowAll] = useState(true);

  const { data: fileRules, isLoading: fileRulesIsLoading } = useQuery(
    ["FileRules"],
    () =>
      getData(
        formToken,
        fetchFileRulesUrl({
          search: undefined,
        })
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: fileRulesFromSearch, refetch } = useQuery(
    ["FileRulesFromSearch"],
    () =>
      getData(
        formToken,
        fetchFileRulesUrl({
          search: wordEntered,
        })
      ),
    {
      refetchOnWindowFocus: false,
      onSettled: () => {
        setShowAll(wordEntered === "");
      },
    }
  );

  useEffect(() => {
    debouncedSearch({
      refetch,
    });
  }, [wordEntered, refetch]);

  useEffect(() => {
    if (fileRules) {
      setData(() =>
        fileRules.map((fr) => ({
          ...fr,
          name: `${fr.name}`,
          additionalName: (
            <AdditionalName>
              (dans{" "}
              {fr.folder
                ? `le dossier ${fr.folder.name}`
                : "offres et opportunités"}
              )
            </AdditionalName>
          ),
          key: `file-rule-${fr.id}`,
          icon: <BaseIcon className="fa-duotone fa-file-circle-exclamation" />,
          type: "file-rule",
          isLeaf: true,
        }))
      );
    }
  }, [fileRules, setData]);

  const filesToHide = useMemo(() => {
    if (!fileRulesFromSearch || showAll) return null;
    const toHide = [];
    data.forEach((el) => {
      if (!fileRulesFromSearch.some((docFrom) => docFrom.id === el.id)) {
        toHide.push(el.key);
      }
    });
    return toHide;
  }, [data, fileRulesFromSearch, showAll]);

  return (
    <SATableTree
      data={data}
      loading={fileRulesIsLoading}
      siteTypologies={[]}
      setData={setData}
      formToken={formToken}
      documentsToHide={filesToHide}
      tableHeight={460}
      checkProps={checkProps}
      selectUnselectChildren={selectUnselectChildren}
    />
  );
}

const AdditionalName = styled.span`
  font-style: italic;
`;

export default SearchFileRules;
