import React from "react";
export const locale_tour = {
  next: (
    <div
      className="btn btn-primary btn-blue btn-next"
      style={{ justifyContent: "center", padding: 0 }}
    >
      <div className="d-flex align-items-center">
        <i className="fas fa-hand-point-right fa-1xhalf" />
        <p className="mb-0" style={{ marginRight: 36 }}>
          Suivant
        </p>
      </div>
    </div>
  ),
  back: (
    <div
      className="btn btn-primary btn-blue"
      style={{ justifyContent: "center", marginRight: 0, padding: 0 }}
    >
      <div className="d-flex align-items-center">
        <i className="fas fa-hand-point-left fa-1xhalf" />
        <p className="mb-0" style={{ marginLeft: 27 }}>
          Précédent
        </p>
      </div>
    </div>
  ),
  skip: (
    <div
      className="btn btn-primary btn-skip"
      style={{ justifyContent: "center" }}
    >
      <div className="d-flex align-items-center">
        <p className="mb-0">Passer</p>
      </div>
    </div>
  ),
  last: (
    <div
      className="btn btn-primary btn-blue"
      style={{ justifyContent: "center" }}
    >
      <div className="d-flex align-items-center">
        <p className="mb-0">Terminé</p>
      </div>
    </div>
  ),
};

export const style_tour = {
  options: {
    zIndex: 10000,
    primaryColor: "transparent",
    width: "auto",
  },
};
