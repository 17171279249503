import { Space } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CostingSection from "../../../Chiffrage/CostingMaterialsPrestations/CostingSection";
import ListSection from "../../../Chiffrage/CostingMaterialsPrestations/ListSection";
import Separator from "../../../react-ui/Separator";
import { useStore } from "../../../store";
import { getUrlParams } from "../../../utils";
import { getFile } from "../FilesImport";
import { StyledCard } from "../styledComponent";
import ChiffrageConsulting from "./ChiffrageConsulting";
import Filters from "./Filters";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
  isUsingMinutes: state.isUsingMinutes,
  vocabulary: state.vocabulary,
});

export default function SelectionItemsTab({
  props,
  management_special_TVA,
  configuration,
  setConfiguration,
}) {
  const { isUsingMinutes, vocabulary } = useStore(selector);

  return (
    <Container>
      <ContainerHorizontal>
        <div
          style={{
            flexBasis: "37%",
          }}
        >
          <Filters
            props={props}
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        </div>
        <Separator size={3} />
        <div
          style={{
            flexBasis: "70%",
          }}
        >
          <ChiffrageConsulting
            configuration={configuration}
            setConfiguration={setConfiguration}
          />
        </div>
      </ContainerHorizontal>

      <Space size={24} direction="vertical" style={{ padding: "10px 20px" }}>
        <PreviewChiffrageConsulting
          props={props}
          isUsingMinutes={isUsingMinutes}
          configuration={configuration}
          vocabulary={vocabulary}
          management_special_TVA={management_special_TVA}
          api_providers_length={props?.list_providers?.length}
        />
        {configuration?.general?.framework_agreement && (
          <PreviewFrameworkAgreement
            props={props}
            isUsingMinutes={isUsingMinutes}
            configuration={configuration}
            vocabulary={vocabulary}
            management_special_TVA={management_special_TVA}
          />
        )}
      </Space>
    </Container>
  );
}
function PreviewChiffrageConsulting({
  api_providers_length,
  isUsingMinutes,
  configuration,
  vocabulary,
  management_special_TVA,
}) {
  return (
    <StyledCard
      headStyle={{ backgroundColor: "#0B2239", color: "white" }}
      bodyStyle={{ backgroundColor: "#F5F6F8" }}
      size="small"
      title="Prévisualisation chiffrage consultation"
      style={{
        width: "100%",
      }}
    >
      <Space direction="horizontal" size={16}>
        <ContainerHorizontal
          style={{
            backgroundColor: "#F5F6F8",
            height: "340px",
            overFlow: "scroll",
          }}
        >
          <ListSection
            configuration={configuration}
            vocabulary={vocabulary}
            api_providers_length={api_providers_length}
            defaultMinutes={isUsingMinutes}
            selectedZoneTemplate={null}
            company_work={null}
            from="configuration"
            entity_work_id={getUrlParams()}
          />
          <Separator className="divider-preview" />

          <CostingSection
            company_work={{ management_special_TVA }}
            selectedZoneTemplate={null}
            configuration={configuration}
            vocabulary={vocabulary}
            from="configuration"
          />
        </ContainerHorizontal>
      </Space>
    </StyledCard>
  );
}

function PreviewFrameworkAgreement({
  isUsingMinutes,
  configuration,
  vocabulary,
  management_special_TVA,
}) {
  return (
    <StyledCard
      headStyle={{ backgroundColor: "#0B2239", color: "white" }}
      bodyStyle={{ backgroundColor: "#F5F6F8" }}
      size="small"
      title="Prévisualisation devis accord-cadre"
      style={{
        width: "100%",
      }}
    >
      <Space direction="horizontal" size={16}>
        <ContainerHorizontal
          style={{
            backgroundColor: "#F5F6F8",
            height: "340px",
            overFlow: "scroll",
          }}
        >
          <ListSection
            configuration={configuration}
            vocabulary={vocabulary}
            api_providers_length={1}
            defaultMinutes={isUsingMinutes}
            selectedZoneTemplate={null}
            company_work={null}
            from="configuration"
            entity_work_id={getUrlParams()}
          />
          <Separator className="divider-preview" />
          <CostingSection
            company_work={{ management_special_TVA }}
            selectedZoneTemplate={null}
            configuration={configuration}
            vocabulary={vocabulary}
            from="configuration"
            faId={1}
          />
        </ContainerHorizontal>
      </Space>
    </StyledCard>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 24px;
`;
