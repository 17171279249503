import { Form } from "antd";
import React from "react";
import styled, { css } from "styled-components";
import Label from "./Label";

function FormCol({
  width = "100%",
  label = "",
  rules = [{ required: false }],
  name = "",
  children,
  customContent = null,
  hidden = false,
  labelOnTop = false,
  withoutPaddingRight = false,
  className = "",
  ...formItemProps
}) {
  return (
    <Container
      width={width}
      labelOnTop={labelOnTop}
      $withoutPaddingRight={withoutPaddingRight}
      className={className}
    >
      <Form.Item
        label={
          typeof label === "string" ? (
            <Label label={label} rules={rules} />
          ) : (
            label
          )
        }
        style={hidden ? { display: "none" } : undefined}
        labelCol={labelOnTop ? { span: 24 } : {}}
        name={name}
        rules={rules}
        {...formItemProps}
      >
        {children}
      </Form.Item>
      {customContent}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  &:only-of-type {
    padding-right: 8px;
  }
  &:not(:last-of-type) {
    padding-right: 8px;
  }
  &:not(:first-of-type) {
    padding-left: 8px;
  }
  ${({ $withoutPaddingRight }) =>
    $withoutPaddingRight &&
    css`
      &:only-of-type {
        padding-right: 0;
      }
      &:not(:last-of-type) {
        padding-right: 0;
      }
    `}

  ${({ labelOnTop }) =>
    labelOnTop &&
    css`
      .ant-form-item-label > label {
        height: auto;
      }
    `}

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export default FormCol;
