import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useStore } from "../../../../store";
import Select from "../../../../react-ui/Select";

const selector = (state) => ({
  formToken: state.formToken,
  selectedCarrycot: state.selectedCarrycot,
});
function Carrycot({
  value,
  carrycots,
  faId,
  updatePrestationOpportunity,
  from,
}) {
  const { id_opportunity } = useParams();

  const { selectedCarrycot } = useStore(selector);
  const carrycot_key =
    from === "admin" ? "carrycot_profession_id" : "carrycot_tpl_opportunity_id";
  return "prestation_profession_id" in value && !value.is_package ? (
    <StyledSelect
      $faId={faId}
      allowClear
      bordered={false}
      fontSize="10px"
      placeholder="Aucune"
      value={value?.[carrycot_key] ? value?.[carrycot_key] : selectedCarrycot}
      onChange={(e) => {
        if (!e)
          updatePrestationOpportunity({
            opportunity_id: id_opportunity,
            id: value.id,
            to_delete: "carrycot_tpl_opportunity_id",
            ...(value?.ouvrage_parent_id && {
              ouvrage_parent_id: value.ouvrage_parent_id,
            }),
          });
        else
          updatePrestationOpportunity({
            opportunity_id: id_opportunity,
            id: value.id,
            [carrycot_key]: e,
            ...(value?.ouvrage_parent_id && {
              ouvrage_parent_id: value.ouvrage_parent_id,
            }),
          });
      }}
      options={carrycots}
    />
  ) : null;
}

const StyledSelect = styled(Select)`
  width: 105px;
`;

export default Carrycot;
