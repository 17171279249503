import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import styled from "styled-components";
import SATableTree, {
  constructDocumentRow,
} from "../../../documents-and-simplified/simplified-application/SATableTree";
import { getData } from "../../../request/instance";
import { fetchDocumentsUrl } from "../../../../utils/fetchDocumentsUrl";
import { fetchFoldersUrl } from "../../../../utils/fetchFoldersUrl";
import BaseIcon from "../../../react-ui/Icons/BaseIcon";

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 600);

const folderIcon = ({ expanded }) =>
  expanded ? (
    <FolderIcon className="fa-duotone fa-folder-open" />
  ) : (
    <FolderIcon className="fa-duotone fa-folder" />
  );

function SearchDocFolders({
  data,
  setData,
  formToken,
  templateId,
  checkProps,
  selectUnselectChildren,
  wordEntered,
}) {
  const [showAll, setShowAll] = useState(true);
  const { data: folders, isLoading: foldersAreLoading } = useQuery(
    ["Folders"],
    () =>
      getData(
        formToken,
        fetchFoldersUrl({
          search: undefined,
        })
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: documentsWithoutFolders, isLoading: documentsAreLoading } =
    useQuery(
      ["Documents", { sa_template_id: templateId }],
      () =>
        getData(
          formToken,
          fetchDocumentsUrl({
            search: undefined,
            sa_template_id: templateId,
          })
        ),
      { refetchOnWindowFocus: false }
    );

  const { data: documentsFromSearch, refetch } = useQuery(
    [
      "DocumentsFromSearch",
      {
        sa_template_id: templateId,
        search_from_template: true,
      },
    ],
    () =>
      getData(
        formToken,
        fetchDocumentsUrl({
          search: wordEntered,
          sa_template_id: templateId,
          search_from_template: true,
        })
      ),
    {
      refetchOnWindowFocus: false,
      onSettled: () => {
        setShowAll(wordEntered === "");
      },
    }
  );

  useEffect(() => {
    debouncedSearch({
      refetch,
    });
  }, [wordEntered, refetch]);

  useEffect(() => {
    if (documentsWithoutFolders) {
      setData((oldData) => {
        const newData = oldData.filter(
          (element) => element.type !== "document"
        );
        documentsWithoutFolders.forEach((document) => {
          newData.push(
            constructDocumentRow({
              document,
              folders,
              siteTypologies: [],
            })
          );
        });
        return newData;
      });
    }
  }, [documentsWithoutFolders, folders, setData]);

  useEffect(() => {
    if (folders) {
      setData((oldData) => {
        const newData = [...oldData].filter(
          (element) => !element.type.includes("folder")
        );
        folders.forEach((folder) => {
          newData.unshift({
            ...folder,
            name: folder.name,
            key: `folder-${folder.id}`,
            icon: folderIcon,
            type: "folder",
            isLeaf: false,
          });
        });
        return newData;
      });
    }
  }, [folders, setData]);

  const documentsToHide = useMemo(() => {
    if (!documentsFromSearch || showAll) return null;
    const toHide = [];
    data.forEach((el) => {
      if (
        el.type === "document" &&
        !documentsFromSearch.some((docFrom) => docFrom.id === el.id)
      ) {
        toHide.push(el.key);
      } else if (el.children?.length > 0) {
        el.children.forEach((doc) => {
          if (
            doc.type === "document" &&
            !documentsFromSearch.some((docFrom) => docFrom.id === doc.id)
          ) {
            toHide.push(doc.key);
          }
        });
      }
    });
    return toHide;
  }, [data, documentsFromSearch, showAll]);

  const foldersToOpen = useMemo(() => {
    if (!documentsFromSearch || showAll) return null;
    const toOpen = [];
    data.forEach((el) => {
      if (
        el.type === "folder" &&
        documentsFromSearch.some((docFrom) =>
          docFrom.document_folders.map((df) => df.folder_id).includes(el.id)
        )
      ) {
        toOpen.push(el);
      }
    });
    return toOpen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsFromSearch, showAll]);

  return (
    <SATableTree
      data={data}
      loading={documentsAreLoading || foldersAreLoading}
      folders={folders}
      siteTypologies={[]}
      setData={setData}
      formToken={formToken}
      documentsToHide={documentsToHide}
      foldersToOpen={foldersToOpen}
      sa_template_id={templateId}
      tableHeight={460}
      checkProps={checkProps}
      selectUnselectChildren={selectUnselectChildren}
    />
  );
}

const FolderIcon = styled(BaseIcon)`
  color: ${({ theme }) => theme.colors.blue11};
`;

export default SearchDocFolders;
