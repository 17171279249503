import React, { memo } from "react";
import { useDrag, useDrop } from "react-dnd";
import styled from "styled-components";
import { getRuleName } from "../../../constants/rules";

const RuleRow = memo(function RuleRow({
  id,
  rule_on,
  operator,
  value_to_compare,
  colId,
  moveRuleRow,
  onDrop,
  typology,
}) {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "rule",
      item: { id },
      collect: (monitor) => ({
        isDragging: id === monitor.getItem()?.id,
      }),
      end: () => onDrop(),
    }),
    [id]
  );

  const [, drop] = useDrop(
    () => ({
      accept: "rule",
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          moveRuleRow({
            draggedId,
            overId: id,
            overColumnId: colId,
          });
        }
      },
    }),
    []
  );

  const opacity = isDragging ? 0.5 : 1;

  return (
    <Container key={id} style={{ opacity }} ref={(node) => drag(drop(node))}>
      <CursorIcon className="fa-duotone fa-arrows" aria-hidden="true" />
      {getRuleName({ rule_on, operator, value_to_compare, typology })}
    </Container>
  );
});

const Container = styled.div`
  font-size: 12px;
  padding: 5px;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  font-weight: 500;
  display: flex;
  align-items: baseline;
  cursor: move;
  cursor: grab;
`;

const CursorIcon = styled.i`
  margin-right: 5px;
`;

export default RuleRow;
