const CalculateTotalSellingPrice = (inputValue, itemValue, id_opportunity, updateFunction) => {
    
    updateFunction({
        opportunity_id: id_opportunity,
        id: itemValue.id,
        selling_price_cents: Number.parseFloat(
          inputValue.target.value / itemValue.quantity
        ),
        margin_rate:
          (Number.parseFloat(inputValue.target.value / itemValue.quantity) /
          itemValue.unitary_cost_price - 1) *100,
      });
}

export default CalculateTotalSellingPrice;