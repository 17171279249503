import { Modal } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { otherCostArray } from "../../../../constants/typeTransports";
import {
  centsToEuros,
  eurosToCents,
} from "../../../../utils/currencyConverter";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { stringToFloat } from "../../../../utils/stringToFloat";
import MovementForm from "../../../Admin-cf/Movements/MovementForm";
import { getData, postData } from "../../../request/instance";
import { computeKmCost, currency_euros_to_cents } from "../../../utils";
import OtherCostTable from "./OtherCostTable";
import OtherMovementTable from "./OtherMovementTable";
import VehiculeTable from "./VehiculeTable";
import {
  otherCost,
  otherCostMovementOpportunity,
  otherMovement,
  otherMovementOpportunity,
  vehiculeMovement,
  vehiculeMovementOpportunity,
} from "../../../data-utils/page2-data";

function MovementSection({
  formToken,
  id_opportunity,
  zones,
  entity_work_id,
  faId,
  from,
}) {
  const queryClient = useQueryClient();
  const [isModalVisibleFor, setIsModalVisibleFor] = useState("");

  const { data: movements } = useQuery(
    "Movements",
    () => getData(formToken, `/movement_tpl_opportunity/${id_opportunity}`),
    { refetchOnWindowFocus: false, enabled: from !== "configuration" }
  );

  const { data: opportunities } = useQuery(
    "MovementOpportunities",
    async () => {
      const data = await getData(
        formToken,
        `/movement_opportunity/${id_opportunity}`
      );
      return data.map((el) => {
        return {
          ...el,
          roundtrip_distance_km: el.roundtrip_distance_km
            ? formatNumberString({
                str: el.roundtrip_distance_km,
                nbDecimal: 2,
                space: true,
              })
            : null,
          roundtrip_duration_hour: el.roundtrip_duration_hour
            ? formatNumberString({
                str: el.roundtrip_duration_hour,
                nbDecimal: 2,
                space: true,
              })
            : null,
          total_distance_km: el.total_distance_km
            ? formatNumberString({
                str: el.total_distance_km,
                nbDecimal: 2,
                space: true,
              })
            : null,
          total_duration_hour: el.total_duration_hour
            ? formatNumberString({
                str: el.total_duration_hour,
                nbDecimal: 2,
                space: true,
              })
            : null,
          other_cost_cents: el.other_cost_cents
            ? formatNumberString({
                str: centsToEuros(el.other_cost_cents),
                nbDecimal: 2,
                space: true,
              })
            : null,
          total_cost_cents: el.total_cost_cents
            ? formatNumberString({
                str: centsToEuros(el.total_cost_cents),
                nbDecimal: 2,
                space: true,
              })
            : null,
        };
      });
    },
    { refetchOnWindowFocus: false, enabled: from !== "configuration" }
  );

  const { mutate: createOpportunityMutation } = useMutation(
    (todo) => postData(formToken, "/movement_opportunity/create", todo),
    {
      enabled: from !== "configuration",
      onSuccess: () => {
        queryClient.invalidateQueries("MovementOpportunities");
      },
      onError: () => queryClient.resetQueries("MovementOpportunities"),
    }
  );

  const { mutate: deleteOpportunityMutation } = useMutation(
    (todo) => postData(formToken, "/movement_opportunity/delete", todo),
    {
      enabled: from !== "configuration",
      onSuccess: () => {
        queryClient.invalidateQueries("MovementOpportunities");
      },
      onError: () => queryClient.resetQueries("MovementOpportunities"),
    }
  );

  const { mutate: updateOpportunityMutation, isLoading } = useMutation(
    (todo) => postData(formToken, "/movement_opportunity/update", todo),
    {
      enabled: from !== "configuration",
      onSuccess: () => {
        queryClient.invalidateQueries("MovementOpportunities");
      },
      onError: () => queryClient.resetQueries("MovementOpportunities"),
    }
  );

  const createOpportunity = ({
    movement_tpl_opportunity_id,
    zone_id,
    movement_type,
    ...movementProperties
  }) => {
    createOpportunityMutation(
      JSON.stringify({
        opportunity_id: id_opportunity,
        movement_tpl_opportunity_id,
        zone_id,
        ...movementProperties,
      })
    );
  };

  const deleteOpportunity = (id) =>
    deleteOpportunityMutation(
      JSON.stringify({
        id,
        opportunity_id: id_opportunity,
      })
    );

  const updateOpportunity = (opp) => {
    updateOpportunityMutation(
      JSON.stringify({
        ...opp,
        not_from_costing: true,
        roundtrip_distance_km: opp.roundtrip_distance_km
          ? stringToFloat(opp.roundtrip_distance_km)
          : null,
        roundtrip_duration_hour: opp.roundtrip_duration_hour
          ? stringToFloat(opp.roundtrip_duration_hour)
          : null,
        total_distance_km: opp.total_distance_km
          ? stringToFloat(opp.total_distance_km)
          : null,
        total_duration_hour: opp.total_duration_hour
          ? stringToFloat(opp.total_duration_hour)
          : null,
        other_cost_cents: opp.other_cost_cents
          ? eurosToCents(opp.other_cost_cents)
          : null,
        total_cost_cents: opp.total_cost_cents
          ? eurosToCents(opp.total_cost_cents)
          : null,
      })
    );
  };

  const { mutate: createMovement } = useMutation(
    (todo) => postData(formToken, "/movement_tpl_opportunity/create", todo),
    {
      enabled: from !== "configuration",
      onSettled: () => {
        queryClient.invalidateQueries("Movements");
      },
    }
  );

  const vehiculeOpportunities = useMemo(() => {
    if (from === "configuration") return vehiculeMovementOpportunity;
    if (opportunities?.length < 1) return [];
    return opportunities?.filter(
      ({ type_transport }) => type_transport === "Vehicule"
    );
  }, [opportunities, from]);

  const otherMovementsOpportunities = useMemo(() => {
    if (from === "configuration") return otherMovementOpportunity;
    if (opportunities?.length < 1) return [];
    return opportunities?.filter(
      ({ type_transport }) =>
        type_transport === "Train" ||
        type_transport === "Avion" ||
        type_transport === "Taxi"
    );
  }, [opportunities, from]);

  const otherCostsOpportunities = useMemo(() => {
    if (from === "configuration") return otherCostMovementOpportunity;
    if (opportunities?.length < 1) return [];
    return opportunities?.filter(
      ({ type_transport }) =>
        type_transport === "Hotel" ||
        type_transport === "Restaurant" ||
        type_transport === "Forfait"
    );
  }, [opportunities, from]);

  const vehiculeMovements = useMemo(() => {
    if (from === "configuration") return vehiculeMovement;
    if (movements?.length < 1) return [];
    return movements?.filter(
      ({ type_transport }) => type_transport === "Vehicule"
    );
  }, [movements, from]);

  const otherMovementsMovements = useMemo(() => {
    if (from === "configuration") return otherMovement;
    if (movements?.length < 1) return [];
    return movements?.filter(
      ({ type_transport }) =>
        type_transport === "Train" ||
        type_transport === "Avion" ||
        type_transport === "Taxi"
    );
  }, [movements, from]);

  const otherCostsMovements = useMemo(() => {
    if (from === "configuration") return otherCost;
    if (movements?.length < 1) return [];
    return movements?.filter(
      ({ type_transport }) =>
        type_transport === "Hotel" ||
        type_transport === "Restaurant" ||
        type_transport === "Forfait"
    );
  }, [movements, from]);

  const onSubmit = (e) => {
    const formatableKeys = [
      "TVA_fuel",
      "TVA_recuperation",
      "price_fuel_cents_cents",
      "cost_annual_vehicle_cents",
      "km_cost",
      "other_cost_cents",
      "consomation",
      "km_annual",
      "reference_code",
    ];
    const formData = new FormData();
    Object.keys(e).forEach(
      (key) =>
        !formatableKeys.includes(key) &&
        formData.append(
          `movement_tpl_opportunity[${key}]`,
          e[key] !== undefined && e[key] !== null ? e[key] : ""
        )
    );
    if (e.consomation) {
      formData.append(
        "movement_tpl_opportunity[consomation]",
        parseFloat(e.consomation.replace(/,/g, "."))
      );
    }
    if (e.km_annual) {
      formData.append(
        "movement_tpl_opportunity[km_annual]",
        parseFloat(e.km_annual.replace(/,/g, "."))
      );
    }
    if (e.other_cost_cents) {
      formData.append(
        "movement_tpl_opportunity[other_cost_cents]",
        currency_euros_to_cents(e.other_cost_cents)
      );
    }
    if (e.km_cost) {
      const kmCost = computeKmCost({
        costAnnualVehicle: stringToFloat(e.cost_annual_vehicle_cents),
        kmAnnual: stringToFloat(e.km_annual) || 1,
        priceFuel: stringToFloat(e.price_fuel_cents_cents),
        TvaRecuperation: stringToFloat(e.TVA_recuperation) / 100,
        TvaFuel: stringToFloat(e.TVA_fuel) / 100,
        consomation: stringToFloat(e.consomation),
      });
      formData.append("movement_tpl_opportunity[km_cost]", kmCost);
    }
    if (e.cost_annual_vehicle_cents) {
      formData.append(
        "movement_tpl_opportunity[cost_annual_vehicle_cents]",
        currency_euros_to_cents(e.cost_annual_vehicle_cents)
      );
    }
    if (e.price_fuel_cents_cents) {
      formData.append(
        "movement_tpl_opportunity[price_fuel_cents_cents]",
        currency_euros_to_cents(e.price_fuel_cents_cents)
      );
    }
    if (e.TVA_fuel) {
      formData.append(
        "movement_tpl_opportunity[TVA_fuel]",
        parseFloat(e.TVA_fuel.replace(/,/g, "."))
      );
    }
    if (e.TVA_recuperation) {
      formData.append(
        "movement_tpl_opportunity[TVA_recuperation]",
        parseFloat(e.TVA_recuperation.replace(/,/g, "."))
      );
    }
    formData.append("movement_tpl_opportunity[entity_work_id]", entity_work_id);
    formData.append("movement_tpl_opportunity[opportunity_id]", id_opportunity);
    createMovement(formData);
    setIsModalVisibleFor("");
  };

  const getFormatedTransport = (key) => {
    if (key === "vehicule") {
      return [
        {
          title: "Véhicule",
          id: "Vehicule",
        },
      ];
    }
    if (key === "otherMovement") {
      return [
        {
          title: "Train",
          id: "Train",
        },
        {
          title: "Avion",
          id: "Avion",
        },
        {
          title: "Taxi",
          id: "Taxi",
        },
      ];
    }
    return otherCostArray;
  };

  const onProfilAddClick = useCallback((id) => {
    // We retrieve panel, then we expand it if not expanded
    const panelNode = document.getElementById(`movements`);

    if (panelNode?.firstChild.getAttribute("aria-expanded") === "false")
      panelNode?.firstChild.click();
    // We retrieve row, then we access the expand button to click on it if not expanded
    const movementParentNode = panelNode.querySelector(
      `[data-row-key='${id}']`
    );
    const expandedButton = movementParentNode?.firstChild?.firstChild;

    if (expandedButton?.getAttribute("aria-expanded") === "false")
      expandedButton.click();
  }, []);

  return (
    <>
      <Modal
        width={840}
        open={isModalVisibleFor !== ""}
        footer={null}
        maskClosable={false}
        onCancel={() => setIsModalVisibleFor("")}
      >
        <MovementForm
          submit={{ onCancel: () => setIsModalVisibleFor("") }}
          onSubmit={onSubmit}
          formatedTransports={getFormatedTransport(isModalVisibleFor)}
          hasPicture={false}
          entityWorkId={entity_work_id}
          opportunityId={id_opportunity}
        />
      </Modal>
      {!faId && (
        <>
          <VehiculeTable
            opportunities={vehiculeOpportunities}
            movements={vehiculeMovements}
            zones={zones}
            formToken={formToken}
            id_opportunity={id_opportunity}
            createOpportunity={createOpportunity}
            deleteOpportunity={deleteOpportunity}
            updateOpportunity={updateOpportunity}
            setIsModalVisibleFor={setIsModalVisibleFor}
            isLoading={isLoading}
            onProfilAddClick={onProfilAddClick}
            from={from}
          />
          <OtherMovementTable
            opportunities={otherMovementsOpportunities}
            movements={otherMovementsMovements}
            zones={zones}
            formToken={formToken}
            id_opportunity={id_opportunity}
            createOpportunity={createOpportunity}
            deleteOpportunity={deleteOpportunity}
            updateOpportunity={updateOpportunity}
            setIsModalVisibleFor={setIsModalVisibleFor}
            isLoading={isLoading}
            onProfilAddClick={onProfilAddClick}
            from={from}
          />
        </>
      )}
      <OtherCostTable
        opportunities={otherCostsOpportunities}
        movements={otherCostsMovements}
        zones={zones}
        createOpportunity={createOpportunity}
        deleteOpportunity={deleteOpportunity}
        updateOpportunity={updateOpportunity}
        setIsModalVisibleFor={setIsModalVisibleFor}
        faId={faId}
        from={from}
      />
    </>
  );
}

export default MovementSection;
