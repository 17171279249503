import React, { useRef, useState } from "react";
import styled from "styled-components";
import ParametersDetails from "./ParametersDetails";
import { MajorationArray } from "./MajorationArray";
import { DifficultyArray } from "./DifficultyArray";
import { UnityArray } from "./UnityArray";
import DefaultValueArray from "./DefaultValueArray";
import useTodo from "../../hookQuery/useTodo";
import { getUrlParams } from "../../utils";
import { useStore } from "../../store";
import TitleContainer from "../../react-ui/TitleContainer";
import Separator from "../../react-ui/Separator";
import AddButton from "../../react-ui/AddButton";
import { fetchMajorationsUrl } from "../../../utils/fetchMajorationsUrl";
import { fetchDifficultiesUrl } from "../../../utils/fetchDifficultiesUrl";
import TvaArray from "./TvaArray";

const selector = (state) => ({
  formToken: state.formToken,
  setSelectedParameter: state.setSelectedParameter,
});

function GeneralParameters({ entity_work_name, configuration, vocabulary }) {
  const { formToken, setSelectedParameter } = useStore(selector);

  const [parameterDetailsStatus, setParameterDetailsStatus] = useState({
    status: "empty",
    type: "none",
  });
  const topRef = useRef(null);
  const { data: difficulties } = useTodo(
    formToken,
    fetchDifficultiesUrl({ from: "admin", id: getUrlParams() }),
    "Difficulties"
  );
  const { data: majorations } = useTodo(
    formToken,
    fetchMajorationsUrl({ from: "admin", id: getUrlParams() }),
    "Majorations"
  );

  const openCreation = (type) => {
    setSelectedParameter(null);
    setParameterDetailsStatus({ status: "create", type });
  };

  return (
    <Container ref={topRef}>
      <LeftContainer>
        <Wrapper>
          {configuration.general.majoration && (
            <>
              <TitleContainer label="Majorations disponibles">
                <AddButton
                  onClick={() => openCreation("majoration")}
                  disabled={
                    parameterDetailsStatus.status === "create" &&
                    parameterDetailsStatus.type === "majoration"
                  }
                  label="Majoration"
                />
              </TitleContainer>
              <MajorationArray
                setParameterDetailsStatus={setParameterDetailsStatus}
                topRef={topRef}
                majorations={majorations}
              />{" "}
            </>
          )}

          <TitleContainer label={`${vocabulary.difficulty_label}s disponibles`}>
            <AddButton
              onClick={() => openCreation("difficulty")}
              disabled={
                parameterDetailsStatus.status === "create" &&
                parameterDetailsStatus.type === "difficulty"
              }
              label="Difficulté"
            />
          </TitleContainer>
          <DifficultyArray
            setParameterDetailsStatus={setParameterDetailsStatus}
            topRef={topRef}
            difficulties={difficulties}
          />
          <TitleContainer label="Unités d'oeuvre disponibles">
            <AddButton
              onClick={() => openCreation("unity")}
              disabled={
                parameterDetailsStatus.status === "create" &&
                parameterDetailsStatus.type === "unity"
              }
              label="Unité"
            />
          </TitleContainer>
          <UnityArray
            setParameterDetailsStatus={setParameterDetailsStatus}
            topRef={topRef}
          />
          <TitleContainer label="Valeurs par défaut" />
          <DefaultValueArray
            setParameterDetailsStatus={setParameterDetailsStatus}
            parameterDetailsStatus={parameterDetailsStatus}
            topRef={topRef}
          />
        </Wrapper>
      </LeftContainer>
      <Separator />
      <ParametersDetails
        parameterDetailsStatus={parameterDetailsStatus}
        setParameterDetailsStatus={setParameterDetailsStatus}
        majorations={majorations}
        difficulties={difficulties}
        entity_work_name={entity_work_name}
        configuration={configuration}
        vocabulary={vocabulary}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45vw;
  height: 100%;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export default GeneralParameters;
