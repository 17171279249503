import React from "react";
import styled from "styled-components";

function CenteredCell({ children }) {
  return <Container>{children}</Container>;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export default CenteredCell;
