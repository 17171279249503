import { Segmented } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import GroupingForm from "./GroupingForm";
import MaterialLinked from "./MaterialLinked";
import PrestationLinked from "./PrestationLinked";
import SegmentLabel from "../../../react-ui/SegmentLabel";

const options = (configuration, vocabulary, selectedSegment, tabs) =>
  [
    {
      value: "details",
      label: (
        <SegmentLabel isSelected={selectedSegment === "details"}>
          Détails
        </SegmentLabel>
      ),
    },
    {
      value: "material_linked",
      label: (
        <SegmentLabel isSelected={selectedSegment === "material_linked"}>
          <i className={configuration.general.product_icon} />{" "}
          {vocabulary.products}
        </SegmentLabel>
      ),
    },
    {
      value: "prestation_linked",
      label: (
        <SegmentLabel isSelected={selectedSegment === "prestation_associates"}>
          <i className={configuration.general.prestation_icon} />{" "}
          {vocabulary.prestations}
        </SegmentLabel>
      ),
    },
  ].filter((tab) => tab.value === "details" || tabs.includes(tab.value));

function GroupingTabs({
  detailsStatus,
  configuration,
  initialGrouping,
  onSubmit,
  updateOnChange,
  vocabulary,
  selectedGrouping,
  updateGrouping,
}) {
  const [selectedSegment, setSelectedSegment] = useState("details");
  return (
    <Container>
      {detailsStatus === "update" && (
        <Segmented
          style={{
            position: "sticky",
            top: 0,
            zIndex: 999,
            width: "100%",
            padding: 0,
            borderRadius: 0,
          }}
          options={options(configuration, vocabulary, selectedSegment, [
            "details",
            "material_linked",
            "prestation_linked",
          ])}
          onChange={(e) => {
            // if (setItem) setItem();
            setSelectedSegment(e);
          }}
          value={selectedSegment}
        />
      )}
      {(selectedSegment === "details" || detailsStatus === "create") && (
        <GroupingForm
          onSubmit={onSubmit}
          updateOnChange={updateOnChange}
          initialGrouping={initialGrouping}
          vocabulary={vocabulary}
        />
      )}
      {selectedSegment === "material_linked" && detailsStatus !== "create" && (
        <MaterialLinked
          vocabulary={vocabulary}
          selectedGrouping={selectedGrouping}
          updateGrouping={updateGrouping}
        />
      )}
      {selectedSegment === "prestation_linked" &&
        detailsStatus !== "create" && (
          <PrestationLinked
            vocabulary={vocabulary}
            selectedGrouping={selectedGrouping}
            updateGrouping={updateGrouping}
          />
        )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
`;
export default GroupingTabs;
