import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Checkbox, notification } from "antd";

import Select from "../../../../react-ui/Select";
import Warning from "../../../../react-ui/Icons/Warning";

function LinkedOpportunity({
  opportunityId,
  updateItem,
  itemId,
  itemType,
  partialOpps = [],
}) {
  const [checked, setChecked] = useState(!!opportunityId);
  const [selected, setSelected] = useState(opportunityId);

  const partialOppsWithWarning = useMemo(
    () =>
      partialOpps?.map((pOpp) => ({
        ...pOpp,
        opportunity_name:
          pOpp.warning_opportunities?.length > 0 ? (
            <>
              <WarningIcon />
              {pOpp.opportunity_name}
            </>
          ) : (
            pOpp.opportunity_name
          ),
      })),
    [partialOpps]
  );

  useEffect(() => {
    setChecked(!!opportunityId);
    setSelected(opportunityId);
  }, [opportunityId]);

  const handleOnCheck = (e) => {
    const filteredPartialOpps = partialOpps.filter(
      (el) => (el.is_shown && el.pipe) || el.id === selected
    );
    if (!e.target.checked) {
      setChecked(false);
      updateItem({
        id: itemId,
        opportunity_id: null,
      });
      return;
    }

    if (filteredPartialOpps.length < 1) {
      notification.info({
        description:
          itemType === "new_work"
            ? "Vous n'avez pas d'opportunité de travaux neufs"
            : "Vous n'avez pas d'opportunité de maintenance",
        placement: "bottom",
        duration: 5,
      });
      return;
    }

    setChecked(true);
    updateItem({
      id: itemId,
      opportunity_id: filteredPartialOpps[0].id,
    });
  };

  const handleOnChange = (e) => {
    setSelected(e);
    updateItem({
      id: itemId,
      opportunity_id: e,
    });
  };

  return (
    <Container>
      <Checkbox checked={checked} onChange={handleOnCheck} />
      <StyledSelect
        disabled={!checked}
        size="small"
        fontSize="10px"
        onChange={handleOnChange}
        value={selected}
        options={partialOppsWithWarning.filter(
          (el) => (el.is_shown && el.pipe) || el.id === selected
        )}
        keyName="opportunity_name"
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const WarningIcon = styled(Warning)`
  margin-right: 3px;
`;

const StyledSelect = styled(Select)`
  margin-left: auto;
  width: 85%;
`;

export default LinkedOpportunity;
