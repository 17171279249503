const fetchMethodPost = (url, body, callback) => {
    fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: body
        }).then(response => response.json())
        .then(
            eval(callback)
        )
}


export {
    fetchMethodPost
};