import React from "react";
import styled from "styled-components";

function SegmentLabel({ isSelected, children }) {
  return <Text isSelected={isSelected}>{children}</Text>;
}

const Text = styled.p`
  font-size: 14px !important;
  margin: 0;
  font-weight: 400;
  color: ${({ isSelected }) =>
    isSelected ? "#1890ff" : "rgba(0, 0, 0, 0.65)"};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: #1890ff;
  }
`;

export default SegmentLabel;
