import React from "react";

const iconSorter = (
  <span
    class="ant-table-column-sorter-inner"
    style={{ display: "inline-grid" }}
  >
    <span
      role="presentation"
      aria-label="caret-up"
      class="anticon anticon-caret-up ant-table-column-sorter-up"
    >
      <svg
        viewBox="0 0 1024 1024"
        focusable="false"
        data-icon="caret-up"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
      </svg>
    </span>
    <span
      role="presentation"
      aria-label="caret-down"
      class="anticon anticon-caret-down ant-table-column-sorter-down"
    >
      <svg
        viewBox="0 0 1024 1024"
        focusable="false"
        data-icon="caret-down"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
      </svg>
    </span>
  </span>
);
const iconFilter = (
  <span role="img" aria-label="filter" class="anticon anticon-filter">
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="filter"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"></path>
    </svg>
  </span>
);
export const content_offers_tour = [
  {
    title: <h2>Découvrez la nouvelle page des offres</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Nouveautés majeures : <br />
        <ul>
          <li>Possibilité de filtrer et trier l’affichage.</li>
          <li>Accès direct aux opportunités depuis la page des offres.</li>
        </ul>
      </div>
    ),
    placement: "center",
    target: "body",
  },
  {
    title: <h2>Barre de recherche</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        La barre de recherche est accessible en permanence.
        <br />
        <br />
        Elle vous permet de rechercher des offres et des opportunités par nom,
        numéro chrono, utilisateur associé ou entreprise facturée.
        <br />
        Les résultats s’affichent directement au fur et à mesure de votre
        saisie.
      </div>
    ),
    spotlightPadding: 5,
    target: ".search__box",
  },
  {
    title: <h2>Filtre par date de création des offres</h2>,
    placement: "right",
    content: (
      <div style={{ textAlign: "left" }}>
        Le filtre par date de création de vos offres vous permet d'afficher
        rapidement ce qui vous intéresse. <br />
        <br />
        3 filtres de base ont été créés: <br />
        <ul>
          <li>6 mois: Affiche les offres créées sur les 6 derniers mois.</li>
          <li>1 an: Affiche les offres créées sur les 12 derniers mois.</li>
          <li>Toutes: Affiche toutes les offres créées.</li>
        </ul>
        Si vous souhaitez filtrer sur une période différente de celles proposées
        par défaut, vous pouvez le faire en utilisant le filtre “personnalisé”
      </div>
    ),
    spotlightPadding: 5,
    target: ".header__filter-offers",
  },
  {
    title: <h2>Nouvelle présentation de la liste des offres</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Désormais il n’y a plus qu’un seul tableau présentant toutes vos offres.
        <br />
        <br />
        Par défaut les offres “en cours” apparaissent en premier, suivies des
        offres “terminées”.
        <br />
        Le tableau dynamique vous permet de modifier l'ordre d'affichage en
        fonction de vos choix.
      </div>
    ),
    spotlightPadding: 5,
    placement: "bottom",
    target: ".table__offers",
  },
  {
    title: <h2>Affichage des opportunités associées aux offres</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Vous avez désormais accès directement aux opportunités depuis la page
        des offres.
      </div>
    ),
    spotlightPadding: 5,
    target: ".ant-table-row-expand-icon",
  },
  {
    title: <h2>Filtre et tri par “statut” des offres</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce filtre vous permet de modifier l’ordre d'affichage des offres en
        fonction de leur statut: <br />
        <br />
            {iconSorter}   Classement des statuts par ordre alphabétique
        croissant ou décroissant.
        <br />
            {iconFilter}   Affichage uniquement des statuts sélectionnés.
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__header-status",
  },
  {
    title: <h2>Filtre et tri “entreprise facturée”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce filtre vous permet de modifier l’ordre d'affichage des offres en
        fonction des entreprises facturées: <br />
        <br />
            {iconSorter}   Classement des entreprises par ordre alphabétique
        croissant ou décroissant.
        <br />
            {iconFilter}   Affichage uniquement des entreprises sélectionnées
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__header-client",
  },
  {
    title: <h2>Tri par “date limite de réponse”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce tri vous permet de modifier l’ordre d'affichage des offres en
        fonction de leur date limite de réponse: <br />
        <br />
            {iconSorter}   Classement chronologique des offres par ordre
        croissant ou décroissant.
        <br />
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__header-delivery-date",
  },
  {
    title: <h2>Tri par “chiffre d’affaires”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce tri vous permet de modifier l’ordre d'affichage des offres en
        fonction du montant de leur chiffre d’affaires: <br />
        <br />
            {iconSorter}   Classement des offres par montant de CA croissant ou
        décroissant..
        <br />
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__header-turnover",
  },
  {
    title: <h2>Tri par “agence(s)”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce tri vous permet de modifier l’ordre d'affichage des offres en
        fonction de l’agence concernée: <br />
        <br />
            {iconSorter}   Classement des offres par agences par ordre
        alphabétique croissant ou décroissant.
        <br />
            {iconFilter}   Affichage uniquement des offres relatives aux agences
        sélectionnées.
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__header-agencies",
  },
  {
    title: <h2>Tri par “propriétaire de l’offre”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce tri vous permet de modifier l’ordre d'affichage des offres en
        fonction du propriétaire de l’offre concernée: <br />
        <br />
            {iconSorter}   Classement des offres par propriétaire par ordre
        alphabétique croissant ou décroissant.
        <br />
            {iconFilter}   Affichage uniquement des offres relatives aux
        propriétaires sélectionnés.
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__header-owner",
  },
  {
    title: <h2>Bouton “Cacher une offre”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce bouton vous permet de cacher une offre (et ses opportunités). Elle
        n’apparaîtra ainsi plus dans votre environnement.
        <br />
        <br />
        Une offre “cachée” est-elle “supprimée” ?<br />
        Non ! En cachant une offre, celle-ci disparaît de votre environnement et
        ses opportunités ne sont plus prises en compte dans vos indicateurs
        commerciaux.
        <br />
        Mais elle n’est pas supprimée en base de données.
        <br />
        Cela signifie qu’une personne avec un rôle “administrateur” de la
        solution Smart4 pourra toujours accéder à cette offre et pourra décider
        de la ré-afficher si besoin.
      </div>
    ),
    spotlightPadding: 5,
    placement: "left",
    target: ".table__hide-offer",
  },
];
