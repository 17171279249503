import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useInfiniteQuery } from "react-query";
import { debounce } from "lodash";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import TitleContainer from "../../react-ui/TitleContainer";
import BaseIcon from "../../react-ui/Icons/BaseIcon";
import { useStore } from "../../store";
import { getData } from "../../request/instance";
import { fetchClientsUrl } from "../../../utils/fetchClientsUrl";
import { handleClientSorterChange } from "../../../utils/sorterTable";

const clientsColumns = ({ setClientModalOpenFrom }) => [
  {
    title: "Nom",
    dataIndex: "name",
    width: "55%",
    sorter: true,
    defaultSortOrder: "ascend",
    render: (name, record) =>
      record.isSearchedClient ? (
        <mark style={{ backgroundColor: "yellow" }}>{name}</mark>
      ) : (
        name
      ),
  },
  {
    title: "Localisation",
    dataIndex: "localisation",
    width: "40%",
    sorter: true,
    render: (_, record) =>
      `${record.city} ${record.zipcode ? `(${record.zipcode})` : ""}`,
  },
  {
    title: "",
    dataIndex: "addClient",
    width: "5%",
    render: (_, record) =>
      record.canAddClient && (
        <BaseIcon
          style={{
            fontSize: 14,
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
          className="fa-kit fa-sharp-regular-building-circle-plus"
          onClick={() => setClientModalOpenFrom(record.id)}
        />
      ),
  },
];

const selector = (state) => ({
  procurement: state.procurement,
  formToken: state.formToken,
});

const debouncedSearch = debounce(({ fetchNextPage, nextPage }) => {
  // eslint-disable-next-line no-param-reassign
  nextPage.current = 1;
  fetchNextPage({ pageParam: nextPage.current });
}, 300);

function ClientsArray({
  clients = [],
  wordEntered,
  setWordEntered,
  selectedClientId,
  setSelectedClientId,
  setStatus,
  setClientModalOpenFrom,
  setClients,
  vocabulary_application,
  vocabulary,
}) {
  const { procurement, formToken } = useStore(selector);
  const [sortBy, setSortBy] = useState({ field: "name", order: "ASC" });

  const nextPage = useRef(1);

  const { fetchNextPage, isFetching } = useInfiniteQuery(
    "Clients",
    () =>
      getData(
        formToken,
        fetchClientsUrl({
          search: wordEntered,
          sortBy,
          nextPage: nextPage.current,
        })
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (payload) => {
        const lastPage = payload.pages[payload.pages.length - 1];

        if (lastPage.current_page === 1) {
          setClients(lastPage.clients);
        } else
          setClients((old) =>
            [...old, ...lastPage.clients].filter(
              (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
            )
          );
        nextPage.current = lastPage.next_page;
      },
    }
  );

  useEffect(() => {
    debouncedSearch({ fetchNextPage, nextPage });
  }, [wordEntered, fetchNextPage, sortBy]);

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };

  const clearInput = () => {
    setWordEntered("");
  };

  const dataSource = useMemo(() => {
    const clientIsInSearch = (
      client,
      searchInParent = true,
      searchInChildren = true
    ) => {
      if (!wordEntered) return true;
      if (clients.find((c) => c.id === client.id)) return true;

      const children = clients.filter((c) => c.parent_id === client.id);
      if (
        searchInChildren &&
        children.length > 0 &&
        children.some((c) => clientIsInSearch(c, false, true))
      )
        return true;
      if (
        searchInParent &&
        client.parent_id &&
        clientIsInSearch(
          clients.find((c) => c.id === client.parent_id),
          true,
          false
        )
      )
        return true;
      return false;
    };

    const newExpandedRowKeys = [];
    const formatClients = (items, id = null) =>
      items
        ?.filter((item) => item.parent_id === id && clientIsInSearch(item))
        ?.map((item) => {
          const children = formatClients(items, item.id);
          newExpandedRowKeys.push(item.id);
          return {
            ...item,
            isSearchedClient:
              wordEntered && clients.find((c) => c.id === item.id),
            canAddClient:
              !procurement ||
              item.position === 0 ||
              (item.position === 1 &&
                clients.filter((c) => c.parent_id === item.id).length <
                  item.max_juridic_entities),
            className: id === null ? "" : "row_children_colored",
            children: children.length > 0 ? children : undefined,
          };
        });
    if (wordEntered) setExpandedRowKeys(newExpandedRowKeys);
    return formatClients(clients);
  }, [clients, procurement, wordEntered]);

  const checkScrollPosition = useCallback(
    (event) => {
      const perc =
        (event.target.scrollTop /
          (event.target.scrollHeight - event.target.clientHeight)) *
        100;
      if (perc >= 95 && !isFetching && nextPage.current !== null) {
        fetchNextPage({ pageParam: nextPage.current });
      }
    },
    [fetchNextPage, isFetching]
  );

  const handleChange = (pagination, fltrs, sorter) => {
    handleClientSorterChange({
      fltrs,
      sorter,
      setSortBy,
    });
  };

  return (
    <Container>
      <TitleContainer
        label={
          vocabulary_application.application_name === "procurement"
            ? `Liste des ${vocabulary_application.clients.clients}`
            : `Liste des ${vocabulary.clients.clients_lowercase}`
        }
      />
      <StyledSearch
        allowClear
        placeholder={
          vocabulary_application.application_name === "procurement"
            ? `Rechercher ${vocabulary_application.clients.a_client} ...`
            : `Rechercher ${vocabulary.clients.a_client} ...`
        }
        value={wordEntered}
        onChange={handleFilter}
      />
      <TableContainer
        bordered={false}
        columns={clientsColumns({ setClientModalOpenFrom })}
        rowKey="id"
        data={dataSource}
        pagination={false}
        isRowSelectable
        scrollY
        loading={isFetching}
        rowSelectableId={selectedClientId}
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedClientId(record.id);
              setStatus("show");
            },
          };
        }}
        onEndScroll={checkScrollPosition}
        onChange={handleChange}
        expandable={{
          expandedRowClassName: () => "expanded-row-class",
          expandedRowKeys,
          onExpandedRowsChange: (e) => setExpandedRowKeys(e),
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 30%;
`;

const StyledSearch = styled(Search)`
  margin-bottom: 12px;
`;

export default ClientsArray;
