import React, { memo } from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";
import Label from "../../../Form/Label";
import RuleRow from "./RuleRow";

const RulesColumn = memo(function RulesColumn({
  title,
  rules,
  id,
  moveRuleRow,
  onDrop,
}) {
  const [, drop] = useDrop(() => ({
    accept: "rule",
    hover({ id: draggedId }, monitor) {
      if (monitor.isOver() && monitor.isOver({ shallow: true }))
        moveRuleRow({
          draggedId,
          overColumnId: id,
        });
    },
  }));
  return (
    <Container>
      <StyledLabel label={title} />
      <Wrapper ref={drop}>
        {rules.map((r) => (
          <RuleRow
            key={r.id}
            id={r.id}
            rule_on={r.rule_on}
            operator={r.operator}
            value_to_compare={r.value_to_compare}
            typology={r.site_typology?.typology}
            colId={id}
            moveRuleRow={moveRuleRow}
            onDrop={onDrop}
          />
        ))}
      </Wrapper>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 300px;
`;

const Wrapper = styled.div`
  background-color: white;
  flex: 1;
  overflow: scroll;
`;

const StyledLabel = styled(Label)`
  font-size: 13px;
  margin-bottom: 8px;
`;

export default RulesColumn;
