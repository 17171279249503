import { useEffect, useState } from "react";

export function useMarginItem({ initial, profils, categories }) {
  const [hasMargin, setHasMargin] = useState(false);
  const [profilId, setProfilId] = useState();
  const [categoryId, setCategoryId] = useState();
  const [hasTVA, setHasTVA] = useState(false)

  useEffect(() => {
    if (initial) {
      setHasMargin(
        initial.margin_rate !== undefined && initial.margin_rate !== null
      );
    } else {
      setHasMargin(false);
    }
  }, [initial]);

  useEffect(() => {
    if (initial) {
      setHasTVA(
        initial.tva_entity_work_id !== undefined && initial.tva_entity_work_id !== null
      );
    } else {
      setHasTVA(false);
    }
  }, [initial]);

  useEffect(() => {
    if (initial && profils) {
      setProfilId(
        profils?.find((el) => el.id === initial.profil_profession_id)?.id
      );
    } else {
      setProfilId();
    }
  }, [initial, profils]);

  useEffect(() => {
    if (initial && categories) {
      setCategoryId(
        categories?.find((el) => el.id === initial.category_profession_id)?.id
      );
    } else {
      setCategoryId();
    }
  }, [initial, categories]);

  return {
    hasMargin,
    setHasMargin,
    profilId,
    setProfilId,
    categoryId,
    setCategoryId,
    hasTVA,
    setHasTVA
  };
}
