import { Form, notification } from "antd";
import React, { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { Loading } from "../../../loading";
import AddButton from "../../../react-ui/AddButton";
import Button from "../../../react-ui/Button";
import TitleContainer from "../../../react-ui/TitleContainer";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import ProfilForm from "./ProfilForm";
import RulesAttributions from "./RulesAttribution";
import Loader from "../../../react-ui/Loader";

const selector = (state) => ({
  formToken: state.formToken,
});

function ProfilDetails({
  profilStatus,
  setProfilStatus,
  works,
  companyWorks,
  selectedProfilRule,
  setSelectedProfilRule,
  rules,
  users,
  ruleProfilRules,
}) {
  const { formToken } = useStore(selector);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const showUnallowedUsers = (unallowedUserIds, companyWorkId) => {
    const unallowedUsers = users
      .filter((user) => unallowedUserIds.includes(user.id))
      .map((user) => user.fullname);
    const pluralSentence = unallowedUsers.length > 1;
    const workId = companyWorks.find((el) => el.id === companyWorkId).work_id;
    const workName = works.find((work) => workId === work.id).fullname;

    notification.error({
      description: `${
        pluralSentence ? "Les utilisateurs " : "L'utilisateur "
      }${unallowedUsers.join(", ")} ${
        pluralSentence ? "sont " : "est "
      } déjà dans un profil du métier ${workName}`,
      placement: "bottom",
      duration: 5,
    });
  };

  const { mutate: createProfilRule } = useMutation(
    (todo) => postData(formToken, "/profil_rule/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("ProfilRules");
      },
      onSuccess: ({ profil_rule, users_in_other_ids }) => {
        const { company_work_id } = profil_rule;
        if (users_in_other_ids?.length > 0)
          showUnallowedUsers(users_in_other_ids, company_work_id);
      },
    }
  );

  const { mutate: updateProfilRule, status } = useMutation(
    (todo) => postData(formToken, "/profil_rule/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("ProfilRules");
      },
      onSuccess: ({ profil_rule, users_in_other_ids }) => {
        const { user_ids, company_work_id } = profil_rule;
        const formUserIds = form.getFieldValue("user_ids");

        if (users_in_other_ids?.length > 0)
          showUnallowedUsers(users_in_other_ids, company_work_id);
        if (
          [...user_ids].sort().join(",") !== [...formUserIds].sort().join(",")
        ) {
          form.setFieldsValue({
            user_ids,
          });
        }
      },
    }
  );

  const { mutate: updateRuleProfilRule, status: ruleProfilRuleStatus } =
    useMutation(
      (todo) =>
        postData(
          formToken,
          "/rule_profil_rules/create_update_and_destroy",
          todo
        ),
      {
        onSuccess: (rule_profil_rules) => {
          queryClient.setQueryData(
            ["RuleProfilRules", { ruleProfilRuleId: selectedProfilRule?.id }],
            rule_profil_rules
          );
        },
      }
    );

  const closeDetails = () => {
    setProfilStatus();
    setSelectedProfilRule();
  };

  const handleSubmit = (values) => {
    const workId =
      profilStatus === "update" ? initialValues.work_id : values.work_id;
    const { name, user_ids } = values;
    const commonKeys = {
      user_ids,
      profil_rule: {
        name,
        company_work_id: companyWorks.find((el) => el.work_id === workId).id,
      },
    };

    if (profilStatus === "create") {
      createProfilRule(commonKeys);
      closeDetails();
    } else if (profilStatus === "update") {
      updateProfilRule({
        id: selectedProfilRule.id,
        ...commonKeys,
      });
    }
  };

  const initialValues = useMemo(() => {
    if (profilStatus !== "update" || !selectedProfilRule) return undefined;

    const profilCw = companyWorks.find(
      (cw) => cw.id === selectedProfilRule.company_work_id
    );
    return {
      ...selectedProfilRule,
      work_id: works.find((work) => profilCw.work_id === work.id).id,
    };
  }, [selectedProfilRule, profilStatus, works, companyWorks]);

  const getStatus = () => {
    if (status === "loading" || ruleProfilRuleStatus === "loading")
      return "loading";
    if (status === "success" || ruleProfilRuleStatus === "success")
      return "success";
    return "idle";
  };

  return (
    <Container>
      <TitleContainer label="Détails du profil">
        {profilStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={closeDetails}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="profil-form"
            />
          </>
        )}
        <Loading status={getStatus()} />
      </TitleContainer>
      {!profilStatus && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez un profil pour afficher les détails
          </p>
        </div>
      )}
      {!!profilStatus && (
        <Wrapper>
          <ProfilForm
            works={works}
            users={users}
            handleSubmit={handleSubmit}
            initialValues={initialValues}
            profilStatus={profilStatus}
            form={form}
          />
          {profilStatus === "update" &&
            (rules && ruleProfilRules ? (
              <RulesAttributions
                key={selectedProfilRule.id}
                allRules={rules}
                ruleProfilRules={ruleProfilRules}
                updateRuleProfilRule={updateRuleProfilRule}
                profilRuleId={selectedProfilRule.id}
              />
            ) : (
              <Loader />
            ))}
        </Wrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;

export default ProfilDetails;
