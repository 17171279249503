import React from "react";
import styled, { css } from "styled-components";
import Plus from "./Icons/Plus";

function CreateLink({ label = "", className, ...props }) {
  return (
    <Container className={className} {...props}>
      <Plus />
      <CreateText>{label}</CreateText>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  align-self: end;
  ${({ disabled }) =>
    disabled
      ? css`
          color: gray;
          cursor: not-allowed;
        `
      : css`
          color: ${({ theme }) => theme.colors.blue06};
          &:hover {
            color: ${({ theme }) => theme.colors.blue04};
          }
          cursor: pointer;
        `}
`;

const CreateText = styled.p`
  margin: 0 0 0 5px;
  font-size: 14px !important;
  font-weight: 600 !important;
`;

export default CreateLink;
