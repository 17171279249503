import {
  Calendar
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { fetchMethodPost } from '../../lib/fetch_method_post';
import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["position_monthly_calendar", "opportunityid", "workpositionid","planningid", "non_worked_days_list", "work_free_days_list", "full_planning_list"]
  WorkFreeDayMapping() {
    let work_free_days_json = JSON.parse(this.work_free_days_listTarget.value);
    return work_free_days_json.map((day) => {
      return {
        'id': day.id,
        'start': day.day_date,
        'allDay': true,
        rendering: 'background',
        backgroundColor: "white",
        className: "work_free_day"
      }
    })
  }
  dayWorkedNotOff(day) {
    return {
      'id': `worked_${day.day}`,
      'start': day.day,
      'allDay': true,
      backgroundColor: "green",
      rendering: 'background'
    } 
  }
  dayWorkedIsOff(day) {
    return {
      'id': `non_worked_${day.day}`,
      'start': day.day,
      'allDay': true,
      rendering: 'background',
      backgroundColor: "red"
    }
  }

  

  daysWorkedPlanningConstruction() {
    let full_planning_json = JSON.parse(this.full_planning_listTarget.value);
    let full_planning_filtered = full_planning_json.filter(day => day.is_worked)
    return full_planning_filtered.map((day) => {
      if(day.is_worked && !day.is_non_worked_day) {
        return this.dayWorkedNotOff(day)
      }  else if(day.is_worked && day.is_non_worked_day) {
        return this.dayWorkedIsOff(day)

      }
    })
  }
  connect() {
    function selectAllDays(planningId) {
      let buttonAllDays = document.getElementsByClassName("fc-selectAll-button")
      buttonAllDays[0].textContent = "En cours ..."
          fetch(`/plannings/${planningId.value}/all_days_are_worked`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "X-Requested-With": "XMLHttpRequest"
            },
            body: JSON.stringify({
              "planningId": planningId.value
            })
          }).then(response => response.json())
          .then(data => {
            for (let i = 0; i < data.non_worked_day_to_destroy.length; i++){
              let event_non_worked= calendar.getEventById( `non_worked_${data.non_worked_day_to_destroy[i].day}` );
              event_non_worked.remove();
              calendar.addEvent({
                'id': `worked_${data.non_worked_day_to_destroy[i].day}`,
                'start': data.non_worked_day_to_destroy[i].day,
                'allDay': true,
                rendering: 'background',
                backgroundColor: "green"
              })
            }
            fetch(`/plannings/${planningId.value}/update_calculation_view`, {
              headers: {
                "Accept": "text/javascript",
                "X-Requested-With": "XMLHttpRequest"
              }
            })
            .then(function(response) { return response.text() }).then(function(body) {
              eval(body);
              buttonAllDays[0].textContent = "Tous travaillés"
            }.bind(this));
            })
    }

    function unselectAllDays(planningId) {
      let buttonNoDays = document.getElementsByClassName("fc-unselectAll-button")
      buttonNoDays[0].textContent = "En cours ..."
      fetch(`/plannings/${planningId.value}/all_days_are_unworked`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "X-Requested-With": "XMLHttpRequest"
        },
        body: JSON.stringify({
          "planningId": planningId.value
        })
      }).then(response => response.json())
      .then(data => {
        for (let i = 0; i < data.worked_day_to_destroy.length; i++){
          let event_worked= calendar.getEventById( `worked_${data.worked_day_to_destroy[i]}` );
            event_worked.remove();
            calendar.addEvent({
              'id': `non_worked_${data.worked_day_to_destroy[i]}`,
              'start': data.worked_day_to_destroy[i],
              'allDay': true,
              rendering: 'background',
              backgroundColor: "red"
            })
        }
        fetch(`/plannings/${planningId.value}/update_calculation_view`, {
          headers: {
            "Accept": "text/javascript",
            "X-Requested-With": "XMLHttpRequest"
          }
        })
        .then(function(response) { return response.text() }).then(function(body) {
          eval(body);
          buttonNoDays[0].textContent = "Tous non travaillés"
        }.bind(this));
        })

        function changeTextAllWorked(text) {
          let buttonAllDays = document.getElementsByClassName("fc-selectAll-button")
            buttonAllDays[0].textContent = text
        }
}

    let planningId = this.planningidTarget;
    var calendar = new Calendar(this.position_monthly_calendarTarget, {
      plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
      fixedWeekCount: false,
      firstDay: 1,
      customButtons: {
        selectAll: {
          text: 'Tous travaillés',
          click: function() {
            selectAllDays(planningId);
          }
        },
        unselectAll: {
          text: 'Tous non travaillés',
          click: function() {
            unselectAllDays(planningId);
          }
        }
      },
      buttonText: {
        today: "Aujourd'hui"
      },
      defaultDate: JSON.parse(this.full_planning_listTarget.value)[0].day,
      showNonCurrentDates: false,
      header: {
        left: "title",
        center: "selectAll unselectAll",
        right: "prev,next today"
      },
      dateClick: (info) => {
        // fetchMethodPost({
        //   url: `/opportunities/${this.opportunityidTarget.value}/work_positions/${this.workpositionidTarget.value}/toggle_not_worked_day`,
        //   body: JSON.stringify({
        //         "day": info.dateStr
        //       }),
        //   callback: data => {
        //         if(data) {
        //           let event_worked= calendar.getEventById( `worked_${info.dateStr}` );
        //           event_worked.remove();
                  
        //           calendar.addEvent({
        //             'id': `non_worked_${info.dateStr}`,
        //             'start': info.dateStr,
        //             'allDay': true,
        //             backgroundColor: "red",
        //           })
        //         } else {
        //           let event_non_worked= calendar.getEventById( `non_worked_${info.dateStr}` );
        //           event_non_worked.remove();
        //           calendar.addEvent({
        //             'id': `worked_${info.dateStr}`,
        //             'start': info.dateStr,
        //             'allDay': true,
        //             backgroundColor: "green",
        //           })
        //         }
        //       }
        //       })
        fetch(`/plannings/${this.planningidTarget.value}/toggle_not_worked_day`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "X-Requested-With": "XMLHttpRequest"
          },
          body: JSON.stringify({
            "day": info.dateStr
          })
        }).then(response => response.json())
        .then(data => {
          if (data.non_worked_day_should_be_created) {
            let event_worked= calendar.getEventById( `worked_${info.dateStr}` );
            event_worked.remove();
            calendar.addEvent({
              'id': `non_worked_${info.dateStr}`,
              'start': info.dateStr,
              'allDay': true,
              rendering: 'background',
              backgroundColor: "red"
            })
          } else {
            let event_non_worked= calendar.getEventById( `non_worked_${info.dateStr}` );
            event_non_worked.remove();
            calendar.addEvent({
              'id': `worked_${info.dateStr}`,
              'start': info.dateStr,
              'allDay': true,
              rendering: 'background',
              backgroundColor: "green"
            })
          }
          fetch(`/plannings/${this.planningidTarget.value}/update_calculation_view`, {
            headers: {
              "Accept": "text/javascript",
              "X-Requested-With": "XMLHttpRequest"
            }
          })
          .then(function(response) { return response.text() }).then(function(body) {
            eval(body)
          }.bind(this));
          })
      },
      eventSources: [
        // Using work_free_day_list to create instances of work free  days 
        {
          events: this.WorkFreeDayMapping()
        },
        // Using non_worked_days_list to create instances of non worked days 
        {
          events: this.daysWorkedPlanningConstruction()
        }
      ]
    });
    calendar.setOption('locale', 'fr');
    calendar.render();
  }
}