import React, { useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { getData, postData } from "../../../request/instance";
import { useStore } from "../../../store";
import TableContainer from "../../CostingMovementsCarrycots/TableContainer";
import { ItemTypes } from "../../itemTypes";
import OuvrageDetailsTable from "./OuvrageDetailsTable";
import Loader from "../../../react-ui/Loader";

const selector = (state) => ({
  formToken: state.formToken,
  setDesableLoadingOuvrageTree: state.setDesableLoadingOuvrageTree,
});

function AssociatedItems({
  zone,
  faId,
  id_opportunity,
  parentMaterial,
  formatZoneData,
  zoneArrayColumns,
  updateOuvrageOpportunity,
  configuration,
  showColTVA,
  from,
}) {
  const { formToken, setDesableLoadingOuvrageTree } = useStore(selector);
  const queryClient = useQueryClient();
  const [ouvrages, setOuvrages] = useState(null);
  const [data, setData] = useState([]);

  const handleMutationResponse = ({ recordType, mutaType = "update" }) => ({
    onMutate: (value) => {
      if (mutaType === "create") return {};

      const id = parseInt(value.id, 10);
      const dataIndex = data.findIndex((el) => el.id === id);
      const oldData = { ...data[dataIndex] };
      return {
        dataIndex,
        oldData,
      };
    },
    onSuccess: async (payload, _, context) => {
      if (_?.ouvrage_parent_id) {
        await setDesableLoadingOuvrageTree(true);
        await queryClient.refetchQueries([
          "Ouvrage",
          {
            id: _?.ouvrage_parent_id,
          },
        ]);
        await setDesableLoadingOuvrageTree(false);
      }
      queryClient.invalidateQueries("Warnings");
      queryClient.invalidateQueries(["Zone", zone.id]);
      if (mutaType === "create") {
        const newItem = formatZoneData({
          value: payload,
          recordType,
        });
        setData([newItem, ...data]);
        return;
      }

      const { dataIndex } = context;
      const newData = [...data];
      if (mutaType === "delete") {
        newData.splice(dataIndex, 1);
        setData(newData);
        return;
      }
      newData[dataIndex] = formatZoneData({
        value: payload,
        recordType,
      });
      setData(newData);
    },
    onError: (_, __, context) => {
      if (mutaType !== "update") return;

      const { dataIndex, oldData } = context;
      const newData = [...data];
      newData[dataIndex] = oldData;
      setData(newData);
    },
  });

  const { isLoading } = useQuery(
    ["AssociatedItems", parentMaterial.id],
    () =>
      getData(
        formToken,
        `/material_opportunity/associated_items/${parentMaterial.id}`
      ),
    {
      onSuccess: (payload) => {
        const materialOpportunities = payload.material_opportunities.map((el) =>
          formatZoneData({ value: el, zone, recordType: "material" })
        );
        const prestationOpportunities = payload.prestation_opportunities.map(
          (el) => formatZoneData({ value: el, zone, recordType: "prestation" })
        );
        setOuvrages(payload.ouvrage_opportunities);
        setData(materialOpportunities.concat(prestationOpportunities));
      },
    }
  );

  const { mutate: updatePrestationOpportunity } = useMutation(
    (todo) => postData(formToken, "/prestation_opportunity/update", todo),
    handleMutationResponse({ recordType: "prestation" })
  );

  const { mutate: updateMaterialOpportunity } = useMutation(
    (todo) => postData(formToken, "/material_opportunity/update", todo),
    handleMutationResponse({ recordType: "material" })
  );

  const { mutate: createSubMatQuotation } = useMutation(
    (todoData) =>
      postData(
        formToken,
        `/material_associate_opportunity/create_for_quotation`,
        todoData
      ),
    handleMutationResponse({ recordType: "material", mutaType: "create" })
  );

  const { mutate: createSubTableData } = useMutation(
    (todoData) =>
      postData(
        formToken,
        `/material_associate_opportunity/createMultiple`,
        todoData
      ),
    handleMutationResponse({ recordType: "material", mutaType: "create" })
  );

  const { mutate: deleteOuvrageOpportunity } = useMutation(
    (todoData) => postData(formToken, `/ouvrage_opportunity/delete`, todoData),
    handleMutationResponse({ recordType: "ouvrage", mutaType: "delete" })
  );

  const { mutate: createGroup } = useMutation(
    (todoData) =>
      postData(formToken, `/ouvrage_material_asso_oprt/create`, todoData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["AssociatedItems", parentMaterial.id]);
      },
    }
  );

  const { mutate: createSubTablePrestation } = useMutation(
    (todoData) =>
      postData(
        formToken,
        `/prestation_associate_opportunity/create?from=${from}`,
        todoData
      ),
    handleMutationResponse({ recordType: "prestation", mutaType: "create" })
  );

  const { mutate: createSubPrestaQuotation } = useMutation(
    (todoData) =>
      postData(
        formToken,
        `/prestation_associate_opportunity/create_for_quotation`,
        todoData
      ),
    handleMutationResponse({ recordType: "prestation", mutaType: "create" })
  );

  const { mutate: deleteMaterialAssoOpportunity } = useMutation(
    (todoData) =>
      postData(formToken, `/material_associate_opportunity/delete`, todoData),
    handleMutationResponse({ recordType: "material", mutaType: "delete" })
  );

  const { mutate: deleteOuvrageMaterialAssoOpportunity } = useMutation(
    (data) => postData(formToken, "/ouvrage_material_asso_oprt/delete", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["AssociatedItems", parentMaterial.id]);
      },
    }
  );

  const { mutate: deletePrestationAssoOpportunity } = useMutation(
    (todoData) =>
      postData(formToken, "/prestation_associate_opportunity/delete", todoData),
    handleMutationResponse({ recordType: "prestation", mutaType: "delete" })
  );

  const deleteItemInZone = useCallback(
    (id, value) => {
      // Delete child group
      if (value.ElementType === "O" && value.parent_ouvrage_id) {
        deleteOuvrageOpportunity({
          id,
          ouvrage_parent_id: value.parent_ouvrage_id,
        });
        return;
      }
      // Delete parent group
      if ("Elements" in value && !value.parent_ouvrage_id) {
        deleteOuvrageMaterialAssoOpportunity({
          ouvrage_opportunity_id: id,
          material_opportunity_id: parentMaterial.id,
        });
        return;
      }
      if ("prestation_profession_id" in value || value.ElementType === "P") {
        deletePrestationAssoOpportunity({
          opportunity_id: id_opportunity,
          id,
          ...(value?.ouvrage_parent_id && {
            ouvrage_parent_id: value.ouvrage_parent_id,
          }),
        });
        return;
      }
      if (value.ElementType === "M" || value?.material_profession_id) {
        deleteMaterialAssoOpportunity({
          opportunity_id: id_opportunity,
          id,
          material_opportunity_id: id,
          material_opportunity_parent_id: parentMaterial.id,
          ...(value?.ouvrage_parent_id && {
            ouvrage_parent_id: value.ouvrage_parent_id,
          }),
        });
      }
    },
    [
      deleteMaterialAssoOpportunity,
      deletePrestationAssoOpportunity,
      id_opportunity,
      parentMaterial,
    ]
  );

  const canIsOver = (monitor) => {
    if (
      monitor.getItem()?.flag === "ouvrages" &&
      monitor.getItem()?.element.group === false
    ) {
      return false;
    }
    return monitor.isOver();
  };

  const isCanDrop = (monitor) => {
    if (
      monitor.getItem()?.flag === "ouvrages" &&
      monitor.getItem()?.element.group === false
    ) {
      return true;
    }
    return monitor.didDrop();
  };

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop: (item, monitor) => {
        if (isCanDrop(monitor)) return;
        if (item.flag === "ouvrages") {
          createGroup({
            opportunity_id: id_opportunity,
            zone_id: zone.id,
            difficulty_opportunity_id: zone.difficulty_opportunity_id,
            material_opportunity_id: parentMaterial.id,
            ouvrage_profession_id: item.element.id,
            majoration_opportunity_id: zone.majoration_opportunity_id,
            carrycot_tpl_opportunity_id: zone.carrycot_tpl_opportunity_id,
          });
        }
        if (item.flag === "materials") {
          if (faId && item.element.is_from_fa) {
            createSubMatQuotation({
              opportunity_id: id_opportunity,
              material_profession_id: item.element.material_profession_id,
              zone_id: zone.id,
              material_framework_agreement_id: item.element.id,
              parent_id: parentMaterial.id,
            });
          } else
            createSubTableData({
              category_profession_id: item.element.category_profession_id,
              material_profession_id: item.element.id,
              opportunity_id: id_opportunity,
              name: item.element.name,
              description: item.element.description,
              margin_rate: item.element.margin_rate,
              cost_price_cents: item.element.cost_price_cents,
              material_position: item.element.material_position,
              selling_price_cents: 0,
              quantity: 0,
              zone_id: parentMaterial.zoneId,
              is_parent: false,
              parent_id: parentMaterial.id,
              reference_code: item.element.reference_code,
              reference_constructor: item.element.reference_constructor,
              unity_profession_id: item.element.unity_profession_id,
              manufacturer_id: item.element.manufacturer_id,
            });
        } else if (item.flag === "prestations") {
          if (faId && item.element.is_from_fa) {
            createSubPrestaQuotation({
              opportunity_id: id_opportunity,
              prestation_profession_id: item.element.prestation_profession_id,
              zone_id: zone.id,
              prestation_framework_agreement_id: item.element.id,
              parent_id: parentMaterial.id,
              is_parent: false,
              is_from_fa: true,
              carrycot_tpl_opportunity_id: zone.carrycot_tpl_opportunity_id,
              minutes: item.element.minutes,
            });
          } else
            createSubTablePrestation({
              prestation_profession_id: item.element.id,
              category_profession_id: item.element.category_profession_id,
              opportunity_id: id_opportunity,
              difficulty_opportunity_id:
                !item.element.is_package && !faId
                  ? zone.difficulty_opportunity_id
                  : null,
              majoration_opportunity_id:
                !item.element.is_package && !faId
                  ? zone.majoration_opportunity_id
                  : null,
              carrycot_tpl_opportunity_id: zone.carrycot_tpl_opportunity_id,
              difficulty_prestation: 0,
              eligible_nacelle: item.element.carrycot,
              unity_quantity: 0,
              unity_profession_id: item.element.unity_profession_id,
              name: item.element.name,
              description: item.element.description,
              hourly_rate_cents: item.element.hourly_rate_cents,
              cost_price_cents: item.element.cost_price_cents,
              margin_rate: item.element.margin_rate,
              subcontracting: item.element.subcontracting,
              maintenance: item.element.maintenance,
              speed: item.element.speed,
              speed_quantity: item.element.speed_quantity,
              estimate_quantity: item.element.quantity,
              zone_id: parentMaterial.zoneId,
              is_parent: false,
              parent_id: parentMaterial.id,
              reference_code: item.element.reference_code,
              selling_price_cents: 0,
              profil_profession_id: item.element.profil_profession_id,
              is_package: item.element.is_package,
              is_from_fa: false,
              minutes: item.element.minutes,
            });
        }
      },
      collect: (monitor) => ({
        isOver: canIsOver(monitor),
        isOverCurrent: monitor.isOver({ shallow: true }),
        canDrop: monitor.canDrop(),
      }),
    }),
    [
      createSubMatQuotation,
      createSubPrestaQuotation,
      createSubTableData,
      createSubTablePrestation,
      faId,
      id_opportunity,
      parentMaterial,
      zone,
    ]
  );

  let child_wrapped_table = "";
  const isActive = canDrop && isOver;
  if (isActive) child_wrapped_table = "child-wrapped-table-true";
  else if (canDrop) child_wrapped_table = "child-wrapped-table-false";
  const renderAsso = useCallback(() => {
    if (data?.length === 0 && ouvrages?.length > 0) {
      return <div />;
    }
    return (
      <TableContainer
        bordered
        showHeader={false}
        columns={zoneArrayColumns({
          updateMaterial: updateMaterialOpportunity,
          updatePrestation: updatePrestationOpportunity,
          deleteItem: deleteItemInZone,
          parentQuantity: parentMaterial.quantity,
        })}
        data={data}
        pagination={false}
        coloredRowOfferPage
        style={{ wordWrap: "break-word", wordBreak: "break-word" }}
      />
    );
  }, [
    data,
    ouvrages,
    zoneArrayColumns,
    updateMaterialOpportunity,
    updatePrestationOpportunity,
    deleteItemInZone,
    parentMaterial.quantity,
  ]);
  return (
    <div ref={drop} className={child_wrapped_table}>
      {isLoading && <Loader />}
      {!isLoading && (
        <ContainerStyle>
          {renderAsso()}
          <OuvragMaterialAsso
            ouvrages={ouvrages}
            formToken={formToken}
            parentMaterial={parentMaterial}
            zoneArrayColumns={zoneArrayColumns}
            updateMaterialOpportunity={updateMaterialOpportunity}
            updatePrestationOpportunity={updatePrestationOpportunity}
            updateOuvrageOpportunity={updateOuvrageOpportunity}
            deleteItemInZone={deleteItemInZone}
            zone={zone}
            showColTVA={showColTVA}
            configuration={configuration}
          />
        </ContainerStyle>
      )}
    </div>
  );
}

const OuvragMaterialAsso = ({
  ouvrages,
  formToken,
  zoneArrayColumns,
  updateMaterialOpportunity,
  updatePrestationOpportunity,
  updateOuvrageOpportunity,
  deleteItemInZone,
  zone,
  configuration,
  parentMaterial,
  showColTVA,
}) => {
  return ouvrages.map((ouvrage) => (
    <OuvrageDetailsTable
      zone={zone}
      parentOuvrage={{
        id: ouvrage.ouvrage_opportunity_id,
        quantity: parentMaterial.quantity,
      }}
      showColTVA={showColTVA}
      formToken={formToken}
      zoneArrayColumns={zoneArrayColumns}
      updateMaterialOpportunity={updateMaterialOpportunity}
      updatePrestationOpportunity={updatePrestationOpportunity}
      updateOuvrageOpportunity={updateOuvrageOpportunity}
      deleteItemInZone={deleteItemInZone}
      include_parent_in_tree
      configuration={configuration}
    />
  ));
};

const ContainerStyle = styled.div`
  border: 1px dashed #277ace;
  border-raduis: 1px;
`;
export default AssociatedItems;
