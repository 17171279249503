export const frFR = {
  general: {
    goToPreviousStepTooltip: "Aller à l'étape précédente",
  },

  fileStep: {
    initialDragDropPrompt:
      "Faites glisser le fichier CSV ici ou cliquez pour choisir dans vos fichiers",
    activeDragDropPrompt: "Déposer le CSV ici...",

    getImportError: (message) => `Erreur à l'import: ${message}`,
    getDataFormatError: (message) =>
      `Veuillez vérifier le formatage des données: ${message}`,
    goBackButton: "Retour",
    nextButton: "Choisir les colonnes",

    rawFileContentsHeading: "Contenu brut du CSV",
    previewImportHeading: "Prévisualition de l'import",
    dataHasHeadersCheckbox: "Contient un header",
    previewLoadingStatus: "Chargement prévisualition...",
  },

  fieldsStep: {
    stepSubtitle: "Choisir colonnes",
    requiredFieldsError: "Veuillez assignez tous les champs obligatoires",
    nextButton: "Importer",

    dragSourceAreaCaption: "Colonnes à importer",
    getDragSourcePageIndicator: (currentPage, pageCount) =>
      `Page ${currentPage} sur ${pageCount}`,
    getDragSourceActiveStatus: (columnCode) => `Assignez colonne ${columnCode}`,
    nextColumnsTooltip: "Afficher prochaines colonnes",
    previousColumnsTooltip: "Afficher colonnes précédentes",
    clearAssignmentTooltip: "Effacer l'affectation des colonnes",
    selectColumnTooltip: "Sélectionner la colonne à affecter",
    unselectColumnTooltip: "Désélectionner colonne",

    dragTargetAreaCaption: "Champs cibles",
    getDragTargetOptionalCaption: (field) => `${field} (optionnel)`,
    getDragTargetRequiredCaption: (field) => `${field} (obligatoire)`,
    dragTargetPlaceholder: "Faites glisser la colonne ici",
    getDragTargetAssignTooltip: (columnCode) =>
      `Assigner colonne ${columnCode}`,
    dragTargetClearTooltip: "Effacer l'affectation des colonnes",

    columnCardDummyHeader: "Champ non attribué",
    getColumnCardHeader: (code) => `Colonne ${code}`,
  },

  progressStep: {
    stepSubtitle: "Importer",
    uploadMoreButton: "Importer un nouveau CSV",
    finishButton: "Fini",
    statusError: "Impossible d'importer",
    statusComplete: "Terminé",
    statusPending: "En cours d'import...",
    processedRowsLabel: "Lignes traitées:",
  },
};
