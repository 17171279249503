import React from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { Loading } from "../../../../loading";
import AddButton from "../../../../react-ui/AddButton";
import Button from "../../../../react-ui/Button";
import TitleContainer from "../../../../react-ui/TitleContainer";
import { postData } from "../../../../request/instance";
import { useStore } from "../../../../store";
import SPForm from "./SPForm";

const selector = (state) => ({
  formToken: state.formToken,
});

function SPDetails({
  spStatus,
  setSpStatus,
  setSelectedSp,
  selectedSp,
  quotationStartDate,
  quotationEndDate,
  monthlyCalendar,
  workPositionId,
  opportunityId,
  setWorkPositionsLocal,
  specialPlannings = [],
  configApplication,
  isLockedByUser,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const getName = () => {
    if (spStatus !== "update" || !selectedSp) return "";
    const curItem = specialPlannings.find((el) => el.id === selectedSp.id);
    if (!curItem?.name) return "";
    return ` : ${curItem.name}`;
  };

  const { mutate: updateSp, status } = useMutation(
    (todo) => postData(formToken, "/special_planning/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries(["SpecialPlannings", { workPositionId }]);
      },
      onSuccess: ({ has_calendar_changed }) => {
        queryClient.invalidateQueries(["Planning", { workPositionId }]);
        queryClient.invalidateQueries(["MonthlyCalendar", { workPositionId }]);
        queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
        setWorkPositionsLocal((state) => {
          const newWp = [...state];
          const index = state.findIndex((el) => el.id === workPositionId);

          newWp[index] = {
            ...newWp[index],
            has_calendar_changed,
          };
          return newWp;
        });
      },
    }
  );

  return (
    <Container>
      <TitleContainer label={`Détails du planning spécial${getName()}`}>
        {spStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={() => setSpStatus()}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="sp-form"
            />
          </>
        )}
        <Loading status={status} />
      </TitleContainer>
      {!spStatus && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez un planning spécial pour afficher les détails
          </p>
        </div>
      )}
      {!!spStatus && (
        <Wrapper>
          <SPForm
            quotationStartDate={quotationStartDate}
            quotationEndDate={quotationEndDate}
            monthlyCalendar={monthlyCalendar}
            spStatus={spStatus}
            workPositionId={workPositionId}
            setSpStatus={setSpStatus}
            setSelectedSp={setSelectedSp}
            selectedSp={selectedSp}
            updateSp={updateSp}
            specialPlannings={specialPlannings}
            opportunityId={opportunityId}
            setWorkPositionsLocal={setWorkPositionsLocal}
            configApplication={configApplication}
            isLockedByUser={isLockedByUser}
          />
        </Wrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default SPDetails;
