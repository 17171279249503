import React from "react";
import { Collapse } from "antd";
import styled from "styled-components";
import { node, string, bool } from "prop-types";

const { Panel: AntdPanel } = Collapse;

function Panel({ children, className = "", option, ...collapseProps }) {
  return (
    <StyledPanel className={className} option={option} {...collapseProps}>
      {children}
    </StyledPanel>
  );
}

const StyledPanel = styled(AntdPanel)`
  background-color: ${({ option }) => (option ? "#d3d3d3" : "#d3e0ee")};
`;

Panel.propTypes = {
  children: node,
  className: string,
  option: bool,
};

Panel.defaultProps = {
  children: null,
  className: "",
  option: false,
};

export default Panel;
