import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Segmented } from "antd";
import styled from "styled-components";
import { useStore } from "../../../store";
import { getData } from "../../../request/instance";
import SegmentLabel from "../../../react-ui/SegmentLabel";
import TableContainer from "../../CostingMovementsCarrycots/TableContainer";
import { getMarginCol } from "../../../../constants/marginCol";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { centsToEuros } from "../../../../utils/currencyConverter";
import { numberSorter } from "../../../../utils/numberSorter";
import DraggableBodyRow from "../../../react-ui/DraggableRow";
import { stringSorter } from "../../../../utils/stringSorter";

const selector = (state) => ({
  formToken: state.formToken,
  calculMethod: state.calculMethod,
  isUsingMargin: state.isUsingMargin,
});

const options = (vocabulary, selectedSegment) => [
  {
    value: "products",
    label: (
      <SegmentLabel isSelected={selectedSegment === "products"}>
        {vocabulary.products}
      </SegmentLabel>
    ),
  },
  {
    value: "prestations",
    label: (
      <SegmentLabel isSelected={selectedSegment === "prestations"}>
        {vocabulary.prestations}
      </SegmentLabel>
    ),
  },
  {
    value: "carrycots",
    label: (
      <SegmentLabel isSelected={selectedSegment === "carrycots"}>
        {vocabulary.carrycots}
      </SegmentLabel>
    ),
  },
  {
    value: "movements",
    label: (
      <SegmentLabel isSelected={selectedSegment === "movements"}>
        {vocabulary.movements}
      </SegmentLabel>
    ),
  },
];

const columns = (
  configuration,
  isUsingMargin,
  calculMethod,
  selectedSegment
) => [
  {
    title: "Intitulé",
    dataIndex: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
    width: "30%",
  },
  {
    title: "Coût de revient",
    dataIndex: "cost_price_cents",
    width: "25%",
    sorter: (a, b) => numberSorter(a.cost_price_cents, b.cost_price_cents),
    render: (value, record) => (
      <CellRight>
        {formatNumberString({
          str: centsToEuros(
            selectedSegment === "prestations" && !record.is_package
              ? record.hourly_rate_cents
              : value
          ),
          space: true,
        })}{" "}
        €
      </CellRight>
    ),
  },
  getMarginCol({
    configuration,
    isUsingMargin,
    calculMethod,
    keyName: "margin_rate",
    width: "20%",
  }),
  {
    title: "Prix de vente HT",
    dataIndex: "selling_price_cents",
    width: "25%",
    sorter: (a, b) =>
      numberSorter(a.selling_price_cents, b.selling_price_cents),
    render: (value) => (
      <CellRight>
        {formatNumberString({
          str: centsToEuros(value),
          space: true,
        })}{" "}
        €
      </CellRight>
    ),
  },
];

function OpportunityItems({ vocabulary, opportunityId, configuration }) {
  const { formToken, calculMethod, isUsingMargin } = useStore(selector);
  const [selectedSegment, setSelectedSegment] = useState("products");

  const { data: materials } = useQuery("MaterialsOpportunity", () =>
    getData(formToken, `/material_opportunity?opportunity_id=${opportunityId}`)
  );

  const { data: prestations } = useQuery("PrestationsOpportunity", () =>
    getData(formToken, `/prestation_opportunity/${opportunityId}`)
  );

  const { data: carrycots } = useQuery("CarrycotsOpportunity", () =>
    getData(formToken, `/carrycot_opportunity/${opportunityId}`)
  );

  const { data: movements } = useQuery("MovementsOpportunity", () =>
    getData(formToken, `/movement_opportunity/${opportunityId}`)
  );

  const data = useMemo(() => {
    let choosenData = [];
    if (selectedSegment === "products") choosenData = materials;
    if (selectedSegment === "prestations") choosenData = prestations;
    if (selectedSegment === "carrycots") choosenData = carrycots;
    if (selectedSegment === "movements") choosenData = movements;
    return choosenData || [];
  }, [carrycots, materials, movements, prestations, selectedSegment]);

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  return (
    <Container>
      <Segmented
        options={options(vocabulary, selectedSegment)}
        onChange={(e) => {
          setSelectedSegment(e);
        }}
        value={selectedSegment}
        style={{ width: "fit-content" }}
      />
      <TableContainer
        components={components}
        bordered={false}
        columns={columns(
          configuration,
          isUsingMargin,
          calculMethod,
          selectedSegment
        )}
        coloredRowOfferPage
        data={data}
        rowKey="id"
        scroll={{ y: 400 }}
        onRow={(record, index) => {
          const itemId = `${selectedSegment}-${record.id}`;
          const attr = {
            index,
            itemId,
            record: { ...record, recordType: selectedSegment, itemId },
            droppable: false,
            type: `basketItem`,
          };
          return attr;
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CellRight = styled.div`
  display: flex;
  justify-content: end;
`;

export default OpportunityItems;
