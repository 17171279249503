import React, { useMemo } from "react";
import { Modal, Spin, Button, notification } from "antd";
import styled from "styled-components";
import { useMutation } from "react-query";
import Select from "../../../react-ui/Select";
import Delete from "../../../react-ui/Icons/Delete";
import TitleContainer from "../../../react-ui/TitleContainer";
import Input from "../../../react-ui/Input";
import { postData, deleteData } from "../../../request/instance";

function CollaboratorModal({
  isModalCollaboratorOpen,
  setIsModalCollaboratorOpen,
  record,
  token,
  setRecord,
  opportunities,
  refetchOffer,
}) {
  const { mutate: add_collaborator } = useMutation(
    (data) =>
      postData(token, `/collaborators`, {
        user_id: data.user_id,
        opportunity_id: data.opportunity_id,
      }),
    {
      onSuccess: (payload) => {
        refetchOffer();
        openNotification({
          title: payload.message,
          description: payload.description,
          backgroundColor: "#BAD6F2",
        });
      },
      onError: (payload) => {
        openNotification({
          title: payload.response.data.message,
          description: payload.response.data.description,
          backgroundColor: "#FBA89D",
        });
      },
    }
  );

  const {
    mutate: remove_collaborator,
    isLoading: isLoadingRemoveCollaborator,
  } = useMutation(
    (data) =>
      deleteData(token, `/collaborators/${data.user_id}`, {
        user_id: data.user_id,
        opportunity_id: data.opportunity_id,
      }),
    {
      onSuccess: (payload) => {
        refetchOffer();
        openNotification({
          title: payload.message,
          description: payload.description,
          backgroundColor: "#BAD6F2",
        });
      },
      onError: (payload) => {
        openNotification({
          title: payload.response.data.message,
          description: payload.response.data.description,
          backgroundColor: "#FBA89D",
        });
      },
    }
  );

  const openNotification = ({ title, description, backgroundColor }) => {
    notification.open({
      message: title,
      description,
      style: {
        backgroundColor: `${backgroundColor}`,
      },
    });
  };

  const showModal = () => {
    setIsModalCollaboratorOpen(true);
  };
  const handleOk = () => {
    setIsModalCollaboratorOpen(false);
    setRecord({});
  };
  const handleCancel = () => {
    setIsModalCollaboratorOpen(false);
    setRecord({});
  };

  const handleAddCollaborator = (value) => {
    add_collaborator({ user_id: value, opportunity_id: record.id });
  };

  const findOpportunity = () => {
    return opportunities.find((opportunity) => opportunity.id === record?.id);
  };

  return (
    <Modal
      open={isModalCollaboratorOpen}
      onCancel={handleCancel}
      onOk={handleOk}
      maskClosable={false}
    >
      <StyledTitleContainer label="Inviter des collaborateurs" />
      {/* <p>{message.content}</p> */}
      <StyledPlainText>
        <Select
          style={{ width: 180, zIndex: 1 }}
          onSelect={(e) => {
            handleAddCollaborator(e);
          }}
          options={
            findOpportunity()?.collaborators_possible?.map(
              (user) =>
                user.first_name !== null && {
                  id: user.id,
                  name: `${user.first_name} ${user.last_name}`,
                }
            ) || []
          }
        />
      </StyledPlainText>
      <StyledPlainText>
        Liste des collaborateurs de l'opportunité:
      </StyledPlainText>
      {findOpportunity()?.collaborators?.map((v) => (
        <StyledListCollaborator>
          <Input
            disabled
            value={v.user_name}
            style={{ width: "300px", margin: 5 }}
          />
          {isLoadingRemoveCollaborator ? (
            <StyledSpin />
          ) : (
            <Delete
              onClick={() =>
                remove_collaborator({
                  user_id: v.id,
                  opportunity_id: record?.id,
                })
              }
            />
          )}
        </StyledListCollaborator>
      ))}
    </Modal>
  );
}

const StyledTitleContainer = styled(TitleContainer)`
  margin: 0;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const StyledSpin = styled(Spin)`
  color: red;
`;

const StyledListCollaborator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPlainText = styled.div`
  display: flex;
  justify-content: start;
  margin: 5px;
`;

export default CollaboratorModal;
