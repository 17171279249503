// ADMIN
// add a grey background to the odd card-box in the accordion admin
$(document).ready(() => {
const materialAdmin = document.getElementById("materials-list")
const materialCosting = document.getElementById("materials-list-costing")

if(!materialAdmin && !materialCosting){
  return
}
  const addBackGroundEvenRow = (cardBoxs) => {
    if (cardBoxs) {
      $.each(cardBoxs, function (index, value) {
        $(this).addClass("greylight-background-odd");
      });
    }
  };
  addBackGroundEvenRow($(".materials-accordion div.content:even"));
  addBackGroundEvenRow($(".material_form_edition div.material-row:even"));


//add background when the item is selected
const materialsDivs = document.querySelectorAll(".materials-accordion .content");
const addBackGroundWhenMaterialDivSelected = () => {
  materialsDivs.forEach(row => row.classList.remove("selected"))
  event.currentTarget.classList.add("selected")
};

materialsDivs.forEach(row => row.addEventListener("click", addBackGroundWhenMaterialDivSelected))

});