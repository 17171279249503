import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import Search from "antd/es/transfer/search";
import TitleContainer from "../../../react-ui/TitleContainer";
import AddButton from "../../../react-ui/AddButton";
import Button from "../../../react-ui/Button";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import SearchDocFolders from "./SearchDocFolders";
import SearchFileRules from "./SearchFileRules";

const selector = (state) => ({
  formToken: state.formToken,
});

function SearchTree({
  nodeToSearch,
  closeModal,
  templateId,
  setStatus,
  constructNodeRow,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();

  const [data, setData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [checkedKeys, setCheckedKeys] = useState([]);

  const { mutate: createTemplateNodes, isLoading } = useMutation(
    (todo) => postData(formToken, "/sa_template_node/create_multiple", todo),
    {
      onSuccess: () => queryClient.invalidateQueries("Templates"),
      onError: () => setStatus("reload"),
    }
  );

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const selectUnselectChildren = useCallback(
    ({ node, checked, newCheckedKeys, fromOldData = false }) => {
      let childNodes = [];
      const newChecked = fromOldData
        ? checkedKeys.some((key) => key === node.key)
        : checked;
      setData((oldData) => {
        return oldData.map((oldNode) => {
          const newNode = { ...oldNode };
          if (newNode.key === node.key && newNode.children) {
            newNode.children = newNode.children.map((child) => ({
              ...child,
              disableCheckbox: newChecked,
            }));
            childNodes = newNode.children.map((el) => el.key);
          }
          return newNode;
        });
      });
      if (childNodes.length > 0) {
        setCheckedKeys((oldData) => {
          const newData = fromOldData ? [...oldData] : [...newCheckedKeys];
          if (newChecked) return newData.concat(childNodes);
          return newData.filter((key) => !childNodes.includes(key));
        });
        return true;
      }
      return false;
    },
    [checkedKeys]
  );

  const onCheck = useCallback(
    ({ checked: newCheckedKeys }, { checked, node }) => {
      if (node.type === "folder") {
        if (selectUnselectChildren({ node, checked, newCheckedKeys })) return;
      }

      setCheckedKeys(newCheckedKeys);
    },
    [selectUnselectChildren]
  );

  const checkProps = useMemo(
    () => ({
      checkable: true,
      checkStrictly: true,
      checkedKeys,
      setCheckedKeys,
      onCheck,
    }),
    [checkedKeys, onCheck]
  );

  const formatDataToSend = (node) => {
    if (node.type === "folder")
      return {
        name: node.name,
        position: 0,
        sa_template_id: templateId,
        folder_id: node?.id,
        parent_id: nodeToSearch?.node?.id,
        node_type: "folder",
      };
    if (node.type === "file-rule")
      return {
        name: node.name,
        position: 0,
        sa_template_id: templateId,
        file_rule_id: node?.id,
        parent_id: nodeToSearch?.node?.id,
        node_type: "file_rule",
      };
    return {
      name: node.name,
      position: 0,
      document_id: node.id,
      sa_template_id: templateId,
      parent_id: nodeToSearch?.node?.id,
      node_type: "admin_file",
    };
  };

  const findCheckedData = (allData) => {
    let toSend = [];
    allData.forEach((node) => {
      if (checkedKeys.some((key) => key === node.key) && !node.disableCheckbox)
        toSend.push(formatDataToSend(node));
      if (node.children) {
        toSend = toSend.concat(findCheckedData(node.children));
      }
    });
    return toSend;
  };

  const createNodes = () => {
    const toSend = findCheckedData(data);

    createTemplateNodes(
      { sa_template_nodes: toSend },
      {
        onSuccess: (payload) => {
          const newFoldersFiles = payload.map(constructNodeRow);
          nodeToSearch.setFoldersAndFilesInTree({
            fromHeader: nodeToSearch.fromHeader,
            newFoldersFiles,
            record: nodeToSearch.node,
          });
          closeModal();
        },
      }
    );
  };

  return (
    <Container>
      <Wrapper>
        <TitleContainer
          label={`Ajouter des ${
            nodeToSearch.from === "file-rules"
              ? "fichiers règles"
              : "dossiers/documents globaux"
          }`}
        >
          <StyledButton
            label="Annuler"
            onClick={closeModal}
            fontSize="14px"
            btnType="cancel"
          />
          <AddButton
            label="Ajouter"
            fontSize="14px"
            onClick={createNodes}
            loading={isLoading}
            disabled={isLoading || checkedKeys.length < 1}
          />
        </TitleContainer>
        <SearchTreeContainer>
          <Search
            allowClear
            placeholder="Nom de fichier"
            value={wordEntered}
            onChange={handleFilter}
          />
          {nodeToSearch.from === "file-rules" && (
            <SearchFileRules
              data={data}
              setData={setData}
              formToken={formToken}
              checkProps={checkProps}
              selectUnselectChildren={selectUnselectChildren}
              wordEntered={wordEntered}
            />
          )}
          {nodeToSearch.from === "doc-folders" && (
            <SearchDocFolders
              data={data}
              setData={setData}
              formToken={formToken}
              templateId={templateId}
              checkProps={checkProps}
              selectUnselectChildren={selectUnselectChildren}
              wordEntered={wordEntered}
            />
          )}
        </SearchTreeContainer>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 532px;
  max-width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SearchTreeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 20px;
  gap: 10px;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default SearchTree;
