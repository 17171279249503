import { encodeUrlParams } from "./encodeUrlParams";

export const fetchCarrycotsUrl = ({ from, id, search }) => {
  let url =
    from === "admin" ? "/carrycot_profession?" : "/carrycot_by_opportunity?";
  const params = {
    ...(search && { search }),
    ...(from === "admin" && { id, from }),
    ...(from === "referencing" && {
      id,
      carrycot_opportunities: true,
      include_entities_child: true,
      only_from_opp: true,
    }),
    from,
    ...(from === "frameworkAgreement" && {
      carrycot_framework_agreements: true,
      framework_agreement_id: id,
      include_entities_child: true,
      only_from_fa: true,
    }),
  };

  url += encodeUrlParams(params);

  return url;
};
