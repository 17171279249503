import { Form } from "antd";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { validateMessages } from "../../../constant";
import FormRow from "../../../Form/FormRow";
import FormCol from "../../../Form/FormCol";
import Select from "../../../react-ui/Select";
import Input from "../../../react-ui/Input";
import Label from "../../../Form/Label";
import TemplateTree from "./TemplateTree";

function TemplateForm({
  works = [],
  handleSubmit,
  initialValues,
  status,
  settableWorks = [],
  canSetGlobalWorks,
  updateOnChange = false,
  companyWorks,
  setStatus,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const worksWithGlobal = useMemo(() => {
    const worksToUse =
      status === "update" && !!initialValues
        ? works
            .filter(
              (el) =>
                settableWorks.some((w) => el.id === w.id) ||
                initialValues.works.includes(el.id)
            )
            .map((w) => {
              const disabled = !settableWorks.map((el) => el.id).includes(w.id);

              return {
                ...w,
                disabled,
              };
            })
        : settableWorks;
    if (!canSetGlobalWorks && !initialValues?.works.includes("global"))
      return [...worksToUse];
    const global = {
      id: "global",
      fullname: "Tous les métiers",
      disabled: !canSetGlobalWorks,
    };
    return [global, ...worksToUse];
  }, [status, initialValues, works, settableWorks, canSetGlobalWorks]);

  const handleGlobalSelection = ({ arr, key }) => {
    const hasGlobal = arr.some((el) => el === "global");
    const globalHasJustBeenSelected =
      hasGlobal && arr[arr.length - 1] === "global";
    const globalIsAlreadySelected = hasGlobal && !globalHasJustBeenSelected;

    if (globalHasJustBeenSelected) {
      form.setFieldValue(key, ["global"]);
      return;
    }
    if (globalIsAlreadySelected) {
      const newArr = [...arr];
      const globalIdx = arr.findIndex((el) => el === "global");
      newArr.splice(globalIdx, 1);
      form.setFieldValue(key, newArr);
      return;
    }
    form.setFieldValue(key, arr);
  };

  const onChangeWorks = (arr) => {
    handleGlobalSelection({ arr, key: "works" });
    onChange();
  };

  const onChange = () => {
    if (updateOnChange) form.submit();
  };

  const getSelectedTemplateWorks = () => {
    if (!initialValues) return "";
    const templCwIds = initialValues.sa_template_company_works.map(
      (el) => el.company_work_id
    );
    return templCwIds.length > 0
      ? works
          .filter((work) =>
            templCwIds?.includes(
              companyWorks.find((cw) => cw.work_id === work.id).id
            )
          )
          .map((work) => work.fullname)
          .join(", ")
      : "Tous les métiers";
  };

  return (
    <Form
      id="template-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      initialValues={initialValues}
      style={{ height: "100%" }}
    >
      <FormRow>
        <FormCol
          width="50%"
          label="Nom"
          name="name"
          rules={[{ required: true }]}
        >
          <Input onBlur={onChange} />
        </FormCol>
        {status === "create" &&
          (canSetGlobalWorks || settableWorks.length > 1) && (
            <FormCol
              label="Métiers"
              width="50%"
              name="works"
              rules={[{ required: true }]}
            >
              <Select
                mode="multiple"
                maxTagCount="responsive"
                showSearch={false}
                options={worksWithGlobal}
                keyName="fullname"
                onChange={onChangeWorks}
              />
            </FormCol>
          )}
        {status === "update" && (
          <WorkContainer>
            <StyledLabel label="Métiers : " />
            {getSelectedTemplateWorks()}
          </WorkContainer>
        )}
      </FormRow>
      <DndProvider backend={HTML5Backend}>
        <TemplateTree
          key={initialValues?.id}
          status={status}
          initialValues={initialValues}
          settableWorks={settableWorks}
          companyWorks={companyWorks}
          templateId={initialValues?.id}
          setStatus={setStatus}
        />
      </DndProvider>
    </Form>
  );
}

const WorkContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const StyledLabel = styled(Label)`
  white-space: nowrap;
`;

export default TemplateForm;
