import React, { useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled from "styled-components";
import Separator from "../../react-ui/Separator";
import ListSection from "./ListSection";
import CostingSection from "./CostingSection";
import { useStore } from "../../store";

const selector = (state) => ({
  setApiProvider: state.setApiProvider,
});

const Costing1 = React.memo(
  ({
    opportunityType,
    faId,
    opportunity_work,
    configuration,
    vocabulary,
    api_providers,
    minutes,
    company_work,
  }) => {
    const { setApiProvider } = useStore(selector);
    useEffect(() => {
      setApiProvider(api_providers);
    }, [api_providers]);
    return (
      <DndProvider backend={HTML5Backend}>
        <Container>
          <ListSection
            opportunityType={opportunityType}
            faId={faId}
            opportunity_work={opportunity_work}
            configuration={configuration}
            vocabulary={vocabulary}
            api_providers_length={api_providers.length}
            defaultMinutes={minutes}
            company_work={company_work}
          />
          <Separator />
          <CostingSection
            company_work={company_work}
            opportunityType={opportunityType}
            faId={faId}
            opportunity_work={opportunity_work}
            configuration={configuration}
            vocabulary={vocabulary}
            from="costing"
          />
        </Container>
      </DndProvider>
    );
  }
);

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  height: 80vh;
`;

export default React.memo(Costing1);
