import React from "react";
import btp from "../../../../app/JSON/configuration/configuration_btp.json";
import cleaning from "../../../../app/JSON/configuration/configuration_cleaning.json";
import concierge from "../../../../app/JSON/configuration/configuration_concierge.json";
import electronic_security from "../../../../app/JSON/configuration/configuration_electronic_security.json";
import energy_enc from "../../../../app/JSON/configuration/configuration_energy_enc.json";
import energy from "../../../../app/JSON/configuration/configuration_energy.json";
import extinguisher from "../../../../app/JSON/configuration/configuration_extinguisher.json";
import fire_safety from "../../../../app/JSON/configuration/configuration_fire_safety.json";
import fireextinguisher from "../../../../app/JSON/configuration/configuration_fireextinguisher.json";
// import guarding from "../../../../app/JSON/configuration/configuration_guarding.json";
import maintenance from "../../../../app/JSON/configuration/configuration_maintenance.json";
import maintenance_2 from "../../../../app/JSON/configuration/configuration_maintenance_2.json";
import multitechnic from "../../../../app/JSON/configuration/configuration_multitechnic.json";
import simple from "../../../../app/JSON/configuration/configuration_simple.json";
import software from "../../../../app/JSON/configuration/configuration_software.json";
import training from "../../../../app/JSON/configuration/configuration_training.json";
import legal from "../../../../app/JSON/configuration/configuration_legal.json";
import master from "../../../../app/JSON/configuration/configuration_master.json";
import starter from "../../../../app/JSON/configuration/configuration_starter.json";

export const FilesImport = {
  btp,
  cleaning,
  concierge,
  electronic_security,
  energy_enc,
  energy,
  extinguisher,
  fire_safety,
  fireextinguisher,
  maintenance,
  maintenance_2,
  multitechnic,
  simple,
  software,
  training,
  legal,
  master,
  starter,
};

export const getFile = (fileName) => {
  return FilesImport[fileName];
};

const iconMap = Object.values(FilesImport).map(
  (file) => file.general.work_icon
);

export const icons = Array.from(new Set(iconMap));

export const filesNames = Object.keys(FilesImport);

export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  return keys1.every(
    (key) => keys2.includes(key) && deepEqual(obj1[key], obj2[key])
  );
}
