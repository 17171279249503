import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { postData } from "../request/instance";

export default function useUpdateTodo(formToken, path, name, arrayName = [], refetchOnError = false) {
    const queryClient = useQueryClient()
    return useMutation(todo => postData(formToken, path, todo), {
        onSuccess: async data => {
            queryClient.invalidateQueries(name, {id: data.id})           
        },
        onSettled: todo => {
            if(arrayName.length > 0){
                arrayName.map((name) => {
                    queryClient.invalidateQueries(name)
                })
            }
        },
        onError: async () => {
            if (refetchOnError)
                queryClient.invalidateQueries(name)
        },
    })
}