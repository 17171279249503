import { Button, Form, Popover } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";
import FormCol from "../../Form/FormCol";
import FormRow from "../../Form/FormRow";
import Input from "../../react-ui/Input";
import { validateMessages } from "../../constant";
import { stringToFloat } from "../../../utils/stringToFloat";
import { formatNumberString } from "../../../utils/formatNumberString";
import { centsToEuros, eurosToCents } from "../../../utils/currencyConverter";
import ActionsContainer from "../../react-ui/ActionsContainer";
import { ShowDeleteConfirm } from "../confirmModal";

function HeaderForm({
  header,
  prevHeader,
  nextHeader,
  idx,
  handleHeaderChange,
  setSelectedHeader,
  type,
  excludedHeaders,
}) {
  const [form] = Form.useForm();
  const actualheader = Form.useWatch("header", form);
  const handleSubmit = (e) => {
    setSelectedHeader(undefined);
    handleHeaderChange(stringToFloat(e.header), idx);
  };

  const initialValues = useMemo(
    () => ({
      header:
        type === "period"
          ? header
          : formatNumberString({ str: centsToEuros(header) }),
    }),
    [header, type]
  );

  const sellingPriceValidation = (actualheaderFloat) => {
    if (actualheaderFloat <= 0)
      return Promise.reject(new Error("La valeur doit être supérieure à 0"));
    if (prevHeader && actualheaderFloat <= prevHeader + 1) {
      const prevStr = centsToEuros(prevHeader + 1);
      return Promise.reject(
        new Error(
          `La valeur ne peut pas être inférieure ou égale à ${formatNumberString(
            {
              str: prevStr,
              nbDecimal: 2,
            }
          )}`
        )
      );
    }
    if (nextHeader && actualheaderFloat >= nextHeader - 1) {
      const nextStr = centsToEuros(nextHeader - 1);
      return Promise.reject(
        new Error(
          `La valeur ne peut pas être supérieure ou égale à ${formatNumberString(
            {
              str: nextStr,
              nbDecimal: 2,
            }
          )}`
        )
      );
    }
    return Promise.resolve();
  };

  const periodValidation = (actualheaderFloat) => {
    if (excludedHeaders.find((el) => stringToFloat(el) === actualheaderFloat))
      return Promise.reject(
        new Error(`La période ${actualheaderFloat} a déjà été assignée`)
      );
    return Promise.resolve();
  };

  return (
    <Form
      id={`${type}-form-${idx}`}
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
      colon={false}
      requiredMark={false}
      validateMessages={validateMessages}
    >
      <FormWrapper>
        <FormRow>
          <FormCol
            label="Valeur de fin"
            rules={[
              { required: true },
              {
                validator: () => {
                  const actualheaderFloat =
                    type === "sellingPrice"
                      ? eurosToCents(stringToFloat(actualheader))
                      : stringToFloat(actualheader);
                  if (actualheaderFloat <= 0)
                    return Promise.reject(
                      new Error("La valeur doit être supérieure à 0")
                    );

                  return type === "sellingPrice"
                    ? sellingPriceValidation(actualheaderFloat)
                    : periodValidation(actualheaderFloat);
                },
              },
            ]}
            name="header"
          >
            <Input
              placeholder="Valeur de fin"
              autoComplete="off"
              onInput={(e) => {
                e.currentTarget.value = formatNumberString({
                  str: e.currentTarget.value,
                  nbDecimal: type === "period" ? 0 : 2,
                });
              }}
            />
          </FormCol>
        </FormRow>
        <FormRow marginTop="auto" justify="flex-end">
          <Button type="default" onClick={() => setSelectedHeader(undefined)}>
            Annuler
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: "5px" }}
          >
            Enregistrer
          </Button>
        </FormRow>
      </FormWrapper>
    </Form>
  );
}

function MatrixHeader({
  title,
  header,
  prevHeader,
  nextHeader,
  idx,
  handleHeaderChange,
  handleHeaderDelete,
  isDeletable,
  selectedHeader,
  setSelectedHeader,
  type,
  excludedHeaders = [],
  isCreating,
  isFavorite,
  handleDefaultPeriodChange,
}) {
  const handleClickChange = (open) => {
    setSelectedHeader(open ? idx : undefined);
  };

  const handleDelete = () => {
    setSelectedHeader(undefined);
    handleHeaderDelete(idx);
  };

  const getIconPopover = () => (
    <Popover
      destroyTooltipOnHide
      trigger="click"
      placement={type === "period" ? "topRight" : "topLeft"}
      open={selectedHeader === idx}
      onOpenChange={handleClickChange}
      title={
        <TitleWrapper>
          {isCreating ? "Ajouter" : "Modifier"}{" "}
          {type === "period" ? "la période" : "le prix de vente"}
        </TitleWrapper>
      }
      content={
        <HeaderForm
          header={header}
          prevHeader={prevHeader}
          nextHeader={nextHeader}
          idx={idx}
          handleHeaderChange={handleHeaderChange}
          setSelectedHeader={setSelectedHeader}
          type={type}
          excludedHeaders={excludedHeaders}
        />
      }
    >
      {isCreating ? (
        <Icon className="fa-duotone fa-plus" />
      ) : (
        <Icon className="fa-duotone fa-pencil-square-o" />
      )}
    </Popover>
  );

  return (
    <Header isCreating={isCreating}>
      {title && <Title>{title}</Title>}
      <ActionsContainer $colGap="8px">
        {getIconPopover()}
        {type === "period" && !isCreating && (
          <Icon
            className={`fa${isFavorite ? "" : "r"} fa-star text-warning`}
            onClick={() => handleDefaultPeriodChange(header)}
          />
        )}
        {isDeletable && (
          <Icon
            className="fa-duotone fa-trash-xmark text-danger"
            onClick={() => ShowDeleteConfirm(undefined, idx, handleDelete)}
          />
        )}
      </ActionsContainer>
    </Header>
  );
}

const Header = styled.div`
  width: 100%;
  min-height: 1.6em;
  display: flex;
  justify-content: ${({ isCreating }) =>
    isCreating ? "center" : "space-between"};
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.i`
  font-size: 11px;
`;

const Title = styled.span`
  margin-right: 8px;
`;

const FormWrapper = styled.div`
  width: 400px;
  height: 140px;
  display: flex;
  flex-direction: column;
`;

export default MatrixHeader;
