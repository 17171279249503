import React from "react";
import { numberSorter } from "../utils/numberSorter";
import { stringSorter } from "../utils/stringSorter";
import NumberCell from "./react-ui/NumberCell";

export const ZoneArrayColumns = [
  {
    title: "Intitulé",
    dataIndex: "entitled",
    key: "entitled",
    width: "23.7%",
  },
  {
    title: "S-T",
    dataIndex: "subcontracting",
    key: "subcontracting",
    width: "3.5%",
    align: "center",
  },
  {
    title: "Qté",
    children: [
      {
        title: "Estimée",
        dataIndex: "estimate",
        key: "estimate",
        width: "5.9%",
      },
      {
        title: "Chiffrée",
        dataIndex: "cipher",
        key: "cipher",
        width: "7.9%",
      },
    ],
  },
  {
    title: "Difficulté",
    dataIndex: "difficulty",
    key: "difficulty",
    width: "19.8%",
  },
  {
    title: "Nb heures",
    dataIndex: "finalquantity",
    key: "finalquantity",
    width: "7.9%",
  },
  {
    title: "Nacelle",
    dataIndex: "nacelle",
    key: "nacelle",
    width: "14.2%",
  },
  {
    title: "Majoration",
    dataIndex: "increase",
    key: "increase",
    width: "14.2%",
  },
  {
    title: "",
    dataIndex: "delete",
    key: "delete",
    align: "center",
  },
];

export const ZoneArrayQuotationColumns = [
  {
    title: "Intitulé",
    dataIndex: "entitled",
    key: "entitled",
    width: "33.7%",
  },
  {
    title: "S-T",
    dataIndex: "subcontracting",
    key: "subcontracting",
    width: "3.5%",
    align: "center",
  },
  {
    title: "Qté",
    children: [
      {
        title: "Estimée",
        dataIndex: "estimate",
        key: "estimate",
        width: "9.5%",
      },
      {
        title: "Chiffrée",
        dataIndex: "cipher",
        key: "cipher",
        width: "12.9%",
      },
    ],
  },
  {
    title: "Nb heures",
    dataIndex: "finalquantity",
    key: "finalquantity",
    width: "12.9%",
  },
  {
    title: "Nacelle",
    dataIndex: "nacelle",
    key: "nacelle",
    width: "24.2%",
  },
  {
    title: "",
    dataIndex: "delete",
    key: "delete",
    align: "center",
  },
];

export const NacelleColumns = [
  {
    title: "Intitulé",
    dataIndex: "name",
    width: "30%",
  },
  {
    title: "Profil personnel",
    dataIndex: "profil",
    width: "30%",
  },
  {
    title: "Editer",
    dataIndex: "edit",
    width: "15%",
  },
  {
    title: "Suppr.",
    dataIndex: "delete",
    width: "15%",
  },
];

export const PerformanceColumns = [
  {
    title: "Intitulé",
    dataIndex: "name",
    width: "30%",
  },
  {
    title: "Catégorie",
    dataIndex: "category",
    width: "30%",
  },
  {
    title: "Editer",
    dataIndex: "edit",
    width: "15%",
  },
  {
    title: "Suppr.",
    dataIndex: "delete",
    width: "15%",
  },
];

export const ProfilColumns = [
  {
    title: "Intitulé",
    dataIndex: "name",
    width: "30%",
  },
  {
    title: "Taux horaire",
    dataIndex: "hourly_rate",
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: "Sous-traitant",
    dataIndex: "subcontracting",
  },
  {
    title: "Editer",
    dataIndex: "edit",
    width: "10%",
  },
  {
    title: "Suppr.",
    dataIndex: "delete",
    width: "10%",
  },
];

export const ShiftColumns = [
  {
    title: "Intitulé",
    dataIndex: "name",
    width: "30%",
  },
  {
    title: "Type",
    dataIndex: "type",
  },
  {
    title: "Editer",
    dataIndex: "edit",
    width: "10%",
  },
  {
    title: "Suppr.",
    dataIndex: "delete",
    width: "10%",
  },
];

export const columnsUnity = [
  {
    title: "Intitulé",
    dataIndex: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Abréviation",
    dataIndex: "abbreviation",
    sorter: (a, b) => stringSorter(a.abbreviation, b.abbreviation),
    width: "30%",
  },
  {
    title: "",
    dataIndex: "delete",
    width: "20px",
  },
];

export const columnsMajoration = [
  {
    title: "Intitulé",
    dataIndex: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Coefficient",
    dataIndex: "rate",
    sorter: (a, b) => numberSorter(a.rate, b.rate),
    width: "30%",
    render: (value) => (value ? <NumberCell>{value}</NumberCell> : null),
  },
  {
    title: "",
    dataIndex: "delete",
    width: "20px",
  },
];

export const vehiculeColumns = [
  {
    title: "Intitulé",
    dataIndex: "movement",
    key: "movement",
    align: "center",
    width: "15%",
  },
  {
    title: "Aller/Retour",
    align: "center",
    children: [
      {
        title: "Distance",
        dataIndex: "roundtripDistance",
        key: "distance",
        align: "center",
      },
      {
        title: "Durée",
        dataIndex: "roundtripDuration",
        key: "duration",
        align: "center",
      },
      {
        title: "Qté",
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
      },
    ],
  },
  {
    title: "TOTAL",
    children: [
      {
        title: "Distance",
        dataIndex: "totalDistance",
        key: "totalDistance",
        align: "center",
      },
      {
        title: "Durée",
        dataIndex: "totalDuration",
        key: "totalDuration",
        align: "center",
      },
    ],
  },
  {
    title: "Zone",
    dataIndex: "zone",
    key: "zone",
    align: "center",
    width: "15%",
  },
  {
    title: "Nb occ.",
    dataIndex: "occupantsNum",
    key: "occupantsNum",
    align: "center",
  },
  {
    title: "S-T",
    dataIndex: "subcontracting",
    key: "subcontracting",
    align: "center",
    width: "36px",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    align: "center",
  },
];

export const otherMovementsColumns = [
  {
    title: "Intitulé",
    dataIndex: "movement",
    key: "movement",
    align: "center",
    width: "15%",
  },
  {
    title: "Aller/Retour",
    align: "center",
    children: [
      {
        title: "Coût",
        dataIndex: "cost",
        key: "cost",
        align: "center",
        width: "10%",
      },
      {
        title: "Durée",
        dataIndex: "roundtripDuration",
        key: "duration",
        align: "center",
      },
      {
        title: "Qté",
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
      },
    ],
  },
  {
    title: "TOTAL",
    children: [
      {
        title: "Coût",
        dataIndex: "totalCost",
        key: "totalCost",
        align: "center",
        width: "10%",
      },
      {
        title: "Durée",
        dataIndex: "totalDuration",
        key: "totalDuration",
        align: "center",
      },
    ],
  },
  {
    title: "Zone",
    dataIndex: "zone",
    key: "zone",
    align: "center",
    width: "15%",
  },
  {
    title: "Nb occ.",
    dataIndex: "occupantsNum",
    key: "occupantsNum",
    align: "center",
  },
  {
    title: "S-T",
    dataIndex: "subcontracting",
    key: "subcontracting",
    align: "center",
    width: "36px",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    align: "center",
  },
];

export const otherCostColumns = [
  {
    title: "Intitulé",
    dataIndex: "movement",
    key: "movement",
    align: "center",
    width: "20%",
  },
  {
    title: "Aller/Retour",
    align: "center",
    children: [
      {
        title: "Coût",
        dataIndex: "cost",
        key: "cost",
        align: "center",
      },
      {
        title: "Qté",
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
      },
    ],
  },
  {
    title: "TOTAL",
    align: "center",
    children: [
      {
        title: "Coût",
        dataIndex: "totalCost",
        key: "totalCost",
        align: "center",
      },
    ],
  },
  {
    title: "Zone",
    dataIndex: "zone",
    key: "zone",
    align: "center",
    width: "20%",
  },
  {
    title: "S-T",
    dataIndex: "subcontracting",
    key: "subcontracting",
    align: "center",
    width: "36px",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    align: "center",
  },
];

export const quotationMovementColumns = [
  {
    title: "Intitulé",
    dataIndex: "movement",
    key: "movement",
    align: "center",
    width: "20%",
  },
  {
    title: "Qté",
    dataIndex: "quantity",
    key: "quantity",
    align: "center",
  },
  {
    title: "Zone",
    dataIndex: "zone",
    key: "zone",
    align: "center",
  },
  {
    title: "S-T",
    dataIndex: "subcontracting",
    key: "subcontracting",
    align: "center",
    width: "36px",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    align: "center",
  },
];

export const quotationCarrycotColumns = [
  {
    title: "Intitulé",
    dataIndex: "carrycot",
    key: "carrycot",
    align: "center",
    width: "20%",
  },
  {
    title: "Qté",
    dataIndex: "quantity",
    key: "quantity",
    align: "center",
  },
  {
    title: "Zone",
    dataIndex: "zone",
    key: "zone",
    align: "center",
  },
  {
    title: "S-T",
    dataIndex: "subcontracting",
    key: "subcontracting",
    align: "center",
    width: "36px",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    align: "center",
  },
];

export const carrycotsColumns = [
  {
    title: "Intitulé",
    dataIndex: "carrycot",
    key: "carrycot",
    align: "center",
    width: "15%",
  },
  {
    title: "Personnel au sol",
    align: "center",
    children: [
      {
        title: "Profil",
        dataIndex: "profil",
        key: "profil",
        align: "center",
        width: "15%",
      },
      {
        title: "Durée",
        dataIndex: "duration",
        key: "duration",
        align: "center",
      },
    ],
  },
  {
    title: "Coût",
    align: "center",
    children: [
      {
        title: "Revient / j",
        dataIndex: "dailyCost",
        key: "dailyCost",
        align: "center",
      },
      {
        title: "Dépl. Aller/Retour",
        dataIndex: "movementCost",
        key: "movementCost",
        align: "center",
      },
    ],
  },
  {
    title: "Jours utilis.",
    dataIndex: "dayQuantity",
    key: "dayQuantity",
    align: "center",
  },
  {
    title: "Qté Aller/Retour",
    dataIndex: "roundtripQuantity",
    key: "roundtripQuantity",
    align: "center",
  },
  {
    title: "Zone",
    dataIndex: "zone",
    key: "zone",
    align: "center",
    width: "15%",
  },
  {
    title: "S-T",
    dataIndex: "subcontracting",
    key: "subcontracting",
    align: "center",
    width: "36px",
  },
  {
    title: "",
    dataIndex: "actions",
    key: "actions",
    align: "center",
  },
];

export const profilOpportunitiesColumns = [
  {
    title: "Profil",
    dataIndex: "profil",
    key: "profil",
    align: "center",
    width: "40%",
  },
  {
    title: "Quantité",
    dataIndex: "quantity",
    key: "quantity",
    align: "center",
    width: "5%",
  },
  {
    title: "Durée totale",
    dataIndex: "totalDuration",
    key: "totalDuration",
    align: "center",
  },
  {
    title: "",
    dataIndex: "delete",
    key: "delete",
    align: "center",
  },
];

export const materialOppColumns = [
  {
    title: "Intitulé",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Catégorie",
    dataIndex: "category",
    key: "category",
    sorter: (a, b) => stringSorter(a.category, b.category),
  },
  {
    title: "Quantité",
    dataIndex: "quantity",
    key: "quantity",
    sorter: (a, b) => numberSorter(a.quantity, b.quantity),
    render: (quantity) => <NumberCell>{quantity}</NumberCell>,
  },
];

export const prestationOuvrageColumns = [
  {
    title: "Prestation",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Majoration",
    dataIndex: "margin_rate",
    key: "margin_rate",
    sorter: (a, b) => numberSorter(a.margin_rate, b.margin_rate),
    render: (margin_rate) => <NumberCell>{margin_rate}</NumberCell>,
  },
  {
    title: "Catégorie",
    dataIndex: "category",
    key: "category",
    sorter: (a, b) => stringSorter(a.category, b.category),
  },
  {
    title: "Quantité",
    dataIndex: "quantity",
    key: "quantity",
    sorter: (a, b) => numberSorter(a.quantity, b.quantity),
    render: (quantity) => <NumberCell>{quantity}</NumberCell>,
  },
];

export const prestationOppColumns = [
  {
    title: "Prestation",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Majoration",
    dataIndex: "majoration",
    key: "majoration",
    sorter: (a, b) => stringSorter(a.majoration, b.majoration),
  },
  {
    title: "Catégorie",
    dataIndex: "category",
    key: "category",
    sorter: (a, b) => stringSorter(a.category, b.category),
  },
  {
    title: "Quantité",
    dataIndex: "quantity",
    key: "quantity",
    sorter: (a, b) => numberSorter(a.quantity, b.quantity),
    render: (quantity) => <NumberCell>{quantity}</NumberCell>,
  },
];

export const carrycotAndProfilOppColumns = [
  {
    title: "Intitulé",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Quantité",
    dataIndex: "quantity",
    key: "quantity",
    sorter: (a, b) => numberSorter(a.quantity, b.quantity),
    render: (quantity) => <NumberCell>{quantity}</NumberCell>,
  },
];

export const opportunityField = {
  workId: "work_id",
  entityId: "entity_id",
  name: "opportunity_name",
  responseType: "response_type",
  opportunityType: "opportunity_type",
  client: "client",
  submissionDate: "submission_date",
  frameworkAgreementId: "framework_agreement_id",
  endDate: "quotation_end_date",
  startDate: "quotation_start_date",
  providedCustomer: "provided_customer",
  providedCustomerId: "provided_customer_id",
  estimatedAmount: "estimated_amount",
  customerDecisionDate: "customer_decision_date",
  decisionMakerCustomer: "decision_maker_customer",
  decisionMakerCustomerId: "decision_maker_customer_id",
  typologiesOpportunityIds: "typologies_opportunity_ids",
  providedOpportunityCustomer: "providers_opportunity_ids",
  successProbability: "success_probability",
  cancellationProbability: "cancellation_probability",
  contactIds: "contact_ids",
  contacts: "contacts",
  clientContacts: "client_contacts",
  clientContactIds: "client_contact_ids",
  providedCustomerContacts: "provided_customer_contacts",
  providedCustomerContactIds: "provided_customer_contact_ids",
  decisionMakerCustomerContacts: "decision_maker_customer_contacts",
  decisionMakerCustomerContactIds: "decision_maker_customer_contact_ids",
  description: "description",
  managementTVA: "management_TVA"
};

export const validateMessages = {
  required: "Champ obligatoire.",
};

export const offerReviewFields = {
  directionContacts: "contact_offers_direction_ids",
  buyerContacts: "contact_offers_buyer_ids",
  technicContacts: "contact_offers_technic_ids",
  otherContacts: "contact_offers_other_ids",
  consultationReference: "consultation_reference",
  typeAo: "type_ao",
  variantes: "variantes",
  visiteSite: "visite_site",
  decisionCriteriaPrice: "decision_criteria_price",
  decisionCriteriaTechnic: "decision_criteria_technic",
  decisionCriteriaOther: "decision_criteria_other",
  reprisePersonnel: "reprise_personnel",
  commentsAo: "comments_ao",
  consultationObject: "consultation_object",
  kindMarket: "kind_market",
  startDateMarket: "start_date_market",
  contractLength: "contract_length",
  batchMarket: "batch_market",
  batchIdentification: "batch_identification",
  minimumPriceMarket: "minimum_price_market_cents",
  maximumPriceMarket: "maximum_price_market_cents",
  subcontracting: "subcontracting",
  priceRevisionModality: "price_revision_modality",
  commentsMarket: "comments_market",
  riskSiteOfferReviews: "risk_site_offer_review_ids",
  riskContractReviews: "risk_contract_review_ids",
  serviceOfferReviews: "service_offer_review_ids",
  commentsRiskManagement: "comments_risk_management",
  entityInChargeId: "entity_in_charge_id",
  groupResponse: "group_response",
  technicalsConform: "technicals_conform",
  contractsConform: "contracts_conform",
  optimizedOffer: "optimized_offer",
  commentsInfo: "comments_info",
  groupType: "group_type",
  mandataryType: "mandatary_type",
  endYearDiscount: "end_year_discount",
  reprisePersonnelStrategy: "reprise_personnel_strategy",
  resultAo: "result_ao",
};
