import React from "react";
import styled from "styled-components";
import { Slider } from "antd";
import FormCol from "../../../Form/FormCol";
import { opportunityField } from "../../../constant";

function SuccessProbability({ successProbability, disabled }) {
  return (
    <FormCol
      labelOnTop
      label="Probabilité de succès"
      name={opportunityField.successProbability}
      customContent={
        <ProbabilityContainer>
          {Number(successProbability).toFixed(0)} %
        </ProbabilityContainer>
      }
    >
      <Slider step={10} tooltip={{ open: false }} disabled={disabled} />
    </FormCol>
  );
}

const ProbabilityContainer = styled.div`
  align-self: end;
`;

export default SuccessProbability;
