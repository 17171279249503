import { select2 } from "select2";

const select = () => {
  $(document).ready(function () {
    $(".select2").select2();
  });
};

export { select };

//TABULATOR
import Tabulator from "tabulator-tables";
$(document).ready(() => {
  const tableContacts = document.getElementById("contacts");
  if (!tableContacts) {
    return;
  }

  if (tableContacts) {
    const contactsJson = tableContacts.getAttribute("data-info");
    const table = new Tabulator("#contacts", {
      maxHeight:
        "calc(100vh - 70px - 65.56px - 0.375rem - 0.375rem - 20px - 51px - 16px)",
      selectable: 1,
      resizableColumns: true,
      layout: "fitColumns",
      rowClick: function (e, row) {
        // use the url generate by the route contacts#infos
        fetch("/companies/1/fetch_contact_infos/" + row._row.data.id, {
          headers: {
            Accept: "text/javascript",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
          .then(function (response) {
            return response.text();
          })
          .then(
            function (body) {
              eval(body);
            }.bind(this)
          );
      },

      columns: [
        { title: "Prénom ", field: "first_name", sorter: "string" },
        { title: "Nom", field: "last_name", sorter: "string" },
        { title: "Entreprise", field: "client.name", sorter: "string" },
      ],
    });

    if (table) {
      table.setData(contactsJson);
      window.addEventListener("resize", function () {
        //trigger full rerender including all data and rows
        table.redraw(true);
      });
    }
  }

  //the first row is select when we open the page
  const contactsTableBody = document.querySelector(
    "#contacts .tabulator-table"
  );

  if (contactsTableBody) {
    if (contactsTableBody.firstChild !== null) {
      const addSelected = () => {
        contactsTableBody.firstChild.classList.add("selected-row");
      };
      const removeSelected = () => {
        contactsTableBody.firstChild.classList.remove("selected-row");
      };
      addSelected();
      contactsTableBody.addEventListener("click", removeSelected);
    }
  }

  $(document).ready(function () {
    const changeTextMaxSelectForContact = (e) => {
      const text = "Supprimez l'entreprise avant de le modifier";
      return text;
    };

    // allow the search box in the new/edit contact activity field
    const clientContact = $(".contact_client_id");
    if (clientContact) {
      $(".dropdown-client-id")
        .change()
        .select2({
          disableSearch: false,
          maximumSelectionLength: 1,
          language: { maximumSelected: changeTextMaxSelectForContact },
        });
    }
  });

  $(document).on("click", ".clickable-row", function () {
    var link = $(this).data("link");
    window.location = link;
  });
});
