import React from "react";
import styled from "styled-components";
import { useStore } from "../../store";
import useDeleteTodo from "../../hookQuery/useDeleteTodo";
import { columnsMajoration } from "../../constant";
import { ShowDeleteConfirm } from "../confirmModal";
import { getUrlParams } from "../../utils";
import ActionsContainer from "../../react-ui/ActionsContainer";
import Delete from "../../react-ui/Icons/Delete";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { formatNumberString } from "../../../utils/formatNumberString";

const selector = (state) => ({
  formToken: state.formToken,
  setSelectedParameter: state.setSelectedParameter,
});

export function MajorationArray({
  setParameterDetailsStatus,
  topRef,
  majorations,
}) {
  const { formToken, setSelectedParameter } = useStore(selector);

  const pageEntityWork = parseInt(getUrlParams(), 10);

  const { mutate: deleteMajorations } = useDeleteTodo(
    formToken,
    `/majoration_profession/delete`,
    "Majorations"
  );

  const isDeletable = (entityWorkId) => entityWorkId === pageEntityWork;

  const handleDeleteMajorations = (idMajoration) => {
    deleteMajorations(JSON.stringify({ id: idMajoration }));
  };

  const data = [];
  let child;

  majorations?.forEach((value) => {
    child = {
      id: value.id,
      key: value.id,
      name: value.name,
      rate: formatNumberString({ str: value.rate }),
      delete: isDeletable(value.entity_work_id) ? (
        <ActionsContainer>
          <Delete
            onClick={() =>
              ShowDeleteConfirm(value.name, value.id, handleDeleteMajorations)
            }
          />
        </ActionsContainer>
      ) : null,
    };
    data.push(child);
  });

  const openUpdate = (value) => {
    setSelectedParameter(value);
    setParameterDetailsStatus({ status: "update", type: "majoration" });
  };

  return (
    <StyledTable
      columns={columnsMajoration}
      dataSource={data}
      scroll={{ y: 400 }}
      pagination={false}
      rowClassName={["cursor-pointer"]}
      onRow={(record) => {
        return {
          onClick: () => {
            openUpdate(majorations.find((el) => el.id === record.id));
            topRef.current?.scrollIntoView({ behavior: "smooth" });
          },
        };
      }}
    />
  );
}

const StyledTable = styled(TableContainer)`
  margin: 10px 0;
`;
