import React, { useCallback, useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Checkbox } from "antd";
import styled from "styled-components";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import { stringSorter } from "../../../../utils/stringSorter";
import NumberCell from "../../../react-ui/NumberCell";
import { translate_filter } from "../../Categories/translate_data";
import Delete from "../../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../../confirmModal";
import FileColumn from "./FileColumn";
import { numberSorter } from "../../../../utils/numberSorter";
import Tag from "../../../react-ui/Tag";
import Warning from "../../../react-ui/Icons/Warning";

const selector = (state) => ({
  formToken: state.formToken,
});

export const documentsDataIndex = {
  file: "file",
  name: "name",
  created_at: "created_at",
  date_validity: "date_validity",
  works: "works",
  entities: "entities",
  site_typologies: "site_typologies",
  folders: "folders",
  delete: "delete",
  linked: "linked",
};

export const documentsColumns = ({
  handleDeleteDocument,
  entities,
  siteTypologies,
  works,
  companyWorks,
  folders,
  handleLinkedChange,
  columnsToShow = [],
  fromAdmin,
  supervisor,
}) =>
  [
    {
      title: "Fichier",
      dataIndex: documentsDataIndex.file,
      fixed: "left",
      width: "70px",
      sorter: (a, b) => stringSorter(a.content_type, b.content_type),
      render: (file, { name, content_type, file_url }) => (
        <FileColumn
          file={file || file_url}
          name={name}
          contentType={content_type}
        />
      ),
    },
    {
      title: "Nom du fichier",
      dataIndex: documentsDataIndex.name,
      fixed: "left",
      width: "190px",
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: "Créé le",
      dataIndex: documentsDataIndex.created_at,
      className: documentsDataIndex.created_at,
      width: "80px",
      sorter: fromAdmin
        ? (a, b) => stringSorter(a.created_at, b.created_at)
        : undefined,
      render: (created_at) =>
        created_at ? (
          <NumberCell>
            {new Date(created_at).toLocaleDateString("fr-FR")}
          </NumberCell>
        ) : null,
    },
    {
      title: "Date de validité",
      dataIndex: documentsDataIndex.date_validity,
      width: "90px",
      sorter: (a, b) => stringSorter(a.date_validity, b.date_validity),
      render: (date_validity, record) =>
        date_validity && (
          <NumberCell>
            {!record.is_valid && <StyledWarning />}{" "}
            {new Date(date_validity).toLocaleDateString("fr-FR")}
          </NumberCell>
        ),
    },
    {
      title: "Métiers",
      dataIndex: documentsDataIndex.works,
      width: "150px",
      filterMode: "tree",
      filters: [
        {
          value: "global",
          text: "Tous les métiers",
        },
      ].concat(translate_filter(works, null, null, "fullname")),
      filterSearch: true,
      onFilter: (value, record) => {
        const docCwIds = record.company_work_documents.map(
          (el) => el.company_work_id
        );
        if (value === "global") return docCwIds.length === 0;
        return (
          docCwIds &&
          docCwIds.includes(
            parseInt(
              companyWorks.find((el) => el.work_id === parseInt(value, 10)).id,
              10
            )
          )
        );
      },
      render: (worksStr) => worksStr || "",
    },
    {
      title: "Agences",
      dataIndex: documentsDataIndex.entities,
      width: "150px",
      filterMode: "tree",
      filters: [
        {
          value: "global",
          text: "Toutes les agences",
        },
      ].concat(translate_filter(entities, null, "parent_id", "name")),
      filterSearch: true,
      onFilter: (value, record) => {
        const docEntIds = record.entity_documents.map((el) => el.entity_id);
        if (value === "global") return docEntIds.length === 0;
        return docEntIds && docEntIds.includes(parseInt(value, 10));
      },
      render: (entitiesStr) => entitiesStr || "",
    },
    {
      title: "Typologies",
      dataIndex: documentsDataIndex.site_typologies,
      className: documentsDataIndex.site_typologies,
      width: "150px",
      filterMode: "tree",
      filters: fromAdmin
        ? [
            {
              value: "global",
              text: "Toutes les typologies",
            },
          ].concat(translate_filter(siteTypologies, null, null, "typology"))
        : undefined,
      filterSearch: true,
      onFilter: (value, record) => {
        const docTypoIds = record.site_typology_documents.map(
          (el) => el.site_typology_id
        );
        if (value === "global") return docTypoIds.length === 0;
        return docTypoIds && docTypoIds.includes(parseInt(value, 10));
      },
      render: (typosStr) => typosStr || "",
    },
    {
      title: "Dossiers",
      dataIndex: documentsDataIndex.folders,
      className: documentsDataIndex.folders,
      width: "150px",
      filterMode: "tree",
      filters: fromAdmin
        ? translate_filter(folders, null, null, "name")
        : undefined,
      filterSearch: true,
      onFilter: (value, record) => {
        const foldIds = record.document_folders.map((el) => el.folder_id);
        return foldIds && foldIds.includes(parseInt(value, 10));
      },
      render: (recordFolders) => (
        <TagsContainer>
          {recordFolders?.map((folder) => (
            <StyledTag
              key={folder.id}
              color={folder.color || "#f0f0f0"}
              label={folder.name}
            />
          ))}
        </TagsContainer>
      ),
    },
    {
      title: "",
      dataIndex: documentsDataIndex.delete,
      fixed: "right",
      align: "center",
      width: "20px",
      render: (_, record) =>
        record.is_editable &&
        !supervisor && (
          <Delete
            onClick={(e) => {
              e.stopPropagation();
              ShowDeleteConfirm(record.name, record.id, handleDeleteDocument);
            }}
          />
        ),
    },
    {
      title: "Lié",
      dataIndex: documentsDataIndex.linked,
      defaultSortOrder: "descend",
      fixed: "right",
      align: "center",
      width: "40px",
      sorter: (a, b) => numberSorter(a.linked, b.linked),
      render: (linked, record) => (
        <Checkbox
          checked={linked}
          disabled={record.disabled}
          onChange={(e) => handleLinkedChange(record.id, e)}
        />
      ),
    },
  ].filter((col) => columnsToShow.includes(col.dataIndex));

function DocumentsArray({
  entities = [],
  works = [],
  companyWorks = [],
  siteTypologies = [],
  documents,
  setSelectedDocument,
  setStatus,
  documentsAreLoading,
  folders = [],
  foldersAreLoading,
  fromFolderForm,
  setFolderDocuments,
  folderDocuments = [],
  fromCosting = false,
  selectedDocument,
  tableHeight,
  invalidateQuery = "Documents",
  formToken,
  supervisor,
}) {
  const queryClient = useQueryClient();
  const { formToken: contextFormToken } = useStore(selector);

  const { mutate: deleteDocument } = useMutation(
    (todo) => postData(contextFormToken || formToken, "/document/delete", todo),
    {
      onSettled: (payload) => {
        queryClient.invalidateQueries(invalidateQuery);
        queryClient.invalidateQueries("numDocuments");
        if (payload.id === selectedDocument?.id) {
          setSelectedDocument();
          setStatus("empty");
        }
      },
    }
  );

  const handleDeleteDocument = (id) => deleteDocument({ id });

  const dataSource = useMemo(() => {
    if (!documents) return [];
    return documents.map((document) => {
      const docCwIds = document.company_work_documents.map(
        (el) => el.company_work_id
      );
      const docEntIds = document.entity_documents.map((el) => el.entity_id);
      const docTypoIds = document.site_typology_documents.map(
        (el) => el.site_typology_id
      );
      const docFolderIds = document.document_folders.map((el) => el.folder_id);
      const docEntities =
        docEntIds.length > 0
          ? entities
              .filter((entity) => docEntIds?.includes(entity.id))
              .map((entity) => entity.name)
              .join(", ")
          : "Toutes les agences";
      const docWorks =
        docCwIds.length > 0
          ? works
              .filter((work) =>
                docCwIds?.includes(
                  companyWorks.find((cw) => cw.work_id === work.id).id
                )
              )
              .map((work) => work.fullname)
              .join(", ")
          : "Tous les métiers";
      const docTypos =
        docTypoIds.length > 0
          ? siteTypologies
              .filter((st) => docTypoIds?.includes(st.id))
              .map((st) => st.typology)
              .join(", ")
          : "Toutes les typologies";
      const docFolders = folders?.filter((fold) =>
        docFolderIds?.includes(fold.id)
      );

      const commonInformations = {
        ...document,
        entities: docEntities,
        works: docWorks,
        site_typologies: docTypos,
        folders: docFolders,
      };

      if (!fromFolderForm) return commonInformations;

      const linked = folderDocuments.some((fd) => fd.id === document.id);

      return {
        ...commonInformations,
        linked,
      };
    });
  }, [
    companyWorks,
    documents,
    entities,
    folderDocuments,
    folders,
    fromFolderForm,
    siteTypologies,
    works,
  ]);

  const handleLinkedChange = useCallback(
    (id, e) => {
      const document = documents.find((el) => el.id === id);
      if (e.target.checked) {
        setFolderDocuments((fds) => [...fds, document]);
      } else {
        setFolderDocuments((fds) => {
          const newFds = [...fds];
          const idxToDel = fds.findIndex((el) => el.id === document.id);
          newFds.splice(idxToDel, 1);
          return newFds;
        });
      }
    },
    [documents, setFolderDocuments]
  );

  const getScroll = () => {
    if (fromFolderForm) return { y: 400 };
    if (tableHeight) return { y: tableHeight };
    return undefined;
  };

  const columnsToShow = useMemo(() => {
    if (fromFolderForm)
      return [
        documentsDataIndex.file,
        documentsDataIndex.name,
        documentsDataIndex.created_at,
        documentsDataIndex.date_validity,
        documentsDataIndex.works,
        documentsDataIndex.entities,
        documentsDataIndex.linked,
      ];
    if (fromCosting)
      return [
        documentsDataIndex.file,
        documentsDataIndex.name,
        documentsDataIndex.created_at,
        documentsDataIndex.delete,
      ];
    return Object.keys(documentsDataIndex).filter(
      (el) => el !== documentsDataIndex.linked
    );
  }, [fromCosting, fromFolderForm]);

  return (
    <Container>
      <TableContainer
        bordered={false}
        columns={documentsColumns({
          handleDeleteDocument,
          entities,
          works,
          companyWorks,
          siteTypologies,
          folders,
          handleLinkedChange,
          columnsToShow,
          supervisor,
          fromAdmin: true,
        })}
        rowKey="id"
        dataSource={dataSource}
        rowSelectableId={selectedDocument ? selectedDocument.id : undefined}
        pagination={false}
        isRowSelectable
        onRow={(record) => {
          return {
            onClick: () => {
              if (fromFolderForm) return;
              setSelectedDocument(documents.find((el) => el.id === record.id));
              setStatus("update");
            },
          };
        }}
        scrollY={!fromFolderForm && !tableHeight}
        scroll={getScroll()}
        loading={documentsAreLoading || foldersAreLoading}
        rowClassName={(record) =>
          fromFolderForm && !record.linked ? "not-linked-row" : ""
        }
      />
    </Container>
  );
}

const TagsContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const StyledTag = styled(Tag)`
  margin-inline-end: 0 !important;
  font-weight: 500;
`;

const StyledWarning = styled(Warning)`
  font-size: 12px;
  margin-right: 5px;
`;

const Container = styled.div`
  & .not-linked-row {
    .ant-table-cell {
      color: #000000a1 !important;
    }
  }
`;

export default DocumentsArray;
