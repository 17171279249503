import { Form } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import FormRow from "../../../Form/FormRow";
import FormCol from "../../../Form/FormCol";
import Select from "../../../react-ui/Select";
import WorkIcon from "../../../react-ui/WorkIcon";
import { useStore } from "../../../store";

const selector = (state) => ({
  procurement: state.procurement,
});

function WorksSelect({
  isShowing,
  isUpdating,
  entities,
  works,
  form,
  allUsers,
  selectedUserId,
  type,
}) {
  const entityId = Form.useWatch("entity_id");
  const role = Form.useWatch("role");
  const active = Form.useWatch("active");
  const { procurement } = useStore(selector);

  const isWorkDisabled = useCallback(
    (work) => {
      if (
        procurement ||
        allUsers.find(
          (user) =>
            selectedUserId &&
            user.id === selectedUserId &&
            user.role === role &&
            user.work_ids.includes(work.id)
        )
      ) {
        return false;
      }
      const remainingLicenses =
        work.licences_roles.find((licenceRole) => licenceRole.role === role)
          .licenses_number -
        allUsers.filter(
          (user) => user.role === role && user.work_ids.includes(work.id)
        ).length;
      return remainingLicenses <= 0;
    },
    [allUsers, role, selectedUserId, procurement]
  );

  const worksFromEntity = useMemo(() => {
    if (procurement && type === "buyer")
      return works.map((work) => {
        return {
          ...work,
          name: (
            <MaxLicenses key={work.id}>
              <div>
                <WorkIcon workName={work.name} /> {work.fullname}
              </div>
            </MaxLicenses>
          ),
        };
      });

    if (!entityId || !role) return [];
    const usedEntity = entities.find((entity) => entity.id === entityId);
    return works
      .filter((work) =>
        usedEntity.works.some((workFromEntity) => work.id === workFromEntity.id)
      )
      .map((work) => {
        const disabled = isWorkDisabled(work);
        return {
          ...work,
          name: (
            <MaxLicenses key={work.id}>
              <div>
                <WorkIcon workName={work.name} /> {work.fullname}
              </div>
              {disabled && <div>(Nombre de licenses max. atteintes)</div>}
            </MaxLicenses>
          ),
          disabled,
        };
      });
  }, [entityId, role, procurement, type, works, entities, isWorkDisabled]);

  useEffect(() => {
    if (isUpdating) {
      form.setFieldValue(
        "user_entity_work_ids",
        form
          .getFieldValue("user_entity_work_ids")
          .filter((workId) =>
            worksFromEntity.some((w) => w.id === workId && !w.disabled)
          )
      );
    }
  }, [worksFromEntity, isUpdating, form]);

  return (
    <StyledFormRow>
      <FormCol
        label="Activité(s)"
        labelOnTop
        withoutPaddingRight
        name="user_entity_work_ids"
      >
        <Select
          options={worksFromEntity}
          disabled={isShowing || (selectedUserId && !active)}
          showSearch={false}
          mode="multiple"
        />
      </FormCol>
    </StyledFormRow>
  );
}

const MaxLicenses = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledFormRow = styled(FormRow)`
  padding-top: 10px !important;
`;

export default WorksSelect;
