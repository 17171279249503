import React from "react";
import LoaderGIF from "../../images/Smart4Loader.gif";

export default function LoaderAuto({ width = 20, height = 20 }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "10px",
        height: "100%",
      }}
    >
      <img
        className="Loader"
        src={LoaderGIF}
        alt={LoaderGIF}
        style={{ width, height }}
      />
    </div>
  );
}
