import React, { useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { Divider } from "antd";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import AddButton from "../../../react-ui/AddButton";
import { constructMinsOrH } from "../../../../utils/planning";
import { theme } from "../../../../theme/theme";
import Loader from "../../../react-ui/Loader";

const selector = (state) => ({
  formToken: state.formToken,
});

function MonthlyCalendar({
  workPositionId,
  planningId,
  setIsSpecialPlanningsOpen,
  monthlyCalendar,
  specialPlannings = [],
  setSelectedSp,
  opportunityId,
  setSpStatus,
  setWorkPositionsLocal,
  configApplication,
  isLockedByUser,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const ref = useRef();
  const { mutate: toggleWorkDay, isLoading } = useMutation(
    (data) =>
      postData(
        formToken,
        `/plannings/${planningId}/toggle_not_worked_day`,
        data
      ),
    {
      onSuccess: ({ has_calendar_changed }) => {
        queryClient.invalidateQueries(["Planning", { workPositionId }]);
        queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
        setWorkPositionsLocal((state) => {
          const newWp = [...state];
          const index = state.findIndex((el) => el.id === workPositionId);

          newWp[index] = {
            ...newWp[index],
            has_calendar_changed,
          };
          return newWp;
        });
      },
    }
  );

  const { mutate: toggleAllNotWorked, isLoading: isAllNonWorkedLoading } =
    useMutation(
      (data) =>
        postData(
          formToken,
          `/plannings/${planningId}/all_days_are_unworked`,
          data
        ),
      {
        onSuccess: ({ worked_day_to_destroy, has_calendar_changed }) => {
          queryClient.invalidateQueries(["Planning", { workPositionId }]);
          queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
          worked_day_to_destroy?.forEach((el) => {
            const event = ref.current.calendar.getEventById(el);
            if (event) event.setProp("backgroundColor", "red");
          });
          setWorkPositionsLocal((state) => {
            const newWp = [...state];
            const index = state.findIndex((el) => el.id === workPositionId);

            newWp[index] = {
              ...newWp[index],
              has_calendar_changed,
            };
            return newWp;
          });
        },
      }
    );

  const { mutate: toggleAllWorked, isLoading: isAllWorkedLoading } =
    useMutation(
      (data) =>
        postData(
          formToken,
          `/plannings/${planningId}/all_days_are_worked`,
          data
        ),
      {
        onSuccess: ({ non_worked_day_to_destroy, has_calendar_changed }) => {
          queryClient.invalidateQueries(["Planning", { workPositionId }]);
          queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
          non_worked_day_to_destroy?.forEach((el) => {
            const event = ref.current.calendar.getEventById(el.day);
            if (event) event.setProp("backgroundColor", "green");
          });
          setWorkPositionsLocal((state) => {
            const newWp = [...state];
            const index = state.findIndex((el) => el.id === workPositionId);

            newWp[index] = {
              ...newWp[index],
              has_calendar_changed,
            };
            return newWp;
          });
        },
      }
    );

  const dayWorkedNotOff = (day) => {
    return {
      id: `${day.day}`,
      start: day.day,
      allDay: true,
      backgroundColor: "green",
      rendering: "background",
    };
  };

  const dayWorkedIsOff = (day) => {
    return {
      id: `${day.day}`,
      start: day.day,
      allDay: true,
      rendering: "background",
      backgroundColor: "red",
    };
  };

  const constructEvents = () => {
    const events = [];
    const specialPlanningEvents = [];
    const specialPlanningDates = [];
    const usedDates = [];

    specialPlannings.forEach((el) => {
      const start = new Date(el.start_datetime);
      const startTime = new Date(
        `2020-06-01T${constructMinsOrH(start.getUTCHours())}:${constructMinsOrH(
          start.getMinutes()
        )}:00`
      );
      const end = new Date(el.end_datetime);
      const endTime = new Date(
        `2020-06-01T${constructMinsOrH(end.getUTCHours())}:${constructMinsOrH(
          end.getMinutes()
        )}:00`
      );
      el.dates.forEach((date) => {
        specialPlanningDates.push(date);
        const startDate = new Date(date);
        startDate.setHours(startTime.getHours(), startTime.getMinutes(), 0, 0);

        const endDate = new Date(date);
        endDate.setHours(endTime.getHours(), endTime.getMinutes(), 0, 0);
        const startHour = `${constructMinsOrH(
          startTime.getHours()
        )}:${constructMinsOrH(startTime.getMinutes())}`;
        const endHour = `${constructMinsOrH(
          endTime.getHours()
        )}:${constructMinsOrH(endTime.getMinutes())}`;
        const title =
          startHour === "00:00" && endHour === "00:00"
            ? "00:00"
            : `${startHour} - ${endHour}`;

        specialPlanningEvents.push({
          id: `${el.id}+${date}`,
          start: startDate,
          end: endDate,
          title,
          extendedProps: {
            id: el.id,
          },
        });
        if (!usedDates.includes(date))
          specialPlanningEvents.push({
            id: `sp-background-${date}`,
            start: startDate,
            allDay: true,
            rendering: "background",
            backgroundColor: theme.colors.blue03,
          });
        usedDates.push(date);
      });
    });

    monthlyCalendar.planning_days.forEach((day) => {
      if (day.is_worked && !specialPlanningDates.includes(day.day)) {
        if (!day.is_non_worked_day) events.push(dayWorkedNotOff(day));
        else events.push(dayWorkedIsOff(day));
      }
    });
    return events.concat(specialPlanningEvents);
  };

  const workFreeDayMapping = () => {
    return monthlyCalendar.work_free_days_list.map((day) => {
      return {
        id: day.id,
        start: day.day_date,
        allDay: true,
        rendering: "background",
        backgroundColor: "white",
        className: "work_free_day",
      };
    });
  };

  const eventClick = (info) => {
    if (info.event.extendedProps.id) {
      setSpStatus("update");
      setSelectedSp(
        specialPlannings.find((el) => el.id === info.event.extendedProps.id)
      );
      setIsSpecialPlanningsOpen(true);
    }
  };

  return (
    <Container>
      <StyledDivider style={{ marginBottom: 0 }}>Calendrier</StyledDivider>
      {monthlyCalendar ? (
        <>
          <FullCalendar
            ref={ref}
            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
            fixedWeekCount={false}
            firstDay={1}
            customButtons={{
              selectAll: {
                text: isAllWorkedLoading ? "En cours..." : "Tous travaillés",
                disabled: true,
                click: () =>
                  !isAllWorkedLoading &&
                  !isAllNonWorkedLoading &&
                  !isLoading &&
                  isLockedByUser
                    ? toggleAllWorked({ planningId })
                    : undefined,
              },
              unselectAll: {
                text: isAllNonWorkedLoading
                  ? "En cours..."
                  : "Tous non travaillés",
                click: () =>
                  !isAllWorkedLoading &&
                  !isAllNonWorkedLoading &&
                  !isLoading &&
                  isLockedByUser
                    ? toggleAllNotWorked({ planningId })
                    : undefined,
              },
            }}
            buttonText={{
              today: "Aujourd'hui",
            }}
            defaultDate={monthlyCalendar.planning_days[0]?.day}
            showNonCurrentDates={false}
            header={{
              left: "title",
              center: configApplication.guarding.workpositions
                .disable_monthly_calendar
                ? ""
                : "selectAll unselectAll",
              right: "prev,next today",
            }}
            dateClick={(info) => {
              if (
                configApplication.guarding.workpositions
                  .disable_monthly_calendar ||
                !isLockedByUser
              )
                return;
              const event = ref.current.calendar.getEventById(info.dateStr);
              if (!event) return;
              toggleWorkDay(
                { day: info.dateStr },
                {
                  onSuccess: ({ non_worked_day_should_be_created }) => {
                    event.setProp(
                      "backgroundColor",
                      non_worked_day_should_be_created ? "red" : "green"
                    );
                  },
                }
              );
            }}
            eventSources={[
              { events: workFreeDayMapping() },
              { events: constructEvents() },
            ]}
            locale="fr"
            displayEventTime={false}
            eventClick={eventClick}
          />
          <StyledAddButton
            label="Plannings spéciaux"
            disabled={
              configApplication.guarding.workpositions
                .disable_special_planning_button || !isLockedByUser
            }
            onClick={() => setIsSpecialPlanningsOpen(true)}
          />
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
}

const Container = styled.div`
  .fc-toolbar.fc-header-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
  }
  .fc-left {
    flex: 1;
    h2 {
      text-transform: capitalize;
      font-size: 18px !important;
    }
  }

  .fc-center {
    flex: 1;
    display: flex;
    gap: 8px;
    white-space: nowrap;
  }

  .fc-right {
    flex: 1;
    gap: 3px;
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
  }

  .fc-button {
    padding: 0.4em 0.5em;
    margin-left: 0;
  }
`;

const StyledAddButton = styled(AddButton)`
  margin-top: 8px;
  margin-left: auto;
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 0 !important;
  border-block-start-color: rgba(0, 0, 0, 0.88) !important;
`;

export default MonthlyCalendar;
