import React from "react";
import { instance, postData, getData } from "./instance";
import { useStore } from "../store";
import { useMutation, useQuery } from "react-query";

export const fetchUnityProfession = (formToken) => {
    const { data, isSuccess, refetch} = useQuery('getUnityProfessions', () => getData(formToken, '/unity_profession'))
    return data
}

export const updateUnitys = (formToken) => useMutation(updateTodo => postData(formToken, "/unity_profession/update", updateTodo))

export const createUnity = (formToken) => useMutation(createTodo=> postData(formToken, "/unity_profession/create", createTodo))

export const deleteUnity = (formToken) => useMutation(deleteTodo => postData(formToken, "/unity_profession/delete", deleteTodo))