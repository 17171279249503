import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import useTodo from "../../../hookQuery/useTodo";
import Collapse from "../../../react-ui/Collapse";
import Panel from "../../../react-ui/Panel";
import ContentZone from "./ContentZone";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import { zonesData } from "../../../data-utils/page3-data";
import Option from "../../../react-ui/Icons/Option";

const selector = (state) => ({
  formToken: state.formToken,
});

function Details({
  responseType,
  opportunity_work,
  configuration,
  vocabulary,
  from,
}) {
  const { formToken } = useStore(selector);
  const { id_opportunity } = useParams();

  const { data: zonesFromApi } = useQuery(
    "Zones",
    () => getData(formToken, `/zone/${id_opportunity}`),
    {
      enabled: from !== "configuration",
      refetchOnWindowFocus: false,
    }
  );

  const zonesToUse = from === "configuration" ? zonesData : zonesFromApi || [];

  const renderZoneTitle = (title, option) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>{title}</span>
      {option && <Option style={{ marginLeft: 8 }} />}
    </div>
  );

  if (!zonesToUse.length) return null;

  return zonesToUse?.map((zone) => (
    <Collapse defaultActiveKey={zone.id}>
      <Panel
        header={renderZoneTitle(zone?.entitled, zone?.option)}
        key={zone.id}
        className="details-panel"
        option={zone?.option}
      >
        <ContentZone
          zone_id={zone.id}
          responseType={responseType}
          opportunity_work={opportunity_work}
          configuration={configuration}
          vocabulary={vocabulary}
        />
      </Panel>
    </Collapse>
  ));
}

export default Details;
