import React, { useEffect, useState, useRef } from "react";
import { Cascader } from "antd";
import styled from "styled-components";
import "./TreeSelect.scss";

function TreeSelect({
  setSelectedNomenclature,
  selectedNomenclature,
  setExpendNomenclature,
  expendNomenclature,
  treeData,
  setCategoryOuvrage,
  ...treeProps
}) {
  const [allTreeData, setAllTreeData] = useState([]);
  const cascaderRef = useRef(null);
  const [cascaderWidth, setCascaderWidth] = useState(50);
  const [currentCascaderChildren, setCurrentCascaderChildren] = useState([]);
  const [loadDataOuvrage, setLoadDataOuvrage] = useState(false);
  useEffect(() => {
    const updatedData = treeData?.map((v) => ({
      id: v.Id,
      value: v.Id,
      label: (
        <div
          style={{ fontSize: 12 }}
          onClick={() => loadData([{ id: v.Id }], true)}
        >
          {v.Title}
        </div>
      ),
      isLeaf: v.IsLeaf,
    }));
    if (updatedData !== null && updatedData !== undefined) {
      setAllTreeData([...allTreeData, ...updatedData]);
    }
  }, [treeData]);

  const loadData = (selectedOptions, click = false) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    setTimeout(() => {
      setExpendNomenclature(targetOption.id);
    }, 600);

    // console.log("targetOption", selectedOptions);
    if (click) {
      setTimeout(() => {
        setSelectedNomenclature(targetOption.id);
      }, 600);
    }
  };

  useEffect(() => {
    setCascaderWidth(cascaderRef.current.offsetWidth / 9.5);
  }, []);

  useEffect(() => {
    setCurrentCascaderChildren(treeData);

    const findOptionById = (options, id) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const option of options) {
        if (option.id === id) {
          return option;
        }
        if (option.children) {
          const foundOption = findOptionById(option.children, id);
          if (foundOption) {
            return foundOption;
          }
        }
      }
      return null;
    };

    if (expendNomenclature !== null) {
      const targetOption = findOptionById(allTreeData, expendNomenclature);
      if (targetOption && !targetOption.children) {
        targetOption.children = treeData?.map((v) => ({
          id: v.Id,
          value: v.Id,
          label: (
            <div
              style={{ fontSize: 12 }}
              onClick={() => loadData([{ id: v.Id }], true)}
            >
              {v.Title}
            </div>
          ),
          isLeaf: v.IsLeaf,
        }));
        setAllTreeData([...allTreeData]);
      }
    }
  }, [treeData]);

  const onChange = (newValue, selectedOptions) => {
    setCategoryOuvrage(selectedOptions[selectedOptions.length - 1].label);
  };

  const displayRender = (labels, selectedOptions) => {
    const lastLabel = labels[labels.length - 1];
    const path = [labels[0].props.children].join(" / ");
    const truncatedPath =
      path.length > cascaderWidth ? `...${path.slice(-cascaderWidth)}` : path;

    return (
      <StyledLabelCascader title={truncatedPath}>
        {truncatedPath}
      </StyledLabelCascader>
    );
  };

  return (
    <div ref={cascaderRef}>
      <Cascader
        // treeDataSimpleMode
        // treeLine
        style={{ width: "500px" }}
        size="medium"
        expandTrigger="hover"
        displayRender={displayRender}
        placeholder="Please select"
        onChange={onChange}
        loadData={loadData}
        showSearch={{ matchInputWidth: true }}
        options={allTreeData}
        changeOnSelect
        popupClassName="test-popup"
        {...treeProps}
      />
    </div>
  );
}

const StyledLabelCascader = styled.span`
  over-flow: "hidden";
  text-overflow: "ellipsis";
  white-space: "nowrap";
`;
export default TreeSelect;
