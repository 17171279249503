import React, { useCallback, useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { debounce } from "lodash";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { getData } from "../../request/instance";
import { fetchMaterialsUrl } from "../../../utils/fetchMaterialsUrl";
import { getFetchUrlIdAndFrom } from "../../../utils/getFetchUrlIdAndFrom";
import { handleMaterialSorterChange } from "../../../utils/sorterTable";
import { useStore } from "../../store";

const debouncedSearch = debounce(({ materialsNextPage, fetchNextPage }) => {
  // eslint-disable-next-line no-param-reassign
  materialsNextPage.current = 1;
  fetchNextPage({ pageParam: materialsNextPage.current });
}, 300);

const selector = (state) => ({
  formToken: state.formToken,
  selectedMateriel: state.selectedMateriel,
  selectedFamily: state.selectedFamily,
});

function LazyMaterialArray({
  columns,
  dataSource,
  rowSelectableId,
  onRow,
  from,
  opportunityId,
  faIsValidate,
  faId,
  entityWorkId,
  materials,
  setMaterials,
  compatibleMaterials,
  wordEntered,
  categoryFiltered,
  setCategoryFiltered,
  onMaterialNewSearch,
  parent,
  ouvrageId,
  isForAssociates = false,
  adminDetails = false,
  groupingId,
}) {
  const { formToken, selectedFamily, selectedMateriel } = useStore(selector);

  const [sortBy, setSortBy] = useState(
    isForAssociates ? { field: "associated", order: "DESC" } : undefined
  );
  const materialsNextPage = useRef(1);
  // Check if useInfiniteQuery has trigger one time
  const didFirstInfiniteQuery = useRef(false);

  const getParentId = () => {
    if (parent === "grouping") {
      return groupingId;
    }
    if (parent === "ouvrage") {
      return ouvrageId;
    }
    if (isForAssociates) {
      if (from === "referencing" || from === "frameworkAgreement") {
        if (selectedMateriel?.material_included)
          return selectedMateriel?.material_included.id;
        return undefined;
      }

      if (parent === "material") {
        return selectedMateriel?.id;
      }
      return selectedFamily?.id;
    }
    return undefined;
  };

  const { fetchNextPage, isFetching } = useInfiniteQuery(
    ["Materials", { from, faId, isForAssociates }],
    () =>
      getData(
        formToken,
        fetchMaterialsUrl({
          ...getFetchUrlIdAndFrom({
            from,
            opportunityId,
            faIsValidate,
            faId,
            entityWorkId,
          }),
          nextPage: materialsNextPage.current,
          search: wordEntered,
          categoryFiltered,
          compatibleMaterials,
          sortBy,
          parent,
          parentId: getParentId(),
          adminDetails,
        })
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (payload) => {
        if (!didFirstInfiniteQuery.current)
          didFirstInfiniteQuery.current = true;
        const lastPage = payload.pages[payload.pages.length - 1];

        // Reset materials when back to first page of materials
        if (lastPage.current_page === 1) {
          setMaterials(lastPage.material_professions);
        } else setMaterials([...materials, ...lastPage.material_professions]);
        materialsNextPage.current = lastPage.next_page;
      },
    }
  );

  useEffect(() => {
    if (didFirstInfiniteQuery.current) {
      if (onMaterialNewSearch) onMaterialNewSearch();
      debouncedSearch({
        materialsNextPage,
        fetchNextPage,
      });
    }
  }, [
    wordEntered,
    fetchNextPage,
    categoryFiltered,
    sortBy,
    onMaterialNewSearch,
  ]);

  useEffect(() => {
    materialsNextPage.current = 1;
    fetchNextPage({ pageParam: materialsNextPage.current });
  }, [compatibleMaterials, fetchNextPage, ouvrageId]);

  const checkScrollPosition = useCallback(
    (event) => {
      const perc =
        (event.target.scrollTop /
          (event.target.scrollHeight - event.target.clientHeight)) *
        100;
      if (perc >= 95 && !isFetching && materialsNextPage.current !== null) {
        fetchNextPage({ pageParam: materialsNextPage.current });
      }
    },
    [fetchNextPage, isFetching]
  );

  const handleChange = (pagination, fltrs, sorter) => {
    handleMaterialSorterChange({
      pagination,
      fltrs,
      sorter,
      setCategoryFiltered,
      categoryFiltered,
      setSortBy,
    });
  };

  return (
    <TableContainer
      bordered={false}
      columns={columns}
      dataSource={dataSource}
      scrollY
      pagination={false}
      isRowSelectable
      rowSelectableId={rowSelectableId}
      onEndScroll={checkScrollPosition}
      onChange={handleChange}
      loading={isFetching}
      onRow={(record) => {
        if (onRow) return onRow(record);
        return undefined;
      }}
    />
  );
}

export default LazyMaterialArray;
