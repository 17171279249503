// add a grey background to the odd div in the each
$(document).ready(() => {

  const tableWorkPosition = document.getElementById("work-positions");
  if (!tableWorkPosition) {
    return
  }
  const addBackGround = () => {
    const formationsCardBoxs = $("div#work_position_fields:even");
    if (formationsCardBoxs) {
      $.each(formationsCardBoxs, function (index, value) {
        $(this).addClass("greylight-background-odd");
      });
    }
  }
  addBackGround()

});


//the div selected on the right has a back-ground grey when click

const allWorkPositions = document.querySelectorAll("#wp");

const addRemoveBackGround = () => {
  allWorkPositions.forEach(wp => {
    wp.classList.remove("grey-background");
  })
  event.currentTarget.classList.add("grey-background");
}

allWorkPositions.forEach(workPosition => {
  workPosition.addEventListener("click", addRemoveBackGround)
})