import React, { useState } from "react";
import Search from "antd/lib/input/Search";
import styled from "styled-components";
import { MaterielComponent } from "../Materials/MaterielComponent";

function MaterialLinked({
  categoriesFilter,
  entityWorkId,
  from,
  opportunityId,
  faIsValidate,
  faId,
  entity_work_name,
  handleFamilyChecked,
  familiesAssociates,
  configuration,
  vocabulary,
  parent,
}) {
  const [wordEntered, setWordEntered] = useState("");
  const [materials, setMaterials] = useState([]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  return (
    <>
      <StyledSearch
        allowClear
        placeholder={`Rechercher par ${vocabulary?.product_lowercase}, code article, etc...`}
        value={wordEntered}
        onChange={handleFilter}
      />
      <MaterielComponent
        parent={parent}
        wordEntered={wordEntered}
        materials={materials}
        setMaterials={setMaterials}
        categories={categoriesFilter}
        entityWorkId={entityWorkId}
        from={from}
        opportunityId={opportunityId}
        faIsValidate={faIsValidate}
        faId={faId}
        entity_work_name={entity_work_name}
        handleFamilyChecked={handleFamilyChecked}
        familiesAssociates={familiesAssociates}
        configuration={configuration}
        adminDetails
      />
    </>
  );
}

const StyledSearch = styled(Search)`
  margin: 10px 0;
`;

export default MaterialLinked;
