import React, { useCallback } from "react";
import { translate_filter } from "../../../Admin-cf/Categories/translate_data";
import { stringSorter } from "../../../../utils/stringSorter";
import NumberCell from "../../../react-ui/NumberCell";

export const ouvrageColumns = ({
  configuration,
  categoryFiltered,
  categories,
  provider,
}) => {
  const itemSelector = (flag) => {
    if (flag === "materials") {
      return configuration.general.product_icon;
    }
    if (flag === "prestations") {
      return configuration.general.prestation_icon;
    }

    return configuration.ouvrage_option.icon;
  };
  return [
    {
      title: "Intitulé",
      dataIndex: "name",
      sorter: true,
      fixed: "left",
      render: (value, globalValue) => (
        <>
          <i className={itemSelector(globalValue.flag)} />{" "}
          {/* <i
                className={
                  globalValue.flag === "materials"
                    ? `fa-duotone ${configuration.general.product_icon} mr-1`
                    : "fa-duotone fa-user-cog mr-1"
                }
              /> */}{" "}
          {value}
        </>
      ),
    },
    {
      title: "Référence",
      dataIndex: "reference_code",
      key: "reference_code",
      width: "80px",
      hidden: !configuration.costing.page_1.table_col_reference_code,
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      filters: translate_filter(categories),
      filterMode: "tree",
      filterSearch: true,
      defaultFilteredValue: categoryFiltered || [],
      filterMultiple: true,
      sorter: true,
      width: "130px",
      // align: "center",
    },
    {
      title: "",
      dataIndex: "action",
      width: "65px",
      fixed: "right",
    },
  ];
};

export const Smart4Column = ({
  configuration,
  categories,
  categoryFiltered,
}) => {
  const iconSelector = (flag, group = null) => {
    if (flag === "materials") {
      return configuration.general.product_icon;
    }
    if (flag === "prestations") {
      return configuration.general.prestation_icon;
    }
    if (flag === "ouvrages") {
      if (group) {
        return configuration.general.group_icon;
      }
      return configuration.ouvrage_option.icon;
    }
  };
  return [
    {
      title: "Intitulé",
      dataIndex: "name",
      sorter: true,
      fixed: "left",
      render: (value, globalValue) => (
        <>
          <i className={iconSelector(globalValue.flag, globalValue?.group)} />{" "}
          {value}
        </>
      ),
    },
    {
      title: "Référence",
      dataIndex: "reference_code",
      key: "reference_code",
      sorter: true,
      width: "80px",
      hidden: !configuration.costing.page_1.table_col_reference_code,
    },
    {
      title: "Catégorie",
      dataIndex: "category",
      filters: translate_filter(categories),
      filterMode: "tree",
      filterSearch: true,
      defaultFilteredValue: categoryFiltered || [],
      onFilter: (value, record) =>
        record.category_profession_id &&
        record.category_profession_id === parseInt(value, 10),
      filterMultiple: true,
      sorter: true,
      width: "110px",
      // align: "center",
    },
    {
      title: "Coût",
      dataIndex: "price",
      width: "77px",
      sorter: true,
      hidden: !configuration.costing.page_1.table_col_price,
      render: (value) => (value ? <NumberCell>{value} €</NumberCell> : null),
    },
    {
      title: "S-T",
      dataIndex: "subcontracting",
      width: "30px",
      align: "center",
      hidden:
        !configuration.general.subcontracting ||
        !configuration.costing.page_1.table_left_col_subcontracting,
    },
    {
      title: "",
      dataIndex: "action",
      width: "65px",
      fixed: "right",
    },
  ]?.filter((column) => !column.hidden);
};
