import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { Checkbox } from "antd";
import { formatNumberString } from "../../../utils/formatNumberString";
import { ShowDeleteConfirm } from "../../Admin-cf/confirmModal";
import Delete from "../../react-ui/Icons/Delete";
import FormattedInput from "../../react-ui/FormattedInput";
import AddLineButton from "../../react-ui/AddLineButton";
import { useStore } from "../../store";
import { postData } from "../../request/instance";
import DeleteDisable from "../../react-ui/Icons/DeleteDisable";

const selector = (state) => ({
  formToken: state.formToken,
});

function AgentTitle({
  wpId,
  partime,
  partime_percent,
  parent_id,
  setAgentToSplit,
  agentId,
  deleteSplitAgentMutation,
  opportunityId,
  agentNumber,
  updateAgent,
  details,
  hasDecreased,
  wpHasDecreased,
  splitted,
  agentsIdsToKeep,
  setAgentsIdsToKeep,
  futureEtp,
  configApplication,
  isAgentForJoyride,
  isLockedByUser,
}) {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);

  const { mutate: createWbMutation, isLoading: isLoadingWb } = useMutation(
    (todo) => postData(formToken, `/work_bonus/create`, todo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["WorkBonuses", { wpId }]);
        queryClient.invalidateQueries(["WorkBonuses", { distinct: true }]);
      },
    }
  );

  const { mutate: createIndemnityMutation, isLoading: isLoadingIndemnity } =
    useMutation((todo) => postData(formToken, `/indemnity/create`, todo), {
      onSuccess: () => {
        queryClient.invalidateQueries(["Indemnities", { wpId }]);
        queryClient.invalidateQueries(["Indemnities", { distinct: true }]);
      },
    });

  const deleteSplitAgent = useCallback(
    (id) => {
      deleteSplitAgentMutation(
        {
          id,
          opportunity_id: opportunityId,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["Agents", { wpId }]);
            queryClient.invalidateQueries(["WorkBonuses", { wpId }]);
            queryClient.invalidateQueries(["WorkBonuses", { distinct: true }]);
            queryClient.invalidateQueries(["Indemnities", { wpId }]);
            queryClient.invalidateQueries(["Indemnities", { distinct: true }]);
          },
        }
      );
    },
    [deleteSplitAgentMutation, opportunityId, wpId, queryClient]
  );

  const agentName = `Agent ${agentNumber} Temps
  ${
    partime
      ? `partiel ${formatNumberString({
          str: partime_percent * 100,
        })} %`
      : `plein`
  }`;

  const createWb = () => {
    createWbMutation({
      opportunity_id: opportunityId,
      agent_id: agentId,
      work_bonus: {
        name: "Nouvelle prime",
        amount_cents: 0,
        frequency: "hourly",
      },
    });
  };

  const createIndemnity = () => {
    createIndemnityMutation({
      opportunity_id: opportunityId,
      agent_id: agentId,
      indemnity: {
        name: "Nouvelle indemnité",
        amount_cents: 0,
        frequency: "hourly",
      },
    });
  };

  const checkAgent = ({ target: { checked } }) => {
    setAgentsIdsToKeep((ids) => {
      const agentIdIdx = ids.findIndex((el) => el.agentId === agentId);
      const newIds = [...ids];
      if (checked && agentIdIdx < 0) {
        newIds.push({ agentId, wpId });
      }
      if (!checked && agentIdIdx >= 0) {
        newIds.splice(agentIdIdx, 1);
      }
      return newIds;
    });
  };

  const isKeep = useMemo(() => {
    if (partime && !splitted) return true;
    if (parent_id)
      return agentsIdsToKeep.some((el) => el.agentId === parent_id);
    return agentsIdsToKeep.some((el) => el.agentId === agentId);
  }, [agentId, agentsIdsToKeep, parent_id, partime, splitted]);

  const checkboxIsDisabled = useMemo(() => {
    if (partime && (!splitted || parent_id)) return true;
    if (agentsIdsToKeep.some((el) => el.agentId === agentId)) return false;
    return (
      formatNumberString({
        str: futureEtp,
      }) ===
      formatNumberString({
        str:
          agentsIdsToKeep.filter((el) => el.wpId === wpId).length +
          (futureEtp % 1),
      })
    );
  }, [agentsIdsToKeep, futureEtp, parent_id, partime, splitted, wpId, agentId]);

  return (
    <Container>
      <AgentWrapper>
        {wpHasDecreased ? (
          <KeepContainer>
            <StyledCheckbox
              disabled={
                checkboxIsDisabled ||
                configApplication.guarding.agents.disable_checkbox ||
                !isLockedByUser
              }
              checked={isKeep}
              onChange={checkAgent}
            />
            Garder {agentName.toLowerCase()}
          </KeepContainer>
        ) : (
          agentName
        )}
        <Description
          value={details}
          keyName="details"
          updateItem={updateAgent}
          itemId={agentId}
          textArea
          placeholder="Description"
          disabled={
            hasDecreased ||
            configApplication.guarding.agents.disable_description ||
            !isLockedByUser
          }
        />
        {isAgentForJoyride && !(!hasDecreased && !partime) && (
          <SplitIconDisable
            className="fa-duotone fa-split fa-dark-grey split-agent-btn"
            title="Agent temps plein → agents temps partiels"
          />
        )}
        {!hasDecreased &&
        !partime &&
        configApplication.guarding.agents.button_delete_agent &&
        isLockedByUser ? (
          <SplitIcon
            onClick={() => setAgentToSplit(agentId, agentName)}
            className="fa-duotone fa-split split-agent-btn"
            title="Agent temps plein → agents temps partiels"
          />
        ) : (
          <SplitIconDisable
            className="fa-duotone fa-split fa-dark-grey split-agent-btn"
            title="Agent temps plein → agents temps partiels"
          />
        )}
        {configApplication.guarding.agents.button_delete_agent ? (
          !hasDecreased && parent_id && isLockedByUser ? (
            <DeleteIcon
              onClick={() => {
                ShowDeleteConfirm(agentName, agentId, deleteSplitAgent);
              }}
            />
          ) : (
            !hasDecreased && parent_id && <DeleteDisable />
          )
        ) : (
          !hasDecreased && parent_id && <DeleteDisable />
        )}
      </AgentWrapper>
      <AssociationsWrapper>
        <AssociationWrapper>
          Primes
          {!hasDecreased && (
            <StyledAddLineButton
              label="Ajouter"
              onClick={createWb}
              loading={isLoadingWb}
              disabled={
                !configApplication.guarding.agents
                  .button_create_workbonus_agent || !isLockedByUser
              }
            />
          )}
        </AssociationWrapper>
        <AssociationWrapper>
          Indemnités
          {!hasDecreased && (
            <StyledAddLineButton
              label="Ajouter"
              onClick={createIndemnity}
              loading={isLoadingIndemnity}
              disabled={
                !configApplication.guarding.agents
                  .button_create_indemnity_agent || !isLockedByUser
              }
            />
          )}
        </AssociationWrapper>
      </AssociationsWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const AgentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 50%;
`;

const KeepContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const AssociationsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  gap: 3px;
`;

const AssociationWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SplitIcon = styled.i`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.blue06};
  font-size: 18px;
  text-align: center;
  transition: transform 0.15s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const SplitIconDisable = styled.i`
  font-size: 18px;
  &:hover {
    transform: initial !important;
    cursor: not-allowed;
  }
`;

const DeleteIcon = styled(Delete)`
  text-align: center;
  transition: transform 0.15s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const Description = styled(FormattedInput)`
  max-width: 400px !important;
`;

const StyledAddLineButton = styled(AddLineButton)`
  position: absolute;
  right: 0;
`;

const StyledCheckbox = styled(Checkbox)`
  ${({ disabled }) =>
    disabled &&
    css`
      .ant-checkbox-inner::after {
        border-color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
`;

export default AgentTitle;
