$(document).ready(function() {
   if(document.querySelector('#avatar_upload')) {
     imageHandler()
   }
})
const imageHandler = () => {
    document.querySelector('#avatar_upload').addEventListener("change", (event) => {
        var input = document.getElementById("avatar_upload");
      var fReader = new FileReader();
      fReader.readAsDataURL(input.files[0]);
      fReader.onloadend = function(event){
          var img = document.querySelector(".user_profil_avatar");
          img.src = event.target.result;
          document.querySelector("label.user_avatar").innerText = input.files[0].name
      }
    })
  }