import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useStore } from "../../store";
import DistributorArray from "./DistributorsArray";
import DistributorDetails from "./DistributorsDetails";
import { getUrlParams } from "../../utils";
import useTodo from "../../hookQuery/useTodo";
import TitleContainer from "../../react-ui/TitleContainer";
import Separator from "../../react-ui/Separator";
import AddButton from "../../react-ui/AddButton";
import { fetchDistributorsUrl } from "../../../utils/fetchDistributorsUrl";

const selector = (state) => ({
  formToken: state.formToken,
  editDistributor: state.editDistributor,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function Distributors() {
  const { formToken, editDistributor } = useStore(selector);

  const [wordEntered, setWordEntered] = useState("");
  const [distributorDetailsStatus, setDistributorDetailsStatus] =
    useState("empty");

  const sendSearch = () => (wordEntered ? `&search=${wordEntered}` : "");

  const { data: distributors, refetch } = useTodo(
    formToken,
    fetchDistributorsUrl({
      search: sendSearch(),
      from: "admin",
      id: getUrlParams(),
    }),
    "Distributors"
  );

  useEffect(() => {
    editDistributor(null);
    setDistributorDetailsStatus("empty");
    debouncedSearch({ refetch });
  }, [wordEntered, refetch, editDistributor]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const openDistributorCreation = () => {
    editDistributor(null);
    setDistributorDetailsStatus("create");
  };

  return (
    <Container>
      <div className="main_admin-materiels">
        <TitleContainer label="Distributeurs disponibles">
          <AddButton
            onClick={openDistributorCreation}
            disabled={distributorDetailsStatus === "create"}
            label="Distributeur"
          />
        </TitleContainer>
        <StyledSearch
          allowClear
          placeholder="Rechercher par nom de distributeur"
          value={wordEntered}
          onChange={handleFilter}
        />
        <Separator isHorizontal size={3} />
        <div>
          <DistributorArray
            setDistributorDetailsStatus={setDistributorDetailsStatus}
            distributors={distributors}
            distributorDetailsStatus={distributorDetailsStatus}
          />
        </div>
      </div>
      <Separator />
      <DistributorDetails
        distributorDetailsStatus={distributorDetailsStatus}
        setDistributorDetailsStatus={setDistributorDetailsStatus}
        distributors={distributors}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
`;

const StyledSearch = styled(Search)``;

export default Distributors;
