import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import {
  Tree,
  Cascader,
  Tabs,
  Radio,
  Select,
  Button,
  Modal,
  Upload,
  Input,
  Collapse,
  Table,
} from "antd";
import React from "react";

export const expandedRowRender = (e) => {
  const columns = [
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Name", dataIndex: "entitled", key: "entitled" },
    {
      title: "Status",
      key: "state",
    },
    { title: "Upgrade Status", dataIndex: "upgradeNum", key: "upgradeNum" },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
    },
  ];

  const data = [];
  for (let i = 0; i < 3; ++i) {
    data.push({
      key: i,
      date: "2014-12-24 23:12:00",
      entitled: "This is production name",
      upgradeNum: "Upgraded: 56",
    });
  }
  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export const getUrlParams = () => {
  const lastSegment = window.location.pathname.split("/").pop();
  if (!lastSegment) return "error";
  return lastSegment;
};

export const currency_cents_to_euros = (price) => {
  return (price / 100).toFixed(2);
};

export const currency_euros_to_cents = (price) =>
  parseFloat(price.replace(/[€]/g, "").replace(/[,]/g, ".").replace(/\s+/g, '')) * 100;

export const formatPercentage = (percentage) => {
  percentage = percentage.replace(/\./g, ",");
  let nbCommas = (percentage.match(/,/g) || []).length;
  let commaPosition = percentage.indexOf(",");
  let beforeComma = percentage.substring(0, commaPosition + 1);
  let afterComma = percentage.substring(commaPosition + 1, percentage.length);

  if (percentage.substring(0, 3) === "100") {
    return percentage.substring(0, 3).replace(/[^0-9,]/g, "");
  }
  if (nbCommas < 1 && percentage.length > 2) {
    return percentage.substring(0, 2).replace(/[^0-9,]/g, "");
  }
  if (nbCommas > 1) {
    afterComma = afterComma.replace(/\,/g, "");
    return (beforeComma + afterComma).replace(/[^0-9,]/g, "");
  }
  return percentage.replace(/[^0-9,]/g, "");
};


export const stringToFloat = (str) => {
  if (!str) return 0;
  return parseFloat(str.replace(/,/g, "."));
};

export const computeKmCost = ({
  costAnnualVehicle,
  kmAnnual,
  priceFuel,
  TvaRecuperation,
  TvaFuel,
  consomation,
}) =>
  costAnnualVehicle / kmAnnual +
  ((priceFuel - TvaRecuperation * (priceFuel * TvaFuel)) * consomation) / 100;

// export const  formatDataZone = (zoneData) =>{

//     const dataFormat = []

//     zoneData?.map((value, index)=>(
//         dataFormat[index] = {
//             key: value.id,
//             zoneId: value.zoneId,
//             entitled: value.entitled,
//             costprice: currency_cents_to_euros(value.costprice),
//             marginrate: <Input size="small" defaultValue={value.marginrate} suffix="%" onChange={()=>updatedTodosZone(value)}/>,
//             sellprice: 12,
//             estimate: <Input size="small" value="14"/>,
//             cipher: <Input size="small" value="34"/>,
//             difficulty: <Input size="small" />,
//             finalquantity: <Input size="small" />,
//             nacelle: <Select size="small" />,
//             increase: <Select size="small" />
//         }
//     ))
//     return dataFormat
// }
