import React from "react";
import { useQuery } from "react-query";
import { marginToCoeff } from "../../utils/marginConverter";
import { formatNumberString } from "../../utils/formatNumberString";
import { useStore } from "../store";
import { getData } from "../request/instance";

const selector = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

function MarginItem({
  profilId,
  hasMargin,
  categoryId,
  entityWorkId,
  opportunityId,
  itemType,
}) {
  const { isUsingMargin, calculMethod, formToken } = useStore(selector);

  const { data } = useQuery(
    [
      "MarginItem",
      {
        profilId,
        hasMargin,
        categoryId,
        entityWorkId,
        opportunityId,
        itemType,
      },
    ],
    () =>
      getData(
        formToken,
        `/${itemType}/used_margin_rate?${
          opportunityId
            ? `opportunity_id=${opportunityId}`
            : `entity_work_id=${entityWorkId}`
        }${categoryId ? `&category_profession_id=${categoryId}` : ""}${
          profilId ? `&profil_profession_id=${profilId}` : ""
        }`
      ),
    { enabled: (!!opportunityId || !!entityWorkId) && !hasMargin }
  );

  if (
    hasMargin ||
    data === null ||
    data === undefined ||
    data.margin_rate === null
  ) {
    return null;
  }

  return (
    <>
      Valeur par défaut:{" "}
      {formatNumberString({
        str: marginToCoeff({
          marginRate: data.margin_rate,
          isUsingMargin,
          calculMethod,
        }),
      })}
      {isUsingMargin ? "%" : ""}
    </>
  );
}

export default MarginItem;
