import React from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { useStore } from "../../store";
import { Loading } from "../../loading";
import {
  computeKmCost,
  currency_euros_to_cents,
  stringToFloat,
} from "../../utils";
import { postData } from "../../request/instance";
import MovementForm from "./MovementForm";
import TitleContainer from "../../react-ui/TitleContainer";
import {
  handleMarginKey,
  handlePictureKey,
} from "../../../utils/createFormData";
import AddButton from "../../react-ui/AddButton";
import Button from "../../react-ui/Button";
import { otherCostArray } from "../../../constants/typeTransports";
import { eurosToCents } from "../../../utils/currencyConverter";

const selector = (state) => ({
  formToken: state.formToken,
  selectedDeplacement: state.selectedDeplacement,
  editDeplacement: state.editDeplacement,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
function MovementDetails({
  movements,
  movementDetailsStatus,
  setMovementDetailsStatus,
  entityWorkId,
  opportunityId,
  from,
  handleFAAndOpp,
  faIsValidate,
  faId,
}) {
  const {
    selectedDeplacement,
    formToken,
    editDeplacement,
    isUsingMargin,
    calculMethod,
  } = useStore(selector);
  const queryClient = useQueryClient();
  const { mutate: updateMovement, status } = useMutation(
    (todo) => postData(formToken, "/movement_profession/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Movements");
      },
      onError: () => {
        editDeplacement(selectedDeplacement);
      },
    }
  );

  const { mutate: createMovement, isLoading } = useMutation(
    (todo) => postData(formToken, "/movement_profession/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Movements");
      },
      onSuccess: (payload) => {
        editDeplacement(payload);
        setMovementDetailsStatus("update");
      },
      onError: () => setMovementDetailsStatus("empty"),
    }
  );

  const { mutate: updateMovementOpportunity, status: movementOppStatus } =
    useMutation(
      (todo) => postData(formToken, "/movement_opportunity/update", todo),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("Movements");
        },
        onError: () => {
          editDeplacement(selectedDeplacement);
        },
      }
    );

  const { mutate: updateMovementFA, status: movementFAStatus } = useMutation(
    (todo) => postData(formToken, "/movement_framework_agreement/update", todo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Movements");
      },
      onError: () => {
        editDeplacement(selectedDeplacement);
      },
    }
  );

  const handleRegisterMovementProfession = (e) => {
    const formatableKeys = [
      "picture",
      "TVA_fuel",
      "TVA_recuperation",
      "price_fuel_cents_cents",
      "cost_annual_vehicle_cents",
      "km_cost",
      "other_cost_cents",
      "consomation",
      "km_annual",
      "margin_rate",
    ];
    const formData = new FormData();
    if (from === "referencing")
      formData.append("movement_profession[opportunity_id]", opportunityId);
    else if (from === "frameworkAgreement")
      formData.append("movement_profession[framework_agreement_id]", faId);
    else formData.append("movement_profession[entity_work_id]", entityWorkId);

    Object.keys(e).forEach(
      (key) =>
        !formatableKeys.includes(key) &&
        formData.append(
          `movement_profession[${key}]`,
          e[key] !== undefined && e[key] !== null ? e[key] : ""
        )
    );
    if (e.consomation) {
      formData.append(
        "movement_profession[consomation]",
        parseFloat(e.consomation.replace(/,/g, "."))
      );
    }
    if (e.km_annual) {
      formData.append(
        "movement_profession[km_annual]",
        parseFloat(e.km_annual.replace(/,/g, "."))
      );
    }
    if (e.other_cost_cents) {
      formData.append(
        "movement_profession[other_cost_cents]",
        currency_euros_to_cents(e.other_cost_cents)
      );
    }
    if (e.km_cost) {
      const kmCost = computeKmCost({
        costAnnualVehicle: stringToFloat(e.cost_annual_vehicle_cents),
        kmAnnual: stringToFloat(e.km_annual) || 1,
        priceFuel: stringToFloat(e.price_fuel_cents_cents),
        TvaRecuperation: stringToFloat(e.TVA_recuperation) / 100,
        TvaFuel: stringToFloat(e.TVA_fuel) / 100,
        consomation: stringToFloat(e.consomation),
      });
      formData.append("movement_profession[km_cost]", kmCost);
    }
    if (e.cost_annual_vehicle_cents) {
      formData.append(
        "movement_profession[cost_annual_vehicle_cents]",
        currency_euros_to_cents(e.cost_annual_vehicle_cents)
      );
    }
    if (e.price_fuel_cents_cents) {
      formData.append(
        "movement_profession[price_fuel_cents_cents]",
        currency_euros_to_cents(e.price_fuel_cents_cents)
      );
    }
    if (e.TVA_fuel) {
      formData.append(
        "movement_profession[TVA_fuel]",
        parseFloat(e.TVA_fuel.replace(/,/g, "."))
      );
    }
    if (e.TVA_recuperation) {
      formData.append(
        "movement_profession[TVA_recuperation]",
        parseFloat(e.TVA_recuperation.replace(/,/g, "."))
      );
    }
    handleMarginKey({
      formData,
      margin_rate: e.margin_rate,
      keyName: "movement_profession",
      isUsingMargin,
      calculMethod,
    });
    if (e.picture) {
      handlePictureKey({
        formData,
        picture: e.picture,
        keyName: "movement_profession",
      });
    }
    if (movementDetailsStatus === "update") {
      formData.append("id", selectedDeplacement.id);
      updateMovement(formData);
    }
    if (movementDetailsStatus === "create") {
      if (from === "referencing")
        formData.append("create_for_referencing", true);
      createMovement(formData);
    }
  };

  const handleRegisterMovementFAAndOpp = (e) => {
    if (!selectedDeplacement.movement_included && e.is_in_framework_agreement) {
      handleFAAndOpp(false, selectedDeplacement);
      return;
    }
    if (selectedDeplacement.movement_included && !e.is_in_framework_agreement) {
      handleFAAndOpp(true, selectedDeplacement);
      return;
    }
    const formData = new FormData();
    const modelName =
      from === "referencing"
        ? "movement_opportunity"
        : "movement_framework_agreement";

    if (
      e.movement_included_name !== null &&
      e.movement_included_name !== undefined
    )
      formData.append(`${modelName}[name]`, e.movement_included_name);
    if (
      e.movement_included_reference_code !== null &&
      e.movement_included_reference_code !== undefined
    )
      formData.append(
        `${modelName}[reference_code]`,
        e.movement_included_reference_code
      );
    if (e.description !== null && e.description !== undefined)
      formData.append(`${modelName}[description]`, e.description);
    formData.append("id", selectedDeplacement.movement_included.id);
    if (from === "referencing") {
      formData.append(`${modelName}[opportunity_id]`, opportunityId);
      formData.append("not_from_costing", true);
      updateMovementOpportunity(formData);
    } else {
      if (e.selling_price_cents !== null && e.selling_price_cents !== undefined)
        formData.append(
          `${modelName}[selling_price_cents]`,
          eurosToCents(e.selling_price_cents)
        );
      updateMovementFA(formData);
    }
  };

  const onSubmit = (e) => {
    if (from !== "admin" && movementDetailsStatus === "update") {
      handleRegisterMovementFAAndOpp(e);
    } else {
      handleRegisterMovementProfession(e);
    }
  };

  const isShowing =
    (movementDetailsStatus === "update" &&
      from === "admin" &&
      selectedDeplacement.entity_work_id !== parseInt(entityWorkId, 10)) ||
    (from === "frameworkAgreement" &&
      !faIsValidate &&
      movementDetailsStatus === "update");

  const isUpdating = movementDetailsStatus === "update" && !isShowing;

  const getStatus = () => {
    if (
      movementOppStatus === "loading" ||
      movementFAStatus === "loading" ||
      status === "loading"
    )
      return "loading";
    if (
      movementOppStatus === "success" ||
      movementFAStatus === "success" ||
      status === "success"
    )
      return "success";
    return "idle";
  };

  const getName = () => {
    if (movementDetailsStatus !== "update") return "";
    const curItem = movements.find((el) => el.id === selectedDeplacement.id);
    if (!curItem?.movement_included?.name && !curItem?.name) return "";
    return ` : ${curItem.movement_included?.name || curItem.name}`;
  };

  return (
    <Container>
      <TitleContainer label={`Détails du déplacement${getName()}`}>
        {movementDetailsStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={() => setMovementDetailsStatus("empty")}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="movement-form"
              loading={isLoading}
            />
          </>
        )}
        <Loading status={getStatus()} />
      </TitleContainer>
      <div />
      {movementDetailsStatus === "empty" && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez un déplacement pour afficher les détails
          </p>
        </div>
      )}
      {movementDetailsStatus !== "empty" && (
        <Wrapper>
          <MovementForm
            updateOnChange={isUpdating}
            initialMovement={
              movementDetailsStatus === "update"
                ? selectedDeplacement
                : undefined
            }
            onSubmit={onSubmit}
            isShowing={isShowing}
            from={from}
            formatedTransports={from !== "admin" ? otherCostArray : undefined}
            faIsValidate={faIsValidate}
            hasMovementIncluded={
              selectedDeplacement.movement_included !== null &&
              selectedDeplacement.movement_included !== undefined
            }
            entityWorkId={entityWorkId}
            opportunityId={opportunityId}
          />
        </Wrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default MovementDetails;
