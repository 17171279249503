import React, { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { useStore } from "../../store";
import { Loading } from "../../loading";
import { getUrlParams } from "../../utils";
import { postData } from "../../request/instance";
import FamilyForm from "./FamilyForm";
import TitleContainer from "../../react-ui/TitleContainer";
import { handlePictureKey } from "../../../utils/createFormData";
import Button from "../../react-ui/Button";
import AddButton from "../../react-ui/AddButton";
import DetailTabs from "../DetailTabs";

const selector = (state) => ({
  selectedFamily: state.selectedFamily,
  formToken: state.formToken,
  editFamily: state.editFamily,
});

function FamilyDetails({
  detailsStatus,
  setDetailsStatus,
  families,
  entityWorkId,
  from,
  opportunityId,
  faIsValidate,
  faId,
  entity_work_name,
  categories,
  unities,
  manufacturers,
  distributors,
  categoriesFilter,
  familiesAssociates,
  configuration,
  vocabulary,
}) {
  const { selectedFamily, formToken, editFamily } = useStore(selector);
  const queryClient = useQueryClient();
  const { mutate: updateFamily, status } = useMutation(
    (todo) => postData(formToken, "/family_profession/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Families");
      },
    }
  );

  const { mutate: createFamily, isLoading } = useMutation(
    (todo) => postData(formToken, "/family_profession/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Families");
      },
      onSuccess: (payload) => {
        editFamily(payload);
        setDetailsStatus("update");
      },
      onError: () => setDetailsStatus("empty"),
    }
  );

  const { mutate: createFamiliyAssociate } = useMutation(
    (todo) => postData(formToken, "/family_associate/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("FamiliesAssociates");
      },
    }
  );

  const { mutate: removeFamilyAssociates } = useMutation(
    (todo) => postData(formToken, "/family_associate/delete", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("FamiliesAssociates");
      },
    }
  );

  const handleFamilyChecked = (id, checked, parent = "material") => {
    if (checked) {
      const formData = new FormData();
      formData.append(
        "family_associate[family_profession_id]",
        selectedFamily?.id
      );
      if (parent === "material")
        formData.append("family_associate[material_profession_id]", id);
      if (parent === "category")
        formData.append("family_associate[category_profession_id]", id);
      createFamiliyAssociate(formData);
    } else {
      if (parent === "material")
        removeFamilyAssociates({
          material_profession_id: id,
          family_profession_id: selectedFamily?.id,
        });
      if (parent === "category")
        removeFamilyAssociates({
          category_profession_id: id,
          family_profession_id: selectedFamily?.id,
        });
    }
  };

  const handleRegisterFamilyProfession = (e) => {
    const formatableKeys = ["picture"];
    const formData = new FormData();
    Object.keys(e).forEach(
      (key) =>
        e[key] !== undefined &&
        e[key] !== null &&
        !formatableKeys.includes(key) &&
        formData.append(`family_profession[${key}]`, e[key])
    );
    if (e.picture) {
      handlePictureKey({
        formData,
        picture: e.picture,
        keyName: "family_profession",
      });
    }
    formData.append("family_profession[entity_work_id]", getUrlParams());
    if (detailsStatus === "update") {
      formData.append("id", selectedFamily.id);
      updateFamily(formData);
    }
    if (detailsStatus === "create") {
      createFamily(formData);
    }
  };

  const onSubmit = (e) => {
    handleRegisterFamilyProfession(e);
  };

  const isShowing =
    detailsStatus === "update" &&
    selectedFamily.entity_work_id !== parseInt(getUrlParams(), 10);

  const isUpdating = detailsStatus === "update" && !isShowing;

  const getName = () => {
    if (detailsStatus !== "update") return "";
    const curItem = families.find((el) => el.id === selectedFamily.id);
    if (!curItem?.name) return "";
    return ` : ${curItem.name}`;
  };

  const setItem = useCallback(() => {
    editFamily(families?.find((el) => el.id === selectedFamily?.id));
  }, [families, selectedFamily?.id, editFamily]);

  return (
    <Container>
      <TitleContainer label={`Détails de la famille${getName()}`}>
        {detailsStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={() => setDetailsStatus("empty")}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="family-form"
              loading={isLoading}
            />
          </>
        )}
        <Loading status={status} />
      </TitleContainer>
      {detailsStatus === "empty" && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez une famille pour afficher les détails
          </p>
        </div>
      )}
      {detailsStatus !== "empty" && (
        <DetailTabs
          configuration={configuration}
          detailsStatus={detailsStatus}
          manufacturers={manufacturers}
          distributors={distributors}
          from={from}
          categories={categories}
          unities={unities}
          opportunityId={opportunityId}
          entityWorkId={entityWorkId}
          faId={faId}
          faIsValidate={faIsValidate}
          parent="family"
          vocabulary={vocabulary}
          setItem={setItem}
          categoriesFilter={categoriesFilter}
          entity_work_name={entity_work_name}
          handleFamilyChecked={handleFamilyChecked}
          familiesAssociates={familiesAssociates}
          tabs={[
            "material_associates",
            "prestation_associates",
            "material_linked",
            "category_linked",
            "ouvrage_associates",
          ]}
        >
          <FamilyForm
            updateOnChange={isUpdating}
            initialFamily={
              detailsStatus === "update" ? selectedFamily : undefined
            }
            onSubmit={onSubmit}
            isShowing={isShowing}
          />
        </DetailTabs>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default FamilyDetails;
