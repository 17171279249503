import React, { useMemo } from "react";
import { Form, Modal } from "antd";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import FormCol from "../Form/FormCol";
import FormRow from "../Form/FormRow";
import Input from "../react-ui/Input";
import TitleContainer from "../react-ui/TitleContainer";
import { useStore } from "../store";
import AddButton from "../react-ui/AddButton";
import Button from "../react-ui/Button";
import { postData } from "../request/instance";
import { offerReviewFields, validateMessages } from "../constant";

const selector = (state) => ({
  formToken: state.formToken,
});

function RiskModal({
  riskModalType,
  setRiskModalType,
  formOfferReview,
  risks = [],
  companyId,
  updateOnChange,
}) {
  const [form] = Form.useForm();
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();

  const fieldValue = useMemo(() => {
    if (riskModalType === "site") return offerReviewFields.riskSiteOfferReviews;
    if (riskModalType === "contract")
      return offerReviewFields.riskContractReviews;
    return offerReviewFields.serviceOfferReviews;
  }, [riskModalType]);

  const queryKey = useMemo(() => {
    if (riskModalType === "site") return "RisksSites";
    if (riskModalType === "contract") return "RisksContracts";
    return "ServicesOffers";
  }, [riskModalType]);

  const modelName = useMemo(() => {
    if (riskModalType === "site") return "risk_management_site";
    if (riskModalType === "contract") return "risk_management_contract";
    return "service_monitoring_obligation";
  }, [riskModalType]);

  const titleLabel = useMemo(() => {
    if (riskModalType === "site") return "Créer un nouveau risque lié au site";
    if (riskModalType === "contract")
      return "Créer un nouveau risque lié au contrat";
    return "Créer une nouvelle obligation de suivi";
  }, [riskModalType]);

  const { mutate: createRisk, isLoading } = useMutation(
    (todoData) => postData(formToken, `/${modelName}s`, todoData),
    {
      onSuccess: (risk) => {
        const newRisks = [...risks, risk];
        const risksIds = formOfferReview.getFieldValue(fieldValue) || [];

        newRisks.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        queryClient.setQueryData(queryKey, newRisks);
        formOfferReview.setFieldsValue({
          [fieldValue]: [...risksIds, risk.id],
        });
        updateOnChange();
      },
    }
  );

  const closeModal = () => {
    setRiskModalType("");
    form.resetFields();
  };

  const handleSubmit = (values) => {
    createRisk({
      [modelName]: {
        name: values.name,
        company_id: companyId,
        offer_type: "low_current",
      },
    });
    closeModal();
  };

  return (
    <StyledModal
      destroyOnClose
      open={riskModalType !== ""}
      footer={null}
      closable={false}
      onCancel={closeModal}
      bodyStyle={{ padding: 0 }}
      width={600}
      maskClosable={false}
    >
      <Form
        id={`new-${riskModalType}-form`}
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
      >
        <TitleContainer label={titleLabel}>
          <StyledButton
            label="Annuler"
            onClick={closeModal}
            fontSize="14px"
            btnType="cancel"
            type="button"
          />
          <AddButton
            label="Créer"
            type="submit"
            value="submit"
            fontSize="14px"
            loading={isLoading}
          />
        </TitleContainer>
        <FormContainer>
          <FormRow>
            <FormCol
              label={
                riskModalType === "service"
                  ? "Intitulé du suivi de la prestation"
                  : "Intitulé du risque"
              }
              name="name"
              rules={[{ required: true }]}
            >
              <Input />
            </FormCol>
          </FormRow>
        </FormContainer>
      </Form>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

export default RiskModal;
