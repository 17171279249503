import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { Loading } from "../../loading";
import TitleContainer from "../../react-ui/TitleContainer";
import { postData } from "../../request/instance";
import { useStore } from "../../store";
import CoefficientsMatrix from "./CoefficientsMatrix";
import RentalOfferForm from "./RentalOfferForm";

const selector = (state) => ({
  formToken: state.formToken,
  setLoadingStatus: state.setLoadingStatus,
  loadingStatus: state.loadingStatus,
});

function RentalOfferDetails({
  setSelectedRental,
  selectedRental,
  constructRentalOfferFormData,
}) {
  const { formToken, setLoadingStatus, loadingStatus } = useStore(selector);
  const queryClient = useQueryClient();
  const [defaultPeriod, setDefaultPeriod] = useState(
    selectedRental.default_period
  );
  const [useMargin, setUseMargin] = useState(selectedRental.use_margin);

  const { mutate: updateRentalOffer, status } = useMutation(
    (todo) => postData(formToken, "/rental_offer/update", todo),
    {
      onError: () => {
        // Reset form on error
        setSelectedRental({ ...selectedRental });
      },
      onSuccess: (payload) => {
        queryClient.invalidateQueries("RentalOffers");
        queryClient.invalidateQueries("CoeffMatrix");
        setDefaultPeriod(payload.default_period);
        setUseMargin(payload.use_margin);
      },
    }
  );

  useEffect(() => {
    setLoadingStatus(status);
  }, [status, setLoadingStatus]);

  const getStatus = () => {
    if (loadingStatus === "loading") return "loading";
    if (loadingStatus === "success") return "success";
    return "idle";
  };

  const onSubmit = (e) => {
    const formData = constructRentalOfferFormData(e);
    formData.append("id", selectedRental.id);
    updateRentalOffer(formData);
  };

  return (
    <Container>
      <HeaderContainer>
        <BackContainer
          onClick={() => {
            setSelectedRental(null);
          }}
        >
          <BackIcon className="fa-duotone fa-arrow-left" />
          Retour aux offres locatives
        </BackContainer>
      </HeaderContainer>
      <TitleContainer label="Détails de l'offre locative">
        <Loading status={getStatus()} />
      </TitleContainer>
      <RentalOfferForm
        onSubmit={onSubmit}
        updateOnChange
        initialRentalOffer={selectedRental}
      />
      <CoefficientsMatrix
        rentalOfferId={selectedRental.id}
        defaultPeriod={defaultPeriod}
        updateRentalOffer={updateRentalOffer}
        useMargin={useMargin}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
`;

const BackContainer = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blue09};
  &:hover {
    color: ${({ theme }) => theme.colors.blue04};
  }
  transition: color 0.15s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const BackIcon = styled.i`
  font-size: 20px;
  margin-right: 10px;
`;

export default RentalOfferDetails;
