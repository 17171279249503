import { ColorPicker, Form } from "antd";
import React, { useEffect, useMemo } from "react";
import styled from "styled-components";
import { validateMessages } from "../../../constant";
import FormRow from "../../../Form/FormRow";
import FormCol from "../../../Form/FormCol";
import TitleContainer from "../../../react-ui/TitleContainer";
import AddButton from "../../../react-ui/AddButton";
import Input from "../../../react-ui/Input";
import Button from "../../../react-ui/Button";
import DocumentsArray from "../Documents/DocumentsArray";
import SecurityCol from "./SecurityCol";

function FolderForm({
  documents,
  handleSubmit,
  initialValues,
  status,
  closeModal,
  formIsLoading,
  documentsAreLoading,
  foldersAreLoading,
  works,
  companyWorks,
  entities,
  siteTypologies,
  folders,
  folderDocuments,
  setFolderDocuments,
}) {
  const [form] = Form.useForm();
  const security = Form.useWatch("security", form);
  const canUpdate = useMemo(() => {
    if (status === "create") return true;
    if (!initialValues) return false;
    return initialValues.is_editable;
  }, [initialValues, status]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const getTitle = () => {
    return status === "create" ? "Créer un dossier" : "Modifier un dossier";
  };

  const selectableDocuments = useMemo(() => {
    if (!documents) return [];
    const settableDocuments = documents?.filter(
      (document) => document.is_editable
    );
    const docsToUse = !canUpdate
      ? documents.map((doc) => {
          const disabled = !settableDocuments
            .map((el) => el.id)
            .includes(doc.id);

          return {
            ...doc,
            disabled,
          };
        })
      : settableDocuments;
    return [...docsToUse];
  }, [documents, canUpdate]);

  const hideTransparency = (open) => {
    if (open) {
      const sliderTransparency = document.getElementsByClassName(
        "ant-color-picker-slider-alpha"
      );
      const inputTransparency = document.getElementsByClassName(
        "ant-color-picker-alpha-input"
      );
      const colorBlock = document.getElementsByClassName(
        "ant-color-picker-color-block"
      );
      if (sliderTransparency && sliderTransparency[0])
        sliderTransparency[0].style.display = "none";
      if (inputTransparency && inputTransparency[0])
        inputTransparency[0].style.display = "none";
      if (colorBlock && colorBlock[1]) colorBlock[1].style.marginBottom = "5px";
    }
  };

  const removeTransparency = (color) => {
    const curColor = color.toHexString();
    form.setFieldValue("color", curColor.substring(0, 7));
  };

  const setTransparency = () => {
    const curColor = form.getFieldValue("color");
    form.setFieldValue("color", `${curColor}00`);
  };

  return (
    <Form
      id="folder-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
      <TitleContainer label={getTitle()}>
        <StyledButton
          label="Annuler"
          onClick={closeModal}
          fontSize="14px"
          btnType="cancel"
          type="button"
        />

        <AddButton
          label={status === "create" ? "Créer" : "Modifier"}
          type="submit"
          value="submit"
          fontSize="14px"
          loading={formIsLoading}
        />
      </TitleContainer>

      <FormContainer>
        <FormRow>
          <FormCol
            width="30%"
            label="Nom"
            name="name"
            rules={[{ required: true }]}
          >
            <Input disabled={!canUpdate} />
          </FormCol>
          <SecurityCol disabled={!canUpdate} security={security} />
          <FormCol width="20%" label="Couleur" name="color">
            <ColorPicker
              allowClear
              onOpenChange={hideTransparency}
              onChange={removeTransparency}
              onClear={setTransparency}
              disabled={!canUpdate}
            />
          </FormCol>
        </FormRow>

        <ArrayWrapper>
          <DocumentsArray
            entities={entities}
            works={works}
            companyWorks={companyWorks}
            siteTypologies={siteTypologies}
            documents={selectableDocuments}
            documentsAreLoading={documentsAreLoading}
            folders={folders}
            foldersAreLoading={foldersAreLoading}
            fromFolderForm
            setFolderDocuments={setFolderDocuments}
            folderDocuments={folderDocuments}
          />
        </ArrayWrapper>
      </FormContainer>
    </Form>
  );
}

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

const ArrayWrapper = styled.div`
  width: 940px;
  padding-top: 16px;
`;

export default FolderForm;
