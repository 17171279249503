import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import { useStore } from "../../../store";
import TableContainer from "../../CostingMovementsCarrycots/TableContainer";
import { getMarginCol } from "../../../../constants/marginCol";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { centsToEuros } from "../../../../utils/currencyConverter";
import { numberSorter } from "../../../../utils/numberSorter";
import { stringSorter } from "../../../../utils/stringSorter";
import Delete from "../../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../../../Admin-cf/confirmModal";
import FormattedInput from "../../../react-ui/FormattedInput";
import { formulaMarginRate } from "../../../../utils/formulaMarginRate";

const selector = (state) => ({
  formToken: state.formToken,
  calculMethod: state.calculMethod,
  isUsingMargin: state.isUsingMargin,
});

const columns = ({
  configuration,
  isUsingMargin,
  calculMethod,
  handleDelete,
  updateBasketItem,
  drop,
}) => [
  {
    title: "",
    dataIndex: "",
    width: "5%",
    render: (_, record) => {
      if (record.id === "total") return "";
      let className = "";
      if (record.material_opportunity_id)
        className = configuration.general.product_icon;
      else if (record.prestation_opportunity_id)
        className = configuration.general.prestation_icon;
      else if (record.carrycot_opportunity_id)
        className = configuration.general.carrycot_icon;
      else className = configuration.general.movement_icon;
      return <i className={className} />;
    },
  },
  {
    title: "Intitulé",
    dataIndex: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
    width: "22%",
    render: (value, record) =>
      record.id === "total" ? <Name>{value}</Name> : value,
  },
  {
    title: "Qt",
    dataIndex: "quantity",
    width: "9%",
    render: (value, record) => {
      if (drop)
        return (
          <FormattedInput
            value={value}
            keyName="quantity"
            updateItem={updateBasketItem}
            itemId={record.id}
            isNumber
            textAlign="right"
          />
        );
      if (record.id === "total") return "";
      return formatNumberString({
        str: value,
        space: true,
      });
    },
  },
  {
    title: "Coût de revient",
    dataIndex: "cost_price_cents",
    width: drop ? "23%" : "25%",
    sorter: (a, b) => numberSorter(a.cost_price_cents, b.cost_price_cents),
    render: (value, record) => (
      <CellRight>
        {formatNumberString({
          str:
            centsToEuros(
              record.prestation_opportunity_id && !record.is_package
                ? record.hourly_rate_cents
                : value
            ) * record.quantity,
          space: true,
        })}{" "}
        €
      </CellRight>
    ),
  },
  getMarginCol({
    configuration,
    isUsingMargin,
    calculMethod,
    keyName: "margin_rate",
    width: "12%",
  }),
  {
    title: "Prix de vente HT",
    dataIndex: "selling_price_cents",
    width: drop ? "23%" : "25%",
    sorter: (a, b) =>
      numberSorter(a.selling_price_cents, b.selling_price_cents),
    render: (value, record) => (
      <CellRight>
        {formatNumberString({
          str: centsToEuros(value) * record.quantity,
          space: true,
        })}{" "}
        €
      </CellRight>
    ),
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
    width: "5%",
    hidden: !drop,
    render: (record) => (
      <Delete
        onClick={() => ShowDeleteConfirm(record.name, record.id, handleDelete)}
      />
    ),
  },
];

function BasketItems({
  configuration,
  basketItems,
  selectedBasketId,
  deleteBasketItem,
  updateBasketItemMutation,
  drop,
  backgroundColor,
}) {
  const { calculMethod, isUsingMargin } = useStore(selector);

  const data = useMemo(() => {
    if (!basketItems) return [];
    const filteredItems = basketItems
      .filter((el) => el.referencing_basket_id === selectedBasketId)
      .map((el) => {
        if (el.material_opportunity_id)
          return {
            className: "row_children_colored",
            ...el.material_opportunity,
            ...el,
          };
        if (el.prestation_opportunity_id)
          return {
            className: "row_children_colored",
            ...el.prestation_opportunity,
            ...el,
          };
        if (el.carrycot_opportunity_id)
          return {
            className: "row_children_colored",
            ...el.carrycot_opportunity,
            ...el,
          };
        return {
          className: "row_children_colored",
          ...el.movement_opportunity,
          ...el,
        };
      });
    if (!drop) {
      let totalCost = 0;
      let totalSelling = 0;
      filteredItems.forEach((record) => {
        totalCost +=
          (record.prestation_opportunity_id && !record.is_package
            ? record.hourly_rate_cents
            : record.cost_price_cents) * record.quantity;
        totalSelling += record.selling_price_cents * record.quantity;
      });
      filteredItems.unshift({
        id: "total",
        name: "TOTAL",
        quantity: 1,
        cost_price_cents: totalCost,
        selling_price_cents: totalSelling,
        margin_rate:
          filteredItems.length === 0
            ? undefined
            : formulaMarginRate({
                costPrice: totalCost,
                calculMethod,
                sellingPrice: totalSelling,
                isUsingMargin: true, // valeur forcée car on travaille avec des marges en % et c'est le front qui convertit
              }),
      });
    }
    return filteredItems;
  }, [basketItems, drop, selectedBasketId, calculMethod, isUsingMargin]);

  const handleDelete = useCallback(
    (id) => {
      deleteBasketItem({ id });
    },
    [deleteBasketItem]
  );

  const updateBasketItem = ({ id, ...values }) => {
    updateBasketItemMutation({
      id,
      referencing_basket_item: values,
    });
  };

  return (
    <Container ref={drop} $drop={!!drop} $backgroundColor={backgroundColor}>
      <TableContainer
        bordered={false}
        columns={columns({
          configuration,
          isUsingMargin,
          calculMethod,
          handleDelete,
          updateBasketItem,
          drop,
        })}
        data={data}
        rowKey="id"
        scroll={{ y: drop ? 400 : 200 }}
      />
    </Container>
  );
}

const Container = styled.div`
  ${({ $drop }) =>
    $drop &&
    css`
      .ant-table-placeholder {
        height: 400px;
      }
      min-height: 400px;
      margin-top: 19px;
    `}
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      .ant-table-thead > tr > th {
        transition: none !important;
        background-color: ${$backgroundColor} !important;
      }
    `}
`;

const CellRight = styled.div`
  display: flex;
  justify-content: end;
`;

const Name = styled.span`
  font-weight: bold;
`;

export default BasketItems;
