import React from "react";
import FormattedInput from "../../../react-ui/FormattedInput";

function DraggableTableInput({ onBlur, onFocus, itemId, ...props }) {
  const handleOnBlur = (values) => {
    if (onBlur) onBlur(values);
    document
      .getElementById(`drag-row-${itemId}`)
      .setAttribute("draggable", "true");
  };

  const handleOnFocus = (values) => {
    if (onFocus) onFocus(values);
    document
      .getElementById(`drag-row-${itemId}`)
      .setAttribute("draggable", "false");
  };

  return (
    <FormattedInput
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      itemId={itemId}
      {...props}
    />
  );
}

export default DraggableTableInput;
