import React from "react";
import styled from "styled-components";

function ActionsContainer({
  children,
  center,
  $colGap = "0px",
  className = "",
}) {
  return (
    <Container className={className} $colGap={$colGap} center={center}>
      {children}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => (center ? "center" : "flex-end")};
  gap: ${({ $colGap }) => `0 ${$colGap}`};
  i {
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  }
`;

export default ActionsContainer;
