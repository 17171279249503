import { Checkbox, Form, Tabs } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import { validateMessages } from "../../../constant";
import FormRow from "../../../Form/FormRow";
import FormCol from "../../../Form/FormCol";
import ButtonCol from "./ButtonCol";
import Avatar from "./Avatar";
import Separator from "../../../react-ui/Separator";
import Select from "../../../react-ui/Select";
import Input from "../../../react-ui/Input";
import UserRights from "./UserRights";
import { useLazyQuery } from "../../../../hooks/useLazyQuery";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import ApiManagement from "../../../profile_management/ApiManagement";
import CopySecret from "../../../react-ui/CopySecret";

const genderArray = [
  { name: "M.", id: "M." },
  { name: "Mme.", id: "Mme." },
];

const selector = (state) => ({
  formToken: state.formToken,
});

function UserForm({
  isUpdating,
  isShowing,
  handleSubmit,
  setStatus,
  selectedUser,
  status,
  currentUserId,
  roles,
  isLoading,
  entities,
  works,
  allUsers,
  form,
  sites,
  option_documents,
}) {
  const { formToken } = useStore(selector);
  useEffect(() => {
    if (status === "empty") form.resetFields();
  }, [status, form]);

  const [loadAvatar] = useLazyQuery(
    ["loadAvatar"],
    () => getData(formToken, `/user/load_avatar?id=${selectedUser?.id}`),
    {
      onSuccess: (avatar) => {
        if (avatar)
          form.setFieldValue("avatar", [
            {
              uid: "1",
              name: "image",
              status: "done",
              url: avatar,
            },
          ]);
      },
    }
  );

  useEffect(() => {
    if (selectedUser) {
      loadAvatar();
      const active = !!selectedUser.active;
      const notification_deprecated_documents =
        !!selectedUser.notification_deprecated_documents;
      const initialValues = {
        ...selectedUser,
        user_entity_work_ids: [...selectedUser.work_ids],
        active,
        notification_deprecated_documents,
      };
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [form, selectedUser, loadAvatar]);

  const onCheckActive = (e) => {
    if (!e.target.checked) {
      form.setFieldValue("user_entity_work_ids", []);
    }
  };

  const getTabName = (keyVocabulary, keyIcon) => (
    <TabLabelContainer>
      <i className={keyIcon} />
      <TabName>{keyVocabulary}</TabName>
    </TabLabelContainer>
  );

  const tabsItems = [
    {
      label: getTabName("Informations", "fa-duotone fa-user"),
      key: "informations",
      children: (
        <Form
          form={form}
          colon={false}
          requiredMark={false}
          onFinish={handleSubmit}
          validateMessages={validateMessages}
        >
          <StyledFormRow>
            <FormCol>
              <FormCol
                label="Compte actif"
                name="active"
                valuePropName="checked"
                withoutPaddingRight
                rules={[{ required: true }]}
              >
                <Checkbox
                  disabled={isShowing || currentUserId === selectedUser?.id}
                  onChange={onCheckActive}
                />
              </FormCol>
            </FormCol>
            <Avatar isShowing={isShowing} />
            <ButtonCol
              isUpdating={isUpdating}
              isShowing={isShowing}
              setStatus={setStatus}
              isLoading={isLoading}
            />
          </StyledFormRow>

          <Separator isHorizontal size={1} />
          <Row>
            <FormCol
              label=""
              labelOnTop
              name="gender"
              rules={[{ required: true }]}
              width="20%"
            >
              <Select
                options={genderArray}
                disabled={isShowing}
                showSearch={false}
              />
            </FormCol>
            <FormCol
              label="Prénom"
              name="first_name"
              labelOnTop
              rules={[{ required: true }]}
              width="40%"
            >
              <Input disabled={isShowing} />
            </FormCol>
            <FormCol
              label="Nom"
              name="last_name"
              labelOnTop
              rules={[{ required: true }]}
              width="40%"
            >
              <Input disabled={isShowing} />
            </FormCol>
          </Row>

          <StyledFormRow>
            <FormCol
              label="Adresse email / Identifiant de connexion"
              name="email"
              labelOnTop
              rules={[
                { required: true },
                {
                  type: "email",
                  message: "Email invalide.",
                },
              ]}
              width="60%"
            >
              <Input disabled={isShowing} />
            </FormCol>
            <FormCol
              label="Numéro de téléphone"
              name="phone"
              labelOnTop
              rules={[
                { required: true },
                {
                  // eslint-disable-next-line prefer-regex-literals
                  pattern: new RegExp(
                    "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$"
                  ),
                  message: "Numéro invalide.",
                },
              ]}
              width="40%"
            >
              <Input disabled={isShowing} />
            </FormCol>
          </StyledFormRow>

          <Separator isHorizontal size={1} />
          <UserRights
            isUpdating={isUpdating}
            isShowing={isShowing}
            selectedUser={selectedUser}
            roles={roles}
            entities={entities}
            works={works}
            allUsers={allUsers}
            form={form}
            sites={sites}
          />
          <Separator isHorizontal size={1} />
          <Row>
            <FormCol label="Fonction" name="function" labelOnTop>
              <Input disabled={isShowing} />
            </FormCol>
          </Row>
          <StyledFormRow>
            <FormCol label="Présentation" name="description" labelOnTop>
              <Input disabled={isShowing} textArea />
            </FormCol>
          </StyledFormRow>
          <Separator isHorizontal size={1} />
          <StyledFormRow>
            <FormCol
              label="Jeton API"
              name="api_token"
              labelOnTop
              valuePropName="secret"
              width="50%"
            >
              <CopySecret copyText="Copier le jeton API" defaultIsHidden />
            </FormCol>
            {option_documents &&
              (selectedUser?.role === "admin" ||
                selectedUser?.role === "setup") && (
                <FormCol
                  label="Notification d'obsolescence des documents"
                  name="notification_deprecated_documents"
                  valuePropName="checked"
                  width="50%"
                  withoutPaddingRight
                  rules={[{ required: true }]}
                >
                  <Checkbox
                    disabled={isShowing || currentUserId === selectedUser?.id}
                  />
                </FormCol>
              )}
          </StyledFormRow>
        </Form>
      ),
    },
    {
      label: getTabName("Applications externes", "fa-duotone fa-code"),
      key: "apis",
      children: (
        <ApiManagement
          formToken={formToken}
          current_user={selectedUser}
          fromAdmin
        />
      ),
    },
  ];

  return (
    <Container>
      <StyledTabs items={tabsItems} />
    </Container>
  );
}

const Container = styled.div`
  overflow: scroll;
  width: 100%;
  height: calc(100% - 58px);
  .ant-form-item-explain-error {
    white-space: nowrap;
  }
`;

const Row = styled.div`
  display: flex;
`;

const StyledFormRow = styled(FormRow)`
  padding-top: 10px !important;
`;

const StyledTabs = styled(Tabs)`
  width: 45.6vw;
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
    width: 100%;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }
`;

const TabLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TabName = styled.span`
  display: block;
`;

export default UserForm;
