import React from "react";
import styled from "styled-components";

function Eye({ className = "", $fontSize = "12px", ...iconProps }) {
  return (
    <StyledIcon
      className={`fa-duotone fa-eye ${className}`}
      $fontSize={$fontSize}
      {...iconProps}
    />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: ${({ $fontSize }) => $fontSize};
`;

export default Eye;
