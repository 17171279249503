import React, { useMemo } from "react";
import { array, bool, func, object, shape, string } from "prop-types";
import Form from "../../Form";
import {
  itemsToCascader,
  singleItemToCascader,
} from "../../../utils/itemsForCascader";
import { formatNumberString } from "../../../utils/formatNumberString";
import { marginToCoeff } from "../../../utils/marginConverter";
import { useStore } from "../../store";
import TvaSelector from "../../react-ui/TvaSelector";
import { getUrlParams } from "../../utils";
import { useMarginItem } from "../../hooks/useMarginItem";
import MarginItem from "../../react-ui/MarginItem";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
function CategoryForm({
  categories,
  onSubmit,
  updateOnChange,
  submit,
  initialCategory,
  entity_work_name,
  configuration,
  tva_professions,
  use_special_tva,
}) {
  const { isUsingMargin, calculMethod } = useStore(selector);
  const { hasTVA, setHasTVA, hasMargin, setHasMargin, categoryId } =
    useMarginItem({
      initial: initialCategory,
      categories,
    });
  const formFactory = useMemo(() => {
    const categoriesToKeep = initialCategory
      ? categories?.filter(
          (el) =>
            el.id !== initialCategory.id &&
            !initialCategory.descendants?.includes(el.id)
        )
      : categories;
    const formatedCategories = [
      { title: "Pas de catégorie parent", id: -1, parent_category_id: null },
      ...categoriesToKeep,
    ];

    const firstRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Nom de la catégorie",
          },
          name: "title",
          label: "Intitulé",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      configuration.admin.categories_tab.form.input_margin_rate && {
        type: "input",
        item: {
          input: {
            customContent: (
              <MarginItem
                hasMargin={hasMargin}
                categoryId={categoryId}
                entityWorkId={getUrlParams()}
                // opportunityId={opportunityId}
                itemType="category_profession"
              />
            ),
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                isCoeff: !isUsingMargin,
              });
              setHasMargin(e.currentTarget.value !== "");
            },
          },
          isMarginRate: true,
          name: "margin_rate",
        },
      },
    ];

    const secondRow = [
      {
        type: "cascader",
        item: {
          cascader: {
            placeholder: "Choisissez une catégorie",
            changeOnSelect: true,
            expandTrigger: "hover",
            allowClear: false,
          },
          options: itemsToCascader({
            items: formatedCategories,
            keyParent: "parent_category_id",
            keyName: "title",
          }),
          name: "parent_category_id",
          label: "Catégorie parent",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const categoryPositionRow = [
      {
        type: "input",
        span: 12,
        item: {
          input: {
            placeholder: "1",
            onInput: (e) => {
              e.currentTarget.value = formatNumberString({
                str: e.currentTarget.value,
                nbDecimal: 0,
              });
            },
          },
          name: "category_position",
          label: "Position",
        },
      },
      use_special_tva && {
        type: "select",
        span: 12,
        item: {
          select: {
            onChange: (e) => {
              setHasTVA(e !== "none" && e !== undefined);
            },
            allowClear: true,
            customContent: (
              <TvaSelector
                hasTVA={hasTVA}
                itemType="category_profession"
                entityWorkId={getUrlParams()}
                categoryId={categoryId || initialCategory?.id}
                tvaProfessionId={initialCategory?.tva_profession_id}
              />
            ),
          },
          options: tva_professions,
          keyName: "name",
          name: "tva_profession_id",
          label: "TVA spéciale",
        },
      },
    ];

    return [
      { columns: firstRow, key: "name+margin_rate" },
      { columns: secondRow, key: "parent_category_id" },
      {
        columns: categoryPositionRow,
        key: "category_position+tva_profession_id",
      },
    ];
  }, [
    categories,
    initialCategory,
    isUsingMargin,
    hasTVA,
    setHasTVA,
    hasMargin,
    setHasMargin,
  ]);

  const initialValues = useMemo(() => {
    if (!initialCategory)
      return {
        parent_category_id: -1,
      };
    const margin_rate = initialCategory.margin_rate
      ? formatNumberString({
          str: marginToCoeff({
            marginRate: initialCategory.margin_rate,
            isUsingMargin,
            calculMethod,
          }),
          isCoeff: !isUsingMargin,
        })
      : undefined;
    return {
      title: initialCategory.title,
      tva_profession_id: initialCategory.tva_profession_id,
      category_position: initialCategory.category_position,
      parent_category_id: initialCategory.parent_category_id
        ? singleItemToCascader({
            childItem: categories?.find(
              (el) => el.id === initialCategory.parent_category_id
            ),
            items: categories,
            keyParent: "parent_category_id",
          })
        : -1,
      margin_rate,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCategory?.id, isUsingMargin]);

  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={formFactory}
      id="category-form"
    />
  );
}

CategoryForm.propTypes = {
  categories: array,
  onSubmit: func,
  updateOnChange: bool,
  submit: shape({
    onCancel: func,
  }),
  initialCategory: object,
  entity_work_name: string,
  configuration: object,
  tva_professions: array,
};

CategoryForm.defaultProps = {
  categories: [],
  onSubmit: undefined,
  updateOnChange: false,
  submit: undefined,
  initialCategory: undefined,
  entity_work_name: "",
  configuration: {},
  tva_professions: [],
};

export default CategoryForm;
