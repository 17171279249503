import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import OfferInformations from "./offerInformations";
import OfferOpportunities from "./offerOpportunities";
import { getData } from "../../request/instance";
import { content_offer_tour } from "../../tour/offer_details_tour";
import { style_tour, locale_tour } from "../../tour/tour_style";
import Loader from "../../react-ui/Loader";

function OfferDetails({
  token,
  offer_id,
  configuration,
  vocabulary,
  entities,
  userEntityId,
  configApplication,
  vocabularyApplication,
  siteTypologies,
  supervisor,
}) {
  const {
    data: offer,
    refetch: refetchOffer,
    isLoading: offerLoading,
  } = useQuery("Offer", () => getData(token, `/show_offer/${offer_id}`), {
    onSuccess: (res) => {
      getData(token, `/verify_tour?tour_name="offer_detail_tour"`).then(
        (res) => {
          if (res.success === true) {
            setRun(true);
          } else {
            setRun(false);
          }
        }
      );
    },
    refetchOnWindowFocus: false,
  });

  const [steps, setSteps] = useState(content_offer_tour);
  const [run, setRun] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };

  useEffect(() => {
    refetchOffer();
  }, [refetchOffer]);

  return (
    <div>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        showSkipButton
        steps={steps}
        locale={locale_tour}
        styles={style_tour}
        disableScrolling
        disableOverlayClose
      />
      {offerLoading ? (
        <Loader width={80} height={80} />
      ) : (
        <>
          <OfferInformations
            offer={offer}
            offer_id={offer_id}
            token={token}
            configApplication={configApplication}
            vocabularyApplication={vocabularyApplication}
            supervisor={supervisor}
            siteTypologies={siteTypologies}
          />
          <OfferOpportunities
            offer={offer}
            token={token}
            refetchOffer={refetchOffer}
            offerLoading={offerLoading}
            configuration={configuration}
            vocabulary={vocabulary}
            entities={entities}
            userEntityId={userEntityId}
            configApplication={configApplication}
            vocabularyApplication={vocabularyApplication}
            siteTypologies={siteTypologies}
            supervisor={supervisor}
          />
        </>
      )}
    </div>
  );
}

export default OfferDetails;
