import { Form, Modal } from "antd";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { validateMessages } from "../constant";
import FormCol from "../Form/FormCol";
import FormRow from "../Form/FormRow";
import AddButton from "../react-ui/AddButton";
import Button from "../react-ui/Button";
import Input from "../react-ui/Input";
import TitleContainer from "../react-ui/TitleContainer";
import { postData } from "../request/instance";
import { useStore } from "../store";

const selector = (state) => ({
  formToken: state.formToken,
});

function PartnerModal({ isOpenFrom, setIsOpenFrom, companyId, offerReviewId }) {
  const queryClient = useQueryClient();

  const { formToken } = useStore(selector);
  const [form] = Form.useForm();
  const { mutate: createContractor, isLoading } = useMutation(
    (todo) => postData(formToken, `/contractor/create`, todo),
    {
      onSuccess: (payload) => {
        queryClient.invalidateQueries("Contractors");
        if (isOpenFrom === "subcontracting") {
          createOfferReviewSubcontractor({
            offer_review_id: offerReviewId,
            contractor_id: payload.id,
          });
        } else {
          createOfferReviewGroupMember({
            offer_review_id: offerReviewId,
            contractor_id: payload.id,
          });
        }
      },
    }
  );

  const { mutate: createOfferReviewGroupMember } = useMutation(
    (todo) => postData(formToken, `/offer_review_group_member/create`, todo),
    {
      onSuccess: () => queryClient.invalidateQueries("OfferReviewGroupMembers"),
    }
  );

  const { mutate: createOfferReviewSubcontractor } = useMutation(
    (todo) => postData(formToken, `/offer_review_subcontractor/create`, todo),
    {
      onSuccess: () =>
        queryClient.invalidateQueries("OfferReviewSubcontractors"),
    }
  );

  const handleSubmit = (values) => {
    createContractor({
      company_id: companyId,
      ...values,
    });

    setIsOpenFrom("");
  };

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      open={isOpenFrom !== ""}
      footer={null}
      closable={false}
      onCancel={() => setIsOpenFrom("")}
      bodyStyle={{ padding: 0 }}
      width={600}
      getContainer={false}
      forceRender
    >
      <Form
        id="new-partner-form"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
      >
        <TitleContainer label="Créer un partenaire">
          <StyledButton
            label="Annuler"
            onClick={() => setIsOpenFrom("")}
            fontSize="14px"
            btnType="cancel"
            type="button"
          />
          <AddButton
            label="Créer le partenaire"
            type="submit"
            value="submit"
            fontSize="14px"
            loading={isLoading}
          />
        </TitleContainer>
        <FormContainer>
          <FormRow>
            <FormCol
              labelOnTop
              label="Nom commercial"
              name="trade_name"
              rules={[{ required: true }]}
            >
              <Input />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol
              label="Dénomination sociale"
              name="corporate_name"
              labelOnTop
            >
              <Input />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol label="Adresse" name="address" labelOnTop>
              <Input textArea />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol label="Site web" name="web_site" labelOnTop>
              <Input />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol label="Email" name="email" labelOnTop>
              <Input />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol label="Téléphone" name="phone" labelOnTop>
              <Input />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol label="Fax" name="fax" labelOnTop>
              <Input />
            </FormCol>
          </FormRow>
          <FormRow>
            <FormCol label="SIRET" name="siret" labelOnTop>
              <Input />
            </FormCol>
          </FormRow>
        </FormContainer>
      </Form>
    </Modal>
  );
}

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

export default PartnerModal;
