import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import "react-csv-importer/dist/index.css";

import { getUrlParams } from "../../../utils";
import ExcelButton from "../../../react-ui/ExcelButton";
import ImportButton from "../../../react-ui/ImportButton";
import MaterialsImporter from "./MaterialsImporter";
import { useStore } from "../../../store";
import Import from "../../../react-ui/Icons/Import";
import MaterialAssosImporter from "./MaterialAssosImporter";
import PrestaAssosImporter from "./PrestaAssosImporter";

const selector = (state) => ({
  role: state.role,
});

const getStartStep = ({ role, openImporter }) =>
  role === "superadmin" && openImporter === "create" ? 0 : 1;

function CsvImporter({ configuration, vocabulary }) {
  const { role } = useStore(selector);
  const [openImporter, setOpenImporter] = useState();
  const [step, setStep] = useState(getStartStep({ role, openImporter }));
  const [typeToImport, setTypeToImport] = useState("material");
  const [toDeleteValue, setToDeleteValue] = useState("1");
  const [toKeepValue, setToKeepValue] = useState("0");
  const [showConfig, setShowConfig] = useState(false);

  useEffect(() => {
    setStep(getStartStep({ role, openImporter }));
  }, [role, openImporter]);

  const entity_work_id = useMemo(() => {
    return getUrlParams();
  }, []);

  const linkXLSX = `/generate_material_professions/${entity_work_id}`;

  const getTitle = () => {
    if (step === 0) {
      return "Choisissez le type d'import";
    }

    // Step === 1
    let title = openImporter === "create" ? "Import de " : "Modification de ";
    if (typeToImport === "material") {
      title += "matériels";
    } else if (typeToImport === "materialAsso") {
      title += "matériels compatibles";
    } else {
      title += "prestations associées";
    }
    return title;
  };

  const handleChooseTypeToImport = (type) => {
    setTypeToImport(type);
    setStep(1);
  };

  return (
    <>
      <Modal
        open={openImporter}
        maskClosable={false}
        onCancel={() => {
          setOpenImporter(undefined);
          setToDeleteValue("1");
          setToKeepValue("0");
          setTypeToImport("material");
          setStep(getStartStep({ role, openImporter }));
        }}
        footer={false}
        width="80vw"
        destroyOnClose
      >
        <Title>{getTitle()}</Title>
        {step === 0 && (
          <CardContainer>
            <Card onClick={() => handleChooseTypeToImport("material")}>
              <Import />
              {vocabulary.products}
            </Card>
            <Card onClick={() => handleChooseTypeToImport("materialAsso")}>
              <Import />
              {vocabulary.products} compatibles
            </Card>
            <Card onClick={() => handleChooseTypeToImport("prestationAsso")}>
              <Import />
              Prestations associées
            </Card>
          </CardContainer>
        )}
        {step === 1 && (
          <MaterialsImporterContainer>
            {typeToImport === "material" && (
              <MaterialsImporter
                openImporter={openImporter}
                toDeleteValue={toDeleteValue}
                setToDeleteValue={setToDeleteValue}
                toKeepValue={toKeepValue}
                setToKeepValue={setToKeepValue}
                showConfig={showConfig}
                setShowConfig={setShowConfig}
                configuration={configuration}
                vocabulary={vocabulary}
              />
            )}
            {typeToImport === "materialAsso" && <MaterialAssosImporter />}
            {typeToImport === "prestationAsso" && <PrestaAssosImporter />}
          </MaterialsImporterContainer>
        )}
      </Modal>

      <CsvButtonsContainer>
        <StyledButton
          label="Import"
          onClick={() => setOpenImporter("create")}
        />
        <StyledButtonEdit
          label="Modifier"
          onClick={() => setOpenImporter("update")}
        />
        <StyledButtonExcel label="Export" goTo={linkXLSX} />
      </CsvButtonsContainer>
    </>
  );
}

const CsvButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(ImportButton)`
  margin-right: 4px;
  max-height: 45px;
`;

const StyledButtonEdit = styled(ImportButton)`
  margin-right: 4px;
  max-height: 45px;
`;

const StyledButtonExcel = styled(ExcelButton)`
  margin-right: 4px;
  max-height: 45px;
`;

const CardContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 40px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  border: 2px dashed black;
  height: 90px;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
`;

const Title = styled.h3`
  color: black;
  margin: 0;
`;

const MaterialsImporterContainer = styled.div`
  margin-top: 40px;
  .CSVImporter_ColumnDragTargetArea {
    align-items: flex-end;
  }
`;

export default CsvImporter;
