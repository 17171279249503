export const typesArray = [
  {
    id: "buyer",
    name: "Donneur d'ordres",
  },
  {
    id: "supplier",
    name: "Fournisseur",
  },
];
