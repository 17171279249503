import { Divider } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";
import { formatNumberString } from "../../../../utils/formatNumberString";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";

const dayTypes = ["weekday", "sunday", "work_free_day", "sunday_work_free_day"];

const THCols = [
  {
    title: "Semaine",
    children: [
      {
        title: () => <i className="fa-duotone fa-sun" />,
        dataIndex: "weekdayDay",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
        width: "12.5%",
      },
      {
        title: () => <i className="fa-duotone fa-moon" />,
        dataIndex: "weekdayNight",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
        width: "12.5%",
      },
    ],
  },
  {
    title: "Dimanche",
    children: [
      {
        title: () => <i className="fa-duotone fa-sun" />,
        dataIndex: "sundayDay",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
        width: "12.5%",
      },
      {
        title: () => <i className="fa-duotone fa-moon" />,
        dataIndex: "sundayNight",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
        width: "12.5%",
      },
    ],
  },
  {
    title: "Jours Fériés",
    children: [
      {
        title: () => <i className="fa-duotone fa-sun" />,
        dataIndex: "work_free_dayDay",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
        width: "12.5%",
      },
      {
        title: () => <i className="fa-duotone fa-moon" />,
        dataIndex: "work_free_dayNight",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
        width: "12.5%",
      },
    ],
  },
  {
    title: "Dim J.F",
    children: [
      {
        title: () => <i className="fa-duotone fa-sun" />,
        dataIndex: "sunday_work_free_dayDay",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
        width: "12.5%",
      },
      {
        title: () => <i className="fa-duotone fa-moon" />,
        dataIndex: "sunday_work_free_dayNight",
        render: (val) => formatNumberString({ str: val }),
        align: "center",
        width: "12.5%",
      },
    ],
  },
];

function TotalHours({ planning }) {
  const dataSource = useMemo(() => {
    const newDataSource = {
      key: "1",
    };
    dayTypes.forEach((el) => {
      newDataSource[`${el}Day`] = planning?.nb_hours_in_period[el].day;
      newDataSource[`${el}Night`] = planning?.nb_hours_in_period[el].night;
    });
    return [newDataSource];
  }, [planning]);

  return (
    <>
      <div>
        <StyledDivider>
          Nombre d'heures totales travaillées (
          {formatNumberString({ str: planning?.total_nb_hours_in_period })}{" "}
          heures)
        </StyledDivider>
      </div>
      <StyledTable
        bordered
        columns={THCols}
        dataSource={dataSource}
        pagination={false}
      />
      {/* <StyledTable className="table table-bordered">
        <tbody>
          <tr className="text-center">
            {dates.map((el) => (
              <DayHeader colSpan="2" key={`head-${el}`}>
                {el}
              </DayHeader>
            ))}
          </tr>
          <tr className="text-center">
            {dates.map((el) => (
              <React.Fragment key={`icon-${el}`}>
                <IconCell>
                  <i className="fa-duotone fa-sun" />
                </IconCell>
                <IconCell>
                  <i className="fa-duotone fa-moon" />
                </IconCell>
              </React.Fragment>
            ))}
          </tr>
          <tr className="text-center">
            {dayTypes.map((el) => (
              <React.Fragment key={el}>
                <HourCell>
                  {formatNumberString({
                    str: planning?.nb_hours_in_period[el]?.day,
                  })}
                </HourCell>
                <HourCell>
                  {formatNumberString({
                    str: planning?.nb_hours_in_period[el]?.night,
                  })}
                </HourCell>
              </React.Fragment>
            ))}
          </tr>
        </tbody>
                </StyledTable> */}
    </>
  );
}

const StyledTable = styled(TableContainer)`
  margin: 10px 0 !important;
  .fa-sun {
    color: orange;
    font-size: 12px;
  }

  .fa-moon {
    color: lightsteelblue;
    font-size: 12px;
  }
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 0 !important;
  margin-top: 20px !important;
  border-block-start-color: rgba(0, 0, 0, 0.88) !important;
`;

export default TotalHours;
