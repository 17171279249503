import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useStore } from "../../store";
import PrestationDetails from "./PrestationDetails";
import PrestationArray from "./PrestationArray";
import { getUrlParams } from "../../utils";
import useTodo from "../../hookQuery/useTodo";
import TitleContainer from "../../react-ui/TitleContainer";
import Separator from "../../react-ui/Separator";
import AddButton from "../../react-ui/AddButton";
import { getData, postData } from "../../request/instance";
import { fetchPrestationsUrl } from "../../../utils/fetchPrestationsUrl";
import { fetchUnitiesUrl } from "../../../utils/fetchUnitiesUrl";
import { fetchProfilsUrl } from "../../../utils/fetchProfilsUrl";
import { getFetchUrlIdAndFrom } from "../../../utils/getFetchUrlIdAndFrom";
import { fetchMajorationsUrl } from "../../../utils/fetchMajorationsUrl";

const selector = (state) => ({
  editPrestation: state.editPrestation,
  formToken: state.formToken,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function Prestation({
  entityWorkId = getUrlParams(),
  from = "admin",
  opportunityId = null,
  faIsValidate = false,
  faId = null,
  defaultMajoration = null,
  entity_work_name,
  configuration,
  vocabulary,
  defaultMinutes,
  use_special_tva,
}) {
  const queryClient = useQueryClient();
  const { formToken, editPrestation } = useStore(selector);
  const [prestationDetailsStatus, setPrestationDetailsStatus] =
    useState("empty");

  const [wordEntered, setWordEntered] = useState("");

  const updatePrestaFAOpp = (is_in_framework_agreement) => ({
    onSuccess: (payload) => {
      const newPrestas = [...prestations];
      const idxToUpdate = prestations.findIndex(
        (el) => el.id === payload.prestation_profession_id
      );
      newPrestas[idxToUpdate] = {
        ...newPrestas[idxToUpdate],
        is_in_framework_agreement,
        prestation_included: is_in_framework_agreement ? payload : null,
      };
      queryClient.setQueryData("Prestations", newPrestas);
      editPrestation(newPrestas[idxToUpdate]);
      setPrestationDetailsStatus("update");
    },
  });

  const { data: prestations, refetch } = useQuery("Prestations", () =>
    getData(
      formToken,
      fetchPrestationsUrl({
        ...getFetchUrlIdAndFrom({
          from,
          opportunityId,
          faIsValidate,
          faId,
          entityWorkId,
        }),
        search: wordEntered,
      })
    )
  );
  const { data: profils } = useTodo(
    formToken,
    fetchProfilsUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
    }),
    ["Profils", { from }]
  );
  const { data: categories } = useTodo(
    formToken,
    `/category_profession?entity_work_id=${entityWorkId}`,
    "Categories"
  );
  const { data: unities } = useTodo(
    formToken,
    fetchUnitiesUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
    }),
    ["Unities", { from }]
  );

  const { mutate: createPrestationOpportunity } = useMutation(
    (todoData) =>
      postData(
        formToken,
        "/prestation_opportunity/create_for_referencing",
        todoData
      ),
    updatePrestaFAOpp(true)
  );

  const { mutate: deletePresOpportunity } = useMutation(
    (todoData) =>
      postData(formToken, "/prestation_opportunity/delete", todoData),
    updatePrestaFAOpp(false)
  );

  const { mutate: createPrestationFA } = useMutation(
    (todoData) =>
      postData(formToken, "/prestation_framework_agreement/create", todoData),
    updatePrestaFAOpp(true)
  );

  const { mutate: deletePresFA } = useMutation(
    (todoData) =>
      postData(formToken, "/prestation_framework_agreement/delete", todoData),
    updatePrestaFAOpp(false)
  );

  const { data: majorationsOpp } = useQuery(
    ["MajorationsOpp", { from }],
    () => getData(formToken, `/majoration_opportunities/${opportunityId}`),
    {
      enabled:
        from === "referencing" ||
        (from === "frameworkAgreement" &&
          opportunityId !== null &&
          !faIsValidate),
    }
  );

  const { data: defaultValueOpp } = useQuery(
    ["DefaultValue", { from }],
    () => getData(formToken, `/default_value_opportunities/${opportunityId}`),
    {
      enabled:
        from === "referencing" ||
        (from === "frameworkAgreement" &&
          opportunityId !== null &&
          !faIsValidate),
    }
  );

  const { data: majorations } = useQuery(
    ["Majorations", { from }],
    () => getData(formToken, fetchMajorationsUrl({ from, id: faId })),
    {
      enabled:
        from === "frameworkAgreement" &&
        (opportunityId === null || faIsValidate),
    }
  );

  useEffect(() => {
    editPrestation(null);
    setPrestationDetailsStatus("empty");
    debouncedSearch({ refetch });
  }, [wordEntered, refetch, editPrestation]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const openPrestationCreation = () => {
    editPrestation(null);
    setPrestationDetailsStatus("create");
  };

  const handlePrestationFA = useCallback(
    (checked, prestationProfession) => {
      if (!checked) {
        const majorationProfession = !prestationProfession.is_package
          ? defaultMajoration.id
          : null;
        createPrestationFA({
          prestation_profession_id: prestationProfession.id,
          framework_agreement_id: faId,
          majoration_profession_id: majorationProfession,
        });
      } else {
        deletePresFA({
          prestation_profession_id: prestationProfession.id,
          id: prestationProfession.prestation_included.id,
        });
      }
    },
    [faId, createPrestationFA, deletePresFA, defaultMajoration]
  );

  const handlePrestationOpportunity = useCallback(
    (checked, id) => {
      if (!checked) {
        createPrestationOpportunity({
          prestation_profession_id: id,
          opportunity_id: opportunityId,
        });
      } else {
        deletePresOpportunity({
          prestation_profession_id: id,
          opportunity_id: opportunityId,
        });
      }
    },
    [opportunityId, createPrestationOpportunity, deletePresOpportunity]
  );

  const handleFAAndOpp = useCallback(
    (checked, prestationProfession) => {
      if (from === "referencing")
        handlePrestationOpportunity(checked, prestationProfession.id);
      else handlePrestationFA(checked, prestationProfession);
    },
    [handlePrestationOpportunity, handlePrestationFA, from]
  );

  const getRelevantMajorations = useCallback(() => {
    if (
      from === "referencing" ||
      (from === "frameworkAgreement" && opportunityId !== null && !faIsValidate)
    ) {
      return majorationsOpp;
    }
    if (
      from === "frameworkAgreement" &&
      (opportunityId === null || faIsValidate)
    ) {
      return majorations;
    }
    return undefined;
  }, [from, majorationsOpp, majorations, opportunityId, faIsValidate]);

  const getRelevantDefaultMajoration = useCallback(() => {
    if (from === "referencing" && defaultValueOpp) {
      return defaultValueOpp[0]?.majoration_opportunity_id;
    }
    if (from === "frameworkAgreement" && defaultMajoration) {
      return defaultMajoration.id;
    }
    return undefined;
  }, [from, defaultMajoration, defaultValueOpp]);

  return (
    <Container>
      <div className="main_admin-materiels">
        <TitleContainer label="Prestations disponibles">
          {(from !== "frameworkAgreement" ||
            (from === "frameworkAgreement" && faIsValidate)) && (
            <AddButton
              onClick={openPrestationCreation}
              disabled={prestationDetailsStatus === "create"}
              label="Prestation"
            />
          )}
        </TitleContainer>

        <StyledSearch
          allowClear
          placeholder="Rechercher par nom de prestation ou catégorie"
          value={wordEntered}
          onChange={handleFilter}
        />
        <Separator isHorizontal size={3} />
        <div className="cascader-categiries-search" />
        <div>
          <PrestationArray
            prestationDetailsStatus={prestationDetailsStatus}
            prestations={prestations}
            setPrestationDetailsStatus={setPrestationDetailsStatus}
            entityWorkId={entityWorkId}
            from={from}
            handleFAAndOpp={handleFAAndOpp}
            faIsValidate={faIsValidate}
            entity_work_name={entity_work_name}
            configuration={configuration}
            vocabulary={vocabulary}
            use_special_tva={use_special_tva}
          />
        </div>
      </div>
      <Separator />
      <PrestationDetails
        use_special_tva={use_special_tva}
        categories={categories}
        profils={profils}
        unities={unities}
        prestationDetailsStatus={prestationDetailsStatus}
        setPrestationDetailsStatus={setPrestationDetailsStatus}
        entityWorkId={entityWorkId}
        from={from}
        opportunityId={opportunityId}
        handleFAAndOpp={handleFAAndOpp}
        faIsValidate={faIsValidate}
        faId={faId}
        majorations={getRelevantMajorations()}
        defaultMajoration={getRelevantDefaultMajoration()}
        prestations={prestations}
        entity_work_name={entity_work_name}
        configuration={configuration}
        vocabulary={vocabulary}
        defaultMinutes={defaultMinutes}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
  width: 100%;
`;

const StyledSearch = styled(Search)``;

export default Prestation;
