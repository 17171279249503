import React from "react";
import styled from "styled-components";
import Input from "../../../../react-ui/Input";
import Select from "../../../../react-ui/Select";

function Difficulty({
  updatePrestationOpportunity,
  value,
  id_opportunity,
  zone,
  difficulties,
  from,
}) {
  const difficulty_key =
    from === "admin" ? "difficulty_profession_id" : "difficulty_opportunity_id";
  return (
    <Container>
      <StyledSelect
        popupClassName={`Difficulty${zone.id}`}
        bordered={false}
        size="small"
        placeholder="Difficulty"
        onChange={(e) =>
          updatePrestationOpportunity({
            opportunity_id: id_opportunity,
            id: value.id,
            [difficulty_key]: e,
            ...(value?.ouvrage_parent_id && {
              ouvrage_parent_id: value.ouvrage_parent_id,
            }),
          })
        }
        value={
          value?.[difficulty_key]
            ? value?.[difficulty_key]
            : zone?.[difficulty_key]
        }
        options={difficulties}
        fontSize="10px"
        disabled={!value.id}
      />
      <StyledInput
        fontSize="10px"
        textAlign="right"
        size="small"
        value={
          value?.[difficulty_key]
            ? difficulties?.find(
                (difficulty) => difficulty.id === value?.[difficulty_key]
              )?.rate
            : difficulties?.find(
                (difficulty) => difficulty.id === zone?.[difficulty_key]
              )?.rate
        }
        disabled
      />
    </Container>
  );
}

const Container = styled.div`
  width: 140px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledSelect = styled(Select)`
  width: 100px;
`;

const StyledInput = styled(Input)`
  width: 38px;
`;

export default Difficulty;
