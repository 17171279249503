import { Form } from "antd";
import React, { useCallback, useEffect } from "react";
import styled from "styled-components";
import { validateMessages } from "../../../constant";
import FormCol from "../../../Form/FormCol";
import FormRow from "../../../Form/FormRow";
import Label from "../../../Form/Label";
import Input from "../../../react-ui/Input";
import Select from "../../../react-ui/Select";

function ProfilForm({
  works,
  users,
  handleSubmit,
  initialValues,
  profilStatus,
  form,
}) {
  const workId = Form.useWatch("work_id", form);

  // Remove users who are not in selected work
  const removeUsersNotInWork = useCallback(() => {
    if (workId) {
      const selectedUsers = form.getFieldValue("user_ids");
      form.setFieldsValue({
        user_ids: selectedUsers?.filter((id) =>
          users.find((user) => user.id === id).work_ids.includes(workId)
        ),
      });
    }
  }, [workId, form, users]);

  useEffect(() => {
    removeUsersNotInWork();
  }, [removeUsersNotInWork]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const updateOnChange = () => {
    if (profilStatus === "update") {
      removeUsersNotInWork();
      form.submit();
    }
  };

  const getUsers = () =>
    users?.filter((el) =>
      el.work_ids.includes(
        profilStatus === "create" ? workId : initialValues?.work_id
      )
    );

  return (
    <Form
      id="profil-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
      <FormRow>
        <FormCol label="Intitulé" name="name" rules={[{ required: true }]}>
          <Input onBlur={updateOnChange} />
        </FormCol>
        {profilStatus === "create" && (
          <FormCol label="Métier" name="work_id" rules={[{ required: true }]}>
            <Select
              showSearch={false}
              options={works}
              keyName="fullname"
              onChange={updateOnChange}
            />
          </FormCol>
        )}
        {profilStatus === "update" && (
          <WorkContainer>
            <WorkWrapper>
              <WorkLabel label="Métier : " />
              {works.find((w) => w.id === initialValues?.work_id)?.fullname}
            </WorkWrapper>
          </WorkContainer>
        )}
      </FormRow>
      <FormRow>
        <FormCol label="Utilisateurs concernés" name="user_ids">
          <Select
            mode="multiple"
            keyName="fullname"
            options={getUsers()}
            onChange={updateOnChange}
          />
        </FormCol>
      </FormRow>
    </Form>
  );
}

const WorkContainer = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
`;

const WorkWrapper = styled.div`
  padding-right: 8px;
`;

const WorkLabel = styled(Label)`
  margin-right: 8px;
`;

export default ProfilForm;
