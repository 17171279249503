import React, { useEffect, useMemo } from "react";
import { Popover } from "antd";
import { useQuery } from "react-query";
import styled from "styled-components";
import {
  costPriceRule,
  estimateQuantityRule,
  getRuleName,
  marginRule,
} from "../constants/rules";
import { getData } from "../request/instance";
import Warning from "../react-ui/Icons/Warning";
import Loader from "../react-ui/Loader";

function Warnings({
  opportunity_id,
  response_type,
  activePage,
  work,
  formToken,
  setWarnings,
}) {
  const rulesToCheck = useMemo(() => {
    if (work === "guarding") return [costPriceRule.id, marginRule.id];
    if (response_type === "consultation" || response_type === "quotation") {
      if (activePage === "1") return [estimateQuantityRule.id];
      if (activePage === "3") return [costPriceRule.id, marginRule.id];
    }
    return null;
  }, [response_type, activePage, work]);

  const { data: warnings, isLoading } = useQuery(
    ["Warnings", { opportunityId: opportunity_id }],
    () =>
      getData(
        formToken,
        `/warnings?opportunity_id=${opportunity_id}${rulesToCheck
          .map((rule) => `&rule_on[]=${rule}`)
          .join("")}`
      ),
    {
      enabled: !!rulesToCheck,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (warnings && setWarnings) setWarnings(warnings);
  }, [setWarnings, warnings]);

  async function clickElementWithRetry(
    elementSelector,
    maxDuration,
    interval,
    index,
    group = true,
    is_last = false
  ) {
    return new Promise(async (resolve) => {
      const startTime = Date.now();
      let clicked = false;

      const clickInterval = setInterval(() => {
        let element = document.querySelector(elementSelector);
        // Seulement si c'est l'ouvrage parent master et que c'est un ouvrage
        if (index === 0 && !group) {
          element = element?.firstChild?.firstChild;
        }
        if (element && !clicked) {
          if (
            element?.getAttribute("aria-expanded") === "false" ||
            element?.parentElement?.className ===
              "ant-tree-switcher ant-tree-switcher_close"
          ) {
            element.click();
            if (is_last) element?.scrollIntoView();
          }
          clicked = true;
        } else if (Date.now() - startTime >= maxDuration || clicked) {
          clearInterval(clickInterval);
          resolve(); // Résoud la promesse une fois le clic réussi ou après la durée maximale
        }
      }, interval);
    });
  }

  async function clickGroupParentIds(group_parent_id, type) {
    if (group_parent_id) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [index, group_id] of group_parent_id.entries()) {
        // eslint-disable-next-line no-await-in-loop
        await clickElementWithRetry(
          `[data-row-key='ouvrage-${group_id}']`,
          5000,
          200,
          index,
          type === "group",
          index === group_parent_id.length - 1
        );
      }
    }
  }

  const estimateWarningClick = (warning) => {
    const {
      material_opportunity_parent_id,
      zone_id,
      group_parent_id,
      ouvrage_parent_id,
    } = warning;

    // We retrieve panel, then we expand it if not expanded
    const panelNode = document.getElementById(`zone-${zone_id}`);

    if (panelNode?.firstChild.getAttribute("aria-expanded") === "false")
      panelNode?.firstChild.click();

    // We retrieve row, then we access the expand button to click on it if not expanded
    const materialParentNode = document.querySelector(
      `[data-row-key='material-${material_opportunity_parent_id}']`
    );
    if (materialParentNode === null && ouvrage_parent_id) {
      clickGroupParentIds(ouvrage_parent_id, "ouvrage");
    }
    const expandedButton = materialParentNode?.firstChild?.firstChild;

    if (expandedButton?.getAttribute("aria-expanded") === "false") {
      expandedButton.click();
    }
    materialParentNode?.scrollIntoView();
    if (group_parent_id) {
      clickGroupParentIds(group_parent_id, "group");
    }
  };

  const onWarningClick = (warning) => {
    if (warning.rule.rule_on === estimateQuantityRule.id) {
      estimateWarningClick(warning);
    }
  };

  const pageWarnings = () => (
    <WarningsContainer>
      {warnings?.map((w) => (
        <WarningWrapper key={w.id} onClick={() => onWarningClick(w)}>
          <WarningIcon className="fa-duotone fa-exclamation-triangle" />
          <span>{getRuleName({ ...w.rule })}</span>
        </WarningWrapper>
      ))}
    </WarningsContainer>
  );

  return (
    <Container>
      {isLoading && <Loader />}
      {warnings?.length > 0 && (
        <Popover placement="bottomRight" content={pageWarnings()}>
          <Wrapper>
            <WarningIcon
              className="fa-duotone fa-exclamation-triangle"
              $title
            />
            <WarningNumber>
              {warnings.length} avertissement
              {warnings.length > 1 ? "s" : ""}
            </WarningNumber>
          </Wrapper>
        </Popover>
      )}
    </Container>
  );
}

const Container = styled.div``;

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const WarningsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const WarningNumber = styled.p`
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-size: 16px !important;
  font-weight: 600;
  color: ${({ theme: styledTheme }) => styledTheme.colors.yellow06};
`;

const WarningWrapper = styled.div`
  display: flex;
  width: max-content;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

const WarningIcon = styled(Warning)`
  margin-right: ${({ $title }) => ($title ? "8px" : "3px")};
`;

export default Warnings;
