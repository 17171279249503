import React, { useState } from "react";
import { Segmented } from "antd";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useStore } from "../../store";
import Carrycots from "../Carrycots";
import Material from "../Materials";
import Movements from "../Movements";
import Prestation from "../Prestations";
import FAForm from "./FAForm";
import TitleContainer from "../../react-ui/TitleContainer";
import { Loading } from "../../loading";
import { getData, postData } from "../../request/instance";
import FAMajorations from "./FAMajorations";
import { fetchMajorationsUrl } from "../../../utils/fetchMajorationsUrl";
import { fetchDifficultiesUrl } from "../../../utils/fetchDifficultiesUrl";
import FADifficulties from "./FADifficulties";

const selector = (state) => ({
  formToken: state.formToken,
});

function FADetails({
  entityWorkId,
  setSelectedFA,
  selectedFA,
  constructFAFormData,
  clients,
  entities,
  initialFA,
  setInitialFA,
  referencingOppId,
  configuration,
  vocabulary,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const [selectedSegment, setSelectedSegment] = useState("Matériels");
  const [status, setStatus] = useState("idle");

  const { data: itemsToCreate } = useQuery(
    ["ItemsToCreate", { faId: selectedFA.id }],
    () =>
      getData(
        formToken,
        `/framework_agreement/items_to_create/${selectedFA.id}`
      ),
    { enabled: referencingOppId !== null && !selectedFA.status }
  );

  const { mutate: updateFA } = useMutation(
    (todo) => postData(formToken, "/framework_agreement/update", todo),
    {
      onMutate: () => {
        const previousFA = selectedFA;
        setStatus("loading");
        return { previousFA };
      },
      onError: (_, __, context) => {
        setSelectedFA(context.previousFA);
        setStatus("error");
        // Reset form on error
        setInitialFA(context.previousFA);
      },
      onSuccess: (payload) => {
        setSelectedFA(payload);
        setStatus("success");
        queryClient.invalidateQueries("FrameworkAgreements");
        queryClient.invalidateQueries("Majorations");
      },
    }
  );

  const { data: majorations } = useQuery(
    ["Majorations", { from: "frameworkAgreement" }],
    async () =>
      getData(
        formToken,
        fetchMajorationsUrl({ from: "frameworkAgreement", id: selectedFA.id })
      )
  );

  const { data: difficulties } = useQuery(
    ["Difficulties", { from: "frameworkAgreement" }],
    async () =>
      getData(
        formToken,
        fetchDifficultiesUrl({ from: "frameworkAgreement", id: selectedFA.id })
      )
  );

  const options = [
    {
      value: "Matériels",
      label: (
        <SegmentLabel isSelected={selectedSegment === "Matériels"}>
          Matériels
        </SegmentLabel>
      ),
    },
    {
      value: "Prestations",
      label: (
        <SegmentLabel isSelected={selectedSegment === "Prestations"}>
          Prestations
        </SegmentLabel>
      ),
    },
    {
      value: "Nacelles",
      label: (
        <SegmentLabel isSelected={selectedSegment === "Nacelles"}>
          Nacelles
        </SegmentLabel>
      ),
    },
    {
      value: "Déplacements",
      label: (
        <SegmentLabel isSelected={selectedSegment === "Déplacements"}>
          Déplacements
        </SegmentLabel>
      ),
    },
    {
      value: "Paramètres généraux",
      label: (
        <SegmentLabel isSelected={selectedSegment === "Paramètres généraux"}>
          Paramètres généraux
        </SegmentLabel>
      ),
    },
  ];

  const onSubmit = (e) => {
    const formData = constructFAFormData(e);
    formData.append("entity_work_id", entityWorkId);
    formData.append("id", selectedFA.id);
    updateFA(formData);
  };

  const handleOnChangeSegment = (e) => {
    if (e === "Paramètres généraux") {
      setInitialFA(selectedFA);
      setStatus("idle");
    }
    setSelectedSegment(e);
  };

  const getStatus = () => {
    if (status === "loading") return "loading";
    if (status === "success") return "success";
    return "idle";
  };

  return (
    <Container>
      <HeaderContainer>
        <RightHeaderSection>
          <BackContainer
            onClick={() => {
              setSelectedFA();
              setInitialFA();
            }}
          >
            <BackIcon className="fa-duotone fa-arrow-left" />
            Retour aux accords cadres
          </BackContainer>
          <NameContainer>
            <Label>Accord-cadre sélectionné : </Label>
            <Name>{selectedFA.name}</Name>
          </NameContainer>
        </RightHeaderSection>
        <Segmented options={options} onChange={handleOnChangeSegment} />
      </HeaderContainer>
      <Wrapper>
        {selectedSegment === "Matériels" && (
          <Material
            token={formToken}
            entityWorkId={entityWorkId}
            from="frameworkAgreement"
            faIsValidate={selectedFA.status}
            faId={selectedFA.id}
            opportunityId={referencingOppId}
            configuration={configuration}
            vocabulary={vocabulary}
            defaultMajoration={selectedFA.default_value?.majoration_profession}
          />
        )}

        {selectedSegment === "Prestations" && (
          <Prestation
            entityWorkId={entityWorkId}
            from="frameworkAgreement"
            faIsValidate={selectedFA.status}
            faId={selectedFA.id}
            opportunityId={referencingOppId}
            defaultMajoration={selectedFA.default_value?.majoration_profession}
            configuration={configuration}
            vocabulary={vocabulary}
          />
        )}

        {selectedSegment === "Nacelles" && (
          <Carrycots
            entityWorkId={entityWorkId}
            from="frameworkAgreement"
            faIsValidate={selectedFA.status}
            faId={selectedFA.id}
            opportunityId={referencingOppId}
            configuration={configuration}
            vocabulary={vocabulary}
          />
        )}

        {selectedSegment === "Déplacements" && (
          <Movements
            entityWorkId={entityWorkId}
            from="frameworkAgreement"
            faIsValidate={selectedFA.status}
            faId={selectedFA.id}
            opportunityId={referencingOppId}
            configuration={configuration}
            vocabulary={vocabulary}
          />
        )}
        {selectedSegment === "Paramètres généraux" && (
          <FAFormWrapper>
            <TitleContainer label="Détails de l'accord-cadre">
              <Loading status={getStatus()} />
            </TitleContainer>
            <FAForm
              onSubmit={onSubmit}
              updateOnChange
              clients={clients}
              entities={entities}
              itemsToCreate={itemsToCreate}
              initialFA={initialFA}
              majorations={majorations}
              difficulties={difficulties}
            />
            {selectedFA.status && (
              <FAMajorations
                selectedFA={selectedFA}
                majorations={majorations}
              />
            )}
            {selectedFA.status && (
              <FADifficulties
                selectedFA={selectedFA}
                difficulties={difficulties}
              />
            )}
          </FAFormWrapper>
        )}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: calc(100% - 52px);
`;

const SegmentLabel = styled.p`
  font-size: 14px !important;
  margin: 0;
  font-weight: 400;
  color: ${({ isSelected }) =>
    isSelected ? "#1890ff" : "rgba(0, 0, 0, 0.65)"};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  &:hover {
    color: #1890ff;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
`;

const BackContainer = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blue09};
  &:hover {
    color: ${({ theme }) => theme.colors.blue04};
  }
  transition: color 0.15s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const BackIcon = styled.i`
  font-size: 20px;
  margin-right: 10px;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.p`
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.blue11};
  margin: 0;
  font-weight: 600;
  margin-right: 5px;
`;

const Name = styled.p`
  font-size: 14px !important;
  margin: 0;
`;

const RightHeaderSection = styled.div`
  display: flex;
  align-items: center;
`;

const FAFormWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default FADetails;
