import React from "react";
import NumberCell from "../components/react-ui/NumberCell";
import WarningLabel from "../components/react-ui/WarningLabel";
import { formatNumberString } from "../utils/formatNumberString";
import { marginToCoeff } from "../utils/marginConverter";
import { numberSorter } from "../utils/numberSorter";

const createRender = ({
  value,
  record,
  render,
  isUsingMargin,
  calculMethod,
  warningLabel,
  hasWarning,
}) => {
  const newValue = formatNumberString({
    str: marginToCoeff({ marginRate: value, isUsingMargin, calculMethod }),
    isCoeff: !isUsingMargin,
  });

  if (!!render) {
    return render(newValue, record);
  }

  const marginComponent = (
    <NumberCell>
      {newValue}
      {isUsingMargin ? " %" : ""}
    </NumberCell>
  );

  return !warningLabel ? (
    marginComponent
  ) : (
    <WarningLabel hasWarning={hasWarning}>{marginComponent}</WarningLabel>
  );
};

export const getMarginCol = ({
  isUsingMargin,
  keyName = "margin",
  width = 60,
  align = "right",
  hidden,
  hasSorter = true,
  customSorter = false,
  render = null,
  hasWarning = false,
  warningLabel = false,
  className = "",
  calculMethod,
  entity_work_name,
  configuration,
}) => {
  return {
    title: isUsingMargin ? "Marge" : "Coeff.",
    hidden: hidden || configuration.costing.page_3.show_margin === false,
    dataIndex: keyName,
    width,
    align,
    ...(hasSorter && {
      sorter: customSorter
        ? true
        : (a, b) => numberSorter(a[keyName], b[keyName]),
    }),
    className,
    render: (value, record) =>
      createRender({
        value,
        record,
        render,
        isUsingMargin,
        calculMethod,
        hasWarning,
        warningLabel,
      }),
  };
};
