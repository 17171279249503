import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import { useQuery } from "react-query";
import Search from "antd/lib/input/Search";
import ImportButton from "../../react-ui/ImportButton";
import { getData } from "../../request/instance";
import { fetchDocumentsUrl } from "../../../utils/fetchDocumentsUrl";
import Separator from "../../react-ui/Separator";
import ImportDocumentDetails from "./ImportDocumentDetails";
import DocumentsArray from "../../Admin-cf/DocumentsFolders/Documents/DocumentsArray";
import TitleContainer from "../../react-ui/TitleContainer";

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function ImportDocument({
  offer_id,
  opportunity_id,
  formToken,
  isOpen,
  status,
  setStatus,
  supervisor,
}) {
  const [selectedDocument, setSelectedDocument] = useState();
  const [wordEntered, setWordEntered] = useState("");

  const {
    data: documents,
    refetch,
    isLoading: documentsAreLoading,
  } = useQuery(
    ["Documents", { offer_id, opportunity_id }],
    () =>
      getData(
        formToken,
        fetchDocumentsUrl({ search: wordEntered, opportunity_id, offer_id })
      ),
    { refetchOnWindowFocus: false, enabled: isOpen }
  );

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };

  const clearInput = () => {
    setWordEntered("");
  };

  return (
    <Container>
      <Wrapper $supervisor={supervisor}>
        <TitleContainer
          label={`Documents de ${offer_id ? "l'offre" : "l'opportunité"}`}
        >
          {!supervisor && (
            <ImportButton
              onClick={() => {
                setStatus("create");
                setSelectedDocument();
              }}
              disabled={status === "create"}
              label="Documents"
            />
          )}
        </TitleContainer>
        <StyledSearch
          allowClear
          placeholder="Rechercher par nom de document"
          value={wordEntered}
          onChange={handleFilter}
        />
        <DocumentsArray
          documents={documents}
          setSelectedDocument={setSelectedDocument}
          setStatus={setStatus}
          documentsAreLoading={documentsAreLoading}
          fromCosting
          tableHeight={410}
          selectedDocument={selectedDocument}
          invalidateQuery={["Documents", { opportunity_id, offer_id }]}
          formToken={formToken}
          supervisor={supervisor}
        />
      </Wrapper>
      {!supervisor && (
        <>
          <Separator />
          <ImportDocumentDetails
            opportunity_id={opportunity_id}
            formToken={formToken}
            status={status}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
            setStatus={setStatus}
            offer_id={offer_id}
          />
        </>
      )}
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  height: 100%;
  max-width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ $supervisor }) => ($supervisor ? "100%" : "50%")};
  height: 100%;
`;

const StyledSearch = styled(Search)`
  margin-bottom: 5px;
`;

export default ImportDocument;
