import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { debounce } from "lodash";
import { Modal } from "antd";
import UsersArray from "./UsersArray";
import Separator from "../../react-ui/Separator";
import { getData } from "../../request/instance";
import { fetchUsersUrl } from "../../../utils/fetchUsersUrl";
import { useStore } from "../../store";
import UserInformations from "./UserInformations";
import AddButton from "../../react-ui/AddButton";
import InviteForm from "./InviteForm";
import { handlePictureKey } from "../../../utils/createFormData";
import { generateFormData } from "../../../utils/generateFormData";

const selector = (state) => ({
  formToken: state.formToken,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function Users({
  works,
  entities,
  roles,
  currentUserId,
  sites = [],
  option_documents,
}) {
  const [status, setStatus] = useState("empty");
  const [selectedUserId, setSelectedUserId] = useState();
  const { formToken } = useStore(selector);

  const [wordEntered, setWordEntered] = useState("");
  const [isInviteOpen, setIsInviteOpen] = useState(false);

  const {
    data: users,
    refetch,
    isLoading: usersAreLoading,
  } = useQuery(
    "Users",
    () => getData(formToken, fetchUsersUrl({ search: wordEntered })),
    { refetchOnWindowFocus: false }
  );

  const { data: allUsers } = useQuery(
    ["Users", { all: true }],
    () => getData(formToken, fetchUsersUrl({ search: undefined })),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  const closeModal = useCallback(() => {
    setIsInviteOpen(false);
  }, []);

  const openModal = () => {
    setIsInviteOpen(true);
  };

  const generateUserFormData = useCallback(
    (values) => {
      const pictureName = "avatar";
      const formName = "user";
      const formData = generateFormData({
        e: values,
        formName,
        excludedKeys: [
          pictureName,
          "active",
          "user_entity_work_ids",
          "client_ids",
        ],
      });
      if (selectedUserId && selectedUserId !== currentUserId) {
        formData.append(`${formName}[active]`, !!values.active);
      }
      if (!values.entity_id && values.type === "buyer")
        formData.append(
          `${formName}[entity_id]`,
          entities.find((el) => !el.parent_id).id
        );
      if (values[pictureName]) {
        handlePictureKey({
          formData,
          picture: values[pictureName],
          keyName: formName,
          pictureName,
        });
      }
      values.user_entity_work_ids?.forEach((workId) =>
        formData.append(`${formName}[user_entity_work_ids][]`, workId)
      );
      values.client_ids?.forEach((clientId) =>
        formData.append(`${formName}[client_ids][]`, clientId)
      );
      if (selectedUserId) formData.append("id", selectedUserId);
      return formData;
    },
    [currentUserId, selectedUserId, entities]
  );

  return (
    <Container>
      <InivteButton label="Inviter utilisateur" onClick={openModal} />
      <StyledModal
        open={isInviteOpen}
        maskClosable={false}
        footer={null}
        width={900}
        destroyOnClose
        onCancel={closeModal}
        closable={false}
      >
        <InviteForm
          roles={roles}
          entities={entities}
          works={works}
          allUsers={allUsers}
          closeModal={closeModal}
          generateUserFormData={generateUserFormData}
          sites={sites}
        />
      </StyledModal>
      <UsersArray
        works={works}
        entities={entities}
        roles={roles}
        users={users}
        usersAreLoading={usersAreLoading}
        wordEntered={wordEntered}
        setWordEntered={setWordEntered}
        selectedUserId={selectedUserId}
        setStatus={setStatus}
        setSelectedUserId={setSelectedUserId}
        sites={sites}
      />
      <Separator />
      <UserInformations
        status={status}
        users={users}
        selectedUserId={selectedUserId}
        setStatus={setStatus}
        currentUserId={currentUserId}
        roles={roles}
        entities={entities}
        works={works}
        allUsers={allUsers}
        generateUserFormData={generateUserFormData}
        sites={sites}
        option_documents={option_documents}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: calc(100vw - 85px);
  position: fixed;
  height: calc(100% - 135.56px);
  padding-right: 15px;
  padding-bottom: 10px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 300px;
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

const InivteButton = styled(AddButton)`
  position: absolute;
  top: -40px;
  right: 15px;
  width: 120px;
`;

export default Users;
