import { Card, Divider, Input } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { deepEqual } from "../FilesImport";
import { checkIsItemInOpportunityVariable } from "./helper-general";
import { StyledCard } from "../styledComponent";

function Vocabulaire({ setVocabulary, vocabulary, configuration }) {
  const [products, setProducts] = useState({
    MajusculeSingulier: vocabulary?.product,
    MajusculePluriel: vocabulary?.products,
    MinusculeSingulier: vocabulary?.product_lowercase,
    MinusculePluriel: vocabulary?.products_lowercase,
  });

  const [prestations, setPrestations] = useState({
    MajusculeSingulier: vocabulary.prestation,
    MajusculePluriel: vocabulary.prestations,
    MinusculeSingulier: vocabulary.prestation_lowercase,
    MinusculePluriel: vocabulary.prestations_lowercase,
  });

  const [others, setOthers] = useState({
    Deplacement: vocabulary.movements,
    Nacelles: vocabulary.carrycots,
  });

  const [breadcrumb, setBreadcrumb] = useState({
    Page1: vocabulary.breadcrumb.title_page_1,
    Page2: vocabulary.breadcrumb.title_page_2,
    Page3: vocabulary.breadcrumb.title_page_3,
  });

  const [clients, setClients] = useState({
    MajusculeSingulier: vocabulary.clients.client_uppercase,
    MajusculePluriel: vocabulary.clients.clients,
    MinusculeSingulier: vocabulary.clients.client_lowercase,
    MinusculePluriel: vocabulary.clients.clients_lowercase,
    UnClient: vocabulary.clients.a_client,
    leClient: vocabulary.clients.the_client,
    CeClient: vocabulary.clients.this_client,
    Nom: vocabulary.clients.name,
    ClientParent: vocabulary.clients.parent_client,
  });

  const [opportunity, setOpportunity] = useState({
    ClientFacture: vocabulary.opportunity.billed_customer,
    ClientPreste: vocabulary.opportunity.provided_customer,
    ClientDecisionnaire: vocabulary.opportunity.decision_customer,
    ChezLeClient: vocabulary.opportunity.inside_client,
    DuClient: vocabulary.opportunity.of_client,
  });

  useEffect(() => {
    let newVocabulary = {};
    newVocabulary = {
      ...vocabulary,
      products: products.MajusculePluriel,
      product: products.MajusculeSingulier,
      product_lowercase: products.MinusculeSingulier,
      products_lowercase: products.MinusculePluriel,
    };
    if (!deepEqual(newVocabulary, vocabulary)) {
      setVocabulary(newVocabulary);
    }

    newVocabulary = {
      ...vocabulary,
      prestations: prestations.MajusculePluriel,
      prestation: prestations.MajusculeSingulier,
      prestation_lowercase: prestations.MinusculeSingulier,
      prestations_lowercase: prestations.MinusculePluriel,
    };
    if (!deepEqual(newVocabulary, vocabulary)) {
      setVocabulary(newVocabulary);
    }

    newVocabulary = {
      ...vocabulary,
      movements: others.Deplacement,
      carrycots: others.Nacelles,
    };

    if (!deepEqual(newVocabulary, vocabulary)) {
      setVocabulary(newVocabulary);
    }

    newVocabulary = {
      ...vocabulary,
      breadcrumb: {
        title_page_1: breadcrumb.Page1,
        title_page_2: breadcrumb.Page2,
        title_page_3: breadcrumb.Page3,
      },
    };

    if (!deepEqual(newVocabulary, vocabulary)) {
      setVocabulary(newVocabulary);
    }

    newVocabulary = {
      ...vocabulary,
      clients: {
        client_uppercase: clients.MajusculeSingulier,
        clients: clients.MajusculePluriel,
        client_lowercase: clients.MinusculeSingulier,
        clients_lowercase: clients.MinusculePluriel,
        a_client: clients.UnClient,
        the_client: clients.leClient,
        this_client: clients.CeClient,
        name: clients.Nom,
        parent_client: clients.ClientParent,
      },
    };

    if (!deepEqual(newVocabulary, vocabulary)) {
      setVocabulary(newVocabulary);
    }

    newVocabulary = {
      ...vocabulary,
      opportunity: {
        billed_customer: opportunity.ClientFacture,
        provided_customer: opportunity.ClientPreste,
        decision_customer: opportunity.ClientDecisionnaire,
        inside_client: opportunity.ChezLeClient,
        of_client: opportunity.DuClient,
      },
    };

    if (!deepEqual(newVocabulary, vocabulary)) {
      setVocabulary(newVocabulary);
    }
  }, [products, prestations, others, breadcrumb, clients, opportunity]);

  return (
    <div>
      <StyledDivider
        style={{
          height: "24px",
          borderColor: "rgba(11, 34, 57, 1)",
          gap: "8px",
        }}
      >
        Vocabulaire
      </StyledDivider>
      {/* ---------- PRODUITS --------- */}
      <StyledCard
        headStyle={{ backgroundColor: "#0B2239", color: "white" }}
        bodyStyle={{ backgroundColor: "#F5F6F8" }}
        size="small"
        title="Produit"
        style={{
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <GridContainer>
          <p className="first">Majuscule singulier</p>
          <Input
            disabled={!configuration.costing.page_1.visible}
            className="second"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            size="small"
            value={products.MajusculeSingulier}
            onChange={(e) =>
              setProducts({
                ...products,
                MajusculeSingulier: e.target.value,
              })
            }
          />
          <p className="third">Minuscule singulier</p>
          <Input
            disabled={!configuration.costing.page_1.visible}
            className="fourth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={products.MinusculeSingulier}
            onChange={(e) =>
              setProducts({
                ...products,
                MinusculeSingulier: e.target.value,
              })
            }
          />
          <p className="fifth">Majuscule pluriel</p>
          <Input
            disabled={!configuration.costing.page_1.visible}
            className="sixth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={products.MajusculePluriel}
            onChange={(e) =>
              setProducts({
                ...products,
                MajusculePluriel: e.target.value,
              })
            }
          />
          <p className="seventh">Minuscule pluriel</p>
          <Input
            disabled={!configuration.costing.page_1.visible}
            className="eighth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={products.MinusculePluriel}
            onChange={(e) =>
              setProducts({
                ...products,
                MinusculePluriel: e.target.value,
              })
            }
          />
        </GridContainer>
      </StyledCard>
      {/* ---------- PRESTATIONS --------- */}
      <StyledCard
        headStyle={{ backgroundColor: "#0B2239", color: "white" }}
        bodyStyle={{ backgroundColor: "#F5F6F8" }}
        size="small"
        title="Prestations"
        style={{
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <GridContainer>
          <p className="first">Majuscule singulier</p>
          <Input
            disabled={!configuration.costing.page_1.visible}
            className="second"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={prestations.MajusculeSingulier}
            onChange={(e) =>
              setPrestations({
                ...prestations,
                MajusculeSingulier: e.target.value,
              })
            }
          />
          <p className="third">Majuscule pluriel</p>
          <Input
            disabled={!configuration.costing.page_1.visible}
            className="fourth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={prestations.MajusculePluriel}
            onChange={(e) =>
              setPrestations({
                ...prestations,
                MajusculePluriel: e.target.value,
              })
            }
          />
          <p className="fifth">Minuscule singulier</p>
          <Input
            disabled={!configuration.costing.page_1.visible}
            className="sixth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={prestations.MinusculeSingulier}
            onChange={(e) =>
              setPrestations({
                ...prestations,
                MinusculeSingulier: e.target.value,
              })
            }
          />
          <p className="seventh">Minuscule pluriel</p>
          <Input
            disabled={!configuration.costing.page_1.visible}
            className="eighth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={prestations.MinusculePluriel}
            onChange={(e) =>
              setPrestations({
                ...prestations,
                MinusculePluriel: e.target.value,
              })
            }
          />
        </GridContainer>
      </StyledCard>
      {/* ---------- AUTRES --------- */}
      <StyledCard
        headStyle={{ backgroundColor: "#0B2239", color: "white" }}
        bodyStyle={{ backgroundColor: "#F5F6F8" }}
        size="small"
        title="Autres"
        style={{
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <GridContainer>
          <p className="first">Déplacements</p>
          <Input
            disabled={
              !configuration.costing.page_2.visible ||
              !configuration.admin.movement_tab.visible
            }
            className="second"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={others.Deplacement}
            onChange={(e) =>
              setOthers({
                ...others,
                Deplacement: e.target.value,
              })
            }
          />
          <p className="third">Nacelles</p>
          <Input
            disabled={
              !configuration.costing.page_2.visible ||
              !configuration.admin.carrycot_tab.visible
            }
            className="fourth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={others.Nacelles}
            onChange={(e) =>
              setOthers({
                ...others,
                Nacelles: e.target.value,
              })
            }
          />
        </GridContainer>
      </StyledCard>
      {/* ---------- FIL D'ARIANE --------- */}
      <StyledCard
        headStyle={{ backgroundColor: "#0B2239", color: "white" }}
        bodyStyle={{ backgroundColor: "#F5F6F8" }}
        size="small"
        title="Fil d'Ariane"
        style={{
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <GridContainer>
          <p className="first">Page 1</p>
          <Input
            disabled={!configuration.costing.page_1.visible}
            className="second"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={breadcrumb.Page1}
            onChange={(e) =>
              setBreadcrumb({
                ...breadcrumb,
                Page1: e.target.value,
              })
            }
          />
          <p className="third">Page 2</p>
          <Input
            disabled={!configuration.costing.page_2.visible}
            className="fourth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={breadcrumb.Page2}
            onChange={(e) =>
              setBreadcrumb({
                ...breadcrumb,
                Page2: e.target.value,
              })
            }
          />
          <p className="fifth">Page 3</p>
          <Input
            disabled={!configuration.costing.page_3.visible}
            className="sixth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={breadcrumb.Page3}
            onChange={(e) =>
              setBreadcrumb({
                ...breadcrumb,
                Page3: e.target.value,
              })
            }
          />
        </GridContainer>
      </StyledCard>
      {/* ---------- CLIENTS --------- */}
      <StyledCard
        headStyle={{ backgroundColor: "#0B2239", color: "white" }}
        bodyStyle={{ backgroundColor: "#F5F6F8" }}
        size="small"
        title="Clients"
        style={{
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <GridContainer>
          <p className="first">Majuscule singulier</p>
          <Input
            className="second"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={clients.MajusculeSingulier}
            onChange={(e) =>
              setClients({
                ...clients,
                MajusculeSingulier: e.target.value,
              })
            }
          />
          <p className="third">Majuscule pluriel</p>
          <Input
            className="fourth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={clients.MajusculePluriel}
            onChange={(e) =>
              setClients({
                ...clients,
                MajusculePluriel: e.target.value,
              })
            }
          />
          <p className="fifth">Minuscule singulier</p>
          <Input
            className="sixth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={clients.MinusculeSingulier}
            onChange={(e) =>
              setClients({
                ...clients,
                MinusculeSingulier: e.target.value,
              })
            }
          />
          <p className="seventh">Minuscule pluriel</p>
          <Input
            className="eighth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={clients.MinusculePluriel}
            onChange={(e) =>
              setClients({
                ...clients,
                MinusculePluriel: e.target.value,
              })
            }
          />
          <p className="ninth">Un client</p>
          <Input
            className="tenth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={clients.UnClient}
            onChange={(e) =>
              setClients({
                ...clients,
                UnClient: e.target.value,
              })
            }
          />
          <p className="eleventh">le client</p>
          <Input
            className="twelfth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={clients.leClient}
            onChange={(e) =>
              setClients({
                ...clients,
                leClient: e.target.value,
              })
            }
          />
          <p className="thirteenth">Ce client</p>
          <Input
            className="fourteenth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={clients.CeClient}
            onChange={(e) =>
              setClients({
                ...clients,
                CeClient: e.target.value,
              })
            }
          />
          <p className="fifteenth">Nom</p>
          <Input
            className="sixteenth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={clients.Nom}
            onChange={(e) =>
              setClients({
                ...clients,
                Nom: e.target.value,
              })
            }
          />
          <p className="seventeenth">Client parent</p>
          <Input
            className="eighteenth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={clients.ClientParent}
            onChange={(e) =>
              setClients({
                ...clients,
                ClientParent: e.target.value,
              })
            }
          />
        </GridContainer>
      </StyledCard>
      {/* ---------- OPPORTUNITE --------- */}
      <StyledCard
        headStyle={{ backgroundColor: "#0B2239", color: "white" }}
        bodyStyle={{ backgroundColor: "#F5F6F8" }}
        size="small"
        title="Opportunité"
        style={{
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <GridContainer>
          <p className="first">Client facturé</p>
          <Input
            className="firstFull"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={opportunity.ClientFacture}
            onChange={(e) =>
              setOpportunity({
                ...opportunity,
                ClientFacture: e.target.value,
              })
            }
          />
          <p className="fifth">Client presté</p>
          <Input
            disabled={
              !checkIsItemInOpportunityVariable(
                "provided_customer_contacts",
                "complement",
                configuration
              )
            }
            className="secondFull"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={opportunity.ClientPreste}
            onChange={(e) =>
              setOpportunity({
                ...opportunity,
                ClientPreste: e.target.value,
              })
            }
          />
          <p className="ninth">Client décisionnaire</p>
          <Input
            disabled={
              !checkIsItemInOpportunityVariable(
                "decision_maker_customer_contacts",
                "complement",
                configuration
              )
            }
            className="thirdFull"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={opportunity.ClientDecisionnaire}
            onChange={(e) =>
              setOpportunity({
                ...opportunity,
                ClientDecisionnaire: e.target.value,
              })
            }
          />
          <p className="thirteenth">Chez le client</p>
          <Input
            className="fourteenth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={opportunity.ChezLeClient}
            onChange={(e) =>
              setOpportunity({
                ...opportunity,
                ChezLeClient: e.target.value,
              })
            }
          />
          <p className="fifteenth">Du client</p>
          <Input
            className="sixteenth"
            size="small"
            style={{
              fontWeight: 400,
              borderRadius: "5px",
            }}
            value={opportunity.DuClient}
            onChange={(e) =>
              setOpportunity({
                ...opportunity,
                DuClient: e.target.value,
              })
            }
          />
        </GridContainer>
      </StyledCard>
    </div>
  );
}

const StyledDivider = styled(Divider)`
  .ant-divider-inner-text {
    color: #0b2239;
    font-size: 14px;
    font-weight: 200 !important;
    line-height: 24px;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 20% 20% auto 20% 20%;
  /* gap: 8px; */
  row-gap: 10px;
  grid-auto-rows: 1fr;
  align-items: center;
  justify-content: space-around;
  justify-items: end;

  p {
    margin: 0;
    font-size: 12px;
  }

  .first {
    grid-area: 1 / 1 / 2 / 2;
  }

  .second {
    grid-area: 1 / 2 / 2 / 3;
  }

  .third {
    grid-area: 1 / 4 / 2 / 5;
  }

  .fourth {
    grid-area: 1 / 5 / 2 / 6;
  }

  .fifth {
    grid-area: 2 / 1 / 3 / 2;
  }

  .sixth {
    grid-area: 2 / 2 / 3 / 3;
  }

  .seventh {
    grid-area: 2 / 4 / 3 / 5;
  }

  .eighth {
    grid-area: 2 / 5 / 3 / 6;
  }

  .ninth {
    grid-area: 3 / 1 / 4 / 2;
  }

  .tenth {
    grid-area: 3 / 2 / 4 / 3;
  }

  .eleventh {
    grid-area: 3 / 4 / 4 / 5;
  }

  .twelfth {
    grid-area: 3 / 5 / 4 / 6;
  }

  .thirteenth {
    grid-area: 4 / 1 / 5 / 2;
  }

  .fourteenth {
    grid-area: 4 / 2 / 5 / 3;
  }

  .fifteenth {
    grid-area: 4 / 4 / 5 / 5;
  }

  .sixteenth {
    grid-area: 4 / 5 / 5 / 6;
  }

  .seventeenth {
    grid-area: 5 / 1 / 6 / 2;
  }

  .eighteenth {
    grid-area: 5 / 2 / 6 / 3;
  }

  .firstFull {
    grid-area: 1 / 2 / 2 / 6;
  }

  .secondFull {
    grid-area: 2 / 2 / 3 / 6;
  }

  .thirdFull {
    grid-area: 3 / 2 / 4 / 6;
  }
`;

export default Vocabulaire;
