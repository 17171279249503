import React, { useMemo } from "react";
import FormCol from "../../../Form/FormCol";
import Select from "../../../react-ui/Select";

export const securityFields = [
  {
    name: "Agence uniquement",
    id: "entity",
  },
  {
    name: "Agence et documents globaux",
    id: "entity_global",
  },
  {
    name: "Agence, documents globaux et parenté",
    id: "entity_related_global",
  },
];

function SecurityCol({ disabled, security, hiddenSecurityFields = [] }) {
  const getSecurityCustomContent = useMemo(() => {
    if (!security) return undefined;
    const base = "Les utilisateurs sont autorisés à accéder aux documents ";
    if (security === "entity") {
      return `${base}de leur agence uniquement`;
    }
    if (security === "entity_global") {
      return `${base}de leur agence et de l’entreprise`;
    }
    return `${base}de leur agence, de l’entreprise et des entités parents/enfants de leur agence`;
  }, [security]);

  return (
    <FormCol
      rules={[{ required: true }]}
      width="50%"
      label="Droits d'accès"
      name="security"
    >
      <Select
        showSearch={false}
        options={securityFields.filter(
          (s) => !hiddenSecurityFields.includes(s.id)
        )}
        disabled={disabled}
        customContent={getSecurityCustomContent}
      />
    </FormCol>
  );
}

export default SecurityCol;
