export const constructMinsOrH = (val) => (val < 10 ? "0" : "") + val;

const getEndWp = (wp, wpArray) => {
  if (!wp.linked_work_period) return wp;
  return getEndWp(
    wpArray.find((el) => el.id === wp.linked_work_period.id),
    wpArray
  );
};

export const formatInitialWorkPeriods = (wpp) =>
  wpp
    ?.filter((el) => !el.plannings_work_period_id)
    .map((el) => {
      const endWp = getEndWp(el, wpp);
      return {
        ...el,
        end_datetime: endWp.end_datetime,
        end_day_type: endWp.day_type,
        end_at_midnight: endWp.end_at_midnight,
      };
    });

export const getApplicatedDays = (days) =>
  days
    ?.sort((a, b) => new Date(a) - new Date(b))
    ?.map((el) => {
      return new Date(el).toLocaleDateString("fr-FR");
    })
    .join(", ");
