import React from "react";
import { instance, postData, getData } from "./instance";
import { useStore } from "../store";
import { useMutation, useQuery } from "react-query";

const getUrlParams = () => {
    const lastSegment = window.location.pathname.split("/").pop();
    if (!lastSegment) return "error"
    return lastSegment
};



export const fetchDeplacementProfession = (formToken) => {
    const { data, isSuccess, refetch} = useQuery('getDeplacementProfessions', () => getData(formToken, `/movement_profession/${getUrlParams()}`))
    return data
}

export const updateDeplacements = (formToken) => useMutation(updateTodo => postData(formToken, "/movement_profession/update", updateTodo))

export const createMovements = (formToken) => useMutation(createTodo=> postData(formToken, "/movement_profession/create", createTodo))

export const deleteMovements = (formToken) => useMutation(deleteTodo => postData(formToken, "/movement_profession/delete", deleteTodo))