import React, { useCallback, useMemo } from "react";
import PropTypes, { func } from "prop-types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { useStore } from "../../store";
import { Loading } from "../../loading";
import { getData, postData } from "../../request/instance";
import MaterialsForm from "./MaterialsForm";
import TitleContainer from "../../react-ui/TitleContainer";
import Button from "../../react-ui/Button";
import AddButton from "../../react-ui/AddButton";
import {
  createMaterialProfessionForm,
  formDataToJson,
  handleDriveLinkKey,
} from "../../../utils/createFormData";
import { eurosToCents } from "../../../utils/currencyConverter";
import DetailTabs from "../DetailTabs";
import { getUrlParams } from "../../utils";

const selector = (state) => ({
  editMateriel: state.editMateriel,
  selectedMateriel: state.selectedMateriel,
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

function MaterielDetail({
  materials,
  setMaterials,
  categories,
  unities,
  materialDetailsStatus,
  setMaterialDetailsStatus,
  manufacturers,
  distributors,
  entityWorkId,
  from,
  opportunityId,
  handleFAAndOpp,
  faIsValidate,
  faId,
  entity_work_name,
  configuration,
  vocabulary,
  defaultMajoration,
  use_special_tva,
  round_type_list,
}) {
  const queryClient = useQueryClient();
  const {
    selectedMateriel,
    formToken,
    isUsingMargin,
    calculMethod,
    editMateriel,
  } = useStore(selector);

  const { data: tva_professions } = useQuery("TvaProfessionsList", () =>
    getData(formToken, `/tva_professions_list/${getUrlParams()}`)
  );

  const { mutate: updateMaterials, status } = useMutation(
    (todo) => postData(formToken, "/material_profession/update", todo),
    {
      onSuccess: (payload) => {
        setMaterials(
          materials.map((el) => (el.id !== payload.id ? el : payload))
        );
        queryClient.invalidateQueries("PrestationsAssociates");
        queryClient.invalidateQueries("MaterialsAssociates");
        queryClient.invalidateQueries("TvaSelector");
      },
    }
  );

  const { mutate: createMaterials, isLoading } = useMutation(
    (todo) => postData(formToken, "/material_profession/create", todo),
    {
      onSuccess: (payload) => {
        setMaterials([payload, ...materials]);
        queryClient.invalidateQueries("PrestationsAssociates");
        queryClient.invalidateQueries("MaterialsAssociates");
        editMateriel(payload);
        setMaterialDetailsStatus("update");
      },
      onError: () => setMaterialDetailsStatus("empty"),
    }
  );

  const onUpdateFAAndOpp = () => ({
    // Optimistic update of material_professions
    onMutate: async () => {
      const previousMaterials = materials;

      return { previousMaterials, prevMaterial: selectedMateriel };
    },
    onSuccess: (payload, _, context) => {
      const materialsUpdated = materials.map((el) => {
        if (el.id === context.prevMaterial.id) {
          return {
            ...context.prevMaterial,
            material_included: payload,
          };
        }
        return el;
      });
      setMaterials(materialsUpdated);
      queryClient.invalidateQueries("PrestationsAssociates");
      queryClient.invalidateQueries("MaterialsAssociates");
    },
    onError: (_, __, context) => {
      setMaterials(context.previousMaterials);
    },
  });

  const { mutate: updateMaterialOpportunity, status: materialOppStatus } =
    useMutation(
      (todo) => postData(formToken, "/material_opportunity/update", todo),
      onUpdateFAAndOpp("material_opportunity")
    );

  const { mutate: updateMaterialFA, status: materialFAStatus } = useMutation(
    (todo) => postData(formToken, "/material_framework_agreement/update", todo),
    onUpdateFAAndOpp("material_framework_agreement")
  );

  const handleRegisterMaterialProfession = (e) => {
    const formData = createMaterialProfessionForm(
      e,
      isUsingMargin,
      calculMethod
    );
    if (use_special_tva) {
      if (e?.tva_profession_id) {
        formData.append("tva_profession_id", e.tva_profession_id);
      }
      if (e?.tva_profession_id === undefined) {
        formData.append("tva_profession_id", "none");
      }
      formData.append("entity_work_id", entityWorkId);
    }
    if (from === "referencing")
      formData.append("material_profession[opportunity_id]", opportunityId);
    else formData.append("material_profession[entity_work_id]", entityWorkId);
    if (materialDetailsStatus === "update") {
      formData.append("id", selectedMateriel.id);
      updateMaterials(formData);
    }
    if (materialDetailsStatus === "create") {
      if (from === "referencing")
        formData.append("create_for_referencing", true);
      if (from === "frameworkAgreement")
        formData.append("framework_agreement_id", faId);
      createMaterials(formData);
    }
  };

  const handleRegisterMaterialFAAndOpp = (e) => {
    if (!selectedMateriel.material_included && e.is_in_framework_agreement) {
      handleFAAndOpp(false, selectedMateriel);
      return;
    }
    if (selectedMateriel.material_included && !e.is_in_framework_agreement) {
      handleFAAndOpp(true, selectedMateriel);
      return;
    }
    const formData = new FormData();
    const modelName =
      from === "referencing"
        ? "material_opportunity"
        : "material_framework_agreement";

    if (e?.tva_profession_id) {
      formData.append("tva_profession_id", e.tva_profession_id);
    }
    if (
      e.material_included_name !== null &&
      e.material_included_name !== undefined
    )
      formData.append(`${modelName}[name]`, e.material_included_name);
    if (
      e.material_included_reference_code !== null &&
      e.material_included_reference_code !== undefined
    )
      formData.append(
        `${modelName}[reference_code]`,
        e.material_included_reference_code
      );
    if (e.description !== null && e.description !== undefined)
      formData.append(`${modelName}[description]`, e.description);
    if (e.drive_link) {
      handleDriveLinkKey({
        formData,
        drive_link: e.drive_link,
        keyName: modelName,
      });
    }
    if (e.material_position !== null && e.material_position !== undefined)
      formData.append(`${modelName}[material_position]`, e.material_position);
    formData.append("id", selectedMateriel.material_included.id);
    if (from === "referencing") {
      formData.append(`${modelName}[opportunity_id]`, opportunityId);
      updateMaterialOpportunity(formData);
    } else {
      if (e.selling_price_cents !== null && e.selling_price_cents !== undefined)
        formData.append(
          `${modelName}[selling_price_cents]`,
          eurosToCents(e.selling_price_cents)
        );
      formData.append(`${modelName}[framework_agreement_id]`, faId);
      updateMaterialFA(formData);
    }
  };

  const onSubmit = (e) => {
    if (from !== "admin" && materialDetailsStatus === "update") {
      handleRegisterMaterialFAAndOpp(e);
    } else {
      handleRegisterMaterialProfession(e);
    }
  };

  const getStatus = () => {
    if (
      // materialAssoStatus === "loading" ||
      status === "loading" ||
      // prestaAssoStatus === "loading" ||
      materialOppStatus === "loading" ||
      materialFAStatus === "loading"
    )
      return "loading";
    if (
      // materialAssoStatus === "success" ||
      status === "success" ||
      // prestaAssoStatus === "success" ||
      materialOppStatus === "success" ||
      materialFAStatus === "success"
    )
      return "success";
    return "idle";
  };

  const getMaterialName = () => {
    if (materialDetailsStatus !== "update") return "";
    const actualMaterial = materials.find(
      (el) => el.id === selectedMateriel.id
    );
    if (!actualMaterial?.material_included?.name && !actualMaterial?.name)
      return "";
    return ` : ${
      actualMaterial.material_included?.name || actualMaterial.name
    }`;
  };

  const tabs = useMemo(() => {
    const baseTabs = [
      "material_associates",
      "prestation_associates",
      "ouvrage_associates",
    ];
    if (from === "admin") baseTabs.push("family_associates");
    return baseTabs;
  }, [from]);

  const setItem = useCallback(() => {
    editMateriel(materials?.find((el) => el.id === selectedMateriel?.id));
  }, [materials, selectedMateriel?.id, editMateriel]);

  return (
    <Container>
      <TitleContainer
        label={`Détails du ${vocabulary.product_lowercase}${getMaterialName()}`}
      >
        {materialDetailsStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={() => setMaterialDetailsStatus("empty")}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="material-form"
              loading={isLoading}
            />
          </>
        )}
        <Loading status={getStatus()} />
      </TitleContainer>
      {materialDetailsStatus === "empty" && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez un {vocabulary.product_lowercase} pour
            afficher les détails
          </p>
        </div>
      )}
      {materialDetailsStatus !== "empty" && (
        <DetailTabs
          configuration={configuration}
          detailsStatus={materialDetailsStatus}
          categories={categories}
          unities={unities}
          manufacturers={manufacturers}
          distributors={distributors}
          from={from}
          opportunityId={opportunityId}
          entityWorkId={entityWorkId}
          faId={faId}
          faIsValidate={faIsValidate}
          parent="material"
          vocabulary={vocabulary}
          tabs={tabs}
          setItem={setItem}
          setMaterialsParent={setMaterials}
          materialsParent={materials}
          defaultMajoration={defaultMajoration}
          round_type_list={round_type_list}
        >
          <MaterialsForm
            use_special_tva={use_special_tva}
            tva_professions={tva_professions}
            updateOnChange={materialDetailsStatus === "update"}
            initialMaterial={
              materialDetailsStatus === "update" ? selectedMateriel : undefined
            }
            categories={categories}
            unities={unities}
            onSubmit={onSubmit}
            manufacturers={manufacturers}
            distributors={distributors}
            from={from}
            faIsValidate={faIsValidate}
            hasMaterialIncluded={
              selectedMateriel.material_included !== null &&
              selectedMateriel.material_included !== undefined
            }
            handleMaterialOpportunity={handleFAAndOpp}
            isShowing={
              from === "frameworkAgreement" &&
              !faIsValidate &&
              materialDetailsStatus === "update"
            }
            entity_work_name={entity_work_name}
            configuration={configuration}
            vocabulary={vocabulary}
            entityWorkId={entityWorkId}
            opportunityId={opportunityId}
          />
        </DetailTabs>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;
const StyledButton = styled(Button)`
  margin-right: 10px;
`;

MaterielDetail.propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      entity_work_id: PropTypes.number,
      category_profession_id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      margin_rate: PropTypes.number,
      cost_price_cents: PropTypes.number,
      date_validity: PropTypes.string,
      material_position: PropTypes.number,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
    })
  ),
  categories: PropTypes.array,
  unities: PropTypes.array,
  materialDetailsStatus: PropTypes.oneOf(["empty", "create", "update"])
    .isRequired,
  setMaterialDetailsStatus: PropTypes.func.isRequired,
  setMaterials: PropTypes.func.isRequired,
  manufacturers: PropTypes.array,
  entityWorkId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  from: PropTypes.string.isRequired,
  opportunityId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleFAAndOpp: func,
  distributors: PropTypes.array,
  faIsValidate: PropTypes.bool,
  faId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  entity_work_name: PropTypes.string,
  configuration: PropTypes.object,
  vocabulary: PropTypes.object,
  defaultMajoration: PropTypes.number,
};

MaterielDetail.defaultProps = {
  materials: [],
  categories: [],
  unities: [],
  manufacturers: [],
  opportunityId: null,
  handleFAAndOpp: null,
  distributors: [],
  faIsValidate: false,
  faId: null,
  configuration: {},
  vocabulary: {},
  defaultMajoration: null,
};

export default MaterielDetail;
