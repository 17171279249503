export const zonesData = [
    {
        "id": 0,
        "opportunity_id": null,
        "entitled": "Zone par défaut",
        "created_at": "2024-05-24T16:47:45.525Z",
        "updated_at": "2024-05-24T16:47:45.525Z",
        "total_cost_price_cents": 0,
        "total_margin_rate": 0,
        "total_selling_price_cents": 0,
        "material_margin_rate": 0,
        "material_cost_price_cents": 0,
        "material_selling_price_cents": 0,
        "internal_margin_rate": 0,
        "internal_cost_price_cents": 0,
        "internal_selling_price_cents": 0,
        "prestation_internal_margin_rate": 0,
        "prestation_internal_cost_price_cents": 0,
        "prestation_internal_selling_price_cents": 0,
        "movement_internal_margin_rate": 0,
        "movement_internal_cost_price_cents": 0,
        "movement_internal_selling_price_cents": 0,
        "carrycot_internal_margin_rate": 0,
        "carrycot_internal_cost_price_cents": 0,
        "carrycot_internal_selling_price_cents": 0,
        "subcontracting_margin_rate": 0,
        "subcontracting_cost_price_cents": 0,
        "subcontracting_selling_price_cents": 0,
        "prestation_subcontracting_margin_rate": 0,
        "prestation_subcontracting_cost_price_cents": 0,
        "prestation_subcontracting_selling_price_cents": 0,
        "movement_subcontracting_margin_rate": 0,
        "movement_subcontracting_cost_price_cents": 0,
        "movement_subcontracting_selling_price_cents": 0,
        "carrycot_subcontracting_margin_rate": 0,
        "carrycot_subcontracting_cost_price_cents": 0,
        "carrycot_subcontracting_selling_price_cents": 0,
        "difficulty_profession_id": null,
        "majoration_profession_id": null,
        "carrycot_tpl_opportunity_id": null,
        "presta_pack_int_margin_rate": 0,
        "presta_pack_int_cost_price_cents": 0,
        "presta_pack_int_selling_price_cents": 0,
        "presta_pack_sub_margin_rate": 0,
        "presta_pack_sub_cost_price_cents": 0,
        "presta_pack_sub_selling_price_cents": 0,
        "difficulty_opportunity_id": 801,
        "majoration_opportunity_id": 881,
        "ouvrage_selling_price_cents": 0,
        "ouvrage_cost_price_cents": 0,
        "ouvrage_margin_rate": 0,
        "position": 100,
        "zonable_type": "Opportunity",
        "zonable_id": 549,
        "carrycot_opportunity_id": null,
        "carrycot_profession_id": null,
        "is_default": false,
        "maintenance": null,
        "difficulty_opportunity": {
            "name": "first"
        },
        "majoration_opportunity": {
            "name": "first"
        },
        "material_opportunities": [],
        "ouvrage_opportunities": [],
        "prestation_opportunities": []
    }
]

export const fakeGlobalData = [
    {
        "desable": true,
        "key": "1",
        "blank": "Total",
        "hide": false,
        "cost_price": "100 €",
        "margin": 50,
        "selling_price": "100"
    },
    {
        "desable": true,
        "key": "2",
        "blank": "Matériels",
        "hide": false,
        "cost_price": "100 €",
        "margin": 50,
        "selling_price": "100"
    },
    {
        "desable": true,
        "key": "36",
        "blank": "Ouvrages",
        "hide": false,
        "margin": 50,
        "selling_price": "100",
        "cost_price": "100 €"
    },
    {
        "desable": true,
        "key": "3",
        "blank": "Coûts internes",
        "hide": false,
        "cost_price": "1000 €",
        "margin": 50,
        "selling_price": "100"
    },
    {
        "desable": true,
        "key": "4",
        "blank": "----- Prestations",
        "className": "row_children_colored",
        "hide": false,
        "cost_price": "100 €",
        "subcontracting": false,
        "is_package": false,
        "margin": 50,
        "selling_price": "100"
    },
    {
        "desable": true,
        "key": "Prestations forfaits int",
        "blank": "----- Prestations forfaits",
        "className": "row_children_colored",
        "hide": false,
        "cost_price": "0,00 €",
        "subcontracting": false,
        "is_package": true,
        "margin": 0,
        "selling_price": "0,00"
    },
    {
        "desable": true,
        "key": "5",
        "blank": "----- Déplacements",
        "className": "row_children_colored",
        "hide": false,
        "cost_price": "0,00 €",
        "margin": 0,
        "selling_price": "0,00",
        "subcontracting": false
    },
    {
        "desable": true,
        "key": "6",
        "blank": "----- Nacelles",
        "className": "row_children_colored",
        "hide": false,
        "cost_price": "0,00 €",
        "margin": 0,
        "selling_price": "0,00",
        "subcontracting": false
    },
    {
        "desable": true,
        "key": "7",
        "blank": "Coûts sous-traitance",
        "hide": false,
        "cost_price": "0,00 €",
        "margin": 0,
        "selling_price": "0,00"
    },
    {
        "desable": true,
        "key": "8",
        "blank": " ----- Prestations",
        "className": "row_children_colored",
        "hide": false,
        "cost_price": "0,00 €",
        "subcontracting": true,
        "is_package": false,
        "margin": 0,
        "selling_price": "0,00"
    },
    {
        "desable": true,
        "key": "Prestations forfaits sub",
        "blank": " ----- Prestations forfaits",
        "className": "row_children_colored",
        "hide": false,
        "cost_price": "0,00 €",
        "subcontracting": true,
        "is_package": true,
        "margin": 0,
        "selling_price": "0,00"
    },
    {
        "desable": true,
        "key": "9",
        "blank": "----- Déplacements",
        "className": "row_children_colored",
        "hide": false,
        "cost_price": "0,00 €",
        "margin": 0,
        "selling_price": "0,00",
        "subcontracting": true
    },
    {
        "desable": true,
        "key": "10",
        "blank": "----- Nacelles",
        "className": "row_children_colored",
        "hide": false,
        "cost_price": "0,00 €",
        "margin": 0,
        "selling_price": "0,00",
        "subcontracting": true
    }
]

export const fakeResumeData = [
    {
        "key": "1",
        "id": 533,
        "selling_price_before": "100",
        "delivery_cents": "1,00",
        "delivery_percentage": "1",
        "selling_price_after": "100",
        "margin_after_discount": 50,
        "selling_price_ttc": "100"
    }
]