/* eslint-disable no-param-reassign */
import { getData } from "../../../../../../request/instance";
import { fetchOuvragesUrl } from "../../../../../../../utils/fetchOuvragesUrl";

export const BatiprixFetching = ({
  formToken,
  ouvragesNextPage,
  nomenclaturePosition,
  selectedNomenclature,
  wordEnteredOuvrage,
  id_opportunity,
}) => {
  return () =>
    getData(
      formToken,
      fetchOuvragesUrl(
        ouvragesNextPage.current,
        nomenclaturePosition.current,
        selectedNomenclature,
        null,
        wordEnteredOuvrage,
        id_opportunity
      )
    );
};

export const BatiprixFetchingSuccess = ({
  ouvrageProvider,
  wordEnteredOuvrage,
  setOuvrageBatiprix,
  ouvrageBatiprix,
  ouvragesNextPage,
  nomenclaturePosition,
  selectedNomenclature,
  from,
}) => {
  return {
    refetchOnWindowFocus: false,
    retry: false,
    enabled:
      ouvrageProvider === "batiprix" &&
      !!selectedNomenclature &&
      ouvragesNextPage.current === null &&
      from !== "admin",
    onSuccess: (payload) => {
      const lastPage = payload.pages[payload.pages.length - 1];
      if (wordEnteredOuvrage.length > 0) {
        if (lastPage.ouvrages === null) {
          setOuvrageBatiprix([]);
        } else {
          setOuvrageBatiprix(lastPage.ouvrages);
        }
      } else {
        if (lastPage && lastPage.ouvrages) {
          setOuvrageBatiprix([...ouvrageBatiprix, ...lastPage.ouvrages]);
        }
        ouvragesNextPage.current = lastPage?.next_page;
        nomenclaturePosition.current = lastPage?.nomenclature_position;
      }
    },
  };
};
