import { encodeUrlParams } from "./encodeUrlParams";

export const fetchClientsUrl = ({
  search,
  sortBy,
  nextPage,
  fromOpportunity,
}) => {
  let url = `/clients?`;
  const params = {
    next_page: nextPage,
    ...(sortBy && { sort_by: sortBy.field, order: sortBy.order }),
    ...(search && { search }),
    ...(fromOpportunity && { from_opportunity: true }),
  };

  url += encodeUrlParams(params);

  return url;
};
