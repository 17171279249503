import React, { useState } from "react";
import { TreeSelect as AntdTreeSelect, Form } from "antd";
import { array, bool, func, shape, string } from "prop-types";
import Label from "./Label";
import ClientsSelect from "../Admin-cf/Clients/Form/ClientsSelect";

function TreeSelect({
  treeSelect,
  options,
  handleUpdateOnChange,
  isShowing,
  ...formItemProps
}) {
  const [clientsInMemory, setClientsInMemory] = useState([]);
  const [clients, setClients] = useState([]);
  const handleOnChange = (value, label, extra) => {
    if (treeSelect.onChange) treeSelect.onChange(value, label, extra);
    handleUpdateOnChange();
  };

  const disabled = treeSelect.disabled || isShowing;

  return (
    <Form.Item
      {...formItemProps}
      label={<Label label={formItemProps.label} rules={formItemProps.rules} />}
    >
      {treeSelect.isClient ? (
        <ClientsSelect
          setClientsInMemory={setClientsInMemory}
          clientsInMemory={clientsInMemory}
          clients={clients}
          setClients={setClients}
          isShowing={disabled}
          onChange={handleOnChange}
          {...treeSelect}
        />
      ) : (
        <AntdTreeSelect
          {...treeSelect}
          onChange={handleOnChange}
          disabled={disabled}
          placeholder={isShowing ? "" : treeSelect.placeholder}
          options={options}
        />
      )}
    </Form.Item>
  );
}

TreeSelect.propTypes = {
  treeSelect: shape({
    placeholder: string,
    onChange: func,
    disabled: bool,
    treeData: array.isRequired,
  }).isRequired,
  name: string.isRequired,
  options: array,
  label: string,
  rules: array,
  handleUpdateOnChange: func.isRequired,
  isShowing: bool,
};

TreeSelect.defaultProps = {
  label: "",
  rules: [],
  isShowing: false,
  options: [],
};

export default TreeSelect;
