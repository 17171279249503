import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-query";
import styled, { css } from "styled-components";
import { Modal } from "antd";
import ReactJoyride, { STATUS } from "react-joyride";
import { getData, postData } from "../../request/instance";
import { useStore } from "../../store";
import TitleContainer from "../../react-ui/TitleContainer";
import { Loading } from "../../loading";
import AgentPanel from "./AgentPanel";
import SplitAgentModal from "./SplitAgentModal";
import Collapse from "../../react-ui/Collapse";
import Panel from "../../react-ui/Panel";
import { formatNumberString } from "../../../utils/formatNumberString";
import ApplyValueForm from "./ApplyValueForm";
import EditButton from "../../react-ui/EditButton";
import AddButton from "../../react-ui/AddButton";
import CreateAssoForm from "./CreateAssoForm";
import Warning from "../../react-ui/Icons/Warning";
import SaveButton from "../../react-ui/SaveButton";
import { locale_tour, style_tour } from "../../tour/tour_style";
import { agent_tour } from "../../tour/agents_tour";

const selector = (state) => ({
  formToken: state.formToken,
});

function Agents({
  opportunityId,
  qualifications,
  contractTypes,
  frequencies,
  configApplication,
  coeffToMonthly,
  isLockedByUser,
}) {
  const { formToken } = useStore(selector);
  const [agentToSplit, setAgentToSplit] = useState();
  const [isOpenApplyValue, setIsOpenApplyValue] = useState(false);
  const [isOpenCreateAsso, setIsOpenCreateAsso] = useState(false);
  const [agentsIdsToKeep, setAgentsIdsToKeep] = useState([]);
  const [fakeLoading, setFakeLoading] = useState(false);
  const [run, setRun] = useState(false);

  const { data: workPositions } = useQuery(
    "WorkPositions",
    () =>
      getData(
        formToken,
        `work_positions_from_agents?opportunity_id=${opportunityId}`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: shouldRun, refetch } = useQuery(
    "AgentTour",
    () => getData(formToken, `/verify_tour?tour_name="agent"`),
    {
      refetchOnWindowFocus: false, // Disable refetch on window focus to avoid unwanted refetches
    }
  );

  const hasDecreased = workPositions?.some((el) => el.has_decreased);

  const { data: disitinctWorkBonuses } = useQuery(
    ["WorkBonuses", { distinct: true }],
    () =>
      getData(
        formToken,
        `/work_bonuses?opportunity_id=${opportunityId}&is_distinct=true`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: disitinctIndemnities } = useQuery(
    ["Indemnities", { distinct: true }],
    () =>
      getData(
        formToken,
        `/indemnities?opportunity_id=${opportunityId}&is_distinct=true`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: updateAgentMutation, status } = useMutation((todo) =>
    postData(formToken, `/agent/update`, todo)
  );

  const {
    mutate: splitAgentMutation,
    status: splitStatus,
    isLoading,
  } = useMutation((todo) => postData(formToken, `/agent/split_agent`, todo));

  const { mutate: deleteSplitAgentMutation, status: deleteSplitStatus } =
    useMutation((todo) =>
      postData(formToken, `/agent/delete_splitted_agent`, todo)
    );

  const { mutate: updateWbMutation, status: updateWbStatus } = useMutation(
    (todo) => postData(formToken, `/work_bonus/update`, todo)
  );

  const { mutate: deleteWbMutation, status: deleteWbStatus } = useMutation(
    (todo) => postData(formToken, `/work_bonus/delete`, todo)
  );

  const { mutate: updateIndMutation, status: updateIndStatus } = useMutation(
    (todo) => postData(formToken, `/indemnity/update`, todo)
  );

  const { mutate: deleteIndMutation, status: deleteIndStatus } = useMutation(
    (todo) => postData(formToken, `/indemnity/delete`, todo)
  );

  const { mutate: reinstantiateAgents, isLoading: reinstantiateIsLoading } =
    useMutation((todo) =>
      postData(formToken, `/work_position/reinstantiate_agents`, todo)
    );

  const getStatus = () => {
    const statusArray = [
      status,
      updateWbStatus,
      deleteWbStatus,
      updateIndStatus,
      deleteIndStatus,
      splitStatus,
      deleteSplitStatus,
    ];
    if (statusArray.some((st) => st === "loading")) return "loading";
    if (statusArray.some((st) => st === "success")) return "success";
    return "idle";
  };

  const posteHeader = ({
    id,
    title,
    profil: { designation },
    planning: { name },
    period_work_positions,
    quantity,
    etp,
    has_decreased,
    future_etp,
  }) => {
    const { hours_quantity } = period_work_positions[0];
    const hoursQuantity = `${formatNumberString({ str: hours_quantity })}h`;
    const quantityStr = `Qté: ${quantity}`;
    let informations = [
      { info: title, key: "title" },
      { info: designation, key: "designation" },
      { info: name, key: "name" },
      { info: hoursQuantity, key: "hoursQuantity" },
      { info: etp, key: "etp" },
      { info: quantityStr, key: "quantity" },
    ];

    if (has_decreased) {
      const futureEtp = formatNumberString({
        str: future_etp,
      });
      const keepedEtp = formatNumberString({
        str:
          agentsIdsToKeep.filter((el) => el.wpId === id).length +
          (future_etp % 1),
      });
      const futureEtpStr = `ETP requis: ${futureEtp}`;
      const keepedEtpStr = `ETP atteints: ${keepedEtp}`;
      const isReached = futureEtp === keepedEtp;
      informations = informations.concat([
        { info: futureEtpStr, key: "futureEtp", isReached },
        { info: keepedEtpStr, key: "keepedEtp", isReached },
      ]);
    }

    return (
      <InfoContainer>
        {has_decreased && <StyledWarning title="ETP diminués pour ce poste" />}
        {informations.map(({ info, key, isReached }, idx) => (
          <React.Fragment key={key}>
            <InfoText $isReached={isReached}>{info}</InfoText>
            {idx + 1 !== informations.length && (
              <NameSeparator>||</NameSeparator>
            )}
          </React.Fragment>
        ))}
      </InfoContainer>
    );
  };

  const closeModalApplyValue = () => {
    setIsOpenApplyValue(false);
  };

  const openModalApplyValue = () => {
    setIsOpenApplyValue(true);
  };

  const closeModalCreateAsso = () => {
    setIsOpenCreateAsso(false);
  };

  const openModalCreateAsso = () => {
    setIsOpenCreateAsso(true);
  };

  const sendKeepedAgents = () => {
    reinstantiateAgents(
      {
        opportunity_id: opportunityId,
        agents_ids: agentsIdsToKeep.map((el) => el.agentId),
      },
      {
        onSuccess: () => {
          window.location.reload();
        },
      }
    );
  };

  const fakeSave = () => {
    setFakeLoading(true);
    setTimeout(() => setFakeLoading(false), 2000);
  };

  const handleJoyrideCallback = (data) => {
    const { status: joyrideStatus } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(joyrideStatus)) {
      setRun(false);
      refetch();
    }
  };

  const handleRunJoyride = useCallback(() => {
    setRun(true);
  }, []);

  return (
    <Container>
      {!hasDecreased && (
        <ReactJoyride
          callback={handleJoyrideCallback}
          continuous
          run={run}
          showSkipButton
          disableScrolling
          steps={agent_tour}
          locale={locale_tour}
          styles={style_tour}
          disableOverlayClose
        />
      )}
      <StyledModal
        open={isOpenApplyValue}
        footer={null}
        maskClosable={false}
        width={1000}
        destroyOnClose
        onCancel={closeModalApplyValue}
        closable={false}
      >
        <ApplyValueForm
          opportunityId={opportunityId}
          closeModal={closeModalApplyValue}
          frequencies={frequencies}
          workPositions={workPositions}
          disitinctWorkBonuses={disitinctWorkBonuses}
          disitinctIndemnities={disitinctIndemnities}
          configApplication={configApplication}
          coeffToMonthly={coeffToMonthly}
          isLockedByUser={isLockedByUser}
        />
      </StyledModal>

      <StyledModal
        open={isOpenCreateAsso}
        maskClosable={false}
        footer={null}
        width={1000}
        destroyOnClose
        onCancel={closeModalCreateAsso}
        closable={false}
      >
        <CreateAssoForm
          closeModal={closeModalCreateAsso}
          frequencies={frequencies}
          workPositions={workPositions}
          opportunityId={opportunityId}
          configApplication={configApplication}
          isLockedByUser={isLockedByUser}
        />
      </StyledModal>
      <TitleContainer label="Liste des postes" onRight={false}>
        {workPositions &&
          (hasDecreased ? (
            <StyledSaveButton
              onClick={sendKeepedAgents}
              label="Enregistrer"
              loading={reinstantiateIsLoading}
              disabled={
                !configApplication.guarding.agents.button_save ||
                !isLockedByUser
              }
            />
          ) : (
            <>
              <StyledSaveButton
                onClick={fakeSave}
                label="Enregistrer"
                loading={fakeLoading}
                disabled={
                  !configApplication.guarding.agents.button_save ||
                  !isLockedByUser
                }
              />
              <StyledEditButton
                className="periodicity-select-modal"
                onClick={openModalApplyValue}
                label="Appliquer des valeurs"
                disabled={
                  fakeLoading ||
                  !configApplication.guarding.agents.button_apply_values ||
                  !isLockedByUser
                }
              />
              <StyledAddButton
                onClick={openModalCreateAsso}
                className="indemnity-work-bonuses-modal"
                label="Primes / indemnités"
                disabled={
                  fakeLoading ||
                  !configApplication.guarding.agents
                    .button_create_bonus_indemnities_all ||
                  !isLockedByUser
                }
              />
            </>
          ))}
        <StyledLoading status={getStatus()} />
      </TitleContainer>
      <SplitAgentModal
        agentToSplit={agentToSplit}
        setAgentToSplit={setAgentToSplit}
        splitAgentMutation={splitAgentMutation}
        isLoading={isLoading}
        opportunityId={opportunityId}
      />
      {hasDecreased && (
        <DecreasedContainer>
          <StyledWarning />
          <DecreasedText>
            Les ETP de certains postes ont diminués. Veuillez sélectionner les
            agents que vous souhaitez garder dans ces postes puis cliquer sur le
            bouton Enregistrer.
          </DecreasedText>
        </DecreasedContainer>
      )}
      <Wrapper $nbPanel={workPositions?.length} $hasDecreased={hasDecreased}>
        {workPositions &&
          workPositions.map((wp, idx) => (
            <StyledCollapse
              key={wp.id}
              defaultActiveKey={
                shouldRun?.success && idx === 0 ? wp.id : undefined
              }
            >
              <StyledPanel header={posteHeader(wp)} key={wp.id}>
                <AgentPanel
                  wpId={wp.id}
                  posteName={wp.title}
                  hasDecreased={hasDecreased}
                  wpHasDecreased={wp.has_decreased}
                  opportunityId={opportunityId}
                  qualifications={qualifications}
                  contractTypes={contractTypes}
                  updateAgentMutation={updateAgentMutation}
                  frequencies={frequencies}
                  updateWbMutation={updateWbMutation}
                  deleteWbMutation={deleteWbMutation}
                  updateIndMutation={updateIndMutation}
                  deleteIndMutation={deleteIndMutation}
                  setAgentToSplit={setAgentToSplit}
                  deleteSplitAgentMutation={deleteSplitAgentMutation}
                  agentsIdsToKeep={agentsIdsToKeep}
                  setAgentsIdsToKeep={setAgentsIdsToKeep}
                  futureEtp={wp.future_etp}
                  configApplication={configApplication}
                  coeffToMonthly={coeffToMonthly}
                  handleRunJoyride={handleRunJoyride}
                  isWpForJoyride={shouldRun?.success && idx === 0}
                  isLockedByUser={isLockedByUser}
                />
              </StyledPanel>
            </StyledCollapse>
          ))}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  height: 76vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow: scroll;
`;

const NameSeparator = styled.span`
  color: #f63c25;
  margin: 0 8px;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  flex-wrap: wrap;
  row-gap: 5px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 300px;
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

const StyledEditButton = styled(EditButton)`
  margin-left: 15px;
`;

const StyledAddButton = styled(AddButton)`
  margin-left: 15px;
`;

const StyledLoading = styled(Loading)`
  position: absolute;
  right: 5px;
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    border-bottom: none !important;
    background-color: #2166ab;
  }
`;

const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    position: sticky !important;
    top: 0;
    z-index: 10;
    background-color: inherit;
    border-bottom: 1px solid #d9d9d9;
    color: white !important;
  }
  .ant-collapse-content {
    border-top: none !important;
  }
`;

const DecreasedContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
`;

const DecreasedText = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
`;

const StyledWarning = styled(Warning)`
  font-size: 16px;
  margin-right: 8px;
`;

const StyledSaveButton = styled(SaveButton)`
  margin-left: 15px;
`;

const InfoText = styled.span`
  ${({ $isReached, theme }) =>
    $isReached !== undefined &&
    $isReached !== null &&
    css`
      color: ${$isReached ? theme.colors.basicgreen : theme.colors.basicorange};
    `}
`;

export default Agents;
