import React from "react";
import FormCol from "../../../Form/FormCol";
import Input from "../../../react-ui/Input";
import { opportunityField } from "../../../constant";

function OpportunityName({ required, disabled }) {
  return (
    <FormCol
      labelOnTop
      label="Intitulé"
      rules={[{ required }]}
      name={opportunityField.name}
    >
      <Input placeholder="Intitulé" disabled={disabled} />
    </FormCol>
  );
}

export default OpportunityName;
