import React from "react";
import { Tooltip, Checkbox } from "antd";
import ActionsContainer from "../../../../../../react-ui/ActionsContainer";
import Info from "../../../../../../react-ui/Icons/Info";
import Eye from "../../../../../../react-ui/Icons/Eye";
import FileDocument from "../../../../../../react-ui/Icons/FileDocument";

export const PrestationFormat = ({
  setPrestaData,
  prestaToHandle,
  colGap,
  setItemToShowId,
  setModalType,
}) => {
  setPrestaData(
    prestaToHandle?.map((value) => {
      return {
        ...value,
        key: `presta-${value.id}`,
        id: value.id,
        name: value.name,
        reference_code: value.reference_code,
        searchName: value.name,
        category: value.category_profession.title,
        flag: "prestations",
        subcontracting: (
          <div>
            <Checkbox defaultChecked={value.subcontracting} disabled />
          </div>
        ),
        enabled: false,
        action: (
          <ActionsContainer $colGap={colGap}>
            {value.remark && (
              <Tooltip placement="bottom" title={value.remark}>
                <Info $fontSize="11px" />
              </Tooltip>
            )}
            <FileDocument
              onClick={() =>
                value.drive_link
                  ? window.open(value.drive_link, "_blank")
                  : undefined
              }
              disabled={!value.drive_link}
              $fontSize="11px"
            />
            <Eye
              onClick={() => {
                setModalType("prestation");
                setItemToShowId(value.id);
              }}
              $fontSize="11px"
            />
          </ActionsContainer>
        ),
      };
    })
  );
};
