import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["template", "add_item"]


  add_association(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforeend', content)
    this.addEventListener();
  }

  removeAssociation(event) {
    event.preventDefault()
    event.target.closest("tr").remove();
  }

  addEventListener(){
    
    const allSelectDropCocontractorItems = document.getElementsByClassName('offer_review_offer_review_group_members_contractor_id');

    [...allSelectDropCocontractorItems].forEach(selectDropCocontractorItems => {  
    if (selectDropCocontractorItems) {
      selectDropCocontractorItems.removeEventListener('mouseover', this.readDropDown);
      // GET REQUEST
      const readDropDown = () => {
        fetch('/contractors', {
          method: "GET",
          headers: { "Content-Type": "application/json",
                    "Accept": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
                  }
        })
          .then(response => response.json())
          .then((data) => {
            eval(data)
            // order by id data JSON
            const dynamicSort = (property) => {
                let sortOrder = 1;
                if(property[0] === "-") {
                    sortOrder = -1;
                    property = property.substr(1);
                }
                return function (a,b) {
                    const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                    return result * sortOrder;
                }
            }

            data.sort(dynamicSort("id"));
            // select the last element to insert only it
            const element = data[data.length -1];
            if (element) {
              const selectDropdown = $(".dropdown-contractors");
              // delete all items of the native select element
              selectDropdown.innerHTML= "";
              if ($(`.dropdown-contractors option[value='${element.id}']`).length === 0) {
                selectDropdown.append("<option value=\"" +  element.id + "\">" +  element.trade_name + "</option>");
              }
            }
          })
      }
      selectDropCocontractorItems.addEventListener('mouseover', readDropDown);
    }
});
  }
  
}
