export const itemsToCascader = ({
  items,
  keyParent,
  keyValue = "id",
  keyName = "name",
  id = null,
}) =>
  items
    ?.filter((el) => el[keyParent] === id)
    .map((el) => ({
      ...el,
      value: el[keyValue],
      label: el[keyName],
      children: itemsToCascader({
        items,
        keyParent,
        keyValue,
        keyName,
        id: el.id,
      }),
    }));

export const singleItemToCascader = ({ childItem, items, keyParent }) => {
  if (!childItem) return [];
  if (!childItem[keyParent]) {
    return [childItem.id];
  }
  const parentItem = items?.find((el) => el.id === childItem[keyParent]);

  const cascaderArray = singleItemToCascader({
    childItem: parentItem,
    items,
    keyParent,
  });

  return cascaderArray.concat([childItem.id]);
};
