import { encodeUrlParams } from "./encodeUrlParams";

export const fetchProfilsUrl = ({ from, id, search }) => {
  let url =
    from === "admin" ? "/profil_profession?" : "/profil_by_opportunity?";
  const params = {
    ...(search && { search }),
    ...(from === "admin" && { id, from }),
    ...(from === "referencing" && {
      id_opportunity: id,
      include_entities_child: true,
    }),
    from,
    ...(from === "frameworkAgreement" && {
      framework_agreement_id: id,
      include_entities_child: true,
    }),
  };

  url += encodeUrlParams(params);

  return url;
};
