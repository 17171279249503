$(document).ready(function() {
  const money_inputs = document.querySelectorAll('.humanized_money');

  const changeDotToCommas = (event) => {
    input_value = event.currentTarget.value;
    event.currentTarget.value= event.currentTarget.value.replace(/\./g, ',')
    let nbCommas = (event.currentTarget.value.match(/,/g) || []).length
    if (nbCommas > 1){
      let commaPosition = event.currentTarget.value.indexOf(",")
      let beforeComma = event.currentTarget.value.substring(0,commaPosition+1)
      let afterComma = event.currentTarget.value.substring(commaPosition +1, event.currentTarget.value.length)
      afterComma = afterComma.replace(/\,/g, '')
      let newString=beforeComma + afterComma
      event.currentTarget.value=newString
    }
    event.currentTarget.value= event.currentTarget.value.replace(/[^0-9 ,]/g, '')
  }
  const modifyMoneyInputDotsOnChange = () => {
    
      money_inputs.forEach((money_input) => {
        //money_input.addEventListener('change', changeDotToCommas)
        money_input.addEventListener('input', changeDotToCommas)
      })
    }
    
    if(money_inputs) {
      modifyMoneyInputDotsOnChange();
    }
})