/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import { TreeSelect } from "antd";
import styled, { css } from "styled-components";
import FormCol from "../../../Form/FormCol";
import { opportunityField } from "../../../constant";
import { filterInTree } from "../../../../utils/search";

function ConcernedAgency({ entitiesInWork, required, disabled }) {
  return (
    <FormCol
      labelOnTop
      label="Agence concernée"
      rules={[{ required }]}
      name={opportunityField.entityId}
    >
      <StyledTreeSelect
        treeDefaultExpandAll
        treeData={entitiesInWork}
        treeDataSimpleMode
        placeholder="Sélectionnez une agence"
        filterTreeNode={filterInTree}
        showSearch
        disabled={disabled}
      />
    </FormCol>
  );
}

const StyledTreeSelect = styled(TreeSelect)`
  ${({ disabled }) =>
    disabled &&
    css`
      .ant-select-selection-item {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}
`;

export default ConcernedAgency;
