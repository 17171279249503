//TABULATOR
import Tabulator from "tabulator-tables";
$(document).ready(() => {
  const profils = document.getElementById("profils");

  if (!profils) {
    return;
  }
  const profilsvalue = document.getElementById("profils-value");

  if (profils) {
    const createTabulatorProfil = () => {
      const profilsJson = profilsvalue.innerText;
      // const profilsJson = profils.getAttribute('data-contacts');
      profilsvalue.addEventListener("change", () => {});
      //custom formatter definition
      const deleteIcon = () =>
        "<i class='fa-duotone text-danger fa-trash-xmark'></i>";
      const editIcon = () => "<i class='fas fa-user-edit text-success'></i>";

      // Fetch to display profils info in the right partial
      const ajaxDetailsProfil = (id) => {
        fetch("/fetch_profil_infos/" + id, {
          headers: {
            Accept: "text/javascript",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
          .then(function (response) {
            return response.text();
          })
          .then(
            function (body) {
              eval(body);
            }.bind(this)
          );
      };

      // Fetch to delete one element and display the Table without the deleted element
      const ajaxTableDelete = (id) => {
        const options = {
          method: "DELETE",
          headers: {
            Accept: "text/javascript",
            "X-Requested-With": "XMLHttpRequest",
            "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
          },
        };
        fetch("profils/" + id, options)
          .then(function (response) {
            return response.text();
          })
          .then(
            function (body) {
              eval(body);
              // get content of data-contacts="..."
              const regex = /(\[.*?\])/;
              const found = body.match(regex);
              const result = convertDataToHTML(found[0]);
              // parse the content to get an json object
              const newProfilsJson = JSON.parse(result);
              const finalData = body.replace(found[0], result);
              body = finalData;
              buildTabulator(newProfilsJson);
              ajaxDetailsProfil(newProfilsJson[0].id);
            }.bind(this)
          );
      };

      // convert the JSON into correct HTML string
      const convertDataToHTML = (text) => {
        const span = document.createElement("span");

        return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
          span.innerHTML = entity;
          return span.innerText;
        });
      };

      //
      const buildTabulator = (json) => {
        const profilsTable = new Tabulator("#profils", {
          maxHeight: 500,
          tooltips: true,
          columnHeaderVertAlign: "middle",
          selectable: 1,
          resizableColumns: false,
          layout: "fitColumns",
          initialSort: [{ column: "coefficient", dir: "asc" }],
          columns: [
            {
              title: "Intitulé",
              field: "designation",
              sorter: "string",
              widthGrow: 3,
              minWidth: 150,
              hozAlign: "left",
              cellClick: function (e, cell) {
                ajaxDetailsProfil(cell._cell.row.data.id);
              },
            },
            {
              title: "Contrat",
              field: "contract_type",
              sorter: "string",
              hozAlign: "left",
              minWidth: 100,
              cellClick: function (e, cell) {
                ajaxDetailsProfil(cell._cell.row.data.id);
              },
            },
            {
              title: "Qualification",
              field: "qualification",
              sorter: "string",
              widthGrow: 1,
              minWidth: 128,
              hozAlign: "center",
              cellClick: function (e, cell) {
                ajaxDetailsProfil(cell._cell.row.data.id);
              },
            },
            {
              title: "Coefficient",
              field: "coefficient",
              sorter: "string",
              minWidth: 115,
              hozAlign: "center",
              cellClick: function (e, cell) {
                ajaxDetailsProfil(cell._cell.row.data.id);
              },
            },
            {
              title: "Prix de vente horaire",
              field: "hourly_price",
              sorter: "string",
              minWidth: 109,
              hozAlign: "center",
              formatter: "money",
              formatterParams: {
                decimal: ",",
                thousand: ",",
                symbol: " €",
                symbolAfter: "p",
                precision: false,
              },
              cellClick: function (e, cell) {
                ajaxDetailsProfil(cell._cell.row.data.id);
              },
            },
            {
              title: "Taux horaire CCN",
              field: "collective_agreement_hourly_rate",
              sorter: "string",
              minWidth: 130,
              hozAlign: "center",
              formatter: "money",
              formatterParams: {
                decimal: ",",
                thousand: ",",
                symbol: " €",
                symbolAfter: "p",
              },
              cellClick: function (e, cell) {
                ajaxDetailsProfil(cell._cell.row.data.id);
              },
            },
            {
              formatter: deleteIcon,
              hozAlign: "center",
              headerSort: false,
              cellClick: function (event, cell) {
                if (
                  window.confirm(
                    "Êtes vous sûr de vouloir supprimer ce profil ?"
                  )
                ) {
                  ajaxTableDelete(cell._cell.row.data.id);
                }
              },
            },
          ],
        });

        if (profilsTable) {
          profilsTable.setData(json);

          // change textValue of qualification
          const celluleQualification =
            document.querySelectorAll(".tabulator-cell");
          celluleQualification.forEach((cell) => {
            if (cell.innerText === "agent_de_maitrise") {
              cell.innerText = "Agent de maîtrise";
            } else if (cell.innerText === "agent_d_exploitation") {
              cell.innerText = "Agent d'exploitation";
            } else if (cell.innerText === "cadre") {
              cell.innerText = "Cadre";
            }
          });
          window.addEventListener("resize", function () {
            //trigger full rerender including all data and rows
            profilsTable.redraw(true);
          });
        }
        // get the info if the user want calcul de revenu_cost and display or not TxH / TxHCCN
        const revenuCostDecision = document.getElementById("wiiz_revenue_cost");
        const displayTauxHoraire = () => {
          const columnComponents = profilsTable.getColumns();
          if (revenuCostDecision.dataset.revenu === "true") {
            columnComponents[4].hide();
          } else {
            columnComponents[5].hide();
          }
        };
        if (revenuCostDecision) {
          displayTauxHoraire();
          profilsTable.redraw(true);
        }
      }; // end buildTabulator

      buildTabulator(profilsJson);
      document
        .querySelector(".profil_deleted")
        .addEventListener("change", () => {
          buildTabulator(document.getElementById("profils-value").innerText);
        });
    };
    createTabulatorProfil();
  }

  // disabled by default the CCN
  const ccn = document.getElementById(
    "profil_collective_agreement_hourly_rate"
  );
  if (ccn) {
    ccn.setAttribute("readonly", true);
  }

  // if wiiz_revenue_cost == false,
  // shoult not be able to continue onboarding if user not change the "prix_de_vente_horaire" for each profil
  const cellSellingPrice = document.querySelectorAll(
    "#profils .tabulator-row [tabulator-field='hourly_price']"
  );
  const btnOnboarding = document.querySelector(".onboarding");
  const message = document.getElementById("message-onboarding");
  const profilsIndex = document.querySelector(".index-profil-partial-tag");
  const revenuCostDecision = document.getElementById("wiiz_revenue_cost");

  if (revenuCostDecision) {
    if (revenuCostDecision.dataset.revenu === "false") {
      if (btnOnboarding) {
        btnOnboarding.classList.add("btn-disabled");
      }
      const checkAllCells = (listOfCells) => {
        let disabled = false;
        listOfCells.forEach((input) => {
          if (input.innerText === "99,99 €") {
            disabled = true;
          }
        });
        if (btnOnboarding !== null) {
          if (disabled) {
            btnOnboarding.classList.add("btn-disabled");
          } else {
            btnOnboarding.classList.remove("btn-disabled");
            message.style.display = "none";
          }
        }
      };

      if (profilsIndex) {
        profilsIndex.addEventListener("click", checkAllCells(cellSellingPrice));
      }
    }
  }

  // The first row is select when we open the page
  const profilsTableBody = document.querySelector(
    "#index-profils .tabulator-tableHolder .tabulator-table"
  );

  if (profilsTableBody) {
    const addSelected = () => {
      profilsTableBody.firstChild.classList.add("selected-row");
    };

    const removeSelected = () => {
      profilsTableBody.firstChild.classList.remove("selected-row");
    };
    if (profilsTableBody.firstChild) {
      addSelected();
    }
    profilsTableBody.addEventListener("click", removeSelected);
  }
});
