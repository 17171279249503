import React from "react";
import { bool, string } from "prop-types";
import styled from "styled-components";
import Button from "./Button";
import Plus from "./Icons/Plus";

function AddButton({ label, className, loading, ...buttonProps }) {
  return (
    <StyledButton className={className} loading={loading} {...buttonProps}>
      {!loading && <StyledIcon />}
      {label}
    </StyledButton>
  );
}

const StyledIcon = styled(Plus)`
  margin-right: 0.313rem;
`;

const StyledButton = styled(Button)`
  padding: 0.3rem 0.5rem;
`;

AddButton.propTypes = {
  label: string,
  className: string,
  loading: bool,
};

AddButton.defaultProps = {
  label: "",
  className: "",
  loading: false,
};

export default AddButton;
