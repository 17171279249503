import { Card } from "antd";
import React from "react";
import styled, { css } from "styled-components";
import BasketTitle from "./BasketTitle";
import BasketItems from "./BasketItems";

function BasketCard({
  id,
  name,
  odd,
  setBasketModalOpen,
  setSelectedBasket,
  configuration,
  basketItems,
}) {
  return (
    <StyledCard
      key={id}
      title={
        <BasketTitle
          name={name}
          id={id}
          setBasketModalOpen={setBasketModalOpen}
          setSelectedBasket={setSelectedBasket}
        />
      }
      $odd={odd}
    >
      <BasketItems
        configuration={configuration}
        basketItems={basketItems}
        selectedBasketId={id}
      />
    </StyledCard>
  );
}

const StyledCard = styled(Card)`
  .ant-card-head {
    padding: 0 !important;
  }
  .ant-card-head-title {
    padding: 8px !important;
    font-size: 14px !important;
  }
  .ant-card-body {
    padding: 0 !important;
  }
  ${({ $odd }) =>
    $odd &&
    css`
      background-color: #d3e0ee;
    `}
`;

export default BasketCard;
