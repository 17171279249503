import React, { useEffect, useState } from "react";
import { useStore } from "../../store";
import { getUrlParams } from "../../utils";
import useDeleteTodo from "../../hookQuery/useDeleteTodo";
import { ShowDeleteConfirm } from "../confirmModal";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import ActionsContainer from "../../react-ui/ActionsContainer";
import Delete from "../../react-ui/Icons/Delete";
import { stringSorter } from "../../../utils/stringSorter";

const selector = (state) => ({
  selectedManufacturer: state.selectedManufacturer,
  editManufacturer: state.editManufacturer,
  formToken: state.formToken,
});

function Actions({ value, handleDeleteManufacturer, isDeletable }) {
  return isDeletable ? (
    <ActionsContainer>
      <Delete
        onClick={() =>
          ShowDeleteConfirm(value.name, value.id, handleDeleteManufacturer)
        }
      />
    </ActionsContainer>
  ) : null;
}

function ManufacturerArray({
  setManufacturerDetailsStatus,
  manufacturers,
  manufacturerDetailsStatus,
}) {
  const { editManufacturer, formToken, selectedManufacturer } =
    useStore(selector);
  const pageEntityWork = parseInt(getUrlParams(), 10);

  const { mutate: removeManufacturers } = useDeleteTodo(
    formToken,
    "/manufacturer/delete",
    "Manufacturers"
  );

  const [tableRowsData, setTableRowData] = useState([]);

  useEffect(() => {
    const isDeletable = (entityWorkId) => entityWorkId === pageEntityWork;

    const handleDeleteManufacturer = (idManufacturer) => {
      setManufacturerDetailsStatus("empty");
      editManufacturer();
      removeManufacturers(
        JSON.stringify({
          id: idManufacturer,
          entity_work_id: pageEntityWork,
        })
      );
    };
    const dataResult = [];
    let child;

    manufacturers?.forEach((value) => {
      child = {
        key: value.id,
        id: value.id,
        name: value.name,
        actions: (
          <Actions
            handleDeleteManufacturer={handleDeleteManufacturer}
            value={value}
            isDeletable={isDeletable(value.entity_work_id)}
          />
        ),
      };
      dataResult.push(child);
    });

    setTableRowData(dataResult);
  }, [
    manufacturers,
    pageEntityWork,
    removeManufacturers,
    setManufacturerDetailsStatus,
    editManufacturer,
  ]);

  useEffect(() => {}, [tableRowsData]);

  const openUpdateManufacturerProfession = (value) => {
    editManufacturer(value);
    setManufacturerDetailsStatus("update");
  };

  const ManufacturerColumns = [
    {
      title: "Intitulé",
      dataIndex: "name",
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: "",
      dataIndex: "actions",
      width: "20px",
    },
  ];

  return (
    <TableContainer
      bordered={false}
      scrollY
      columns={ManufacturerColumns}
      dataSource={tableRowsData}
      pagination={false}
      isRowSelectable
      rowSelectableId={
        manufacturerDetailsStatus === "update"
          ? selectedManufacturer?.id
          : undefined
      }
      onRow={(record) => {
        return {
          onClick: () => {
            openUpdateManufacturerProfession(
              manufacturers.find((el) => el.id === record.id)
            );
          },
        };
      }}
    />
  );
}

export default ManufacturerArray;
