import React from "react";
import { bool, string } from "prop-types";
import styled from "styled-components";
import Button from "./Button";
import Duplicate from "./Icons/Duplicate";

function DuplicateButton({ label, className, loading, ...buttonProps }) {
  return (
    <StyledButton className={className} loading={loading} {...buttonProps}>
      {!loading && <StyledIconDuplicate />}
      {label}
    </StyledButton>
  );
}

const StyledIconDuplicate = styled(Duplicate)`
  margin-right: 0.313rem;
`;

const StyledButton = styled(Button)`
  padding: 0.3rem 0.5rem;
`;

DuplicateButton.propTypes = {
  label: string,
  className: string,
  loading: bool,
};

DuplicateButton.defaultProps = {
  label: "",
  className: "",
  loading: false,
};

export default DuplicateButton;
