import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["previous", "next", "save", "new"];

  disabled_breadcrumb() {
    let breadcrumbs = document.getElementsByClassName("breadcrumb");
    let breadcrumbs_separators = document.getElementsByClassName(
      "breadcrumb-separator"
    );
    let breadcrumbs_selected = document.getElementsByClassName(
      "breadcrumb-selected"
    );
    let breadcrumbs_clicked =
      document.getElementsByClassName("breadcrumb-clicked");
    let breadcrumbs_separators_clicked = document.getElementsByClassName(
      "breadcrumb-separator-clicked"
    );
    let breadcrumbs_selected_clicked = document.getElementsByClassName(
      "breadcrumb-selected-clicked"
    );
    breadcrumbs.forEach((breadcrumb) => (breadcrumb.hidden = true));
    breadcrumbs_separators.forEach(
      (breadcrumb_separator) => (breadcrumb_separator.hidden = true)
    );
    breadcrumbs_selected.forEach(
      (breadcrumb_selected) => (breadcrumb_selected.hidden = true)
    );
    breadcrumbs_clicked.forEach(
      (breadcrumb_clicked) => (breadcrumb_clicked.hidden = false)
    );
    breadcrumbs_separators_clicked.forEach(
      (breadcrumb_separator_clicked) =>
        (breadcrumb_separator_clicked.hidden = false)
    );
    breadcrumbs_selected_clicked.forEach(
      (breadcrumb_selected_clicked) =>
        (breadcrumb_selected_clicked.hidden = false)
    );
  }

  disabled_buttons_next() {
    let button_edit = document.getElementById("button_edit_formation");
    let button_next = this.nextTarget;
    let button_save = this.saveTarget;
    let button_new = document.getElementById("button_formation_new");
    // this.disabled_breadcrumb()
    button_next.disabled = true;
    button_save.hidden = true;
    button_edit.hidden = true;
    button_new.hidden = true;
  }

  disabled_buttons_previous() {
    let button_edit = document.getElementById("button_edit_formation");
    let button_previous = this.previousTarget;
    let button_save = this.saveTarget;
    let button_new = document.getElementById("button_formation_new");
    // this.disabled_breadcrumb()
    button_previous.disabled = true;
    button_edit.hidden = true;
    button_save.hidden = true;
    button_new.hidden = true;
  }

  disabled_buttons_save() {
    let button_edit = document.getElementById("button_edit_formation");
    let button_previous = this.previousTarget;
    let button_next = this.nextTarget;
    let button_new = document.getElementById("button_formation_new");
    // this.disabled_breadcrumb()
    button_previous.disabled = true;
    button_edit.hidden = true;
    button_next.disabled = true;
    button_new.hidden = true;
  }

  disabled_buttons_save_two() {
    let button_save = this.saveTarget;
    button_save.hidden = true;
    let button_edit = document.getElementById("button_edit_formation");
    let button_previous = this.previousTarget;
    let button_next = this.nextTarget;
    let button_new = document.getElementById("button_formation_new");
    // this.disabled_breadcrumb()
    button_previous.disabled = true;
    button_edit.hidden = true;
    button_next.disabled = true;
    button_new.hidden = true;
  }

  disabled_buttons() {
    let button_save = this.saveTarget;
    button_save.hidden = true;
    let button_edit = document.getElementById("button_edit_formation");
    let button_previous = this.previousTarget;
    let button_next = this.nextTarget;
    let button_new = document.getElementById("button_formation_new");
    // this.disabled_breadcrumb()
    button_previous.disabled = true;
    button_edit.hidden = true;
    button_next.disabled = true;
    button_new.hidden = true;
  }

  changeDotToCommas = (event) => {
    event.currentTarget.value = event.currentTarget.value.replace(/\./g, ",");
    let nbCommas = (event.currentTarget.value.match(/,/g) || []).length;
    if (nbCommas > 1) {
      let commaPosition = event.currentTarget.value.indexOf(",");
      let beforeComma = event.currentTarget.value.substring(
        0,
        commaPosition + 1
      );
      let afterComma = event.currentTarget.value.substring(
        commaPosition + 1,
        event.currentTarget.value.length
      );
      afterComma = afterComma.replace(/\,/g, "");
      let newString = beforeComma + afterComma;
      event.currentTarget.value = newString;
    }
    event.currentTarget.value = event.currentTarget.value.replace(
      /[^0-9 ,]/g,
      ""
    );
  };
}
