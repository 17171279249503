import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useStore } from "../../../../store";
import useUpdateTodo from "../../../../hookQuery/useUpdateTodo";
import { currency_cents_to_euros } from "../../../../utils";
import TableContainer from "../../../CostingMovementsCarrycots/TableContainer";
import Input from "../../../../react-ui/Input";
import { formatNumberString } from "../../../../../utils/formatNumberString";
import { stringToFloat } from "../../../../../utils/stringToFloat";
import { eurosToCents } from "../../../../../utils/currencyConverter";
import { getMarginCol } from "../../../../../constants/marginCol";
import { coeffToMargin } from "../../../../../utils/marginConverter";

const selector = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

const TablePrestation = React.memo(
  ({
    zone_id,
    subcontracting,
    responseType,
    getItems,
    prestationsOpp,
    type,
    configuration,
  }) => {
    const { id_opportunity } = useParams();

    const { formToken, calculMethod, isUsingMargin } = useStore(selector);

    const {
      mutate: calculPrestationOpportunity,
      isLoading: prestationLoading,
    } = useUpdateTodo(
      formToken,
      "/prestation_opportunity/update_costing",
      "PrestationsOpportunity",
      ["OpportunityCotationLc", "Zones", "BasketItems"]
    );

    const [margin, setMargin] = useState(null);
    const [hourly_selling_HT, setHourly_selling_HT] = useState(null);
    const [total_selling_HT, setTotal_selling_HT] = useState(null);

    const pushDataInTable = useMemo(() => {
      const prestationDataSource = [];
      getItems({
        arr: prestationsOpp,
        keyIds: [
          "prestation_profession_id",
          "name",
          "hourly_cost_price",
          "majoration_opportunity_id",
          "margin_rate",
          "tva_profession_id",
        ],
        quantityKey: "hour_quantity",
        totalCostKey: "total_cost_cents",
      })
        ?.filter((el) => el.hour_quantity > 0)
        ?.forEach((prestation) => {
          const prestaSubcontract = prestation.subcontracting
            ? prestation.subcontracting
            : false;
          if (
            (prestation.zone_id === zone_id ||
              responseType === "referencing") &&
            prestaSubcontract === subcontracting &&
            !prestation.is_package
          ) {
            prestationDataSource.push({
              ...prestation,
              name: prestation.name,
              id: prestation.id,
              categories: prestation.category_profession?.title,
              profil: prestation.profil_opportunity.name,
              hour: formatNumberString({
                str: prestation.hour_quantity,
                space: true,
              }),
              total_cost_price: formatNumberString({
                str: currency_cents_to_euros(prestation.total_cost_cents),
                nbDecimal: 2,
                space: true,
              }),
              margin: prestation.margin_rate,
              hourly_cost_price: formatNumberString({
                str: currency_cents_to_euros(prestation.hourly_rate_cents),
                nbDecimal: 2,
                space: true,
              }),
              total_selling_HT: formatNumberString({
                str: currency_cents_to_euros(
                  prestation.total_selling_price_cents
                ),
                nbDecimal: 2,
                space: true,
              }),
              hourly_selling_HT: formatNumberString({
                str: currency_cents_to_euros(prestation.selling_price_cents),
                nbDecimal: 2,
                space: true,
              }),
            });
          }
        });

      return prestationDataSource;
    }, [
      prestationsOpp,
      zone_id,
      subcontracting,
      prestationLoading,
      responseType,
      getItems,
    ]);
    const PrestationColumns = [
      {
        title: "intitulé",
        dataIndex: "name",
      },
      {
        title: "Profil",
        dataIndex: "profil",
        width: "10%",
      },
      {
        title: "Categories",
        dataIndex: "categories",
        width: "13%",
      },
      {
        title: "Heures",
        dataIndex: "hour",
        width: "7%",
        render: (value) => <div>{value} h</div>,
      },
      {
        title: "Coût de revient",
        hidden:
          responseType !== "consultation" ||
          !configuration.costing.page_3.show_cost_price,
        children: [
          {
            title: "Horaire",
            dataIndex: "hourly_cost_price",
            width: "10%",
            align: "right",
            render: (value) => <div>{value} €</div>,
          },
          {
            title: "Total",
            dataIndex: "total_cost_price",
            width: "10%",
            align: "right",
            render: (value) => <div>{value} €</div>,
          },
        ],
      },
      {
        title: "Coût de revient",
        hidden:
          responseType !== "referencing" ||
          !configuration.costing.page_3.show_cost_price,
        dataIndex: "hourly_cost_price",
        width: "10%",
        align: "right",
        render: (value) => <div>{value} €</div>,
      },
      getMarginCol({
        configuration,
        isUsingMargin,
        calculMethod,
        hasSorter: false,
        hidden: responseType === "quotation",
        render: (value, prestation) => (
          <Input
            fontSize="10px"
            size="small"
            textAlign="right"
            isMarginRate
            id={prestation.id}
            value={formatNumberString({
              str:
                margin?.[prestation.id] != null
                  ? margin?.[prestation.id]
                  : value,
              isCoeff: !isUsingMargin,
            })}
            onBlur={(e) => {
              if (margin?.[prestation.id]) {
                calculPrestationOpportunity({
                  ids: prestation.ids,
                  opportunity_id: id_opportunity,
                  margin_rate: coeffToMargin({
                    calculMethod,
                    isUsingMargin,
                    coeff: stringToFloat(e.target.value),
                  }),
                });
              }
            }}
            onChange={(e) =>
              setMargin((v) => ({
                ...v,
                [prestation.id]: e.target.value,
              }))
            }
          />
        ),
      }),
      {
        title: "Prix de vente HT",
        hidden: responseType === "referencing",
        children: [
          {
            title: "Horaire",
            dataIndex: "hourly_selling_HT",
            width: "10%",
            align: "right",
            render: (value, prestation) =>
              responseType === "quotation" ? (
                <div>{value} €</div>
              ) : (
                <Input
                  fontSize="10px"
                  textAlign="right"
                  suffix="€"
                  size="small"
                  disabled={configuration.costing.page_3.disable_selling_price}
                  value={hourly_selling_HT?.[prestation.id] || value}
                  id={prestation.id}
                  onBlur={(e) => {
                    if (hourly_selling_HT?.[prestation.id]) {
                      calculPrestationOpportunity({
                        ids: prestation.ids,
                        opportunity_id: id_opportunity,
                        selling_price_cents: eurosToCents(e.target.value),
                      });
                    }
                    setHourly_selling_HT(null);
                  }}
                  onChange={(e) =>
                    setHourly_selling_HT((v) => ({
                      ...v,
                      [prestation.id]: formatNumberString({
                        str: e.target.value,
                        nbDecimal: 2,
                      }),
                    }))
                  }
                  onFocus={(e) =>
                    setHourly_selling_HT((v) => ({
                      ...v,
                      [e.target.id]: formatNumberString({
                        str: e.target.value,
                        nbDecimal: 2,
                      }),
                    }))
                  }
                />
              ),
          },
          {
            title: "Total",
            dataIndex: "total_selling_HT",
            width: "10%",
            align: "right",
            render: (value, prestation) =>
              responseType === "quotation" ? (
                <div>{value} €</div>
              ) : (
                <Input
                  fontSize="10px"
                  textAlign="right"
                  size="small"
                  suffix="€"
                  disabled={configuration.costing.page_3.disable_selling_price}
                  id={prestation.id}
                  value={total_selling_HT?.[prestation.id] || value}
                  onBlur={(e) => {
                    if (total_selling_HT?.[prestation.id]) {
                      calculPrestationOpportunity({
                        ids: prestation.ids,
                        opportunity_id: id_opportunity,
                        total_selling_price_cents: eurosToCents(e.target.value),
                      });
                    }
                    setTotal_selling_HT(null);
                  }}
                  onChange={(e) =>
                    setTotal_selling_HT((v) => ({
                      ...v,
                      [prestation.id]: formatNumberString({
                        str: e.target.value,
                        nbDecimal: 2,
                      }),
                    }))
                  }
                  onFocus={(e) =>
                    setTotal_selling_HT((v) => ({
                      ...v,
                      [e.target.id]: formatNumberString({
                        str: e.target.value,
                        nbDecimal: 2,
                      }),
                    }))
                  }
                />
              ),
          },
        ],
      },
      {
        title: "Prix de vente HT",
        hidden: responseType !== "referencing",
        dataIndex: "hourly_selling_HT",
        width: "10%",
        align: "right",
        render: (value, prestation) => (
          <Input
            fontSize="10px"
            textAlign="right"
            suffix="€"
            size="small"
            value={hourly_selling_HT?.[prestation.id] || value}
            id={prestation.id}
            onBlur={(e) => {
              if (hourly_selling_HT?.[prestation.id]) {
                calculPrestationOpportunity({
                  ids: prestation.ids,
                  opportunity_id: id_opportunity,
                  selling_price_cents: eurosToCents(e.target.value),
                });
              }
              setTotal_selling_HT(null);
            }}
            onChange={(e) =>
              setHourly_selling_HT((v) => ({
                ...v,
                [prestation.id]: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              }))
            }
            onFocus={(e) =>
              setHourly_selling_HT((v) => ({
                ...v,
                [e.target.id]: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              }))
            }
          />
        ),
      },
    ];
    return pushDataInTable.length ? (
      <Container>
        <div>
          <h4 id={type} className="pl-2">
            {type}
          </h4>
        </div>
        <TableContainer columns={PrestationColumns} data={pushDataInTable} />
      </Container>
    ) : null;
  }
);

const Container = styled.div`
  margin-top: 20px;
`;

export default TablePrestation;
