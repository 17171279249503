import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["add_item", "template", "opportunityid"];

  add_association(event) {
    event.preventDefault();
    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.add_itemTarget.insertAdjacentHTML("beforeend", content);
  }

  add_association_wb(event) {
    event.preventDefault();
    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.add_itemTarget.insertAdjacentHTML("beforeend", content);
  }

  add_association_indemnity(event) {
    event.preventDefault();
    var content = this.templateTarget.innerHTML.replace(
      /TEMPLATE_RECORD/g,
      new Date().valueOf()
    );
    this.add_itemTarget.insertAdjacentHTML("beforeend", content);
  }

  removeIndemnityAssociation(event) {
    event.preventDefault();
    event.target.closest("tr").remove();
  }

  removeWorkBonusAssociation(event) {
    event.preventDefault();
    event.target.closest("tr").remove();
  }

  remove_association(event) {
    event.preventDefault();
    let item = event.target.closest("tr");
    item.querySelector("input[name*='_destroy']").value = 1;
    item.style.display = "none";
  }

  remove_wp(event) {
    event.preventDefault();
    let item = event.target.closest("div#wp");
    item.remove();
  }

  calculate_prestation_price(event) {
    if (event.currentTarget.closest(".prestation_line")) {
      let prestationRow = event.currentTarget.closest(".prestation_line");
      let prestation_total_cost = prestationRow.querySelector(
        ".prestation_total_cost"
      ).value;
      let prestation_margin_rate = prestationRow.querySelector(
        ".prestation_margin_rate"
      ).value;
      prestationRow.querySelector(".prestation_selling_price").value =
        (1 + Number.parseFloat(prestation_margin_rate) / 100) *
        Number.parseInt(prestation_total_cost);
      prestationRow.querySelector(".prestation_selling_price").value =
        prestationRow
          .querySelector(".prestation_selling_price")
          .value.replace(/\./g, ",");
    }
  }

  calculate_outfits_price(event) {
    if (event.currentTarget.closest(".clothing_line")) {
      let outfitRow = event.currentTarget.closest(".clothing_line");
      this.calculateRow(outfitRow);
    }
  }
  calculateRow(outfitRow) {
    let initialAmount = outfitRow.querySelector(
      ".clothing_initial_amount input"
    );
    let renewalAmonunt = outfitRow.querySelector(
      ".clothing_renewal_amount input"
    );
    let renewalFrequency = outfitRow.querySelector(
      ".clothing_renewal_frequency"
    );
    let unitPricing = outfitRow.querySelector(".clothing_unit_pricing input");
    let clothing_initial_price = outfitRow.querySelector(
      ".clothing_initial_price"
    );
    let clothing_renewal_price = outfitRow.querySelector(
      ".clothing_renewal_price"
    );
    clothing_initial_price.value =
      (parseFloat(initialAmount.value) *
        parseFloat(unitPricing.value.replace(/,/g, "."))) /
      3;
    clothing_renewal_price.value =
      (parseFloat(renewalAmonunt.value) *
        (36 / parseFloat(renewalFrequency.value) - 1) *
        parseFloat(unitPricing.value.replace(/,/g, "."))) /
      3;
    this.calculate_outfit_global(outfitRow);
  }

  connect() {
    document.querySelectorAll(".clothing_line").forEach((row) => {
      this.calculateRow(row);
    });
  }

  changeDotToCommas(
    currentModal,
    annualPrice,
    totalInitialsValue,
    totalRenewalsValue
  ) {
    let totalInitials = currentModal.querySelector(".initial_global_price");
    let totalRenewals = currentModal.querySelector(".renewal_global_price");

    totalInitials.value = Number.parseFloat(totalInitialsValue)
      .toFixed(2)
      .replace(/\./g, ",");
    totalRenewals.value = Number.parseFloat(totalRenewalsValue)
      .toFixed(2)
      .replace(/\./g, ",");
    annualPrice.value = Number.parseFloat(annualPrice.value)
      .toFixed(2)
      .replace(/\./g, ",");
  }
  calculate_outfit_global(outfitRow) {
    let currentModal = outfitRow.closest(".modal_outfit");
    let totalInitialsValue = this.global_initial_price(currentModal);
    let totalRenewalsValue = this.global_renewal_price(currentModal);
    let annualPrice = currentModal.querySelector(".annual_flat_price");
    annualPrice.value = (
      Number.parseFloat(totalInitialsValue) +
      Number.parseFloat(totalRenewalsValue)
    ).toFixed(2);
    this.changeDotToCommas(
      currentModal,
      annualPrice,
      totalInitialsValue,
      totalRenewalsValue
    );
  }
  global_initial_price(currentModal) {
    let totalInitials = currentModal.querySelector(".initial_global_price");
    let allClothingsInitial = currentModal.querySelectorAll(
      ".clothing_initial_price"
    );
    let tempTotal = 0;
    allClothingsInitial.forEach((clothingInitial) => {
      tempTotal += Number.parseFloat(clothingInitial.value);
    });
    if (Number.parseFloat(tempTotal) > 0) {
      totalInitials.value = Number.parseFloat(tempTotal).toFixed(2);
      return totalInitials.value;
    }
    return 0;
  }
  global_renewal_price(currentModal) {
    let totalRenewals = currentModal.querySelector(".renewal_global_price");
    let allClothingsRenewal = currentModal.querySelectorAll(
      ".clothing_renewal_price"
    );
    let tempTotal = 0;
    allClothingsRenewal.forEach((clothingRenewal) => {
      tempTotal += Number.parseFloat(clothingRenewal.value);
    });
    if (Number.parseFloat(tempTotal) > 0) {
      totalRenewals.value = Number.parseFloat(tempTotal).toFixed(2);
      return totalRenewals.value;
    }
    return 0;
  }

  validateNumbersOnly = (event) => {
    event.currentTarget.value = event.currentTarget.value.replace(/\./g, ",");
    let nbCommas = (event.currentTarget.value.match(/,/g) || []).length;
    if (nbCommas > 1) {
      let commaPosition = event.currentTarget.value.indexOf(",");
      let beforeComma = event.currentTarget.value.substring(
        0,
        commaPosition + 1
      );
      let afterComma = event.currentTarget.value.substring(
        commaPosition + 1,
        event.currentTarget.value.length
      );
      afterComma = afterComma.replace(/\,/g, "");
      let newString = beforeComma + afterComma;
      event.currentTarget.value = newString;
    }
    event.currentTarget.value = event.currentTarget.value.replace(
      /[^0-9 ,]/g,
      ""
    );
  };
}
