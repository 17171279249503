import React from "react";
import { instance, postData, getData } from "./instance";
import { useStore } from "../store";
import { useMutation, useQuery } from "react-query";

const getUrlParams = () => {
    const lastSegment = window.location.pathname.split("/").pop();
    if (!lastSegment) return "error"
    return lastSegment
};



export const fetchMajorationProfession = (formToken) => {
    const { data, isSuccess, refetch} = useQuery('getMajorationProfessions', () => getData(formToken, `/majoration_profession/${getUrlParams()}`))
    return data
}
export const fetchMajorationProfessionOpportunity = (formToken, id_opportunity) => {
    const { data, isSuccess, refetch} = useQuery('getMajorationProfessionsOpportunity', () => getData(formToken, `/majoration_by_opportunity/${id_opportunity}`))
    return data
}

export const updateMajoration = (formToken) => useMutation(updateTodo => postData(formToken, "/majoration_profession/update", updateTodo))

export const createMajoration = (formToken) => useMutation(createTodo=> postData(formToken, "/majoration_profession/create", createTodo))

export const deleteMajoration = (formToken) => useMutation(deleteTodo => postData(formToken, "/majoration_profession/delete", deleteTodo))