import React from "react";
import FormCol from "../../../Form/FormCol";
import { opportunityField } from "../../../constant";
import Input from "../../../react-ui/Input";

function OpportunityDescription({ disabled }) {
  return (
    <FormCol
      labelOnTop
      label="Description de l'opportunité"
      name={opportunityField.description}
    >
      <Input
        textArea
        placeholder="Description de l'opportunité"
        disabled={disabled}
      />
    </FormCol>
  );
}

export default OpportunityDescription;
