import i18next from "i18next";
import { select2 } from "select2";

const select = () => {
  $(document).ready(function() {
    $('.select2').select2();
  })
}

export { select };

//TABULATOR
import Tabulator from 'tabulator-tables';
const tableCompanyworks = document.getElementById('companyworks');


if (tableCompanyworks) {
  const companyworksJson = tableCompanyworks.getAttribute('data-info');
  const table = new Tabulator("#companyworks", {
    selectable: 1,
    resizableColumns:true,
    layout : "fitColumns",
    rowClick:function(e, row){
      // use the url generate by the route companyworks#infos
      fetch("/companies/1/fetch_companywork_infos/"+row._row.data.id, {
          headers: {
            "Accept": "text/javascript",
            "X-Requested-With": "XMLHttpRequest"
          }
        }).then(function(response) { return response.text() }).then(function(body) {
          eval(body)
      }.bind(this));
    },
    
    columns:[
      {title:"Métiers disponibles", field: `work.name`, formatter:function(cell, formatterParams, onRendered){return i18next.t(`work.${cell.getValue()}`);}, sorter:"string"},
    ],
  });


  if (table) {
    table.setData(companyworksJson);
    window.addEventListener('resize', function(){
      //trigger full rerender including all data and rows
      table.redraw(true);
    });
  }
}


//the first row is select when we open the page
const companyworksTableBody = document.querySelector('#companyworks .tabulator-table')

if (companyworksTableBody) {
  if (companyworksTableBody.firstChild !== null) {
    const addSelected = () => {
      companyworksTableBody.firstChild.classList.add("selected-row");
    }
    const removeSelected = () => {
      companyworksTableBody.firstChild.classList.remove("selected-row");
    }
    addSelected()
    companyworksTableBody.addEventListener("click", removeSelected)
  }
}

$(document).ready(function() {
  const changeTextMaxSelectForContractor = (e) => {
    const text = "Supprimez l'entreprise avant de le modifier";
      return text;
  }

  // allow the search box in the new/edit contractor activity field
  const clientContractor = $(".contractor_client_id");
  if (clientContractor) {
    $(".dropdown-client-id").change().select2({
        disableSearch: false,
        maximumSelectionLength: 1,
        language: {maximumSelected: changeTextMaxSelectForContractor}
    })
  }
});

