import { Modal } from "antd";
import React, { useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import AddButton from "../../react-ui/AddButton";
import TitleContainer from "../../react-ui/TitleContainer";
import { getData, postData } from "../../request/instance";
import { useStore } from "../../store";
import FAArray from "./FAArray";
import FADetails from "./FADetails";
import FAForm from "./FAForm";

const selector = (state) => ({
  formToken: state.formToken,
});

function FrameworkAgreements({
  entityWorkId,
  companyId,
  selectedFA,
  setSelectedFA,
  initialFA,
  setInitialFA,
  entityId,
  configuration,
  vocabulary,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { mutate: createFA } = useMutation(
    (todo) => postData(formToken, "/framework_agreement/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("FrameworkAgreements");
      },
    }
  );

  const { data: clients } = useQuery(
    "Clients",
    () => getData(formToken, `/companies/${companyId}/clients`),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: entities } = useQuery(
    "Entities",
    () =>
      getData(
        formToken,
        `/entities?${new URLSearchParams({
          to_json: true,
          entity_work_id: entityWorkId,
        })}`
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  const constructFAFormData = (e) => {
    const formData = new FormData();
    const formatableKeys = [
      "date_validity",
      "clients_ids",
      "entities_ids",
      "majoration_profession_id",
      "difficulty_profession_id",
    ];

    Object.keys(e).forEach(
      (key) =>
        !formatableKeys.includes(key) &&
        formData.append(
          `framework_agreement[${key}]`,
          e[key] !== undefined && e[key] !== null ? e[key] : ""
        )
    );

    formData.append("entity_work_id", entityWorkId);

    formData.append(
      "clients_ids",
      e.clients_ids !== undefined && e.clients_ids !== null
        ? e.clients_ids?.map((el) => el.value)
        : ""
    );
    formData.append(
      "entities_ids",
      e.entities_ids !== undefined && e.entities_ids !== null
        ? e.entities_ids?.map((el) => el.value)
        : ""
    );
    formData.append(
      "difficulty_profession_id",
      e.difficulty_profession_id !== undefined &&
        e.difficulty_profession_id !== null
        ? e.difficulty_profession_id
        : ""
    );
    formData.append(
      "majoration_profession_id",
      e.majoration_profession_id !== undefined &&
        e.majoration_profession_id !== null
        ? e.majoration_profession_id
        : ""
    );
    return formData;
  };

  const onSubmit = (e) => {
    const formData = constructFAFormData(e);
    createFA(formData);
    setIsModalVisible(false);
  };

  const formatedEntities = useMemo(
    () =>
      entities?.map((el) => ({
        id: el.id,
        disabled: el.is_disabled,
        pId: el.parent_id,
        title: el.name,
        value: el.id,
      })),
    [entities]
  );

  return selectedFA ? (
    <FADetails
      entityWorkId={entityWorkId}
      setSelectedFA={setSelectedFA}
      setInitialFA={setInitialFA}
      selectedFA={selectedFA}
      initialFA={initialFA}
      constructFAFormData={constructFAFormData}
      clients={clients?.map((el) => ({
        id: el.id,
        pId: el.parent_id,
        title: el.name,
        value: el.id,
      }))}
      entities={formatedEntities}
      referencingOppId={selectedFA.referencing_opportunity_id}
      configuration={configuration}
      vocabulary={vocabulary}
    />
  ) : (
    <Container>
      <Modal
        open={isModalVisible}
        footer={null}
        width={1000}
        onCancel={() => setIsModalVisible(false)}
        closable={false}
        maskClosable={false}
        destroyOnClose
      >
        <FAForm
          onSubmit={onSubmit}
          submit={{ onCancel: () => setIsModalVisible(false) }}
          clients={clients?.map((el) => ({
            id: el.id,
            pId: el.parent_id,
            title: el.name,
            value: el.id,
          }))}
          entities={formatedEntities}
          initialFA={{
            entities: formatedEntities?.filter((el) => el.id === entityId),
          }}
        />
      </Modal>
      <TitleContainer label="Accords-cadres">
        <AddButton
          onClick={() => setIsModalVisible(true)}
          label="Accord-cadre"
        />
      </TitleContainer>
      <FAArray
        entityWorkId={entityWorkId}
        setSelectedFA={setSelectedFA}
        setInitialFA={setInitialFA}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  overflow-x: hidden;
  height: 100%;
`;

export default FrameworkAgreements;
