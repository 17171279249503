import { encodeUrlParams } from "./encodeUrlParams";

export const fetchApplicationsUrl = ({ search }) => {
  let url = "/applications?";
  const params = {
    ...(search && { search }),
  };

  url += encodeUrlParams(params);

  return url;
};

export const fetchAuthorizedApplicationsUrl = ({ search }) => {
  let url = "/authorized_applications?";
  const params = {
    ...(search && { search }),
  };

  url += encodeUrlParams(params);

  return url;
};
