import React from "react";
import styled from "styled-components";
import Separator from "../../../../react-ui/Separator";
import SPArray from "./SPArray";
import SPDetails from "./SPDetails";

function SpecialPlannings({
  quotationStartDate,
  quotationEndDate,
  monthlyCalendar,
  workPositionId,
  specialPlannings = [],
  selectedSp,
  setSelectedSp,
  spStatus,
  setSpStatus,
  opportunityId,
  setWorkPositionsLocal,
  configApplication,
  isLockedByUser,
}) {
  return (
    <Container>
      <SPArray
        setSpStatus={setSpStatus}
        spStatus={spStatus}
        selectedSp={selectedSp}
        setSelectedSp={setSelectedSp}
        specialPlannings={specialPlannings}
        workPositionId={workPositionId}
        opportunityId={opportunityId}
        setWorkPositionsLocal={setWorkPositionsLocal}
        configApplication={configApplication}
        isLockedByUser={isLockedByUser}
      />
      <Separator />
      <SPDetails
        spStatus={spStatus}
        setSpStatus={setSpStatus}
        setSelectedSp={setSelectedSp}
        selectedSp={selectedSp}
        quotationStartDate={quotationStartDate}
        quotationEndDate={quotationEndDate}
        monthlyCalendar={monthlyCalendar}
        workPositionId={workPositionId}
        specialPlannings={specialPlannings}
        opportunityId={opportunityId}
        setWorkPositionsLocal={setWorkPositionsLocal}
        configApplication={configApplication}
        isLockedByUser={isLockedByUser}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 500px;
  max-width: 100%;
`;

export default SpecialPlannings;
