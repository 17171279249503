import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import styled, { css } from "styled-components";

function DraggableBodyRow({
  index,
  moveRow,
  className,
  style,
  itemId,
  dragKey,
  children,
  record,
  droppable = true,
  draggable = true,
  type = "DraggableBodyRow",
  ...restProps
}) {
  const ref = useRef(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? "downward" : "upward",
      };
    },
    drop: (item) => {
      moveRow(item.index, index, { record: item.record });
    },
  });
  const [, drag] = useDrag({
    type,
    item: {
      index,
      record,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (!itemId)
    return (
      <tr
        className={`${className}`}
        style={{
          ...style,
        }}
        {...restProps}
      >
        {children}
      </tr>
    );
  if (droppable && draggable) drop(drag(ref));
  else if (draggable) drag(ref);
  else if (droppable) drop(ref);
  return (
    <StyledRow
      id={`drag-row-${itemId}`}
      className={`${className}`}
      direction={isOver ? dropClassName : ""}
      ref={ref}
      style={{
        ...(!dragKey && { cursor: "move" }),
        ...style,
      }}
      {...restProps}
    >
      {dragKey
        ? React.Children.map(children, (child) => {
            if (child.key === dragKey) {
              return React.cloneElement(child, {
                ref,
              });
            }
            return child;
          })
        : children}
    </StyledRow>
  );
}

const StyledRow = styled.tr`
  td {
    ${({ direction, theme }) =>
      direction === "downward" &&
      css`
        border-bottom: 2px dashed ${theme.colors.blue07} !important;
      `}

    ${({ direction, theme }) =>
      direction === "upward" &&
      css`
        border-top: 2px dashed ${theme.colors.blue07} !important;
      `}
  }
`;

export default DraggableBodyRow;
