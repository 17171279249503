import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["add_item", "template", "opportunityid", "margin_rate", "calcul_method", "amount"]

  connect(event) {
    document.querySelectorAll('.prestation_line').forEach((row) => {
        this.calculate_prestation_price_row(row);
    })

	}
  add_association(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforeend', content)
  }

  remove_association(event) {
    event.preventDefault()
    let item = event.target.closest("tr")
    item.querySelector("input[name*='_destroy']").value = "true"
    item.style.display = 'none'
  }

  remove_wp(event) {
    event.preventDefault()
    let item = event.target.closest("div#wp")
    item.remove()
  }

  calculate_prestation_price_amount(event) {
    const errorLabel = document.querySelector(".error-label")
    if(!this.checkFieldNull(event)){
      errorLabel.textContent = 'Merci d\'entrer un coût de revient'
    }
    else{
    if(event.currentTarget.closest('.prestation_line')) {
      let prestationRow = event.currentTarget.closest('.prestation_line')
      this.calculate_prestation_price_row(prestationRow);
    }
  }
}

  calculate_prestation_price(event) {
    const errorLabel = document.querySelector(".error-label")
    if(!this.checkField(event)){
      errorLabel.textContent = 'Il est impossible d\'avoir un taux de marge de plus de 100%'
    }
    else if(!this.checkFieldNull(event)){
      errorLabel.textContent = 'Merci d\'entrer un taux de marge'
    }
    else{
    if(event.currentTarget.closest('.prestation_line')) {
      let prestationRow = event.currentTarget.closest('.prestation_line')
      this.calculate_prestation_price_row(prestationRow);
    }
	}
}
	calculate_prestation_price_row(prestationRow) {
    let prestation_total_cost = prestationRow.querySelector('.prestation_total_cost').value.replace(' ', '').replace(',','.')
    let prestation_margin_rate = prestationRow.querySelector('.prestation_margin_rate').value.replace(' ', '').replace(',','.')
    let coefficient_option = document.querySelector(".coefficient_imputation")
    let prestation_coefficient
    if (coefficient_option.value === "true") {
      prestation_coefficient = prestationRow.querySelector('.prestation_coefficient').value.replace(' ', '').replace(',','.')
    } else {
      prestation_coefficient = 1
    }
    if (this.calcul_methodTarget.value === "cost_price") {
      prestationRow.querySelector('.prestation_selling_price').value = (( 1 + (Number.parseFloat(prestation_margin_rate) / 100 )) * Number.parseFloat(prestation_total_cost * prestation_coefficient) || 0 )
      prestationRow.querySelector('.prestation_selling_price').value = (Number.parseFloat(prestationRow.querySelector('.prestation_selling_price').value).toFixed(2).replace(/\./g, ',') || 0 )
      prestationRow.querySelector('.prestation_selling_price').value = Number.parseFloat(prestationRow.querySelector('.prestation_selling_price').value.replace(',','.')).toLocaleString()
      if (coefficient_option.value === "true") {
      // prestationRow.querySelector('.prestation_total_cost_coefficient').value = Number.parseFloat(prestation_total_cost * prestation_coefficient).toFixed(2)
      // prestationRow.querySelector('.prestation_total_cost_coefficient').value = Number.parseFloat(prestationRow.querySelector('.prestation_total_cost_coefficient').value.replace(/\./g, ',') || 0 )
      // prestationRow.querySelector('.prestation_total_cost_coefficient').value = Number.parseFloat(prestationRow.querySelector('.prestation_total_cost_coefficient').value.replace(',','.')).toLocaleString()
      }
    } else {
      prestationRow.querySelector('.prestation_selling_price').value = ((Number.parseFloat(prestation_total_cost * prestation_coefficient) || 0) / ( 1 - (Number.parseFloat(prestation_margin_rate) / 100 )) )
      prestationRow.querySelector('.prestation_selling_price').value = (Number.parseFloat(prestationRow.querySelector('.prestation_selling_price').value).toFixed(2).replace(/\./g, ',') || 0 )
      prestationRow.querySelector('.prestation_selling_price').value = Number.parseFloat(prestationRow.querySelector('.prestation_selling_price').value.replace(',','.')).toLocaleString()
      if (coefficient_option.value === "true") {
      // prestationRow.querySelector('.prestation_total_cost_coefficient').value = Number.parseFloat(prestation_total_cost * prestation_coefficient).toFixed(2)
      // prestationRow.querySelector('.prestation_total_cost_coefficient').value = (Number.parseFloat(prestationRow.querySelector('.prestation_total_cost_coefficient').value).replace(/\./g, ',') || 0 )
      // prestationRow.querySelector('.prestation_total_cost_coefficient').value = Number.parseFloat(prestationRow.querySelector('.prestation_total_cost_coefficient').value.replace(',','.')).toLocaleString()
      }
    }
  }
  checkField(event){
    const input = event.currentTarget
    if(input.placeholder !== "Marge"){
      return
    }
    const calculMethod = this.calcul_methodTarget.value
    const submitButton = document.querySelector(".submit-btn")
    const errorLabel = document.querySelector(".error-label")
    if(calculMethod === "selling_price"){
      const value = Number.parseFloat(input.value.replace(" ", "").replace(",", ".")).toFixed(2)
      if(value >= 100){
        submitButton.disabled = true
        input.value = "0"
        return false
      }
      else{
      submitButton.disabled = false
      errorLabel.textContent = ""
      return true
      }
    } else {
      submitButton.disabled = false
      return true
    }
  }
  checkFieldNull(event){
    const input = event.currentTarget
    const submitButton = document.querySelector(".submit-btn")
    const errorLabel = document.querySelector(".error-label")
    const value = Number.parseFloat(input.value.replace(" ", "").replace(",", ".")).toFixed(2)
      if(value == "NaN"){
        submitButton.disabled = true
        input.value = "0"
        return false
      }
      else{
      submitButton.disabled = false
      errorLabel.textContent = ""
      return true
      }
  }
  changeDotToCommas = (event) => {
    event.currentTarget.value= event.currentTarget.value.replace(/\./g, ',')
    let nbCommas = (event.currentTarget.value.match(/,/g) || []).length
    if (nbCommas > 1){
      let commaPosition = event.currentTarget.value.indexOf(",")
      let beforeComma = event.currentTarget.value.substring(0,commaPosition+1)
      let afterComma = event.currentTarget.value.substring(commaPosition +1, event.currentTarget.value.length)
      afterComma = afterComma.replace(/\,/g, '')
      let newString=beforeComma + afterComma
      event.currentTarget.value=newString
    }
  event.currentTarget.value= event.currentTarget.value.replace(/[^0-9 ,]/g, '')
  }
}
