import React from "react";
import styled from "styled-components";
import { ShowDeleteConfirm } from "../confirmModal";
import { useStore } from "../../store";
import useDeleteTodo from "../../hookQuery/useDeleteTodo";
import { getUrlParams } from "../../utils";
import ActionsContainer from "../../react-ui/ActionsContainer";
import Delete from "../../react-ui/Icons/Delete";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import NumberCell from "../../react-ui/NumberCell";
import { formatNumberString } from "../../../utils/formatNumberString";
import { numberSorter } from "../../../utils/numberSorter";
import { stringSorter } from "../../../utils/stringSorter";

const selector = (state) => ({
  formToken: state.formToken,
  setSelectedParameter: state.setSelectedParameter,
});

const columns = [
  {
    title: "Intitulé",
    dataIndex: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Coefficient",
    dataIndex: "rate",
    sorter: (a, b) => numberSorter(a.rate, b.rate),
    width: "30%",
    render: (value) => (value ? <NumberCell>{value}</NumberCell> : null),
  },
  {
    title: "",
    dataIndex: "actions",
    width: "20px",
  },
];

export function DifficultyArray({
  setParameterDetailsStatus,
  topRef,
  difficulties,
}) {
  const { formToken, setSelectedParameter } = useStore(selector);
  const { mutate: deleteDifficulty } = useDeleteTodo(
    formToken,
    `/difficulty_profession/delete`,
    "Difficulties"
  );

  const pageEntityWork = parseInt(getUrlParams(), 10);

  const handleDeleteDifficulty = async (idDifficulty) => {
    await deleteDifficulty(JSON.stringify({ id: idDifficulty }));
  };

  const isDeletable = (entityWorkId) => entityWorkId === pageEntityWork;

  const data = [];
  let child;
  difficulties?.forEach((value) => {
    child = {
      key: value.id,
      id: value.id,
      name: value.name,
      rate: formatNumberString({ str: value.rate }),
      actions: isDeletable(value.entity_work_id) ? (
        <ActionsContainer>
          <Delete
            onClick={() =>
              ShowDeleteConfirm(value.name, value.id, handleDeleteDifficulty)
            }
          />
        </ActionsContainer>
      ) : null,
    };
    data.push(child);
  });

  const openUpdate = (value) => {
    setSelectedParameter(value);
    setParameterDetailsStatus({ status: "update", type: "difficulty" });
  };

  return (
    <StyledTable
      columns={columns}
      dataSource={data}
      scroll={{ y: 400 }}
      pagination={false}
      rowClassName={["cursor-pointer"]}
      onRow={(record) => {
        return {
          onClick: () => {
            openUpdate(difficulties.find((el) => el.id === record.id));
            topRef.current?.scrollIntoView({ behavior: "smooth" });
          },
        };
      }}
    />
  );
}

const StyledTable = styled(TableContainer)`
  margin: 10px 0;
`;
