import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useStore } from "../../../../../store";
import useTodo from "../../../../../hookQuery/useTodo";
import TableMovementChild from "./TableMovementChild";
import TableContainer from "../../../../CostingMovementsCarrycots/TableContainer";
import useUpdateTodo from "../../../../../hookQuery/useUpdateTodo";
import { currency_cents_to_euros } from "../../../../../utils";
import Input from "../../../../../react-ui/Input";
import { formatNumberString } from "../../../../../../utils/formatNumberString";
import { stringToFloat } from "../../../../../../utils/stringToFloat";
import { getMarginCol } from "../../../../../../constants/marginCol";
import { coeffToMargin } from "../../../../../../utils/marginConverter";

const selector = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
const TableMovement = React.memo(
  ({ zone_id, subcontracting, movementsOpp, configuration }) => {
    const { id_opportunity } = useParams();
    const { formToken, isUsingMargin, calculMethod } = useStore(selector);
    const [margin, setMargin] = useState(null);

    const { data: movement_profils_opportunity } = useTodo(
      formToken,
      `/profils_movement_opportunity/${id_opportunity}`,
      "MovementProfilsOpportunity"
    );

    const { data: profils_opportunity } = useTodo(
      formToken,
      `/profil_opportunity/${id_opportunity}`,
      "ProfilsOpportunity"
    );

    const { mutate: movementUpdate, isLoading: movementLoading } =
      useUpdateTodo(
        formToken,
        `/movement_opportunity/update`,
        "MovementsOpportunity",
        [
          "MovementProfilsOpportunity",
          "VehicleMovementOprt",
          "OpportunityCotationLc",
          "Zones",
        ],
        true
      );

    const concatenate = (movement_id) => {
      return movement_profils_opportunity
        ?.map((profils_movement) => {
          if (profils_movement.movement_opportunity_id === movement_id)
            return profils_movement.profil_opportunity_id;
        })
        .map((p) => {
          return profils_opportunity?.find((profil) => profil.id === p)?.name;
        })
        .filter(Boolean)
        .join(", ");
    };

    const pushDataInTable = useMemo(() => {
      const movementDataSource = [];
      movementsOpp
        ?.filter(
          (el) => currency_cents_to_euros(el.total_selling_price_cents) > 0
        )
        ?.forEach((movement) => {
          if (
            movement.zone_id === zone_id &&
            movement.subcontracting === subcontracting
          ) {
            movementDataSource.push({
              flag: "movement",
              type_transport: movement.type_transport,
              method_update: movementUpdate,
              key: movement.id,
              id: movement.id,
              profil_type: concatenate(movement.id),
              name: movement.name,
              quantity: formatNumberString({
                str: movement.quantity,
                nbDecimal: 2,
                space: true,
              }),
              profil_total: movement.occupants_number,
              unitary: formatNumberString({
                str: currency_cents_to_euros(movement.cost_price_cents),
                nbDecimal: 2,
                space: true,
              }),
              cost_price_total: formatNumberString({
                str: currency_cents_to_euros(movement.total_cost_cents),
                nbDecimal: 2,
                space: true,
              }),
              margin: movement.margin_rate,
              unitary_selling_HT: formatNumberString({
                str: currency_cents_to_euros(movement.selling_price_cents),
                nbDecimal: 2,
                space: true,
              }),
              total_selling_HT: formatNumberString({
                str: currency_cents_to_euros(
                  movement.total_selling_price_cents
                ),
                nbDecimal: 2,
                space: true,
              }),
            });
          }
        });
      if (!movementLoading) setMargin(null);
      return movementDataSource;
    }, [
      movementsOpp,
      movement_profils_opportunity,
      profils_opportunity,
      movementsOpp,
    ]);

    const MovementsColumns = [
      {
        title: "intitulé",
        dataIndex: "name",
      },
      {
        title: "Profils",
        children: [
          {
            title: "Type",
            dataIndex: "profil_type",
            width: "12%",
          },
          {
            title: "Total",
            dataIndex: "profil_total",
            width: "12%",
          },
        ],
      },
      {
        title: "Qt",
        dataIndex: "quantity",
        width: "5%",
      },
      {
        title: "Coût de revient",
        children: [
          {
            title: "Unitaire",
            dataIndex: "unitary",
            width: "10%",
            align: "right",
            render: (value) => <div>{value} €</div>,
          },
          {
            title: "Total",
            dataIndex: "cost_price_total",
            width: "10%",
            align: "right",
            render: (value) => <div>{value} €</div>,
          },
        ],
      },
      getMarginCol({
        configuration,
        isUsingMargin,
        calculMethod,
        hasSorter: false,
        render: (value, global) => (
          <Input
            fontSize="10px"
            textAlign="right"
            size="small"
            id={global.id}
            isMarginRate
            value={formatNumberString({
              str: margin?.[global.id] != null ? margin?.[global.id] : value,
              isCoeff: !isUsingMargin,
            })}
            onBlur={(e) => {
              if (margin?.[global.id]) {
                global.method_update({
                  opportunity_id: id_opportunity,
                  id: global.id,
                  margin_rate: coeffToMargin({
                    calculMethod,
                    isUsingMargin,
                    coeff: stringToFloat(e.target.value),
                  }),
                });
              }
            }}
            onChange={(e) =>
              setMargin((v) => ({
                ...v,
                [e.target.id]: e.target.value,
              }))
            }
          />
        ),
      }),
      {
        title: "Prix de vente HT",
        children: [
          {
            title: "Unitaire",
            dataIndex: "unitary_selling_HT",
            width: "10%",
            align: "right",
            render: (value) => <div>{value} €</div>,
          },
          {
            title: "Total",
            dataIndex: "total_selling_HT",
            width: "10%",
            align: "right",
            render: (value) => <div>{value} €</div>,
          },
        ],
      },
    ];

    const movementChilds = (record) => {
      return (
        <TableMovementChild
          parentId={record.id}
          movementsOpp={movementsOpp}
          configuration={configuration}
        />
      );
    };

    return pushDataInTable.length ? (
      <Container>
        <div>
          {subcontracting ? (
            <h4 id="movement-subcontracting" className="pl-2">
              Coûts sous-traitance - Déplacements
            </h4>
          ) : (
            <h4 id="movement" className="pl-2">
              Coûts internes - Déplacements
            </h4>
          )}
        </div>

        <TableContainer
          columns={MovementsColumns}
          data={pushDataInTable}
          expandedRowRender={movementChilds}
        />
      </Container>
    ) : null;
  }
);

const Container = styled.div`
  margin-top: 20px;
`;

export default TableMovement;
