/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Checkbox, Tooltip } from "antd";
import { useMutation } from "react-query";
import moment from "moment";
import i18next from "i18next";

import Select from "../../react-ui/Select";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import TitleContainer from "../../react-ui/TitleContainer";
import { postData, putData } from "../../request/instance";
import ConfirmModal from "./Modal/confirmAction";
import CollaboratorModal from "./Modal/collaboratorModal";
import { currency_cents_to_euros } from "../../utils";
import pipeIMG from "../../../images/pipe_icon.png";
import { formatNumberString } from "../../../utils/formatNumberString";
import { stringSorter } from "../../../utils/stringSorter";
import { dateSorter } from "../../../utils/dateSorter";
import { numberSorter } from "../../../utils/numberSorter";
import Warning from "../../react-ui/Icons/Warning";
import DuplicateModal from "./Modal/duplicateModal";
import DocSaModal from "../../documents-and-simplified/DocSaModal";

function OfferOpportunities({
  token,
  offer,
  refetchOffer,
  offerLoading,
  configuration,
  vocabulary,
  entities,
  userEntityId,
  configApplication,
  vocabularyApplication,
  siteTypologies,
  supervisor,
}) {
  const isUsingPrices = (recordTable) => {
    return configuration.find((config) => config.work === recordTable.work_name)
      ?.configuration.costing.page_3.visible;
  };

  const recordVocabulary = (recordTable) => {
    return vocabulary.find((vocab) => vocab.work === recordTable.work_name)
      ?.vocabulary;
  };

  const { mutate: update_opportunities_owner } = useMutation(
    (data) =>
      postData(token, `/offers/update_opportunities_owner`, {
        id: data.id,
        user_id: data.user_id,
      }),
    { onSuccess: () => refetchOffer() }
  );

  const { mutate: update_offer_owner } = useMutation(
    (data) =>
      postData(token, `/offers/update_offer_owner`, {
        id: data.id,
        user_id: data.user_id,
      }),
    { onSuccess: () => refetchOffer() }
  );

  const { mutate: update_offer_opportunity } = useMutation(
    (data) =>
      postData(token, `/offers/update_offer_opportunity`, {
        id: data.id,
        user_id: data.user_id,
        opportunity_id: data.opportunity_id,
      }),
    { onSuccess: () => refetchOffer() }
  );

  const { mutate: hidde_opportunity } = useMutation(
    (data) => postData(token, `/opportunities/${data.opportunity_id}/hidde`),
    { onSuccess: () => refetchOffer() }
  );

  const { mutate: update_opportunity_status } = useMutation(
    (data) =>
      putData(token, `/opportunities/${data.opportunity_id}/status_change`, {
        status: data.status,
      }),
    { onSuccess: () => refetchOffer() }
  );
  const { mutate: update_opportunity_pipe } = useMutation(
    (data) =>
      putData(token, `/opportunities/${data.opportunity_id}/pipe_change`, {
        pipe: data.pipe,
      }),
    { onSuccess: () => refetchOffer() }
  );

  const [message, setMessage] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalCollaboratorOpen, setIsModalCollaboratorOpen] = useState(false);
  const [isModalDuplicateOpen, setIsModalDuplicateOpen] = useState(false);
  const [isModalDocsOpen, setIsModalDocsOpen] = useState(false);
  const [opportunityCollaborator, setOpportunityCollaborator] = useState([]);
  const [modalAction, setModalAction] = useState("");
  const [record, setRecord] = useState({});
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    if (modalAction === "hidde")
      hidde_opportunity({ opportunity_id: record.id });
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChangeOpportunitiesOwner = (value) => {
    update_opportunities_owner({ id: offer?.offer.id, user_id: value });
  };

  const handleChangeOfferOwner = (value) => {
    update_offer_owner({ id: offer?.offer.id, user_id: value });
  };

  const handleChangeStatus = (value, record) => {
    update_opportunity_status({
      opportunity_id: record.id,
      status: value,
    });
  };

  const handleChangeOpportunityOwner = (value, opportunity) => {
    update_offer_opportunity({
      id: offer?.offer.id,
      user_id: value,
      opportunity_id: opportunity.id,
    });
  };

  const iconOpportunity = (recordTable) => {
    if (recordTable.work_name === "guarding") {
      return (
        <i
          className="fa-duotone fa-user-shield"
          title={i18next.t("work.guarding")}
        />
      );
    }
    return (
      <i
        className={`${
          configuration.find((config) => config.work === recordTable.work_name)
            ?.configuration.general.work_icon
        }`}
        title={i18next.t(`work.${recordTable.work_name}`)}
      />
    );
  };

  const sortUserForAllOpportunities = () => {
    let user_list = offer?.list_users;
    offer?.list_users.map((user) =>
      offer?.opportunities.map((opportunity) => {
        if (!user?.[1]?.[3]?.includes(opportunity.work_name)) {
          user_list = user_list.filter((u) => u !== user);
        }
      })
    );
    return user_list;
  };

  const sortUserForOpportunity = (opportunity) => {
    let user_list = opportunity?.list_possible_owners;
    opportunity?.list_possible_owners.map((user) =>
      user?.[1]?.[3]?.includes(opportunity.work_name)
        ? user_list
        : (user_list = user_list.filter((u) => u !== user))
    );
    return user_list;
  };

  const statusList = () => {
    let statusArray = [];
    if (configApplication.common.offer.specific_status_translation) {
      statusArray = [
        { id: "prospect", name: vocabularyApplication.offer.status_prospect },
        { id: "pending", name: vocabularyApplication.offer.status_pending },
        { id: "given", name: vocabularyApplication.offer.status_given },
        { id: "won", name: vocabularyApplication.offer.status_won },
        { id: "lost", name: vocabularyApplication.offer.status_lost },
        { id: "abandoned", name: vocabularyApplication.offer.status_abandoned },
      ];
    } else {
      statusArray = [
        { id: "prospect", name: i18next.t("status_offer.prospect") },
        { id: "pending", name: i18next.t("status_offer.pending") },
        { id: "given", name: i18next.t("status_offer.given") },
        { id: "won", name: i18next.t("status_offer.won") },
        { id: "lost", name: i18next.t("status_offer.lost") },
        { id: "abandoned", name: i18next.t("status_offer.abandoned") },
      ];
    }
    return statusArray;
  };

  const columns = [
    {
      title: "",
      width: "15px",
      fixed: "left",
      colSpan: 2,
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "inline",
            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          {offer?.opportunities_lock.find((id) => id === record.id) ? (
            <Tooltip title={record.lock_user_name} placement="right">
              <i className="fa-duotone fa-lock fa-red" />
            </Tooltip>
          ) : (
            <div />
          )}
        </div>
      ),
    },
    {
      title: "",
      width: "20px",
      fixed: "left",
      colSpan: 0,
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            flexDirection: "inline",
            alignItems: "baseline",
            justifyContent: "center",
          }}
        >
          <div>
            {iconOpportunity(record)}
            {/* {record.work_name === "guarding" && (
              <i
                className="fa-duotone fa-user-shield"
                title={i18next.t("work.guarding")}
              />
            )}
            {record.work_name === "fire safety" && (
              <i
                className="fa-duotone fa-fire-extinguisher"
                title={i18next.t("work.fire safety")}
              />
            )}
            {record.work_name === "electronic security" && (
              <i
                className="fa-duotone fa-video"
                title={i18next.t("work.electronic security")}
              />
            )} */}
          </div>
        </div>
      ),
    },
    {
      title: <img style={{ width: "20px" }} src={pipeIMG} />,
      dataIndex: "pipe",
      key: "pipe",
      align: "center",
      width: "30px",
      fixed: "left",
      className: "table__header-pipe",
      render: (pipe, recordTable) => (
        <div onClick={(e) => e.stopPropagation()}>
          <Checkbox
            disabled={configApplication.common.offer.disable_pipe}
            defaultChecked={pipe}
            onChange={(e) =>
              update_opportunity_pipe({
                opportunity_id: recordTable.id,
                pipe: e.target.checked,
              })
            }
          />
        </div>
      ),
    },
    {
      title: "N°",
      dataIndex: "opportunity_number",
      key: "opportunity_number",
      width: "40px",
      fixed: "left",
      sorter: (a, b) =>
        numberSorter(a.opportunity_number, b.opportunity_number),
    },
    {
      title: vocabularyApplication.offer.table.title,
      dataIndex: "opportunity_name",
      key: "opportunity_name",
      fixed: "left",
    },
    {
      title: vocabularyApplication.offer.table.chrono,
      dataIndex: "chrono",
      key: "chrono",
      width: "136px",
    },
    {
      title: vocabularyApplication.offer.table.clients,
      dataIndex: "client_name",
      key: "client_name",
      width: "150px",
      className: "table__column-client",
      filters: offer.client_list
        .split(",")
        .map((v) => ({ value: v.trim(), text: v.trim() })),
      onFilter: (value, recordTable) => recordTable.client_name === value,
      sorter: (a, b) => stringSorter(a.client_name, b.client_name),

      filterSearch: true,
    },
    {
      title: vocabularyApplication.offer.table.limit_date,
      dataIndex: "submission_date",
      key: "submission_date",
      width: "80px",
      className: "table__header-delivery-date",
      sorter: (a, b) => dateSorter(a.delivery_date, b.delivery_date),
      render: (submission_date) => (
        <div>{moment(submission_date).format("DD/MM/YYYY")}</div>
      ),
    },
    {
      title: vocabularyApplication.offer.table.turnover,
      key: "turnover",
      dataIndex: "turnover",
      width: "115px",
      className: "table__header-turnover",
      align: "right",
      sorter: (a, b) => numberSorter(a.turnover, b.turnover),
      render: (turnover) =>
        `${formatNumberString({
          str: currency_cents_to_euros(turnover),
          nbDecimal: 2,
          space: true,
        })} €`,
    },
    {
      title: vocabularyApplication.offer.table.entities,
      key: "entity_name",
      dataIndex: "entity_name",
      width: "150px",
      className: "table__header-entity-name",
      sorter: (a, b) => stringSorter(a.entity_name, b.entity_name),
    },
    {
      title: vocabularyApplication.offer.table.owner,
      dataIndex: "opportunity_owner",
      key: "opportunity_owner",
      className: offer.role_user[0] !== "salesman" ? "table__column-owner" : "",
      width: "150px",
      hidden: !configApplication.common.offer.show_owner,
      // sorter: (a, b) => a.user_name.length - b.user_name.length,
      render: (opportunity_owner, recordTable) => (
        <div>
          {offer.role_user[0] !== "salesman" ? (
            <Select
              disabled={
                configApplication.common.offer.disable_change_opportunity_owner
              }
              onClick={(e) => e.stopPropagation()}
              fontSize="12px"
              bordered={false}
              style={{ width: 140 }}
              defaultValue={recordTable.user_name}
              onSelect={(e) => handleChangeOpportunityOwner(e, recordTable)}
              options={sortUserForOpportunity(recordTable)?.map((user) => ({
                id: user?.[0],
                name: `${user?.[1]?.[1]?.[0]} ${user?.[1]?.[2]?.[0]}`,
              }))}
            />
          ) : (
            <div style={{ fontSize: 12 }}>{recordTable.user_name}</div>
          )}
        </div>
      ),
    },
    {
      title: "Statut",
      dataIndex: "status",
      key: "status",
      width: "130px",
      fixed: "right",
      className: "table__column-status",
      render: (status, recordTable) => (
        <Select
          disabled={
            configApplication.common.offer.disable_change_opportunity_status
          }
          onClick={(e) => e.stopPropagation()}
          fontSize="12px"
          defaultValue={status}
          bordered={false}
          style={{ width: 120 }}
          onSelect={(e) => {
            handleChangeStatus(e, recordTable);
          }}
          options={statusList()}
        />
      ),
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      title: () => <WarningIcon />,
      dataIndex: "warnings",
      key: "warnings",
      fixed: "right",
      render: (action, rec) => rec.has_warning && <WarningIcon />,
      width: "40px",
      sorter: (a, b) => numberSorter(a.has_warning, b.has_warning),
      hidden: !configApplication.common.offer.show_rules,
    },
    {
      title: "Actions",
      dataIndex: "action",
      width: "160px",
      key: "action",
      fixed: "right",
      render: (action, record) => (
        <StyledIconTable>
          {/* <i className="fa-duotone fa-eye-slash fa-primary" title="Masquer l'offre" />
          <i className="fa-duotone fa-duotone fa-lock fa-1xhalf fa-red" title="lock" /> */}

          <div>
            {record?.duplicate &&
            !configApplication.common.offer.disable_duplicate ? (
              record.work_name === "guarding" ? (
                <StyledI
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsModalOpen(true);
                    setModalAction("duplicate");
                    setRecord(record);
                    setMessage({
                      title: "Dupliquer l'opportunité",
                      content: (
                        <p>
                          Êtes vous sûr de vouloir dupliquer l'opportunité{" "}
                          {record.opportunity_name} ?{" "}
                        </p>
                      ),
                    });
                  }}
                  className="text-dark fa-duotone fa-1x25-duotone fa-copy fa-blue"
                  title="Dupliquer"
                />
              ) : (
                <StyledI
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsModalDuplicateOpen(true);
                    setRecord(record);
                  }}
                  className="text-dark fa-duotone fa-1x25-duotone fa-copy fa-blue"
                  title="Dupliquer"
                />
              )
            ) : (
              <StyledI
                onClick={(e) => e.stopPropagation()}
                className="text-dark fa-duotone fa-1x25-duotone fa-copy fa-duotone-grey"
                title="Dupliquer - non disponible"
              />
            )}
          </div>
          {/* <i
            className="fa-duotone fa-file-invoice-dollar fa-2x fa-duotone-green"
            title="Synthèse financière"
          />
          <i
            className="fa-duotone fa-file-invoice-dollar fa-2x fa-duotone-grey"
            title="Synthèse financière - non disponible"
          /> */}
          <div>
            {record.work_name === "guarding" && record.step === 7 && (
              <StyledI
                onClick={(e) => {
                  e.stopPropagation();
                  window.location = `/opportunities/${record.id}/xlsx_export`;
                }}
                className="fa-duotone fa-1x25-duotone fa-file-invoice-dollar fa-duotone-green"
                title="Détails des coûts"
              />
            )}
            {record.work_name !== "guarding" && record.step === 4 && (
              <StyledI
                onClick={(e) => {
                  e.stopPropagation();
                  window.location = `/opportunities/${record.id}/generate_low_current`;
                }}
                className="fa-duotone fa-1x25-duotone fa-file-invoice-dollar fa-duotone-green"
                title="Détails des coûts"
              />
            )}
            {record.work_name === "guarding" && record.step !== 7 && (
              <StyledI
                className="fa-duotone fa-1x25-duotone fa-file-invoice-dollar fa-duotone-grey"
                title="Détails des coûts - non disponible"
              />
            )}
            {record.work_name !== "guarding" && record.step !== 4 && (
              <StyledI
                className="fa-duotone fa-1x25-duotone fa-file-invoice-dollar fa-duotone-grey"
                title="Détails des coûts - non disponible"
              />
            )}
          </div>
          <div>
            {record.editor && !configApplication.common.offer.disable_editor ? (
              <StyledI
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(
                    `/opportunities/${record.opportunity_id}/proposal_editor_redirection`,
                    "_blank"
                  );
                }}
                className="fa-duotone fa-1x25-duotone fa-file-alt fa-duotone-orange"
                title="Document Editor"
              />
            ) : (
              <StyledI
                onClick={(e) => e.stopPropagation()}
                className="fa-duotone fa-1x25-duotone fa-file-alt fa-duotone-grey"
                title="Document Editor - non disponible"
              />
            )}
          </div>
          <div>
            {offer.documents ? (
              <StyledI
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalDocsOpen(true);
                  setRecord(record);
                }}
                className="text-dark fa-duotone fa-1x25-duotone fa-folder-tree"
                title="Gestion des documents"
              />
            ) : (
              <StyledI
                onClick={(e) => e.stopPropagation()}
                className="fa-duotone fa-1x25-duotone fa-folder-tree fa-duotone-grey"
                title="Gestion des documents - non disponible"
              />
            )}
          </div>
          <div>
            {record.allowed_to_add_collaborators &&
            !configApplication.common.offer.disable_collaborator ? (
              <StyledI
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalCollaboratorOpen(true);
                  setRecord(record);
                }}
                className="text-dark fa-duotone fa-1x25-duotone fa-user-friends fa-purple"
                title="Collaboration"
              />
            ) : (
              <StyledI
                onClick={(e) => e.stopPropagation()}
                className="text-dark fa-duotone fa-1x25-duotone fa-user-friends fa-duotone-grey"
                title="Collaboration - non disponible"
              />
            )}
          </div>
          <div>
            {record.allowed_to_add_collaborators &&
            !configApplication.common.offer.disable_hidde ? (
              <StyledI
                onClick={(e) => {
                  e.stopPropagation();
                  setIsModalOpen(true);
                  setModalAction("hidde");
                  setRecord(record);
                  setMessage({
                    title: "Masquer l'opportunité",
                    content: (
                      <>
                        <p>
                          Êtes vous sûr de vouloir masquer l'opportunité{" "}
                          {record.opportunity_name}?{" "}
                        </p>
                        <p>
                          {" "}
                          Seul votre administrateur sera en mesure de la rendre
                          visible de nouveau!
                        </p>
                        {offer.crm && (
                          <p>
                            !!!!! IMPORTANT !!!!! Les données de l'opportunité
                            ne seront plus prises en compte dans les indicateurs
                            du dashboard
                          </p>
                        )}
                      </>
                    ),
                  });
                }}
                className="fa-duotone fa-eye-slash fa-primary table__hide-opportunity"
                title="Masquer l'opportunité"
              />
            ) : (
              <StyledI
                onClick={(e) => e.stopPropagation()}
                className="fa-duotone fa-eye-slash fa-duotone-grey table__hide-opportunity"
                title="Masquer l'opportunité - non disponible"
              />
            )}
          </div>
        </StyledIconTable>
      ),
    },
  ];

  // eslint-disable-next-line react/no-unstable-nested-components
  function OWnerSelect() {
    if (offer.allow_to_see_deal_owner) {
      if (
        ["supervisor", "manager", "admin", "superadmin", "setup"].includes(
          offer.role_user[0]
        )
      ) {
        return (
          <div>
            Propriétaire :{" "}
            <Select
              disabled={
                configApplication.common.offer.disable_change_offer_owner
              }
              onSelect={(e) => handleChangeOfferOwner(e)}
              style={{ width: "300px" }}
              defaultValue={offer?.offer.user_name}
              options={offer?.list_users.map((user) => ({
                id: user?.[0],
                name: `${user?.[1]?.[1]?.[0]} ${user?.[1]?.[2]?.[0]}`,
              }))}
            />
          </div>
        );
      }
      return (
        <div style={{ fontWeight: "bold" }}>
          Propriétaire : {offer.offer.user_name}
        </div>
      );
    }
    return <div />;
  }

  const opportunity_ids = useMemo(() => (!record ? [] : [record.id]), [record]);
  const opportunity_names = useMemo(
    () => (!record ? [] : [record.chrono]),
    [record]
  );
  return (
    <div>
      <StyledTitleContainer
        label={`${vocabularyApplication.offer.opportunities_title} (${offer.opportunities.length})`}
      />
      <StyledSelectOwner>
        {configApplication.common.offer.show_owner ? (
          <div
            className={
              offer.allow_to_create_opportunity && "select__offer-owner"
            }
          >
            {OWnerSelect()}
          </div>
        ) : (
          <div />
        )}
        {offer.allow_to_create_opportunity &&
        configApplication.common.offer.create_button_opportunity ? (
          <button
            type="button"
            className="btn btn-primary btn-warning button__create-offer"
            onClick={() =>
              (window.location = `/opportunities/new?offer_id=${offer.offer.id}`)
            }
          >
            <div className="d-flex align-items-center mr-2 ml-2">
              <i className="fa-duotone mr-1 fa-plus-circle" />
              {vocabularyApplication.offer.button_create_opportunity}
            </div>
          </button>
        ) : (
          <div />
        )}
      </StyledSelectOwner>
      {/* <Label label="Changer le propriétaire de toutes les opportunités" />
      <Select
        onSelect={(e) => handleChangeOpportunitiesOwner(e)}
        style={{ width: "200px" }}
        defaultValue={offer?.offer.user_name}
        options={sortUserForAllOpportunities()?.map((user) => ({
          value: user?.[0],
          label: `${user?.[1]?.[1]?.[0]} ${user?.[1]?.[2]?.[0]}`,
        }))}
      />
      <Label label="Changer le propriétaire de l'offre" />
      <Select
        onSelect={(e) => handleChangeOfferOwner(e)}
        style={{ width: "200px" }}
        defaultValue={offer?.offer.user_name}
        options={offer?.list_users.map((user) => ({
          value: user?.[0],
          label: `${user?.[1]?.[1]?.[0]} ${user?.[1]?.[2]?.[0]}`,
        }))}
      /> */}
      <TableContainer
        className="offer-opportunities-table table__opportunities"
        columns={columns}
        data={offer?.opportunities}
        fontSize={12}
        loading={offerLoading}
        onRowAction
        rowKey="id"
        scroll={{
          x: 1300,
          y: "calc(100vh - 70px - 65.56px - 0.375rem - 0.375rem - 305.08px - 48.29px - 32.6px - 10px - 10px - 50px - 2vh)",
        }}
      />
      <ConfirmModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        message={message}
        modalAction={modalAction}
        record={record}
        entities={entities}
        userEntityId={userEntityId}
        token={token}
        refetchOffer={refetchOffer}
      />
      <CollaboratorModal
        isModalCollaboratorOpen={isModalCollaboratorOpen}
        setIsModalCollaboratorOpen={setIsModalCollaboratorOpen}
        record={record}
        setRecord={setRecord}
        opportunities={offer?.opportunities}
        token={token}
        refetchOffer={refetchOffer}
      />
      <DuplicateModal
        isModalDuplicateOpen={isModalDuplicateOpen}
        setIsModalDuplicateOpen={setIsModalDuplicateOpen}
        record={record}
        setRecord={setRecord}
        opportunities={offer?.opportunities}
        token={token}
        refetchOffer={refetchOffer}
        isUsingPrices={isUsingPrices(record)}
        recordVocabulary={recordVocabulary(record)}
        entities={entities}
        userEntityId={userEntityId}
      />
      {record && offer.documents && (
        <DocSaModal
          opportunity_ids={opportunity_ids}
          formToken={token}
          closeModal={() => setIsModalDocsOpen(false)}
          isOpen={isModalDocsOpen}
          siteTypologies={siteTypologies}
          opportunity_names={opportunity_names}
          offer_name={offer.offer.name}
          offer_id={record.offer_id}
          supervisor={supervisor}
          opportunity_work={record.work_name}
        />
      )}
    </div>
  );
}

const StyledTitleContainer = styled(TitleContainer)`
  margin: 0;
  display: flex;
  justify-content: center;
`;

const StyledI = styled.i`
  cursor: pointer;
`;

const StyledIconTable = styled.div`
  display: flex;
  flex-direction: inline;
  justify-content: space-around;
`;

const StyledSelectOwner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
`;

const WarningIcon = styled(Warning)`
  display: flex;
  justify-content: center;
`;

// const StyledListCollaborators = styled`
//     display: flex;
//     flex-direction: inline;
//     justify-content: space-around;
// `;
export default OfferOpportunities;
