import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Badge } from "antd";
import { useQuery } from "react-query";
import DocumentsButton from "../react-ui/DocumentsButton";
import DocSaModal from "./DocSaModal";
import { getData } from "../request/instance";

function DocSa({
  opportunity_id,
  formToken,
  siteTypologies,
  opportunity_name,
  offer_name,
  offer_id,
  supervisor,
  opportunity_chrono,
  opportunity_work,
  documents_disabled,
}) {
  const { data: numDocuments } = useQuery(
    ["numDocuments", opportunity_id],
    () => getData(formToken, `/opportunities/${opportunity_id}/documents_size`)
  );
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const opportunity_ids = useMemo(() => [opportunity_id], [opportunity_id]);
  const opportunity_names = useMemo(
    () => [opportunity_chrono],
    [opportunity_chrono]
  );

  return (
    <>
      <DocSaModal
        opportunity_ids={opportunity_ids}
        formToken={formToken}
        closeModal={closeModal}
        isOpen={isOpen}
        siteTypologies={siteTypologies}
        opportunity_names={opportunity_names}
        offer_name={offer_name}
        offer_id={offer_id}
        supervisor={supervisor}
        opportunity_work={opportunity_work}
      />
      <ContainerDocuments>
        <StyledBadge size="small" count={numDocuments} overflowCount={999} />
        <StyledDocumentsButton
          label={`Documents ${
            supervisor ? "de l'opportunité" : "et construction de dossiers"
          }`}
          onClick={openModal}
          disabled={documents_disabled}
        />
      </ContainerDocuments>
    </>
  );
}

const StyledDocumentsButton = styled(DocumentsButton)`
  padding: 0.2rem 0.5rem;
`;

const StyledBadge = styled(Badge)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -40%);
  .ant-badge-count-sm {
    font-size: 8px;
  }
`;

const ContainerDocuments = styled.div`
  position: relative;
  display: inline-block;
`;

export default DocSa;
