import React from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { stringSorter } from "../../../../utils/stringSorter";
import { numberSorter } from "../../../../utils/numberSorter";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import AddButton from "../../../react-ui/AddButton";
import TitleContainer from "../../../react-ui/TitleContainer";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import Delete from "../../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../../confirmModal";

const columns = (handleDeleteProfilRule, usersFromApi) => [
  {
    title: "Intitulé",
    dataIndex: "name",
    width: "30%",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Métier",
    dataIndex: "workName",
    width: "20%",
    sorter: (a, b) => numberSorter(a.workName, b.workName),
  },
  {
    title: "Utilisateurs concernés",
    dataIndex: "user_ids",
    render: (user_ids) => (
      <UserConcerned>
        {usersFromApi
          ?.filter((user) => user_ids.includes(user.id))
          ?.map((el) => el.fullname)
          .join(", ")}
      </UserConcerned>
    ),
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
    width: "5%",
    render: (record) => (
      <Delete
        onClick={() =>
          ShowDeleteConfirm(record.name, record.id, handleDeleteProfilRule)
        }
      />
    ),
  },
];

const selector = (state) => ({
  formToken: state.formToken,
});

function ProfilArray({
  profilStatus,
  setProfilStatus,
  profilRules,
  works,
  companyWorks,
  selectedProfilRule,
  setSelectedProfilRule,
  users,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const openCreate = () => {
    setProfilStatus("create");
    setSelectedProfilRule();
  };

  const openUpdate = (record) => {
    setProfilStatus("update");
    setSelectedProfilRule(profilRules.find((el) => el.id === record.id));
  };

  const { mutate: deleteProfilRule } = useMutation(
    (todo) => postData(formToken, "/profil_rule/delete", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("ProfilRules");
      },
    }
  );

  const handleDeleteProfilRule = (id) => {
    deleteProfilRule({ id });
    setProfilStatus();
    setSelectedProfilRule();
  };

  return (
    <Container>
      <TitleContainer label="Profils disponibles">
        <AddButton
          label="Profil"
          onClick={openCreate}
          disabled={profilStatus === "create"}
        />
      </TitleContainer>
      <TableContainer
        bordered={false}
        columns={columns(handleDeleteProfilRule, users)}
        rowKey="id"
        dataSource={
          profilRules?.map((el) => {
            const profilCw = companyWorks.find(
              (cw) => cw.id === el.company_work_id
            );
            return {
              ...el,
              workName: works.find((work) => profilCw.work_id === work.id)
                .fullname,
            };
          }) || []
        }
        pagination={false}
        isRowSelectable
        rowSelectableId={
          profilStatus === "update" ? selectedProfilRule?.id : undefined
        }
        onRow={(record) => {
          return {
            onClick: () => openUpdate(record),
          };
        }}
        scrollY
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const UserConcerned = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default ProfilArray;
