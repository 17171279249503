const CalculateSellingPrice = (inputValue, itemValue, id_opportunity, updateFunction, method_calcul) => {
    const margin_selling_price = (selling_price, cost_price) => {
        return Number.parseFloat(((selling_price / cost_price) -1) * 100)
    }
    
    const no_margin_method_selling_price = (selling_price, cost_price) => {
        return Number.parseFloat((1-(cost_price / selling_price)*100))
    }
    updateFunction({
       opportunity_id: id_opportunity,
       id: itemValue.id,
       selling_price_cents: inputValue.target.value,
       margin_rate: method_calcul === "selling_price" ? 
            margin_selling_price(inputValue.target.value, itemValue.unitary_cost_price) 
        :
            no_margin_method_selling_price(inputValue.target.value, itemValue.unitary_cost_price)
    })
}

export default CalculateSellingPrice;