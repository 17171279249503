import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['save', 'delete']

  update_batch_identification(){
    debugger
    let checkBox_batch_market = this.batch-marketTarget;
    let text_batch_identification = this.batch-identificationTarget;
      if (checkBox_batch_market=true){
        text_batch_identification.hidden = true
      } else {
        text_batch_identification.hidden = false
      }
  }

  disabled_buttons_save() { 
    let button_save = this.saveTarget;
    let button_new = document.getElementById('button_create_offer')
    button_save.disabled = true
    button_save.hidden = false
    button_new.hidden = true
  }

  disabled_buttons_delete() {
    let button_delete = this.deleteTarget;
    let button_new = document.getElementById('button_create_offer')
    if (button_delete.hidden){
      button_delete.disabled = true
      button_delete.hidden = true
    } else {
      button_delete.disabled = true
      button_delete.hidden = false
    }
    button_new.hidden = true
  }
}