import { encodeUrlParams } from "./encodeUrlParams";

const getUrl = (from, id) => {
  if (from === "referencing") return `/material_associate_opportunity?`;
  if (from === "frameworkAgreement")
    return "/material_asso_framework_agreements?";
  return "/material_associates?";
};

export const fetchMaterialAssociatesUrl = ({ from, id, keyId, parentId }) => {
  let url = getUrl(from, id);
  const params = {
    from,
    ...(from === "admin" && { entity_work_id: id }),
    ...(from === "referencing" && { opportunity_id: id }),
    ...(from === "frameworkAgreement" && { framework_agreement_id: id }),
    ...(keyId && { [keyId]: parentId }),
  };

  url += encodeUrlParams(params);

  return url;
};
