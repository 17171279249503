import { notification } from "antd";

export const copyInClipboard = (copyText) => {
  navigator.clipboard.writeText(copyText);
  notification.info({
    description: "Copié dans le presse papier.",
    placement: "bottom",
    duration: 2,
  });
};
