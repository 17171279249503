import React, { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { formatNumberString } from "../../../utils/formatNumberString";
import Input from "../../react-ui/Input";
import { postData } from "../../request/instance";
import { useStore } from "../../store";
import { stringToFloat } from "../../utils";

const selector = (state) => ({
  formToken: state.formToken,
  setLoadingStatus: state.setLoadingStatus,
  loadingStatus: state.loadingStatus,
});

function CoefficientInput({ record, x, y, rentalOfferId, useMargin }) {
  const nbDecimal = useMemo(() => (useMargin ? 2 : 4), [useMargin]);
  const suffix = useMargin ? "%" : <span />;
  const { formToken, setLoadingStatus } = useStore(selector);
  const [value, setValue] = useState(
    formatNumberString({ str: record, nbDecimal })
  );

  const { mutate: updateCoefficient, status } = useMutation(
    (todo) => postData(formToken, "/rental_coefficient/update", todo),
    {
      onSuccess: (payload) =>
        setValue(
          formatNumberString({
            str: useMargin ? payload.coefficient * 100 : payload.coefficient,
            nbDecimal,
          })
        ),
      onError: (err) =>
        setValue(
          formatNumberString({
            str: err.response?.data?.coefficient || record,
            nbDecimal,
          })
        ),
    }
  );

  useEffect(() => {
    setLoadingStatus(status);
  }, [setLoadingStatus, status]);

  const handleOnBlur = (e) => {
    const newValue = formatNumberString({
      str: e.target.value,
      nbDecimal,
    });
    updateCoefficient({
      rental_coefficient: {
        x,
        y,
        rental_offer_id: rentalOfferId,
        coefficient: useMargin
          ? stringToFloat(newValue) / 100
          : stringToFloat(newValue),
      },
    });
  };

  const handleOnChange = (e) => {
    const newValue = formatNumberString({
      str: e.target.value,
      nbDecimal,
    });

    setValue(newValue);
  };

  useEffect(() => {
    setValue(formatNumberString({ str: record, nbDecimal }));
  }, [record, nbDecimal]);

  return (
    <Input
      value={value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      fontSize="10px"
      suffix={suffix}
      textAlign="right"
    />
  );
}

export default CoefficientInput;
