import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { useStore } from "../../store";
import { stringSorter } from "../../../utils/stringSorter";

const selector = (state) => ({
  setWorkCompany: state.setWorkCompany,
  selectedWorkCompany: state.selectedWorkCompany,
});
function JobsAvailable({ works, user_works, formEditMode }) {
  const { setWorkCompany, selectedWorkCompany } = useStore(selector);
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    const dataResult = [];
    let child;
    const works_data = works;
    works_data.map((work) => {
      child = {
        name: work.work.name,
        enabled: !!user_works.find((uw) => uw.work_id === work.work_id),
        ...work,
      };
      dataResult.push(child);
    });
    const setTableRowsAsync = new Promise((resolve) => {
      const sortedData = dataResult.sort(
        (a, b) => Number(b.enabled) - Number(a.enabled)
      );
      setTableRows(sortedData);
      resolve(sortedData);
    });

    if (!selectedWorkCompany) {
      setTableRowsAsync.then((sortedData) => {
        setWorkCompany(sortedData[0]);
      });
    }
  }, [works]);

  const colums_jobs_available = useMemo(() => {
    return [
      {
        title: "Intitulé",
        dataIndex: "name",
        width: "30%",
      },
    ];
  }, []);

  const clickOnRow = (record) => {
    if (formEditMode) return;
    if (record.enabled) setWorkCompany(record);
  };

  return (
    <TableContainer
      columns={colums_jobs_available}
      fontSize={12}
      data={tableRows}
      scroll={{ y: 255 }}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            clickOnRow(record);
          },
        };
      }}
      rowClassName={useMemo(
        () => (record) => !record.enabled && "disable-row",
        []
      )}
      isRowSelectable
      rowSelectableId={selectedWorkCompany?.id}
      pagination={false}
      rowKey="id"
    />
  );
}

export default JobsAvailable;
