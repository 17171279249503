import styled from "styled-components";
import { Card, Divider, Space } from "antd";

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 10px;
  padding: 10px;
`;

export const StyledDivider = styled(Divider)`
  .ant-divider-inner-text {
    color: #0b2239;
    font-size: 14px;
    font-weight: 200 !important;
    line-height: 24px;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
  .ant-card-body {
    padding: 10px 24px;
    flex-wrap: wrap;
  }
  .ant-card-head-title {
    font-size: 14px;
  }
`;

export const StyledSpace = styled(Space)`
  .ant-space-item {
    display: flex;
    flex-basis: 50%;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1FR 1FR;
  /* gap: 8px; */
  column-gap: 18px;
  row-gap: 10px;
  grid-auto-rows: 1fr;
  align-items: center;
  justify-content: space-between;
  justify-items: end;

  p {
    margin: 0;
    font-size: 12px !important;
  }
`;

export const ContainerOutCard = styled(Space)`
  padding: 10px 24px;
`;
