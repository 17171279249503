import React from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import Search from "antd/lib/input/Search";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { stringSorter } from "../../../utils/stringSorter";
import TitleContainer from "../../react-ui/TitleContainer";
import NumberCell from "../../react-ui/NumberCell";
import Delete from "../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../confirmModal";
import { useStore } from "../../store";
import { postData } from "../../request/instance";
import CopySecret from "../../react-ui/CopySecret";

const applicationsColumns = (handleDelete) => [
  {
    title: "Nom",
    dataIndex: "name",
    width: "90px",
    fixed: "left",
    sorter: (a, b) => stringSorter(a.name, b.name),
    render: (name) => <NameCell>{name}</NameCell>,
  },
  {
    title: "Créée le",
    dataIndex: "created_at",
    width: "80px",
    sorter: (a, b) => stringSorter(a.created_at, b.created_at),
    render: (created_at) => (
      <NumberCell>
        {new Date(created_at).toLocaleDateString("fr-FR")}
      </NumberCell>
    ),
  },
  {
    title: "URL de redirection",
    dataIndex: "redirect_uri",
    width: "200px",
    render: (redirect_uri) => <NameCell>{redirect_uri}</NameCell>,
  },
  {
    title: "ID du client",
    dataIndex: "uid",
    width: "200px",
    render: (uid) => <CopySecret copyText="Copier l'id client" secret={uid} />,
  },
  {
    title: "Secret du client",
    dataIndex: "secret",
    render: (secret) => (
      <CopySecret copyText="Copier le secret client" secret={secret} />
    ),
  },
  {
    title: "",
    dataIndex: "delete",
    fixed: "right",
    align: "center",
    width: "20px",
    render: (_, record) => (
      <Delete
        onClick={(e) => {
          e.stopPropagation();
          ShowDeleteConfirm(record.name, record.id, handleDelete);
        }}
      />
    ),
  },
];

function ApplicationsArray({
  applications,
  applicationsAreLoading,
  wordEntered,
  setWordEntered,
  setStatus,
  setSelectedApplication,
  selectedApplication,
}) {
  // eslint-disable-next-line no-shadow
  const formToken = useStore(({ formToken }) => formToken);
  const queryClient = useQueryClient();

  const { mutate: deleteApplication } = useMutation(
    (todo) => postData(formToken, `/applications/delete`, todo),
    {
      onSuccess: ({ application }) => {
        queryClient.invalidateQueries("Applications");
        if (selectedApplication && application.id === selectedApplication.id) {
          setSelectedApplication();
          setStatus("empty");
        }
      },
    }
  );

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const handleDelete = (id) => {
    deleteApplication({ id });
  };

  return (
    <Container>
      <TitleContainer label="Liste des applications connectées" />
      <StyledSearch
        allowClear
        placeholder="Rechercher une application..."
        value={wordEntered}
        onChange={handleFilter}
      />
      <TableContainer
        bordered={false}
        columns={applicationsColumns(handleDelete)}
        rowKey="id"
        dataSource={applications}
        pagination={false}
        isRowSelectable
        rowSelectableId={selectedApplication?.id}
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedApplication(record);
              setStatus("show");
            },
          };
        }}
        scrollY
        loading={applicationsAreLoading}
      />
    </Container>
  );
}

const Container = styled.div`
  width: ${({ $procurement }) => ($procurement ? "60%" : "50%")};
`;

const NameCell = styled.div`
  word-break: break-word;
`;

const StyledSearch = styled(Search)`
  margin-bottom: 12px;
`;

export default ApplicationsArray;
