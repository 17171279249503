import { Space } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Filters from "./Filters";
import CostingSection from "../../../Chiffrage/CostingMovementsCarrycots/CostingSection";
import ListSection from "../../../Chiffrage/CostingMovementsCarrycots/ListSection";
import Separator from "../../../react-ui/Separator";
import { getUrlParams } from "../../../utils";

import { useStore } from "../../../store";
import { StyledCard } from "../styledComponent";
import { deepEqual } from "../FilesImport";

const selector = (state) => ({
  formToken: state.formToken,
});

function MovingCarrycotsTab({ configuration, setConfiguration }) {
  const [isDisabled, setIsDisabled] = useState({
    show_movements:
      configuration.admin.movement_tab.visible === false
        ? true
        : false ?? false,
    show_carrycots:
      configuration.admin.carrycot_tab.visible === false
        ? true
        : false ?? false,
  });
  const { show_movements, show_carrycots, visible } =
    configuration.costing.page_2;
  const [filters, setFilters] = useState({
    show_movements,
    show_carrycots,
    visible,
  });

  const { formToken } = useStore(selector);

  useEffect(() => {
    if (deepEqual(filters, configuration.costing.page_2)) return;
    setConfiguration({
      ...configuration,
      costing: {
        ...configuration.costing,
        page_2: {
          ...configuration.costing.page_2,
          show_movements: filters.show_movements,
          show_carrycots: filters.show_carrycots,
          visible: filters.visible,
        },
      },
    });
  }, [filters]);

  useEffect(() => {
    setIsDisabled({
      show_movements:
        configuration.admin.movement_tab.visible === false
          ? true
          : false ?? false,
      show_carrycots:
        configuration.admin.carrycot_tab.visible === false
          ? true
          : false ?? false,
    });
  }, [configuration]);

  return (
    <Container>
      <ContainerHorizontal>
        <div
          style={{
            flexBasis: "37%",
          }}
        />
        <Separator size={3} />
        <div
          style={{
            flexBasis: "70%",
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <Filters
            filters={filters}
            setFilters={setFilters}
            isDisabled={isDisabled}
          />
        </div>
      </ContainerHorizontal>
      <Space size={24} direction="vertical" style={{ padding: "10px 20px" }}>
        <PreviewMovingCarrycots
          formToken={formToken}
          entity_work_id={getUrlParams("entity_work_id")}
          configuration={configuration}
        />
      </Space>
    </Container>
  );
}

function PreviewMovingCarrycots({ formToken, entity_work_id, configuration }) {
  return (
    <StyledCard
      headStyle={{ backgroundColor: "#0B2239", color: "white" }}
      bodyStyle={{ backgroundColor: "#F5F6F8" }}
      size="small"
      title="Prévisualisation"
      style={{
        width: "100%",
      }}
    >
      <ContainerHorizontal
        style={{
          backgroundColor: "#F5F6F8",
          height: "auto",
          overFlow: "scroll",
        }}
      >
        <ListSection
          formToken={formToken}
          id_opportunity="1"
          from="configuration"
        />
        <Separator className="divider-preview" />
        <CostingSection
          formToken={formToken}
          entity_work_id={entity_work_id}
          configuration={configuration}
          from="configuration"
        />
      </ContainerHorizontal>
    </StyledCard>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding-top: 24px;
`;

export default MovingCarrycotsTab;
