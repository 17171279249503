import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
import styled from "styled-components";
import { useQuery } from "react-query";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useStore } from "../../store";
import useTodo from "../../hookQuery/useTodo";
import { stringSorter } from "../../../utils/stringSorter";
import { numberSorter } from "../../../utils/numberSorter";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { getData } from "../../request/instance";
import { generateFormData } from "../../../utils/generateFormData";
import useDeleteTodo from "../../hookQuery/useDeleteTodo";
import useCreateTodo from "../../hookQuery/useCreateTodo";

const selector = (state) => ({
  formToken: state.formToken,
  selectedCategory: state.selectedCategory,
  selectedMateriel: state.selectedMateriel,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function FamilyAssociates({ entityWorkId, parent }) {
  const { selectedMateriel, selectedCategory, formToken } = useStore(selector);
  const [familyAssociateData, setFamilyAssociateData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  const sendSearch = () => (wordEntered ? `&search=${wordEntered}` : "");

  const { refetch, data: families } = useTodo(
    formToken,
    `/family_professions?entity_work_id=${entityWorkId}${sendSearch()}&from=admin`,
    "Families"
  );

  const { data: familiesAssociatesApi } = useQuery(
    ["FamiliesAssociates", { parent }],
    () =>
      getData(
        formToken,
        `/family_associates?entity_work_id=${entityWorkId}&parent=${parent}`
      )
  );

  const { mutate: removeFamilyAssociate } = useDeleteTodo(
    formToken,
    `/family_associate/delete`,
    "FamiliesAssociates"
  );

  const { mutate: createFamiliyAssociate } = useCreateTodo(
    formToken,
    "/family_associate/create",
    "FamiliesAssociates"
  );

  useEffect(() => {
    setFamilyAssociateData(
      families?.map((family) => {
        const familiesAssos =
          familiesAssociatesApi?.filter(
            (asso) => asso.family_profession_id === family.id
          ) || [];
        let familyAssoFields = {};

        if (parent === "category") {
          const familyAsso = familiesAssos.find(
            (el) => el.category_profession_id === selectedCategory?.id
          );
          familyAssoFields = familyAsso
            ? {
                linked: true,
                asso_id: familyAsso.id,
              }
            : {};
        }

        if (parent === "material") {
          const familyAssoCategory = familiesAssos.find(
            (el) =>
              el.category_profession_id ===
              selectedMateriel?.category_profession_id
          );

          if (familyAssoCategory) {
            familyAssoFields = {
              linked: true,
              asso_id: familyAssoCategory.id,
            };
          } else {
            const familyAsso = familiesAssos.find(
              (el) => el.material_profession_id === selectedMateriel?.id
            );
            familyAssoFields = familyAsso
              ? {
                  linked: true,
                  asso_id: familyAsso.id,
                }
              : {};
          }
        }

        return {
          ...family,
          ...familyAssoFields,
        };
      })
    );
  }, [
    familiesAssociatesApi,
    selectedMateriel,
    families,
    parent,
    selectedCategory,
  ]);

  const handleFamilyChecked = (id, checked) => {
    if (checked) {
      const family = families.find((el) => el.id === id);
      const formData = generateFormData({
        e: family,
        formName: "family_associate",
        excludedKeys: ["id"],
      });
      if (parent === "material") {
        formData.append(
          `family_associate[material_profession_id]`,
          selectedMateriel?.id
        );
      }
      if (parent === "category") {
        formData.append(
          `family_associate[category_profession_id]`,
          selectedCategory?.id
        );
      }
      formData.append(`family_associate[family_profession_id]`, id);
      createFamiliyAssociate(formData);
    } else {
      const keysToSend =
        parent === "material"
          ? {
              family_profession_id: id,
              material_profession_id: selectedMateriel?.id,
            }
          : {
              family_profession_id: id,
              category_profession_id: selectedCategory?.id,
            };
      removeFamilyAssociate(JSON.stringify(keysToSend));
    }
  };

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const familyAssoCols = [
    {
      title: "Intitulé",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: "Associer",
      dataIndex: "linked",
      width: "65px",
      align: "center",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        numberSorter(a.linked, b.linked) || numberSorter(a.asso_id, b.asso_id),
      render: (linked, record) => (
        <Checkbox
          disabled={
            parent === "material" &&
            record.asso_id &&
            familiesAssociatesApi?.find((asso) => asso.id === record.asso_id)
              ?.category_profession_id
          }
          checked={linked}
          onChange={({ target: { checked } }) => {
            if (
              !(
                parent === "material" &&
                record.asso_id &&
                familiesAssociatesApi?.find(
                  (asso) => asso.id === record.asso_id
                )?.category_profession_id
              )
            )
              handleFamilyChecked(record.id, checked);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <StyledSearch
        allowClear
        placeholder="Rechercher par nom de famille"
        value={wordEntered}
        onChange={handleFilter}
      />
      <TableContainer
        columns={familyAssoCols}
        data={familyAssociateData}
        rowKey="id"
      />
    </>
  );
}

const StyledSearch = styled(Search)`
  margin: 10px 0;
`;

export default FamilyAssociates;
