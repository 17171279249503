import React, { useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import Separator from "../../../react-ui/Separator";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import RuleArray from "./RuleArray";
import RuleDetails from "./RuleDetails";

const selector = (state) => ({
  formToken: state.formToken,
});

function Rules({ works, companyWorks, siteTypologies, entities }) {
  const { formToken } = useStore(selector);
  const [ruleStatus, setRuleStatus] = useState();
  const [selectedRule, setSelectedRule] = useState();

  const { data: rules } = useQuery("Rules", () => getData(formToken, "/rules"));

  return (
    <Container>
      <RuleArray
        ruleStatus={ruleStatus}
        setRuleStatus={setRuleStatus}
        rules={rules}
        works={works}
        companyWorks={companyWorks}
        selectedRule={selectedRule}
        setSelectedRule={setSelectedRule}
        siteTypologies={siteTypologies}
      />
      <Separator />
      <RuleDetails
        ruleStatus={ruleStatus}
        setRuleStatus={setRuleStatus}
        companyWorks={companyWorks}
        works={works}
        selectedRule={selectedRule}
        setSelectedRule={setSelectedRule}
        rules={rules}
        siteTypologies={siteTypologies}
        entities={entities}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 10px;
`;

export default Rules;
