import React, { useMemo } from "react";
import { bool, func, object, shape } from "prop-types";
import Form from "../../Form";

function DistributorsForm({
  isShowing,
  onSubmit,
  updateOnChange,
  submit,
  initialDistributor,
}) {
  const formFactory = useMemo(() => {
    const firstRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Nom du distributeur",
          },
          name: "name",
          label: "Intitulé",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },

      {
        type: "upload",
        item: {
          upload: {
            listType: "picture-card",
            maxCount: 1,
            accept: "image/*",
          },
          name: "picture",
        },
        style: { display: "flex", justifyContent: "flex-end" },
      },
    ];

    return [{ columns: firstRow, key: "name+picture" }];
  }, []);

  const initialValues = useMemo(() => {
    if (!initialDistributor) return {};
    const picture = initialDistributor.picture
      ? [
          {
            uid: "1",
            name: "image",
            status: "done",
            url: initialDistributor.picture,
          },
        ]
      : [];

    return {
      name: initialDistributor.name,
      //   manufacturer: initialDistributor.manufacturer,
      //   distributor: initialDistributor.distributor,
      picture,
    };
  }, [initialDistributor]);

  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={formFactory}
      isShowing={isShowing}
      id="manu-distrib-form"
    />
  );
}

DistributorsForm.propTypes = {
  isShowing: bool,
  onSubmit: func,
  updateOnChange: bool,
  submit: shape({
    onCancel: func,
  }),
  initialDistributor: object,
};

DistributorsForm.defaultProps = {
  isShowing: false,
  onSubmit: undefined,
  updateOnChange: false,
  submit: undefined,
  initialDistributor: undefined,
};

export default DistributorsForm;
