import React, { useCallback, useEffect, useRef, useState } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { debounce } from "lodash";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import {
  fetchOuvragesUrl,
  fetchOuvragesProfessionUrl,
} from "../../../../utils/fetchOuvragesUrl";
import { handleMaterialSorterChange } from "../../../../utils/sorterTable";

const debouncedSearch = debounce(({ ouvragesNextPage, fetchNextPage }) => {
  // eslint-disable-next-line no-param-reassign
  ouvragesNextPage.current = 1;
  fetchNextPage({ pageParam: ouvragesNextPage.current });
}, 300);

const selector = (state) => ({
  formToken: state.formToken,
  selectedMateriel: state.selectedMateriel,
  selectedFamily: state.selectedFamily,
});

function LazyOuvrageArray({
  columns,
  dataSource,
  rowSelectableId,
  onRow,
  from,
  opportunityId,
  faIsValidate,
  faId,
  entityWorkId,
  ouvrages,
  setOuvrages,
  wordEntered,
  categoryFiltered,
  setCategoryFiltered,
  onOuvrageNewSearch,
  parent,
  ouvrageId,
  isForAssociates = false,
  material_parent_id = null,
  components,
  ouvrageProfessionsQueryKey = ["Ouvrages", { from }],
  group,
  selectedFamilyId = null,
}) {
  const queryClient = useQueryClient();
  const { formToken } = useStore(selector);
  const [sortBy, setSortBy] = useState(
    isForAssociates ? { field: "associated", order: "DESC" } : undefined
  );
  const ouvragesNextPage = useRef(1);
  // Check if useInfiniteQuery has trigger one time
  const didFirstInfiniteQuery = useRef(false);

  const { fetchNextPage, isFetching } = useInfiniteQuery(
    ouvrageProfessionsQueryKey,
    () =>
      getData(
        formToken,
        fetchOuvragesProfessionUrl({
          nextPage: ouvragesNextPage.current,
          categoryFiltered,
          search: wordEntered,
          id: from === "admin" ? entityWorkId : opportunityId,
          from: from,
          ouvrage_id: ouvrageId,
          // id_opportunity: opportunityId,
          material_parent_id,
          group,
          selectedFamilyId,
        })
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (payload) => {
        if (!didFirstInfiniteQuery.current)
          didFirstInfiniteQuery.current = true;
        const lastPage = payload.pages[payload.pages.length - 1];
        // Reset ouvrages when back to first page of materials
        if (lastPage.current_page === 1) {
          setOuvrages(lastPage.ouvrage_professions);
        } else setOuvrages([...ouvrages, ...lastPage.ouvrage_professions]);
        ouvragesNextPage.current = lastPage.next_page;
      },
    }
  );

  useEffect(() => {
    if (didFirstInfiniteQuery.current) {
      if (onOuvrageNewSearch) onOuvrageNewSearch();
      debouncedSearch({
        ouvragesNextPage,
        fetchNextPage,
      });
    }
  }, [
    wordEntered,
    fetchNextPage,
    categoryFiltered,
    sortBy,
    onOuvrageNewSearch,
  ]);

  useEffect(() => {
    ouvragesNextPage.current = 1;
    fetchNextPage({ pageParam: ouvragesNextPage.current });
  }, [fetchNextPage, ouvrageId, material_parent_id, selectedFamilyId]);

  const checkScrollPosition = useCallback(
    (event) => {
      const perc =
        (event.target.scrollTop /
          (event.target.scrollHeight - event.target.clientHeight)) *
        100;
      if (perc >= 95 && !isFetching && ouvragesNextPage.current !== null) {
        fetchNextPage({ pageParam: ouvragesNextPage.current });
      }
    },
    [fetchNextPage, isFetching]
  );

  const handleChange = (pagination, fltrs, sorter) => {
    handleMaterialSorterChange({
      pagination,
      fltrs,
      sorter,
      setCategoryFiltered,
      categoryFiltered,
      setSortBy,
    });
  };

  return (
    <TableContainer
      bordered={false}
      columns={columns}
      dataSource={dataSource || ouvrages}
      scrollY
      pagination={false}
      isRowSelectable
      rowSelectableId={rowSelectableId}
      onEndScroll={checkScrollPosition}
      onChange={handleChange}
      components={components}
      loading={isFetching}
      onRow={(record, index) => ({
        index,
        record,
        ...(onRow && { onClick: (e) => onRow(record) }),
      })}
      // onRow={(record) => {
      //   if (onRow) return onRow(record);
      //   return undefined;
      // }}
    />
  );
}

export default LazyOuvrageArray;
