import React from "react";
import styled from "styled-components";

function Save({ className = "", ...iconProps }) {
  return (
    <StyledIcon className={`fa-duotone fa-save ${className}`} {...iconProps} />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: 12px;
  &:before {
    color: inherit;
  }
`;

export default Save;
