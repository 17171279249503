import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { Modal, Tabs } from "antd";
import { useQuery } from "react-query";
import ImportDocument from "./import-document";
import SA from "./simplified-application";
import Docusign from "./docusign";
import DocusignIcon from "../react-ui/Icons/DocusignIcon";
import { getData } from "../request/instance";

function DocSaModal({
  opportunity_ids,
  formToken,
  closeModal,
  isOpen,
  siteTypologies,
  opportunity_names,
  offer_name,
  offer_id,
  supervisor,
  fromOffer = false,
  opportunity_work,
}) {
  const [status, setStatus] = useState("empty");
  const getTabName = (keyVocabulary, docusignIcon, keyIcon) => (
    <TabLabelContainer>
      {icon(docusignIcon, keyIcon)}
      <TabName>{keyVocabulary}</TabName>
    </TabLabelContainer>
  );

  const icon = (docusignIcon, keyIcon) => {
    if (docusignIcon === true) {
      return <DocusignIcon />;
    }
    return <i className={keyIcon} />;
  };

  const { data: docusign_api } = useQuery(
    "qqq",
    () => getData(formToken, `/docusign_api/show_current_user`),
    {
      enabled: isOpen,
    }
  );

  const docusignTab = useCallback(() => {
    if (docusign_api?.docusign_user_id) {
      return [
        {
          label: getTabName("Signature électronique", true),
          key: "se",
          disabled: supervisor,
          children: (
            <Docusign
              formToken={formToken}
              opportunity_id={opportunity_ids[0]}
            />
          ),
        },
      ];
    }
    return null;
  }, [docusign_api]);
  const items = [
    {
      label: getTabName("Documents", false, "fa-solid fa-files"),
      key: "documents",
      children: fromOffer ? (
        <ImportDocument
          offer_id={offer_id}
          formToken={formToken}
          isOpen={isOpen}
          status={status}
          setStatus={setStatus}
          supervisor={supervisor}
        />
      ) : (
        <ImportDocument
          opportunity_id={opportunity_ids[0]}
          formToken={formToken}
          isOpen={isOpen}
          status={status}
          setStatus={setStatus}
          supervisor={supervisor}
        />
      ),
    },
    {
      label: getTabName(
        "Construction de dossiers",
        false,
        "fa-kit fa-solid-table-tree-pen"
      ),
      key: "sa",
      disabled: supervisor,
      children: (
        <SA
          opportunity_ids={opportunity_ids}
          formToken={formToken}
          closeModal={closeModal}
          isOpen={isOpen}
          siteTypologies={siteTypologies}
          opportunity_names={opportunity_names}
          offer_name={offer_name}
          offer_id={offer_id}
          fromOffer={fromOffer}
          opportunity_work={opportunity_work}
        />
      ),
    },
    ...(docusignTab() === null ? [] : docusignTab()),
  ];

  return (
    <StyledModal
      open={isOpen}
      maskClosable={false}
      footer={null}
      width="100vw"
      destroyOnClose
      onCancel={() => {
        closeModal();
        setStatus("empty");
      }}
    >
      <StyledTabs
        defaultActiveKey="products"
        type="card"
        // style={{ height: "calc(100% - 70px)", overflow: "auto" }}
        destroyInactiveTabPane
        // onTabClick={handleTabClick}
        items={items}
      />
    </StyledModal>
  );
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 2.5px !important;
    padding-top: 12px !important;
    height: 85vh;
    background-color: #f5f6f8;
  }
  .ant-modal-body {
    height: 100%;
  }

  .ant-tabs {
    width: auto !important;
  }
  .ant-tabs,
  .ant-tabs-top,
  .ant-tabs-card {
    position: relative !important;
    height: 100%;
  }
  .ant-tabs-content-holder {
    overflow-y: hidden !important;
  }
`;

const TabLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const TabName = styled.span`
  display: block;
`;

export default DocSaModal;
