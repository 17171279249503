import React, { useEffect, useMemo, useState } from "react";
import { Checkbox } from "antd";
import { useStore } from "../../store";
import { translate_filter } from "../Categories/translate_data";
import useTodo from "../../hookQuery/useTodo";
import useDeleteTodo from "../../hookQuery/useDeleteTodo";
import { ShowDeleteConfirm } from "../confirmModal";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import ActionsContainer from "../../react-ui/ActionsContainer";
import FileDocument from "../../react-ui/Icons/FileDocument";
import Delete from "../../react-ui/Icons/Delete";
import { stringSorter } from "../../../utils/stringSorter";
import { numberSorter } from "../../../utils/numberSorter";
import { getMarginCol } from "../../../constants/marginCol";

const selector = (state) => ({
  selectedPrestation: state.selectedPrestation,
  editPrestation: state.editPrestation,
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

function Actions({ value, handleDeletePrestation, from }) {
  const driveLink =
    from !== "admin" && value.prestation_included
      ? value.prestation_included.drive_link
      : value.drive_link;
  const colGap = "8%";
  return (
    <ActionsContainer $colGap={colGap}>
      <FileDocument
        onClick={() =>
          driveLink ? window.open(driveLink, "_blank") : undefined
        }
        disabled={!driveLink}
      />
      {from === "admin" && (
        <Delete
          onClick={() =>
            ShowDeleteConfirm(value.name, value.id, handleDeletePrestation)
          }
        />
      )}
    </ActionsContainer>
  );
}

const PrestationArray = React.memo(
  ({
    setPrestationDetailsStatus,
    prestations,
    entityWorkId,
    from,
    handleFAAndOpp,
    faIsValidate,
    prestationDetailsStatus,
    entity_work_name,
    configuration,
    vocabulary,
    use_special_tva,
  }) => {
    const {
      selectedPrestation,
      editPrestation,
      formToken,
      isUsingMargin,
      calculMethod,
    } = useStore(selector);
    const { data: categories } = useTodo(
      formToken,
      `/category_profession?entity_work_id=${entityWorkId}`,
      "Categories"
    );
    const { mutate: removePrestations } = useDeleteTodo(
      formToken,
      "/prestation_profession/delete",
      "Prestations"
    );

    const [tableRowsData, setTableRowData] = useState([]);

    useEffect(() => {
      const dataResult = [];
      let child;

      prestations?.forEach((value) => {
        child = {
          ...value,
          key: value.id,
          id: value.id,
          name: value.prestation_included
            ? value.prestation_included.name
            : value.name,
          is_in_framework_agreement:
            value.is_in_framework_agreement || value.prestation_included,
          maintenance: value.maintenance,
          subcontracting: value.subcontracting,
          is_package: value.is_package,
          margin: value.margin_rate,
          actions: (
            <Actions
              handleDeletePrestation={handleDeletePrestation}
              value={value}
              from={from}
            />
          ),
          category: categories?.find(
            (category) => category.id === value.category_profession_id
          )?.title,
        };
        dataResult.push(child);
      });

      setTableRowData(dataResult);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prestations]);

    const openUpdatePrestationProfession = (value) => {
      editPrestation(value);
      setPrestationDetailsStatus("update");
    };

    const getColumnsPrestations = useMemo(() => {
      const nameCol = {
        title: "Intitulé",
        dataIndex: "name",
        sorter: (a, b) => stringSorter(a.name, b.name),
      };
      const codeCol = {
        title: "Référence",
        dataIndex: "reference_code",
        sorter: (a, b) => stringSorter(a.reference_code, b.reference_code),
        width: "90px",
        align: "right",
        hidden: !configuration.costing.page_1.table_col_reference_code,
      };

      const categoryCol = {
        title: "Catégorie",
        dataIndex: "category",
        filters: translate_filter(categories),
        filterMode: "tree",
        filterSearch: true,
        onFilter: (value, record) =>
          record.category_profession_id &&
          record.category_profession_id === parseInt(value, 10),
        sorter: (a, b) => stringSorter(a.category, b.category),
        width: from === "admin" ? "20%" : "15%",
      };

      const maintenanceCol = {
        title: "Maint.",
        dataIndex: "maintenance",
        width: "60px",
        align: "center",
        hidden: !configuration.general.maintenance,
        sorter: (a, b) => numberSorter(a.maintenance, b.maintenance),
        render: (value) => <Checkbox checked={value} disabled />,
      };

      const subcontractingCol = {
        title: "S-T",
        dataIndex: "subcontracting",
        width: "60px",
        align: "center",
        hidden: !configuration.general.subcontracting,
        sorter: (a, b) => numberSorter(a.subcontacting, b.subcontacting),
        render: (value) => <Checkbox checked={value} disabled />,
      };

      const packageCol = {
        title: "Forfait",
        dataIndex: "is_package",
        width: "60px",
        align: "center",
        sorter: (a, b) => numberSorter(a.is_package, b.is_package),
        render: (value) => <Checkbox checked={value} disabled />,
      };

      const tvaCol = {
        title: vocabulary.TVA,
        dataIndex: "tva_profession_name",
        width: "15%",
        sorter: (a, b) =>
          stringSorter(a.tva_profession_name, b.tva_profession_name),
        hidden: !use_special_tva,
      };

      const frameworkAgreementCol = {
        title: "Accord cadre",
        dataIndex: "is_in_framework_agreement",
        width: "60px",
        align: "center",
        hidden: !configuration.general.framework_agreement,
        sorter: (a, b) =>
          numberSorter(
            a.is_in_framework_agreement,
            b.is_in_framework_agreement
          ),
        render: (value, record) => (
          <Checkbox
            checked={value}
            onClick={(e) => {
              e.stopPropagation();
              handleFAAndOpp(value, record);
            }}
            disabled={from === "frameworkAgreement" && !faIsValidate}
          />
        ),
      };

      const actionsCol = {
        title: "",
        dataIndex: "actions",
        width: from === "admin" ? "35px" : "15px",
      };

      if (from !== "admin")
        return [
          nameCol,
          categoryCol,
          maintenanceCol,
          getMarginCol({
            isUsingMargin,
            calculMethod,
            entity_work_name,
            configuration,
          }),
          frameworkAgreementCol,
          actionsCol,
        ].filter((col) => !col.hidden);
      return [
        nameCol,
        codeCol,
        categoryCol,
        maintenanceCol,
        subcontractingCol,
        packageCol,
        getMarginCol({
          isUsingMargin,
          calculMethod,
          entity_work_name,
          configuration,
        }),
        tvaCol,
        actionsCol,
      ].filter((col) => !col.hidden);
    }, [
      from,
      categories,
      handleFAAndOpp,
      faIsValidate,
      isUsingMargin,
      calculMethod,
    ]);

    const handleDeletePrestation = (idPrestation) => {
      setPrestationDetailsStatus("empty");
      editPrestation();
      removePrestations(
        JSON.stringify({ id: idPrestation, entity_work_id: entityWorkId })
      );
    };

    return (
      <TableContainer
        bordered={false}
        scrollY
        columns={getColumnsPrestations}
        dataSource={tableRowsData}
        pagination={false}
        isRowSelectable
        rowSelectableId={
          prestationDetailsStatus === "update"
            ? selectedPrestation?.id
            : undefined
        }
        onRow={(record) => {
          return {
            onClick: () => {
              openUpdatePrestationProfession(
                prestations.find((el) => el.id === record.id)
              );
            },
          };
        }}
      />
    );
  }
);

export default PrestationArray;
