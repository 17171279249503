import React from "react";
import { Route, Routes } from "react-router-dom";
import ManageHidden from "../offers-opportunities-hidden";

function HiddenRoutes({ token, users, entities, role }) {
  return (
    <Routes>
      <Route
        index
        element={
          <ManageHidden
            token={token}
            users={users}
            entities={entities}
            role={role}
          />
        }
      />
    </Routes>
  );
}

export default HiddenRoutes;
