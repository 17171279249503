import React, { useEffect } from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import OuvrageModify from "./OuvrageModify";
import OuvrageForm from "./OuvrageForm";
import { useStore } from "../../store";
import { createOuvrageProfessionForm } from "../../../utils/createFormData";
import { postData, getData } from "../../request/instance";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
function OuvrageUpdate({
  ouvrageSelected = null,
  token,
  ouvrageDetailsStatus,
  setOuvrageDetailsStatus,
  configuration,
  ouvrageChildId,
  setOuvrageChildId,
  vocabulary,
  entityWorkId,
  faIsValidate,
  faId,
  from,
  unities,
  ouvrageDetails,
  setStatusOuvrage,
  entityId,
  categories,
  opportunityId,
  setOuvrageDetails,
  setOuvrageSelected,
  group,
  opportunityType,
  setOuvrageDynamicLabel,
  round_type_list,
  setOuvrages = () => {},
}) {
  const { isUsingMargin, calculMethod } = useStore(selector);
  const queryClient = useQueryClient();

  const ouvrageProfessionQueryKey = [
    "OuvrageProfession",
    ouvrageSelected[0]?.id,
  ];

  const { mutate: updateOuvrage, status: ouvrageStatus } = useMutation(
    (todo) => postData(token, "/ouvrage_profession/update", todo),
    {
      onSuccess: (payload) => {
        setOuvrages((oldOuvrages) => {
          const updatedOuvrages = [...oldOuvrages];
          const idxOuvrage = updatedOuvrages.findIndex(
            (ovr) => ovr.id === payload.id
          );
          updatedOuvrages[idxOuvrage] = payload;
          return updatedOuvrages;
        });
        queryClient.invalidateQueries(ouvrageProfessionQueryKey);
        // queryClient.invalidateQueries("OuvrageProfessions");
      },
    }
  );

  const { mutate: createOuvrage, isLoading: loadingCreateOuvrage } =
    useMutation((todo) => postData(token, "/ouvrage_profession/create", todo), {
      onSuccess: (payload) => {
        if (ouvrageDetailsStatus === "knot") {
          queryClient.invalidateQueries(ouvrageProfessionQueryKey);
        } else {
          setOuvrages((oldOuvrages) => {
            const updatedOuvrages = [...oldOuvrages];
            return [payload, ...updatedOuvrages];
          });
          setOuvrageSelected([payload]);
          setOuvrageChildId(payload.id);
          queryClient.invalidateQueries("OuvrageProfessions");
        }
      },
    });

  const handleRegisterOuvrageProfession = (e) => {
    const formData = createOuvrageProfessionForm(
      e,
      isUsingMargin,
      calculMethod
    );
    if (from === "referencing")
      formData.append("ouvrage_profession[opportunity_id]", opportunityId);
    else {
      formData.append("ouvrage_profession[entity_id]", entityId);
      formData.append("ouvrage_profession[entity_work_id]", entityWorkId);
    }

    if (
      ouvrageDetailsStatus === "knot" ||
      ouvrageDetailsStatus === "knot_modify"
    ) {
      formData.append("ouvrage_profession[ouvrage_master]", false);
    } else {
      formData.append("ouvrage_profession[ouvrage_master]", true);
    }

    if (ouvrageDetailsStatus === "knot") {
      formData.append("ouvrage_profession[parent_ouvrage_ids]", ouvrageChildId);
    }

    if (group) {
      formData.append("ouvrage_profession[group]", true);
    } else {
      formData.append("ouvrage_profession[group]", false);
    }
    if (
      ouvrageDetailsStatus === "show" ||
      ouvrageDetailsStatus === "modify" ||
      ouvrageDetailsStatus === "knot_modify"
    ) {
      if (
        ouvrageDetailsStatus === "knot_modify" ||
        ouvrageChildId !== ouvrageSelected[0]?.id
      ) {
        formData.append("id", ouvrageChildId);
      } else {
        formData.append("id", ouvrageSelected[0]?.id);
      }
      updateOuvrage(formData);
    }
    if (ouvrageDetailsStatus === "create" || ouvrageDetailsStatus === "knot") {
      if (!("maintenance" in e) || e?.maintenance === null) {
        formData.append("ouvrage_profession[maintenance]", false);
      }
      if (from === "referencing")
        formData.append("create_for_referencing", true);
      if (from === "frameworkAgreement")
        formData.append("framework_agreement_id", faId);
      createOuvrage(formData);
      setOuvrageDetailsStatus("show");
    }
  };
  const onSubmit = (e) => {
    handleRegisterOuvrageProfession(e);
  };

  useEffect(() => {
    if (ouvrageStatus === "loading") {
      setStatusOuvrage("loading");
      return;
    }
    if (ouvrageStatus === "success") {
      setStatusOuvrage("success");
      return;
    }
    setStatusOuvrage("idle");
  }, [ouvrageStatus, setStatusOuvrage]);

  const { data: ouvrage_profession_knot } = useQuery(
    ["OuvrageProfession", ouvrageChildId],
    () => getData(token, `/ouvrage_profession/${ouvrageChildId}`),
    {
      enabled: !!ouvrageChildId,
    }
  );

  // useEffect(() => {
  //   ouvrage_knot_refetch();
  // }, [ouvrageChildId]);

  const initialOuvrage = () => {
    if (ouvrageDetailsStatus === "knot" || ouvrageDetailsStatus === "create") {
      return undefined;
    }
    if (
      ouvrageDetailsStatus === "modify" ||
      ouvrageDetailsStatus === "knot_modify"
    ) {
      if (ouvrageDetails?.length === 0) return undefined;
      return ouvrage_profession_knot;
    }

    return ouvrageDetails?.[0];
  };
  return (
    <div>
      {(ouvrageDetailsStatus === "modify" ||
        ouvrageDetailsStatus === "knot_modify" ||
        ouvrageDetailsStatus === "show") && (
        <OuvrageModify
          setOuvrageChildId={setOuvrageChildId}
          setOuvrageDynamicLabel={setOuvrageDynamicLabel}
          ouvrageSelected={ouvrageSelected}
          configuration={configuration}
          token={token}
          from={from}
          ouvrageChildId={ouvrageChildId}
          vocabulary={vocabulary}
          entityWorkId={entityWorkId}
          faId={faId}
          faIsValidate={faIsValidate}
          unities={unities}
          opportunityId={opportunityId}
          ouvrageDetailsStatus={ouvrageDetailsStatus}
          setOuvrageDetailsStatus={setOuvrageDetailsStatus}
          isUsingMargin={isUsingMargin}
          calculMethod={calculMethod}
          initialOuvrage={initialOuvrage}
          categories={categories}
          onSubmit={onSubmit}
          group={group}
          round_type_list={round_type_list}
        />
      )}
      {(ouvrageDetailsStatus === "create" ||
        ouvrageDetailsStatus === "knot") && (
        <OuvrageForm
          ouvrageDetailsStatus={ouvrageDetailsStatus}
          setOuvrageDetailsStatus={setOuvrageDetailsStatus}
          isUsingMargin={isUsingMargin}
          onSubmit={onSubmit}
          unities={unities}
          initialOuvrage={initialOuvrage()}
          updateOnChange={
            ouvrageDetailsStatus !== "knot" && ouvrageDetailsStatus !== "create"
          }
          configuration={configuration}
          vocabulary={vocabulary}
          calculMethod={calculMethod}
          faIsValidate={faIsValidate}
          categories={categories}
          id="ouvrage_form_master"
          opportunityType={opportunityType}
          from={from}
          group={group}
        />
      )}
    </div>
  );
}

export default OuvrageUpdate;
