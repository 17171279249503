import React from "react";
import { string } from "prop-types";
import styled from "styled-components";

function CSV({ className, ...iconProps }) {
  return (
    <StyledIcon
      className={`fa-duotone fa-file-csv ${className}`}
      {...iconProps}
    />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: 12px;
`;

CSV.propTypes = {
  className: string,
};

CSV.defaultProps = {
  className: "",
};

export default CSV;
