import React, { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Importer, ImporterField } from "react-csv-importer";

import { frFR } from "../../../../constants/ImporterLocale";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import { getUrlParams } from "../../../utils";

const selector = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
});

function PrestaAssosImporter() {
  const queryClient = useQueryClient();

  const { formToken } = useStore(selector);
  const entity_work_id = useMemo(() => {
    return getUrlParams();
  }, []);
  const { mutateAsync: createPrestaAssosByCsv } = useMutation((todo) =>
    postData(formToken, "/material_profession/create_presta_asso_by_csv", todo)
  );

  return (
    <Importer
      assumeNoHeaders={false}
      restartable
      onComplete={() => {
        queryClient.invalidateQueries({
          queryKey: "PrestationsAssociates",
        });
      }}
      processChunk={async (rows) => {
        await createPrestaAssosByCsv({
          prestations_assos: rows,
          entity_work_id,
        });
      }}
      locale={frFR}
    >
      <ImporterField
        name="reference_code_parent"
        label="Code article matériel parent"
      />
      <ImporterField
        name="prestation_parent_id"
        label="Id de la prestation associée"
      />
      <ImporterField
        name="estimate_quantity"
        label="Quantité estimée"
        optional
      />
    </Importer>
  );
}

export default PrestaAssosImporter;
