import React from "react";
import { useDrop } from "react-dnd";
import { Divider } from "antd";
import styled from "styled-components";
import DropZoneComponent from "./DropZoneComponent";
import DragElement from "./DragElement";

function OpportunityLayoutDraggable({
  label,
  dataJson,
  setDataJson,
  defaultTVA,
  type,
  elementsList,
  setElementsList,
  headerName,
  handleDragIn,
  handleDragOut,
  removeElement,
  forbidKeys,
}) {
  const [, drop] = useDrop(
    () => ({
      accept: type,
      drop: (item) => dragOutElement(item),
    }),
    [dataJson, elementsList, setElementsList, type]
  );

  const dragOutElement = (item) => {
    return handleDragOut(item, type);
  };

  return (
    <Layout>
      <div className="drop-zone">
        <header>{headerName}</header>
        <div className="drop-grid">
          {Object.keys(dataJson).map((key) => {
            return (
              <div key={`flexCol${key}`} className="drop-child-col">
                {Object.keys(dataJson[key]).map((key2) => {
                  return (
                    <DropChildRow key={`flex${key}-${key2}`}>
                      <DropZoneComponent
                        label={label}
                        elementsList={elementsList}
                        setElementsList={setElementsList}
                        data={dataJson[key][key2]}
                        dataJson={dataJson}
                        setDataJson={setDataJson}
                        defaultTVA={defaultTVA}
                        handleDragIn={handleDragIn}
                        col={key}
                        line={key2}
                        type={type}
                        removeElement={removeElement}
                      />
                    </DropChildRow>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <Divider
        className="divider"
        type="vertical"
        style={{
          height: "auto",
          borderColor: "rgba(11, 34, 57, 1)",
          margin: "0 4px",
        }}
      />
      <div ref={drop} className="drag-list">
        <header>{headerName}</header>
        <div className="grid-list">
          {elementsList.map((element) => (
            <DragElement
              key={element}
              label={label}
              element={element}
              type={type}
              forbidKeys={forbidKeys}
              className="drag-element"
            />
          ))}
        </div>
      </div>
    </Layout>
  );
}

const Layout = styled.div`
  display: grid;
  grid-template-columns: 80% 1% 19%;
  grid-column-gap: 0;

  .drop-zone {
    grid-column: 1;
    margin-bottom: 8px;
  }

  .divider {
    grid-column: 2;
  }

  .drag-list {
    grid-column: 3;

    .grid-list {
      /* 2 colonnes, lignes illimitées */
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 16px;
      column-gap: 12px;
      padding: 4px;
      width: 100%;
      overflow-y: auto;
    }
  }

  header {
    font-size: 14px;
    font-weight: 700;
    padding: 4px 10px;
    margin-bottom: 8px;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(11, 34, 57, 1);
  }

  .drop-grid {
    display: flex;
    row-gap: 16px;
    column-gap: 12px;
    flex-wrap: wrap;
    padding: 4px;
    width: 100%;

    .drop-child-col {
      display: flex;
      flex-direction: column;
      flex-basis: calc(100% / 2 - 6px);
      row-gap: 16px;
    }
  }
`;

const DropChildRow = styled.div`
  height: 80px;
`;

export default OpportunityLayoutDraggable;
