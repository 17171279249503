import React, { useCallback, useEffect, useState } from "react";

import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { useStore } from "../../store";
import CarrycotArray from "./CarrycotArray";
import CarrycotDetails from "./CarrycotDetails";
import { getUrlParams } from "../../utils";
import useTodo from "../../hookQuery/useTodo";
import TitleContainer from "../../react-ui/TitleContainer";
import Separator from "../../react-ui/Separator";
import AddButton from "../../react-ui/AddButton";
import { postData } from "../../request/instance";
import { fetchCarrycotsUrl } from "../../../utils/fetchCarrycotsUrl";
import { fetchProfilsUrl } from "../../../utils/fetchProfilsUrl";
import { getFetchUrlIdAndFrom } from "../../../utils/getFetchUrlIdAndFrom";

const selector = (state) => ({
  formToken: state.formToken,
  editCarrycot: state.editCarrycot,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function Nacelles({
  entityWorkId = getUrlParams(),
  from = "admin",
  opportunityId = null,
  faIsValidate = false,
  faId = null,
}) {
  const queryClient = useQueryClient();
  const { formToken, editCarrycot } = useStore(selector);

  const [wordEntered, setWordEntered] = useState("");
  const [carrycotDetailsStatus, setCarrycotDetailsStatus] = useState("empty");

  const { data: carrycots, refetch } = useTodo(
    formToken,
    fetchCarrycotsUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
      search: wordEntered,
    }),
    ["Carrycots", { from }]
  );
  const { data: profils } = useTodo(
    formToken,
    fetchProfilsUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
    }),
    ["Profils", { from }]
  );

  const {
    mutate: createCarrycotOpportunity,
    isLoading: isCreaCarryOppLoading,
  } = useMutation(
    (todoData) =>
      postData(
        formToken,
        "/carrycot_opportunity/create_for_referencing",
        todoData
      ),
    {
      onSuccess: (payload) => {
        queryClient.invalidateQueries("Carrycots");
        editCarrycot({
          ...carrycots.find((el) => el.id === payload.carrycot_profession_id),
          carrycot_included: payload,
          is_in_framework_agreement: true,
        });
      },
    }
  );

  const { mutate: deleteCarryOpportunity, isLoading: isDelCarryOppLoading } =
    useMutation(
      (todoData) =>
        postData(formToken, "/carrycot_opportunity/delete", todoData),
      {
        onSuccess: (payload) => {
          queryClient.invalidateQueries("Carrycots");
          editCarrycot({
            ...carrycots.find((el) => el.id === payload.carrycot_profession_id),
            carrycot_included: null,
            is_in_framework_agreement: false,
          });
        },
      }
    );

  const { mutate: createCarryFA, isLoading: isCreaCarryFALoading } =
    useMutation(
      (todoData) =>
        postData(formToken, "/carrycot_framework_agreement/create", todoData),
      {
        onSuccess: (payload) => {
          queryClient.invalidateQueries("Carrycots");
          editCarrycot({
            ...carrycots.find((el) => el.id === payload.carrycot_profession_id),
            carrycot_included: payload,
            is_in_framework_agreement: true,
          });
        },
      }
    );

  const { mutate: deleteCarryFA, isLoading: isDelCarryFALoading } = useMutation(
    (todoData) =>
      postData(formToken, "/carrycot_framework_agreement/delete", todoData),
    {
      onSuccess: (payload) => {
        queryClient.invalidateQueries("Carrycots");
        editCarrycot({
          ...carrycots.find((el) => el.id === payload.carrycot_profession_id),
          carrycot_included: null,
          is_in_framework_agreement: false,
        });
      },
    }
  );

  useEffect(() => {
    editCarrycot(null);
    setCarrycotDetailsStatus("empty");
    debouncedSearch({ refetch });
  }, [wordEntered, refetch, editCarrycot]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const openCarrycotCreation = () => {
    editCarrycot(null);
    setCarrycotDetailsStatus("create");
  };

  const handleCarrycotFA = useCallback(
    (checked, carrycotProfession) => {
      if (isCreaCarryFALoading || isDelCarryFALoading) return;
      if (!checked) {
        createCarryFA({
          carrycot_profession_id: carrycotProfession.id,
          framework_agreement_id: faId,
        });
      } else {
        deleteCarryFA({
          carrycot_profession_id: carrycotProfession.id,
          id: carrycotProfession.carrycot_included.id,
        });
      }
    },
    [
      isCreaCarryFALoading,
      faId,
      isDelCarryFALoading,
      createCarryFA,
      deleteCarryFA,
    ]
  );

  const handleCarrycotOpportunity = useCallback(
    (checked, id) => {
      if (isCreaCarryOppLoading || isDelCarryOppLoading) return;
      if (!checked) {
        createCarrycotOpportunity({
          carrycot_profession_id: id,
          opportunity_id: opportunityId,
        });
      } else {
        deleteCarryOpportunity({
          carrycot_profession_id: id,
          opportunity_id: opportunityId,
        });
      }
    },
    [
      isCreaCarryOppLoading,
      opportunityId,
      isDelCarryOppLoading,
      createCarrycotOpportunity,
      deleteCarryOpportunity,
    ]
  );

  const handleFAAndOpp = useCallback(
    (checked, carrycotProfession) => {
      if (from === "referencing")
        handleCarrycotOpportunity(checked, carrycotProfession.id);
      else handleCarrycotFA(checked, carrycotProfession);
    },
    [handleCarrycotOpportunity, handleCarrycotFA, from]
  );

  return (
    <Container>
      <div className="main_admin-materiels">
        <TitleContainer label="Nacelles disponibles">
          {(from !== "frameworkAgreement" ||
            (from === "frameworkAgreement" && faIsValidate)) && (
            <AddButton
              onClick={openCarrycotCreation}
              disabled={carrycotDetailsStatus === "create"}
              label="Nacelle"
            />
          )}
        </TitleContainer>
        <StyledSearch
          allowClear
          placeholder="Rechercher par nom de nacelle"
          value={wordEntered}
          onChange={handleFilter}
        />
        <Separator isHorizontal size={3} />
        <div>
          <CarrycotArray
            carrycots={carrycots}
            carrycotDetailsStatus={carrycotDetailsStatus}
            setCarrycotDetailsStatus={setCarrycotDetailsStatus}
            profils={profils}
            entityWorkId={entityWorkId}
            from={from}
            handleFAAndOpp={handleFAAndOpp}
            faIsValidate={faIsValidate}
          />
        </div>
      </div>
      <Separator />
      <CarrycotDetails
        profils={profils}
        setCarrycotDetailsStatus={setCarrycotDetailsStatus}
        carrycotDetailsStatus={carrycotDetailsStatus}
        entityWorkId={entityWorkId}
        from={from}
        opportunityId={opportunityId}
        handleFAAndOpp={handleFAAndOpp}
        faIsValidate={faIsValidate}
        faId={faId}
        carrycots={carrycots}
      />
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
  width: 100%;
`;

const StyledSearch = styled(Search)``;

export default Nacelles;
