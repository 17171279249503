import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useDrop } from "react-dnd";
import { useMutation, useQueryClient } from "react-query";
import { Modal } from "antd";
import SATableTree, {
  constructDocumentRow,
} from "../../../documents-and-simplified/simplified-application/SATableTree";
import { useStore } from "../../../store";
import {
  findIsRootItem,
  findItemParentIdById,
  findItemPositionById,
  findRootItemIdx,
  getNewRootItem,
  reconstructRecord,
} from "../../../react-ui/TableTree/treeutils";
import { postData } from "../../../request/instance";
import BaseIcon from "../../../react-ui/Icons/BaseIcon";
import SearchTree from "./SearchTree";
import { useConfigurationWork } from "../../../../hooks/useConfigurationWork";

const selector = (state) => ({
  formToken: state.formToken,
  vocabulary: state.vocabulary,
});

const folderIcon = ({ expanded }) =>
  expanded ? (
    <NodeIcon className="fa-duotone fa-folder-open" />
  ) : (
    <NodeIcon className="fa-duotone fa-folder" />
  );

function TemplateTree({
  status,
  initialValues,
  settableWorks,
  companyWorks,
  templateId,
  setStatus,
}) {
  const queryClient = useQueryClient();
  const { formToken, vocabulary } = useStore(selector);
  const [data, setData] = useState([]);
  const [nodeToSearch, setNodeToSearch] = useState();
  const recordId = useRef(0);
  const { configuration } = useConfigurationWork();

  const { mutate: createTemplateNode } = useMutation(
    (todo) => postData(formToken, "/sa_template_node/create", todo),
    {
      onSuccess: () => queryClient.invalidateQueries("Templates"),
      onError: () => setStatus("reload"),
    }
  );

  const { mutate: updateTemplateNode } = useMutation(
    (todo) => postData(formToken, "/sa_template_node/update", todo),
    {
      onSuccess: () => queryClient.invalidateQueries("Templates"),
      onError: () => setStatus("reload"),
    }
  );

  const { mutate: deleteTemplateNode } = useMutation(
    (todo) => postData(formToken, "/sa_template_node/delete", todo),
    {
      onSuccess: () => queryClient.invalidateQueries("Templates"),
      onError: () => setStatus("reload"),
    }
  );

  const isUpdatingTemplate = useMemo(
    () => status === "update" && !!initialValues,
    [initialValues, status]
  );

  const constructNodeRow = useCallback(
    (node) => {
      if (node.node_type === "folder" && node.folder_id === null) {
        return {
          id: node.id,
          name: node.name,
          key: `folder-new-${node.id}`,
          icon: folderIcon,
          type: "folder-new",
          isLeaf: false,
          fromTemplate: true,
        };
      }
      if (node.node_type === "folder" && node.folder_id) {
        return {
          ...node.folder,
          id: node.id,
          name: node.name,
          key: `folder-${node.id}`,
          icon: folderIcon,
          type: "folder",
          folder_id: node.folder_id,
          isLeaf: false,
          fromTemplate: true,
        };
      }
      if (node.node_type === "admin_file") {
        const newFile = constructDocumentRow({
          document: node.document,
          folders: [],
          siteTypologies: [],
        });
        return {
          ...newFile,
          id: node.id,
          name: node.name,
          type: node.node_type,
          key: `document-${node.id}`,
        };
      }
      if (node.node_type === "product_files") {
        return {
          id: node.id,
          name: `Fiches produits des ${vocabulary.products}`,
          key: `product-files-${node.id}`,
          icon: <NodeIcon className={configuration.general.product_icon} />,
          type: node.node_type,
          isLeaf: true,
          notEdittable: true,
        };
      }
      if (node.node_type === "prestation_files") {
        return {
          id: node.id,
          name: `Fiches produits des ${vocabulary.prestations}`,
          key: `product-files-${node.id}`,
          icon: <NodeIcon className={configuration.general.prestation_icon} />,
          type: node.node_type,
          isLeaf: true,
          notEdittable: true,
        };
      }
      if (node.node_type === "file_rule") {
        return {
          id: node.id,
          name: `${node.file_rule.name}`,
          additionalName: (
            <AdditionalName>
              (dans{" "}
              {node.file_rule.folder
                ? `le dossier ${node.file_rule.folder.name}`
                : "offres et opportunités"}
              )
            </AdditionalName>
          ),
          key: `file-rule-${node.id}`,
          icon: <NodeIcon className="fa-duotone fa-file-circle-exclamation" />,
          type: node.node_type,
          isLeaf: true,
          notEdittable: true,
        };
      }
      if (node.node_type === "opportunity_folders") {
        return {
          id: node.id,
          name: "Dossiers des opportunités",
          key: `opportunity-folders-${node.id}`,
          icon: <NodeIcon className="fa-duotone fa-folder-tree" />,
          type: node.node_type,
          isLeaf: false,
          notEdittable: true,
          fromTemplate: true,
        };
      }
      return {};
    },
    [vocabulary, configuration]
  );

  useEffect(() => {
    if (isUpdatingTemplate) {
      setData(() =>
        initialValues.root_nodes.map((node) => constructNodeRow(node))
      );
    }
  }, [constructNodeRow, isUpdatingTemplate, initialValues]);

  const companyWorkIds = useMemo(
    () =>
      isUpdatingTemplate
        ? initialValues.sa_template_company_works
            .map((el) => el.company_work_id)
            .filter((cwId) =>
              companyWorks.some(
                (cw) =>
                  cw.id === cwId &&
                  settableWorks.some((w) => w.id === cw.work_id)
              )
            )
        : [],
    [
      companyWorks,
      initialValues?.sa_template_company_works,
      isUpdatingTemplate,
      settableWorks,
    ]
  );

  const moveRow = useCallback(
    (_, __, { record, hoverRecord, direction }) => {
      const draggedRecord = record;

      const deleteDraggedRecordFromOldPostion = (newData, oldData) => {
        const newDataWithoutRecord = [...newData];
        const isRootItem = findIsRootItem(draggedRecord);
        const rootItemIdx = findRootItemIdx(oldData, draggedRecord);

        if (isRootItem) {
          newDataWithoutRecord.splice(rootItemIdx, 1);
        } else {
          newDataWithoutRecord[rootItemIdx] = getNewRootItem({
            item: newDataWithoutRecord[rootItemIdx],
            record: draggedRecord,
          });
        }
        return newDataWithoutRecord;
      };

      const addDraggedRecordToNewPosition = (newData) => {
        const newDataWithRecord = [...newData];
        if (hoverRecord.type === "no-data") {
          const isRootItem = findIsRootItem(hoverRecord.parent);
          const folderIdx = findRootItemIdx(newData, hoverRecord.parent);
          if (isRootItem) {
            newDataWithRecord[folderIdx] = reconstructRecord({
              oldRecord: {
                ...newDataWithRecord[folderIdx],
                children: [draggedRecord],
              },
            });
          } else {
            newDataWithRecord[folderIdx] = reconstructRecord({
              oldRecord: getNewRootItem({
                item: newDataWithRecord[folderIdx],
                record: hoverRecord.parent,
                deleteItem: false,
                recordsToReplace: [draggedRecord],
              }),
            });
          }
          return newDataWithRecord;
        }
        const isHoverRootItem = findIsRootItem(hoverRecord);
        const hoverRootItemIdx = findRootItemIdx(newData, hoverRecord);
        if (isHoverRootItem) {
          newDataWithRecord.splice(
            direction === "downward" ? hoverRootItemIdx + 1 : hoverRootItemIdx,
            0,
            { ...draggedRecord, parentKeys: undefined }
          );
        } else {
          newDataWithRecord[hoverRootItemIdx] = reconstructRecord({
            oldRecord: getNewRootItem({
              item: newDataWithRecord[hoverRootItemIdx],
              record: hoverRecord,
              deleteItem: false,
              direction,
              recordToAdd: draggedRecord,
            }),
          });
        }
        return newDataWithRecord;
      };
      setData((oldData) => {
        let newData = [...oldData];
        newData = deleteDraggedRecordFromOldPostion(newData, oldData);
        newData = addDraggedRecordToNewPosition(newData);
        updateTemplateNode({
          id: draggedRecord.id,
          parent_id: findItemParentIdById({
            data: newData,
            id: draggedRecord.id,
          }),
          position: findItemPositionById({
            data: newData,
            id: draggedRecord.id,
            excludedNodes: ["all-files"],
          }),
        });
        return newData;
      });
    },
    [updateTemplateNode]
  );

  const draggableProps = useMemo(() => {
    return {
      canDropFunction: (_, __, { record, hoverRecord }) => {
        return (
          record.key !== hoverRecord.key &&
          (!hoverRecord.parentKeys ||
            !hoverRecord.parentKeys.includes(record.key))
        );
      },
      type: "templateItem",
      accept: ["templateItem"],
      moveRow,
    };
  }, [moveRow]);

  // eslint-disable-next-line no-unused-vars
  const [_, drop] = useDrop(
    () => ({
      accept: "nothing",
      drop: async ({ record }, monitor) => {
        if (monitor.isOver({ shallow: true })) {
          setData((oldData) => {
            return [
              reconstructRecord({
                oldRecord: record,
                recordId: recordId.current,
              }),
              ...oldData,
            ];
          });
          recordId.current += 1;
        }
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    []
  );

  const createNewFolder = useCallback(
    ({ newFolder, setFolderInTree, fromHeader, record }) => {
      createTemplateNode(
        {
          name: newFolder.name,
          position: 0,
          sa_template_id: templateId,
          parent_id: record?.id,
          node_type: "folder",
        },
        {
          onSuccess: (payload) => {
            setFolderInTree({
              fromHeader,
              newFolder: {
                ...newFolder,
                id: payload.id,
                key: `folder-new-${payload.id}`,
                type: "folder-new",
              },
              record,
            });
          },
        }
      );
    },
    [createTemplateNode, templateId]
  );

  const createNewFile = useCallback(
    ({ document, record, setFileInTree }) => {
      createTemplateNode(
        {
          name: document.name,
          position: 0,
          document_id: document.id,
          sa_template_id: templateId,
          parent_id: record?.id,
          node_type: "admin_file",
        },
        {
          onSuccess: (payload) => {
            setFileInTree(document, payload);
          },
        }
      );
    },
    [createTemplateNode, templateId]
  );

  const onBlurName = useCallback(
    ({ id, name, extension }) => {
      if (name !== "")
        updateTemplateNode({
          id,
          name: name + extension,
        });
    },
    [updateTemplateNode]
  );

  const onDeleteNode = useCallback(
    ({ id }) => {
      deleteTemplateNode({
        id,
      });
    },
    [deleteTemplateNode]
  );

  const onClickSearchIcon = useCallback(
    ({ event, record, fromHeader, setFoldersAndFilesInTree, from }) => {
      event.stopPropagation();
      setNodeToSearch({
        from,
        node: record,
        fromHeader,
        setFoldersAndFilesInTree,
      });
    },
    []
  );

  const onAnnexAdd = useCallback(
    ({ event, record, fromHeader, setFoldersAndFilesInTree, type }) => {
      event.stopPropagation();
      createTemplateNode(
        {
          name: "",
          position: 0,
          sa_template_id: templateId,
          parent_id: record?.id,
          node_type: type,
        },
        {
          onSuccess: (payload) => {
            setFoldersAndFilesInTree({
              fromHeader,
              newFoldersFiles: [constructNodeRow(payload)],
              record,
            });
          },
        }
      );
    },
    [constructNodeRow, createTemplateNode, templateId]
  );

  const closeModal = useCallback(() => {
    setNodeToSearch();
  }, []);

  const onFolderOpportunityAdd = useCallback(
    ({ event, record, fromHeader, setFoldersAndFilesInTree }) => {
      event.stopPropagation();
      createTemplateNode(
        {
          name: "Dossiers d'opportunité",
          position: 0,
          sa_template_id: templateId,
          parent_id: record?.id,
          node_type: "opportunity_folders",
        },
        {
          onSuccess: (payload) => {
            setFoldersAndFilesInTree({
              fromHeader,
              newFoldersFiles: [constructNodeRow(payload)],
              record,
            });
          },
        }
      );
    },
    [constructNodeRow, createTemplateNode, templateId]
  );

  return (
    <Container>
      <StyledModal
        open={!!nodeToSearch}
        maskClosable={false}
        footer={null}
        width={1000}
        destroyOnClose
        onCancel={closeModal}
        closable={false}
      >
        <SearchTree
          nodeToSearch={nodeToSearch}
          closeModal={closeModal}
          templateId={templateId}
          setStatus={setStatus}
          constructNodeRow={constructNodeRow}
        />
      </StyledModal>
      {status === "create" && (
        <p className="my-0">
          Créez le modèle pour avoir accès à son organisation
        </p>
      )}
      {isUpdatingTemplate && (
        <SATableTree
          data={data}
          setFilters={() => null}
          folders={[]}
          siteTypologies={[]}
          columnsToShow={[]}
          setData={setData}
          formToken={formToken}
          drop={drop}
          backgroundColor=""
          draggableProps={draggableProps}
          editable
          tableHeight={460}
          companyWorkIds={companyWorkIds}
          createNewFolder={createNewFolder}
          constructNodeRow={constructNodeRow}
          createNewFile={createNewFile}
          withFile={false}
          onBlur={onBlurName}
          onDelete={onDeleteNode}
          withSearch
          withAnnex
          withOpportunityFolders
          onFolderOpportunityAdd={onFolderOpportunityAdd}
          onClickSearchIcon={onClickSearchIcon}
          onAnnexAdd={onAnnexAdd}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
`;

const NodeIcon = styled(BaseIcon)`
  color: ${({ theme }) => theme.colors.blue11};
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 2.5px !important;
    padding: 0 0 24px 0 !important;
    height: 600px;
    background-color: #f5f6f8;
  }
  .ant-modal-body {
    height: 100%;
  }

  .ant-tabs {
    width: auto !important;
  }
  .ant-tabs,
  .ant-tabs-top,
  .ant-tabs-card {
    position: relative !important;
  }
  .ant-tabs-content-holder {
    overflow-y: hidden !important;
  }
`;

const AdditionalName = styled.span`
  font-style: italic;
`;

export default TemplateTree;
