import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['add_item', 'template', 'opportunityid', 'previous', 'next', 'save', 'new']
  add_association(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforeend', content)
  }

  remove_association(event) {
      event.preventDefault()
      let item = event.target.closest('tr')
      item.remove()
  }

  remove_wp(event) {
    event.preventDefault()
    let item = event.target.closest('div.work_position_fields')
    item.style.display = 'none'
    item.querySelector('.destroy-hidden-field').value = "true"
  }

  disabled_breadcrumb(){
    let breadcrumbs = document.getElementsByClassName('breadcrumb')
    let breadcrumbs_separators = document.getElementsByClassName('breadcrumb-separator')
    let breadcrumbs_selected = document.getElementsByClassName('breadcrumb-selected')
    let breadcrumbs_clicked = document.getElementsByClassName('breadcrumb-clicked')
    let breadcrumbs_separators_clicked = document.getElementsByClassName('breadcrumb-separator-clicked')
    let breadcrumbs_selected_clicked = document.getElementsByClassName('breadcrumb-selected-clicked')
    breadcrumbs.forEach (breadcrumb => breadcrumb.hidden=true)
    breadcrumbs_separators.forEach (breadcrumb_separator => breadcrumb_separator.hidden=true)
    breadcrumbs_selected.forEach (breadcrumb_selected => breadcrumb_selected.hidden=true)
    breadcrumbs_clicked.forEach (breadcrumb_clicked => breadcrumb_clicked.hidden=false)
    breadcrumbs_separators_clicked.forEach (breadcrumb_separator_clicked => breadcrumb_separator_clicked.hidden=false)
    breadcrumbs_selected_clicked.forEach (breadcrumb_selected_clicked => breadcrumb_selected_clicked.hidden=false)
  }

  disabled_buttons_next() {
    let button_next = this.nextTarget;
    let button_save = this.saveTarget;
    let button_new = this.newTarget;
    // this.disabled_breadcrumb()
    button_next.disabled = true
    button_save.hidden = true
    button_new.setAttribute('class', 'disabled');
  }

  disabled_buttons_previous() {
    let button_previous = this.previousTarget;
    let button_save = this.saveTarget;
    let button_new = this.newTarget;
    // this.disabled_breadcrumb()
    button_previous.disabled = true
    button_save.hidden = true
    button_new.setAttribute('class', 'disabled');
  }

  disabled_buttons_save() {
    let workposition_new = document.getElementById('work_position_fields')
    let workposition_created = document.getElementById('work_position_created')
    let button_previous = this.previousTarget;
    let button_next = this.nextTarget;
    let button_new = this.newTarget;
    // this.disabled_breadcrumb()
    if (workposition_new != null || workposition_created != null){
    button_previous.disabled = true
    button_next.disabled = true
    button_new.setAttribute('class', 'disabled');
    }
  }

  disabled_buttons(){
    let button_next = this.nextTarget;
    let button_previous = this.previousTarget;
    let button_save = this.saveTarget;
    let button_new = this.newTarget;
    // this.disabled_breadcrumb()
    button_next.disabled = true
    button_previous.disabled = true
    button_save.hidden = true
    button_new.setAttribute('class', 'disabled');
  }
}
