import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import MaterialsForm from "../../../Admin-cf/Materials/MaterialsForm";
import { useLazyQuery } from "../../../../hooks/useLazyQuery";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import { fetchMaterialByIdUrl } from "../../../../utils/fetchMaterialsUrl";
import { fetchPrestationByIdUrl } from "../../../../utils/fetchPrestationsUrl";
import PrestationForm from "../../../Admin-cf/Prestations/PrestationForm";
import DetailsOuvrage from "./DetailsOuvrage";

const selector = (state) => ({
  formToken: state.formToken,
});

function ModalItemShow({
  setModalType,
  modalType,
  itemToShowId,
  setItemToShowId,
  categories,
  unities,
  manufacturers,
  distributors,
  showFaItems,
  profils,
  configuration,
  vocabulary,
  opportunityId,
}) {
  const { formToken } = useStore(selector);

  const from = showFaItems ? "frameworkAgreement" : "admin";

  const [selectedItem, setSelectedItem] = useState(null);

  const [fetchMaterial] = useLazyQuery(
    ["Material", { id: itemToShowId }],
    () =>
      getData(
        formToken,
        fetchMaterialByIdUrl({
          from,
          id: itemToShowId,
        })
      ),
    {
      onSuccess: (payload) => {
        setSelectedItem(payload);
      },
    }
  );

  const [fetchOuvrage] = useLazyQuery(
    ["Ouvrage", { id: itemToShowId }],
    // hardcode id ouvrage 7937
    () => getData(formToken, `/ouvrage/${itemToShowId}`),
    {
      onSuccess: (payload) => {
        setSelectedItem(payload);
      },
    }
  );

  const [fetchOuvrageProfession] = useLazyQuery(
    ["Ouvrage", { id: itemToShowId }],
    // hardcode id ouvrage 7937
    () => getData(formToken, `/ouvrage_profession/${itemToShowId}`),
    {
      onSuccess: (payload) => {
        setSelectedItem(payload);
      },
    }
  );

  const [fetchPrestation] = useLazyQuery(
    ["Prestation", { id: itemToShowId }],
    () =>
      getData(
        formToken,
        fetchPrestationByIdUrl({
          from,
          id: itemToShowId,
        })
      ),
    {
      onSuccess: (payload) => {
        setSelectedItem(payload);
      },
    }
  );

  const handleCancelModal = () => {
    setModalType(null);
    setItemToShowId(null);
    setSelectedItem(null);
  };

  useEffect(() => {
    if (modalType === "material") fetchMaterial();
    if (modalType === "prestation") fetchPrestation();
    if (modalType === "ouvrage_batiprix") fetchOuvrage();
    if (modalType === "ouvrage_smart4") fetchOuvrageProfession();
  }, [
    itemToShowId,
    modalType,
    fetchMaterial,
    fetchPrestation,
    fetchOuvrage,
    fetchOuvrageProfession,
  ]);

  return (
    <Modal
      width={840}
      maskClosable={false}
      open={modalType !== null && selectedItem !== null}
      footer={null}
      onCancel={handleCancelModal}
    >
      {modalType === "prestation" && (
        <>
          <h3>Information de la prestation</h3>
          <PrestationForm
            initialPrestation={selectedItem}
            categories={categories}
            unities={unities}
            profils={profils}
            from={from}
            faIsValidate={showFaItems}
            hasPrestationIncluded={
              selectedItem?.prestation_included !== null &&
              selectedItem?.prestation_included !== undefined
            }
            isShowing
            configuration={configuration}
            vocabulary={vocabulary}
            opportunityId={opportunityId}
          />
        </>
      )}
      {modalType === "material" && (
        <>
          <h3>Information du matériel</h3>
          <MaterialsForm
            initialMaterial={selectedItem}
            categories={categories}
            unities={unities}
            manufacturers={manufacturers}
            distributors={distributors}
            isShowing
            from={from}
            hasMaterialIncluded={
              selectedItem?.material_included !== null &&
              selectedItem?.material_included !== undefined
            }
            faIsValidate={showFaItems}
            configuration={configuration}
            vocabulary={vocabulary}
            opportunityId={opportunityId}
          />
        </>
      )}
      {(modalType === "ouvrage_batiprix" || modalType === "ouvrage_smart4") && (
        <>
          <h3>Information de l'ouvrage</h3>

          <DetailsOuvrage
            selectedItem={selectedItem}
            configuration={configuration}
            ouvrage_type={modalType}
          />
        </>
      )}
    </Modal>
  );
}

export default ModalItemShow;
