// add background to every same row when we click on one formation for both tables

$(document).ready(() => {
const indexFormationWorkpostion = document.getElementById("formations-workpositions")
if(!indexFormationWorkpostion){
  return
}
const formationsNameAccordion =  document.querySelectorAll(".formation-name a");

if (formationsNameAccordion) {
  const removeBackgroundSecond = () => {
    formationsNameAccordion.forEach(formation => {
      formation.parentNode.parentNode.classList.remove("grey-background");
    })
  }

  const addBackgroundSecond = (event) => {
    removeBackgroundSecond()
    event.currentTarget.parentNode.parentNode.classList.add("grey-background");
    const divsArr = Array.from(formationsNameAccordion);
    const result = divsArr.filter(formation => formation.innerText === event.currentTarget.innerText);
    result.forEach(formation => {
      formation.parentNode.parentNode.classList.add("grey-background");
    })
  }

  formationsNameAccordion.forEach(formation => {
    formation.addEventListener("click", addBackgroundSecond)
  })


  validateNumbersOnly = (event) => {
    event.currentTarget.value= event.currentTarget.value.replace(/\./g, ',')
    let nbCommas = (event.currentTarget.value.match(/,/g) || []).length
    if (nbCommas > 1){
      let commaPosition = event.currentTarget.value.indexOf(",")
      let beforeComma = event.currentTarget.value.substring(0,commaPosition+1)
      let afterComma = event.currentTarget.value.substring(commaPosition +1, event.currentTarget.value.length)
      afterComma = afterComma.replace(/\,/g, '')
      let newString=beforeComma + afterComma
      event.currentTarget.value=newString
    }
  event.currentTarget.value= event.currentTarget.value.replace(/[^0-9 ,]/g, '')
  }
}
})
