import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, Modal, Select, Switch } from "antd";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { useMutation } from "react-query";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import TitleContainer from "../../../react-ui/TitleContainer";
import AddButton from "../../../react-ui/AddButton";
import MaterialsForm from "../../../Admin-cf/Materials/MaterialsForm";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import PrestationForm from "../../../Admin-cf/Prestations/PrestationForm";
import useTodo from "../../../hookQuery/useTodo";
import TreeSelect from "../../../react-ui/TreeSelect";
import {
  createMaterialProfessionForm,
  createPrestationProfessionForm,
} from "../../../../utils/createFormData";
import ModalOuvrage from "./Section_ouvrage/ModalOuvrage";
import logo_smart4 from "../../../../../public/favicon.png";
import logo_batiprix from "../../../../../public/batiprix.ico";
import { getUrlParams } from "../../../utils";

const { Option } = Select;

const selector = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
  isUsingMinutes: state.isUsingMinutes,
  apiProvider: state.apiProvider,
});

const logoMappings = {
  smart4: logo_smart4,
  batiprix: logo_batiprix,
};
function generateApiProviderOptions(companyWork) {
  function getLogo(api_provider) {
    const logo = logoMappings[api_provider?.name];
    return logo || null;
  }
  return companyWork?.map((api_provider) => ({
    value: api_provider.name,
    label: (
      <div>
        <img
          style={{ width: 16, height: 16 }}
          src={getLogo(api_provider)}
          alt={api_provider.name}
        />{" "}
        {api_provider.name}
      </div>
    ),
  }));
}

function ListHeader({
  setOuvrageIdSelected,
  ouvrageIdSelected = null,
  isModalFormVisible,
  setIsModalFormVisible,
  typeForm,
  setTypeForm,
  setFilterOuvrageSmart,
  setOuvrageProvider,
  ouvrageProvider,
  wordEntered,
  setWordEntered,
  wordEnteredOuvrage,
  setWordEnteredOuvrage,
  setWordEnteredOuvrageSmart,
  wordEnteredOuvrageSmart,
  filters,
  setFilters,
  compatibleMaterials,
  setCompatibleMaterials,
  categories,
  id_opportunity,
  resetMaterials,
  setPrestations,
  prestations,
  opportunityType,
  showFaItems,
  setShowFaItems,
  faId,
  opportunity_work,
  configuration,
  vocabulary,
  ouvrageMode,
  setSelectedNomenclature,
  selectedNomenclature,
  setExpendNomenclature,
  expendNomenclature,
  treeData,
  setCategoryOuvrage,
  setOuvragMode,
  defaultMinutes,
  company_work,
  from,
}) {
  const updateFilters = (value) => {
    setFilters(
      value.reduce((obj, item) => {
        obj[item] = true;
        return obj;
      }, {})
    );
  };

  useEffect(() => {
    updateFilters(configuration.costing.page_1.filter_default_values);
  }, [configuration.costing.page_1.filter_default_values]);

  const {
    formToken,
    isUsingMargin,
    calculMethod,
    isUsingMinutes,
    apiProvider,
  } = useStore(selector);

  const urlUnities =
    from !== "admin" && from !== "configuration"
      ? `/unity_by_opportunity?from=${from}&id=${id_opportunity}`
      : `/unity_by_opportunity?from=${from}&id=${getUrlParams()}`;

  const { data: unities } = useTodo(formToken, urlUnities, "Unities");

  const urlProfils =
    from !== "admin" && from !== "configuration"
      ? `/profil_by_opportunity?from=${from}&id=${id_opportunity}`
      : `/profil_by_opportunity?from=${from}&id=${getUrlParams()}`;

  const { data: profils } = useTodo(formToken, urlProfils, "Profils");

  const { mutate: createMaterials } = useMutation(
    (todo) => postData(formToken, "/material_profession/create", todo),
    {
      onSuccess: () => {
        resetMaterials();
      },
    }
  );

  const { mutate: createPrestation } = useMutation(
    (todo) => postData(formToken, "/prestation_profession/create", todo),
    {
      onSuccess: (payload) => {
        setPrestations([payload, ...prestations]);
      },
    }
  );

  const urlManufacturers =
    from !== "admin" && from !== "configuration"
      ? `/manufacturers?from=${from}&id=${id_opportunity}`
      : `/manufacturers?from=${from}&id=${getUrlParams()}`;

  const { data: manufacturers } = useTodo(
    formToken,
    urlManufacturers,
    "Manufacturers"
  );

  const urlDistributors =
    from !== "admin" && from !== "configuration"
      ? `/distributors?from=${from}&id=${id_opportunity}`
      : `/distributors?from=${from}&id=${getUrlParams()}`;

  const { data: distributors } = useTodo(
    formToken,
    urlDistributors,
    "Distributors"
  );

  const handleOpenFormModal = (type) => {
    setIsModalFormVisible(true);
    setTypeForm(type);
  };

  const handleCancelModalForm = () => {
    setIsModalFormVisible(false);
    setTypeForm(null);
    setOuvrageIdSelected(null);
  };

  const handleSearch = (event) => {
    if (ouvrageProvider === "batiprix") {
      const searchWord = event.target.value;
      setWordEnteredOuvrage(searchWord);

      if (searchWord === "") {
        setWordEnteredOuvrage("");
      }
      // if (ouvrageProvider === "smart") {
      //   const searchWord = event.target.value;
      //   setWordEnteredOuvrage(searchWord);

      //   if (searchWord === "") {
      //     setWordEnteredOuvrage("");
      //   }
      // }
    } else {
      const searchWord = event.target.value;
      setWordEntered(searchWord);

      if (searchWord === "") {
        clearInput();
      }
    }
  };

  const clearInput = () => {
    setWordEntered("");
  };

  const deselectMaterial = (e) => {
    const newCompatibleMaterials = compatibleMaterials.filter(
      (el) => el.id !== e
    );
    setCompatibleMaterials(newCompatibleMaterials);
  };

  const handleRegisterMaterialProfession = (e) => {
    const formData = createMaterialProfessionForm(
      e,
      isUsingMargin,
      calculMethod
    );
    formData.append("material_profession[opportunity_id]", id_opportunity);
    createMaterials(formData);
    setIsModalFormVisible(false);
  };

  const handleRegisterPrestationProfession = (e) => {
    const formData = createPrestationProfessionForm(
      e,
      profils.find((el) => el.id === e.profil_profession_id),
      isUsingMargin,
      calculMethod,
      isUsingMinutes
    );
    formData.append("prestation_profession[opportunity_id]", id_opportunity);
    createPrestation(formData);
    setIsModalFormVisible(false);
  };

  const handleChangeTable = (checked) => {
    setOuvragMode(checked);
  };

  const handleChangeProvider = (value) => {
    setOuvrageProvider(value);
  };

  const setFilterCategories = (value) => {
    setFilterOuvrageSmart(value);
  };

  const typeCascader = () => {
    // if (ouvrageProvider === "smart") {
    //   return (
    //     <Cascader
    //       style={{ margin: 0 }}
    //       cascader={{
    //         disabled: false,
    //         placeholder: "Sélectionner une catégorie",
    //         changeOnSelect: true,
    //         onChange: setFilterCategories,
    //       }}
    //       handleUpdateOnChange={() => {}}
    //       options={itemsToCascader({
    //         items: categories,
    //         keyParent: "parent_category_id",
    //         keyName: "title",
    //       })}
    //     />
    //   );
    // }
    if (ouvrageProvider === "batiprix") {
      return (
        <TreeSelect
          selectedNomenclature={selectedNomenclature}
          setSelectedNomenclature={setSelectedNomenclature}
          setExpendNomenclature={setExpendNomenclature}
          expendNomenclature={expendNomenclature}
          treeData={treeData}
          setCategoryOuvrage={setCategoryOuvrage}
          placeholder="Catégories des ouvrages"
          disabled={ouvrageProvider !== "batiprix"}
        />
      );
    }
    return null;
  };

  const apiProviderOptions = useCallback(
    () => generateApiProviderOptions(apiProvider),
    [apiProvider]
  );

  return (
    <>
      <Modal
        open={isModalFormVisible}
        footer={null}
        maskClosable={false}
        width={1000}
        onCancel={handleCancelModalForm}
      >
        {typeForm === "materials" && (
          <MaterialsForm
            submit={{ onCancel: () => setIsModalFormVisible(false) }}
            categories={categories}
            unities={unities}
            distributors={distributors}
            manufacturers={manufacturers}
            onSubmit={handleRegisterMaterialProfession}
            entity_work_name={opportunity_work}
            configuration={configuration}
            vocabulary={vocabulary}
            opportunityId={id_opportunity}
            use_special_tva={company_work?.management_special_TVA}
          />
        )}
        {typeForm === "prestations" && (
          <PrestationForm
            submit={{ onCancel: () => setIsModalFormVisible(false) }}
            profils={profils}
            categories={categories}
            unities={unities}
            onSubmit={handleRegisterPrestationProfession}
            from="opportunity"
            opportunityType={opportunityType}
            initialPrestation={{
              maintenance: opportunityType === "maintenance",
            }}
            isCreating
            entity_work_name={opportunity_work}
            configuration={configuration}
            vocabulary={vocabulary}
            defaultMinutes={defaultMinutes}
            opportunityId={id_opportunity}
            use_special_tva={company_work?.management_special_TVA}
          />
        )}
        {typeForm === "ouvrages" && (
          <ModalOuvrage
            categories={categories}
            formToken={formToken}
            configuration={configuration}
            vocabulary={vocabulary}
            unities={unities}
            opportunity_id={id_opportunity}
            ouvrageId={ouvrageIdSelected}
            opportunityType={opportunityType}
          />
        )}
      </Modal>
      {/* Test de nouveau titre */}
      {/* <TitleContainer label={`${vocabulary.products} et prestations`}> */}
      <TitleContainer label="Liste des éléments">
        {ouvrageProvider === "batiprix" ? (
          <div />
        ) : (
          <>
            {" "}
            {configuration.ouvrage && (
              <StyledAddButton
                label="Ouvrage"
                onClick={() => handleOpenFormModal("ouvrages")}
                disabled={from === "configuration"}
              />
            )}
            <StyledAddButton
              label={`${vocabulary.product}`}
              onClick={() => handleOpenFormModal("materials")}
              disabled={from === "configuration"}
            />
            <StyledAddButton
              label="Prestation"
              onClick={() => handleOpenFormModal("prestations")}
              disabled={from === "configuration"}
            />
          </>
        )}
      </TitleContainer>
      <HeaderContainer className="container__header">
        {ouvrageProvider !== "smart4" ? (
          <StyledSearch
            allowClear
            placeholder={`Rechercher par ouvrages ${ouvrageProvider}`}
            value={wordEnteredOuvrage}
            onChange={handleSearch}
          />
        ) : (
          <StyledSearch
            allowClear
            placeholder={`Rechercher par ${vocabulary.product_lowercase}, prestations, etc...`}
            value={wordEntered}
            onChange={handleSearch}
          />
        )}
        {(apiProvider?.length > 1 ||
          configuration.general.compatibility === true) && (
          <FilterContainer>
            {apiProvider?.length > 1 && from !== "admin" && (
              <div>
                <Select
                  placeholder="Choisissez votre fournisseur"
                  onChange={handleChangeProvider}
                  defaultValue={
                    configuration.ouvrage_option.ouvrage_default
                      ? "batiprix"
                      : "smart4"
                  }
                  style={{ marginRight: "5px", width: "120px" }}
                  options={apiProviderOptions()}
                />
              </div>
            )}
            {configuration.general.compatibility &&
              ouvrageProvider === "smart4" && (
                <CompatibilityContainer>
                  <FilterText>Filtres de compatibilité</FilterText>
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Cliquez sur l’icône “Filtre” du matériel souhaité"
                    value={compatibleMaterials?.map((el) => el.id)}
                    optionLabelProp="label"
                    onDeselect={(e) => deselectMaterial(e)}
                  >
                    {compatibleMaterials?.map((associateArray) => (
                      <Option
                        value={associateArray.id}
                        label={associateArray.name}
                        key={associateArray.id}
                      >
                        <div>{associateArray.name}</div>
                      </Option>
                    ))}
                  </Select>
                </CompatibilityContainer>
              )}
          </FilterContainer>
        )}
        {ouvrageProvider !== "smart4" && (
          <CategoriesContainer>
            <FilterText>Filtre de catégories</FilterText>
            {typeCascader()}
          </CategoriesContainer>
        )}
        {ouvrageProvider === "smart4" && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                width: "100%",
                height: "53px",
              }}
            >
              <Select
                mode="multiple"
                style={{
                  width: "100%",
                }}
                options={[
                  { label: vocabulary.products, value: "materials" },
                  { label: "Prestations", value: "prestations" },
                  { label: "Sous-traitance", value: "subcontractings" },
                  ...(configuration.group
                    ? [{ label: vocabulary.groups, value: "groups" }]
                    : []),
                  ...(configuration.ouvrage
                    ? [
                        {
                          label: vocabulary.ouvrages,
                          value: "ouvrages",
                        },
                      ]
                    : []),
                ]}
                defaultValue={configuration.costing.page_1.filter_default_values.filter(
                  (item) => {
                    if (item === "ouvrages" && configuration.ouvrage) {
                      return true;
                    }
                    if (item === "groups" && configuration.group) {
                      return true;
                    }
                    return item !== "ouvrages" && item !== "groups";
                  }
                )}
                maxTagCount="responsive"
                allowClear
                onChange={(value) => {
                  setFilters(
                    value.reduce((obj, item) => {
                      // eslint-disable-next-line no-param-reassign
                      obj[item] = true;
                      return obj;
                    }, {})
                  );
                }}
              />
            </div>
            {faId && (
              <SwitchContainer>
                <SwitchText>Hors accord-cadre</SwitchText>
                <div>
                  <Switch
                    checked={!showFaItems}
                    size="small"
                    onChange={(e) => setShowFaItems(!e)}
                    unCheckedChildren={<EyeInvisibleOutlined />}
                    checkedChildren={<EyeOutlined />}
                  />
                </div>
              </SwitchContainer>
            )}
          </>
        )}
      </HeaderContainer>
    </>
  );
}

const StyledAddButton = styled(AddButton)`
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const StyledSearch = styled(Search)`
  margin-bottom: 5px;
`;

const CompatibilityContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

const CategoriesContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 53px;
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;
  height: 53px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const FilterText = styled.p`
  margin: 0 0 0 0;
  font-size: 14px !important;
  white-space: nowrap;
`;

const ShowText = styled.p`
  margin: 0 10px 0 0;
  font-size: 14px !important;
  white-space: nowrap;
`;

const CheckboxWrapper = styled.div`
  display: "flex";
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 2px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 10px;
`;

const SwitchText = styled.p`
  margin: 0 10px 0 0;
  font-size: 14px !important;
`;

export default ListHeader;
