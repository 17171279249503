import Search from "antd/lib/input/Search";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Modal, Checkbox } from "antd";
import { useQueryClient } from "react-query";
import LazyMaterialArray from "../../Materials/LazyMaterialArray";
import Eye from "../../../react-ui/Icons/Eye";
import { numberSorter } from "../../../../utils/numberSorter";
import { currency_cents_to_euros, getUrlParams } from "../../../utils";
import { formatNumberString } from "../../../../utils/formatNumberString";

function MaterialLinked({
  vocabulary,
  from,
  faIsValidate,
  faId,
  selectedGrouping,
  updateGrouping,
}) {
  const queryClient = useQueryClient();
  const [wordEntered, setWordEntered] = useState("");
  const [materials, setMaterials] = useState([]);
  const [tableRowsData, setTableRowData] = useState([]);

  useEffect(() => {
    queryClient.invalidateQueries("Materials");
  }, [selectedGrouping]);

  const clearInput = () => {
    setWordEntered("");
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };

  const checkIfMaterialIsInGrouping = useCallback(
    (grouping_id) => {
      if (
        grouping_id === selectedGrouping.id ||
        grouping_id === null ||
        grouping_id === undefined
      )
        return true;
      return false;
    },
    [selectedGrouping]
  );

  useEffect(() => {
    const dataResult = [];
    let child;
    materials?.forEach((value) => {
      child = {
        ...value,
        key: value.id,
        id: value.id,
        name: value.material_included
          ? value.material_included.name
          : value.name,
        price: formatNumberString({
          str: currency_cents_to_euros(value.cost_price_cents),
          nbDecimal: 2,
          space: true,
        }),
        is_in_framework_agreement:
          value.is_in_framework_agreement || value.material_included,
        margin: value.margin_rate,
        actions: undefined,
        category: value.category_profession?.title,
      };
      dataResult.push(child);
    });
    setTableRowData(dataResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materials, selectedGrouping]);
  const matCols = useMemo(
    () =>
      [
        {
          title: "Intitulé",
          dataIndex: "name",
          key: "name",
          sorter: true,
        },
        {
          title: "Regrouper",
          dataIndex: "grouping_id",
          width: "80px",
          align: "center",
          defaultSortOrder: "descend",
          sorter: (a, b) =>
            numberSorter(a.grouping_id, b.grouping_id) ||
            numberSorter(a.id, b.id),
          render: (grouping_id, record) => (
            <CenteredContainer>
              <Checkbox
                onChange={({ target: { checked } }) => {
                  updateGrouping(
                    {
                      id: selectedGrouping.id,
                      material_profession_id: record.id,
                      checked,
                    },
                    {
                      onSuccess: (grouping) => {
                        setTableRowData((oldTableRowData) => {
                          const newTableRowData = [...oldTableRowData];
                          const recordIndex = newTableRowData.findIndex(
                            (material) => material.id === record.id
                          );
                          newTableRowData[recordIndex] = {
                            ...record,
                            grouping_id: record.grouping_id
                              ? undefined
                              : grouping.id,
                            grouping_show_editor: false,
                          };
                          return newTableRowData;
                        });
                      },
                    }
                  );
                }}
                disabled={!checkIfMaterialIsInGrouping(grouping_id)}
                defaultChecked={
                  !(grouping_id === null || grouping_id === undefined)
                }
              />
            </CenteredContainer>
          ),
        },
        {
          title: "Visualiser dans Editor",
          dataIndex: "grouping_show_editor",
          width: "80px",
          align: "center",
          defaultSortOrder: "descend",
          render: (grouping_show_editor, record) => (
            <CenteredContainer>
              <Checkbox
                onChange={({ target: { checked } }) => {
                  updateGrouping(
                    {
                      id: selectedGrouping.id,
                      material_profession_id: record.id,
                      checked_show: checked,
                    },
                    {
                      onSuccess: () => {
                        setTableRowData((oldTableRowData) => {
                          const newTableRowData = [...oldTableRowData];
                          const recordIndex = newTableRowData.findIndex(
                            (material) => material.id === record.id
                          );
                          newTableRowData[recordIndex] = {
                            ...record,
                            grouping_show_editor: !record.grouping_show_editor,
                          };
                          return newTableRowData;
                        });
                      },
                    }
                  );
                }}
                checked={record.grouping_show_editor === true}
                disabled={
                  !checkIfMaterialIsInGrouping(record.grouping_id) ||
                  record.grouping_id === null ||
                  record.grouping_id === undefined
                }
              />
            </CenteredContainer>
          ),
        },
      ].filter((col) => !col.hidden),
    [selectedGrouping, updateGrouping]
  );
  return (
    <div>
      <StyledSearch
        allowClear
        placeholder={`Rechercher par ${vocabulary?.product_lowercase}, code article, etc...`}
        value={wordEntered}
        onChange={handleFilter}
      />
      <LazyMaterialArray
        columns={matCols}
        dataSource={tableRowsData}
        from={from}
        faIsValidate={faIsValidate}
        faId={faId}
        entityWorkId={getUrlParams()}
        materials={materials}
        setMaterials={setMaterials}
        wordEntered={wordEntered}
        parent="grouping"
        isForAssociates
        adminDetails
        groupingId={selectedGrouping.id}
      />
    </div>
  );
}
const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSearch = styled(Search)`
  margin: 10px 0;
`;
export default MaterialLinked;
