import React, { useState } from "react";
import { Tooltip } from "antd";
import styled from "styled-components";
import BaseIcon from "./Icons/BaseIcon";
import { copyInClipboard } from "../../utils/copyInClipboard";

function CopySecret({ secret, copyText, defaultIsHidden = false }) {
  const [isHovered, setIsHovered] = useState(false);
  const [isHidden, setIsHidden] = useState(defaultIsHidden);

  const onKeyDown = (e) => {
    if (e.key === "Enter") copySecret();
  };

  const copySecret = () => {
    if (isHidden) {
      setIsHidden(false);
    } else {
      copyInClipboard(secret);
    }
  };
  return (
    <Tooltip
      open={isHovered}
      placement="bottom"
      mouseLeaveDelay="0"
      title={isHidden ? "Cliquez pour afficher" : copyText}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 8,
          backgroundColor: isHovered ? "white" : "inherit",
          borderRadius: 4,
          padding: 2,
        }}
        role="button"
        tabIndex={0}
        onKeyDown={onKeyDown}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={copySecret}
      >
        <SecretCell>
          {isHidden ? "******************************" : secret}
        </SecretCell>
        <BaseIcon
          className={`fa-duotone ${isHidden ? "fa-eye" : "fa-copy"}`}
          $fontSize="14px"
        />
      </div>
    </Tooltip>
  );
}

const SecretCell = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default CopySecret;
