import { Form } from "antd";
import React, { useMemo } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { stringToFloat } from "../../../../utils/stringToFloat";
import { Loading } from "../../../loading";
import AddButton from "../../../react-ui/AddButton";
import Button from "../../../react-ui/Button";
import TitleContainer from "../../../react-ui/TitleContainer";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import RuleForm from "./RuleForm";

const selector = (state) => ({
  formToken: state.formToken,
});

function RuleDetails({
  ruleStatus,
  setRuleStatus,
  works,
  companyWorks,
  selectedRule,
  setSelectedRule,
  rules,
  siteTypologies,
  entities,
}) {
  const { formToken } = useStore(selector);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate: createRule } = useMutation(
    (todo) => postData(formToken, "/rule/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Rules");
      },
    }
  );

  const { mutate: updateRule, status } = useMutation(
    (todo) => postData(formToken, "/rule/update", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Rules");
      },
      onError: () => {
        setSelectedRule({ ...rules.find((el) => el.id === selectedRule.id) });
      },
    }
  );

  const closeDetails = () => {
    setRuleStatus();
    setSelectedRule();
  };

  const handleSubmit = (values) => {
    const {
      rule_on,
      value_to_compare,
      work_id,
      site_typology_ids,
      response_type,
      opportunity_type,
      entity_ids,
    } = values;

    const commonKeys = {
      entity_ids,
      site_typology_ids,
      rule: {
        rule_on,
        value_to_compare: stringToFloat(value_to_compare),
        response_type: response_type || null,
        opportunity_type: opportunity_type || null,
      },
    };

    if (ruleStatus === "create") {
      const companyWorkId = companyWorks.find(
        (cw) => work_id === cw.work_id
      ).id;
      createRule({ company_work_id: companyWorkId, ...commonKeys });
      closeDetails();
    } else if (ruleStatus === "update") {
      updateRule({
        id: selectedRule.id,
        ...commonKeys,
      });
    }
  };

  const initialValues = useMemo(() => {
    if (ruleStatus !== "update" || !selectedRule) return undefined;

    const ruleWorkId = companyWorks.find(
      (cw) => selectedRule.company_work_id === cw.id
    ).work_id;

    return {
      ...selectedRule,
      work_id: ruleWorkId,
    };
  }, [selectedRule, ruleStatus, companyWorks]);

  return (
    <Container>
      <TitleContainer label="Détails de la règle">
        {ruleStatus === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={closeDetails}
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="rule-form"
            />
          </>
        )}
        <Loading status={status} />
      </TitleContainer>
      {!ruleStatus && (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez une règle pour afficher les détails
          </p>
        </div>
      )}
      {!!ruleStatus && (
        <Wrapper>
          <RuleForm
            works={works}
            siteTypologies={siteTypologies}
            handleSubmit={handleSubmit}
            initialValues={initialValues}
            ruleStatus={ruleStatus}
            form={form}
            entities={entities}
          />
        </Wrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
`;

export default RuleDetails;
