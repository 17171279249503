import React from "react";
import styled from "styled-components";

function Label({ label, rules, className = "" }) {
  if (!label) return null;
  if (!rules || rules[0]?.required !== true)
    return <LabelText className={className}>{label}</LabelText>;
  return (
    <Container className={className}>
      <LabelText>{label}</LabelText>
      <StyledAbbr title="required">*</StyledAbbr>
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

const LabelText = styled.label``;

const StyledAbbr = styled.abbr`
  margin-left: 2px;
`;
export default Label;
