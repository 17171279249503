import React from "react";
import { bool, string } from "prop-types";
import styled from "styled-components";
import Button from "./Button";
import Update from "./Icons/Update";

function UpdateButton({
  label,
  className,
  loading,
  marginIcon,
  ...buttonProps
}) {
  return (
    <StyledButton
      className={className}
      loading={loading}
      marginIcon={marginIcon}
      {...buttonProps}
    >
      {!loading &&
        (marginIcon === "true" ? <StyledIcon /> : <StyledIconNoMargin />)}
      {label}
    </StyledButton>
  );
}

const StyledIcon = styled(Update)`
  margin-right: 0.313rem;
`;

const StyledIconNoMargin = styled(Update)`
  margin-right: 0px;
`;

const StyledButton = styled(Button)`
  padding: 0.3rem 0.5rem;
`;

UpdateButton.propTypes = {
  label: string,
  className: string,
  loading: bool,
  marginIcon: string,
};

UpdateButton.defaultProps = {
  label: "",
  className: "",
  loading: false,
  marginIcon: "true",
};

export default UpdateButton;
