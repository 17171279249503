import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useDrop, useDrag } from "react-dnd";
import { Select } from "antd";
import SelectComponent from "./SelectComponent";

function DropElement({
  label,
  elementsList,
  setElementsList,
  place,
  col,
  isSplit,
  element,
  elementRequired,
  dataJson,
  setDataJson,
  defaultTVA,
  handleDragIn,
  type,
  removeElement,
}) {
  const [name, setName] = useState(label[element]);
  const [mandatoryRequired, setMandatoryRequired] = useState(
    label[element] === "Intitulé" ||
      label[element] === "Client facturé" ||
      label[element] === "Agence concernée"
  );
  const [newType, setNewType] = useState(
    mandatoryRequired ? "mandatoryRequired" : type
  );

  const [required, setRequired] = useState(
    mandatoryRequired ? true : elementRequired
  );

  const source = {
    place,
    col,
    isSplit,
  };

  const [, drag] = useDrag(
    () => ({
      type: newType,
      item: {
        content: element,
        type: newType,
        required,
        source,
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [dataJson, elementsList, isSplit, element, mandatoryRequired, newType]
  );

  const [, drop] = useDrop(
    () => ({
      accept: [type, "mandatoryRequired"],
      drop: (item) => moveElement(item),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [dataJson, elementsList, isSplit, element, mandatoryRequired, newType]
  );

  const moveElement = (item) => {
    if (item?.source?.place === place && item?.source?.col === col) {
      return;
    }

    handleDragIn(place, col, isSplit, item, newType);
    if (newType === "mandatoryRequired") {
      return;
    }

    setName(label[item.content]);
  };

  const handleDelete = () => {
    if (mandatoryRequired) {
      return;
    }
    setElementsList((prev) => [...prev, element]);

    removeElement(source, type);
  };

  const handleChange = useCallback(
    (value) => {
      setRequired(value === "required");

      const [place_col, line] = place.split("-");

      if (type === "mandatory") {
        if (col === 1) {
          setDataJson((prevState) => ({
            ...prevState,
            mandatory: {
              ...prevState.mandatory,
              [place_col]: {
                ...prevState.mandatory[place_col],
                [line]: {
                  ...prevState.mandatory[place_col][line],
                  required: [
                    prevState.mandatory[place_col][line].required[0],
                    value === "required",
                  ],
                },
              },
            },
          }));
        } else if (col === 0 && isSplit) {
          setDataJson((prevState) => ({
            ...prevState,
            mandatory: {
              ...prevState.mandatory,
              [place_col]: {
                ...prevState.mandatory[place_col],
                [line]: {
                  ...prevState.mandatory[place_col][line],
                  required: [
                    value === "required",
                    prevState.mandatory[place_col][line].required[1],
                  ],
                },
              },
            },
          }));
        } else {
          setDataJson((prevState) => ({
            ...prevState,
            mandatory: {
              ...prevState.mandatory,
              [place_col]: {
                ...prevState.mandatory[place_col],
                [line]: {
                  ...prevState.mandatory[place_col][line],
                  required: [value === "required"],
                },
              },
            },
          }));
        }
      }
    },
    [place, col, isSplit, type, setDataJson]
  );

  const handleTVA = useCallback(
    (value) => {
      setDataJson((prevState) => ({
        ...prevState,
        default_values: {
          ...prevState.default_values,
          special_tva: value === "special",
        },
      }));
    },
    [setDataJson]
  );

  useEffect(() => {
    setMandatoryRequired(
      label[element] === "Intitulé" ||
        label[element] === "Client facturé" ||
        label[element] === "Agence concernée"
    );
  }, [label, element, name]);

  useEffect(() => {
    setNewType(mandatoryRequired ? "mandatoryRequired" : type);
    setRequired(mandatoryRequired ? true : elementRequired);
  }, [mandatoryRequired, type, elementRequired, element, label]);

  return (
    <DropItem ref={drop}>
      {element !== "blank" && element !== "empty" && (
        <ItemWrapper>
          {type === "mandatory" || mandatoryRequired ? (
            <Item ref={drag}>
              <p className="label">{name}</p>
              {mandatoryRequired ? (
                <p className="required">Obligatoire</p>
              ) : (
                <div>
                  {name === "Gestion de la TVA" ? (
                    <SelectItem
                      className="select"
                      defaultValue={
                        defaultTVA?.special_tva ? "special" : "default"
                      }
                      size="small"
                      onChange={handleTVA}
                    >
                      <Select.Option value="default">taux normal</Select.Option>
                      <Select.Option value="special">
                        taux spécial
                      </Select.Option>
                    </SelectItem>
                  ) : (
                    <SelectComponent
                      name={name}
                      required={elementRequired}
                      handleChange={handleChange}
                      label={label}
                      element={element}
                      defaultTVA={defaultTVA}
                      handleTVA={handleTVA}
                    />
                  )}
                </div>
              )}
            </Item>
          ) : (
            <Item ref={drag}>
              <p className="label">{name}</p>
            </Item>
          )}
          {mandatoryRequired ? null : (
            <Icon
              className="fa-duotone fa-trash-xmark"
              onClick={() => handleDelete()}
            />
          )}
        </ItemWrapper>
      )}
    </DropItem>
  );
}

const DropItem = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  height: 100%;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(217, 217, 217, 1);
`;

const Item = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: move;
  cursor: grab;

  p {
    margin: 0;
  }

  .label {
    font-size: 12px;
    font-weight: 700;
  }

  .required {
    font-size: 10px;
    font-weight: 400;
  }
`;

const Icon = styled.i`
  font-size: 12px;
  color: rgba(222, 42, 42, 1);
  cursor: pointer;
  align-self: flex-start;
  padding-top: 5px;
`;

const SelectItem = styled(Select)`
  width: 143px;
  margin: 0;

  .ant-select-selector {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export default DropElement;
