import React, { useCallback, useState } from "react";
import { Select } from "antd";

import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { useMutation, useQueryClient } from "react-query";
import { MaterielComponent } from "./MaterielComponent";
import MaterialDetails from "./MaterialDetails";
import { useStore } from "../../store";
import { getUrlParams } from "../../utils";
import useTodo from "../../hookQuery/useTodo";
import AddButton from "../../react-ui/AddButton";
import TitleContainer from "../../react-ui/TitleContainer";
import Separator from "../../react-ui/Separator";
import CsvImporter from "./CsvImporter";
import { postData } from "../../request/instance";
import { fetchDistributorsUrl } from "../../../utils/fetchDistributorsUrl";
import { fetchManufacturersUrl } from "../../../utils/fetchManufacturersUrl";
import { fetchUnitiesUrl } from "../../../utils/fetchUnitiesUrl";
import { getFetchUrlIdAndFrom } from "../../../utils/getFetchUrlIdAndFrom";

const selector = (state) => ({
  setFormToken: state.setFormToken,
  formToken: state.formToken,
  editMateriel: state.editMateriel,
});

const { Option } = Select;

function Material({
  entityWorkId = getUrlParams(),
  from = "admin",
  opportunityId = null,
  faIsValidate = false,
  faId = null,
  defaultMajoration = null,
  entity_work_name,
  configuration,
  vocabulary,
  use_special_tva,
  round_type_list,
}) {
  const queryClient = useQueryClient();
  const { formToken, editMateriel } = useStore(selector);
  const [materials, setMaterials] = useState([]);
  const [compatibleMaterials, setCompatibleMaterials] = useState([]);

  const { data: categoriesFilter } = useTodo(
    formToken,
    `/category_profession?entity_work_id=${entityWorkId}&import_csv=true`,
    "CategoriesFilter"
  );

  const { data: categories } = useTodo(
    formToken,
    `/category_profession?entity_work_id=${entityWorkId}`,
    "Categories"
  );

  const { data: unities } = useTodo(
    formToken,
    fetchUnitiesUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
    }),
    ["Unities", { from }]
  );

  const { data: manufacturers } = useTodo(
    formToken,
    fetchManufacturersUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
    }),
    ["Manufacturers", { from }]
  );

  const { data: distributors } = useTodo(
    formToken,
    fetchDistributorsUrl({
      ...getFetchUrlIdAndFrom({
        from,
        opportunityId,
        faIsValidate,
        faId,
        entityWorkId,
      }),
    }),
    ["Distributors", { from }]
  );

  const optimisticUpdateMatFAOpp = (is_in_framework_agreement) => ({
    // Optimistic update of material_professions
    onMutate: async ({ material_profession_id }) => {
      const previousMaterials = [...materials];
      const idxToUpdate = materials.findIndex(
        (el) => el.id === material_profession_id
      );
      const materialsUpdated = materials;
      materialsUpdated[idxToUpdate] = {
        ...materialsUpdated[idxToUpdate],
        is_in_framework_agreement,
        material_included: null,
      };
      setMaterials([...materialsUpdated]);
      editMateriel(materialsUpdated[idxToUpdate]);
      return {
        previousMaterials,
        material_profession_id,
        materialsUpdated,
      };
    },
    onSuccess: (payload, _, context) => {
      const idxToUpdate = context.materialsUpdated.findIndex(
        (el) => el.id === context.material_profession_id
      );
      const { materialsUpdated } = context;
      materialsUpdated[idxToUpdate] = {
        ...materialsUpdated[idxToUpdate],
        material_included: materialsUpdated[idxToUpdate]
          .is_in_framework_agreement
          ? payload
          : null,
      };
      setMaterials([...materialsUpdated]);
      editMateriel(materialsUpdated[idxToUpdate]);
      queryClient.invalidateQueries("MaterialsAssociates");
    },
    onError: (_, __, context) => {
      setMaterials(context.previousMaterials);
      editMateriel(
        context.previousMaterials.find(
          (el) => el.id === context.material_profession_id
        )
      );
    },
  });

  const { mutate: createMaterialOpportunity, isLoading: isCreaMatOppLoading } =
    useMutation(
      (todoData) =>
        postData(
          formToken,
          "/material_opportunity/create_for_referencing_or_quotation",
          todoData
        ),
      optimisticUpdateMatFAOpp(true)
    );

  const { mutate: deleteMaterialOpportunity, isLoading: isDelMatOppLoading } =
    useMutation(
      (todoData) =>
        postData(formToken, "/material_opportunity/delete", todoData),
      optimisticUpdateMatFAOpp(false)
    );

  const { mutate: createMaterialFA, isLoading: isCreaMatFALoading } =
    useMutation(
      (todoData) =>
        postData(formToken, "/material_framework_agreement/create", todoData),
      optimisticUpdateMatFAOpp(true)
    );

  const { mutate: deleteMaterialFA, isLoading: isDelMatFALoading } =
    useMutation(
      (todoData) =>
        postData(formToken, "/material_framework_agreement/delete", todoData),
      optimisticUpdateMatFAOpp(false)
    );

  const handleMaterialOpportunity = useCallback(
    (checked, id) => {
      if (isCreaMatOppLoading || isDelMatOppLoading) return;
      if (!checked) {
        createMaterialOpportunity({
          material_profession_id: id,
          opportunity_id: opportunityId,
        });
      } else {
        deleteMaterialOpportunity({
          material_profession_id: id,
          opportunity_id: opportunityId,
          referencing: true,
        });
      }
    },
    [
      createMaterialOpportunity,
      opportunityId,
      deleteMaterialOpportunity,
      isCreaMatOppLoading,
      isDelMatOppLoading,
    ]
  );

  const handleMaterialFA = useCallback(
    (checked, materialProfession) => {
      if (isCreaMatFALoading || isDelMatFALoading) return;
      if (!checked) {
        createMaterialFA({
          material_profession_id: materialProfession.id,
          framework_agreement_id: faId,
        });
      } else {
        deleteMaterialFA({
          material_profession_id: materialProfession.id,
          id: materialProfession.material_included.id,
        });
      }
    },
    [
      isCreaMatFALoading,
      faId,
      deleteMaterialFA,
      isDelMatFALoading,
      createMaterialFA,
    ]
  );

  const handleFAAndOpp = useCallback(
    (checked, materialProfession) => {
      if (from === "referencing")
        handleMaterialOpportunity(checked, materialProfession.id);
      else handleMaterialFA(checked, materialProfession);
    },
    [handleMaterialOpportunity, handleMaterialFA, from]
  );

  const [wordEntered, setWordEntered] = useState("");
  const [materialDetailsStatus, setMaterialDetailsStatus] = useState("empty");
  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const openMaterialCreation = () => {
    editMateriel(null);
    setMaterialDetailsStatus("create");
  };

  const deselectMaterial = (e) => {
    const newCompatibleMaterials = compatibleMaterials.filter(
      (el) => el.id !== e
    );
    setCompatibleMaterials(newCompatibleMaterials);
  };

  return (
    <Container>
      <div className="main_admin-materiels">
        <TitleContainer label={`${vocabulary?.products} disponibles`}>
          <Wrapper>
            <>
              {(from !== "frameworkAgreement" ||
                (from === "frameworkAgreement" && faIsValidate)) && (
                <StyledButton
                  onClick={openMaterialCreation}
                  disabled={materialDetailsStatus === "create"}
                  label={vocabulary?.product}
                />
              )}
              {from === "admin" && (
                <CsvImporter
                  entity_work_name={entity_work_name}
                  configuration={configuration}
                  vocabulary={vocabulary}
                />
              )}
            </>
          </Wrapper>
        </TitleContainer>

        <StyledSearch
          allowClear
          placeholder={`Rechercher par ${vocabulary?.product_lowercase}, code article, etc...`}
          value={wordEntered}
          onChange={handleFilter}
        />
        {configuration?.general.compatibility && (
          <CompatibilityContainer>
            <FilterText>Filtres de compatibilité</FilterText>
            <Select
              mode="multiple"
              style={{ width: "100%", minWidth: 0 }}
              placeholder="Cliquez sur l’icône “Filtre” du matériel souhaité"
              value={compatibleMaterials?.map((el) => el.id)}
              optionLabelProp="label"
              onDeselect={(e) => deselectMaterial(e)}
            >
              {compatibleMaterials?.map((associateArray) => (
                <Option value={associateArray.id} label={associateArray.name}>
                  <div>{associateArray.name}</div>
                </Option>
              ))}
            </Select>
          </CompatibilityContainer>
        )}

        <Separator isHorizontal size={3} />

        <MaterielComponent
          materialDetailsStatus={materialDetailsStatus}
          setMaterialDetailsStatus={setMaterialDetailsStatus}
          wordEntered={wordEntered}
          materials={materials}
          setMaterials={setMaterials}
          setCompatibleMaterials={setCompatibleMaterials}
          compatibleMaterials={compatibleMaterials}
          categories={categoriesFilter}
          entityWorkId={entityWorkId}
          from={from}
          opportunityId={opportunityId}
          handleFAAndOpp={handleFAAndOpp}
          faIsValidate={faIsValidate}
          faId={faId}
          entity_work_name={entity_work_name}
          configuration={configuration}
          use_special_tva={use_special_tva}
        />
      </div>
      <Separator />
      <MaterialDetails
        materials={materials}
        setMaterials={setMaterials}
        categories={categories}
        unities={unities}
        manufacturers={manufacturers}
        distributors={distributors}
        materialDetailsStatus={materialDetailsStatus}
        setMaterialDetailsStatus={setMaterialDetailsStatus}
        entityWorkId={entityWorkId}
        from={from}
        opportunityId={opportunityId}
        handleFAAndOpp={handleFAAndOpp}
        faIsValidate={faIsValidate}
        faId={faId}
        entity_work_name={entity_work_name}
        configuration={configuration}
        vocabulary={vocabulary}
        defaultMajoration={defaultMajoration}
        use_special_tva={use_special_tva}
        round_type_list={round_type_list}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
  width: 100%;
`;

const StyledButton = styled(AddButton)`
  margin-right: 4px;
  max-height: 45px;
`;

const StyledSearch = styled(Search)`
  margin: 0 0 10px;
`;

const CompatibilityContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FilterText = styled.p`
  margin: 0 5px 0px 0;
  font-size: 14px !important;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export default Material;
