import React, { useCallback } from "react";
import { useStore } from "../../../store";
import useDeleteTodo from "../../../hookQuery/useDeleteTodo";
import { ShowDeleteConfirm } from "../../../Admin-cf/confirmModal";

import Delete from "../../../react-ui/Icons/Delete";
import Zone from "./Zone";
import { quantityTimeConverter } from "../../../../utils/quantityTimeConverter";
import Loader from "../../../react-ui/Loader";

const selector = (state) => ({
  zoneOpenData: state.zoneOpenData,
  setZoneOpen: state.setZoneOpen,
  formToken: state.formToken,
  isUsingMinutes: state.isUsingMinutes,
});

function ZonesArray({
  difficulties,
  id_opportunity,
  zones,
  zoneLoading,
  opportunityType,
  faId,
  majorations,
  carrycots,
  opportunity_work,
  configuration,
  vocabulary,
  tva_professions,
  showColTVA,
  from,
}) {
  const { formToken, isUsingMinutes } = useStore(selector);

  const { mutate: deleteZone } = useDeleteTodo(
    formToken,
    "/zone/delete",
    "Zones"
  );

  const formatZoneData = useCallback(
    ({ value, recordType }) => {
      const isPrestation = recordType === "prestation";
      const getUnityQuantity = () => {
        if (!isPrestation) return value.unity_quantity;
        return value.is_package || value.speed
          ? value.unity_quantity
          : quantityTimeConverter({
              quantity: value.unity_quantity,
              isUsingMinutes:
                value.minutes !== null ? value.minutes : isUsingMinutes,
            });
      };
      const getEstimateQuantity = () => {
        if (!isPrestation) return value.estimate_quantity;
        return value.is_package || value.speed
          ? value.estimate_quantity
          : quantityTimeConverter({
              quantity: value.estimate_quantity,
              isUsingMinutes:
                value.minutes !== null ? value.minutes : isUsingMinutes,
            });
      };
      const getHourQuantity = () => {
        if (!isPrestation) return value.hour_quantity;
        return value.is_package
          ? value.hour_quantity
          : quantityTimeConverter({
              quantity: value.hour_quantity,
              isUsingMinutes:
                value.minutes !== null ? value.minutes : isUsingMinutes,
            });
      };
      return {
        ...value,
        key: `${recordType}-${value.id}`,
        recordType,
        zoneId: value.zone_id,
        entitled: value.name,
        // Needed to get parent quantity for associated presta and materials
        quantity: isPrestation ? value.unity_quantity : value.quantity,
        estimate_quantity: getEstimateQuantity(),
        unity_quantity: getUnityQuantity(),
        hour_quantity: getHourQuantity(),
      };
    },
    [isUsingMinutes]
  );

  const handleDeleteZone = (valueZone) => {
    deleteZone({ opportunity_id: id_opportunity, id: valueZone });
  };
  const genExtra = (zone) =>
    zones.length > 1 &&
    zone.material_opportunities?.length < 1 &&
    zone.prestation_opportunities?.length < 1 ? (
      <Delete
        onClick={(event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          ShowDeleteConfirm(zone.entitled, zone.id, handleDeleteZone);
          event.stopPropagation();
        }}
      />
    ) : null;

  return zoneLoading ? (
    <Loader />
  ) : (
    zones?.map((zone) => (
      <Zone
        showColTVA={showColTVA}
        tva_professions={tva_professions}
        key={zone?.id}
        partialZone={zone}
        isCollapsed={zones.length > 1 ? null : zone?.id}
        genExtra={genExtra}
        faId={faId}
        id_opportunity={id_opportunity}
        difficulties={difficulties}
        carrycots={carrycots}
        majorations={majorations}
        formatZoneData={formatZoneData}
        opportunityType={opportunityType}
        opportunity_work={opportunity_work}
        configuration={configuration}
        vocabulary={vocabulary}
        from={from}
      />
    ))
  );
}

export default ZonesArray;
