import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['save', 'close']

  delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  async close_modal_and_save() { 
    let button_save = this.saveTarget;
    let label_name = document.getElementById('risk_management_contract_name')
    await this.delay(600)
    button_save.click = this.hidde_modal();
    button_save.disabled = false
    button_save.outerHTML= "<button style=\"padding-top:0; padding-bottom:0\" data-target=\"offer-review-risk-contract.save\" data-action=\"offer-review-risk-contract#close_modal_and_save\" data-disable-with=\"<div class=&quot;btn btn-success&quot;><div class=&quot;d-flex align-items-center&quot;><i class=&quot;fas fa-save mr-2&quot;></i><p class=&quot;mb-0&quot; style=&quot;margin-right: 15px&quot;>En cours...</p></div></div>\" type=\"submit\" class=\"\">\n            <div class=\"btn btn-primary btn-success\">                    \n                <div class=\"d-flex align-items-center\">\n                    <i class=\"fa fa-save mr-2\"></i>\n                    <p class=\"mb-0\">Enregistrer</p>\n                </div>\n            </div>\n</button>"
    label_name.value = ""
  }

  hidde_modal(){
    $('#modal-contract').hide();
    let last_modal = document.querySelectorAll(".modal-backdrop:last-child")
    last_modal[0].remove();
  }

  close_modal() {
    let button_close = this.closeTarget;
    let label_name = document.getElementById('risk_management_contract_name')
    button_close.click = this.hidde_modal()
    label_name.value = ""
  }
}