import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useQueryClient } from "react-query";
import { dateSorter } from "../../utils/dateSorter";
import { formatNumberString } from "../../utils/formatNumberString";
import { numberSorter } from "../../utils/numberSorter";
import { stringSorter } from "../../utils/stringSorter";
import TableContainer from "../Chiffrage/CostingMovementsCarrycots/TableContainer";
import NumberCell from "../react-ui/NumberCell";
import { useStore } from "../store";
import { useLazyQuery } from "../../hooks/useLazyQuery";
import { getData } from "../request/instance";
import ActionsContainer from "../react-ui/ActionsContainer";
import Eye from "../react-ui/Icons/Eye";

const selector = (state) => ({
  formToken: state.formToken,
});

function OpportunitiesHidden({ opportunities_hidden }) {
  const [data, setData] = useState([]);
  const { formToken } = useStore(selector);
  const [opportunityId, setOpportunityId] = useState();
  const queryClient = useQueryClient();

  const [showOpportunity] = useLazyQuery(
    ["showOpportunityId"],
    () => getData(formToken, `/admin/hiddens/opportunity/${opportunityId}`),
    {
      onSuccess: () => queryClient.invalidateQueries("HiddenOpportunities"),
    }
  );
  useEffect(() => {
    if (opportunityId) {
      showOpportunity();
    }
  }, [opportunityId, showOpportunity]);
  useEffect(() => {
    if (opportunities_hidden?.length > 0) {
      setData([
        ...opportunities_hidden.map((opportunity) => ({
          ...opportunity,
          show: (
            <ActionsContainer center="center">
              <Eye onClick={() => setOpportunityId(opportunity.key)} />
            </ActionsContainer>
          ),
        })),
      ]);
    }
  }, [opportunities_hidden]);

  const globalArray = [
    {
      title: "Intitulé",
      dataIndex: "name",
      key: "name",
      width: 200,
      fixed: "left",
      sorter: (a, b) => stringSorter(a.name, b.name),
    },
    {
      title: "Intitulé de l'offre",
      dataIndex: "offer_name",
      key: "offer_name",
      width: 200,
      align: "left",
      sorter: (a, b) => stringSorter(a.offer_name, b.offer_name),
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      width: 100,
      align: "left",
      sorter: (a, b) => stringSorter(a.client, b.client),
    },
    {
      title: "Date limite de réponse",
      dataIndex: "answer_date",
      key: "answer_date",
      width: 100,
      align: "left",
      sorter: (a, b) => dateSorter(a.answer_date, b.answer_date),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      align: "left",
      sorter: (a, b) => stringSorter(a.status, b.status),
    },
    {
      title: "Etape",
      dataIndex: "step",
      key: "step",
      width: 100,
      align: "left",
      sorter: (a, b) => stringSorter(a.step, b.step),
    },
    {
      title: "Type de chiffrage",
      dataIndex: "work",
      key: "work",
      width: 100,
      align: "left",
      sorter: (a, b) => stringSorter(a.work, b.work),
    },
    {
      title: "CA",
      dataIndex: "turnover",
      key: "turnover",
      width: 100,
      align: "right",
      sorter: (a, b) => numberSorter(a.turnover, b.turnover),
      render: (value) =>
        value ? (
          <NumberCell>
            {formatNumberString({
              str: value,
              nbDecimal: 2,
              space: true,
            })}
            €
          </NumberCell>
        ) : (
          "0€"
        ),
    },
    {
      title: "Agence",
      dataIndex: "entity",
      key: "entity",
      width: 100,
      align: "left",
      sorter: (a, b) => stringSorter(a.entity, b.entity),
    },
    {
      title: "Propriétaire",
      dataIndex: "opportunity_user",
      key: "opportunity_user",
      width: 100,
      align: "left",
      sorter: (a, b) => stringSorter(a.opportunity_user, b.opportunity_user),
    },
    {
      title: "",
      dataIndex: "show",
      key: "show",
      align: "center",
      fixed: "right",
      width: 25,
    },
  ];

  return (
    <Container>
      <WrapperFullTable>
        <Title>Opportunités</Title>
        <WrapperTable>
          <TableContainer
            columns={globalArray}
            data={data}
            pagination={false}
            scroll={{ x: 1300, y: "69vh" }}
          />
        </WrapperTable>
      </WrapperFullTable>
    </Container>
  );
}

const Container = styled.div`
  justify-content: space-between;
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  height: fit-content;
  width: 100%;
`;
const WrapperTable = styled.div`
  display: inline-block;
  width: 100%;
  height: 72vh;
`;
const WrapperFullTable = styled.div`
  background-color: white;
  border-radius: 8px;
  flex: 1 1 300px;
  display: inline-block;
  width: 100%;
  height: auto;
  margin-bottom: 0.7rem;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;
const Title = styled.h4`
  display: flex;
  font-weight: 500;
  font-size: 18px;
  font-family: "Cerebri Sans,sans-serif";
  color: #343a40;
  justify-content: center;
  margin: 0;
  margin-top: 0.375rem;
`;

export default OpportunitiesHidden;
