export const searchCaseDiatricsInsensitive = (search, text) =>
  text
    ?.toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .includes(
      search
        .toLowerCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
    );

export const filterInTree = (search, option) =>
  searchCaseDiatricsInsensitive(search, option.title);
