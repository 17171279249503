import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStore } from "../../store";
import { getUrlParams } from "../../utils";
import RightBreadcrumb from "../../breadcrumb-right/RightBreadcrumb";

const selector = (state) => ({
  formToken: state.formToken,
  setWarnings: state.setWarnings,
});

function LowCurrentBreadcrumb({
  opportunity,
  offer_name,
  opportunity_work,
  configuration,
  vocabulary,
  vocabularyApplication,
  is_last_page,
  site_typologies,
  supervisor,
  documents_disabled,
}) {
  const { formToken, setWarnings } = useStore(selector);
  const [activePage, setActivePage] = useState("");

  const navigate = (pageNumber = 0, edit = false, offer = false) => {
    let realPageNumber = pageNumber;

    if (realPageNumber === 2 && opportunity.response_type === "referencing")
      realPageNumber += 1;
    if (realPageNumber === 1 && opportunity.response_type === "renting")
      realPageNumber += 2;
    const path = window.location.pathname.split("/");
    let basePath = path.slice(0, 3).join("/");
    let endpoint = `costing/${realPageNumber}`;
    if (edit) {
      endpoint = `edit`;
    }
    if (offer) {
      basePath = path.slice(0, 1).join("/");
      endpoint = `offers/${opportunity.offer_id}`;
    }
    window.location = `${basePath}/${endpoint}`;
  };

  useEffect(() => {
    let urlParams = getUrlParams();
    if (urlParams !== "edit") {
      urlParams = parseInt(urlParams, 10);
      if (urlParams === 3 && opportunity.response_type === "referencing")
        urlParams -= 1;
      if (urlParams === 3 && opportunity.response_type === "renting")
        urlParams -= 2;
    }
    setActivePage(urlParams.toString());
  }, [opportunity]);

  const getPageOneName = () => {
    if (opportunity.response_type === "referencing")
      return `${vocabulary.breadcrumb.title_page_1} & ${vocabulary?.breadcrumb?.title_page_2}`;
    if (opportunity.response_type === "renting") return "Offre locative";
    return vocabulary.breadcrumb.title_page_1;
  };

  const getPageTwoName = () => {
    if (opportunity.response_type === "referencing")
      return vocabulary.breadcrumb?.title_page_3;
    return vocabulary?.breadcrumb?.title_page_2;
  };

  const page_1 = () => {
    return (
      <>
        <i className=" fa-duotone fa-angle-right breadcrumb-separator" />

        <Element active={activePage === "1"} onClick={() => navigate(1)}>
          {" "}
          {getPageOneName()}{" "}
        </Element>
      </>
    );
  };

  const page_2 = () => {
    return (
      <>
        <i className=" fa-duotone fa-angle-right breadcrumb-separator" />
        <Element active={activePage === "2"} onClick={() => navigate(2)}>
          {" "}
          {getPageTwoName()}{" "}
        </Element>
      </>
    );
  };

  const page_3 = () => {
    if (opportunity.response_type === "referencing") return null;
    return (
      <>
        <i className=" fa-duotone fa-angle-right breadcrumb-separator" />
        <Element active={activePage === "3"} onClick={() => navigate(3)}>
          {vocabulary.breadcrumb?.title_page_3}
        </Element>
      </>
    );
  };
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {window.location.pathname.split("/")[2] !== "new" &&
        opportunity_work !== "guarding" && (
          <div className="d-flex align-items-center font-18">
            <Element onClick={() => navigate(0, false, true)}>
              {vocabularyApplication.breadcrumb.offer} ({offer_name}){" "}
            </Element>
            <i className="fa-duotone fa-angle-right breadcrumb-separator" />
            <Element
              active={activePage === "edit"}
              onClick={() => navigate(0, true)}
            >
              {vocabularyApplication.breadcrumb.opportunity} (
              {opportunity?.opportunity_name}){" "}
            </Element>

            {configuration?.costing?.page_1.visible && page_1()}
            {configuration?.costing?.page_2.visible &&
              opportunity.step > 2 &&
              page_2()}
            {configuration?.costing?.page_3.visible &&
              opportunity.step === 4 &&
              page_3()}
            <RightBreadcrumb
              formToken={formToken}
              opportunity_id={opportunity.id}
              response_type={opportunity.response_type}
              work={opportunity.work}
              activePage={activePage}
              setWarnings={setWarnings}
              is_last_page={is_last_page}
              site_typologies={site_typologies}
              opportunity_name={opportunity.opportunity_name}
              offer_id={opportunity.offer_id}
              offer_name={offer_name}
              supervisor={supervisor}
              opportunity_chrono={opportunity.chrono}
              opportunity_work={opportunity_work}
              documents_disabled={documents_disabled}
            />
          </div>
        )}
    </>
  );
}

const Element = styled.div`
  color: ${({ active }) => (active ? "#6a6a6a" : "#585858")};
  font-size: 12px;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
  cursor: pointer;
  margin-right: 0px;
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  font-size: ${({ active }) => (active ? "14px !important" : "12px")};

  &:hover {
    ${({ active }) => !active && `color: red;`}
  }
`;

export default LowCurrentBreadcrumb;
