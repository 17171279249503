import React, { useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { centsToEuros, eurosToCents } from "../../../utils/currencyConverter";
import { formatNumberString } from "../../../utils/formatNumberString";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { getData } from "../../request/instance";
import { useStore } from "../../store";
import CoefficientInput from "./CoefficientInput";
import MatrixHeader from "./MatrixHeader";
import Loader from "../../react-ui/Loader";

const constructColumns = ({
  periods,
  handlePeriodChange,
  selectedPeriod,
  setSelectedPeriod,
  handlePeriodDelete,
  render,
  rentalOfferId,
  defaultPeriod,
  handleDefaultPeriodChange,
  useMargin,
}) => {
  const columns = [
    {
      title: ``,
      dataIndex: "selling_price",
      key: "selling_price",
      fixed: "left",
      width: 200,
      render,
    },
  ];
  const width = 150;
  const isDeletable = periods.length > 1;

  periods.forEach((period, idx) => {
    columns.push({
      title: (
        <MatrixHeader
          title={`${period} mois`}
          header={period}
          idx={idx}
          handleHeaderChange={handlePeriodChange}
          isDeletable={isDeletable}
          selectedHeader={selectedPeriod}
          setSelectedHeader={setSelectedPeriod}
          handleHeaderDelete={handlePeriodDelete}
          excludedHeaders={periods.filter((_, index) => index !== idx)}
          isFavorite={defaultPeriod === period}
          handleDefaultPeriodChange={handleDefaultPeriodChange}
          type="period"
        />
      ),
      dataIndex: `${idx}`,
      key: `${period}-${idx}`,
      width,
      render: (record, global) => (
        <CoefficientInput
          record={useMargin ? record * 100 : record}
          x={idx}
          y={global.key}
          rentalOfferId={rentalOfferId}
          useMargin={useMargin}
        />
      ),
    });
  });
  columns.push({
    title: (
      <MatrixHeader
        idx={periods.length}
        handleHeaderChange={handlePeriodChange}
        selectedHeader={selectedPeriod}
        setSelectedHeader={setSelectedPeriod}
        excludedHeaders={periods}
        isCreating
        type="period"
      />
    ),
    dataIndex: "plus",
    key: "plus",
    fixed: "right",
    align: "center",
    width: 20,
  });
  return columns;
};

const constructLine = ({ line, position, nbColumns, prevLine, lineNum }) => {
  const formattedSellingPrice = formatNumberString({
    str: centsToEuros(line[0]),
  });
  let selling_price;
  if (position === "first") {
    selling_price = `${formattedSellingPrice} euros ou moins`;
  } else if (position === "first-last") {
    selling_price = `${formattedSellingPrice} euros ou plus`;
  } else if (position === "last") {
    selling_price = `${formattedSellingPrice} euros ou plus`;
  } else {
    const formattedPrevSellingPrice = formatNumberString({
      str: centsToEuros(prevLine[0] + 1),
    });
    selling_price = `Entre ${formattedPrevSellingPrice} euros et ${formattedSellingPrice} euros`;
  }
  const coeffs = [];
  for (let idx = 1; idx < nbColumns; idx += 1) {
    coeffs.push(line[idx]);
  }

  return {
    key: lineNum,
    selling_price,
    ...coeffs,
  };
};

const constructData = ({ lines, nbColumns }) => {
  const data = [];

  // Handle case when there is no selling_price
  if (lines.length === 1 && lines[0].length !== nbColumns) {
    data.push(
      constructLine({
        line: [0, ...lines[0]],
        position: "first-last",
        nbColumns,
        lineNum: 0,
      })
    );
    return data;
  }

  lines.forEach((line, idx) => {
    if (idx === 0) {
      data.push(
        constructLine({
          line,
          position: "first",
          nbColumns,
          lineNum: 0,
        })
      );
    } else if (idx === lines.length - 1) {
      data.push(
        constructLine({
          line: [lines[idx - 1][0] + 1, ...line],
          position: "last",
          nbColumns,
          lineNum: idx,
        })
      );
    } else {
      data.push(
        constructLine({
          line,
          prevLine: lines[idx - 1],
          nbColumns,
          lineNum: idx,
        })
      );
    }
  });
  return data;
};

const selector = (state) => ({
  formToken: state.formToken,
});

function CoefficientsMatrix({
  rentalOfferId,
  defaultPeriod,
  updateRentalOffer,
  useMargin,
}) {
  const { formToken } = useStore(selector);
  const [selectedPeriod, setSelectedPeriod] = useState();
  const [selectedSellingPrice, setSelectedSellingPrice] = useState();

  const {
    data: coeffMatrix,
    isLoading,
    isError,
  } = useQuery(["CoeffMatrix", { id: rentalOfferId }], () =>
    getData(formToken, `/rental_offer_coeff_matrix?id=${rentalOfferId}`)
  );

  if (isLoading) return <Loader />;

  if (isError || !coeffMatrix) return null;

  const { lines, periods } = coeffMatrix;

  const handleDefaultPeriodChange = (value) => {
    updateRentalOffer({
      id: rentalOfferId,
      rental_offer: {
        default_period: value,
      },
    });
  };

  const handlePeriodChange = (value, idx) => {
    if (idx === periods.length) {
      updateRentalOffer({
        id: rentalOfferId,
        x: idx,
        rental_offer: {
          periods: [...periods, value],
        },
      });
    } else {
      const newPeriods = [...periods];
      newPeriods[idx] = value;
      updateRentalOffer({
        id: rentalOfferId,
        x: idx,
        rental_offer: {
          periods: newPeriods,
        },
      });
    }
  };

  const handlePeriodDelete = (idx) => {
    const newPeriods = [...periods];
    newPeriods.splice(idx, 1);
    updateRentalOffer({
      id: rentalOfferId,
      x: idx,
      rental_offer: {
        periods: newPeriods,
      },
    });
  };

  const handleSellingPriceChange = (value, idx) => {
    const valueToCents = eurosToCents(value);
    if (lines.length === 1 && lines[0].length !== columns.length) {
      updateRentalOffer({
        id: rentalOfferId,
        rental_offer: {
          selling_prices: [valueToCents],
        },
      });
    } else if (idx === lines.length - 1) {
      const newSellingPrices = [];
      for (let i = 0; i < lines.length - 1; i += 1) {
        newSellingPrices.push(lines[i][0]);
      }
      newSellingPrices.push(valueToCents);
      updateRentalOffer({
        id: rentalOfferId,
        rental_offer: {
          selling_prices: newSellingPrices,
        },
      });
    } else {
      const newSellingPrices = [];
      for (let i = 0; i < lines.length - 1; i += 1) {
        newSellingPrices.push(lines[i][0]);
      }
      newSellingPrices[idx] = valueToCents;
      updateRentalOffer({
        id: rentalOfferId,
        rental_offer: {
          selling_prices: newSellingPrices,
        },
      });
    }
  };

  const handleSellingPriceDelete = (idx) => {
    const newSellingPrices = [];
    for (let i = 0; i < lines.length - 1; i += 1) {
      newSellingPrices.push(lines[i][0]);
    }
    newSellingPrices.splice(idx, 1);
    updateRentalOffer({
      id: rentalOfferId,
      y: idx,
      rental_offer: {
        selling_prices: newSellingPrices,
      },
    });
  };

  const render = (sellingPrice, __, idx) => {
    if (lines.length === 1 && lines[0].length !== columns.length) {
      return (
        <MatrixHeader
          title={sellingPrice}
          idx={0}
          handleHeaderChange={handleSellingPriceChange}
          selectedHeader={selectedSellingPrice}
          setSelectedHeader={setSelectedSellingPrice}
          type="sellingPrice"
        />
      );
    }
    if (idx === 0) {
      return (
        <MatrixHeader
          title={sellingPrice}
          idx={idx}
          header={lines[idx][0]}
          handleHeaderChange={handleSellingPriceChange}
          selectedHeader={selectedSellingPrice}
          setSelectedHeader={setSelectedSellingPrice}
          nextHeader={lines.length > idx + 2 ? lines[idx + 1][0] : undefined}
          isDeletable
          handleHeaderDelete={handleSellingPriceDelete}
          type="sellingPrice"
        />
      );
    }
    if (idx === lines.length - 1) {
      return (
        <MatrixHeader
          title={sellingPrice}
          idx={idx}
          handleHeaderChange={handleSellingPriceChange}
          selectedHeader={selectedSellingPrice}
          setSelectedHeader={setSelectedSellingPrice}
          prevHeader={lines[idx - 1][0]}
          type="sellingPrice"
        />
      );
    }
    return (
      <MatrixHeader
        title={sellingPrice}
        idx={idx}
        handleHeaderChange={handleSellingPriceChange}
        selectedHeader={selectedSellingPrice}
        setSelectedHeader={setSelectedSellingPrice}
        nextHeader={lines.length > idx + 2 ? lines[idx + 1][0] : undefined}
        prevHeader={lines[idx - 1][0]}
        header={lines[idx][0]}
        isDeletable
        handleHeaderDelete={handleSellingPriceDelete}
        type="sellingPrice"
      />
    );
  };

  const columns = constructColumns({
    periods,
    handlePeriodChange,
    selectedPeriod,
    setSelectedPeriod,
    handlePeriodDelete,
    render,
    rentalOfferId,
    defaultPeriod,
    handleDefaultPeriodChange,
    useMargin,
  });

  const data = constructData({ lines, nbColumns: columns.length });

  return (
    <StyledTable
      columns={columns}
      data={data}
      scroll={{ x: "max-content" }}
      scrollY
    />
  );
}

const StyledTable = styled(TableContainer)`
  .ant-table-tbody > tr > td:first-child {
    background-color: ${({ theme }) => `${theme.colors.blue07} !important`};
    color: white;
    border-inline-end: 1px solid white !important;
    font-weight: bold;
  }

  .ant-table-tbody > tr:not(:last-child):not(:first-child) > td:first-child {
    border-bottom: 1px solid white !important;
  }
`;

export default CoefficientsMatrix;
