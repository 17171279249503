import React from "react";
import styled, { css } from "styled-components";
import EditButton from "../../../react-ui/EditButton";
import SaveButton from "../../../react-ui/SaveButton";
import Button from "../../../react-ui/Button";
import AddButton from "../../../react-ui/AddButton";

function ButtonCol({
  isUpdating,
  isShowing,
  setStatus,
  isLoading,
  isCreating,
  onCancel,
}) {
  const allowUserEdition = () => {
    setStatus("update");
  };

  return (
    <Container>
      {isShowing && (
        <StyledEditButton
          label="Editer"
          onClick={allowUserEdition}
          fontSize="14px"
          type="button"
        />
      )}
      {isCreating && (
        <StyledButton
          label="Annuler"
          onClick={onCancel}
          fontSize="14px"
          btnType="cancel"
          type="button"
        />
      )}
      {isCreating && (
        <StyledAddButton
          label="Créer"
          loading={isLoading}
          fontSize="14px"
          type="submit"
          disabled={isLoading}
        />
      )}{" "}
      {isUpdating && (
        <StyledSaveButton
          label="Enregistrer"
          loading={isLoading}
          fontSize="14px"
          type="submit"
          disabled={isLoading}
        />
      )}
    </Container>
  );
}

const buttonStyle = css`
  width: 100px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: baseline;
`;

const StyledEditButton = styled(EditButton)`
  ${buttonStyle}
`;
const StyledSaveButton = styled(SaveButton)`
  ${buttonStyle}
`;
const StyledAddButton = styled(AddButton)`
  ${buttonStyle}
`;
const StyledButton = styled(Button)`
  margin-right: 20px;
`;

export default ButtonCol;
