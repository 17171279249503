import { Form } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import { validateMessages } from "../../../constant";
import FormRow from "../../../Form/FormRow";
import FormCol from "../../../Form/FormCol";
import TitleContainer from "../../../react-ui/TitleContainer";
import AddButton from "../../../react-ui/AddButton";
import Input from "../../../react-ui/Input";
import Button from "../../../react-ui/Button";
import Select from "../../../react-ui/Select";

function ProfilManagementForm({
  handleSubmit,
  initialValues,
  status,
  closeModal,
  formIsLoading,
  users,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  const getTitle = () => {
    return status === "create" ? "Créer un profil" : "Modifier un profil";
  };

  return (
    <Form
      id="profil-management-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
      <TitleContainer label={getTitle()}>
        <StyledButton
          label="Annuler"
          onClick={closeModal}
          fontSize="14px"
          btnType="cancel"
          type="button"
        />

        <AddButton
          label={status === "create" ? "Créer" : "Modifier"}
          type="submit"
          value="submit"
          fontSize="14px"
          loading={formIsLoading}
        />
      </TitleContainer>

      <FormContainer>
        <FormRow>
          <FormCol label="Nom" name="name" rules={[{ required: true }]}>
            <Input />
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol label="Utilisateurs" name="users">
            <Select
              mode="multiple"
              showSearch={false}
              options={users || []}
              keyName="fullname"
            />
          </FormCol>
        </FormRow>
      </FormContainer>
    </Form>
  );
}

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

export default ProfilManagementForm;
