import React, { useEffect, useRef, useState } from "react";
import { array, func, number, string } from "prop-types";
import { Checkbox } from "antd";
import styled from "styled-components";
import { otherCostColumns, quotationMovementColumns } from "../../../constant";
import TableContainer from "../TableContainer";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { multiplyFloat } from "../../../../utils/multiplyFloat";
import { ShowDeleteConfirm } from "../../../Admin-cf/confirmModal";
import { centsToEuros } from "../../../../utils/currencyConverter";
import Input from "../../../react-ui/Input";
import Select from "../../../react-ui/Select";
import InputNumber from "../../../react-ui/InputNumber";
import { stringToFloat } from "../../../utils";
import Delete from "../../../react-ui/Icons/Delete";
import ActionsContainer from "../../../react-ui/ActionsContainer";

function OtherCostTable({
  opportunities,
  movements,
  zones,
  createOpportunity,
  deleteOpportunity,
  updateOpportunity,
  setIsModalVisibleFor,
  faId,
  from,
}) {
  const [inputValues, setInputValues] = useState({});
  const [opportunitiesState, setOpportunitiesState] = useState([]);
  const newOpportunityRef = useRef();

  useEffect(() => {
    if (opportunities) {
      const sortedOpportunities = [...opportunities];
      sortedOpportunities.sort((a, b) => a.id - b.id);
      setOpportunitiesState([...sortedOpportunities]);
    }
  }, [opportunities]);

  const handleOnBlur = () => {
    if (
      movements?.length > 0 &&
      zones?.length > 0 &&
      newOpportunityRef.current
    ) {
      updateOpportunity(newOpportunityRef.current);
      newOpportunityRef.current = null;
    }
  };

  const handleFocusToRight = (e) => {
    setTimeout(() => {
      const { length } = e.target.value;
      e.target.setSelectionRange(length, length); // Place le curseur à la fin
    }, 0);
  };

  const handleCostChange = ({ e, opportunity }) => {
    const other_cost_cents =
      formatNumberString({
        str: e.currentTarget.value,
        nbDecimal: 2,
      }) || 0;
    const quantity = opportunity.quantity || 0;

    return {
      ...opportunity,
      other_cost_cents: formatNumberString({
        str: e.currentTarget.value,
        nbDecimal: 2,
      }),
      total_cost_cents: formatNumberString({
        str: multiplyFloat({
          floatArray: [other_cost_cents, quantity],
        }),
        nbDecimal: 2,
      }),
    };
  };

  const handleQuantityChange = ({ e, opportunity }) => {
    const quantity = parseFloat(e, 10) || 0;
    const cost = stringToFloat(opportunity.other_cost_cents) || 0;

    return {
      ...opportunity,
      quantity,
      total_cost_cents: formatNumberString({
        str: multiplyFloat({
          floatArray: [quantity, cost],
        }),
        nbDecimal: 2,
      }),
    };
  };

  const handleMovementChange = ({ e, opportunity }) => {
    const { id, ...movement } = movements.find((el) => el.id === e);

    return {
      ...opportunity,
      movement_tpl_opportunity_id: e,
      ...movement,
      other_cost_cents: formatNumberString({
        str: centsToEuros(movement.other_cost_cents),
        nbDecimal: 2,
      }),
      total_cost_cents: formatNumberString({
        str: multiplyFloat({
          floatArray: [
            centsToEuros(movement.other_cost_cents),
            opportunity.quantity,
          ],
        }),
        nbDecimal: 2,
      }),
    };
  };

  const handleSubcontractingChange = ({ e, opportunity }) => {
    return {
      ...opportunity,
      subcontracting: e.target.checked,
    };
  };

  const handleZoneChange = ({ e, opportunity }) => {
    return {
      ...opportunity,
      zone_id: e,
    };
  };

  const handleFieldChange = ({ e, opportunity, key, idx }) => {
    const newOpportunities = [...opportunitiesState];
    let newOpportunity = { ...opportunity };

    if (key === "cost") newOpportunity = handleCostChange({ e, opportunity });
    else if (key === "quantity")
      newOpportunity = handleQuantityChange({ e, opportunity });
    else if (key === "movement")
      newOpportunity = handleMovementChange({ e, opportunity });
    else if (key === "subcontracting")
      newOpportunity = handleSubcontractingChange({ e, opportunity });
    else if (key === "zone")
      newOpportunity = handleZoneChange({ e, opportunity });

    newOpportunities[idx] = newOpportunity;
    newOpportunityRef.current = newOpportunity;
    setOpportunitiesState(newOpportunities);
  };

  const handleInputChange = ({ idx, key, value }) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [`${key}-${idx}`]: value,
    }));
  };

  const handleInputBlur = ({ idx, key, value, opportunity }) => {
    const formattedValue = formatNumberString({
      str: value,
      nbDecimal: 2,
      space: true,
    });

    handleInputChange({
      idx,
      key,
      value: formattedValue, // Mettre la valeur formatée dans l'état
    });

    handleFieldChange({
      // Utiliser handleFieldChange pour mettre à jour le champ
      e: { currentTarget: { value: formattedValue } },
      idx,
      opportunity,
      key,
    });

    handleOnBlur(); // Gérer le blur global si nécessaire
  };

  const dataFactory = opportunitiesState.map((opportunity, idx) => ({
    key: opportunity.id,
    movement: (
      <SytledSelect
        value={opportunity.movement_tpl_opportunity_id}
        onSelect={(e) => {
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "movement",
          });
          handleOnBlur();
        }}
        fontSize="10px"
        customTooltip
        size="small"
        options={movements}
        disabled={from === "configuration"}
      />
    ),
    cost: (
      <Input
        value={
          inputValues[`cost-${idx}`] !== undefined
            ? inputValues[`cost-${idx}`]
            : opportunity.other_cost_cents
        }
        placeholder="0"
        suffix="€"
        onBlur={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "cost",
            value: formattedValue,
          });
          handleInputBlur({
            idx,
            key: "cost",
            value: formattedValue,
            opportunity,
          });
        }}
        onInput={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "cost",
            value: formattedValue,
          });
        }}
        onFocus={(e) => {
          const { value } = e.currentTarget;
          const formattedValue = formatNumberString({
            str: value,
            nbDecimal: 2,
          });
          handleInputChange({
            idx,
            key: "cost",
            value: formattedValue,
          });
          handleFocusToRight(e);
        }}
        fontSize="10px"
        textAlign="right"
        disabled={!!faId || from === "configuration"}
      />
    ),
    quantity: (
      <StyledInputNumber
        value={opportunity.quantity}
        placeholder="0"
        controls={false}
        onInput={(e) =>
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "quantity",
          })
        }
        onChange={(e) =>
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "quantity",
          })
        }
        onBlur={handleOnBlur}
        onFocus={(e) => {
          handleFocusToRight(e);
        }}
        fontSize="10px"
        size="small"
        disabled={from === "configuration"}
      />
    ),
    totalCost: (
      <Input
        value={opportunity.total_cost_cents}
        disabled
        placeholder="0"
        suffix="€"
        fontSize="10px"
        textAlign="right"
      />
    ),
    subcontracting: (
      <Checkbox
        checked={opportunity.subcontracting || false}
        onChange={(e) => {
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "subcontracting",
          });
          handleOnBlur();
        }}
        onBlur={handleOnBlur}
        disabled={from === "configuration"}
      />
    ),
    zone: (
      <SytledSelect
        value={opportunity.zone_id}
        onSelect={(e) => {
          handleFieldChange({
            e,
            idx,
            opportunity,
            key: "zone",
          });
          handleOnBlur();
        }}
        customTooltip
        fontSize="10px"
        size="small"
        options={zones}
        keyName="entitled"
        disabled={from === "configuration"}
      />
    ),
    actions: (
      <ActionsContainer center>
        <Delete
          onClick={() =>
            ShowDeleteConfirm(
              opportunity.name,
              opportunity.id,
              deleteOpportunity
            )
          }
        />
      </ActionsContainer>
    ),
  }));

  return (
    <TableContainer
      header={{
        title: faId ? "" : "Autres coûts",
        buttons: [
          {
            buttonLabel: faId ? "Ajouter" : "Ajouter",
            disabled: from === "configuration",
            onClick: () => {
              if (movements?.length > 0 && zones?.length > 0)
                createOpportunity({
                  movement_tpl_opportunity_id: movements[0].id,
                  zone_id: zones[0].id,
                  ...movements[0],
                });
            },
          },
          {
            buttonLabel: faId ? "Créer" : "Créer",
            disabled: from === "configuration",
            onClick: () => setIsModalVisibleFor("otherCost"),
          },
        ],
      }}
      columns={faId ? quotationMovementColumns : otherCostColumns}
      data={zones.length > 0 ? dataFactory : []}
      hideIfNoData={zones.length > 0 && !dataFactory.length}
      tr={2}
    />
  );
}

const SytledSelect = styled(Select)`
  width: 90%;
`;

const StyledInputNumber = styled(InputNumber)`
  width: auto;
`;

OtherCostTable.propTypes = {
  opportunities: array,
  movements: array,
  zones: array,
  createOpportunity: func.isRequired,
  deleteOpportunity: func.isRequired,
  updateOpportunity: func.isRequired,
  setIsModalVisibleFor: func.isRequired,
  faId: number,
  from: string,
};

OtherCostTable.defaultProps = {
  opportunities: [],
  movements: [],
  zones: [],
  faId: undefined,
  from: "",
};

export default OtherCostTable;
