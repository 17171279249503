$(document).ready(function () {
  const outfitCosting = document.getElementById("update_all_outfits_btn");

  if (!outfitCosting) {
    return;
  }
  const updateAll = (event) => {
    let button_previous = document.getElementById("button_outfit_previous");
    let button_next = document.getElementById("button_outfit_next");
    let button_new_outfit = document.getElementById("button_new_outfit");
    // let breadcrumbs = document.getElementsByClassName('breadcrumb')
    // let breadcrumbs_separators = document.getElementsByClassName('breadcrumb-separator')
    // let breadcrumbs_selected = document.getElementsByClassName('breadcrumb-selected')
    // let breadcrumbs_clicked = document.getElementsByClassName('breadcrumb-clicked')
    // let breadcrumbs_separators_clicked = document.getElementsByClassName('breadcrumb-separator-clicked')
    // let breadcrumbs_selected_clicked = document.getElementsByClassName('breadcrumb-selected-clicked')
    // breadcrumbs.forEach (breadcrumb => breadcrumb.hidden=true)
    // breadcrumbs_separators.forEach (breadcrumb_separator => breadcrumb_separator.hidden=true)
    // breadcrumbs_selected.forEach (breadcrumb_selected => breadcrumb_selected.hidden=true)
    // breadcrumbs_clicked.forEach (breadcrumb_clicked => breadcrumb_clicked.hidden=false)
    // breadcrumbs_separators_clicked.forEach (breadcrumb_separator_clicked => breadcrumb_separator_clicked.hidden=false)
    // breadcrumbs_selected_clicked.forEach (breadcrumb_selected_clicked => breadcrumb_selected_clicked.hidden=false)
    button_previous.disabled = true;
    button_next.disabled = true;
    button_new_outfit.hidden = true;
    outfitCosting.innerHTML =
      "<div class='d-flex align-items-center'><i class='fas fa-spin fa-spinner text-success mr-1'></i> <p class='text-success mb-0'>Enregistrement</p></div>";

    document.querySelectorAll(".update_buttons").forEach((button) => {
      button.click();
    });
    updateEquipedAgents;
  };
  const allQuantityFields = document.querySelectorAll(
    ".outfit_quantity > input"
  );
  const sumEquippedAgents = document.querySelector(".equipped_agents");

  const updateEquipedAgents = () => {
    let sumQuantity = 0;
    allQuantityFields.forEach((quantity_input) => {
      parsedQuantity = Number(quantity_input.value) || 0;
      sumQuantity += parsedQuantity;
    });
    sumEquippedAgents.value = sumQuantity.toString().replace(".", ",");
  };

  if (document.getElementById("update_all_outfits_btn")) {
    document
      .getElementById("update_all_outfits_btn")
      .addEventListener("click", updateAll);
  }
  if (allQuantityFields.length !== 0) {
    allQuantityFields.forEach((quantity_input) => {
      quantity_input.addEventListener("change", updateEquipedAgents);
    });
    updateEquipedAgents();
  }
});
