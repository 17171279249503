import React from "react";
import GuardingBreadcrumb from "./GuardingBreadcrumb";
import LowCurrentBreadcrumb from "./LowCurrentBreadcrumb";

function Breadcrumb({
  isBuyer,
  opportunity,
  offer_name,
  opportunity_work,
  configuration,
  vocabulary,
  vocabularyApplication,
  is_last_page,
  site_typologies,
  supervisor,
  documents_disabled,
  costing_enabled,
}) {
  const splittedPathname = window.location.pathname.split("/");
  return (
    <>
      {splittedPathname?.length >= 3 &&
        splittedPathname[2] !== "new" &&
        opportunity_work !== "guarding" && (
          <LowCurrentBreadcrumb
            opportunity={opportunity}
            offer_name={offer_name}
            opportunity_work={opportunity_work}
            configuration={configuration}
            vocabulary={vocabulary}
            vocabularyApplication={vocabularyApplication}
            is_last_page={is_last_page}
            site_typologies={site_typologies}
            supervisor={supervisor}
            documents_disabled={documents_disabled}
          />
        )}
      {opportunity_work === "guarding" &&
        splittedPathname?.length >= 4 &&
        !["edit", "new"].includes(splittedPathname[2]) && (
          <GuardingBreadcrumb
            isBuyer={isBuyer}
            opportunity={opportunity}
            offer_name={offer_name}
            vocabularyApplication={vocabularyApplication}
            site_typologies={site_typologies}
            supervisor={supervisor}
            documents_disabled={documents_disabled}
            costing_enabled={costing_enabled}
          />
        )}
    </>
  );
}

export default Breadcrumb;
