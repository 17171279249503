import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select from "../../react-ui/Select";

function FormattedSelect({
  value,
  options,
  onChange,
  updateItem,
  itemId,
  keyName,
  ...selectProps
}) {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const handleOnChange = (e) => {
    if (onChange) onChange(e);
    setSelected(e);
    if (updateItem)
      updateItem({
        id: itemId,
        [keyName]: e,
      });
  };

  return (
    <StyledSelect
      size="small"
      fontSize="10px"
      onChange={handleOnChange}
      value={selected}
      options={options}
      showSearch={false}
      {...selectProps}
    />
  );
}

const StyledSelect = styled(Select)`
  .ant-select-arrow {
    inset-inline-end: 5px !important;
  }
`;

export default FormattedSelect;
