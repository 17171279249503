import { encodeUrlParams } from "./encodeUrlParams";

export const fetchReferencesUrl = ({ search }) => {
  let url = "/references?";
  const params = {
    ...(search && { search }),
  };

  url += encodeUrlParams(params);

  return url;
};
