import React, { useCallback, useMemo } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import ContactForm, { contactField } from "../../Form/ContactForm";
import { ContactOption } from "../../react-ui/SelectContacts";
import { opportunityField } from "../../constant";
import { genNode } from "./ClientsSelectWrapper";

function ContactModal({
  contactModalOpenFrom,
  setContactModalOpenFrom,
  companyId,
  formOpp,
  setClientsInMemory,
  clientsInMemory,
  setContactsInMemory,
  vocabulary,
}) {
  const clientDisabled =
    contactModalOpenFrom === opportunityField.clientContacts ||
    contactModalOpenFrom === opportunityField.providedCustomerContacts ||
    contactModalOpenFrom === opportunityField.decisionMakerCustomerContacts;
  const formatClients = useCallback((cls) => {
    return cls.map(genNode);
  }, []);

  const onSuccess = (contact) => {
    const contacts = formOpp.getFieldValue(contactModalOpenFrom) || [];

    setContactsInMemory((olds) => [
      {
        ...contact,
        name: `${contact.last_name} ${contact.first_name}`,
      },
      ...olds,
    ]);

    formOpp.setFieldsValue({
      [contactModalOpenFrom]: [
        ...contacts,
        {
          value: contact.id,
          label: <ContactOption value={contact} withLabel />,
        },
      ],
    });
  };

  const initialValues = useMemo(() => {
    if (!clientDisabled) return {};
    let fieldValue;

    if (contactModalOpenFrom === opportunityField.clientContacts) {
      fieldValue = opportunityField.client;
    } else if (
      contactModalOpenFrom === opportunityField.providedCustomerContacts
    ) {
      fieldValue = opportunityField.providedCustomer;
    } else if (
      contactModalOpenFrom === opportunityField.decisionMakerCustomerContacts
    ) {
      fieldValue = opportunityField.decisionMakerCustomer;
    }
    if (!fieldValue) return {};
    const client = clientsInMemory.find(
      (c) => c.id === formOpp.getFieldValue(fieldValue).value
    );

    return {
      [contactField.client]: {
        ...client,
        label: client.name,
        title: genNode(client).title,
        value: client.id,
      },
    };
  }, [clientDisabled, clientsInMemory, contactModalOpenFrom, formOpp]);

  return (
    <StyledModal
      destroyOnClose
      maskClosable={false}
      open={contactModalOpenFrom !== ""}
      footer={null}
      closable={false}
      onCancel={() => setContactModalOpenFrom("")}
      bodyStyle={{ padding: 0 }}
      width={900}
    >
      <ContactForm
        companyId={companyId}
        onSuccess={onSuccess}
        onClose={() => setContactModalOpenFrom("")}
        setClientsInMemory={setClientsInMemory}
        clientsInMemory={clientsInMemory}
        formatClients={formatClients}
        clientDisabled={clientDisabled}
        initialValues={initialValues}
        vocabulary={vocabulary}
      />
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

export default ContactModal;
