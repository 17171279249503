import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import SATableTree, { constructDocumentRow } from "./SATableTree";
import { documentsDataIndex } from "../../Admin-cf/DocumentsFolders/Documents/DocumentsArray";

function DoubleModal({
  addTemplateToTree,
  doubleModal,
  setDoubleModal,
  formToken,
  folders,
  siteTypologies,
}) {
  const [data, setData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const onCancel = () => setDoubleModal();
  const onCheck = useCallback(({ checked: newCheckedKeys }) => {
    setCheckedKeys(newCheckedKeys);
  }, []);

  useEffect(() => {
    if (!doubleModal) {
      setData([]);
      setCheckedKeys([]);
    } else {
      let key = 0;
      const newData = doubleModal.doubles
        .filter(
          (document, index, self) =>
            index === self.findIndex((doc2) => doc2.id === document.id)
        )
        .map((document) => {
          key += 1;
          return constructDocumentRow({
            document,
            folders,
            folderKey: key,
            siteTypologies,
          });
        });
      setData(newData);
      setCheckedKeys(newData.map((document) => document.key));
    }
  }, [doubleModal, folders, siteTypologies]);

  const checkProps = useMemo(
    () => ({
      checkable: true,
      checkStrictly: true,
      checkedKeys,
      setCheckedKeys,
      onCheck,
    }),
    [checkedKeys, onCheck]
  );

  return (
    <StyledModal
      open={!!doubleModal}
      closeIcon={null}
      footer={null}
      maskClosable={false}
      onCancel={onCancel}
      title={
        <FooterContainer>
          <StyledExclamationCircleOutlined />
          Plusieurs documents ont été trouvé pour une même règle. Lesquels
          souhaitez-vous garder ?
        </FooterContainer>
      }
    >
      <SATableTree
        data={data}
        siteTypologies={[]}
        setData={setData}
        formToken={formToken}
        tableHeight={300}
        checkProps={checkProps}
        columnsToShow={[
          documentsDataIndex.created_at,
          documentsDataIndex.site_typologies,
          documentsDataIndex.folders,
        ]}
      />

      <ButtonContainer>
        <Button
          key="2"
          ghost
          type="primary"
          onClick={() => {
            addTemplateToTree({
              selectedTemplate: doubleModal.selectedTemplate,
              treeData: doubleModal.treeData,
              isReinstantiate: doubleModal.isReinstantiate,
              documentIds: data
                .filter((el) => checkedKeys.includes(el.key))
                .map((el) => el.id),
            });
            onCancel();
          }}
        >
          Garder ces documents
        </Button>
      </ButtonContainer>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    width: 800px;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
`;

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  font-size: 22px;
  color: #faad14 !important;
`;

export default DoubleModal;
