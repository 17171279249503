/* eslint-disable no-param-reassign */
import { debounce, get } from "lodash";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { useDrag } from "react-dnd";
import { useInfiniteQuery, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useTodo from "../../../hookQuery/useTodo";
import Separator from "../../../react-ui/Separator";
import ActionsContainer from "../../../react-ui/ActionsContainer";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import TableContainer from "../../CostingMovementsCarrycots/TableContainer";
import { ItemTypes } from "../../itemTypes";
import ListHeader from "./ListHeader";
import ModalItemShow from "./ModalItemShow";
import Eye from "../../../react-ui/Icons/Eye";
import { ouvrageColumns, Smart4Column } from "./TableColumns";
import {
  fetchOuvragesUrl,
  fetchOuvragesProfessionUrl,
} from "../../../../utils/fetchOuvragesUrl";
import {
  fieldsDico,
  handleMaterialSorterChange,
} from "../../../../utils/sorterTable";
import {
  MaterialFetching,
  MaterialFetchingSucess,
} from "./DataProvider/Smart4/DataFetching/MaterialFetching";
import { MaterialFormat } from "./DataProvider/Smart4/FormatData/MaterialFormat";
import {
  PrestationFetching,
  PrestationFetchingSuccess,
} from "./DataProvider/Smart4/DataFetching/PrestationFetching";
import { PrestationFormat } from "./DataProvider/Smart4/FormatData/PrestationFormat";
import {
  BatiprixFetching,
  BatiprixFetchingSuccess,
} from "./DataProvider/Batiprix/DataFetching/BatiprixFecting";
import { OuvrageSmart4Fetching } from "./DataProvider/Smart4/DataFetching/OuvrageSmart4Fetching";
import { OuvrageSmart4Format } from "./DataProvider/Smart4/FormatData/OuvrageSmart4Format";
import { getUrlParams } from "../../../utils";

const selector = (state) => ({
  ediMateriel: state.ediMateriel,
  selectedMateriel: state.selectedMateriel,
  setMaterials: state.setMaterials,
  formToken: state.formToken,
  prestationAssociate: state.prestationAssociate,
});

const debouncedSearch = debounce(
  ({
    materialsNextPage,
    fetchNextPage,
    refetch,
    ouvrageSmart4NextPage,
    ouvrageSmart4FetchNextPage,
    showFaItems,
  }) => {
    // eslint-disable-next-line no-param-reassign
    materialsNextPage.current = 1;
    ouvrageSmart4NextPage.current = 1;
    fetchNextPage({ pageParam: materialsNextPage.current });
    if (!showFaItems) {
      ouvrageSmart4NextPage.current = null;
      ouvrageSmart4FetchNextPage({ pageParam: ouvrageSmart4NextPage.current });
    }
    refetch();
  },
  300
);

const debouncedSearchOuvrage = debounce(
  ({ ouvragesNextPage, ouvragefetchNextPage, from }) => {
    // eslint-disable-next-line no-param-reassign
    if (from !== "configuration") {
      ouvragesNextPage.current = 1;
      ouvragefetchNextPage({ pageParam: ouvragesNextPage.current });
    }
  },
  500
);

const colGap = "8%";

function ListSection({
  opportunityType,
  faId,
  opportunity_work,
  configuration,
  vocabulary,
  api_providers_length,
  defaultMinutes,
  company_work,
  from,
  entity_work_id,
  selectedZoneTemplate,
}) {
  const { id_opportunity } = useParams();
  const { formToken } = useStore(selector);

  const [wordEntered, setWordEntered] = useState("");
  const [wordEnteredOuvrage, setWordEnteredOuvrage] = useState("");
  const [wordEnteredOuvrageSmart, setWordEnteredOuvrageSmart] = useState("");
  const [filters, setFilters] = useState({
    prestations: true,
    subcontractings: true,
    materials: true,
    ouvrages: configuration.ouvrage,
    groups: configuration.group,
  });
  const [showFaItems, setShowFaItems] = useState(!!faId);
  const [materials, setMaterials] = useState([]);
  const [prestations, setPrestations] = useState([]);
  const [prestaData, setPrestaData] = useState([]);
  const [ouvrageData, setOuvrageData] = useState([]);
  const [ouvrageBatiprix, setOuvrageBatiprix] = useState([]);
  const [ouvrageMode, setOuvragMode] = useState(configuration.ouvrage);
  const [ouvrageProfessions, setOuvrageProfessions] = useState([]);
  const [ouvrageProvider, setOuvrageProvider] = useState(
    configuration.ouvrage_option.ouvrage_default ? "batiprix" : "smart4"
  );
  const [materialData, setMaterialData] = useState([]);
  const [compatibleMaterials, setCompatibleMaterials] = useState([]);
  const [categoryFiltered, setCategoryFiltered] = useState();
  const [modalType, setModalType] = useState(null);
  const [itemToShowId, setItemToShowId] = useState(null);
  const [sortBy, setSortBy] = useState();
  const [isModalFormVisible, setIsModalFormVisible] = useState(false);
  const [typeForm, setTypeForm] = useState(null);
  const [ouvrageIdSelected, setOuvrageIdSelected] = useState(null);
  const [ouvrages, setOuvrages] = useState([]);
  const [tableRowsData, setTableRowData] = useState([]);
  const [ouvrageSmart4, setOuvrageSmart4] = useState([]);
  const [ouvrageSmart4Data, setOuvrageSmart4Data] = useState([]);
  const materialsNextPage = useRef(1);
  const ouvragesNextPage = useRef(1);
  const ouvrageSmart4NextPage = useRef(1);
  const [selectedNomenclature, setSelectedNomenclature] = useState("");
  const [expendNomenclature, setExpendNomenclature] = useState(null);
  const [categoryOuvrage, setCategoryOuvrage] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [filterOuvrageSmart, setFilterOuvrageSmart] = useState([]);
  const nomenclaturePosition = useRef(1);
  // Check if useInfiniteQuery has trigger one time
  const didFirstInfiniteQuery = useRef(false);

  const urlUnities =
    from !== "admin" && from !== "configuration"
      ? `/unity_by_opportunity?from=${from}&id=${id_opportunity}`
      : `/unity_by_opportunity?from=${from}&id=${getUrlParams()}`;

  const { data: unities } = useTodo(formToken, urlUnities, "Unities");

  const urlManufacturers =
    from !== "admin" && from !== "configuration"
      ? `/manufacturers?from=${from}&id=${id_opportunity}`
      : `/manufacturers?from=${from}&id=${getUrlParams()}`;

  const { data: manufacturers } = useTodo(
    formToken,
    urlManufacturers,
    "Manufacturers"
  );

  const urlDistributors =
    from !== "admin" && from !== "configuration"
      ? `/distributors?from=${from}&id=${id_opportunity}`
      : `/distributors?from=${from}&id=${getUrlParams()}`;

  const { data: distributors } = useTodo(
    formToken,
    urlDistributors,
    "Distributors"
  );

  const { data: categories } = useTodo(
    formToken,
    `/category_profession_by_opportunity/${id_opportunity}`,
    "Categories"
  );

  const urlProfils =
    from !== "admin" && from !== "configuration"
      ? `/profil_by_opportunity?from=${from}&id=${id_opportunity}`
      : `/profil_by_opportunity?from=${from}&id=${getUrlParams()}`;

  const { data: profils } = useTodo(formToken, urlProfils, "Profils");

  const { refetch, isLoading: prestationsLoading } = useQuery(
    "Prestations",
    PrestationFetching({
      formToken,
      showFaItems,
      id: from === "admin" ? entity_work_id : id_opportunity,
      wordEntered,
      compatibleMaterials,
      opportunityType,
      faId,
      sortBy,
      from,
      fieldsDico,
      from_zone_template: from === "admin",
      selectedZoneTemplate: from === "admin" ? selectedZoneTemplate : null,
    }),
    PrestationFetchingSuccess({ setPrestations, showFaItems, from })
  );

  const ouvrageProfessionsQueryKey = useMemo(
    () => ["OuvrageProfessions", getUrlParams()],
    [id_opportunity]
  );

  const ouvrageSmart4QueryKey = useMemo(
    () => ["OuvragesSmart4", { id: getUrlParams(), from }],
    [getUrlParams]
  );

  const { data: ouvrage_professions } = useQuery(
    ouvrageProfessionsQueryKey,
    () =>
      getData(
        formToken,
        `/ouvrage_professions?from=${from}&id=${from === "admin" ? entity_work_id : id_opportunity}}`
      ),
    {
      enabled: !showFaItems && from !== "configuration",
      onSuccess: (payload) => {
        setOuvrageProfessions(payload);
      },
    }
  );

  const { fetchNextPage, isFetching } = useInfiniteQuery(
    ["Materials", { from }],
    MaterialFetching({
      showFaItems,
      sortBy,
      id: from === "admin" ? entity_work_id : id_opportunity,
      faId,
      wordEntered,
      categoryFiltered,
      compatibleMaterials,
      materialsNextPage,
      from,
    }),
    MaterialFetchingSucess({
      didFirstInfiniteQuery,
      setMaterials,
      materials,
      materialsNextPage,
      showFaItems,
      ouvrageSmart4NextPage,
      from,
    })
  );

  const groupFilter = useCallback(() => {
    if (filters.groups && filters.ouvrages) {
      return "all";
    }
    if (filters.groups) return filters.groups;
  }, [filters]);

  const {
    fetchNextPage: ouvrageSmart4FetchNextPage,
    isFetching: ouvrageSmart4IsFetching,
  } = useInfiniteQuery(
    ouvrageSmart4QueryKey,
    OuvrageSmart4Fetching({
      formToken,
      showFaItems,
      ouvrageSmart4NextPage,
      categoryFiltered,
      wordEntered,
      group_filter: groupFilter(),
      compatibleMaterials,
      from,
      id: from === "admin" ? entity_work_id : id_opportunity,
      from_zone_template: from === "admin",
      selectedZoneTemplate: from === "admin" ? selectedZoneTemplate : null,
    }),
    {
      enabled: !showFaItems && from !== "configuration",
      refetchOnWindowFocus: false,
      onSuccess: (payload) => {
        if (!didFirstInfiniteQuery.current)
          didFirstInfiniteQuery.current = true;
        const lastPage = payload.pages[payload.pages.length - 1];

        // Reset ouvrages when back to first page of materials
        if (lastPage?.current_page === 1) {
          setOuvrageSmart4(lastPage.ouvrage_professions);
        } else {
          setOuvrageSmart4([...ouvrageSmart4, ...lastPage.ouvrage_professions]);
        }
        ouvrageSmart4NextPage.current = lastPage.next_page;
      },
    }
  );

  const { data: list_nomenclature, refetch: refetchNomenclature } = useQuery(
    "Nomenclature",
    () =>
      getData(
        formToken,
        fetchOuvragesUrl(
          ouvragesNextPage.current,
          nomenclaturePosition.current,
          null,
          expendNomenclature,
          wordEnteredOuvrage,
          id_opportunity
        )
      ),
    {
      refetchOnWindowFocus: false,
      enabled: ouvrageProvider === "batiprix",
      onSuccess: (data) => {
        setTreeData(data?.list_nomenclature);
      },
    }
  );

  useEffect(() => {
    setTreeData([]);
    setExpendNomenclature(null);
    setSelectedNomenclature(null);
  }, [ouvrageProvider]);

  useEffect(() => {
    if (expendNomenclature) {
      refetchNomenclature();
    }
  }, [expendNomenclature]);

  const { fetchNextPage: ouvragefetchNextPage, isFetching: ouvrageIsFetching } =
    useInfiniteQuery(
      "Ouvrages",
      BatiprixFetching({
        formToken,
        ouvragesNextPage,
        nomenclaturePosition,
        selectedNomenclature,
        wordEnteredOuvrage,
        id_opportunity,
      }),
      BatiprixFetchingSuccess({
        ouvrageProvider,
        wordEnteredOuvrage,
        setOuvrageBatiprix,
        ouvrageBatiprix,
        ouvragesNextPage,
        nomenclaturePosition,
        from,
      })
    );
  useEffect(() => {
    if (wordEnteredOuvrage.length > 0) {
      ouvragesNextPage.current = 1;
      if (from !== "configuration" && from !== "admin")
        ouvragefetchNextPage({ pageParam: ouvragesNextPage.current });
    } else {
      // if (!ouvrageMode) {
      //   return;
      // }
      setOuvrageData([]);
      setOuvrageBatiprix([]);
      debouncedSearchOuvrage({ ouvragesNextPage, ouvragefetchNextPage, from });
      // ouvragesNextPage.current = 1;
      // ouvragefetchNextPage({ pageParam: ouvragesNextPage.current });
    }
  }, [wordEnteredOuvrage, selectedNomenclature]);

  const checkScrollPosition = useCallback(
    (event) => {
      const perc =
        (event.target.scrollTop /
          (event.target.scrollHeight - event.target.clientHeight)) *
        100;
      if (
        perc >= 95 &&
        !ouvrageSmart4IsFetching &&
        (filters.ouvrages || filters.groups) &&
        ouvrageSmart4NextPage.current !== null &&
        from !== "configuration" &&
        !showFaItems
      ) {
        ouvrageSmart4FetchNextPage({
          pageParam: ouvrageSmart4NextPage.current,
        });
      }
      if (
        (ouvrageSmart4NextPage.current === null ||
          (!filters.ouvrages && !filters.groups)) &&
        filters.materials &&
        perc >= 95 &&
        !isFetching &&
        materialsNextPage.current !== null
      ) {
        fetchNextPage({ pageParam: materialsNextPage.current });
      }
    },
    [
      ouvrageSmart4FetchNextPage,
      filters,
      ouvrageSmart4IsFetching,
      fetchNextPage,
      isFetching,
      from,
      showFaItems,
    ]
  );

  const checkScrollOuvragePosition = useCallback(
    (e) => {
      if (wordEnteredOuvrage.length > 0) return;
      const perc =
        (e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight)) *
        100;
      if (
        perc >= 95 &&
        !ouvrageIsFetching &&
        ouvragesNextPage.current !== null &&
        from !== "configuration" &&
        from !== "admin"
      ) {
        ouvragefetchNextPage({ pageParam: ouvragesNextPage.current });
      }
    },
    [ouvragefetchNextPage, ouvrageIsFetching]
  );

  const resetMaterials = useCallback(() => {
    materialsNextPage.current = 1;
    fetchNextPage({ pageParam: materialsNextPage.current });
  }, [fetchNextPage]);

  const resetOuvrageSmart4 = useCallback(() => {
    ouvrageSmart4NextPage.current = 1;

    if (from !== "configuration" && !showFaItems)
      ouvrageSmart4FetchNextPage({ pageParam: ouvrageSmart4NextPage.current });
    else ouvrageSmart4NextPage.current = null;
  }, [from, ouvrageSmart4FetchNextPage, showFaItems]);

  useEffect(() => {
    if (from !== "configuration") {
      resetMaterials();
      resetOuvrageSmart4();
      refetch();
    }
  }, [
    compatibleMaterials,
    categoryFiltered,
    resetMaterials,
    resetOuvrageSmart4,
    refetch,
    showFaItems,
    wordEntered,
    sortBy,
  ]);

  useEffect(() => {
    if (didFirstInfiniteQuery.current) {
      if (wordEntered.length > 0) {
        debouncedSearch({
          materialsNextPage,
          fetchNextPage,
          refetch,
          ouvrageSmart4NextPage,
          ouvrageSmart4FetchNextPage,
          showFaItems,
        });
      }
    }
  }, [
    wordEntered,
    fetchNextPage,
    categoryFiltered,
    refetch,
    ouvrageSmart4FetchNextPage,
    showFaItems,
  ]);

  useEffect(() => {
    ouvrageSmart4NextPage.current = 1;
    if (from !== "configuration" && !showFaItems)
      ouvrageSmart4FetchNextPage({ pageParam: ouvrageSmart4NextPage.current });
    else ouvrageSmart4NextPage.current = null;
  }, [from, ouvrageSmart4FetchNextPage, showFaItems]);

  const handleCompatibleClick = useCallback(
    (value) => {
      if (compatibleMaterials?.filter((el) => el.id === value.id).length > 0)
        return;
      setCompatibleMaterials([...compatibleMaterials, value]);
    },
    [compatibleMaterials]
  );

  useEffect(() => {
    let prestaToHandle = prestations;
    if (!filters.prestations) {
      prestaToHandle = prestaToHandle?.filter((el) => el.subcontracting);
    }
    if (!filters.subcontractings) {
      prestaToHandle = prestaToHandle?.filter((el) => !el.subcontracting);
    }
    PrestationFormat({
      setPrestaData,
      prestaToHandle,
      colGap,
      setItemToShowId,
      setModalType,
    });
  }, [prestations, categories, filters]);

  const handleOpenFormModal = (type, ouvrageId) => {
    setOuvrageIdSelected(ouvrageId);
    setIsModalFormVisible(true);
    setTypeForm(type);
  };
  useEffect(() => {
    const provider = {
      batiprix: ouvrageBatiprix,
    };
    let ouvragesToHandle = provider[ouvrageProvider];

    if (ouvrageProvider === "smart4" && filterOuvrageSmart.length > 0) {
      ouvragesToHandle = ouvragesToHandle.ouvrage_professions.filter(
        (ovr) =>
          ovr.category_profession_id ===
          filterOuvrageSmart[filterOuvrageSmart.length - 1]
      );
    }
    if (ouvrageProvider === "batiprix") {
      setOuvrageData(
        ouvragesToHandle?.map((ouvrage) => {
          return {
            ...ouvrage,
            key: ouvrage.Code,
            id: ouvrage.Id,
            flag: "ouvrages",
            name: ouvrage.Label,
            category: ouvrage.category_name
              ? ouvrage.category_name
              : categoryOuvrage,
            action: (
              <ActionsContainer $colGap={colGap}>
                <Eye
                  $fontSize="11px"
                  onClick={() => {
                    setModalType("ouvrage_batiprix");
                    setItemToShowId(ouvrage.Id);
                  }}
                />
              </ActionsContainer>
            ),
          };
        })
      );
    }
  }, [
    categoryOuvrage,
    ouvrageBatiprix,
    ouvrageMode,
    ouvrageProfessions,
    ouvrageProvider,
    filterOuvrageSmart,
    ouvrages,
  ]);

  useEffect(() => {
    let materialsToHandle = materials;
    if (!filters.materials) {
      materialsToHandle = [];
    }
    // if (!filters.ouvrages && materialsNextPage.current === 1) {
    //   resetMaterials();
    // }
    MaterialFormat({
      setItemToShowId,
      setMaterialData,
      setModalType,
      materialsToHandle,
      colGap,
      configuration,
      handleCompatibleClick,
      faId,
    });
  }, [materials, filters, handleCompatibleClick, faId]);

  useEffect(() => {
    let ouvragesSmartToHandle = ouvrageSmart4;
    if (!filters.ouvrages && !filters.groups) {
      ouvragesSmartToHandle = [];
    }
    if (!filters.groups && filters.ouvrages) {
      ouvragesSmartToHandle = ouvragesSmartToHandle.filter(
        (ouvrage) => ouvrage.group === false
      );
    }
    if (filters.groups && !filters.ouvrages) {
      ouvragesSmartToHandle = ouvragesSmartToHandle.filter(
        (ouvrage) => ouvrage.group === true
      );
    }
    OuvrageSmart4Format({
      setOuvrageSmart4Data,
      ouvrageSmart4: ouvragesSmartToHandle,
      setModalType,
      colGap,
      setItemToShowId,
      handleOpenFormModal,
    });
  }, [ouvrageSmart4, filters]);

  const itemDraggable = (record) => {
    if (record) {
      if (record.flag === "materials") {
        return {
          flag: "materials",
          element: materials
            ? materials.find((material) => material.id === record.id)
            : undefined,
        };
      }
      if (record.flag === "prestations") {
        return {
          flag: "prestations",
          element: prestations
            ? prestations.find((prestation) => prestation.id === record.id)
            : undefined,
        };
      }
      if (record?.flag === "ouvrages") {
        if (ouvrageProvider === "batiprix") {
          return {
            flag: "ouvrages",
            element: ouvrageData
              ? ouvrageData.find((ouvrage) => ouvrage.id === record.id)
              : undefined,
          };
        }
        return {
          flag: "ouvrages",
          element: ouvrageSmart4Data
            ? ouvrageSmart4Data.find((ouvrage) => ouvrage.id === record.id)
            : undefined,
        };
      }
    }
    return null;
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function DraggableBodyRow({ index, record, className, style, ...restProps }) {
    const [, drag] = useDrag({
      type: ItemTypes.BOX,
      item: itemDraggable(record),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    return (
      <tr
        ref={drag}
        // eslint-disable-next-line jsx-a11y/aria-role
        role="Box"
        className={className}
        style={{ cursor: "move", ...style }}
        {...restProps}
      />
    );
  }
  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const handleChange = (pagination, fltrs, sorter) => {
    handleMaterialSorterChange({
      pagination,
      fltrs,
      sorter,
      setCategoryFiltered,
      categoryFiltered,
      setSortBy,
      isFa: showFaItems,
    });
  };

  const Ouvrage_table_batiprix = useMemo(() => {
    return (
      <TableContainer
        columns={ouvrageColumns({
          configuration,
          categoryFiltered,
          provider: ouvrageProvider,
        })}
        dataSource={ouvrageData}
        emptyLabel="Sélectionnez une catégorie d'ouvrage"
        scrollY
        bordered={false}
        className="table_batiprix"
        pagination={false}
        loading={ouvrageIsFetching}
        components={components}
        onEndScroll={
          ouvrageProvider !== "smart4" ? checkScrollOuvragePosition : null
        }
        onRow={(record, index) => ({
          index,
          record,
        })}
      />
    );
  }, [
    categoryFiltered,
    checkScrollOuvragePosition,
    components,
    configuration,
    id_opportunity,
    ouvrageData,
    ouvrageIsFetching,
    ouvrageProvider,
    ouvrages,
    tableRowsData,
    wordEntered,
  ]);

  return (
    <Container>
      <ModalItemShow
        setModalType={setModalType}
        modalType={modalType}
        itemToShowId={itemToShowId}
        setItemToShowId={setItemToShowId}
        categories={categories}
        unities={unities}
        manufacturers={manufacturers}
        distributors={distributors}
        showFaItems={showFaItems}
        profils={profils}
        configuration={configuration}
        vocabulary={vocabulary}
        opportunityId={id_opportunity}
      />
      <ListHeader
        from={from}
        setOuvrageIdSelected={setOuvrageIdSelected}
        ouvrageIdSelected={ouvrageIdSelected}
        isModalFormVisible={isModalFormVisible}
        setIsModalFormVisible={setIsModalFormVisible}
        typeForm={typeForm}
        setTypeForm={setTypeForm}
        setFilterOuvrageSmart={setFilterOuvrageSmart}
        setOuvrageProvider={setOuvrageProvider}
        ouvrageProvider={ouvrageProvider}
        wordEntered={wordEntered}
        setWordEntered={setWordEntered}
        wordEnteredOuvrage={wordEnteredOuvrage}
        setWordEnteredOuvrage={setWordEnteredOuvrage}
        setWordEnteredOuvrageSmart={setWordEnteredOuvrageSmart}
        wordEnteredOuvrageSmart={wordEnteredOuvrageSmart}
        filters={filters}
        setFilters={setFilters}
        compatibleMaterials={compatibleMaterials}
        setCompatibleMaterials={setCompatibleMaterials}
        categories={categories}
        id_opportunity={id_opportunity}
        materials={materials}
        setMaterials={setMaterials}
        setPrestations={setPrestations}
        prestations={prestations}
        opportunityType={opportunityType}
        resetMaterials={resetMaterials}
        showFaItems={showFaItems}
        setShowFaItems={setShowFaItems}
        faId={faId}
        opportunity_work={opportunity_work}
        configuration={configuration}
        vocabulary={vocabulary}
        ouvrageMode={ouvrageMode}
        setOuvragMode={setOuvragMode}
        selectedNomenclature={selectedNomenclature}
        setSelectedNomenclature={setSelectedNomenclature}
        setExpendNomenclature={setExpendNomenclature}
        expendNomenclature={expendNomenclature}
        treeData={treeData}
        setCategoryOuvrage={setCategoryOuvrage}
        defaultMinutes={defaultMinutes}
        company_work={company_work}
      />
      <Separator isHorizontal size={3} />
      {ouvrageProvider === "batiprix" ? (
        Ouvrage_table_batiprix
      ) : (
        <TableContainer
          scrollY
          bordered={false}
          className="table__items"
          columns={Smart4Column({
            configuration,
            categories,
            categoryFiltered,
          })}
          dataSource={
            ouvrageSmart4NextPage.current === null ||
            (!filters.ouvrages && !filters.groups)
              ? [...prestaData, ...ouvrageSmart4Data, ...materialData]
              : [...ouvrageSmart4Data, ...prestaData]
          }
          pagination={false}
          components={components}
          onChange={handleChange}
          loading={ouvrageSmart4IsFetching || prestationsLoading || isFetching}
          onRow={(record, index) => ({
            index,
            record,
          })}
          onEndScroll={checkScrollPosition}
          api_providers_length={api_providers_length}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  height: 100%;
`;

export default ListSection;
