/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import Loader from "../../../../react-ui/Loader";

function CustomIconExpandTable({ expanded, onExpand, record }) {
  if (!expanded && record?.isLoading) {
    return <Loader width="100%" height="100%" />;
  }
  if (record?.recordType === "prestation") return <div />;
  if (expanded) {
    return (
      <button
        type="button"
        className="ant-table-row-expand-icon ant-table-row-expand-icon-expanded"
        aria-expanded="true"
        onClick={(e) => onExpand(record, e)}
      />
    );
  }
  return (
    <button
      type="button"
      className="ant-table-row-expand-icon ant-table-row-expand-icon-collapsed"
      aria-expanded="false"
      onClick={(e) => onExpand(record, e)}
    />
  );
}

export default CustomIconExpandTable;
