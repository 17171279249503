import React from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import { useQuery } from "react-query";
import { getData } from "../request/instance";
import OffersHidden from "./offers";
import OpportunitiesHidden from "./opportunities";

function ManageHidden({ token }) {
  const { data: offers_hidden } = useQuery("HiddenOffers", () =>
    getData(token, `/admin/hiddens/offers`)
  );

  const { data: opportunities_hidden } = useQuery("HiddenOpportunities", () =>
    getData(token, `/admin/hiddens/opportunities`)
  );

  const items = [
    {
      label: "Offres",
      key: "item-1",
      children: <OffersHidden offers_hidden={offers_hidden} />,
    },
    {
      label: "Opportunités",
      key: "item-2",
      children: (
        <OpportunitiesHidden opportunities_hidden={opportunities_hidden} />
      ),
    },
  ];

  return (
    <StyledTabs
      defaultActiveKey="1"
      type="card"
      style={{ height: "calc(100% - 70px)", overflow: "auto" }}
      destroyInactiveTabPane
      items={items}
    />
  );
}

const StyledTabs = styled(Tabs)`
  .ant-tabs-content {
    height: 100%;
  }
`;

export default ManageHidden;
