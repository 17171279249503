import React, { useMemo } from "react";
import { bool, func, object, shape } from "prop-types";
import { formatPercentage } from "../../utils";
import Form from "../../Form";

function MajorationForm({
  isShowing,
  onSubmit,
  updateOnChange,
  submit,
  initialMajoration,
}) {
  const formFactory = useMemo(() => {
    const firstRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Nom de la majoration",
          },
          name: "name",
          label: "Intitulé",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            onInput: (e) => {
              e.currentTarget.value = formatPercentage(e.currentTarget.value);
            },
          },
          name: "rate",
          label: "Coefficient",
        },
      },
    ];

    const secondRow = [
      {
        type: "textarea",
        item: {
          textarea: {
            placeholder: "Ajoutez une description",
          },
          name: "description",
          label: "Description",
        },
      },
    ];

    return [
      { columns: firstRow, key: "name+rate" },
      { columns: secondRow, key: "description" },
    ];
  }, []);

  const initialValues = useMemo(() => {
    if (!initialMajoration) return {};
    const rate = initialMajoration.rate
      ? formatPercentage(initialMajoration.rate.toString())
      : undefined;
    return {
      name: initialMajoration.name,
      rate,
      description: initialMajoration.description,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMajoration?.id]);

  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={formFactory}
      isShowing={isShowing}
      id="parameter-form"
    />
  );
}

MajorationForm.propTypes = {
  isShowing: bool,
  onSubmit: func,
  updateOnChange: bool,
  submit: shape({
    onCancel: func,
  }),
  initialMajoration: object,
};

MajorationForm.defaultProps = {
  isShowing: false,
  onSubmit: undefined,
  updateOnChange: false,
  submit: undefined,
  initialMajoration: undefined,
};

export default MajorationForm;
