import React from "react";
import { Select } from "antd";
import styled from "styled-components";

function SelectComponent({
  label,
  element,
  name,
  required,
  defaultTVA,
  handleChange,
  handleTVA,
}) {
  if (name === "Gestion de la TVA") {
    return (
      <SelectItem
        className="select"
        defaultValue={defaultTVA.special_tva ? "special" : "default"}
        size="small"
        onChange={handleTVA}
      >
        <Select.Option value="default">taux normal</Select.Option>
        <Select.Option value="special">taux spécial</Select.Option>
      </SelectItem>
    );
  }
  return (
    <SelectItem
      className="select"
      defaultValue={required ? "required" : "optional"}
      size="small"
      onChange={handleChange}
    >
      <Select.Option value="required">Obligatoire</Select.Option>
      <Select.Option value="optional">Facultatif</Select.Option>
    </SelectItem>
  );
}

const SelectItem = styled(Select)`
  width: 143px;
  margin: 0;

  .ant-select-selector {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default SelectComponent;
