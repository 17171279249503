import Search from "antd/lib/input/Search";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Checkbox } from "antd";
import { useQuery, useQueryClient } from "react-query";
import { debounce } from "lodash";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { numberSorter } from "../../../../utils/numberSorter";
import { getData } from "../../../request/instance";
import { fetchPrestationsUrl } from "../../../../utils/fetchPrestationsUrl";
import { getFetchUrlIdAndFrom } from "../../../../utils/getFetchUrlIdAndFrom";
import { getUrlParams } from "../../../utils";

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function PrestationLinked({
  vocabulary,
  from,
  faIsValidate,
  faId,
  selectedGrouping,
  updateGrouping,
  token,
}) {
  const queryClient = useQueryClient();
  const [wordEntered, setWordEntered] = useState("");
  const [tableRowsData, setTableRowData] = useState([]);
  const [prestations, setPrestations] = useState([]);
  const { refetch } = useQuery(
    "Prestations",
    () =>
      getData(
        token,
        fetchPrestationsUrl({
          ...getFetchUrlIdAndFrom({
            from,
            faIsValidate,
            faId,
            entityWorkId: getUrlParams(),
          }),
          search: wordEntered,
        })
      ),
    {
      onSuccess: (data) => {
        setPrestations(data);
      },
    }
  );

  useEffect(() => {
    queryClient.invalidateQueries("Prestations");
  }, [selectedGrouping]);

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  useEffect(() => {
    const dataResult = [];
    let child;

    prestations?.forEach((value) => {
      child = {
        ...value,
        key: value.id,
        id: value.id,
        name: value.prestation_included
          ? value.prestation_included.name
          : value.name,
        is_in_framework_agreement:
          value.is_in_framework_agreement || value.prestation_included,
        is_package: value.is_package,
      };
      dataResult.push(child);
    });
    setTableRowData(dataResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestations, selectedGrouping]);

  const checkIfPrestationIsInGrouping = useCallback(
    (grouping_id) => {
      if (
        grouping_id === selectedGrouping.id ||
        grouping_id === null ||
        grouping_id === undefined
      )
        return true;
      return false;
    },
    [selectedGrouping]
  );

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };

  const clearInput = () => {
    setWordEntered("");
  };

  const colPresta = useMemo(
    () => [
      {
        title: "Intitulé",
        dataIndex: "name",
        key: "name",
        sorter: true,
      },
      {
        title: "Regrouper",
        dataIndex: "grouping_id",
        defaultSortOrder: "descend",
        width: "80px",
        sorter: (a, b) =>
          numberSorter(a.grouping_id, b.grouping_id) ||
          numberSorter(a.id, b.id),
        render: (grouping_id, record) => (
          <CenteredContainer>
            <Checkbox
              onChange={({ target: { checked } }) =>
                updateGrouping(
                  {
                    id: selectedGrouping.id,
                    prestation_profession_id: record.id,
                    checked,
                  },
                  {
                    onSuccess: (grouping) => {
                      setTableRowData((oldTableRowData) => {
                        const newTableRowData = [...oldTableRowData];
                        const recordIndex = newTableRowData.findIndex(
                          (material) => material.id === record.id
                        );
                        newTableRowData[recordIndex] = {
                          ...record,
                          grouping_id: record.grouping_id
                            ? undefined
                            : grouping.id,
                          grouping_show_editor: false,
                        };
                        return newTableRowData;
                      });
                    },
                  }
                )
              }
              disabled={!checkIfPrestationIsInGrouping(grouping_id)}
              defaultChecked={
                !(grouping_id === null || grouping_id === undefined)
              }
            />
          </CenteredContainer>
        ),
      },
      {
        title: "Visualiser dans Editor",
        dataIndex: "grouping_show_editor",
        width: "80px",
        align: "center",
        defaultSortOrder: "descend",
        render: (grouping_show_editor, record) => (
          <CenteredContainer>
            <Checkbox
              onChange={({ target: { checked } }) => {
                updateGrouping(
                  {
                    id: selectedGrouping.id,
                    prestation_profession_id: record.id,
                    checked_show: checked,
                  },
                  {
                    onSuccess: () => {
                      setTableRowData((oldTableRowData) => {
                        const newTableRowData = [...oldTableRowData];
                        const recordIndex = newTableRowData.findIndex(
                          (material) => material.id === record.id
                        );
                        newTableRowData[recordIndex] = {
                          ...record,
                          grouping_show_editor: !record.grouping_show_editor,
                        };
                        return newTableRowData;
                      });
                    },
                  }
                );
              }}
              checked={record.grouping_show_editor === true}
              disabled={
                !checkIfPrestationIsInGrouping(record.grouping_id) ||
                record.grouping_id === null ||
                record.grouping_id === undefined
              }
            />
          </CenteredContainer>
        ),
      },
    ],
    [selectedGrouping, updateGrouping, checkIfPrestationIsInGrouping]
  );

  return (
    <>
      <StyledSearch
        allowClear
        placeholder="Rechercher par nom de prestation ou catégorie"
        value={wordEntered}
        onChange={handleFilter}
      />
      <TableContainer
        dataSource={tableRowsData}
        columns={colPresta}
        bordered={false}
        pagination={false}
      />
    </>
  );
}

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSearch = styled(Search)`
  margin: 10px 0;
`;

export default PrestationLinked;
