import React from "react";
import styled from "styled-components";

function Warning({ className = "", ...iconProps }) {
  return (
    <StyledIcon
      className={`fa-duotone fa-exclamation-triangle ${className}`}
      {...iconProps}
    />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
`;

export default Warning;
