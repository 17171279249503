import React from "react";
import { Route, Routes } from "react-router-dom";
import OfferReview from "../offer-review";
import Offers from "../offers";
import OfferDetails from "../offers/offer-details/offerDetails";

function OffersRoutes(props) {
  return (
    <Routes>
      <Route
        path=":offer_id/offer_review"
        element={
          <OfferReview
            companyId={props.company_id}
            offerReviewId={props.offer_review_id}
            clientsList={props.clients_list}
            decisionClientsList={props.decision_clients_list}
            providedClientsList={props.provided_clients_list}
            typeAosList={props.type_aos_list}
            variantesList={props.variantes_list}
            visiteSitesList={props.visite_sites_list}
            reprisePersonnelsList={props.reprise_personnels_list}
            kindMarketsList={props.kind_markets_list}
            subcontractingsList={props.subcontractings_list}
            priceRevisionModalitiesList={props.price_revision_modalities_list}
            userName={props.user_name}
            collaboratorsList={props.collaborators_list}
            entities={props.entities}
            technicalsConformList={props.technicals_conform_list}
            contractsConformList={props.contracts_conform_list}
            groupTypesList={props.group_types_list}
            mandataryTypesList={props.mandatary_types_list}
          />
        }
      />
      <Route
        path=""
        element={
          <Offers
            token={props.token}
            role_user={props.role_user}
            configuration={props.configuration}
            vocabularyApplication={props.vocabulary_application}
            configApplication={props.config_application}
            works={props.works}
          />
        }
      />
      <Route
        path=":offer_id"
        element={
          <OfferDetails
            token={props.token}
            offer_id={props.offer_id}
            configuration={props.configuration}
            vocabulary={props.vocabulary}
            configApplication={props.config_application}
            vocabularyApplication={props.vocabulary_application}
            entities={props.entities}
            userEntityId={props.user_entity_id}
            siteTypologies={props.site_typologies}
            supervisor={props.supervisor}
          />
        }
      />
    </Routes>
  );
}

export default OffersRoutes;
