import React from "react";
import styled, { css } from "styled-components";
import EditButton from "../../../react-ui/EditButton";
import SaveButton from "../../../react-ui/SaveButton";

function ButtonCol({ isUpdating, isShowing, setStatus, isLoading }) {
  const allowUserEdition = () => {
    setStatus("update");
  };

  return (
    <Container>
      {isShowing && (
        <StyledEditButton
          label="Editer"
          onClick={allowUserEdition}
          fontSize="14px"
          type="button"
        />
      )}
      {isUpdating && (
        <StyledSaveButton
          label="Enregistrer"
          loading={isLoading}
          fontSize="14px"
          type="submit"
          disabled={isLoading}
        />
      )}
    </Container>
  );
}

const buttonStyle = css`
  width: 100px;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: baseline;
`;

const StyledEditButton = styled(EditButton)`
  ${buttonStyle}
`;
const StyledSaveButton = styled(SaveButton)`
  ${buttonStyle}
`;
export default ButtonCol;
