$(document).ready(() => {

  const opportunityNew = document.getElementById("create_opportunity")
  const opportunityEdit = document.getElementById("edit_opportunity")

  if(!opportunityNew && !opportunityEdit){
    return
  }

const formNewOpportunity = document.getElementById('new_site_typology');
const selectDropDownTypologyItems = document.querySelector('.opportunity_typologies_opportunity_ids');
const formInputTypologyName = document.getElementById('site_typology_typology');

if (formNewOpportunity) {
  // POST REQUEST
  const addNewTypologie = () => {
    // Sending data to the serveur at the url specify
    // Where I will receive the post
    fetch("/site_typologies", {
      method: "POST",
      headers: { "Content-Type": "application/json",
                 "Accept": "application/json",
                 "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
               },
      body: JSON.stringify({ "typology": formInputTypologyName.value })
    }).then(response => response.json())
      .then((data) => {
        eval(data)
      })
      $("#modal_typology").modal('hide');
  }
  formNewOpportunity.addEventListener('submit', addNewTypologie);
}
if (selectDropDownTypologyItems) {
  // GET REQUEST
  const readDropDown = () => {
    fetch('/site_typologies', {
      method: "GET",
      headers: { "Content-Type": "application/json",
                 "Accept": "application/json",
                 "X-Requested-With": "XMLHttpRequest",
                 "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
               }
    })
      .then(response => response.json())
      .then((data) => {
        eval(data)
        // order by id data JSON
        const dynamicSort = (property) => {
            let sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }

        data.sort(dynamicSort("id"));
        // select the last element to insert only it
        const element = data[data.length -1];
        if (element) {
          const selectDropdown = $(".dropdown-typology");
          // delete all items of the native select element
          selectDropdown.innerHTML= "";
          if ($(`.dropdown-typology option[value='${element.id}']`).length === 0) {
            selectDropdown.append("<option value=\"" +  element.id + "\">" +  element.typology + "</option>");
          }
        }
      })
      // refresh the input intitule of TypologieForm
      formInputTypologyName.value = "";
  }
  selectDropDownTypologyItems.addEventListener('mouseover', readDropDown);
}
})
