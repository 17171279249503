import React from "react";
import styled from "styled-components";
import { formatNumberString } from "../../../utils/formatNumberString";
import { marginToCoeff } from "../../../utils/marginConverter";
import ActionsContainer from "../../react-ui/ActionsContainer";
import Delete from "../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../confirmModal";

const getMargin = (isUsingMargin, marginRate, calculMethod) => {
  const margin = formatNumberString({
    str: marginToCoeff({ isUsingMargin, marginRate, calculMethod }),
    isCoeff: !isUsingMargin,
  });
  return isUsingMargin ? `${margin}%` : `${margin}`;
};

// const getTvaProfession = (tvaProfessionName) => {

// }

export const translate_data = ({
  items,
  handleDelete,
  id = null,
  link = "parent_category_id",
  isUsingMargin,
  calculMethod,
}) =>
  items
    ?.filter((item) => item[link] === id)
    ?.map((item) => ({
      ...item,
      title: `${item.title} (${getMargin(
        isUsingMargin,
        item.determined_margin_rate,
        calculMethod
      )} | ${item.tva_profession_name})`,
      key: item.id,
      icon:
        handleDelete && item.is_deletable ? (
          <StyledActionsContainer center>
            <Delete
              onClick={() =>
                ShowDeleteConfirm(item.title, item.id, handleDelete)
              }
            />
          </StyledActionsContainer>
        ) : undefined,
      children: translate_data({
        items,
        handleDelete,
        id: item.id,
        isUsingMargin,
        calculMethod,
      }),
    }));

export const translate_filter = (
  items,
  id = null,
  link = "parent_category_id",
  nameKey = "title"
) =>
  items
    ?.filter((item) => (link ? item[link] === id : true))
    ?.map((item) => ({
      ...{
        text: item[nameKey],
        value: `${item.id}`,
        children: link ? translate_filter(items, item.id, link, nameKey) : null,
      },
    }));

export const formatBreadCrumb = (
  categories,
  categorie_start,
  stringConcact
) => {
  const findCategorie = categories?.find(
    (categorie) => categorie.id === categorie_start.parent_category_id
  );
  if (findCategorie) {
    formatBreadCrumb(
      categories,
      findCategorie,
      findCategorie.title.concat("/", stringConcact)
    );
  } else {
    return stringConcact;
  }
};

const StyledActionsContainer = styled(ActionsContainer)`
  height: 100%;
`;
