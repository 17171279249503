import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useIsMutating, useQuery, useQueryClient } from "react-query";
import { useStore } from "../../../store";
import useUpdateTodo from "../../../hookQuery/useUpdateTodo";
import { currency_cents_to_euros } from "../../../utils";
import TableContainer from "../../CostingMovementsCarrycots/TableContainer";
import Input from "../../../react-ui/Input";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { stringToFloat } from "../../../../utils/stringToFloat";
import { eurosToCents } from "../../../../utils/currencyConverter";
import { getMarginCol } from "../../../../constants/marginCol";
import { getData } from "../../../request/instance";
import WarningLabel from "../../../react-ui/WarningLabel";
import { fakeResumeData } from "../../../data-utils/page3-data";

const store = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
function ResumeTable({ configuration, from, hasOption }) {
  const queryClient = useQueryClient();
  const isMutating = useIsMutating();
  const { formToken, calculMethod, isUsingMargin } = useStore(store);
  const { id_opportunity } = useParams();

  const [deliveryCents, setDeliveryCents] = useState(null);
  const [deliveryPercentage, setDeliveryPercentage] = useState(null);
  const { data: opportunity_quotation_lc, isLoading } = useQuery(
    "OpportunityCotationLc",
    () => getData(formToken, `/opportunity_quotation_lc/${id_opportunity}`),
    {
      enabled: from !== "configuration",
      onSuccess: () => queryClient.invalidateQueries("Warnings"),
    }
  );

  const { mutate: update_opportunity_quotation_lc } = useUpdateTodo(
    formToken,
    `/opportunity_quotation_lc/update`,
    "OpportunityCotationLc"
  );

  const data = [
    {
      key: "1",
      id: opportunity_quotation_lc?.id,
      selling_price_before: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.total_selling_price_cents
        ),
        nbDecimal: 2,
        space: true,
      }),
      delivery_cents: formatNumberString({
        str: currency_cents_to_euros(opportunity_quotation_lc?.delivery_cents),
        nbDecimal: 2,
        space: true,
      }),
      delivery_percentage: formatNumberString({
        str: opportunity_quotation_lc?.delivery_percentage,
        nbDecimal: 2,
      }),
      selling_price_after: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.selling_price_after_delivery
        ),
        nbDecimal: 2,
        space: true,
      }),
      margin_after_discount: opportunity_quotation_lc?.margin_after_delivery,
      selling_price_ttc: formatNumberString({
        str: currency_cents_to_euros(
          opportunity_quotation_lc?.selling_price_ttc
        ),
        nbDecimal: 2,
        space: true,
      }),
    },
  ];

  const resumeArray = (warnings) => [
    {
      title: "Prix de vente HT avant remise",
      dataIndex: "selling_price_before",
      width: "20%",
      align: "right",
      render: (value) => <div>{value} €</div>,
    },
    {
      title: "Remise",
      dataIndex: "discount",
      align: "center",
      width: "20%",
      render: (value, globalValue) => (
        <>
          <InputMargin
            suffix="%"
            textAlign="right"
            id={globalValue.id}
            value={
              deliveryPercentage?.[globalValue.id] != null
                ? deliveryPercentage?.[globalValue.id]
                : globalValue.delivery_percentage
            }
            onBlur={(e) => {
              if (deliveryPercentage) {
                update_opportunity_quotation_lc(
                  JSON.stringify({
                    opportunity_id: id_opportunity,
                    delivery_percentage: stringToFloat(e.target.value),
                  })
                );
              }

              setDeliveryPercentage(null);
            }}
            onChange={(e) =>
              setDeliveryPercentage((v) => ({
                ...v,
                [e.target.id]: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              }))
            }
            size="small"
            fontSize="10px"
            disabled={from === "configuration"}
          />
          <InputCost
            id={globalValue.id}
            textAlign="right"
            value={
              deliveryCents?.[globalValue.id] != null
                ? deliveryCents?.[globalValue.id]
                : globalValue.delivery_cents
            }
            onBlur={(e) => {
              if (deliveryCents) {
                update_opportunity_quotation_lc(
                  JSON.stringify({
                    opportunity_id: id_opportunity,
                    delivery_cents: eurosToCents(e.target.value),
                  })
                );
              }
              setDeliveryCents(null);
            }}
            onChange={(e) =>
              setDeliveryCents((v) => ({
                ...v,
                [e.target.id]: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              }))
            }
            onFocus={(e) =>
              setDeliveryCents((v) => ({
                ...v,
                [e.target.id]: formatNumberString({
                  str: e.target.value,
                  nbDecimal: 2,
                }),
              }))
            }
            suffix="€"
            size="small"
            fontSize="10px"
            disabled={from === "configuration"}
          />
        </>
      ),
    },
    configuration.costing.page_3.show_margin
      ? {
          title: "Après remise",
          children: [
            {
              title: "Prix HT",
              dataIndex: "selling_price_after",
              width: "20%",
              align: "right",
              render: (value) => {
                return (
                  <WarningLabel
                    hasWarning={
                      warnings &&
                      warnings.some(({ rule }) => {
                        return rule.rule_on === "final_price";
                      })
                    }
                  >
                    {value} €
                  </WarningLabel>
                );
              },
            },
            getMarginCol({
              configuration,
              isUsingMargin,
              calculMethod,
              width: "20%",
              keyName: "margin_after_discount",
              hasSorter: false,
              hidden: true,
              warningLabel: true,
              hasWarning:
                warnings &&
                warnings.some(({ rule }) => {
                  return rule.rule_on === "margin";
                }),
            }),
            {
              title: "Prix de vente TTC",
              dataIndex: "selling_price_ttc",
              width: "20%",
              align: "right",
              render: (value) => <div>{value} €</div>,
            },
          ],
        }
      : {
          title: "Après remise",
          children: [
            {
              title: "Prix HT",
              dataIndex: "selling_price_after",
              width: "15%",
              align: "right",
              render: (value) => {
                return (
                  <WarningLabel
                    hasWarning={
                      warnings &&
                      warnings.some(({ rule }) => {
                        return rule.rule_on === "final_price";
                      })
                    }
                  >
                    {value} €
                  </WarningLabel>
                );
              },
            },
            {
              title: "Prix de vente TTC",
              dataIndex: "selling_price_ttc",
              width: "15%",
              align: "right",
              render: (value) => <div>{value} €</div>,
            },
          ],
        },
  ];

  return (
    <Container>
      <h4 id="total_cost_price">
        {hasOption ? "TOTAL (hors option)" : "TOTAL"}
      </h4>
      <TableContainer
        hoverBackground={false}
        columns={resumeArray(
          queryClient.getQueryData([
            "Warnings",
            { opportunityId: parseInt(id_opportunity, 10) },
          ])
        )}
        data={from === "configuration" ? fakeResumeData : data}
        pagination={false}
        loading={!!isMutating || isLoading}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
`;

const InputMargin = styled(Input)`
  margin-bottom: 2px;
  width: 60%;
`;

const InputCost = styled(Input)`
  margin-bottom: 2px;
  width: 60%;
`;

export default ResumeTable;
