import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submitallbtn", 'previous', 'next']

  disabled_breadcrumb(){
    let breadcrumbs = document.getElementsByClassName('breadcrumb')
    let breadcrumbs_separators = document.getElementsByClassName('breadcrumb-separator')
    let breadcrumbs_selected = document.getElementsByClassName('breadcrumb-selected')
    let breadcrumbs_clicked = document.getElementsByClassName('breadcrumb-clicked')
    let breadcrumbs_separators_clicked = document.getElementsByClassName('breadcrumb-separator-clicked')
    let breadcrumbs_selected_clicked = document.getElementsByClassName('breadcrumb-selected-clicked')
    breadcrumbs.forEach (breadcrumb => breadcrumb.hidden=true)
    breadcrumbs_separators.forEach (breadcrumb_separator => breadcrumb_separator.hidden=true)
    breadcrumbs_selected.forEach (breadcrumb_selected => breadcrumb_selected.hidden=true)
    breadcrumbs_clicked.forEach (breadcrumb_clicked => breadcrumb_clicked.hidden=false)
    breadcrumbs_separators_clicked.forEach (breadcrumb_separator_clicked => breadcrumb_separator_clicked.hidden=false)
    breadcrumbs_selected_clicked.forEach (breadcrumb_selected_clicked => breadcrumb_selected_clicked.hidden=false)
  }

  disabled_buttons_next() {
    let button_next = this.nextTarget;
    let button_submitallbtn = this.submitallbtnTarget;
    let button_edit_agent = document.getElementById("edit_button");
    // this.disabled_breadcrumb()
    button_next.disabled = true
    button_edit_agent.hidden = true
    button_submitallbtn.setAttribute('class', 'disabled');
  }

  disabled_buttons_previous() {
    let button_previous = this.previousTarget;
    let button_submitallbtn = this.submitallbtnTarget;
    let button_edit_agent = document.getElementById("edit_button");
    // this.disabled_breadcrumb()
    button_previous.disabled = true
    button_edit_agent.hidden = true
    button_submitallbtn.setAttribute('class', 'disabled');
  }

disabled_buttons(){
  let button_next = this.nextTarget;
  let button_previous = this.previousTarget;
  let button_submitallbtn = this.submitallbtnTarget;
  let button_edit_agent = document.getElementById("edit_button");
  // this.disabled_breadcrumb()
  button_next.disabled = true
  button_previous.disabled = true
  button_edit_agent.hidden = true
  button_submitallbtn.setAttribute('class', 'disabled');
}  

  update_all_agents(event) {
    event.preventDefault();
    let button_previous = this.previousTarget;
    let button_next = this.nextTarget;
    let button_edit_agent = document.getElementById("edit_button");
    // this.disabled_breadcrumb()
    button_previous.disabled = true
    button_next.disabled = true
    button_edit_agent.hidden = true
    
    let button = document.getElementById("save_agents")
    this.submitallbtnTarget.innerHTML = "<div class='d-flex align-items-center'><span class=' mr-1 text-blue fas fa-spin fa-spinner' style='height:15px' role='status' aria-hidden='true'></span><p class='text-blue mb-0'>Enregistrement en cours</p></div> ";
    button.style.pointerEvents = "none";
    let tailleArray = document.querySelectorAll('form.update-agent');
    let nbAgents = tailleArray.length;
    let tailleFetch = tailleArray.length;
    let scalingoQueuing = 100;
    let multiple = 1;
    let counter = 0 ;
    let scalingoWaitingTime = 10000;
    let numberOfClusters = Math.floor(tailleFetch/scalingoQueuing)
    let timeDelay = ((0.083 * nbAgents + 1.98) * 1.2 * 1000 + numberOfClusters*scalingoWaitingTime).toFixed(0);
    

    function delay() {
    return new Promise(resolve => setTimeout(resolve, scalingoWaitingTime));
    }

    async function delayedLog(item) {
      // notice that we can await a function
      // that returns a promise
      await delay();
    }

  function saveAgent(form) {
    fetch(form.action, {
      method: 'POST',
      body: new FormData(form),
    }).then(function (response) {
      if (response.ok) {
        return response.text();
      }
      return Promise.reject(response);
    }).then(function (data) {
      console.log(data);
    }).catch(function (error) {
      console.warn(error);
    });
  }

  async function parcoursAgents(array){
    for (const agent of array){
      if (counter<multiple*scalingoQueuing){
        saveAgent(agent)
      } else {
        multiple += 1;
        await delayedLog(counter);
        saveAgent(agent)
      }
      counter+=1;
    }
  }

 parcoursAgents(tailleArray);

       setTimeout(() => {
         this.submitallbtnTarget.innerHTML = "<div class='d-flex align-items-center'><i class='fas fa-check text-success mr-1'></i> <p class='text-success mb-0'>Modifications enregistrées - Rechargement de la page en cours</p></div>";
         window.location.reload();
       }
         , timeDelay)
      
  }
}