const PreloadingItem = ({ setData, data, recordType, zone_id, name }) => {
  setData([
    {
      isLoading: true,
      entitled: name,
      zone_id,
      recordType,
    },
    ...data,
  ]);
};
export default PreloadingItem;
