import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ConfigProvider, Table } from "antd";
import {
  arrayOf,
  array,
  func,
  shape,
  string,
  bool,
  number,
  oneOfType,
  node as propNode,
} from "prop-types";
import styled, { css } from "styled-components";
import { debounce } from "lodash";
import AddButton from "../../react-ui/AddButton";
import AddLineButton from "../../react-ui/AddLineButton";
import Loader from "../../react-ui/Loader";

function TableContainer({
  header,
  columns,
  expandedRowRender,
  data,
  bordered,
  scrollY,
  paddingBottomScrollY,
  emptyLabel,
  onEndScroll,
  isExpandTableLinked,
  hideIfNoData,
  className,
  fontSize,
  onRowAction,
  coloredRowOfferPage,
  rowSelectableId,
  rowClassName,
  isRowSelectable,
  hoverBackground,
  api_providers_length,
  ...tableProps
}) {
  const [tableHeight, setTableHeight] = useState(600);

  const ref = useRef();

  useLayoutEffect(() => {
    const handleResize = () => {
      if (ref && ref.current) {
        const node = ref.current;
        const { top } = node.getBoundingClientRect();
        let height_provider_selector = 0;
        if (api_providers_length > 1) {
          height_provider_selector = 60;
        }

        // normally TABLE_HEADER_HEIGHT would be 55.
        setTableHeight(
          window.innerHeight -
            top -
            55 -
            height_provider_selector -
            paddingBottomScrollY
        );
      }
    };
    handleResize();
    const debouncedHandleResize = debounce(handleResize, 1000);
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, [paddingBottomScrollY, ref]);

  useEffect(() => {
    const scrollerListener =
      ref && ref.current ? ref.current.querySelector(".ant-table-body") : null;
    if (scrollerListener && onEndScroll) {
      scrollerListener.addEventListener("scroll", onEndScroll);
    }
    return () => {
      if (scrollerListener && onEndScroll) {
        scrollerListener.removeEventListener("scroll", onEndScroll);
      }
    };
  }, [onEndScroll]);

  const getTitle = () => {
    const { title, buttons } = header;
    return (
      <HeaderContainer>
        {title !== undefined && <Title>{title}</Title>}
        {buttons?.length > 0 &&
          buttons.map((btn) => {
            if (btn.buttonLabel.includes("Ajouter")) {
              return (
                <StyledAddLineButton
                  {...btn}
                  label={btn.buttonLabel}
                  onClick={btn.onClick}
                  key={btn.buttonLabel}
                />
              );
            }
            return (
              <StyledAddButton
                {...btn}
                label={btn.buttonLabel}
                onClick={btn.onClick}
                key={btn.buttonLabel}
              />
            );
          })}
      </HeaderContainer>
    );
  };

  const getRowClassname = (record) => {
    const key = tableProps.rowKey || "key";
    const classes = [];
    if (coloredRowOfferPage && record && record[key] && !record.children) {
      classes.push("row_children_colored");
    }
    if (record.hide) return "hide_row_table_antd";

    if (rowClassName) {
      if (typeof rowClassName === "function")
        classes.push(rowClassName(record));
      else classes.push(rowClassName);
    }
    if (record.className) classes.push(record.className);
    if (isRowSelectable) classes.push("cursor-pointer");
    if (rowSelectableId && rowSelectableId === record[key])
      classes.push(["ant-table-row-selected", "ant-table-cell-row-hover"]);

    return classes;
  };

  return (
    <ConfigProvider renderEmpty={() => emptyLabel}>
      <Container bordered={bordered}>
        <StyledTable
          className={className}
          columns={columns?.filter((el) => !el.hidden)}
          dataSource={data}
          title={header ? getTitle : undefined}
          bordered={bordered}
          fontSize={fontSize}
          onRow={(record, rowIndex) => {
            if (onRowAction) {
              return {
                onClick: async (event) => {
                  event.preventDefault();
                  const { target } = event;
                  if (target.classList.contains("fa-eye-slash")) {
                    return;
                  }
                  if (record.has_user_right !== false) {
                    if (record.is_opportunity) {
                      window.location = `/opportunities/${record.opportunity_id}/last_page`;
                    } else {
                      window.location = `/offers/${record.key}`;
                    }
                  }
                }, // click row
              };
            }
          }}
          expandable={{
            expandedRowRender,
            columnWidth: 1,
            rowExpandable: (record) => {
              if (record.flag === undefined) return true;
              if (
                (record.flag === "movement" &&
                  ["Train", "Taxi", "Avion", "Vehicule"].includes(
                    record.type_transport
                  )) ||
                record.flag === "carrycot"
              ) {
                return true;
              }
              return false;
            },
          }}
          rowClassName={getRowClassname}
          pagination={false}
          isExpandTableLinked={isExpandTableLinked}
          ref={ref}
          hideIfNoData={hideIfNoData}
          $hoverBackground={hoverBackground}
          showSorterTooltip={false}
          {...tableProps}
          loading={{
            spinning: !!tableProps.loading,
            indicator: <Loader width={40} height={40} />,
          }}
          scroll={
            scrollY
              ? { y: tableHeight, x: tableProps?.scroll?.x }
              : tableProps.scroll
          }
        />
      </Container>
    </ConfigProvider>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-of-type) {
    margin-bottom: 0rem;
  }

  .ant-table-wrapper table {
    border-radius: 0px !important;
  }

  .ant-table-wrapper .ant-table .ant-table-header {
    border-radius: 0px !important;
  }

  ${({ bordered }) =>
    bordered &&
    `
    .ant-table-wrapper
    .ant-table-thead
    > tr
    > td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
    `}
`;

const StyledTable = styled(Table)`
  padding: 0;
  margin: 0;

  .ant-table-row-selected > .ant-table-cell {
    background: #bae0ff !important;
  }

  .ant-table-column-sorter {
    color: white !important;
  }

  .ant-table-filter-trigger:not(.active) {
    color: white !important;
  }

  ${({ hideIfNoData }) =>
    hideIfNoData &&
    `
      .ant-table-container {
    display: none;
      }
  `}

  .ant-table {
    border-radius: 0px !important;
  }

  .ant-table-cell {
    font-size: ${({ fontSize }) => `${fontSize}px !important`};
    padding: 5px !important;
    color: ${({ theme }) => theme.colors?.blue11};
    border-radius: 0px !important;
  }

  .ant-table-thead > tr > th {
    background-color: ${({ theme }) => `${theme.colors?.blue07} !important`};
    color: white;
  }

  .ant-table-thead > tr > td {
    background-color: ${({ theme }) => `${theme.colors?.blue07} !important`};
    color: white;
  }

  ${({ bordered, tr }) =>
    bordered &&
    `
      .ant-table-thead > tr > th:not(:last-child)
      {
        border-inline-end: 1px solid white !important;
      }
      .ant-table-thead > tr > th
      {
        border-bottom: 1px solid white !important;
      }
      ${
        tr &&
        `
    .ant-table-thead > tr:nth-child(${tr}) > th:last-child
      {
        border-inline-end: 1px solid white !important;
      }
      `
      }
    `}

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: ${({ bordered }) =>
      bordered ? "none" : "white !important"};
  }

  .ant-table.ant-table-bordered > .ant-table-title {
    border: none;
  }

  td.ant-table-column-sort {
    background-color: white;
  }

  .ant-table-body {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .ant-table-body::-webkit-scrollbar {
    display: none;
  }

  .ant-input-affix-wrapper {
    padding: 2px 4px !important;
  }

  ${({ isExpandTableLinked }) =>
    isExpandTableLinked &&
    css`
      .ant-table-expanded-row {
        position: relative;

        &:before {
          content: "Matériels compatibles et prestations associées";
          position: absolute;
          z-index: 999;
          font-size: ${({ fontSize }) => `${fontSize}px`};

          color: ${({ theme }) => theme.colors.blue07};
          top: 0;
          left: 10px;
        }

        td[colspan]:first-of-type {
          padding: 17px 0px 17px 0 !important;

          table {
            border-left: none;
            border-top: none;
          }

          tr {
            td:last-of-type {
              border-right: none;
            }
          }

          tr:last-of-type {
            td {
              border-bottom: none;
            }
          }
        }
      }
    `}
  /* .ant-table-row > td {
    background-color: white;
  } */

  ${({ $hoverBackground }) =>
    !$hoverBackground &&
    css`
      .ant-table-row:hover > td {
        background-color: transparent !important;
      }
    `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  margin: 0 0.938rem 0 0;
`;

const StyledAddButton = styled(AddButton)`
  margin-right: 20px;
`;

const StyledAddLineButton = styled(AddLineButton)`
  margin-right: 20px;
`;

TableContainer.propTypes = {
  header: shape({
    buttons: arrayOf(
      shape({
        buttonLabel: string.isRequired,
        onClick: func.isRequired,
      })
    ),
    title: oneOfType([string, propNode]),
  }),
  columns: array.isRequired,
  data: array,
  expandedRowRender: func,
  bordered: bool,
  scrollY: bool,
  paddingBottomScrollY: number,
  emptyLabel: string,
  onEndScroll: func,
  isExpandTableLinked: bool,
  hideIfNoData: bool,
  tr: number,
  className: string,
  fontSize: number,
  onRowAction: bool,
  coloredRowOfferPage: bool,
  rowSelectableId: number,
  isRowSelectable: bool,
  rowClassName: oneOfType([string, array, func]),
  hoverBackground: bool,
  api_providers_length: number,
};

TableContainer.defaultProps = {
  header: undefined,
  expandedRowRender: undefined,
  bordered: true,
  scrollY: false,
  paddingBottomScrollY: 0,
  data: [],
  emptyLabel: "Aucune donnée",
  onEndScroll: undefined,
  isExpandTableLinked: false,
  hideIfNoData: false,
  tr: undefined,
  className: "",
  fontSize: 10,
  onRowAction: false,
  coloredRowOfferPage: false,
  rowSelectableId: undefined,
  rowClassName: [],
  isRowSelectable: false,
  hoverBackground: true,
  api_providers_length: 0,
};

export default TableContainer;
