import React from "react";
import { bool, string } from "prop-types";
import styled from "styled-components";
import Excel from "./Icons/Excel";
import ButtonLink from "./ButtonLink";

function ExcelButton({ label, goTo, className, loading, ...buttonProps }) {
  return (
    <StyledButton
      className={className}
      loading={loading}
      label={label}
      goTo={goTo}
      {...buttonProps}
    >
      {!loading && <StyledIcon />}
    </StyledButton>
  );
}

const StyledIcon = styled(Excel)`
  margin-right: 0.313rem;
`;

const StyledButton = styled(ButtonLink)`
  padding: 0.3rem 0.5rem;
`;

ExcelButton.propTypes = {
  label: string,
  className: string,
  loading: bool,
  goTo: string,
};

ExcelButton.defaultProps = {
  label: "",
  className: "",
  loading: false,
  goTo: "",
};

export default ExcelButton;
