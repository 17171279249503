import { sendCategoryFiltered } from "./sendCategoryFiltered";
import { encodeUrlParams } from "./encodeUrlParams";
import { sendCompatibleMaterials } from "./sendCompatibleMaterials";

const getUrl = (from) => {
  return from === "quotation"
    ? "/material_framework_agreements?"
    : "/material_profession?";
};

export const fetchMaterialsUrl = ({
  from,
  id,
  nextPage,
  search,
  categoryFiltered,
  compatibleMaterials,
  oppId,
  sortBy,
  parent,
  parentId,
  adminDetails,
}) => {
  let url = getUrl(from);
  const params = {
    next_page: nextPage,
    from,
    ...(sortBy && { sort_by: sortBy.field, order: sortBy.order }),
    ...(search && { search }),
    ...(parent && { parent }),
    ...(parentId && { parent_id: parentId }),
    ...(from === "admin" && {
      id,
      exclude_entities_parent: true,
      adminDetails: adminDetails,
    }),
    ...(from === "consultation" && { id }),
    ...(from === "quotation" && {
      framework_agreement_id: id,
      id: oppId,
    }),
    ...(from === "referencing" && {
      id,
      with_material_included: true,
      include_entities_child: true,
    }),
    ...(from === "frameworkAgreement" && {
      with_material_included: true,
      framework_agreement_id: id,
      include_entities_child: true,
    }),
  };

  url += encodeUrlParams(params);
  url += sendCategoryFiltered(categoryFiltered);
  url += sendCompatibleMaterials(compatibleMaterials);

  return url;
};

export const fetchMaterialByIdUrl = ({ from, id }) => {
  let url = "/material_by_id?";
  const params = {
    id,
    ...(from === "referencing" && { referencing: true }),
    ...(from === "frameworkAgreement" && { framework_agreement: true }),
  };

  url += encodeUrlParams(params);

  return url;
};
