import { getData } from "../../../../../../request/instance";
import { fetchPrestationsUrl } from "../../../../../../../utils/fetchPrestationsUrl";

export const PrestationFetching = ({
  formToken,
  showFaItems,
  id,
  wordEntered,
  compatibleMaterials,
  opportunityType,
  faId,
  sortBy,
  from,
  fieldsDico,
  from_zone_template = false,
  selectedZoneTemplate,
}) => {
  return () => {
    let come_from = "";
    let id_from = "";
    if (from === "admin") {
      come_from = "admin";
      id_from = id;
    } else {
      come_from = showFaItems ? "quotation" : "consultation";
      id_from = showFaItems ? faId : id;
    }
    return getData(
      formToken,
      fetchPrestationsUrl({
        from: come_from,
        id: id_from,
        search: wordEntered,
        compatibleMaterials,
        opportunityType,
        ...(from !== "admin" && { oppId: id }),
        sortBy,
        from_zone_template,
        selectedZoneTemplate,
      })
    );
  };
};

export const PrestationFetchingSuccess = ({
  setPrestations,
  showFaItems,
  from,
}) => {
  return {
    refetchOnWindowFocus: false,
    enabled: from !== "configuration",
    onSuccess: (payload) =>
      setPrestations(showFaItems ? payload.prestation_fas : payload),
  };
};
