// Table Opportunities



$(document).ready(() => {
  const offerShow = document.getElementById("opportunities-list")
  if(!offerShow){
    return
  }
const editRowBtn = document.querySelectorAll(".edit-opp")
const editOpportunity  = () => {
  window.location.href = `/opportunities/${event.currentTarget.dataset.id}/last_page`;
}
if (editRowBtn) {
  editRowBtn.forEach(row => row.addEventListener('click', editOpportunity))
}
})
// Table Offer
$(document).ready(() => {

const offerIndex = document.getElementById("offer-index-id")
if(!offerIndex){
  return
}
const clickableRow = document.querySelectorAll(".edit-offer")
const editOffer  = (event) => {
  window.location.href = `/offers/${event.currentTarget.dataset.id}`;
}
if (clickableRow) {
  clickableRow.forEach(row => row.addEventListener('click', editOffer))
}
})