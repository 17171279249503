$(document).ready(() => {

  const opportunityNew = document.getElementById("create_opportunity")
  const opportunityEdit = document.getElementById("edit_opportunity")
  
  if(!opportunityNew && !opportunityEdit){
    return
  }

const formCurrentProvider = document.getElementById('new_current_provider');
const formCurrentProviderInput = document.getElementById('current_provider_name');
const dropDownCurrentProvider = document.querySelector('.opportunity_providers_opportunity_ids');

if (formCurrentProvider){
  const addCurentProvider = () => {
     // Sending data to the serveur at the url specify
    // Where I will receive the post
    fetch("/current_provider", {
      method: "POST",
      headers: { "Content-Type": "application/json",
                 "Accept": "application/json",
                 "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
               },
      body: JSON.stringify({ "name": formCurrentProviderInput.value })
    }).then(response => response.json())
      .then((data) => {
        eval(data)
      })
      $("#modal_current_provider").modal('hide');
  };

  formCurrentProvider.addEventListener('submit', addCurentProvider);
}

if (dropDownCurrentProvider) {
  const displayAllCurrentProvider = () => {
    fetch('/current_providers', {
      method: "GET",
      headers: { "Content-Type": "application/json",
                 "Accept": "application/json",
                 "X-Requested-With": "XMLHttpRequest",
                 "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
               }
    })
    .then(response => response.json())
    .then((data) => {
      eval(data)

      // order by id data JSON
        const dynamicSort = (property) => {
            let sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }

        data.sort(dynamicSort("id"));
        // select the last element to insert only it
        const element = data[data.length -1];
        if (element) {
          const selectDropdown = $(".dropdown-current-provider");
          // delete all items of the native select element
          selectDropdown.innerHTML= "";
          if ($(`.dropdown-current-provider option[value='${element.id}']`).length === 0) {
            selectDropdown.append("<option value=\"" +  element.id + "\">" +  element.name + "</option>");
          }
        }
    data.sort(dynamicSort("name"));
    })
    // refresh the input intitule of TypologieForm
    formCurrentProviderInput.value = "";
  }
  dropDownCurrentProvider.addEventListener("mouseenter", displayAllCurrentProvider)
}
})