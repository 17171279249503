import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import TableContainer from "../../../../CostingMovementsCarrycots/TableContainer";
import { currency_cents_to_euros } from "../../../../../utils";
import { formatNumberString } from "../../../../../../utils/formatNumberString";
import OuvrageContent from "./OuvrageContent";
import { useStore } from "../../../../../store";
import Input from "../../../../../react-ui/Input";
import { getMarginCol } from "../../../../../../constants/marginCol";
import { stringToFloat } from "../../../../../../utils/stringToFloat";
import { coeffToMargin } from "../../../../../../utils/marginConverter";
import { eurosToCents } from "../../../../../../utils/currencyConverter";
import TotalSelling from "./format_zone/TotalSelling";
import SellingPrice from "./format_zone/SellingPrice";
import MarginRate from "./format_zone/MarginRate";

const selector = (state) => ({
  isUsingMinutes: state.isUsingMinutes,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

function TablesOuvrage({
  type,
  ouvrages_opportunity,
  zone_id,
  formToken,
  configuration,
  calculOuvrageOpportunity,
  calculMaterialOpportunity,
  calculPrestaOpportunity,
}) {
  const { isUsingMinutes, isUsingMargin, calculMethod } = useStore(selector);
  const { id_opportunity } = useParams();

  const pushDataInTable = useMemo(() => {
    const ouvrageDataSource = [];
    ouvrages_opportunity
      //   ?.filter(
      //     (el) => currency_cents_to_euros(el.total_selling_price_cents) > 0
      //   )
      ?.map((ouvrage) => {
        if (ouvrage.zone_id === zone_id) {
          ouvrageDataSource.push({
            flag: "ouvrage",
            key: ouvrage.id,
            id: ouvrage.id,
            name: ouvrage.name,
            unity_abbreviation: ouvrage?.unity_profession?.abbreviation,
            quantity: formatNumberString({
              str: `${ouvrage.quantity}`,
              nbDecimal: 2,
              space: true,
            }),
            cost_price_cents: ouvrage.cost_price_cents,

            total_cost_price_cents: ouvrage.total_cost_price_cents,
            margin_rate: ouvrage.margin_rate,
            selling_price_cents: ouvrage.selling_price_cents,
            total_selling_price_cents: ouvrage.total_selling_price_cents,
          });
        }
      });
    // if (!movementLoading) setMargin(null);
    return ouvrageDataSource;
  }, [ouvrages_opportunity]);

  const OuvragesColumns = useCallback(() => {
    const update_function = {
      ouvrage: calculOuvrageOpportunity,
      material: calculMaterialOpportunity,
      prestation: calculPrestaOpportunity,
    };

    const quantity_render = (value) => {
      if ("quantity" in value) {
        return (
          <div>
            {formatNumberString({
              str: `${value.quantity}`,
              nbDecimal: 2,
              space: true,
            })}{" "}
            {value.unity_abbreviation ? value.unity_abbreviation : "u"}
          </div>
        );
      }
      if (value.is_package === true) {
        return (
          <div>
            {formatNumberString({
              str: `${value.unity_quantity}`,
              nbDecimal: 2,
              space: true,
            })}{" "}
            {value.unity_abbreviation ? value.unity_abbreviation : "u"}
          </div>
        );
      }
      return (
        <div>
          {formatNumberString({
            str: `${value.hour_quantity}`,
            nbDecimal: 2,
            space: true,
          })}{" "}
          {isUsingMinutes ? "min" : "h"}
        </div>
      );
    };
    return [
      {
        title: "Intitulé",
        dataIndex: "name",
        align: "left",
        render: (_, record) => <div>{record.name}</div>,
      },
      {
        title: "Qt",
        dataIndex: "quantity",
        align: "left",
        width: 58,
        render: (_, record) => quantity_render(record),
      },
      {
        title: "Coût de revient",
        dataIndex: "cost ",
        align: "center",
        width: 20,
        hidden: !configuration.costing.page_3.show_cost_price,
        children: [
          {
            title: "Unitaire",
            dataIndex: "cost_unitary",
            align: "right",
            width: 90,
            render: (_, record) =>
              `${formatNumberString({
                str:
                  record.cost_price_cents === null
                    ? currency_cents_to_euros(record.hourly_rate_cents)
                    : currency_cents_to_euros(record.cost_price_cents),
                nbDecimal: 2,
                space: true,
              })} €`,
          },
          {
            title: "Total",
            dataIndex: "cost_total",
            align: "right",
            width: 90,
            render: (_, record) =>
              `${formatNumberString({
                str: currency_cents_to_euros(
                  record.flag === "prestation"
                    ? record.total_cost_cents
                    : record.total_cost_price_cents
                ),
                nbDecimal: 2,
                space: true,
              })} €`,
          },
        ],
      },
      getMarginCol({
        configuration,
        isUsingMargin,
        calculMethod,
        keyName: "margin_rate",
        customSorter: true,
        hasSorter: false,
        render: (value, record) => (
          <MarginRate
            update_function={update_function}
            record={record}
            value={value}
            id_opportunity={id_opportunity}
            isUsingMargin={isUsingMargin}
            calculMethod={calculMethod}
          />
        ),
      }),
      {
        title: "Prix de vente HT",
        dataIndex: "selling_price_cents",
        children: [
          {
            title: "Unitaire",
            dataIndex: "unitary_selling_HT",
            width: 90,
            align: "right",
            render: (value, opp) => (
              <SellingPrice
                id_opportunity={id_opportunity}
                record={opp}
                update_function={update_function}
                disabled={configuration.costing.page_3.disable_selling_price}
              />
            ),
          },
          {
            title: "Total",
            dataIndex: "total_selling_HT",
            width: 90,
            align: "right",
            render: (value, opp) => (
              <TotalSelling
                record={opp}
                id_opportunity={id_opportunity}
                update_function={update_function}
                disabled={configuration.costing.page_3.disable_selling_price}
              />
            ),
          },
        ],
      },
    ];
  }, [
    calculMaterialOpportunity,
    calculMethod,
    calculOuvrageOpportunity,
    calculPrestaOpportunity,
    configuration,
    id_opportunity,
    isUsingMargin,
    isUsingMinutes,
  ]);

  const expandedRowRender = (parent) => {
    return (
      <OuvrageContent
        parentOuvrage={parent}
        OuvrageColumns={OuvragesColumns()}
        formToken={formToken}
        calculPrestaOpportunity={calculPrestaOpportunity}
        calculMaterialOpportunity={calculMaterialOpportunity}
        calculOuvrageOpportunity={calculOuvrageOpportunity}
        configuration={configuration}
      />
    );
  };

  return pushDataInTable.length ? (
    <Container>
      <TitleOuvrageContainer>
        <h4>Ouvrages</h4>
      </TitleOuvrageContainer>
      <TableContainer
        columns={OuvragesColumns()}
        data={pushDataInTable}
        expandable={{ expandedRowRender }}
        // isExpandTableLinked
      />
    </Container>
  ) : null;
}

const Container = styled.div`
  margin-top: 20px;
`;

const TitleOuvrageContainer = styled.div`
  margin-left: 12px;
`;
export default TablesOuvrage;
