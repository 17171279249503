import React from "react";
import { bool, func, string } from "prop-types";
import styled from "styled-components";
import { Switch } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import Button from "./Button";
import Eye from "./Icons/Eye";

function TvaButton({ label, className, loading, showColTVA, setShowColTVA }) {
  return (
    <StyledButton className={className} loading={loading}>
      {!loading && (
        <i
          style={{ marginRight: "0.313rem" }}
          className="fa-kit fa-solid-building-columns-circle-dollar"
        />
      )}
      {label}
      <div style={{ padding: "0 0.3rem" }}>
        <Switch
          onChange={() => setShowColTVA(!showColTVA)}
          unCheckedChildren={<EyeInvisibleOutlined />}
          checkedChildren={<EyeOutlined />}
        />
      </div>
    </StyledButton>
  );
}

const StyledButton = styled(Button)`
  padding: 0.175rem 0.5rem;
  margin-right: 0.2rem;
`;

TvaButton.propTypes = {
  label: string,
  className: string,
  loading: bool,
  showColTVA: bool,
  setShowColTVA: func,
};

TvaButton.defaultProps = {
  label: "",
  className: "",
  loading: false,
  showColTVA: false,
  setShowColTVA: () => {},
};

export default TvaButton;
