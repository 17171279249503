import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { useMutation, useQuery } from "react-query";
import fileDownload from "js-file-download";
import { Form, notification } from "antd";
import styled from "styled-components";
import SATableTree, { constructDocumentRow } from "./SATableTree";
import {
  findIsRootItem,
  findItemByIdType,
  findRootItemIdx,
  getNewRootItem,
  reconstructRecord,
} from "../../react-ui/TableTree/treeutils";
import ImportButton from "../../react-ui/ImportButton";
import TitleContainer from "../../react-ui/TitleContainer";
import FilterHeader from "./FilterHeader";
import { getData, postData } from "../../request/instance";
import BaseIcon from "../../react-ui/Icons/BaseIcon";
import TemplateConfirmModal from "./TemplateConfirmModal";
import DoubleModal from "./DoubleModal";
import { fetchTemplatesUrl } from "../../../utils/fetchTemplatesUrl";

const folderIcon = ({ expanded }) =>
  expanded ? (
    <NodeIcon className="fa-duotone fa-folder-open" />
  ) : (
    <NodeIcon className="fa-duotone fa-folder" />
  );

function SAZipTable({
  folders,
  siteTypologies,
  opportunity_ids,
  formToken,
  closeModal,
  offer_id,
  fromOffer,
  opportunity_work,
}) {
  const [, setFilters] = useState({});
  const [templateId, setTemplateId] = useState();
  const [selectedTemplateModal, setSelectedTemplateModal] = useState();
  const [foldersToOpen, setFoldersToOpen] = useState();
  const [doubleModal, setDoubleModal] = useState();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const recordId = useRef(0);
  const newNodeId = useRef(0);

  const { data: templates, isLoading: templateIsLoading } = useQuery(
    ["Templates"],
    () =>
      getData(
        formToken,
        fetchTemplatesUrl({
          offer_id,
          opportunity_ids,
          fromOffer,
        })
      ),
    { refetchOnWindowFocus: false }
  );

  const { mutate: showTree, isLoading: isTemplateConstructing } = useMutation(
    (todo) => postData(formToken, "/sa_template/show_tree", todo)
  );

  const { mutate: generateZip, isLoading } = useMutation((todo) =>
    postData(formToken, "/opportunity/simplified_application_export", todo, {
      responseType: "blob",
    })
  );

  const moveRow = useCallback(
    (_, __, { record, hoverRecord, direction, type }) => {
      let draggedRecord = record;

      if (type === "resourceItem") {
        draggedRecord = reconstructRecord({
          oldRecord: record,
          recordId: recordId.current,
        });
        recordId.current += 1;
      }

      const deleteDraggedRecordFromOldPostion = (newData, oldData) => {
        const newDataWithoutRecord = [...newData];
        const isRootItem = findIsRootItem(draggedRecord);
        const rootItemIdx = findRootItemIdx(oldData, draggedRecord);

        if (isRootItem) {
          newDataWithoutRecord.splice(rootItemIdx, 1);
        } else {
          newDataWithoutRecord[rootItemIdx] = getNewRootItem({
            item: newDataWithoutRecord[rootItemIdx],
            record: draggedRecord,
          });
        }
        return newDataWithoutRecord;
      };

      const addDraggedRecordToNewPosition = (newData) => {
        const newDataWithRecord = [...newData];
        if (hoverRecord.type === "no-data") {
          const isRootItem = findIsRootItem(hoverRecord.parent);
          const folderIdx = findRootItemIdx(newData, hoverRecord.parent);
          if (isRootItem) {
            newDataWithRecord[folderIdx] = reconstructRecord({
              oldRecord: {
                ...newDataWithRecord[folderIdx],
                children: [draggedRecord],
              },
            });
          } else {
            newDataWithRecord[folderIdx] = reconstructRecord({
              oldRecord: getNewRootItem({
                item: newDataWithRecord[folderIdx],
                record: hoverRecord.parent,
                deleteItem: false,
                recordsToReplace: [draggedRecord],
              }),
            });
          }
          return newDataWithRecord;
        }
        if (hoverRecord.type === "empty-file") {
          const isRootItem = findIsRootItem(hoverRecord);
          const folderIdx = findRootItemIdx(newData, hoverRecord);
          if (isRootItem) {
            newDataWithRecord[folderIdx] = reconstructRecord({
              oldRecord: draggedRecord,
            });
          } else {
            newDataWithRecord[folderIdx] = reconstructRecord({
              oldRecord: getNewRootItem({
                item: newDataWithRecord[folderIdx],
                record: hoverRecord,
                deleteItem: false,
                recordToReplace: draggedRecord,
              }),
            });
          }
          return newDataWithRecord;
        }
        const isHoverRootItem = findIsRootItem(hoverRecord);
        const hoverRootItemIdx = findRootItemIdx(newData, hoverRecord);
        if (isHoverRootItem) {
          newDataWithRecord.splice(
            direction === "downward" ? hoverRootItemIdx + 1 : hoverRootItemIdx,
            0,
            { ...draggedRecord, parentKeys: undefined }
          );
        } else {
          newDataWithRecord[hoverRootItemIdx] = reconstructRecord({
            oldRecord: getNewRootItem({
              item: newDataWithRecord[hoverRootItemIdx],
              record: hoverRecord,
              deleteItem: false,
              direction,
              recordToAdd: draggedRecord,
            }),
          });
        }
        return newDataWithRecord;
      };
      setData((oldData) => {
        let newData = [...oldData];
        if (type !== "resourceItem")
          newData = deleteDraggedRecordFromOldPostion(newData, oldData);
        newData = addDraggedRecordToNewPosition(newData);
        return newData;
      });
    },
    []
  );

  const draggableProps = useMemo(() => {
    return {
      canDropFunction: (_, __, { record, hoverRecord }) => {
        return (
          record.key !== hoverRecord.key &&
          (!hoverRecord.parentKeys ||
            !hoverRecord.parentKeys.includes(record.key))
        );
      },
      type: "zipItem",
      accept: ["zipItem", "resourceItem"],
      moveRow,
    };
  }, [moveRow]);

  const [{ cantDropOnItem, canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "resourceItem",
      drop: async ({ record }, monitor) => {
        if (monitor.isOver({ shallow: true })) {
          setData((oldData) => {
            return [
              reconstructRecord({
                oldRecord: record,
                recordId: recordId.current,
              }),
              ...oldData,
            ];
          });
          recordId.current += 1;
        }
      },
      collect: (monitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
          cantDropOnItem: monitor.getItemType() === "resourceItem",
        };
      },
    }),
    []
  );

  const backgroundColor = useMemo(() => {
    if (cantDropOnItem) {
      if (canDrop && isOver) return "green";
      if (canDrop) return "red";
    }
    return "";
  }, [canDrop, isOver, cantDropOnItem]);

  const generateZipData = ({ treeData = [] }) => {
    const zip = [];
    treeData.forEach((element) => {
      if (element.type === "empty-file") return;
      const treeNode = {
        name: element.name,
        type: element.type,
        id: element.id,
      };
      if (element.material_opportunities?.length > 0) {
        treeNode.id = element.material_opportunities[0].id;
        treeNode.type = "material_document";
      }
      if (element.prestation_opportunities?.length > 0) {
        treeNode.id = element.prestation_opportunities[0].id;
        treeNode.type = "prestation_document";
      }
      if (element.type.includes("folder")) {
        if (element.children !== undefined) {
          treeNode.children = generateZipData({
            treeData: element.children,
          });
        }
        zip.push(treeNode);
      } else {
        zip.push(treeNode);
      }
    });
    return zip;
  };

  const dowloadZip = ({ name }) => {
    const zip = generateZipData({ treeData: data });
    generateZip(
      {
        name,
        tree_data: zip,
        opportunity_ids,
        from_offer: fromOffer,
        offer_id,
      },
      {
        onSuccess: (payload) => {
          fileDownload(payload, `${name}.zip`);
          closeModal();
        },
      }
    );
  };

  const templatesOptions = useMemo(() => {
    if (!templates) return [];
    return [
      {
        name: "Modèles",
        options: templates.map((template) => ({
          name: template.name,
          id: template.id,
        })),
      },
      {
        name: "Modèle sélectionné",
        options: [
          templateId
            ? {
                id: `customTemplateId`,
                name: templates.find((template) => template.id === templateId)
                  .name,
              }
            : {
                id: "disabled",
                name: "Aucun modèle sélectionné",
                disabled: true,
              },
        ],
      },
    ];
  }, [templates, templateId]);

  const constructNodeRow = useCallback(
    (node) => {
      newNodeId.current += 1;
      if (node.node_type === "folder" && node.folder_id === null) {
        return {
          id: node.id,
          name: node.name,
          key: `folder-new-node-${newNodeId.current}`,
          icon: folderIcon,
          type: "folder-new",
          isLeaf: false,
          fromTemplate: true,
        };
      }
      if (node.node_type === "folder" && node.folder_id) {
        return {
          ...node.folder,
          id: node.folder_id,
          name: node.name,
          key: `folder-node-${newNodeId.current}`,
          icon: folderIcon,
          type: "folder",
          folder_id: node.folder_id,
          isLeaf: false,
          fromTemplate: true,
        };
      }
      if (node.node_type === "admin_file") {
        const newFile = constructDocumentRow({
          document: node.document,
          folders,
          siteTypologies,
        });
        return {
          ...newFile,
          key: `document-node-${newNodeId.current}`,
          fromTemplate: true,
          name: node.name,
        };
      }
      if (node.node_type === "file_rule") {
        return {
          name: node.file_rule.name,
          key: `empty-file-${newNodeId.current}`,
          type: "empty-file",
          isLeaf: true,
          notEdittable: true,
          notDelettable: true,
          checkable: false,
          icon: <EmptyIcon className="fa-duotone fa-empty-set" />,
          elementDraggableProps: {
            ...draggableProps,
            draggable: false,
            type: "needDropItem",
          },
        };
      }
      if (node.node_type === "opportunity_folders") {
        return {
          id: node.opportunity.id,
          name: `${node.opportunity.chrono}`,
          workName: node.opportunity.work_name,
          additionalName: <AdditionalName>(depuis template)</AdditionalName>,
          key: `folder-opportunity-node-${newNodeId.current}`,
          icon: folderIcon,
          type: "folder-opportunity",
          isLeaf: false,
        };
      }
      return {
        ...constructDocumentRow({
          document: node,
          folders,
          siteTypologies,
        }),
        key: `document-node-${newNodeId.current}`,
      };
    },
    [draggableProps, folders, siteTypologies]
  );

  const generateTemplate = useCallback(
    (treeData = [], documentIds = [], hasParent = false) => {
      const tree = [];
      let fToOpen = [];
      let hasEmptyFile = false;
      treeData.forEach((node) => {
        if (
          node.node_type === "product_files" ||
          node.node_type === "prestation_files"
        ) {
          node.drive_links.forEach((driveLink) => {
            let newNode = constructNodeRow(driveLink);
            recordId.current += 1;
            newNode = reconstructRecord({
              oldRecord: newNode,
              recordId: recordId.current,
            });
            tree.push(newNode);
          });
        } else if (node.node_type === "file_rule") {
          let hasDoc = false;
          node.documents.forEach((doc) => {
            if (node.documents.length === 1 || documentIds.includes(doc.id)) {
              hasDoc = true;
              let newNode = constructNodeRow(doc);
              recordId.current += 1;
              newNode = reconstructRecord({
                oldRecord: newNode,
                recordId: recordId.current,
              });
              tree.push(newNode);
            }
          });
          if (!hasDoc) {
            let newNode = constructNodeRow(node);
            recordId.current += 1;
            newNode = reconstructRecord({
              oldRecord: newNode,
              recordId: recordId.current,
            });
            if (hasParent) hasEmptyFile = true;
            tree.push(newNode);
          }
        } else {
          let newNode = constructNodeRow(node);
          recordId.current += 1;
          if (newNode.type.includes("folder")) {
            let children = [];
            if (node.children?.length > 0) {
              const {
                tree: newChildren,
                foldersToOpenIdType,
                hasEmptyFile: childHasEmptyFile,
              } = generateTemplate(node.children, documentIds, true);
              children = newChildren;
              if (childHasEmptyFile)
                fToOpen.push({ id: newNode.id, type: newNode.type });
              fToOpen = fToOpen.concat(foldersToOpenIdType);
            }
            newNode.children = children;
          }
          newNode = reconstructRecord({
            oldRecord: newNode,
            recordId: recordId.current,
          });
          tree.push(newNode);
        }
      });
      return { tree, foldersToOpenIdType: fToOpen, hasEmptyFile };
    },
    [constructNodeRow]
  );

  const getDoublesFileRules = useCallback((treeData = []) => {
    let doubles = [];
    treeData.forEach((node) => {
      if (node.node_type === "file_rule" && node.documents.length > 1)
        doubles = doubles.concat(node.documents);
      if (node.children?.length > 0)
        doubles = doubles.concat(getDoublesFileRules(node.children));
    });
    return doubles;
  }, []);

  const addTemplateToTree = useCallback(
    ({
      selectedTemplate,
      treeData = [],
      isReinstantiate = false,
      documentIds = [],
    }) => {
      setTemplateId(selectedTemplate.id);
      const { tree: newData, foldersToOpenIdType } = generateTemplate(
        treeData,
        documentIds
      );
      setData((oldData) => {
        return isReinstantiate ? newData : newData.concat(oldData);
      });
      let newFoldersToOpen = [];
      foldersToOpenIdType.forEach(({ id, type }) => {
        const foldWithEmpty = findItemByIdType({ data: newData, id, type });
        newFoldersToOpen.push(foldWithEmpty);
        if (foldWithEmpty.parentKeys?.length > 0)
          newFoldersToOpen = newFoldersToOpen.concat(
            foldWithEmpty.parentKeys.map((key) => ({ key }))
          );
      });
      setFoldersToOpen(newFoldersToOpen);
    },
    [generateTemplate]
  );

  const onTemplateSelect = useCallback(
    (item) => {
      if (item === "customTemplateId") return;
      const selectedTemplate = templates.find(
        (template) => template.id === item
      );
      if (templateId === item) return;
      if (data.length === 0) {
        showTree(
          {
            id: item,
            offer_id,
            opportunity_ids,
          },
          {
            onSuccess: (payload) => {
              const doubles = getDoublesFileRules(payload.tree);
              if (doubles.length < 1)
                addTemplateToTree({
                  selectedTemplate,
                  treeData: payload.tree,
                  isReinstantiate: true,
                });
              else
                setDoubleModal({
                  doubles,
                  selectedTemplate,
                  treeData: payload.tree,
                  isReinstantiate: true,
                });
            },
          }
        );
        return;
      }
      setSelectedTemplateModal(selectedTemplate);
    },
    [
      templates,
      templateId,
      data.length,
      showTree,
      offer_id,
      opportunity_ids,
      getDoublesFileRules,
      addTemplateToTree,
    ]
  );

  const onAnnexAdd = useCallback(
    async ({ event, fromHeader, appendChildren, type, record }) => {
      event.stopPropagation();
      const annexs = await getData(
        formToken,
        `/sa_template/generate_annexs?generate_only_annexs=true&node_type=${type}${
          fromHeader
            ? opportunity_ids
                .map((opportunity_id) => `&opportunity_ids[]=${opportunity_id}`)
                .join("")
            : `&opportunity_ids[]=${record.id}`
        }`
      );
      const children = [];
      annexs.forEach((document) => {
        const formattedNode = constructNodeRow({
          node_type: "admin_file",
          name: document.name,
          document,
        });
        if (formattedNode) children.push(formattedNode);
      });
      if (fromHeader) setData((old) => children.concat(old));
      else {
        appendChildren({
          record,
          children: children.concat(record.children),
        });
      }
    },
    [constructNodeRow, formToken, opportunity_ids]
  );

  const onLoadClick = () => {
    const name = form.getFieldValue("name");
    if (!name)
      notification.error({
        description: "Nom obligatoire.",
        placement: "bottom",
        duration: 5,
      });
  };

  return (
    <>
      <TemplateConfirmModal
        setSelectedTemplate={setSelectedTemplateModal}
        selectedTemplate={selectedTemplateModal}
        addTemplateToTree={addTemplateToTree}
        showTree={showTree}
        offer_id={offer_id}
        opportunity_ids={opportunity_ids}
        getDoublesFileRules={getDoublesFileRules}
        setDoubleModal={setDoubleModal}
      />
      <DoubleModal
        setDoubleModal={setDoubleModal}
        doubleModal={doubleModal}
        addTemplateToTree={addTemplateToTree}
        offer_id={offer_id}
        formToken={formToken}
        folders={folders}
        siteTypologies={siteTypologies}
      />
      <Form
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={dowloadZip}
        validateMessages={{
          required: "",
        }}
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <TitleContainer label="Construction de dossiers">
          <ImportButton
            label="Télécharger"
            loading={isLoading}
            type="submit"
            value="submit"
            onClick={onLoadClick}
            disabled={isLoading || data?.length === 0}
          />
        </TitleContainer>
        <FilterHeader
          templatesOptions={templatesOptions}
          onTemplateSelect={onTemplateSelect}
          templateId={templateId ? "customTemplateId" : null}
          templateIsLoading={templateIsLoading}
          isTemplateConstructing={isTemplateConstructing}
        />
        <SATableTree
          data={data}
          setFilters={setFilters}
          folders={folders}
          siteTypologies={siteTypologies}
          opportunity_ids={opportunity_ids}
          setData={setData}
          formToken={formToken}
          drop={drop}
          backgroundColor={backgroundColor}
          draggableProps={draggableProps}
          editable
          constructNodeRow={constructNodeRow}
          fromSA
          offer_id={offer_id}
          fromOffer={fromOffer}
          foldersToOpen={foldersToOpen}
          withAnnex
          workName={opportunity_work}
          onAnnexAdd={onAnnexAdd}
          tableHeight={500}
        />
      </Form>
    </>
  );
}

const NodeIcon = styled(BaseIcon)`
  color: ${({ theme }) => theme.colors.blue11};
`;

const EmptyIcon = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.blue11};
`;

const AdditionalName = styled.span`
  margin-left: auto;
  font-style: italic;
`;

export default SAZipTable;
