import React from "react";
import styled from "styled-components";
import ReactDatePicker from "../../../Form/ReactDatePicker";
import FormCol from "../../../Form/FormCol";
import { opportunityField } from "../../../constant";

function DecisionDate({ disabled, vocabulary }) {
  return (
    <FormCol
      labelOnTop
      label={`Date de décision ${vocabulary.opportunity.of_client}`}
      name={opportunityField.customerDecisionDate}
    >
      <StyledDatePicker
        placeholderText="Sélectionnez une date"
        disabled={disabled}
      />
    </FormCol>
  );
}

const StyledDatePicker = styled(ReactDatePicker)`
  width: 100%;
`;

export default DecisionDate;
