import { Checkbox, Form } from "antd";
import React, { useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { constructMinsOrH } from "../../../../utils/planning";
import { validateMessages } from "../../../constant";
import FormCol from "../../../Form/FormCol";
import FormRow from "../../../Form/FormRow";
import ReactDatePicker from "../../../Form/ReactDatePicker";
import Button from "../../../react-ui/Button";
import EditButton from "../../../react-ui/EditButton";
import Select from "../../../react-ui/Select";
import TitleContainer from "../../../react-ui/TitleContainer";

const weekDays = [
  { name: "Lundi", id: 1 },
  { name: "Mardi", id: 2 },
  { name: "Mercredi", id: 3 },
  { name: "Jeudi", id: 4 },
  { name: "Vendredi", id: 5 },
  { name: "Samedi", id: 6 },
  { name: "Dimanche", id: 7 },
  { name: "Jour férié", id: 8 },
];

function WorkPeriodForm({
  workPeriodsModalObj,
  setWorkPeriodsModalObj,
  destroyAndCreateWPeriod,
  deleteWorkPeriod,
  planningId,
}) {
  const [form] = Form.useForm();
  const { selectedWorkPeriod, workPeriods, similarWorkPeriods } =
    workPeriodsModalObj;

  const startDay = Form.useWatch("startDay", form);
  const startTime = Form.useWatch("startTime", form);
  const endDay = Form.useWatch("endDay", form);
  const endTime = Form.useWatch("endTime", form);
  const dayRepeat = Form.useWatch("dayRepeat", form);
  const endAtMidnight = Form.useWatch("endAtMidnight", form);
  const daySimilar = Form.useWatch("daySimilar", form);

  useEffect(() => {
    if (endAtMidnight) {
      form.setFieldValue("endTime", new Date(`2020-06-01T00:00:00`));
    }
  }, [endAtMidnight, form]);

  const handleSubmit = (values) => {
    let curEndDay = values.startDay === 8 ? values.startDay : values.endDay;
    if (values.endAtMidnight) curEndDay += 1;
    const wpToSend = [
      {
        start_datetime: `2020-06-0${values.startDay}T${constructMinsOrH(
          values.startTime.getHours()
        )}:${constructMinsOrH(values.startTime.getMinutes())}:00`,
        end_datetime: `2020-06-0${curEndDay}T${constructMinsOrH(
          values.endTime.getHours()
        )}:${constructMinsOrH(values.endTime.getMinutes())}:00`,
        planning_id: planningId,
      },
    ];

    if (selectedWorkPeriod) wpToSend[0].id = selectedWorkPeriod.id;

    if (values.dayRepeat) {
      const dayDiff = curEndDay - values.startDay;
      values.dayRepeat.forEach((el) => {
        wpToSend.push({
          start_datetime: `2020-06-0${el}T${constructMinsOrH(
            values.startTime.getHours()
          )}:${constructMinsOrH(values.startTime.getMinutes())}:00`,
          end_datetime: `2020-06-0${el + dayDiff}T${constructMinsOrH(
            values.endTime.getHours()
          )}:${constructMinsOrH(values.endTime.getMinutes())}:00`,
          planning_id: planningId,
        });
      });
    }

    if (values.daySimilar) {
      const dayDiff = curEndDay - values.startDay;
      values.daySimilar.forEach((el) => {
        wpToSend.push({
          id: similarWorkPeriods.find((swp) => swp.startTime.getDate() === el)
            .id,
          start_datetime: `2020-06-0${el}T${constructMinsOrH(
            values.startTime.getHours()
          )}:${constructMinsOrH(values.startTime.getMinutes())}:00`,
          end_datetime: `2020-06-0${el + dayDiff}T${constructMinsOrH(
            values.endTime.getHours()
          )}:${constructMinsOrH(values.endTime.getMinutes())}:00`,
          planning_id: planningId,
        });
      });
    }

    destroyAndCreateWPeriod(
      {
        work_periods: wpToSend,
        planning_id: planningId,
      },
      {}
    );
    setWorkPeriodsModalObj();
  };

  const handleOnClose = () => {
    setWorkPeriodsModalObj();
  };

  const initialValues = useMemo(() => {
    if (!selectedWorkPeriod) return undefined;
    const initEndDay = selectedWorkPeriod.end.getUTCDate();
    return {
      startDay: selectedWorkPeriod.start.getUTCDate(),
      startTime: new Date(
        `2020-06-0${selectedWorkPeriod.start.getUTCDate()}T${constructMinsOrH(
          selectedWorkPeriod.start.getUTCHours()
        )}:${constructMinsOrH(selectedWorkPeriod.start.getMinutes())}:00`
      ),
      endDay: initEndDay !== 8 ? initEndDay : null,
      endTime: new Date(
        `2020-06-0${selectedWorkPeriod.end.getUTCDate()}T${constructMinsOrH(
          selectedWorkPeriod.end.getUTCHours()
        )}:${constructMinsOrH(selectedWorkPeriod.end.getMinutes())}:00`
      ),
      daySimilar: similarWorkPeriods.map((el) => el.startTime.getDate()),
    };
  }, [selectedWorkPeriod, similarWorkPeriods]);

  const validatorPeriod = () => {
    if (!startTime || !endTime || !startDay || (startDay !== 8 && !endDay))
      return Promise.reject(new Error(`La période est invalide`));
    const startDate = new Date(
      `2020-06-0${startDay}T${constructMinsOrH(
        startTime.getHours()
      )}:${constructMinsOrH(startTime.getMinutes())}:00`
    );

    let curEndDay = startDay === 8 ? startDay : endDay;
    if (endAtMidnight) curEndDay += 1;
    const endDate = new Date(
      `2020-06-0${curEndDay}T${constructMinsOrH(
        endTime.getHours()
      )}:${constructMinsOrH(endTime.getMinutes())}:00`
    );

    if (validatorValidPeriod(startDate, endDate))
      return Promise.reject(new Error(`La période est invalide`));
    if (validatorPeriodIsBetween(startDate, endDate))
      return Promise.reject(
        new Error(`Une période existe déjà dans ce créneau horaire.`)
      );
    return Promise.resolve();
  };

  const validatorValidPeriod = (startDate, endDate) => {
    return startDate.getTime() >= endDate.getTime();
  };

  const validatorPeriodIsBetween = (startDate, endDate) => {
    const similarWorkPeriodsUsed = similarWorkPeriods.filter((el) =>
      daySimilar.some((id) => id === el.startTime.getDate())
    );

    const workPeriodsWithoutSimilars = workPeriods.filter(
      (el) => !similarWorkPeriodsUsed.some((swpu) => swpu.id === el.id)
    );
    return workPeriodsWithoutSimilars.some(
      (el) =>
        startDate.getTime() < el.endTime.getTime() &&
        endDate.getTime() > el.startTime.getTime()
    );
  };

  const validatorRepeat = () => {
    if (
      !startTime ||
      !endTime ||
      !endDay ||
      !startDay ||
      !dayRepeat ||
      dayRepeat.length < 1
    )
      return Promise.resolve();

    const startDate = new Date(
      `2020-06-0${startDay}T${constructMinsOrH(
        startTime.getHours()
      )}:${constructMinsOrH(startTime.getMinutes())}:00`
    );

    const curEndDay = endAtMidnight ? endDay + 1 : endDay;

    const endDate = new Date(
      `2020-06-0${curEndDay}T${constructMinsOrH(
        endTime.getHours()
      )}:${constructMinsOrH(endTime.getMinutes())}:00`
    );

    const endWeekDate = new Date(`2020-06-08T00:00:00`);

    let error = "";
    const dayDiff = curEndDay - startDay;
    const potentialPeriods = dayRepeat.map((el) => ({
      startDate: new Date(
        `2020-06-0${el}T${constructMinsOrH(
          startTime.getHours()
        )}:${constructMinsOrH(startTime.getMinutes())}:00`
      ),
      endDate: new Date(
        `2020-06-0${el + dayDiff}T${constructMinsOrH(
          endTime.getHours()
        )}:${constructMinsOrH(endTime.getMinutes())}:00`
      ),
    }));
    const similarWorkPeriodsUsed = similarWorkPeriods.filter((el) =>
      daySimilar.some((id) => id === el.startTime.getDate())
    );

    const workPeriodsWithoutSimilars = workPeriods.filter(
      (el) => !similarWorkPeriodsUsed.some((swpu) => swpu.id === el.id)
    );

    if (
      potentialPeriods.some((el, idx) => {
        const potPerWithoutCurrentPotPer = potentialPeriods.filter(
          (_, ppIdx) => idx !== ppIdx
        );

        if (el.endDate.getTime() > endWeekDate.getTime())
          error = "Une période dépasse dimanche minuit.";

        if (
          el.startDate.getTime() < endDate.getTime() &&
          el.endDate.getTime() > startDate.getTime()
        ) {
          error = "Des périodes se chevauchent.";
        }

        if (
          potPerWithoutCurrentPotPer.some((pp) => {
            return (
              el.startDate.getTime() < pp.endDate.getTime() &&
              el.endDate.getTime() > pp.startDate.getTime()
            );
          })
        ) {
          error = "Des périodes se chevauchent.";
        } else if (
          workPeriodsWithoutSimilars.some(
            (wp) =>
              el.startDate.getTime() < wp.endTime.getTime() &&
              el.endDate.getTime() > wp.startTime.getTime()
          )
        ) {
          error = "Des périodes se chevauchent.";
        }

        return !!error;
      })
    )
      return Promise.reject(new Error(error));

    return Promise.resolve();
  };

  const validatorSimilar = () => {
    if (
      !startTime ||
      !endTime ||
      !endDay ||
      !startDay ||
      !daySimilar ||
      daySimilar.length < 1
    )
      return Promise.resolve();

    const startDate = new Date(
      `2020-06-0${startDay}T${constructMinsOrH(
        startTime.getHours()
      )}:${constructMinsOrH(startTime.getMinutes())}:00`
    );

    const curEndDay = endAtMidnight ? endDay + 1 : endDay;

    const endDate = new Date(
      `2020-06-0${curEndDay}T${constructMinsOrH(
        endTime.getHours()
      )}:${constructMinsOrH(endTime.getMinutes())}:00`
    );

    const endWeekDate = new Date(`2020-06-08T00:00:00`);

    let error = "";
    const dayDiff = curEndDay - startDay;
    const potentialPeriodsRepeat =
      dayRepeat?.map((el) => ({
        startDate: new Date(
          `2020-06-0${el}T${constructMinsOrH(
            startTime.getHours()
          )}:${constructMinsOrH(startTime.getMinutes())}:00`
        ),
        endDate: new Date(
          `2020-06-0${el + dayDiff}T${constructMinsOrH(
            endTime.getHours()
          )}:${constructMinsOrH(endTime.getMinutes())}:00`
        ),
      })) || [];

    const potentialPeriods = daySimilar.map((el) => ({
      startDate: new Date(
        `2020-06-0${el}T${constructMinsOrH(
          startTime.getHours()
        )}:${constructMinsOrH(startTime.getMinutes())}:00`
      ),
      endDate: new Date(
        `2020-06-0${el + dayDiff}T${constructMinsOrH(
          endTime.getHours()
        )}:${constructMinsOrH(endTime.getMinutes())}:00`
      ),
    }));

    const similarWorkPeriodsUsed = similarWorkPeriods.filter((el) =>
      daySimilar.some((id) => id === el.startTime.getDate())
    );

    const workPeriodsWithoutSimilars = workPeriods.filter(
      (el) => !similarWorkPeriodsUsed.some((swpu) => swpu.id === el.id)
    );

    if (
      potentialPeriods.some((el, idx) => {
        const potPerWithoutCurrentPotPer = potentialPeriods.filter(
          (_, ppIdx) => idx !== ppIdx
        );

        if (el.endDate.getTime() > endWeekDate.getTime())
          error = "Une période dépasse dimanche minuit.";
        else if (
          el.startDate.getTime() < endDate.getTime() &&
          el.endDate.getTime() > startDate.getTime()
        ) {
          error = "Des périodes se chevauchent.";
        } else if (
          potPerWithoutCurrentPotPer.some((pp) => {
            return (
              el.startDate.getTime() < pp.endDate.getTime() &&
              el.endDate.getTime() > pp.startDate.getTime()
            );
          })
        ) {
          error = "Des périodes se chevauchent.";
        } else if (
          workPeriodsWithoutSimilars.some(
            (wp) =>
              el.startDate.getTime() < wp.endTime.getTime() &&
              el.endDate.getTime() > wp.startTime.getTime()
          )
        ) {
          error = "Des périodes se chevauchent.";
        } else if (
          potentialPeriodsRepeat.some(
            (wp) =>
              el.startDate.getTime() < wp.endDate.getTime() &&
              el.endDate.getTime() > wp.startDate.getTime()
          )
        ) {
          error = "Des périodes se chevauchent.";
        }
        return !!error;
      })
    )
      return Promise.reject(new Error(error));

    return Promise.resolve();
  };

  return (
    <Form
      id="planning-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
      <TitleContainer label="Éditer la période">
        <StyledButton
          label="Annuler"
          onClick={handleOnClose}
          fontSize="14px"
          btnType="cancel"
          type="button"
        />
        <EditButton
          label="Modifier"
          type="submit"
          value="submit"
          fontSize="14px"
        />
      </TitleContainer>

      <FormContainer>
        <FormRow>
          <DayFormCol
            label="Jour de début"
            name="startDay"
            width="50%"
            dependencies={["endDay", "startTime", "endTime", "endAtMidnight"]}
            rules={[
              { required: true },
              {
                validator: validatorPeriod,
              },
            ]}
          >
            <Select options={weekDays} />
          </DayFormCol>
          <HourFormRow
            label="Heure de début"
            name="startTime"
            width="35%"
            rules={[{ required: true }]}
          >
            <ReactDatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Heure"
              dateFormat="HH:mm"
              allowClear={false}
            />
          </HourFormRow>
        </FormRow>

        <FormRow>
          {startDay !== 8 && (
            <DayFormCol
              label="Jour de fin"
              name="endDay"
              rules={[{ required: true }]}
              width="50%"
            >
              <Select options={weekDays.filter((el) => el.id !== 8)} />
            </DayFormCol>
          )}
          <HourFormRow
            label="Heure de fin"
            name="endTime"
            rules={[{ required: true }]}
            width="35%"
            withoutPaddingRight
            $isEndHour
          >
            <ReactDatePicker
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Heure"
              dateFormat="HH:mm"
              allowClear={false}
              disabled={endAtMidnight}
            />
          </HourFormRow>
          <FormCol
            label="Fini à minuit"
            name="endAtMidnight"
            width="15%"
            withoutPaddingRight
            valuePropName="checked"
          >
            <Checkbox />
          </FormCol>
        </FormRow>

        {startDay !== 8 && (
          <FormRow>
            <FormCol
              label="Répéter pour (jour de début)"
              name="dayRepeat"
              withoutPaddingRight
              dependencies={[
                "startDay",
                "endDay",
                "startTime",
                "endTime",
                "endAtMidnight",
                "daySimilar",
              ]}
              rules={[
                {
                  validator: validatorRepeat,
                },
              ]}
            >
              <Select
                options={weekDays.filter((el) => el.id !== 8)}
                mode="multiple"
              />
            </FormCol>
          </FormRow>
        )}
        {startDay !== 8 && similarWorkPeriods.length > 0 && (
          <FormRow>
            <FormCol
              label="Appliquer aux périodes similaires commençant le "
              name="daySimilar"
              withoutPaddingRight
              dependencies={[
                "startDay",
                "endDay",
                "startTime",
                "endTime",
                "endAtMidnight",
                "dayRepeat",
              ]}
              rules={[
                {
                  validator: validatorSimilar,
                },
              ]}
            >
              <Select
                options={weekDays.filter(
                  (el) =>
                    el.id !== 8 &&
                    similarWorkPeriods.some((swp) => {
                      return swp.startTime.getDate() === el.id;
                    })
                )}
                mode="multiple"
              />
            </FormCol>
          </FormRow>
        )}
        {selectedWorkPeriod && (
          <FormRow marginTop="32px">
            <DeleteText
              onClick={() => {
                const ids = [selectedWorkPeriod.id];
                const similarWorkPeriodsUsed = similarWorkPeriods.filter((el) =>
                  daySimilar.some((id) => id === el.startTime.getDate())
                );
                similarWorkPeriodsUsed?.forEach((el) => ids.push(el.id));
                deleteWorkPeriod({ ids, planning_id: planningId }, {});
                setWorkPeriodsModalObj();
              }}
            >
              Supprimer {daySimilar?.length > 0 ? "les périodes" : "la période"}
            </DeleteText>
          </FormRow>
        )}
      </FormContainer>
    </Form>
  );
}

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

const DeleteText = styled.span`
  text-decoration: underline;
  color: red;
  font-size: 12px;
  cursor: pointer;
`;

const DayFormCol = styled(FormCol)`
  .ant-form-item-required.ant-form-item-no-colon {
    width: 115px;
  }
`;

const HourFormRow = styled(FormCol)`
  .ant-form-item-required.ant-form-item-no-colon {
    width: 125px;
  }
  ${({ $isEndHour }) =>
    $isEndHour &&
    css`
      margin-left: auto;
      padding-left: 8px;
    `}
`;

export default WorkPeriodForm;
