import React from "react";
import styled from "styled-components";

function ArrowLeft({ className = "", ...iconProps }) {
  return (
    <StyledIcon
      className={`fa-duotone fa-arrow-left ${className}`}
      {...iconProps}
    />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: ${({ $fontSize }) => $fontSize};
`;
export default ArrowLeft;
