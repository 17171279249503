import { Switch, TreeSelect } from "antd";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Label from "../Form/Label";
import ReactDatePicker from "../Form/ReactDatePicker";

function FilterHeader({ params, setParams, entities, users, role }) {
  const handleOnChangeEntities = (value) => {
    setParams({
      ...params,
      entitiesToFilter: value.map((entity) => entity.value),
    }); // ...params = on déstructure l'objet et on garde les infos contenues dedans
  };
  const handleOnChangeUsers = (value) => {
    setParams({
      ...params,
      usersToFilter: value.map((user) => user.value),
    }); // ...params = on déstructure l'objet et on garde les infos contenues dedans
  };
  const handleOnChangeExternalOrder = (value) => {
    setParams({
      ...params,
      external_order_anonymous: value,
    }); // ...params = on déstructure l'objet et on garde les infos contenues dedans
  };

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState(
    new Date(new Date().getFullYear(), 11, 31)
  );
  const handleOnChangeStartDate = (value) => {
    setParams({
      ...params,
      start_date: value.toDateString(),
    }); // ...params = on déstructure l'objet et on garde les infos contenues dedans
    setStartDate(value);
  };
  const handleOnChangeEndDate = (value) => {
    setParams({
      ...params,
      end_date: value.toDateString(),
    }); // ...params = on déstructure l'objet et on garde les infos contenues dedans
    setEndDate(value);
  };
  const formatedEntities = useMemo(
    () =>
      entities?.map((el) => ({
        id: el.id,
        disabled: el.is_disabled,
        pId: el.parent_id,
        title: el.name,
        value: el.id,
      })),
    [entities]
  );
  const defaultEntities = useMemo(
    () => entities?.map((el) => el.id),
    [entities]
  );
  const defaultUsers = useMemo(() => users?.map((user) => user.id), [users]);
  const formatedUsers = useMemo(
    () =>
      users?.map((user) => ({
        id: user.id,
        disabled: user.is_disabled,
        pId: null,
        title: `${user.first_name} ${user.last_name}`,
        value: user.id,
      })),
    [users]
  );
  return (
    <Container>
      <Wrapper>
        <Label label="Agences concernées : " />
        <StyledTreeSelect
          allowClear
          treeData={formatedEntities}
          onChange={handleOnChangeEntities}
          treeDataSimpleMode
          treeDefaultExpandAll
          treeCheckable
          treeCheckStrictly
          defaultValue={defaultEntities}
          showCheckedStrategy={TreeSelect.SHOW_ALL}
          maxTagCount="responsive"
        />
      </Wrapper>
      <Wrapper>
        <FilterDateContainer>
          <FilterDate>
            <Label label="Date de début : " />
            <StyledDatePicker
              placeholderText="Sélectionnez une date"
              selected={startDate}
              onChange={handleOnChangeStartDate}
            />
          </FilterDate>
          <FilterDate>
            <Label label="Date de fin : " />
            <StyledDatePicker
              placeholderText="Sélectionnez une date"
              selected={endDate}
              onChange={handleOnChangeEndDate}
            />
          </FilterDate>
        </FilterDateContainer>
      </Wrapper>
      {!(role === "salesman") && (
        <Wrapper>
          <Label label="Utilisateurs concernés : " />
          <StyledTreeSelect
            allowClear
            treeData={formatedUsers}
            onChange={handleOnChangeUsers}
            treeDataSimpleMode
            treeDefaultExpandAll
            treeCheckable
            treeCheckStrictly
            defaultValue={defaultUsers}
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            maxTagCount="responsive"
          />
        </Wrapper>
      )}

      <Wrapper>
        <Label label="Commandes externes anonymes : " />
        <Switch onChange={handleOnChangeExternalOrder} />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: sticky;
  top: calc(65.56px + 0.375rem + 0.375rem);
  z-index: 10;
  background-color: #f5f6f8;
`;

const Wrapper = styled.div`
  display: inline-block;
  width: 30vw;
  height: 100%;
  @media screen and (max-width: 999px) {
    width: 100%;
  }
  margin-bottom: 0.7rem;
`;
const FilterDateContainer = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
`;

const FilterDate = styled.div`
  display: inline-block;
  width: 45%;
  @media screen and (max-width: 999px) {
    width: 45%;
  }
`;

const StyledTreeSelect = styled(TreeSelect)`
  width: 100%;
`;

const StyledDatePicker = styled(ReactDatePicker)`
  width: 100%;
`;
export default FilterHeader;
