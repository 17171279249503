import React from "react";
import { string } from "prop-types";
import styled from "styled-components";

function DeleteDisable({ className, ...iconProps }) {
  return (
    <StyledIcon
      className={`fa-duotone fa-trash-xmark fa-dark-grey ${className}`}
      {...iconProps}
    />
  );
}

const StyledIcon = styled.i`
  font-size: 12px;
  :hover {
    transform: initial !important;
    cursor: not-allowed;
  }
`;

DeleteDisable.propTypes = {
  className: string,
};

DeleteDisable.defaultProps = {
  className: "",
};

export default DeleteDisable;
