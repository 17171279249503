import React from "react";
import { instance, postData, getData } from "./instance";
import { useStore } from "../store";
import { useMutation, useQuery } from "react-query";

const getUrlParams = () => {
    const lastSegment = window.location.pathname.split("/").pop();
    if (!lastSegment) return "error"
    return lastSegment
};



export const fetchDifficultyProfession = (formToken) => {
    const { data, isSuccess, refetch} = useQuery('getDifficultyProfessions', () => getData(formToken, `/difficulty_profession/${getUrlParams()}`))
    return data
}
export const fetchDifficultyProfessionOpportunity = (formToken, id_opportunity) => {
    const { data, isSuccess, refetch} = useQuery('getDifficultyProfessionsOpportunity', () => getData(formToken, `/difficulty_by_opportunity/${id_opportunity}`))
    return data
}

export const updateDifficulty = (formToken) => useMutation(updateTodo => postData(formToken, "/difficulty_profession/update", updateTodo))

export const createDifficulty = (formToken) => useMutation(createTodo=> postData(formToken, "/difficulty_profession/create", createTodo))

export const deleteDifficulty = (formToken) => useMutation(deleteTodo => postData(formToken, "/difficulty_profession/delete", deleteTodo))