import React, { useEffect } from "react";
import { Form, Modal } from "antd";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import FormCol from "../../Form/FormCol";
import FormRow from "../../Form/FormRow";
import Input from "../../react-ui/Input";
import TitleContainer from "../../react-ui/TitleContainer";
import { useStore } from "../../store";
import AddButton from "../../react-ui/AddButton";
import Button from "../../react-ui/Button";
import { postData } from "../../request/instance";
import { opportunityField, validateMessages } from "../../constant";
import Select from "../../react-ui/Select";

const selector = (state) => ({
  formToken: state.formToken,
});

function ProviderModal({
  opportunity,
  workId,
  works,
  providerModalType,
  setProviderModalType,
  companyId,
  formOpp,
  providers,
}) {
  const [form] = Form.useForm();
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();

  const { mutate: createProvider, isLoading: isProviderMutationLoading } =
    useMutation(
      (todoData) => postData(formToken, "/current_provider", todoData),
      {
        onSuccess: (provider) => {
          const newProviders = [...providers, provider];
          const providersOpportunityIds =
            formOpp.getFieldValue(
              opportunityField.providedOpportunityCustomer
            ) || [];

          newProviders.sort((a, b) => {
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });
          queryClient.setQueryData("Providers", newProviders);
          formOpp.setFieldsValue({
            [opportunityField.providedOpportunityCustomer]: [
              ...providersOpportunityIds,
              provider.id,
            ],
          });
        },
      }
    );

  const closeModal = () => {
    setProviderModalType("");
    form.resetFields();
  };

  const handleSubmit = (values) => {
    createProvider({
      current_provider: {
        name: values.name,
        company_id: companyId,
        work_id: values[opportunityField.workId],
      },
    });

    closeModal();
  };

  useEffect(() => {
    if (!opportunity)
      form.setFieldsValue({ [opportunityField.workId]: workId });
  }, [form, workId, opportunity]);

  return (
    <StyledModal
      destroyOnClose
      maskClosable={false}
      open={providerModalType !== ""}
      footer={null}
      closable={false}
      onCancel={closeModal}
      bodyStyle={{ padding: 0 }}
      width={600}
      getContainer={false}
      forceRender
    >
      <Form
        id={`new-${providerModalType}-form`}
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{
          [opportunityField.workId]: opportunity
            ? opportunity[opportunityField.workId]
            : workId,
        }}
      >
        <TitleContainer label="Créer un prestataire">
          <StyledButton
            label="Annuler"
            onClick={closeModal}
            fontSize="14px"
            btnType="cancel"
            type="button"
          />
          <AddButton
            label="Créer le prestataire"
            type="submit"
            value="submit"
            fontSize="14px"
            loading={isProviderMutationLoading}
          />
        </TitleContainer>
        <FormContainer>
          <FormRow>
            <FormCol
              label="Nom du prestataire"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Nom du prestataire" />
            </FormCol>
          </FormRow>
          <FormRow marginTop={works.length === 1 ? "0" : undefined}>
            <FormCol
              label="Métier"
              rules={[{ required: true }]}
              name={opportunityField.workId}
            >
              <StyledSelect
                options={works}
                placeholder="Sélectionnez un métier"
              />
            </FormCol>
          </FormRow>
        </FormContainer>
      </Form>
    </StyledModal>
  );
}

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

export default ProviderModal;
