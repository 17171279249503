import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { centsToEuros } from "../../../../utils/currencyConverter";
import { formatNumberString } from "../../../../utils/formatNumberString";
import NumberCell from "../../../react-ui/NumberCell";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import TableContainer from "../../CostingMovementsCarrycots/TableContainer";

const detailsColumns = (periodType) => [
  {
    title: "Intitulé",
    dataIndex: "name",
    width: "20%",
  },
  {
    title: "Coût de revient",
    dataIndex: "total_cost_price_cents",
    width: "20%",
    render: (value) => (
      <NumberCell>
        {value !== null && value !== undefined
          ? `${formatNumberString({ str: centsToEuros(value), space: true })} €`
          : ""}
      </NumberCell>
    ),
  },
  {
    title: "Marge",
    dataIndex: "margin",
    width: "20%",
    render: (value) => (
      <NumberCell>
        {value !== null && value !== undefined
          ? `${formatNumberString({ str: centsToEuros(value), space: true })} €`
          : ""}
      </NumberCell>
    ),
  },
  {
    title: "Prix de vente",
    dataIndex: "total_selling_price_cents",
    width: "20%",
    render: (value) => (
      <NumberCell>
        {value !== null && value !== undefined
          ? `${formatNumberString({ str: centsToEuros(value), space: true })} €`
          : ""}
      </NumberCell>
    ),
  },
  {
    title: `Loyer ${periodType === "Mensuel" ? "mensuel" : "trimestiel"}`,
    dataIndex: "selling_price_cents",
    width: "20%",
    render: (value) => (
      <NumberCell>
        {value !== null && value !== undefined
          ? `${formatNumberString({ str: centsToEuros(value), space: true })} €`
          : ""}
      </NumberCell>
    ),
  },
];

const selector = (state) => ({
  formToken: state.formToken,
});

const constructData = (service) => {
  const total_selling_price_cents = service.total_selling_price_cents_forced
    ? service.total_selling_price_cents_forced
    : service.total_selling_price_cents || 0;
  const total_cost_price_cents =
    service.total_cost_price_cents === null
      ? total_selling_price_cents
      : service.total_cost_price_cents || 0;
  const margin = total_selling_price_cents - total_cost_price_cents;
  const selling_price_cents = service.selling_price_cents || 0;

  return {
    ...service,
    key: service.id,
    total_cost_price_cents,
    margin,
    total_selling_price_cents,
    selling_price_cents,
  };
};

function Details({
  opportunityType,
  rentingOppId,
  periodType = "Mensuel",
  configuratio,
  vocabulary,
}) {
  const [data, setData] = useState([]);
  const { formToken } = useStore(selector);
  const queryKey = ["Services", { type: opportunityType }];

  const { data: services } = useQuery(queryKey, () =>
    getData(
      formToken,
      `/service_rentings?renting_opportunity_id=${rentingOppId}&opportunity_type=${opportunityType}`
    )
  );

  useEffect(() => {
    if (services) {
      const allServices = services.map((el) => constructData(el));
      const totalService = {
        name: "TOTAL",
        id: "TOTAL",
        total_cost_price_cents: 0,
        margin: 0,
        total_selling_price_cents: 0,
        selling_price_cents: 0,
        className: "row_children_colored",
      };

      allServices.forEach((el) => {
        totalService.total_cost_price_cents += el.total_cost_price_cents;
        totalService.margin += el.margin;
        totalService.total_selling_price_cents += el.total_selling_price_cents;
        totalService.selling_price_cents += el.selling_price_cents;
      });

      setData([totalService, ...allServices]);
    }
  }, [services]);

  return (
    <Container>
      <h4>
        DETAILS{" "}
        {opportunityType === "new_work"
          ? `${vocabulary.product_lowercase.toUpperCase()} ET POSE`
          : "SERVICES COMPLEMENTAIRES"}
      </h4>
      <TableContainer
        columns={detailsColumns(periodType)}
        data={data}
        rowKey="id"
        pagination={false}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
`;

export default Details;
