export const MaterialColumns = [
    {
        title: "intitulé",
        dataIndex: "name",
        width: "10%"
    },
    {
        title: "Categories",
        dataIndex: "categories",
        width: "10%"
    },
    {
        title: "Quantité",
        dataIndex: "quantity",
        width: "10%",
    },
    {
        title: "Coût de revient",
        children: [
            {
                title: "Unitaire", 
                dataIndex: "unitary",
                width: "10%"
            },
            {
                title: "Total",
                dataIndex: "total",
                width: "10%"
            }
        ]
    },
    {
        title: "Marge",
        dataIndex: "margin",
        width: "10%"
    },
    {
        title: "Prix de vente HT",
        children: [
            {
                title: "Unitaire",
                dataIndex: "unitary-selling-HT",
                width: "10%"
            },
            {
                title: "Total",
                dataIndex: "total-selling-HT",
                width: "10%"
            }
        ]
    }
]