import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['batchmarket', 'batchidentification', 'group', 'subcontracting']

  update_batch_identification(){
    let checkBox_batch_market = document.getElementById('offer_review_batch_market');
    let text_batch_identification = this.batchidentificationTarget;
      if (checkBox_batch_market.checked==true){
        text_batch_identification.disabled = false
        text_batch_identification.style.backgroundColor = "#ffffff";
      } else {
        text_batch_identification.disabled = true
        text_batch_identification.style.backgroundColor = "#EAEAEA";
      }
  }

  update_group(){
    let div_group= document.getElementById('group-informations');
    let checkbox_group = this.groupTarget;
    if (checkbox_group.checked == false){
      div_group.style.display = "none"
    } else {
      div_group.style.display = "flex"
    }
  }

  update_subcontractors(){
    let div_subcontractors= document.getElementById('subcontractor-informations');
    let selector_subcontracting = this.subcontractingTarget;
    if (selector_subcontracting.value == "authorized_subcon"){
      div_subcontractors.style.display = "flex"
    } else {
      div_subcontractors.style.display = "none"
    }
  }
}