export const formatDates = ({
  values,
  convertToString = false,
  dateFields,
}) => {
  const obj = {};
  dateFields.forEach((key) => {
    if (values[key])
      obj[key] = convertToString
        ? values[key].toDateString()
        : new Date(values[key]);
  });
  return obj;
};
