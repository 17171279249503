import { Controller } from "stimulus";
import { formatNumberString } from "../utils/formatNumberString";

export default class extends Controller {
  static targets = ["previous", "defaultmargin", "usemargin", "percentage"];

  initialize() {
    if (
      !!this.usemarginTargets[0] &&
      !!this.defaultmarginTargets[0] &&
      this.usemarginTargets[0].value == "true"
    ) {
      this.percentageTargets[0].style.display = "block";
    }
  }

  changeUseMargin(event) {
    if (
      !!this.usemarginTargets[0] &&
      !!this.defaultmarginTargets[0] &&
      event.target.value == "true"
    ) {
      this.percentageTargets[0].style.display = "block";
    } else {
      this.percentageTargets[0].style.display = "none";
    }
  }

  changeDotToCommas = (event) => {
    event.currentTarget.value = formatNumberString({str: event.currentTarget.value});
  };
}
