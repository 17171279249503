import React from "react";
import styled from "styled-components";
import { offerReviewFields } from "../constant";
import FormCol from "../Form/FormCol";
import FormRow from "../Form/FormRow";
import Select from "../react-ui/Select";
import Input from "../react-ui/Input";
import { inputNumberFormHelper } from "../../utils/inputNumberHelper";
import Label from "../Form/Label";

function TenderProcedure({
  updateOnChange,
  typeAosList,
  variantesList,
  visiteSitesList,
  reprisePersonnelsList,
  form,
}) {
  return (
    <>
      <FormRow marginTop="0">
        <FormCol
          label="Référence de la consultation"
          name={offerReviewFields.consultationReference}
        >
          <Input onBlur={updateOnChange} />
        </FormCol>
      </FormRow>

      <FormRow>
        <FormCol label="Type" name={offerReviewFields.typeAo} width="50%">
          <StyledSelect
            options={typeAosList}
            onChange={updateOnChange}
            allowClear
          />
        </FormCol>
        <FormCol
          label="Variantes"
          name={offerReviewFields.variantes}
          width="25%"
        >
          <StyledSelect
            options={variantesList}
            onChange={updateOnChange}
            allowClear
          />
        </FormCol>
        <FormCol
          label="Visite de site"
          name={offerReviewFields.visiteSite}
          width="25%"
        >
          <StyledSelect
            options={visiteSitesList}
            onChange={updateOnChange}
            allowClear
          />
        </FormCol>
      </FormRow>

      <StyledLabel label="Critères de décision du client : " />
      <FormRow marginTop="8px">
        <FormCol label="Prix" name={offerReviewFields.decisionCriteriaPrice}>
          <Input
            suffix="%"
            {...inputNumberFormHelper({
              form,
              key: offerReviewFields.decisionCriteriaPrice,
              onBlur: updateOnChange,
            })}
          />
        </FormCol>
        <FormCol
          label="Technique"
          name={offerReviewFields.decisionCriteriaTechnic}
        >
          <Input
            suffix="%"
            {...inputNumberFormHelper({
              form,
              key: offerReviewFields.decisionCriteriaTechnic,
              onBlur: updateOnChange,
            })}
          />
        </FormCol>
        <FormCol label="Autre" name={offerReviewFields.decisionCriteriaOther}>
          <Input
            suffix="%"
            {...inputNumberFormHelper({
              form,
              key: offerReviewFields.decisionCriteriaOther,
              onBlur: updateOnChange,
            })}
          />
        </FormCol>
      </FormRow>

      <FormRow>
        <FormCol
          label="Eléments de reprise du personnel"
          name={offerReviewFields.reprisePersonnel}
        >
          <StyledSelect
            options={reprisePersonnelsList}
            onChange={updateOnChange}
            allowClear
          />
        </FormCol>
        <FormCol label="Commentaires" name={offerReviewFields.commentsAo}>
          <StyledTextArea onBlur={updateOnChange} textArea />
        </FormCol>
      </FormRow>
    </>
  );
}

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledLabel = styled(Label)`
  padding-top: 16px;
`;

const StyledTextArea = styled(Input)`
  resize: none;
  height: 105px !important;
`;

export default TenderProcedure;
