import { useCallback, useState } from "react";
import { useQuery } from "react-query";

export const useLazyQuery = (key, fn, options = {}) => {
  const query = useQuery(key, fn, {
    ...options,
    enabled: false,
  });

  return [query.refetch, query];
};
