import { Space } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Filters from "./Filters";
import Costing3 from "../../../Chiffrage/CostingLowCurrent3/CostingLowCurrent3";
import { getUrlParams } from "../../../utils";

import { useStore } from "../../../store";
import { StyledCard } from "../styledComponent";

const selector = (state) => ({
  vocabulary: state.vocabulary,
});

function SellingTab({ configuration, setConfiguration }) {
  const { vocabulary } = useStore(selector);
  const { show_cost_price, show_margin, disable_selling_price } =
    configuration.costing.page_3;

  const [filters, setFilters] = useState({
    show_cost_price,
    show_margin,
    disable_selling_price,
  });

  useEffect(() => {
    setConfiguration({
      ...configuration,
      costing: {
        ...configuration.costing,
        page_3: {
          ...configuration.costing.page_3,
          show_cost_price: filters.show_cost_price,
          show_margin: filters.show_margin,
          disable_selling_price: filters.disable_selling_price,
        },
      },
    });
  }, [filters]);

  return (
    <Container>
      <ContainerHorizontal>
        <Filters filters={filters} setFilters={setFilters} />
      </ContainerHorizontal>
      <Space size={24} direction="vertical" style={{ padding: "10px 20px" }}>
        <PreviewCosting
          entity_work_id={getUrlParams("entity_work_id")}
          configuration={configuration}
          vocabulary={vocabulary}
        />
      </Space>
    </Container>
  );
}

function PreviewCosting({ configuration, vocabulary }) {
  return (
    <StyledCard
      headStyle={{ backgroundColor: "#0B2239", color: "white" }}
      bodyStyle={{ backgroundColor: "#F5F6F8" }}
      size="small"
      title="Prévisualisation"
      style={{
        width: "100%",
      }}
    >
      <Costing3
        responseType="consultation"
        opportunityType="maintenance"
        opportunityId="1"
        chrono={10}
        hasSummaryExcel
        hasRenting
        opportunity_work={1}
        configuration={configuration}
        vocabulary={vocabulary}
        from="configuration"
      />
    </StyledCard>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContainerHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
  padding-top: 24px;
`;
export default SellingTab;
