import React, { useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import Separator from "../../react-ui/Separator";
import { getData } from "../../request/instance";
import { useStore } from "../../store";
import CostingSection from "./CostingSection";
import ListSection from "./ListSection";

const selector = (state) => ({
  formToken: state.formToken,
});

function CostingRenting({ opportunityId, offerId, configuration, vocabulary }) {
  const { formToken } = useStore(selector);
  const [selectedLeaser, setSelectedLeaser] = useState();
  const { data: rentingOpp, isLoading } = useQuery(
    ["RentingOpportunity", opportunityId],
    () =>
      getData(formToken, `/renting_opportunity?opportunity_id=${opportunityId}`)
  );

  return (
    <Container>
      <ListSection
        opportunityId={opportunityId}
        rentingOpp={rentingOpp}
        isLoading={isLoading}
        selectedLeaser={selectedLeaser}
        configuration={configuration}
        vocabulary={vocabulary}
      />
      <Separator />
      <CostingSection
        rentingOpp={rentingOpp}
        isLoading={isLoading}
        offerId={offerId}
        selectedLeaser={selectedLeaser}
        setSelectedLeaser={setSelectedLeaser}
        configuration={configuration}
        vocabulary={vocabulary}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  height: 76vh;
`;

export default CostingRenting;
