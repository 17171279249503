/* eslint-disable consistent-return */
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Tree } from "antd";
import { DownOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useDrop } from "react-dnd";
import { useQueryClient } from "react-query";
import { useLazyQuery } from "../../../../hooks/useLazyQuery";
import { getData } from "../../../request/instance";
import Input from "../../../react-ui/Input";
import Delete from "../../../react-ui/Icons/Delete";
import { useStore } from "../../../store";
import { quantityTimeConverter } from "../../../../utils/quantityTimeConverter";
import { estimateQuantityRule } from "../../../constants/rules";
import Loader from "../../../react-ui/Loader";

const selector = (state) => ({
  isUsingMinutes: state.isUsingMinutes,
  warnings: state.warnings,
  desableLoadingOuvrageTree: state.desableLoadingOuvrageTree,
});

function OuvrageDetailsTable({
  zone,
  parentOuvrage,
  formToken,
  zoneArrayColumns,
  updateMaterialOpportunity,
  updatePrestationOpportunity,
  deleteItemInZone,
  updateOuvrageOpportunity,
  include_parent_in_tree = false,
  configuration,
  showColTVA,
}) {
  const [treeData, setTreeData] = useState([]);
  const queryClient = useQueryClient();
  const { isUsingMinutes, warnings, desableLoadingOuvrageTree } =
    useStore(selector);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [fetchOuvrage, { isFetching: ouvrageContentFetching }] = useLazyQuery(
    ["Ouvrage", { id: parentOuvrage.id, zone_id: zone.id }],
    () => getData(formToken, `/ouvrage_opportunity/${parentOuvrage.id}`),
    {
      onSuccess: (payload) => {
        if (include_parent_in_tree) {
          setTreeData(childrenPopulate([payload.ouvrage]));
        } else {
          setTreeData(childrenPopulate(payload.ouvrage.Elements));
        }
      },
    }
  );

  useEffect(() => {
    queryClient.refetchQueries([
      "Ouvrage",
      { id: parentOuvrage.id, zone_id: zone.id },
    ]);
  }, [showColTVA]);

  const getRowClassName = useCallback(
    (record) => {
      if (
        warnings
          ?.filter((w) => w.rule.rule_on === estimateQuantityRule.id)
          .some((w) => {
            const { group_parent_id, ouvrage_parent_id } = w;
            if (group_parent_id?.includes(record)) return true;
            if (ouvrage_parent_id?.includes(record)) return true;
            return false;
          })
      )
        return "tree_plus_warnnings";
      return "";
    },
    [warnings]
  );

  const backgroundColorGroup = (ElementType, item = null) => {
    if (item?.group && ElementType !== "M" && ElementType !== "P") {
      return (
        <div style={{ fontSize: 10, backgroundColor: "#eef5fc" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <div>
              {" "}
              {itemSelector(ElementType, item)}
              {item.name}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                borderInlineStart: "1px solid #f0f0f0",
              }}
            >
              {populataColumnsTree(item)}
            </div>
          </div>
        </div>
      );
    }
    if (ElementType === "O") {
      return (
        <div style={{ fontSize: 10, backgroundColor: "#eef5fc" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <div>
              {" "}
              {itemSelector(ElementType, item)}
              {item.name}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                borderInlineStart: "1px solid #f0f0f0",
              }}
            >
              {populataColumnsTree(item)}
            </div>
          </div>
        </div>
      );
    }
    if (ElementType === "M") {
      return (
        <div style={{ fontSize: 10, backgroundColor: "#E6F0FB" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <div>
              {" "}
              {itemSelector(ElementType, item)}
              {item.name}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                borderInlineStart: "1px solid #f0f0f0",
              }}
            >
              {populataColumnsTree(item)}
            </div>
          </div>
        </div>
      );
    }
    if (ElementType === "P") {
      return (
        <div style={{ fontSize: 10, backgroundColor: "#E6F0FB" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <div>
              {" "}
              {itemSelector(ElementType, item)}
              {item.name}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                borderInlineStart: "1px solid #f0f0f0",
              }}
            >
              {populataColumnsTree(item)}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={{ fontSize: 10, backgroundColor: "#eef5fc" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <div>
            {" "}
            {itemSelector(ElementType, item)}
            {item.name}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              borderInlineStart: "1px solid #f0f0f0",
            }}
          >
            {populataColumnsTree(item)}
          </div>
        </div>
      </div>
    );
  };

  const itemSelector = (ElementType, item = null) => {
    if (item?.group && ElementType !== "M" && ElementType !== "P") {
      return <i className={`${configuration.general.group_icon} mr-1`} />;
    }
    if (ElementType === "O") {
      return <i className={`${configuration.general.ouvrage_icon} mr-1`} />;
    }
    if (ElementType === "M") {
      return <i className={`${configuration.general.product_icon} mr-1`} />;
    }
    if (ElementType === "P") {
      return <i className={`${configuration.general.prestation_icon} mr-1`} />;
    }
    return <i className={`${configuration.general.ouvrage_icon} mr-1`} />;
  };

  const populataColumnsTree = (element) => {
    let type_element = "";
    if (element.ElementType === "P") {
      type_element = "prestation";
      if (!element.speed && !element.is_package) {
        element.unity_quantity = quantityTimeConverter({
          quantity: element.unity_quantity,
          isUsingMinutes:
            element.minutes !== null ? element.minutes : isUsingMinutes,
        });
      }

      if (!element.is_package) {
        element.hour_quantity = quantityTimeConverter({
          quantity: element.hour_quantity,
          isUsingMinutes:
            element.minutes !== null ? element.minutes : isUsingMinutes,
        });
      }

      if (!element.speed && !element.is_package) {
        element.estimate_quantity = quantityTimeConverter({
          quantity: element.estimate_quantity,
          isUsingMinutes:
            element.minutes !== null ? element.minutes : isUsingMinutes,
        });
      }
    }
    if (element.ElementType === "M") {
      type_element = "material";
    }
    if (element.ElementType === "O" && !element.group) {
      type_element = "ouvrage";
    }

    return zoneArrayColumns({
      updateMaterial: updateMaterialOpportunity,
      updatePrestation: updatePrestationOpportunity,
      updateOuvrage: updateOuvrageOpportunity,
      deleteItem: deleteItemInZone,
      parentQuantity:
        "parent_ouvrage_quantity" in element
          ? element.parent_ouvrage_quantity
          : parentOuvrage.quantity,
    })
      .slice(1)
      .map((v) =>
        v.children?.length > 0 ? (
          v.children.map((e) => (
            <div
              style={{
                width: `${e.width}px`,
                borderTop: "1px #f0f0f0 solid",
                borderInlineEnd: "1px solid #f0f0f0",
                padding: 5,
                marginBottom: -1,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {e.render(element.Label, {
                recordType: type_element,
                ouvrage_parent_id: parentOuvrage.id,
                ...element,
              })}
            </div>
          ))
        ) : (
          <div
            style={{
              width:
                v.dataIndex === "delete" ||
                v.dataIndex === "finalquantity" ||
                v.dataIndex === "subcontracting"
                  ? `${v.width}px`
                  : `${v.width + 1}px`,
              borderTop: "1px #f0f0f0 solid",
              borderInlineEnd: "1px solid #f0f0f0",
              padding: 5,
              marginBottom: -1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {v?.render(element.Label, {
              ouvrage_parent_id: parentOuvrage.id,
              recordType: type_element,
              ...element,
            })}
          </div>
        )
      );
  };

  const childrenPopulate = (elements, parentId = "") => {
    if (elements?.length === 0) return;
    return elements?.map((v) => ({
      title: backgroundColorGroup(v?.ElementType, v),
      key: `${parentId}-${v.id}`,
      children: childrenPopulate(v.Elements, `${parentId}-${v.id}`),
    }));
  };

  // Prevent an unexpected reload
  const previousParentOuvrageId = useRef(null);

  useEffect(() => {
    if (previousParentOuvrageId.current !== parentOuvrage?.id) {
      fetchOuvrage();
      previousParentOuvrageId.current = parentOuvrage?.id;
    }
  }, [parentOuvrage]);

  const handleExpand = (expandedKey) => {
    setExpandedKeys(expandedKey);
  };

  const switcherIcon = (el) => {
    if (expandedKeys.includes(el.eventKey)) {
      // Node is expanded, render minus icon
      return (
        <MinusOutlined
          data-row-key={`ouvrage-${el.eventKey}`}
          className={getRowClassName(el.eventKey)}
        />
      );
    }
    // Node is collapsed, render plus icon
    return (
      <PlusOutlined
        data-row-key={`ouvrage-${el.eventKey}`}
        className={getRowClassName(el.eventKey)}
      />
    );
  };

  return (
    <div className="tree_costing-table">
      {ouvrageContentFetching && !desableLoadingOuvrageTree ? (
        <Loader />
      ) : (
        <Tree
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          showLine
          expandedKeys={expandedKeys}
          onExpand={handleExpand}
          switcherIcon={switcherIcon}
          treeData={treeData}
        />
      )}
    </div>
  );
}

export default OuvrageDetailsTable;
