import React, { useState } from "react";
import Input from "../../../../../../react-ui/Input";
import {
  eurosToCents,
  centsToEuros,
} from "../../../../../../../utils/currencyConverter";
import { formatNumberString } from "../../../../../../../utils/formatNumberString";

function TotalSelling({ record, id_opportunity, update_function, disabled }) {
  const [total_selling_HT, setTotal_selling_HT] = useState([]);
  return (
    <Input
      fontSize="10px"
      textAlign="right"
      size="small"
      suffix="€"
      id={record.id}
      disabled={disabled}
      value={
        total_selling_HT?.[record.id] != null
          ? total_selling_HT?.[record.id]
          : formatNumberString({
              str: centsToEuros(record.total_selling_price_cents),
              nbDecimal: 2,
              space: true,
            })
      }
      onBlur={async (e) => {
        if (total_selling_HT?.[record.id]) {
          await update_function[record.flag]({
            ids: record.id,
            id: record.id,
            opportunity_id: id_opportunity,
            ouvrage_parent_id: record.ouvrage_parent_id,
            total_selling_price_cents: eurosToCents(e.target.value),
          });
          setTotal_selling_HT(null);
        }
      }}
      onChange={(e) => {
        setTotal_selling_HT((v) => ({
          ...v,
          [record.id]: formatNumberString({
            str: e.target.value,
            nbDecimal: 2,
          }),
        }));
      }}
      onFocus={(e) => {
        setTotal_selling_HT((v) => ({
          ...v,
          [record.id]: formatNumberString({
            str: e.target.value,
            nbDecimal: 2,
          }),
        }));
      }}
    />
  );
}

export default TotalSelling;
