import { bool, number, string } from "prop-types";
import React from "react";
import styled from "styled-components";

function Separator({ className, isHorizontal, size }) {
  return (
    <StyledLine isHorizontal={isHorizontal} size={size} className={className} />
  );
}

const StyledLine = styled.div`
  width: ${({ isHorizontal, size }) => (isHorizontal ? "auto" : `${size}px`)};
  min-width: ${({ isHorizontal, size }) =>
    isHorizontal ? "auto" : `${size}px`};
  margin: ${({ isHorizontal }) => (isHorizontal ? "10px 0" : "0 10px")};
  height: ${({ isHorizontal, size }) => (isHorizontal ? `${size}px` : "auto")};
  min-height: ${({ isHorizontal, size }) =>
    isHorizontal ? `${size}px` : "auto"};
  border-radius: 5px;
  background-color: #030a11;
`;

Separator.propTypes = {
  isHorizontal: bool,
  size: number,
  className: string,
};

Separator.defaultProps = {
  isHorizontal: false,
  size: 5,
  className: "",
};

export default Separator;
