import React, { forwardRef, useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarOutlined, CloseCircleFilled } from "@ant-design/icons";
import styled from "styled-components";
import Input from "./Input";

registerLocale("fr", fr);

const InputDatePicker = forwardRef(
  ({ clearDate, allowClear, ...props }, ref) => {
    return (
      <span ref={ref}>
        <Input
          suffix={
            <>
              <CalendarOutlined className="date-picker-calendar" />
              {!!props.value && allowClear && (
                <CloseCircleFilled
                  onClick={clearDate}
                  className="date-picker-close"
                />
              )}
            </>
          }
          {...props}
        />
      </span>
    );
  }
);

const ReactDatePicker = forwardRef(
  (
    { value, onChange, onBlur, className = "", allowClear = true, ...props },
    ref
  ) => {
    const datePickerRef = useRef();
    const handleOnChange = (e) => {
      onChange?.(e);
    };

    const handleOnBlur = (e) => {
      onBlur?.(e);
    };

    const clearDate = (event) => {
      if (datePickerRef.current) {
        datePickerRef.current.clear();
        handleOnBlur(event);
      }
      event.stopPropagation();
    };

    return (
      <Container
        isClearable={!!value && allowClear}
        ref={ref}
        className={className}
      >
        <DatePicker
          dateFormat="dd/MM/yyyy"
          selected={value ? new Date(value) : value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          locale="fr"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          autoComplete="off"
          ref={datePickerRef}
          customInput={
            <InputDatePicker clearDate={clearDate} allowClear={allowClear} />
          }
          popperProps={{ strategy: "fixed" }}
          {...props}
        />
      </Container>
    );
  }
);

const Container = styled.div`
  width: 100%;
  .react-datepicker__input-container {
    .date-picker-calendar {
      opacity: 1;
      color: rgba(0, 0, 0, 0.25);
      margin-inline-end: 0px;
    }
    .date-picker-close {
      transition: opacity 0.2s, color 0.2s;
      position: absolute;
      opacity: 0;
      color: #f1556c;
      font-size: 12px;
      cursor: pointer;
    }
  }

  .react-datepicker-popper {
    z-index: 10;
  }

  ${({ isClearable }) =>
    isClearable &&
    `.react-datepicker__input-container {
        &:hover {
          .date-picker-close {
            opacity: 1;
            &:hover {
              color: rgba(0, 0, 0, 0.45);
            }
          }
          .date-picker-calendar {
            opacity: 0;
          }
        }
      }`}
`;

export default ReactDatePicker;
