import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { debounce } from "lodash";
import { useStore } from "../../store";
import ProfilDetails from "./ProfilDetails";
import ProfilArray from "./ProfilArray";
import { getUrlParams } from "../../utils";
import useTodo from "../../hookQuery/useTodo";
import TitleContainer from "../../react-ui/TitleContainer";
import Separator from "../../react-ui/Separator";
import AddButton from "../../react-ui/AddButton";

const selector = (state) => ({
  formToken: state.formToken,
  editProfil: state.editProfil,
  addTodosProfil: state.addTodosProfil,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function Profil({ entity_work_name, configuration, from }) {
  const { formToken, editProfil } = useStore(selector);

  const [wordEntered, setWordEntered] = useState("");
  const [profilDetailsStatus, setProfilDetailsStatus] = useState("empty");

  const sendSearch = () => (wordEntered ? `&search=${wordEntered}` : "");

  const { data: profils, refetch } = useTodo(
    formToken,
    `/profil_profession?id=${getUrlParams()}${sendSearch()}&from=${from}`,
    "Profils"
  );

  useEffect(() => {
    editProfil(null);
    setProfilDetailsStatus("empty");
    debouncedSearch({ refetch });
  }, [wordEntered, refetch, editProfil]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };

  const openProfilCreation = () => {
    editProfil(null);
    setProfilDetailsStatus("create");
  };

  return (
    <Container>
      <div className="main_admin-materiels">
        <TitleContainer label="Profils disponibles">
          <AddButton
            onClick={openProfilCreation}
            disabled={profilDetailsStatus === "create"}
            label="Profil"
          />
        </TitleContainer>

        <StyledSearch
          allowClear
          placeholder="Rechercher par nom de profil"
          value={wordEntered}
          onChange={handleFilter}
        />
        <Separator isHorizontal size={3} />
        <div>
          <ProfilArray
            profilDetailsStatus={profilDetailsStatus}
            setProfilDetailsStatus={setProfilDetailsStatus}
            profils={profils}
            entity_work_name={entity_work_name}
            configuration={configuration}
          />
        </div>
      </div>
      <Separator />
      <ProfilDetails
        profilDetailsStatus={profilDetailsStatus}
        setProfilDetailsStatus={setProfilDetailsStatus}
        profils={profils}
        entity_work_name={entity_work_name}
        configuration={configuration}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
`;

const StyledSearch = styled(Search)``;

export default Profil;
