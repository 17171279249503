import React from "react";
import styled from "styled-components";

function DuplicateDuotone({
  className = "",
  $fontSize = "12px",
  ...iconProps
}) {
  return (
    <StyledIcon
      className={`fa-duotone fa-copy fa-blue fa-1xhalf ${className}`}
      $fontSize={$fontSize}
      {...iconProps}
    />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: ${({ $fontSize }) => $fontSize};
  --fa-secondary-color: rgba(0, 0, 0, 1);
`;

export default DuplicateDuotone;
