import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import ClientsArray from "./ClientsArray";
import Separator from "../../react-ui/Separator";
import { useStore } from "../../store";
import AddButton from "../../react-ui/AddButton";
import ClientInformations from "./ClientInformations";
import { generateFormData } from "../../../utils/generateFormData";
import { handlePictureKey } from "../../../utils/createFormData";
import ClientModal from "../../Chiffrage/OpportunityDetails/ClientModal";

const selector = (state) => ({
  formToken: state.formToken,
  configurationApplication: state.configurationApplication,
  procurement: state.procurement,
});

function Clients({
  companyId,
  statuses,
  activities,
  works,
  vocabulary,
  configuration,
  vocabulary_application,
  role_user,
  entities,
  users,
}) {
  const [status, setStatus] = useState("empty");
  const { configurationApplication } = useStore(selector);

  const [wordEntered, setWordEntered] = useState("");
  const [selectedClientId, setSelectedClientId] = useState();
  const [clientModalOpenFrom, setClientModalOpenFrom] = useState("");
  const [clients, setClients] = useState([]);
  const [clientsInMemory, setClientsInMemory] = useState([]);

  const generateClientFormData = useCallback(
    (values) => {
      const pictureName = "logo";
      const formName = "client";
      const formData = generateFormData({
        e: values,
        formName,
        excludedKeys: [pictureName, "work_ids", "parent"],
      });
      if (values[pictureName]) {
        handlePictureKey({
          formData,
          picture: values[pictureName],
          keyName: formName,
          pictureName,
        });
      }
      if (values.work_ids) {
        values.work_ids.forEach((workId) =>
          formData.append(`work_ids[]`, workId)
        );
      }
      if (values.parent?.value) {
        formData.append(`${formName}[parent_id]`, values.parent.value);
      }
      if (selectedClientId) formData.append("id", selectedClientId);
      return formData;
    },
    [selectedClientId]
  );

  useEffect(() => {
    setClientsInMemory((olds) =>
      [...clients, ...olds].filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      )
    );
  }, [clients]);

  return (
    <Container>
      <InivteButton
        label={
          vocabulary_application.application_name === "procurement"
            ? `Créer ${vocabulary_application.clients.a_client}`
            : `Créer ${vocabulary.clients.a_client}`
        }
        onClick={() => setClientModalOpenFrom("new")}
      />
      {configurationApplication && (
        <ClientModal
          clientModalOpenFrom={clientModalOpenFrom}
          setClientModalOpenFrom={setClientModalOpenFrom}
          companyId={companyId}
          clientActivities={activities}
          configApplication={configurationApplication}
          works={works}
          setOtherClients={setClients}
          setClientsInMemory={setClientsInMemory}
          clientsInMemory={clientsInMemory}
          vocabulary={vocabulary}
          configuration={configuration}
        />
      )}
      <ClientsArray
        clients={clients}
        wordEntered={wordEntered}
        setWordEntered={setWordEntered}
        setStatus={setStatus}
        selectedClientId={selectedClientId}
        setSelectedClientId={setSelectedClientId}
        setClientModalOpenFrom={setClientModalOpenFrom}
        setClients={setClients}
        vocabulary_application={vocabulary_application}
        vocabulary={vocabulary}
      />
      <Separator />
      <ClientInformations
        status={status}
        clients={clients}
        selectedClientId={selectedClientId}
        generateClientFormData={generateClientFormData}
        setStatus={setStatus}
        companyId={companyId}
        statuses={statuses}
        activities={activities}
        works={works}
        vocabulary_application={vocabulary_application}
        role_user={role_user}
        entities={entities}
        users={users}
        setClients={setClients}
        clientsInMemory={clientsInMemory}
        setClientsInMemory={setClientsInMemory}
        vocabulary={vocabulary}
        configuration={configuration}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: calc(100vw - 85px);
  position: fixed;
  height: calc(100% - 135.56px);
  padding-right: 15px;
  padding-bottom: 10px;
`;

const InivteButton = styled(AddButton)`
  position: absolute;
  top: -40px;
  right: 15px;
`;

export default Clients;
