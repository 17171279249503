import React, { useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import Button from "../../react-ui/Button";
import { getData } from "../../request/instance";
import AddButton from "../../react-ui/AddButton";
import Loader from "../../react-ui/Loader";

const Docusign = function ({ formToken, opportunity_id }) {
  const { data: consent, isFetching: consentFetching } = useQuery(
    ["Consent"],
    () => getData(formToken, `/docusign/consent`)
  );
  const { data: url_conosle } = useQuery(
    ["Url"],
    () => getData(formToken, `/docusign/console_view`),
    {
      enabled: !!consent?.access_token,
    }
  );

  const handleClickConsent = () => {
    window.open(consent).focus();
  };

  const loading = useCallback(() => {
    if (consentFetching) {
      return (
        <LoadingContainer>
          <Loader width={80} height={80} />
        </LoadingContainer>
      );
    }
  }, [consentFetching]);

  const docusign_content = useCallback(() => {
    if (!consentFetching) {
      if (consent?.access_token) {
        return (
          <iframe
            id="inlineFrameExample"
            title="Inline Frame Example"
            width="100%"
            height="100%"
            src={url_conosle?.url}
          />
        );
      }

      return (
        <ConsentButton>
          <AddButton
            label="Autoriser la connexion à votre compte DocuSign"
            onClick={() => handleClickConsent()}
          />
        </ConsentButton>
      );
    }
  }, [consent, url_conosle]);
  return (
    <Container>
      <title>DosuSign</title>
      {loading()}
      {docusign_content()}
    </Container>
  );
};

const Container = styled.div`
  height: 75vh;
  width: 100%;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

const ConsentButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;
export default Docusign;
