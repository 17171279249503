import React, { useMemo } from "react";
import { Form } from "antd";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { useDrop } from "react-dnd";
import TitleContainer from "../../../react-ui/TitleContainer";
import AddButton from "../../../react-ui/AddButton";
import FormRow from "../../../Form/FormRow";
import FormCol from "../../../Form/FormCol";
import Input from "../../../react-ui/Input";
import Separator from "../../../react-ui/Separator";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import { validateMessages } from "../../../constant";
import OpportunityItems from "./OpportunityItems";
import BasketItems from "./BasketItems";
import { Loading } from "../../../loading";

const selector = (state) => ({
  formToken: state.formToken,
});

function BasketModalForm({
  isOpen,
  opportunityId,
  setSelectedBasket,
  selectedBasket,
  vocabulary,
  configuration,
  basketItems,
  setBasketModalOpen,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { mutate: createBasket, isLoading: createLoading } = useMutation(
    (todo) => postData(formToken, "/referencing_basket/create", todo),
    {
      onSuccess: (payload) => {
        queryClient.invalidateQueries("Baskets");
        setSelectedBasket(payload);
        setBasketModalOpen("update");
      },
    }
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "basketItem",
      drop: async ({ record }) => {
        const toSend = {
          quantity: 1,
          referencing_basket_id: selectedBasket.id,
        };
        if (record.recordType === "products")
          toSend.material_opportunity_id = record.id;
        else if (record.recordType === "prestations")
          toSend.prestation_opportunity_id = record.id;
        else if (record.recordType === "carrycots")
          toSend.carrycot_opportunity_id = record.id;
        else toSend.movement_opportunity_id = record.id;
        createBasketItem({ referencing_basket_item: toSend });
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [selectedBasket]
  );

  const { mutate: updateBasket, status } = useMutation(
    (todo) => postData(formToken, "/referencing_basket/update", todo),
    {
      onSuccess: () => queryClient.invalidateQueries("Baskets"),
    }
  );

  const { mutate: createBasketItem, status: statusCreateBasketItem } =
    useMutation(
      (todo) => postData(formToken, "/referencing_basket_item/create", todo),
      {
        onSuccess: () => queryClient.invalidateQueries("BasketItems"),
      }
    );

  const { mutate: deleteBasketItem, status: statusDeleteBasketItem } =
    useMutation(
      (todo) => postData(formToken, "/referencing_basket_item/delete", todo),
      {
        onSuccess: () => queryClient.invalidateQueries("BasketItems"),
      }
    );

  const { mutate: updateBasketItemMutation, status: statusUpdateBasketItem } =
    useMutation(
      (todo) => postData(formToken, "/referencing_basket_item/update", todo),
      {
        onSuccess: () => queryClient.invalidateQueries("BasketItems"),
      }
    );

  const handleSubmit = (values) => {
    const toSend = {
      referencing_basket: {
        name: values.name,
        opportunity_id: opportunityId,
      },
    };
    if (isOpen === "create") {
      createBasket(toSend);
    }
    if (isOpen === "update") {
      updateBasket({ ...toSend, id: selectedBasket.id });
    }
  };

  const handleOnBlur = () => {
    if (isOpen === "update") form.submit();
  };

  const initialValues = useMemo(() => {
    if (!selectedBasket) return {};
    return {
      ...selectedBasket,
    };
  }, [selectedBasket]);

  const getStatus = () => {
    const statusArray = [
      status,
      statusCreateBasketItem,
      statusDeleteBasketItem,
      statusUpdateBasketItem,
    ];
    if (statusArray.some((st) => st === "loading")) return "loading";
    if (statusArray.some((st) => st === "success")) return "success";
    return "idle";
  };

  const backgroundColor = useMemo(() => {
    if (canDrop && isOver) return "green";
    if (canDrop) return "red";
    return "";
  }, [canDrop, isOver]);

  return (
    <Form
      id="basket-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      preserve={false}
      initialValues={initialValues}
    >
      <FormContainer>
        <FormWrapper>
          <TitleContainer label="Matériels & Prestations & Déplacements & Nacelles" />
          {selectedBasket ? (
            <OpportunityItems
              vocabulary={vocabulary}
              opportunityId={opportunityId}
              configuration={configuration}
            />
          ) : (
            <div>
              Veuillez créer un panier pour pouvoir lui attribuer des articles.
            </div>
          )}
        </FormWrapper>
        <Separator />
        <FormWrapper>
          <TitleContainer label="Détails du panier">
            {isOpen === "create" && (
              <AddButton
                label="Créer"
                type="submit"
                value="submit"
                fontSize="14px"
                loading={createLoading}
              />
            )}
            <Loading status={getStatus()} />
          </TitleContainer>
          <FormRow marginTop="0px">
            <FormCol
              label="Intitulé"
              name="name"
              rules={[{ required: true }]}
              withoutPaddingRight
            >
              <Input onBlur={handleOnBlur} />
            </FormCol>
          </FormRow>
          {selectedBasket && (
            <BasketItems
              configuration={configuration}
              createBasketItem={createBasketItem}
              selectedBasketId={selectedBasket.id}
              basketItems={basketItems}
              deleteBasketItem={deleteBasketItem}
              updateBasketItemMutation={updateBasketItemMutation}
              drop={drop}
              backgroundColor={backgroundColor}
            />
          )}
        </FormWrapper>
      </FormContainer>
    </Form>
  );
}

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
  display: flex;
  height: 550px;
`;

const FormWrapper = styled.div`
  width: 50%;
`;

export default BasketModalForm;
