import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import TableContainer from "../../../../CostingMovementsCarrycots/TableContainer";
import useTodo from "../../../../../hookQuery/useTodo";
import { useStore } from "../../../../../store";
import useUpdateTodo from "../../../../../hookQuery/useUpdateTodo";
import { currency_cents_to_euros } from "../../../../../utils";
import Input from "../../../../../react-ui/Input";
import { formatNumberString } from "../../../../../../utils/formatNumberString";
import { stringToFloat } from "../../../../../../utils/stringToFloat";
import { eurosToCents } from "../../../../../../utils/currencyConverter";
import { getMarginCol } from "../../../../../../constants/marginCol";
import { coeffToMargin } from "../../../../../../utils/marginConverter";

const store = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});
function TableCarrycotChild({ parentId, carrycotsOpp, configuration }) {
  const { formToken, isUsingMargin, calculMethod } = useStore(store);
  const { id_opportunity } = useParams();

  const { data: carrycot_costing } = useTodo(
    formToken,
    `/carrycot_costing_oprt/${id_opportunity}`,
    "CarrycotCosting"
  );

  const { data: profils_carrycot_opportunities } = useTodo(
    formToken,
    `/profils_carrycot_opportunity/${id_opportunity}`,
    "ProfilsCarrycotOpportunity"
  );

  const {
    mutate: calculate_carrycot_costing,
    isLoading: CarrycotCostingLoading,
  } = useUpdateTodo(
    formToken,
    `/carrycot_costing_oprt/update_costing`,
    "CarrycotCosting",
    ["OpportunityCotationLc", "Zones", "CarrycotOpportunities"],
    true
  );

  const {
    mutate: calculate_profils_carrycot,
    isLoading: ProfilsCarrycotLoading,
  } = useUpdateTodo(
    formToken,
    `/profils_carrycot_opportunity/update_costing`,
    "ProfilsCarrycotOpportunity",
    ["OpportunityCotationLc", "Zones", "CarrycotOpportunities"],
    true
  );

  const { data: profils_opportunity } = useTodo(
    formToken,
    `/profil_opportunity/${id_opportunity}`,
    "ProfilsOpportunity"
  );

  const [margin, setMargin] = useState(null);
  const [unitary_selling_HT, setUnitary_selling_HT] = useState(null);
  const [total_selling_HT, setTotal_selling_HT] = useState(null);
  const pushDataInTable = useMemo(() => {
    const carrycotDataSource = [];
    carrycot_costing?.forEach((carrycot) => {
      if (carrycot.carrycot_opportunity_id === parentId) {
        carrycotDataSource.push({
          updateCalculate: calculate_carrycot_costing,
          name: "Nacelle",
          id: carrycot.id,
          categories: carrycot.category_profession_id,
          total_intervention_duration: "",
          total_intervention_day: formatNumberString({
            str: carrycot.day_quantity,
            nbDecimal: 2,
            space: true,
          }),
          total_intervention_round_trip: formatNumberString({
            str: carrycot.roundtrip_quantity,
            nbDecimal: 2,
            space: true,
          }),
          // unitary_cost: currency_cents_to_euros(carrycot.cost_price_cents),
          total_cost: formatNumberString({
            str: currency_cents_to_euros(carrycot.total_cost_price_cents),
            nbDecimal: 2,
            space: true,
          }),
          margin: carrycot.margin_rate,
          total_selling_price: formatNumberString({
            str: currency_cents_to_euros(carrycot.selling_price_cents),
            nbDecimal: 2,
            space: true,
          }),
        });
      }
    });
    profils_carrycot_opportunities?.forEach((profil_carrycot) => {
      if (profil_carrycot.carrycot_opportunity_id === parentId) {
        // id à définir dans le store
        carrycotDataSource.push({
          updateCalculate: calculate_profils_carrycot,
          name: profils_opportunity?.find(
            (profil) => profil.id === profil_carrycot.profil_opportunity_id
          ).name,
          id: profil_carrycot.id,
          categories: profil_carrycot.category_profession_id,
          total_cost: formatNumberString({
            str: currency_cents_to_euros(
              profil_carrycot.total_cost_price_cents
            ),
            nbDecimal: 2,
            space: true,
          }),
          unitary_cost: formatNumberString({
            str: currency_cents_to_euros(profil_carrycot.cost_price_cents),
            nbDecimal: 2,
            space: true,
          }),
          total_intervention_duration: formatNumberString({
            str: profil_carrycot?.total_duration_hour,
            nbDecimal: 2,
            space: true,
          }),
          margin: profil_carrycot.margin_rate,
          total_selling_price: formatNumberString({
            str: currency_cents_to_euros(
              profil_carrycot.total_selling_price_cents
            ),
            nbDecimal: 2,
            space: true,
          }),
          hour_selling_price: formatNumberString({
            str: currency_cents_to_euros(profil_carrycot.selling_price_cents),
            nbDecimal: 2,
            space: true,
          }),
        });
      }
    });
    return carrycotDataSource;
  }, [
    carrycot_costing,
    profils_carrycot_opportunities,
    profils_opportunity,
    carrycotsOpp,
    configuration,
  ]);

  const CarrycotColummnsChild = [
    {
      title: "Intitulé",
      dataIndex: "name",
    },
    {
      title: "Total intervention",
      children: [
        {
          title: "Durée",
          dataIndex: "total_intervention_duration",
          width: "10%",
          render: (value) => <div>{value} h</div>,
        },
        {
          title: "Jours",
          dataIndex: "total_intervention_day",
          width: "10%",
        },
        {
          title: "Aller/retour",
          dataIndex: "total_intervention_round_trip",
          width: "10%",
        },
      ],
    },
    {
      title: "Coût de revient",
      children: [
        {
          title: "Unitaire",
          dataIndex: "unitary_cost",
          width: "10%",
          align: "right",
          render: (value) => <div>{value} €/h</div>,
        },
        {
          title: "Total",
          dataIndex: "total_cost",
          width: "10%",
          align: "right",
          render: (value) => <div>{value} €</div>,
        },
      ],
    },
    getMarginCol({
      isUsingMargin,
      configuration,
      calculMethod,
      hasSorter: false,
      render: (value, global) => (
        <Input
          fontSize="10px"
          textAlign="right"
          size="small"
          id={global.id}
          isMarginRate
          value={formatNumberString({
            str:
              margin?.[`${global.name}#${global.id}`] != null
                ? margin?.[`${global.name}#${global.id}`]
                : value,
            isCoeff: !isUsingMargin,
          })}
          onBlur={(e) => {
            if (margin?.[`${global.name}#${e.target.id}`]) {
              global.updateCalculate({
                opportunity_id: id_opportunity,
                id: global.id,
                margin_rate: coeffToMargin({
                  calculMethod,
                  isUsingMargin,
                  coeff: stringToFloat(e.target.value),
                }),
                has_change: true,
              });
            }
          }}
          onChange={(e) =>
            setMargin((v) => ({
              ...v,
              [`${global.name}#${e.target.id}`]: e.target.value,
            }))
          }
        />
      ),
    }),
    {
      title: "Prix de vente HT",
      children: [
        {
          title: "Unitaire",
          dataIndex: "hour_selling_price",
          width: "10%",
          aling: "right",
          render: (value, global) =>
            global.name === "Nacelle" ? null : (
              <Input
                fontSize="10px"
                textAlign="right"
                size="small"
                suffix="€"
                id={global.id}
                value={
                  unitary_selling_HT?.[`${global.name}#${global.id}`] || value
                }
                onBlur={(e) => {
                  if (unitary_selling_HT?.[`${global.name}#${e.target.id}`]) {
                    global.updateCalculate({
                      opportunity_id: id_opportunity,
                      id: global.id,
                      selling_price_cents: eurosToCents(e.target.value),
                      has_change: true,
                    });
                  }
                  setUnitary_selling_HT(null);
                }}
                onChange={(e) =>
                  setUnitary_selling_HT((v) => ({
                    ...v,
                    [`${global.name}#${e.target.id}`]: formatNumberString({
                      str: e.target.value,
                      nbDecimal: 2,
                    }),
                  }))
                }
                onFocus={(e) =>
                  setUnitary_selling_HT((v) => ({
                    ...v,
                    [`${global.name}#${e.target.id}`]: formatNumberString({
                      str: e.target.value,
                      nbDecimal: 2,
                    }),
                  }))
                }
              />
            ),
        },
        {
          title: "Total",
          dataIndex: "total_selling_price",
          width: "10%",
          align: "right",
          render: (value, global) => (
            <Input
              fontSize="10px"
              textAlign="right"
              size="small"
              suffix="€"
              id={global.id}
              value={total_selling_HT?.[`${global.name}#${global.id}`] || value}
              onBlur={(e) => {
                if (total_selling_HT?.[`${global.name}#${e.target.id}`]) {
                  global.updateCalculate({
                    opportunity_id: id_opportunity,
                    id: global.id,
                    total_selling_price_cents: eurosToCents(e.target.value),
                    has_change: true,
                  });
                }
                setTotal_selling_HT(null);
              }}
              onChange={(e) =>
                setTotal_selling_HT((v) => ({
                  ...v,
                  [`${global.name}#${e.target.id}`]: formatNumberString({
                    str: e.target.value,
                    nbDecimal: 2,
                  }),
                }))
              }
              onFocus={(e) =>
                setTotal_selling_HT((v) => ({
                  ...v,
                  [`${global.name}#${e.target.id}`]: formatNumberString({
                    str: e.target.value,
                    nbDecimal: 2,
                  }),
                }))
              }
            />
          ),
        },
      ],
    },
  ];
  return (
    <TableContainer columns={CarrycotColummnsChild} data={pushDataInTable} />
  );
}
export default TableCarrycotChild;
