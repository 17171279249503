$(document).ready(() => {

const opportunityNew = document.getElementById("create_opportunity")
const opportunityEdit = document.getElementById("edit_opportunity")

if(!opportunityNew && !opportunityEdit){
  return
}
const formNewClient = document.getElementById("new_client_form");
const inputFormClientName = document.getElementById("first_form_client_name");
const dropDownClients = document.querySelector(".opportunity_client_id");
const dropDownProvidedCustomer = document.querySelector(".opportunity_provided_customer");
const dropDownClientsDecisionMaker = document.querySelector(".opportunity_decision_maker_customer");
const currentUserCompanyId = document.getElementById("main-data");
const btnCreateClient = document.getElementById("create-client");

if (currentUserCompanyId && btnCreateClient) {
  const id = currentUserCompanyId.dataset.company;
  const btnAddNewClient = document.querySelectorAll(".btn-add-new-client");
  btnAddNewClient.forEach(btn => btn.addEventListener('mouseover', () => {
    btnCreateClient.disabled = false
  }));
}
if (dropDownClients) {
  const id = currentUserCompanyId.dataset.company;
    const displayAllClients = () => {
      // GET Request
      fetch(`/companies/${id}/clients`, {
        method: "GET",
        headers: { "Content-Type": "application/json",
                   "Accept": "application/json",
                   "X-Requested-With": "XMLHttpRequest",
                 }
      })
      .then(response => response.json())
      .then((data) => {
        eval(data)
        // order by id data JSON
          const dynamicSort = (property) => {
              let sortOrder = 1;
              if(property[0] === "-") {
                  sortOrder = -1;
                  property = property.substr(1);
              }
              return function (a,b) {
                  const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                  return result * sortOrder;
              }
          }

          data.sort(dynamicSort("id"));
          // select the last element to insert only it
          const element = data[data.length -1];
          const selectDropdownBilledCustomer = $(".dropdown-billed-customer");
          const selectDropdownProvidedCustomer = $(".dropdown-provided-customer");
          const selectDropdownDecisionMakerCustomer = $(".dropdown-decision-maker-customer");
          // delete all items of the native select element
          selectDropdownBilledCustomer.innerHTML= "";
          selectDropdownProvidedCustomer.innerHTML= "";
          selectDropdownDecisionMakerCustomer.innerHTML= "";
          if (element) {
            // if ($(`.dropdown-billed-customer option[value='${element.id}']`).length === 0) {
            //   selectDropdownBilledCustomer.append("<option value=\"" +  element.id + "\">" +  element.name + "</option>");
            // }
            if ($(`.dropdown-provided-customer option[value='${element.id}']`).length === 0) {
              selectDropdownProvidedCustomer.append("<option value=\"" +  element.id + "\">" +  element.name + "</option>");
            } else {
            }
            if ($(`.dropdown-decision-maker-customer option[value='${element.id}']`).length === 0) {
              selectDropdownDecisionMakerCustomer.append("<option value=\"" +  element.id + "\">" +  element.name + "</option>");
            }
          }
      })
      // POST Request
      if (formNewClient) {
        const addNewClient = () => {
          fetch(`/companies/${id}/send_client`, {
            method: "POST",
            headers: { "Content-Type": "application/json",
                       "Accept": "application/json",
                       "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
                     },
            body: JSON.stringify({ "name": inputFormClientName.value })
          }).then(response => response.json())
            .then((data) => {
              eval(data)

            })
            $("#newClientModal").modal("hide");
        };
        formNewClient.addEventListener("submit", addNewClient)
      }
      // refresh the input first_name
      const inputValues = formNewClient.getElementsByTagName("input");
      inputValues.forEach(input => {
        if (input.value !== "Créer cette entreprise") {
          input.value = "";
        }
      })
    };

    dropDownClients.addEventListener("mouseenter", displayAllClients)
    dropDownProvidedCustomer.addEventListener("mouseenter", displayAllClients)
    dropDownClientsDecisionMaker.addEventListener("mouseenter", displayAllClients)
  }
})