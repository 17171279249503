import React from "react";
import { encodeUrlParams } from "./encodeUrlParams";
import { sendCategoryFiltered } from "./sendCategoryFiltered";
import { sendCompatibleMaterials } from "./sendCompatibleMaterials";

export const fetchOuvragesUrl = (
  page,
  nomenclature_position,
  selectedNomenclature,
  expendNomenclature = 0,
  search_word,
  id_opportunity
) => {
  const base_url = "/ouvrages?";
  let params_basics = `page=${page}&nomenclature_position=${nomenclature_position}&opportunity_id=${id_opportunity}`;
  if (search_word) {
    params_basics = params_basics + `&search=${search_word}`;
  }
  if (selectedNomenclature) {
    params_basics =
      params_basics +
      `&nomenclature_id=${selectedNomenclature}&load_ouvrages=${true}`;
  } else {
    params_basics =
      params_basics +
      `&nomenclature_id=${expendNomenclature}&load_nomenclature=${true}`;
  }

  return base_url + params_basics;
};
const getUrl = (from) => {
  if (from === "admin_associate") {
    return "/ouvrage_professions/child?";
  }
  return "/ouvrage_professions?";
};
export const fetchOuvragesProfessionUrl = ({
  search,
  nextPage,
  categoryFiltered,
  ouvrage_id,
  from,
  material_parent_id,
  group,
  selectedFamilyId,
  compatibleMaterials,
  id,
  from_zone_template,
  selectedZoneTemplate,
}) => {
  let url = getUrl(from);
  const params = {
    ...((from === "admin" ||
      from === "admin_associate" ||
      from === "consultation") && {
      next_page: nextPage,
      id: id,
      from,
      group,
      ...(from_zone_template && { from_zone_template }),
      ...(selectedZoneTemplate && {
        selected_zone_template_id: selectedZoneTemplate.id,
      }),
      ...(ouvrage_id && from === "admin_associate" && { id: ouvrage_id }),
      // ...(from !== "admin" && { id }),
      ...(search && { search }),
      ...(material_parent_id && { material_parent_id }),
      ...(selectedFamilyId && { family_profession_id: selectedFamilyId }),
    }),
  };
  url += encodeUrlParams(params);
  url += sendCategoryFiltered(categoryFiltered);
  url += sendCompatibleMaterials(compatibleMaterials);
  return url;
};
