import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../dashboard";

function DashboardRoutes({ token, users, entities, role }) {
  return (
    <Routes>
      <Route
        index
        element={
          <Dashboard
            token={token}
            users={users}
            entities={entities}
            role={role}
          />
        }
      />
    </Routes>
  );
}

export default DashboardRoutes;
