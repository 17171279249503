import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import styled, { css } from "styled-components";

const DraggableBodyRow = React.memo(
  ({
    index,
    moveRow,
    className,
    style,
    itemId,
    dragKey,
    children,
    record,
    droppable = true,
    draggable = true,
    type = "itemType",
    accept = type,
    canDropFunction = () => true,
    onHover,
    onStart,
    onDrop,
    onDragEnter,
    ...restProps
  }) => {
    const [direction, setDirection] = useState("");
    const ref = useRef(null);

    const [{ draggedRecord, isOver, canDrop }, drop] = useDrop({
      accept,
      canDrop: (item) =>
        canDropFunction(item.index, index, {
          record: item.record,
          hoverRecord: record,
        }),
      hover(item, monitor) {
        // Don't replace an item with itself
        if (onHover) onHover(ref);
        if (!ref.current || item.index === index || !canDrop) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current?.getBoundingClientRect();

        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        setDirection(hoverClientY > hoverMiddleY ? "downward" : "upward");
      },
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};
        if (dragIndex === index) {
          return { draggedRecord: monitor.getItem()?.record };
        }
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
          draggedRecord: monitor.getItem()?.record,
        };
      },
      drop: (item, monitor) => {
        onDrop();
        moveRow(item.index, index, {
          record: item.record,
          hoverRecord: record,
          direction,
          type: monitor.getItemType(),
        });
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type,
      item: {
        index,
        record,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        type: monitor.getItemType(),
      }),
    });

    useEffect(() => {
      if (isDragging && onStart) onStart({ record, type });
    }, [isDragging, onStart, record, type]);

    const rowProps = useMemo(
      () =>
        itemId
          ? {
              id: `drag-row-${itemId}`,
              className: `${className}`,
              direction: isOver && canDrop ? direction : "",
              $type: type,
              canDrop,
              isOver,
              ref,
              style: {
                ...(!dragKey && { cursor: "move" }),
                ...style,
              },
              ...restProps,
            }
          : {},
      [
        className,
        dragKey,
        direction,
        isOver,
        itemId,
        restProps,
        style,
        canDrop,
        type,
      ]
    );

    if (!itemId)
      return (
        <div
          className={`${className}`}
          style={{
            ...style,
          }}
          {...restProps}
        >
          {children}
        </div>
      );

    if (droppable && draggable) drop(drag(ref));
    else if (draggable) drag(ref);
    else if (droppable) drop(ref);

    const content = dragKey
      ? React.Children.map(children, (child) => {
          if (child.key === dragKey) {
            return React.cloneElement(child, {
              ref,
            });
          }
          return child;
        })
      : children;

    return (
      <StyledRowDiv
        onDragEnter={() =>
          onDragEnter({ draggedRecord, hoverRecord: record, droppable })
        }
        {...rowProps}
      >
        {content}
      </StyledRowDiv>
    );
  }
);

const StyledRowDiv = styled.div`
  ${({ $type, isOver, theme, canDrop }) =>
    ((canDrop && $type === "onlyDropItem") || $type === "needDropItem") &&
    css`
      background-color: ${isOver ? "#c7faa4" : theme.colors.orange04};
      box-shadow: inset 0px 0px 0px 2px
        ${isOver ? "#3f960eed" : theme.colors.orange10};
      border-radius: 8px;
    `}
  ${({ direction, theme, $type, canDrop }) =>
    canDrop &&
    !["onlyDropItem", "needDropItem"].includes($type) &&
    direction === "downward" &&
    css`
      &:after {
        position: absolute;
        top: 24px;
        width: 8px;
        height: 8px;
        background-color: transparent;
        border: 2px solid ${theme.colors.blue07};
        border-radius: 50%;
        left: 8px;
        content: "";
      }
      &:before {
        position: absolute;
        top: 27px;
        width: calc(100% - 16px);
        height: 2px;
        background-color: ${theme.colors.blue07};
        left: 16px;
        content: "";
      }
    `}

  ${({ direction, theme, $type, canDrop }) =>
    canDrop &&
    !["onlyDropItem", "needDropItem"].includes($type) &&
    direction === "upward" &&
    css`
      &:after {
        position: absolute;
        top: -4px;
        width: 8px;
        height: 8px;
        background-color: transparent;
        border: 2px solid ${theme.colors.blue07};
        border-radius: 50%;
        left: 8px;
        content: "";
      }
      &:before {
        position: absolute;
        top: -1px;
        width: calc(100% - 16px);
        height: 2px;
        background-color: ${theme.colors.blue07};
        left: 16px;
        content: "";
      }
    `}
`;

export default DraggableBodyRow;
