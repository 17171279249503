import React, { useMemo } from "react";
import { bool, func, object, shape } from "prop-types";
import Form from "../../Form";

function UnityForm({
  isShowing,
  onSubmit,
  updateOnChange,
  submit,
  initialUnity,
}) {
  const formFactory = useMemo(() => {
    const firstRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Nom de l'unité",
          },
          name: "name",
          label: "Intitulé",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            placeholder: "Ajoutez une abréviation",
          },
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
          name: "abbreviation",
          label: "Abréviation",
        },
      },
    ];

    return [{ columns: firstRow, key: "name+abbreviation" }];
  }, []);

  const initialValues = useMemo(() => {
    if (!initialUnity) return {};
    return {
      name: initialUnity.name,
      abbreviation: initialUnity.abbreviation,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUnity?.id]);

  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={formFactory}
      isShowing={isShowing}
      id="parameter-form"
    />
  );
}

UnityForm.propTypes = {
  isShowing: bool,
  onSubmit: func,
  updateOnChange: bool,
  submit: shape({
    onCancel: func,
  }),
  initialUnity: object,
};

UnityForm.defaultProps = {
  isShowing: false,
  onSubmit: undefined,
  updateOnChange: false,
  submit: undefined,
  initialUnity: undefined,
};

export default UnityForm;
