import React from "react";
import { Form, Upload as AntdUpload } from "antd";
import {
  array,
  bool,
  func,
  number,
  object,
  oneOf,
  shape,
  string,
} from "prop-types";
import { UploadOutlined } from "@ant-design/icons";
import styled, { css } from "styled-components";
import Label from "./Label";

function Upload({
  upload,
  text,
  icon,
  handleUpdateOnChange,
  isShowing,
  singleLine,
  ...formItemProps
}) {
  const handleOnChange = (e) => {
    if (upload.onChange) upload.onChange(e);
    handleUpdateOnChange();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const disabled = upload.disabled || isShowing;

  return (
    <Form.Item
      {...formItemProps}
      valuePropName="fileList"
      getValueFromEvent={normFile}
      label={<Label label={formItemProps.label} rules={formItemProps.rules} />}
    >
      <StyledUpload
        {...upload}
        disabled={disabled}
        onChange={handleOnChange}
        $singleLine={singleLine}
        beforeUpload={() => false} // Prevent <AntdUpload /> trigerring post default request after choosing img
      >
        <div>
          {text ? (
            <>
              {icon}
              <UploadText>{text}</UploadText>
            </>
          ) : (
            <UploadOutlined
              style={{
                padding: "4px",
                backgroundColor: "#f0f1f3",
                border: "1px solid #d9d9d9",
                borderRadius: "5px",
              }}
            />
          )}
        </div>
      </StyledUpload>
    </Form.Item>
  );
}

const StyledUpload = styled(AntdUpload)`
  .ant-upload-list-picture-card-container {
    margin: 0 10px 0 0;
  }
  .ant-upload-select-picture-card {
    margin: 0;
  }
  a {
    color: rgba(0, 0, 0, 0.88) !important;
  }
  ${({ $singleLine }) =>
    $singleLine &&
    css`
      display: flex;
      align-items: center;
      gap: 10px;
      .ant-upload-list-item {
        margin-top: 0px !important;
      }
    `}
`;

const UploadText = styled.p`
  margin-top: 8px;
`;

Upload.propTypes = {
  upload: shape({
    disabled: bool,
    listType: oneOf(["text", "picture", "picture-card"]),
    maxCount: number,
    accept: string,
    onChange: func,
  }).isRequired,
  text: string,
  icon: object,
  name: string.isRequired,
  label: string,
  rules: array,
  singleLine: bool,
  handleUpdateOnChange: func.isRequired,
  isShowing: bool,
};

Upload.defaultProps = {
  text: "Télécharger image",
  icon: <UploadOutlined />,
  label: "",
  rules: [],
  singleLine: false,
  isShowing: false,
};

export default Upload;
