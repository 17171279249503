import React from "react";
import { instance, postData, getData } from "./instance";
import { useStore } from "../store";
import { useMutation, useQuery } from "react-query";

const getUrlParams = () => {
    const lastSegment = window.location.pathname.split("/").pop();
    if (!lastSegment) return "error"
    return lastSegment
};



export const fetchProfilProfession = (formToken) => {
    const { data, isSuccess, refetch} = useQuery('getProfilProfession', () => getData(formToken, `/profil_profession/${getUrlParams()}`))
    return data
}

export const updateProfils = (formToken) => useMutation(updateTodo => postData(formToken, "/profil_profession/update", updateTodo))

export const createProfils = (formToken) => useMutation(createTodo => postData(formToken, "/profil_profession/create", createTodo))

export const deleteProfils = (formToken) => useMutation(deleteTodo => postData(formToken, "/profil_profession/delete", deleteTodo))