import { useCallback, useMemo, useState } from "react";
import { useStore } from "../components/store";

const selector = (state) => ({
  configurations: state.configurations,
});

export const useConfigurationWork = (name) => {
  const { configurations } = useStore(selector);

  const configurationWork = useMemo(() => {
    if (!configurations || configurations?.length === 0)
      return { configuration: null, work: null };
    if (name && configurations.length > 1)
      return { ...configurations?.find((config) => config.work === name) };

    if (configurations[0].configuration) return { ...configurations[0] };
    return { configuration: configurations[0], work: null };
  }, [name]);

  return configurationWork;
};
