import { encodeUrlParams } from "./encodeUrlParams";

export const fetchExternalOrdersUrl = ({ search, clientId }) => {
  let url = `/external_orders?`;
  const params = {
    client_id: clientId,
    ...(search && { search }),
  };

  url += encodeUrlParams(params);

  return url;
};
