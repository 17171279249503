import { encodeUrlParams } from "./encodeUrlParams";

export const fetchMovementsUrl = ({ from, id, search }) => {
  let url =
    from === "admin" ? "/movement_profession?" : "/movement_by_opportunity?";
  const params = {
    from,
    ...(search && { search }),
    ...(from === "admin" && { entity_work_id: id }),
    ...(from === "referencing" && {
      id_opportunity: id,
      movement_opportunities: true,
      include_entities_child: true,
      only_from_opp: true,
    }),
    ...(from === "frameworkAgreement" && {
      movement_framework_agreements: true,
      framework_agreement_id: id,
      include_entities_child: true,
      only_from_fa: true,
    }),
  };

  url += encodeUrlParams(params);

  return url;
};
