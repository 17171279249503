/* eslint-disable no-param-reassign */
import { getData } from "../../../../../../request/instance";
import { fetchOuvragesProfessionUrl } from "../../../../../../../utils/fetchOuvragesUrl";

export const OuvrageSmart4Fetching = ({
  formToken,
  showFaItems,
  ouvrageSmart4NextPage,
  categoryFiltered,
  wordEntered,
  group_filter,
  compatibleMaterials,
  id,
  from,
  faId,
  from_zone_template = false,
  selectedZoneTemplate,
}) => {
  return async () => {
    let come_from = "";
    let id_from = "";
    if (from === "admin") {
      come_from = "admin";
      id_from = id;
    } else {
      come_from = showFaItems ? "quotation" : "consultation";
      id_from = showFaItems ? faId : id;
    }
    const data = await getData(
      formToken,
      fetchOuvragesProfessionUrl({
        nextPage: ouvrageSmart4NextPage.current,
        categoryFiltered,
        search: wordEntered,
        from: come_from,
        group: group_filter,
        compatibleMaterials,
        id: id_from,
        from_zone_template,
        selectedZoneTemplate,
      })
    );
    return data;
  };
};

export const OuvrageSmart4Success = (
  didFirstInfiniteQuery,
  setOuvrageSmart4,
  ouvrageSmart4,
  ouvrageSmart4NextPage
) => {
  return {
    refetchOnWindowFocus: false,
    onSuccess: (payload) => {
      if (!didFirstInfiniteQuery.current) didFirstInfiniteQuery.current = true;
      const lastPage = payload.pages[payload.pages.length - 1];

      // Reset ouvrages when back to first page of materials
      if (lastPage.current_page === 1) {
        setOuvrageSmart4(lastPage.ouvrage_professions);
      } else
        setOuvrageSmart4([...ouvrageSmart4, ...lastPage.ouvrage_professions]);
      ouvrageSmart4NextPage.current = lastPage.next_page;
    },
  };
};
