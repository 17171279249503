import React, { useCallback, useMemo } from "react";
import { formatNumberString } from "../../../utils/formatNumberString";
import { marginToCoeff } from "../../../utils/marginConverter";

export const WorkFormInitialValue = ({
  initialWork,
  useMarginState,
  formCanceled,
  useManagementSpecialTVA,
}) => {
  const tva_profession_initial = useCallback(() => {
    const tva_filter = initialWork?.default_tva_professions?.filter(
      (tva) => tva.tva_type === "special"
    );
    if (tva_filter?.length > 0) {
      return tva_filter[0].id;
    }
    return "no_tva_special";
  }, [initialWork?.default_tva_professions]);

  return useMemo(() => {
    const default_margin = formatNumberString({
      str: marginToCoeff({
        marginRate: initialWork?.default_margin,
        isUsingMargin: useMarginState,
        calculMethod: initialWork?.calcul_method,
      }),
      isCoeff: !initialWork,
    });
    return {
      admin_licences: initialWork?.admin_licences,
      costing: initialWork?.costing,
      salesman_licences: initialWork?.salesman_licences,
      setup_licences: initialWork?.setup_licences,
      supervisor_licences: initialWork?.supervisor_licences,
      manager_licences: initialWork?.manager_licences,
      minutes: initialWork?.minutes,
      renting: initialWork?.renting,
      management_special_TVA: useManagementSpecialTVA,
      default_margin,
      use_margin: useMarginState,
      default_tva_profession: tva_profession_initial(),
      api_providers: initialWork?.api_providers
        .filter((api) => api.name !== "smart4")
        .map((api) => api.id),
      calcul_method: initialWork?.calcul_method,
      site_licences: initialWork?.site_licences,
    };
  }, [initialWork, useMarginState, formCanceled, useManagementSpecialTVA]);
};
