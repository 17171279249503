import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Checkbox, Modal } from "antd";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { postData } from "../../../request/instance";
import Eye from "../../../react-ui/Icons/Eye";
import OuvrageForm from "../OuvrageForm";
import LazyOuvrageArray from "../OuvrageStructure/LazyOuvrageArray";
import { numberSorter } from "../../../../utils/numberSorter";
import FormattedInput from "../../../react-ui/FormattedInput";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { stringToFloat } from "../../../utils";

function ChildOuvrageArray({
  token,
  ouvrageParentId,
  ouvrageSelected,
  configuration,
  vocabulary,
  from,
  setWordEntered,
  wordEntered,
  unities,
  entityWorkId,
  opportunityId,
  group,
  setImpactedModelZone,
}) {
  const queryClient = useQueryClient();
  const [categoryFiltered, setCategoryFiltered] = useState();
  const [ouvrages, setOuvrages] = useState([]);
  const [isModalOuvrageVisible, setIsModalOuvrageVisible] = useState(false);
  const [showOuvrage, setShowOuvrage] = useState();
  const [ouvrageChecked, setOuvrageChecked] = useState([]);
  const [ouvrageAssociateData, setOuvrageAssociateData] = useState([]);
  const ouvrageProfessionQueryKey = [
    "OuvrageProfession",
    ouvrageSelected[0]?.id,
  ];
  const { mutate: add_associate_ouvrage } = useMutation(
    (todo) => postData(token, "/ouvrage_profession/associate_ouvrage", todo),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(ouvrageProfessionQueryKey);
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );
  const { mutate: update_associate_ouvrage } = useMutation(
    (todo) =>
      postData(token, "/ouvrage_profession/update_associate_ouvrage", todo),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(ouvrageProfessionQueryKey);
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  const { mutate: delete_associate_ouvrage } = useMutation(
    (todo) =>
      postData(token, "/ouvrage_profession/delete_associate_ouvrage", todo),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(ouvrageProfessionQueryKey);
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  useEffect(() => {
    setOuvrageChecked([]);
  }, [ouvrageSelected[0]?.id]);

  const showModalOuvrage = (ouvrage) => {
    setIsModalOuvrageVisible(true);
    setShowOuvrage(
      ouvrages.ouvrage_professions.find((item) => item.id === ouvrage.id)
    );
  };

  const handleCancel = () => {
    setIsModalOuvrageVisible(false);
  };

  const handleAddAssociateOuvrage = (checked, record) => {
    if (checked) {
      add_associate_ouvrage({
        ...record,
        ...{ new_parent_id: ouvrageParentId },
      });
    } else {
      delete_associate_ouvrage({ parent_id: ouvrageParentId, id: record.id });
    }
  };

  const displayInputQuantity = (record) => {
    return ouvrageChecked.some((item) => item.id === record.id)
      ? ouvrageChecked.find((item) => item.id === record.id).check
      : record.associated;
  };

  const findQuantityAssociated = (record) => {
    const associatedItem = ouvrageAssociateData.find(
      (item) => item.id === record.id
    );
    return associatedItem
      ? parseFloat(associatedItem.quantity_associated)
      : null;
  };

  const getDefaultQuantity = (record) => {
    const quantity = parseFloat(record.quantity_associated);
    return Number.isNaN(quantity) ? 1 : quantity;
  };

  const inputValue = (record) => {
    const quantityAssociated = findQuantityAssociated(record);
    const defaultQuantity = getDefaultQuantity(record);

    return quantityAssociated !== null ? quantityAssociated : defaultQuantity;
  };

  const column = [
    {
      title: "Intitulé",
      dataIndex: "name",
    },
    {
      title: "Associer",
      dataIndex: "associated",
      defaultSortOrder: "descend",
      width: "65px",
      sorter: (a, b) =>
        numberSorter(a.associated, b.associated) || numberSorter(a.id, b.id),
      render: (_, record) => (
        <CenteredContainer>
          <Checkbox
            onChange={({ target: { checked } }) => {
              handleAddAssociateOuvrage(checked, record);
              if (!ouvrageChecked.some((item) => item.id === record.id)) {
                // Add the record to materialChecked if it doesn't exist
                setOuvrageChecked([
                  ...ouvrageChecked,
                  {
                    id: record.id,
                    check: checked,
                  },
                ]);
              } else {
                // Update the check value if the record exists
                setOuvrageChecked(
                  ouvrageChecked.map((item) => {
                    if (item.id === record.id) {
                      return {
                        ...item,
                        check: checked,
                      };
                    }
                    return item;
                  })
                );
              }
            }}
            checked={
              ouvrageChecked.some((item) => item.id === record.id)
                ? ouvrageChecked.find((item) => item.id === record.id).check
                : record.associated
            }
          />
        </CenteredContainer>
      ),
    },
    {
      title: "Quantité",
      dataIndex: "quantity",
      key: "quantity",
      width: "75px",
      render: (val, record) =>
        displayInputQuantity(record) && (
          <FormattedInput
            isNumber
            fontSize="10px"
            value={inputValue(record)}
            size="small"
            textAlign="right"
            onChange={(e) => {
              if (!ouvrageAssociateData.some((item) => item.id === record.id)) {
                // Add the record to materialAssociateData if it doesn't exist
                setOuvrageAssociateData([
                  ...ouvrageAssociateData,
                  {
                    id: record.id,
                    quantity_associated: formatNumberString({
                      str: e.target.value,
                    }),
                  },
                ]);
              } else {
                // Update the quantity_associated value if the record exists
                setOuvrageAssociateData(
                  ouvrageAssociateData.map((item) => {
                    if (item.id === record.id) {
                      return {
                        ...item,
                        quantity_associated: formatNumberString({
                          str: e.target.value,
                        }),
                      };
                    }
                    return item;
                  })
                );
              }
            }}
            onBlur={(e) =>
              update_associate_ouvrage({
                id: ouvrageParentId,
                child_ouvrage_id: record.id,
                quantity: stringToFloat(e.target.value),
              })
            }
          />
        ),
    },
    {
      title: "",
      dataIndex: "show",
      key: "show",
      width: "20px",
      render: (_, ouvrage) => (
        <CenteredContainer>
          <Eye
            onClick={() => {
              showModalOuvrage(ouvrage);
            }}
          />
        </CenteredContainer>
      ),
    },
  ];

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };
  const clearInput = () => {
    setWordEntered("");
  };
  return (
    <>
      <Modal
        width={840}
        maskClosable={false}
        open={isModalOuvrageVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <h3>Information du {vocabulary?.product_lowercase}</h3>
        <OuvrageForm
          initialOuvrage={showOuvrage}
          // unities={unities}
          from={from}
          isShowing
          unities={unities}
          configuration={configuration}
          vocabulary={vocabulary}
          group={group}
        />
      </Modal>
      <StyledSearch
        allowClear
        placeholder="Rechercher par nom"
        value={wordEntered}
        onChange={handleFilter}
      />
      <LazyOuvrageArray
        ouvrageId={ouvrageParentId}
        columns={column}
        from="admin_associate"
        ouvrages={ouvrages}
        setOuvrages={setOuvrages}
        wordEntered={wordEntered}
        categoryFiltered={categoryFiltered}
        setCategoryFiltered={setCategoryFiltered}
        group={group}
        entityWorkId={entityWorkId}
        opportunityId={opportunityId}
      />
    </>
  );
}

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSearch = styled(Search)`
  margin: 10px 0;
`;

export default ChildOuvrageArray;
