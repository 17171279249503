import React from "react";
import styled from "styled-components";
import { Checkbox, Form, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { offerReviewFields } from "../constant";
import FormCol from "../Form/FormCol";
import FormRow from "../Form/FormRow";
import Select from "../react-ui/Select";
import Input from "../react-ui/Input";
import { inputNumberFormHelper } from "../../utils/inputNumberHelper";
import ReactDatePicker from "../Form/ReactDatePicker";

const { confirm } = Modal;

function Market({
  updateOnChange,
  kindMarketsList,
  subcontractingsList,
  priceRevisionModalitiesList,
  offerReview,
  form,
}) {
  const batchMarket = Form.useWatch(offerReviewFields.batchMarket, form);
  const handleChangeBatchMarket = (e) => {
    form.setFieldsValue({ [offerReviewFields.batchIdentification]: "" });
    updateOnChange(e);
  };

  return (
    <>
      <FormRow marginTop="0">
        <FormCol
          width="75%"
          label="Objet de la consultation"
          name={offerReviewFields.consultationObject}
        >
          <Input onBlur={updateOnChange} />
        </FormCol>
        <FormCol label="Nature" name={offerReviewFields.kindMarket} width="25%">
          <StyledSelect
            options={kindMarketsList}
            onChange={updateOnChange}
            allowClear
          />
        </FormCol>
      </FormRow>

      <FormRow>
        <FormCol
          label="Durée du contrat"
          name={offerReviewFields.contractLength}
          width="75%"
        >
          <Input onBlur={updateOnChange} />
        </FormCol>
        <FormCol
          label="Date de démarrage"
          name={offerReviewFields.startDateMarket}
          width="25%"
        >
          <StyledDatePicker onChange={updateOnChange} />
        </FormCol>
      </FormRow>

      <FormRow>
        <FormCol
          label="Marché alloti"
          name={offerReviewFields.batchMarket}
          width="25%"
          valuePropName="checked"
        >
          <Checkbox onChange={handleChangeBatchMarket} />
        </FormCol>
        <FormCol
          label="Identification des lots"
          name={offerReviewFields.batchIdentification}
          width="75%"
        >
          <StyledTextArea
            disabled={!batchMarket}
            onBlur={updateOnChange}
            textArea
          />
        </FormCol>
      </FormRow>

      <FormRow>
        <FormCol
          label="Montant minimum"
          name={offerReviewFields.minimumPriceMarket}
        >
          <Input
            suffix="€"
            {...inputNumberFormHelper({
              form,
              key: offerReviewFields.minimumPriceMarket,
              onBlur: updateOnChange,
            })}
          />
        </FormCol>
        <FormCol
          label="Montant maximum"
          name={offerReviewFields.maximumPriceMarket}
        >
          <Input
            suffix="€"
            {...inputNumberFormHelper({
              form,
              key: offerReviewFields.maximumPriceMarket,
              onBlur: updateOnChange,
            })}
          />
        </FormCol>
        <FormCol label="Sous-traitance" name={offerReviewFields.subcontracting}>
          <StyledSelect
            options={subcontractingsList}
            onChange={() => {
              if (
                offerReview[offerReviewFields.subcontracting] ===
                  "authorized_subcon" &&
                form.getFieldValue(offerReviewFields.subcontracting) !==
                  "authorized_subcon"
              ) {
                const oldVal = form.getFieldValue(
                  offerReviewFields.subcontracting
                );
                form.setFieldsValue({
                  [offerReviewFields.subcontracting]: "authorized_subcon",
                });
                confirm({
                  title: `Le tableau des sous-traitants sera vidé.`,
                  icon: <ExclamationCircleOutlined />,
                  okText: "Continuer",
                  okType: "danger",
                  cancelText: "Annuler",
                  maskClosable: false,
                  onOk() {
                    form.setFieldsValue({
                      [offerReviewFields.subcontracting]: oldVal,
                    });
                    updateOnChange();
                    return false;
                  },
                  onCancel() {
                    return false;
                  },
                });
              } else updateOnChange();
            }}
            allowClear
          />
        </FormCol>
      </FormRow>

      <FormRow>
        <FormCol
          label="Modalité de révision des prix"
          name={offerReviewFields.priceRevisionModality}
        >
          <StyledSelect
            options={priceRevisionModalitiesList}
            onChange={updateOnChange}
            allowClear
          />
        </FormCol>
        <FormCol label="Commentaires" name={offerReviewFields.commentsMarket}>
          <StyledTextArea onBlur={updateOnChange} textArea />
        </FormCol>
      </FormRow>
    </>
  );
}

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledDatePicker = styled(ReactDatePicker)`
  width: 100%;
`;

const StyledTextArea = styled(Input)`
  resize: none;
  height: 105px !important;
`;

export default Market;
