import React from "react";
import SwitchComponent from "../SwitchComponent";
import { GridContainer } from "../styledComponent";

function Filters({ filters, setFilters, isDisabled }) {
  const handleChangeValue = (value, name) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  return (
    <GridContainer
      direction="vertical"
      size={16}
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        width: "35%",
      }}
    >
      <SwitchComponent
        initialValue={
          !isDisabled.show_movements ? filters.show_movements : false
        }
        labelText="Déplacements"
        isDisabled={isDisabled.show_movements}
        handleChange={handleChangeValue}
        name="show_movements"
      />
      <SwitchComponent
        initialValue={
          !isDisabled.show_carrycots ? filters.show_carrycots : false
        }
        labelText="Nacelles"
        isDisabled={isDisabled.show_carrycots}
        handleChange={handleChangeValue}
        name="show_carrycots"
      />
    </GridContainer>
  );
}

export default Filters;
