import React, { useEffect, useState } from "react";
import SwitchComponent from "../SwitchComponent";
import { StyledDiv } from "../styledComponent";

export default function GeneralSection({
  userRole,
  general,
  setGeneral,
  setProducts,
  setPrestations,
  setProfils,
  setCategories,
  setArrayBlock,
  setTextBlock,
}) {
  const [itemsFirstCol, setItemsFirstCol] = useState([
    {
      initialValue: general.pageSelectionItems,
      isDisabled: userRole !== "superadmin",
      labelText: "Page de sélection des items",
      name: "pageSelectionItems",
    },
    {
      initialValue: general.pageSelectionItems && general.accordCadre,
      isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
      labelText: "Accord cadre",
      name: "accordCadre",
    },
    {
      initialValue: general.pageSelectionItems && general.maintenance,
      isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
      labelText: "Maintenance",
      name: "maintenance",
    },
    {
      initialValue: general.carrycotsMovement ? general.deplacements : false,
      isDisabled: userRole !== "superadmin" || !general.carrycotsMovement,
      labelText: "Déplacements",
      name: "deplacements",
    },
    {
      initialValue: general.makers,
      labelText: "Fabricants",
      name: "makers",
    },
    {
      initialValue: general.option,
      labelText: "Option",
      name: "option",
    },
  ]);

  const [itemsSecondCol, setItemsSecondCol] = useState([
    {
      initialValue: general.carrycotsMovement,
      isDisabled: userRole !== "superadmin",
      labelText: "Page des déplacements & nacelles",
      name: "carrycotsMovement",
    },
    {
      initialValue: general.pageSelectionItems && general.offreLocative,
      isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
      labelText: "Offre locative",
      name: "offreLocative",
    },
    {
      initialValue: general.pageSelectionItems && general.regroupement,
      labelText: "Regroupement",
      isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
      name: "regroupement",
    },
    {
      initialValue:
        general.carrycotsMovement && general.profils
          ? general.carrycots
          : false,
      isDisabled: !general.carrycotsMovement || !general.profils,
      labelText: "Nacelles",
      name: "carrycots",
    },
    {
      initialValue: general.distributors,
      labelText: "Distributeurs",
      name: "distributors",
    },
  ]);

  const [itemsThirdCol, setItemsThirdCol] = useState([
    {
      initialValue:
        !general.carrycotsMovement && !general.pageSelectionItems
          ? false
          : general.prixVente,
      isDisabled:
        userRole !== "superadmin" ||
        (!general.carrycotsMovement && !general.pageSelectionItems),
      labelText: "Page des prix de vente",
      name: "prixVente",
    },
    {
      initialValue: general.pageSelectionItems && general.ouvrage,
      value: general.ouvrage,
      isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
      labelText: "Ouvrage",
      name: "ouvrage",
    },
    {
      initialValue: general.pageSelectionItems && general.groupement,
      isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
      labelText: "Groupement",
      name: "groupement",
    },

    {
      initialValue: general.profils,
      labelText: "Profils",
      name: "profils",
    },
    {
      initialValue: general.pageSelectionItems && general.batiprix,
      isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
      labelText: "Batiprix",
      name: "batiprix",
    },
  ]);

  useEffect(() => {
    setItemsFirstCol([
      {
        initialValue: general.pageSelectionItems,
        isDisabled: userRole !== "superadmin",
        labelText: "Page de sélection des items",
        name: "pageSelectionItems",
      },
      {
        initialValue: general.pageSelectionItems && general.accordCadre,
        isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
        labelText: "Accord cadre",
        name: "accordCadre",
      },
      {
        initialValue: general.pageSelectionItems && general.maintenance,
        isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
        labelText: "Maintenance",
        name: "maintenance",
      },
      {
        initialValue: general.carrycotsMovement ? general.deplacements : false,
        isDisabled: userRole !== "superadmin" || !general.carrycotsMovement,
        labelText: "Déplacements",
        name: "deplacements",
      },
      {
        initialValue: general.makers,
        labelText: "Fabricants",
        name: "makers",
      },
      {
        initialValue: general.option,
        labelText: "Option",
        name: "option",
      },
    ]);

    setItemsSecondCol([
      {
        initialValue: general.carrycotsMovement,
        isDisabled: userRole !== "superadmin",
        labelText: "Page des déplacements & nacelles",
        name: "carrycotsMovement",
      },
      {
        initialValue: general.pageSelectionItems && general.offreLocative,
        isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
        labelText: "Offre locative",
        name: "offreLocative",
      },
      {
        initialValue: general.pageSelectionItems && general.regroupement,
        labelText: "Regroupement",
        isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
        name: "regroupement",
      },
      {
        initialValue:
          general.carrycotsMovement && general.profils
            ? general.carrycots
            : false,
        isDisabled: !general.carrycotsMovement || !general.profils,
        labelText: "Nacelles",
        name: "carrycots",
      },
      {
        initialValue: general.distributors,
        labelText: "Distributeurs",
        name: "distributors",
      },
    ]);

    setItemsThirdCol([
      {
        initialValue:
          !general.carrycotsMovement && !general.pageSelectionItems
            ? false
            : general.prixVente,
        isDisabled:
          userRole !== "superadmin" ||
          (!general.carrycotsMovement && !general.pageSelectionItems),
        labelText: "Page des prix de vente",
        name: "prixVente",
      },
      {
        initialValue: general.pageSelectionItems && general.ouvrage,
        value: general.ouvrage,
        isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
        labelText: "Ouvrage",
        name: "ouvrage",
      },
      {
        initialValue: general.pageSelectionItems && general.groupement,
        isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
        labelText: "Groupement",
        name: "groupement",
      },

      {
        initialValue: general.profils,
        labelText: "Profils",
        name: "profils",
      },
      {
        initialValue: general.pageSelectionItems && general.batiprix,
        isDisabled: userRole !== "superadmin" || !general.pageSelectionItems,
        labelText: "Batiprix",
        name: "batiprix",
      },
    ]);
  }, [general]);

  const handleSaveNewValue = (value, name) => {
    const condition =
      (name === "pageSelectionItems" &&
        !general.carrycotsMovement &&
        value === false) ||
      (name === "carrycotsMovement" &&
        value === false &&
        !general.pageSelectionItems);
    if (condition) {
      setProfils((prev) => ({
        ...prev,
        margeProfil: false,
      }));
    }
    if (name === "pageSelectionItems" && value === false) {
      setGeneral({
        ...general,
        [name]: value,
        accordCadre: false,
        maintenance: false,
        offreLocative: false,
        ouvrage: false,
        regroupement: false,
        batiprix: false,
        groupement: false,
      });
      setArrayBlock((prev) => ({
        ...prev,
        materialsWork: false,
        materialsZonesWork: false,
        fonctionnalitiesWork: false,
        fonctionnalitiesZonesWork: false,
        prestationsWork: false,
        prestationsZonesWork: false,
        prestationDescriptionWork: false,
        prestationPictureWork: false,
        ouvragesWork: false,
        ouvragesDetailsWork: false,
      }));
      setProducts((prev) => ({
        ...prev,
        marge: false,
        colonneMarge: false,
        dateValidite: false,
        associerProduits: false,
      }));
      setPrestations((prev) => ({
        ...prev,
        margePrestation: false,
        description: false,
        speed: false,
        majoration: false,
      }));
      setCategories((prev) => ({
        ...prev,
        margeCategorie: false,
      }));
      setTextBlock((prev) => ({
        ...prev,
        frameworkAgreement: false,
      }));
    } else if (name === "option" && value === false) {
      setGeneral({
        ...general,
        [name]: value,
      });
      setArrayBlock((prev) => ({
        ...prev,

        ouvragesOptionWork: false,
        ouvragesOptionDetailsWork: false,
      }));
    } else if (name === "carrycotsMovement" && value === false) {
      setGeneral({
        ...general,
        [name]: value,
        carrycots: false,
        deplacements: false,
      });
    } else if ((name === "prixVente" && value === false) || condition) {
      setGeneral({
        ...general,
        [name]: value,
      });
      setProducts((prev) => ({
        ...prev,
        marge: false,
        colonneMarge: false,
      }));

      setPrestations((prev) => ({
        ...prev,
        margePrestation: false,
      }));

      setProfils((prev) => ({
        ...prev,
        margeProfil: false,
      }));

      setCategories((prev) => ({
        ...prev,
        margeCategorie: false,
      }));
      setTextBlock((prev) => ({
        ...prev,
        sellingPrice: false,
        sellingPriceTTC: false,
      }));
    } else if (name === "accordCadre" && value === false) {
      setGeneral({
        ...general,
        [name]: value,
      });
      setPrestations((prev) => ({
        ...prev,
        majoration: false,
      }));
      setTextBlock((prev) => ({
        ...prev,
        frameworkAgreement: false,
      }));
    } else if (name === "profils" && value === false) {
      setGeneral({
        ...general,
        [name]: value,
      });
      setProfils((prev) => ({
        ...prev,
        margeProfil: false,
      }));
    } else {
      setGeneral({
        ...general,
        [name]: value,
        ...(!general.pageSelectionItems &&
          !general.carrycotsMovement && { prixVente: false }),
      });
    }
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1FR)",
        columnGap: "24px",
        gap: "24px",
        backgroundColor: "#F5F6F8",
        borderRadius: 10,
      }}
    >
      {/* FIRST COL */}
      <StyledDiv>
        {itemsFirstCol.map((item) => (
          <SwitchComponent
            key={item.name}
            initialValue={item.initialValue}
            labelText={item.labelText}
            handleChange={handleSaveNewValue}
            name={item.name}
            isDisabled={item?.isDisabled}
          />
        ))}
      </StyledDiv>
      {/* SECOND COL */}
      <StyledDiv>
        {itemsSecondCol.map((item) => (
          <SwitchComponent
            key={item.name}
            initialValue={item.initialValue}
            labelText={item.labelText}
            handleChange={handleSaveNewValue}
            name={item.name}
            isDisabled={item?.isDisabled}
          />
        ))}
      </StyledDiv>
      {/* THIRD COL */}
      <StyledDiv>
        {itemsThirdCol.map((item) => (
          <SwitchComponent
            key={item.name}
            initialValue={item.initialValue}
            labelText={item.labelText}
            handleChange={handleSaveNewValue}
            name={item.name}
            isDisabled={item?.isDisabled}
          />
        ))}
      </StyledDiv>
    </div>
  );
}
