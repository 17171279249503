$(document).ready(function() {

    const materialsCosting = document.getElementById("update_all_materials_btn")

    if (!materialsCosting) {
        return
    }


const updateAll = (event) => {
    document.querySelectorAll('.update_buttons').forEach((button) => {
        button.click();
    })
}
const allQuantityFields = document.querySelectorAll('.material_quantity > input');
const sumEquippedAgents = document.querySelector('.equipped_agents');

const updateEquipedAgents = () => {
    let sumQuantity = 0;
    allQuantityFields.forEach((quantity_input) => {
        parsedQuantity = (Number.parseInt(quantity_input.value) ||0)
        sumQuantity += parsedQuantity;
    })
    if (sumEquippedAgents) {
      sumEquippedAgents.value = sumQuantity;
    }
}


    if(document.getElementById('update_all_materials_btn')){
        document.getElementById('update_all_materials_btn').addEventListener('click', updateAll)
    }
    if(allQuantityFields.length !== 0) {
        allQuantityFields.forEach((quantity_input) => {
            quantity_input.addEventListener('change', updateEquipedAgents)
        })
        updateEquipedAgents();
    }
})

