import React from "react";
import moment from "moment/moment";
import styled from "styled-components";
import FormCol from "../../../Form/FormCol";

function OpportunityPeriodAlert({ determinePeriod, isGuarding, startDate }) {
  return (
    <FormCol>
      {isGuarding() && determinePeriod() >= 1 && (
        <Wrapper>
          <GuardingInfoDurationContainer>
            La période de chiffrage est d'une durée maximum de 12 mois
          </GuardingInfoDurationContainer>
          <GuardingInfoDurationContainer>
            du {moment(startDate).format("L")} au{" "}
            {moment(startDate).add(1, "years").subtract(1, "days").format("L")}
          </GuardingInfoDurationContainer>
        </Wrapper>
      )}
    </FormCol>
  );
}

const GuardingInfoDurationContainer = styled.div`
  margin-left: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const Wrapper = styled.div`
  width: 100%;
`;

export default OpportunityPeriodAlert;
