import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { Segmented, DatePicker, TreeSelect } from "antd";
import Search from "antd/lib/input/Search";
import moment from "moment/moment";
import i18next from "i18next";
import Label from "../Form/Label";
import ReactDatePicker from "../Form/ReactDatePicker";

function FilterHeader({
  params,
  setParams,
  dispatch,
  hidde_date_picker,
  works,
  selectedClientId,
}) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());

  const handleOnChangeStartDate = (value) => {
    const formattedDate = `${value.getFullYear()}-${
      value.getMonth() + 1
    }-${value.getDate()}`;
    dispatch({
      type: "start_date",
      payload: formattedDate,
    });
    setStartDate(value);
  };

  const handleOnChangeEndDate = (value) => {
    const formattedDate = `${value.getFullYear()}-${
      value.getMonth() + 1
    }-${value.getDate()}`;
    dispatch({
      type: "end_date",
      payload: formattedDate,
    });
    setEndDate(value);
  };

  const handleOnChangeWorks = (value) => {
    setParams({
      ...params,
      worksToFilter: value.map((work) => work.value),
    }); // ...params = on déstructure l'objet et on garde les infos contenues dedans
  };

  const defaultWorks = useMemo(() => works?.map((work) => work.id), [works]);
  const formatedWorks = useMemo(
    () =>
      works?.map((work) => ({
        id: work.id,
        pId: null,
        title: i18next.t(`work.${work.name}`),
        value: work.id,
      })),
    [works]
  );

  return (
    <Container $fromClient={selectedClientId}>
      <ContainerDate>
        <Wrapper className="header__filter-offers">
          <Label label="Filtrer par date de création :" />
          <Segmented
            onChange={(e) => {
              if (e === "1 an") {
                dispatch({
                  type: "start_date",
                  payload: moment().subtract(1, "year").format("YYYY-MM-DD"),
                });
                dispatch({ type: "hidde_date_picker", payload: true });
              } else if (e === "6 mois") {
                dispatch({
                  type: "start_date",
                  payload: moment().subtract(6, "months").format("YYYY-MM-DD"),
                });
                dispatch({ type: "hidde_date_picker", payload: true });
              } else if (e === "Toutes") {
                dispatch({
                  type: "start_date",
                  payload: moment().subtract(40, "years").format("YYYY-MM-DD"),
                });
                dispatch({ type: "hidde_date_picker", payload: true });
              } else if (e === "Personnalisé") {
                dispatch({ type: "hidde_date_picker", payload: false });
              }
            }}
            options={["6 mois", "1 an", "Toutes", "Personnalisé"]}
          />
        </Wrapper>
        {!hidde_date_picker && (
          <WrapperDate>
            <FilterDateContainer>
              <FilterDate>
                <Label label="Du " />
                <StyledDatePicker
                  selected={startDate}
                  onChange={handleOnChangeStartDate}
                />
              </FilterDate>
              <FilterDate>
                <Label label="au " />
                <StyledDatePicker
                  selected={endDate}
                  onChange={handleOnChangeEndDate}
                />
              </FilterDate>
            </FilterDateContainer>
          </WrapperDate>
        )}
      </ContainerDate>
      {works.length > 1 && (
        <WrapperWork>
          <WrapperLabel>
            <Label label="Activités : " />
          </WrapperLabel>
          <StyledTreeSelect
            allowClear
            treeData={formatedWorks}
            onChange={handleOnChangeWorks}
            treeDataSimpleMode
            treeDefaultExpandAll
            treeCheckable
            treeCheckStrictly
            defaultValue={defaultWorks}
            showCheckedStrategy={TreeSelect.SHOW_ALL}
            maxTagCount="responsive"
          />
        </WrapperWork>
      )}
    </Container>
  );
}

const ContainerDate = styled.div`
  justify-content: flex-start;
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 0.7rem;
  @media screen and (max-width: 1306px) {
    width: 310px;
    align-items: flex-end;
  }
`;

const Container = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
  position: sticky;
  top: 0;
  ${({ $fromClient }) =>
    $fromClient &&
    css`
      flex-direction: column;
    `}
  z-index: 10;
  background-color: #f5f6f8;
`;

const StyledDatePicker = styled(ReactDatePicker)`
  width: 120px;
`;

const FilterDateContainer = styled.div`
  justify-content: flex-start;
  display: flex;
  width: 100%;
`;

const FilterDate = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
`;

const Wrapper = styled.div`
  display: inline-block;
  height: 100%;
  @media screen and (max-width: 999px) {
    width: 100%;
  }
`;

const WrapperWork = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 500px;
  @media screen and (max-width: 1306px) {
    width: 400px;
  }
  @media screen and (max-width: 1124px) {
    width: 300px;
  }
  margin-bottom: 0.7rem;
`;

const WrapperLabel = styled.div`
  display: flex;
  height: 100%;
  width: 97px;
  @media screen and (max-width: 1124px) {
    width: 102px;
  }
`;

const WrapperDate = styled.div`
  display: flex;
  width: 300px;
  height: 100%;
`;

const StyledTreeSelect = styled(TreeSelect)`
  width: 100%;
`;
export default FilterHeader;
