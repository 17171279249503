import React from "react";
import styled from "styled-components";
import Select from "../../../react-ui/Select";
import FormCol from "../../../Form/FormRow";
import { opportunityField } from "../../../constant";
import CreateLink from "../../../react-ui/CreateLink";

function ProviderInPlace({ providers, setProviderModalType, isSimple }) {
  return (
    <FormCol
      visibility={multipleUserWorks()}
      height={multipleUserWorks() === "collapse" ? "0px" : "50px"}
      marginTop={multipleUserWorks() === "collapse" ? "0px" : "0px"}
      labelOnTop
      label={isSimple() ? "Concurent(s)" : "Prestataire(s) en place"}
      name={opportunityField.providedOpportunityCustomer}
      customContent={
        <StyledCreateLink
          label={isSimple() ? "Créer un concurent" : "Créer un prestataire"}
          onClick={() => setProviderModalType("provider")}
        />
      }
    >
      <StyledSelect
        options={providers}
        placeholder={`Sélectionnez un ou plusieurs ${
          isSimple() ? "concurents" : "prestataires"
        }`}
        mode="multiple"
      />
    </FormCol>
  );
}

const StyledCreateLink = styled(CreateLink)`
  margin-top: 10px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
export default ProviderInPlace;
