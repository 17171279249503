export const en = {
  en: {
    translation: {
      work: {
        guarding: "Sécurité humaine",
        "electronic security": "Sécurité électronique",
        "fire safety": "Systèmes",
        simple: "Simple",
        extinguisher: "Matériel incendie",
        training: "Services",
        cleaning: "Nettoyage",
        maintenance: "Maintenance",
        multitechnic: "Multi-technique",
        concierge: "Conciergerie",
        legal: "Legal",
        software: "Logiciel",
        btp: "BTP",
        energy: "Energie",
        "energy enc": "Energie ENC",
      },
      work_fr_to_en: {
        "Sécurité humaine": "guarding",
        "Sécurité électronique": "electronic security",
        Systèmes: "fire safety",
        Simple: "simple",
        "Matériel incendie": "extinguisher",
        Services: "training",
        Logiciel: "software",
        BTP: "btp",
        Energie: "energy",
        "Energie ENC": "energy enc",
        Maintenance: "maintenance",
        Nettoyage: "cleaning",
        Legal: "legal",
      },
      opportunity_type: {
        new_work: "Travaux neufs",
        maintenance: "Maintenance",
      },
      status_offer: {
        pending: "En cours",
        completed: "Terminée",
        prospect: "Prospect",
        given: "Remise",
        won: "Gagnée",
        lost: "Perdue",
        abandoned: "Abandonnée",
      },
      roles: {
        admin: "Administrateur",
        manager: "Manager",
        supervisor: "Superviseur",
        setup: "Paramétreur",
        salesman: "Utilisateur",
        superadmin: "Administrateur SmartProposal",
      },
      response_type: {
        consultation: "Consultation",
        referencing: "Référencement accord-cadre",
        quotation: "Devis accord-cadre",
        renting: "Offre locative",
      },
      tva: {
        special: "Spéciale",
        general: "Normale",
      },
    },
  },
};
