import React from "react";
import { Select as AntdSelect, Tooltip } from "antd";
import styled, { css } from "styled-components";
import { array, bool, node, oneOfType, string } from "prop-types";
import { searchCaseDiatricsInsensitive } from "../../utils/search";

const filterOption = (search, option, keysToSearch) =>
  keysToSearch.some((keyName) =>
    searchCaseDiatricsInsensitive(search, option[keyName])
  );
function Select({
  options,
  keyName,
  className,
  fontSize,
  mode,
  keysToSearch,
  customTooltip,
  disabled,
  customContent = null,
  ...selectProps
}) {
  const element = (value) => (
    <StyledSpan fontSize={fontSize}>{value[keyName]}</StyledSpan>
  );

  const constructOption = (value) => (
    <AntdSelect.Option {...value} key={value.id} value={value.id}>
      {customTooltip ? (
        <Tooltip title={value[keyName]} mouseLeaveDelay={0}>
          {element(value)}
        </Tooltip>
      ) : (
        element(value)
      )}
    </AntdSelect.Option>
  );

  return (
    <>
      <StyledSelect
        fontSize={fontSize}
        className={className}
        showSearch
        disabled={disabled}
        defaultActiveFirstOption={false}
        filterOption={(search, option) =>
          filterOption(
            search,
            option,
            keysToSearch.length > 0 ? keysToSearch : [keyName]
          )
        }
        mode={mode}
        dropdownStyle={{
          fontSize,
        }}
        {...selectProps}
      >
        {options?.map((value) =>
          value.options ? (
            <AntdSelect.OptGroup key={value[keyName]}>
              {value.options?.map((el) => constructOption(el))}
            </AntdSelect.OptGroup>
          ) : (
            constructOption(value)
          )
        )}
      </StyledSelect>
      {customContent && <StyledText>{customContent}</StyledText>}
    </>
  );
}

const StyledSelect = styled(AntdSelect)`
  width: 100%;
  .ant-select-arrow-loading {
    color: #5499de !important;
    font-size: 18px;
  }
  .ant-select-selection-item,
  .ant-select-selection-placeholder,
  .ant-select-item-option-content {
    font-size: ${({ fontSize }) => `${fontSize} !important`};
    color: rgba(0, 0, 0, 0.88) !important;
  }
  ${({ allowClear }) =>
    allowClear &&
    css`
      &:hover {
        .ant-select-arrow {
          opacity: 0;
        }
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      .ant-select-selection-item {
        color: rgba(0, 0, 0, 0.88) !important;
      }
    `}

  .ant-select-clear {
    background-color: inherit;
    color: #f1556c;
  }
  .ant-select-dropdown .ant-select-item {
    font-size: ${({ fontSize }) => `${fontSize} !important`};
  }
  input {
    font-size: ${({ fontSize }) => `${fontSize} !important`};
  }
`;

const StyledSpan = styled.span`
  font-size: ${({ fontSize }) => `${fontSize} !important`};
  width: 100%;
`;

const StyledText = styled.p`
  margin: 0;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #5499de;
`;
Select.propTypes = {
  options: array,
  keyName: string,
  className: string,
  fontSize: string,
  mode: string,
  keysToSearch: array,
  disabled: bool,
  customTooltip: bool,
  customContent: oneOfType([string, node]),
};

Select.defaultProps = {
  options: [],
  keyName: "name",
  className: "",
  fontSize: "14px",
  mode: "",
  keysToSearch: [],
  disabled: false,
  customTooltip: false,
  customContent: null,
};

export default Select;
