import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import Label from "../../../../Form/Label";
import Select from "../../../../react-ui/Select";
import { postData } from "../../../../request/instance";
import { useStore } from "../../../../store";

const selector = (state) => ({
  formToken: state.formToken,
});

function PeriodLeaser({ period, rentingOppId, periods }) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const [value, setValue] = useState(period);

  const { mutate: updatePeriod } = useMutation(
    (todo) => postData(formToken, "/renting_opportunity/update", todo),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Services");
        queryClient.invalidateQueries("RentingOpportunity");
        queryClient.invalidateQueries("OpportunityCotationLc");
      },
      onError: (err) => setValue(err.response?.data?.period || period),
    }
  );

  const handlePeriodChange = (e) => {
    if (e) {
      setValue(e);
      updatePeriod({
        id: rentingOppId,
        renting_opportunity: {
          period: e,
        },
      });
    }
  };

  useEffect(() => {
    setValue(period);
  }, [period]);

  return (
    <>
      <Label label="Nombre de mois" />
      <StyledSelect
        value={value}
        options={periods}
        onChange={handlePeriodChange}
      />
    </>
  );
}

const StyledSelect = styled(Select)`
  width: 50%;
`;

export default PeriodLeaser;
