export const formatRoundNumber = ({ number, round_type, round_number }) => {
  switch (round_type) {
    case "decimal":
      var roundNumber = round_number || 0;
      return number.toFixed(roundNumber);
    case "superior":
      return Math.ceil(number);
    case "lower":
      return Math.floor(number);
  }
};
