import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Checkbox, Form, Modal, TreeSelect } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";
import { filterInTree } from "../../utils/search";
import { offerReviewFields } from "../constant";
import FormCol from "../Form/FormCol";
import FormRow from "../Form/FormRow";
import Label from "../Form/Label";
import Input from "../react-ui/Input";
import Select from "../react-ui/Select";
import PartnerArray from "./PartnerArray";

const { confirm } = Modal;

function InternalInformations({
  userName,
  collaboratorsList,
  entities,
  technicalsConformList,
  contractsConformList,
  groupTypesList,
  mandataryTypesList,
  offerReviewId,
  setIsOpenFrom,
  updateOnChange,
  form,
}) {
  const groupResponse = Form.useWatch(offerReviewFields.groupResponse, form);
  const subcontracting = Form.useWatch(offerReviewFields.subcontracting, form);
  const groupType = Form.useWatch(offerReviewFields.groupType, form);

  const formattedEntities = useMemo(
    () =>
      entities.map((el) => ({
        id: el.id,
        pId: el.parent_id,
        title: el.name,
        value: el.id,
      })),
    [entities]
  );

  return (
    <>
      <FormRow marginTop="0px" justify="space-between">
        <Row>
          <StyledLabel label="Créateur de l'offre : " />
          <Text>{userName}</Text>
        </Row>
        <Row>
          <StyledLabel label="Collaborateurs de l'offre : " />
          <Text>
            {collaboratorsList || "Aucun collaborateur sur cette offre"}
          </Text>
        </Row>
      </FormRow>

      <FormRow>
        <FormCol
          label="Entité responsable de l'offre"
          name={offerReviewFields.entityInChargeId}
        >
          <TreeSelect
            treeDefaultExpandAll
            treeData={formattedEntities}
            treeDataSimpleMode
            placeholder="Sélectionnez une entité"
            filterTreeNode={filterInTree}
            showSearch
            allowClear
            onChange={updateOnChange}
          />
        </FormCol>
        <FormCol
          label="Réponse en groupement"
          name={offerReviewFields.groupResponse}
          valuePropName="checked"
        >
          <Checkbox
            onChange={(e) => {
              if (!e.target.checked) {
                form.setFieldsValue({
                  [offerReviewFields.groupResponse]: true,
                });
                confirm({
                  title: `Le tableau des cotraitants sera vidé.`,
                  icon: <ExclamationCircleOutlined />,
                  okText: "Continuer",
                  okType: "danger",
                  cancelText: "Annuler",
                  maskClosable: false,
                  onOk() {
                    form.setFieldsValue({
                      [offerReviewFields.groupResponse]: false,
                    });
                    updateOnChange();
                    return false;
                  },
                  onCancel() {
                    return false;
                  },
                });
              } else updateOnChange();
            }}
          />
        </FormCol>
      </FormRow>

      {groupResponse && (
        <FormRow>
          <FormCol
            label="Type de groupement"
            name={offerReviewFields.groupType}
            width="50%"
          >
            <StyledSelect
              options={groupTypesList}
              onChange={updateOnChange}
              allowClear
            />
          </FormCol>
          {groupType === "joint" && (
            <FormCol
              label="Type de mandataire"
              name={offerReviewFields.mandataryType}
              width="50%"
            >
              <StyledSelect
                options={mandataryTypesList}
                onChange={updateOnChange}
                allowClear
              />
            </FormCol>
          )}
        </FormRow>
      )}

      {groupResponse && (
        <PartnerArray
          subcontracting={false}
          offerReviewId={offerReviewId}
          setIsOpenFrom={setIsOpenFrom}
        />
      )}

      {subcontracting === "authorized_subcon" && (
        <PartnerArray
          subcontracting
          offerReviewId={offerReviewId}
          setIsOpenFrom={setIsOpenFrom}
        />
      )}

      <FormRow>
        <FormCol
          labelOnTop
          label="Remise de fin d'année"
          name={offerReviewFields.endYearDiscount}
        >
          <StyledTextArea onBlur={updateOnChange} textArea />
        </FormCol>
        <FormCol
          labelOnTop
          label="Stratégie en termes de reprise de personnel"
          name={offerReviewFields.reprisePersonnelStrategy}
        >
          <StyledTextArea onBlur={updateOnChange} textArea />
        </FormCol>
      </FormRow>

      <StyledLabelQuestion label="L'offre est-elle conforme aux exigences du donneur d'ordres ?" />
      <FormRow marginTop="8px">
        <FormCol
          label="Exigences techniques"
          name={offerReviewFields.technicalsConform}
        >
          <StyledSelect
            options={technicalsConformList}
            onChange={updateOnChange}
            allowClear
          />
        </FormCol>
        <FormCol
          label="Exigences contractuelles"
          name={offerReviewFields.contractsConform}
        >
          <StyledSelect
            options={contractsConformList}
            onChange={updateOnChange}
            allowClear
          />
        </FormCol>
      </FormRow>

      <FormRow>
        <FormCol
          labelOnTop
          label="L'offre est-elle optimisée en fonction des critères de jugement des offres du client?"
          name={offerReviewFields.optimizedOffer}
        >
          <StyledTextArea onBlur={updateOnChange} textArea />
        </FormCol>
      </FormRow>

      <FormRow>
        <FormCol label="Commentaires" name={offerReviewFields.commentsInfo}>
          <StyledTextArea onBlur={updateOnChange} textArea />
        </FormCol>
      </FormRow>

      <FormRow>
        <FormCol label="Raisons succés/échec" name={offerReviewFields.resultAo}>
          <StyledTextArea onBlur={updateOnChange} textArea />
        </FormCol>
      </FormRow>
    </>
  );
}

const Row = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
`;

const Text = styled.span``;

const StyledLabel = styled(Label)`
  white-space: nowrap;
`;

const StyledLabelQuestion = styled(Label)`
  padding-top: 16px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledTextArea = styled(Input)`
  resize: none;
  height: 105px !important;
`;

export default InternalInformations;
