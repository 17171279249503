import {
    Calendar
  } from '@fullcalendar/core';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import interactionPlugin from '@fullcalendar/interaction';
  import timeGridPlugin from '@fullcalendar/timegrid';
  import { fetchPartial} from '../../lib/fetch_partial';
  import {
    Controller
  } from "stimulus"
  
  export default class extends Controller {
    static targets = ["position_weekly_calendar", "opportunityid", "workpositionid", "accordion", "planning_work_periods", "planningid"]
    columnHeaderCustom = (date, is_first_monday) => {
          const days = ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'];
          const nextMonday =  new Date(2020,5,8);
        if(date.getDate() === nextMonday.getDate()) {
            return('Jour férié');
          } 
        return(days[date.getDay()]);
      }

    word_periods_array() {
        let planning_work_periods_json = JSON.parse(this.planning_work_periodsTarget.value);
        const daytype_to_date = {
          "monday":'1',
          "tuesday":'2',
          "wednesday":'3',
          "thursday":'4',
          "friday":'5',
          "saturday":'6',
          "sunday":'7',
          "work_free_day": '8'
        }
        return planning_work_periods_json.map((work_period) => {
        let start_date = new Date(work_period.start_datetime)
        let start_hour = (start_date.getUTCHours()<10?'0':'') + start_date.getUTCHours()
        let start_minuts = (start_date.getMinutes()<10?'0':'') + start_date.getMinutes()
        let end_date = new Date(work_period.end_datetime)
        let end_hour = (end_date.getUTCHours()<10?'0':'') + end_date.getUTCHours()
        let end_minuts = (end_date.getMinutes()<10?'0':'') + end_date.getMinutes()
        let endingTime =  `2020-06-0${daytype_to_date[work_period.day_type]}T${end_hour}:${end_minuts}:00`
        if(work_period.end_at_midnight === true) {
          endingTime = `2020-06-0${Number.parseInt(daytype_to_date[work_period.day_type]) + 1 }T00:00:00`
        }
        return {
          'id': `${work_period.id}`,
          'start': `2020-06-0${daytype_to_date[work_period.day_type]}T${start_hour}:${start_minuts}:00`,
          'end': endingTime,
          backgroundColor: "red"
        }
      })
    }
    initialize() {
      var calendar = new Calendar(this.position_weekly_calendarTarget, {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
        timeZone: 'UTC',
        defaultView: 'timeGridEightDay',
        dateAlignment: "week",
        contentHeight: 590,
        views: {
          timeGridEightDay: {
            type: 'timeGrid',
                        visibleRange: {
                          start: '2020-06-01',
                          end: '2020-06-09'
                        },
              
            }
          },
          columnHeaderText: (date) => {
            let is_first_monday = true
            return this.columnHeaderCustom(date)
          },
        firstDay: 1,
        slotDuration: '01:00:00',
        slotLabelInterval: {hours: 1},
        columnHeaderFormat: { weekday: 'long' },
        slotEventOverlap: false,
        header: {
            left   : '',
            center : '',
            right  : '',
           },
        allDaySlot: false,
        eventClick: (click) => {
          // $(`.edition${this.planningidTarget.value}`).modal('show');
          // fetchPartial({
          // url: `/plannings/${this.planningidTarget.value}/work_periods/${click.event.id}/edit`, 
          // callback: $('.update_work_period_form').on('submit', (event) => $('.planning-edition').modal('hide'))
          // });
          $(`.edition${this.planningidTarget.value}`).modal('show');
          fetch(`/plannings/${this.planningidTarget.value}/work_periods/${click.event.id}/edit`)
          .then(function(response) { return response.text() }).then(function(body) {
            eval(body)
            $('.update_work_period_form').on('submit', (event) => $('.planning-edition').modal('hide'));
          }.bind(this));
        },
        eventSources: [
       
          {

            events: this.word_periods_array()
          }
        ],
        eventOverlap: function isOverlapping(event){
          // "calendar" on line below should ref the element on which fc has been called 
          var array = calendar.fullCalendar('clientEvents');
          for(i in array){
              if (event.end >= array[i].start && event.start <= array[i].end){
                 return true;
              }
          }
          return false;
      }
          });
      calendar.setOption('locale', 'fr');
      calendar.render();
    }
  }
