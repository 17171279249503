const entityIsInWork = ({ entity, workId, workIds }) => {
  if (!entity) return false;
  if (workIds?.length > 0)
    return entity.works.some((el) => workIds.includes(el.id));
  return entity.works.some((el) => el.id === workId);
};

export function getEntitiesByWork({
  entities,
  parentEntity,
  workId,
  workIds = [],
}) {
  let workEntities = [];
  const childEntities = entities?.filter(
    (entity) => entity.parent_id === parentEntity?.id
  );
  if (childEntities.length === 0)
    return entityIsInWork({ entity: parentEntity, workId, workIds })
      ? [parentEntity]
      : [];
  childEntities?.forEach((childEntity) => {
    const childWorkEntities = getEntitiesByWork({
      entities,
      parentEntity: childEntity,
      workId,
      workIds,
    });
    if (childWorkEntities.length > 0) {
      workEntities = workEntities.concat(childWorkEntities);
    }
  });
  if (
    entityIsInWork({ entity: parentEntity, workId, workIds }) ||
    workEntities.length > 0
  )
    workEntities.push(parentEntity);
  return workEntities;
}
