/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState, useReducer, useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import { Modal } from "antd";
import moment from "moment/moment";
import { important } from "polished";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import i18next from "i18next";
import styled from "styled-components";
import TableContainer from "../Chiffrage/CostingMovementsCarrycots/TableContainer";
import FilterHeader from "./FilterHeader";
import { getData, postData } from "../request/instance";
import { formatNumberString } from "../../utils/formatNumberString";
import { currency_cents_to_euros } from "../utils";
import { numberSorter } from "../../utils/numberSorter";
import { stringSorter } from "../../utils/stringSorter";
import { dateSorter } from "../../utils/dateSorter";
import { style_tour, locale_tour } from "../tour/tour_style";
import { content_offers_tour } from "../tour/offers_index_tour";
import TitleContainer from "../react-ui/TitleContainer";
import Warning from "../react-ui/Icons/Warning";

const logicReducer = (state, action) => {
  switch (action.type) {
    case "start_date": {
      return {
        ...state,
        start_date: action.payload,
      };
    }
    case "end_date": {
      return {
        ...state,
        end_date: action.payload,
      };
    }
    case "hidde_date_picker": {
      return {
        ...state,
        hidde_date_picker: action.payload,
      };
    }

    default:
      return state;
  }
};

const initialState = () => {
  return {
    end_date: moment().add(1, "days").format("YYYY-MM-DD"),
    start_date: moment().subtract(6, "months").format("YYYY-MM-DD"),
    hidde_date_picker: true,
  };
};

function Offers({
  token,
  role_user,
  configuration,
  vocabularyApplication,
  configApplication,
  works,
  selectedClientId,
}) {
  const [state, dispatch] = useReducer(logicReducer, initialState());
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const { start_date, end_date, hidde_date_picker } = state;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hiddeOffer, setHideOffer] = useState(null);
  const [hiddeOfferValidation, setHiddeOfferValidation] = useState(false);
  const [params, setParams] = useState({
    worksToFilter: [works.map((work) => work.id)],
  });
  // const { refetch: refetchTour } = useQuery(
  //   "Tour",
  //   () => getData(token, `/verify_tour?tour_name="offers_tour"`),
  //   {
  //     onSuccess: (res) => {
  //       if (res.success === true) {
  //         setRun(true);
  //       } else {
  //         setRun(false);
  //       }
  //     },
  //     refetchOnWindowFocus: false, // Disable refetch on window focus to avoid unwanted refetches
  //     refetchInterval: false, // Disable refetch interval to avoid unwanted refetches
  //     refetchIntervalInBackground: false, // Disable refetch interval in background to avoid unwanted refetches
  //     retry: false, // Disable retry on error to avoid unwanted refetches
  //   }
  // );

  // useEffect(() => {
  //   refetchTour();
  // }, [refetchTour]);

  const [steps, setSteps] = useState(content_offers_tour);
  const [run, setRun] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const {
    data: offers,
    refetch: refetchOffers,
    isLoading: offersLoading,
  } = useQuery(
    "Offers",
    () =>
      getData(
        token,
        `/offers_filter?start_date=${start_date}&end_date=${end_date}&work_ids=${
          params.worksToFilter
        }${selectedClientId ? `&client_id=${selectedClientId}` : ""}`
      ),
    {
      onSuccess: (res) => {
        getData(token, `/verify_tour?tour_name="offers_index_tour"`).then(
          (r) => {
            if (r.success === true) {
              setRun(true);
            } else {
              setRun(false);
            }
          }
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: offer_hidde } = useMutation(
    "OfferHidde",
    (data) => postData(token, `/offers/${data.id}/hidde`),
    {
      onSuccess: () => {
        refetchOffers();
        setHiddeOfferValidation(false);
      },
    }
  );

  const { mutate: update_offer_owner } = useMutation("Offers", (data) =>
    postData(token, `offers/update_offer_owner`, {
      offer: {
        id: 2,
        new_owner_id: data.id,
      },
    })
  );

  useEffect(() => {
    refetchOffers();
  }, [
    refetchOffers,
    end_date,
    start_date,
    offer_hidde,
    params,
    selectedClientId,
  ]);

  useEffect(() => {
    offer_hidde({ id: hiddeOffer?.key });
  }, [hiddeOfferValidation]);

  const workIcon = (workName) => {
    const cn =
      workName === "guarding"
        ? "fa-duotone fa-user-shield"
        : configuration.find((config) => config.work === workName)
            ?.configuration.general.work_icon;
    return <i className={`${cn} mr-1`} title={i18next.t(`work.${workName}`)} />;
  };

  const workIcons = (workNames) => {
    return <>{workNames.map((wn) => workIcon(wn))}</>;
  };

  const columns = [
    {
      title: vocabularyApplication.offers.table.title,
      dataIndex: "title",
      key: "title",
      fixed: "left",
      render: (title, record) => {
        return record.offer_id ? (
          <StyledTitleCell>
            <div style={{ width: "21px", textAlign: "left" }}>
              {workIcon(record.work_name)}
            </div>
            {title}
          </StyledTitleCell>
        ) : (
          <StyledTitleCell>
            <div style={{ width: "41px", textAlign: "left" }}>
              {workIcons(record.work_names)}
            </div>
            {title}
          </StyledTitleCell>
        );
      },
    },
    {
      title: vocabularyApplication.offers.table.chrono,
      dataIndex: "chrono",
      key: "chrono",
      width: "135px",
    },
    {
      title: vocabularyApplication.offers.table.status,
      dataIndex: "status",
      key: "status",
      className: "table__header-status",
      width: "90px",
      filters: offers?.status_value?.map((v) => ({
        text: i18next.t(`status_offer.${v}`),
        value: v,
      })),
      onFilter: (value, record) =>
        i18next.t(`status_offer.${offers?.status_value[record.status]}`) ===
        i18next.t(`status_offer.${value}`),
      sorter: (a, b) => stringSorter(a.status, b.status),
      render: (status, record) =>
        record.offer_id
          ? `${
              vocabularyApplication.offer[
                `status_${offers?.status_opportunity[status]}`
              ]
            }`
          : `${i18next.t(`status_offer.${offers?.status_value[status]}`)}`,
    },
    {
      title: vocabularyApplication.offers.table.clients,
      dataIndex: "clients",
      key: "clients",
      width: "140px",
      className: "table__header-client",
      filters: offers?.clients?.map((v) => ({ text: v, value: v })),
      onFilter: (value, record) =>
        record?.clients?.split(",").some((x) => x.trim() === value),
      sorter: (a, b) => stringSorter(a.clients, b.clients),
      filterSearch: true,
    },
    {
      title: vocabularyApplication.offers.table.limit_date,
      dataIndex: "delivery_date",
      key: "delivery_date",
      className: "table__header-delivery-date",
      width: "110px",
      sorter: (a, b) => dateSorter(a.delivery_date, b.delivery_date),
      render: (delivery_date) =>
        `${moment(delivery_date).format("DD/MM/YYYY")}`,
    },
    {
      title: vocabularyApplication.offers.table.turnover,
      key: "turnover",
      dataIndex: "turnover",
      width: "110px",
      className: "table__header-turnover",
      align: "right",
      sorter: (a, b) => numberSorter(a.turnover, b.turnover),
      render: (turnover) =>
        `${formatNumberString({
          str: currency_cents_to_euros(turnover),
          nbDecimal: 2,
          space: true,
        })} €`,
    },
    {
      title: vocabularyApplication.offers.table.entities,
      key: "agencies",
      dataIndex: "agencies",
      width: "140px",
      fixed: selectedClientId ? "right" : undefined,
      className: "table__header-agencies",
      filters: offers?.agencies?.map((v) => ({ text: v, value: v })),
      onFilter: (value, record) => record.agencies?.indexOf(value) === 0,
      sorter: (a, b) => stringSorter(a.agencies, b.agencies),
      filterSearch: true,
    },
    {
      title: vocabularyApplication.offers.table.owner,
      dataIndex: "owners",
      key: "owners",
      width: "140px",
      fixed: "right",
      className: "table__header-owner",
      filters: offers?.users?.map((v) => ({ text: v, value: v })),
      onFilter: (value, record) => record.owners.indexOf(value) === 0,
      sorter: (a, b) => stringSorter(a.owners, b.owners),
      filterSearch: true,
      hidden:
        selectedClientId ||
        role_user[0] === "salesman" ||
        !configApplication.common.offers.show_owner,
    },
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      title: () => <WarningIcon />,
      dataIndex: "has_warning",
      key: "has_warning",
      fixed: "right",
      render: (action, record) => record.has_warning && <WarningIcon />,
      width: "40px",
      sorter: (a, b) => numberSorter(a.has_warning, b.has_warning),
      hidden: selectedClientId || !configApplication.common.offers.show_rules,
    },
    {
      title: "",
      dataIndex: "action",
      width: "30px",
      key: "action",
      fixed: "right",
      hidden: selectedClientId,
      render: (action, record) =>
        !record.opportunity_id && (
          <div style={{ zIndex: 100, width: "100%", textAlign: "center" }}>
            {record.hidde_offer &&
            !configApplication.common.offers.disable_hidde_button ? (
              <div
                className="table__hide-offer"
                onClick={(event) => {
                  event.preventDefault();
                  showModal();
                  setHideOffer(record);
                }}
              >
                <i
                  style={{ cursor: "pointer" }}
                  className="fa-duotone fa-eye-slash fa-primary"
                  title="Masquer l'offre"
                />
              </div>
            ) : (
              <i
                className="fa-duotone fa-eye-slash fa-duotone-grey"
                title="Masquer l'offre - non disponible"
              />
            )}
          </div>
        ),
    },
  ].filter((v) => !v.hidden);

  const getRowClassName = useCallback((rec) => {
    let className = "";
    if (rec.has_warning) className += "warning-expand-icon";
    if (rec.has_user_right !== false) {
      className += " cursor-pointer";
    } else className += " cursor-not-allowed";
    return className;
  }, []);

  return (
    <div>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        showSkipButton
        disableScrolling
        steps={steps}
        locale={locale_tour}
        styles={style_tour}
        disableOverlayClose
      />
      <FilterHeader
        params={params}
        setParams={setParams}
        dispatch={dispatch}
        hidde_date_picker={hidde_date_picker}
        works={works}
        selectedClientId={selectedClientId}
      />
      <TableContainer
        className="table-tree table__offers"
        columns={columns}
        data={offers?.offers}
        rowClassName={getRowClassName}
        expandable={{
          expandedRowKeys,
          onExpandedRowsChange: (e) => setExpandedRowKeys(e),
          expandedRowClassName: getRowClassName,
        }}
        onRowAction
        loading={offersLoading}
        coloredRowOfferPage
        paddingBottomScrollY={15}
        scrollY
        scroll={{
          x: selectedClientId ? 920 : 1300,
        }}
        fontSize={12}
      />
      <Modal
        open={isModalOpen}
        maskClosable={false}
        onOk={() => {
          handleOk();
          setHiddeOfferValidation(true);
        }}
        onCancel={handleCancel}
      >
        <StyledTitleContainer label="Masquer une offre" />
        <p>
          Êtes vous sûr de vouloir masquer l'offre {hiddeOffer?.title} et toutes
          les opportunités associées?
        </p>
        <p>
          Seul votre administrateur sera en mesure de les rendre visible de
          nouveau!
        </p>
        {offers?.crm && (
          <p>
            !!!!! IMPORTANT !!!!!
            <br /> Les données des opportunités ne seront plus prises en compte
            dans les indicateurs du dashboard
          </p>
        )}
      </Modal>
    </div>
  );
}

const StyledTitleCell = styled.div`
  display: flex;
`;

const StyledTitleContainer = styled(TitleContainer)`
  margin: 0;
`;

const WarningIcon = styled(Warning)`
  display: flex;
  justify-content: center;
`;

export default Offers;
