import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Form } from "antd";
import FormCol from "../../../Form/FormCol";
import Select from "../../../react-ui/Select";
import WorkIcon from "../../../react-ui/WorkIcon";

function WorksSelect({
  isShowing,
  isUpdating,
  works,
  form,
  clients,
  selectedClientId,
  fromModal,
  selectedClientPosition,
  selectedParentId,
}) {
  const parent = Form.useWatch("parent");
  const parentId = useMemo(() => {
    if (selectedParentId) return selectedParentId;
    return parent?.value;
  }, [parent, selectedParentId]);

  const position = useMemo(() => {
    if (
      selectedClientPosition !== undefined &&
      typeof selectedClientPosition === "number"
    )
      return selectedClientPosition;
    const findPosition = (client, oldPosition = 1) => {
      if (!client.parent_id) return oldPosition;
      const newPosition = oldPosition + 1;
      return findPosition(
        clients.find((c) => c.id === client.parent_id),
        newPosition
      );
    };
    return parentId ? findPosition(clients.find((c) => c.id === parentId)) : 0;
  }, [clients, parentId, selectedClientPosition]);

  const isWorkDisabled = useCallback(
    (work) => {
      if (
        position === 2 ||
        clients.find(
          (client) =>
            selectedClientId &&
            client.id === selectedClientId &&
            client.work_ids.includes(work.id)
        )
      ) {
        return false;
      }
      const remainingLicenses =
        work.site_licences -
        clients.filter(
          (client) => client.position === 1 && client.work_ids.includes(work.id)
        ).length;
      return remainingLicenses <= 0;
    },
    [clients, selectedClientId, position]
  );

  const worksFromSite = useMemo(() => {
    const parentClient = clients.find((c) => c.id === parentId);
    return works
      .filter((work) => {
        if (position === 1) return true;
        if (position === 2) return parentClient.work_ids.includes(work.id);
        return false;
      })
      .map((work) => {
        const disabled = isWorkDisabled(work);
        return {
          ...work,
          name: (
            <MaxLicenses key={work.id}>
              <div>
                <WorkIcon
                  workName={work.name !== work.fullname ? work.name : work.type}
                />{" "}
                {work.fullname}
              </div>
              {disabled && <div>(Nombre de licenses max. atteintes)</div>}
            </MaxLicenses>
          ),
          disabled,
        };
      });
  }, [clients, isWorkDisabled, parentId, position, works]);

  useEffect(() => {
    if (position > 0 && isUpdating) {
      form.setFieldValue(
        "work_ids",
        form
          .getFieldValue("work_ids")
          ?.filter((workId) =>
            worksFromSite.some((w) => w.id === workId && !w.disabled)
          ) || []
      );
    }
  }, [worksFromSite, isUpdating, form, position]);

  if (position <= 0 || !parentId) return null;

  return (
    <FormCol
      label="Activité(s)"
      labelOnTop
      name="work_ids"
      width={fromModal ? "50%" : "70%"}
    >
      <Select
        options={worksFromSite}
        disabled={isShowing}
        showSearch={false}
        mode="multiple"
      />
    </FormCol>
  );
}

const MaxLicenses = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default WorksSelect;
