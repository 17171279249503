import React, { useEffect, useState } from "react";
import ReactDatePicker from "../../Form/ReactDatePicker";

function EntryDate({ value, keyName, updateItem, itemId, onChange, disabled }) {
  const [dateValue, setDateValue] = useState(value);

  useEffect(() => {
    setDateValue(value);
  }, [value]);

  const handleOnChange = (e) => {
    const date = e;
    if (date) {
      date.setHours((-1 * date.getTimezoneOffset()) / 60);
    }
    if (onChange) onChange(date);
    setDateValue(date);
    if (updateItem)
      updateItem({
        id: itemId,
        [keyName]: date,
      });
  };

  return (
    <ReactDatePicker
      value={dateValue}
      onChange={handleOnChange}
      fontSize="10px"
      disabled={disabled}
    />
  );
}

export default EntryDate;
