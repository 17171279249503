import React, { useMemo } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { Importer, ImporterField } from "react-csv-importer";

import { frFR } from "../../../../constants/ImporterLocale";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import { getUrlParams } from "../../../utils";
import Input from "../../../react-ui/Input";

const selector = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
});

function MaterialsImporter({
  openImporter,
  toDeleteValue,
  setToDeleteValue,
  toKeepValue,
  setToKeepValue,
  showConfig,
  setShowConfig,
  configuration,
  vocabulary,
}) {
  const queryClient = useQueryClient();

  const { formToken, isUsingMargin } = useStore(selector);
  const entity_work_id = useMemo(() => {
    return getUrlParams();
  }, []);
  const { mutateAsync: createMaterialsByCsv } = useMutation((todo) =>
    postData(formToken, "/material_profession/create_by_csv", todo)
  );

  const { mutateAsync: updateMaterialByCsv } = useMutation((todo) =>
    postData(formToken, "/material_profession/update_by_csv", todo)
  );

  const hideConfig = () => {
    setShowConfig(false);
  };

  const handleShowConfig = () => {
    if (openImporter !== "update") return;

    const els = document.getElementsByClassName(
      "CSVImporter_ColumnDragTargetArea__box"
    );
    // If to delete column is filled, show config
    if (
      els.length >= 13 &&
      els[12].getElementsByClassName(
        "CSVImporter_ColumnDragTargetArea__boxValueAction"
      ).length > 0
    ) {
      setShowConfig(true);
    } else {
      hideConfig();
    }
  };

  return (
    <MaterialsImporterContainer onMouseMove={handleShowConfig}>
      <Importer
        assumeNoHeaders={false}
        restartable
        onStart={hideConfig}
        onComplete={() => {
          queryClient.resetQueries({
            queryKey: "Materials",
            refetchPage: (page, index) => index === 0,
          });
        }}
        processChunk={async (rows) => {
          if (openImporter === "create") {
            await createMaterialsByCsv({
              material_profession: rows,
              entity_work_id,
            });
          } else {
            await updateMaterialByCsv({
              material_profession: rows,
              entity_work_id,
              to_delete_value: toDeleteValue,
            });
          }
        }}
        locale={frFR}
      >
        <ImporterField name="reference_code" label="Code article" />
        <ImporterField
          name="name"
          label="Intitulé"
          optional={openImporter === "update"}
        />
        <ImporterField
          name="cost_price_cents"
          label="Prix d'achat / Coût de revient"
          optional={openImporter === "update"}
        />
        <ImporterField name="description" label="Description" optional />
        <ImporterField name="drive_link" label="Fiche produit" optional />
        {configuration.admin.product_tab.csv.import_field_margin_rate && (
          <ImporterField
            name="margin_rate"
            label={isUsingMargin ? "Taux de marge" : "Coefficient"}
            optional
          />
        )}
        <ImporterField
          name="material_position"
          label="Position du matériel"
          optional
        />
        {configuration.admin.product_tab.csv.import_field_date_validity && (
          <ImporterField
            name="date_validity"
            label="Date (aaaa-mm-jj)"
            optional
          />
        )}
        <ImporterField
          name="category_profession_id"
          label="Id de la catégorie"
          optional
        />
        {configuration.admin.product_tab.csv.import_field_manufacturer_id && (
          <ImporterField
            name="manufacturer_id"
            label="Id du fabricant"
            optional
          />
        )}
        {configuration.admin.product_tab.csv.import_field_distributor_ids && (
          <ImporterField
            name="distributor_ids"
            label="Ids des distributeurs"
            optional
          />
        )}
        {configuration.admin.product_tab.csv
          .import_field_reference_constructor && (
          <ImporterField
            name="reference_constructor"
            label="Référence constructeur"
            optional
          />
        )}
        {openImporter === "update" && (
          <ImporterField name="to_delete" label="À supprimer" optional />
        )}
        {showConfig && (
          <ConfigContainer>
            <ConfigTitle>À supprimer :</ConfigTitle>
            <ChoiceContainer>
              <ConfigText>Oui = </ConfigText>
              <StyledInput
                value={toDeleteValue}
                onChange={(e) => setToDeleteValue(e.target.value)}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setToDeleteValue("1");
                  }
                }}
              />
            </ChoiceContainer>
            <ChoiceContainer>
              <ConfigText>Non = </ConfigText>
              <StyledInput
                value={toKeepValue}
                onChange={(e) => setToKeepValue(e.target.value)}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setToKeepValue("0");
                  }
                }}
              />
            </ChoiceContainer>
          </ConfigContainer>
        )}
      </Importer>
    </MaterialsImporterContainer>
  );
}

const MaterialsImporterContainer = styled.div``;

const ConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

const ConfigTitle = styled.h3`
  margin-bottom: 18px;
  font-size: 20px;
`;

const ChoiceContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const ConfigText = styled.p`
  white-space: nowrap;
  margin: 0;
  width: 55px;
`;

const StyledInput = styled(Input)`
  width: 100px;
`;

export default MaterialsImporter;
