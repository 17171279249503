import React from "react";
import { bool, node, oneOf, string } from "prop-types";
import styled, { css } from "styled-components";
import { theme } from "../../theme/theme";

function ButtonLink({
  children,
  backgroundColor,
  color,
  className,
  label,
  fontSize,
  btnType,
  loading,
  goTo,
  ...buttonProps
}) {
  const getColor = () => {
    if (color) return color;
    if (btnType === "outlined") return theme.colors.blue06;
    return "white";
  };
  const getBgColor = () => {
    if (backgroundColor) return backgroundColor;
    if (btnType === "outlined") return "white";
    return theme.colors.grey03;
  };

  return (
    <StyledButtonLink
      backgroundColor={getBgColor()}
      color={getColor()}
      className={className}
      fontSize={fontSize}
      btnType={btnType}
      disabled={buttonProps.disabled || loading}
      href={goTo}
      {...buttonProps}
    >
      {children}
      {label}
    </StyledButtonLink>
  );
}

const StyledButtonLink = styled.a`
  font-size: ${({ fontSize }) => `${fontSize} !important`};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 30px;
  padding: 0.3rem 0.5rem;
  transition: 0.15s ease-in-out;
  border: ${({ color, btnType }) =>
    `1px solid ${btnType === "outlined" ? color : "transparent"}`};

  @media screen and (min-width: 1200px) and (max-width: 1339px) {
    font-size: 10px !important;
  }

  @media screen and (max-width: 1199px) {
    font-size: 8px !important;
  }

  &:hover:not(:disabled) {
    color: white;
    background-color: ${({ color }) => color};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      ${({ btnType }) =>
        btnType === "outlined" &&
        css`
          color: ${({ backgroundColor }) => backgroundColor};
          background-color: ${() => theme.colors.blue04};
          border-color: ${() => theme.colors.blue04};
          opacity: 0.4 !important;
        `}

      ${({ btnType }) =>
        btnType === "cancel" &&
        css`
          background-color: ${() => theme.colors.grey01};
        `}
    `}
`;

ButtonLink.propTypes = {
  children: node,
  label: string,
  backgroundColor: string,
  color: string,
  className: string,
  fontSize: string,
  btnType: oneOf(["outlined", "cancel"]),
  loading: bool,
  goTo: string,
};

ButtonLink.defaultProps = {
  children: null,
  color: undefined,
  backgroundColor: undefined,
  label: undefined,
  className: "",
  fontSize: "12px",
  btnType: "outlined",
  loading: false,
  goTo: "",
};

export default ButtonLink;
