import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, TreeSelect } from "antd";
import moment from "moment";
import React, { useCallback, useMemo, useRef, useState } from "react";
import Form from "../../Form";
import { filterInTree } from "../../../utils/search";

const { confirm } = Modal;

const getAllChilds = (id, arr) => {
  const childs = arr?.filter((el) => el.pId === id && !el.disabled);

  if (childs.length < 1) return [];

  let allChilds = childs;
  childs.forEach((el) => {
    allChilds = allChilds.concat(getAllChilds(el.id, arr));
  });
  return allChilds;
};
const selectAllChilds = (id, arr, formRef, keyName) => {
  const allChilds = getAllChilds(id, arr);

  if (formRef.current) {
    const selectedEntities = formRef.current.form.getFieldValue(keyName) || [];
    formRef.current.form.setFieldsValue({
      [keyName]: selectedEntities.concat(allChilds),
    });
  }
};
function FAForm({
  isShowing,
  onSubmit,
  updateOnChange,
  submit,
  initialFA,
  entities = [],
  itemsToCreate = { materials: [] },
  majorations = [],
  difficulties = [],
}) {
  const formRef = useRef();
  const [disableStatus, setDisableStatus] = useState(initialFA?.status);

  const onSubmitWithStatusChange = useCallback(
    (e) => {
      if (
        updateOnChange &&
        !initialFA.status &&
        e.status &&
        itemsToCreate.materials.length > 0
      ) {
        const materialsStr =
          itemsToCreate.materials.length > 0
            ? `Matériels : ${itemsToCreate.materials.join(", ")}`
            : "";
        confirm({
          title: `Ces éléments vont être créés dans l'espace admin de votre agence. Êtes-vous sûr de continuer ?`,
          content: materialsStr,
          icon: <ExclamationCircleOutlined />,
          okText: "Oui",
          okType: "danger",
          maskClosable: false,
          cancelText: "Non",
          onOk() {
            setDisableStatus(true);
            onSubmit(e);
          },
          onCancel() {
            formRef.current?.form.setFieldsValue({
              status: 0,
            });
          },
        });
      } else if (updateOnChange && !initialFA.status && e.status) {
        setDisableStatus(true);
        onSubmit(e);
      } else {
        onSubmit(e);
      }
    },
    [onSubmit, updateOnChange, initialFA, itemsToCreate]
  );

  const formFactory = useMemo(() => {
    const nameAndStatus = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Nom de l'accord-cadre",
          },
          name: "name",
          label: "Intitulé",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "select",
        item: {
          select: {
            placeholder: "Statut de l'accord-cadre",
            disabled: disableStatus,
          },
          options: [
            {
              title: "Non validé",
              id: 0,
            },
            {
              title: "Validé",
              id: 1,
            },
          ],
          name: "status",
          label: "Statut",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const clientsArr = [
      {
        type: "treeSelect",
        item: {
          treeSelect: {
            isClient: true,
            placeholder: "Choisissez les entreprises",
            treeDataSimpleMode: true,
            treeDefaultExpandAll: true,
            treeCheckable: true,
            showSearch: true,
            onSelect: (value, clientsInMemory) =>
              selectAllChilds(value, clientsInMemory, formRef, "clients_ids"),
            showCheckedStrategy: TreeSelect.SHOW_ALL,
          },
          name: "clients_ids",
          label: "Entreprises facturées",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const dates = [
      {
        type: "datePicker",
        item: {
          datePicker: {
            placeholder: "Choisir une date",
          },
          name: "start_date",
          label: "Date de début",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },

      {
        type: "datePicker",
        item: {
          datePicker: {
            placeholder: "Choisir une date",
          },
          name: "end_date",
          label: "Date de fin",
          dependencies: ["start_date"],
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
            {
              validator: () => {
                const endDate = formRef.current.form.getFieldValue("end_date");
                const startDate =
                  formRef.current.form.getFieldValue("start_date");
                if (!endDate || !startDate) return Promise.resolve();
                const diff = moment.duration(
                  moment(endDate).diff(moment(startDate))
                );
                return diff >= 0
                  ? Promise.resolve()
                  : Promise.reject(new Error("Durée invalide."));
              },
            },
          ],
        },
      },
    ];

    const entitiesRow = [
      {
        type: "treeSelect",
        item: {
          treeSelect: {
            placeholder: "Choisissez les agences",
            treeDataSimpleMode: true,
            treeData: entities,
            treeDefaultExpandAll: true,
            treeCheckable: true,
            treeCheckStrictly: true,
            showSearch: true,
            filterTreeNode: filterInTree,
            onSelect: (value) =>
              selectAllChilds(value, entities, formRef, "entities_ids"),
            showCheckedStrategy: TreeSelect.SHOW_ALL,
          },
          name: "entities_ids",
          label: "Agences concernées",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const majorationDifficultyRow = [
      {
        type: "select",
        item: {
          select: {
            placeholder: "Choisissez une majoration",
          },
          options: [...majorations],
          keyName: "name",
          name: "majoration_profession_id",
          label: "Majoration par défaut",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "select",
        item: {
          select: {
            placeholder: "Choisissez une difficulté",
          },
          options: [...difficulties],
          keyName: "name",
          name: "difficulty_profession_id",
          label: "Difficulté par défaut",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    return [
      { columns: nameAndStatus, key: "nameAndStatus" },
      { columns: clientsArr, key: "clientsArr" },
      { columns: dates, key: "dates" },
      { columns: entitiesRow, key: "entitiesRow" },
      updateOnChange && {
        columns: majorationDifficultyRow,
        key: "majorationDifficultyRow",
      },
    ];
  }, [entities, disableStatus, majorations, updateOnChange, difficulties]);

  const initialValues = useMemo(() => {
    if (!initialFA) return {};

    const entities_ids =
      initialFA.entities?.map((el) => ({ label: el.title, value: el.id })) ||
      [];
    const clients_ids =
      initialFA.clients?.map((el) => ({ label: el.name, value: el.id })) || [];

    let { status } = initialFA;
    if (status !== undefined) status = status ? 1 : 0;

    return {
      ...initialFA,
      entities_ids,
      clients_ids,
      status,
      majoration_profession_id:
        initialFA.default_value?.majoration_profession?.id,
      difficulty_profession_id:
        initialFA.default_value?.difficulty_profession.id,
    };
  }, [initialFA]);

  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmitWithStatusChange}
      rows={formFactory}
      isShowing={isShowing}
      id="fa-form"
      ref={formRef}
    />
  );
}

export default FAForm;
