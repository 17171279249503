import React from "react";
import { bool, node, oneOf, string } from "prop-types";
import styled, { css } from "styled-components";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { theme } from "../../theme/theme";

function Button({
  children,
  backgroundColor,
  color,
  className,
  label,
  fontSize,
  btnType,
  loading,
  ...buttonProps
}) {
  const getColor = () => {
    if (color) return color;
    if (btnType === "outlined") return theme.colors.blue06;
    return "white";
  };

  const getBgColor = () => {
    if (backgroundColor) return backgroundColor;
    if (btnType === "outlined") return "white";
    return theme.colors.grey03;
  };

  return (
    <StyledButton
      backgroundColor={getBgColor()}
      color={getColor()}
      className={className}
      fontSize={fontSize}
      btnType={btnType}
      disabled={buttonProps.disabled || loading}
      {...buttonProps}
    >
      {loading && (
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: "12px",
                color: "white",
                display: "flex",
                marginRight: "5px",
              }}
              spin
            />
          }
        />
      )}
      {label || children}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  white-space: nowrap;
  font-size: ${({ fontSize }) => `${fontSize} !important`};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 30px;
  padding: 0.3rem 0.5rem;
  transition: 0.15s ease-in-out;
  border: ${({ color, btnType }) =>
    `1px solid ${btnType === "outlined" ? color : "transparent"}`};

  @media screen and (min-width: 1200px) and (max-width: 1339px) {
    font-size: 10px !important;
  }

  @media screen and (max-width: 1199px) {
    font-size: 8px !important;
  }

  &:hover:not(:disabled) {
    ${({ btnType }) =>
      btnType === "outlined" &&
      css`
        color: ${({ backgroundColor }) => backgroundColor};
        background-color: ${({ color }) => color};
      `}

    ${({ btnType }) =>
      btnType === "cancel" &&
      css`
        background-color: ${() => theme.colors.grey05};
      `}
  }

  &:disabled {
    ${({ btnType }) =>
      btnType === "outlined" &&
      css`
        color: ${({ backgroundColor }) => backgroundColor};
        background-color: ${() => theme.colors.blue04};
        border-color: ${() => theme.colors.blue04};
        opacity: 0.4 !important;
      `}

    ${({ btnType }) =>
      btnType === "cancel" &&
      css`
        background-color: ${() => theme.colors.grey01};
      `}
  }
`;

Button.propTypes = {
  children: node,
  label: string,
  backgroundColor: string,
  color: string,
  className: string,
  fontSize: string,
  btnType: oneOf(["outlined", "cancel"]),
  loading: bool,
};

Button.defaultProps = {
  children: null,
  color: undefined,
  backgroundColor: undefined,
  label: undefined,
  className: "",
  fontSize: "12px",
  btnType: "outlined",
  loading: false,
};

export default Button;
