$(document).ready(() => {

  const opportunityNew = document.getElementById("create_opportunity")
  const opportunityEdit = document.getElementById("edit_opportunity")

  if(!opportunityNew && !opportunityEdit){
    return
  }

const formContact = document.getElementById('new_contact_form');
const formContactInputFirstName = document.getElementById('first_form_contact_first_name');
const formContactInputLastName = document.getElementById('first_form_contact_last_name');
const dropDownContacts = document.querySelector('.opportunity_contact_ids');
const currentUserCompanyId = document.getElementById("main-data");

if (currentUserCompanyId) {
  if (formContact) {
    const addContact = () => {
      const id = currentUserCompanyId.dataset.company;
      fetch(`/companies/${id}/send_contact`, {
        method: "POST",
        headers: { "Content-Type": "application/json",
                   "Accept": "application/json",
                   "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
                 },
        body: JSON.stringify({ "first_name": formContactInputFirstName.value,
                                "last_name": formContactInputLastName.value })
      }).then(response => response.json())
        .then((data) => {
          eval(data)
        })
        $("#siteMapNewContactModal").modal('hide');
    };

    formContact.addEventListener('submit', addContact);
  }

  if (dropDownContacts) {
    const displayAllContact = () => {
      const billed_customer_id = $(".dropdown-billed-customer").select2('data')[0].id;
      const id = currentUserCompanyId.dataset.company;
      fetch(`/fetch_dropdown_contacts/${billed_customer_id}`, {

        method: "GET",
        headers: { "Content-Type": "application/json",
                   "Accept": "application/json",
                   "X-Requested-With": "XMLHttpRequest",
                 }
      })
      .then(response => response.json())
      .then((data) => {
        eval(data)

        // order by id data JSON
          const dynamicSort = (property) => {
              let sortOrder = 1;
              if(property[0] === "-") {
                  sortOrder = -1;
                  property = property.substr(1);
              }
              return function (a,b) {
                  const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                  return result * sortOrder;
              }
          }

          data.sort(dynamicSort("id"));
          // select the last element to insert only it
          const element = data[data.length -1];
          if (element) {
            const selectDropdown = $(".dropdown-contacts");
            // delete all items of the native select element
            selectDropdown.innerHTML= "";
            if ($(`.dropdown-contacts option[value='${element.id}']`).length === 0) {
              const newOption = new Option(`${element.text}`, element.id, true, true);
              selectDropdown.append(newOption).trigger('change');
            }
          }
      })
      const addContact = document.getElementById("add-modal-contact");
      const btnCreateContact = document.getElementById("create-contact");
      if (addContact) {
        const refreshContactForm = () => {
          // refresh the input first_name
          const inputValues = formContact.getElementsByTagName("input");
          inputValues.forEach(input => {
            if (input.value !== "Créer ce contact") {
              input.value = "";
            }
          })
          btnCreateContact.disabled = false
          btnCreateContact.value = "Créer un contact"
        };
        addContact.addEventListener("mouseover", refreshContactForm);
      }
    };
    $('#siteMapNewContactModal').on('hidden.bs.modal', function (e) {
      displayAllContact()
    });
  }
}
const editopportunityForm = document.querySelector(".edit_opportunity_page");
// FILL DIRECTLY THE CASE "Dans quelle entreprise travail cette entreprise ?" by the Client Presté present in Edit_Opportunity



if ( editopportunityForm) {
  $(document).ready(function() {
    $('.dropdown-billed-customer').on('change', () => {
      const currentClientId = $(".client_id");
    });
    // execute code when the modal new_contact is open
    $('#siteMapNewContactModal').on('shown.bs.modal', function (e) {
      const currentClientId = $(".client_id");
      const idClient = currentClientId.data("client");
      const client = document.querySelector(".select2-selection__rendered")
      if (currentClientId) {
        const modalNewContact = document.querySelector("#siteMapNewContactModal")
        if (modalNewContact) {
          $(".contact_client_id select").val(idClient).change()
        }
      }
    });
  });
}
})

