import { ConfigProvider } from "antd";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import frFr from "antd/lib/locale/fr_FR";
import { ThemeProvider } from "styled-components";
import RightBreadcrumb from "./RightBreadcrumb";
import { theme } from "../../theme/theme";

const queryClient = new QueryClient();

function GuardingRightBreadcrumb(props) {
  return (
    <ConfigProvider locale={frFr}>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <RightBreadcrumb {...props} opportunity_work="guarding" />
        </QueryClientProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
}

export default GuardingRightBreadcrumb;
