import { Divider } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";
import { formatNumberString } from "../../../../utils/formatNumberString";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";

const dayTypes = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
  "work_free_day",
  "sunday_work_free_day",
];

const WDCols = [
  {
    title: "Lun",
    dataIndex: "monday",
    render: (val) => formatNumberString({ str: val }),
    align: "center",
  },
  {
    title: "Mar",
    dataIndex: "tuesday",
    render: (val) => formatNumberString({ str: val }),
    align: "center",
  },
  {
    title: "Mer",
    dataIndex: "wednesday",
    render: (val) => formatNumberString({ str: val }),
    align: "center",
  },
  {
    title: "Jeu",
    dataIndex: "thursday",
    render: (val) => formatNumberString({ str: val }),
    align: "center",
  },
  {
    title: "Ven",
    dataIndex: "friday",
    render: (val) => formatNumberString({ str: val }),
    align: "center",
  },
  {
    title: "Sam",
    dataIndex: "saturday",
    render: (val) => formatNumberString({ str: val }),
    align: "center",
  },
  {
    title: "Dim",
    dataIndex: "sunday",
    render: (val) => formatNumberString({ str: val }),
    align: "center",
  },
  {
    title: "J.F",
    dataIndex: "work_free_day",
    render: (val) => formatNumberString({ str: val }),
    align: "center",
  },
  {
    title: "Dim J.F",
    dataIndex: "sunday_work_free_day",
    render: (val) => formatNumberString({ str: val }),
    align: "center",
  },
];

function WorkedDays({ planning }) {
  const workedDays = useMemo(
    () =>
      dayTypes.map((day) => {
        const include = day === "sunday_work_free_day" ? "work_free_day" : day;
        const regularWorkedDays = planning?.worked_days_list.includes(include)
          ? planning?.nb_days_in_period[day]
          : 0;
        const spWorkedDays = planning?.nb_sp_days_in_period[day];
        return { day, value: regularWorkedDays + spWorkedDays };
      }),
    [planning]
  );

  const dataSource = useMemo(() => {
    const newDataSource = {
      key: "1",
    };
    workedDays.forEach(({ day, value }) => {
      newDataSource[`${day}`] = value;
    });
    return [newDataSource];
  }, [workedDays]);

  return (
    <>
      <div>
        <StyledDivider style={{ marginBottom: 0 }}>
          Nombre de jours travaillés (
          {workedDays?.reduce((acc, curr) => acc + curr.value, 0)})
        </StyledDivider>
      </div>
      <StyledTable
        bordered
        columns={WDCols}
        dataSource={dataSource}
        pagination={false}
      />
    </>
  );
}

const StyledTable = styled(TableContainer)`
  margin: 10px 0 !important;
  .fa-sun {
    color: orange;
    font-size: 12px;
  }

  .fa-moon {
    color: lightsteelblue;
    font-size: 12px;
  }
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 0 !important;
  border-block-start-color: rgba(0, 0, 0, 0.88) !important;
  margin-top: 20px !important;
`;

export default WorkedDays;
