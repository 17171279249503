import React from "react";

const CalculateMargin = (inputValue, itemValue, id_opportunity, updateFunction, method_calcul, flag) => {
  const selling_price_method_selling_price = (margin, cost_price) => {
    return Number.parseFloat((margin / 100 + 1) * cost_price)
  }

  const no_selling_price_method_selling_price = (margin, cost_price) => {
    return Number.parseFloat(1+(margin/100) * cost_price)
  }
  let dataRequest = {
    opportunity_id: id_opportunity,
    id: itemValue.id,
  }
  if (flag === "materials"){
    dataRequest = {...dataRequest, margin_rate: inputValue.target.value,
    selling_price_cents: method_calcul === "selling_price" ? 
        selling_price_method_selling_price(inputValue.target.value, itemValue.unitary_cost_price) 
      :
        no_selling_price_method_selling_price(inputValue.target.value, itemValue.unitary_cost_price)}
  }
  

    updateFunction({
        opportunity_id: id_opportunity,
        id: itemValue.id,
        margin_rate: inputValue.target.value,
        selling_price_cents: method_calcul === "selling_price" ? 
            selling_price_method_selling_price(inputValue.target.value, itemValue.unitary_cost_price) 
          :
            no_selling_price_method_selling_price(inputValue.target.value, itemValue.unitary_cost_price)
    })
}

export default CalculateMargin