import React from "react";
import { Cascader as AntdCascader, Form } from "antd";
import { array, bool, func, shape, string } from "prop-types";
import Label from "./Label";

function Cascader({
  cascader,
  options,
  handleUpdateOnChange,
  isShowing,
  ...formItemProps
}) {
  const handleOnChange = (e) => {
    if (cascader.onChange) cascader.onChange(e);
    handleUpdateOnChange();
  };

  const disabled = cascader.disabled || isShowing;

  return (
    <Form.Item
      {...formItemProps}
      // label={<Label label={formItemProps.label} rules={formItemProps.rules} />}
    >
      <AntdCascader
        {...cascader}
        onChange={handleOnChange}
        disabled={disabled}
        placeholder={isShowing ? "" : cascader.placeholder}
        options={options}
      />
    </Form.Item>
  );
}

Cascader.propTypes = {
  cascader: shape({
    placeholder: string,
    onChange: func,
    disabled: bool,
  }).isRequired,
  name: string.isRequired,
  options: array,
  label: string,
  rules: array,
  handleUpdateOnChange: func.isRequired,
  isShowing: bool,
};

Cascader.defaultProps = {
  label: "",
  rules: [],
  isShowing: false,
  options: [],
};

export default Cascader;
