import React from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import { useStore } from "../../store";
import { Loading } from "../../loading";
import MajorationForm from "./MajorationForm";
import { postData } from "../../request/instance";
import { getUrlParams } from "../../utils";
import DifficultyForm from "./DifficultyForm";
import UnityForm from "./UnityForm";
import DefaultValueForm from "./DefaultValueForm";
import TitleContainer from "../../react-ui/TitleContainer";
import AddButton from "../../react-ui/AddButton";
import Button from "../../react-ui/Button";

const selector = (state) => ({
  selectedGenralParameter: state.selectedGenralParameter,
  formToken: state.formToken,
});
function ParametersDetails({
  parameterDetailsStatus,
  setParameterDetailsStatus,
  difficulties,
  majorations,
  entity_work_name,
  configuration,
  vocabulary,
}) {
  const { formToken, selectedGenralParameter } = useStore(selector);
  const queryClient = useQueryClient();

  const parameterRouteUpdate = () => {
    if (parameterDetailsStatus.type !== "default_value") {
      return `${parameterDetailsStatus.type}_profession`;
    }
    return parameterDetailsStatus.type;
  };
  const parameterQueryKey = () => {
    switch (parameterDetailsStatus.type) {
      case "default_value":
        return "DefaultValues";
      case "unity":
        return "Unities";
      case "difficulty":
        return "Difficulties";
      default:
        return "Majorations";
    }
  };

  const { mutate: updateParameter, status } = useMutation(
    (todo) => postData(formToken, `/${parameterRouteUpdate()}/update`, todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries(parameterQueryKey());
      },
    }
  );

  const { mutate: createParameter } = useMutation(
    (todo) => postData(formToken, `/${parameterRouteUpdate()}/create`, todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries(parameterQueryKey());
      },
    }
  );

  const handleRegisterParameterProfession = (e) => {
    const formatableKeys = ["rate"];
    const formData = new FormData();
    Object.keys(e).forEach(
      (key) =>
        !formatableKeys.includes(key) &&
        formData.append(
          `${parameterRouteUpdate()}[${key}]`,
          e[key] !== undefined && e[key] !== null ? e[key] : ""
        )
    );
    formData.append(
      `${parameterRouteUpdate()}[rate]`,
      e.rate ? parseFloat(e.rate.replace(/,/g, ".")) : ""
    );
    formData.append(
      `${parameterRouteUpdate()}[entity_work_id]`,
      getUrlParams()
    );
    if (parameterDetailsStatus.status === "update") {
      formData.append("id", selectedGenralParameter.id);
      updateParameter(formData);
    }
    if (parameterDetailsStatus.status === "create") {
      createParameter(formData);
      setParameterDetailsStatus({ type: "none", status: "empty" });
    }
  };

  const onSubmit = (e) => {
    handleRegisterParameterProfession(e);
    if (parameterDetailsStatus === "create")
      setParameterDetailsStatus({ type: "none", status: "empty" });
  };

  const submit = {
    onCancel: () =>
      setParameterDetailsStatus({ type: "none", status: "empty" }),
    backgroundColor: "#f5f6f8",
  };

  const isShowing =
    parameterDetailsStatus.status === "update" &&
    selectedGenralParameter.entity_work_id !== parseInt(getUrlParams(), 10);

  const isUpdating = parameterDetailsStatus.status === "update" && !isShowing;

  return (
    <Container>
      <TitleContainer label="Détails du paramètre">
        {parameterDetailsStatus.status === "create" && (
          <>
            <StyledButton
              label="Annuler"
              onClick={() =>
                setParameterDetailsStatus({ type: "none", status: "empty" })
              }
              fontSize="14px"
              btnType="cancel"
            />
            <AddButton
              label="Créer"
              type="submit"
              value="submit"
              fontSize="14px"
              form="parameter-form"
            />
          </>
        )}
        <Loading status={status} />
      </TitleContainer>
      {parameterDetailsStatus.status === "empty" && (
        <div className="d-flex justify-content-center align-items-center">
          <p className="my-0">
            Sélectionnez ou créez un paramètre pour afficher les détails
          </p>
        </div>
      )}
      {parameterDetailsStatus !== "empty" &&
        parameterDetailsStatus.type === "majoration" && (
          <MajorationForm
            updateOnChange={isUpdating}
            initialMajoration={
              parameterDetailsStatus.status === "update"
                ? selectedGenralParameter
                : undefined
            }
            onSubmit={onSubmit}
            isShowing={isShowing}
          />
        )}
      {parameterDetailsStatus !== "empty" &&
        parameterDetailsStatus.type === "difficulty" && (
          <DifficultyForm
            updateOnChange={isUpdating}
            initialDifficulty={
              parameterDetailsStatus.status === "update"
                ? selectedGenralParameter
                : undefined
            }
            onSubmit={onSubmit}
            isShowing={isShowing}
            entity_work_name={entity_work_name}
            configuration={configuration}
            vocabulary={vocabulary}
          />
        )}
      {parameterDetailsStatus !== "empty" &&
        parameterDetailsStatus.type === "unity" && (
          <UnityForm
            updateOnChange={isUpdating}
            initialUnity={
              parameterDetailsStatus.status === "update"
                ? selectedGenralParameter
                : undefined
            }
            onSubmit={onSubmit}
            isShowing={isShowing}
          />
        )}
      {parameterDetailsStatus !== "empty" &&
        parameterDetailsStatus.type === "default_value" && (
          <DefaultValueForm
            submit={
              parameterDetailsStatus.status === "create" ? submit : undefined
            }
            updateOnChange={parameterDetailsStatus.status === "update"}
            initialDefaultValue={
              parameterDetailsStatus.status === "update"
                ? selectedGenralParameter
                : undefined
            }
            onSubmit={onSubmit}
            difficulties={difficulties}
            majorations={majorations}
            entity_work_name={entity_work_name}
            configuration={configuration}
            vocabulary={vocabulary}
          />
        )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

export default ParametersDetails;
