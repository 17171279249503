import { Form, Switch, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

export default function SwitchComponent({
  initialValue,
  handleChange,
  labelText,
  name,
  info,
  isDisabled,
}) {
  const [componentChecked, setComponentChecked] = useState(initialValue);

  const onFormLayoutChange = (form) => {
    const switchValue = form?.[name];
    setComponentChecked(switchValue);
    handleChange(switchValue, name);
  };

  useEffect(() => {
    setComponentChecked(initialValue);
  }, [initialValue]);
  return (
    <Form
      style={{ width: "100%", display: "grid" }}
      layout="horizontal"
      initialValues={{
        [name]: componentChecked,
      }}
      onValuesChange={onFormLayoutChange}
    >
      {/* CONDITION IF TOOLTIP ON SWITCH */}
      {info ? (
        <StyledInfoDiv>
          <div
            className="ant-form-item-label"
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "end",
              gap: "5px",
            }}
          >
            <StyledInfoLabel>{labelText}</StyledInfoLabel>
            <Tooltip title={info} placement="top">
              <i className="fa-duotone fa-circle-info" />
            </Tooltip>
          </div>
          <StyledFormItem
            className="ant-form-item-info"
            style={{
              width: "auto",
            }}
            name={name}
          >
            <Switch
              disabled={isDisabled}
              checked={componentChecked}
              checkedChildren="Oui"
              unCheckedChildren="Non"
              onChange={(checked) => {
                setComponentChecked(checked);
                handleChange(checked, name);
              }}
              style={{
                backgroundColor: componentChecked ? "#1990FE" : "#BFBFBF",
              }}
            />
          </StyledFormItem>
        </StyledInfoDiv>
      ) : (
        <StyledFormItem
          className="ant-form-item-long-label"
          label={labelText}
          name={name}
        >
          <Switch
            disabled={isDisabled}
            checked={componentChecked}
            checkedChildren="Oui"
            unCheckedChildren="Non"
            onChange={(checked) => {
              setComponentChecked(checked);
              handleChange(checked, name);
            }}
            style={{
              backgroundColor: componentChecked ? "#1990FE" : "#BFBFBF",
            }}
          />
        </StyledFormItem>
      )}
    </Form>
  );
}

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    color: #000000 !important;
    font-size: 12px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    white-space: wrap;
    height: 100%;
    max-width: auto !important;
    text-align: end;
    align-items: center;
  }

  ,
  .ant-form-item-label > label::after {
    content: "" !important;
  }
  ,
  .ant-form-item {
    margin-bottom: 0px !important;
    display: flex;
    justify-content: space-between;
  }
  ,
  .ant-form-item-row {
    display: grid !important;
    grid-template-columns: 1fr auto;
  }
  ,
  .ant-form-horizontal {
    display: flex !important;
    justify-content: end !important;
  }
  ,
  .ant-row {
    flex-flow: row !important;
  }
`;

const StyledInfoDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: end !important;
  width: 100%;
  gap: 10px;
`;

const StyledInfoLabel = styled.label`
  color: #000000 !important;
  font-size: 12px !important;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  white-space: wrap;
  height: 100%;
  max-width: auto !important;
  text-align: end;
  align-items: center;
`;
