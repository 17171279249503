import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { debounce } from "lodash";
import { Modal } from "antd";
import Separator from "../../react-ui/Separator";
import { getData, postData } from "../../request/instance";
import { useStore } from "../../store";
import ApplicationInformations from "./ApplicationInformations";
import AddButton from "../../react-ui/AddButton";
import { fetchApplicationsUrl } from "../../../utils/fetchApplicationsUrl";
import ApplicationsArray from "./ApplicationsArray";
import ApplicationForm from "./Form";
import { generateFormData } from "../../../utils/generateFormData";

const selector = (state) => ({
  formToken: state.formToken,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);

function Applications() {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();

  const [status, setStatus] = useState("empty");
  const [selectedApplication, setSelectedApplication] = useState("empty");
  const [wordEntered, setWordEntered] = useState("");
  const [isNewOpen, setIsNewOpen] = useState(false);

  const {
    data: applications,
    refetch,
    isLoading,
    isRefetching,
  } = useQuery(
    "Applications",
    () => getData(formToken, fetchApplicationsUrl({ search: wordEntered })),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  const closeModal = useCallback(() => {
    setIsNewOpen(false);
  }, []);

  const openModal = () => {
    setIsNewOpen(true);
  };

  const { mutate: createApplication, isLoading: createIsLoading } = useMutation(
    (todo) => postData(formToken, `/applications/create`, todo),
    {
      onSuccess: ({ application }) => {
        setSelectedApplication(application);
        setStatus("show");
        queryClient.invalidateQueries("Applications");
        closeModal();
      },
    }
  );

  const handleSubmit = (values) => {
    const formName = "application";
    const formData = generateFormData({
      e: values,
      formName,
    });

    createApplication(formData);
  };

  return (
    <Container>
      <NewButton label="Application connectée" onClick={openModal} />
      <StyledModal
        open={isNewOpen}
        maskClosable={false}
        footer={null}
        width={900}
        destroyOnClose
        onCancel={closeModal}
        closable={false}
      >
        <ApplicationForm
          isCreating
          handleSubmit={handleSubmit}
          setStatus={setStatus}
          isLoading={createIsLoading}
          onCancel={closeModal}
        />
      </StyledModal>
      <ApplicationsArray
        applications={applications}
        applicationsAreLoading={isLoading || isRefetching}
        wordEntered={wordEntered}
        setWordEntered={setWordEntered}
        setStatus={setStatus}
        selectedApplication={selectedApplication}
        setSelectedApplication={setSelectedApplication}
      />
      <Separator />
      <ApplicationInformations
        status={status}
        setStatus={setStatus}
        selectedApplication={selectedApplication}
        setSelectedApplication={setSelectedApplication}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: calc(100vw - 85px);
  position: fixed;
  height: calc(100% - 135.56px);
  padding-right: 15px;
  padding-bottom: 10px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    min-height: 300px;
    padding: 8px !important;
    border-radius: 2.5px !important;
  }
`;

const NewButton = styled(AddButton)`
  position: absolute;
  top: -40px;
  right: 15px;
`;

export default Applications;
