import moment from "moment";
import React from "react";
import styled from "styled-components";
import { opportunityField } from "../../constant";
import FormCol from "../../Form/FormCol";
import ReactDatePicker from "../../Form/ReactDatePicker";

function DurationCol({
  opportunity,
  isGuarding,
  hasRenting,
  onlyStartDate = false,
  endDate,
  startDate,
  responseTypeId,
  works,
  work_id,
  isSimple,
  required,
  disabled,
}) {
  const getDateDiff = () => {
    if (!endDate || !startDate) return "0 jour";
    const momentEndDate = moment(endDate);
    const momentStartDate = moment(startDate);
    if (momentStartDate.isAfter(momentEndDate)) return "0 jour";
    const days = momentEndDate.diff(moment(momentStartDate), "days") + 1;

    let str = "";
    if (days) str += `${days} jour${days > 1 ? "s" : ""}`;
    return str;
  };

  const work_selected = works.find((el) => el.id === work_id)?.name;

  const label_date = () => {
    if (work_selected === "Simple") {
      return "Date de réalisation";
    }
    if (!opportunity && !isGuarding()) {
      return onlyStartDate && responseTypeId !== "renting"
        ? "Début du chantier"
        : "Début du contrat";
    }
    if (opportunity && !isGuarding()) {
      return onlyStartDate && !hasRenting()
        ? "Début du chantier"
        : "Début du contrat";
    }
    if (isGuarding()) {
      return "Début du contrat";
    }
  };
  return (
    <>
      {" "}
      {(!isSimple || !isSimple()) && (
        <>
          <FormCol
            labelOnTop
            label={label_date()}
            rules={[{ required: !onlyStartDate }]}
            name={opportunityField.startDate}
          >
            <StyledDatePicker
              placeholderText="Sélectionnez une date"
              disabled={disabled}
            />
          </FormCol>
          {!onlyStartDate && (
            <FormCol
              labelOnTop
              dependencies={[opportunityField.startDate]}
              label="Fin du contrat"
              rules={[
                {
                  required,
                },
                {
                  validator: () => {
                    if (!endDate || !startDate) return Promise.resolve();
                    const diff = moment.duration(
                      moment(endDate).diff(moment(startDate))
                    );
                    return diff >= 0
                      ? Promise.resolve()
                      : Promise.reject(new Error("Durée invalide."));
                  },
                },
              ]}
              name={opportunityField.endDate}
              customContent={
                <DurationContainer>
                  Durée du contrat : {getDateDiff()}
                </DurationContainer>
              }
            >
              <StyledDatePicker
                placeholderText="Sélectionnez une date"
                disabled={disabled}
              />
            </FormCol>
          )}
        </>
      )}
    </>
  );
}

const StyledDatePicker = styled(ReactDatePicker)`
  width: 100%;
`;

const DurationContainer = styled.div`
  align-self: end;
  margin-top: 10px;
`;

export default DurationCol;
