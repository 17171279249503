$(document).ready(() => {
  const offerShow = document.getElementById("opportunities-list")
  if(!offerShow){
    return
  }

const selectDropDownServiceObligationItems = document.querySelector('.offer_review_service_offer_review_ids');

if (selectDropDownServiceObligationItems) {
  // GET REQUEST
  const readDropDown = () => {
    fetch('/service_monitoring_obligations', {
      method: "GET",
      headers: { "Content-Type": "application/json",
                 "Accept": "application/json",
                 "X-Requested-With": "XMLHttpRequest",
                 "X-CSRF-Token": $('meta[name="csrf-token"]').attr('content')
               }
    })
      .then(response => response.json())
      .then((data) => {
        eval(data)
        // order by id data JSON
        const dynamicSort = (property) => {
            let sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }

        data.sort(dynamicSort("id"));
        // select the last element to insert only it
        const element = data[data.length -1];
        if (element) {
          const selectDropdown = $(".dropdown-monitoring-obligations");
          // delete all items of the native select element
          selectDropdown.innerHTML= "";
          if ($(`.dropdown-monitoring-obligations option[value='${element.id}']`).length === 0) {
            selectDropdown.append("<option value=\"" +  element.id + "\">" +  element.name + "</option>");
          }
        }
      })
  }
  selectDropDownServiceObligationItems.addEventListener('mouseover', readDropDown);
}
})
