import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import Search from "antd/lib/input/Search";
import { Modal, Checkbox } from "antd";
import { debounce } from "lodash";
import { getData, postData } from "../../../request/instance";
import { fetchPrestationsUrl } from "../../../../utils/fetchPrestationsUrl";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import { getFetchUrlIdAndFrom } from "../../../../utils/getFetchUrlIdAndFrom";
import PrestationForm from "../../Prestations/PrestationForm";
import Eye from "../../../react-ui/Icons/Eye";
import Input from "../../../react-ui/Input";
import { formatNumberString } from "../../../../utils/formatNumberString";

import { stringToFloat } from "../../../utils";
import { numberSorter } from "../../../../utils/numberSorter";
import { quantityTimeConverter } from "../../../../utils/quantityTimeConverter";
import { useStore } from "../../../store";
import FormattedInput from "../../../react-ui/FormattedInput";

const selector = (state) => ({
  isUsingMinutes: state.isUsingMinutes,
});

const debouncedSearch = debounce(({ refetch }) => {
  refetch();
}, 300);
function ChildPrestationArray({
  formToken,
  entityWorkId,
  faId,
  faIsValidate,
  opportunityId,
  from = "admin",
  ouvrageSelected,
  ouvrageChildId,
  configuration,
  vocabulary,
  unities,
  setImpactedModelZone,
}) {
  const { isUsingMinutes } = useStore(selector);
  const [tableRowsData, setTableRowData] = useState([]);
  const [wordEntered, setWordEntered] = useState("");
  const [prestationAssociateData, setPrestationAssociateData] = useState([]);

  const [isModalPrestationVisible, setIsModalPrestationVisible] =
    useState(false);
  const [showPrestation, setShowPrestation] = useState();
  const queryClient = useQueryClient();

  const ouvrageProfessionQueryKey = [
    "OuvrageProfession",
    ouvrageSelected[0]?.id,
  ];

  const { mutate: createAssociate } = useMutation(
    (todo) => postData(formToken, "/prestation_associate/create", todo),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(ouvrageProfessionQueryKey);
        queryClient.invalidateQueries("Prestations");
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  const { mutate: deleteAssociate } = useMutation(
    (todo) => postData(formToken, `/prestation_associate/delete`, todo),
    {
      onSettled: (data) => {
        queryClient.invalidateQueries(ouvrageProfessionQueryKey);
        queryClient.invalidateQueries("Prestations");
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  const { mutate: updateAssociate } = useMutation(
    (todo) => postData(formToken, `/prestation_associate/update`, todo),
    {
      onSettled: (data) => {
        queryClient.invalidateQueries("Prestations");
        if (data && data?.impacted) {
          setImpactedModelZone(data.impacted);
        } else {
          setImpactedModelZone([]);
        }
      },
    }
  );

  useEffect(() => {
    setPrestationAssociateData([]);
  }, [ouvrageSelected[0]?.id]);
  const handleCheckPrestation = (checked, record) => {
    if (checked) {
      createAssociate({
        prestation_parent_id: record.id,
        ouvrage_profession_id: ouvrageChildId,
        linked: true,
        speed: record.speed,
        speed_quantity: record.speed_quantity,
        quantity: record.quantity ? record.quantity : 0,
      });
    } else {
      deleteAssociate({
        prestation_parent_id: record.id,
        ouvrage_profession_id: ouvrageChildId,
      });
    }
  };

  const getSuffix = (presta) => {
    if (!presta.speed) {
      if (!presta.is_package) return isUsingMinutes ? "min" : "h";
      return "u";
    }

    return (
      unities.find((unity) => unity.id === presta?.unity_profession_id)
        ?.abbreviation || "u"
    );
  };

  const handleCancelPrestation = () => {
    setIsModalPrestationVisible(false);
  };

  const showModalPrestation = (prestation) => {
    setIsModalPrestationVisible(true);
    setShowPrestation(prestations.find((item) => item.id === prestation.id));
  };

  const inputValue = (record) => {
    return prestationAssociateData.id === record.id
      ? parseFloat(prestationAssociateData.quantity_associated)
      : formatNumberString({
          str:
            record.is_package || record.speed
              ? record.quantity_associated
              : quantityTimeConverter({
                  quantity: record.quantity_associated,
                  isUsingMinutes: !record.is_package ? isUsingMinutes : false,
                }),
        });
  };

  const colPresta = [
    {
      title: "Intitulé",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Associer",
      dataIndex: "associated",
      defaultSortOrder: "descend",
      width: "65px",
      sorter: (a, b) =>
        numberSorter(a.associated, b.associated) || numberSorter(a.id, b.id),
      render: (_, record) => (
        <CenteredContainer>
          <Checkbox
            onChange={({ target: { checked } }) =>
              handleCheckPrestation(checked, record)
            }
            checked={record.associated}
          />
        </CenteredContainer>
      ),
    },
    {
      title: "Quantité",
      dataIndex: "quantity",
      key: "quantity",
      width: "75px",
      render: (val, record) =>
        record.associated && (
          <FormattedInput
            isNumber
            controls={false}
            fontSize="10px"
            value={inputValue(record)}
            suffix={getSuffix(record)}
            size="small"
            textAlign="right"
            onChange={(e) => {
              setPrestationAssociateData({
                id: record.id,
                quantity_associated: e.target.value,
              });
            }}
            onBlur={(e) =>
              updateAssociate({
                prestation_parent_id: record.id,
                ouvrage_profession_id: ouvrageChildId,
                quantity:
                  record.is_package || record.speed
                    ? stringToFloat(e.target.value)
                    : quantityTimeConverter({
                        quantity: stringToFloat(e.target.value),
                        isUsingMinutes,
                        toMinutes: false,
                      }),
              })
            }
          />
        ),
    },
    {
      title: "",
      dataIndex: "show",
      key: "show",
      width: "20px",
      render: (_, prestation) => (
        <CenteredContainer>
          <Eye
            onClick={() => {
              showModalPrestation(prestation);
            }}
          />
        </CenteredContainer>
      ),
    },
  ];

  const { data: prestations, refetch } = useQuery("Prestations", () =>
    getData(
      formToken,
      fetchPrestationsUrl({
        ...getFetchUrlIdAndFrom({
          from,
          opportunityId,
          faIsValidate,
          faId,
          entityWorkId,
        }),
        search: wordEntered,
        parent: "ouvrage",
        parentId: ouvrageChildId,
      })
    )
  );

  useEffect(() => {
    debouncedSearch({ refetch });
  }, [wordEntered, refetch]);

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);

    if (searchWord === "") {
      clearInput();
    }
  };

  const clearInput = () => {
    setWordEntered("");
  };

  useEffect(() => {
    refetch();
  }, [ouvrageChildId, refetch]);

  useEffect(() => {
    const dataResult = [];
    let child;

    prestations?.forEach((value) => {
      child = {
        ...value,
        key: value.id,
        id: value.id,
        name: value.prestation_included
          ? value.prestation_included.name
          : value.name,
        is_in_framework_agreement:
          value.is_in_framework_agreement || value.prestation_included,
        maintenance: value.maintenance,
        subcontracting: value.subcontracting,
        is_package: value.is_package,
        margin: value.margin_rate,
        // category: categories?.find(
        //   (category) => category.id === value.category_profession_id
        // )?.title,
      };
      dataResult.push(child);
    });
    setTableRowData(
      dataResult?.filter((presta) => {
        if (from === "referencing") return true;
        if (
          presta.maintenance === null &&
          ouvrageSelected[0]?.maintenance === false
        ) {
          return true;
        }
        if (presta.maintenance === ouvrageSelected[0]?.maintenance) {
          return true;
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestations, from]);
  return (
    <>
      <Modal
        width={840}
        maskClosable={false}
        open={isModalPrestationVisible}
        footer={null}
        onCancel={handleCancelPrestation}
      >
        <h3>Information de la {vocabulary?.prestation_lowercase}</h3>
        <PrestationForm
          initialPrestation={showPrestation}
          // categories={categories}
          // unities={unities}
          // profils={profils}
          from={from}
          faIsValidate={faIsValidate}
          // majorations={getRelevantMajorations()}
          // defaultMajoration={getRelevantDefaultMajoration()}
          hasPrestationIncluded={
            showPrestation?.prestation_included !== null &&
            showPrestation?.prestation_included !== undefined
          }
          configuration={configuration}
          vocabulary={vocabulary}
          isShowing
        />
      </Modal>
      <StyledSearch
        allowClear
        placeholder="Rechercher par nom de prestation ou catégorie"
        value={wordEntered}
        onChange={handleFilter}
      />
      <TableContainer
        dataSource={tableRowsData}
        columns={colPresta}
        bordered={false}
        pagination={false}
      />
    </>
  );
}

const StyledSearch = styled(Search)`
  margin: 10px 0;
`;

const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ChildPrestationArray;
