import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDrag } from "react-dnd";
import ArrowCross from "../../../react-ui/Icons/ArrowCross";

function DragElement({ label, element, type, forbidKeys }) {
  const [forbidDrag, setForbidDrag] = useState(forbidKeys[element]);
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type,
      canDrag: !forbidDrag,
      item: { content: element, type },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [element, type, forbidDrag]
  );

  useEffect(() => {
    setForbidDrag(forbidKeys[element]);
  }, [element, forbidKeys]);

  return (
    <DragItem
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: forbidDrag ? "not-allowed" : "grab",
      }}
    >
      <DragHandle>
        <ArrowCross />
      </DragHandle>
      <p> {label[element]} </p>
      <DragHandle>
        <ArrowCross />
      </DragHandle>
    </DragItem>
  );
}

const DragItem = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  justify-content: center;
  background-color: rgba(217, 217, 217, 1);

  color: rgba(0, 0, 0, 1);
  cursor: move;
  cursor: grab;

  p {
    font-size: 12px !important;
    font-weight: 700;
    text-align: center;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const DragHandle = styled.div`
  color: #333; /* Couleur de l'icône */
  cursor: move; /* Curseur de déplacement */
`;

export default DragElement;
