import React, { useMemo } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { stringSorter } from "../../../../utils/stringSorter";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import AddButton from "../../../react-ui/AddButton";
import TitleContainer from "../../../react-ui/TitleContainer";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import Delete from "../../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../../confirmModal";
import { findRule } from "../../../constants/rules";
import { formatNumberString } from "../../../../utils/formatNumberString";

const columns = (handleDeleteRule) => [
  {
    title: "Règle sur",
    dataIndex: "rule_on",
    width: "25%",
    sorter: (a, b) => stringSorter(a.rule_on, b.rule_on),
    render: (rule_on) => findRule(rule_on).name,
  },
  {
    title: "Métier",
    dataIndex: "works",
    width: "25%",
    sorter: (a, b) => stringSorter(a.works, b.works),
  },
  {
    title: "Valeur définie",
    width: "15%",
    dataIndex: "value_to_compare",
    render: (value_to_compare, { rule_on }) =>
      value_to_compare
        ? `${formatNumberString({ str: value_to_compare, space: true })} ${
            findRule(rule_on)?.suffix
          }`
        : "",
  },
  {
    title: "Typologies",
    width: "30%",
    dataIndex: "site_typologies",
    render: (site_typologies) => site_typologies || "",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
    width: "5%",
    render: (record) => (
      <Delete
        onClick={() =>
          ShowDeleteConfirm(record.name, record.id, handleDeleteRule)
        }
      />
    ),
  },
];

const selector = (state) => ({
  formToken: state.formToken,
});

function RuleArray({
  ruleStatus,
  setRuleStatus,
  rules,
  works,
  companyWorks,
  selectedRule,
  setSelectedRule,
  siteTypologies,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const openCreate = () => {
    setRuleStatus("create");
    setSelectedRule();
  };

  const openUpdate = (record) => {
    setRuleStatus("update");
    setSelectedRule(rules.find((el) => el.id === record.id));
  };

  const { mutate: deleteRule } = useMutation(
    (todo) => postData(formToken, "/rule/delete", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Rules");
      },
    }
  );

  const dataSource = useMemo(() => {
    if (!rules) return [];
    return rules.map((r) => {
      const worksIds = companyWorks
        .filter((cw) => r.company_work_id === cw.id)
        .map((cw) => cw.work_id);
      return {
        ...r,
        works: works
          .filter((w) => worksIds?.includes(w.id))
          .map((w) => w.fullname)
          .join(", "),
        site_typologies: siteTypologies
          .filter((st) => r.site_typology_ids?.includes(st.id))
          .map((st) => st.typology)
          .join(", "),
      };
    });
  }, [rules, companyWorks, works, siteTypologies]);

  const handleDeleteRule = (id) => {
    deleteRule({ id });
    setRuleStatus();
    setSelectedRule();
  };

  return (
    <Container>
      <TitleContainer label="Règles disponibles">
        <AddButton
          label="Règle"
          onClick={openCreate}
          disabled={ruleStatus === "create"}
        />
      </TitleContainer>
      <TableContainer
        bordered={false}
        columns={columns(handleDeleteRule)}
        rowKey="id"
        dataSource={dataSource}
        pagination={false}
        isRowSelectable
        rowSelectableId={ruleStatus === "update" ? selectedRule?.id : undefined}
        onRow={(record) => {
          return {
            onClick: () => openUpdate(record),
          };
        }}
        scrollY
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export default RuleArray;
