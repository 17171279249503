import { select2 } from "select2";

const select = () => {
  $(document).ready(function () {
    $(".select2").select2();
  });
};

export { select };

//TABULATOR
import Tabulator from "tabulator-tables";
const tableusers = document.getElementById("users");

if (tableusers) {
  const usersJson = tableusers.getAttribute("data-info");
  const table = new Tabulator("#users", {
    selectable: 1,
    resizableColumns: true,
    layout: "fitColumns",
    rowClick: function (e, row) {
      // use the url generate by the route users#infos
      fetch("/admin/users/" + row._row.data.id, {
        headers: {
          Accept: "text/javascript",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then(function (response) {
          return response.text();
        })
        .then(
          function (body) {
            eval(body);
          }.bind(this)
        );
    },

    columns: [
      { title: "Prénom ", field: "first_name", sorter: "string" },
      { title: "Nom", field: "last_name", sorter: "string" },
      { title: "Agence", field: "entity", sorter: "string" },
      { title: "Rôle", field: "role", sorter: "string" },
      { title: "Actif", field: "active", sorter: "string" },
    ],
  });

  if (table) {
    table.setData(usersJson);
    window.addEventListener("resize", function () {
      //trigger full rerender including all data and rows
      table.redraw(true);
    });
  }
}

//the first row is select when we open the page
const usersTableBody = document.querySelector("#users .tabulator-table");

if (usersTableBody) {
  if (usersTableBody.firstChild !== null) {
    const addSelected = () => {
      usersTableBody.firstChild.classList.add("selected-row");
    };
    const removeSelected = () => {
      usersTableBody.firstChild.classList.remove("selected-row");
    };
    addSelected();
    usersTableBody.addEventListener("click", removeSelected);
  }
}

$(document).ready(function () {
  const changeTextMaxSelectForuser = (e) => {
    const text = "Supprimez l'entreprise avant de le modifier";
    return text;
  };

  // allow the search box in the new/edit user activity field
  const clientuser = $(".user_client_id");
  if (clientuser) {
    $(".dropdown-client-id")
      .change()
      .select2({
        disableSearch: false,
        maximumSelectionLength: 1,
        language: { maximumSelected: changeTextMaxSelectForuser },
      });
  }
});
