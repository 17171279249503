import React, { useState } from "react";
import Input from "../../../../../../react-ui/Input";
import {
  eurosToCents,
  centsToEuros,
} from "../../../../../../../utils/currencyConverter";
import { formatNumberString } from "../../../../../../../utils/formatNumberString";

function SellingPrice({ id_opportunity, record, update_function, disabled }) {
  const [unitary_selling_HT, setUnitary_selling_HT] = useState([]);
  return (
    <Input
      fontSize="10px"
      textAlign="right"
      size="small"
      suffix="€"
      id={record.id}
      disabled={disabled}
      value={
        unitary_selling_HT?.[record.id] != null
          ? unitary_selling_HT?.[record.id]
          : formatNumberString({
              str: centsToEuros(record.selling_price_cents),
              nbDecimal: 2,
              space: true,
            })
      }
      onBlur={async (e) => {
        if (unitary_selling_HT?.[record.id]) {
          await update_function[record.flag]({
            opportunity_id: id_opportunity,
            ouvrage_parent_id: record.ouvrage_parent_id,
            ids: record.id,
            id: record.id,
            selling_price_cents: eurosToCents(e.target.value),
          });
          setUnitary_selling_HT(null);
        }
      }}
      onChange={(e) =>
        setUnitary_selling_HT((v) => ({
          ...v,
          [e.target.id]: formatNumberString({
            str: e.target.value,
            nbDecimal: 2,
          }),
        }))
      }
      onFocus={(e) =>
        setUnitary_selling_HT((v) => ({
          ...v,
          [e.target.id]: formatNumberString({
            str: e.target.value,
            nbDecimal: 2,
          }),
        }))
      }
    />
  );
}

export default SellingPrice;
