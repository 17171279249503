import { Tree } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";
import { translate_data } from "../Categories/translate_data";
import { useStore } from "../../store";

const selector = (state) => ({
  selectedFamily: state.selectedFamily,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

function CategoryLinked({
  categories,
  familiesAssociates,
  handleFamilyChecked,
}) {
  const [antdArray, setAntdArray] = useState([]);
  const { selectedFamily, isUsingMargin, calculMethod } = useStore(selector);

  useEffect(() => {
    setAntdArray(
      translate_data({
        items: categories,
        isUsingMargin,
        calculMethod,
      })
    );
  }, [categories, isUsingMargin, calculMethod]);

  return (
    <StyledTree
      showLine={{ showLeafIcon: false }}
      showIcon
      switcherIcon={<DownOutlined />}
      defaultExpandAll
      treeData={antdArray}
      checkable
      checkStrictly
      checkedKeys={familiesAssociates
        ?.filter(
          (el) =>
            el.family_profession_id === selectedFamily?.id &&
            el.category_profession_id
        )
        ?.map((el) => el.category_profession_id)}
      onCheck={(_, { checked, node: { id } }) => {
        handleFamilyChecked(id, checked, "category");
      }}
    />
  );
}

const StyledTree = styled(Tree)`
  background-color: transparent;
  .ant-tree-switcher {
    background: transparent;
  }

  .ant-tree-node-content-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  .ant-tree-iconEle,
  .ant-tree-icon__customize {
    margin-left: 10px;
  }

  .ant-tree-list-holder-inner {
    height: 70vh;
    overflow-y: scroll;
  }
`;

export default CategoryLinked;
