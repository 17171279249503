import React, { useMemo } from "react";
import { Form } from "antd";
import FormCol from "../../../Form/FormCol";
import FormattedInput from "../../../react-ui/FormattedInput";
import { useStore } from "../../../store";

const selector = (state) => ({
  procurement: state.procurement,
  role: state.role,
});

function MaxSitesInput({
  isShowing,
  clients,
  fromModal,
  selectedClientPosition,
}) {
  const { procurement, role } = useStore(selector);
  const parent = Form.useWatch("parent");
  const parentId = parent?.value;

  const position = useMemo(() => {
    if (
      selectedClientPosition !== undefined &&
      typeof selectedClientPosition === "number"
    )
      return selectedClientPosition;
    const findPosition = (client, oldPosition = 1) => {
      if (!client.parent_id) return oldPosition;
      const newPosition = oldPosition + 1;
      return findPosition(
        clients.find((c) => c.id === client.parent_id),
        newPosition
      );
    };
    return parentId ? findPosition(clients.find((c) => c.id === parentId)) : 0;
  }, [clients, parentId, selectedClientPosition]);

  if (position !== 1 || !procurement || role !== "superadmin") return null;

  return (
    <FormCol
      label="Nombre de site max."
      name="max_juridic_entities"
      width={fromModal ? "50%" : "30%"}
      labelOnTop
      rules={[{ required: true }]}
    >
      <FormattedInput
        isNumber
        disabled={isShowing}
        textAlign="right"
        size="middle"
      />
    </FormCol>
  );
}

export default MaxSitesInput;
