import React, { useEffect } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { getData } from "../../../../request/instance";
import { useStore } from "../../../../store";
import LeaserSelect from "./LeaserSelect";
import CoefficientLeaser from "./CoefficientLeaser";
import PeriodLeaser from "./PeriodLeaser";
import Loader from "../../../../react-ui/Loader";

const selector = (state) => ({
  formToken: state.formToken,
});

function Leaser({ rentingOpp, selectedLeaser, setSelectedLeaser }) {
  const { formToken } = useStore(selector);

  const { data: rentalOffers, isLoading: rentalOffersLoading } = useQuery(
    ["RentalOffers", rentingOpp.id],
    () =>
      getData(
        formToken,
        `/rental_offers?renting_opportunity_id=${rentingOpp.id}`
      )
  );

  useEffect(() => {
    if (rentalOffers)
      setSelectedLeaser(
        rentalOffers.find(
          (el) => el.id === rentingOpp.rental_offer_opportunity.rental_offer_id
        )
      );
  }, [rentalOffers, rentingOpp, setSelectedLeaser]);

  if (rentalOffersLoading || !selectedLeaser) return <Loader />;

  return (
    <Container>
      <Wrapper width="42%">
        <LeaserSelect
          selectedLeaserId={selectedLeaser.id}
          rentalOffers={rentalOffers}
          setSelectedLeaser={setSelectedLeaser}
          rentingOpp={rentingOpp}
        />
      </Wrapper>
      <Wrapper width="32%">
        <PeriodLeaser
          period={rentingOpp.period}
          rentingOppId={rentingOpp.id}
          periods={selectedLeaser.periods.map((el) => ({ id: el, name: el }))}
        />
      </Wrapper>
      <Wrapper width="22%">
        <CoefficientLeaser
          rentingOppId={rentingOpp.id}
          coeff={
            selectedLeaser.use_margin
              ? rentingOpp.coefficient * 100
              : rentingOpp.coefficient
          }
          useMargin={selectedLeaser.use_margin}
        />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  gap: 8px;
`;

export default Leaser;
