import React, { useMemo } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { stringSorter } from "../../../../utils/stringSorter";
import TableContainer from "../../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import AddButton from "../../../react-ui/AddButton";
import TitleContainer from "../../../react-ui/TitleContainer";
import { postData } from "../../../request/instance";
import { useStore } from "../../../store";
import Delete from "../../../react-ui/Icons/Delete";
import { ShowDeleteConfirm } from "../../confirmModal";
import { translate_filter } from "../../Categories/translate_data";

const columns = ({ companyWorks, works, handleDelete }) => [
  {
    title: "Nom",
    dataIndex: "name",
    sorter: (a, b) => stringSorter(a.name, b.name),
  },
  {
    title: "Métiers",
    dataIndex: "works",
    filterMode: "tree",
    filters: [
      {
        value: "global",
        text: "Tous les métiers",
      },
    ].concat(translate_filter(works, null, null, "fullname")),
    filterSearch: true,
    onFilter: (value, record) => {
      const docCwIds = record.sa_template_company_works.map(
        (el) => el.company_work_id
      );
      if (value === "global") return docCwIds.length === 0;
      return (
        docCwIds &&
        docCwIds.includes(
          parseInt(
            companyWorks.find((el) => el.work_id === parseInt(value, 10)).id,
            10
          )
        )
      );
    },
    render: (worksStr) => worksStr || "",
  },
  {
    title: "",
    dataIndex: "",
    align: "center",
    width: "5%",
    render: (record) => (
      <Delete
        onClick={(e) => {
          e.stopPropagation();
          ShowDeleteConfirm(record.name, record.id, handleDelete);
        }}
      />
    ),
  },
];

const selector = (state) => ({
  formToken: state.formToken,
});

function TemplateArray({
  status,
  setStatus,
  templates,
  works,
  companyWorks,
  selectedTemplate,
  setSelectedTemplate,
  closeDetails,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const openCreate = () => {
    setStatus("create");
    setSelectedTemplate();
  };

  const openUpdate = (record) => {
    setStatus("update");
    setSelectedTemplate(templates.find((el) => el.id === record.id));
  };

  const { mutate: deleteTemplate } = useMutation(
    (todo) => postData(formToken, "/sa_template/delete", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("Templates");
      },
    }
  );

  const dataSource = useMemo(() => {
    if (!templates) return [];
    return templates.map((t) => {
      const templCwIds = t.sa_template_company_works.map(
        (el) => el.company_work_id
      );
      const templWorks =
        templCwIds.length > 0
          ? works
              .filter((work) =>
                templCwIds?.includes(
                  companyWorks.find((cw) => cw.work_id === work.id).id
                )
              )
              .map((work) => work.fullname)
              .join(", ")
          : "Tous les métiers";
      return { ...t, works: templWorks };
    });
  }, [companyWorks, templates, works]);

  const handleDelete = (id) => {
    deleteTemplate({ id });
    closeDetails();
  };

  return (
    <Container>
      <TitleContainer label="Modèles disponibles">
        <AddButton
          label="Modèle"
          onClick={openCreate}
          disabled={status === "create"}
        />
      </TitleContainer>
      <TableContainer
        bordered={false}
        columns={columns({ works, companyWorks, handleDelete })}
        rowKey="id"
        dataSource={dataSource}
        pagination={false}
        isRowSelectable
        rowSelectableId={status === "update" ? selectedTemplate?.id : undefined}
        onRow={(record) => {
          return {
            onClick: () => openUpdate(record),
          };
        }}
        scrollY
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export default TemplateArray;
