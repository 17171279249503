export const getFrom = ({ from, faIsValidate, opportunityId }) => {
  if (
    from === "referencing" ||
    (from === "frameworkAgreement" && opportunityId && !faIsValidate)
  )
    return "referencing";
  if (from === "frameworkAgreement") return "frameworkAgreement";
  return "admin";
};

export const getFetchUrlIdAndFrom = ({
  from,
  opportunityId,
  faIsValidate,
  faId,
  entityWorkId,
}) => {
  if (
    from === "referencing" ||
    (from === "frameworkAgreement" && opportunityId && !faIsValidate)
  )
    return { from: "referencing", id: opportunityId };
  if (from === "frameworkAgreement")
    return { from: "frameworkAgreement", id: faId };
  return { from: "admin", id: entityWorkId };
};
