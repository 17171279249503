import React from "react";
import { useQuery } from "react-query";
import { marginToCoeff } from "../../utils/marginConverter";
import { formatNumberString } from "../../utils/formatNumberString";
import { useStore } from "../store";
import { getData } from "../request/instance";

const selector = (state) => ({
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
});

function TvaSelector({
  tvaProfessionId,
  hasTVA,
  categoryId,
  entityWorkId,
  opportunityId,
  itemType,
}) {
  const { formToken } = useStore(selector);
  const { data } = useQuery(
    [
      "TvaSelector",
      {
        tvaProfessionId,
        categoryId,
        entityWorkId,
        opportunityId,
        itemType,
      },
    ],
    () =>
      getData(
        formToken,
        `/${itemType}/used_tva_rate?${
          opportunityId
            ? `opportunity_id=${opportunityId}`
            : `entity_work_id=${entityWorkId}`
        }${categoryId ? `&category_profession_id=${categoryId}` : ""}${
          tvaProfessionId ? `&tva_profession_id=${tvaProfessionId}` : ""
        }`
      ),
    { enabled: (!!opportunityId || !!entityWorkId) && !hasTVA }
  );

  if (hasTVA || data === null || data === undefined || data.tva_name === null) {
    return null;
  }

  return <>Valeur par défaut: {data.tva_name}</>;
}

export default TvaSelector;
