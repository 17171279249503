import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { centsToEuros } from "../../../../utils/currencyConverter";
import { formatNumberString } from "../../../../utils/formatNumberString";
import NumberCell from "../../../react-ui/NumberCell";
import TableContainer from "../../CostingMovementsCarrycots/TableContainer";

const totalColumns = (periodType) => [
  {
    title: "Prix de vente total",
    dataIndex: "total_selling_price",
    width: "33%",
    render: (value) => (
      <NumberCell>
        {value !== null && value !== undefined
          ? `${formatNumberString({ str: centsToEuros(value), space: true })} €`
          : ""}
      </NumberCell>
    ),
  },
  {
    title: "Coût du leasing",
    dataIndex: "leasing_cost",
    width: "33%",
    render: (value) => (
      <NumberCell>
        {value !== null && value !== undefined
          ? `${formatNumberString({ str: centsToEuros(value), space: true })} €`
          : ""}
      </NumberCell>
    ),
  },
  {
    title: `Loyer ${periodType === "Mensuel" ? "mensuel" : "trimestiel"}`,
    dataIndex: "rent_selling_price",
    width: "33%",
    render: (value) => (
      <NumberCell>
        {value !== null && value !== undefined
          ? `${formatNumberString({ str: centsToEuros(value), space: true })} €`
          : ""}
      </NumberCell>
    ),
  },
];

function Total({ oppQuoLc, period, periodType = "Mensuel" }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (oppQuoLc && period) {
      setData([
        {
          ...oppQuoLc,
          key: "1",
          total_selling_price:
            oppQuoLc.maintenance_total_selling_price +
            oppQuoLc.new_work_selling_price,
          leasing_cost:
            oppQuoLc.new_work_total_selling_price -
            oppQuoLc.new_work_selling_price,
          rent_selling_price: oppQuoLc.selling_price_after_delivery / period,
        },
      ]);
    }
  }, [oppQuoLc, period]);
  return (
    <Container>
      <h4>TOTAL</h4>
      <TableContainer
        columns={totalColumns(periodType)}
        data={data}
        pagination={false}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 20px;
`;

export default Total;
