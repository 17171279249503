import React from "react";
import styled from "styled-components";
import FormCol from "../../../Form/FormCol";
import FormRow from "../../../Form/FormRow";
import { opportunityField } from "../../../constant";
import Label from "../../../Form/Label";
import Select from "../../../react-ui/Select";

function OpportunityTypeCosting({
  opportunity,
  multipleUserWorks,
  works,
  required,
}) {
  return (
    <>
      {opportunity && (
        <FormRow
          justify="space-between"
          visibility={multipleUserWorks()}
          height={multipleUserWorks() === "collapse" ? "0px" : "50px"}
          marginTop={multipleUserWorks() === "collapse" ? "0px" : "0px"}
          rules={[{ required }]}
        >
          {opportunity[opportunityField.workId] && (
            <div style={{ marginTop: 35 }}>
              <Label label="Type de chiffrage : " />
              {
                works.find(
                  (el) => el.id === opportunity[opportunityField.workId]
                ).name
              }
            </div>
          )}
        </FormRow>
      )}
      {!opportunity && (
        <FormRow
          visibility={multipleUserWorks()}
          height={multipleUserWorks() === "collapse" ? "0px" : "100px"}
          marginTop={multipleUserWorks() === "collapse" ? "0px" : "16px"}
        >
          <FormCol
            width="100%"
            label="Type de chiffrage"
            rules={[{ required: true }]}
            name={opportunityField.workId}
            labelOnTop
          >
            <StyledSelect options={works} placeholder="Sélectionnez un type" />
          </FormCol>
        </FormRow>
      )}
    </>
  );
}
const StyledSelect = styled(Select)`
  width: 100%;
`;
export default OpportunityTypeCosting;
