import { encodeUrlParams } from "./encodeUrlParams";

export const fetchDefaultMarginrate = ({
  itemType,
  opportunityId,
  entityWorkId,
  categoryId,
  profilId,
}) => {
  let url = `/${itemType}/used_margin_rate?`;
  const params = {
    ...(profilId && { profil_profession_id: profilId }),
    ...(opportunityId
      ? { opportunity_id: opportunityId }
      : { entity_work_id: entityWorkId }),
    ...(categoryId && { category_profession_id: categoryId }),
  };

  url += encodeUrlParams(params);

  return url;
};
