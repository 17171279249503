import React, { useCallback } from "react";
import styled, { css } from "styled-components";
import { Card } from "antd";
import { useQuery, useQueryClient } from "react-query";
import AgentInformations from "./AgentInformations";
import AgentTitle from "./AgentTitle";
import { getData } from "../../request/instance";
import { useStore } from "../../store";
import AssociationsInformations from "./AssociationsInformations";
import { groupArrayByKey } from "../../../utils/groupArrayByKey";
import Loader from "../../react-ui/Loader";

const selector = (state) => ({
  formToken: state.formToken,
});

const AgentPanel = React.memo(
  ({
    wpId,
    opportunityId,
    qualifications,
    contractTypes,
    updateAgentMutation,
    frequencies,
    updateWbMutation,
    deleteWbMutation,
    updateIndMutation,
    deleteIndMutation,
    setAgentToSplit,
    deleteSplitAgentMutation,
    posteName,
    hasDecreased,
    wpHasDecreased,
    agentsIdsToKeep,
    setAgentsIdsToKeep,
    futureEtp,
    configApplication,
    coeffToMonthly,
    handleRunJoyride,
    isWpForJoyride,
    isLockedByUser,
  }) => {
    const queryClient = useQueryClient();
    const { formToken } = useStore(selector);
    const { data: agents, isLoading } = useQuery(
      ["Agents", { wpId }],
      async () => {
        const data = await getData(
          formToken,
          `agents_from_opp?opportunity_id=${opportunityId}&work_position_id=${wpId}`
        );
        const agentsWithoutParent = data
          .filter((el) => !el.parent_id)
          .map((agent, idx) => ({
            ...agent,
            agentBaseNumber: idx + 1,
            agentNumber: `${idx + 1}${!agent.splitted ? "" : "-1"}`,
          }));
        const groupedAgentsWithParent = groupArrayByKey({
          arr: data.filter((el) => el.parent_id),
          keyId: "parent_id",
        });
        let agentsToSort = agentsWithoutParent;
        Object.entries(groupedAgentsWithParent).forEach(([key, value]) => {
          const parentNumber = agentsWithoutParent.find(
            (el) => parseInt(el.id, 10) === parseInt(key, 10)
          ).agentBaseNumber;
          agentsToSort = agentsToSort.concat(
            value.map((agent, idx) => ({
              ...agent,
              agentBaseNumber: parentNumber,
              agentNumber: `${parentNumber}-${idx + 2}`,
            }))
          );
        });
        agentsToSort.sort((a, b) => {
          if (a.splitted && !b.splitted) return 1;
          if (!a.splitted && b.splitted) return -1;
          if (a.partime) {
            if (b.partime) {
              if (b.agentBaseNumber > a.agentBaseNumber) return -1;
              if (b.agentBaseNumber === a.agentBaseNumber) {
                return b.agentNumber > a.agentNumber ? -1 : 1;
              }
            }
            return 1;
          }
          if (b.partime || b.agentBaseNumber >= a.agentBaseNumber) {
            return -1;
          }
          return 1;
        });
        return agentsToSort;
      },
      {
        onSuccess: () => isWpForJoyride && handleRunJoyride(wpId),
        refetchOnWindowFocus: false,
      }
    );

    const { data: workBonuses } = useQuery(
      ["WorkBonuses", { wpId }],
      () =>
        getData(
          formToken,
          `/work_bonuses?opportunity_id=${opportunityId}&work_position_id=${wpId}`
        ),
      {
        refetchOnWindowFocus: false,
      }
    );

    const { data: indemnities } = useQuery(
      ["Indemnities", { wpId }],
      () =>
        getData(
          formToken,
          `/indemnities?opportunity_id=${opportunityId}&work_position_id=${wpId}`
        ),
      {
        refetchOnWindowFocus: false,
      }
    );

    const updateAgent = useCallback(
      ({ id, ...fields }) => {
        if (hasDecreased) return;
        updateAgentMutation(
          {
            id,
            opportunity_id: opportunityId,
            agent: {
              ...fields,
            },
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(["Agents", { wpId }]);
            },
          }
        );
      },
      [updateAgentMutation, opportunityId, wpId, queryClient, hasDecreased]
    );

    const setAgentToSplitCallback = useCallback(
      (id, agentName) => {
        setAgentToSplit({
          ...agents.find((agent) => agent.id === id),
          posteName,
          agentName,
          wpId,
        });
      },
      [setAgentToSplit, agents, posteName, wpId]
    );

    if (!agents && isLoading)
      return (
        <SpinContainer>
          <Loader />
        </SpinContainer>
      );

    if (!agents) return null;

    return agents.map(
      (
        {
          partime,
          partime_percent,
          contract_type,
          qualification,
          coefficient,
          collective_agreement_hourly_rate,
          hourly_price,
          vacation_time,
          health_mutual_cents,
          entry_date,
          calculated_seniority,
          forced_seniority,
          parent_id,
          details,
          agentNumber,
          coefficients,
          splitted,
          id,
          periodicity,
        },
        idx
      ) => (
        <StyledCard
          title={
            <AgentTitle
              wpId={wpId}
              partime={partime}
              partime_percent={partime_percent}
              parent_id={parent_id}
              setAgentToSplit={setAgentToSplitCallback}
              agentId={id}
              deleteSplitAgentMutation={deleteSplitAgentMutation}
              opportunityId={opportunityId}
              agentNumber={agentNumber}
              updateAgent={updateAgent}
              details={details}
              hasDecreased={hasDecreased}
              wpHasDecreased={wpHasDecreased}
              splitted={splitted}
              parentId={parent_id}
              agentsIdsToKeep={agentsIdsToKeep}
              setAgentsIdsToKeep={setAgentsIdsToKeep}
              futureEtp={futureEtp}
              configApplication={configApplication}
              isAgentForJoyride={isWpForJoyride && idx === 0}
              isLockedByUser={isLockedByUser}
            />
          }
          $odd={idx % 2 > 0}
          key={id}
          bordered={false}
        >
          <CardContainer>
            <AgentInformations
              coefficients={coefficients}
              qualifications={qualifications}
              contractTypes={contractTypes}
              updateAgent={updateAgent}
              contract_type={contract_type}
              qualification={qualification}
              coefficient={coefficient}
              collective_agreement_hourly_rate={
                collective_agreement_hourly_rate
              }
              hourly_price={hourly_price}
              vacation_time={vacation_time}
              health_mutual_cents={health_mutual_cents}
              entry_date={entry_date}
              calculated_seniority={calculated_seniority}
              forced_seniority={forced_seniority}
              id={id}
              hasDecreased={hasDecreased}
              configApplication={configApplication}
              periodicity={periodicity}
              coeffToMonthly={coeffToMonthly}
              isLockedByUser={isLockedByUser}
              hasForcedSeniority={
                forced_seniority !== "" &&
                forced_seniority !== null &&
                forced_seniority !== undefined
              }
            />
            <AssociationsInformations
              agentId={id}
              wpId={wpId}
              opportunityId={opportunityId}
              workBonuses={workBonuses}
              frequencies={frequencies}
              updateWbMutation={updateWbMutation}
              deleteWbMutation={deleteWbMutation}
              updateIndMutation={updateIndMutation}
              deleteIndMutation={deleteIndMutation}
              indemnities={indemnities}
              odd={idx % 2 > 0}
              hasDecreased={hasDecreased}
              configApplication={configApplication}
              isLockedByUser={isLockedByUser}
            />
          </CardContainer>
        </StyledCard>
      )
    );
  }
);

const CardContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const SpinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

const StyledCard = styled(Card)`
  ${({ $odd }) =>
    $odd &&
    css`
      background-color: ${({ theme }) => theme.colors.blue01};
    `}
  .ant-card-head {
    padding: 0 !important;
  }
  .ant-card-head-title {
    padding: 5px !important;
    font-size: 14px !important;
  }
  .ant-card-body {
    padding: 5px !important;
  }
`;

export default AgentPanel;
