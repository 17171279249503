import { Form, Modal, Switch } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ConcernedAgency from "../../../Chiffrage/OpportunityDetails/OpportunityForm/ConcernedAgency";
import { opportunityField, validateMessages } from "../../../constant";
import DurationCol from "../../../Chiffrage/OpportunityDetails/DurationCol";
import FormRow from "../../../Form/FormRow";
import { formatDates } from "../../../../utils/formatDates";
import { getEntitiesByWork } from "../../../../utils/getEntitiesByWork";
import { postData } from "../../../request/instance";

const { confirm } = Modal;

function GuardingInfos({
  record,
  entities,
  userEntityId,
  token,
  refetchOffer,
}) {
  const [checked, setChecked] = useState(false);
  const [form] = Form.useForm();
  const startDate = Form.useWatch(opportunityField.startDate, form);
  const endDate = Form.useWatch(opportunityField.endDate, form);

  const { mutate: duplicate_opportunity } = useMutation(
    (data) =>
      postData(
        token,
        `/opportunities/${data.opportunity_id}/duplicate`,
        data.to_send
      ),
    { onSuccess: () => refetchOffer() }
  );

  const initialValues = useMemo(
    () =>
      record
        ? {
            ...record,
            ...formatDates({
              values: record,
              dateFields: [
                opportunityField.startDate,
                opportunityField.endDate,
              ],
            }),
          }
        : {},
    [record]
  );

  const handleSubmit = (values) => {
    const toSend = checked
      ? {
          opportunity: {
            entity_id: values[opportunityField.entityId],
            ...formatDates({
              values,
              convertToString: true,
              dateFields: [
                opportunityField.startDate,
                opportunityField.endDate,
              ],
            }),
          },
        }
      : {};
    duplicate_opportunity(
      {
        opportunity_id: record.id,
        to_send: {
          ...toSend,
          check_important_field_changement: true,
        },
      },
      {
        onSuccess: ({ work_positions }) => {
          if (work_positions) {
            const wpStr = work_positions.map(({ title }) => title).join(" - ");
            confirm({
              title: `Les dispositifs suivants ne seront pas présents dans la copie de l'opportunité car la nouvelle agence ne possède pas leur profil :`,
              content: wpStr,
              icon: <ExclamationCircleOutlined />,
              okText: "Continuer",
              okType: "danger",
              cancelText: "Annuler",
              maskClosable: false,
              onOk() {
                duplicate_opportunity({
                  opportunity_id: record.id,
                  to_send: {
                    ...toSend,
                  },
                });
              },
            });
          }
        },
      }
    );
  };

  const entitiesInWork = useMemo(() => {
    const id = record[opportunityField.workId];
    const parentEntity = entities.find((entity) => entity.id === userEntityId);
    const entitiesByWork = getEntitiesByWork({
      entities,
      parentEntity,
      workId: id,
    });
    return entitiesByWork?.map((el) => {
      const disabled = !el.works.some((w) => w.id === id);
      return {
        id: el.id,
        pId: el.parent_id,
        title: el.name,
        value: el.id,
        disabled,
      };
    });
  }, [entities, userEntityId, record]);

  const isGuarding = useCallback(() => {
    return record.work_name === "guarding";
  }, [record.work_name]);

  return (
    <Container>
      <Wrapper>
        <span>Changer d'agence et/ou la période du contrat ?</span>
        <Switch
          checkedChildren="Oui"
          unCheckedChildren="Non"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
      </Wrapper>

      <Form
        id="duplicate-opportunity-form"
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
        colon={false}
        requiredMark={false}
        validateMessages={validateMessages}
      >
        {checked && (
          <>
            <FormRow>
              <ConcernedAgency entitiesInWork={entitiesInWork} required />
            </FormRow>
            <FormRow>
              <DurationCol
                isGuarding={isGuarding}
                onlyStartDate={false}
                startDate={startDate}
                endDate={endDate}
                works={[{ name: "guarding" }]}
                work_id="guarding"
                required
              />
            </FormRow>
          </>
        )}
      </Form>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  padding-bottom: 10px;
`;

export default GuardingInfos;
