import { encodeUrlParams } from "./encodeUrlParams";

export const fetchDocumentsUrl = ({
  search,
  opportunity_id,
  offer_id,
  simplified_application,
  only_opportunity,
  folder_id,
  search_from_sa,
  sa_template_id,
  search_from_template,
  opportunity_ids,
}) => {
  let url = "/documents?";
  const params = {
    ...(search && { search }),
    ...(opportunity_id && { opportunity_id }),
    ...(only_opportunity && { only_opportunity }),
    ...(offer_id && { offer_id }),
    ...(folder_id && { folder_id }),
    ...(sa_template_id && { sa_template_id }),
    ...(search_from_sa && { search_from_sa: true }),
    ...(search_from_template && { search_from_template: true }),
    ...(simplified_application && { simplified_application: true }),
  };

  url += encodeUrlParams(params);
  if (opportunity_ids?.length > 0) {
    let query = "";
    opportunity_ids?.forEach((el) => {
      query += `&opportunity_ids[]=${el}`;
    });
    url += query;
  }
  return url;
};
