import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['previous', 'next', 'save', 'new']
  
  disabled_breadcrumb(){
    let breadcrumbs = document.getElementsByClassName('breadcrumb')
    let breadcrumbs_separators = document.getElementsByClassName('breadcrumb-separator')
    let breadcrumbs_selected = document.getElementsByClassName('breadcrumb-selected')
    let breadcrumbs_clicked = document.getElementsByClassName('breadcrumb-clicked')
    let breadcrumbs_separators_clicked = document.getElementsByClassName('breadcrumb-separator-clicked')
    let breadcrumbs_selected_clicked = document.getElementsByClassName('breadcrumb-selected-clicked')
    breadcrumbs.forEach (breadcrumb => breadcrumb.hidden=true)
    breadcrumbs_separators.forEach (breadcrumb_separator => breadcrumb_separator.hidden=true)
    breadcrumbs_selected.forEach (breadcrumb_selected => breadcrumb_selected.hidden=true)
    breadcrumbs_clicked.forEach (breadcrumb_clicked => breadcrumb_clicked.hidden=false)
    breadcrumbs_separators_clicked.forEach (breadcrumb_separator_clicked => breadcrumb_separator_clicked.hidden=false)
    breadcrumbs_selected_clicked.forEach (breadcrumb_selected_clicked => breadcrumb_selected_clicked.hidden=false)
  }

  disabled_buttons_next() {
    let button_edit = document.getElementById('button_edit_material')
    let button_delete = document.getElementById('button_delete_material')  
    let button_next = this.nextTarget;
    let button_save = document.getElementById('update_all_materials_btn')
    let button_new = document.getElementById('button_material_new')
    // this.disabled_breadcrumb()
    button_next.disabled = true
    button_save.hidden = true
    button_new.hidden = true
    if (button_edit !== null) {
      button_edit.hidden = true
      button_delete.hidden = true
    }
  }

  disabled_buttons_previous() {
    let button_edit = document.getElementById('button_edit_material')
    let button_delete = document.getElementById('button_delete_material')
    let button_previous = this.previousTarget;
    let button_save = document.getElementById('update_all_materials_btn')
    let button_new = document.getElementById('button_material_new')
    // this.disabled_breadcrumb()
    button_previous.disabled = true
    button_save.hidden = true
    button_new.hidden = true
    if (button_edit !== null) {
      button_edit.hidden = true
      button_delete.hidden = true
    }
  }

  disabled_buttons_save() {
    let button_edit = document.getElementById('button_edit_material')
    let button_delete = document.getElementById('button_delete_material')
    let button_previous = this.previousTarget;
    let button_next = this.nextTarget;
    let button_new = document.getElementById('button_material_new')
    // this.disabled_breadcrumb()
    button_previous.disabled = true
    button_next.disabled = true
    button_new.hidden = true
    if (button_edit !== null) {
      button_edit.hidden = true
      button_delete.hidden = true
    }
  }
  disabled_buttons(){
    let button_edit = document.getElementById('button_edit_material')
    let button_delete = document.getElementById('button_delete_material')
    let button_previous = this.previousTarget;
    let button_next = this.nextTarget;
    let button_new = document.getElementById('button_material_new')
    let button_save = document.getElementById('update_all_materials_btn')
    // this.disabled_breadcrumb()
    button_previous.disabled = true
    button_next.disabled = true
    button_new.hidden = true
    button_save.hidden = true
    if (button_edit !== null) {
      button_edit.hidden = true
      button_delete.hidden = true
    }
  }
}