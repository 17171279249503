import React, { useCallback, useMemo, useState } from "react";
import { array, string } from "prop-types";
import { useQuery } from "react-query";
import styled from "styled-components";
import {
  carrycotAndProfilOppColumns,
  prestationOppColumns,
  materialOppColumns,
  prestationOuvrageColumns,
} from "../../../constant";
import Separator from "../../../react-ui/Separator";
import TitleContainer from "../../../react-ui/TitleContainer";
import { getData } from "../../../request/instance";
import TableContainer from "../TableContainer";
import ListHeader from "./ListHeader";
import {
  groupArrayByKey,
  groupByMultipleKeys,
} from "../../../../utils/groupArrayByKey";
import Collapse from "../../../react-ui/Collapse";
import Panel from "../../../react-ui/Panel";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { quantityTimeConverter } from "../../../../utils/quantityTimeConverter";
import { useStore } from "../../../store";
import { marginToCoeff } from "../../../../utils/marginConverter";
import Option from "../../../react-ui/Icons/Option";

const selector = (state) => ({
  isUsingMinutes: state.isUsingMinutes,
  isUsingMargin: state.isUsingMargin,
});
const extendedTable = (record) => {
  const dataPrestations =
    record.prestations
      ?.filter((el) => el.quantity > 0)
      .map((prestation) => ({
        key: prestation.id,
        category: prestation.category_profession.title,
        name: prestation.name,
        majoration: prestation.majoration_opportunity?.name,
        quantity: `${formatNumberString({
          str: prestation.quantity,
          nbDecimal: 2,
          space: true,
        })} h`,
      })) || [];

  return (
    <TableContainer columns={prestationOppColumns} data={dataPrestations} />
  );
};

const tableFactory = ({
  materials,
  carrycots,
  internalProfils,
  subcontractingProfils,
  packagePrestations,
  packageSubPrestations,
  prestationsOuvrage,
  showMaterial,
  isUsingMinutes,
  isUsingMargin,
}) => {
  const timeUnity = isUsingMinutes ? "min" : "h";

  const dataMaterials =
    materials
      ?.filter((el) => el.quantity > 0)
      .map((material) => ({
        key: material.id,
        name: material.name,
        category: material.category_profession.title,
        quantity: `${formatNumberString({
          str: material.quantity,
          nbDecimal: 2,
          space: true,
        })} ${material.unity_profession?.abbreviation || "u"}`,
      })) || [];

  const dataInternalProfils =
    internalProfils
      ?.filter((el) => el.quantity > 0)
      .map((internalProfil) => ({
        key: internalProfil.id,
        name: internalProfil.name,
        quantity: `${formatNumberString({
          str: internalProfil.quantity,
          nbDecimal: 2,
          space: true,
        })} h`,
        prestations: internalProfil.prestations,
      })) || [];

  const dataSubcontractingProfils =
    subcontractingProfils
      ?.filter((el) => el.quantity > 0)
      .map((subcontractingProfil) => ({
        key: subcontractingProfil.id,
        name: subcontractingProfil.name,
        quantity: `${formatNumberString({
          str: subcontractingProfil.quantity,
          nbDecimal: 2,
          space: true,
        })} h`,
        prestations: subcontractingProfil.prestations,
      })) || [];

  const dataCarrycots =
    carrycots
      ?.filter((el) => el.quantity > 0)
      .map((carrycot) => ({
        key: carrycot.id,
        name: carrycot.name,
        quantity: `${formatNumberString({
          str: carrycot.quantity,
          nbDecimal: 2,
          space: true,
        })} h`,
        prestations: carrycot.prestations,
      })) || [];

  const dataPackagePresta =
    packagePrestations
      ?.filter((el) => el.quantity > 0)
      .map((presta) => ({
        key: presta.id,
        name: presta.name,
        category: presta.category_profession.title,
        quantity: `${formatNumberString({
          str: presta.quantity,
          nbDecimal: 2,
          space: true,
        })} u`,
      })) || [];

  const dataPackageSubPresta =
    packageSubPrestations
      ?.filter((el) => el.quantity > 0)
      .map((presta) => ({
        key: presta.id,
        name: presta.name,
        category: presta.category_profession.title,
        quantity: `${formatNumberString({
          str: presta.quantity,
          nbDecimal: 2,
          space: true,
        })} u`,
      })) || [];

  const dataPrestationsOuvrage = prestationsOuvrage?.map((prestation) => ({
    key: prestation.id,
    name: prestation.name,
    category: prestation.category_profession.title,
    margin_rate: `${formatNumberString({
      str: marginToCoeff({
        marginRate: prestation.margin_rate,
        isUsingMargin,
      }),
      nbDecimal: 2,
      space: true,
    })} ${isUsingMargin ? "%" : ""}`,
    quantity: `${formatNumberString({
      str: quantityTimeConverter({
        quantity: prestation.unity_quantity,
        isUsingMinutes,
      }),
      nbDecimal: 2,
      space: true,
    })} ${timeUnity}`,
  }));

  return (
    <>
      {dataInternalProfils.length > 0 && (
        <TableContainer
          header={{ title: "Profils internes" }}
          columns={carrycotAndProfilOppColumns}
          data={dataInternalProfils}
          expandedRowRender={extendedTable}
        />
      )}
      {dataSubcontractingProfils.length > 0 && (
        <TableContainer
          header={{ title: "Profils sous-traitance" }}
          columns={carrycotAndProfilOppColumns}
          data={dataSubcontractingProfils}
          expandedRowRender={extendedTable}
        />
      )}
      {dataPackagePresta.length > 0 && (
        <TableContainer
          header={{ title: "Prestations forfait internes" }}
          columns={materialOppColumns}
          data={dataPackagePresta}
        />
      )}
      {dataPackageSubPresta.length > 0 && (
        <TableContainer
          header={{ title: "Prestations forfait sous-traitance" }}
          columns={materialOppColumns}
          data={dataPackageSubPresta}
        />
      )}
      {dataCarrycots.length > 0 && (
        <TableContainer
          header={{ title: "Nacelles" }}
          columns={carrycotAndProfilOppColumns}
          data={dataCarrycots}
          expandedRowRender={extendedTable}
        />
      )}
      {showMaterial && (
        <TableContainer
          header={{ title: "Matériels" }}
          columns={materialOppColumns}
          data={dataMaterials}
        />
      )}
    </>
  );
};

function ListSection({ formToken, zones, id_opportunity, from }) {
  const { isUsingMinutes, isUsingMargin } = useStore(selector);
  const [showAll, setShowAll] = useState(false);
  const [showMaterial, setShowMaterial] = useState(false);
  const [searchedWord, setSearchedWord] = useState("");
  const hasOption = zones.some((zone) => zone.option);
  const { data: materials } = useQuery(
    "MaterialOpportunities",
    () =>
      getData(
        formToken,
        `/material_opportunity?opportunity_id=${id_opportunity}&all_materials=true`
      ),
    {
      refetchOnWindowFocus: false,
      enabled: from !== "configuration",
    }
  );

  const { data: prestations } = useQuery(
    "PrestationOpportunities",
    () => getData(formToken, `/prestation_opportunity/${id_opportunity}`),
    {
      refetchOnWindowFocus: false,
      enabled: from !== "configuration",
    }
  );

  const { data: prestation_ouvrage } = useQuery(
    "PrestationOuvrage",
    () =>
      getData(formToken, `/prestation_opportunity/ouvrage/${id_opportunity}`),
    {
      refetchOnWindowFocus: false,
      enabled: from !== "configuration",
    }
  );

  const carrycots = useMemo(() => {
    return prestations
      ?.filter((presta) => presta.carrycot_tpl_opportunity_id !== null)
      .map((presta) => {
        const {
          carrycot_tpl_opportunity_id,
          carrycot_tpl_opportunity: { name },
          hour_quantity,
          zone_id,
          ...prestaProps
        } = presta;
        return {
          id: carrycot_tpl_opportunity_id,
          name,
          quantity: hour_quantity,
          zone_id,
          prestations: { ...prestaProps, hour_quantity },
        };
      });
  }, [prestations]);

  const internalProfils = useMemo(() => {
    return prestations
      ?.filter(
        (presta) =>
          presta.profil_opportunity_id !== null && !presta.subcontracting
      )
      .map((presta) => {
        const {
          profil_opportunity_id,
          profil_opportunity: { name },
          hour_quantity,
          zone_id,
          ...prestaProps
        } = presta;
        return {
          id: profil_opportunity_id,
          name,
          quantity: hour_quantity,
          zone_id,
          prestations: { ...prestaProps, hour_quantity },
        };
      });
  }, [prestations]);

  const subcontractingProfils = useMemo(() => {
    return prestations
      ?.filter(
        (presta) =>
          presta.profil_opportunity_id !== null && presta.subcontracting
      )
      .map((presta) => {
        const {
          profil_opportunity_id,
          profil_opportunity: { name },
          hour_quantity,
          zone_id,
          ...prestaProps
        } = presta;
        return {
          id: profil_opportunity_id,
          name,
          quantity: hour_quantity,
          zone_id,
          prestations: { ...prestaProps, hour_quantity },
        };
      });
  }, [prestations]);

  const packagePrestations = useMemo(
    () =>
      prestations
        ?.filter(
          (el) => el.unity_quantity > 0 && el.is_package && !el.subcontracting
        )
        .map((prestation) => ({
          ...prestation,
          quantity: prestation.unity_quantity,
        })) || [],
    [prestations]
  );

  const packageSubPrestations = useMemo(
    () =>
      prestations
        ?.filter(
          (el) => el.unity_quantity > 0 && el.is_package && el.subcontracting
        )
        .map((prestation) => ({
          ...prestation,
          quantity: prestation.unity_quantity,
        })) || [],
    [prestations]
  );

  const regroupPrestationsById = (prestas) => {
    const groupedById = groupByMultipleKeys(prestas, [
      "prestation_profession_id",
      "majoration_opportunity_id",
    ]);

    if (!groupedById) return [];

    const groupedPrestas = Object.keys(groupedById).map((idx) => {
      return {
        ...groupedById[idx][0],
        quantity: groupedById[idx].reduce(
          (acc, curr) => acc + curr.hour_quantity,
          0
        ),
      };
    });

    return groupedPrestas;
  };

  const groupAndFilterBySearchedWord = useCallback(
    ({ arr, keyId = "id", hasPrestations = true }) => {
      // Group same opportunities by id
      const groupedById = groupArrayByKey({ arr, keyId });

      // Addition quantity of same opportunities
      const newArr = groupedById
        ? Object.keys(groupedById).map((idx) => {
            return {
              ...groupedById[idx][0],
              quantity: groupedById[idx].reduce(
                (acc, curr) => acc + curr.quantity,
                0
              ),
              prestations: hasPrestations
                ? regroupPrestationsById(
                    groupedById[idx].map((res) => res.prestations)
                  )
                : [],
            };
          })
        : [];
      // Search by searched word
      if (!searchedWord) return newArr;
      return newArr?.filter(
        (el) =>
          el.name.toLowerCase().includes(searchedWord) ||
          el.prestations.find((childEl) =>
            childEl.name.toLowerCase().includes(searchedWord)
          )
      );
    },
    [searchedWord]
  );

  const renderZoneTitle = (title, option) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>{title}</span>
      {option && <Option style={{ marginLeft: 8 }} />}
    </div>
  );
  return (
    <Container>
      <TitleContainer label="Liste des matériels et prestations chiffrées" />
      <ListHeader
        setShowAll={setShowAll}
        showAll={showAll}
        showMaterial={showMaterial}
        setShowMaterial={setShowMaterial}
        setSearchedWord={setSearchedWord}
        hasOption={hasOption}
      />
      <Separator isHorizontal size={3} />
      <Wrapper>
        {!showAll &&
          zones?.map((zone) => (
            <Collapse key={zone.id} defaultActiveKey={zone.id}>
              <Panel
                header={renderZoneTitle(zone.entitled, zone.option)}
                key={zone.id}
                option={zone.option}
              >
                {tableFactory({
                  materials: groupAndFilterBySearchedWord({
                    arr: materials?.filter((el) => el.zone_id === zone.id),
                    keyId: "material_profession_id",
                    hasPrestations: false,
                  }),
                  carrycots: groupAndFilterBySearchedWord({
                    arr: carrycots?.filter((el) => el.zone_id === zone.id),
                  }),
                  internalProfils: groupAndFilterBySearchedWord({
                    arr: internalProfils?.filter(
                      (el) => el.zone_id === zone.id
                    ),
                  }),
                  subcontractingProfils: groupAndFilterBySearchedWord({
                    arr: subcontractingProfils?.filter(
                      (el) => el.zone_id === zone.id
                    ),
                  }),
                  packagePrestations: groupAndFilterBySearchedWord({
                    arr: packagePrestations?.filter(
                      (el) => el.zone_id === zone.id
                    ),
                    keyId: "prestation_profession_id",
                    hasPrestations: false,
                  }),
                  prestationsOuvrage: groupAndFilterBySearchedWord({
                    arr: prestation_ouvrage?.filter(
                      (el) => el.ouvrage_opportunity.zone_id === zone.id
                    ),
                    keyId: "id",
                    hasPrestations: false,
                  }),
                  packageSubPrestations: groupAndFilterBySearchedWord({
                    arr: packageSubPrestations?.filter(
                      (el) => el.zone_id === zone.id
                    ),
                    keyId: "prestation_profession_id",
                    hasPrestations: false,
                  }),
                  showMaterial,
                  isUsingMinutes,
                  isUsingMargin,
                })}
              </Panel>
            </Collapse>
          ))}
        {showAll &&
          tableFactory({
            materials: groupAndFilterBySearchedWord({
              arr: materials?.filter((el) => {
                return !el.zone.option;
              }),
              keyId: "material_profession_id",
              hasPrestations: false,
            }),
            internalProfils: groupAndFilterBySearchedWord({
              arr: internalProfils?.filter((el) => {
                return !el.prestations.zone.option;
              }),
            }),
            subcontractingProfils: groupAndFilterBySearchedWord({
              arr: subcontractingProfils?.filter((el) => {
                return !el.prestations.zone.option;
              }),
            }),
            packagePrestations: groupAndFilterBySearchedWord({
              arr: packagePrestations?.filter((el) => {
                return !el.zone.option;
              }),
              keyId: "prestation_profession_id",
              hasPrestations: false,
            }),
            packageSubPrestations: groupAndFilterBySearchedWord({
              arr: packageSubPrestations?.filter((el) => {
                return !el.zone.option;
              }),
              keyId: "prestation_profession_id",
              hasPrestations: false,
            }),
            prestationsOuvrage: groupAndFilterBySearchedWord({
              arr: prestation_ouvrage?.filter((el) => {
                return !el.zone.option;
              }),
              keyId: "id",
              hasPrestations: false,
            }),
            carrycots: groupAndFilterBySearchedWord({
              arr: carrycots?.filter((el) => {
                return !el.prestations.zone.option;
              }),
            }),
            showMaterial,
          })}
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

ListSection.propTypes = {
  formToken: string.isRequired,
  zones: array,
  id_opportunity: string.isRequired,
  from: string,
};

ListSection.defaultProps = {
  zones: [],
  from: "",
};

export default ListSection;
