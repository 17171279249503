import React from "react";
import { Form, Input } from "antd";
import { array, bool, func, shape, string } from "prop-types";
import Label from "./Label";

function TextArea({
  textarea,
  handleUpdateOnChange,
  isShowing,
  ...formItemProps
}) {
  const handleOnBlur = (e) => {
    if (textarea.onBlur) textarea.onBlur(e);
    handleUpdateOnChange();
  };

  const disabled = textarea.disabled || isShowing;
  return (
    <Form.Item
      {...formItemProps}
      label={<Label label={formItemProps.label} rules={formItemProps.rules} />}
    >
      <Input.TextArea
        {...textarea}
        onBlur={handleOnBlur}
        disabled={disabled}
        placeholder={isShowing ? "" : textarea.placeholder}
      />
    </Form.Item>
  );
}

TextArea.propTypes = {
  textarea: shape({
    placeholder: string,
    onBlur: func,
    disabled: bool,
  }).isRequired,
  name: string.isRequired,
  label: string,
  rules: array,
  handleUpdateOnChange: func.isRequired,
  isShowing: bool,
};

TextArea.defaultProps = {
  label: "",
  rules: [],
  isShowing: false,
};

export default TextArea;
