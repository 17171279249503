import React from "react";
import styled, { css } from "styled-components";

function BaseIcon({ className = "", $fontSize = "12px", ...iconProps }) {
  return (
    <StyledIcon className={className} $fontSize={$fontSize} {...iconProps} />
  );
}

const StyledIcon = styled.i`
  ${({ onClick }) =>
    onClick !== undefined &&
    css`
      cursor: pointer;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      color: rgba(150, 150, 150, 0.4);
      cursor: not-allowed;
    `}
  font-size: ${({ $fontSize }) => $fontSize};
`;

export default BaseIcon;
