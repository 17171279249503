import React from "react";
import { Form, Checkbox as AntdCheckBox } from "antd";
import { array, bool, func, shape, string } from "prop-types";
import Label from "./Label";

function CheckBox({
  checkbox,
  handleUpdateOnChange,
  isShowing,
  ...formItemProps
}) {
  const handleOnChange = (e) => {
    if (checkbox.onChange) checkbox.onChange(e);
    handleUpdateOnChange();
  };

  const disabled = checkbox.disabled || isShowing;

  return (
    <Form.Item
      {...formItemProps}
      valuePropName="checked"
      label={<Label label={formItemProps.label} rules={formItemProps.rules} />}
    >
      <AntdCheckBox
        {...checkbox}
        onChange={handleOnChange}
        disabled={disabled}
      />
    </Form.Item>
  );
}

CheckBox.propTypes = {
  checkbox: shape({
    onChange: func,
    disabled: bool,
  }).isRequired,
  name: string.isRequired,
  label: string,
  rules: array,
  handleUpdateOnChange: func.isRequired,
  isShowing: bool,
};

CheckBox.defaultProps = {
  label: "",
  rules: [],
  isShowing: false,
};

export default CheckBox;
