import React, { useEffect } from "react";
import { Form, Modal } from "antd";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import FormCol from "../../Form/FormCol";
import FormRow from "../../Form/FormRow";
import Input from "../../react-ui/Input";
import TitleContainer from "../../react-ui/TitleContainer";
import { useStore } from "../../store";
import AddButton from "../../react-ui/AddButton";
import Button from "../../react-ui/Button";
import { postData } from "../../request/instance";
import { opportunityField, validateMessages } from "../../constant";

const selector = (state) => ({
  formToken: state.formToken,
});

function TypologyModal({
  workId,
  typologyModalType,
  setTypologyModalType,
  companyId,
  typologies,
  formOpp,
}) {
  const [form] = Form.useForm();
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();

  const { mutate: createTypo, isLoading: isTypoMutationLoading } = useMutation(
    (todoData) => postData(formToken, "/site_typologies", todoData),
    {
      onSuccess: (typology) => {
        const newTypologies = [
          ...typologies,
          { ...typology, name: typology.typology },
        ];
        const typologiesOpportunityIds =
          formOpp.getFieldValue(opportunityField.typologiesOpportunityIds) ||
          [];

        newTypologies.sort((a, b) => {
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
        });
        queryClient.setQueryData("Typologies", newTypologies);
        formOpp.setFieldsValue({
          [opportunityField.typologiesOpportunityIds]: [
            ...typologiesOpportunityIds,
            typology.id,
          ],
        });
      },
    }
  );

  const closeModal = () => {
    setTypologyModalType("");
    form.resetFields();
  };

  const handleSubmit = (values) => {
    createTypo({
      site_typology: {
        typology: values.name,
        company_id: companyId,
      },
    });
    closeModal();
  };

  useEffect(() => {
    form.setFieldsValue({ [opportunityField.workId]: workId });
  }, [form, workId]);

  return (
    <StyledModal
      destroyOnClose
      maskClosable={false}
      open={typologyModalType !== ""}
      footer={null}
      closable={false}
      onCancel={closeModal}
      bodyStyle={{ padding: 0 }}
      width={600}
    >
      <Form
        id={`new-${typologyModalType}-form`}
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        initialValues={{ [opportunityField.workId]: workId }}
      >
        <TitleContainer label="Créer une typologie">
          <StyledButton
            label="Annuler"
            onClick={closeModal}
            fontSize="14px"
            btnType="cancel"
            type="button"
          />
          <AddButton
            label="Créer la typologie"
            type="submit"
            value="submit"
            fontSize="14px"
            loading={isTypoMutationLoading}
          />
        </TitleContainer>
        <FormContainer>
          <FormRow>
            <FormCol
              label="Nom de la typologie"
              name="name"
              rules={[{ required: true }]}
            >
              <Input placeholder="Nom de la typologie" />
            </FormCol>
          </FormRow>
        </FormContainer>
      </Form>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

export default TypologyModal;
