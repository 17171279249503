import React from "react";
import FormCol from "../../../Form/FormCol";
import { opportunityField } from "../../../constant";
import { formatNumberString } from "../../../../utils/formatNumberString";
import Input from "../../../react-ui/Input";

function EstimatePrice({ form, disabled }) {
  return (
    <FormCol
      labelOnTop
      label="Montant estimé HT"
      name={opportunityField.estimatedAmount}
    >
      <Input
        disabled={disabled}
        placeholder="Montant estimé HT"
        suffix="€"
        onInput={(e) => {
          e.currentTarget.value = formatNumberString({
            str: e.currentTarget.value,
            nbDecimal: 2,
          });
        }}
        onFocus={(e) => {
          form.setFieldsValue({
            [opportunityField.estimatedAmount]: formatNumberString({
              str: e.currentTarget.value,
              nbDecimal: 2,
            }),
          });
        }}
        onBlur={(e) => {
          form.setFieldsValue({
            [opportunityField.estimatedAmount]: formatNumberString({
              str: e.currentTarget.value,
              nbDecimal: 2,
              space: true,
            }),
          });
        }}
      />
    </FormCol>
  );
}

export default EstimatePrice;
