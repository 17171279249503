import moment from "moment";
import React, { useMemo, useRef } from "react";
import Form from "../../Form";

function RentalOfferForm({
  isShowing,
  onSubmit,
  updateOnChange,
  submit,
  initialRentalOffer,
}) {
  const formRef = useRef();

  const rentalOfferFactory = useMemo(() => {
    const nameAndTypeRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: "Nom de l'offre locative",
          },
          name: "name",
          label: "Intitulé",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "select",
        item: {
          select: {
            placeholder: "Type de période",
          },
          options: [
            {
              title: "Mensuel",
              id: "Mensuel",
            },
            {
              title: "Trimestriel",
              id: "Trimestriel",
            },
          ],
          name: "period_type",
          label: "Type de période",
          noSort: true,
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
    ];

    const datesRow = [
      {
        type: "datePicker",
        item: {
          datePicker: {
            placeholder: "Choisir une date",
          },
          name: "start_date",
          label: "Date de début",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },

      {
        type: "datePicker",
        item: {
          datePicker: {
            placeholder: "Choisir une date",
          },
          name: "end_date",
          label: "Date de fin",
          dependencies: ["start_date"],
          rules: [
            {
              validator: () => {
                const endDate = formRef.current.form.getFieldValue("end_date");
                const startDate =
                  formRef.current.form.getFieldValue("start_date");
                if (!endDate || !startDate) return Promise.resolve();
                const diff = moment.duration(
                  moment(endDate).diff(moment(startDate))
                );
                return diff >= 0
                  ? Promise.resolve()
                  : Promise.reject(new Error("Durée invalide."));
              },
            },
          ],
        },
      },
    ];

    const useMarginAndFavoriteRow = [
      {
        type: "select",
        item: {
          select: {
            placeholder: "Type d'affichage",
          },
          options: [
            {
              title: "Coefficient",
              id: false,
            },
            {
              title: "Taux",
              id: true,
            },
          ],
          name: "use_margin",
          label: "Type d'affichage",
          noSort: true,
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "checkbox",
        item: {
          checkbox: {},
          name: "favorite",
          label: "Favori",
        },
      },
    ];

    return [
      { columns: nameAndTypeRow, key: "nameAndTypeRow" },
      { columns: datesRow, key: "datesRow" },
      { columns: useMarginAndFavoriteRow, key: "useMarginAndFavoriteRow" },
    ];
  }, []);

  const initialValues = useMemo(() => {
    if (!initialRentalOffer)
      return {
        period_type: "Mensuel",
      };

    return {
      ...initialRentalOffer,
    };
  }, [initialRentalOffer]);

  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={rentalOfferFactory}
      isShowing={isShowing}
      id="rental-offer-form"
      ref={formRef}
    />
  );
}

export default RentalOfferForm;
