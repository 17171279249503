/* eslint-disable no-return-assign */
import React, { useMemo } from "react";
import styled from "styled-components";
import i18next from "i18next";

function OfferResult({ offers_result, configuration, vocabulary_application }) {
  const formattedOfferResult = useMemo(() => {
    if (offers_result?.length === 0)
      return (
        <StyledResultDiv>
          {vocabulary_application.search_bar.preview_no_offer_result}
        </StyledResultDiv>
      );
    return offers_result?.map((offer) => (
      <StyledResultDiv
        key={offer?.id}
        onClick={() => (window.location = `/offers/${offer?.id}`)}
      >
        <StyledIcon>
          {offer?.works_name?.includes("guarding") && (
            <i
              className="fa-duotone fa-user-shield mr-1 icon-search"
              title={i18next.t("work.guarding")}
            />
          )}
          {offer?.works_name?.map((work) => (
            <i
              className={`${
                configuration.find((config) => config.work.name === work)
                  ?.configuration.general.work_icon
              } mr-1 icon-search`}
              title={i18next.t(
                `work.${
                  configuration.find((config) => config.work.name === work)
                    ?.work
                }`
              )}
            />
          ))}
        </StyledIcon>

        {offer?.name}
      </StyledResultDiv>
    ));
  }, [
    offers_result,
    configuration,
    vocabulary_application.search_bar.preview_no_offer_result,
  ]);

  return (
    <StyledContainerOffer>
      <StyledTitle>
        {vocabulary_application.search_bar.preview_offers}
      </StyledTitle>
      {formattedOfferResult}
    </StyledContainerOffer>
  );
}

const StyledContainerOffer = styled.div`
  background-color: white;
  margin-bottom: 0.5em;
`;

const StyledResultDiv = styled.div`
  line-height: 1.5;
  font-size: 12px;
  color: #333333;
  display: flex;
  align-items: center;
  padding-left: 2em;
  cursor: pointer;
  text-align: left;
  &:hover {
    background-color: #9abcea;
  }
`;

const StyledTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  display: flex;
  align-items: center;
  height: 30px;
  padding-left: 1em;
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: left;
  width: 41px;
  flex-wrap: wrap;
`;
export default OfferResult;
