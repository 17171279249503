export const fieldsDico = (isFa = false) => ({
  name: "LOWER(name)",
  reference_code: "LOWER(reference_code)",
  margin: "margin_rate",
  price: isFa ? "selling_price_cents" : "cost_price_cents",
  category: "LOWER(category_profession.title)",
  associated: "associated",
  is_in_framework_agreement: "is_in_framework_agreement",
});

export const handleMaterialSorterChange = ({
  pagination,
  fltrs,
  sorter,
  setCategoryFiltered,
  categoryFiltered,
  setSortBy,
  isFa = false,
}) => {
  if (!fltrs.category && categoryFiltered) setCategoryFiltered(undefined);
  else if (fltrs.category && fltrs.category.length > 0) {
    setCategoryFiltered(fltrs.category);
  }
  if (sorter?.order === "ascend") {
    setSortBy({ order: "ASC", field: fieldsDico(isFa)[sorter.field] });
  } else if (sorter?.order === "descend") {
    setSortBy({ order: "DESC", field: fieldsDico(isFa)[sorter.field] });
  } else setSortBy(undefined);
};

export const handleClientSorterChange = ({ sorter, setSortBy }) => {
  if (sorter?.order === "ascend") {
    setSortBy({ order: "ASC", field: sorter.field });
  } else if (sorter?.order === "descend") {
    setSortBy({ order: "DESC", field: sorter.field });
  } else setSortBy(undefined);
};
