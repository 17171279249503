import React from "react";
import { instance, postData, getData } from "./instance";
import { useStore } from "../store";
import { useMutation, useQuery } from "react-query";

const getUrlParams = () => {
    const lastSegment = window.location.pathname.split("/").pop();
    if (!lastSegment) return "error"
    return lastSegment
};



export const fetchNacelleProfession = (formToken) => {
    const { data, isSuccess, refetch} = useQuery('getNacelleProfessions', () => getData(formToken, `/carrycot_profession/${getUrlParams()}`))
    return data
}

export const fetchNacelleProfessionOpportunity = (formToken, id_opportunity) => {
    const { data, isSuccess, refetch} = useQuery('getNacelleProfessions', () => getData(formToken, `/carrycot_by_opportunity/${id_opportunity}`))
    return data
}

export const updateNacelles = (formToken) => useMutation(updateTodo => postData(formToken, "/carrycot_profession/update", updateTodo))

export const createCarrycots = (formToken) => useMutation(createTodo => postData(formToken, "/carrycot_profession/create", createTodo))

export const deleteCarrycot = (formToken) => useMutation(deleteTodo => postData(formToken, "/carrycot_profession/delete", deleteTodo))