import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Modal } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TitleContainer from "../../react-ui/TitleContainer";
import AddButton from "../../react-ui/AddButton";
import ZoneTemplateArray from "./ZoneTemplateArray";
import ZoneTemplateModal from "./ZoneTemplateModal";
import { getData, postData } from "../../request/instance";
import ListSection from "../../Chiffrage/CostingMaterialsPrestations/ListSection";
import Separator from "../../react-ui/Separator";
import CostingSection from "../../Chiffrage/CostingMaterialsPrestations/CostingSection";
import { useStore } from "../../store";
import { getUrlParams } from "../../utils";
import ArrowRight from "../../react-ui/Icons/ArrowRight";
import ArrowLeft from "../../react-ui/Icons/ArrowLeft";
import { fetchDifficultiesUrl } from "../../../utils/fetchDifficultiesUrl";
import useTodo from "../../hookQuery/useTodo";
import { fetchCarrycotsUrl } from "../../../utils/fetchCarrycotsUrl";
import { fetchMajorationsUrl } from "../../../utils/fetchMajorationsUrl";

const selector = (state) => ({
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
  isUsingMinutes: state.isUsingMinutes,
  configurations: state.configurations,
  vocabulary: state.vocabulary,
});

function ZoneTemplates({ token, entityId, management_special_TVA }) {
  const { isUsingMinutes, configurations, vocabulary } = useStore(selector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusForm, setStatusForm] = useState("");
  const [zoneEdit, setZoneEdit] = useState(false);
  const [selectedZoneTemplate, setSelectedZoneTemplate] = useState(null);
  const queryClient = useQueryClient();
  const entityWorkId = getUrlParams();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedZoneTemplate(null);
  };

  useEffect(() => {
    if (selectedZoneTemplate) {
      setIsModalOpen(true);
    }
  }, [selectedZoneTemplate]);

  const { data: difficulties } = useTodo(
    token,
    fetchDifficultiesUrl({ from: "admin", id: entityWorkId }),
    "Difficulties"
  );

  const { data: majorations } = useTodo(
    token,
    fetchMajorationsUrl({ from: "admin", id: entityWorkId }),
    "Majorations2"
  );

  const { data: carrycot_professions } = useTodo(
    token,
    fetchCarrycotsUrl({
      from: "admin",
      id: entityWorkId,
    }),
    ["Carrycots", { form: "admin" }]
  );

  const { mutate: updateZoneTemplate } = useMutation(
    (todo) => postData(token, "/zone/update", todo),
    {
      onSuccess: () => {
        queryClient.refetchQueries("ZoneTemplates");
        handleCancel();
      },
    }
  );

  return (
    <div>
      {zoneEdit ? (
        <DndProvider backend={HTML5Backend}>
          <StyledBackButton>
            <BackContainer
              onClick={() => {
                setZoneEdit(!zoneEdit);
                setSelectedZoneTemplate(null);
                setIsModalOpen(false);
              }}
            >
              <BackIcon className="fa-duotone fa-arrow-left" />
              Retour aux zones
            </BackContainer>
            <NameContainer>
              <Label>Zone sélectionné : </Label>
              <Name>{selectedZoneTemplate?.entitled}</Name>
            </NameContainer>
          </StyledBackButton>
          <Container>
            <ListSection
              // opportunityType={opportunityType}
              // faId={faId}
              // opportunity_work={opportunity_work}
              configuration={configurations}
              vocabulary={vocabulary}
              api_providers_length={1}
              defaultMinutes={isUsingMinutes}
              selectedZoneTemplate={selectedZoneTemplate}
              company_work={{ management_special_TVA }}
              from="admin"
              entity_work_id={getUrlParams()}
            />
            <Separator />
            <CostingSection
              company_work={{ management_special_TVA }}
              // opportunityType={opportunityType}
              // faId={faId}
              // opportunity_work={opportunity_work}
              selectedZoneTemplate={selectedZoneTemplate}
              configuration={configurations}
              vocabulary={vocabulary}
              from="admin"
            />
          </Container>
        </DndProvider>
      ) : (
        <>
          <TitleContainer label="Liste des zones">
            <StyledButton
              onClick={() => {
                showModal();
                setStatusForm("create");
              }}
              label="Ajouter une zone"
            />
          </TitleContainer>
          <ZoneTemplateArray
            token={token}
            entityId={entityId}
            setSelectedZoneTemplate={setSelectedZoneTemplate}
            setZoneEdit={setZoneEdit}
            updateZoneTemplate={updateZoneTemplate}
            difficulties={difficulties}
            majorations={majorations}
            carrycot_professions={carrycot_professions}
          />
          <ZoneTemplateModal
            difficulties={difficulties}
            majorations={majorations}
            carrycot_professions={carrycot_professions}
            statusForm={statusForm}
            setStatusForm={setStatusForm}
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            token={token}
            selectedZoneTemplate={selectedZoneTemplate}
          />
        </>
      )}
    </div>
  );
}

const Name = styled.p`
  font-size: 14px !important;
  margin: 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  height: 80vh;
`;

const StyledBackButton = styled.div`
  display: flex;
  flex-direction: row;
  width: 500px;
  margin: 10px;
`;

const StyledButton = styled(AddButton)`
  margin-right: 4px;
  max-height: 45px;
`;

const BackContainer = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.blue09};
  &:hover {
    color: ${({ theme }) => theme.colors.blue04};
  }
  transition: color 0.15s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

const BackIcon = styled.i`
  font-size: 20px;
  margin-right: 10px;
`;

const Label = styled.p`
  font-size: 14px !important;
  color: ${({ theme }) => theme.colors.blue11};
  margin: 0;
  font-weight: 600;
  margin-right: 5px;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
`;
export default ZoneTemplates;
