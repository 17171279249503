import React, { useMemo } from "react";
import { bool, func, object, shape, string } from "prop-types";
import { formatPercentage } from "../../utils";
import Form from "../../Form";

function DifficultyForm({
  isShowing,
  onSubmit,
  updateOnChange,
  submit,
  initialDifficulty,
  entity_work_name,
  configuration,
  vocabulary,
}) {
  const formFactory = useMemo(() => {
    const firstRow = [
      {
        type: "input",
        item: {
          input: {
            placeholder: `Nom de la ${vocabulary.difficulty_label}`,
          },
          name: "name",
          label: "Intitulé",
          rules: [
            {
              required: true,
              message: "Champ obligatoire.",
            },
          ],
        },
      },
      {
        type: "input",
        item: {
          input: {
            placeholder: "0,00",
            onInput: (e) => {
              e.currentTarget.value = formatPercentage(e.currentTarget.value);
            },
          },
          name: "rate",
          label: "Coefficient",
        },
      },
    ];

    const secondRow = [
      {
        type: "textarea",
        item: {
          textarea: {
            placeholder: "Ajoutez une description",
          },
          name: "description",
          label: "Description",
        },
      },
    ];

    return [
      { columns: firstRow, key: "name+rate" },
      { columns: secondRow, key: "description" },
    ];
  }, []);

  const initialValues = useMemo(() => {
    if (!initialDifficulty) return {};
    const rate = initialDifficulty.rate
      ? formatPercentage(initialDifficulty.rate.toString())
      : undefined;
    return {
      name: initialDifficulty.name,
      rate,
      description: initialDifficulty.description,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDifficulty?.id]);

  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={formFactory}
      isShowing={isShowing}
      id="parameter-form"
    />
  );
}

DifficultyForm.propTypes = {
  isShowing: bool,
  onSubmit: func,
  updateOnChange: bool,
  submit: shape({
    onCancel: func,
  }),
  initialDifficulty: object,
  entity_work_name: string,
  configuration: object,
  vocabulary: {},
};

DifficultyForm.defaultProps = {
  isShowing: false,
  onSubmit: undefined,
  updateOnChange: false,
  submit: undefined,
  initialDifficulty: undefined,
  entity_work_name: "",
  configuration: {},
  vocabulary: {},
};

export default DifficultyForm;
