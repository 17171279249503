// add a grey background to the odd card-box
$(document).ready(() => {
  const actualPage = document.getElementById("agents");

  if(!actualPage){
    return
  }

  const addBackGround = () => {
    const cardBoxs = $("#agents div.card-box:odd");
    if (cardBoxs) {
      $.each(cardBoxs, function (index, value) {
        $(this).addClass("greylight-background-odd");
      });
    }
  }
  addBackGround()

  //make input "Taux ancienneté CCN" & "Tarif horaire CCN" not editable
  const tauxHoraire = document.querySelectorAll(".agent_collective_agreement_hourly_rate");
  if (tauxHoraire) {
    tauxHoraire.forEach(cell => cell.classList.add("agent-cell-disabled"));
  }

  const forcedSeniorityCCN = document.querySelectorAll(".agent_calculated_seniority");
  if (forcedSeniorityCCN) {
    forcedSeniorityCCN.forEach(cell => cell.classList.add("agent-cell-disabled"));
  }

  //make input of the modal modify value in DB for each agent of a work position
  const inputForcedSeniority = document.getElementById("forced_seniority");
  const inputHourlyPrice = document.getElementById("hourly_price");
  const inputMensualMutual = document.getElementById("mensual_mutual");
  const inputVacation = document.getElementById("vacation");
  const submitModalTotalRate = document.getElementById("submit-modal-total-rate")
  const checkboxList = document.getElementsByName("work_position_checked");
  const inputForcedSeniorityAgent = document.getElementsByName("agent[forced_seniority]");
  const inputVacationAgent = document.getElementsByName("agent[vacation_time]");
  const inputAgent = document.getElementsByName("agent");
  const inputHourlyPriceAgent = document.getElementsByName("agent[hourly_price]");
  const inputMensualMutualAgent = document.getElementsByName("agent[health_mutual]");
  const inputWorkBonusName = document.querySelectorAll("[id^='wb_name']");
  const inputWorkBonusAmount = document.querySelectorAll("[id^='wb_amount']")
  const inputWorkBonusFrequency = document.querySelectorAll("[id^='wb_frequency']");
  const inputIndemnityName = document.querySelectorAll("[id^='indemnity_name']");
  const inputIndemnityAmount = document.querySelectorAll("[id^='indemnity_amount']");
  const inputIndemnityFrequency = document.querySelectorAll("[id^='indemnity_frequency']");
  const valueModal = document.getElementsByClassName("valueModal");
  const deleteSeniority = document.getElementsByClassName("delete_seniority_value_if_checked");
  const deleteHourly = document.getElementsByClassName("delete_hourly_value_if_checked");
  
  const wbLabel= document.getElementsByClassName("wb_label");
  const wbAmount= document.getElementsByClassName("wb_amount");
  const wbFrequency= document.getElementsByClassName("wb_frequency");
  const indemnityLabel= document.getElementsByClassName("indemnity_label");
  const indemnityAmount= document.getElementsByClassName("indemnity_amount");
  const indemnityFrequency= document.getElementsByClassName("indemnity_frequency");

  
  deleteSeniority[0].addEventListener('change', () => {
    if (deleteSeniority[0].checked === true){
      inputForcedSeniority.style.backgroundColor = "#EAEAEA"
      inputForcedSeniority.value = false

    }
    else {
      inputForcedSeniority.style.backgroundColor = "#FFFFFF"
    }
  })

  deleteHourly[0].addEventListener('change', () => {
    if (deleteHourly[0].checked === true){
      inputHourlyPrice.style.backgroundColor = "#EAEAEA"
      inputHourlyPrice.value = false
    }
    else {
      inputHourlyPrice.style.backgroundColor = "#FFFFFF"
    } 
  })

   
  submitModalTotalRate.addEventListener("click", () => {
    begin = 0
    let listeWb = []
    let listeIndemnity = []
    // pour chaque workposition
    for (let i = 0; i < checkboxList.length; ++i) {
      textJoin = valueModal[i].innerText.replace(/\n/g, " ").split(" ").join("")
      join = valueModal[i].attributes[3].nodeValue
      numberAgentsWorkPosition = parseInt(join.split("-").reverse()[0])+begin
      // pour chaque agent
        for (let j = begin; j < numberAgentsWorkPosition; j++) {
          healthMutualId = inputAgent[j].firstElementChild[9].id
          if (checkboxList[i].checked === true){
            if (inputMensualMutual.value > 0){
              healthMutualInput = document.getElementById(healthMutualId)
              healthMutualInput.value = inputMensualMutual.value
              evenement = new Event('input')
              healthMutualInput.dispatchEvent(evenement)
              }
            if (inputForcedSeniority.value >= 0 && inputForcedSeniority.value.length>0){
              inputForcedSeniorityAgent[j].value = inputForcedSeniority.value
              }
            if (inputHourlyPrice.value > 0){
              inputHourlyPriceAgent[j].value = inputHourlyPrice.value
            }
            if (inputVacation.value > 0){
              inputVacationAgent[j].value = inputVacation.value
            }
            if (deleteSeniority[0].checked === true){
              inputForcedSeniorityAgent[j].value = false
            }
            if (deleteHourly[0].checked === true){
              inputHourlyPriceAgent[j].value = false
            }
            //pour chaque agent je dois trouver l'id des primes faisant parti des workpositions cochées
            for (let k = 0; k < inputAgent[j].firstElementChild.length ; k++){
                if (inputAgent[j].firstElementChild[k].id.startsWith("wb_name",0)) {
                  listeWb.push(inputAgent[j].firstElementChild[k].id.split("_").reverse()[0])
                }
                if (inputAgent[j].firstElementChild[k].id.startsWith("indemnity_name",0)) {
                  listeIndemnity.push(inputAgent[j].firstElementChild[k].id.split("_").reverse()[0])
                }
            }
          }
        begin=numberAgentsWorkPosition
      }
    }
    //je modifie les workbonuses appartenant à la liste
    for (let i = 0; i < listeWb.length ; i++){
      for (let j =0; j < inputWorkBonusName.length; j++){
        if (inputWorkBonusName[j].id == "wb_name_"+listeWb[i] ){
        //le work-bonus fait parti de la liste, il faut vérifier si il y'a une valeur à changer
        for (let k=0; k < wbLabel.length; k++){
          //je parcours la liste des wb présents dans ma modale
            //je vérifie le nom du work-bonus
            if(wbLabel[k].innerText == inputWorkBonusName[j].value){
              // je vérifie si les valeurs sont non nulles ou supérieures ou égales à 0
              if (wbAmount[k].value){
                if (wbAmount[k].value >=0) {
                  wbAmountId = inputWorkBonusAmount[j].id
                  wbInput = document.getElementById(wbAmountId)
                  wbInput.value = wbAmount[k].value
                  evenement = new Event('input')
                  wbInput.dispatchEvent(evenement)
                }
              }
              if (wbFrequency[k].value >0) {
                const frequency = parseInt(wbFrequency[k].value) -1
                inputWorkBonusFrequency[j].selectedIndex = frequency
              }
            }
          }
        }
      }
    }
    //je modifie les indemnities appartenant à la liste
    for (let i = 0; i < listeIndemnity.length ; i++){
      for (let j =0; j < inputIndemnityName.length; j++){
        if (inputIndemnityName[j].id == "indemnity_name_"+listeIndemnity[i] ){
        //le indemnity fait parti de la liste, il faut vérifier si il y'a une valeur à changer
        for (let k=0; k < indemnityLabel.length; k++){
          //je parcours la liste des indemnity présents dans ma modale
            //je vérifie le nom du indemnity
            if(indemnityLabel[k].innerText == inputIndemnityName[j].value){
              // je vérifie si les valeurs sont non nulles ou supérieures ou égales à 0
              if (indemnityAmount[k].value) {
                if (indemnityAmount[k].value >=0) {
                  //inputIndemnityAmount[j].value = indemnityAmount[k].value
                  indemnityAmountId = inputIndemnityAmount[j].id
                  indemnityInput = document.getElementById(indemnityAmountId)
                  indemnityInput.value = indemnityAmount[k].value
                  evenement = new Event('input')
                  indemnityInput.dispatchEvent(evenement)
                }
              }
              
              if (indemnityFrequency[k].value >0) {
                const frequency = parseInt(indemnityFrequency[k].value) -1
                inputIndemnityFrequency[j].selectedIndex = frequency
              }
            }
          }
        }
      }
    }
    inputMensualMutual.value = false
    inputForcedSeniority.value = false
    inputHourlyPrice.value = false
    for (let k=0; k < wbLabel.length; k++){
    wbLabel[k].value=false
    wbAmount[k].value=false
    wbFrequency[k].value=false
    }
    for (let l=0; l < indemnityLabel.length; l++){
      indemnityLabel[l].value=false
      indemnityAmount[l].value=false
      indemnityFrequency[l].value=false
      }
    for (let i = 0; i < checkboxList.length; ++i) {
      checkboxList[i].checked = true
    }
  })

  // make reinitialisation of modal value on click "Annuler" and "Appliquer"
  const cleanValueWhenClick = document.getElementById("closeModal")


  cleanValueWhenClick.addEventListener( 'click', () => {
    inputMensualMutual.value = false
    inputForcedSeniority.value = false
    inputHourlyPrice.value = false
    for (let k=0; k < wbLabel.length; k++){
      wbLabel[k].value=false
      wbAmount[k].value=false
      wbFrequency[k].value=false
      }
      for (let l=0; l < indemnityLabel.length; l++){
        indemnityLabel[l].value=false
        indemnityAmount[l].value=false
        indemnityFrequency[l].value=false
        }
    for (let i = 0; i < checkboxList.length; ++i) {
      checkboxList[i].checked = true
    }
  })


})