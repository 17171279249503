import React, { useCallback, useEffect, useMemo, useState } from "react";
import Tooltip from "antd/lib/tooltip";
import { Checkbox } from "antd";
import { useMutation, useQueryClient } from "react-query";
import { useStore } from "../../store";
import { currency_cents_to_euros } from "../../utils";
import { ShowDeleteConfirm } from "../confirmModal";
import { postData } from "../../request/instance";
import { formatNumberString } from "../../../utils/formatNumberString";
import Delete from "../../react-ui/Icons/Delete";
import FileDocument from "../../react-ui/Icons/FileDocument";
import Filter from "../../react-ui/Icons/Filter";
import ActionsContainer from "../../react-ui/ActionsContainer";
import NumberCell from "../../react-ui/NumberCell";
import { translate_filter } from "../Categories/translate_data";
import { getMarginCol } from "../../../constants/marginCol";
import LazyMaterialArray from "./LazyMaterialArray";
import Info from "../../react-ui/Icons/Info";
import { stringSorter } from "../../../utils/stringSorter";

const selector = (state) => ({
  selectedMateriel: state.selectedMateriel,
  selectedFamily: state.selectedFamily,
  editMateriel: state.editMateriel,
  formToken: state.formToken,
  isUsingMargin: state.isUsingMargin,
  calculMethod: state.calculMethod,
  vocabulary: state.vocabulary,
});

function Actions({
  value,
  handleDeleteMaterial,
  setCompatibleMaterials,
  compatibleMaterials,
  from,
  configuration,
}) {
  const handleCompatibleClick = () => {
    if (compatibleMaterials?.filter((el) => el.id === value.id).length > 0)
      return;
    setCompatibleMaterials([...compatibleMaterials, value]);
  };
  const colGap = "8%";
  const driveLink =
    from !== "admin" && value.material_included
      ? value.material_included.drive_link
      : value.drive_link;

  return (
    <ActionsContainer $colGap={colGap}>
      {value.remark && (
        <Tooltip placement="bottom" title={value.remark}>
          <Info $fontSize="11px" />
        </Tooltip>
      )}
      {configuration?.general.compatibility && (
        <Filter onClick={handleCompatibleClick} />
      )}
      <FileDocument
        onClick={() =>
          driveLink ? window.open(driveLink, "_blank") : undefined
        }
        disabled={!driveLink}
      />

      {from === "admin" && (
        <Delete
          onClick={() =>
            ShowDeleteConfirm(value.name, value.id, handleDeleteMaterial)
          }
        />
      )}
    </ActionsContainer>
  );
}

export function MaterielComponent({
  materialDetailsStatus,
  setMaterialDetailsStatus,
  wordEntered,
  materials,
  setMaterials,
  setCompatibleMaterials,
  compatibleMaterials,
  categories,
  entityWorkId,
  from,
  opportunityId,
  handleFAAndOpp,
  faIsValidate,
  faId,
  entity_work_name,
  parent = "material",
  handleFamilyChecked,
  familiesAssociates,
  configuration,
  use_special_tva,
  adminDetails = false,
}) {
  const queryClient = useQueryClient();
  const {
    selectedMateriel,
    editMateriel,
    formToken,
    isUsingMargin,
    calculMethod,
    selectedFamily,
    vocabulary,
  } = useStore(selector);

  const [categoryFiltered, setCategoryFiltered] = useState();

  const { mutate: removeMaterials } = useMutation((todoData) =>
    postData(formToken, "/material_profession/delete", todoData)
  );

  const [tableRowsData, setTableRowData] = useState([]);

  useEffect(() => {
    const dataResult = [];
    let child;
    materials?.forEach((value) => {
      child = {
        ...value,
        key: value.id,
        id: value.id,
        name: value.material_included
          ? value.material_included.name
          : value.name,
        price: formatNumberString({
          str: currency_cents_to_euros(value.cost_price_cents),
          nbDecimal: 2,
          space: true,
        }),
        is_in_framework_agreement:
          value.is_in_framework_agreement || value.material_included,
        margin: value.margin_rate,
        actions: !(parent === "family") ? (
          <Actions
            handleDeleteMaterial={handleDeleteMaterial}
            value={value}
            setCompatibleMaterials={setCompatibleMaterials}
            compatibleMaterials={compatibleMaterials}
            from={from}
            entity_work_name={entity_work_name}
            configuration={configuration}
          />
        ) : undefined,
        category: value.category_profession?.title,
      };
      dataResult.push(child);
    });
    setTableRowData(dataResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [materials, compatibleMaterials]);

  const onMaterialNewSearch = useCallback(() => {
    if (!(parent === "family")) {
      editMateriel(null);
      setMaterialDetailsStatus("empty");
    }
  }, [parent, editMateriel, setMaterialDetailsStatus]);

  const openUpdateMaterialProfession = (value) => {
    editMateriel(value);
    setMaterialDetailsStatus("update");
  };

  const getColumnsMaterials = useMemo(() => {
    const entitledCol = {
      title: "Intitulé",
      dataIndex: "name",
      sorter: true,
    };

    const codeCol = {
      title: "Référence",
      dataIndex: "reference_code",
      sorter: (a, b) => stringSorter(a.reference_code, b.reference_code),
      width: "90px",
      align: "right",
      hidden: !configuration.costing.page_1.table_col_reference_code,
    };

    const categoriesCol = {
      title: "Catégorie",
      dataIndex: "category",
      filters: translate_filter(categories),
      filterMode: "tree",
      filterSearch: true,
      sorter: true,
      defaultFilteredValue: categoryFiltered || [],
      onFilter: () => {
        return true;
      },
      filterMultiple: true,
      width: "25%",
    };

    const priceCol = {
      title: "Coût",
      dataIndex: "price",
      sorter: true,
      width: "90px",
      render: (value) => (value ? <NumberCell>{value} €</NumberCell> : null),
    };

    const frameworkAgreementCol = {
      title: "Accord cadre",
      dataIndex: "is_in_framework_agreement",
      width: "60px",
      align: "center",
      sorter: true,
      render: (value, record) => (
        <Checkbox
          checked={value}
          onClick={() => handleFAAndOpp(value, record)}
          disabled={from === "frameworkAgreement" && !faIsValidate}
        />
      ),
    };

    const familyCol = {
      title: "Lié",
      dataIndex: "families_linked",
      width: "60px",
      align: "center",
      render: (_, record) => (
        <Checkbox
          checked={familiesAssociates?.some(
            (el) =>
              el.material_profession_id === record.id &&
              el.family_profession_id === selectedFamily?.id
          )}
          onChange={({ target: { checked } }) =>
            handleFamilyChecked(record.id, checked)
          }
        />
      ),
    };

    const tvaCol = {
      title: vocabulary?.TVA,
      dataIndex: "tva_profession_name",
      width: "15%",
      sorter: (a, b) =>
        stringSorter(a.tva_profession_name, b.tva_profession_name),
      hidden: !use_special_tva,
    };

    const actionsCol = {
      title: "",
      dataIndex: "actions",
      width: from === "admin" ? "65px" : "50px",
    };

    if (parent === "family") {
      return [
        entitledCol,
        categoriesCol,
        getMarginCol({
          isUsingMargin,
          calculMethod,
          customSorter: true,
          entity_work_name,
          configuration,
        }),
        priceCol,
        familyCol,
      ].filter((col) => !col.hidden);
    }

    if (from === "admin")
      return [
        entitledCol,
        codeCol,
        categoriesCol,
        getMarginCol({
          isUsingMargin,
          calculMethod,
          customSorter: true,
          entity_work_name,
          configuration,
          hidden: configuration.admin.product_tab.table_marge_coeff,
        }),
        tvaCol,
        priceCol,
        actionsCol,
      ].filter((col) => !col.hidden);

    return [
      entitledCol,
      categoriesCol,
      priceCol,
      frameworkAgreementCol,
      actionsCol,
    ];
  }, [
    categories,
    from,
    categoryFiltered,
    handleFAAndOpp,
    faIsValidate,
    isUsingMargin,
    calculMethod,
    entity_work_name,
    parent,
    selectedFamily,
    handleFamilyChecked,
    familiesAssociates,
    configuration,
  ]);

  const handleDeleteMaterial = (idMaterial) => {
    setMaterialDetailsStatus("empty");
    editMateriel();
    removeMaterials(
      JSON.stringify({ id: idMaterial, entity_work_id: entityWorkId }),
      {
        onSuccess: () => {
          setMaterials(
            materials.filter((el) => el.id !== parseInt(idMaterial, 10))
          );
          queryClient.invalidateQueries("MaterialsAssociates");
        },
      }
    );
  };

  return (
    <LazyMaterialArray
      columns={getColumnsMaterials}
      parent={parent}
      dataSource={tableRowsData}
      rowSelectableId={
        !(parent === "family") && materialDetailsStatus === "update"
          ? selectedMateriel?.id
          : undefined
      }
      onRow={(record) => {
        return {
          onClick: () => {
            if (!(parent === "family"))
              openUpdateMaterialProfession(
                materials.find((el) => el.id === record.id)
              );
          },
        };
      }}
      from={from}
      opportunityId={opportunityId}
      faIsValidate={faIsValidate}
      faId={faId}
      entityWorkId={entityWorkId}
      materials={materials}
      setMaterials={setMaterials}
      compatibleMaterials={compatibleMaterials}
      wordEntered={wordEntered}
      categoryFiltered={categoryFiltered}
      setCategoryFiltered={setCategoryFiltered}
      onMaterialNewSearch={onMaterialNewSearch}
      adminDetails={adminDetails}
    />
  );
}
