import React from "react";
import axios from "axios";
import { QueryClient, useMutation, useQueryClient } from "react-query";
import { postData } from "../request/instance";

export default function useCreateTodo(formToken, path, name, arrayName = null) {
  const queryClient = useQueryClient();
  return useMutation((todo) => postData(formToken, path, todo), {
    onSuccess: async (data) => {
      queryClient.invalidateQueries(name);
    },
    // onMutate: async (newTodo) => {
    //   await queryClient.cancelQueries(name);

    //   const prev = queryClient.getQueryData(name);

    //   queryClient.setQueryData(name, (old) => [
    //     { ...newTodo, id: Date.now() },
    //     ...old,
    //   ]);

    //   return {
    //     prev,
    //     newTodo,
    //   };
    // },
    onError: (err, _, context) => {
      queryClient.setQueryData(name, context.prev);
    },
    onSettled: () => {
      if (arrayName && arrayName.length > 0) {
        arrayName.map((name) => {
          queryClient.invalidateQueries(name);
        });
      }
    },
  });
}
