import React, { useCallback, useEffect, useMemo } from "react";
import { Form, Modal } from "antd";
import styled from "styled-components";
import { useQueryClient } from "react-query";
import FormCol from "../../Form/FormCol";
import FormRow from "../../Form/FormRow";
import TitleContainer from "../../react-ui/TitleContainer";
import AddButton from "../../react-ui/AddButton";
import Button from "../../react-ui/Button";
import { validateMessages } from "../../constant";
import FormattedInput from "../../react-ui/FormattedInput";
import { stringToFloat } from "../../../utils/stringToFloat";

function SplitAgentModal({
  agentToSplit,
  setAgentToSplit,
  splitAgentMutation,
  isLoading,
  opportunityId,
}) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const agentNumberForm = Form.useWatch("agentNumber", form);

  const initialValues = useMemo(
    () => ({
      agentNumber: 2,
      last_agent: 100,
    }),
    []
  );

  const closeModal = () => {
    setAgentToSplit();
    form.resetFields();
  };

  const getLastPartimePercent = useCallback(() => {
    let lastPartimePercent = 100;
    let i = 0;

    for (i; i < agentNumberForm - 1; i += 1) {
      const partimePercent =
        stringToFloat(form.getFieldValue(`partimePercent_${i + 1}`)) || 0;
      lastPartimePercent -= partimePercent;
    }
    return lastPartimePercent.toFixed(2);
  }, [agentNumberForm, form]);

  useEffect(() => {
    form.setFieldValue("last_agent", getLastPartimePercent());
  }, [form, getLastPartimePercent]);

  const handleSubmit = (values) => {
    const { agentNumber } = values;
    const splittedAgents = new FormData();

    for (let i = 0; i < agentNumber - 1; i += 1) {
      splittedAgents.append(
        "splitted_agents[]",
        stringToFloat(form.getFieldValue(`partimePercent_${i + 1}`))
      );
    }
    splittedAgents.append("opportunity_id", opportunityId);
    splittedAgents.append("id", agentToSplit.id);
    splitAgentMutation(splittedAgents, {
      onSuccess: () => {
        queryClient.invalidateQueries(["Agents", { wpId: agentToSplit.wpId }]);
        queryClient.invalidateQueries([
          "WorkBonuses",
          { wpId: agentToSplit.wpId },
        ]);
        queryClient.invalidateQueries([
          "Indemnities",
          { wpId: agentToSplit.wpId },
        ]);
        queryClient.invalidateQueries(["WorkBonuses", { distinct: true }]);
        queryClient.invalidateQueries(["Indemnities", { distinct: true }]);
      },
    });
    closeModal();
  };

  const partimePercentsRows = () => {
    const rows = [];
    let i = 0;
    const agentNumber = agentNumberForm > 10 ? 10 : agentNumberForm;
    const dependencies = ["agentNumber"];

    for (i; i < agentNumber - 1; i += 1) {
      dependencies.push(`partimePercent_${i + 1}`);
      rows.push(
        <FormRow key={`agent-${i + 1}`}>
          <FormCol
            width="50%"
            label={`Temps partiel agent ${i + 1}`}
            rules={[{ required: true }]}
            name={`partimePercent_${i + 1}`}
            onChange={() =>
              form.setFieldValue("last_agent", getLastPartimePercent())
            }
          >
            <FormattedInput
              isNumber
              suffix="%"
              textAlign="right"
              size="middle"
            />
          </FormCol>
        </FormRow>
      );
    }
    rows.push(
      <FormRow key="last_agent">
        <FormCol
          width="50%"
          label={`Temps partiel agent ${i + 1}`}
          dependencies={dependencies}
          rules={[
            { required: true },
            {
              validator: () => {
                if (
                  getLastPartimePercent() > 99.99 ||
                  getLastPartimePercent() < 0.01
                )
                  return Promise.reject(
                    new Error(`Doit être compris entre 0,01 et 99,99`)
                  );
                return Promise.resolve();
              },
            },
          ]}
          name="last_agent"
        >
          <FormattedInput
            isNumber
            suffix="%"
            textAlign="right"
            size="middle"
            disabled
          />
        </FormCol>
      </FormRow>
    );
    return rows;
  };

  return (
    <StyledModal
      destroyOnClose
      maskClosable={false}
      open={!!agentToSplit}
      footer={null}
      closable={false}
      onCancel={closeModal}
      bodyStyle={{ padding: 0 }}
      width={900}
    >
      <Form
        id="splitted-agents-form"
        form={form}
        colon={false}
        requiredMark={false}
        initialValues={initialValues}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
      >
        <TitleContainer
          label={`${agentToSplit?.posteName} : ${agentToSplit?.agentName}`}
        >
          <StyledButton
            label="Annuler"
            onClick={closeModal}
            fontSize="14px"
            btnType="cancel"
            type="button"
          />
          <AddButton
            label="Diviser l'agent"
            type="submit"
            value="submit"
            fontSize="14px"
            loading={isLoading}
          />
        </TitleContainer>
        <FormContainer>
          <FormRow>
            <FormCol
              width="50%"
              label="Nombre d'agents à temps partiel"
              rules={[
                { required: true },
                {
                  validator: () => {
                    if (agentNumberForm && agentNumberForm < 2)
                      return Promise.reject(new Error(`Pas moins de 2 agents`));
                    if (agentNumberForm > 10)
                      return Promise.reject(new Error(`Pas plus de 10 agents`));
                    return Promise.resolve();
                  },
                },
              ]}
              name="agentNumber"
            >
              <FormattedInput
                isNumber
                textAlign="right"
                nbDecimal={0}
                size="middle"
              />
            </FormCol>
          </FormRow>

          {partimePercentsRows()}
        </FormContainer>
      </Form>
    </StyledModal>
  );
}

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

export default SplitAgentModal;
