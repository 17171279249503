import { encodeUrlParams } from "./encodeUrlParams";

export const fetchFileRulesUrl = ({ search }) => {
  let url = "/file_rules?";
  const params = {
    ...(search && { search }),
  };

  url += encodeUrlParams(params);

  return url;
};
