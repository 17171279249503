import React, { useMemo } from "react";
import moment from "moment/moment";
import { formatNumberString } from "../../../../utils/formatNumberString";
import { marginToCoeff } from "../../../../utils/marginConverter";
import { formulaMarginRate } from "../../../../utils/formulaMarginRate";
import { currency_cents_to_euros } from "../../../utils";

export const InitialValues = ({
  isUsingMargin,
  calculMethod,
  initialOuvrage,
  from,
  categories,
  singleItemToCascader,
  opportunityType,
}) =>
  useMemo(() => {
    if (!initialOuvrage)
      return { maintenance: opportunityType === "maintenance" };
    const margin_rate = formatNumberString({
      str: marginToCoeff({
        marginRate: initialOuvrage.margin_rate,
        isUsingMargin,
        calculMethod,
      }),
      isCoeff: !isUsingMargin,
    });
    const date_validity = initialOuvrage.date_validity
      ? moment(initialOuvrage.date_validity)
      : undefined;
    const picture = initialOuvrage.picture
      ? [
          {
            uid: "1",
            name: "image",
            status: "done",
            url: initialOuvrage.picture,
          },
        ]
      : [];
    return {
      ...initialOuvrage,
      name: initialOuvrage.name,
      description: initialOuvrage.description,
      maintenance: initialOuvrage.maintenance,
      margin_rate,
      cost_price_cents: formatNumberString({
        str: currency_cents_to_euros(initialOuvrage.cost_price_cents),
        nbDecimal: 2,
        space: true,
      }),
      category_profession_id: singleItemToCascader({
        childItem: categories?.find(
          (el) => el.id === initialOuvrage.category_profession_id
        ),
        items: categories,
        keyParent: "parent_category_id",
      }),
      reference_code: initialOuvrage.reference_code,
      unity_profession_id: initialOuvrage.unity_profession_id,
      date_validity,
      drive_link: initialOuvrage.drive_link,
      // material_position: initialOuvrage.material_position,
      // manufacturer_id: initialOuvrage.manufacturer_id,
      // distributor_id: initialOuvrage.distributors,
      picture,
      material_included_name:
        initialOuvrage.material_included?.name || initialOuvrage.name,
      material_included_reference_code:
        initialOuvrage.material_included?.reference_code ||
        initialOuvrage.reference_code,
      is_in_framework_agreement: initialOuvrage.is_in_framework_agreement,
      selling_price_cents: formatNumberString({
        str: currency_cents_to_euros(initialOuvrage.cost_price_cents),
        nbDecimal: 2,
        space: true,
      }),
      margin_rate_computed:
        from !== "frameworkAgreement"
          ? 0
          : formatNumberString({
              str: formulaMarginRate({
                costPrice: initialOuvrage.cost_price_cents,
                calculMethod,
                sellingPrice:
                  initialOuvrage.material_included?.selling_price_cents,
                isUsingMargin,
              }),
              isCoeff: !isUsingMargin,
              space: true,
            }),
    };
  }, [initialOuvrage, calculMethod, from, isUsingMargin]);
