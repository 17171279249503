import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import Arrow from "./Icons/ArrowDown";
import TitleContainer from "./TitleContainer";

function CollapsibleTitle({ children, titleChildren = null, ...titleProps }) {
  const [collapse, setCollapse] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef();

  useEffect(() => {
    let RO = new ResizeObserver(() => setHeight(ref.current.offsetHeight));
    RO.observe(ref.current);

    return () => {
      RO.disconnect();
      RO = null;
    };
  }, [ref]);

  return (
    <Container>
      <StyledTitleContainer
        {...titleProps}
        down={!collapse}
        onClick={() => setCollapse(!collapse)}
      >
        {titleChildren}
        <StyledArrow down={!collapse} />
      </StyledTitleContainer>
      <CollapseWrapper down={!collapse} height={height}>
        <Wrapper ref={ref}>{children}</Wrapper>
      </CollapseWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

const CollapseWrapper = styled.div`
  border: 1px solid #dee2e6;
  border-top: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: max-height 0.4s;
  max-height: ${({ down, height }) => (down ? `${height}px` : "0")};
  overflow: hidden;
  background-color: white;
`;

const Wrapper = styled.div`
  padding: 16px;
`;

const StyledTitleContainer = styled(TitleContainer)`
  cursor: pointer;
  margin-bottom: 0;
  transition: border-bottom-left-radius 0.4s, border-bottom-right-radius 0.4s;
  ${({ down }) =>
    down &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const StyledArrow = styled(Arrow)`
  font-weight: 900;
  color: white;
  font-size: 22px;
  margin-top: auto;
  transition: transform 0.4s;
  transform: ${({ down }) => (down ? "rotate(0)" : "rotate(-180deg)")};
`;

export default CollapsibleTitle;
