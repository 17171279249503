import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled from "styled-components";

import { Tabs } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getData, postData } from "../../request/instance";
import { useStore } from "../../store";
import { deepEqual } from "./FilesImport";
import GeneralTab from "./General/GeneralTab";
import MovingCarrycotsTab from "./MovingCarrycots/MovingCarrycotsTab";
import OpportunityTab from "./Opportunity/OpportunityTab";
import SelectionItemsTab from "./PageItemsSelection/SelectionItemsTab";
import SellingTab from "./Selling/SellingTab";

const selector = (state) => ({
  formToken: state.formToken,
  setApiProvider: state.setApiProvider,
  config: state.configurations,
  setConfig: state.setConfigurations,
  setVocabulary: state.setVocabulary,
  vocabulary: state.vocabulary,
});

function UserInterface({ props, management_special_TVA }) {
  const queryClient = useQueryClient();
  const { setConfig, config, formToken, vocabulary, setVocabulary } =
    useStore(selector);
  const { data: prevConfig } = useQuery("getPrevConfig", () =>
    getData(
      formToken,
      `/company_work_configuration/${props?.company_work_config_id}`
    )
  );

  const { data: prevVocab } = useQuery("getPrevVocab", () =>
    getData(
      formToken,
      `/company_work_vocabulary/${props?.company_work_vocabulary_id}`
    )
  );

  const { mutate: changeConfiguration, isLoading: isConfigurationLoading } =
    useMutation(
      (todoData) =>
        postData(
          formToken,
          `/company_work_configuration/${props?.company_work_config_id}/edit`,
          todoData
        ),
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getPrevConfig");
        },
        onError: (error) => {
          console.log("ERROR ====>", error);
          setConfig(prevConfig);
        },
      }
    );

  const { mutate: changeVocabulary, isLoading: isVocabularyLoading } =
    useMutation(
      (todoData) =>
        postData(
          formToken,
          `/company_work_vocabulary/${props?.company_work_vocabulary_id}/edit`,
          todoData
        ),
      {
        onSuccess: (payload) => {
          queryClient.invalidateQueries("getPrevVocab");
        },
        onError: (error) => {
          console.log("ERROR ====>", error);
          setVocabulary(prevVocab);
        },
      }
    );

  const handleMutateConfiguration = () => {
    if (
      prevConfig &&
      prevConfig !== config &&
      !deepEqual(prevConfig, config) &&
      !isConfigurationLoading
    ) {
      changeConfiguration({
        content: config,
        company_work_id: props?.company_work_id,
      });
    }
  };

  const handleMutateVocabulary = () => {
    if (
      prevVocab &&
      prevVocab !== vocabulary &&
      !deepEqual(prevVocab, vocabulary) &&
      !isVocabularyLoading
    ) {
      changeVocabulary({
        content: vocabulary,
        company_work_id: props?.company_work_id,
      });
    }
  };

  useEffect(() => {
    if (prevConfig) {
      handleMutateConfiguration();
    }
  }, [config]);

  useEffect(() => {
    if (prevVocab) {
      handleMutateVocabulary();
    }
  }, [vocabulary]);

  const items = [
    {
      label: "Général",
      key: "General",
      children: (
        <GeneralTab
          props={props}
          configuration={config}
          setConfiguration={setConfig}
          vocabulary={vocabulary}
          setVocabulary={setVocabulary}
          formToken={formToken}
        />
      ),
    },
    {
      label: "Opportunité",
      key: "Opportunity",
      children: (
        <OpportunityTab
          props={props}
          configuration={config}
          setConfiguration={setConfig}
          management_special_TVA={management_special_TVA}
          HTML5Backend={HTML5Backend}
        />
      ),
    },
    {
      label: "Page de sélection des items",
      key: "Item selection",
      disabled: !config.costing.page_1.visible,
      children: (
        <SelectionItemsTab
          props={props}
          configuration={config}
          setConfiguration={setConfig}
          management_special_TVA={management_special_TVA}
          HTML5Backend={HTML5Backend}
        />
      ),
    },
    {
      label: "Page de déplacements et nacelles",
      key: "Moving",
      disabled: !config.costing.page_2.visible,
      children: (
        <MovingCarrycotsTab
          configuration={config}
          setConfiguration={setConfig}
        />
      ),
    },
    {
      label: "Page des prix de vente",
      key: "Selling",
      disabled: !config.costing.page_3.visible,
      children: (
        <SellingTab configuration={config} setConfiguration={setConfig} />
      ),
    },
  ];
  return (
    <Container>
      <DndProvider backend={HTML5Backend}>
        <StyledTabs
          defaultActiveKey="General"
          type="card"
          style={{
            width: "100%",
            overflow: "auto",
            position: "unset",
          }}
          destroyInactiveTabPane
          items={items}
        />
      </DndProvider>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
  background: rgba(255, 255, 255, 1);
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0px !important;
    display: flex;
    align-self: end;
    width: fit-content;
  }

  .ant-tabs-nav-list {
    margin-top: 0px !important;
    .ant-tabs-tab {
      border-radius: 0px !important;
      border-top: 0px !important;
    }

    .ant-tabs-tab-active {
      background: rgba(255, 255, 255, 1) !important;
      .ant-tabs-tab-btn {
        color: rgba(24, 144, 255, 1) !important;
      }
    }
  }
`;

export default UserInterface;
