import React from "react";
import styled from "styled-components";
import Warnings from "../warnings";
import DocSa from "../documents-and-simplified";

function RightBreadcrumb({
  formToken,
  opportunity_id,
  response_type,
  work,
  activePage,
  setWarnings,
  without_warnings = false,
  site_typologies,
  opportunity_name,
  offer_name,
  offer_id,
  supervisor,
  opportunity_chrono,
  opportunity_work,
  documents_disabled,
}) {
  return (
    <Container>
      {!without_warnings && (
        <Warnings
          opportunity_id={opportunity_id}
          response_type={response_type}
          work={work}
          activePage={activePage}
          formToken={formToken}
          setWarnings={setWarnings}
        />
      )}
      <DocSa
        opportunity_id={opportunity_id}
        formToken={formToken}
        siteTypologies={site_typologies}
        opportunity_name={opportunity_name}
        opportunity_chrono={opportunity_chrono}
        offer_name={offer_name}
        offer_id={offer_id}
        supervisor={supervisor}
        opportunity_work={opportunity_work}
        documents_disabled={documents_disabled}
      />
    </Container>
  );
}

const Container = styled.div`
  margin-left: auto;
  margin-right: 2px;
  display: flex;
  gap: 5px;
  align-items: center;
`;

export default RightBreadcrumb;
