import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import FormattedInput from "../../../react-ui/FormattedInput";
import TitleContainer from "../../../react-ui/TitleContainer";
import Label from "../../../Form/Label";
import WeeklyCalendar from "./WeeklyCalendar";
import { getData, postData } from "../../../request/instance";
import { useStore } from "../../../store";
import { Loading } from "../../../loading";
import WorkPeriodForm from "./WorkPeriodForm";
import WeeklyHours from "./WeeklyHours";
import MonthlyCalendar from "./MonthlyCalendar";
import WorkedDays from "./WorkedDays";
import TotalHours from "./TotalHours";
import SpecialPlannings from "./SpecialPlannings";
import { formatInitialWorkPeriods } from "../../../../utils/planning";

const selector = (state) => ({
  formToken: state.formToken,
});

function PlanningSection({
  selectedWorkPosition,
  updateWorkPosition,
  updateWorkPositionApi,
  nightTimeStart,
  nightTimeEnd,
  quotationStartDate,
  quotationEndDate,
  opportunityId,
  setWorkPositionsLocal,
  configApplication,
  isLockedByUser,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const [workPeriodsModalObj, setWorkPeriodsModalObj] = useState();
  const [isSpecialPlanningsOpen, setIsSpecialPlanningsOpen] = useState(false);
  const [selectedSp, setSelectedSp] = useState();
  const [spStatus, setSpStatus] = useState();

  const { data: planning } = useQuery(
    ["Planning", { workPositionId: selectedWorkPosition?.id }],
    () =>
      getData(
        formToken,
        `/planning?work_position_id=${selectedWorkPosition.id}`
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedWorkPosition,
    }
  );

  const { data: monthlyCalendar } = useQuery(
    ["MonthlyCalendar", { workPositionId: selectedWorkPosition?.id }],
    () =>
      getData(
        formToken,
        `/work_position/monthly_calendar?id=${selectedWorkPosition?.id}`
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedWorkPosition,
    }
  );

  const { data: specialPlannings } = useQuery(
    ["SpecialPlannings", { workPositionId: selectedWorkPosition?.id }],
    () =>
      getData(
        formToken,
        `/special_plannings?work_position_id=${selectedWorkPosition?.id}`
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedWorkPosition,
    }
  );

  const { mutate: updateWposition, status: workPosStatus } = useMutation(
    (data) => postData(formToken, `/work_position/update_partial`, data),
    {
      onSuccess: (payload) => {
        updateWorkPosition({
          id: payload.id,
          planning_name: payload.planning.name,
          description: payload.description,
        });
        updateWorkPositionApi({
          id: payload.id,
          planning_name: payload.planning.name,
          description: payload.description,
        });
      },
    }
  );

  const {
    mutate: destroyAndCreateWPeriod,
    status: workPerStatus,
    isLoading,
  } = useMutation(
    (data) => postData(formToken, `/work_period/destroy_and_create`, data),
    {
      onSuccess: ({ is_planning_empty, work_periods }) => {
        queryClient.setQueryData(
          ["WorkPeriods", { workPositionId: selectedWorkPosition?.id }],
          formatInitialWorkPeriods(work_periods)
        );
        queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
        setWorkPositionsLocal((state) => {
          const newWp = [...state];
          const index = state.findIndex(
            (el) => el.id === selectedWorkPosition?.id
          );

          newWp[index] = {
            ...newWp[index],
            is_planning_empty,
          };
          return newWp;
        });
      },
    }
  );

  const {
    mutate: deleteWorkPeriod,
    status: delWorkPerStatus,
    isLoading: isDelWorkPeriodLoading,
  } = useMutation((data) => postData(formToken, `/work_period/delete`, data), {
    onSuccess: ({ is_planning_empty, work_periods }) => {
      queryClient.setQueryData(
        ["WorkPeriods", { workPositionId: selectedWorkPosition?.id }],
        formatInitialWorkPeriods(work_periods)
      );
      queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
      setWorkPositionsLocal((state) => {
        const newWp = [...state];
        const index = state.findIndex(
          (el) => el.id === selectedWorkPosition?.id
        );

        newWp[index] = {
          ...newWp[index],
          is_planning_empty,
        };
        return newWp;
      });
    },
  });

  const getStatus = () => {
    if (
      workPosStatus === "loading" ||
      workPerStatus === "loading" ||
      delWorkPerStatus === "loading"
    )
      return "loading";
    if (
      workPosStatus === "success" ||
      workPerStatus === "success" ||
      delWorkPerStatus === "success"
    )
      return "success";
    return "idle";
  };

  return (
    <Container>
      <StyledModal
        width={840}
        maskClosable={false}
        open={!!workPeriodsModalObj}
        footer={null}
        closable={false}
        onCancel={() => setWorkPeriodsModalObj()}
        destroyOnClose
      >
        {!!workPeriodsModalObj && (
          <WorkPeriodForm
            workPeriodsModalObj={workPeriodsModalObj}
            setWorkPeriodsModalObj={setWorkPeriodsModalObj}
            destroyAndCreateWPeriod={destroyAndCreateWPeriod}
            workPositionId={selectedWorkPosition.id}
            planningId={selectedWorkPosition.planning_id}
            deleteWorkPeriod={deleteWorkPeriod}
          />
        )}
      </StyledModal>
      <PlanningModal
        width="100%"
        maskClosable={false}
        open={isSpecialPlanningsOpen}
        footer={null}
        closable
        onCancel={() => {
          setSpStatus();
          setSelectedSp();
          setIsSpecialPlanningsOpen(false);
        }}
        destroyOnClose
      >
        {isSpecialPlanningsOpen && (
          <SpecialPlannings
            quotationStartDate={quotationStartDate}
            quotationEndDate={quotationEndDate}
            monthlyCalendar={monthlyCalendar}
            workPositionId={selectedWorkPosition.id}
            specialPlannings={specialPlannings}
            selectedSp={selectedSp}
            setSelectedSp={setSelectedSp}
            spStatus={spStatus}
            setSpStatus={setSpStatus}
            opportunityId={opportunityId}
            setWorkPositionsLocal={setWorkPositionsLocal}
            configApplication={configApplication}
            isLockedByUser={isLockedByUser}
          />
        )}
      </PlanningModal>
      <StyledTitleContainer
        label={
          !selectedWorkPosition
            ? "Aucun planning sélectionné"
            : selectedWorkPosition.title
        }
        noBorderBottom
      >
        <Loading status={getStatus()} />
      </StyledTitleContainer>
      {selectedWorkPosition && (
        <Wrapper key={selectedWorkPosition.id}>
          <InputWrapper>
            <StyledLabel label="Description de la mission" />
            <FormattedInput
              value={selectedWorkPosition.description}
              size="middle"
              keyName="description"
              itemId={selectedWorkPosition.id}
              updateItem={(values) => updateWposition({ ...values })}
              disabled={
                configApplication.guarding.workpositions.disable_description ||
                !isLockedByUser
              }
            />
          </InputWrapper>
          <InputWrapper>
            <StyledLabel label="Nom du planning" rules={[{ required: true }]} />
            <FormattedInput
              value={selectedWorkPosition.planning_name}
              size="middle"
              keyName="planning_name"
              itemId={selectedWorkPosition.id}
              isRequired
              disabled={
                configApplication.guarding.workpositions
                  .disable_planning_name || !isLockedByUser
              }
              updateItem={({ planning_name, ...values }) =>
                planning_name
                  ? updateWposition({ ...values, planning_name })
                  : undefined
              }
            />
          </InputWrapper>
          <WeeklyCalendar
            planningId={selectedWorkPosition.planning_id}
            destroyAndCreateWPeriod={destroyAndCreateWPeriod}
            workPositionId={selectedWorkPosition.id}
            nightTimeStart={nightTimeStart}
            nightTimeEnd={nightTimeEnd}
            newWpIsLoading={isLoading}
            isDelWorkPeriodLoading={isDelWorkPeriodLoading}
            setWorkPeriodsModalObj={setWorkPeriodsModalObj}
            configApplication={configApplication}
            isLockedByUser={isLockedByUser}
          />
          <WeeklyHours planning={planning} />
          <MonthlyCalendar
            workPositionId={selectedWorkPosition.id}
            planningId={selectedWorkPosition.planning_id}
            setIsSpecialPlanningsOpen={setIsSpecialPlanningsOpen}
            monthlyCalendar={monthlyCalendar}
            specialPlannings={specialPlannings}
            setSelectedSp={setSelectedSp}
            setSpStatus={setSpStatus}
            opportunityId={opportunityId}
            setWorkPositionsLocal={setWorkPositionsLocal}
            configApplication={configApplication}
            isLockedByUser={isLockedByUser}
          />
          <WorkedDays planning={planning} />
          <TotalHours planning={planning} />
        </Wrapper>
      )}
    </Container>
  );
}

const StyledTitleContainer = styled(TitleContainer)`
  position: sticky;
  top: 0;
  z-index: 999;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;

const Wrapper = styled.div`
  padding-left: 4px;
  padding-right: 4px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const StyledLabel = styled(Label)`
  margin-right: 8px;
  white-space: nowrap;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 2.5px !important;
  }
`;

const PlanningModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 2.5px !important;
    padding-top: 50px !important;
    min-height: 500px;
    background-color: #f5f6f8;
  }
`;

export default PlanningSection;
