import { notification } from "antd";
import React, { useCallback, useState, useMemo, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import ReactPlayer from "react-player";
import { getData, putData } from "../../request/instance";
import { useStore } from "../../store";
import PlanningSection from "./PlanningSection";
import WorkPositionSection from "./WorkPositionSection";
import { style_tour, locale_tour } from "../../tour/tour_style";

const selector = (state) => ({
  formToken: state.formToken,
});

const workPositionsAreEqual = (oldWorkPosition, newWorkPosition) =>
  oldWorkPosition.title === newWorkPosition.title &&
  oldWorkPosition.quantity === newWorkPosition.quantity &&
  oldWorkPosition.profil_id === newWorkPosition.profil_id;

function WorkPositions({
  profils = [],
  plannings = [],
  opportunityId,
  chrono,
  nightTimeStart,
  nightTimeEnd,
  quotationStartDate,
  quotationEndDate,
  configApplication,
  isLockedByUser,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const [selectedWorkPositionId, setSelectedWorkPositionId] = useState();
  const [workPositionsLocal, setWorkPositionsLocal] = useState([]);
  const [worksPositionsApi, setWorksPositionsApi] = useState([]);
  const { isLoading: isLoadingWorkPositions } = useQuery(
    "WorkPositions",
    () =>
      getData(
        formToken,
        `work_positions_from_opp?opportunity_id=${opportunityId}`
      ),
    {
      refetchOnWindowFocus: false,
      onSuccess: (payload) => {
        setWorksPositionsApi(payload);
        setWorkPositionsLocal([
          ...payload.map((el) => ({
            ...el,
            key: el.id,
            planning_name: el.planning.name,
          })),
        ]);
      },
    }
  );

  const { mutate: updateWorkPositions, isLoading } = useMutation(
    (data) => putData(formToken, `update_work_positions`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("WorkPositions");
        queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
      },
    }
  );

  const { mutate: updateWorkPositionsStatus } = useMutation(
    (data) => putData(formToken, `update_work_positions_status`, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("WorkPositions");
        queryClient.invalidateQueries(["OpportunityStep", { opportunityId }]);
      },
    }
  );

  const { refetch: refetchTour } = useQuery(
    "Tour",
    () => getData(formToken, `/verify_tour?tour_name="work_position"`),
    {
      onSuccess: (res) => {
        if (res.success === true) {
          setRun(true);
        } else {
          setRun(false);
        }
      },
      refetchOnWindowFocus: false, // Disable refetch on window focus to avoid unwanted refetches
      refetchInterval: false, // Disable refetch interval to avoid unwanted refetches
      refetchIntervalInBackground: false, // Disable refetch interval in background to avoid unwanted refetches
      retry: false, // Disable retry on error to avoid unwanted refetches
    }
  );

  useEffect(() => {
    refetchTour();
  }, [refetchTour]);

  const [steps, setSteps] = useState([
    {
      title: <h2>Nouvelle page du dispositif</h2>,
      content: (
        <ReactPlayer
          url="https://www.youtube.com/watch?v=EdAw1UiqzKY&ab_channel=SmartProposal"
          loop
          controls
          width="60vw"
          height="70vh"
          playing
          autoplay
          muted
        />
      ),
      placement: "center",
      target: "body",
    },
  ]);
  const [run, setRun] = useState(false);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };

  const saveWorkPositions = useCallback(
    (callback) => {
      const formData = new FormData();
      let error = false;

      workPositionsLocal.forEach((el, idx) => {
        if (
          !el.destroy &&
          (!el.title || el.quantity === undefined || !el.planning_name)
        )
          error = true;
        const key = `opportunity[work_positions_attributes][${idx}]`;

        if (el.id) formData.append(`${key}[id]`, el.id);
        if (el.destroy) formData.append(`${key}[_destroy]`, "true");
        formData.append(`${key}[title]`, el.title);
        formData.append(`${key}[quantity]`, el.quantity);
        formData.append(`${key}[profil_id]`, el.profil_id);
        formData.append(`${key}[planning_id]`, el.planning_id);
      });

      if (error) {
        notification.error({
          description: "Veuillez remplir tous les champs obligatoires",
          placement: "bottom",
          duration: 5,
        });
        return;
      }

      updateWorkPositions(formData, {
        onSuccess: callback,
      });
    },
    [updateWorkPositions, workPositionsLocal]
  );

  const saveWorkPositionsStatus = useCallback(
    (callback) => {
      const formData = new FormData();
      let error = false;

      workPositionsLocal.forEach((el, idx) => {
        if (
          !el.destroy &&
          (!el.title || el.quantity === undefined || !el.planning_name)
        )
          error = true;
        const key = `opportunity[work_positions_attributes][${idx}]`;

        if (el.id) formData.append(`${key}[id]`, el.id);
        if (el.destroy) formData.append(`${key}[_destroy]`, "true");
        formData.append(`${key}[title]`, el.title);
        formData.append(`${key}[quantity]`, el.quantity);
        formData.append(`${key}[profil_id]`, el.profil_id);
        formData.append(`${key}[planning_id]`, el.planning_id);
      });

      if (error) {
        notification.error({
          description: "Veuillez remplir tous les champs obligatoires",
          placement: "bottom",
          duration: 5,
        });
        return;
      }

      updateWorkPositionsStatus(formData, {
        onSuccess: callback,
      });
    },
    [updateWorkPositionsStatus, workPositionsLocal]
  );

  const updateWorkPosition = useCallback(
    ({ id, ...toUpdate }) => {
      setWorkPositionsLocal((state) => {
        const updatedWorkPositions = [...state];
        const idx = state.findIndex((el) => `${el.key}` === `${id}`);
        updatedWorkPositions[idx] = {
          ...updatedWorkPositions[idx],
          ...toUpdate,
        };

        const oldWorkPosition = worksPositionsApi.find(
          (el) => el.id === updatedWorkPositions[idx].id
        );
        if (workPositionsAreEqual(oldWorkPosition, updatedWorkPositions[idx]))
          updatedWorkPositions[idx].hasBeenModified = false;
        else updatedWorkPositions[idx].hasBeenModified = true;

        return [...updatedWorkPositions];
      });
    },
    [worksPositionsApi]
  );

  const updateWorkPositionApi = useCallback(({ id, ...toUpdate }) => {
    setWorksPositionsApi((state) => {
      const updatedWorkPositions = [...state];
      const idx = state.findIndex((el) => `${el.id}` === `${id}`);
      updatedWorkPositions[idx] = {
        ...updatedWorkPositions[idx],
        ...toUpdate,
      };

      return [...updatedWorkPositions];
    });
  }, []);

  const nightTimeStartFormatted = useMemo(() => {
    const nightTimeStartDate = new Date(nightTimeStart);
    const startHour =
      (nightTimeStartDate.getUTCHours() < 10 ? "0" : "") +
      nightTimeStartDate.getUTCHours();
    const startMinutes =
      (nightTimeStartDate.getMinutes() < 10 ? "0" : "") +
      nightTimeStartDate.getMinutes();
    return `${startHour}:${startMinutes}`;
  }, [nightTimeStart]);

  const nightTimeEndFormatted = useMemo(() => {
    const nightTimeEndDate = new Date(nightTimeEnd);
    const endHour =
      (nightTimeEndDate.getUTCHours() < 10 ? "0" : "") +
      nightTimeEndDate.getUTCHours();
    const endMinutes =
      (nightTimeEndDate.getMinutes() < 10 ? "0" : "") +
      nightTimeEndDate.getMinutes();
    if (`${endHour}:${endMinutes}` === "00:00") return "24:00";
    return `${endHour}:${endMinutes}`;
  }, [nightTimeEnd]);
  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        showSkipButton
        steps={steps}
        locale={locale_tour}
        styles={style_tour}
        disableOverlayClose
      />
      <Container>
        <Wrapper>
          <WorkPositionSection
            profils={profils}
            workPositionsLocal={workPositionsLocal}
            setWorkPositionsLocal={setWorkPositionsLocal}
            isLoadingWorkPositions={isLoadingWorkPositions}
            isLoading={isLoading}
            saveWorkPositions={saveWorkPositions}
            saveWorkPositionsStatus={saveWorkPositionsStatus}
            plannings={plannings}
            selectedWorkPositionId={selectedWorkPositionId}
            setSelectedWorkPositionId={setSelectedWorkPositionId}
            worksPositionsApi={worksPositionsApi}
            updateWorkPosition={updateWorkPosition}
            opportunityId={opportunityId}
            setWorksPositionsApi={setWorksPositionsApi}
            configApplication={configApplication}
            chrono={chrono}
            isLockedByUser={isLockedByUser}
          />
        </Wrapper>
        <Wrapper className="wrapper__planning-section">
          <PlanningSection
            updateWorkPosition={updateWorkPosition}
            updateWorkPositionApi={updateWorkPositionApi}
            selectedWorkPosition={workPositionsLocal?.find(
              (el) => el.id === selectedWorkPositionId
            )}
            workPositionsLocal={workPositionsLocal}
            nightTimeStart={nightTimeStartFormatted}
            nightTimeEnd={nightTimeEndFormatted}
            quotationStartDate={quotationStartDate}
            quotationEndDate={quotationEndDate}
            opportunityId={opportunityId}
            setWorkPositionsLocal={setWorkPositionsLocal}
            configApplication={configApplication}
            isLockedByUser={isLockedByUser}
          />
        </Wrapper>
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  height: 76vh;
`;

const Wrapper = styled.div`
  flex: 1;
  background-color: white;
  border-radius: 2.5px;
  min-width: 500px;
  max-width: 100%;
  padding-bottom: 10px;
  height: 100%;
`;

export default WorkPositions;
