import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { Col, Form as AntdForm, Row } from "antd";
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from "prop-types";
import styled from "styled-components";
import Input from "./Input";
import Upload from "./Upload";
import Select from "./Select";
import TextArea from "./TextArea";
import InputNumber from "./InputNumber";
import DatePicker from "./DatePicker";
import CheckBox from "./CheckBox";
import AddButton from "../react-ui/AddButton";
import Button from "../react-ui/Button";
import Cascader from "./Cascader";
import TreeSelect from "./TreeSelect";

const itemSelector = ({ type, item, handleUpdateOnChange, isShowing }) => {
  const key = item.name;
  switch (type) {
    case "input":
      return (
        <Input
          {...item}
          handleUpdateOnChange={handleUpdateOnChange}
          isShowing={isShowing}
          key={key}
        />
      );
    case "upload":
      return (
        <Upload
          {...item}
          handleUpdateOnChange={handleUpdateOnChange}
          isShowing={isShowing}
          key={key}
        />
      );
    case "select":
      return (
        <Select
          {...item}
          handleUpdateOnChange={handleUpdateOnChange}
          isShowing={isShowing}
          key={key}
          mode={item?.select?.mode ?? item.select.mode}
        />
      );
    case "textarea":
      return (
        <TextArea
          {...item}
          handleUpdateOnChange={handleUpdateOnChange}
          isShowing={isShowing}
          key={key}
        />
      );
    case "inputNumber":
      return (
        <InputNumber
          {...item}
          handleUpdateOnChange={handleUpdateOnChange}
          isShowing={isShowing}
          key={key}
        />
      );
    case "datePicker":
      return (
        <DatePicker
          {...item}
          handleUpdateOnChange={handleUpdateOnChange}
          isShowing={isShowing}
          key={key}
        />
      );
    case "checkbox":
      return (
        <CheckBox
          {...item}
          handleUpdateOnChange={handleUpdateOnChange}
          isShowing={isShowing}
          key={key}
        />
      );
    case "cascader":
      return (
        <Cascader
          {...item}
          handleUpdateOnChange={handleUpdateOnChange}
          isShowing={isShowing}
          key={key}
        />
      );
    case "treeSelect":
      return (
        <TreeSelect
          {...item}
          handleUpdateOnChange={handleUpdateOnChange}
          isShowing={isShowing}
          key={key}
        />
      );
    default:
      return null;
  }
};

const Form = forwardRef(
  (
    {
      submit,
      rows,
      updateOnChange,
      onSubmit,
      initialValues,
      isShowing,
      onValuesChange,
      id,
    },
    ref
  ) => {
    const [form] = AntdForm.useForm();
    useEffect(() => {
      form.resetFields();
      form.setFieldsValue(initialValues);
    }, [form, initialValues]);

    const handleCancel = () => {
      if (submit.onCancel) submit.onCancel();
      form.resetFields();
    };

    const handleSubmit = (e) => {
      if (onSubmit) onSubmit(e);
      if (submit) form.resetFields();
    };

    const handleUpdateOnChange = () => {
      if (updateOnChange) {
        form.submit();
      }
    };

    const setFieldsValue = (fields) => {
      form.setFieldsValue(fields);
    };

    useImperativeHandle(ref, () => ({
      setFieldsValue,
      form,
    }));

    const itemsChecker = ({ type, items, item }) => {
      if (type === "multiple") return items?.filter((el) => !!el);
      if (item) return [{ item, type }];
      return [];
    };

    return (
      <AntdForm
        id={id}
        form={form}
        onFinish={handleSubmit}
        initialValues={initialValues}
        onValuesChange={(changedValues, allValues) =>
          onValuesChange && onValuesChange(changedValues, allValues)
        }
        colon={false}
        requiredMark={false}
      >
        {submit && (
          <SubmitRow
            justify="center"
            align="end"
            backgroundcolor={submit.backgroundColor || "#fff"}
          >
            <CancelCol>
              <Button
                label="Annuler"
                onClick={handleCancel}
                fontSize="14px"
                btnType="cancel"
                type="button"
              />
            </CancelCol>
            <Col>
              <AddButton
                label="Créer"
                type="submit"
                value="submit"
                fontSize="14px"
              />
            </Col>
          </SubmitRow>
        )}

        {rows
          .filter((el) => el)
          .map(({ key, columns, justify = "space-between" }) => {
            if (!columns) return null;
            const formattedColumns = columns.filter((el) => el);
            return (
              <FormRow justify={justify} key={key}>
                {formattedColumns.map(({ item, items, type, span, style }) => {
                  const colItems = itemsChecker({ type, item, items });
                  if (!colItems || colItems.length < 1) return null;
                  const defaultSpan = Math.trunc(24 / formattedColumns.length);
                  return (
                    <FormCol
                      key={colItems.map((el) => el.item.name).join()}
                      span={span || defaultSpan}
                      style={style}
                    >
                      {colItems.map((el) =>
                        itemSelector({
                          type: el.type,
                          item: el.item,
                          handleUpdateOnChange,
                          isShowing,
                        })
                      )}
                    </FormCol>
                  );
                })}
              </FormRow>
            );
          })}
      </AntdForm>
    );
  }
);

const SubmitRow = styled(Row)`
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: ${({ backgroundcolor }) => backgroundcolor};
`;

const CancelCol = styled(Col)`
  margin-right: 20px;
`;

const FormRow = styled(Row)`
  margin-top: 10px;
`;

const FormCol = styled(Col)`
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  &:not(:first-of-type) {
    padding-left: 20px;
  }
`;

Form.propTypes = {
  submit: shape({
    onCancel: func,
    backgroundColor: string,
  }),
  onSubmit: func,
  updateOnChange: bool,
  rows: arrayOf(
    oneOfType([
      shape({
        columns: arrayOf(
          oneOfType([
            shape({
              item: object,
              // items handle case when you need multiple items on one col
              items: arrayOf(
                oneOfType([
                  shape({
                    item: object.isRequired,
                    type: oneOf([
                      "input",
                      "upload",
                      "select",
                      "textarea",
                      "inputNumber",
                      "datePicker",
                      "checkbox",
                      "cascader",
                      "treeSelect",
                    ]).isRequired,
                  }),
                  bool,
                ])
              ),
              type: oneOf([
                "input",
                "upload",
                "select",
                "textarea",
                "inputNumber",
                "datePicker",
                "checkbox",
                "cascader",
                "treeSelect",
                "multiple",
              ]).isRequired,
              span: number,
              style: object,
            }),
            bool,
          ])
        ),
        justify: string,
        align: string,
        key: string.isRequired,
      }),
      bool,
    ])
  ),
  initialValues: object,
  onValuesChange: func,
  isShowing: bool,
  id: string,
};

Form.defaultProps = {
  onValuesChange: undefined,
  submit: undefined,
  updateOnChange: false,
  rows: [],
  onSubmit: undefined,
  initialValues: {},
  isShowing: false,
  id: undefined,
};

export default Form;
