import React, { useMemo } from "react";
import { array, bool, func, object, shape, string } from "prop-types";
import Form from "../../Form";

function DefaultValueForm({
  isShowing,
  onSubmit,
  updateOnChange,
  submit,
  difficulties,
  majorations,
  initialDefaultValue,
  entity_work_name,
  configuration,
  vocabulary,
}) {
  const formFactory = useMemo(() => {
    const formatedDifficulties = difficulties.map((profil) => {
      return {
        title: profil.name,
        ...profil,
      };
    });
    const formatedMajorations = majorations.map((profil) => {
      return {
        title: profil.name,
        ...profil,
      };
    });
    const firstRow = [
      configuration.general.majoration && {
        type: "select",
        item: {
          select: {
            placeholder: "Choisissez une majoration",
          },
          options: formatedMajorations,
          name: "majoration_profession_id",
          label: "Majoration",
        },
      },
      {
        type: "select",
        item: {
          select: {
            placeholder: `Choisissez une ${vocabulary.difficulty_label}}`,
          },
          options: formatedDifficulties,
          name: "difficulty_profession_id",
          label: vocabulary.difficulty_label,
        },
      },
    ];

    return [
      {
        columns: firstRow,
        key: "majoration_profession_id+difficulty_profession_id",
      },
    ];
  }, [majorations, difficulties]);

  const initialValues = useMemo(() => {
    if (!initialDefaultValue) return {};
    return {
      majoration_profession_id: initialDefaultValue.majoration_profession_id,
      difficulty_profession_id: initialDefaultValue.difficulty_profession_id,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDefaultValue?.id]);

  return (
    <Form
      submit={submit}
      updateOnChange={updateOnChange}
      initialValues={initialValues}
      onSubmit={onSubmit}
      rows={formFactory}
      isShowing={isShowing}
    />
  );
}


DefaultValueForm.defaultProps = {
  isShowing: false,
  difficulties: [],
  majorations: [],
  onSubmit: undefined,
  updateOnChange: false,
  submit: undefined,
  initialDefaultValue: undefined,
  entity_work_name: "",
  configuration: {},
  vocabulary: {},
};

export default DefaultValueForm;
