/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Tree } from "antd";
import styled from "styled-components";
import Separator from "../../../react-ui/Separator";

function DescriptionRender(detailsElement, ouvrage_type) {
  const name_parser = (el) => {
    if ("name" in el) return el.name;
    if ("material_parent" in el) return el.material_parent.name;
    if ("prestation_parent" in el) return el.prestation_parent.name;
  };
  if (ouvrage_type === "ouvrage_batiprix") {
    return (
      <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
        {detailsElement && (
          <div style={{ margin: 5 }}>
            <b>Label</b>: {detailsElement?.Label}
          </div>
        )}
        {detailsElement && (
          <div style={{ margin: 5 }}>
            <b> Description</b>: {detailsElement?.Description}
          </div>
        )}
      </div>
    );
  }
  return (
    <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
      {detailsElement && (
        <div style={{ margin: 5 }}>
          <b>Label</b>: {name_parser(detailsElement)}
        </div>
      )}
      {detailsElement && (
        <div style={{ margin: 5 }}>
          <b> Description</b>: {detailsElement?.description}
        </div>
      )}
    </div>
  );
}

function DetailsOuvrage({ selectedItem, configuration, ouvrage_type }) {
  const [treeData, setTreeData] = useState([]);
  const [detailsElement, setDetailsElement] = useState(null);
  const itemSelector = (ElementType) => {
    if (ElementType === "O") {
      return <i className={configuration.general.ouvrage_icon} />;
    }
    if (ElementType === "M") {
      return <i className={configuration.general.product_icon} />;
    }
  };

  const itemSelectorProfession = (item) => {
    if (item?.is_ouvrage) {
      return <i className={configuration.general.ouvrage_icon} />;
    }
    if ("material_parent" in item || "material_position" in item) {
      return <i className={configuration.general.product_icon} />;
    }
    if ("prestation_parent" in item || "speed_quantity" in item) {
      return <i className={configuration.general.prestation_icon} />;
    }
  };

  const childrenPopulateProfession = (elements) => {
    if (elements.length === 0) return;

    const name_parser = (el) => {
      if ("name" in el) return el.name;
      if ("material_parent" in el) return el.material_parent.name;
      if ("prestation_parent" in el) return el.prestation_parent.name;
    };

    return elements.map((v) => ({
      title: (
        <div>
          <div onClick={() => setDetailsElement(v)}>
            {" "}
            {itemSelectorProfession(v)} {name_parser(v)}
          </div>
        </div>
      ),
      key: v.id,
      children:
        v.is_ouvrage &&
        childrenPopulateProfession([
          ...v?.child,
          ...v?.material_associates,
          ...v?.prestation_associates,
        ]),
    }));
  };

  const childrenPopulate = (elements) => {
    if (elements.length === 0) return;
    return elements.map((v) => ({
      title: (
        <div>
          <div onClick={() => setDetailsElement(v)}>
            {" "}
            {itemSelector(v.ElementType)} {v.Label}
          </div>
        </div>
      ),
      key: v.Id,
      children: childrenPopulate(v.Elements),
    }));
  };
  useEffect(() => {
    if (ouvrage_type === "ouvrage_batiprix") {
      setDetailsElement(selectedItem?.ouvrage);
      setTreeData([
        {
          title: (
            <div>
              <OuvrageTreeLabel>
                <i className={`${configuration.general.ouvrage_icon} mr-1`} />
                <div onClick={() => setDetailsElement(selectedItem.ouvrage)}>
                  {selectedItem.ouvrage.Label}
                </div>
              </OuvrageTreeLabel>
            </div>
          ),
          key: selectedItem.ouvrage.Id,
          children: childrenPopulate(selectedItem.ouvrage.Elements),
        },
      ]);
    } else {
      setDetailsElement(selectedItem);
      const child = selectedItem?.child || [];
      const materialAssociates = selectedItem?.material_associates || [];
      const prestationAssociates = selectedItem?.prestation_associates || [];

      const itemsToPass = [
        ...child,
        ...materialAssociates,
        ...prestationAssociates,
      ];
      setTreeData([
        {
          title: (
            <div>
              <OuvrageTreeLabel>
                <i className={`${configuration.general.ouvrage_icon} mr-1`} />
                <div onClick={() => setDetailsElement(selectedItem.ouvrage)}>
                  {selectedItem.name}
                </div>
              </OuvrageTreeLabel>
            </div>
          ),
          key: selectedItem.id,
          children: childrenPopulateProfession(itemsToPass),
        },
      ]);
    }
  }, [selectedItem]);
  return (
    <Container style={{ height: 600 }}>
      <div style={{ width: "50%" }}>
        <Tree
          defaultExpandAll
          showLine
          switcherIcon={<DownOutlined />}
          treeData={treeData}
        />
      </div>
      <Separator />
      {DescriptionRender(detailsElement, ouvrage_type)}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  padding-bottom: 10px;
  height: 100%;
`;

const OuvrageTreeLabel = styled.div`
  display: flex;
  justify-content: column;
`;
export default DetailsOuvrage;
