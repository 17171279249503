import React from "react";

const iconSorter = (
  <span
    class="ant-table-column-sorter-inner"
    style={{ display: "inline-grid" }}
  >
    <span
      role="presentation"
      aria-label="caret-up"
      class="anticon anticon-caret-up ant-table-column-sorter-up"
    >
      <svg
        viewBox="0 0 1024 1024"
        focusable="false"
        data-icon="caret-up"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
      </svg>
    </span>
    <span
      role="presentation"
      aria-label="caret-down"
      class="anticon anticon-caret-down ant-table-column-sorter-down"
    >
      <svg
        viewBox="0 0 1024 1024"
        focusable="false"
        data-icon="caret-down"
        width="1em"
        height="1em"
        fill="currentColor"
        aria-hidden="true"
      >
        <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
      </svg>
    </span>
  </span>
);
const iconFilter = (
  <span role="img" aria-label="filter" class="anticon anticon-filter">
    <svg
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="filter"
      width="1em"
      height="1em"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"></path>
    </svg>
  </span>
);
export const content_offer_tour = [
  {
    title: (
      <h2>Découvrez la nouvelle page de l’offre et de ses opportunités</h2>
    ),
    content: (
      <div style={{ textAlign: "left" }}>
        Nouveautés majeures : <br />
        <ul>
          <li>Possibilité de filtrer et trier l’affichage.</li>
          <li>
            Possibilité de modifier le propriétaire d’une offre et d'une
            opportunité (en fonction des droits utilisateurs).
          </li>
        </ul>
      </div>
    ),
    placement: "center",
    target: "body",
  },

  {
    title: <h2>Propriétaire de l'offre</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Vous pouvez modifier le propriétaire d’une offre.
        <br /> <br />
        C’est utile si l'offre est créée par une personne qui ne sera pas en
        charge de cette offre, par exemple un.e assistant.e de direction qui a
        récupéré un lead et qui veut le transmettre à un commercial.
        <br /> <br />
        C'est également utile si le commercial A a travaillé sur une offre mais
        que cette offre est récupérée par le commercial B (suite au départ du
        commercial A de l’entreprise par exemple).
      </div>
    ),
    spotlightPadding: 5,
    target: ".select__offer-owner",
  },
  {
    title: <h2> Pipe commercial</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Si vous décochez cette case, alors l’opportunité concernée ne sera plus
        prise en compte au niveau de vos indicateurs commerciaux.
        <br />
        C’est utile notamment pour gérer le versioning d’une même opportunité.
        <br />
        <br />
        Exemple:
        <br />
        <ul>
          <li>Vous avez créé une opportunité “Chiffrage V1”.</li>
          <li>
            Vous devez créer une opportunité “Chiffrage V2” suite à une
            modification du cahier des charges. L’opportunité V1 devient alors
            obsolète.
          </li>
          <li>
            En décochant la case, votre opportunité V1 restera toujours
            accessible, mais elle ne sera plus prise en compte dans vos
            différents indicateurs commerciaux (KPIs).
          </li>
          <li>
            Si finalement le donneur d’ordres décide de retenir votre
            opportunité V1, il vous suffira de la re-sélectionner et de
            dé-selectionner la V2.
          </li>
        </ul>
        Lorsqu’une opportunité est “perdue” ou “abandonnée”, il ne faut pas
        forcément décocher la case “pipe commercial”.
        <br />
        En effet, pour que votre reporting soit précis, c’est toujours
        intéressant de remonter les informations des opportunités perdues ou
        abandonnées dans vos indicateurs commerciaux.
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__header-pipe",
  },
  {
    title: <h2>Filtre et tri “entreprise facturée”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce filtre vous permet de modifier l’ordre d'affichage des opportunités
        en fonction de l'entreprise facturée: <br />
        <br />
            {iconSorter}   Classement des entreprises par ordre alphabétique
        croissant ou décroissant.
        <br />
            {iconFilter}   Affichage uniquement des entreprises sélectionnées.
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__column-client",
  },
  {
    title: <h2>Tri par “date limite de réponse”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce tri vous permet de modifier l’ordre d'affichage des opportunités en
        fonction de leur date limite de réponse: <br />
        <br />
            {iconSorter}   Classement chronologique des opportunités par ordre
        croissant ou décroissant.
        <br />
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__header-delivery-date",
  },
  {
    title: <h2>Tri par “chiffre d’affaires”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce tri vous permet de modifier l’ordre d'affichage des opportunités en
        fonction du montant de leur chiffre d’affaires: <br />
        <br />
            {iconSorter}   Classement des opportunités par montant de CA
        croissant ou décroissant.
        <br />
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__header-turnover",
  },
  {
    title: <h2>Tri par “agence(s)”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce tri vous permet de modifier l’ordre d'affichage des opportunités en
        fonction de l’agence concernée: <br />
        <br />
            {iconSorter}   Classement des opportunités par agences par ordre
        alphabétique croissant ou décroissant.
        <br />
            {iconFilter}   Affichage uniquement des opportunités relatives aux
        agences sélectionnées.
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__header-entity-name",
  },
  {
    title: <h2>Colonne “Propriétaire”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Vous pouvez ici modifier le propriétaire d’une opportunité directement
        depuis le sélecteur.
        <br />
        <br />
        Pourquoi y-a-t-il des propriétaires pour les offres et des propriétaires
        pour les opportunités ?<br />
        En règle générale, le propriétaire de chaque opportunité devrait être
        similaire au propriétaire de l’offre concernée.
        <br />
        Mais cela n’est pas forcément toujours le cas.
        <br />
        <br />
        Exemple :<br />
        Vous répondez à un appel d’offres multi-lots national (un lot par
        région).
        <br />
        Un Commercial Grands Comptes pilote l’appel d’offres au niveau national,
        il est le propriétaire de l’offre.
        <br />
        Mais ce sont les commerciaux régionaux qui vont répondre chacun à leur
        lot.
        <br />
        Le commercial de la région A sera ainsi le propriétaire de l’opportunité
        “lot région A”, le commercial de la région B sera lui le propriétaire de
        l’opportunité “lot région B”, …
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__column-owner",
  },
  {
    title: <h2>Colonne “statut”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Vous pouvez ici faire évoluer le statut de votre opportunité en fonction
        de votre avancée :<br />
        prospect {">"} en cours {">"} offre remise {">"}
        gagnée/perdue/abandonnée.
        <br />
        <br />
        IMPORTANT : <br />
        Vos indicateurs commerciaux sont mis à jour automatiquement par Smart
        Proposal.
        <br />
        La seule action que vous ayez à réaliser pour que vos indicateurs soient
        “propres” et exploitables est de mettre à jour le statut de vos
        opportunités.
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__column-status",
  },
  {
    title: <h2>Bouton “Cacher une opportunité”</h2>,
    content: (
      <div style={{ textAlign: "left" }}>
        Ce bouton vous permet de cacher une opportunité. Elle n’apparaîtra ainsi
        plus dans votre environnement.
        <br />
        <br />
        Une opportunité “cachée” est-elle “supprimée” ?<br />
        Non ! En cachant une opportunité, celle-ci disparaît de votre
        environnement et elle n’est bien entendu plus prise en compte dans vos
        indicateurs commerciaux.
        <br />
        Mais elle n’est pas supprimée en base de données.
        <br />
        Cela signifie qu’une personne avec un rôle “administrateur” de la
        solution Smart4 pourra toujours accéder à cette opportunité et pourra
        décider de la ré-afficher si besoin.
      </div>
    ),
    spotlightPadding: 5,
    target: ".table__hide-opportunity",
  },
];
