import React from "react";
import styled from "styled-components";
import { useStore } from "../../store";
import { getUrlParams } from "../../utils";
import useTodo from "../../hookQuery/useTodo";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";

const selector = (state) => ({
  formToken: state.formToken,
  setSelectedParameter: state.setSelectedParameter,
});

const columns = [
  {
    title: "Intitulé",
    dataIndex: "name",
    width: "50%",
  },
];

function DefaultValueArray({ setParameterDetailsStatus, topRef }) {
  const { formToken, setSelectedParameter } = useStore(selector);

  const { data: defaultValues } = useTodo(
    formToken,
    `/default_value/${getUrlParams()}`,
    "DefaultValues"
  );

  const openUpdate = (value) => {
    setSelectedParameter(value);
    setParameterDetailsStatus({ status: "update", type: "default_value" });
  };

  return (
    <StyledTable
      columns={columns}
      dataSource={
        defaultValues && defaultValues[0]
          ? [{ name: "Valeurs par défaut" }]
          : []
      }
      scroll={{ y: 400 }}
      pagination={false}
      rowClassName={["cursor-pointer"]}
      onRow={(_, idx) => {
        return {
          onClick: () => {
            openUpdate(defaultValues[idx]);
            topRef.current?.scrollIntoView({ behavior: "smooth" });
          },
        };
      }}
    />
  );
}

const StyledTable = styled(TableContainer)`
  margin: 10px 0;
`;

export default DefaultValueArray;
