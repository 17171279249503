import { formatNumberString } from "./formatNumberString";

export const inputNumberFormHelper = ({ form, key, onBlur }) => ({
  onInput: (e) => {
    e.currentTarget.value = formatNumberString({
      str: e.currentTarget.value,
      nbDecimal: 2,
    });
  },
  onFocus: (e) => {
    form.setFieldsValue({
      [key]: formatNumberString({
        str: e.currentTarget.value,
        nbDecimal: 2,
      }),
    });
  },
  onBlur: (e) => {
    form.setFieldsValue({
      [key]: formatNumberString({
        str: e.currentTarget.value,
        nbDecimal: 2,
        space: true,
      }),
    });
    onBlur(e);
  },
});
