import React, { useState } from "react";
import styled from "styled-components";
import { useQuery } from "react-query";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Separator from "../../react-ui/Separator";
import SAResourceTable from "./SAResourceTable";
import FilterHeader from "./FilterHeader";
import TitleContainer from "../../react-ui/TitleContainer";
import { getData } from "../../request/instance";
import { fetchFoldersUrl } from "../../../utils/fetchFoldersUrl";
import SAZipTable from "./SAZipTable";

function SA({
  opportunity_ids,
  formToken,
  closeModal,
  isOpen,
  siteTypologies,
  opportunity_names,
  offer_name,
  offer_id,
  fromOffer,
  opportunity_work,
}) {
  const [wordEntered, setWordEntered] = useState("");
  const [columnsToShowResource, setColumnsToShowResource] = useState([]);

  const { data: folders, isLoading: foldersAreLoading } = useQuery(
    ["Folders"],
    () =>
      getData(
        formToken,
        fetchFoldersUrl({
          search: undefined,
        })
      ),
    { refetchOnWindowFocus: false, enabled: isOpen }
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Container>
        <Wrapper>
          <TitleContainer label="Ressources disponibles" />
          <FilterHeader
            columnsToShow={columnsToShowResource}
            setColumnsToShow={setColumnsToShowResource}
            wordEntered={wordEntered}
            setWordEntered={setWordEntered}
            isSearch
          />
          <SAResourceTable
            folders={folders}
            foldersAreLoading={foldersAreLoading}
            opportunity_ids={opportunity_ids}
            formToken={formToken}
            siteTypologies={siteTypologies}
            columnsToShow={columnsToShowResource}
            opportunity_names={opportunity_names}
            offer_name={offer_name}
            offer_id={offer_id}
            wordEntered={wordEntered}
            fromOffer={fromOffer}
          />
        </Wrapper>
        <Separator />
        <Wrapper>
          <SAZipTable
            folders={folders}
            siteTypologies={siteTypologies}
            opportunity_ids={opportunity_ids}
            opportunity_work={opportunity_work}
            formToken={formToken}
            closeModal={closeModal}
            offer_id={offer_id}
            fromOffer={fromOffer}
          />
        </Wrapper>
      </Container>
    </DndProvider>
  );
}

const Container = styled.div`
  display: flex;
  height: 100%;
  max-width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
`;

export default SA;
