import React from "react";
import { string } from "prop-types";
import styled from "styled-components";

function Plus({ className, ...iconProps }) {
  return (
    <StyledIcon
      className={`fa-duotone fa-circle-plus ${className}`}
      {...iconProps}
    />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: 12px;
`;

Plus.propTypes = {
  className: string,
};

Plus.defaultProps = {
  className: "",
};

export default Plus;
