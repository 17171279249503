import React, { useCallback, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled from "styled-components";
import RulesColumn from "./RulesColumn";

function RulesAttributions({
  allRules,
  ruleProfilRules,
  updateRuleProfilRule,
  profilRuleId,
}) {
  const getNonAttributedRules = () =>
    allRules.filter(
      (r) => !ruleProfilRules.some((rpr) => rpr.rule_id === r.id)
    );
  const getAttributedRules = () =>
    allRules
      .filter((r) => ruleProfilRules.some((rpr) => rpr.rule_id === r.id))
      .sort(
        (a, b) =>
          ruleProfilRules.find((rpr) => rpr.rule_id === a.id).position -
          ruleProfilRules.find((rpr) => rpr.rule_id === b.id).position
      );

  const rulesToSend = useRef([]);

  const [rulesColumns, setRulesColumns] = useState([
    {
      id: 1,
      rules: getNonAttributedRules(),
      title: "Règles du métier",
    },
    {
      id: 2,
      rules: getAttributedRules(),
      title: "Appliquées (par ordre de priorité)",
    },
  ]);

  const rowPositionner = useCallback(
    ({ rules, id, title, draggedId, overId, overColumnId }) => {
      const newRules = rules.filter((r) => r.id !== draggedId);
      const newRuleRow = allRules.find((r) => r.id === draggedId);
      const overRuleIdx = overId
        ? rules.findIndex((r) => r.id === overId)
        : rules.length;
      if (id === overColumnId) {
        newRules.splice(overRuleIdx, 0, newRuleRow);
      }
      return {
        id,
        title,
        rules: newRules,
      };
    },
    [allRules]
  );

  const moveRuleRow = useCallback(
    ({ draggedId, overId, overColumnId }) => {
      setRulesColumns((rcols) =>
        rcols.map(({ rules, id, title }) => {
          const newRulesCol = rowPositionner({
            rules,
            id,
            title,
            draggedId,
            overId,
            overColumnId,
          });
          if (id === 2) rulesToSend.current = newRulesCol.rules;
          return newRulesCol;
        })
      );
    },
    [setRulesColumns, rowPositionner]
  );

  const onDrop = useCallback(() => {
    updateRuleProfilRule({
      profil_rule_id: profilRuleId,
      rule_profil_rules: rulesToSend.current.map((r, idx) => ({
        rule_id: r.id,
        profil_rule_id: profilRuleId,
        position: idx,
      })),
    });
    rulesToSend.current = [];
  }, [updateRuleProfilRule, profilRuleId]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Container>
        {rulesColumns.map(({ title, rules, id }) => (
          <RulesColumn
            key={id}
            id={id}
            title={title}
            rules={rules}
            moveRuleRow={moveRuleRow}
            onDrop={onDrop}
          />
        ))}
      </Container>
    </DndProvider>
  );
}

const Container = styled.div`
  padding-top: 32px;
  display: flex;
  flex: 1;
  gap: 24px;
`;

export default RulesAttributions;
