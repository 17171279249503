import React, { useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { numberSorter } from "../../../utils/numberSorter";
import { stringSorter } from "../../../utils/stringSorter";
import TableContainer from "../../Chiffrage/CostingMovementsCarrycots/TableContainer";
import ActionsContainer from "../../react-ui/ActionsContainer";
import CenteredCell from "../../react-ui/CenteredCell";
import Delete from "../../react-ui/Icons/Delete";
import NumberCell from "../../react-ui/NumberCell";
import { getData, postData } from "../../request/instance";
import { useStore } from "../../store";
import { ShowDeleteConfirm } from "../confirmModal";

const selector = (state) => ({
  formToken: state.formToken,
});

function RentalOfferArray({ companyWorkId, setSelectedRental }) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();

  const { data: rentalOffers, isLoading } = useQuery(
    ["RentalOffers", { companyWorkId }],
    () => getData(formToken, `/rental_offers?company_work_id=${companyWorkId}`)
  );
  const { mutate: deleteRentalOffer } = useMutation(
    (todo) => postData(formToken, "/rental_offer/delete", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("RentalOffers");
      },
    }
  );

  const handleDeleteRentalOffer = (id) => {
    deleteRentalOffer({ id });
  };

  const columnsFA = [
    {
      title: "Intitulé",
      dataIndex: "name",
      sorter: (a, b) => stringSorter(a.name, b.name),
      width: "30%",
    },
    {
      title: "Type de période",
      dataIndex: "periodType",
      sorter: (a, b) => stringSorter(a.name, b.name),
      width: "22%",
    },
    {
      title: "Date de début",
      dataIndex: "startDate",
      render: (startDate) =>
        startDate && (
          <NumberCell>
            {new Date(startDate).toLocaleDateString("fr-FR")}
          </NumberCell>
        ),
      sorter: (a, b) => stringSorter(a.startDate, b.startDate),
      width: "19%",
    },
    {
      title: "Date de fin",
      dataIndex: "endDate",
      render: (endDate) =>
        endDate && (
          <NumberCell>
            {new Date(endDate).toLocaleDateString("fr-FR")}
          </NumberCell>
        ),
      sorter: (a, b) => stringSorter(a.endDate, b.endDate),
      width: "19%",
    },
    {
      title: "Favori",
      dataIndex: "favorite",
      render: (favorite) => (
        <CenteredCell>
          {favorite ? (
            <StartIcon className="fa-duotone fa-star text-warning" />
          ) : null}
        </CenteredCell>
      ),
      sorter: (a, b) => numberSorter(a.status, b.status),
      width: "5%",
    },
    {
      title: "Supp.",
      dataIndex: "delete",
      render: (_, record) => (
        <ActionsContainer>
          <Delete
            onClick={(e) => {
              ShowDeleteConfirm(
                record.name,
                record.id,
                handleDeleteRentalOffer
              );
              e.stopPropagation();
            }}
          />
        </ActionsContainer>
      ),
      width: "5%",
    },
  ];

  const dataSource = useMemo(
    () =>
      rentalOffers?.map((el) => ({
        ...el,
        key: el.id,
        endDate: el.end_date,
        startDate: el.start_date,
        periodType: el.period_type,
      })),
    [rentalOffers]
  );

  return (
    <TableContainer
      bordered={false}
      scrollY
      columns={columnsFA}
      dataSource={dataSource}
      pagination={false}
      rowClassName={["cursor-pointer"]}
      loading={isLoading}
      onRow={(record) => {
        return {
          onClick: () => {
            setSelectedRental(rentalOffers.find((el) => el.id === record.id));
          },
        };
      }}
    />
  );
}

const StartIcon = styled.i`
  font-size: 14px;
`;

export default RentalOfferArray;
