import React from "react";
import styled from "styled-components";

function ArrowDown({ className = "", ...iconProps }) {
  return (
    <StyledIcon
      className={`fa-duotone fa-sort-down ${className}`}
      {...iconProps}
    />
  );
}

const StyledIcon = styled.i`
  cursor: ${({ onClick }) => (onClick !== undefined ? "pointer" : "auto")};
  font-size: 12px;
`;

export default ArrowDown;
