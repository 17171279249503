import React, { useState } from "react";
import { Form } from "antd";
import styled from "styled-components";
import { useMutation } from "react-query";
import { useStore } from "../store";
import { postData } from "../request/instance";
import { validateMessages } from "../constant";
import TitleContainer from "../react-ui/TitleContainer";
import AddButton from "../react-ui/AddButton";
import FormRow from "./FormRow";
import FormCol from "./FormCol";
import Input from "../react-ui/Input";
import Select from "../react-ui/Select";
import Button from "../react-ui/Button";
import ClientsSelect from "../Admin-cf/Clients/Form/ClientsSelect";

const selector = (state) => ({
  formToken: state.formToken,
});

export const contactField = {
  firstName: "first_name",
  lastName: "last_name",
  address1: "address1",
  address2: "address2",
  gender: "gender",
  work: "work",
  city: "city",
  zipCode: "zipcode",
  client: "client",
  fonction: "fonction",
  phone: "phone",
  email: "email",
};

function ContactForm({
  companyId,
  onSuccess = () => null,
  onClose = () => null,
  setClientsInMemory,
  clientsInMemory,
  clientDisabled,
  formatClients,
  initialValues,
  vocabulary,
}) {
  const [form] = Form.useForm();
  const { formToken } = useStore(selector);
  const [clients, setClients] = useState([]);

  const { mutate: createContact, isLoading } = useMutation(
    (todoData) =>
      postData(formToken, `/companies/${companyId}/contacts`, todoData),
    {
      onSuccess,
    }
  );

  const handleOnClose = () => {
    onClose();
    form.resetFields();
  };

  const handleSubmit = (values) => {
    const { client, ...valuesToUse } = values;
    createContact({ contact: { ...valuesToUse, client_id: [client.value] } });
    handleOnClose();
  };

  return (
    <Form
      id="new-contact-form"
      form={form}
      colon={false}
      requiredMark={false}
      onFinish={handleSubmit}
      validateMessages={validateMessages}
      initialValues={initialValues}
    >
      <TitleContainer label="Créer un contact">
        <StyledButton
          label="Annuler"
          onClick={handleOnClose}
          fontSize="14px"
          btnType="cancel"
          type="button"
        />
        <AddButton
          label="Créer"
          type="submit"
          value="submit"
          fontSize="14px"
          loading={isLoading}
        />
      </TitleContainer>
      <FormContainer>
        <FormRow>
          <FormCol
            width="50%"
            label="Prénom"
            rules={[{ required: true }]}
            name={contactField.firstName}
          >
            <Input placeholder="Prénom" />
          </FormCol>
          <FormCol
            width="50%"
            label="Nom"
            name={contactField.lastName}
            rules={[{ required: true }]}
          >
            <Input placeholder="Nom" />
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol width="50%" label="Adresse" name={contactField.address1}>
            <Input placeholder="Adresse" />
          </FormCol>
          <FormCol width="50%" label="Ville" name={contactField.city}>
            <Input placeholder="Ville" />
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol
            width="50%"
            label="Complément d'adresse"
            name={contactField.address2}
          >
            <Input placeholder="Complément d'adresse" />
          </FormCol>
          <FormCol width="50%" label="Code postal" name={contactField.zipCode}>
            <Input placeholder="Code postal" />
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol width="50%" label="Genre" name={contactField.gender}>
            <StyledSelect
              options={[
                { name: "Homme", id: "homme" },
                { name: "Femme", id: "femme" },
              ]}
            />
          </FormCol>
          <FormCol
            width="50%"
            label={vocabulary?.clients?.client_uppercase ?? "Entreprise"}
            rules={[{ required: true }]}
            name={contactField.client}
          >
            <ClientsSelect
              setClientsInMemory={setClientsInMemory}
              clientsInMemory={clientsInMemory}
              clients={clients}
              setClients={setClients}
              isShowing={clientDisabled}
              formatClients={formatClients}
            />
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol width="50%" label="Email" name={contactField.email}>
            <Input placeholder="Email" />
          </FormCol>
          <FormCol width="50%" label="Fonction" name={contactField.fonction}>
            <Input placeholder="Fonction" />
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol
            width="50%"
            label="N° de téléphone"
            name={contactField.phone}
          >
            <Input placeholder="N° de téléphone" />
          </FormCol>
        </FormRow>
      </FormContainer>
    </Form>
  );
}

const StyledSelect = styled(Select)`
  width: 100%;
`;

const FormContainer = styled.div`
  padding: 0 30px 16px 30px;
`;

const StyledButton = styled(Button)`
  margin-right: 20px;
`;

export default ContactForm;
