import React, { useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { getData } from "../../../request/instance";
import { useStore } from "../../../store";
import {
  constructMinsOrH,
  formatInitialWorkPeriods,
} from "../../../../utils/planning";
import AddButton from "../../../react-ui/AddButton";

const selector = (state) => ({
  formToken: state.formToken,
});

const dayTypeToDate = {
  monday: "1",
  tuesday: "2",
  wednesday: "3",
  thursday: "4",
  friday: "5",
  saturday: "6",
  sunday: "7",
  work_free_day: "8",
};

const formatEvent = ({
  startDate,
  endDate,
  startDayType,
  endDayType,
  endAtMidnight,
  id,
  planningId,
  pending = false,
  fromApi = false,
}) => {
  const startHour =
    (startDate.getUTCHours() < 10 ? "0" : "") + startDate.getUTCHours();
  const startMinutes =
    (startDate.getMinutes() < 10 ? "0" : "") + startDate.getMinutes();
  const endHour =
    (endDate.getUTCHours() < 10 ? "0" : "") + endDate.getUTCHours();
  const endMinutes =
    (endDate.getMinutes() < 10 ? "0" : "") + endDate.getMinutes();
  let endingTime = `2020-06-0${endDayType}T${endHour}:${endMinutes}:00`;

  if (endAtMidnight) {
    endingTime = `2020-06-0${Number.parseInt(endDayType, 10) + 1}T00:00:00`;
  }

  const diff =
    (new Date(endingTime).getTime() -
      new Date(
        `2020-06-0${startDayType}T${startHour}:${startMinutes}:00`
      ).getTime()) /
    1000;
  const diffHours = Math.trunc(diff / (60 * 60));
  const diffMins = Math.abs(Math.round(diff / 60 - diffHours * 60));

  return {
    id: `${id}`,
    start: `2020-06-0${startDayType}T${startHour}:${startMinutes}:00`,
    end: endingTime,
    title: `${diffHours ? `(${diffHours}h` : ""}${
      diffMins ? `${constructMinsOrH(diffMins)}min` : ""
    })`,
    backgroundColor: pending ? "#faad14" : undefined,
    borderColor: pending ? "#faad14" : undefined,
    extendedProps: {
      pending,
      planningId,
      fromApi,
    },
  };
};

function WeeklyCalendar({
  planningId,
  workPositionId,
  nightTimeStart,
  nightTimeEnd,
  destroyAndCreateWPeriod,
  newWpIsLoading,
  setWorkPeriodsModalObj,
  isDelWorkPeriodLoading,
  configApplication,
  isLockedByUser,
}) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const ref = useRef();
  const { data: workPeriodsApi, isLoading } = useQuery(
    ["WorkPeriods", { workPositionId }],
    async () => {
      const data = await getData(
        formToken,
        `/work_periods?work_position_id=${workPositionId}`
      );
      return formatInitialWorkPeriods(data);
    },
    {
      refetchOnWindowFocus: false,
      placeholderData: [],
      onSuccess: () => {
        queryClient.invalidateQueries(["Planning", { workPositionId }]);
        queryClient.invalidateQueries(["MonthlyCalendar", { workPositionId }]);
      },
    }
  );

  const columnHeaderCustom = (date) => {
    const days = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    const nextMonday = new Date(2020, 5, 8);
    if (date.getDate() === nextMonday.getDate()) {
      return "Jour férié";
    }
    return days[date.getDay()];
  };

  const handleSelect = (selectionInfo) => {
    destroyAndCreateWPeriod(
      {
        start_datetime: selectionInfo.start,
        end_datetime: selectionInfo.end,
        planning_id: planningId,
      },
      {
        onError: () => {
          selectionInfo.view.context.calendar.unselect();
        },
        onSuccess: () => {
          selectionInfo.view.context.calendar.unselect();
        },
      }
    );
  };

  const handleResizeOrDrop = ({ revert, event }) => {
    destroyAndCreateWPeriod(
      {
        id: event.id,
        start_datetime: event.start,
        end_datetime: event.end,
        planning_id: planningId,
      },
      {
        onError: () => {
          revert();
        },
      }
    );
  };
  const buisnessHourEvents = () => {
    const { work_free_day, sunday, ...buisnessableDay } = dayTypeToDate;
    const isNightTimeOnSameDay = nightTimeEnd > nightTimeStart;
    const events = [];

    Object.values(buisnessableDay).forEach((day) => {
      if (isNightTimeOnSameDay)
        events.push({
          start: `2020-06-0${day}T${nightTimeStart}:00`,
          end: `2020-06-0${day}T${nightTimeEnd}:00`,
          rendering: "background",
          backgroundColor: "hsla(0,0%,20%,.3)",
        });
      else {
        events.push({
          start: `2020-06-0${day}T00:00:00`,
          end: `2020-06-0${day}T${nightTimeEnd}:00`,
          rendering: "background",
          backgroundColor: "hsla(0,0%,20%,.3)",
        });
        events.push({
          start: `2020-06-0${day}T${nightTimeStart}:00`,
          end: `2020-06-0${day}T24:00:00`,
          rendering: "background",
          backgroundColor: "hsla(0,0%,20%,.3)",
        });
      }
    });
    events.push({
      start: `2020-06-07T00:00:00`,
      end: `2020-06-07T24:00:00`,
      rendering: "background",
      backgroundColor: "hsla(0,0%,20%,.3)",
    });
    events.push({
      start: `2020-06-08T00:00:00`,
      end: `2020-06-08T24:00:00`,
      rendering: "background",
      backgroundColor: "hsla(0,0%,20%,.3)",
    });
    return events;
  };

  const workPeriodApiEvents = () =>
    workPeriodsApi.map((wp) => {
      const startDate = new Date(wp.start_datetime);
      const endDate = new Date(wp.end_datetime);

      return formatEvent({
        startDate,
        endDate,
        id: wp.id,
        startDayType: dayTypeToDate[wp.day_type],
        endDayType: dayTypeToDate[wp.end_day_type],
        endAtMidnight: wp.end_at_midnight,
        fromApi: true,
      });
    });

  const eventAllow = (dropInfo) => {
    // Disallow before monday
    if (dropInfo.start.getUTCMonth() < 5) return false;

    // Disallow after work free day and handle end at midnight
    if (dropInfo.end.getUTCDate() > 9) return false;
    if (dropInfo.end.getUTCDate() === 9) {
      if (
        dropInfo.end.getUTCHours() === 0 &&
        dropInfo.end.getUTCMinutes() === 0 &&
        dropInfo.start.getUTCDate() === 8
      )
        return true;
      return false;
    }

    // Disallow between sunday/work free day and handle end at midnight
    if (dropInfo.end.getUTCDate() === 8 && dropInfo.start.getUTCDate() !== 8) {
      if (
        dropInfo.end.getUTCHours() === 0 &&
        dropInfo.end.getUTCMinutes() === 0
      )
        return true;
      return false;
    }

    return true;
  };

  const constructWorkPeriodForForm = (el) => {
    const startDate = new Date(el.start);
    const endDate = new Date(el.end);
    return {
      ...el,
      startTime: startDate,
      endTime: endDate,
    };
  };

  const eventClick = (info) => {
    if (
      configApplication.guarding.workpositions.disable_weekly_calendar ||
      !isLockedByUser
    )
      return;
    const startDateInfo = new Date(
      `2020-06-0${info.event.start.getUTCDate()}T${constructMinsOrH(
        info.event.start.getUTCHours()
      )}:${constructMinsOrH(info.event.start.getMinutes())}:00`
    );
    const endDateInfo = new Date(
      `2020-06-0${info.event.end.getUTCDate()}T${constructMinsOrH(
        info.event.end.getUTCHours()
      )}:${constructMinsOrH(info.event.end.getMinutes())}:00`
    );
    setWorkPeriodsModalObj({
      selectedWorkPeriod: info.event,
      workPeriods: workPeriodApiEvents()
        .filter((el) => `${el.id}` !== `${info.event.id}`)
        .map(constructWorkPeriodForForm),
      similarWorkPeriods: workPeriodApiEvents()
        .filter((el) => {
          if (startDateInfo.getDate() === 8) return false;
          const startDate = new Date(el.start);
          const endDate = new Date(el.end);
          if (startDate.getDate() === 8) return false;
          if (`${el.id}` === `${info.event.id}`) return false;

          const duration = endDate.getTime() - startDate.getTime();
          const durationEvent = endDateInfo.getTime() - startDateInfo.getTime();

          if (duration !== durationEvent) return false;

          const startTime = `${startDate.getHours()}${startDate.getMinutes()}`;
          const eventStartTime = `${startDateInfo.getHours()}${startDateInfo.getMinutes()}`;

          if (startTime !== eventStartTime) return false;
          return true;
        })
        .map(constructWorkPeriodForForm),
    });
  };

  return (
    <Container>
      <FullCalendar
        ref={ref}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        height="parent"
        editable={
          !newWpIsLoading &&
          !isDelWorkPeriodLoading &&
          !configApplication.guarding.workpositions.disable_weekly_calendar &&
          isLockedByUser
        }
        selectable={!newWpIsLoading && !isDelWorkPeriodLoading}
        selectMirror
        timeZone="UTC"
        defaultView="timeGridEightDay"
        dateAlignment="week"
        views={{
          timeGridEightDay: {
            type: "timeGrid",
            visibleRange: {
              start: "2020-06-01",
              end: "2020-06-09",
            },
          },
        }}
        firstDay={1}
        slotDuration="01:00:00"
        slotLabelInterval={{ hours: 1 }}
        columnHeaderFormat={{ weekday: "long" }}
        slotEventOverlap={false}
        header={false}
        allDaySlot={false}
        locale="fr"
        columnHeaderText={columnHeaderCustom}
        eventSources={[
          {
            events: buisnessHourEvents().concat(workPeriodApiEvents()),
          },
        ]}
        eventOverlap={(stillEvent) => {
          return stillEvent.rendering === "background";
        }}
        select={handleSelect}
        selectOverlap={(stillEvent) => {
          return stillEvent.rendering === "background";
        }}
        loading={isLoading || newWpIsLoading || isDelWorkPeriodLoading}
        eventResize={handleResizeOrDrop}
        eventDrop={handleResizeOrDrop}
        contentHeight="auto"
        eventAllow={eventAllow}
        selectAllow={eventAllow}
        eventClick={eventClick}
      />
      <StyledAddButton
        label="Créer une période"
        disabled={
          configApplication.guarding.workpositions
            .disable_create_weekly_calendar || !isLockedByUser
        }
        onClick={() =>
          setWorkPeriodsModalObj({
            workPeriods: workPeriodApiEvents().map(constructWorkPeriodForForm),
            similarWorkPeriods: [],
          })
        }
      />
    </Container>
  );
}

const Container = styled.div`
  .fc th.fc-widget-header {
    font-size: 12px !important;
    padding: 2px 0;
    text-transform: none;
  }
  .fc-axis {
    font-size: 12px !important;
    line-height: 1.4;
  }
  .fc-time-grid .fc-slats td {
    height: auto;
  }
  .fc-view {
    margin: 0;
  }
  .fc-event-container {
    .fc-time-grid-event {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  .fc-content {
    .fc-time {
      font-weight: bold;
      font-size: 11px !important;
    }
  }

  .fc-title {
    color: ${({ theme }) => theme.colors.blue02};
    font-style: italic;
  }
`;

const StyledAddButton = styled(AddButton)`
  margin-top: 8px;
  margin-left: auto;
`;

export default WeeklyCalendar;
