import { Form } from "antd";
import React, { useEffect } from "react";
import styled from "styled-components";
import { validateMessages } from "../../../constant";
import FormRow from "../../../Form/FormRow";
import FormCol from "../../../Form/FormCol";
import ButtonCol from "./ButtonCol";
import Separator from "../../../react-ui/Separator";
import Input from "../../../react-ui/Input";
import CopySecret from "../../../react-ui/CopySecret";

function ApplicationForm({
  isUpdating,
  isShowing,
  handleSubmit,
  setStatus,
  selectedApplication,
  status,
  isLoading,
  isCreating,
  onCancel,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (status === "empty" || isCreating) form.resetFields();
  }, [status, form, isCreating]);

  useEffect(() => {
    if (selectedApplication) {
      const initialValues = {
        ...selectedApplication,
      };
      form.resetFields();
      form.setFieldsValue(initialValues);
    }
  }, [form, selectedApplication]);

  return (
    <Container>
      <Form
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
      >
        <StyledFormRow>
          <ButtonCol
            isCreating={isCreating}
            isUpdating={isUpdating}
            isShowing={isShowing}
            setStatus={setStatus}
            isLoading={isLoading}
            onCancel={onCancel}
          />
        </StyledFormRow>
        <Separator isHorizontal size={1} />
        <Row>
          <FormCol
            label="Nom"
            name="name"
            labelOnTop
            rules={[{ required: true }]}
          >
            <Input disabled={isShowing} />
          </FormCol>
          <FormCol
            label="URL de redirection"
            name="redirect_uri"
            labelOnTop
            rules={[{ required: true }]}
          >
            <Input disabled={isShowing} />
          </FormCol>
        </Row>

        {!isCreating && (
          <>
            {" "}
            <Separator isHorizontal size={1} />
            <StyledFormRow>
              <FormCol
                label="ID du client"
                name="uid"
                labelOnTop
                rules={[{ required: true }]}
                valuePropName="secret"
                width="50%"
              >
                <CopySecret copyText="Copier l'id client" />
              </FormCol>
            </StyledFormRow>
            <StyledFormRow>
              <FormCol
                label="Secret du client"
                name="secret"
                labelOnTop
                rules={[{ required: true }]}
                valuePropName="secret"
                width="50%"
              >
                <CopySecret copyText="Copier le secret client" />
              </FormCol>
            </StyledFormRow>
          </>
        )}
      </Form>
    </Container>
  );
}

const Container = styled.div`
  overflow: scroll;
  width: 100%;
  height: calc(100% - 58px);
  .ant-form-item-explain-error {
    white-space: nowrap;
  }
`;

const Row = styled.div`
  display: flex;
`;

const StyledFormRow = styled(FormRow)`
  padding-top: 10px !important;
`;

export default ApplicationForm;
