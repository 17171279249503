import React, { useEffect, useState } from "react";
import { eurosToCents } from "../../utils/currencyConverter";
import { formatNumberString } from "../../utils/formatNumberString";
import { stringToFloat } from "../../utils/stringToFloat";
import Input from "./Input";

const formatString = ({ isNumber, value, nbDecimal, space = true }) =>
  isNumber ? formatNumberString({ str: value, space, nbDecimal }) : value;

function FormattedInput({
  value,
  updateItem = null,
  keyName,
  itemId,
  isNumber = false,
  toCents = false,
  suffix = undefined,
  disabled = false,
  nbDecimal = undefined,
  isRequired = false,
  status = undefined,
  size = "small",
  onFocus,
  onBlur,
  onChange,
  updateOnChange = false,
  ...inputProps
}) {
  const [inputValue, setInputValue] = useState(
    formatString({ isNumber, value, nbDecimal })
  );

  useEffect(() => {
    setInputValue((state) => {
      if (
        formatString({ isNumber, value: state, nbDecimal }) !==
        formatString({ isNumber, value, nbDecimal })
      )
        return formatString({ isNumber, value, nbDecimal });
      return state;
    });
  }, [value, isNumber, nbDecimal]);

  const sendValue = (currentValue = inputValue) => {
    let valueToSend = currentValue;
    if (isNumber && currentValue !== "") {
      if (toCents) {
        valueToSend = eurosToCents(currentValue);
      } else {
        valueToSend = stringToFloat(currentValue);
      }
    }
    updateItem({
      id: itemId,
      [keyName]: valueToSend,
    });
  };

  const handleOnBlur = (e) => {
    if (onBlur) onBlur(e);
    setInputValue(formatString({ isNumber, value: inputValue, nbDecimal }));
    if (!updateOnChange && updateItem) sendValue();
  };

  const handleOnChange = (e) => {
    e.currentTarget.value = formatString({
      isNumber,
      value: e.target.value,
      space: false,
      nbDecimal,
    });
    if (onChange) onChange(e);
    setInputValue(
      formatString({ isNumber, value: e.target.value, space: false, nbDecimal })
    );
    if (updateOnChange && updateItem) sendValue(e.currentTarget.value);
  };

  const handleOnFocus = (e) => {
    if (onFocus) onFocus(e);
    setInputValue(
      formatString({ isNumber, value: e.target.value, space: false, nbDecimal })
    );
  };

  return (
    <Input
      {...inputProps}
      fontSize={size === "small" ? "10px" : undefined}
      size={size}
      value={inputValue}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      onChange={handleOnChange}
      suffix={suffix}
      disabled={disabled}
      status={isRequired && inputValue?.length < 1 ? "error" : status}
    />
  );
}

export default FormattedInput;
