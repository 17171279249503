import { Modal } from "antd";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import styled from "styled-components";
import AddButton from "../../react-ui/AddButton";
import TitleContainer from "../../react-ui/TitleContainer";
import { postData } from "../../request/instance";
import { useStore } from "../../store";
import RentalOfferArray from "./RentalOfferArray";
import RentalOfferDetails from "./RentalOfferDetails";
import RentalOfferForm from "./RentalOfferForm";

const selector = (state) => ({
  formToken: state.formToken,
});

function RentalOffers({ companyWorkId, selectedRental, setSelectedRental }) {
  const { formToken } = useStore(selector);
  const queryClient = useQueryClient();
  const [openModal, setOpenModal] = useState(false);

  const { mutate: createRentalOffer } = useMutation(
    (todo) => postData(formToken, "/rental_offer/create", todo),
    {
      onSettled: () => {
        queryClient.invalidateQueries("RentalOffers");
      },
    }
  );

  const constructRentalOfferFormData = (e) => {
    const formData = new FormData();
    const formatableKeys = ["favorite"];

    Object.keys(e).forEach(
      (key) =>
        !formatableKeys.includes(key) &&
        formData.append(
          `rental_offer[${key}]`,
          e[key] !== undefined && e[key] !== null ? e[key] : ""
        )
    );

    if (e.favorite !== undefined && e.favorite !== null)
      formData.append("rental_offer[favorite]", e.favorite);

    formData.append("rental_offer[company_work_id]", companyWorkId);
    return formData;
  };

  const onSubmit = (e) => {
    const formData = constructRentalOfferFormData(e);
    createRentalOffer(formData);
    setOpenModal(false);
  };

  return selectedRental ? (
    <RentalOfferDetails
      setSelectedRental={setSelectedRental}
      selectedRental={selectedRental}
      constructRentalOfferFormData={constructRentalOfferFormData}
    />
  ) : (
    <Container>
      <Modal
        open={openModal}
        maskClosable={false}
        footer={null}
        width={1000}
        onCancel={() => setOpenModal(false)}
        closable={false}
        destroyOnClose
      >
        <RentalOfferForm
          onSubmit={onSubmit}
          submit={{ onCancel: () => setOpenModal(false) }}
        />
      </Modal>
      <TitleContainer label="Offres locatives">
        <AddButton onClick={() => setOpenModal(true)} label="Offre locative" />
      </TitleContainer>
      <RentalOfferArray
        companyWorkId={companyWorkId}
        setSelectedRental={setSelectedRental}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  overflow-x: hidden;
  height: 100%;
`;

export default RentalOffers;
