import React from "react";
import { Collapse as AntdCollapse } from "antd";
import styled from "styled-components";
import { node, string } from "prop-types";

function Collapse({ children, className = "", ...collapseProps }) {
  return (
    <StyledCollapse className={className} {...collapseProps}>
      {children}
    </StyledCollapse>
  );
}

const StyledCollapse = styled(AntdCollapse)`
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  margin-bottom: 5px;
`;

Collapse.propTypes = {
  children: node,
  className: string,
};

Collapse.defaultProps = {
  children: null,
  className: "",
};

export default Collapse;
